"use client";

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import Image from "next/image";
// import LoadingGif from "../public/loading.gif";

function ClientOnly({ children }) {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted)
    return (
      <div className="viewport-loading">
        <div className="center-content">
          {/* <Image src={LoadingGif} alt="loading" width="90" height="120" /> */}
        </div>
      </div>
    );
  return children;
}
export default ClientOnly;

ClientOnly.propTypes = {
  children: PropTypes.node.isRequired,
};

import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { setError } from "../../../features/flight/flightSlice";

function ErrorHandler({ code }) {
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(setError());
  }
  return (
    <div className={styles.error}>
      {/* <div>
        <h3>
          Seems we are experiencing some downtime on our flight search engine
        </h3>
        <p>Please check back after a while.</p>
      </div> */}

      {code === undefined && (
        <div>
          <h3>
            Seems we are experiencing some downtime on our flight search engine
          </h3>
          <p>Please check back after a while.</p>
        </div>
      )}
      {code === "141" && (
        <div>
          <h3>
            Seems we are experiencing some downtime on our flight search engine
          </h3>
          <p>Please check back after a while.</p>
        </div>
      )}
      {code === "425" && (
        <div>
          <h3>The Date you Provided isn&apos;t valid</h3>
          <p>Enter a preffered date.</p>
        </div>
      )}
      {code === "477" && (
        <div>
          <h3>Search not Valid</h3>
          <b>POSSIBLE REASONS</b>
          <p>
            You didn&apos;t provide an important Parameter eg ORIGIN,
            DESTINATION OR DATE
          </p>
        </div>
      )}
      {code === "2668" && (
        <div>
          <h3>Parameter combination is Invalid</h3>
          <p>You Origin and Destination are thesame.</p>
        </div>
      )}
      {code === "4926" && (
        <div>
          <h3>The booking data you provided is invalid</h3>
          <b>POSSIBLE REASONS</b>
          <p>Your traveller selection is enumeration is not allowed.</p>
          <p>Your Origin and Destination are thesame.</p>
        </div>
      )}
      {code === "10661" && (
        <div>
          <h3>Maximum number of occurences is exceded</h3>
          <p>Maximum number of occurences is exceded.</p>
        </div>
      )}
      {code === "32171" && (
        <div>
          <h3>Mandatory Data is missing</h3>
          <p>Mandatory Data is missing.</p>
        </div>
      )}

      <button type="button" onClick={handleClose}>
        Close
      </button>
    </div>
  );
}
ErrorHandler.propTypes = {
  code: PropTypes.number.isRequired,
};
export default ErrorHandler;

import PropTypes from "prop-types";
import React from "react";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
// import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";

function FlightSwitcher({ index, toggleSegment }) {
  return (
    <div className={styles.switch}>
      <HiOutlineSwitchHorizontal
        onClick={() => {
          toggleSegment(index);
        }}
        className={styles.icon}
      />
    </div>
  );
}
FlightSwitcher.propTypes = {
  index: PropTypes.number.isRequired,
  toggleSegment: PropTypes.func.isRequired,
};
export default FlightSwitcher;

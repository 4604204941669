/* eslint-disable react/button-has-type */
import PropTypes from "prop-types";
import React from "react";
import Deck from "./Deck";
import styles from "./styles.module.scss";

function SeatMap({
  isOpen,
  setIsOpen,
  index,
  seatData,
  handleSeatSelection,
  seatmaps,
  flight,
}) {
  const handleClose = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const seatsshow = seatmaps?.data;
  console.log(seatData);
  console.log(seatsshow);
  return (
    <div className={styles.seatmap}>
      {isOpen && (
        <div className={styles.container}>
          <div className={styles.popUp} onClick={(e) => e.stopPropagation()}>
            {seatData.map((traveler, travelerIndex) => (
              <div className={styles.seatMapContainer} key={travelerIndex}>
                <div className={styles.infoSection}>
                  {/* {selectedSeats.length > 0 ? (
                  <p>Selected Seats: {selectedSeats.join(", ")}</p>
                ) : (
                  <p>Select seat(s)</p>
                )} */}
                  <div>
                    <h2>Legend</h2>
                    <div className={styles.legend}>
                      <div className={styles.legendItem}>
                        <div
                          className={styles.legendColor}
                          style={{
                            backgroundColor: "#7afa6b",
                            width: "1em",
                            height: "1em",
                            borderRadius: "2px",
                          }}
                        />
                        <p>Available</p>
                      </div>
                      <div className={styles.legendItem}>
                        <div
                          className={styles.legendColor}
                          style={{
                            backgroundColor: "#FE5F55",
                            width: "1em",
                            height: "1em",
                            borderRadius: "2px",
                          }}
                        />
                        <p>Blocked</p>
                      </div>
                      <div className={styles.legendItem}>
                        <div
                          className={styles.legendColor}
                          style={{
                            backgroundColor: "grey",
                            width: "1em",
                            height: "1em",
                            borderRadius: "2px",
                          }}
                        />
                        <p>Occupied</p>
                      </div>
                      <div className={styles.legendItem}>
                        <div
                          className={styles.legendColor}
                          style={{
                            backgroundColor: "purple",
                            width: "1em",
                            height: "1em",
                            borderRadius: "2px",
                          }}
                        />
                        <p>Selected</p>
                      </div>
                    </div>
                    <button
                      className={styles.closeButton}
                      onClick={(e) => handleClose(e)}
                    >
                      Done
                    </button>
                  </div>
                </div>
                <div className={styles.planeSection}>
                  <p>Select seat for traveler {travelerIndex + 1}</p>
                  {seatsshow?.[index].decks.map((deck, i) => (
                    <Deck
                      segmentId={seatsshow[index].segmentId}
                      deck={deck}
                      key={i}
                      segmentindex={index}
                      travelerindex={travelerIndex}
                      onSeatSelect={handleSeatSelection}
                      seatData={traveler}
                      segmentdata={
                        flight.travelerPricings[travelerIndex]
                          .fareDetailsBySegment[index]
                      }
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SeatMap;

SeatMap.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  seatData: PropTypes.shape().isRequired,
  seatmaps: PropTypes.shape().isRequired,
  handleSeatSelection: PropTypes.func.isRequired,
  flight: PropTypes.shape().isRequired,
};

export const airports = [
  {
    ident: "03N",
    type: "small_airport",
    name: "Utirik Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-UTI",
    municipality: "Utirik Island",
    gps_code: "K03N",
    iata_code: "UTK",
    local_code: "03N",
    coordinates: "169.852005, 11.222",
  },
  {
    ident: "07FA",
    type: "small_airport",
    name: "Ocean Reef Club Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Key Largo",
    gps_code: "07FA",
    iata_code: "OCA",
    local_code: "07FA",
    coordinates: "-80.274803161621, 25.325399398804",
  },
  {
    ident: "0AK",
    type: "small_airport",
    name: "Pilot Station Airport",
    elevation_ft: "305",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Pilot Station",
    iata_code: "PQS",
    local_code: "0AK",
    coordinates: "-162.899994, 61.934601",
  },
  {
    ident: "0CO2",
    type: "small_airport",
    name: "Crested Butte Airpark",
    elevation_ft: "8980",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Crested Butte",
    gps_code: "0CO2",
    iata_code: "CSE",
    local_code: "0CO2",
    coordinates: "-106.928341, 38.851918",
  },
  {
    ident: "0TE7",
    type: "small_airport",
    name: "LBJ Ranch Airport",
    elevation_ft: "1515",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Johnson City",
    gps_code: "0TE7",
    iata_code: "JCY",
    local_code: "0TE7",
    coordinates: "-98.62249755859999, 30.251800537100003",
  },
  {
    ident: "13MA",
    type: "small_airport",
    name: "Metropolitan Airport",
    elevation_ft: "418",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Palmer",
    gps_code: "13MA",
    iata_code: "PMX",
    local_code: "13MA",
    coordinates: "-72.31140136719999, 42.223300933800004",
  },
  {
    ident: "13Z",
    type: "seaplane_base",
    name: "Loring Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Loring",
    gps_code: "13Z",
    iata_code: "WLR",
    local_code: "13Z",
    coordinates: "-131.636993408, 55.6012992859",
  },
  {
    ident: "16A",
    type: "small_airport",
    name: "Nunapitchuk Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nunapitchuk",
    gps_code: "PPIT",
    iata_code: "NUP",
    local_code: "16A",
    coordinates: "-162.440454, 60.905591",
  },
  {
    ident: "16K",
    type: "seaplane_base",
    name: "Port Alice Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Alice",
    gps_code: "16K",
    iata_code: "PTC",
    local_code: "16K",
    coordinates: "-133.597, 55.803",
  },
  {
    ident: "19AK",
    type: "small_airport",
    name: "Icy Bay Airport",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Icy Bay",
    gps_code: "19AK",
    iata_code: "ICY",
    local_code: "19AK",
    coordinates: "-141.662002563, 59.96900177",
  },
  {
    ident: "19P",
    type: "seaplane_base",
    name: "Port Protection Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Protection",
    gps_code: "19P",
    iata_code: "PPV",
    local_code: "19P",
    coordinates: "-133.61000061035, 56.328800201416",
  },
  {
    ident: "1KC",
    type: "small_airport",
    name: "Kalakaket Creek AS Airport",
    elevation_ft: "1598",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kalakaket Creek",
    gps_code: "1KC",
    iata_code: "KKK",
    local_code: "1KC",
    coordinates: "-156.820392609, 64.4166256967",
  },
  {
    ident: "1O6",
    type: "small_airport",
    name: "Dunsmuir Muni-Mott Airport",
    elevation_ft: "3258",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Dunsmuir",
    gps_code: "KMHS",
    iata_code: "MHS",
    local_code: "1O6",
    coordinates: "-122.272003, 41.263199",
  },
  {
    ident: "1XA2",
    type: "small_airport",
    name: "Chase Field Industrial Airport",
    elevation_ft: "190",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Beeville",
    iata_code: "NIR",
    local_code: "TX2",
    coordinates: "-97.661917, 28.362444",
  },
  {
    ident: "1Z1",
    type: "small_airport",
    name: "Grand Canyon Bar Ten Airstrip",
    elevation_ft: "4100",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Whitmore",
    iata_code: "GCT",
    local_code: "1Z1",
    coordinates: "-113.231159, 36.258614",
  },
  {
    ident: "1Z9",
    type: "seaplane_base",
    name: "Ellamar Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ellamar",
    iata_code: "ELW",
    local_code: "1Z9",
    coordinates: "-146.704038, 60.893818",
  },
  {
    ident: "2AK",
    type: "small_airport",
    name: "Lime Village Airport",
    elevation_ft: "552",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Lime Village",
    gps_code: "2AK",
    iata_code: "LVD",
    local_code: "2AK",
    coordinates: "-155.440002441, 61.3591003418",
  },
  {
    ident: "2AK6",
    type: "small_airport",
    name: "Hog River Airport",
    elevation_ft: "534",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Hogatza",
    gps_code: "2AK6",
    iata_code: "HGZ",
    local_code: "2AK6",
    coordinates: "-155.6690063, 66.2161026",
  },
  {
    ident: "2IG4",
    type: "small_airport",
    name: "Ed-Air Airport",
    elevation_ft: "426",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Oaktown",
    gps_code: "I20",
    iata_code: "OTN",
    local_code: "I20",
    coordinates: "-87.4997024536, 38.851398468",
  },
  {
    ident: "2K5",
    type: "small_airport",
    name: "Telida Airport",
    elevation_ft: "650",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Telida",
    gps_code: "2K5",
    iata_code: "TLF",
    local_code: "2K5",
    coordinates: "-153.26899719238, 63.393901824951",
  },
  {
    ident: "2TE0",
    type: "small_airport",
    name: "Eagle Air Park",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Brazoria",
    gps_code: "2TE0",
    iata_code: "BZT",
    local_code: "2TE0",
    coordinates: "-95.579696655273, 28.982200622559",
  },
  {
    ident: "2Z1",
    type: "seaplane_base",
    name: "Entrance Island Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Entrance Island",
    iata_code: "HBH",
    coordinates: "-133.43848, 57.412201",
  },
  {
    ident: "2Z6",
    type: "seaplane_base",
    name: "False Island Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "False Island",
    gps_code: "2Z6",
    iata_code: "FAK",
    local_code: "2Z6",
    coordinates: "-135.212997437, 57.5321998596",
  },
  {
    ident: "38WA",
    type: "small_airport",
    name: "Blakely Island Airport",
    elevation_ft: "66",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Blakely Island",
    gps_code: "38WA",
    iata_code: "BYW",
    local_code: "38WA",
    coordinates: "-122.825996399, 48.578998565700005",
  },
  {
    ident: "3AK5",
    type: "small_airport",
    name: "Drift River Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kenai",
    gps_code: "3AK5",
    iata_code: "DRF",
    local_code: "3AK5",
    coordinates: "-152.162002563, 60.588901519800004",
  },
  {
    ident: "3IS8",
    type: "small_airport",
    name: "Rinkenberger Restricted Landing Area",
    elevation_ft: "808",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Bradford",
    gps_code: "3IS8",
    iata_code: "BDF",
    local_code: "3IS8",
    coordinates: "-89.61569976810001, 41.230899810800004",
  },
  {
    ident: "3VS",
    type: "small_airport",
    name: "Roy Otten Memorial Airfield",
    elevation_ft: "1030",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Versailles",
    gps_code: "3VS",
    iata_code: "VRS",
    local_code: "3VS",
    coordinates: "-92.875198364258, 38.427799224854",
  },
  {
    ident: "3Z8",
    type: "seaplane_base",
    name: "Golden Horn Lodge Seaplane Base",
    elevation_ft: "91",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Golden Horn Lodge",
    gps_code: "3Z8",
    iata_code: "GDH",
    local_code: "3Z8",
    coordinates: "-158.875, 59.7470016479",
  },
  {
    ident: "4A2",
    type: "small_airport",
    name: "Atmautluak Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Atmautluak",
    iata_code: "ATT",
    local_code: "4A2",
    coordinates: "-162.272995, 60.866699",
  },
  {
    ident: "4AK",
    type: "small_airport",
    name: "Livengood Camp Airport",
    elevation_ft: "425",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Livengood",
    gps_code: "4AK",
    iata_code: "LIV",
    local_code: "4AK",
    coordinates: "-148.6534, 65.467",
  },
  {
    ident: "4K0",
    type: "small_airport",
    name: "Pedro Bay Airport",
    elevation_ft: "45",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Pedro Bay",
    gps_code: "4K0",
    iata_code: "PDB",
    local_code: "4K0",
    coordinates: "-154.12399292, 59.7896003723",
  },
  {
    ident: "4K5",
    type: "small_airport",
    name: "Ouzinkie Airport",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ouzinkie",
    iata_code: "KOZ",
    local_code: "4K5",
    coordinates: "-152.496715, 57.925362",
  },
  {
    ident: "4KA",
    type: "small_airport",
    name: "Tununak Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tununak",
    gps_code: "4KA",
    iata_code: "TNK",
    local_code: "4KA",
    coordinates: "-165.27200317383, 60.575500488281",
  },
  {
    ident: "4TE8",
    type: "closed",
    name: "Ben Bruce Memorial Airpark",
    elevation_ft: "44",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Evadale",
    gps_code: "4TE8",
    iata_code: "EVA",
    local_code: "4TE8",
    coordinates: "-94.07350158690001, 30.3209991455",
  },
  {
    ident: "4Z7",
    type: "seaplane_base",
    name: "Hyder Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Hyder",
    iata_code: "WHD",
    local_code: "4Z7",
    coordinates: "-130.009975, 55.903324",
  },
  {
    ident: "51Z",
    type: "small_airport",
    name: "Minto Al Wright Airport",
    elevation_ft: "460",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Minto",
    iata_code: "MNT",
    local_code: "51Z",
    coordinates: "-149.369995, 65.1437",
  },
  {
    ident: "57A",
    type: "seaplane_base",
    name: "Tokeen Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tokeen",
    gps_code: "57A",
    iata_code: "TKI",
    local_code: "57A",
    coordinates: "-133.32699585, 55.9370994568",
  },
  {
    ident: "5A8",
    type: "medium_airport",
    name: "Aleknagik / New Airport",
    elevation_ft: "66",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Aleknagik",
    gps_code: "5A8",
    iata_code: "WKK",
    local_code: "5A8",
    coordinates: "-158.617996216, 59.2826004028",
  },
  {
    ident: "5KE",
    type: "seaplane_base",
    name: "Ketchikan Harbor Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ketchikan",
    iata_code: "WFB",
    local_code: "5KE",
    coordinates: "-131.677002, 55.349899",
  },
  {
    ident: "5NK",
    type: "small_airport",
    name: "Naknek Airport",
    elevation_ft: "70",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Naknek",
    gps_code: "5NK",
    iata_code: "NNK",
    local_code: "5NK",
    coordinates: "-157.02000427246, 58.732898712158",
  },
  {
    ident: "5Z9",
    type: "seaplane_base",
    name: "Lake Brooks Seaplane Base",
    elevation_ft: "36",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Katmai National Park",
    gps_code: "5Z9",
    iata_code: "BKF",
    local_code: "5Z9",
    coordinates: "-155.77699279785, 58.554798126221",
  },
  {
    ident: "65LA",
    type: "small_airport",
    name: "Southern Seaplane Airport",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Belle Chasse",
    gps_code: "65LA",
    iata_code: "BCS",
    local_code: "65LA",
    coordinates: "-90.0222015381, 29.8661003113",
  },
  {
    ident: "6CA3",
    type: "heliport",
    name: "Catalina Air-Sea Terminal Heliport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Pedro",
    gps_code: "6CA3",
    iata_code: "SPQ",
    local_code: "6CA3",
    coordinates: "-118.275001526, 33.7491989136",
  },
  {
    ident: "6N5",
    type: "heliport",
    name: "East 34th Street Heliport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "New York",
    gps_code: "6N5",
    iata_code: "TSS",
    local_code: "6N5",
    coordinates: "-73.97209930419922, 40.74259948730469",
  },
  {
    ident: "6N7",
    type: "seaplane_base",
    name: "New York Skyports Inc Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "New York",
    iata_code: "QNY",
    local_code: "6N7",
    coordinates: "-73.9729, 40.734001",
  },
  {
    ident: "6OK6",
    type: "small_airport",
    name: "Earl Henry Airport",
    elevation_ft: "1054",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Blackwell",
    gps_code: "6OK6",
    iata_code: "BWL",
    local_code: "6OK6",
    coordinates: "-97.3170013428, 36.7958984375",
  },
  {
    ident: "78K",
    type: "seaplane_base",
    name: "Yes Bay Lodge Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Yes Bay",
    gps_code: "78K",
    iata_code: "WYB",
    local_code: "78K",
    coordinates: "-131.800994873, 55.9163017273",
  },
  {
    ident: "78WA",
    type: "small_airport",
    name: "Center Island Airport",
    elevation_ft: "115",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Center Island",
    gps_code: "78WA",
    iata_code: "CWS",
    local_code: "78WA",
    coordinates: "-122.832000732, 48.4901008606",
  },
  {
    ident: "7KA",
    type: "small_airport",
    name: "Tatitlek Airport",
    elevation_ft: "62",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tatitlek",
    gps_code: "PAKA",
    iata_code: "TEK",
    local_code: "7KA",
    coordinates: "-146.690297, 60.871449",
  },
  {
    ident: "7NC2",
    type: "small_airport",
    name: "Pine Island Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Corolla",
    gps_code: "7NC2",
    iata_code: "DUF",
    local_code: "7NC2",
    coordinates: "-75.7884979248, 36.2535018921",
  },
  {
    ident: "7WA5",
    type: "small_airport",
    name: "Stuart Island Airpark",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Stuart Island",
    gps_code: "7WA5",
    iata_code: "SSW",
    local_code: "7WA5",
    coordinates: "-123.17600250244, 48.672901153564",
  },
  {
    ident: "82CL",
    type: "small_airport",
    name: "Fort Bragg Airport",
    elevation_ft: "71",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Fort Bragg",
    gps_code: "82CL",
    iata_code: "FOB",
    local_code: "82CL",
    coordinates: "-123.79599762, 39.4743003845",
  },
  {
    ident: "83Q",
    type: "seaplane_base",
    name: "Port of Poulsbo Marina Moorage Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Poulsbo",
    gps_code: "83Q",
    iata_code: "PUL",
    local_code: "83Q",
    coordinates: "-122.64700317383, 47.734001159668",
  },
  {
    ident: "84CL",
    type: "heliport",
    name: "Century City Heliport",
    elevation_ft: "473",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Los Angeles",
    gps_code: "84CL",
    iata_code: "CCD",
    local_code: "84CL",
    coordinates: "-118.417999268, 34.060798645",
  },
  {
    ident: "84K",
    type: "seaplane_base",
    name: "Meyers Chuck Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Meyers Chuck",
    gps_code: "84K",
    iata_code: "WMK",
    local_code: "84K",
    coordinates: "-132.255004883, 55.7396011353",
  },
  {
    ident: "89NY",
    type: "small_airport",
    name: "Maxson Airfield",
    elevation_ft: "340",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Alexandria Bay",
    gps_code: "89NY",
    iata_code: "AXB",
    local_code: "89NY",
    coordinates: "-75.90034, 44.312002",
  },
  {
    ident: "8XS8",
    type: "small_airport",
    name: "Reese Airpark",
    elevation_ft: "3338",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Lubbock",
    gps_code: "8XS8",
    iata_code: "REE",
    local_code: "8XS8",
    coordinates: "-102.03700256348, 33.590301513672",
  },
  {
    ident: "90WA",
    type: "small_airport",
    name: "Waldron Airstrip",
    elevation_ft: "140",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "East Sound",
    gps_code: "90WA",
    iata_code: "WDN",
    local_code: "90WA",
    coordinates: "-123.017998, 48.7118",
  },
  {
    ident: "96Z",
    type: "seaplane_base",
    name: "Whale Pass Seaplane Float Harbor Facility",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Whale Pass",
    iata_code: "WWP",
    local_code: "96Z",
    coordinates: "-133.121994, 56.116299",
  },
  {
    ident: "9A3",
    type: "small_airport",
    name: "Chuathbaluk Airport",
    elevation_ft: "244",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chuathbaluk",
    gps_code: "PACH",
    iata_code: "CHU",
    local_code: "9A3",
    coordinates: "-159.216003, 61.579102",
  },
  {
    ident: "9A8",
    type: "small_airport",
    name: "Ugashik Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ugashik",
    gps_code: "9A8",
    iata_code: "UGS",
    local_code: "9A8",
    coordinates: "-157.399002075, 57.5279006958",
  },
  {
    ident: "9B1",
    type: "small_airport",
    name: "Marlboro Airport",
    elevation_ft: "285",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Marlboro",
    iata_code: "MXG",
    local_code: "9B1",
    coordinates: "-71.509002, 42.343201",
  },
  {
    ident: "9N2",
    type: "seaplane_base",
    name: "Philadelphia Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Essington",
    gps_code: "9N2",
    iata_code: "PSQ",
    local_code: "9N2",
    coordinates: "-75.299598693848, 39.859001159668",
  },
  {
    ident: "9Z8",
    type: "small_airport",
    name: "Levelock Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Levelock",
    gps_code: "9Z8",
    iata_code: "KLL",
    local_code: "9Z8",
    coordinates: "-156.85899353, 59.128101348899996",
  },
  {
    ident: "A23",
    type: "seaplane_base",
    name: "Saginaw Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Saginaw Bay",
    gps_code: "A23",
    iata_code: "SGW",
    local_code: "A23",
    coordinates: "-134.158004761, 56.8862991333",
  },
  {
    ident: "A61",
    type: "small_airport",
    name: "Tuntutuliak Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tuntutuliak",
    gps_code: "A61",
    iata_code: "WTL",
    local_code: "A61",
    coordinates: "-162.667007446, 60.3353004456",
  },
  {
    ident: "A63",
    type: "small_airport",
    name: "Twin Hills Airport",
    elevation_ft: "82",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Twin Hills",
    gps_code: "A63",
    iata_code: "TWA",
    local_code: "A63",
    coordinates: "-160.27299499512, 59.07559967041",
  },
  {
    ident: "A79",
    type: "small_airport",
    name: "Chignik Lake Airport",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chignik Lake",
    gps_code: "A79",
    iata_code: "KCQ",
    local_code: "A79",
    coordinates: "-158.774993896, 56.2550010681",
  },
  {
    ident: "AAD",
    type: "small_airport",
    name: "Adado Airport",
    elevation_ft: "1001",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-GA",
    municipality: "Adado",
    iata_code: "AAD",
    coordinates: "46.6375, 6.095802",
  },
  {
    ident: "ABP",
    type: "small_airport",
    name: "Atkamba Airport",
    elevation_ft: "150",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Atkamba Mission",
    iata_code: "ABP",
    local_code: "AKA",
    coordinates: "141.095277778, -6.0655555555600005",
  },
  {
    ident: "ABW",
    type: "closed",
    name: "Abau Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Abau",
    iata_code: "ABW",
    coordinates: "148.7389, -10.1956",
  },
  {
    ident: "AD-ALV",
    type: "heliport",
    name: "Andorra la Vella Heliport",
    elevation_ft: "3450",
    continent: "EU",
    iso_country: "AD",
    iso_region: "AD-07",
    municipality: "Andorra La Vella",
    iata_code: "ALV",
    coordinates: "1.533551, 42.511174",
  },
  {
    ident: "ADC",
    type: "small_airport",
    name: "Andakombe Airport",
    elevation_ft: "3600",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EHG",
    municipality: "Andekombe",
    gps_code: "AYAN",
    iata_code: "ADC",
    local_code: "ADK",
    coordinates: "145.744722222, -7.13722222222",
  },
  {
    ident: "ADV",
    type: "small_airport",
    name: "El Daein Airport",
    elevation_ft: "1560",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-DE",
    municipality: "El Daein",
    iata_code: "ADV",
    coordinates: "26.1186, 11.4023",
  },
  {
    ident: "AE-0006",
    type: "seaplane_base",
    name: "Jebel Ali Seaplane Base",
    elevation_ft: "0",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-DU",
    municipality: "Jebel Ali",
    iata_code: "DJH",
    coordinates: "55.023796, 24.988967",
  },
  {
    ident: "AEE",
    type: "small_airport",
    name: "Adareil Airport",
    elevation_ft: "1301",
    continent: "AF",
    iso_country: "SS",
    iso_region: "SS-23",
    iata_code: "AEE",
    coordinates: "32.9594444444, 10.0536111111",
  },
  {
    ident: "AEI",
    type: "heliport",
    name: "Algeciras Heliport",
    elevation_ft: "98",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CA",
    municipality: "Algeciras",
    gps_code: "LEAG",
    iata_code: "AEI",
    coordinates: "-5.441118, 36.12888",
  },
  {
    ident: "AEK",
    type: "small_airport",
    name: "Aseki Airport",
    elevation_ft: "4106",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Aseki",
    gps_code: "AYAX",
    iata_code: "AEK",
    local_code: "ASE",
    coordinates: "146.19386673, -7.35080485552",
  },
  {
    ident: "AEQ",
    type: "small_airport",
    name: "Ar Horqin Airport",
    elevation_ft: "1335",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Chifeng",
    iata_code: "AEQ",
    coordinates: "120.15958, 43.87042",
  },
  {
    ident: "AF10",
    type: "small_airport",
    name: "Or����zg����n Airport",
    elevation_ft: "6725",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-ORU",
    municipality: "Or����zg����n",
    gps_code: "OARG",
    iata_code: "URZ",
    coordinates: "66.630897522, 32.9029998779",
  },
  {
    ident: "AF11",
    type: "small_airport",
    name: "Salerno Airport",
    elevation_ft: "3780",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-KHO",
    municipality: "Khost",
    gps_code: "OASL",
    iata_code: "OLR",
    coordinates: "69.9561, 33.3638",
  },
  {
    ident: "AFK",
    type: "seaplane_base",
    name: "Kondavattavana Tank Seaplane Base",
    elevation_ft: "95",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-5",
    municipality: "Ampara",
    iata_code: "AFK",
    coordinates: "81.644, 7.284",
  },
  {
    ident: "AFR",
    type: "small_airport",
    name: "Afore Airstrip",
    elevation_ft: "2500",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    gps_code: "AYAF",
    iata_code: "AFR",
    local_code: "AFE",
    coordinates: "148.390833333, -9.142222222220001",
  },
  {
    ident: "AGAF",
    type: "small_airport",
    name: "Afutara Aerodrome",
    elevation_ft: "23",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-ML",
    municipality: "Bila",
    gps_code: "AGAF",
    iata_code: "AFT",
    coordinates: "160.948611111, -9.19138888889",
  },
  {
    ident: "AGAR",
    type: "small_airport",
    name: "Ulawa Airport",
    elevation_ft: "40",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-MK",
    municipality: "Arona",
    gps_code: "AGAR",
    iata_code: "RNA",
    coordinates: "161.979546547, -9.86054358262",
  },
  {
    ident: "AGAT",
    type: "small_airport",
    name: "Uru Harbour Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-ML",
    municipality: "Atoifi",
    gps_code: "AGAT",
    iata_code: "ATD",
    coordinates: "161.011002, -8.87333",
  },
  {
    ident: "AGBA",
    type: "small_airport",
    name: "Barakoma Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Barakoma",
    gps_code: "AGBA",
    iata_code: "VEV",
    coordinates: "156.70599365234375, -7.912779808044434",
  },
  {
    ident: "AGEV",
    type: "small_airport",
    name: "Geva Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Liangia",
    gps_code: "AGEV",
    iata_code: "GEF",
    coordinates: "156.5970001220703, -7.575829982757568",
  },
  {
    ident: "AGG",
    type: "small_airport",
    name: "Angoram Airport",
    elevation_ft: "75",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Angoram",
    iata_code: "AGG",
    local_code: "ANG",
    coordinates: "144.073888889, -4.05583333333",
  },
  {
    ident: "AGGA",
    type: "small_airport",
    name: "Gwaunaru'u Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-ML",
    municipality: "Auki",
    gps_code: "AGGA",
    iata_code: "AKS",
    coordinates: "160.682007, -8.70257",
  },
  {
    ident: "AGGB",
    type: "small_airport",
    name: "Bellona/Anua Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-RB",
    municipality: "Anua",
    gps_code: "AGGB",
    iata_code: "BNY",
    coordinates: "159.798333333, -11.302222222200001",
  },
  {
    ident: "AGGC",
    type: "small_airport",
    name: "Choiseul Bay Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-CH",
    gps_code: "AGGC",
    iata_code: "CHY",
    coordinates: "156.396111, -6.711944",
  },
  {
    ident: "AGGE",
    type: "small_airport",
    name: "Ballalae Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Ballalae",
    gps_code: "AGGE",
    iata_code: "BAS",
    coordinates: "155.886656, -6.990745",
  },
  {
    ident: "AGGF",
    type: "small_airport",
    name: "Fera/Maringe Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-IS",
    municipality: "Fera Island",
    gps_code: "AGGF",
    iata_code: "FRE",
    coordinates: "159.576996, -8.1075",
  },
  {
    ident: "AGGH",
    type: "medium_airport",
    name: "Honiara International Airport",
    elevation_ft: "28",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-CT",
    municipality: "Honiara",
    gps_code: "AGGH",
    iata_code: "HIR",
    coordinates: "160.05499267578, -9.4280004501343",
  },
  {
    ident: "AGGI",
    type: "small_airport",
    name: "Babanakira Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-GU",
    municipality: "Mbambanakira",
    gps_code: "AGGI",
    iata_code: "MBU",
    coordinates: "159.83900451660156, -9.7475004196167",
  },
  {
    ident: "AGGJ",
    type: "small_airport",
    name: "Avu Avu Airport",
    elevation_ft: "25",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-GU",
    gps_code: "AGGJ",
    iata_code: "AVU",
    coordinates: "160.410555556, -9.86833333333",
  },
  {
    ident: "AGGK",
    type: "small_airport",
    name: "Ngorangora Airport",
    elevation_ft: "54",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-MK",
    municipality: "Kirakira",
    gps_code: "AGGK",
    iata_code: "IRA",
    coordinates: "161.897994995, -10.449700355500001",
  },
  {
    ident: "AGGL",
    type: "small_airport",
    name: "Santa Cruz/Graciosa Bay/Luova Airport",
    elevation_ft: "18",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-TE",
    municipality: "Santa Cruz/Graciosa Bay/Luova",
    gps_code: "AGGL",
    iata_code: "SCZ",
    coordinates: "165.7949981689453, -10.72029972076416",
  },
  {
    ident: "AGGM",
    type: "medium_airport",
    name: "Munda Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    gps_code: "AGGM",
    iata_code: "MUA",
    coordinates: "157.26300048828125, -8.327969551086426",
  },
  {
    ident: "AGGN",
    type: "small_airport",
    name: "Nusatupe Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Gizo",
    gps_code: "AGGN",
    iata_code: "GZO",
    coordinates: "156.863998413, -8.09778022766",
  },
  {
    ident: "AGGO",
    type: "small_airport",
    name: "Mono Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Stirling Island",
    gps_code: "AGGO",
    iata_code: "MNY",
    coordinates: "155.56500244140625, -7.416940212249756",
  },
  {
    ident: "AGGP",
    type: "small_airport",
    name: "Parasi Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-ML",
    municipality: "Parasi",
    gps_code: "AGGP",
    iata_code: "PRS",
    coordinates: "161.4250030517578, -9.641670227050781",
  },
  {
    ident: "AGGQ",
    type: "closed",
    name: "Ontong Java Atoll Airstrip",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-ML",
    municipality: "Ontong Java Atoll",
    gps_code: "AGGQ",
    iata_code: "OTV",
    coordinates: "159.527778, -5.515",
  },
  {
    ident: "AGGR",
    type: "small_airport",
    name: "Rennell/Tingoa Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-RB",
    municipality: "Rennell Island",
    gps_code: "AGGR",
    iata_code: "RNL",
    coordinates: "160.06300354003906, -11.533900260925293",
  },
  {
    ident: "AGGS",
    type: "small_airport",
    name: "Sege Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Sege",
    gps_code: "AGGS",
    iata_code: "EGM",
    coordinates: "157.87600708007812, -8.578889846801758",
  },
  {
    ident: "AGGT",
    type: "small_airport",
    name: "Santa Ana Airport",
    elevation_ft: "3",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-MK",
    municipality: "Santa Ana Island",
    gps_code: "AGGT",
    iata_code: "NNB",
    coordinates: "162.454108, -10.847994",
  },
  {
    ident: "AGGU",
    type: "small_airport",
    name: "Marau Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-GU",
    municipality: "Marau",
    gps_code: "AGGU",
    iata_code: "RUS",
    coordinates: "160.824996948, -9.861669540409999",
  },
  {
    ident: "AGGV",
    type: "small_airport",
    name: "Suavanao Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-IS",
    municipality: "Suavanao",
    gps_code: "AGGV",
    iata_code: "VAO",
    coordinates: "158.7310028076172, -7.585559844970703",
  },
  {
    ident: "AGGY",
    type: "small_airport",
    name: "Yandina Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-CE",
    municipality: "Yandina",
    gps_code: "AGGY",
    iata_code: "XYA",
    coordinates: "159.21841, -9.092816",
  },
  {
    ident: "AGK",
    type: "small_airport",
    name: "Kagua Airport",
    elevation_ft: "5350",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SHM",
    municipality: "Kagua",
    iata_code: "AGK",
    coordinates: "143.853611111, -6.396388888890001",
  },
  {
    ident: "AGKG",
    type: "small_airport",
    name: "Kaghau Airport",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-CH",
    municipality: "Kagau Island",
    gps_code: "AGKG",
    iata_code: "KGE",
    coordinates: "157.585, -7.3305",
  },
  {
    ident: "AGKU",
    type: "small_airport",
    name: "Kukudu Airport",
    elevation_ft: "32",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Kolombangara Island",
    gps_code: "AGKU",
    iata_code: "KUE",
    coordinates: "156.94783, -8.0262",
  },
  {
    ident: "AGKW",
    type: "small_airport",
    name: "Kwailabesi Airport",
    elevation_ft: "52",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-ML",
    municipality: "Kwailabesi",
    gps_code: "AGKW",
    iata_code: "KWS",
    coordinates: "160.775127, -8.360508",
  },
  {
    ident: "AGL",
    type: "small_airport",
    name: "Wanigela Airport",
    elevation_ft: "53",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    gps_code: "AYWG",
    iata_code: "AGL",
    local_code: "WGL",
    coordinates: "149.155555556, -9.3375",
  },
  {
    ident: "AGNA",
    type: "small_airport",
    name: "Nana Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-MK",
    municipality: "Star Harbor",
    gps_code: "AGNA",
    iata_code: "NAZ",
    coordinates: "162.2049, -10.6758",
  },
  {
    ident: "AGRC",
    type: "small_airport",
    name: "Ringi Cove Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Ringi Cove",
    gps_code: "AGRC",
    iata_code: "RIN",
    coordinates: "157.14300537109375, -8.12639045715332",
  },
  {
    ident: "AGRM",
    type: "small_airport",
    name: "Ramata Airport",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Ramata",
    gps_code: "AGRM",
    iata_code: "RBV",
    coordinates: "157.64300537109375, -8.168060302734375",
  },
  {
    ident: "AGTI",
    type: "heliport",
    name: "Tulaghi Heliport",
    elevation_ft: "3",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-CE",
    municipality: "Tulaghi Island",
    gps_code: "AGTI",
    iata_code: "TLG",
    coordinates: "160.149166667, -9.108",
  },
  {
    ident: "AGY",
    type: "small_airport",
    name: "Argyle Downs Airport",
    elevation_ft: "407",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Argyle Downs",
    iata_code: "AGY",
    coordinates: "128.9245, -16.514",
  },
  {
    ident: "AHJ",
    type: "medium_airport",
    name: "Hongyuan Airport",
    elevation_ft: "11600",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Aba",
    gps_code: "ZUHY",
    iata_code: "AHJ",
    coordinates: "102.35224, 32.53154",
  },
  {
    ident: "AHT",
    type: "closed",
    name: "Amchitka Army Airfield",
    elevation_ft: "215",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Amchitka Island",
    gps_code: "PAHT",
    iata_code: "AHT",
    coordinates: "179.259166667, 51.3777777778",
  },
  {
    ident: "AHY",
    type: "small_airport",
    name: "Ambatolhy Airport",
    elevation_ft: "340",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Ambatolahy",
    iata_code: "AHY",
    coordinates: "45.535, -20.015833333299998",
  },
  {
    ident: "AIE",
    type: "small_airport",
    name: "Aiome Airport",
    elevation_ft: "350",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Aiome",
    gps_code: "AYAO",
    iata_code: "AIE",
    local_code: "AIO",
    coordinates: "144.7307003, -5.145699978",
  },
  {
    ident: "AIH",
    type: "small_airport",
    name: "Aiambak Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Aiambak",
    gps_code: "AYAK",
    iata_code: "AIH",
    local_code: "AMB",
    coordinates: "141.2675, -7.342777777779999",
  },
  {
    ident: "AIP",
    type: "small_airport",
    name: "Ailinglaplap Airok Airport",
    elevation_ft: "16",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-ALL",
    municipality: "Bigatyelang Island",
    iata_code: "AIC",
    coordinates: "168.8257, 7.279422",
  },
  {
    ident: "AK13",
    type: "small_airport",
    name: "Chena Hot Springs Airport",
    elevation_ft: "1195",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chena Hot Springs",
    gps_code: "AK13",
    iata_code: "CEX",
    local_code: "AK13",
    coordinates: "-146.04699707, 65.0518035889",
  },
  {
    ident: "AK26",
    type: "small_airport",
    name: "Solomon State Field",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Solomon",
    gps_code: "AK26",
    iata_code: "SOL",
    local_code: "AK26",
    coordinates: "-164.4457, 64.5605",
  },
  {
    ident: "AK33",
    type: "small_airport",
    name: "Herendeen Bay Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Herendeen Bay",
    gps_code: "AK33",
    iata_code: "HED",
    local_code: "AK33",
    coordinates: "-160.899002075, 55.801399231",
  },
  {
    ident: "AK49",
    type: "small_airport",
    name: "Taylor Airport",
    elevation_ft: "440",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Taylor",
    gps_code: "AK49",
    iata_code: "TWE",
    local_code: "AK49",
    coordinates: "-164.798995972, 65.6792984009",
  },
  {
    ident: "AK56",
    type: "seaplane_base",
    name: "Tikchik Lodge Seaplane Base",
    elevation_ft: "304",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tikchik",
    gps_code: "AK56",
    iata_code: "KTH",
    local_code: "AK56",
    coordinates: "-158.477005005, 59.9631996155",
  },
  {
    ident: "AK62",
    type: "seaplane_base",
    name: "Naukati Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tuxekan Island",
    gps_code: "AK62",
    iata_code: "NKI",
    local_code: "AK62",
    coordinates: "-133.227994, 55.849602",
  },
  {
    ident: "AK71",
    type: "small_airport",
    name: "Lonely Air Station",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Lonely",
    gps_code: "PALN",
    iata_code: "LNI",
    local_code: "AK71",
    coordinates: "-153.2420044, 70.91069794",
  },
  {
    ident: "AK75",
    type: "small_airport",
    name: "Candle 2 Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Candle",
    gps_code: "AK75",
    iata_code: "CDL",
    local_code: "AK75",
    coordinates: "-161.925994873, 65.907699585",
  },
  {
    ident: "AK81",
    type: "seaplane_base",
    name: "Amook Bay Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Amook Bay",
    gps_code: "AK81",
    iata_code: "AOS",
    local_code: "AK81",
    coordinates: "-153.815002441, 57.4715003967",
  },
  {
    ident: "AK96",
    type: "small_airport",
    name: "Bartletts Airport",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Egegik",
    gps_code: "AK96",
    iata_code: "BSZ",
    local_code: "AK96",
    coordinates: "-157.352005005, 58.2162017822",
  },
  {
    ident: "AK97",
    type: "small_airport",
    name: "Boswell Bay Airport",
    elevation_ft: "230",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Boswell Bay",
    gps_code: "AK97",
    iata_code: "BSW",
    local_code: "AK97",
    coordinates: "-146.145996094, 60.4230995178",
  },
  {
    ident: "AKM",
    type: "small_airport",
    name: "Zakuoma Airport",
    elevation_ft: "1370",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-SA",
    municipality: "ZaKouma",
    iata_code: "AKM",
    coordinates: "19.8172222222, 10.8902777778",
  },
  {
    ident: "AL73",
    type: "small_airport",
    name: "Sharpe Field",
    elevation_ft: "253",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Tuskegee",
    gps_code: "AL73",
    iata_code: "TGE",
    local_code: "AL73",
    coordinates: "-85.7755966187, 32.4919013977",
  },
  {
    ident: "ALZ",
    type: "seaplane_base",
    name: "Alitak Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Lazy Bay",
    iata_code: "ALZ",
    local_code: "ALZ",
    coordinates: "-154.248001099, 56.8995018005",
  },
  {
    ident: "AMC",
    type: "small_airport",
    name: "Mar de Cort����s International Airport",
    elevation_ft: "71",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SON",
    municipality: "Puerto Pe����asco",
    gps_code: "MMPE",
    iata_code: "PPE",
    local_code: "83550",
    coordinates: "-113.305177, 31.351987",
  },
  {
    ident: "AME",
    type: "small_airport",
    name: "Alto Molocue Airport",
    elevation_ft: "1950",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-Q",
    municipality: "Alto Molocue",
    iata_code: "AME",
    coordinates: "37.6813888889, -15.6102777778",
  },
  {
    ident: "AMF",
    type: "small_airport",
    name: "Ama Airport",
    elevation_ft: "145",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    gps_code: "AYAA",
    iata_code: "AMF",
    local_code: "AMA",
    coordinates: "141.67, -4.10111111111",
  },
  {
    ident: "AMU",
    type: "small_airport",
    name: "Amanab Airport",
    elevation_ft: "1307",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Amanab",
    gps_code: "AYAM",
    iata_code: "AMU",
    local_code: "ANA",
    coordinates: "141.214333333, -3.586",
  },
  {
    ident: "AMY",
    type: "small_airport",
    name: "Ambatomainty Airport",
    elevation_ft: "1025",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    iata_code: "AMY",
    coordinates: "45.623888888900005, -17.6866666667",
  },
  {
    ident: "ANH",
    type: "closed",
    name: "Anuha Island Resort Airport",
    elevation_ft: "22",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-CE",
    municipality: "Anhua Island",
    iata_code: "ANH",
    coordinates: "160.225, -9.00138888889",
  },
  {
    ident: "ANYN",
    type: "medium_airport",
    name: "Nauru International Airport",
    elevation_ft: "22",
    continent: "OC",
    iso_country: "NR",
    iso_region: "NR-14",
    municipality: "Yaren District",
    gps_code: "ANYN",
    iata_code: "INU",
    coordinates: "166.919006, -0.547458",
  },
  {
    ident: "ANZ",
    type: "small_airport",
    name: "Angus Downs Airport",
    elevation_ft: "1724",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Angus Downs Station",
    iata_code: "ANZ",
    coordinates: "132.2748, -25.0325",
  },
  {
    ident: "AO-0005",
    type: "small_airport",
    name: "Andulo Airport",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-BIE",
    iata_code: "ANL",
    coordinates: "16.710899353, -11.472299575800001",
  },
  {
    ident: "AO-CNZ",
    type: "small_airport",
    name: "Cangamba Airport",
    elevation_ft: "3894",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-MOX",
    municipality: "Cangamba",
    iata_code: "CNZ",
    coordinates: "19.861099, -13.7106",
  },
  {
    ident: "AO-DRC",
    type: "small_airport",
    name: "Dirico Airport",
    elevation_ft: "3504",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CCU",
    municipality: "Dirico",
    iata_code: "DRC",
    coordinates: "20.7680606842041, -17.981924057006836",
  },
  {
    ident: "AO-GGC",
    type: "small_airport",
    name: "Lumbala Airport",
    elevation_ft: "3510",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-MOX",
    municipality: "Lumbala",
    iata_code: "GGC",
    coordinates: "22.5868206024, -12.6398773193",
  },
  {
    ident: "AO-JMB",
    type: "small_airport",
    name: "Jamba Airport",
    elevation_ft: "4912",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-HUA",
    municipality: "Jamba",
    iata_code: "JMB",
    coordinates: "16.070148468017578, -14.698192596435547",
  },
  {
    ident: "AO-KNP",
    type: "small_airport",
    name: "Capanda Airport",
    elevation_ft: "3366",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-MAL",
    municipality: "Capanda",
    gps_code: "FNCP",
    iata_code: "KNP",
    coordinates: "15.4553194046, -9.76937007904",
  },
  {
    ident: "AO-NDF",
    type: "small_airport",
    name: "Ndalatandos Airport",
    elevation_ft: "2684",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CNO",
    municipality: "Ndalatandos",
    iata_code: "NDF",
    coordinates: "14.977242469787598, -9.275188446044922",
  },
  {
    ident: "AOA",
    type: "closed",
    name: "Aroa Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Agevairu",
    iata_code: "AOA",
    coordinates: "146.8, -9.0254",
  },
  {
    ident: "AOB",
    type: "small_airport",
    name: "Annanberg Airport",
    elevation_ft: "130",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    iata_code: "AOB",
    local_code: "ANB",
    coordinates: "144.635833333, -4.90416666667",
  },
  {
    ident: "AOD",
    type: "small_airport",
    name: "Abou-De����a Airport",
    elevation_ft: "1592",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-SA",
    municipality: "Abou-De����a",
    iata_code: "AOD",
    coordinates: "19.2874, 11.4773",
  },
  {
    ident: "APP",
    type: "small_airport",
    name: "Asapa Airport",
    elevation_ft: "1930",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    iata_code: "APP",
    local_code: "APA",
    coordinates: "148.103611111, -8.97916666667",
  },
  {
    ident: "APR",
    type: "small_airport",
    name: "April River Airport",
    elevation_ft: "220",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "April River",
    gps_code: "AYPE",
    iata_code: "APR",
    local_code: "APR",
    coordinates: "142.540138889, -4.67666666667",
  },
  {
    ident: "AQ-0002",
    type: "small_airport",
    name: "Troll Airfield",
    elevation_ft: "4167",
    continent: "AN",
    iso_country: "AQ",
    iso_region: "AQ-U-A",
    municipality: "Troll Station",
    gps_code: "AT27",
    iata_code: "QET",
    coordinates: "2.455581, -71.95559",
  },
  {
    ident: "AQY",
    type: "small_airport",
    name: "Girdwood Airport",
    elevation_ft: "150",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Girdwood",
    iata_code: "AQY",
    local_code: "AQY",
    coordinates: "-149.126007, 60.966099",
  },
  {
    ident: "AR-0005",
    type: "small_airport",
    name: "Bragado Airport",
    elevation_ft: "196",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Bragado",
    iata_code: "QRF",
    local_code: "BRA",
    coordinates: "-60.4803, -35.144",
  },
  {
    ident: "AR-0007",
    type: "small_airport",
    name: "Caleta Olivia Airport",
    elevation_ft: "37",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "Caleta Olivia",
    iata_code: "CVI",
    local_code: "CAO",
    coordinates: "-67.5186004639, -46.4318008423",
  },
  {
    ident: "AR-0009",
    type: "small_airport",
    name: "Charata Airport",
    elevation_ft: "318",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-H",
    municipality: "Charata",
    iata_code: "CNT",
    local_code: "CHA",
    coordinates: "-61.2103, -27.2164",
  },
  {
    ident: "AR-0010",
    type: "small_airport",
    name: "General Villegas Airport",
    elevation_ft: "383",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "General Villegas",
    iata_code: "VGS",
    local_code: "GAS",
    coordinates: "-63, -35",
  },
  {
    ident: "AR-0011",
    type: "small_airport",
    name: "Los Menucos Airport",
    elevation_ft: "2571",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "Los Menucos",
    iata_code: "LMD",
    local_code: "MCO",
    coordinates: "-68.074699, -40.817699",
  },
  {
    ident: "AR-0015",
    type: "closed",
    name: "Saenz Pe����a Airport",
    elevation_ft: "20",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-H",
    municipality: "Presidencia Roque S����enz Pe����a",
    iata_code: "SZQ",
    coordinates: "-60.448299407958984, -26.815799713134766",
  },
  {
    ident: "AR-0017",
    type: "small_airport",
    name: "Valcheta Airport",
    elevation_ft: "629",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "Valcheta",
    iata_code: "VCF",
    local_code: "VAL",
    coordinates: "-66.1500015259, -40.7000007629",
  },
  {
    ident: "AR-0346",
    type: "small_airport",
    name: "Sunchales Aeroclub Airport",
    elevation_ft: "299",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-S",
    municipality: "Sunchales",
    gps_code: "SAFS",
    iata_code: "NCJ",
    local_code: "SCA",
    coordinates: "-61.5283, -30.9575",
  },
  {
    ident: "AR-0390",
    type: "small_airport",
    name: "Carmen De Patagones Airport",
    elevation_ft: "131",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Carmen de Patagones",
    iata_code: "CPG",
    local_code: "PAT",
    coordinates: "-62.9803, -40.7781",
  },
  {
    ident: "AR-0462",
    type: "small_airport",
    name: "Termal Airport",
    elevation_ft: "308",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-H",
    municipality: "Presidencia Roque S����enz Pe����a",
    gps_code: "SARS",
    iata_code: "PRQ",
    local_code: "PSP",
    coordinates: "-60.492222, -26.753611",
  },
  {
    ident: "AR-0494",
    type: "small_airport",
    name: "Colonia Sarmiento Airport",
    elevation_ft: "877",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-U",
    municipality: "Sarmiento",
    iata_code: "OLN",
    local_code: "STO",
    coordinates: "-68.999841, -45.582371",
  },
  {
    ident: "ARP",
    type: "small_airport",
    name: "Aragip Airport",
    elevation_ft: "1750",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    iata_code: "ARP",
    local_code: "AGP",
    coordinates: "149.483333333, -9.88333333333",
  },
  {
    ident: "ARX",
    type: "closed",
    name: "Asbury Park Neptune Air Terminal",
    elevation_ft: "95",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Asbury Park",
    iata_code: "ARX",
    coordinates: "-74.0908333333, 40.2193055556",
  },
  {
    ident: "AS-TAV",
    type: "small_airport",
    name: "Tau Airport",
    elevation_ft: "185",
    continent: "OC",
    iso_country: "AS",
    iso_region: "AS-U-A",
    municipality: "Tau Village",
    iata_code: "TAV",
    local_code: "HI36",
    coordinates: "-169.511001587, -14.2292003632",
  },
  {
    ident: "ASZ",
    type: "small_airport",
    name: "Asirim Airport",
    elevation_ft: "1050",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    iata_code: "ASZ",
    local_code: "ASM",
    coordinates: "150.368611111, -6.009722222220001",
  },
  {
    ident: "ATN",
    type: "small_airport",
    name: "Namatanai Airport",
    elevation_ft: "150",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Namatanai",
    gps_code: "AYNX",
    iata_code: "ATN",
    local_code: "NTI",
    coordinates: "152.438, -3.6695",
  },
  {
    ident: "ATP",
    type: "small_airport",
    name: "Aitape Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Aitape",
    gps_code: "AYAI",
    iata_code: "ATP",
    local_code: "APE",
    coordinates: "142.346805556, -3.1436111111100002",
  },
  {
    ident: "AU-0081",
    type: "small_airport",
    name: "Lady Elliot Island Airstrip",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Lady Elliot Island",
    iata_code: "LYT",
    coordinates: "152.7156, -24.1129",
  },
  {
    ident: "AU-AGW",
    type: "small_airport",
    name: "Agnew Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Agnew",
    iata_code: "AGW",
    coordinates: "142.1490020751953, -12.145600318908691",
  },
  {
    ident: "AU-AYD",
    type: "small_airport",
    name: "Alroy Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Alroy Downs",
    iata_code: "AYD",
    coordinates: "136.07899475097656, -19.290800094604492",
  },
  {
    ident: "AU-BYX",
    type: "small_airport",
    name: "Baniyala Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Baniyala",
    iata_code: "BYX",
    coordinates: "136.227005005, -13.19810009",
  },
  {
    ident: "AU-COB",
    type: "small_airport",
    name: "Coolibah Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Coolibah",
    iata_code: "COB",
    coordinates: "130.96200561523438, -15.548299789428711",
  },
  {
    ident: "AU-CRJ",
    type: "small_airport",
    name: "Coorabie Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Coorabie",
    iata_code: "CRJ",
    coordinates: "132.29600524902344, -31.894399642944336",
  },
  {
    ident: "AU-CRY",
    type: "small_airport",
    name: "Carlton Hill Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Carlton Hill",
    iata_code: "CRY",
    coordinates: "128.53399658203125, -15.501899719238281",
  },
  {
    ident: "AU-CSD",
    type: "small_airport",
    name: "Cresswell Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Cresswell Downs",
    iata_code: "CSD",
    coordinates: "135.91600036621094, -17.947999954223633",
  },
  {
    ident: "AU-DYM",
    type: "small_airport",
    name: "Diamantina Lakes Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Diamantina Lakes",
    iata_code: "DYM",
    coordinates: "141.14500427246094, -23.761699676513672",
  },
  {
    ident: "AU-HIS",
    type: "seaplane_base",
    name: "Hayman Island Resort Seaplane Base",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Hayman Island",
    iata_code: "HIS",
    coordinates: "148.88099670410156, -20.059999465942383",
  },
  {
    ident: "AU-HLV",
    type: "small_airport",
    name: "Helenvale Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Helenvale",
    iata_code: "HLV",
    coordinates: "145.21499633789062, -15.685799598693848",
  },
  {
    ident: "AU-KBD",
    type: "small_airport",
    name: "Kimberley Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Kimberley Downs",
    iata_code: "KBD",
    coordinates: "124.3550033569336, -17.39780044555664",
  },
  {
    ident: "AU-KGR",
    type: "small_airport",
    name: "Kulgera Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Kulgera",
    iata_code: "KGR",
    coordinates: "133.29200744628906, -25.84280014038086",
  },
  {
    ident: "AU-MWY",
    type: "small_airport",
    name: "Miranda Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Miranda Downs",
    gps_code: "YMIR",
    iata_code: "MWY",
    coordinates: "141.886002, -17.328899",
  },
  {
    ident: "AU-MYO",
    type: "small_airport",
    name: "Camballin Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Myroodah",
    iata_code: "MYO",
    coordinates: "124.27200317382812, -18.12470054626465",
  },
  {
    ident: "AU-OKB",
    type: "small_airport",
    name: "Orchid Beach Airport",
    elevation_ft: "71",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Orchid Beach",
    gps_code: "YORC",
    iata_code: "OKB",
    local_code: "YORC",
    coordinates: "153.31500244141, -24.959400177002",
  },
  {
    ident: "AU-PEP",
    type: "small_airport",
    name: "Peppimenarti Airport",
    elevation_ft: "83",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Peppimenarti",
    iata_code: "PEP",
    local_code: "YPEP",
    coordinates: "130.091003418, -14.144200325",
  },
  {
    ident: "AU-RDA",
    type: "small_airport",
    name: "Rockhampton Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Rockhampton Downs",
    iata_code: "RDA",
    coordinates: "135.2010040283203, -18.95330047607422",
  },
  {
    ident: "AU-SSK",
    type: "small_airport",
    name: "Sturt Creek Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Sturt Creek",
    iata_code: "SSK",
    coordinates: "128.1739959716797, -19.166400909423828",
  },
  {
    ident: "AU-SWB",
    type: "small_airport",
    name: "Shaw River Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Shaw River",
    iata_code: "SWB",
    coordinates: "119.36199951171875, -21.510299682617188",
  },
  {
    ident: "AU-TPR",
    type: "small_airport",
    name: "Tom Price Airport",
    elevation_ft: "2300",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Tom Price",
    gps_code: "YTMP",
    iata_code: "TPR",
    coordinates: "117.869003296, -22.746000289900003",
  },
  {
    ident: "AU-TWP",
    type: "small_airport",
    name: "Torwood Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Torwood",
    iata_code: "TWP",
    coordinates: "143.75, -17.363300323486328",
  },
  {
    ident: "AU-ZVG",
    type: "small_airport",
    name: "Springvale Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Springvale",
    iata_code: "ZVG",
    coordinates: "127.66999816894531, -17.78689956665039",
  },
  {
    ident: "AUI",
    type: "small_airport",
    name: "Aua Island Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MRL",
    municipality: "Aua Island",
    gps_code: "AYND",
    iata_code: "AUI",
    local_code: "AUA",
    coordinates: "143.064722222, -1.46055555556",
  },
  {
    ident: "AUJ",
    type: "small_airport",
    name: "Ambunti Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Ambunti",
    gps_code: "AYAT",
    iata_code: "AUJ",
    local_code: "AMI",
    coordinates: "142.823166667, -4.21566666667",
  },
  {
    ident: "AUP",
    type: "small_airport",
    name: "Agaun Airport",
    elevation_ft: "3200",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    gps_code: "AYAG",
    iata_code: "AUP",
    local_code: "AUN",
    coordinates: "149.385667, -9.930833",
  },
  {
    ident: "AUV",
    type: "small_airport",
    name: "Aumo Airport",
    elevation_ft: "450",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Aumo",
    gps_code: "AYUM",
    iata_code: "AUV",
    local_code: "AUMO",
    coordinates: "148.445277778, -5.731111111110001",
  },
  {
    ident: "AWE",
    type: "small_airport",
    name: "Alowe Airport",
    elevation_ft: "600",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-3",
    municipality: "Wonga Wongu���� Presidential Reserve",
    gps_code: "FOGW",
    iata_code: "AWE",
    coordinates: "9.444, -0.545",
  },
  {
    ident: "AWR",
    type: "closed",
    name: "Awar Airport",
    elevation_ft: "35",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Awar",
    iata_code: "AWR",
    coordinates: "144.853, -4.124",
  },
  {
    ident: "AXF",
    type: "medium_airport",
    name: "Alxa Left Banner Bayanhot Airport",
    elevation_ft: "4560",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Bayanhot",
    gps_code: "ZBAL",
    iata_code: "AXF",
    coordinates: "105.58858, 38.74831",
  },
  {
    ident: "AYAQ",
    type: "small_airport",
    name: "Kompiam Airport",
    elevation_ft: "5100",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EPW",
    gps_code: "AYAQ",
    iata_code: "KPM",
    local_code: "KOM",
    coordinates: "143.924722222, -5.38166666667",
  },
  {
    ident: "AYBK",
    type: "medium_airport",
    name: "Buka Airport",
    elevation_ft: "11",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Buka Island",
    gps_code: "AYBK",
    iata_code: "BUA",
    coordinates: "154.67300415039062, -5.4223198890686035",
  },
  {
    ident: "AYBR",
    type: "small_airport",
    name: "Biaru Airport",
    elevation_ft: "4900",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Biaru",
    gps_code: "AYBR",
    iata_code: "BRP",
    local_code: "BRU",
    coordinates: "146.7594, -7.6693",
  },
  {
    ident: "AYCH",
    type: "medium_airport",
    name: "Chimbu Airport",
    elevation_ft: "4974",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPK",
    municipality: "Kundiawa",
    gps_code: "AYCH",
    iata_code: "CMU",
    coordinates: "144.9709930419922, -6.024290084838867",
  },
  {
    ident: "AYDK",
    type: "small_airport",
    name: "Munduku Airport",
    elevation_ft: "100",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Munduku",
    gps_code: "AYDK",
    iata_code: "MDM",
    local_code: "MUN",
    coordinates: "143.4516, -4.6204",
  },
  {
    ident: "AYDL",
    type: "small_airport",
    name: "Kondobol Airport",
    elevation_ft: "132",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Kondobol",
    gps_code: "AYDL",
    iata_code: "KPF",
    local_code: "KDB",
    coordinates: "142.5049, -8.5336",
  },
  {
    ident: "AYDN",
    type: "small_airport",
    name: "Dinangat Airport",
    elevation_ft: "5309",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Dinangat",
    gps_code: "AYDN",
    iata_code: "DNU",
    local_code: "DIN",
    coordinates: "146.6772, -6.1546",
  },
  {
    ident: "AYDO",
    type: "small_airport",
    name: "Doini Airport",
    elevation_ft: "31",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Castori Islets",
    gps_code: "AYDO",
    iata_code: "DOI",
    local_code: "DOI",
    coordinates: "150.7218, -10.7009",
  },
  {
    ident: "AYDU",
    type: "medium_airport",
    name: "Daru Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Daru",
    gps_code: "AYDU",
    iata_code: "DAU",
    coordinates: "143.207992554, -9.08675956726",
  },
  {
    ident: "AYEB",
    type: "small_airport",
    name: "Embessa Airport",
    elevation_ft: "131",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Embessa",
    gps_code: "AYEB",
    iata_code: "EMS",
    local_code: "EMB",
    coordinates: "148.7628, -9.447",
  },
  {
    ident: "AYED",
    type: "small_airport",
    name: "Edwaki Airport",
    elevation_ft: "190",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Yellow River Mission",
    gps_code: "AYED",
    iata_code: "XYR",
    local_code: "EWK",
    coordinates: "141.792234, -3.883986",
  },
  {
    ident: "AYEL",
    type: "small_airport",
    name: "Eliptamin Airport",
    elevation_ft: "4825",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Eliptamin",
    gps_code: "AYEL",
    iata_code: "EPT",
    local_code: "ELP",
    coordinates: "141.6779, -5.0412",
  },
  {
    ident: "AYEN",
    type: "small_airport",
    name: "Engati Airstrip",
    elevation_ft: "3715",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Engati",
    gps_code: "AYEN",
    iata_code: "EGA",
    local_code: "EGT",
    coordinates: "146.1053, -6.9179",
  },
  {
    ident: "AYEO",
    type: "small_airport",
    name: "Emo River Airstrip",
    elevation_ft: "2240",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Emo Mission",
    gps_code: "AYEO",
    iata_code: "EMO",
    local_code: "EMO",
    coordinates: "148.0415, -9.1234",
  },
  {
    ident: "AYER",
    type: "small_airport",
    name: "Erume Airport",
    elevation_ft: "6690",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Erume",
    gps_code: "AYER",
    iata_code: "ERU",
    local_code: "EME",
    coordinates: "146.9747, -8.2538",
  },
  {
    ident: "AYES",
    type: "small_airport",
    name: "Meselia Airport",
    elevation_ft: "180",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Demgulu",
    gps_code: "AYES",
    iata_code: "MFZ",
    coordinates: "149.1183, -6.1364",
  },
  {
    ident: "AYFE",
    type: "small_airport",
    name: "Feramin Airport",
    elevation_ft: "4655",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Feramin",
    gps_code: "AYFE",
    iata_code: "FRQ",
    local_code: "FMN",
    coordinates: "141.6988, -5.208",
  },
  {
    ident: "AYFR",
    type: "small_airport",
    name: "Frieda River Airport",
    elevation_ft: "217",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Frieda River",
    gps_code: "AYFR",
    iata_code: "FAQ",
    local_code: "FRR",
    coordinates: "141.960138, -4.610468",
  },
  {
    ident: "AYFU",
    type: "small_airport",
    name: "Fuma Airport",
    elevation_ft: "466",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Fuma",
    gps_code: "AYFU",
    iata_code: "FUM",
    local_code: "FUMA",
    coordinates: "142.4408, -6.3933",
  },
  {
    ident: "AYGA",
    type: "medium_airport",
    name: "Goroka Airport",
    elevation_ft: "5282",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EHG",
    municipality: "Goronka",
    gps_code: "AYGA",
    iata_code: "GKA",
    coordinates: "145.391998291, -6.081689834590001",
  },
  {
    ident: "AYGF",
    type: "small_airport",
    name: "Guari Airport",
    elevation_ft: "6450",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Guari",
    gps_code: "AYGF",
    iata_code: "GUG",
    local_code: "GAI",
    coordinates: "146.8722, -8.1286",
  },
  {
    ident: "AYGG",
    type: "small_airport",
    name: "Garasa Airport",
    elevation_ft: "2539",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Au",
    gps_code: "AYGG",
    iata_code: "GRL",
    local_code: "GSA",
    coordinates: "147.213461, -7.980713",
  },
  {
    ident: "AYGN",
    type: "medium_airport",
    name: "Gurney Airport",
    elevation_ft: "88",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Gurney",
    gps_code: "AYGN",
    iata_code: "GUR",
    coordinates: "150.333999634, -10.3114995956",
  },
  {
    ident: "AYGP",
    type: "small_airport",
    name: "Gusap Airport",
    elevation_ft: "1504",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Gusap",
    gps_code: "AYGP",
    iata_code: "GAP",
    local_code: "GSP",
    coordinates: "145.959047, -6.053196",
  },
  {
    ident: "AYGR",
    type: "medium_airport",
    name: "Girua Airport",
    elevation_ft: "311",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Popondetta",
    gps_code: "AYGR",
    iata_code: "PNP",
    coordinates: "148.309005737, -8.80453968048",
  },
  {
    ident: "AYGS",
    type: "small_airport",
    name: "Gasuke Airport",
    elevation_ft: "157",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Gasuke",
    gps_code: "AYGS",
    iata_code: "GBC",
    local_code: "GSK",
    coordinates: "141.7393, -6.1028",
  },
  {
    ident: "AYHB",
    type: "small_airport",
    name: "Habi Airport",
    elevation_ft: "993",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Habi",
    gps_code: "AYHB",
    iata_code: "HBD",
    local_code: "HABI",
    coordinates: "142.4893, -6.32",
  },
  {
    ident: "AYHE",
    type: "small_airport",
    name: "Heiweni Airport",
    elevation_ft: "3480",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Heiweni",
    gps_code: "AYHE",
    iata_code: "HNI",
    local_code: "HWI",
    coordinates: "146.4276, -7.4223",
  },
  {
    ident: "AYHH",
    type: "small_airport",
    name: "Honinabi Airport",
    elevation_ft: "452",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Honinabi",
    gps_code: "AYHH",
    iata_code: "HNN",
    local_code: "HBI",
    coordinates: "142.1771, -16.2457",
  },
  {
    ident: "AYHK",
    type: "medium_airport",
    name: "Kimbe Airport",
    elevation_ft: "66",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Hoskins",
    gps_code: "AYHK",
    iata_code: "HKN",
    coordinates: "150.40499877929688, -5.462170124053955",
  },
  {
    ident: "AYHO",
    type: "small_airport",
    name: "Haivaro Airport",
    elevation_ft: "180",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Haivaro",
    gps_code: "AYHO",
    iata_code: "HIT",
    local_code: "HWO",
    coordinates: "143.059, -6.9406",
  },
  {
    ident: "AYII",
    type: "small_airport",
    name: "Imane Airport",
    elevation_ft: "3860",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Imane",
    gps_code: "AYII",
    iata_code: "IMN",
    local_code: "IMN",
    coordinates: "146.1072, -6.7524",
  },
  {
    ident: "AYIM",
    type: "small_airport",
    name: "Kungim Airport",
    elevation_ft: "349",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Kungim",
    gps_code: "AYIM",
    iata_code: "KGM",
    local_code: "KNM",
    coordinates: "141.03, -5.671",
  },
  {
    ident: "AYIO",
    type: "small_airport",
    name: "Imonda Airport",
    elevation_ft: "990",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Imonda",
    gps_code: "AYIO",
    iata_code: "IMD",
    local_code: "IMO",
    coordinates: "141.1573, -3.328",
  },
  {
    ident: "AYIU",
    type: "small_airport",
    name: "Ialibu Airport",
    elevation_ft: "6736",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SHM",
    municipality: "Ialibu",
    gps_code: "AYIU",
    iata_code: "IAL",
    local_code: "IBU",
    coordinates: "143.9944, -6.2782",
  },
  {
    ident: "AYIX",
    type: "small_airport",
    name: "Witu Airport",
    elevation_ft: "25",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Garove Island",
    gps_code: "AYIX",
    iata_code: "WIU",
    local_code: "WITU",
    coordinates: "149.440112, -4.689522",
  },
  {
    ident: "AYJO",
    type: "small_airport",
    name: "Yongai Airport",
    elevation_ft: "6500",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Yongai",
    gps_code: "AYJO",
    iata_code: "KGH",
    local_code: "YON",
    coordinates: "147.4603, -8.5289",
  },
  {
    ident: "AYKA",
    type: "small_airport",
    name: "Losuia Airport",
    elevation_ft: "27",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Losuia",
    gps_code: "AYKA",
    iata_code: "LSA",
    coordinates: "151.08099365234375, -8.505820274353027",
  },
  {
    ident: "AYKG",
    type: "small_airport",
    name: "Kopiago Airport",
    elevation_ft: "4445",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-HE",
    municipality: "Kopiago",
    gps_code: "AYKG",
    iata_code: "KPA",
    local_code: "KGO",
    coordinates: "142.4977, -5.3883",
  },
  {
    ident: "AYKI",
    type: "small_airport",
    name: "Kiunga Airport",
    elevation_ft: "88",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Kiunga",
    gps_code: "AYKI",
    iata_code: "UNG",
    coordinates: "141.28199768066406, -6.1257100105285645",
  },
  {
    ident: "AYKJ",
    type: "small_airport",
    name: "Kanainj Airport",
    elevation_ft: "4064",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Kanainj",
    gps_code: "AYKJ",
    iata_code: "KNE",
    local_code: "KNJ",
    coordinates: "144.7072, -5.2903",
  },
  {
    ident: "AYKK",
    type: "small_airport",
    name: "Kikori Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Kikori",
    gps_code: "AYKK",
    iata_code: "KRI",
    coordinates: "144.2500762939453, -7.424379825592041",
  },
  {
    ident: "AYKM",
    type: "medium_airport",
    name: "Kerema Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Kerema",
    gps_code: "AYKM",
    iata_code: "KMA",
    coordinates: "145.770996094, -7.96361017227",
  },
  {
    ident: "AYKR",
    type: "small_airport",
    name: "Kar Kar Airport",
    elevation_ft: "130",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Kar Kar Island",
    gps_code: "AYKR",
    iata_code: "KRX",
    local_code: "KKR",
    coordinates: "145.9404, -4.557",
  },
  {
    ident: "AYKT",
    type: "small_airport",
    name: "Aropa Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Kieta",
    gps_code: "AYIQ",
    iata_code: "KIE",
    local_code: "KIA",
    coordinates: "155.728139, -6.305417",
  },
  {
    ident: "AYKU",
    type: "small_airport",
    name: "Kuri Airport",
    elevation_ft: "35",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Kuri",
    gps_code: "AYKU",
    iata_code: "KUQ",
    local_code: "KURI",
    coordinates: "143.276111, -7.135083",
  },
  {
    ident: "AYKV",
    type: "medium_airport",
    name: "Kavieng Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Kavieng",
    gps_code: "AYKV",
    iata_code: "KVG",
    coordinates: "150.807998657, -2.57940006256",
  },
  {
    ident: "AYKY",
    type: "small_airport",
    name: "Londolovit Airport",
    elevation_ft: "167",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Londolovit",
    gps_code: "AYKY",
    iata_code: "LNV",
    coordinates: "152.628997803, -3.04361009598",
  },
  {
    ident: "AYLB",
    type: "small_airport",
    name: "Lab Lab Airport",
    elevation_ft: "160",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Lab Lab Mission",
    gps_code: "AYLB",
    iata_code: "LAB",
    local_code: "LAB",
    coordinates: "148.0566, -5.7204",
  },
  {
    ident: "AYLO",
    type: "small_airport",
    name: "Lowai Airport",
    elevation_ft: "2900",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Lowai",
    gps_code: "AYLO",
    iata_code: "LWI",
    local_code: "LOW",
    coordinates: "146.6458, -6.3344",
  },
  {
    ident: "AYLP",
    type: "small_airport",
    name: "Leron Plains Airport",
    elevation_ft: "680",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Leron Plains",
    gps_code: "AYLP",
    iata_code: "LPN",
    local_code: "LPS",
    coordinates: "146.3434, -6.3917",
  },
  {
    ident: "AYLS",
    type: "small_airport",
    name: "Lese Airport",
    elevation_ft: "40",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Lese",
    gps_code: "AYLS",
    iata_code: "LNG",
    local_code: "LESE",
    coordinates: "146.2765, -8.2799",
  },
  {
    ident: "AYLX",
    type: "small_airport",
    name: "Long Island Airport",
    elevation_ft: "116",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Long Island",
    gps_code: "AYLX",
    iata_code: "LSJ",
    local_code: "LIS",
    coordinates: "147.0177, -5.3604",
  },
  {
    ident: "AYM",
    type: "seaplane_base",
    name: "Yas Island Seaplane Base",
    elevation_ft: "0",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-AZ",
    municipality: "Abu Dhabi",
    iata_code: "AYM",
    coordinates: "54.6103, 24.467",
  },
  {
    ident: "AYMA",
    type: "small_airport",
    name: "Manari Airport",
    elevation_ft: "2630",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Manari",
    gps_code: "AYMA",
    iata_code: "MRM",
    local_code: "MRI",
    coordinates: "147.6219, -9.1908",
  },
  {
    ident: "AYMB",
    type: "small_airport",
    name: "Morobe Airport",
    elevation_ft: "8",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Morobe",
    gps_code: "AYMB",
    iata_code: "OBM",
    local_code: "MBE",
    coordinates: "147.59, -7.74",
  },
  {
    ident: "AYMD",
    type: "medium_airport",
    name: "Madang Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Madang",
    gps_code: "AYMD",
    iata_code: "MAG",
    coordinates: "145.789001465, -5.20707988739",
  },
  {
    ident: "AYMH",
    type: "medium_airport",
    name: "Mount Hagen Kagamuga Airport",
    elevation_ft: "5388",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WHM",
    municipality: "Mount Hagen",
    gps_code: "AYMH",
    iata_code: "HGU",
    coordinates: "144.29600524902344, -5.826789855957031",
  },
  {
    ident: "AYML",
    type: "small_airport",
    name: "Mougulu Airport",
    elevation_ft: "825",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Mougulu",
    gps_code: "AYML",
    iata_code: "GUV",
    local_code: "MGU",
    coordinates: "142.420775, -6.280859",
  },
  {
    ident: "AYMN",
    type: "medium_airport",
    name: "Mendi Airport",
    elevation_ft: "5680",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SHM",
    municipality: "Mendi",
    gps_code: "AYMN",
    iata_code: "MDU",
    coordinates: "143.656998, -6.14774",
  },
  {
    ident: "AYMO",
    type: "medium_airport",
    name: "Momote Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MRL",
    municipality: "Manus Island",
    gps_code: "AYMO",
    iata_code: "MAS",
    coordinates: "147.423996, -2.06189",
  },
  {
    ident: "AYMR",
    type: "small_airport",
    name: "Moro Airport",
    elevation_ft: "2740",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SHM",
    municipality: "Moro",
    gps_code: "AYMR",
    iata_code: "MXH",
    coordinates: "143.238006592, -6.36332988739",
  },
  {
    ident: "AYMS",
    type: "small_airport",
    name: "Misima Island Airport",
    elevation_ft: "26",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Misima Island",
    gps_code: "AYMS",
    iata_code: "MIS",
    coordinates: "152.837997437, -10.689200401299999",
  },
  {
    ident: "AYMW",
    type: "small_airport",
    name: "Marawaka Airport",
    elevation_ft: "6050",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EHG",
    municipality: "Marawaka",
    gps_code: "AYMW",
    iata_code: "MWG",
    local_code: "MWK",
    coordinates: "145.8849, -6.9736",
  },
  {
    ident: "AYNA",
    type: "small_airport",
    name: "Nankina Airport",
    elevation_ft: "5800",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Gwarawon",
    gps_code: "AYNA",
    iata_code: "NKN",
    local_code: "NKN",
    coordinates: "146.4533, -5.837",
  },
  {
    ident: "AYNE",
    type: "small_airport",
    name: "Negarbo(Negabo) Airport",
    elevation_ft: "4500",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPK",
    municipality: "Negarbo",
    gps_code: "AYNE",
    iata_code: "GBF",
    local_code: "NBO",
    coordinates: "144.703058, -6.567778",
  },
  {
    ident: "AYNG",
    type: "small_airport",
    name: "Manguna Airport",
    elevation_ft: "187",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Manguna",
    gps_code: "AYNG",
    iata_code: "MFO",
    local_code: "MGA",
    coordinates: "151.792333, -5.577778",
  },
  {
    ident: "AYNM",
    type: "small_airport",
    name: "Kasonombe Airport",
    elevation_ft: "5800",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Kasonombe",
    gps_code: "AYNM",
    iata_code: "KSB",
    local_code: "KNB",
    coordinates: "146.9859, -6.3819",
  },
  {
    ident: "AYNO",
    type: "small_airport",
    name: "Nomane Airport",
    elevation_ft: "6032",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPK",
    municipality: "Namane",
    gps_code: "AYNO",
    iata_code: "NMN",
    local_code: "NOM",
    coordinates: "145.070667, -6.32183",
  },
  {
    ident: "AYNY",
    type: "small_airport",
    name: "Nambaiyufa Airport",
    elevation_ft: "5550",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPK",
    municipality: "Nambaiyufa",
    gps_code: "AYNY",
    iata_code: "NBA",
    local_code: "NBF",
    coordinates: "145.2424, -6.2412",
  },
  {
    ident: "AYNZ",
    type: "medium_airport",
    name: "Nadzab Airport",
    elevation_ft: "239",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Lae",
    gps_code: "AYNZ",
    iata_code: "LAE",
    coordinates: "146.725977, -6.569803",
  },
  {
    ident: "AYOE",
    type: "small_airport",
    name: "Konge Airport",
    elevation_ft: "5900",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Konge",
    gps_code: "AYOE",
    iata_code: "KGB",
    local_code: "KGE",
    coordinates: "147.2152, -6.2239",
  },
  {
    ident: "AYOJ",
    type: "small_airport",
    name: "Oksapmin Airport",
    elevation_ft: "5140",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Oksapmin",
    gps_code: "AYOJ",
    iata_code: "OKP",
    local_code: "OKN",
    coordinates: "142.2259, -5.2261",
  },
  {
    ident: "AYOK",
    type: "small_airport",
    name: "Komako Airport",
    elevation_ft: "3960",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Komako",
    gps_code: "AYOK",
    iata_code: "HOC",
    local_code: "KMA",
    coordinates: "145.8827, -7.3984",
  },
  {
    ident: "AYOQ",
    type: "small_airport",
    name: "Komaio Airport",
    elevation_ft: "28",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Komaio",
    gps_code: "AYOQ",
    iata_code: "KCJ",
    local_code: "KOI",
    coordinates: "143.5952, -7.2714",
  },
  {
    ident: "AYOW",
    type: "small_airport",
    name: "Koroba Airport",
    elevation_ft: "5638",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-HE",
    municipality: "Koroba",
    gps_code: "AYOW",
    iata_code: "KDE",
    coordinates: "142.7441, -5.6952",
  },
  {
    ident: "AYOY",
    type: "small_airport",
    name: "Open Bay Airport",
    elevation_ft: "33",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Maitanakunai",
    gps_code: "AYOY",
    iata_code: "OPB",
    local_code: "OBY",
    coordinates: "151.696195, -4.794727",
  },
  {
    ident: "AYPC",
    type: "small_airport",
    name: "Pangoa Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Pangoa",
    gps_code: "AYPC",
    iata_code: "PGB",
    local_code: "PGA",
    coordinates: "141.5605, -7.0226",
  },
  {
    ident: "AYPG",
    type: "small_airport",
    name: "Pangia Airport",
    elevation_ft: "5340",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SHM",
    municipality: "Pangia",
    gps_code: "AYPG",
    iata_code: "PGN",
    local_code: "PNA",
    coordinates: "144.1084, -6.386",
  },
  {
    ident: "AYPO",
    type: "small_airport",
    name: "Mapoda Airport",
    elevation_ft: "45",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Mapoda",
    gps_code: "AYPO",
    iata_code: "MPF",
    local_code: "MPO",
    coordinates: "143.1694, -7.979",
  },
  {
    ident: "AYPQ",
    type: "small_airport",
    name: "Pumani Airport",
    elevation_ft: "427",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Pumani",
    gps_code: "AYPQ",
    iata_code: "PMN",
    local_code: "PMN",
    coordinates: "149.4766, -9.7469",
  },
  {
    ident: "AYPY",
    type: "large_airport",
    name: "Port Moresby Jacksons International Airport",
    elevation_ft: "146",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NCD",
    municipality: "Port Moresby",
    gps_code: "AYPY",
    iata_code: "POM",
    coordinates: "147.22000122070312, -9.443380355834961",
  },
  {
    ident: "AYQO",
    type: "small_airport",
    name: "Sopu Airport",
    elevation_ft: "6580",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Sopu",
    gps_code: "AYQO",
    iata_code: "SPH",
    local_code: "SOPU",
    coordinates: "147.1659, -8.3038",
  },
  {
    ident: "AYQS",
    type: "small_airport",
    name: "Sialum Airport",
    elevation_ft: "170",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Sialum",
    gps_code: "AYQS",
    iata_code: "SXA",
    local_code: "SLU",
    coordinates: "147.5955, -6.0908",
  },
  {
    ident: "AYRG",
    type: "small_airport",
    name: "Rumginae Airport",
    elevation_ft: "160",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    gps_code: "AYRG",
    iata_code: "RMN",
    local_code: "RMG",
    coordinates: "141.271666667, -5.89722222222",
  },
  {
    ident: "AYRI",
    type: "small_airport",
    name: "Karimui Airport",
    elevation_ft: "3640",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPK",
    municipality: "Karimui",
    gps_code: "AYRI",
    iata_code: "KMR",
    local_code: "KRI",
    coordinates: "144.823, -6.4921",
  },
  {
    ident: "AYRM",
    type: "small_airport",
    name: "Maramuni Airport",
    elevation_ft: "5160",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EPW",
    municipality: "Maramuni",
    gps_code: "AYRM",
    iata_code: "MWI",
    local_code: "MAR",
    coordinates: "143.4829, -5.13",
  },
  {
    ident: "AYRV",
    type: "small_airport",
    name: "May River Airstrip",
    elevation_ft: "107",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "May River",
    gps_code: "AYRV",
    iata_code: "MRH",
    local_code: "MRV",
    coordinates: "141.785, -4.3615",
  },
  {
    ident: "AYSA",
    type: "small_airport",
    name: "Suabi Airport",
    elevation_ft: "465",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    gps_code: "AYSA",
    iata_code: "SBE",
    local_code: "SUA",
    coordinates: "142.278333333, -6.10444444444",
  },
  {
    ident: "AYSE",
    type: "small_airport",
    name: "Simberi Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Simberi Island",
    gps_code: "AYSE",
    iata_code: "NIS",
    local_code: "SBI",
    coordinates: "151.997777778, -2.66222222222",
  },
  {
    ident: "AYSG",
    type: "small_airport",
    name: "Sila Airport",
    elevation_ft: "2230",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Sila Mission",
    gps_code: "AYSG",
    iata_code: "SIL",
    local_code: "SILA",
    coordinates: "148.38925, -9.07355555556",
  },
  {
    ident: "AYSH",
    type: "small_airport",
    name: "Sabah Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Sabah",
    gps_code: "AYSH",
    iata_code: "SBV",
    local_code: "SAB",
    coordinates: "155.0489, -5.5765",
  },
  {
    ident: "AYSJ",
    type: "small_airport",
    name: "Simbai Airport",
    elevation_ft: "5804",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Simbai",
    gps_code: "AYSJ",
    iata_code: "SIM",
    local_code: "SAI",
    coordinates: "144.544722222, -5.278611111110001",
  },
  {
    ident: "AYSO",
    type: "small_airport",
    name: "Selbang Airport",
    elevation_ft: "5130",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Selbang",
    gps_code: "AYSO",
    iata_code: "SBC",
    local_code: "SEL",
    coordinates: "141.752, -5.315",
  },
  {
    ident: "AYSQ",
    type: "small_airport",
    name: "Sepik Plains Airport",
    elevation_ft: "230",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Sepik Plains",
    gps_code: "AYSQ",
    iata_code: "SPV",
    local_code: "SPP",
    coordinates: "143.6734, -3.8821",
  },
  {
    ident: "AYSV",
    type: "small_airport",
    name: "Sauren Airport",
    elevation_ft: "46",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Sauren",
    gps_code: "AYSV",
    iata_code: "SXW",
    local_code: "SAU",
    coordinates: "148.8543, -5.9701",
  },
  {
    ident: "AYSX",
    type: "small_airport",
    name: "Masa Airport",
    elevation_ft: "5900",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Masa",
    gps_code: "AYSX",
    iata_code: "MBV",
    local_code: "MASA",
    coordinates: "147.591, -6.345",
  },
  {
    ident: "AYTA",
    type: "small_airport",
    name: "Tari Airport",
    elevation_ft: "5500",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-HE",
    municipality: "Tari",
    gps_code: "AYTA",
    iata_code: "TIZ",
    coordinates: "142.947998047, -5.84499979019",
  },
  {
    ident: "AYTB",
    type: "small_airport",
    name: "Tabubil Airport",
    elevation_ft: "1570",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Tabubil",
    gps_code: "AYTB",
    iata_code: "TBG",
    coordinates: "141.225998, -5.27861",
  },
  {
    ident: "AYTI",
    type: "small_airport",
    name: "Tapini Airport",
    elevation_ft: "3100",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Tapini",
    gps_code: "AYTI",
    iata_code: "TPI",
    local_code: "TAP",
    coordinates: "146.989166667, -8.35666666667",
  },
  {
    ident: "AYTK",
    type: "medium_airport",
    name: "Tokua Airport",
    elevation_ft: "32",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Tokua",
    gps_code: "AYTK",
    iata_code: "RAB",
    coordinates: "152.380004883, -4.34045982361",
  },
  {
    ident: "AYTN",
    type: "small_airport",
    name: "Tekin Airport",
    elevation_ft: "5785",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Tekin",
    gps_code: "AYTN",
    iata_code: "TKW",
    local_code: "TEK",
    coordinates: "142.165194444, -5.24366666667",
  },
  {
    ident: "AYTP",
    type: "small_airport",
    name: "Tep Tep Airport",
    elevation_ft: "7011",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Teptep",
    gps_code: "AYTP",
    iata_code: "TEP",
    local_code: "TEP",
    coordinates: "146.5595, -5.9553",
  },
  {
    ident: "AYTS",
    type: "small_airport",
    name: "Tsewi Airport",
    elevation_ft: "4185",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Tsewi",
    gps_code: "AYTS",
    iata_code: "TSW",
    local_code: "TSE",
    coordinates: "146.1272, -7.0695",
  },
  {
    ident: "AYTT",
    type: "small_airport",
    name: "Tarakbits Airport",
    elevation_ft: "281",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Tarakbits",
    gps_code: "AYTT",
    iata_code: "TRJ",
    local_code: "TRT",
    coordinates: "141.0421, -5.614",
  },
  {
    ident: "AYTW",
    type: "small_airport",
    name: "Tawa Airport",
    elevation_ft: "5020",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Tawa",
    gps_code: "AYTW",
    iata_code: "TWY",
    local_code: "TAWA",
    coordinates: "146.1067, -7.4463",
  },
  {
    ident: "AYTZ",
    type: "small_airport",
    name: "Tekadu Airport",
    elevation_ft: "1310",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Tekadu",
    gps_code: "AYTZ",
    iata_code: "TKB",
    local_code: "TKD",
    coordinates: "146.5503, -7.6808",
  },
  {
    ident: "AYU",
    type: "small_airport",
    name: "Aiyura Airport",
    elevation_ft: "5355",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EHG",
    municipality: "Aiyura Valley",
    gps_code: "AYAY",
    iata_code: "AYU",
    local_code: "AYU",
    coordinates: "145.904166667, -6.33805555556",
  },
  {
    ident: "AYUC",
    type: "small_airport",
    name: "Umba Airport",
    elevation_ft: "5950",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Umba",
    gps_code: "AYUC",
    iata_code: "UMC",
    local_code: "UMBA",
    coordinates: "145.966, -7.0214",
  },
  {
    ident: "AYUE",
    type: "small_airport",
    name: "Uroubi Airport",
    elevation_ft: "481",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Uroubi",
    gps_code: "AYUE",
    iata_code: "URU",
    local_code: "URO",
    coordinates: "148.5591, -9.5162",
  },
  {
    ident: "AYUR",
    type: "small_airport",
    name: "Upiara Airport",
    elevation_ft: "52",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Upiara",
    gps_code: "AYUR",
    iata_code: "UPR",
    local_code: "UPA",
    coordinates: "142.6503, -8.5411",
  },
  {
    ident: "AYUZ",
    type: "small_airport",
    name: "Uvol Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Uvol",
    gps_code: "AYUZ",
    iata_code: "UVO",
    local_code: "UVOL",
    coordinates: "150.9557, -6.0178",
  },
  {
    ident: "AYVL",
    type: "small_airport",
    name: "Talasea Airport",
    elevation_ft: "44",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Talasea",
    gps_code: "AYVL",
    iata_code: "TLW",
    local_code: "TLS",
    coordinates: "150.0089, -5.2726",
  },
  {
    ident: "AYVM",
    type: "small_airport",
    name: "Torembi Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Torembi",
    gps_code: "AYVM",
    iata_code: "TCJ",
    local_code: "TBI",
    coordinates: "143.1329, -4.0165",
  },
  {
    ident: "AYVN",
    type: "medium_airport",
    name: "Vanimo Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Vanimo",
    gps_code: "AYVN",
    iata_code: "VAI",
    coordinates: "141.3028, -2.6926",
  },
  {
    ident: "AYVO",
    type: "small_airport",
    name: "Tonu Airport",
    elevation_ft: "300",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Tonu",
    gps_code: "AYVO",
    iata_code: "TON",
    local_code: "TONU",
    coordinates: "155.4326, -6.6552",
  },
  {
    ident: "AYWB",
    type: "small_airport",
    name: "Wabo Airport",
    elevation_ft: "132",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Wabo",
    gps_code: "AYWB",
    iata_code: "WAO",
    local_code: "WABO",
    coordinates: "145.075111111, -6.98944444444",
  },
  {
    ident: "AYWD",
    type: "medium_airport",
    name: "Wapenamanda Airport",
    elevation_ft: "5889",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EPW",
    gps_code: "AYWD",
    iata_code: "WBM",
    coordinates: "143.89500427246094, -5.6433000564575195",
  },
  {
    ident: "AYWF",
    type: "small_airport",
    name: "Wawoi Falls Airport",
    elevation_ft: "370",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Wavoi Falls",
    gps_code: "AYWF",
    iata_code: "WAJ",
    local_code: "WWF",
    coordinates: "142.6557, -6.9523",
  },
  {
    ident: "AYWK",
    type: "medium_airport",
    name: "Wewak International Airport",
    elevation_ft: "19",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Wewak",
    gps_code: "AYWK",
    iata_code: "WWK",
    coordinates: "143.669006348, -3.58383011818",
  },
  {
    ident: "AYWO",
    type: "small_airport",
    name: "Wonenara Airport",
    elevation_ft: "5028",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EHG",
    municipality: "Wonenara",
    gps_code: "AYWO",
    iata_code: "WOA",
    local_code: "WOA",
    coordinates: "145.891944444, -6.796861111110001",
  },
  {
    ident: "AYWQ",
    type: "small_airport",
    name: "Wakunai Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Wakunai",
    gps_code: "AYWQ",
    iata_code: "WKN",
    local_code: "WAI",
    coordinates: "155.2223, -5.8603",
  },
  {
    ident: "AYWS",
    type: "small_airport",
    name: "Wasu Airport",
    elevation_ft: "34",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Wasu",
    gps_code: "AYWS",
    iata_code: "WSU",
    local_code: "WASU",
    coordinates: "147.19822526, -5.96170944919",
  },
  {
    ident: "AYWT",
    type: "small_airport",
    name: "Woitape Airport",
    elevation_ft: "5200",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Fatima Mission",
    gps_code: "AYWT",
    iata_code: "WTP",
    local_code: "WTP",
    coordinates: "147.2525, -8.54583333333",
  },
  {
    ident: "AYWU",
    type: "small_airport",
    name: "Wau Airport",
    elevation_ft: "3600",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Wau",
    gps_code: "AYWU",
    iata_code: "WUG",
    local_code: "WAU",
    coordinates: "146.718611111, -7.345555555560001",
  },
  {
    ident: "AYXE",
    type: "small_airport",
    name: "Yeva Airport",
    elevation_ft: "4510",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Yeva",
    gps_code: "AYXE",
    iata_code: "YVD",
    local_code: "YEVA",
    coordinates: "146.188, -7.548",
  },
  {
    ident: "AYXI",
    type: "small_airport",
    name: "Sim Airport",
    elevation_ft: "5460",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Sim",
    gps_code: "AYXI",
    iata_code: "SMJ",
    local_code: "SIM",
    coordinates: "146.9273, -7.75",
  },
  {
    ident: "AYXW",
    type: "small_airport",
    name: "Weam Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Weam",
    gps_code: "AYXW",
    iata_code: "WEP",
    local_code: "WEAM",
    coordinates: "141.1381, -8.6184",
  },
  {
    ident: "AYY",
    type: "seaplane_base",
    name: "Arugam Bay SPB",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-5",
    municipality: "Pottuvil",
    iata_code: "AYY",
    coordinates: "81.82388888890002, 6.86",
  },
  {
    ident: "AYYE",
    type: "small_airport",
    name: "Yalumet Airport",
    elevation_ft: "2600",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Yalumet",
    gps_code: "AYYE",
    iata_code: "KYX",
    local_code: "YLT",
    coordinates: "147.0117, -6.09",
  },
  {
    ident: "AYYR",
    type: "small_airport",
    name: "Yasuru Airport",
    elevation_ft: "1520",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Yasuru",
    gps_code: "AYYR",
    iata_code: "KSX",
    local_code: "YAS",
    coordinates: "146.1813, -6.6015",
  },
  {
    ident: "AYZ",
    type: "closed",
    name: "Zahn's Airport",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Amityville",
    iata_code: "AYZ",
    coordinates: "-73.4, 40.71",
  },
  {
    ident: "AYZM",
    type: "small_airport",
    name: "Wasum Airport",
    elevation_ft: "175",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Wasum",
    gps_code: "AYZM",
    iata_code: "WUM",
    local_code: "WSM",
    coordinates: "149.337, -6.0491",
  },
  {
    ident: "AZ-0001",
    type: "medium_airport",
    name: "Zabrat Airport",
    elevation_ft: "36",
    continent: "AS",
    iso_country: "AZ",
    iso_region: "AZ-BA",
    municipality: "Baku",
    gps_code: "UBTT",
    iata_code: "ZXT",
    coordinates: "49.9768066406, 40.4955422161",
  },
  {
    ident: "AZ15",
    type: "heliport",
    name: "Lava Falls Heliport",
    elevation_ft: "1720",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Supai Village",
    gps_code: "AZ15",
    iata_code: "HAE",
    local_code: "AZ15",
    coordinates: "-113.092002869, 36.191600799599996",
  },
  {
    ident: "AZB",
    type: "small_airport",
    name: "Amazon Bay Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    iata_code: "AZB",
    local_code: "AZB",
    coordinates: "149.338333333, -10.2991666667",
  },
  {
    ident: "BAJ",
    type: "small_airport",
    name: "Bali Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Unea Island",
    iata_code: "BAJ",
    local_code: "BALI",
    coordinates: "149.133333333, -4.8833333333299995",
  },
  {
    ident: "BAP",
    type: "closed",
    name: "Baibara Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Nabai",
    iata_code: "BAP",
    coordinates: "149.6414, -10.3432",
  },
  {
    ident: "BBG",
    type: "small_airport",
    name: "Branson Airport",
    elevation_ft: "1302",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Branson",
    gps_code: "KBBG",
    iata_code: "BKG",
    local_code: "BBG",
    coordinates: "-93.200544, 36.532082",
  },
  {
    ident: "BCJ",
    type: "closed",
    name: "Baca Grande Airfield",
    elevation_ft: "7620",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Crestone",
    iata_code: "BCJ",
    coordinates: "-105.776666667, 37.964777777799995",
  },
  {
    ident: "BCP",
    type: "small_airport",
    name: "Bambu Airport",
    elevation_ft: "6790",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Bambu",
    gps_code: "AYBC",
    iata_code: "BCP",
    local_code: "BAM",
    coordinates: "146.4925, -5.863611111110001",
  },
  {
    ident: "BCW",
    type: "medium_airport",
    name: "Benguera Island Airport",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-I",
    municipality: "Benguera Island",
    iata_code: "BCW",
    coordinates: "35.43830108642578, -21.853300094604492",
  },
  {
    ident: "BCZ",
    type: "small_airport",
    name: "Milyakburra Airport",
    elevation_ft: "85",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Bickerton Island",
    iata_code: "BCZ",
    local_code: "YBIC",
    coordinates: "136.201666667, -13.780833333299999",
  },
  {
    ident: "BDH",
    type: "small_airport",
    name: "Willmar Municipal -John L Rice Field",
    elevation_ft: "1126",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Willmar",
    gps_code: "KBDH",
    iata_code: "ILL",
    local_code: "BDH",
    coordinates: "-95.13040161, 45.11769867",
  },
  {
    ident: "BDZ",
    type: "small_airport",
    name: "Baindoung Airport",
    elevation_ft: "4400",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    gps_code: "AYBG",
    iata_code: "BDZ",
    local_code: "BDO",
    coordinates: "146.942222222, -6.34722222222",
  },
  {
    ident: "BEA",
    type: "closed",
    name: "Bereina Airport",
    elevation_ft: "58",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Bereina Mission",
    iata_code: "BEA",
    coordinates: "146.5083, -8.64",
  },
  {
    ident: "BER",
    type: "closed",
    name: "Berlin Brandenburg Airport (U.C.)",
    elevation_ft: "157",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BR",
    municipality: "Berlin",
    gps_code: "EDDB",
    iata_code: "BER",
    coordinates: "13.493889, 52.351389",
  },
  {
    ident: "BG-HKV",
    type: "small_airport",
    name: "Malevo Airport",
    elevation_ft: "600",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-26",
    municipality: "Haskovo",
    iata_code: "HKV",
    coordinates: "25.6047992706, 41.871799469",
  },
  {
    ident: "BG-JAM",
    type: "medium_airport",
    name: "Bezmer Air Base",
    elevation_ft: "509",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-28",
    municipality: "Yambol",
    gps_code: "LBIA",
    iata_code: "JAM",
    coordinates: "26.3521995544, 42.4548988342",
  },
  {
    ident: "BGAA",
    type: "medium_airport",
    name: "Aasiaat Airport",
    elevation_ft: "74",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Aasiaat",
    gps_code: "BGAA",
    iata_code: "JEG",
    coordinates: "-52.7846984863, 68.7218017578",
  },
  {
    ident: "BGAG",
    type: "heliport",
    name: "Aappilattoq (Qaasuitsup) Heliport",
    elevation_ft: "42",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Qaasuitsup",
    gps_code: "BGAG",
    iata_code: "AOQ",
    local_code: "AAP",
    coordinates: "-55.596287, 72.88703",
  },
  {
    ident: "BGAM",
    type: "heliport",
    name: "Tasiilaq Heliport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Tasiilaq",
    gps_code: "BGAM",
    iata_code: "AGM",
    local_code: "AGM",
    coordinates: "-37.618335, 65.612296",
  },
  {
    ident: "BGAP",
    type: "heliport",
    name: "Alluitsup Paa Heliport",
    elevation_ft: "54",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Alluitsup Paa",
    gps_code: "BGAP",
    iata_code: "LLU",
    coordinates: "-45.56917, 60.46445",
  },
  {
    ident: "BGAT",
    type: "heliport",
    name: "Attu Heliport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Attu",
    gps_code: "BGAT",
    iata_code: "QGQ",
    coordinates: "-53.6218696833, 67.9406628551",
  },
  {
    ident: "BGBW",
    type: "medium_airport",
    name: "Narsarsuaq Airport",
    elevation_ft: "112",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Narsarsuaq",
    gps_code: "BGBW",
    iata_code: "UAK",
    coordinates: "-45.4259986877, 61.1604995728",
  },
  {
    ident: "BGCH",
    type: "heliport",
    name: "Qasigiannguit Heliport",
    elevation_ft: "70",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Qasigiannguit",
    gps_code: "BGCH",
    iata_code: "JCH",
    coordinates: "-51.1734473705, 68.822815547",
  },
  {
    ident: "BGCO",
    type: "small_airport",
    name: "Neerlerit Inaat Airport",
    elevation_ft: "45",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Neerlerit Inaat",
    gps_code: "BGCO",
    iata_code: "CNP",
    coordinates: "-22.6504993439, 70.7431030273",
  },
  {
    ident: "BGDU",
    type: "closed",
    name: "Dundas Airport",
    elevation_ft: "200",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Pituffik",
    gps_code: "BGDU",
    iata_code: "DUN",
    coordinates: "-68.8, 76.525",
  },
  {
    ident: "BGET",
    type: "heliport",
    name: "Eqalugaarsuit Heliport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Eqalugaarsuit",
    gps_code: "BGET",
    iata_code: "QFG",
    coordinates: "-45.9140619636, 60.6197196536",
  },
  {
    ident: "BGGH",
    type: "medium_airport",
    name: "Godthaab / Nuuk Airport",
    elevation_ft: "283",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Nuuk",
    gps_code: "BGGH",
    iata_code: "GOH",
    coordinates: "-51.6781005859, 64.19090271",
  },
  {
    ident: "BGGN",
    type: "heliport",
    name: "Qeqertarsuaq Heliport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Qeqertarsuaq Airport",
    gps_code: "BGGN",
    iata_code: "JGO",
    coordinates: "-53.5148763657, 69.251181993",
  },
  {
    ident: "BGIG",
    type: "heliport",
    name: "Iginniarfik",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Iginniarfik",
    gps_code: "BGIG",
    iata_code: "QFI",
    coordinates: "-53.1735277176, 68.1472255861",
  },
  {
    ident: "BGIK",
    type: "heliport",
    name: "Ikerassaarsuk Heliport",
    elevation_ft: "165",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Ikerassaarsuk",
    gps_code: "BGIK",
    iata_code: "QRY",
    coordinates: "-53.4414589405, 68.14088100629999",
  },
  {
    ident: "BGIL",
    type: "heliport",
    name: "Ilimanaq Heliport",
    elevation_ft: "52",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Ilimanaq",
    gps_code: "BGIL",
    iata_code: "XIQ",
    coordinates: "-51.1143153906, 69.0809150007",
  },
  {
    ident: "BGIN",
    type: "heliport",
    name: "Innarsuit Heliport",
    elevation_ft: "95",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Innarsuit",
    gps_code: "BGIN",
    iata_code: "IUI",
    local_code: "INN",
    coordinates: "-56.010817, 73.199895",
  },
  {
    ident: "BGIS",
    type: "heliport",
    name: "Isortoq Heliport",
    elevation_ft: "92",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Isortoq",
    gps_code: "BGIS",
    iata_code: "IOQ",
    local_code: "ISO",
    coordinates: "-38.976552, 65.547792",
  },
  {
    ident: "BGIT",
    type: "heliport",
    name: "Ikamiut Heliport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Ikamiut",
    gps_code: "BGIT",
    iata_code: "QJI",
    coordinates: "-51.8322622776, 68.6342530984",
  },
  {
    ident: "BGJH",
    type: "heliport",
    name: "Qaqortoq Heliport",
    elevation_ft: "53",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Qaqortoq",
    gps_code: "BGJH",
    iata_code: "JJU",
    coordinates: "-46.0299186409, 60.715684155299996",
  },
  {
    ident: "BGJN",
    type: "small_airport",
    name: "Ilulissat Airport",
    elevation_ft: "95",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Ilulissat",
    gps_code: "BGJN",
    iata_code: "JAV",
    coordinates: "-51.0570983887, 69.2432022095",
  },
  {
    ident: "BGKA",
    type: "heliport",
    name: "Kangaatsiaq Heliport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Kangaatsiaq",
    gps_code: "BGKA",
    iata_code: "QPW",
    coordinates: "-53.4602075815, 68.3126574861",
  },
  {
    ident: "BGKK",
    type: "small_airport",
    name: "Kulusuk Airport",
    elevation_ft: "117",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Kulusuk",
    gps_code: "BGKK",
    iata_code: "KUS",
    coordinates: "-37.1236000061, 65.573600769",
  },
  {
    ident: "BGKM",
    type: "heliport",
    name: "Kuummiut Heliport",
    elevation_ft: "89",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Kuummiut",
    gps_code: "BGKM",
    iata_code: "KUZ",
    local_code: "KMT",
    coordinates: "-36.997919, 65.863935",
  },
  {
    ident: "BGKQ",
    type: "heliport",
    name: "Kullorsuaq Heliport",
    elevation_ft: "148",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Kullorsuaq",
    gps_code: "BGKQ",
    iata_code: "KHQ",
    local_code: "KLQ",
    coordinates: "-57.226828, 74.57805",
  },
  {
    ident: "BGKS",
    type: "heliport",
    name: "Kangersuatsiaq Heliport",
    elevation_ft: "112",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Kangersuatsiaq",
    gps_code: "BGKS",
    iata_code: "KGQ",
    local_code: "KAQ",
    coordinates: "-55.536586, 72.381092",
  },
  {
    ident: "BGKT",
    type: "heliport",
    name: "Kitsissuarsuit Heliport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Kitsissuarsuit",
    gps_code: "BGKT",
    iata_code: "QJE",
    coordinates: "-53.123295307199996, 68.85792599759999",
  },
  {
    ident: "BGMQ",
    type: "small_airport",
    name: "Maniitsoq Airport",
    elevation_ft: "91",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Maniitsoq",
    gps_code: "BGMQ",
    iata_code: "JSU",
    coordinates: "-52.9393997192, 65.4124984741",
  },
  {
    ident: "BGNN",
    type: "heliport",
    name: "Nanortalik Heliport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Nanortalik",
    gps_code: "BGNN",
    iata_code: "JNN",
    coordinates: "-45.232976675, 60.141883975899994",
  },
  {
    ident: "BGNS",
    type: "heliport",
    name: "Narsaq Heliport",
    elevation_ft: "83",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Narsaq",
    gps_code: "BGNS",
    iata_code: "JNS",
    coordinates: "-46.059923172, 60.9172827256",
  },
  {
    ident: "BGNT",
    type: "heliport",
    name: "Niaqornat Heliport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Niaqornat",
    gps_code: "BGNT",
    iata_code: "NIQ",
    local_code: "NIA",
    coordinates: "-53.662945, 70.789385",
  },
  {
    ident: "BGNU",
    type: "heliport",
    name: "Nuussuaq Heliport",
    elevation_ft: "184",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Nuussuaq",
    gps_code: "BGNU",
    iata_code: "NSQ",
    local_code: "NUS",
    coordinates: "-57.065037, 74.109853",
  },
  {
    ident: "BGP",
    type: "small_airport",
    name: "Bongo Airport",
    elevation_ft: "120",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-8",
    municipality: "Bongo",
    iata_code: "BGP",
    coordinates: "10.2088, -2.1713",
  },
  {
    ident: "BGPT",
    type: "small_airport",
    name: "Paamiut Airport",
    elevation_ft: "120",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Paamiut",
    gps_code: "BGPT",
    iata_code: "JFR",
    coordinates: "-49.6709365845, 62.0147361755",
  },
  {
    ident: "BGQE",
    type: "heliport",
    name: "Qeqertaq Heliport",
    elevation_ft: "69",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Qeqertaq",
    gps_code: "BGQE",
    iata_code: "QQT",
    coordinates: "-51.3009166718, 69.9958611087",
  },
  {
    ident: "BGQQ",
    type: "small_airport",
    name: "Qaanaaq Airport",
    elevation_ft: "51",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Qaanaaq",
    gps_code: "BGQQ",
    iata_code: "NAQ",
    coordinates: "-69.3887023926, 77.4886016846",
  },
  {
    ident: "BGSC",
    type: "heliport",
    name: "Ittoqqortoormiit Heliport",
    elevation_ft: "238",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Ittoqqortoormiit",
    gps_code: "BGSC",
    iata_code: "OBY",
    coordinates: "-21.971679925900002, 70.4882288244",
  },
  {
    ident: "BGSF",
    type: "medium_airport",
    name: "Kangerlussuaq Airport",
    elevation_ft: "165",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Kangerlussuaq",
    gps_code: "BGSF",
    iata_code: "SFJ",
    coordinates: "-50.7116031647, 67.0122218992",
  },
  {
    ident: "BGSI",
    type: "heliport",
    name: "Siorapaluk Heliport",
    elevation_ft: "102",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Siorapaluk",
    gps_code: "BGSI",
    iata_code: "SRK",
    local_code: "SIO",
    coordinates: "-70.638657, 77.786517",
  },
  {
    ident: "BGSO",
    type: "heliport",
    name: "Saarloq Heliport",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    gps_code: "BGSO",
    iata_code: "QOQ",
    coordinates: "-46.024722, 60.537778",
  },
  {
    ident: "BGSS",
    type: "medium_airport",
    name: "Sisimiut Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Sisimiut",
    gps_code: "BGSS",
    iata_code: "JHS",
    coordinates: "-53.729301, 66.951302",
  },
  {
    ident: "BGSV",
    type: "heliport",
    name: "Savissivik Heliport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Savissivik",
    gps_code: "BGSV",
    iata_code: "SVR",
    local_code: "SAV",
    coordinates: "-65.117683, 76.018613",
  },
  {
    ident: "BGTA",
    type: "heliport",
    name: "Tasiusaq (Qaasuitsup) Heliport",
    elevation_ft: "181",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Upernavik",
    gps_code: "BGTA",
    iata_code: "TQA",
    coordinates: "-56.06028, 73.373055",
  },
  {
    ident: "BGTL",
    type: "medium_airport",
    name: "Thule Air Base",
    elevation_ft: "251",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Thule",
    gps_code: "BGTL",
    iata_code: "THU",
    coordinates: "-68.7032012939, 76.5311965942",
  },
  {
    ident: "BGTN",
    type: "heliport",
    name: "Tiniteqilaaq Heliport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Tiniteqilaaq",
    gps_code: "BGTN",
    iata_code: "TQI",
    local_code: "TNT",
    coordinates: "-37.783409, 65.892027",
  },
  {
    ident: "BGUK",
    type: "small_airport",
    name: "Upernavik Airport",
    elevation_ft: "414",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Upernavik",
    gps_code: "BGUK",
    iata_code: "JUV",
    coordinates: "-56.1305999756, 72.7901992798",
  },
  {
    ident: "BGUM",
    type: "heliport",
    name: "Uummannaq Heliport",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Uummannaq",
    gps_code: "BGUM",
    iata_code: "UMD",
    coordinates: "-52.111630439799995, 70.6804279261",
  },
  {
    ident: "BGUQ",
    type: "small_airport",
    name: "Qaarsut Airport",
    elevation_ft: "289",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Uummannaq",
    gps_code: "BGUQ",
    iata_code: "JQA",
    coordinates: "-52.6962013245, 70.7341995239",
  },
  {
    ident: "BGUT",
    type: "heliport",
    name: "Ukkusissat Heliport",
    elevation_ft: "223",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Ukkusissat",
    gps_code: "BGUT",
    iata_code: "JUK",
    local_code: "UKK",
    coordinates: "-51.890016, 71.049438",
  },
  {
    ident: "BHL",
    type: "small_airport",
    name: "Bah����a de los ����ngeles Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCN",
    municipality: "Bah����a de los ����ngeles",
    iata_code: "BHL",
    local_code: "BAX",
    coordinates: "-113.560997, 28.9786",
  },
  {
    ident: "BHT",
    type: "small_airport",
    name: "Brighton Downs Airport",
    elevation_ft: "390",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    iata_code: "BHT",
    coordinates: "141.562777778, -23.3638888889",
  },
  {
    ident: "BIAR",
    type: "medium_airport",
    name: "Akureyri Airport",
    elevation_ft: "6",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "Akureyri",
    gps_code: "BIAR",
    iata_code: "AEY",
    coordinates: "-18.07270050048828, 65.66000366210938",
  },
  {
    ident: "BIBD",
    type: "small_airport",
    name: "Bildudalur Airport",
    elevation_ft: "18",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-4",
    municipality: "Bildudalur",
    gps_code: "BIBD",
    iata_code: "BIU",
    coordinates: "-23.546199798583984, 65.64129638671875",
  },
  {
    ident: "BIBF",
    type: "small_airport",
    name: "Borgarfj����r����ur eystri Airport",
    elevation_ft: "80",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-7",
    municipality: "Borgarfj����r����ur eystri",
    gps_code: "BIBF",
    iata_code: "BGJ",
    coordinates: "-13.805000305175781, 65.51640319824219",
  },
  {
    ident: "BIBK",
    type: "small_airport",
    name: "Bakkafj����r����ur Airport",
    elevation_ft: "14",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "Bakkafj����r����ur",
    gps_code: "BIBK",
    iata_code: "BJD",
    coordinates: "-14.824399948120117, 66.02189636230469",
  },
  {
    ident: "BIBL",
    type: "small_airport",
    name: "Hjaltabakki Airport",
    elevation_ft: "131",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-5",
    municipality: "Bl����ndu����s",
    gps_code: "BIBL",
    iata_code: "BLO",
    coordinates: "-20.287500381469727, 65.6449966430664",
  },
  {
    ident: "BIBR",
    type: "small_airport",
    name: "B��������ardalur Airport",
    elevation_ft: "131",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-3",
    municipality: "B��������ardalur",
    gps_code: "BIBR",
    iata_code: "BQD",
    coordinates: "-21.80030059814453, 65.07530212402344",
  },
  {
    ident: "BIBV",
    type: "small_airport",
    name: "Brei����dalsv����k Airport",
    elevation_ft: "8",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-7",
    municipality: "Brei����dalsv����k",
    gps_code: "BIBV",
    iata_code: "BXV",
    coordinates: "-14.02280044555664, 64.79000091552734",
  },
  {
    ident: "BIDV",
    type: "small_airport",
    name: "Dj����pivogur Airport",
    elevation_ft: "9",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-7",
    municipality: "Dj����pivogur",
    gps_code: "BIDV",
    iata_code: "DJU",
    coordinates: "-14.28279972076416, 64.64420318603516",
  },
  {
    ident: "BIEG",
    type: "medium_airport",
    name: "Egilssta����ir Airport",
    elevation_ft: "76",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-7",
    municipality: "Egilssta����ir",
    gps_code: "BIEG",
    iata_code: "EGS",
    coordinates: "-14.401399612426758, 65.2833023071289",
  },
  {
    ident: "BIFF",
    type: "small_airport",
    name: "F����skr��������sfj����r����ur Airport",
    elevation_ft: "15",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-7",
    municipality: "F����skr��������sfj����r����ur",
    gps_code: "BIFF",
    iata_code: "FAS",
    coordinates: "-14.0606002808, 64.9317016602",
  },
  {
    ident: "BIFM",
    type: "small_airport",
    name: "Fagurh����lsm����ri Airport",
    elevation_ft: "56",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-7",
    municipality: "Fagurh����lsm����ri",
    gps_code: "BIFM",
    iata_code: "FAG",
    coordinates: "-16.64109992980957, 63.874698638916016",
  },
  {
    ident: "BIGF",
    type: "small_airport",
    name: "Grundarfj����r����ur Airport",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-3",
    municipality: "Grundarfj����r����ur",
    gps_code: "BIGF",
    iata_code: "GUU",
    coordinates: "-23.224700927734375, 64.99140167236328",
  },
  {
    ident: "BIGJ",
    type: "small_airport",
    name: "Gj����gur Airport",
    elevation_ft: "83",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-4",
    municipality: "Gj����gur",
    gps_code: "BIGJ",
    iata_code: "GJR",
    coordinates: "-21.326900482177734, 65.99530029296875",
  },
  {
    ident: "BIGR",
    type: "small_airport",
    name: "Gr����msey Airport",
    elevation_ft: "66",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "Gr����msey",
    gps_code: "BIGR",
    iata_code: "GRY",
    coordinates: "-18.0173, 66.5458",
  },
  {
    ident: "BIHK",
    type: "small_airport",
    name: "H����lmav����k Airport",
    elevation_ft: "90",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-4",
    municipality: "H����lmav����k",
    gps_code: "BIHK",
    iata_code: "HVK",
    coordinates: "-21.696399688720703, 65.70469665527344",
  },
  {
    ident: "BIHN",
    type: "medium_airport",
    name: "Hornafj����r����ur Airport",
    elevation_ft: "24",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-8",
    municipality: "H����fn",
    gps_code: "BIHN",
    iata_code: "HFN",
    coordinates: "-15.2272, 64.295601",
  },
  {
    ident: "BIHT",
    type: "small_airport",
    name: "Holt Airport",
    elevation_ft: "10",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-4",
    municipality: "Flateyri",
    gps_code: "BIHT",
    iata_code: "FLI",
    coordinates: "-23.4416913986, 66.0141804294",
  },
  {
    ident: "BIHU",
    type: "medium_airport",
    name: "H����sav����k Airport",
    elevation_ft: "48",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "H����sav����k",
    gps_code: "BIHU",
    iata_code: "HZK",
    coordinates: "-17.426001, 65.952301",
  },
  {
    ident: "BIHV",
    type: "small_airport",
    name: "Kr����ksta����armelar Airport",
    elevation_ft: "164",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-5",
    municipality: "Hvammstangi",
    gps_code: "BIHV",
    iata_code: "HVM",
    coordinates: "-20.846900939941406, 65.26640319824219",
  },
  {
    ident: "BIID",
    type: "small_airport",
    name: "Ingjaldssan����ur Airport",
    elevation_ft: "70",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-4",
    municipality: "Onundarfj����r����ur",
    gps_code: "BIID",
    iata_code: "HLO",
    coordinates: "-23.69610023498535, 66.05000305175781",
  },
  {
    ident: "BIIS",
    type: "medium_airport",
    name: "����safj����r����ur Airport",
    elevation_ft: "8",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-4",
    municipality: "����safj����r����ur",
    gps_code: "BIIS",
    iata_code: "IFJ",
    coordinates: "-23.135299682617188, 66.05809783935547",
  },
  {
    ident: "BIJ",
    type: "closed",
    name: "Biliau Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Biliau",
    iata_code: "BIJ",
    coordinates: "146.339, -5.578",
  },
  {
    ident: "BIKF",
    type: "large_airport",
    name: "Keflavik International Airport",
    elevation_ft: "171",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-2",
    municipality: "Reykjav����k",
    gps_code: "BIKF",
    iata_code: "KEF",
    coordinates: "-22.6056, 63.985001",
  },
  {
    ident: "BIKP",
    type: "small_airport",
    name: "K����pasker Airport",
    elevation_ft: "20",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "K����pasker",
    gps_code: "BIKP",
    iata_code: "OPA",
    coordinates: "-16.466699600219727, 66.31079864501953",
  },
  {
    ident: "BIKR",
    type: "small_airport",
    name: "Sau��������rkr����kur Airport",
    elevation_ft: "8",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-5",
    municipality: "Sau��������rkr����kur",
    gps_code: "BIKR",
    iata_code: "SAK",
    coordinates: "-19.572799682599996, 65.73169708249999",
  },
  {
    ident: "BINF",
    type: "small_airport",
    name: "Nor����fj����r����ur Airport",
    elevation_ft: "13",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-7",
    municipality: "Nor����fj����r����ur",
    gps_code: "BINF",
    iata_code: "NOR",
    coordinates: "-13.746399879455566, 65.13189697265625",
  },
  {
    ident: "BIOF",
    type: "small_airport",
    name: "����lafsfj����r����ur Airport",
    elevation_ft: "32",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "����lafsfj����r����ur",
    gps_code: "BIOF",
    iata_code: "OFJ",
    coordinates: "-18.66670036315918, 66.08329772949219",
  },
  {
    ident: "BIPA",
    type: "small_airport",
    name: "Patreksfj����r����ur Airport",
    elevation_ft: "11",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-4",
    municipality: "Patreksfj����r����ur",
    gps_code: "BIPA",
    iata_code: "PFJ",
    coordinates: "-23.965, 65.555801",
  },
  {
    ident: "BIRE",
    type: "small_airport",
    name: "Reykh����lar Airport",
    elevation_ft: "60",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-4",
    municipality: "Reykh����lar",
    gps_code: "BIRE",
    iata_code: "RHA",
    coordinates: "-22.20611572265625, 65.45262908935547",
  },
  {
    ident: "BIRF",
    type: "small_airport",
    name: "Rif Airport",
    elevation_ft: "18",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-3",
    municipality: "Rif",
    gps_code: "BIRF",
    iata_code: "OLI",
    coordinates: "-23.8230991364, 64.9113998413",
  },
  {
    ident: "BIRG",
    type: "small_airport",
    name: "Raufarh����fn Airport",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "Raufarh����fn",
    gps_code: "BIRG",
    iata_code: "RFN",
    coordinates: "-15.918299674987793, 66.40640258789062",
  },
  {
    ident: "BIRK",
    type: "medium_airport",
    name: "Reykjavik Airport",
    elevation_ft: "48",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-1",
    municipality: "Reykjavik",
    gps_code: "BIRK",
    iata_code: "RKV",
    coordinates: "-21.9405994415, 64.1299972534",
  },
  {
    ident: "BIRL",
    type: "small_airport",
    name: "Reykjahl�������� Airport",
    elevation_ft: "1030",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "Myvatn",
    gps_code: "BIRL",
    iata_code: "MVA",
    coordinates: "-16.918100357055664, 65.65579986572266",
  },
  {
    ident: "BISI",
    type: "medium_airport",
    name: "Siglufj����r����ur Airport",
    elevation_ft: "10",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "Siglufj����r����ur",
    gps_code: "BISI",
    iata_code: "SIJ",
    coordinates: "-18.9167, 66.133301",
  },
  {
    ident: "BIST",
    type: "small_airport",
    name: "Stykkish����lmur Airport",
    elevation_ft: "42",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-3",
    municipality: "Stykkish����lmur",
    gps_code: "BIST",
    iata_code: "SYK",
    coordinates: "-22.794200897216797, 65.05809783935547",
  },
  {
    ident: "BITE",
    type: "small_airport",
    name: "����ingeyri Airport",
    elevation_ft: "65",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-4",
    municipality: "����ingeyri",
    gps_code: "BITE",
    iata_code: "TEY",
    coordinates: "-23.559999465942383, 65.87030029296875",
  },
  {
    ident: "BITN",
    type: "small_airport",
    name: "Thorshofn Airport",
    elevation_ft: "65",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-6",
    municipality: "Thorshofn",
    gps_code: "BITN",
    iata_code: "THO",
    coordinates: "-15.335599899291992, 66.21849822998047",
  },
  {
    ident: "BIVM",
    type: "medium_airport",
    name: "Vestmannaeyjar Airport",
    elevation_ft: "326",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-8",
    municipality: "Vestmannaeyjar",
    gps_code: "BIVM",
    iata_code: "VEY",
    coordinates: "-20.278900146484375, 63.42430114746094",
  },
  {
    ident: "BIVO",
    type: "small_airport",
    name: "Vopnafj����r����ur Airport",
    elevation_ft: "16",
    continent: "EU",
    iso_country: "IS",
    iso_region: "IS-7",
    municipality: "Vopnafj����r����ur",
    gps_code: "BIVO",
    iata_code: "VPN",
    coordinates: "-14.850600242614746, 65.72059631347656",
  },
  {
    ident: "BIZ",
    type: "closed",
    name: "Bimin Airport",
    elevation_ft: "5775",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    iata_code: "BIZ",
    local_code: "BMN",
    coordinates: "142.033055556, -5.28611111111",
  },
  {
    ident: "BJE",
    type: "small_airport",
    name: "Baleela Airport",
    elevation_ft: "1690",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-13",
    municipality: "Baleela Base Camp",
    iata_code: "BJE",
    coordinates: "28.5230555556, 11.199444444400001",
  },
  {
    ident: "BJQ",
    type: "small_airport",
    name: "Bahja Airport",
    elevation_ft: "515",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-WU",
    municipality: "Bahja",
    iata_code: "BJQ",
    coordinates: "56.067777777799996, 19.8730555556",
  },
  {
    ident: "BJT",
    type: "seaplane_base",
    name: "Bentota River Waterdrome",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-1",
    municipality: "Bentota",
    iata_code: "BJT",
    coordinates: "79.996, 6.431",
  },
  {
    ident: "BKPR",
    type: "large_airport",
    name: "Pri����tina International Airport",
    elevation_ft: "1789",
    continent: "EU",
    iso_country: "XK",
    iso_region: "XK-01",
    municipality: "Prishtina",
    gps_code: "BKPR",
    iata_code: "PRN",
    coordinates: "21.035801, 42.5728",
  },
  {
    ident: "BMH",
    type: "small_airport",
    name: "Bomai Airport",
    elevation_ft: "3300",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPK",
    municipality: "Bomai",
    gps_code: "AYBO",
    iata_code: "BMH",
    local_code: "BOM",
    coordinates: "144.637, -6.37316666667",
  },
  {
    ident: "BMQ",
    type: "small_airport",
    name: "Bamburi Airport",
    elevation_ft: "52",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    gps_code: "HKBM",
    iata_code: "BMQ",
    coordinates: "39.730768, -3.98191",
  },
  {
    ident: "BMZ",
    type: "small_airport",
    name: "Bamu Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Bamu",
    gps_code: "AYBF",
    iata_code: "BMZ",
    local_code: "BAMU",
    coordinates: "143.2433, -7.8485",
  },
  {
    ident: "BNF",
    type: "seaplane_base",
    name: "Baranof Warm Springs Float and Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Baranof",
    iata_code: "BNF",
    local_code: "BNF",
    coordinates: "-134.833146, 57.088799",
  },
  {
    ident: "BNM",
    type: "small_airport",
    name: "Bodinumu Airport",
    elevation_ft: "3700",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Bodinumu",
    gps_code: "AYBD",
    iata_code: "BNM",
    local_code: "BNU",
    coordinates: "147.666722222, -9.107777777779999",
  },
  {
    ident: "BNQ",
    type: "closed",
    name: "Baganga airport",
    elevation_ft: "42",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-DAO",
    municipality: "Baganga",
    iata_code: "BNQ",
    coordinates: "126.568888889, 7.611944444440001",
  },
  {
    ident: "BNT",
    type: "small_airport",
    name: "Bundi Airport",
    elevation_ft: "4400",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Bundi",
    gps_code: "AYDI",
    iata_code: "BNT",
    local_code: "BDI",
    coordinates: "145.2275, -5.742222222220001",
  },
  {
    ident: "BNV",
    type: "closed",
    name: "Boana Airport",
    elevation_ft: "2950",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Boana",
    iata_code: "BNV",
    coordinates: "146.825, -6.433",
  },
  {
    ident: "BNZ",
    type: "closed",
    name: "Banz Airport",
    elevation_ft: "5150",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-JI",
    municipality: "Banz",
    iata_code: "BNZ",
    coordinates: "144.623, -5.807",
  },
  {
    ident: "BO-0001",
    type: "small_airport",
    name: "Rurenabaque Airport",
    elevation_ft: "676",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Rurenabaque",
    gps_code: "SLRQ",
    iata_code: "RBQ",
    coordinates: "-67.4968032837, -14.427900314299999",
  },
  {
    ident: "BO-BVL",
    type: "small_airport",
    name: "Baures Airport",
    elevation_ft: "470",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Baures",
    gps_code: "SLBA",
    iata_code: "BVL",
    coordinates: "-63.5833320618, -13.583333015400001",
  },
  {
    ident: "BOF",
    type: "heliport",
    name: "Bolling Air Force Base",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-DC",
    municipality: "Washington",
    gps_code: "KBOF",
    iata_code: "BOF",
    local_code: "BOF",
    coordinates: "-77.0160980225, 38.842899322499996",
  },
  {
    ident: "BOK",
    type: "small_airport",
    name: "Brookings Airport",
    elevation_ft: "459",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Brookings",
    gps_code: "KBOK",
    iata_code: "BOK",
    local_code: "BOK",
    coordinates: "-124.290001, 42.0746",
  },
  {
    ident: "BOQ",
    type: "closed",
    name: "Boku Airport",
    elevation_ft: "225",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Boku",
    iata_code: "BOQ",
    coordinates: "155.3419, -6.5427",
  },
  {
    ident: "BOT",
    type: "small_airport",
    name: "Bosset Airport",
    elevation_ft: "80",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Bosset",
    gps_code: "AYET",
    iata_code: "BOT",
    local_code: "BST",
    coordinates: "141.1063222, -7.237257",
  },
  {
    ident: "BOV",
    type: "small_airport",
    name: "Boang Airport",
    elevation_ft: "85",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Boang Island",
    iata_code: "BOV",
    local_code: "BOG",
    coordinates: "153.2812, -3.3834",
  },
  {
    ident: "BPF",
    type: "small_airport",
    name: "Batuna Aerodrome",
    elevation_ft: "24",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Batuna Mission Station",
    gps_code: "AGBT",
    iata_code: "BPF",
    coordinates: "158.119305556, -8.56202777778",
  },
  {
    ident: "BQV",
    type: "seaplane_base",
    name: "Bartlett Cove Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Gustavus",
    gps_code: "BQV",
    iata_code: "BQV",
    local_code: "BQV",
    coordinates: "-135.884994507, 58.4552001953",
  },
  {
    ident: "BR-0015",
    type: "closed",
    name: "Tucum���� Airport",
    elevation_ft: "1060",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Tucum����",
    iata_code: "TUZ",
    coordinates: "-51.1478, -6.7488",
  },
  {
    ident: "BR-ALT",
    type: "small_airport",
    name: "Alenquer Airport",
    elevation_ft: "55",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Alenquer",
    gps_code: "SDWQ",
    iata_code: "ALT",
    coordinates: "-54.7231, -1.917",
  },
  {
    ident: "BR-SWM",
    type: "closed",
    name: "Suia-Missu Airport",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Alto Boa Vista",
    iata_code: "SWM",
    coordinates: "-51.43470001220703, -11.671699523925781",
  },
  {
    ident: "BRG",
    type: "closed",
    name: "Whitesburg Municipal Airport",
    elevation_ft: "1985",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Whitesburg",
    iata_code: "BRG",
    coordinates: "-82.8742, 37.2219",
  },
  {
    ident: "BS-NSB",
    type: "seaplane_base",
    name: "Bimini North Seaplane Base",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-BI",
    municipality: "Bimini",
    iata_code: "NSB",
    coordinates: "-79.25, 25.767000198364258",
  },
  {
    ident: "BSP",
    type: "small_airport",
    name: "Bensbach Airport",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Bensbach",
    gps_code: "AYBH",
    iata_code: "BSP",
    local_code: "BEN",
    coordinates: "141.259444444, -8.85805555556",
  },
  {
    ident: "BSV",
    type: "small_airport",
    name: "Besakoa Airport",
    elevation_ft: "40",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Besakoa",
    iata_code: "BSV",
    coordinates: "47.0616666667, -15.6725",
  },
  {
    ident: "BUL",
    type: "small_airport",
    name: "Bulolo Airport",
    elevation_ft: "2240",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Bulolo",
    gps_code: "AYBU",
    iata_code: "BUL",
    local_code: "BUO",
    coordinates: "146.649541855, -7.216286671410001",
  },
  {
    ident: "BVP",
    type: "closed",
    name: "Bolovip Airstrip",
    elevation_ft: "4990",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Bolovip",
    iata_code: "BVP",
    coordinates: "141.655, -5.3633",
  },
  {
    ident: "BVR",
    type: "small_airport",
    name: "Esperadinha Airport",
    elevation_ft: "64",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-S",
    municipality: "Brava Island",
    gps_code: "GVBR",
    iata_code: "BVR",
    coordinates: "-24.746, 14.864305555600001",
  },
  {
    ident: "BW-HUK",
    type: "small_airport",
    name: "Hukuntsi Airport",
    elevation_ft: "3720",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-KG",
    municipality: "Hukuntsi",
    gps_code: "FBHK",
    iata_code: "HUK",
    coordinates: "21.758101, -23.9897",
  },
  {
    ident: "BWJ",
    type: "small_airport",
    name: "Bawan Airport",
    elevation_ft: "4700",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    iata_code: "BWJ",
    local_code: "BWN",
    coordinates: "146.881666667, -6.396944444440001",
  },
  {
    ident: "BWP",
    type: "small_airport",
    name: "Bewani Airport",
    elevation_ft: "550",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Bewani",
    gps_code: "AYBI",
    iata_code: "BWP",
    local_code: "BEW",
    coordinates: "141.165277778, -3.0216666666700003",
  },
  {
    ident: "BXL",
    type: "seaplane_base",
    name: "Blue Lagoon Seaplane Base",
    elevation_ft: "0",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Nanuya Lailai Island",
    iata_code: "BXL",
    coordinates: "177.368, -16.943",
  },
  {
    ident: "BXZ",
    type: "small_airport",
    name: "Bunsil Airport",
    elevation_ft: "201",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Bunsil - Umboi Island",
    gps_code: "AYNS",
    iata_code: "BXZ",
    local_code: "BSL",
    coordinates: "147.8667, -5.7243",
  },
  {
    ident: "BYA",
    type: "small_airport",
    name: "Boundary Airport",
    elevation_ft: "2940",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Boundary",
    gps_code: "BYA",
    iata_code: "BYA",
    local_code: "BYA",
    coordinates: "-141.113006592, 64.0783004761",
  },
  {
    ident: "BYL",
    type: "small_airport",
    name: "Bella Yella Airport",
    elevation_ft: "690",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-LO",
    municipality: "Beliyela",
    iata_code: "BYL",
    coordinates: "-9.99372222222, 7.36916666667",
  },
  {
    ident: "BYV",
    type: "seaplane_base",
    name: "Beira Lake Seaplane Base",
    elevation_ft: "17",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-1",
    municipality: "Colombo",
    iata_code: "BYV",
    coordinates: "79.85416666670001, 6.9294444444400005",
  },
  {
    ident: "BZ-BCV",
    type: "small_airport",
    name: "Hector Silva Airstrip",
    elevation_ft: "150",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-CY",
    municipality: "Belmopan",
    iata_code: "BCV",
    coordinates: "-88.776496, 17.269603",
  },
  {
    ident: "BZ-BGK",
    type: "medium_airport",
    name: "Big Creek Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-SC",
    municipality: "Big Creek",
    iata_code: "BGK",
    coordinates: "-88.40791320800781, 16.51936912536621",
  },
  {
    ident: "BZ-CUK",
    type: "medium_airport",
    name: "Caye Caulker Airport",
    elevation_ft: "1",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-BZ",
    municipality: "Caye Caulker",
    iata_code: "CUK",
    coordinates: "-88.03250122070312, 17.734699249267578",
  },
  {
    ident: "BZ-CYC",
    type: "medium_airport",
    name: "Caye Chapel Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-BZ",
    municipality: "Caye Chapel",
    iata_code: "CYC",
    coordinates: "-88.04109954833984, 17.7007999420166",
  },
  {
    ident: "BZ-CZH",
    type: "medium_airport",
    name: "Corozal Municipal Airport",
    elevation_ft: "40",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-CZL",
    municipality: "Corozal",
    iata_code: "CZH",
    coordinates: "-88.41190338134766, 18.382200241088867",
  },
  {
    ident: "BZ-DGA",
    type: "medium_airport",
    name: "Dangriga Airport",
    elevation_ft: "508",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-SC",
    municipality: "Dangriga",
    iata_code: "DGA",
    coordinates: "-88.23098754882812, 16.98250961303711",
  },
  {
    ident: "BZ-INB",
    type: "small_airport",
    name: "Independence Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-SC",
    municipality: "Independence",
    iata_code: "INB",
    coordinates: "-88.44129943847656, 16.534500122070312",
  },
  {
    ident: "BZ-MDB",
    type: "small_airport",
    name: "Melinda Airport",
    elevation_ft: "101",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-SC",
    municipality: "Melinda",
    iata_code: "MDB",
    coordinates: "-88.30419921880001, 17.0042991638",
  },
  {
    ident: "BZ-ORZ",
    type: "small_airport",
    name: "Orange Walk Airport",
    elevation_ft: "111",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-OW",
    municipality: "Orange Walk",
    iata_code: "ORZ",
    coordinates: "-88.58386993410001, 18.0467662811",
  },
  {
    ident: "BZ-PLJ",
    type: "medium_airport",
    name: "Placencia Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-SC",
    municipality: "Placencia",
    iata_code: "PLJ",
    coordinates: "-88.36151123046875, 16.536956787109375",
  },
  {
    ident: "BZ-PND",
    type: "medium_airport",
    name: "Punta Gorda Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-TOL",
    municipality: "Punta Gorda",
    iata_code: "PND",
    coordinates: "-88.80829620360001, 16.102399826",
  },
  {
    ident: "BZ-SJX",
    type: "medium_airport",
    name: "Sartaneja Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-CZL",
    municipality: "Sartaneja",
    iata_code: "SJX",
    coordinates: "-88.13079833984375, 18.35610008239746",
  },
  {
    ident: "BZ-SPR",
    type: "medium_airport",
    name: "San Pedro Airport",
    elevation_ft: "4",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-CY",
    municipality: "San Pedro",
    gps_code: "MZSP",
    iata_code: "SPR",
    coordinates: "-87.9711, 17.9139",
  },
  {
    ident: "BZ-SQS",
    type: "small_airport",
    name: "Matthew Spain Airport",
    elevation_ft: "199",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-CY",
    municipality: "San Ignacio",
    gps_code: "MZCF",
    iata_code: "SQS",
    coordinates: "-89.0098, 17.1859",
  },
  {
    ident: "BZ-STU",
    type: "small_airport",
    name: "Santa Cruz Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-CZL",
    municipality: "Santa Cruz",
    iata_code: "STU",
    coordinates: "-88.456298828125, 18.2721004486084",
  },
  {
    ident: "BZ-SVK",
    type: "small_airport",
    name: "Silver Creek Airport",
    elevation_ft: "216",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-SC",
    municipality: "Silver Creek",
    iata_code: "SVK",
    coordinates: "-88.33999633789062, 16.725299835205078",
  },
  {
    ident: "BZ-TZA",
    type: "medium_airport",
    name: "Belize City Municipal Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-BZ",
    municipality: "Belize City",
    iata_code: "TZA",
    coordinates: "-88.19444274902344, 17.516389846801758",
  },
  {
    ident: "BZB",
    type: "medium_airport",
    name: "Bazaruto Island Airport",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-I",
    municipality: "Bazaruto Island",
    iata_code: "BZB",
    coordinates: "35.4729, -21.5411",
  },
  {
    ident: "BZM",
    type: "small_airport",
    name: "Bemolanga Airport",
    elevation_ft: "950",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Bemolanga",
    iata_code: "BZM",
    coordinates: "45.0888333333, -17.6925",
  },
  {
    ident: "BZS",
    type: "closed",
    name: "Buzzards Point Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-DC",
    municipality: "Washington",
    iata_code: "BZS",
    coordinates: "-77.011, 38.863",
  },
  {
    ident: "CA-0053",
    type: "closed",
    name: "Blissville Airport",
    elevation_ft: "60",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NB",
    municipality: "Blissville",
    iata_code: "YYS",
    coordinates: "-66.543678, 45.605633",
  },
  {
    ident: "CA-0095",
    type: "closed",
    name: "Clinton Creek Airport",
    elevation_ft: "2040",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Clinton Creek Mine",
    iata_code: "YLM",
    coordinates: "-140.741748, 64.475545",
  },
  {
    ident: "CA-0112",
    type: "closed",
    name: "Deception Airport",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    iata_code: "YGY",
    coordinates: "-74.554681778, 62.112314446199996",
  },
  {
    ident: "CA-0201",
    type: "closed",
    name: "Lady Franklin Point Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    gps_code: "CYUJ",
    iata_code: "YUJ",
    coordinates: "-113.224411011, 68.4753126151",
  },
  {
    ident: "CA-0213",
    type: "closed",
    name: "Lupin Airport",
    elevation_ft: "1608",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Lupin Mine",
    gps_code: "CYWO",
    iata_code: "YWO",
    coordinates: "-111.25, 65.7666702271",
  },
  {
    ident: "CA-0241",
    type: "closed",
    name: "Moose Lake Airport",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    iata_code: "YAD",
    local_code: "CJB4",
    coordinates: "-100.343971252, 53.7062589637",
  },
  {
    ident: "CA-0245",
    type: "closed",
    name: "Mould Bay Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Prince Patrick Is.",
    iata_code: "YMD",
    local_code: "YMD",
    coordinates: "-119.3216, 76.2392",
  },
  {
    ident: "CA-0274",
    type: "closed",
    name: "Paradise River Airport",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    iata_code: "YDE",
    local_code: "CDF4",
    coordinates: "-57.233333587646, 53.43",
  },
  {
    ident: "CA-0313",
    type: "closed",
    name: "Rivers Airport",
    elevation_ft: "1550",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    iata_code: "YYI",
    coordinates: "-100.313930511, 50.010055763699995",
  },
  {
    ident: "CA-0322",
    type: "closed",
    name: "Saglek Airport",
    elevation_ft: "269",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "RCAF Station Saglek",
    iata_code: "YSV",
    coordinates: "-62.655373, 58.475119",
  },
  {
    ident: "CA-0378",
    type: "closed",
    name: "Terrace Bay Airport",
    elevation_ft: "950",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Terrace Bay, ON",
    gps_code: "CYTJ",
    iata_code: "YTJ",
    coordinates: "-87.09943771360001, 48.81197898719999",
  },
  {
    ident: "CA-0412",
    type: "closed",
    name: "Winisk Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Winisk",
    iata_code: "YWN",
    coordinates: "-85.1174, 55.2242",
  },
  {
    ident: "CA-0446",
    type: "small_airport",
    name: "Stuart Island Airstrip",
    elevation_ft: "360",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Big Bay",
    iata_code: "YRR",
    coordinates: "-125.131616592, 50.4094474336",
  },
  {
    ident: "CA-0504",
    type: "small_airport",
    name: "Mary River Aerodrome",
    elevation_ft: "584",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    iata_code: "YMV",
    local_code: "CMR2",
    coordinates: "-79.3569444444, 71.3241666667",
  },
  {
    ident: "CAB3",
    type: "seaplane_base",
    name: "Bedwell Harbour Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Bedwell Harbour",
    gps_code: "CAB3",
    iata_code: "YBW",
    local_code: "CAB3",
    coordinates: "-123.233001709, 48.75",
  },
  {
    ident: "CAB4",
    type: "seaplane_base",
    name: "Tofino Harbour Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Tofino Harbour",
    gps_code: "CAB4",
    iata_code: "YTP",
    local_code: "CAB4",
    coordinates: "-125.91, 49.155",
  },
  {
    ident: "CAB5",
    type: "closed",
    name: "Bronson Creek Airport",
    elevation_ft: "396",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Bronson Creek",
    iata_code: "YBM",
    coordinates: "-131.087005615, 56.681098938",
  },
  {
    ident: "CAC3",
    type: "closed",
    name: "Alice Arm/Silver City Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    gps_code: "CAC3",
    iata_code: "ZAA",
    local_code: "CAC3",
    coordinates: "-129.483001709, 55.4667015076",
  },
  {
    ident: "CAC8",
    type: "seaplane_base",
    name: "Nanaimo Harbour Water Airport",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Nanaimo",
    gps_code: "CAC8",
    iata_code: "ZNA",
    local_code: "CAC8",
    coordinates: "-123.949996948, 49.1833000183",
  },
  {
    ident: "CAD4",
    type: "small_airport",
    name: "Trail Airport",
    elevation_ft: "1427",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Trail",
    gps_code: "CAD4",
    iata_code: "YZZ",
    local_code: "CAD4",
    coordinates: "-117.60900116, 49.0555992126",
  },
  {
    ident: "CAD5",
    type: "small_airport",
    name: "Merritt Airport",
    elevation_ft: "2080",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Merritt",
    gps_code: "CAD5",
    iata_code: "YMB",
    local_code: "CAD5",
    coordinates: "-120.747001648, 50.122798919699996",
  },
  {
    ident: "CAE3",
    type: "seaplane_base",
    name: "Campbell River Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Campbell River",
    gps_code: "CAE3",
    iata_code: "YHH",
    local_code: "CAE3",
    coordinates: "-125.25, 50.049999",
  },
  {
    ident: "CAE5",
    type: "seaplane_base",
    name: "Whistler/Green Lake Water Aerodrome",
    elevation_ft: "2100",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Whistler",
    gps_code: "CAE5",
    iata_code: "YWS",
    local_code: "CAE5",
    coordinates: "-122.948997498, 50.1436004639",
  },
  {
    ident: "CAE9",
    type: "seaplane_base",
    name: "Bamfield Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Bamfield",
    gps_code: "CAE9",
    iata_code: "YBF",
    local_code: "CAE9",
    coordinates: "-125.133003235, 48.8333015442",
  },
  {
    ident: "CAF6",
    type: "seaplane_base",
    name: "Big Bay Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Stuart Island",
    gps_code: "CAF6",
    iata_code: "YIG",
    local_code: "CAF6",
    coordinates: "-125.1372, 50.3923",
  },
  {
    ident: "CAG3",
    type: "small_airport",
    name: "Chilko Lake (Tsylos Park Lodge) Airport",
    elevation_ft: "3850",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Chilko Lake",
    gps_code: "CAG3",
    iata_code: "CJH",
    local_code: "CAG3",
    coordinates: "-124.141998291, 51.626098632799994",
  },
  {
    ident: "CAG8",
    type: "seaplane_base",
    name: "Pender Harbour Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    gps_code: "CAG8",
    iata_code: "YPT",
    local_code: "CAG8",
    coordinates: "-124.024884, 49.623785",
  },
  {
    ident: "CAH2",
    type: "seaplane_base",
    name: "Ocean Falls Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Ocean Falls",
    gps_code: "CAH2",
    iata_code: "ZOF",
    local_code: "CAH2",
    coordinates: "-127.717002869, 52.3666992188",
  },
  {
    ident: "CAH3",
    type: "small_airport",
    name: "Courtenay Airpark",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Courtenay",
    gps_code: "CAH3",
    iata_code: "YCA",
    local_code: "CAH3",
    coordinates: "-124.9820022583, 49.679401397705",
  },
  {
    ident: "CAJ3",
    type: "small_airport",
    name: "Creston Valley Regional Airport - Art Sutcliffe Field",
    elevation_ft: "2070",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Creston",
    gps_code: "CAJ3",
    iata_code: "CFQ",
    local_code: "CAJ3",
    coordinates: "-116.49800109863, 49.03689956665",
  },
  {
    ident: "CAJ4",
    type: "small_airport",
    name: "Anahim Lake Airport",
    elevation_ft: "3635",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Anahim Lake",
    gps_code: "CAJ4",
    iata_code: "YAA",
    local_code: "CAJ4",
    coordinates: "-125.3030014038086, 52.45249938964844",
  },
  {
    ident: "CAL3",
    type: "small_airport",
    name: "Douglas Lake Airport",
    elevation_ft: "2770",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Douglas Lake",
    gps_code: "CAL3",
    iata_code: "DGF",
    local_code: "CAL3",
    coordinates: "-120.171273351, 50.1654600485",
  },
  {
    ident: "CAL4",
    type: "small_airport",
    name: "Fort MacKay/Albian Aerodrome",
    elevation_ft: "1048",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Albian Village",
    gps_code: "CAL4",
    iata_code: "JHL",
    local_code: "CAL4",
    coordinates: "-111.418998718, 57.223899841299996",
  },
  {
    ident: "CAL9",
    type: "seaplane_base",
    name: "Tahsis Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Tahsis",
    gps_code: "CAL9",
    iata_code: "ZTS",
    local_code: "CAL9",
    coordinates: "-126.666999817, 49.916698455799995",
  },
  {
    ident: "CAM3",
    type: "small_airport",
    name: "Duncan Airport",
    elevation_ft: "300",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Duncan",
    gps_code: "CAM3",
    iata_code: "DUQ",
    local_code: "CAM3",
    coordinates: "-123.709702492, 48.7545336204",
  },
  {
    ident: "CAN8",
    type: "closed",
    name: "Port Simpson Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Lax Kw'alaams",
    gps_code: "CAN8",
    iata_code: "YPI",
    local_code: "CAN8",
    coordinates: "-130.432999, 54.5667",
  },
  {
    ident: "CAP3",
    type: "small_airport",
    name: "Sechelt-Gibsons Airport",
    elevation_ft: "340",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Sechelt",
    gps_code: "CAP3",
    iata_code: "YHS",
    local_code: "CAP3",
    coordinates: "-123.71900177, 49.460601806599996",
  },
  {
    ident: "CAP7",
    type: "seaplane_base",
    name: "Kitkatla Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Kitkatla",
    gps_code: "CAP7",
    iata_code: "YKK",
    local_code: "CAP7",
    coordinates: "-130.432998657, 53.7999992371",
  },
  {
    ident: "CAQ8",
    type: "seaplane_base",
    name: "Powell Lake Seaplane Base",
    elevation_ft: "183",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    gps_code: "CAQ8",
    iata_code: "WPL",
    local_code: "CAQ8",
    coordinates: "-124.532997131, 49.8833007812",
  },
  {
    ident: "CAT4",
    type: "small_airport",
    name: "Qualicum Beach Airport",
    elevation_ft: "191",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Qualicum Beach",
    gps_code: "CAT4",
    iata_code: "XQU",
    local_code: "CAT4",
    coordinates: "-124.393997, 49.3372",
  },
  {
    ident: "CAT5",
    type: "small_airport",
    name: "Port Mcneill Airport",
    elevation_ft: "225",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Port Mcneill",
    gps_code: "CAT5",
    iata_code: "YMP",
    local_code: "CAT5",
    coordinates: "-127.028999329, 50.5755996704",
  },
  {
    ident: "CAU8",
    type: "seaplane_base",
    name: "Rivers Inlet Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Rivers Inlet",
    gps_code: "CAU8",
    iata_code: "YRN",
    local_code: "CAU8",
    coordinates: "-127.264044, 51.683985",
  },
  {
    ident: "CAV5",
    type: "seaplane_base",
    name: "Sullivan Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Sullivan Bay",
    gps_code: "CAV5",
    iata_code: "YTG",
    local_code: "CAV5",
    coordinates: "-126.831069, 50.885359",
  },
  {
    ident: "CAV7",
    type: "seaplane_base",
    name: "Mansons Landing Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    gps_code: "CAV7",
    iata_code: "YMU",
    local_code: "CAV7",
    coordinates: "-124.983001709, 50.0666999817",
  },
  {
    ident: "CAW7",
    type: "closed",
    name: "Mayne Island Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Miners Bay",
    gps_code: "CAW7",
    iata_code: "YAV",
    local_code: "CAW7",
    coordinates: "-123.300003052, 48.8666992188",
  },
  {
    ident: "CAW8",
    type: "seaplane_base",
    name: "Bella Bella/Shearwater Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Bella Bella",
    gps_code: "CAW8",
    iata_code: "YSX",
    local_code: "CAW8",
    coordinates: "-128.08299, 52.150002",
  },
  {
    ident: "CAX6",
    type: "seaplane_base",
    name: "Ganges Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Salt Spring Island",
    gps_code: "CAX6",
    iata_code: "YGG",
    local_code: "CAX6",
    coordinates: "-123.4969, 48.8545",
  },
  {
    ident: "CAY4",
    type: "seaplane_base",
    name: "Hartley Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Hartley Bay",
    gps_code: "CAY4",
    iata_code: "YTB",
    local_code: "CAY4",
    coordinates: "-129.25, 53.416698",
  },
  {
    ident: "CAZ5",
    type: "small_airport",
    name: "Cache Creek-Ashcroft Regional Airport",
    elevation_ft: "2034",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Cache Creek",
    gps_code: "CAZ5",
    iata_code: "YZA",
    local_code: "CAZ5",
    coordinates: "-121.321314, 50.775258",
  },
  {
    ident: "CBBC",
    type: "small_airport",
    name: "Bella Bella (Campbell Island) Airport",
    elevation_ft: "141",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Bella Bella",
    gps_code: "CBBC",
    iata_code: "ZEL",
    local_code: "CBBC",
    coordinates: "-128.156994, 52.185001",
  },
  {
    ident: "CBC",
    type: "small_airport",
    name: "Cherrabun Airport",
    elevation_ft: "540",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    iata_code: "CBC",
    coordinates: "125.537777778, -18.917777777800005",
  },
  {
    ident: "CBM5",
    type: "closed",
    name: "Telegraph Creek Airport",
    elevation_ft: "1100",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Telegraph Creek",
    gps_code: "CBM5",
    iata_code: "YTX",
    local_code: "CBM5",
    coordinates: "-131.117007, 57.916698",
  },
  {
    ident: "CBS8",
    type: "small_airport",
    name: "Alberni Valley Regional Airport",
    elevation_ft: "250",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Port Alberni",
    gps_code: "CBS8",
    iata_code: "YPB",
    local_code: "CBS8",
    coordinates: "-124.931, 49.321899",
  },
  {
    ident: "CBW4",
    type: "small_airport",
    name: "Bob Quinn Lake Airport",
    elevation_ft: "2000",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Bob Quinn Lake",
    gps_code: "CBW4",
    iata_code: "YBO",
    local_code: "CBW4",
    coordinates: "-130.25, 56.9667015076",
  },
  {
    ident: "CBX5",
    type: "small_airport",
    name: "Tungsten (Cantung) Airport",
    elevation_ft: "3500",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Tungsten",
    gps_code: "CBX5",
    iata_code: "TNS",
    local_code: "CBX5",
    coordinates: "-128.20300292969, 61.956901550293",
  },
  {
    ident: "CBX7",
    type: "small_airport",
    name: "Tumbler Ridge Airport",
    elevation_ft: "3075",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Tumbler Ridge",
    gps_code: "CBX7",
    iata_code: "TUX",
    local_code: "CBX7",
    coordinates: "-120.93499755859, 55.025001525879",
  },
  {
    ident: "CCA6",
    type: "small_airport",
    name: "Williams Harbour Airport",
    elevation_ft: "70",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Williams Harbour",
    gps_code: "CCA6",
    iata_code: "YWM",
    local_code: "CCA6",
    coordinates: "-55.784698486328125, 52.566898345947266",
  },
  {
    ident: "CCD4",
    type: "small_airport",
    name: "Postville Airport",
    elevation_ft: "193",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Postville",
    gps_code: "CCD4",
    iata_code: "YSO",
    local_code: "CCD4",
    coordinates: "-59.78507, 54.9105",
  },
  {
    ident: "CCE4",
    type: "small_airport",
    name: "Black Tickle Airport",
    elevation_ft: "57",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Black Tickle",
    gps_code: "CCE4",
    iata_code: "YBI",
    local_code: "CCE4",
    coordinates: "-55.784999847399995, 53.4693984985",
  },
  {
    ident: "CCK4",
    type: "small_airport",
    name: "St. Lewis (Fox Harbour) Airport",
    elevation_ft: "74",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "St. Lewis",
    gps_code: "CCK4",
    iata_code: "YFX",
    local_code: "CCK4",
    coordinates: "-55.67390060424805, 52.372798919677734",
  },
  {
    ident: "CCP4",
    type: "small_airport",
    name: "Port Hope Simpson Airport",
    elevation_ft: "347",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Port Hope Simpson",
    gps_code: "CCP4",
    iata_code: "YHA",
    local_code: "CCP4",
    coordinates: "-56.28609848022461, 52.528099060058594",
  },
  {
    ident: "CCZ2",
    type: "small_airport",
    name: "Rigolet Airport",
    elevation_ft: "180",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Rigolet",
    gps_code: "CCZ2",
    iata_code: "YRG",
    local_code: "CCZ2",
    coordinates: "-58.45750045776367, 54.1796989440918",
  },
  {
    ident: "CDK2",
    type: "small_airport",
    name: "Diavik Airport",
    elevation_ft: "1413",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Diavik",
    gps_code: "CDK2",
    iata_code: "DVK",
    local_code: "CDK2",
    coordinates: "-110.289001465, 64.5113983154",
  },
  {
    ident: "CDL7",
    type: "small_airport",
    name: "Doris Lake",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Hope Bay",
    iata_code: "JOJ",
    local_code: "CDL7",
    coordinates: "-106.585281372, 68.1252746582",
  },
  {
    ident: "CEB5",
    type: "small_airport",
    name: "Fairview Airport",
    elevation_ft: "2166",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Fairview",
    gps_code: "CEB5",
    iata_code: "ZFW",
    local_code: "CEB5",
    coordinates: "-118.434997559, 56.081401825",
  },
  {
    ident: "CEC4",
    type: "small_airport",
    name: "Hinton/Jasper-Hinton Airport",
    elevation_ft: "4006",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Hinton",
    gps_code: "CEC4",
    iata_code: "YJP",
    local_code: "CEC4",
    coordinates: "-117.752998352, 53.3191986084",
  },
  {
    ident: "CEM3",
    type: "small_airport",
    name: "What���� Airport",
    elevation_ft: "882",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "What����",
    gps_code: "CEM3",
    iata_code: "YLE",
    local_code: "CEM3",
    coordinates: "-117.24600219726562, 63.13169860839844",
  },
  {
    ident: "CEQ5",
    type: "small_airport",
    name: "Grande Cache Airport",
    elevation_ft: "4117",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Grande Cache",
    gps_code: "CEQ5",
    iata_code: "YGC",
    local_code: "CEQ5",
    coordinates: "-118.87400054932, 53.916900634766",
  },
  {
    ident: "CER3",
    type: "small_airport",
    name: "Drayton Valley Industrial Airport",
    elevation_ft: "2776",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Drayton Valley",
    gps_code: "CER3",
    iata_code: "YDC",
    local_code: "CER3",
    coordinates: "-114.95999908447, 53.265800476074",
  },
  {
    ident: "CER4",
    type: "small_airport",
    name: "Fort McMurray / Mildred Lake Airport",
    elevation_ft: "1046",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Fort McMurray",
    gps_code: "CER4",
    iata_code: "NML",
    local_code: "CER4",
    coordinates: "-111.573997498, 57.0555992126",
  },
  {
    ident: "CET2",
    type: "small_airport",
    name: "Conklin (Leismer) Airport",
    elevation_ft: "1930",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Conklin",
    gps_code: "CET2",
    iata_code: "CFM",
    local_code: "CET2",
    coordinates: "-111.278999, 55.695301",
  },
  {
    ident: "CEW3",
    type: "small_airport",
    name: "St. Paul Airport",
    elevation_ft: "2147",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "St. Paul",
    gps_code: "CEW3",
    iata_code: "ZSP",
    local_code: "CEW3",
    coordinates: "-111.379997253, 53.9933013916",
  },
  {
    ident: "CFA7",
    type: "small_airport",
    name: "Taltheilei Narrows Airport",
    elevation_ft: "570",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Taltheilei Narrows",
    gps_code: "CFA7",
    iata_code: "GSL",
    local_code: "CFA7",
    coordinates: "-111.542999268, 62.5980987549",
  },
  {
    ident: "CFC4",
    type: "small_airport",
    name: "Macmillan Pass Airport",
    elevation_ft: "3810",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Macmillan Pass",
    gps_code: "CFC4",
    iata_code: "XMP",
    local_code: "CFC4",
    coordinates: "-130.20199585, 63.181098938",
  },
  {
    ident: "CFF4",
    type: "small_airport",
    name: "Great Bear Lake Airport",
    elevation_ft: "562",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Great Bear Lake",
    gps_code: "CFF4",
    iata_code: "DAS",
    local_code: "CFF4",
    coordinates: "-119.707000732, 66.7031021118",
  },
  {
    ident: "CFG6",
    type: "small_airport",
    name: "Fort Mackay / Firebag",
    elevation_ft: "1762",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Suncor Energy Site",
    gps_code: "CYFI",
    iata_code: "YFI",
    local_code: "CYFI",
    coordinates: "-110.976666, 57.275833",
  },
  {
    ident: "CFJ2",
    type: "small_airport",
    name: "Wekwe����t���� Airport",
    elevation_ft: "1208",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Wekwe����t����",
    gps_code: "CYWE",
    iata_code: "YFJ",
    local_code: "CFJ7",
    coordinates: "-114.077002, 64.190804",
  },
  {
    ident: "CFM4",
    type: "small_airport",
    name: "Donnelly Airport",
    elevation_ft: "1949",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Donnelly",
    gps_code: "CFM4",
    iata_code: "YOE",
    local_code: "CFM4",
    coordinates: "-117.094002, 55.7094",
  },
  {
    ident: "CFQ4",
    type: "small_airport",
    name: "Cheadle Airport",
    elevation_ft: "3300",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Cheadle",
    gps_code: "CFQ4",
    iata_code: "TIL",
    local_code: "CFQ4",
    coordinates: "-113.62400054932, 51.057498931885",
  },
  {
    ident: "CG-OKG",
    type: "small_airport",
    name: "Okoyo Airport",
    elevation_ft: "1415",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-8",
    municipality: "Okoyo",
    iata_code: "OKG",
    coordinates: "15.073300361633, -1.4483300447464",
  },
  {
    ident: "CGA",
    type: "seaplane_base",
    name: "Craig Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Craig",
    gps_code: "CGA",
    iata_code: "CGA",
    local_code: "CGA",
    coordinates: "-133.1479949951172, 55.47880172729492",
  },
  {
    ident: "CGC",
    type: "small_airport",
    name: "Cape Gloucester Airport",
    elevation_ft: "78",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Cape Gloucester",
    gps_code: "AYCG",
    iata_code: "CGC",
    local_code: "CPG",
    coordinates: "148.432417, -5.458965",
  },
  {
    ident: "CGG",
    type: "small_airport",
    name: "Casiguran Airport",
    elevation_ft: "48",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-AUR",
    municipality: "Casiguran",
    iata_code: "CGG",
    coordinates: "122.0651, 16.1941",
  },
  {
    ident: "CGK2",
    type: "small_airport",
    name: "Gahcho Kue",
    elevation_ft: "1371",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    gps_code: "CGK2",
    iata_code: "GHK",
    local_code: "CGK2",
    coordinates: "-109.144812, 63.435198",
  },
  {
    ident: "CGT",
    type: "closed",
    name: "Chinguetti Airport",
    elevation_ft: "1775",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-07",
    municipality: "Chinguetti",
    iata_code: "CGT",
    coordinates: "-12.3978, 20.5055",
  },
  {
    ident: "CHP",
    type: "small_airport",
    name: "Circle Hot Springs Airport",
    elevation_ft: "956",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Circle Hot Springs",
    gps_code: "CHP",
    iata_code: "CHP",
    local_code: "CHP",
    coordinates: "-144.610992432, 65.485496521",
  },
  {
    ident: "CIV",
    type: "seaplane_base",
    name: "Chomley Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chomley",
    iata_code: "CIV",
    coordinates: "-132.21, 55.217",
  },
  {
    ident: "CJC8",
    type: "small_airport",
    name: "Laurie River Airport",
    elevation_ft: "1200",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Laurie River",
    gps_code: "CJC8",
    iata_code: "LRQ",
    local_code: "CJC8",
    coordinates: "-101.304000854, 56.2486000061",
  },
  {
    ident: "CJL2",
    type: "small_airport",
    name: "Hatchet Lake Airport",
    elevation_ft: "1362",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Hatchet Lake",
    gps_code: "CJL2",
    iata_code: "YDJ",
    local_code: "CJL2",
    coordinates: "-103.53800201416, 58.662498474121",
  },
  {
    ident: "CJL8",
    type: "small_airport",
    name: "Kasba Lake Airport",
    elevation_ft: "1131",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Kasba Lake",
    gps_code: "CJL8",
    iata_code: "YDU",
    local_code: "CJL8",
    coordinates: "-102.50199890137, 60.291900634766",
  },
  {
    ident: "CJN8",
    type: "seaplane_base",
    name: "Fort Reliance Seaplane Base",
    elevation_ft: "514",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Fort Reliance",
    gps_code: "CJN8",
    iata_code: "YFL",
    local_code: "CJN8",
    coordinates: "-109.167, 62.7",
  },
  {
    ident: "CJS3",
    type: "small_airport",
    name: "Cluff Lake Airport",
    elevation_ft: "1112",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Cluff Lake",
    gps_code: "CJS3",
    iata_code: "XCL",
    local_code: "CJS3",
    coordinates: "-109.51599884, 58.3911018372",
  },
  {
    ident: "CJT3",
    type: "small_airport",
    name: "Knee Lake Airport",
    elevation_ft: "625",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Knee Lake",
    gps_code: "CJT3",
    iata_code: "YKE",
    local_code: "CJT3",
    coordinates: "-94.798103, 54.915298",
  },
  {
    ident: "CJV7",
    type: "small_airport",
    name: "Summer Beaver Airport",
    elevation_ft: "832",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Summer Beaver",
    gps_code: "CJV7",
    iata_code: "SUR",
    local_code: "CJV7",
    coordinates: "-88.54190063476562, 52.70859909057617",
  },
  {
    ident: "CJX",
    type: "small_airport",
    name: "Crooked Creek Airport",
    elevation_ft: "178",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Crooked Creek",
    gps_code: "CJX",
    iata_code: "CKD",
    local_code: "CJX",
    coordinates: "-158.134994507, 61.8679008484",
  },
  {
    ident: "CJY3",
    type: "small_airport",
    name: "Tisdale Airport",
    elevation_ft: "1525",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Tisdale",
    gps_code: "CJY3",
    iata_code: "YTT",
    local_code: "CJY3",
    coordinates: "-104.06700134277, 52.836700439453",
  },
  {
    ident: "CKB6",
    type: "small_airport",
    name: "Wapekeka Airport",
    elevation_ft: "712",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Angling Lake",
    gps_code: "CKB6",
    iata_code: "YAX",
    local_code: "CKB6",
    coordinates: "-89.57939910888672, 53.84920120239258",
  },
  {
    ident: "CKL3",
    type: "small_airport",
    name: "Wunnumin Lake Airport",
    elevation_ft: "819",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Wunnumin Lake",
    gps_code: "CKL3",
    iata_code: "WNN",
    local_code: "CKL3",
    coordinates: "-89.28919982910156, 52.89390182495117",
  },
  {
    ident: "CKM8",
    type: "small_airport",
    name: "Opapimiskan Lake Airport",
    elevation_ft: "1023",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Opapimiskan Lake",
    gps_code: "CKM8",
    iata_code: "YBS",
    local_code: "CKM8",
    coordinates: "-90.3768997192, 52.6067008972",
  },
  {
    ident: "CKQ3",
    type: "small_airport",
    name: "North Spirit Lake Airport",
    elevation_ft: "1082",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "North Spirit Lake",
    gps_code: "CKQ3",
    iata_code: "YNO",
    local_code: "CKQ3",
    coordinates: "-92.97109985351562, 52.4900016784668",
  },
  {
    ident: "CKR",
    type: "small_airport",
    name: "Crane Island Airstrip",
    elevation_ft: "108",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Crane Island",
    iata_code: "CKR",
    coordinates: "-122.9979, 48.5978",
  },
  {
    ident: "CKU",
    type: "small_airport",
    name: "Cordova Municipal Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Cordova",
    gps_code: "CKU",
    iata_code: "CKU",
    local_code: "CKU",
    coordinates: "-145.727005005, 60.5438995361",
  },
  {
    ident: "CKV4",
    type: "small_airport",
    name: "North of Sixty Airport",
    elevation_ft: "1195",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Obre Lake",
    gps_code: "CKV4",
    iata_code: "YDW",
    local_code: "CKV4",
    coordinates: "-103.12899780273, 60.316398620605",
  },
  {
    ident: "CKX",
    type: "small_airport",
    name: "Chicken Airport",
    elevation_ft: "1640",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chicken",
    gps_code: "CKX",
    iata_code: "CKX",
    local_code: "CKX",
    coordinates: "-141.95199585, 64.0712966919",
  },
  {
    ident: "CLC",
    type: "closed",
    name: "Clear Lake Metroport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Clear Lake",
    iata_code: "CLC",
    coordinates: "-95.1383, 29.5555",
  },
  {
    ident: "CLG",
    type: "closed",
    name: "Coalinga Airport",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    iata_code: "CLG",
    coordinates: "-120.360116959, 36.1580433385",
  },
  {
    ident: "CMT",
    type: "small_airport",
    name: "New Camet���� Airport",
    elevation_ft: "60",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Camet����",
    iata_code: "CMT",
    coordinates: "-49.56, -2.2468",
  },
  {
    ident: "CMZ",
    type: "small_airport",
    name: "Caia Airport",
    elevation_ft: "156",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-S",
    municipality: "Caia",
    iata_code: "CMZ",
    coordinates: "35.3341, -17.8331",
  },
  {
    ident: "CN-0005",
    type: "small_airport",
    name: "Tangshan Sann����he Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-13",
    municipality: "Tangshan",
    gps_code: "ZBTS",
    iata_code: "TVS",
    coordinates: "118.002624512, 39.7178001404",
  },
  {
    ident: "CN-0010",
    type: "medium_airport",
    name: "Yinchuan Hedong International Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-64",
    municipality: "Yinchuan",
    gps_code: "ZLIC",
    iata_code: "INC",
    coordinates: "106.393214, 38.322758",
  },
  {
    ident: "CN-0011",
    type: "medium_airport",
    name: "Duplicate --Yantai Penglai International Airport",
    elevation_ft: "154",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-U-A",
    municipality: "Yantai",
    gps_code: "ZSYT",
    iata_code: "YNT",
    coordinates: "120.9872, 37.65722",
  },
  {
    ident: "CN-0012",
    type: "medium_airport",
    name: "Yuanmou Air Base",
    elevation_ft: "3810",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Yuanmou",
    gps_code: "ZPYM",
    iata_code: "YUA",
    coordinates: "101.88200378418, 25.737499237061",
  },
  {
    ident: "CN-0024",
    type: "medium_airport",
    name: "Zhangjiakou Ningyuan Airport",
    elevation_ft: "2347",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-13",
    municipality: "Zhangjiakou",
    gps_code: "ZBZJ",
    iata_code: "ZQZ",
    coordinates: "114.930000305, 40.7386016846",
  },
  {
    ident: "CN-0027",
    type: "medium_airport",
    name: "Baoshan Yunduan Airport",
    elevation_ft: "5453",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    gps_code: "ZPBS",
    iata_code: "BSD",
    coordinates: "99.168297, 25.053301",
  },
  {
    ident: "CN-0034",
    type: "small_airport",
    name: "Dazu Air Base",
    elevation_ft: "1220",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-50",
    municipality: "Dazu",
    gps_code: "ZUDZ",
    iata_code: "DZU",
    coordinates: "105.773621, 29.636227",
  },
  {
    ident: "CN-0040",
    type: "medium_airport",
    name: "Lintsang Airfield",
    elevation_ft: "6230",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Lincang",
    gps_code: "ZPLC",
    iata_code: "LNJ",
    coordinates: "100.025001526, 23.738100051900002",
  },
  {
    ident: "CN-0046",
    type: "medium_airport",
    name: "Shigatse Air Base",
    elevation_ft: "3782",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-54",
    municipality: "Xigaz����",
    gps_code: "ZURK",
    iata_code: "RKZ",
    coordinates: "89.311401, 29.3519",
  },
  {
    ident: "CN-0050",
    type: "medium_airport",
    name: "Bao'anying Airport",
    elevation_ft: "1620",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Panzhihua",
    gps_code: "ZUZH",
    iata_code: "PZI",
    coordinates: "101.79852, 26.54",
  },
  {
    ident: "CN-0051",
    type: "small_airport",
    name: "Zigong Airport",
    elevation_ft: "1133",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Zigong",
    iata_code: "ZKL",
    coordinates: "104.625789, 29.376484",
  },
  {
    ident: "CN-0061",
    type: "medium_airport",
    name: "Foshan Shadi Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Foshan",
    gps_code: "ZGFS",
    iata_code: "FUO",
    coordinates: "113.069999695, 23.083299636799996",
  },
  {
    ident: "CN-0062",
    type: "small_airport",
    name: "Bazhong Enyang Airport",
    elevation_ft: "1804",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Bazhong",
    iata_code: "BZX",
    coordinates: "106.644872, 31.73842",
  },
  {
    ident: "CN-0067",
    type: "medium_airport",
    name: "Xinyang Minggang Airport",
    elevation_ft: "312",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-41",
    municipality: "Xinyang",
    gps_code: "ZHXY",
    iata_code: "XAI",
    coordinates: "114.079141, 32.540819",
  },
  {
    ident: "CN-0068",
    type: "medium_airport",
    name: "Huizhou Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Huizhou",
    gps_code: "ZGHZ",
    iata_code: "HUZ",
    coordinates: "114.599998474, 23.0499992371",
  },
  {
    ident: "CN-0079",
    type: "medium_airport",
    name: "Shaoguan Guitou Airport",
    elevation_ft: "280",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Shaoguan",
    iata_code: "HSC",
    coordinates: "113.42099762, 24.9785995483",
  },
  {
    ident: "CN-0081",
    type: "medium_airport",
    name: "Jinggangshan Airport",
    elevation_ft: "281",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-36",
    municipality: "Ji'an",
    gps_code: "ZSJA",
    iata_code: "JGS",
    coordinates: "114.736999512, 26.856899261499997",
  },
  {
    ident: "CN-0082",
    type: "medium_airport",
    name: "Baise Youjiang Airport",
    elevation_ft: "490",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-45",
    municipality: "Baise",
    gps_code: "ZGBS",
    iata_code: "AEB",
    coordinates: "106.959999084, 23.7206001282",
  },
  {
    ident: "CN-0091",
    type: "medium_airport",
    name: "Dongying Shengli Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-37",
    municipality: "Dongying",
    gps_code: "ZSDY",
    iata_code: "DOY",
    coordinates: "118.788002014, 37.5085983276",
  },
  {
    ident: "CN-0104",
    type: "medium_airport",
    name: "Xingcheng Air Base",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-21",
    gps_code: "ZYXC",
    iata_code: "XEN",
    coordinates: "120.697998047, 40.580299377399996",
  },
  {
    ident: "CN-0113",
    type: "medium_airport",
    name: "Altay Air Base",
    elevation_ft: "2460",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Altay",
    gps_code: "ZWAT",
    iata_code: "AAT",
    coordinates: "88.08580780030002, 47.7498855591",
  },
  {
    ident: "CN-0130",
    type: "medium_airport",
    name: "Tianshui Maijishan Airport",
    elevation_ft: "3590",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-62",
    municipality: "Tianshui",
    gps_code: "ZLTS",
    iata_code: "THQ",
    coordinates: "105.86000061, 34.5593986511",
  },
  {
    ident: "CN-0143",
    type: "medium_airport",
    name: "Zhangye Ganzhou Airport",
    elevation_ft: "5280",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-62",
    municipality: "Zhangye",
    gps_code: "ZLZY",
    iata_code: "YZY",
    coordinates: "100.675003052, 38.8018989563",
  },
  {
    ident: "CN-0189",
    type: "medium_airport",
    name: "Dandong Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-21",
    municipality: "Dandong",
    gps_code: "ZYDD",
    iata_code: "DDG",
    coordinates: "124.286003, 40.0247",
  },
  {
    ident: "CN-NTG",
    type: "small_airport",
    name: "Nantong Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Nantong",
    gps_code: "ZSNT",
    iata_code: "NTG",
    coordinates: "120.975997925, 32.0708007812",
  },
  {
    ident: "CNE3",
    type: "small_airport",
    name: "Bearskin Lake Airport",
    elevation_ft: "800",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Bearskin Lake",
    gps_code: "CNE3",
    iata_code: "XBE",
    local_code: "CNE3",
    coordinates: "-91.0271987915039, 53.965599060058594",
  },
  {
    ident: "CNH2",
    type: "small_airport",
    name: "Natuashish Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Natuashish",
    gps_code: "CNH2",
    iata_code: "YNP",
    local_code: "CNH2",
    coordinates: "-61.184399, 55.913898",
  },
  {
    ident: "CNJ8",
    type: "seaplane_base",
    name: "White River Seaplane Base",
    elevation_ft: "1380",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "White River",
    gps_code: "CNJ8",
    iata_code: "YWR",
    local_code: "CNJ8",
    coordinates: "-85.223297, 48.6269",
  },
  {
    ident: "CNK4",
    type: "small_airport",
    name: "Parry Sound Area Municipal Airport",
    elevation_ft: "832",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Parry Sound",
    gps_code: "CNK4",
    iata_code: "YPD",
    local_code: "CNK4",
    coordinates: "-79.829697, 45.2575",
  },
  {
    ident: "CNL3",
    type: "small_airport",
    name: "Brockville - Thousand Islands Regional Tackaberry Airport",
    elevation_ft: "402",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Brockville",
    gps_code: "CNL3",
    iata_code: "XBR",
    local_code: "CNL3",
    coordinates: "-75.7502975464, 44.6394004822",
  },
  {
    ident: "CNM5",
    type: "small_airport",
    name: "Kingfisher Lake Airport",
    elevation_ft: "866",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Kingfisher Lake",
    gps_code: "CNM5",
    iata_code: "KIF",
    local_code: "CNM5",
    coordinates: "-89.85530090332031, 53.01250076293945",
  },
  {
    ident: "CNT3",
    type: "small_airport",
    name: "Ogoki Post Airport",
    elevation_ft: "594",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Ogoki Post",
    gps_code: "CYKP",
    iata_code: "YOG",
    local_code: "CYKP",
    coordinates: "-85.9017028809, 51.6585998535",
  },
  {
    ident: "CO-0039",
    type: "heliport",
    name: "Proma Heliport",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CUN",
    municipality: "Bogota",
    iata_code: "PRM",
    coordinates: "-74.084444, 4.728056",
  },
  {
    ident: "CO-ARQ",
    type: "small_airport",
    name: "El Troncal Airport",
    elevation_ft: "512",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ARA",
    municipality: "Arauquita",
    gps_code: "SKAT",
    iata_code: "ARQ",
    coordinates: "-71.388901, 7.02106",
  },
  {
    ident: "CO-LCR",
    type: "small_airport",
    name: "La Chorrera Airport",
    elevation_ft: "709",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-AMA",
    municipality: "La Chorrera",
    iata_code: "LCR",
    coordinates: "-73.01667, -0.733333",
  },
  {
    ident: "CO-SNT",
    type: "small_airport",
    name: "Las Cruces Airport",
    elevation_ft: "440",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SAN",
    municipality: "Sabana De Torres",
    iata_code: "SNT",
    coordinates: "-73.505402, 7.38322",
  },
  {
    ident: "CO-TCD",
    type: "small_airport",
    name: "Tarapac���� Airport",
    elevation_ft: "253",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-AMA",
    municipality: "Tarapac����",
    gps_code: "SKRA",
    iata_code: "TCD",
    coordinates: "-69.747222, -2.894722",
  },
  {
    ident: "CPF2",
    type: "small_airport",
    name: "Bar River Airport",
    elevation_ft: "591",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Bar River",
    gps_code: "CPF2",
    iata_code: "YEB",
    local_code: "CPF2",
    coordinates: "-84.0922012329, 46.42029953",
  },
  {
    ident: "CPI",
    type: "small_airport",
    name: "Cape Orford Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    iata_code: "CPI",
    local_code: "COF",
    coordinates: "152.081944444, -5.44833333333",
  },
  {
    ident: "CPS9",
    type: "seaplane_base",
    name: "Parry Sound/Frying Pan Island-Sans Souci Seaplane Base",
    elevation_ft: "580",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Frying Pan Island",
    gps_code: "CPS9",
    iata_code: "YSI",
    local_code: "CPS9",
    coordinates: "-80.137497, 45.173302",
  },
  {
    ident: "CPV7",
    type: "small_airport",
    name: "Poplar Hill Airport",
    elevation_ft: "1095",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Poplar Hill",
    gps_code: "CYHP",
    iata_code: "YHP",
    local_code: "CPV7",
    coordinates: "-94.2556, 52.1133",
  },
  {
    ident: "CPV8",
    type: "small_airport",
    name: "Keewaywin Airport",
    elevation_ft: "988",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Keewaywin",
    gps_code: "CPV8",
    iata_code: "KEW",
    local_code: "CPV8",
    coordinates: "-92.8364028931, 52.991100311299995",
  },
  {
    ident: "CSB2",
    type: "small_airport",
    name: "Sable Island Landing Strip",
    elevation_ft: "4",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NS",
    municipality: "Sable Island",
    gps_code: "CSB2",
    iata_code: "YSA",
    local_code: "CSB2",
    coordinates: "-59.960289, 43.930292",
  },
  {
    ident: "CSH4",
    type: "small_airport",
    name: "Lebel-sur-Quevillon Airport",
    elevation_ft: "960",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Lebel-sur-Qu����villon",
    gps_code: "CSH4",
    iata_code: "YLS",
    local_code: "CSH4",
    coordinates: "-77.0171966553, 49.0303001404",
  },
  {
    ident: "CSK6",
    type: "small_airport",
    name: "Snap Lake Airport",
    elevation_ft: "1557",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Snap Lake Mine",
    gps_code: "CSK6",
    iata_code: "YNX",
    local_code: "CSK6",
    coordinates: "-110.905998, 63.593601",
  },
  {
    ident: "CSR8",
    type: "small_airport",
    name: "La Sarre Airport",
    elevation_ft: "1048",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "La Sarre",
    gps_code: "CSR8",
    iata_code: "SSQ",
    local_code: "CSR8",
    coordinates: "-79.178596496582, 48.917198181152",
  },
  {
    ident: "CSU2",
    type: "small_airport",
    name: "Chisasibi Airport",
    elevation_ft: "43",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Chisasibi",
    gps_code: "CSU2",
    iata_code: "YKU",
    local_code: "CSU2",
    coordinates: "-78.91690063476562, 53.805599212646484",
  },
  {
    ident: "CT88",
    type: "heliport",
    name: "Rentschler Heliport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CT",
    municipality: "East Hartford",
    gps_code: "CT88",
    iata_code: "EHT",
    local_code: "CT88",
    coordinates: "-72.6253, 41.7517",
  },
  {
    ident: "CTB6",
    type: "small_airport",
    name: "T����te-����-la-Baleine Airport",
    elevation_ft: "107",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "T����te-����-la-Baleine",
    gps_code: "CTB6",
    iata_code: "ZTB",
    local_code: "CTB6",
    coordinates: "-59.38359832763672, 50.674400329589844",
  },
  {
    ident: "CTK6",
    type: "small_airport",
    name: "Kegaska Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Kegaska",
    gps_code: "CTK6",
    iata_code: "ZKG",
    local_code: "CTK6",
    coordinates: "-61.265800476100004, 50.1958007812",
  },
  {
    ident: "CTM3",
    type: "seaplane_base",
    name: "Chute-Des-Passes/Lac Margane Seaplane Base",
    elevation_ft: "1310",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Chutes-des-Passes",
    gps_code: "CTM3",
    iata_code: "YWQ",
    local_code: "CTM3",
    coordinates: "-71.138, 49.9434",
  },
  {
    ident: "CTP9",
    type: "small_airport",
    name: "Donaldson Airport",
    elevation_ft: "1902",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Kattiniq",
    gps_code: "CTP9",
    iata_code: "YAU",
    local_code: "CTP9",
    coordinates: "-73.3214035034, 61.6622009277",
  },
  {
    ident: "CTR7",
    type: "seaplane_base",
    name: "Ottawa / Rockcliffe Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Ottawa",
    gps_code: "CTR7",
    iata_code: "TR7",
    local_code: "CTR7",
    coordinates: "-75.64254283910002, 45.463832946100005",
  },
  {
    ident: "CTT5",
    type: "small_airport",
    name: "La Romaine Airport",
    elevation_ft: "90",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "La Romaine",
    gps_code: "CTT5",
    iata_code: "ZGS",
    local_code: "CTT5",
    coordinates: "-60.679401, 50.259701",
  },
  {
    ident: "CTU2",
    type: "small_airport",
    name: "Fontanges Airport",
    elevation_ft: "1550",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Fontanges",
    gps_code: "CTU2",
    iata_code: "YFG",
    local_code: "CTU2",
    coordinates: "-71.173301696777, 54.553901672363",
  },
  {
    ident: "CTU4",
    type: "closed",
    name: "Lac Gagnon Seaplane Base",
    elevation_ft: "700",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Duhamel",
    gps_code: "CTU4",
    iata_code: "YGA",
    local_code: "CTU4",
    coordinates: "-75.115303039551, 46.117500305176",
  },
  {
    ident: "CTU5",
    type: "small_airport",
    name: "La Tabati����re Airport",
    elevation_ft: "102",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "La Tabati����re",
    gps_code: "CTU5",
    iata_code: "ZLT",
    local_code: "CTU5",
    coordinates: "-58.97560119628906, 50.8307991027832",
  },
  {
    ident: "CU-0003",
    type: "small_airport",
    name: "Preston Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-11",
    municipality: "Preston",
    iata_code: "PST",
    coordinates: "-75.6583023071289, 20.735000610351562",
  },
  {
    ident: "CUJ",
    type: "small_airport",
    name: "Culion Airport",
    elevation_ft: "155",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-PLW",
    municipality: "Culion Island",
    iata_code: "CUJ",
    coordinates: "119.9378, 11.8553",
  },
  {
    ident: "CVH",
    type: "small_airport",
    name: "Hollister Municipal Airport",
    elevation_ft: "230",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Hollister",
    gps_code: "KCVH",
    iata_code: "HLI",
    local_code: "CVH",
    coordinates: "-121.410003662, 36.8932991028",
  },
  {
    ident: "CVL",
    type: "small_airport",
    name: "Cape Vogel Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    iata_code: "CVL",
    local_code: "CVG",
    coordinates: "150.019722222, -9.67",
  },
  {
    ident: "CVR",
    type: "closed",
    name: "Hughes Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Culver City",
    iata_code: "CVR",
    coordinates: "-118.417, 33.975",
  },
  {
    ident: "CXC",
    type: "small_airport",
    name: "Chitina Airport",
    elevation_ft: "556",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chitina",
    gps_code: "CXC",
    iata_code: "CXC",
    local_code: "CXC",
    coordinates: "-144.427001953, 61.582901001",
  },
  {
    ident: "CY-0002",
    type: "medium_airport",
    name: "Lefkoniko Airport",
    elevation_ft: "146",
    continent: "AS",
    iso_country: "CY",
    iso_region: "CY-03",
    municipality: "Ge����itkale",
    gps_code: "LCGK",
    iata_code: "GEC",
    coordinates: "33.724358, 35.235947",
  },
  {
    ident: "CY-NIC",
    type: "closed",
    name: "Nicosia International Airport",
    continent: "AS",
    iso_country: "CY",
    iso_region: "CY-02",
    municipality: "Nicosia",
    gps_code: "LCNC",
    iata_code: "NIC",
    coordinates: "33.2787017822, 35.1507987976",
  },
  {
    ident: "CYAB",
    type: "small_airport",
    name: "Arctic Bay Airport",
    elevation_ft: "72",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Arctic Bay",
    gps_code: "CYAB",
    iata_code: "YAB",
    coordinates: "-85.0425052643, 73.0057668174",
  },
  {
    ident: "CYAC",
    type: "medium_airport",
    name: "Cat Lake Airport",
    elevation_ft: "1344",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Cat Lake",
    gps_code: "CYAC",
    iata_code: "YAC",
    coordinates: "-91.82440185546875, 51.72719955444336",
  },
  {
    ident: "CYAD",
    type: "small_airport",
    name: "La Grande-3 Airport",
    elevation_ft: "775",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "La Grande-3",
    gps_code: "CYAD",
    iata_code: "YAR",
    local_code: "YAD",
    coordinates: "-76.19640350339999, 53.5717010498",
  },
  {
    ident: "CYAG",
    type: "medium_airport",
    name: "Fort Frances Municipal Airport",
    elevation_ft: "1125",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Fort Frances",
    gps_code: "CYAG",
    iata_code: "YAG",
    coordinates: "-93.439697265625, 48.65420150756836",
  },
  {
    ident: "CYAH",
    type: "medium_airport",
    name: "La Grande-4 Airport",
    elevation_ft: "1005",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "La Grande-4",
    gps_code: "CYAH",
    iata_code: "YAH",
    coordinates: "-73.6753005981, 53.754699707",
  },
  {
    ident: "CYAL",
    type: "medium_airport",
    name: "Alert Bay Airport",
    elevation_ft: "240",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Alert Bay",
    gps_code: "CYAL",
    iata_code: "YAL",
    coordinates: "-126.91600036621094, 50.58219909667969",
  },
  {
    ident: "CYAM",
    type: "medium_airport",
    name: "Sault Ste Marie Airport",
    elevation_ft: "630",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Sault Ste Marie",
    gps_code: "CYAM",
    iata_code: "YAM",
    coordinates: "-84.509399, 46.485001",
  },
  {
    ident: "CYAQ",
    type: "medium_airport",
    name: "Kasabonika Airport",
    elevation_ft: "672",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Kasabonika",
    gps_code: "CYAQ",
    iata_code: "XKS",
    coordinates: "-88.6427993774414, 53.52470016479492",
  },
  {
    ident: "CYAS",
    type: "medium_airport",
    name: "Kangirsuk Airport",
    elevation_ft: "403",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Kangirsuk",
    gps_code: "CYAS",
    iata_code: "YKG",
    coordinates: "-69.99919891357422, 60.027198791503906",
  },
  {
    ident: "CYAT",
    type: "medium_airport",
    name: "Attawapiskat Airport",
    elevation_ft: "31",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Attawapiskat",
    gps_code: "CYAT",
    iata_code: "YAT",
    coordinates: "-82.43190002441406, 52.9275016784668",
  },
  {
    ident: "CYAY",
    type: "medium_airport",
    name: "St. Anthony Airport",
    elevation_ft: "108",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "St. Anthony",
    gps_code: "CYAY",
    iata_code: "YAY",
    coordinates: "-56.083099365200006, 51.3918991089",
  },
  {
    ident: "CYAZ",
    type: "medium_airport",
    name: "Tofino / Long Beach Airport",
    elevation_ft: "80",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Tofino",
    gps_code: "CYAZ",
    iata_code: "YAZ",
    coordinates: "-125.775583, 49.079833",
  },
  {
    ident: "CYBA",
    type: "small_airport",
    name: "Banff Airport",
    elevation_ft: "4583",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Banff",
    gps_code: "CYBA",
    iata_code: "YBA",
    coordinates: "-115.541861057, 51.207340469900004",
  },
  {
    ident: "CYBB",
    type: "medium_airport",
    name: "Kugaaruk Airport",
    elevation_ft: "56",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Kugaaruk",
    gps_code: "CYBB",
    iata_code: "YBB",
    coordinates: "-89.808098, 68.534401",
  },
  {
    ident: "CYBC",
    type: "medium_airport",
    name: "Baie Comeau Airport",
    elevation_ft: "71",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Baie-Comeau",
    gps_code: "CYBC",
    iata_code: "YBC",
    local_code: "YBC",
    coordinates: "-68.204399, 49.1325",
  },
  {
    ident: "CYBD",
    type: "medium_airport",
    name: "Bella Coola Airport",
    elevation_ft: "117",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Bella Coola",
    gps_code: "CYBD",
    iata_code: "QBC",
    coordinates: "-126.596001, 52.387501",
  },
  {
    ident: "CYBE",
    type: "medium_airport",
    name: "Uranium City Airport",
    elevation_ft: "1044",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Uranium City",
    gps_code: "CYBE",
    iata_code: "YBE",
    coordinates: "-108.48100280761719, 59.5614013671875",
  },
  {
    ident: "CYBF",
    type: "medium_airport",
    name: "Bonnyville Airport",
    elevation_ft: "1836",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Bonnyville",
    gps_code: "CYBF",
    iata_code: "YBY",
    coordinates: "-110.744003, 54.304199",
  },
  {
    ident: "CYBG",
    type: "medium_airport",
    name: "CFB Bagotville",
    elevation_ft: "522",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Bagotville",
    gps_code: "CYBG",
    iata_code: "YBG",
    coordinates: "-70.99639892578125, 48.33060073852539",
  },
  {
    ident: "CYBK",
    type: "medium_airport",
    name: "Baker Lake Airport",
    elevation_ft: "59",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Baker Lake",
    gps_code: "CYBK",
    iata_code: "YBK",
    coordinates: "-96.077796936, 64.29889678960001",
  },
  {
    ident: "CYBL",
    type: "medium_airport",
    name: "Campbell River Airport",
    elevation_ft: "346",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Campbell River",
    gps_code: "CYBL",
    iata_code: "YBL",
    local_code: "YBL",
    coordinates: "-125.271004, 49.950802",
  },
  {
    ident: "CYBQ",
    type: "medium_airport",
    name: "Tadoule Lake Airport",
    elevation_ft: "923",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Tadoule Lake",
    gps_code: "CYBQ",
    iata_code: "XTL",
    coordinates: "-98.512199, 58.7061",
  },
  {
    ident: "CYBR",
    type: "medium_airport",
    name: "Brandon Municipal Airport",
    elevation_ft: "1343",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Brandon",
    gps_code: "CYBR",
    iata_code: "YBR",
    coordinates: "-99.951897, 49.91",
  },
  {
    ident: "CYBT",
    type: "medium_airport",
    name: "Brochet Airport",
    elevation_ft: "1136",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Brochet",
    gps_code: "CYBT",
    iata_code: "YBT",
    coordinates: "-101.679001, 57.8894",
  },
  {
    ident: "CYBV",
    type: "medium_airport",
    name: "Berens River Airport",
    elevation_ft: "728",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Berens River",
    gps_code: "CYBV",
    iata_code: "YBV",
    coordinates: "-97.018303, 52.358898",
  },
  {
    ident: "CYBX",
    type: "medium_airport",
    name: "Lourdes de Blanc Sablon Airport",
    elevation_ft: "121",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Lourdes-De-Blanc-Sablon",
    gps_code: "CYBX",
    iata_code: "YBX",
    coordinates: "-57.185298919699996, 51.443599700899995",
  },
  {
    ident: "CYCA",
    type: "medium_airport",
    name: "Cartwright Airport",
    elevation_ft: "40",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Cartwright",
    gps_code: "CYCA",
    iata_code: "YRF",
    coordinates: "-57.041900634765625, 53.68280029296875",
  },
  {
    ident: "CYCB",
    type: "medium_airport",
    name: "Cambridge Bay Airport",
    elevation_ft: "90",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Cambridge Bay",
    gps_code: "CYCB",
    iata_code: "YCB",
    coordinates: "-105.138000488, 69.1081008911",
  },
  {
    ident: "CYCC",
    type: "medium_airport",
    name: "Cornwall Regional Airport",
    elevation_ft: "175",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Cornwall",
    gps_code: "CYCC",
    iata_code: "YCC",
    coordinates: "-74.56330108642578, 45.09280014038086",
  },
  {
    ident: "CYCD",
    type: "medium_airport",
    name: "Nanaimo Airport",
    elevation_ft: "92",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Nanaimo",
    gps_code: "CYCD",
    iata_code: "YCD",
    coordinates: "-123.869862556, 49.054970224899996",
  },
  {
    ident: "CYCE",
    type: "medium_airport",
    name: "Centralia / James T. Field Memorial Aerodrome",
    elevation_ft: "824",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Centralia",
    gps_code: "CYCE",
    iata_code: "YCE",
    local_code: "CYCE",
    coordinates: "-81.508301, 43.285599",
  },
  {
    ident: "CYCG",
    type: "medium_airport",
    name: "Castlegar/West Kootenay Regional Airport",
    elevation_ft: "1624",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Castlegar",
    gps_code: "CYCG",
    iata_code: "YCG",
    coordinates: "-117.632003784, 49.2963981628",
  },
  {
    ident: "CYCH",
    type: "medium_airport",
    name: "Miramichi Airport",
    elevation_ft: "108",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NB",
    municipality: "Miramichi",
    gps_code: "CYCH",
    iata_code: "YCH",
    coordinates: "-65.449203, 47.007801",
  },
  {
    ident: "CYCK",
    type: "small_airport",
    name: "Chatham Kent Airport",
    elevation_ft: "650",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Chatham-Kent",
    gps_code: "CYCK",
    iata_code: "XCM",
    local_code: "NZ3",
    coordinates: "-82.0819015503, 42.3064002991",
  },
  {
    ident: "CYCL",
    type: "medium_airport",
    name: "Charlo Airport",
    elevation_ft: "132",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NB",
    municipality: "Charlo",
    gps_code: "CYCL",
    iata_code: "YCL",
    coordinates: "-66.330299, 47.990799",
  },
  {
    ident: "CYCN",
    type: "medium_airport",
    name: "Cochrane Airport",
    elevation_ft: "861",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Cochrane",
    gps_code: "CYCN",
    iata_code: "YCN",
    coordinates: "-81.01360321044922, 49.10559844970703",
  },
  {
    ident: "CYCO",
    type: "medium_airport",
    name: "Kugluktuk Airport",
    elevation_ft: "74",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Kugluktuk",
    gps_code: "CYCO",
    iata_code: "YCO",
    coordinates: "-115.143997, 67.816704",
  },
  {
    ident: "CYCQ",
    type: "medium_airport",
    name: "Chetwynd Airport",
    elevation_ft: "2000",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Chetwynd",
    gps_code: "CYCQ",
    iata_code: "YCQ",
    coordinates: "-121.62699890136719, 55.687198638916016",
  },
  {
    ident: "CYCR",
    type: "medium_airport",
    name: "Cross Lake (Charlie Sinclair Memorial) Airport",
    elevation_ft: "709",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Cross Lake",
    gps_code: "CYCR",
    iata_code: "YCR",
    coordinates: "-97.76080322265625, 54.610599517822266",
  },
  {
    ident: "CYCS",
    type: "medium_airport",
    name: "Chesterfield Inlet Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Chesterfield Inlet",
    gps_code: "CYCS",
    iata_code: "YCS",
    coordinates: "-90.73110198970001, 63.346900939899996",
  },
  {
    ident: "CYCT",
    type: "small_airport",
    name: "Coronation Airport",
    elevation_ft: "2595",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Coronation",
    gps_code: "CYCT",
    iata_code: "YCT",
    coordinates: "-111.444999695, 52.0750007629",
  },
  {
    ident: "CYCV",
    type: "closed",
    name: "Cartierville Airport",
    elevation_ft: "120",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Montreal",
    gps_code: "CYCV",
    iata_code: "YCV",
    coordinates: "-73.7166976928711, 45.516700744628906",
  },
  {
    ident: "CYCW",
    type: "small_airport",
    name: "Chilliwack Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Chilliwack",
    gps_code: "CYCW",
    iata_code: "YCW",
    coordinates: "-121.939002991, 49.1528015137",
  },
  {
    ident: "CYCY",
    type: "medium_airport",
    name: "Clyde River Airport",
    elevation_ft: "87",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Clyde River",
    gps_code: "CYCY",
    iata_code: "YCY",
    coordinates: "-68.5167007446, 70.4860992432",
  },
  {
    ident: "CYCZ",
    type: "small_airport",
    name: "Fairmont Hot Springs Airport",
    elevation_ft: "2661",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Fairmont Hot Springs",
    gps_code: "CYCZ",
    iata_code: "YCZ",
    coordinates: "-115.873001099, 50.330299377399996",
  },
  {
    ident: "CYDA",
    type: "medium_airport",
    name: "Dawson City Airport",
    elevation_ft: "1215",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Dawson City",
    gps_code: "CYDA",
    iata_code: "YDA",
    coordinates: "-139.1280059814453, 64.04309844970703",
  },
  {
    ident: "CYDB",
    type: "medium_airport",
    name: "Burwash Airport",
    elevation_ft: "2647",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Burwash",
    gps_code: "CYDB",
    iata_code: "YDB",
    coordinates: "-139.04100036621094, 61.37110137939453",
  },
  {
    ident: "CYDF",
    type: "medium_airport",
    name: "Deer Lake Airport",
    elevation_ft: "72",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Deer Lake",
    gps_code: "CYDF",
    iata_code: "YDF",
    coordinates: "-57.39139938354492, 49.21080017089844",
  },
  {
    ident: "CYDL",
    type: "small_airport",
    name: "Dease Lake Airport",
    elevation_ft: "2600",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Dease Lake",
    gps_code: "CYDL",
    iata_code: "YDL",
    coordinates: "-130.031997681, 58.4221992493",
  },
  {
    ident: "CYDM",
    type: "medium_airport",
    name: "Ross River Airport",
    elevation_ft: "2314",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Ross River",
    gps_code: "CYDM",
    iata_code: "XRR",
    local_code: "YDM",
    coordinates: "-132.42300415, 61.9706001282",
  },
  {
    ident: "CYDN",
    type: "medium_airport",
    name: "Dauphin Barker Airport",
    elevation_ft: "999",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Dauphin",
    gps_code: "CYDN",
    iata_code: "YDN",
    coordinates: "-100.052001953125, 51.100799560546875",
  },
  {
    ident: "CYDO",
    type: "medium_airport",
    name: "Dolbeau St Felicien Airport",
    elevation_ft: "372",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Dolbeau-St-F����licien",
    gps_code: "CYDO",
    iata_code: "YDO",
    coordinates: "-72.375, 48.778499603271",
  },
  {
    ident: "CYDP",
    type: "medium_airport",
    name: "Nain Airport",
    elevation_ft: "22",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Nain",
    gps_code: "CYDP",
    iata_code: "YDP",
    coordinates: "-61.680301666259766, 56.549198150634766",
  },
  {
    ident: "CYDQ",
    type: "medium_airport",
    name: "Dawson Creek Airport",
    elevation_ft: "2148",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Dawson Creek",
    gps_code: "CYDQ",
    iata_code: "YDQ",
    coordinates: "-120.18299865722656, 55.7422981262207",
  },
  {
    ident: "CYEG",
    type: "large_airport",
    name: "Edmonton International Airport",
    elevation_ft: "2373",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Edmonton",
    gps_code: "CYEG",
    iata_code: "YEG",
    local_code: "CYEG",
    coordinates: "-113.580001831, 53.309700012200004",
  },
  {
    ident: "CYEK",
    type: "medium_airport",
    name: "Arviat Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Arviat",
    gps_code: "CYEK",
    iata_code: "YEK",
    coordinates: "-94.07080078119999, 61.0942001343",
  },
  {
    ident: "CYEL",
    type: "medium_airport",
    name: "Elliot Lake Municipal Airport",
    elevation_ft: "1087",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Elliot Lake",
    gps_code: "CYEL",
    iata_code: "YEL",
    coordinates: "-82.5614013672, 46.351398468",
  },
  {
    ident: "CYEM",
    type: "medium_airport",
    name: "Manitoulin East Municipal Airport",
    elevation_ft: "869",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Manitowaning",
    gps_code: "CYEM",
    iata_code: "YEM",
    coordinates: "-81.85810089111328, 45.84280014038086",
  },
  {
    ident: "CYEN",
    type: "medium_airport",
    name: "Estevan Airport",
    elevation_ft: "1905",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Estevan",
    gps_code: "CYEN",
    iata_code: "YEN",
    coordinates: "-102.966003418, 49.2103004456",
  },
  {
    ident: "CYER",
    type: "medium_airport",
    name: "Fort Severn Airport",
    elevation_ft: "48",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Fort Severn",
    gps_code: "CYER",
    iata_code: "YER",
    coordinates: "-87.67610168457031, 56.01890182495117",
  },
  {
    ident: "CYET",
    type: "medium_airport",
    name: "Edson Airport",
    elevation_ft: "3043",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Edson",
    gps_code: "CYET",
    iata_code: "YET",
    coordinates: "-116.464996338, 53.578899383499994",
  },
  {
    ident: "CYEU",
    type: "small_airport",
    name: "Eureka Airport",
    elevation_ft: "256",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Eureka",
    gps_code: "CYEU",
    iata_code: "YEU",
    coordinates: "-85.814201355, 79.9946975708",
  },
  {
    ident: "CYEV",
    type: "medium_airport",
    name: "Inuvik Mike Zubko Airport",
    elevation_ft: "224",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Inuvik",
    gps_code: "CYEV",
    iata_code: "YEV",
    coordinates: "-133.483001709, 68.30419921880001",
  },
  {
    ident: "CYEY",
    type: "medium_airport",
    name: "Amos/Magny Airport",
    elevation_ft: "1068",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Amos",
    gps_code: "CYEY",
    iata_code: "YEY",
    coordinates: "-78.249702, 48.563903",
  },
  {
    ident: "CYFA",
    type: "medium_airport",
    name: "Fort Albany Airport",
    elevation_ft: "48",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Fort Albany",
    gps_code: "CYFA",
    iata_code: "YFA",
    coordinates: "-81.6968994140625, 52.20140075683594",
  },
  {
    ident: "CYFB",
    type: "medium_airport",
    name: "Iqaluit Airport",
    elevation_ft: "110",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Iqaluit",
    gps_code: "CYFB",
    iata_code: "YFB",
    coordinates: "-68.555801, 63.756402",
  },
  {
    ident: "CYFC",
    type: "medium_airport",
    name: "Fredericton Airport",
    elevation_ft: "68",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NB",
    municipality: "Fredericton",
    gps_code: "CYFC",
    iata_code: "YFC",
    coordinates: "-66.53720092773438, 45.868900299072266",
  },
  {
    ident: "CYFE",
    type: "medium_airport",
    name: "Forestville Airport",
    elevation_ft: "293",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Forestville",
    gps_code: "CYFE",
    iata_code: "YFE",
    coordinates: "-69.09719848632812, 48.74610137939453",
  },
  {
    ident: "CYFH",
    type: "medium_airport",
    name: "Fort Hope Airport",
    elevation_ft: "899",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Fort Hope",
    gps_code: "CYFH",
    iata_code: "YFH",
    coordinates: "-87.90779876708984, 51.5619010925293",
  },
  {
    ident: "CYFJ",
    type: "medium_airport",
    name: "La Macaza / Mont-Tremblant International Inc Airport",
    elevation_ft: "827",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Rivi����re Rouge",
    gps_code: "CYFJ",
    iata_code: "YTM",
    coordinates: "-74.779999, 46.409401",
  },
  {
    ident: "CYFO",
    type: "medium_airport",
    name: "Flin Flon Airport",
    elevation_ft: "997",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Flin Flon",
    gps_code: "CYFO",
    iata_code: "YFO",
    coordinates: "-101.68199920654297, 54.6781005859375",
  },
  {
    ident: "CYFR",
    type: "medium_airport",
    name: "Fort Resolution Airport",
    elevation_ft: "526",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Fort Resolution",
    gps_code: "CYFR",
    iata_code: "YFR",
    coordinates: "-113.690002441, 61.1808013916",
  },
  {
    ident: "CYFS",
    type: "medium_airport",
    name: "Fort Simpson Airport",
    elevation_ft: "555",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Fort Simpson",
    gps_code: "CYFS",
    iata_code: "YFS",
    coordinates: "-121.23699951171875, 61.76020050048828",
  },
  {
    ident: "CYFT",
    type: "medium_airport",
    name: "Makkovik Airport",
    elevation_ft: "234",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Makkovik",
    gps_code: "CYFT",
    iata_code: "YMN",
    coordinates: "-59.1864013671875, 55.076900482177734",
  },
  {
    ident: "CYGB",
    type: "medium_airport",
    name: "Texada Gillies Bay Airport",
    elevation_ft: "326",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Texada",
    gps_code: "CYGB",
    iata_code: "YGB",
    coordinates: "-124.51799774169922, 49.69419860839844",
  },
  {
    ident: "CYGH",
    type: "medium_airport",
    name: "Fort Good Hope Airport",
    elevation_ft: "268",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Fort Good Hope",
    gps_code: "CYGH",
    iata_code: "YGH",
    coordinates: "-128.6510009765625, 66.24079895019531",
  },
  {
    ident: "CYGK",
    type: "medium_airport",
    name: "Kingston Norman Rogers Airport",
    elevation_ft: "305",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Kingston",
    gps_code: "CYGK",
    iata_code: "YGK",
    coordinates: "-76.5969009399414, 44.22529983520508",
  },
  {
    ident: "CYGL",
    type: "medium_airport",
    name: "La Grande Rivi����re Airport",
    elevation_ft: "639",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "La Grande Rivi����re",
    gps_code: "CYGL",
    iata_code: "YGL",
    coordinates: "-77.7042007446289, 53.625301361083984",
  },
  {
    ident: "CYGM",
    type: "medium_airport",
    name: "Gimli Industrial Park Airport",
    elevation_ft: "753",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Gimli",
    gps_code: "CYGM",
    iata_code: "YGM",
    coordinates: "-97.04329681396484, 50.62810134887695",
  },
  {
    ident: "CYGO",
    type: "medium_airport",
    name: "Gods Lake Narrows Airport",
    elevation_ft: "617",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Gods Lake Narrows",
    gps_code: "CYGO",
    iata_code: "YGO",
    coordinates: "-94.49140167236328, 54.55889892578125",
  },
  {
    ident: "CYGP",
    type: "medium_airport",
    name: "Gasp���� (Michel-Pouliot) Airport",
    elevation_ft: "112",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Gasp����",
    gps_code: "CYGP",
    iata_code: "YGP",
    coordinates: "-64.4785995483, 48.7752990723",
  },
  {
    ident: "CYGQ",
    type: "medium_airport",
    name: "Geraldton Greenstone Regional Airport",
    elevation_ft: "1144",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Geraldton",
    gps_code: "CYGQ",
    iata_code: "YGQ",
    coordinates: "-86.93939971923828, 49.77830123901367",
  },
  {
    ident: "CYGR",
    type: "medium_airport",
    name: "����les-de-la-Madeleine Airport",
    elevation_ft: "35",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "����les-de-la-Madeleine",
    gps_code: "CYGR",
    iata_code: "YGR",
    coordinates: "-61.778099060058594, 47.42470169067383",
  },
  {
    ident: "CYGT",
    type: "medium_airport",
    name: "Igloolik Airport",
    elevation_ft: "174",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Igloolik",
    gps_code: "CYGT",
    iata_code: "YGT",
    coordinates: "-81.8161010742, 69.3647003174",
  },
  {
    ident: "CYGV",
    type: "medium_airport",
    name: "Havre St Pierre Airport",
    elevation_ft: "124",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Havre St-Pierre",
    gps_code: "CYGV",
    iata_code: "YGV",
    coordinates: "-63.61140060424805, 50.281898498535156",
  },
  {
    ident: "CYGW",
    type: "medium_airport",
    name: "Kuujjuarapik Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Kuujjuarapik",
    gps_code: "CYGW",
    iata_code: "YGW",
    coordinates: "-77.76529693603516, 55.281898498535156",
  },
  {
    ident: "CYGX",
    type: "medium_airport",
    name: "Gillam Airport",
    elevation_ft: "476",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Gillam",
    gps_code: "CYGX",
    iata_code: "YGX",
    coordinates: "-94.71060180664062, 56.35749816894531",
  },
  {
    ident: "CYGZ",
    type: "medium_airport",
    name: "Grise Fiord Airport",
    elevation_ft: "146",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Grise Fiord",
    gps_code: "CYGZ",
    iata_code: "YGZ",
    coordinates: "-82.90920257570001, 76.4261016846",
  },
  {
    ident: "CYHA",
    type: "medium_airport",
    name: "Quaqtaq Airport",
    elevation_ft: "103",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Quaqtaq",
    gps_code: "CYHA",
    iata_code: "YQC",
    coordinates: "-69.6177978515625, 61.0463981628418",
  },
  {
    ident: "CYHB",
    type: "small_airport",
    name: "Hudson Bay Airport",
    elevation_ft: "1175",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Hudson Bay",
    gps_code: "CYHB",
    iata_code: "YHB",
    coordinates: "-102.310997009, 52.8166999817",
  },
  {
    ident: "CYHC",
    type: "seaplane_base",
    name: "Vancouver Harbour Water Aerodrome",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Vancouver",
    gps_code: "CYHC",
    iata_code: "CXH",
    coordinates: "-123.111000061, 49.2943992615",
  },
  {
    ident: "CYHD",
    type: "medium_airport",
    name: "Dryden Regional Airport",
    elevation_ft: "1354",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Dryden",
    gps_code: "CYHD",
    iata_code: "YHD",
    coordinates: "-92.744202, 49.831699",
  },
  {
    ident: "CYHE",
    type: "medium_airport",
    name: "Hope Airport",
    elevation_ft: "128",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Hope",
    gps_code: "CYHE",
    iata_code: "YHE",
    coordinates: "-121.498001099, 49.3683013916",
  },
  {
    ident: "CYHF",
    type: "medium_airport",
    name: "Hearst Ren���� Fontaine Municipal Airport",
    elevation_ft: "827",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Hearst",
    gps_code: "CYHF",
    iata_code: "YHF",
    coordinates: "-83.68609619140625, 49.71419906616211",
  },
  {
    ident: "CYHH",
    type: "medium_airport",
    name: "Nemiscau Airport",
    elevation_ft: "802",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Nemiscau",
    gps_code: "CYHH",
    iata_code: "YNS",
    coordinates: "-76.1355972290039, 51.69110107421875",
  },
  {
    ident: "CYHI",
    type: "medium_airport",
    name: "Ulukhaktok Holman Airport",
    elevation_ft: "117",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Ulukhaktok",
    gps_code: "CYHI",
    iata_code: "YHI",
    coordinates: "-117.80599975585938, 70.76280212402344",
  },
  {
    ident: "CYHK",
    type: "medium_airport",
    name: "Gjoa Haven Airport",
    elevation_ft: "152",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Gjoa Haven",
    gps_code: "CYHK",
    iata_code: "YHK",
    coordinates: "-95.84970092770001, 68.635597229",
  },
  {
    ident: "CYHM",
    type: "medium_airport",
    name: "John C. Munro Hamilton International Airport",
    elevation_ft: "780",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Hamilton",
    gps_code: "CYHM",
    iata_code: "YHM",
    coordinates: "-79.93499755859999, 43.173599243199995",
  },
  {
    ident: "CYHN",
    type: "medium_airport",
    name: "Hornepayne Municipal Airport",
    elevation_ft: "1099",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Hornepayne",
    gps_code: "CYHN",
    iata_code: "YHN",
    coordinates: "-84.75890350341797, 49.19309997558594",
  },
  {
    ident: "CYHO",
    type: "medium_airport",
    name: "Hopedale Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Hopedale",
    gps_code: "CYHO",
    iata_code: "YHO",
    coordinates: "-60.228599548339844, 55.448299407958984",
  },
  {
    ident: "CYHR",
    type: "medium_airport",
    name: "Chevery Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Chevery",
    gps_code: "CYHR",
    iata_code: "YHR",
    coordinates: "-59.63669967651367, 50.46889877319336",
  },
  {
    ident: "CYHT",
    type: "medium_airport",
    name: "Haines Junction Airport",
    elevation_ft: "2150",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Haines Junction",
    gps_code: "CYHT",
    iata_code: "YHT",
    coordinates: "-137.54600524902344, 60.78919982910156",
  },
  {
    ident: "CYHU",
    type: "medium_airport",
    name: "Montr����al / Saint-Hubert Airport",
    elevation_ft: "90",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Montr����al",
    gps_code: "CYHU",
    iata_code: "YHU",
    coordinates: "-73.4169006348, 45.5175018311",
  },
  {
    ident: "CYHY",
    type: "medium_airport",
    name: "Hay River / Merlyn Carter Airport",
    elevation_ft: "541",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Hay River",
    gps_code: "CYHY",
    iata_code: "YHY",
    coordinates: "-115.782997131, 60.8396987915",
  },
  {
    ident: "CYHZ",
    type: "large_airport",
    name: "Halifax / Stanfield International Airport",
    elevation_ft: "477",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NS",
    municipality: "Halifax",
    gps_code: "CYHZ",
    iata_code: "YHZ",
    coordinates: "-63.5085983276, 44.8807983398",
  },
  {
    ident: "CYIB",
    type: "medium_airport",
    name: "Atikokan Municipal Airport",
    elevation_ft: "1408",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Atikokan",
    gps_code: "CYIB",
    iata_code: "YIB",
    coordinates: "-91.6386032104, 48.7738990784",
  },
  {
    ident: "CYID",
    type: "medium_airport",
    name: "Digby / Annapolis Regional Airport",
    elevation_ft: "499",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NS",
    municipality: "Digby",
    gps_code: "CYID",
    iata_code: "YDG",
    coordinates: "-65.7854247093, 44.5458450365",
  },
  {
    ident: "CYIF",
    type: "medium_airport",
    name: "St Augustin Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "St-Augustin",
    gps_code: "CYIF",
    iata_code: "YIF",
    coordinates: "-58.6582984924, 51.2117004395",
  },
  {
    ident: "CYIK",
    type: "medium_airport",
    name: "Ivujivik Airport",
    elevation_ft: "126",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Ivujivik",
    gps_code: "CYIK",
    iata_code: "YIK",
    coordinates: "-77.92530059814453, 62.417301177978516",
  },
  {
    ident: "CYIO",
    type: "medium_airport",
    name: "Pond Inlet Airport",
    elevation_ft: "181",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Pond Inlet",
    gps_code: "CYIO",
    iata_code: "YIO",
    coordinates: "-77.9666976929, 72.6832962036",
  },
  {
    ident: "CYIV",
    type: "medium_airport",
    name: "Island Lake Airport",
    elevation_ft: "770",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Island Lake",
    gps_code: "CYIV",
    iata_code: "YIV",
    coordinates: "-94.65360260009766, 53.857200622558594",
  },
  {
    ident: "CYJA",
    type: "small_airport",
    name: "Jasper Airport",
    elevation_ft: "3350",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Jasper",
    gps_code: "CYJA",
    iata_code: "YJA",
    coordinates: "-118.058998, 52.9967",
  },
  {
    ident: "CYJF",
    type: "medium_airport",
    name: "Fort Liard Airport",
    elevation_ft: "708",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Fort Liard",
    gps_code: "CYJF",
    iata_code: "YJF",
    coordinates: "-123.46900177, 60.235801696799996",
  },
  {
    ident: "CYJN",
    type: "medium_airport",
    name: "St Jean Airport",
    elevation_ft: "136",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "St Jean",
    gps_code: "CYJN",
    iata_code: "YJN",
    coordinates: "-73.28109741210938, 45.29439926147461",
  },
  {
    ident: "CYJT",
    type: "medium_airport",
    name: "Stephenville Airport",
    elevation_ft: "84",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Stephenville",
    gps_code: "CYJT",
    iata_code: "YJT",
    coordinates: "-58.54999923706055, 48.5442008972168",
  },
  {
    ident: "CYKA",
    type: "medium_airport",
    name: "Kamloops Airport",
    elevation_ft: "1133",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Kamloops",
    gps_code: "CYKA",
    iata_code: "YKA",
    coordinates: "-120.444000244, 50.7022018433",
  },
  {
    ident: "CYKC",
    type: "small_airport",
    name: "Collins Bay Airport",
    elevation_ft: "1341",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Collins Bay",
    gps_code: "CYKC",
    iata_code: "YKC",
    coordinates: "-103.678001404, 58.236099243199995",
  },
  {
    ident: "CYKD",
    type: "medium_airport",
    name: "Aklavik/Freddie Carmichael Airport",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Aklavik",
    gps_code: "CYKD",
    iata_code: "LAK",
    coordinates: "-135.00599, 68.223297",
  },
  {
    ident: "CYKF",
    type: "medium_airport",
    name: "Waterloo Airport",
    elevation_ft: "1055",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Kitchener",
    gps_code: "CYKF",
    iata_code: "YKF",
    coordinates: "-80.3786010742, 43.460800170899994",
  },
  {
    ident: "CYKG",
    type: "medium_airport",
    name: "Kangiqsujuaq (Wakeham Bay) Airport",
    elevation_ft: "501",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Kangiqsujuaq",
    gps_code: "CYKG",
    iata_code: "YWB",
    coordinates: "-71.929397583, 61.5886001587",
  },
  {
    ident: "CYKJ",
    type: "medium_airport",
    name: "Key Lake Airport",
    elevation_ft: "1679",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Key Lake",
    gps_code: "CYKJ",
    iata_code: "YKJ",
    coordinates: "-105.61799621582031, 57.256099700927734",
  },
  {
    ident: "CYKL",
    type: "medium_airport",
    name: "Schefferville Airport",
    elevation_ft: "1709",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Schefferville",
    gps_code: "CYKL",
    iata_code: "YKL",
    coordinates: "-66.8052978515625, 54.805301666259766",
  },
  {
    ident: "CYKM",
    type: "small_airport",
    name: "Kincardine Municipal Airport",
    elevation_ft: "772",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Kincardine",
    gps_code: "CYKM",
    iata_code: "YKD",
    local_code: "CYKM",
    coordinates: "-81.606697, 44.201401",
  },
  {
    ident: "CYKO",
    type: "medium_airport",
    name: "Akulivik Airport",
    elevation_ft: "75",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Akulivik",
    gps_code: "CYKO",
    iata_code: "AKV",
    coordinates: "-78.14859771728516, 60.818599700927734",
  },
  {
    ident: "CYKQ",
    type: "medium_airport",
    name: "Waskaganish Airport",
    elevation_ft: "80",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Waskaganish",
    gps_code: "CYKQ",
    iata_code: "YKQ",
    coordinates: "-78.75830078125, 51.47330093383789",
  },
  {
    ident: "CYKX",
    type: "medium_airport",
    name: "Kirkland Lake Airport",
    elevation_ft: "1157",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Kirkland Lake",
    gps_code: "CYKX",
    iata_code: "YKX",
    coordinates: "-79.98139953613281, 48.21030044555664",
  },
  {
    ident: "CYKY",
    type: "medium_airport",
    name: "Kindersley Airport",
    elevation_ft: "2277",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Kindersley",
    gps_code: "CYKY",
    iata_code: "YKY",
    local_code: "YKY",
    coordinates: "-109.180999756, 51.5175018311",
  },
  {
    ident: "CYKZ",
    type: "medium_airport",
    name: "Buttonville Municipal Airport",
    elevation_ft: "650",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Toronto",
    gps_code: "CYKZ",
    iata_code: "YKZ",
    coordinates: "-79.37000274658203, 43.86220169067383",
  },
  {
    ident: "CYLA",
    type: "medium_airport",
    name: "Aupaluk Airport",
    elevation_ft: "119",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Aupaluk",
    gps_code: "CYLA",
    iata_code: "YPJ",
    coordinates: "-69.59970092773438, 59.29669952392578",
  },
  {
    ident: "CYLB",
    type: "small_airport",
    name: "Lac La Biche Airport",
    elevation_ft: "1884",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Lac La Biche",
    gps_code: "CYLB",
    iata_code: "YLB",
    coordinates: "-112.031997681, 54.7703018188",
  },
  {
    ident: "CYLC",
    type: "medium_airport",
    name: "Kimmirut Airport",
    elevation_ft: "175",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Kimmirut",
    gps_code: "CYLC",
    iata_code: "YLC",
    coordinates: "-69.88330078119999, 62.8499984741",
  },
  {
    ident: "CYLD",
    type: "medium_airport",
    name: "Chapleau Airport",
    elevation_ft: "1470",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Chapleau",
    gps_code: "CYLD",
    iata_code: "YLD",
    coordinates: "-83.3467025756836, 47.81999969482422",
  },
  {
    ident: "CYLH",
    type: "medium_airport",
    name: "Lansdowne House Airport",
    elevation_ft: "834",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Lansdowne House",
    gps_code: "CYLH",
    iata_code: "YLH",
    coordinates: "-87.93419647216797, 52.19559860229492",
  },
  {
    ident: "CYLJ",
    type: "medium_airport",
    name: "Meadow Lake Airport",
    elevation_ft: "1576",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Meadow Lake",
    gps_code: "CYLJ",
    iata_code: "YLJ",
    coordinates: "-108.52300262451172, 54.125301361083984",
  },
  {
    ident: "CYLK",
    type: "medium_airport",
    name: "Lutselk'e Airport",
    elevation_ft: "596",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Lutselk'e",
    gps_code: "CYLK",
    iata_code: "YSG",
    coordinates: "-110.681998, 62.418303",
  },
  {
    ident: "CYLL",
    type: "medium_airport",
    name: "Lloydminster Airport",
    elevation_ft: "2193",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Lloydminster",
    gps_code: "CYLL",
    iata_code: "YLL",
    coordinates: "-110.072998046875, 53.309200286865234",
  },
  {
    ident: "CYLQ",
    type: "small_airport",
    name: "La Tuque Airport",
    elevation_ft: "548",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "La Tuque",
    gps_code: "CYLQ",
    iata_code: "YLQ",
    coordinates: "-72.7889022827, 47.4096984863",
  },
  {
    ident: "CYLR",
    type: "medium_airport",
    name: "Leaf Rapids Airport",
    elevation_ft: "959",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Leaf Rapids",
    gps_code: "CYLR",
    iata_code: "YLR",
    coordinates: "-99.98529815673828, 56.513301849365234",
  },
  {
    ident: "CYLS",
    type: "small_airport",
    name: "Barrie-Orillia (Lake Simcoe Regional Airport)",
    elevation_ft: "972",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Barrie-Orillia",
    gps_code: "CYLS",
    iata_code: "YLK",
    local_code: "CYLS",
    coordinates: "-79.55560302730001, 44.4852981567",
  },
  {
    ident: "CYLT",
    type: "medium_airport",
    name: "Alert Airport",
    elevation_ft: "100",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Alert",
    gps_code: "CYLT",
    iata_code: "YLT",
    coordinates: "-62.2806015015, 82.51779937740001",
  },
  {
    ident: "CYLU",
    type: "medium_airport",
    name: "Kangiqsualujjuaq (Georges River) Airport",
    elevation_ft: "215",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Kangiqsualujjuaq",
    gps_code: "CYLU",
    iata_code: "XGR",
    coordinates: "-65.9927978515625, 58.71139907836914",
  },
  {
    ident: "CYLW",
    type: "medium_airport",
    name: "Kelowna International Airport",
    elevation_ft: "1421",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Kelowna",
    gps_code: "CYLW",
    iata_code: "YLW",
    coordinates: "-119.377998352, 49.9561004639",
  },
  {
    ident: "CYM",
    type: "seaplane_base",
    name: "Chatham Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chatham",
    gps_code: "05AA",
    iata_code: "CYM",
    local_code: "05AA",
    coordinates: "-134.945999, 57.5149",
  },
  {
    ident: "CYMA",
    type: "medium_airport",
    name: "Mayo Airport",
    elevation_ft: "1653",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Mayo",
    gps_code: "CYMA",
    iata_code: "YMA",
    coordinates: "-135.8679962158203, 63.61640167236328",
  },
  {
    ident: "CYME",
    type: "medium_airport",
    name: "Matane Airport",
    elevation_ft: "102",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Matane",
    gps_code: "CYME",
    iata_code: "YME",
    coordinates: "-67.45330047607422, 48.85689926147461",
  },
  {
    ident: "CYMG",
    type: "medium_airport",
    name: "Manitouwadge Airport",
    elevation_ft: "1198",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Manitouwadge",
    gps_code: "CYMG",
    iata_code: "YMG",
    coordinates: "-85.86060333251953, 49.083900451660156",
  },
  {
    ident: "CYMH",
    type: "medium_airport",
    name: "Mary's Harbour Airport",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Mary's Harbour",
    gps_code: "CYMH",
    iata_code: "YMH",
    coordinates: "-55.847198486328125, 52.302799224853516",
  },
  {
    ident: "CYMJ",
    type: "medium_airport",
    name: "Moose Jaw Air Vice Marshal C. M. McEwen Airport",
    elevation_ft: "1892",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Moose Jaw",
    gps_code: "CYMJ",
    iata_code: "YMJ",
    coordinates: "-105.55899810791016, 50.330299377441406",
  },
  {
    ident: "CYML",
    type: "medium_airport",
    name: "Charlevoix Airport",
    elevation_ft: "977",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Charlevoix",
    gps_code: "CYML",
    iata_code: "YML",
    coordinates: "-70.2238998413086, 47.59749984741211",
  },
  {
    ident: "CYMM",
    type: "medium_airport",
    name: "Fort McMurray Airport",
    elevation_ft: "1211",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Fort McMurray",
    gps_code: "CYMM",
    iata_code: "YMM",
    coordinates: "-111.222000122, 56.653301239",
  },
  {
    ident: "CYMO",
    type: "medium_airport",
    name: "Moosonee Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Moosonee",
    gps_code: "CYMO",
    iata_code: "YMO",
    coordinates: "-80.60780334472656, 51.291099548339844",
  },
  {
    ident: "CYMT",
    type: "medium_airport",
    name: "Chapais Airport",
    elevation_ft: "1270",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Chibougamau",
    gps_code: "CYMT",
    iata_code: "YMT",
    coordinates: "-74.5280990600586, 49.77190017700195",
  },
  {
    ident: "CYMU",
    type: "medium_airport",
    name: "Umiujaq Airport",
    elevation_ft: "250",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Umiujaq",
    gps_code: "CYMU",
    iata_code: "YUD",
    coordinates: "-76.51830291748047, 56.53609848022461",
  },
  {
    ident: "CYMW",
    type: "small_airport",
    name: "Maniwaki Airport",
    elevation_ft: "656",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Maniwaki",
    gps_code: "CYMW",
    iata_code: "YMW",
    local_code: "YMW",
    coordinates: "-75.9906005859, 46.2728004456",
  },
  {
    ident: "CYMX",
    type: "medium_airport",
    name: "Montreal International (Mirabel) Airport",
    elevation_ft: "270",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Montr����al",
    gps_code: "CYMX",
    iata_code: "YMX",
    coordinates: "-74.038696, 45.679501",
  },
  {
    ident: "CYNA",
    type: "medium_airport",
    name: "Natashquan Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Natashquan",
    gps_code: "CYNA",
    iata_code: "YNA",
    coordinates: "-61.78919982910156, 50.189998626708984",
  },
  {
    ident: "CYNC",
    type: "medium_airport",
    name: "Wemindji Airport",
    elevation_ft: "66",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Wemindji",
    gps_code: "CYNC",
    iata_code: "YNC",
    coordinates: "-78.83110046386719, 53.01060104370117",
  },
  {
    ident: "CYND",
    type: "medium_airport",
    name: "Ottawa / Gatineau Airport",
    elevation_ft: "211",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Gatineau",
    gps_code: "CYND",
    iata_code: "YND",
    local_code: "CYND",
    coordinates: "-75.563599, 45.521702",
  },
  {
    ident: "CYNE",
    type: "medium_airport",
    name: "Norway House Airport",
    elevation_ft: "734",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Norway House",
    gps_code: "CYNE",
    iata_code: "YNE",
    coordinates: "-97.84420013427734, 53.95830154418945",
  },
  {
    ident: "CYNH",
    type: "small_airport",
    name: "Hudsons Hope Airport",
    elevation_ft: "2220",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Hudson's Hope",
    gps_code: "CYNH",
    iata_code: "YNH",
    coordinates: "-121.975997925, 56.0355987549",
  },
  {
    ident: "CYNJ",
    type: "small_airport",
    name: "Langley Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Langley",
    gps_code: "CYNJ",
    iata_code: "YLY",
    coordinates: "-122.630996704, 49.10079956049999",
  },
  {
    ident: "CYNL",
    type: "medium_airport",
    name: "Points North Landing Airport",
    elevation_ft: "1605",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Points North Landing",
    gps_code: "CYNL",
    iata_code: "YNL",
    coordinates: "-104.08200073242188, 58.27669906616211",
  },
  {
    ident: "CYNM",
    type: "medium_airport",
    name: "Matagami Airport",
    elevation_ft: "918",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Matagami",
    gps_code: "CYNM",
    iata_code: "YNM",
    coordinates: "-77.80280303955078, 49.76169967651367",
  },
  {
    ident: "CYNR",
    type: "small_airport",
    name: "Fort Mackay / Horizon Airport",
    elevation_ft: "916",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Fort Mackay",
    gps_code: "CYNR",
    iata_code: "HZP",
    local_code: "CYNR",
    coordinates: "-111.700996399, 57.3816986084",
  },
  {
    ident: "CYOA",
    type: "medium_airport",
    name: "Ekati Airport",
    elevation_ft: "1536",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Ekati",
    gps_code: "CYOA",
    iata_code: "YOA",
    coordinates: "-110.614997864, 64.6988983154",
  },
  {
    ident: "CYOC",
    type: "medium_airport",
    name: "Old Crow Airport",
    elevation_ft: "824",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Old Crow",
    gps_code: "CYOC",
    iata_code: "YOC",
    coordinates: "-139.83900451660156, 67.57060241699219",
  },
  {
    ident: "CYOD",
    type: "medium_airport",
    name: "CFB Cold Lake",
    elevation_ft: "1775",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Cold Lake",
    gps_code: "CYOD",
    iata_code: "YOD",
    coordinates: "-110.27899932861328, 54.404998779296875",
  },
  {
    ident: "CYOH",
    type: "medium_airport",
    name: "Oxford House Airport",
    elevation_ft: "663",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Oxford House",
    gps_code: "CYOH",
    iata_code: "YOH",
    coordinates: "-95.27890014648438, 54.93330001831055",
  },
  {
    ident: "CYOJ",
    type: "medium_airport",
    name: "High Level Airport",
    elevation_ft: "1110",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "High Level",
    gps_code: "CYOJ",
    iata_code: "YOJ",
    coordinates: "-117.16500091552734, 58.62139892578125",
  },
  {
    ident: "CYOO",
    type: "medium_airport",
    name: "Toronto/Oshawa Executive Airport",
    elevation_ft: "460",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Oshawa",
    gps_code: "CYOO",
    iata_code: "YOO",
    local_code: "CYOO",
    coordinates: "-78.894997, 43.922798",
  },
  {
    ident: "CYOP",
    type: "medium_airport",
    name: "Rainbow Lake Airport",
    elevation_ft: "1759",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Rainbow Lake",
    gps_code: "CYOP",
    iata_code: "YOP",
    coordinates: "-119.40799713134766, 58.49140167236328",
  },
  {
    ident: "CYOS",
    type: "medium_airport",
    name: "Owen Sound / Billy Bishop Regional Airport",
    elevation_ft: "1007",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Owen Sound",
    gps_code: "CYOS",
    iata_code: "YOS",
    coordinates: "-80.8375015259, 44.5903015137",
  },
  {
    ident: "CYOW",
    type: "large_airport",
    name: "Ottawa Macdonald-Cartier International Airport",
    elevation_ft: "374",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Ottawa",
    gps_code: "CYOW",
    iata_code: "YOW",
    local_code: "YOW",
    coordinates: "-75.66919708251953, 45.3224983215332",
  },
  {
    ident: "CYOY",
    type: "heliport",
    name: "C J.H.L.(Joe) Lecomte) Heliport",
    elevation_ft: "550",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Valcartier",
    gps_code: "CYOY",
    iata_code: "YOY",
    coordinates: "-71.502199173, 46.9021961734",
  },
  {
    ident: "CYPA",
    type: "medium_airport",
    name: "Prince Albert Glass Field",
    elevation_ft: "1405",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Prince Albert",
    gps_code: "CYPA",
    iata_code: "YPA",
    coordinates: "-105.672996521, 53.214199066199996",
  },
  {
    ident: "CYPC",
    type: "medium_airport",
    name: "Paulatuk (Nora Aliqatchialuk Ruben) Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Paulatuk",
    gps_code: "CYPC",
    iata_code: "YPC",
    coordinates: "-124.075469971, 69.3608381154",
  },
  {
    ident: "CYPD",
    type: "medium_airport",
    name: "Port Hawkesbury Airport",
    elevation_ft: "377",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NS",
    municipality: "Port Hawkesbury",
    gps_code: "CYPD",
    iata_code: "YPS",
    coordinates: "-61.3680992126, 45.6567001343",
  },
  {
    ident: "CYPE",
    type: "medium_airport",
    name: "Peace River Airport",
    elevation_ft: "1873",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Peace River",
    gps_code: "CYPE",
    iata_code: "YPE",
    coordinates: "-117.446999, 56.226898",
  },
  {
    ident: "CYPG",
    type: "medium_airport",
    name: "Portage-la-Prairie / Southport Airport",
    elevation_ft: "885",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Portage la Prairie",
    gps_code: "CYPG",
    iata_code: "YPG",
    coordinates: "-98.273817, 49.903099",
  },
  {
    ident: "CYPH",
    type: "medium_airport",
    name: "Inukjuak Airport",
    elevation_ft: "83",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Inukjuak",
    gps_code: "CYPH",
    iata_code: "YPH",
    coordinates: "-78.07689666748047, 58.471900939941406",
  },
  {
    ident: "CYPL",
    type: "medium_airport",
    name: "Pickle Lake Airport",
    elevation_ft: "1267",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Pickle Lake",
    gps_code: "CYPL",
    iata_code: "YPL",
    coordinates: "-90.21420288085938, 51.4463996887207",
  },
  {
    ident: "CYPM",
    type: "medium_airport",
    name: "Pikangikum Airport",
    elevation_ft: "1114",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Pikangikum",
    gps_code: "CYPM",
    iata_code: "YPM",
    coordinates: "-93.97329711914062, 51.819698333740234",
  },
  {
    ident: "CYPN",
    type: "medium_airport",
    name: "Port Menier Airport",
    elevation_ft: "167",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Port-Menier",
    gps_code: "CYPN",
    iata_code: "YPN",
    coordinates: "-64.2885971069336, 49.83639907836914",
  },
  {
    ident: "CYPO",
    type: "medium_airport",
    name: "Peawanuck Airport",
    elevation_ft: "173",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Peawanuck",
    gps_code: "CYPO",
    iata_code: "YPO",
    coordinates: "-85.44329833984375, 54.98809814453125",
  },
  {
    ident: "CYPQ",
    type: "medium_airport",
    name: "Peterborough Airport",
    elevation_ft: "628",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Peterborough",
    gps_code: "CYPQ",
    iata_code: "YPQ",
    coordinates: "-78.36329650878906, 44.22999954223633",
  },
  {
    ident: "CYPR",
    type: "medium_airport",
    name: "Prince Rupert Airport",
    elevation_ft: "116",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Prince Rupert",
    gps_code: "CYPR",
    iata_code: "YPR",
    coordinates: "-130.445007324, 54.286098480199996",
  },
  {
    ident: "CYPW",
    type: "medium_airport",
    name: "Powell River Airport",
    elevation_ft: "425",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Powell River",
    gps_code: "CYPW",
    iata_code: "YPW",
    coordinates: "-124.5, 49.83420181274414",
  },
  {
    ident: "CYPX",
    type: "medium_airport",
    name: "Puvirnituq Airport",
    elevation_ft: "74",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Puvirnituq",
    gps_code: "CYPX",
    iata_code: "YPX",
    coordinates: "-77.28690338134766, 60.05059814453125",
  },
  {
    ident: "CYPY",
    type: "medium_airport",
    name: "Fort Chipewyan Airport",
    elevation_ft: "761",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Fort Chipewyan",
    gps_code: "CYPY",
    iata_code: "YPY",
    coordinates: "-111.11699676513672, 58.7672004699707",
  },
  {
    ident: "CYPZ",
    type: "small_airport",
    name: "Burns Lake Airport",
    elevation_ft: "2343",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Burns Lake",
    gps_code: "CYPZ",
    iata_code: "YPZ",
    local_code: "YPZ",
    coordinates: "-125.950996399, 54.3763999939",
  },
  {
    ident: "CYQA",
    type: "medium_airport",
    name: "Muskoka Airport",
    elevation_ft: "925",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Muskoka",
    gps_code: "CYQA",
    iata_code: "YQA",
    coordinates: "-79.30329895019531, 44.974700927734375",
  },
  {
    ident: "CYQB",
    type: "medium_airport",
    name: "Quebec Jean Lesage International Airport",
    elevation_ft: "244",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Quebec",
    gps_code: "CYQB",
    iata_code: "YQB",
    local_code: "YQB",
    coordinates: "-71.393303, 46.7911",
  },
  {
    ident: "CYQD",
    type: "medium_airport",
    name: "The Pas Airport",
    elevation_ft: "887",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "The Pas",
    gps_code: "CYQD",
    iata_code: "YQD",
    coordinates: "-101.09100341796875, 53.97140121459961",
  },
  {
    ident: "CYQF",
    type: "medium_airport",
    name: "Red Deer Regional Airport",
    elevation_ft: "2968",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Red Deer",
    gps_code: "CYQF",
    iata_code: "YQF",
    coordinates: "-113.89399719238281, 52.18220138549805",
  },
  {
    ident: "CYQG",
    type: "medium_airport",
    name: "Windsor Airport",
    elevation_ft: "622",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Windsor",
    gps_code: "CYQG",
    iata_code: "YQG",
    coordinates: "-82.95559692382812, 42.27560043334961",
  },
  {
    ident: "CYQH",
    type: "medium_airport",
    name: "Watson Lake Airport",
    elevation_ft: "2255",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Watson Lake",
    gps_code: "CYQH",
    iata_code: "YQH",
    coordinates: "-128.82200622558594, 60.11640167236328",
  },
  {
    ident: "CYQI",
    type: "medium_airport",
    name: "Yarmouth Airport",
    elevation_ft: "141",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NS",
    municipality: "Yarmouth",
    gps_code: "CYQI",
    iata_code: "YQI",
    coordinates: "-66.08809661865234, 43.826900482177734",
  },
  {
    ident: "CYQK",
    type: "medium_airport",
    name: "Kenora Airport",
    elevation_ft: "1332",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Kenora",
    gps_code: "CYQK",
    iata_code: "YQK",
    coordinates: "-94.36309814453125, 49.788299560546875",
  },
  {
    ident: "CYQL",
    type: "medium_airport",
    name: "Lethbridge County Airport",
    elevation_ft: "3048",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Lethbridge",
    gps_code: "CYQL",
    iata_code: "YQL",
    coordinates: "-112.800003052, 49.6302986145",
  },
  {
    ident: "CYQM",
    type: "medium_airport",
    name: "Greater Moncton Rom����o LeBlanc International Airport",
    elevation_ft: "232",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NB",
    municipality: "Moncton",
    gps_code: "CYQM",
    iata_code: "YQM",
    coordinates: "-64.678596, 46.112202",
  },
  {
    ident: "CYQN",
    type: "medium_airport",
    name: "Nakina Airport",
    elevation_ft: "1057",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Nakina",
    gps_code: "CYQN",
    iata_code: "YQN",
    coordinates: "-86.69640350341797, 50.18280029296875",
  },
  {
    ident: "CYQQ",
    type: "medium_airport",
    name: "Comox Airport",
    elevation_ft: "84",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Comox",
    gps_code: "CYQQ",
    iata_code: "YQQ",
    coordinates: "-124.88700103759766, 49.71080017089844",
  },
  {
    ident: "CYQR",
    type: "medium_airport",
    name: "Regina International Airport",
    elevation_ft: "1894",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Regina",
    gps_code: "CYQR",
    iata_code: "YQR",
    coordinates: "-104.66600036621094, 50.43190002441406",
  },
  {
    ident: "CYQS",
    type: "medium_airport",
    name: "St Thomas Municipal Airport",
    elevation_ft: "778",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "St Thomas",
    gps_code: "CYQS",
    iata_code: "YQS",
    coordinates: "-81.11080169677734, 42.77000045776367",
  },
  {
    ident: "CYQT",
    type: "medium_airport",
    name: "Thunder Bay Airport",
    elevation_ft: "653",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Thunder Bay",
    gps_code: "CYQT",
    iata_code: "YQT",
    coordinates: "-89.32389831542969, 48.37189865112305",
  },
  {
    ident: "CYQU",
    type: "medium_airport",
    name: "Grande Prairie Airport",
    elevation_ft: "2195",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Grande Prairie",
    gps_code: "CYQU",
    iata_code: "YQU",
    coordinates: "-118.885002136, 55.1796989441",
  },
  {
    ident: "CYQV",
    type: "medium_airport",
    name: "Yorkton Municipal Airport",
    elevation_ft: "1635",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Yorkton",
    gps_code: "CYQV",
    iata_code: "YQV",
    coordinates: "-102.46199798583984, 51.26470184326172",
  },
  {
    ident: "CYQW",
    type: "medium_airport",
    name: "North Battleford Airport",
    elevation_ft: "1799",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "North Battleford",
    gps_code: "CYQW",
    iata_code: "YQW",
    coordinates: "-108.24400329589844, 52.76919937133789",
  },
  {
    ident: "CYQX",
    type: "medium_airport",
    name: "Gander International Airport",
    elevation_ft: "496",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Gander",
    gps_code: "CYQX",
    iata_code: "YQX",
    coordinates: "-54.56809997558594, 48.9369010925293",
  },
  {
    ident: "CYQY",
    type: "medium_airport",
    name: "Sydney / J.A. Douglas McCurdy Airport",
    elevation_ft: "203",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NS",
    municipality: "Sydney",
    gps_code: "CYQY",
    iata_code: "YQY",
    coordinates: "-60.047798, 46.1614",
  },
  {
    ident: "CYQZ",
    type: "medium_airport",
    name: "Quesnel Airport",
    elevation_ft: "1789",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Quesnel",
    gps_code: "CYQZ",
    iata_code: "YQZ",
    coordinates: "-122.51000213623047, 53.026100158691406",
  },
  {
    ident: "CYRA",
    type: "medium_airport",
    name: "Rae Lakes Airport",
    elevation_ft: "723",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Gam����t����",
    gps_code: "CYRA",
    iata_code: "YRA",
    coordinates: "-117.30999755859375, 64.11609649658203",
  },
  {
    ident: "CYRB",
    type: "medium_airport",
    name: "Resolute Bay Airport",
    elevation_ft: "215",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Resolute Bay",
    gps_code: "CYRB",
    iata_code: "YRB",
    coordinates: "-94.9693984985, 74.7169036865",
  },
  {
    ident: "CYRI",
    type: "medium_airport",
    name: "Rivi����re-du-Loup Airport",
    elevation_ft: "427",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Rivi����re-du-Loup",
    gps_code: "CYRI",
    iata_code: "YRI",
    coordinates: "-69.58470153808594, 47.764400482177734",
  },
  {
    ident: "CYRJ",
    type: "medium_airport",
    name: "Roberval Airport",
    elevation_ft: "586",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Roberval",
    gps_code: "CYRJ",
    iata_code: "YRJ",
    coordinates: "-72.2656021118164, 48.52000045776367",
  },
  {
    ident: "CYRL",
    type: "medium_airport",
    name: "Red Lake Airport",
    elevation_ft: "1265",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Red Lake",
    gps_code: "CYRL",
    iata_code: "YRL",
    coordinates: "-93.79309844970703, 51.066898345947266",
  },
  {
    ident: "CYRM",
    type: "small_airport",
    name: "Rocky Mountain House Airport",
    elevation_ft: "3244",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Rocky Mountain House",
    gps_code: "CYRM",
    iata_code: "YRM",
    coordinates: "-114.903999329, 52.4296989441",
  },
  {
    ident: "CYRO",
    type: "medium_airport",
    name: "Ottawa / Rockcliffe Airport",
    elevation_ft: "188",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Ottawa",
    gps_code: "CYRO",
    iata_code: "YRO",
    local_code: "CYRO",
    coordinates: "-75.64610290530001, 45.4603004456",
  },
  {
    ident: "CYRQ",
    type: "medium_airport",
    name: "Trois-Rivi����res Airport",
    elevation_ft: "199",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Trois-Rivi����res",
    gps_code: "CYRQ",
    iata_code: "YRQ",
    coordinates: "-72.67939758300781, 46.35279846191406",
  },
  {
    ident: "CYRS",
    type: "medium_airport",
    name: "Red Sucker Lake Airport",
    elevation_ft: "729",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Red Sucker Lake",
    gps_code: "CYRS",
    iata_code: "YRS",
    coordinates: "-93.55719757080078, 54.167198181152344",
  },
  {
    ident: "CYRT",
    type: "medium_airport",
    name: "Rankin Inlet Airport",
    elevation_ft: "94",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Rankin Inlet",
    gps_code: "CYRT",
    iata_code: "YRT",
    coordinates: "-92.1157989502, 62.8114013672",
  },
  {
    ident: "CYRV",
    type: "medium_airport",
    name: "Revelstoke Airport",
    elevation_ft: "1459",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Revelstoke",
    gps_code: "CYRV",
    iata_code: "YRV",
    coordinates: "-118.182998657, 50.9667015076",
  },
  {
    ident: "CYSB",
    type: "medium_airport",
    name: "Sudbury Airport",
    elevation_ft: "1141",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Sudbury",
    gps_code: "CYSB",
    iata_code: "YSB",
    coordinates: "-80.79889678955078, 46.625",
  },
  {
    ident: "CYSC",
    type: "medium_airport",
    name: "Sherbrooke Airport",
    elevation_ft: "792",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Sherbrooke",
    gps_code: "CYSC",
    iata_code: "YSC",
    coordinates: "-71.69139862060547, 45.4385986328125",
  },
  {
    ident: "CYSD",
    type: "heliport",
    name: "Suffield Heliport",
    elevation_ft: "2525",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Suffield",
    gps_code: "CYSD",
    iata_code: "YSD",
    coordinates: "-111.18299865722656, 50.266700744628906",
  },
  {
    ident: "CYSE",
    type: "small_airport",
    name: "Squamish Airport",
    elevation_ft: "171",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Squamish",
    gps_code: "CYSE",
    iata_code: "YSE",
    local_code: "YSE",
    coordinates: "-123.162002563, 49.7817001343",
  },
  {
    ident: "CYSF",
    type: "medium_airport",
    name: "Stony Rapids Airport",
    elevation_ft: "805",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Stony Rapids",
    gps_code: "CYSF",
    iata_code: "YSF",
    coordinates: "-105.84100341796875, 59.250301361083984",
  },
  {
    ident: "CYSH",
    type: "medium_airport",
    name: "Smiths Falls-Montague (Russ Beach) Airport",
    elevation_ft: "416",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Smiths Falls",
    gps_code: "CYSH",
    iata_code: "YSH",
    coordinates: "-75.94059753417969, 44.94580078125",
  },
  {
    ident: "CYSJ",
    type: "medium_airport",
    name: "Saint John Airport",
    elevation_ft: "357",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NB",
    municipality: "Saint John",
    gps_code: "CYSJ",
    iata_code: "YSJ",
    coordinates: "-65.89029693603516, 45.31610107421875",
  },
  {
    ident: "CYSK",
    type: "medium_airport",
    name: "Sanikiluaq Airport",
    elevation_ft: "104",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Sanikiluaq",
    gps_code: "CYSK",
    iata_code: "YSK",
    coordinates: "-79.2466964722, 56.5377998352",
  },
  {
    ident: "CYSL",
    type: "medium_airport",
    name: "St Leonard Airport",
    elevation_ft: "793",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NB",
    municipality: "St Leonard",
    gps_code: "CYSL",
    iata_code: "YSL",
    coordinates: "-67.83470153808594, 47.157501220703125",
  },
  {
    ident: "CYSM",
    type: "medium_airport",
    name: "Fort Smith Airport",
    elevation_ft: "671",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Fort Smith",
    gps_code: "CYSM",
    iata_code: "YSM",
    coordinates: "-111.96199798583984, 60.020301818847656",
  },
  {
    ident: "CYSN",
    type: "medium_airport",
    name: "Niagara District Airport",
    elevation_ft: "321",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "St Catharines",
    gps_code: "CYSN",
    iata_code: "YCM",
    coordinates: "-79.17169952392578, 43.19169998168945",
  },
  {
    ident: "CYSP",
    type: "medium_airport",
    name: "Marathon Airport",
    elevation_ft: "1035",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Marathon",
    gps_code: "CYSP",
    iata_code: "YSP",
    coordinates: "-86.34439849853516, 48.75529861450195",
  },
  {
    ident: "CYST",
    type: "medium_airport",
    name: "St. Theresa Point Airport",
    elevation_ft: "773",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "St. Theresa Point",
    gps_code: "CYST",
    iata_code: "YST",
    coordinates: "-94.85189819335938, 53.84560012817383",
  },
  {
    ident: "CYSU",
    type: "medium_airport",
    name: "Summerside Airport",
    elevation_ft: "56",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-PE",
    municipality: "Summerside",
    gps_code: "CYSU",
    iata_code: "YSU",
    coordinates: "-63.83359909057617, 46.44060134887695",
  },
  {
    ident: "CYSY",
    type: "medium_airport",
    name: "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
    elevation_ft: "282",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Sachs Harbour",
    gps_code: "CYSY",
    iata_code: "YSY",
    coordinates: "-125.242996216, 71.9938964844",
  },
  {
    ident: "CYTA",
    type: "medium_airport",
    name: "Pembroke Airport",
    elevation_ft: "529",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Pembroke",
    gps_code: "CYTA",
    iata_code: "YTA",
    coordinates: "-77.25170135498047, 45.86439895629883",
  },
  {
    ident: "CYTE",
    type: "medium_airport",
    name: "Cape Dorset Airport",
    elevation_ft: "164",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Cape Dorset",
    gps_code: "CYTE",
    iata_code: "YTE",
    coordinates: "-76.5267028809, 64.2300033569",
  },
  {
    ident: "CYTF",
    type: "medium_airport",
    name: "Alma Airport",
    elevation_ft: "445",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Alma",
    gps_code: "CYTF",
    iata_code: "YTF",
    coordinates: "-71.64189910889999, 48.50889968869999",
  },
  {
    ident: "CYTH",
    type: "medium_airport",
    name: "Thompson Airport",
    elevation_ft: "729",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Thompson",
    gps_code: "CYTH",
    iata_code: "YTH",
    coordinates: "-97.86419677734375, 55.80110168457031",
  },
  {
    ident: "CYTL",
    type: "medium_airport",
    name: "Big Trout Lake Airport",
    elevation_ft: "729",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Big Trout Lake",
    gps_code: "CYTL",
    iata_code: "YTL",
    coordinates: "-89.89689636230469, 53.81779861450195",
  },
  {
    ident: "CYTQ",
    type: "medium_airport",
    name: "Tasiujaq Airport",
    elevation_ft: "122",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Tasiujaq",
    gps_code: "CYTQ",
    iata_code: "YTQ",
    coordinates: "-69.95580291748047, 58.66780090332031",
  },
  {
    ident: "CYTR",
    type: "medium_airport",
    name: "CFB Trenton",
    elevation_ft: "283",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Trenton",
    gps_code: "CYTR",
    iata_code: "YTR",
    coordinates: "-77.5280990600586, 44.118900299072266",
  },
  {
    ident: "CYTS",
    type: "medium_airport",
    name: "Timmins/Victor M. Power",
    elevation_ft: "967",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Timmins",
    gps_code: "CYTS",
    iata_code: "YTS",
    coordinates: "-81.376701355, 48.569698333699996",
  },
  {
    ident: "CYTZ",
    type: "medium_airport",
    name: "Billy Bishop Toronto City Centre Airport",
    elevation_ft: "252",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Toronto",
    gps_code: "CYTZ",
    iata_code: "YTZ",
    coordinates: "-79.396202, 43.627499",
  },
  {
    ident: "CYUB",
    type: "medium_airport",
    name: "Tuktoyaktuk Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Tuktoyaktuk",
    gps_code: "CYUB",
    iata_code: "YUB",
    coordinates: "-133.0260009765625, 69.43329620361328",
  },
  {
    ident: "CYUL",
    type: "large_airport",
    name: "Montreal / Pierre Elliott Trudeau International Airport",
    elevation_ft: "118",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Montr����al",
    gps_code: "CYUL",
    iata_code: "YUL",
    local_code: "YUL",
    coordinates: "-73.7407989502, 45.4706001282",
  },
  {
    ident: "CYUT",
    type: "medium_airport",
    name: "Naujaat Airport",
    elevation_ft: "80",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Repulse Bay",
    gps_code: "CYUT",
    iata_code: "YUT",
    coordinates: "-86.224701, 66.5214",
  },
  {
    ident: "CYUX",
    type: "medium_airport",
    name: "Hall Beach Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Hall Beach",
    gps_code: "CYUX",
    iata_code: "YUX",
    coordinates: "-81.2425, 68.77610015869999",
  },
  {
    ident: "CYUY",
    type: "medium_airport",
    name: "Rouyn Noranda Airport",
    elevation_ft: "988",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Rouyn-Noranda",
    gps_code: "CYUY",
    iata_code: "YUY",
    coordinates: "-78.83560180664062, 48.20610046386719",
  },
  {
    ident: "CYVB",
    type: "medium_airport",
    name: "Bonaventure Airport",
    elevation_ft: "123",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Bonaventure",
    gps_code: "CYVB",
    iata_code: "YVB",
    coordinates: "-65.46029663085938, 48.07109832763672",
  },
  {
    ident: "CYVC",
    type: "medium_airport",
    name: "La Ronge Airport",
    elevation_ft: "1242",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "La Ronge",
    gps_code: "CYVC",
    iata_code: "YVC",
    coordinates: "-105.262001038, 55.151401519800004",
  },
  {
    ident: "CYVG",
    type: "small_airport",
    name: "Vermilion Airport",
    elevation_ft: "2025",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Vermilion",
    gps_code: "CYVG",
    iata_code: "YVG",
    local_code: "YVG",
    coordinates: "-110.823997498, 53.355800628699996",
  },
  {
    ident: "CYVK",
    type: "medium_airport",
    name: "Vernon Airport",
    elevation_ft: "1140",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Vernon",
    gps_code: "CYVK",
    iata_code: "YVE",
    coordinates: "-119.33100128173828, 50.24810028076172",
  },
  {
    ident: "CYVL",
    type: "small_airport",
    name: "Tommy Kochon Airport",
    elevation_ft: "870",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Colville Lake",
    gps_code: "CYVL",
    iata_code: "YCK",
    coordinates: "-126.126, 67.02",
  },
  {
    ident: "CYVM",
    type: "medium_airport",
    name: "Qikiqtarjuaq Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Qikiqtarjuaq",
    gps_code: "CYVM",
    iata_code: "YVM",
    coordinates: "-64.03140258789999, 67.5457992554",
  },
  {
    ident: "CYVO",
    type: "medium_airport",
    name: "Val-d'Or Airport",
    elevation_ft: "1107",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Val-d'Or",
    gps_code: "CYVO",
    iata_code: "YVO",
    coordinates: "-77.7827987671, 48.0532989502",
  },
  {
    ident: "CYVP",
    type: "medium_airport",
    name: "Kuujjuaq Airport",
    elevation_ft: "129",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Kuujjuaq",
    gps_code: "CYVP",
    iata_code: "YVP",
    coordinates: "-68.4269027709961, 58.096099853515625",
  },
  {
    ident: "CYVQ",
    type: "medium_airport",
    name: "Norman Wells Airport",
    elevation_ft: "238",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Norman Wells",
    gps_code: "CYVQ",
    iata_code: "YVQ",
    coordinates: "-126.7979965209961, 65.28160095214844",
  },
  {
    ident: "CYVR",
    type: "large_airport",
    name: "Vancouver International Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Vancouver",
    gps_code: "CYVR",
    iata_code: "YVR",
    coordinates: "-123.183998108, 49.193901062",
  },
  {
    ident: "CYVT",
    type: "small_airport",
    name: "Buffalo Narrows Airport",
    elevation_ft: "1423",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Buffalo Narrows",
    gps_code: "CYVT",
    iata_code: "YVT",
    coordinates: "-108.417999268, 55.8418998718",
  },
  {
    ident: "CYVV",
    type: "medium_airport",
    name: "Wiarton Airport",
    elevation_ft: "729",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Wiarton",
    gps_code: "CYVV",
    iata_code: "YVV",
    coordinates: "-81.107201, 44.7458",
  },
  {
    ident: "CYVZ",
    type: "medium_airport",
    name: "Deer Lake Airport",
    elevation_ft: "1092",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Deer Lake",
    gps_code: "CYVZ",
    iata_code: "YVZ",
    coordinates: "-94.0614013671875, 52.655799865722656",
  },
  {
    ident: "CYWA",
    type: "medium_airport",
    name: "Petawawa Airport",
    elevation_ft: "427",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Petawawa",
    gps_code: "CYWA",
    iata_code: "YWA",
    coordinates: "-77.31919860839844, 45.95220184326172",
  },
  {
    ident: "CYWG",
    type: "large_airport",
    name: "Winnipeg / James Armstrong Richardson International Airport",
    elevation_ft: "783",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Winnipeg",
    gps_code: "CYWG",
    iata_code: "YWG",
    local_code: "YWG",
    coordinates: "-97.2398986816, 49.909999847399995",
  },
  {
    ident: "CYWH",
    type: "seaplane_base",
    name: "Victoria Harbour Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Victoria",
    gps_code: "CYWH",
    iata_code: "YWH",
    coordinates: "-123.388867378, 48.4249858939",
  },
  {
    ident: "CYWJ",
    type: "medium_airport",
    name: "D����line Airport",
    elevation_ft: "703",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "D����line",
    gps_code: "CYWJ",
    iata_code: "YWJ",
    coordinates: "-123.43599700927734, 65.21109771728516",
  },
  {
    ident: "CYWK",
    type: "medium_airport",
    name: "Wabush Airport",
    elevation_ft: "1808",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Wabush",
    gps_code: "CYWK",
    iata_code: "YWK",
    coordinates: "-66.8644027709961, 52.92190170288086",
  },
  {
    ident: "CYWL",
    type: "medium_airport",
    name: "Williams Lake Airport",
    elevation_ft: "3085",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Williams Lake",
    gps_code: "CYWL",
    iata_code: "YWL",
    coordinates: "-122.054000854, 52.1831016541",
  },
  {
    ident: "CYWP",
    type: "medium_airport",
    name: "Webequie Airport",
    elevation_ft: "685",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Webequie",
    gps_code: "CYWP",
    iata_code: "YWP",
    coordinates: "-87.3748683929, 52.9593933975",
  },
  {
    ident: "CYWY",
    type: "medium_airport",
    name: "Wrigley Airport",
    elevation_ft: "489",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Wrigley",
    gps_code: "CYWY",
    iata_code: "YWY",
    coordinates: "-123.43699645996094, 63.20940017700195",
  },
  {
    ident: "CYXC",
    type: "medium_airport",
    name: "Cranbrook/Canadian Rockies International Airport",
    elevation_ft: "3082",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Cranbrook",
    gps_code: "CYXC",
    iata_code: "YXC",
    coordinates: "-115.78199768066, 49.610801696777",
  },
  {
    ident: "CYXD",
    type: "closed",
    name: "Edmonton City Centre (Blatchford Field) Airport",
    elevation_ft: "2202",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Edmonton",
    gps_code: "CYXD",
    iata_code: "YXD",
    local_code: "CYXD",
    coordinates: "-113.521003723, 53.5724983215",
  },
  {
    ident: "CYXE",
    type: "medium_airport",
    name: "Saskatoon John G. Diefenbaker International Airport",
    elevation_ft: "1653",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Saskatoon",
    gps_code: "CYXE",
    iata_code: "YXE",
    coordinates: "-106.69999694824219, 52.170799255371094",
  },
  {
    ident: "CYXH",
    type: "medium_airport",
    name: "Medicine Hat Airport",
    elevation_ft: "2352",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Medicine Hat",
    gps_code: "CYXH",
    iata_code: "YXH",
    coordinates: "-110.72100067138672, 50.01890182495117",
  },
  {
    ident: "CYXI",
    type: "closed",
    name: "Bonnechere Airport",
    elevation_ft: "595",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Killaloe",
    gps_code: "CYXI",
    iata_code: "YXI",
    coordinates: "-77.60279846191406, 45.66310119628906",
  },
  {
    ident: "CYXJ",
    type: "medium_airport",
    name: "Fort St John Airport",
    elevation_ft: "2280",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Fort St.John",
    gps_code: "CYXJ",
    iata_code: "YXJ",
    coordinates: "-120.739998, 56.238098",
  },
  {
    ident: "CYXK",
    type: "medium_airport",
    name: "Rimouski Airport",
    elevation_ft: "82",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Rimouski",
    gps_code: "CYXK",
    iata_code: "YXK",
    coordinates: "-68.49690246582031, 48.47809982299805",
  },
  {
    ident: "CYXL",
    type: "medium_airport",
    name: "Sioux Lookout Airport",
    elevation_ft: "1258",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Sioux Lookout",
    gps_code: "CYXL",
    iata_code: "YXL",
    coordinates: "-91.9052963256836, 50.11389923095703",
  },
  {
    ident: "CYXN",
    type: "medium_airport",
    name: "Whale Cove Airport",
    elevation_ft: "40",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Whale Cove",
    gps_code: "CYXN",
    iata_code: "YXN",
    coordinates: "-92.59809875490001, 62.24000167849999",
  },
  {
    ident: "CYXP",
    type: "medium_airport",
    name: "Pangnirtung Airport",
    elevation_ft: "75",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Pangnirtung",
    gps_code: "CYXP",
    iata_code: "YXP",
    coordinates: "-65.71360015869999, 66.1449966431",
  },
  {
    ident: "CYXQ",
    type: "medium_airport",
    name: "Beaver Creek Airport",
    elevation_ft: "2131",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Beaver Creek",
    gps_code: "CYXQ",
    iata_code: "YXQ",
    coordinates: "-140.86700439453125, 62.410301208496094",
  },
  {
    ident: "CYXR",
    type: "medium_airport",
    name: "Earlton (Timiskaming Regional) Airport",
    elevation_ft: "800",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Earlton",
    gps_code: "CYXR",
    iata_code: "YXR",
    coordinates: "-79.8473453522, 47.697400654599996",
  },
  {
    ident: "CYXS",
    type: "medium_airport",
    name: "Prince George Airport",
    elevation_ft: "2267",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Prince George",
    gps_code: "CYXS",
    iata_code: "YXS",
    coordinates: "-122.679000854, 53.8894004822",
  },
  {
    ident: "CYXT",
    type: "medium_airport",
    name: "Northwest Regional Airport Terrace-Kitimat",
    elevation_ft: "713",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Terrace",
    gps_code: "CYXT",
    iata_code: "YXT",
    coordinates: "-128.576009, 54.468498",
  },
  {
    ident: "CYXU",
    type: "medium_airport",
    name: "London Airport",
    elevation_ft: "912",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "London",
    gps_code: "CYXU",
    iata_code: "YXU",
    coordinates: "-81.1539, 43.035599",
  },
  {
    ident: "CYXX",
    type: "medium_airport",
    name: "Abbotsford Airport",
    elevation_ft: "195",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Abbotsford",
    gps_code: "CYXX",
    iata_code: "YXX",
    coordinates: "-122.36100006103516, 49.025299072265625",
  },
  {
    ident: "CYXY",
    type: "medium_airport",
    name: "Whitehorse / Erik Nielsen International Airport",
    elevation_ft: "2317",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Whitehorse",
    gps_code: "CYXY",
    iata_code: "YXY",
    coordinates: "-135.067001343, 60.7095985413",
  },
  {
    ident: "CYXZ",
    type: "medium_airport",
    name: "Wawa Airport",
    elevation_ft: "942",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Wawa",
    gps_code: "CYXZ",
    iata_code: "YXZ",
    coordinates: "-84.78669738769531, 47.96670150756836",
  },
  {
    ident: "CYYB",
    type: "medium_airport",
    name: "North Bay Jack Garland Airport",
    elevation_ft: "1215",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "North Bay",
    gps_code: "CYYB",
    iata_code: "YYB",
    coordinates: "-79.422798, 46.363602",
  },
  {
    ident: "CYYC",
    type: "large_airport",
    name: "Calgary International Airport",
    elevation_ft: "3557",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Calgary",
    gps_code: "CYYC",
    iata_code: "YYC",
    coordinates: "-114.019996643, 51.113899231",
  },
  {
    ident: "CYYD",
    type: "medium_airport",
    name: "Smithers Airport",
    elevation_ft: "1712",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Smithers",
    gps_code: "CYYD",
    iata_code: "YYD",
    coordinates: "-127.18299865722656, 54.82469940185547",
  },
  {
    ident: "CYYE",
    type: "medium_airport",
    name: "Fort Nelson Airport",
    elevation_ft: "1253",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Fort Nelson",
    gps_code: "CYYE",
    iata_code: "YYE",
    coordinates: "-122.597000122, 58.8363990784",
  },
  {
    ident: "CYYF",
    type: "medium_airport",
    name: "Penticton Airport",
    elevation_ft: "1129",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Penticton",
    gps_code: "CYYF",
    iata_code: "YYF",
    coordinates: "-119.60199737548828, 49.46310043334961",
  },
  {
    ident: "CYYG",
    type: "medium_airport",
    name: "Charlottetown Airport",
    elevation_ft: "160",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-PE",
    municipality: "Charlottetown",
    gps_code: "CYYG",
    iata_code: "YYG",
    coordinates: "-63.12110137939453, 46.290000915527344",
  },
  {
    ident: "CYYH",
    type: "medium_airport",
    name: "Taloyoak Airport",
    elevation_ft: "92",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Taloyoak",
    gps_code: "CYYH",
    iata_code: "YYH",
    coordinates: "-93.576698, 69.5467",
  },
  {
    ident: "CYYJ",
    type: "large_airport",
    name: "Victoria International Airport",
    elevation_ft: "63",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Victoria",
    gps_code: "CYYJ",
    iata_code: "YYJ",
    coordinates: "-123.426002502, 48.646900177",
  },
  {
    ident: "CYYL",
    type: "medium_airport",
    name: "Lynn Lake Airport",
    elevation_ft: "1170",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Lynn Lake",
    gps_code: "CYYL",
    iata_code: "YYL",
    coordinates: "-101.07599639892578, 56.86389923095703",
  },
  {
    ident: "CYYM",
    type: "small_airport",
    name: "Cowley Airport",
    elevation_ft: "3876",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Cowley",
    gps_code: "CYYM",
    iata_code: "YYM",
    local_code: "YYM",
    coordinates: "-114.09400177, 49.636398315399994",
  },
  {
    ident: "CYYN",
    type: "medium_airport",
    name: "Swift Current Airport",
    elevation_ft: "2680",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Swift Current",
    gps_code: "CYYN",
    iata_code: "YYN",
    coordinates: "-107.691001892, 50.291900634799994",
  },
  {
    ident: "CYYQ",
    type: "medium_airport",
    name: "Churchill Airport",
    elevation_ft: "94",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Churchill",
    gps_code: "CYYQ",
    iata_code: "YYQ",
    coordinates: "-94.06500244140625, 58.739200592041016",
  },
  {
    ident: "CYYR",
    type: "medium_airport",
    name: "Goose Bay Airport",
    elevation_ft: "160",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Goose Bay",
    gps_code: "CYYR",
    iata_code: "YYR",
    coordinates: "-60.4258003235, 53.3191986084",
  },
  {
    ident: "CYYT",
    type: "large_airport",
    name: "St. John's International Airport",
    elevation_ft: "461",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "St. John's",
    gps_code: "CYYT",
    iata_code: "YYT",
    coordinates: "-52.7518997192, 47.618598938",
  },
  {
    ident: "CYYU",
    type: "medium_airport",
    name: "Kapuskasing Airport",
    elevation_ft: "743",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Kapuskasing",
    gps_code: "CYYU",
    iata_code: "YYU",
    coordinates: "-82.46749877929688, 49.41389846801758",
  },
  {
    ident: "CYYW",
    type: "medium_airport",
    name: "Armstrong Airport",
    elevation_ft: "1058",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Armstrong",
    gps_code: "CYYW",
    iata_code: "YYW",
    coordinates: "-88.90969848632812, 50.29029846191406",
  },
  {
    ident: "CYYY",
    type: "medium_airport",
    name: "Mont Joli Airport",
    elevation_ft: "172",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Mont-Joli",
    gps_code: "CYYY",
    iata_code: "YYY",
    coordinates: "-68.20809936523438, 48.60860061645508",
  },
  {
    ident: "CYYZ",
    type: "large_airport",
    name: "Lester B. Pearson International Airport",
    elevation_ft: "569",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Toronto",
    gps_code: "CYYZ",
    iata_code: "YYZ",
    local_code: "YYZ",
    coordinates: "-79.63059997559999, 43.6772003174",
  },
  {
    ident: "CYZE",
    type: "medium_airport",
    name: "Gore Bay Manitoulin Airport",
    elevation_ft: "635",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Gore Bay",
    gps_code: "CYZE",
    iata_code: "YZE",
    coordinates: "-82.56780242919922, 45.88529968261719",
  },
  {
    ident: "CYZF",
    type: "medium_airport",
    name: "Yellowknife Airport",
    elevation_ft: "675",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Yellowknife",
    gps_code: "CYZF",
    iata_code: "YZF",
    coordinates: "-114.44000244140625, 62.462799072265625",
  },
  {
    ident: "CYZG",
    type: "medium_airport",
    name: "Salluit Airport",
    elevation_ft: "743",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Salluit",
    gps_code: "CYZG",
    iata_code: "YZG",
    coordinates: "-75.66719818115234, 62.17940139770508",
  },
  {
    ident: "CYZH",
    type: "medium_airport",
    name: "Slave Lake Airport",
    elevation_ft: "1912",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Slave Lake",
    gps_code: "CYZH",
    iata_code: "YZH",
    coordinates: "-114.777000427, 55.2930984497",
  },
  {
    ident: "CYZP",
    type: "medium_airport",
    name: "Sandspit Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Sandspit",
    gps_code: "CYZP",
    iata_code: "YZP",
    coordinates: "-131.813995361, 53.25429916379999",
  },
  {
    ident: "CYZR",
    type: "medium_airport",
    name: "Chris Hadfield Airport",
    elevation_ft: "594",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Sarnia",
    gps_code: "CYZR",
    iata_code: "YZR",
    coordinates: "-82.30889892578125, 42.9994010925293",
  },
  {
    ident: "CYZS",
    type: "medium_airport",
    name: "Coral Harbour Airport",
    elevation_ft: "210",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Coral Harbour",
    gps_code: "CYZS",
    iata_code: "YZS",
    coordinates: "-83.3593978882, 64.1932983398",
  },
  {
    ident: "CYZT",
    type: "medium_airport",
    name: "Port Hardy Airport",
    elevation_ft: "71",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Port Hardy",
    gps_code: "CYZT",
    iata_code: "YZT",
    coordinates: "-127.36699676513672, 50.680599212646484",
  },
  {
    ident: "CYZU",
    type: "medium_airport",
    name: "Whitecourt Airport",
    elevation_ft: "2567",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Whitecourt",
    gps_code: "CYZU",
    iata_code: "YZU",
    coordinates: "-115.78700256347656, 54.14390182495117",
  },
  {
    ident: "CYZV",
    type: "medium_airport",
    name: "Sept-����les Airport",
    elevation_ft: "180",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Sept-����les",
    gps_code: "CYZV",
    iata_code: "YZV",
    coordinates: "-66.2656021118164, 50.22330093383789",
  },
  {
    ident: "CYZW",
    type: "medium_airport",
    name: "Teslin Airport",
    elevation_ft: "2313",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Teslin",
    gps_code: "CYZW",
    iata_code: "YZW",
    coordinates: "-132.7429962158203, 60.17279815673828",
  },
  {
    ident: "CYZX",
    type: "medium_airport",
    name: "CFB Greenwood",
    elevation_ft: "92",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NS",
    municipality: "Greenwood",
    gps_code: "CYZX",
    iata_code: "YZX",
    coordinates: "-64.91690063476562, 44.98440170288086",
  },
  {
    ident: "CZAC",
    type: "medium_airport",
    name: "York Landing Airport",
    elevation_ft: "621",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "York Landing",
    gps_code: "CZAC",
    iata_code: "ZAC",
    coordinates: "-96.08920288085938, 56.08940124511719",
  },
  {
    ident: "CZAM",
    type: "medium_airport",
    name: "Shuswap Regional Airport",
    elevation_ft: "1751",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Salmon Arm",
    gps_code: "CZAM",
    iata_code: "YSN",
    coordinates: "-119.228996, 50.682802",
  },
  {
    ident: "CZBB",
    type: "medium_airport",
    name: "Boundary Bay Airport",
    elevation_ft: "6",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Vancouver",
    gps_code: "CZBB",
    iata_code: "YDT",
    coordinates: "-123.012001, 49.0742",
  },
  {
    ident: "CZBD",
    type: "medium_airport",
    name: "Ilford Airport",
    elevation_ft: "642",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Ilford",
    gps_code: "CZBD",
    iata_code: "ILF",
    coordinates: "-95.613899231, 56.0614013672",
  },
  {
    ident: "CZBF",
    type: "medium_airport",
    name: "Bathurst Airport",
    elevation_ft: "193",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NB",
    municipality: "Bathurst",
    gps_code: "CZBF",
    iata_code: "ZBF",
    coordinates: "-65.738899231, 47.629699707",
  },
  {
    ident: "CZBM",
    type: "medium_airport",
    name: "Bromont (Roland Desourdy) Airport",
    elevation_ft: "375",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Bromont",
    gps_code: "CZBM",
    iata_code: "ZBM",
    coordinates: "-72.74140167239999, 45.2907981873",
  },
  {
    ident: "CZEE",
    type: "medium_airport",
    name: "Kelsey Airport",
    elevation_ft: "600",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Kelsey",
    gps_code: "CZEE",
    iata_code: "KES",
    local_code: "ZEE",
    coordinates: "-96.50969696039999, 56.0374984741",
  },
  {
    ident: "CZEM",
    type: "medium_airport",
    name: "Eastmain River Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Eastmain River",
    gps_code: "CZEM",
    iata_code: "ZEM",
    coordinates: "-78.52249908447266, 52.22639846801758",
  },
  {
    ident: "CZFA",
    type: "medium_airport",
    name: "Faro Airport",
    elevation_ft: "2351",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-YT",
    municipality: "Faro",
    gps_code: "CZFA",
    iata_code: "ZFA",
    coordinates: "-133.37600708007812, 62.20750045776367",
  },
  {
    ident: "CZFD",
    type: "medium_airport",
    name: "Fond-Du-Lac Airport",
    elevation_ft: "814",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Fond-Du-Lac",
    gps_code: "CZFD",
    iata_code: "ZFD",
    coordinates: "-107.18199920654297, 59.33440017700195",
  },
  {
    ident: "CZFG",
    type: "medium_airport",
    name: "Pukatawagan Airport",
    elevation_ft: "958",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Pukatawagan",
    gps_code: "CZFG",
    iata_code: "XPK",
    local_code: "ZFG",
    coordinates: "-101.26599884, 55.7491989136",
  },
  {
    ident: "CZFM",
    type: "medium_airport",
    name: "Fort Mcpherson Airport",
    elevation_ft: "116",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Fort Mcpherson",
    gps_code: "CZFM",
    iata_code: "ZFM",
    coordinates: "-134.86099243164062, 67.40750122070312",
  },
  {
    ident: "CZFN",
    type: "medium_airport",
    name: "Tulita Airport",
    elevation_ft: "332",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NT",
    municipality: "Tulita",
    gps_code: "CZFN",
    iata_code: "ZFN",
    coordinates: "-125.572998, 64.909697",
  },
  {
    ident: "CZGF",
    type: "medium_airport",
    name: "Grand Forks Airport",
    elevation_ft: "1720",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Grand Forks",
    gps_code: "CZGF",
    iata_code: "ZGF",
    coordinates: "-118.43099975585938, 49.01559829711914",
  },
  {
    ident: "CZGI",
    type: "medium_airport",
    name: "Gods River Airport",
    elevation_ft: "627",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Gods River",
    gps_code: "CZGI",
    iata_code: "ZGI",
    coordinates: "-94.07859802246094, 54.839698791503906",
  },
  {
    ident: "CZGR",
    type: "medium_airport",
    name: "Little Grand Rapids Airport",
    elevation_ft: "1005",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Little Grand Rapids",
    gps_code: "CZGR",
    iata_code: "ZGR",
    coordinates: "-95.4657974243164, 52.04560089111328",
  },
  {
    ident: "CZHP",
    type: "small_airport",
    name: "High Prairie Airport",
    elevation_ft: "1974",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "High Prairie",
    gps_code: "CZHP",
    iata_code: "ZHP",
    coordinates: "-116.474998474, 55.3936004639",
  },
  {
    ident: "CZJ",
    type: "small_airport",
    name: "Coraz����n de Jes����s Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Coraz����n de Jes����s and Nargan���� Islands",
    iata_code: "CZJ",
    coordinates: "-78.5888, 9.4446",
  },
  {
    ident: "CZJG",
    type: "medium_airport",
    name: "Jenpeg Airport",
    elevation_ft: "729",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Jenpeg",
    gps_code: "CZJG",
    iata_code: "ZJG",
    coordinates: "-98.04609680175781, 54.51890182495117",
  },
  {
    ident: "CZJN",
    type: "medium_airport",
    name: "Swan River Airport",
    elevation_ft: "1100",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Swan River",
    gps_code: "CZJN",
    iata_code: "ZJN",
    coordinates: "-101.23600006103516, 52.120601654052734",
  },
  {
    ident: "CZK",
    type: "small_airport",
    name: "Cascade Locks State Airport",
    elevation_ft: "151",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Cascade Locks",
    gps_code: "KCZK",
    iata_code: "CZK",
    local_code: "CZK",
    coordinates: "-121.878997803, 45.6768989563",
  },
  {
    ident: "CZKE",
    type: "medium_airport",
    name: "Kashechewan Airport",
    elevation_ft: "35",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Kashechewan",
    gps_code: "CZKE",
    iata_code: "ZKE",
    coordinates: "-81.67780303955078, 52.282501220703125",
  },
  {
    ident: "CZLQ",
    type: "medium_airport",
    name: "Thicket Portage Airport",
    elevation_ft: "678",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Thicket Portage",
    gps_code: "CZLQ",
    iata_code: "YTD",
    coordinates: "-97.70780181884766, 55.31890106201172",
  },
  {
    ident: "CZMD",
    type: "medium_airport",
    name: "Muskrat Dam Airport",
    elevation_ft: "911",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Muskrat Dam",
    gps_code: "CZMD",
    iata_code: "MSA",
    coordinates: "-91.76280212402344, 53.44139862060547",
  },
  {
    ident: "CZML",
    type: "small_airport",
    name: "South Cariboo Region / 108 Mile Airport",
    elevation_ft: "3126",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "108 Mile",
    gps_code: "CZML",
    iata_code: "ZMH",
    coordinates: "-121.333000183, 51.736099243199995",
  },
  {
    ident: "CZMN",
    type: "medium_airport",
    name: "Pikwitonei Airport",
    elevation_ft: "630",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Pikwitonei",
    gps_code: "CZMN",
    iata_code: "PIW",
    coordinates: "-97.16419982910156, 55.58890151977539",
  },
  {
    ident: "CZMT",
    type: "medium_airport",
    name: "Masset Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Masset",
    gps_code: "CZMT",
    iata_code: "ZMT",
    coordinates: "-132.125, 54.02750015258789",
  },
  {
    ident: "CZN",
    type: "small_airport",
    name: "Chisana Airport",
    elevation_ft: "3318",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chisana",
    gps_code: "CZN",
    iata_code: "CZN",
    local_code: "CZN",
    coordinates: "-142.04800415, 62.0712013245",
  },
  {
    ident: "CZNG",
    type: "medium_airport",
    name: "Poplar River Airport",
    elevation_ft: "728",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Poplar River",
    gps_code: "CZNG",
    iata_code: "XPP",
    coordinates: "-97.2741937637, 52.9965258788",
  },
  {
    ident: "CZO",
    type: "small_airport",
    name: "Chistochina Airport",
    elevation_ft: "1850",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chistochina",
    gps_code: "CZO",
    iata_code: "CZO",
    local_code: "CZO",
    coordinates: "-144.669006348, 62.5634994507",
  },
  {
    ident: "CZPB",
    type: "medium_airport",
    name: "Sachigo Lake Airport",
    elevation_ft: "876",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Sachigo Lake",
    gps_code: "CZPB",
    iata_code: "ZPB",
    coordinates: "-92.19640350341797, 53.8911018371582",
  },
  {
    ident: "CZPC",
    type: "medium_airport",
    name: "Pincher Creek Airport",
    elevation_ft: "3903",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-AB",
    municipality: "Pincher Creek",
    gps_code: "CZPC",
    iata_code: "WPC",
    coordinates: "-113.997001648, 49.520599365200006",
  },
  {
    ident: "CZPO",
    type: "small_airport",
    name: "Pinehouse Lake Airport",
    elevation_ft: "1278",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Pinehouse Lake",
    gps_code: "CZPO",
    iata_code: "ZPO",
    coordinates: "-106.582000732, 55.5280990601",
  },
  {
    ident: "CZRJ",
    type: "medium_airport",
    name: "Round Lake (Weagamow Lake) Airport",
    elevation_ft: "974",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Round Lake",
    gps_code: "CZRJ",
    iata_code: "ZRJ",
    coordinates: "-91.31279754638672, 52.943599700927734",
  },
  {
    ident: "CZSJ",
    type: "medium_airport",
    name: "Sandy Lake Airport",
    elevation_ft: "951",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Sandy Lake",
    gps_code: "CZSJ",
    iata_code: "ZSJ",
    coordinates: "-93.34439849853516, 53.06420135498047",
  },
  {
    ident: "CZSN",
    type: "medium_airport",
    name: "South Indian Lake Airport",
    elevation_ft: "951",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "South Indian Lake",
    gps_code: "CZSN",
    iata_code: "XSI",
    local_code: "ZSN",
    coordinates: "-98.9072036743, 56.7928009033",
  },
  {
    ident: "CZST",
    type: "medium_airport",
    name: "Stewart Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Stewart",
    gps_code: "CZST",
    iata_code: "ZST",
    local_code: "CZST",
    coordinates: "-129.982434511, 55.935410448",
  },
  {
    ident: "CZSW",
    type: "seaplane_base",
    name: "Prince Rupert/Seal Cove Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    gps_code: "CZSW",
    iata_code: "ZSW",
    coordinates: "-130.2830047607422, 54.33330154418945",
  },
  {
    ident: "CZTA",
    type: "small_airport",
    name: "Bloodvein River Airport",
    elevation_ft: "721",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Bloodvein River",
    gps_code: "CZTA",
    iata_code: "YDV",
    local_code: "ZTA",
    coordinates: "-96.692305, 51.784568",
  },
  {
    ident: "CZTM",
    type: "medium_airport",
    name: "Shamattawa Airport",
    elevation_ft: "289",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Shamattawa",
    gps_code: "CZTM",
    iata_code: "ZTM",
    coordinates: "-92.0813980102539, 55.8656005859375",
  },
  {
    ident: "CZUC",
    type: "medium_airport",
    name: "Ignace Municipal Airport",
    elevation_ft: "1435",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-ON",
    municipality: "Ignace",
    gps_code: "CZUC",
    iata_code: "ZUC",
    coordinates: "-91.7177963256836, 49.4296989440918",
  },
  {
    ident: "CZUM",
    type: "small_airport",
    name: "Churchill Falls Airport",
    elevation_ft: "1442",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NL",
    municipality: "Churchill Falls",
    gps_code: "CZUM",
    iata_code: "ZUM",
    coordinates: "-64.10639953613281, 53.5619010925293",
  },
  {
    ident: "CZWH",
    type: "medium_airport",
    name: "Lac Brochet Airport",
    elevation_ft: "1211",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-MB",
    municipality: "Lac Brochet",
    gps_code: "CZWH",
    iata_code: "XLB",
    local_code: "ZWH",
    coordinates: "-101.46900177, 58.6175003052",
  },
  {
    ident: "CZWL",
    type: "medium_airport",
    name: "Wollaston Lake Airport",
    elevation_ft: "1360",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-SK",
    municipality: "Wollaston Lake",
    gps_code: "CZWL",
    iata_code: "ZWL",
    coordinates: "-103.1719970703125, 58.10689926147461",
  },
  {
    ident: "D38",
    type: "small_airport",
    name: "Canandaigua Airport",
    elevation_ft: "814",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Ontario County IDA",
    gps_code: "KIUA",
    iata_code: "IUA",
    local_code: "IUA",
    coordinates: "-77.325226, 42.908902",
  },
  {
    ident: "D66",
    type: "small_airport",
    name: "Delta Junction Airport",
    elevation_ft: "1150",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Delta Junction",
    gps_code: "D66",
    iata_code: "DJN",
    local_code: "D66",
    coordinates: "-145.716995239, 64.0503997803",
  },
  {
    ident: "DA14",
    type: "small_airport",
    name: "Mostaganem Airport",
    elevation_ft: "732",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-27",
    gps_code: "DA14",
    iata_code: "MQV",
    local_code: "DA14",
    coordinates: "0.149382993579, 35.9087982178",
  },
  {
    ident: "DAAB",
    type: "medium_airport",
    name: "Blida Airport",
    elevation_ft: "535",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-09",
    gps_code: "DAAB",
    iata_code: "QLD",
    coordinates: "2.8141698837280273, 36.50360107421875",
  },
  {
    ident: "DAAD",
    type: "medium_airport",
    name: "Bou Saada Airport",
    elevation_ft: "1506",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-28",
    gps_code: "DAAD",
    iata_code: "BUJ",
    coordinates: "4.206389904022217, 35.33250045776367",
  },
  {
    ident: "DAAE",
    type: "medium_airport",
    name: "Soummam Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-06",
    municipality: "B����ja����a",
    gps_code: "DAAE",
    iata_code: "BJA",
    coordinates: "5.0699200630200005, 36.7120018005",
  },
  {
    ident: "DAAG",
    type: "large_airport",
    name: "Houari Boumediene Airport",
    elevation_ft: "82",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-35",
    municipality: "Algiers",
    gps_code: "DAAG",
    iata_code: "ALG",
    coordinates: "3.215409994125366, 36.691001892089844",
  },
  {
    ident: "DAAJ",
    type: "medium_airport",
    name: "Djanet Inedbirene Airport",
    elevation_ft: "3176",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-33",
    municipality: "Djanet",
    gps_code: "DAAJ",
    iata_code: "DJG",
    coordinates: "9.45244, 24.292801",
  },
  {
    ident: "DAAP",
    type: "medium_airport",
    name: "Illizi Takhamalt Airport",
    elevation_ft: "1778",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-33",
    municipality: "Illizi",
    gps_code: "DAAP",
    iata_code: "VVZ",
    coordinates: "8.62265014648, 26.7234992981",
  },
  {
    ident: "DAAS",
    type: "medium_airport",
    name: "Ain Arnat Airport",
    elevation_ft: "3360",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-19",
    municipality: "S����tif",
    gps_code: "DAAS",
    iata_code: "QSF",
    coordinates: "5.3244900703399995, 36.178100585900005",
  },
  {
    ident: "DAAT",
    type: "medium_airport",
    name: "Aguenar ������ Hadj Bey Akhamok Airport",
    elevation_ft: "4518",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-11",
    municipality: "Tamanrasset",
    gps_code: "DAAT",
    iata_code: "TMR",
    coordinates: "5.45107984543, 22.8115005493",
  },
  {
    ident: "DAAV",
    type: "medium_airport",
    name: "Jijel Ferhat Abbas Airport",
    elevation_ft: "36",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-18",
    municipality: "Jijel",
    gps_code: "DAAV",
    iata_code: "GJL",
    coordinates: "5.87361001968, 36.7951011658",
  },
  {
    ident: "DAAY",
    type: "medium_airport",
    name: "Mecheria Airport",
    elevation_ft: "3855",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-45",
    municipality: "Mecheria",
    gps_code: "DAAY",
    iata_code: "MZW",
    coordinates: "-0.242353007197, 33.535900116",
  },
  {
    ident: "DABB",
    type: "medium_airport",
    name: "Rabah Bitat Airport",
    elevation_ft: "16",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-36",
    municipality: "Annaba",
    gps_code: "DABB",
    iata_code: "AAE",
    coordinates: "7.809174, 36.822201",
  },
  {
    ident: "DABC",
    type: "medium_airport",
    name: "Mohamed Boudiaf International Airport",
    elevation_ft: "2265",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-25",
    municipality: "Constantine",
    gps_code: "DABC",
    iata_code: "CZL",
    coordinates: "6.620389938354492, 36.2760009765625",
  },
  {
    ident: "DABO",
    type: "small_airport",
    name: "Oum el Bouaghi airport",
    elevation_ft: "2980",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-04",
    municipality: "Oum El Bouaghi",
    gps_code: "DAEO",
    iata_code: "QMH",
    coordinates: "7.270800113679999, 35.879699707",
  },
  {
    ident: "DABP",
    type: "closed",
    name: "Skikda Airport",
    elevation_ft: "27",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-21",
    municipality: "Skikda",
    gps_code: "DABP",
    iata_code: "SKI",
    coordinates: "6.9516, 36.8641",
  },
  {
    ident: "DABS",
    type: "medium_airport",
    name: "Cheikh Larbi T����bessi Airport",
    elevation_ft: "2661",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-12",
    municipality: "T����bessi",
    gps_code: "DABS",
    iata_code: "TEE",
    coordinates: "8.12071990967, 35.4315986633",
  },
  {
    ident: "DABT",
    type: "medium_airport",
    name: "Batna Airport",
    elevation_ft: "2697",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-05",
    municipality: "Batna",
    gps_code: "DABT",
    iata_code: "BLJ",
    coordinates: "6.308589935300001, 35.752101898199996",
  },
  {
    ident: "DAF",
    type: "small_airport",
    name: "Daup Airport",
    elevation_ft: "280",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    iata_code: "DAF",
    local_code: "DAUP",
    coordinates: "145.9515, -4.7403",
  },
  {
    ident: "DAFH",
    type: "medium_airport",
    name: "Hassi R'Mel Airport",
    elevation_ft: "2540",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-03",
    gps_code: "DAFH",
    iata_code: "HRM",
    coordinates: "3.311539888381958, 32.93040084838867",
  },
  {
    ident: "DAFI",
    type: "small_airport",
    name: "Tsletsi Airport",
    elevation_ft: "3753",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-17",
    municipality: "Djelfa",
    gps_code: "DAFI",
    iata_code: "QDJ",
    coordinates: "3.351, 34.6657",
  },
  {
    ident: "DAO",
    type: "small_airport",
    name: "Dabo Airport",
    elevation_ft: "118",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    iata_code: "DAO",
    local_code: "DABO",
    coordinates: "147.843055556, -8.431944444440001",
  },
  {
    ident: "DAOB",
    type: "medium_airport",
    name: "Bou Chekif Airport",
    elevation_ft: "3245",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-14",
    municipality: "Tiaret",
    gps_code: "DAOB",
    iata_code: "TID",
    coordinates: "1.46315002441, 35.3410987854",
  },
  {
    ident: "DAOF",
    type: "medium_airport",
    name: "Tindouf Airport",
    elevation_ft: "1453",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-37",
    municipality: "Tindouf",
    gps_code: "DAOF",
    iata_code: "TIN",
    coordinates: "-8.167099952700001, 27.7003993988",
  },
  {
    ident: "DAOI",
    type: "medium_airport",
    name: "Ech Cheliff Airport",
    elevation_ft: "463",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-02",
    gps_code: "DAOI",
    iata_code: "CFK",
    coordinates: "1.33176994324, 36.2126998901",
  },
  {
    ident: "DAOL",
    type: "medium_airport",
    name: "Tafaraoui Airport",
    elevation_ft: "367",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-31",
    gps_code: "DAOL",
    iata_code: "TAF",
    coordinates: "-0.5322780013084412, 35.54240036010742",
  },
  {
    ident: "DAON",
    type: "medium_airport",
    name: "Zenata ������ Messali El Hadj Airport",
    elevation_ft: "814",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-13",
    municipality: "Tlemcen",
    gps_code: "DAON",
    iata_code: "TLM",
    coordinates: "-1.45000004768, 35.0167007446",
  },
  {
    ident: "DAOO",
    type: "medium_airport",
    name: "Es Senia Airport",
    elevation_ft: "295",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-31",
    municipality: "Oran",
    gps_code: "DAOO",
    iata_code: "ORN",
    coordinates: "-0.6211829781529999, 35.6239013672",
  },
  {
    ident: "DAOR",
    type: "medium_airport",
    name: "B����char Boudghene Ben Ali Lotfi Airport",
    elevation_ft: "2661",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-08",
    municipality: "B����char",
    gps_code: "DAOR",
    iata_code: "CBH",
    coordinates: "-2.269860029220581, 31.645700454711914",
  },
  {
    ident: "DAOS",
    type: "small_airport",
    name: "Sidi Bel Abbes Airport",
    elevation_ft: "1614",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-22",
    municipality: "Sidi Bel Abb����s",
    gps_code: "DAOS",
    iata_code: "BFW",
    coordinates: "-0.593275010586, 35.1717987061",
  },
  {
    ident: "DAOV",
    type: "medium_airport",
    name: "Ghriss Airport",
    elevation_ft: "1686",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-29",
    gps_code: "DAOV",
    iata_code: "MUW",
    coordinates: "0.14714199304580688, 35.207698822021484",
  },
  {
    ident: "DAOY",
    type: "small_airport",
    name: "El Bayadh Airport",
    elevation_ft: "4493",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-32",
    municipality: "El Bayadh",
    gps_code: "DAOY",
    iata_code: "EBH",
    coordinates: "1.0925, 33.721666666699996",
  },
  {
    ident: "DATG",
    type: "small_airport",
    name: "In Guezzam Airport",
    elevation_ft: "1312",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-11",
    municipality: "In Guezzam",
    gps_code: "DATG",
    iata_code: "INF",
    coordinates: "5.75, 19.566999435424805",
  },
  {
    ident: "DATM",
    type: "small_airport",
    name: "Bordj Badji Mokhtar Airport",
    elevation_ft: "1303",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-01",
    municipality: "Bordj Badji Mokhtar",
    gps_code: "DATM",
    iata_code: "BMW",
    coordinates: "0.923888981342, 21.375",
  },
  {
    ident: "DAUA",
    type: "medium_airport",
    name: "Touat Cheikh Sidi Mohamed Belkebir Airport",
    elevation_ft: "919",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-01",
    gps_code: "DAUA",
    iata_code: "AZR",
    coordinates: "-0.18641400337219238, 27.837600708007812",
  },
  {
    ident: "DAUB",
    type: "medium_airport",
    name: "Biskra Airport",
    elevation_ft: "289",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-07",
    municipality: "Biskra",
    gps_code: "DAUB",
    iata_code: "BSK",
    coordinates: "5.73823022842, 34.793300628699996",
  },
  {
    ident: "DAUE",
    type: "medium_airport",
    name: "El Golea Airport",
    elevation_ft: "1306",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-47",
    gps_code: "DAUE",
    iata_code: "ELG",
    coordinates: "2.8595900535583496, 30.571300506591797",
  },
  {
    ident: "DAUG",
    type: "medium_airport",
    name: "Noum����rat - Moufdi Zakaria Airport",
    elevation_ft: "1512",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-47",
    municipality: "Gharda����a",
    gps_code: "DAUG",
    iata_code: "GHA",
    coordinates: "3.794110059738159, 32.38410186767578",
  },
  {
    ident: "DAUH",
    type: "medium_airport",
    name: "Oued Irara Airport",
    elevation_ft: "463",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-30",
    municipality: "Hassi Messaoud",
    gps_code: "DAUH",
    iata_code: "HME",
    coordinates: "6.140439987180001, 31.673000335699996",
  },
  {
    ident: "DAUI",
    type: "medium_airport",
    name: "In Salah Airport",
    elevation_ft: "896",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-11",
    municipality: "In Salah",
    gps_code: "DAUI",
    iata_code: "INZ",
    coordinates: "2.51202011108, 27.250999450699997",
  },
  {
    ident: "DAUK",
    type: "medium_airport",
    name: "Touggourt Sidi Madhi Airport",
    elevation_ft: "279",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-30",
    municipality: "Touggourt",
    gps_code: "DAUK",
    iata_code: "TGR",
    coordinates: "6.088669776916504, 33.06779861450195",
  },
  {
    ident: "DAUL",
    type: "medium_airport",
    name: "Laghouat Airport",
    elevation_ft: "2510",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-03",
    municipality: "Laghouat",
    gps_code: "DAUL",
    iata_code: "LOO",
    coordinates: "2.92833995819, 33.764400482199996",
  },
  {
    ident: "DAUO",
    type: "medium_airport",
    name: "Guemar Airport",
    elevation_ft: "203",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-39",
    municipality: "Guemar",
    gps_code: "DAUO",
    iata_code: "ELU",
    coordinates: "6.77679014206, 33.5113983154",
  },
  {
    ident: "DAUT",
    type: "medium_airport",
    name: "Timimoun Airport",
    elevation_ft: "1027",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-01",
    municipality: "Timimoun",
    gps_code: "DAUT",
    iata_code: "TMX",
    coordinates: "0.276033014059, 29.2371006012",
  },
  {
    ident: "DAUU",
    type: "medium_airport",
    name: "Ain el Beida Airport",
    elevation_ft: "492",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-30",
    municipality: "Ouargla",
    gps_code: "DAUU",
    iata_code: "OGX",
    coordinates: "5.412779808044434, 31.917200088500977",
  },
  {
    ident: "DAUZ",
    type: "medium_airport",
    name: "In Am����nas Airport",
    elevation_ft: "1847",
    continent: "AF",
    iso_country: "DZ",
    iso_region: "DZ-33",
    municipality: "Am����nas",
    gps_code: "DAUZ",
    iata_code: "IAM",
    coordinates: "9.64291, 28.0515",
  },
  {
    ident: "DBBB",
    type: "medium_airport",
    name: "Cadjehoun Airport",
    elevation_ft: "19",
    continent: "AF",
    iso_country: "BJ",
    iso_region: "BJ-AQ",
    municipality: "Cotonou",
    gps_code: "DBBB",
    iata_code: "COO",
    coordinates: "2.384350061416626, 6.357230186462402",
  },
  {
    ident: "DBBD",
    type: "small_airport",
    name: "Djougou Airport",
    elevation_ft: "1480",
    continent: "AF",
    iso_country: "BJ",
    iso_region: "BJ-DO",
    municipality: "Djougou",
    gps_code: "DBBD",
    iata_code: "DJA",
    coordinates: "1.63777777778, 9.69208333333",
  },
  {
    ident: "DBBK",
    type: "small_airport",
    name: "Kandi Airport",
    elevation_ft: "951",
    continent: "AF",
    iso_country: "BJ",
    iso_region: "BJ-AL",
    municipality: "Kandi",
    gps_code: "DBBK",
    iata_code: "KDC",
    coordinates: "2.940381, 11.14479",
  },
  {
    ident: "DBBN",
    type: "small_airport",
    name: "Natitingou Airport",
    elevation_ft: "1512",
    continent: "AF",
    iso_country: "BJ",
    iso_region: "BJ-AK",
    municipality: "Natitingou",
    gps_code: "DBBN",
    iata_code: "NAE",
    coordinates: "1.360507, 10.376965",
  },
  {
    ident: "DBBP",
    type: "small_airport",
    name: "Parakou Airport",
    elevation_ft: "1266",
    continent: "AF",
    iso_country: "BJ",
    iso_region: "BJ-BO",
    municipality: "Parakou",
    gps_code: "DBBP",
    iata_code: "PKO",
    coordinates: "2.609679937362671, 9.35768985748291",
  },
  {
    ident: "DBBS",
    type: "small_airport",
    name: "Sav���� Airport",
    elevation_ft: "597",
    continent: "AF",
    iso_country: "BJ",
    iso_region: "BJ-CO",
    municipality: "Sav����",
    gps_code: "DBBS",
    iata_code: "SVF",
    coordinates: "2.4645800590515137, 8.018170356750488",
  },
  {
    ident: "DBC",
    type: "medium_airport",
    name: "Baicheng Chang'an Airport",
    elevation_ft: "480",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-22",
    municipality: "Baicheng",
    gps_code: "ZYBA",
    iata_code: "DBC",
    coordinates: "123.019722, 45.505278",
  },
  {
    ident: "DBK",
    type: "seaplane_base",
    name: "Dutch Bay SPB",
    elevation_ft: "0",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-6",
    municipality: "Kalpitiya Island",
    iata_code: "DBK",
    coordinates: "79.756, 8.273",
  },
  {
    ident: "DBP",
    type: "small_airport",
    name: "Debepare Airport",
    elevation_ft: "360",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Debepare",
    gps_code: "AYDB",
    iata_code: "DBP",
    local_code: "DBP",
    coordinates: "141.905555556, -6.30861111111",
  },
  {
    ident: "DBU",
    type: "seaplane_base",
    name: "Dambulu Oya Tank Seaplane Base",
    elevation_ft: "535",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-2",
    municipality: "Dambulla",
    iata_code: "DBU",
    coordinates: "80.6304, 7.8604",
  },
  {
    ident: "DCG",
    type: "seaplane_base",
    name: "Dubai Creek SPB",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-DU",
    municipality: "Dubai",
    iata_code: "DCG",
    coordinates: "55.3313888889, 25.2422222222",
  },
  {
    ident: "DCK",
    type: "small_airport",
    name: "Dahl Creek Airport",
    elevation_ft: "260",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Dahl Creek",
    gps_code: "DCK",
    iata_code: "DCK",
    local_code: "DCK",
    coordinates: "-156.904998779, 66.9432983398",
  },
  {
    ident: "DCR",
    type: "closed",
    name: "Decatur HI-Way Airfield",
    elevation_ft: "842",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Decatur",
    iata_code: "DCR",
    local_code: "DCR",
    coordinates: "-84.862464, 40.837548",
  },
  {
    ident: "DDM",
    type: "small_airport",
    name: "Dodoima Airport",
    elevation_ft: "75",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    iata_code: "DDM",
    local_code: "DDM",
    coordinates: "147.809444444, -8.17736111111",
  },
  {
    ident: "DE-0419",
    type: "closed",
    name: "Heidelberg Army Helicopter Base",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BW",
    municipality: "Heidelberg",
    gps_code: "ETIE",
    iata_code: "HDB",
    coordinates: "8.651935, 49.392397",
  },
  {
    ident: "DE-0428",
    type: "closed",
    name: "B����blingen Flugfeld",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BW",
    municipality: "B����blingen",
    iata_code: "PHM",
    coordinates: "8.996193, 48.689544",
  },
  {
    ident: "DE-0429",
    type: "closed",
    name: "B����blingen Flugfeld",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BW",
    municipality: "B����blingen",
    iata_code: "PHM",
    coordinates: "8.996193, 48.689544",
  },
  {
    ident: "DER",
    type: "small_airport",
    name: "Derim Airport",
    elevation_ft: "4850",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Derim",
    gps_code: "AYDE",
    iata_code: "DER",
    local_code: "DEM",
    coordinates: "147.107222222, -6.14472222222",
  },
  {
    ident: "DEX",
    type: "small_airport",
    name: "Nop Goliat Airport",
    elevation_ft: "198",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Dekai - Yahukimo",
    gps_code: "WAVD",
    iata_code: "DEX",
    coordinates: "139.482006, -4.8557",
  },
  {
    ident: "DFCA",
    type: "small_airport",
    name: "Kaya Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-SMT",
    municipality: "Kaya",
    gps_code: "DFCA",
    iata_code: "XKY",
    coordinates: "-1.100000023841858, 13.067000389099121",
  },
  {
    ident: "DFCC",
    type: "small_airport",
    name: "Ouahigouya Airport",
    elevation_ft: "1106",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-YAT",
    municipality: "Ouahigouya",
    gps_code: "DFCC",
    iata_code: "OUG",
    coordinates: "-2.4170000553131104, 13.567000389099121",
  },
  {
    ident: "DFCJ",
    type: "small_airport",
    name: "Djibo Airport",
    elevation_ft: "1001",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-SOM",
    municipality: "Djibo",
    gps_code: "DFCJ",
    iata_code: "XDJ",
    coordinates: "-1.6330000162124634, 14.100000381469727",
  },
  {
    ident: "DFCL",
    type: "small_airport",
    name: "Leo Airport",
    elevation_ft: "1181",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-SIS",
    municipality: "Leo",
    gps_code: "DFCL",
    iata_code: "XLU",
    coordinates: "-2.0999999046325684, 11.100000381469727",
  },
  {
    ident: "DFCP",
    type: "small_airport",
    name: "Po Airport",
    elevation_ft: "1050",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-NAO",
    municipality: "Po",
    gps_code: "DFCP",
    iata_code: "PUP",
    coordinates: "-1.149999976158142, 11.149999618530273",
  },
  {
    ident: "DFEA",
    type: "small_airport",
    name: "Boulsa Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-NAM",
    municipality: "Boulsa",
    gps_code: "DFEA",
    iata_code: "XBO",
    coordinates: "-0.5669999718666077, 12.649999618530273",
  },
  {
    ident: "DFEB",
    type: "small_airport",
    name: "Bogande Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-GNA",
    municipality: "Bogande",
    gps_code: "DFEB",
    iata_code: "XBG",
    coordinates: "-0.16699999570846558, 12.982999801635742",
  },
  {
    ident: "DFED",
    type: "small_airport",
    name: "Diapaga Airport",
    elevation_ft: "951",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-TAP",
    municipality: "Diapaga",
    gps_code: "DFED",
    iata_code: "DIP",
    coordinates: "1.784631, 12.060324",
  },
  {
    ident: "DFEE",
    type: "small_airport",
    name: "Dori Airport",
    elevation_ft: "919",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-SEN",
    municipality: "Dori",
    gps_code: "DFEE",
    iata_code: "DOR",
    coordinates: "-0.032999999821186066, 14.032999992370605",
  },
  {
    ident: "DFEF",
    type: "small_airport",
    name: "Fada N'gourma Airport",
    elevation_ft: "1011",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-GOU",
    municipality: "Fada N'gourma",
    gps_code: "DFEF",
    iata_code: "FNG",
    coordinates: "0.3499999940395355, 12.032999992370605",
  },
  {
    ident: "DFEG",
    type: "small_airport",
    name: "Gorom-Gorom Airport",
    elevation_ft: "935",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-OUD",
    municipality: "Gorom-Gorom",
    gps_code: "DFEG",
    iata_code: "XGG",
    coordinates: "-0.2329999953508377, 14.449999809265137",
  },
  {
    ident: "DFEL",
    type: "small_airport",
    name: "Kantchari Airport",
    elevation_ft: "879",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-TAP",
    municipality: "Kantchari",
    gps_code: "DFEL",
    iata_code: "XKA",
    coordinates: "1.5, 12.467000007629395",
  },
  {
    ident: "DFEM",
    type: "small_airport",
    name: "Tambao Airport",
    elevation_ft: "820",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-OUD",
    municipality: "Tambao",
    gps_code: "DFEM",
    iata_code: "TMQ",
    coordinates: "0.05000000074505806, 14.800000190734863",
  },
  {
    ident: "DFEP",
    type: "small_airport",
    name: "Pama Airport",
    elevation_ft: "699",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-KMP",
    municipality: "Pama",
    gps_code: "DFEP",
    iata_code: "XPA",
    coordinates: "0.699999988079071, 11.25",
  },
  {
    ident: "DFER",
    type: "small_airport",
    name: "Arly Airport",
    elevation_ft: "761",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-TAP",
    municipality: "Arly",
    gps_code: "DFER",
    iata_code: "ARL",
    coordinates: "1.4830000400543213, 11.597000122070312",
  },
  {
    ident: "DFES",
    type: "small_airport",
    name: "Sebba Airport",
    elevation_ft: "886",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-SEN",
    municipality: "Sebba",
    gps_code: "DFES",
    iata_code: "XSE",
    coordinates: "0.5170000195503235, 13.449999809265137",
  },
  {
    ident: "DFET",
    type: "small_airport",
    name: "Tenkodogo Airport",
    elevation_ft: "1017",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-BLG",
    municipality: "Tenkodogo",
    gps_code: "DFET",
    iata_code: "TEG",
    coordinates: "-0.367000013589859, 11.800000190734863",
  },
  {
    ident: "DFEZ",
    type: "small_airport",
    name: "Zabr���� Airport",
    elevation_ft: "886",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-BLG",
    municipality: "Zabr����",
    gps_code: "DFEZ",
    iata_code: "XZA",
    coordinates: "-0.6169999837875366, 11.166999816894531",
  },
  {
    ident: "DFFD",
    type: "large_airport",
    name: "Ouagadougou Airport",
    elevation_ft: "1037",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-KAD",
    municipality: "Ouagadougou",
    gps_code: "DFFD",
    iata_code: "OUA",
    coordinates: "-1.51242, 12.3532",
  },
  {
    ident: "DFOB",
    type: "small_airport",
    name: "Banfora Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-COM",
    municipality: "Banfora",
    gps_code: "DFOB",
    iata_code: "BNR",
    coordinates: "-4.7170000076293945, 10.682999610900879",
  },
  {
    ident: "DFOD",
    type: "small_airport",
    name: "Dedougou Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-MOU",
    municipality: "Dedougou",
    gps_code: "DFOD",
    iata_code: "DGU",
    coordinates: "-3.490000009536743, 12.458999633789062",
  },
  {
    ident: "DFOG",
    type: "small_airport",
    name: "Gaoua Airport",
    elevation_ft: "1099",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-PON",
    municipality: "Gaoua",
    gps_code: "DFOG",
    iata_code: "XGA",
    coordinates: "-3.163454, 10.384059",
  },
  {
    ident: "DFON",
    type: "small_airport",
    name: "Nouna Airport",
    elevation_ft: "886",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-KOS",
    municipality: "Nouna",
    gps_code: "DFON",
    iata_code: "XNU",
    coordinates: "-3.867000103, 12.75",
  },
  {
    ident: "DFOO",
    type: "medium_airport",
    name: "Bobo Dioulasso Airport",
    elevation_ft: "1511",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-HOU",
    municipality: "Bobo Dioulasso",
    gps_code: "DFOO",
    iata_code: "BOY",
    coordinates: "-4.33096981048584, 11.160099983215332",
  },
  {
    ident: "DFOT",
    type: "small_airport",
    name: "Tougan Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-SOR",
    municipality: "Tougan",
    gps_code: "DFOT",
    iata_code: "TUQ",
    coordinates: "-3.066999912261963, 13.067000389099121",
  },
  {
    ident: "DFOU",
    type: "small_airport",
    name: "Diebougou Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-BGR",
    municipality: "Diebougou",
    gps_code: "DFOU",
    iata_code: "XDE",
    coordinates: "-3.25, 10.949999809265137",
  },
  {
    ident: "DFOY",
    type: "small_airport",
    name: "Aribinda Airport",
    elevation_ft: "1122",
    continent: "AF",
    iso_country: "BF",
    iso_region: "BF-SOM",
    municipality: "Aribinda",
    gps_code: "DFOY",
    iata_code: "XAR",
    coordinates: "-0.883000016212, 14.217000007600001",
  },
  {
    ident: "DGAA",
    type: "large_airport",
    name: "Kotoka International Airport",
    elevation_ft: "205",
    continent: "AF",
    iso_country: "GH",
    iso_region: "GH-AA",
    municipality: "Accra",
    gps_code: "DGAA",
    iata_code: "ACC",
    coordinates: "-0.16678600013256073, 5.605189800262451",
  },
  {
    ident: "DGG",
    type: "closed",
    name: "Fishermans Airfield",
    elevation_ft: "24",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Daugo Island",
    iata_code: "DGG",
    coordinates: "147.0505, -9.5128",
  },
  {
    ident: "DGLE",
    type: "medium_airport",
    name: "Tamale Airport",
    elevation_ft: "553",
    continent: "AF",
    iso_country: "GH",
    iso_region: "GH-NP",
    municipality: "Tamale",
    gps_code: "DGLE",
    iata_code: "TML",
    coordinates: "-0.8632140159606934, 9.55718994140625",
  },
  {
    ident: "DGM",
    type: "seaplane_base",
    name: "Dandugama Seaplane Base",
    elevation_ft: "0",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-1",
    municipality: "Colombo",
    iata_code: "DGM",
    coordinates: "79.8721, 7.1079",
  },
  {
    ident: "DGSI",
    type: "medium_airport",
    name: "Kumasi Airport",
    elevation_ft: "942",
    continent: "AF",
    iso_country: "GH",
    iso_region: "GH-AH",
    municipality: "Kumasi",
    gps_code: "DGSI",
    iata_code: "KMS",
    coordinates: "-1.5908199548721313, 6.714560031890869",
  },
  {
    ident: "DGSN",
    type: "medium_airport",
    name: "Sunyani Airport",
    elevation_ft: "1014",
    continent: "AF",
    iso_country: "GH",
    iso_region: "GH-BA",
    municipality: "Sunyani",
    gps_code: "DGSN",
    iata_code: "NYI",
    coordinates: "-2.3287599086761475, 7.361830234527588",
  },
  {
    ident: "DGTK",
    type: "medium_airport",
    name: "Takoradi Airport",
    elevation_ft: "21",
    continent: "AF",
    iso_country: "GH",
    iso_region: "GH-WP",
    municipality: "Sekondi-Takoradi",
    gps_code: "DGTK",
    iata_code: "TKD",
    coordinates: "-1.7747600078582764, 4.896059989929199",
  },
  {
    ident: "DHB",
    type: "seaplane_base",
    name: "Deer Harbor SPB",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Deer Harbor",
    iata_code: "DHB",
    coordinates: "-123.002777778, 48.6166666667",
  },
  {
    ident: "DHG",
    type: "small_airport",
    name: "Dalnegorsk Airport",
    elevation_ft: "833",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Dalnegorsk",
    iata_code: "DHG",
    coordinates: "135.490005, 44.558748",
  },
  {
    ident: "DIAO",
    type: "small_airport",
    name: "Aboisso Airport",
    elevation_ft: "95",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-13",
    municipality: "Aboisso",
    gps_code: "DIAO",
    iata_code: "ABO",
    coordinates: "-3.234722, 5.461944",
  },
  {
    ident: "DIAP",
    type: "medium_airport",
    name: "Port Bouet Airport",
    elevation_ft: "21",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-01",
    municipality: "Abidjan",
    gps_code: "DIAP",
    iata_code: "ABJ",
    coordinates: "-3.9262900352478027, 5.261390209197998",
  },
  {
    ident: "DIAU",
    type: "small_airport",
    name: "Abengourou Airport",
    elevation_ft: "676",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-05",
    municipality: "Abengourou",
    gps_code: "DIAU",
    iata_code: "OGO",
    coordinates: "-3.4702799320220947, 6.715559959411621",
  },
  {
    ident: "DIBI",
    type: "small_airport",
    name: "Boundiali Airport",
    elevation_ft: "1286",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-03",
    municipality: "Boundiali",
    gps_code: "DIBI",
    iata_code: "BXI",
    coordinates: "-6.4670000076293945, 9.532999992370605",
  },
  {
    ident: "DIBK",
    type: "medium_airport",
    name: "Bouak���� Airport",
    elevation_ft: "1230",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-04",
    gps_code: "DIBK",
    iata_code: "BYK",
    coordinates: "-5.073669910430908, 7.738800048828125",
  },
  {
    ident: "DIBN",
    type: "small_airport",
    name: "Bouna Airport",
    elevation_ft: "1148",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-08",
    municipality: "Bouna",
    gps_code: "DIBN",
    iata_code: "BQO",
    coordinates: "-3.025279998779297, 9.27750015258789",
  },
  {
    ident: "DIBU",
    type: "small_airport",
    name: "Soko Airport",
    elevation_ft: "1247",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-08",
    municipality: "Bondoukou",
    gps_code: "DIBU",
    iata_code: "BDK",
    coordinates: "-2.7619400024414062, 8.017219543457031",
  },
  {
    ident: "DIDK",
    type: "small_airport",
    name: "Dimbokro Airport",
    elevation_ft: "344",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-11",
    municipality: "Dimbokro",
    gps_code: "DIDK",
    iata_code: "DIM",
    coordinates: "-4.640560150146484, 6.651669979095459",
  },
  {
    ident: "DIDL",
    type: "medium_airport",
    name: "Daloa Airport",
    elevation_ft: "823",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-02",
    gps_code: "DIDL",
    iata_code: "DJO",
    coordinates: "-6.473189830780029, 6.792809963226318",
  },
  {
    ident: "DIFK",
    type: "small_airport",
    name: "Ferkessedougou Airport",
    elevation_ft: "1102",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-03",
    municipality: "Ferkessedougou",
    gps_code: "DIFK",
    iata_code: "FEK",
    coordinates: "-5.1833333, 9.6",
  },
  {
    ident: "DIGA",
    type: "small_airport",
    name: "Gagnoa Airport",
    elevation_ft: "732",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-02",
    municipality: "Gagnoa",
    gps_code: "DIGA",
    iata_code: "GGN",
    coordinates: "-5.949999809265137, 6.132999897003174",
  },
  {
    ident: "DIGL",
    type: "small_airport",
    name: "Guiglo Airport",
    elevation_ft: "722",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-19",
    municipality: "Guiglo",
    gps_code: "DIGL",
    iata_code: "GGO",
    coordinates: "-7.526847, 6.534711",
  },
  {
    ident: "DIGN",
    type: "small_airport",
    name: "Nero-Mer Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-09",
    municipality: "Grand-B����r����by",
    gps_code: "DIGN",
    iata_code: "BBV",
    coordinates: "-6.923961639400001, 4.64341306686",
  },
  {
    ident: "DIKO",
    type: "medium_airport",
    name: "Korhogo Airport",
    elevation_ft: "1214",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-03",
    municipality: "Korhogo",
    gps_code: "DIKO",
    iata_code: "HGO",
    coordinates: "-5.55666017532, 9.38718032837",
  },
  {
    ident: "DIMN",
    type: "medium_airport",
    name: "Man Airport",
    elevation_ft: "1089",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-06",
    gps_code: "DIMN",
    iata_code: "MJC",
    coordinates: "-7.58735990524292, 7.272069931030273",
  },
  {
    ident: "DIOD",
    type: "small_airport",
    name: "Odienne Airport",
    elevation_ft: "1365",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-10",
    municipality: "Odienne",
    gps_code: "DIOD",
    iata_code: "KEO",
    coordinates: "-7.566999912261963, 9.5",
  },
  {
    ident: "DIOF",
    type: "small_airport",
    name: "Ouango Fitini Airport",
    elevation_ft: "974",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-08",
    municipality: "Ouango Fitini",
    gps_code: "DIOF",
    iata_code: "OFI",
    coordinates: "-4.050000190734863, 9.600000381469727",
  },
  {
    ident: "DISG",
    type: "small_airport",
    name: "Seguela Airport",
    elevation_ft: "1056",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-14",
    municipality: "Seguela",
    gps_code: "DISG",
    iata_code: "SEO",
    coordinates: "-6.710830211639404, 7.968329906463623",
  },
  {
    ident: "DISP",
    type: "medium_airport",
    name: "San Pedro Airport",
    elevation_ft: "26",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-09",
    gps_code: "DISP",
    iata_code: "SPY",
    coordinates: "-6.660820007324219, 4.746719837188721",
  },
  {
    ident: "DISS",
    type: "small_airport",
    name: "Sassandra Airport",
    elevation_ft: "203",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-09",
    municipality: "Sassandra",
    gps_code: "DISS",
    iata_code: "ZSS",
    coordinates: "-6.132780075073242, 4.928329944610596",
  },
  {
    ident: "DITB",
    type: "small_airport",
    name: "Tabou Airport",
    elevation_ft: "39",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-09",
    municipality: "Tabou",
    gps_code: "DITB",
    iata_code: "TXU",
    coordinates: "-7.362728118896484, 4.437809467315674",
  },
  {
    ident: "DITN",
    type: "small_airport",
    name: "Mahana Airport",
    elevation_ft: "1583",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-17",
    municipality: "Touba",
    gps_code: "DITM",
    iata_code: "TOZ",
    coordinates: "-7.674, 8.2934",
  },
  {
    ident: "DIW",
    type: "seaplane_base",
    name: "Mawella Lagoon Seaplane Base",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-3",
    municipality: "Dickwella",
    iata_code: "DIW",
    coordinates: "80.733129, 5.990192",
  },
  {
    ident: "DIYO",
    type: "medium_airport",
    name: "Yamoussoukro Airport",
    elevation_ft: "699",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-07",
    municipality: "Yamoussoukro",
    gps_code: "DIYO",
    iata_code: "ASK",
    coordinates: "-5.36558008194, 6.9031701088",
  },
  {
    ident: "DLR",
    type: "small_airport",
    name: "Dalnerechensk Airport",
    elevation_ft: "272",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Dalnerechensk",
    gps_code: "UHHD",
    iata_code: "DLR",
    coordinates: "133.7363, 45.8783",
  },
  {
    ident: "DN57",
    type: "small_airport",
    name: "Katsina Airport",
    elevation_ft: "1660",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-KT",
    municipality: "Katsina",
    iata_code: "DKA",
    coordinates: "7.660449981689999, 13.007800102200001",
  },
  {
    ident: "DNAA",
    type: "large_airport",
    name: "Nnamdi Azikiwe International Airport",
    elevation_ft: "1123",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-FC",
    municipality: "Abuja",
    gps_code: "DNAA",
    iata_code: "ABV",
    coordinates: "7.263169765472412, 9.006790161132812",
  },
  {
    ident: "DNAI",
    type: "large_airport",
    name: "Akwa Ibom International Airport",
    elevation_ft: "170",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-AK",
    municipality: "Uyo",
    gps_code: "DNAI",
    iata_code: "QUO",
    coordinates: "8.093, 4.8725",
  },
  {
    ident: "DNAK",
    type: "medium_airport",
    name: "Akure Airport",
    elevation_ft: "1100",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-ON",
    municipality: "Akure",
    gps_code: "DNAK",
    iata_code: "AKR",
    coordinates: "5.3010101318359375, 7.246739864349365",
  },
  {
    ident: "DNAS",
    type: "medium_airport",
    name: "Asaba International Airport",
    elevation_ft: "305",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-DE",
    municipality: "Asaba",
    gps_code: "DNAS",
    iata_code: "ABB",
    coordinates: "6.665278, 6.204167",
  },
  {
    ident: "DNBC",
    type: "medium_airport",
    name: "Sir Abubakar Tafawa Balewa International Airport",
    elevation_ft: "1965",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-BA",
    municipality: "Bauchi",
    gps_code: "DNBC",
    iata_code: "BCU",
    coordinates: "9.744, 10.482833",
  },
  {
    ident: "DNBE",
    type: "medium_airport",
    name: "Benin Airport",
    elevation_ft: "258",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-ED",
    municipality: "Benin",
    gps_code: "DNBE",
    iata_code: "BNI",
    coordinates: "5.5995001792907715, 6.316979885101318",
  },
  {
    ident: "DNCA",
    type: "medium_airport",
    name: "Margaret Ekpo International Airport",
    elevation_ft: "210",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-CR",
    municipality: "Calabar",
    gps_code: "DNCA",
    iata_code: "CBQ",
    coordinates: "8.347200393676758, 4.976019859313965",
  },
  {
    ident: "DNEN",
    type: "medium_airport",
    name: "Akanu Ibiam International Airport",
    elevation_ft: "466",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-EN",
    municipality: "Enegu",
    gps_code: "DNEN",
    iata_code: "ENU",
    coordinates: "7.561960220336914, 6.474269866943359",
  },
  {
    ident: "DNIB",
    type: "medium_airport",
    name: "Ibadan Airport",
    elevation_ft: "725",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-OY",
    municipality: "Ibadan",
    gps_code: "DNIB",
    iata_code: "IBA",
    coordinates: "3.97832989692688, 7.362460136413574",
  },
  {
    ident: "DNIL",
    type: "medium_airport",
    name: "Ilorin International Airport",
    elevation_ft: "1126",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-KW",
    municipality: "Ilorin",
    gps_code: "DNIL",
    iata_code: "ILR",
    coordinates: "4.493919849395752, 8.440210342407227",
  },
  {
    ident: "DNIM",
    type: "medium_airport",
    name: "Sam Mbakwe International Airport",
    elevation_ft: "373",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-IM",
    municipality: "Owerri",
    gps_code: "DNIM",
    iata_code: "QOW",
    coordinates: "7.206029891967773, 5.427060127258301",
  },
  {
    ident: "DNJO",
    type: "medium_airport",
    name: "Yakubu Gowon Airport",
    elevation_ft: "4232",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-PL",
    municipality: "Jos",
    gps_code: "DNJO",
    iata_code: "JOS",
    coordinates: "8.869050025939941, 9.639829635620117",
  },
  {
    ident: "DNKA",
    type: "medium_airport",
    name: "Kaduna Airport",
    elevation_ft: "2073",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-KD",
    municipality: "Kaduna",
    gps_code: "DNKA",
    iata_code: "KAD",
    coordinates: "7.320109844207764, 10.696000099182129",
  },
  {
    ident: "DNKN",
    type: "large_airport",
    name: "Mallam Aminu International Airport",
    elevation_ft: "1562",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-KN",
    municipality: "Kano",
    gps_code: "DNKN",
    iata_code: "KAN",
    coordinates: "8.52462, 12.0476",
  },
  {
    ident: "DNMA",
    type: "medium_airport",
    name: "Maiduguri International Airport",
    elevation_ft: "1099",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-BO",
    municipality: "Maiduguri",
    gps_code: "DNMA",
    iata_code: "MIU",
    coordinates: "13.080900192260742, 11.855299949645996",
  },
  {
    ident: "DNMK",
    type: "medium_airport",
    name: "Makurdi Airport",
    elevation_ft: "371",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-BE",
    municipality: "Makurdi",
    gps_code: "DNMK",
    iata_code: "MDI",
    coordinates: "8.61394, 7.70388",
  },
  {
    ident: "DNMM",
    type: "large_airport",
    name: "Murtala Muhammed International Airport",
    elevation_ft: "135",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-LA",
    municipality: "Lagos",
    gps_code: "DNMM",
    iata_code: "LOS",
    coordinates: "3.321160078048706, 6.5773701667785645",
  },
  {
    ident: "DNMN",
    type: "medium_airport",
    name: "Minna Airport",
    elevation_ft: "834",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-NI",
    municipality: "Minna",
    gps_code: "DNMN",
    iata_code: "MXJ",
    coordinates: "6.462259769439697, 9.652170181274414",
  },
  {
    ident: "DNPO",
    type: "medium_airport",
    name: "Port Harcourt International Airport",
    elevation_ft: "87",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-RI",
    municipality: "Port Harcourt",
    gps_code: "DNPO",
    iata_code: "PHC",
    coordinates: "6.94959020614624, 5.0154900550842285",
  },
  {
    ident: "DNSO",
    type: "medium_airport",
    name: "Sadiq Abubakar III International Airport",
    elevation_ft: "1010",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-SO",
    municipality: "Sokoto",
    gps_code: "DNSO",
    iata_code: "SKO",
    coordinates: "5.207190036773682, 12.916299819946289",
  },
  {
    ident: "DNYO",
    type: "medium_airport",
    name: "Yola Airport",
    elevation_ft: "599",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-AD",
    municipality: "Yola",
    gps_code: "DNYO",
    iata_code: "YOL",
    coordinates: "12.430399894714355, 9.257550239562988",
  },
  {
    ident: "DNZA",
    type: "medium_airport",
    name: "Zaria Airport",
    elevation_ft: "2170",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-KD",
    municipality: "Zaria",
    gps_code: "DNZA",
    iata_code: "ZAR",
    coordinates: "7.68581, 11.1302",
  },
  {
    ident: "DOO",
    type: "small_airport",
    name: "Dorobisoro Airport",
    elevation_ft: "1767",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Dorobisoro",
    gps_code: "AYDR",
    iata_code: "DOO",
    local_code: "DOR",
    coordinates: "147.920907, -9.461855",
  },
  {
    ident: "DOS",
    type: "closed",
    name: "Dios Airport",
    elevation_ft: "335",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Dios",
    iata_code: "DOS",
    coordinates: "154.9616, -5.5609",
  },
  {
    ident: "DPK",
    type: "closed",
    name: "Deer Park Airport",
    elevation_ft: "75",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Deer Park",
    iata_code: "DPK",
    coordinates: "-73.31, 40.76",
  },
  {
    ident: "DPT",
    type: "small_airport",
    name: "Deputatskiy Airport",
    elevation_ft: "950",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Deputatskiy",
    gps_code: "UEBD",
    iata_code: "DPT",
    coordinates: "139.890012, 69.392503",
  },
  {
    ident: "DPU",
    type: "closed",
    name: "Dumpu Airport",
    elevation_ft: "870",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Dumpu",
    iata_code: "DPU",
    coordinates: "145.705, -5.858",
  },
  {
    ident: "DQA",
    type: "small_airport",
    name: "Saertu Airport",
    elevation_ft: "496",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Daqing Shi",
    gps_code: "ZYDQ",
    iata_code: "DQA",
    coordinates: "125.140555556, 46.7463888889",
  },
  {
    ident: "DRRM",
    type: "medium_airport",
    name: "Maradi Airport",
    elevation_ft: "1240",
    continent: "AF",
    iso_country: "NE",
    iso_region: "NE-4",
    municipality: "Maradi",
    gps_code: "DRRM",
    iata_code: "MFQ",
    coordinates: "7.1267499923706055, 13.5024995803833",
  },
  {
    ident: "DRRN",
    type: "large_airport",
    name: "Diori Hamani International Airport",
    elevation_ft: "732",
    continent: "AF",
    iso_country: "NE",
    iso_region: "NE-8",
    municipality: "Niamey",
    gps_code: "DRRN",
    iata_code: "NIM",
    coordinates: "2.18361, 13.4815",
  },
  {
    ident: "DRRT",
    type: "medium_airport",
    name: "Tahoua Airport",
    elevation_ft: "1266",
    continent: "AF",
    iso_country: "NE",
    iso_region: "NE-5",
    municipality: "Tahoua",
    gps_code: "DRRT",
    iata_code: "THZ",
    coordinates: "5.265359878540039, 14.875699996948242",
  },
  {
    ident: "DRZA",
    type: "medium_airport",
    name: "Mano Dayak International Airport",
    elevation_ft: "1657",
    continent: "AF",
    iso_country: "NE",
    iso_region: "NE-1",
    municipality: "Agadez",
    gps_code: "DRZA",
    iata_code: "AJY",
    coordinates: "8.000109672546387, 16.965999603271484",
  },
  {
    ident: "DRZL",
    type: "small_airport",
    name: "Arlit Airport",
    elevation_ft: "1443",
    continent: "AF",
    iso_country: "NE",
    iso_region: "NE-1",
    municipality: "Arlit",
    gps_code: "DRZL",
    iata_code: "RLT",
    coordinates: "7.36595010757, 18.7903995514",
  },
  {
    ident: "DRZR",
    type: "medium_airport",
    name: "Zinder Airport",
    elevation_ft: "1516",
    continent: "AF",
    iso_country: "NE",
    iso_region: "NE-7",
    municipality: "Zinder",
    gps_code: "DRZR",
    iata_code: "ZND",
    coordinates: "8.983759880065918, 13.779000282287598",
  },
  {
    ident: "DSG",
    type: "small_airport",
    name: "Dilasag Airport",
    elevation_ft: "60",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-AUR",
    municipality: "Dilasag",
    iata_code: "DSG",
    coordinates: "122.206805556, 16.445833333299998",
  },
  {
    ident: "DTKA",
    type: "medium_airport",
    name: "Tabarka 7 Novembre Airport",
    elevation_ft: "230",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-32",
    municipality: "Tabarka",
    gps_code: "DTKA",
    iata_code: "TBJ",
    coordinates: "8.87693977355957, 36.97999954223633",
  },
  {
    ident: "DTMB",
    type: "medium_airport",
    name: "Monastir Habib Bourguiba International Airport",
    elevation_ft: "9",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-52",
    municipality: "Monastir",
    gps_code: "DTMB",
    iata_code: "MIR",
    coordinates: "10.75469970703125, 35.75809860229492",
  },
  {
    ident: "DTTA",
    type: "large_airport",
    name: "Tunis Carthage International Airport",
    elevation_ft: "22",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-11",
    municipality: "Tunis",
    gps_code: "DTTA",
    iata_code: "TUN",
    coordinates: "10.22719955444336, 36.85100173950195",
  },
  {
    ident: "DTTF",
    type: "medium_airport",
    name: "Gafsa Ksar International Airport",
    elevation_ft: "1060",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-71",
    municipality: "Gafsa",
    gps_code: "DTTF",
    iata_code: "GAF",
    coordinates: "8.822500228881836, 34.422000885009766",
  },
  {
    ident: "DTTG",
    type: "medium_airport",
    name: "Gab����s Matmata International Airport",
    elevation_ft: "26",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-81",
    municipality: "Gab����s",
    gps_code: "DTTG",
    iata_code: "GAE",
    coordinates: "10.103300094604492, 33.87689971923828",
  },
  {
    ident: "DTTJ",
    type: "medium_airport",
    name: "Djerba Zarzis International Airport",
    elevation_ft: "19",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-82",
    municipality: "Djerba",
    gps_code: "DTTJ",
    iata_code: "DJE",
    coordinates: "10.775500297546387, 33.875",
  },
  {
    ident: "DTTR",
    type: "medium_airport",
    name: "El Borma Airport",
    elevation_ft: "827",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-83",
    municipality: "El Borma",
    gps_code: "DTTR",
    iata_code: "EBM",
    coordinates: "9.254619598388672, 31.704299926757812",
  },
  {
    ident: "DTTX",
    type: "medium_airport",
    name: "Sfax Thyna International Airport",
    elevation_ft: "85",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-61",
    municipality: "Sfax",
    gps_code: "DTTX",
    iata_code: "SFA",
    coordinates: "10.690999984741211, 34.71799850463867",
  },
  {
    ident: "DTTZ",
    type: "medium_airport",
    name: "Tozeur Nefta International Airport",
    elevation_ft: "287",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-72",
    municipality: "Tozeur",
    gps_code: "DTTZ",
    iata_code: "TOE",
    coordinates: "8.110560417175293, 33.939701080322266",
  },
  {
    ident: "DVD",
    type: "small_airport",
    name: "Andavadoaka Airport",
    elevation_ft: "30",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Andavadoaka",
    iata_code: "DVD",
    coordinates: "43.259573, -22.06608",
  },
  {
    ident: "DWO",
    type: "seaplane_base",
    name: "Diyawanna Oya Seaplane Base",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-1",
    municipality: "Kotte",
    iata_code: "DWO",
    coordinates: "79.909, 6.906585",
  },
  {
    ident: "DWR",
    type: "small_airport",
    name: "Dywer Airbase",
    elevation_ft: "2380",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-HEL",
    municipality: "Camp Dwyer",
    iata_code: "DWR",
    coordinates: "64, 31",
  },
  {
    ident: "DWS",
    type: "closed",
    name: "Lake Buena Vista STOLport",
    elevation_ft: "94",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Walt Disney World",
    iata_code: "DWS",
    coordinates: "-81.5712777778, 28.3994444444",
  },
  {
    ident: "DXNG",
    type: "medium_airport",
    name: "Niamtougou International Airport",
    elevation_ft: "1515",
    continent: "AF",
    iso_country: "TG",
    iso_region: "TG-K",
    municipality: "Niamtougou",
    gps_code: "DXNG",
    iata_code: "LRL",
    coordinates: "1.091249942779541, 9.767330169677734",
  },
  {
    ident: "DXXX",
    type: "medium_airport",
    name: "Lom����-Tokoin Airport",
    elevation_ft: "72",
    continent: "AF",
    iso_country: "TG",
    iso_region: "TG-M",
    municipality: "Lom����",
    gps_code: "DXXX",
    iata_code: "LFW",
    coordinates: "1.2545100450515747, 6.165609836578369",
  },
  {
    ident: "EAL",
    type: "small_airport",
    name: "Elenak Airport",
    elevation_ft: "16",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-KWA",
    municipality: "Mejato Island",
    iata_code: "EAL",
    coordinates: "166.847777778, 9.319305555560002",
  },
  {
    ident: "EBAW",
    type: "medium_airport",
    name: "Antwerp International Airport (Deurne)",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "BE",
    iso_region: "BE-VAN",
    municipality: "Antwerp",
    gps_code: "EBAW",
    iata_code: "ANR",
    coordinates: "4.46027994156, 51.1893997192",
  },
  {
    ident: "EBBR",
    type: "large_airport",
    name: "Brussels Airport",
    elevation_ft: "184",
    continent: "EU",
    iso_country: "BE",
    iso_region: "BE-BRU",
    municipality: "Brussels",
    gps_code: "EBBR",
    iata_code: "BRU",
    coordinates: "4.48443984985, 50.901401519800004",
  },
  {
    ident: "EBCI",
    type: "large_airport",
    name: "Brussels South Charleroi Airport",
    elevation_ft: "614",
    continent: "EU",
    iso_country: "BE",
    iso_region: "BE-WHT",
    municipality: "Brussels",
    gps_code: "EBCI",
    iata_code: "CRL",
    coordinates: "4.45382, 50.459202",
  },
  {
    ident: "EBKT",
    type: "medium_airport",
    name: "Wevelgem Airport",
    elevation_ft: "64",
    continent: "EU",
    iso_country: "BE",
    iso_region: "BE-VWV",
    municipality: "Wevelgem",
    gps_code: "EBKT",
    iata_code: "KJK",
    coordinates: "3.20472002029, 50.817199707",
  },
  {
    ident: "EBLG",
    type: "large_airport",
    name: "Li����ge Airport",
    elevation_ft: "659",
    continent: "EU",
    iso_country: "BE",
    iso_region: "BE-WLG",
    municipality: "Li����ge",
    gps_code: "EBLG",
    iata_code: "LGG",
    coordinates: "5.443220138549805, 50.63740158081055",
  },
  {
    ident: "EBO",
    type: "small_airport",
    name: "Ebon Airport",
    elevation_ft: "8",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-EBO",
    municipality: "Ebon Atoll",
    iata_code: "EBO",
    coordinates: "168.752, 4.5982",
  },
  {
    ident: "EBOS",
    type: "medium_airport",
    name: "Ostend-Bruges International Airport",
    elevation_ft: "13",
    continent: "EU",
    iso_country: "BE",
    iso_region: "BE-VWV",
    municipality: "Ostend",
    gps_code: "EBOS",
    iata_code: "OST",
    coordinates: "2.8622200489, 51.198898315399994",
  },
  {
    ident: "EBZR",
    type: "small_airport",
    name: "Zoersel (Oostmalle) Airfield",
    elevation_ft: "53",
    continent: "EU",
    iso_country: "BE",
    iso_region: "BE-VAN",
    municipality: "Zoersel",
    gps_code: "EBZR",
    iata_code: "OBL",
    coordinates: "4.75333, 51.264702",
  },
  {
    ident: "EC-MZD",
    type: "small_airport",
    name: "M����ndez Airport",
    elevation_ft: "1770",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-S",
    municipality: "Santiago de M����ndez",
    iata_code: "MZD",
    coordinates: "-78.31670379638672, -2.733330011367798",
  },
  {
    ident: "EDA",
    type: "seaplane_base",
    name: "Edna Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Edna Bay",
    iata_code: "EDA",
    coordinates: "-133.661012, 55.949653",
  },
  {
    ident: "EDAC",
    type: "medium_airport",
    name: "Altenburg-Nobitz Airport",
    elevation_ft: "640",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-TH",
    municipality: "Altenburg",
    gps_code: "EDAC",
    iata_code: "AOC",
    coordinates: "12.506389, 50.981945",
  },
  {
    ident: "EDAH",
    type: "medium_airport",
    name: "Heringsdorf Airport",
    elevation_ft: "93",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-MV",
    municipality: "Heringsdorf",
    gps_code: "EDAH",
    iata_code: "HDF",
    coordinates: "14.152299881, 53.8787002563",
  },
  {
    ident: "EDAU",
    type: "small_airport",
    name: "Riesa-G����hlis Airport",
    elevation_ft: "322",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SN",
    municipality: "Riesa",
    gps_code: "EDAU",
    iata_code: "IES",
    coordinates: "13.3561105728, 51.2936096191",
  },
  {
    ident: "EDAX",
    type: "small_airport",
    name: "Rechlin-L����rz Airport",
    elevation_ft: "220",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-MV",
    municipality: "L����rz",
    gps_code: "EDAX",
    iata_code: "REB",
    coordinates: "12.7522220612, 53.306388855",
  },
  {
    ident: "EDBC",
    type: "small_airport",
    name: "Cochstedt Airport",
    elevation_ft: "594",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-ST",
    municipality: "Magdeburg",
    gps_code: "EDBC",
    iata_code: "CSO",
    coordinates: "11.42029953, 51.8563995361",
  },
  {
    ident: "EDBH",
    type: "small_airport",
    name: "Barth Airport",
    elevation_ft: "23",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-MV",
    gps_code: "EDBH",
    iata_code: "BBH",
    coordinates: "12.710515, 54.338253",
  },
  {
    ident: "EDBM",
    type: "medium_airport",
    name: 'Magdeburg "City" Airport',
    elevation_ft: "259",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-ST",
    municipality: "Magdeburg",
    gps_code: "EDBM",
    iata_code: "ZMG",
    coordinates: "11.626389, 52.073612",
  },
  {
    ident: "EDBN",
    type: "medium_airport",
    name: "Neubrandenburg Airport",
    elevation_ft: "228",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-MV",
    municipality: "Neubrandenburg",
    gps_code: "EDBN",
    iata_code: "FNB",
    coordinates: "13.306, 53.6022",
  },
  {
    ident: "EDCD",
    type: "closed",
    name: "Cottbus-Drewitz Airport",
    elevation_ft: "272",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BR",
    municipality: "Cottbus",
    gps_code: "EDCD",
    iata_code: "CBU",
    coordinates: "14.531944, 51.889442",
  },
  {
    ident: "EDCG",
    type: "small_airport",
    name: "Flugplatz G����ttin / R����gen",
    elevation_ft: "69",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-MV",
    municipality: "R����gen",
    gps_code: "EDCG",
    iata_code: "GTI",
    coordinates: "13.325556, 54.383331",
  },
  {
    ident: "EDCK",
    type: "small_airport",
    name: "K����then Airport",
    elevation_ft: "305",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-ST",
    municipality: "K����then",
    gps_code: "EDCK",
    iata_code: "KOQ",
    coordinates: "11.952777862500001, 51.7211112976",
  },
  {
    ident: "EDCP",
    type: "small_airport",
    name: "Peenem����nde Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-MV",
    municipality: "Peenem����nde",
    gps_code: "EDCP",
    iata_code: "PEF",
    coordinates: "13.774443626399998, 54.1577796936",
  },
  {
    ident: "EDDB",
    type: "large_airport",
    name: "Berlin-Sch����nefeld Airport",
    elevation_ft: "157",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BR",
    municipality: "Berlin",
    gps_code: "EDDB",
    iata_code: "SXF",
    coordinates: "13.5225, 52.380001",
  },
  {
    ident: "EDDC",
    type: "large_airport",
    name: "Dresden Airport",
    elevation_ft: "755",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SN",
    municipality: "Dresden",
    gps_code: "EDDC",
    iata_code: "DRS",
    coordinates: "13.767200469970703, 51.1328010559082",
  },
  {
    ident: "EDDE",
    type: "medium_airport",
    name: "Erfurt Airport",
    elevation_ft: "1036",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-TH",
    municipality: "Erfurt",
    gps_code: "EDDE",
    iata_code: "ERF",
    coordinates: "10.958100318908691, 50.979801177978516",
  },
  {
    ident: "EDDF",
    type: "large_airport",
    name: "Frankfurt am Main Airport",
    elevation_ft: "364",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-HE",
    municipality: "Frankfurt am Main",
    gps_code: "EDDF",
    iata_code: "FRA",
    coordinates: "8.570556, 50.033333",
  },
  {
    ident: "EDDG",
    type: "large_airport",
    name: "M����nster Osnabr����ck Airport",
    elevation_ft: "160",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "M����nster",
    gps_code: "EDDG",
    iata_code: "FMO",
    coordinates: "7.68483018875, 52.134601593",
  },
  {
    ident: "EDDH",
    type: "large_airport",
    name: "Hamburg Airport",
    elevation_ft: "53",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-HH",
    municipality: "Hamburg",
    gps_code: "EDDH",
    iata_code: "HAM",
    coordinates: "9.9882297515869, 53.630401611328",
  },
  {
    ident: "EDDI",
    type: "closed",
    name: "Berlin Tempelhof International Airport",
    elevation_ft: "167",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BE",
    municipality: "Berlin",
    gps_code: "EDDI",
    iata_code: "THF",
    coordinates: "13.4039, 52.473",
  },
  {
    ident: "EDDK",
    type: "large_airport",
    name: "Cologne Bonn Airport",
    elevation_ft: "302",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "Cologne",
    gps_code: "EDDK",
    iata_code: "CGN",
    coordinates: "7.1427397728, 50.8658981323",
  },
  {
    ident: "EDDL",
    type: "large_airport",
    name: "D����sseldorf Airport",
    elevation_ft: "147",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "D����sseldorf",
    gps_code: "EDDL",
    iata_code: "DUS",
    coordinates: "6.76678, 51.289501",
  },
  {
    ident: "EDDM",
    type: "large_airport",
    name: "Munich Airport",
    elevation_ft: "1487",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Munich",
    gps_code: "EDDM",
    iata_code: "MUC",
    coordinates: "11.7861, 48.353802",
  },
  {
    ident: "EDDN",
    type: "large_airport",
    name: "Nuremberg Airport",
    elevation_ft: "1046",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Nuremberg",
    gps_code: "EDDN",
    iata_code: "NUE",
    coordinates: "11.078056, 49.498699",
  },
  {
    ident: "EDDP",
    type: "large_airport",
    name: "Leipzig/Halle Airport",
    elevation_ft: "465",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SN",
    municipality: "Leipzig",
    gps_code: "EDDP",
    iata_code: "LEJ",
    coordinates: "12.236389, 51.423889",
  },
  {
    ident: "EDDR",
    type: "medium_airport",
    name: "Saarbr����cken Airport",
    elevation_ft: "1058",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SL",
    municipality: "Saarbr����cken",
    gps_code: "EDDR",
    iata_code: "SCN",
    coordinates: "7.10950994492, 49.214599609400004",
  },
  {
    ident: "EDDS",
    type: "large_airport",
    name: "Stuttgart Airport",
    elevation_ft: "1276",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BW",
    municipality: "Stuttgart",
    gps_code: "EDDS",
    iata_code: "STR",
    coordinates: "9.22196006775, 48.689899444599995",
  },
  {
    ident: "EDDT",
    type: "large_airport",
    name: "Berlin-Tegel Airport",
    elevation_ft: "122",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BE",
    municipality: "Berlin",
    gps_code: "EDDT",
    iata_code: "TXL",
    coordinates: "13.2877, 52.5597",
  },
  {
    ident: "EDDV",
    type: "large_airport",
    name: "Hannover Airport",
    elevation_ft: "183",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Hannover",
    gps_code: "EDDV",
    iata_code: "HAJ",
    coordinates: "9.685079574580001, 52.461101532",
  },
  {
    ident: "EDDW",
    type: "large_airport",
    name: "Bremen Airport",
    elevation_ft: "14",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-HB",
    municipality: "Bremen",
    gps_code: "EDDW",
    iata_code: "BRE",
    coordinates: "8.78666973114, 53.0475006104",
  },
  {
    ident: "EDFH",
    type: "medium_airport",
    name: "Frankfurt-Hahn Airport",
    elevation_ft: "1649",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-RP",
    municipality: "Frankfurt am Main",
    gps_code: "EDFH",
    iata_code: "HHN",
    coordinates: "7.26389, 49.9487",
  },
  {
    ident: "EDFM",
    type: "medium_airport",
    name: "Mannheim-City Airport",
    elevation_ft: "308",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BW",
    municipality: "Mannheim",
    gps_code: "EDFM",
    iata_code: "MHG",
    coordinates: "8.514167, 49.473057",
  },
  {
    ident: "EDGE",
    type: "medium_airport",
    name: "Eisenach-Kindel Airport",
    elevation_ft: "1112",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-TH",
    municipality: "Eisenach",
    gps_code: "EDGE",
    iata_code: "EIB",
    coordinates: "10.47973, 50.991604",
  },
  {
    ident: "EDGS",
    type: "medium_airport",
    name: "Siegerland Airport",
    elevation_ft: "1966",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    gps_code: "EDGS",
    iata_code: "SGE",
    coordinates: "8.082969665527344, 50.707698822021484",
  },
  {
    ident: "EDHI",
    type: "medium_airport",
    name: "Hamburg-Finkenwerder Airport",
    elevation_ft: "23",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-HH",
    municipality: "Hamburg",
    gps_code: "EDHI",
    iata_code: "XFW",
    coordinates: "9.835556030273438, 53.5352783203125",
  },
  {
    ident: "EDHK",
    type: "medium_airport",
    name: "Kiel-Holtenau Airport",
    elevation_ft: "102",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "Kiel",
    gps_code: "EDHK",
    iata_code: "KEL",
    coordinates: "10.145277976989746, 54.37944412231445",
  },
  {
    ident: "EDHL",
    type: "medium_airport",
    name: "L����beck Blankensee Airport",
    elevation_ft: "53",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "L����beck",
    gps_code: "EDHL",
    iata_code: "LBC",
    coordinates: "10.7192001343, 53.8054008484",
  },
  {
    ident: "EDHN",
    type: "small_airport",
    name: "Neum����nster Airport",
    elevation_ft: "72",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "Neum����nster",
    gps_code: "EDHN",
    iata_code: "EUM",
    coordinates: "9.941389083862305, 54.079444885253906",
  },
  {
    ident: "EDJA",
    type: "medium_airport",
    name: "Memmingen Allgau Airport",
    elevation_ft: "2077",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Memmingen",
    gps_code: "EDJA",
    iata_code: "FMM",
    coordinates: "10.2395000458, 47.988800048799995",
  },
  {
    ident: "EDKA",
    type: "small_airport",
    name: "Aachen-Merzbr����ck Airport",
    elevation_ft: "623",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "Aachen",
    gps_code: "EDKA",
    iata_code: "AAH",
    coordinates: "6.186389, 50.823055",
  },
  {
    ident: "EDKB",
    type: "small_airport",
    name: "Bonn-Hangelar Airport",
    elevation_ft: "197",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "Bonn",
    gps_code: "EDKB",
    iata_code: "BNJ",
    coordinates: "7.16333293915, 50.7688903809",
  },
  {
    ident: "EDLE",
    type: "small_airport",
    name: "Essen Mulheim Airport",
    elevation_ft: "424",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    gps_code: "EDLE",
    iata_code: "ESS",
    coordinates: "6.9373297691345215, 51.40230178833008",
  },
  {
    ident: "EDLI",
    type: "small_airport",
    name: "Bielefeld Airport",
    elevation_ft: "454",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "Bielefeld",
    gps_code: "EDLI",
    iata_code: "BFE",
    coordinates: "8.544444, 51.964722",
  },
  {
    ident: "EDLN",
    type: "medium_airport",
    name: "M����nchengladbach Airport",
    elevation_ft: "125",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "M����nchengladbach",
    gps_code: "EDLN",
    iata_code: "MGL",
    coordinates: "6.504444, 51.230278",
  },
  {
    ident: "EDLP",
    type: "medium_airport",
    name: "Paderborn Lippstadt Airport",
    elevation_ft: "699",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "Paderborn",
    gps_code: "EDLP",
    iata_code: "PAD",
    coordinates: "8.616319656369999, 51.614101409899995",
  },
  {
    ident: "EDLV",
    type: "medium_airport",
    name: "Weeze Airport",
    elevation_ft: "106",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "Weeze",
    gps_code: "EDLV",
    iata_code: "NRN",
    coordinates: "6.14216995239, 51.6024017334",
  },
  {
    ident: "EDLW",
    type: "large_airport",
    name: "Dortmund Airport",
    elevation_ft: "425",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "Dortmund",
    gps_code: "EDLW",
    iata_code: "DTM",
    coordinates: "7.61223983765, 51.51829910279999",
  },
  {
    ident: "EDMA",
    type: "medium_airport",
    name: "Augsburg Airport",
    elevation_ft: "1516",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Augsburg",
    gps_code: "EDMA",
    iata_code: "AGB",
    coordinates: "10.931667, 48.425278",
  },
  {
    ident: "EDMO",
    type: "medium_airport",
    name: "Oberpfaffenhofen Airport",
    elevation_ft: "1947",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    gps_code: "EDMO",
    iata_code: "OBF",
    coordinates: "11.2831, 48.081402",
  },
  {
    ident: "EDMS",
    type: "small_airport",
    name: "Straubing Airport",
    elevation_ft: "1047",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Straubing",
    gps_code: "EDMS",
    iata_code: "RBM",
    coordinates: "12.516667366027832, 48.90083312988281",
  },
  {
    ident: "EDNY",
    type: "medium_airport",
    name: "Friedrichshafen Airport",
    elevation_ft: "1367",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BW",
    municipality: "Friedrichshafen",
    gps_code: "EDNY",
    iata_code: "FDH",
    coordinates: "9.51148986816, 47.671298980699994",
  },
  {
    ident: "EDOP",
    type: "medium_airport",
    name: "Schwerin Parchim Airport",
    elevation_ft: "166",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-MV",
    gps_code: "EDOP",
    iata_code: "SZW",
    coordinates: "11.7834, 53.426998",
  },
  {
    ident: "EDQD",
    type: "medium_airport",
    name: "Bayreuth Airport",
    elevation_ft: "1601",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Bayreuth",
    gps_code: "EDQD",
    iata_code: "BYU",
    coordinates: "11.64, 49.985001",
  },
  {
    ident: "EDQE",
    type: "small_airport",
    name: "Burg Feuerstein Airport",
    elevation_ft: "1673",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Ebermannstadt",
    gps_code: "EDQE",
    iata_code: "URD",
    coordinates: "11.133610725403, 49.794166564941",
  },
  {
    ident: "EDQG",
    type: "small_airport",
    name: "Giebelstadt Airport",
    elevation_ft: "981",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Giebelstadt",
    gps_code: "EDQG",
    iata_code: "GHF",
    coordinates: "9.966388702390002, 49.6480560303",
  },
  {
    ident: "EDQM",
    type: "medium_airport",
    name: "Hof-Plauen Airport",
    elevation_ft: "1959",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Hof",
    gps_code: "EDQM",
    iata_code: "HOQ",
    coordinates: "11.856389045715332, 50.288612365722656",
  },
  {
    ident: "EDRB",
    type: "small_airport",
    name: "Bitburg Airport",
    elevation_ft: "1220",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-RP",
    municipality: "Bitburg",
    gps_code: "EDRB",
    iata_code: "BBJ",
    coordinates: "6.565, 49.945278",
  },
  {
    ident: "EDRZ",
    type: "small_airport",
    name: "Zweibr����cken Airport",
    elevation_ft: "1132",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-RP",
    municipality: "Zweibr����cken",
    gps_code: "EDRZ",
    iata_code: "ZQW",
    coordinates: "7.40056, 49.2094",
  },
  {
    ident: "EDSB",
    type: "large_airport",
    name: "Karlsruhe Baden-Baden Airport",
    elevation_ft: "408",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BW",
    municipality: "Baden-Baden",
    gps_code: "EDSB",
    iata_code: "FKB",
    coordinates: "8.08049964905, 48.7793998718",
  },
  {
    ident: "EDTD",
    type: "medium_airport",
    name: "Donaueschingen-Villingen Airport",
    elevation_ft: "2231",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BW",
    municipality: "Donaueschingen",
    gps_code: "EDTD",
    iata_code: "ZQL",
    coordinates: "8.52222156525, 47.97333145139999",
  },
  {
    ident: "EDTL",
    type: "medium_airport",
    name: "Lahr Airport",
    elevation_ft: "511",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BW",
    gps_code: "EDTL",
    iata_code: "LHA",
    coordinates: "7.82772016525, 48.3693008423",
  },
  {
    ident: "EDVE",
    type: "medium_airport",
    name: "Braunschweig-Wolfsburg Airport",
    elevation_ft: "295",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Braunschweig",
    gps_code: "EDVE",
    iata_code: "BWE",
    coordinates: "10.5561, 52.319199",
  },
  {
    ident: "EDVK",
    type: "medium_airport",
    name: "Kassel-Calden Airport",
    elevation_ft: "820",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-HE",
    municipality: "Kassel",
    gps_code: "EDVK",
    iata_code: "KSF",
    coordinates: "9.384967, 51.417273",
  },
  {
    ident: "EDWB",
    type: "closed",
    name: "Bremerhaven Airport",
    elevation_ft: "10",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-HB",
    municipality: "Bremerhaven",
    gps_code: "EDWB",
    iata_code: "BRV",
    coordinates: "8.572778, 53.506943",
  },
  {
    ident: "EDWD",
    type: "closed",
    name: "Lemwerder Airport",
    elevation_ft: "20",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-HB",
    municipality: "Lemwerder",
    gps_code: "EDWD",
    iata_code: "XLW",
    coordinates: "8.62444, 53.144699",
  },
  {
    ident: "EDWE",
    type: "small_airport",
    name: "Emden Airport",
    elevation_ft: "3",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Emden",
    gps_code: "EDWE",
    iata_code: "EME",
    coordinates: "7.2275, 53.391109",
  },
  {
    ident: "EDWG",
    type: "small_airport",
    name: "Wangerooge Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Wangerooge",
    gps_code: "EDWG",
    iata_code: "AGE",
    coordinates: "7.913888931274414, 53.782779693603516",
  },
  {
    ident: "EDWI",
    type: "small_airport",
    name: "Wilhelmshaven-Mariensiel Airport",
    elevation_ft: "16",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Wilhelmshaven",
    gps_code: "EDWI",
    iata_code: "WVN",
    coordinates: "8.05222225189209, 53.502220153808594",
  },
  {
    ident: "EDWJ",
    type: "small_airport",
    name: "Juist Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Juist",
    gps_code: "EDWJ",
    iata_code: "JUI",
    coordinates: "7.055832862854004, 53.68111038208008",
  },
  {
    ident: "EDWL",
    type: "small_airport",
    name: "Langeoog Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Langeoog",
    gps_code: "EDWL",
    iata_code: "LGO",
    coordinates: "7.497777938842773, 53.74250030517578",
  },
  {
    ident: "EDWR",
    type: "small_airport",
    name: "Borkum Airport",
    elevation_ft: "3",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Borkum",
    gps_code: "EDWR",
    iata_code: "BMK",
    coordinates: "6.709167, 53.59639",
  },
  {
    ident: "EDWS",
    type: "small_airport",
    name: "Norden-Norddeich Airport",
    elevation_ft: "3",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Norddeich",
    gps_code: "EDWS",
    iata_code: "NOD",
    coordinates: "7.19027805328, 53.633056640599996",
  },
  {
    ident: "EDWU",
    type: "small_airport",
    name: "Varrelbusch Airport",
    elevation_ft: "128",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Cloppenburg",
    gps_code: "EDWU",
    iata_code: "VAC",
    coordinates: "8.040556, 52.908333",
  },
  {
    ident: "EDWY",
    type: "small_airport",
    name: "Norderney Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Norderney",
    gps_code: "EDWY",
    iata_code: "NRD",
    coordinates: "7.23, 53.706944",
  },
  {
    ident: "EDWZ",
    type: "small_airport",
    name: "Baltrum Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Baltrum",
    gps_code: "EDWZ",
    iata_code: "BMR",
    coordinates: "7.373332977294922, 53.72472381591797",
  },
  {
    ident: "EDXB",
    type: "small_airport",
    name: "Heide-B����sum Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "B����sum",
    gps_code: "EDXB",
    iata_code: "HEI",
    coordinates: "8.90166664124, 54.153331756600004",
  },
  {
    ident: "EDXF",
    type: "small_airport",
    name: "Flensburg-Sch����ferhaus Airport",
    elevation_ft: "131",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "Flensburg",
    gps_code: "EDXF",
    iata_code: "FLF",
    coordinates: "9.378889083862305, 54.77333450317383",
  },
  {
    ident: "EDXH",
    type: "small_airport",
    name: "Helgoland-D����ne Airport",
    elevation_ft: "8",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "Helgoland",
    gps_code: "EDXH",
    iata_code: "HGL",
    coordinates: "7.91583299637, 54.185279846200004",
  },
  {
    ident: "EDXJ",
    type: "small_airport",
    name: "Husum-Schwesing Airport",
    elevation_ft: "62",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "Husum",
    gps_code: "EDXJ",
    iata_code: "QHU",
    coordinates: "9.138333320620001, 54.5099983215",
  },
  {
    ident: "EDXO",
    type: "small_airport",
    name: "St. Peter-Ording Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "Sankt Peter-Ording",
    gps_code: "EDXO",
    iata_code: "PSH",
    coordinates: "8.686944007873535, 54.30888748168945",
  },
  {
    ident: "EDXW",
    type: "medium_airport",
    name: "Westerland Sylt Airport",
    elevation_ft: "51",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "Westerland",
    gps_code: "EDXW",
    iata_code: "GWT",
    coordinates: "8.34047031403, 54.9132003784",
  },
  {
    ident: "EDXY",
    type: "small_airport",
    name: "Wyk auf F����hr Airport",
    elevation_ft: "26",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "Wyk auf F����hr",
    gps_code: "EDXY",
    iata_code: "OHR",
    coordinates: "8.528332710266113, 54.684444427490234",
  },
  {
    ident: "EEKA",
    type: "medium_airport",
    name: "K����rdla Airport",
    elevation_ft: "18",
    continent: "EU",
    iso_country: "EE",
    iso_region: "EE-39",
    municipality: "K����rdla",
    gps_code: "EEKA",
    iata_code: "KDL",
    coordinates: "22.830699920654297, 58.99079895019531",
  },
  {
    ident: "EEKE",
    type: "medium_airport",
    name: "Kuressaare Airport",
    elevation_ft: "14",
    continent: "EU",
    iso_country: "EE",
    iso_region: "EE-74",
    municipality: "Kuressaare",
    gps_code: "EEKE",
    iata_code: "URE",
    coordinates: "22.50950050354004, 58.22990036010742",
  },
  {
    ident: "EEPU",
    type: "medium_airport",
    name: "P����rnu Airport",
    elevation_ft: "47",
    continent: "EU",
    iso_country: "EE",
    iso_region: "EE-67",
    municipality: "P����rnu",
    gps_code: "EEPU",
    iata_code: "EPU",
    coordinates: "24.47279930114746, 58.41899871826172",
  },
  {
    ident: "EETN",
    type: "large_airport",
    name: "Lennart Meri Tallinn Airport",
    elevation_ft: "131",
    continent: "EU",
    iso_country: "EE",
    iso_region: "EE-37",
    municipality: "Tallinn",
    gps_code: "EETN",
    iata_code: "TLL",
    coordinates: "24.832799911499997, 59.41329956049999",
  },
  {
    ident: "EETU",
    type: "medium_airport",
    name: "Tartu Airport",
    elevation_ft: "219",
    continent: "EU",
    iso_country: "EE",
    iso_region: "EE-7B",
    municipality: "Tartu",
    gps_code: "EETU",
    iata_code: "TAY",
    coordinates: "26.690399169900004, 58.3074989319",
  },
  {
    ident: "EFET",
    type: "medium_airport",
    name: "Enontekio Airport",
    elevation_ft: "1005",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LL",
    municipality: "Enontekio",
    gps_code: "EFET",
    iata_code: "ENF",
    coordinates: "23.424299240112, 68.362602233887",
  },
  {
    ident: "EFG",
    type: "small_airport",
    name: "Efogi Airport",
    elevation_ft: "3900",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Efogi",
    gps_code: "AYEF",
    iata_code: "EFG",
    local_code: "EFO",
    coordinates: "147.659805556, -9.15380555556",
  },
  {
    ident: "EFHA",
    type: "medium_airport",
    name: "Halli Airport",
    elevation_ft: "479",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    municipality: "Halli / Kuorevesi",
    gps_code: "EFHA",
    iata_code: "KEV",
    coordinates: "24.786686, 61.856039",
  },
  {
    ident: "EFHF",
    type: "medium_airport",
    name: "Helsinki Malmi Airport",
    elevation_ft: "57",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-ES",
    municipality: "Helsinki",
    gps_code: "EFHF",
    iata_code: "HEM",
    coordinates: "25.042800903320312, 60.254600524902344",
  },
  {
    ident: "EFHK",
    type: "large_airport",
    name: "Helsinki Vantaa Airport",
    elevation_ft: "179",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-ES",
    municipality: "Helsinki",
    gps_code: "EFHK",
    iata_code: "HEL",
    coordinates: "24.963300704956, 60.317199707031",
  },
  {
    ident: "EFHV",
    type: "small_airport",
    name: "Hyvink�������� Airfield",
    elevation_ft: "430",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-ES",
    municipality: "Hyvink��������",
    gps_code: "EFHV",
    iata_code: "HYV",
    coordinates: "24.8810997009, 60.6543998718",
  },
  {
    ident: "EFIT",
    type: "medium_airport",
    name: "Kitee Airport",
    elevation_ft: "364",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-IS",
    gps_code: "EFIT",
    iata_code: "KTQ",
    coordinates: "30.073601, 62.1661",
  },
  {
    ident: "EFIV",
    type: "medium_airport",
    name: "Ivalo Airport",
    elevation_ft: "481",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LL",
    municipality: "Ivalo",
    gps_code: "EFIV",
    iata_code: "IVL",
    coordinates: "27.405300140381, 68.607299804688",
  },
  {
    ident: "EFJO",
    type: "medium_airport",
    name: "Joensuu Airport",
    elevation_ft: "398",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-IS",
    municipality: "Joensuu / Liperi",
    gps_code: "EFJO",
    iata_code: "JOE",
    coordinates: "29.607500076294, 62.662899017334",
  },
  {
    ident: "EFJY",
    type: "medium_airport",
    name: "Jyvaskyla Airport",
    elevation_ft: "459",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    municipality: "Jyv����skyl����n Maalaiskunta",
    gps_code: "EFJY",
    iata_code: "JYV",
    coordinates: "25.678300857544, 62.399501800537",
  },
  {
    ident: "EFKA",
    type: "medium_airport",
    name: "Kauhava Airport",
    elevation_ft: "151",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    municipality: "Kauhava",
    gps_code: "EFKA",
    iata_code: "KAU",
    coordinates: "23.051399, 63.127102",
  },
  {
    ident: "EFKE",
    type: "medium_airport",
    name: "Kemi-Tornio Airport",
    elevation_ft: "61",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LL",
    municipality: "Kemi / Tornio",
    gps_code: "EFKE",
    iata_code: "KEM",
    coordinates: "24.582099914551, 65.778701782227",
  },
  {
    ident: "EFKI",
    type: "medium_airport",
    name: "Kajaani Airport",
    elevation_ft: "483",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-OL",
    municipality: "Kajaani",
    gps_code: "EFKI",
    iata_code: "KAJ",
    coordinates: "27.692399978638, 64.285499572754",
  },
  {
    ident: "EFKJ",
    type: "medium_airport",
    name: "Kauhajoki Airport",
    elevation_ft: "407",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    gps_code: "EFKJ",
    iata_code: "KHJ",
    coordinates: "22.3931007385, 62.4625015259",
  },
  {
    ident: "EFKK",
    type: "medium_airport",
    name: "Kokkola-Pietarsaari Airport",
    elevation_ft: "84",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    municipality: "Kokkola / Kruunupyy",
    gps_code: "EFKK",
    iata_code: "KOK",
    coordinates: "23.143100738525, 63.721199035645",
  },
  {
    ident: "EFKS",
    type: "medium_airport",
    name: "Kuusamo Airport",
    elevation_ft: "866",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-OL",
    municipality: "Kuusamo",
    gps_code: "EFKS",
    iata_code: "KAO",
    coordinates: "29.239400863647, 65.987602233887",
  },
  {
    ident: "EFKT",
    type: "medium_airport",
    name: "Kittil���� Airport",
    elevation_ft: "644",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LL",
    municipality: "Kittil����",
    gps_code: "EFKT",
    iata_code: "KTT",
    coordinates: "24.846799850464, 67.700996398926",
  },
  {
    ident: "EFKU",
    type: "medium_airport",
    name: "Kuopio Airport",
    elevation_ft: "323",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-IS",
    municipality: "Kuopio / Siilinj����rvi",
    gps_code: "EFKU",
    iata_code: "KUO",
    coordinates: "27.797800064087, 63.007099151611",
  },
  {
    ident: "EFLA",
    type: "small_airport",
    name: "Lahti Vesivehmaa Airport",
    elevation_ft: "502",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-ES",
    gps_code: "EFLA",
    iata_code: "QLF",
    coordinates: "25.693501, 61.144199",
  },
  {
    ident: "EFLP",
    type: "medium_airport",
    name: "Lappeenranta Airport",
    elevation_ft: "349",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-ES",
    municipality: "Lappeenranta",
    gps_code: "EFLP",
    iata_code: "LPP",
    coordinates: "28.144743, 61.044601",
  },
  {
    ident: "EFMA",
    type: "medium_airport",
    name: "Mariehamn Airport",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-AL",
    gps_code: "EFMA",
    iata_code: "MHQ",
    coordinates: "19.898199081421, 60.122200012207",
  },
  {
    ident: "EFMI",
    type: "medium_airport",
    name: "Mikkeli Airport",
    elevation_ft: "329",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-IS",
    municipality: "Mikkeli",
    gps_code: "EFMI",
    iata_code: "MIK",
    coordinates: "27.201799, 61.6866",
  },
  {
    ident: "EFOU",
    type: "medium_airport",
    name: "Oulu Airport",
    elevation_ft: "47",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-OL",
    municipality: "Oulu / Oulunsalo",
    gps_code: "EFOU",
    iata_code: "OUL",
    coordinates: "25.354600906372, 64.930099487305",
  },
  {
    ident: "EFPO",
    type: "medium_airport",
    name: "Pori Airport",
    elevation_ft: "44",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    municipality: "Pori",
    gps_code: "EFPO",
    iata_code: "POR",
    coordinates: "21.799999237061, 61.461700439453",
  },
  {
    ident: "EFRO",
    type: "medium_airport",
    name: "Rovaniemi Airport",
    elevation_ft: "642",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LL",
    municipality: "Rovaniemi",
    gps_code: "EFRO",
    iata_code: "RVN",
    coordinates: "25.830400466919, 66.564796447754",
  },
  {
    ident: "EFSA",
    type: "medium_airport",
    name: "Savonlinna Airport",
    elevation_ft: "311",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-IS",
    municipality: "Savonlinna",
    gps_code: "EFSA",
    iata_code: "SVL",
    coordinates: "28.945100784302, 61.943099975586",
  },
  {
    ident: "EFSI",
    type: "medium_airport",
    name: "Sein����joki Airport",
    elevation_ft: "302",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    municipality: "Sein����joki / Ilmajoki",
    gps_code: "EFSI",
    iata_code: "SJY",
    coordinates: "22.8323, 62.692101",
  },
  {
    ident: "EFSO",
    type: "medium_airport",
    name: "Sodankyla Airport",
    elevation_ft: "602",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LL",
    municipality: "Sodankyla",
    gps_code: "EFSO",
    iata_code: "SOT",
    coordinates: "26.6191005707, 67.3949966431",
  },
  {
    ident: "EFTP",
    type: "medium_airport",
    name: "Tampere-Pirkkala Airport",
    elevation_ft: "390",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    municipality: "Tampere / Pirkkala",
    gps_code: "EFTP",
    iata_code: "TMP",
    coordinates: "23.604400634766, 61.414100646973",
  },
  {
    ident: "EFTU",
    type: "medium_airport",
    name: "Turku Airport",
    elevation_ft: "161",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    municipality: "Turku",
    gps_code: "EFTU",
    iata_code: "TKU",
    coordinates: "22.262800216675, 60.514099121094",
  },
  {
    ident: "EFUT",
    type: "medium_airport",
    name: "Utti Air Base",
    elevation_ft: "339",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-ES",
    municipality: "Utti / Valkeala",
    gps_code: "EFUT",
    iata_code: "UTI",
    coordinates: "26.938400268555, 60.89640045166",
  },
  {
    ident: "EFVA",
    type: "medium_airport",
    name: "Vaasa Airport",
    elevation_ft: "19",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-LS",
    municipality: "Vaasa",
    gps_code: "EFVA",
    iata_code: "VAA",
    coordinates: "21.762199401855, 63.050701141357",
  },
  {
    ident: "EFVR",
    type: "medium_airport",
    name: "Varkaus Airport",
    elevation_ft: "286",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-IS",
    municipality: "Varkaus / Joroinen",
    gps_code: "EFVR",
    iata_code: "VRK",
    coordinates: "27.868600845337, 62.171100616455",
  },
  {
    ident: "EFYL",
    type: "medium_airport",
    name: "Ylivieska Airfield",
    elevation_ft: "252",
    continent: "EU",
    iso_country: "FI",
    iso_region: "FI-OL",
    gps_code: "EFYL",
    iata_code: "YLI",
    coordinates: "24.7252778, 64.0547222",
  },
  {
    ident: "EG-AUE",
    type: "small_airport",
    name: "Abu Rudeis Airport",
    elevation_ft: "39",
    continent: "AS",
    iso_country: "EG",
    iso_region: "EG-JS",
    municipality: "Abu Rudeis",
    iata_code: "AUE",
    coordinates: "33.20249938964844, 28.89900016784668",
  },
  {
    ident: "EGAA",
    type: "large_airport",
    name: "Belfast International Airport",
    elevation_ft: "268",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-NIR",
    municipality: "Belfast",
    gps_code: "EGAA",
    iata_code: "BFS",
    coordinates: "-6.2158298492399995, 54.6575012207",
  },
  {
    ident: "EGAB",
    type: "medium_airport",
    name: "St Angelo Airport",
    elevation_ft: "155",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-NIR",
    municipality: "Enniskillen",
    gps_code: "EGAB",
    iata_code: "ENK",
    coordinates: "-7.651669979095459, 54.39889907836914",
  },
  {
    ident: "EGAC",
    type: "large_airport",
    name: "George Best Belfast City Airport",
    elevation_ft: "15",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-NIR",
    municipality: "Belfast",
    gps_code: "EGAC",
    iata_code: "BHD",
    coordinates: "-5.872499942779541, 54.618099212646484",
  },
  {
    ident: "EGAE",
    type: "medium_airport",
    name: "City of Derry Airport",
    elevation_ft: "22",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-NIR",
    municipality: "Derry",
    gps_code: "EGAE",
    iata_code: "LDY",
    coordinates: "-7.161109924316406, 55.04280090332031",
  },
  {
    ident: "EGBB",
    type: "large_airport",
    name: "Birmingham International Airport",
    elevation_ft: "327",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Birmingham",
    gps_code: "EGBB",
    iata_code: "BHX",
    coordinates: "-1.74802994728, 52.453899383499994",
  },
  {
    ident: "EGBE",
    type: "medium_airport",
    name: "Coventry Airport",
    elevation_ft: "267",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Coventry",
    gps_code: "EGBE",
    iata_code: "CVT",
    coordinates: "-1.4797199964499999, 52.3697013855",
  },
  {
    ident: "EGBJ",
    type: "medium_airport",
    name: "Gloucestershire Airport",
    elevation_ft: "101",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Staverton",
    gps_code: "EGBJ",
    iata_code: "GLO",
    coordinates: "-2.167220115661621, 51.89419937133789",
  },
  {
    ident: "EGBK",
    type: "small_airport",
    name: "Sywell Aerodrome",
    elevation_ft: "429",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Northampton",
    gps_code: "EGBK",
    iata_code: "ORM",
    coordinates: "-0.7930560112, 52.305301666300004",
  },
  {
    ident: "EGBN",
    type: "medium_airport",
    name: "Nottingham Airport",
    elevation_ft: "138",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Nottingham",
    gps_code: "EGBN",
    iata_code: "NQT",
    coordinates: "-1.0791699886322021, 52.91999816894531",
  },
  {
    ident: "EGBP",
    type: "small_airport",
    name: "Cotswold Airport",
    elevation_ft: "433",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Kemble",
    gps_code: "EGBP",
    iata_code: "GBA",
    coordinates: "-2.05694, 51.668095",
  },
  {
    ident: "EGCC",
    type: "large_airport",
    name: "Manchester Airport",
    elevation_ft: "257",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Manchester",
    gps_code: "EGCC",
    iata_code: "MAN",
    coordinates: "-2.2749500274658203, 53.35369873046875",
  },
  {
    ident: "EGCN",
    type: "large_airport",
    name: "Robin Hood Doncaster Sheffield Airport",
    elevation_ft: "55",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Doncaster",
    gps_code: "EGCN",
    iata_code: "DSA",
    coordinates: "-1.01065635681, 53.4805378105",
  },
  {
    ident: "EGDJ",
    type: "small_airport",
    name: "Upavon Aerodrome",
    elevation_ft: "574",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Upavon",
    gps_code: "EGDJ",
    iata_code: "UPV",
    coordinates: "-1.7820199728, 51.2862014771",
  },
  {
    ident: "EGDL",
    type: "closed",
    name: "RAF Lyneham",
    elevation_ft: "513",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Lyneham",
    gps_code: "EGDL",
    iata_code: "LYE",
    coordinates: "-1.99343, 51.5051",
  },
  {
    ident: "EGDY",
    type: "medium_airport",
    name: "RNAS Yeovilton",
    elevation_ft: "75",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Yeovil",
    gps_code: "EGDY",
    iata_code: "YEO",
    coordinates: "-2.638819932937622, 51.0093994140625",
  },
  {
    ident: "EGEC",
    type: "medium_airport",
    name: "Campbeltown Airport",
    elevation_ft: "42",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Campbeltown",
    gps_code: "EGEC",
    iata_code: "CAL",
    coordinates: "-5.686389923095703, 55.437198638916016",
  },
  {
    ident: "EGED",
    type: "medium_airport",
    name: "Eday Airport",
    elevation_ft: "10",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Eday",
    gps_code: "EGED",
    iata_code: "EOI",
    coordinates: "-2.7722198963165283, 59.19060134887695",
  },
  {
    ident: "EGEF",
    type: "medium_airport",
    name: "Fair Isle Airport",
    elevation_ft: "223",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Fair Isle",
    gps_code: "EGEF",
    iata_code: "FIE",
    coordinates: "-1.628059983253479, 59.53580093383789",
  },
  {
    ident: "EGEH",
    type: "small_airport",
    name: "Whalsay Airport",
    elevation_ft: "100",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Whalsay",
    gps_code: "EGEH",
    iata_code: "WHS",
    coordinates: "-0.9255560040473938, 60.377498626708984",
  },
  {
    ident: "EGEL",
    type: "small_airport",
    name: "Coll Airport",
    elevation_ft: "21",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Coll Island",
    gps_code: "EGEL",
    iata_code: "COL",
    coordinates: "-6.61778020859, 56.6018981934",
  },
  {
    ident: "EGEN",
    type: "small_airport",
    name: "North Ronaldsay Airport",
    elevation_ft: "40",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "North Ronaldsay",
    gps_code: "EGEN",
    iata_code: "NRL",
    coordinates: "-2.43443989754, 59.3675003052",
  },
  {
    ident: "EGEO",
    type: "small_airport",
    name: "Oban Airport",
    elevation_ft: "20",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "North Connel",
    gps_code: "EGEO",
    iata_code: "OBN",
    coordinates: "-5.399670124053955, 56.4635009765625",
  },
  {
    ident: "EGEP",
    type: "small_airport",
    name: "Papa Westray Airport",
    elevation_ft: "91",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Papa Westray",
    gps_code: "EGEP",
    iata_code: "PPW",
    coordinates: "-2.9002799987800003, 59.351699829100006",
  },
  {
    ident: "EGER",
    type: "small_airport",
    name: "Stronsay Airport",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Stronsay",
    gps_code: "EGER",
    iata_code: "SOY",
    coordinates: "-2.64139008522, 59.1553001404",
  },
  {
    ident: "EGES",
    type: "small_airport",
    name: "Sanday Airport",
    elevation_ft: "68",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Sanday",
    gps_code: "EGES",
    iata_code: "NDY",
    coordinates: "-2.576669931411743, 59.250301361083984",
  },
  {
    ident: "EGET",
    type: "small_airport",
    name: "Lerwick / Tingwall Airport",
    elevation_ft: "43",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Lerwick",
    gps_code: "EGET",
    iata_code: "LWK",
    coordinates: "-1.24361002445, 60.192199707",
  },
  {
    ident: "EGEW",
    type: "small_airport",
    name: "Westray Airport",
    elevation_ft: "29",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Westray",
    gps_code: "EGEW",
    iata_code: "WRY",
    coordinates: "-2.95000004768, 59.3502998352",
  },
  {
    ident: "EGEY",
    type: "small_airport",
    name: "Colonsay Airstrip",
    elevation_ft: "44",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Colonsay",
    gps_code: "EGEY",
    iata_code: "CSA",
    coordinates: "-6.24306, 56.057499",
  },
  {
    ident: "EGFE",
    type: "medium_airport",
    name: "Haverfordwest Airport",
    elevation_ft: "159",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-WLS",
    municipality: "Haverfordwest",
    gps_code: "EGFE",
    iata_code: "HAW",
    coordinates: "-4.9611101150512695, 51.833099365234375",
  },
  {
    ident: "EGFF",
    type: "large_airport",
    name: "Cardiff International Airport",
    elevation_ft: "220",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-WLS",
    municipality: "Cardiff",
    gps_code: "EGFF",
    iata_code: "CWL",
    coordinates: "-3.343329906463623, 51.39670181274414",
  },
  {
    ident: "EGFH",
    type: "medium_airport",
    name: "Swansea Airport",
    elevation_ft: "299",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-WLS",
    municipality: "Swansea",
    gps_code: "EGFH",
    iata_code: "SWS",
    coordinates: "-4.0678300857543945, 51.60530090332031",
  },
  {
    ident: "EGGD",
    type: "large_airport",
    name: "Bristol Airport",
    elevation_ft: "622",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Bristol",
    gps_code: "EGGD",
    iata_code: "BRS",
    coordinates: "-2.71909, 51.382702",
  },
  {
    ident: "EGGP",
    type: "large_airport",
    name: "Liverpool John Lennon Airport",
    elevation_ft: "80",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Liverpool",
    gps_code: "EGGP",
    iata_code: "LPL",
    coordinates: "-2.849720001220703, 53.33359909057617",
  },
  {
    ident: "EGGW",
    type: "large_airport",
    name: "London Luton Airport",
    elevation_ft: "526",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "London",
    gps_code: "EGGW",
    iata_code: "LTN",
    coordinates: "-0.36833301186561584, 51.874698638916016",
  },
  {
    ident: "EGHC",
    type: "medium_airport",
    name: "Land's End Airport",
    elevation_ft: "398",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Land's End",
    gps_code: "EGHC",
    iata_code: "LEQ",
    coordinates: "-5.67056, 50.102798",
  },
  {
    ident: "EGHD",
    type: "closed",
    name: "Plymouth City Airport",
    elevation_ft: "476",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Plymouth",
    gps_code: "EGHD",
    iata_code: "PLH",
    coordinates: "-4.10583, 50.422798",
  },
  {
    ident: "EGHE",
    type: "medium_airport",
    name: "St. Mary's Airport",
    elevation_ft: "116",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "St. Mary's",
    gps_code: "EGHE",
    iata_code: "ISC",
    coordinates: "-6.291669845581055, 49.913299560546875",
  },
  {
    ident: "EGHH",
    type: "large_airport",
    name: "Bournemouth Airport",
    elevation_ft: "38",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Bournemouth",
    gps_code: "EGHH",
    iata_code: "BOH",
    coordinates: "-1.8424999713897705, 50.779998779296875",
  },
  {
    ident: "EGHI",
    type: "large_airport",
    name: "Southampton Airport",
    elevation_ft: "44",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Southampton",
    gps_code: "EGHI",
    iata_code: "SOU",
    coordinates: "-1.3567999601364136, 50.95029830932617",
  },
  {
    ident: "EGHJ",
    type: "small_airport",
    name: "Bembridge Airport",
    elevation_ft: "53",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Bembridge",
    gps_code: "EGHJ",
    iata_code: "BBP",
    coordinates: "-1.10943996906, 50.6781005859",
  },
  {
    ident: "EGHK",
    type: "closed",
    name: "Penzance Heliport",
    elevation_ft: "14",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Penzance",
    gps_code: "EGHK",
    iata_code: "PZE",
    coordinates: "-5.51845, 50.128101",
  },
  {
    ident: "EGHQ",
    type: "medium_airport",
    name: "Newquay Cornwall Airport",
    elevation_ft: "390",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Newquay",
    gps_code: "EGHQ",
    iata_code: "NQY",
    coordinates: "-4.995409965515137, 50.44060134887695",
  },
  {
    ident: "EGHR",
    type: "small_airport",
    name: "Goodwood Aerodrome",
    elevation_ft: "98",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Chichester",
    gps_code: "EGHR",
    iata_code: "QUG",
    coordinates: "-0.759167, 50.859402",
  },
  {
    ident: "EGJA",
    type: "medium_airport",
    name: "Alderney Airport",
    elevation_ft: "290",
    continent: "EU",
    iso_country: "GG",
    iso_region: "GG-U-A",
    municipality: "Saint Anne",
    gps_code: "EGJA",
    iata_code: "ACI",
    coordinates: "-2.21472, 49.706104",
  },
  {
    ident: "EGJB",
    type: "medium_airport",
    name: "Guernsey Airport",
    elevation_ft: "336",
    continent: "EU",
    iso_country: "GG",
    iso_region: "GG-U-A",
    municipality: "Saint Peter Port",
    gps_code: "EGJB",
    iata_code: "GCI",
    coordinates: "-2.6019699573516846, 49.435001373291016",
  },
  {
    ident: "EGJJ",
    type: "medium_airport",
    name: "Jersey Airport",
    elevation_ft: "277",
    continent: "EU",
    iso_country: "JE",
    iso_region: "JE-U-A",
    municipality: "Saint Helier",
    gps_code: "EGJJ",
    iata_code: "JER",
    coordinates: "-2.195509910583496, 49.20790100097656",
  },
  {
    ident: "EGKA",
    type: "medium_airport",
    name: "Shoreham Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Brighton",
    gps_code: "EGKA",
    iata_code: "ESH",
    coordinates: "-0.297222, 50.835602",
  },
  {
    ident: "EGKB",
    type: "medium_airport",
    name: "London Biggin Hill Airport",
    elevation_ft: "598",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "London",
    gps_code: "EGKB",
    iata_code: "BQH",
    coordinates: "0.0324999988079, 51.33079910279999",
  },
  {
    ident: "EGKK",
    type: "large_airport",
    name: "London Gatwick Airport",
    elevation_ft: "202",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "London",
    gps_code: "EGKK",
    iata_code: "LGW",
    coordinates: "-0.190278, 51.148102",
  },
  {
    ident: "EGKM",
    type: "closed",
    name: "RAF West Malling",
    elevation_ft: "308",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "West Malling",
    gps_code: "EGKM",
    iata_code: "WEM",
    coordinates: "0.4, 51.27",
  },
  {
    ident: "EGKR",
    type: "small_airport",
    name: "Redhill Aerodrome",
    elevation_ft: "222",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Redhill",
    gps_code: "EGKR",
    iata_code: "KRH",
    coordinates: "-0.138611003757, 51.2136001587",
  },
  {
    ident: "EGLC",
    type: "medium_airport",
    name: "London City Airport",
    elevation_ft: "19",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "London",
    gps_code: "EGLC",
    iata_code: "LCY",
    coordinates: "0.055278, 51.505299",
  },
  {
    ident: "EGLF",
    type: "medium_airport",
    name: "Farnborough Airport",
    elevation_ft: "238",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Farnborough",
    gps_code: "EGLF",
    iata_code: "FAB",
    coordinates: "-0.776332974434, 51.2757987976",
  },
  {
    ident: "EGLK",
    type: "medium_airport",
    name: "Blackbushe Airport",
    elevation_ft: "325",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Yateley",
    gps_code: "EGLK",
    iata_code: "BBS",
    coordinates: "-0.8475000262260437, 51.32389831542969",
  },
  {
    ident: "EGLL",
    type: "large_airport",
    name: "London Heathrow Airport",
    elevation_ft: "83",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "London",
    gps_code: "EGLL",
    iata_code: "LHR",
    coordinates: "-0.461941, 51.4706",
  },
  {
    ident: "EGMC",
    type: "medium_airport",
    name: "Southend Airport",
    elevation_ft: "49",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Southend",
    gps_code: "EGMC",
    iata_code: "SEN",
    coordinates: "0.6955559849739075, 51.5713996887207",
  },
  {
    ident: "EGMD",
    type: "medium_airport",
    name: "Lydd Airport",
    elevation_ft: "13",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Lydd, Ashford",
    gps_code: "EGMD",
    iata_code: "LYX",
    coordinates: "0.9391670227050781, 50.95610046386719",
  },
  {
    ident: "EGMH",
    type: "closed",
    name: "Kent International Airport",
    elevation_ft: "178",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Manston",
    gps_code: "EGMH",
    iata_code: "MSE",
    coordinates: "1.34611, 51.342201",
  },
  {
    ident: "EGMK",
    type: "closed",
    name: "Lympne Airport",
    elevation_ft: "351",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Ashford",
    gps_code: "EGMK",
    iata_code: "LYM",
    coordinates: "1.013, 51.08",
  },
  {
    ident: "EGNC",
    type: "medium_airport",
    name: "Carlisle Airport",
    elevation_ft: "190",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Carlisle",
    gps_code: "EGNC",
    iata_code: "CAX",
    coordinates: "-2.8091700077056885, 54.9375",
  },
  {
    ident: "EGNH",
    type: "medium_airport",
    name: "Blackpool International Airport",
    elevation_ft: "34",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Blackpool",
    gps_code: "EGNH",
    iata_code: "BLK",
    coordinates: "-3.0286099910736084, 53.77170181274414",
  },
  {
    ident: "EGNJ",
    type: "medium_airport",
    name: "Humberside Airport",
    elevation_ft: "121",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Grimsby",
    gps_code: "EGNJ",
    iata_code: "HUY",
    coordinates: "-0.350832998752594, 53.57440185546875",
  },
  {
    ident: "EGNL",
    type: "medium_airport",
    name: "Barrow Walney Island Airport",
    elevation_ft: "173",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Barrow-in-Furness",
    gps_code: "EGNL",
    iata_code: "BWF",
    coordinates: "-3.2675, 54.1286111",
  },
  {
    ident: "EGNM",
    type: "large_airport",
    name: "Leeds Bradford Airport",
    elevation_ft: "681",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Leeds",
    gps_code: "EGNM",
    iata_code: "LBA",
    coordinates: "-1.6605700254440308, 53.86589813232422",
  },
  {
    ident: "EGNO",
    type: "medium_airport",
    name: "Warton Aerodrome",
    elevation_ft: "55",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Warton",
    gps_code: "EGNO",
    iata_code: "WRT",
    coordinates: "-2.88306, 53.745098",
  },
  {
    ident: "EGNR",
    type: "medium_airport",
    name: "Hawarden Airport",
    elevation_ft: "45",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-WLS",
    municipality: "Hawarden",
    gps_code: "EGNR",
    iata_code: "CEG",
    coordinates: "-2.9777801036834717, 53.1781005859375",
  },
  {
    ident: "EGNS",
    type: "medium_airport",
    name: "Isle of Man Airport",
    elevation_ft: "52",
    continent: "EU",
    iso_country: "IM",
    iso_region: "IM-U-A",
    municipality: "Castletown",
    gps_code: "EGNS",
    iata_code: "IOM",
    coordinates: "-4.623889923095703, 54.08330154418945",
  },
  {
    ident: "EGNT",
    type: "large_airport",
    name: "Newcastle Airport",
    elevation_ft: "266",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Newcastle",
    gps_code: "EGNT",
    iata_code: "NCL",
    coordinates: "-1.6916699409484863, 55.037498474121094",
  },
  {
    ident: "EGNV",
    type: "medium_airport",
    name: "Durham Tees Valley Airport",
    elevation_ft: "120",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Durham",
    gps_code: "EGNV",
    iata_code: "MME",
    coordinates: "-1.4294099807739258, 54.50920104980469",
  },
  {
    ident: "EGNX",
    type: "large_airport",
    name: "East Midlands Airport",
    elevation_ft: "306",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Nottingham",
    gps_code: "EGNX",
    iata_code: "EMA",
    coordinates: "-1.32806003094, 52.8311004639",
  },
  {
    ident: "EGOV",
    type: "medium_airport",
    name: "Anglesey Airport",
    elevation_ft: "37",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-WLS",
    municipality: "Angelsey",
    gps_code: "EGOV",
    iata_code: "VLY",
    coordinates: "-4.53533983231, 53.2481002808",
  },
  {
    ident: "EGPA",
    type: "medium_airport",
    name: "Kirkwall Airport",
    elevation_ft: "50",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Orkney Islands",
    gps_code: "EGPA",
    iata_code: "KOI",
    coordinates: "-2.9049999713897705, 58.957801818847656",
  },
  {
    ident: "EGPB",
    type: "medium_airport",
    name: "Sumburgh Airport",
    elevation_ft: "20",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Lerwick",
    gps_code: "EGPB",
    iata_code: "LSI",
    coordinates: "-1.2955600023269653, 59.87889862060547",
  },
  {
    ident: "EGPC",
    type: "medium_airport",
    name: "Wick Airport",
    elevation_ft: "126",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Wick",
    gps_code: "EGPC",
    iata_code: "WIC",
    coordinates: "-3.09306001663208, 58.458900451660156",
  },
  {
    ident: "EGPD",
    type: "large_airport",
    name: "Aberdeen Dyce Airport",
    elevation_ft: "215",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Aberdeen",
    gps_code: "EGPD",
    iata_code: "ABZ",
    coordinates: "-2.197779893875122, 57.201900482177734",
  },
  {
    ident: "EGPE",
    type: "medium_airport",
    name: "Inverness Airport",
    elevation_ft: "31",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Inverness",
    gps_code: "EGPE",
    iata_code: "INV",
    coordinates: "-4.047500133514404, 57.54249954223633",
  },
  {
    ident: "EGPF",
    type: "large_airport",
    name: "Glasgow International Airport",
    elevation_ft: "26",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Glasgow",
    gps_code: "EGPF",
    iata_code: "GLA",
    coordinates: "-4.43306, 55.871899",
  },
  {
    ident: "EGPH",
    type: "large_airport",
    name: "Edinburgh Airport",
    elevation_ft: "135",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Edinburgh",
    gps_code: "EGPH",
    iata_code: "EDI",
    coordinates: "-3.372499942779541, 55.95000076293945",
  },
  {
    ident: "EGPI",
    type: "medium_airport",
    name: "Islay Airport",
    elevation_ft: "56",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Port Ellen",
    gps_code: "EGPI",
    iata_code: "ILY",
    coordinates: "-6.256669998168945, 55.68190002441406",
  },
  {
    ident: "EGPK",
    type: "medium_airport",
    name: "Glasgow Prestwick Airport",
    elevation_ft: "65",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Glasgow",
    gps_code: "EGPK",
    iata_code: "PIK",
    coordinates: "-4.586669921875, 55.5093994140625",
  },
  {
    ident: "EGPL",
    type: "medium_airport",
    name: "Benbecula Airport",
    elevation_ft: "19",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Balivanich",
    gps_code: "EGPL",
    iata_code: "BEB",
    coordinates: "-7.3627800941467285, 57.48109817504883",
  },
  {
    ident: "EGPM",
    type: "medium_airport",
    name: "Scatsta Airport",
    elevation_ft: "81",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Shetland Islands",
    gps_code: "EGPM",
    iata_code: "SCS",
    coordinates: "-1.2961100339889526, 60.43280029296875",
  },
  {
    ident: "EGPN",
    type: "medium_airport",
    name: "Dundee Airport",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Dundee",
    gps_code: "EGPN",
    iata_code: "DND",
    coordinates: "-3.025830030441284, 56.45249938964844",
  },
  {
    ident: "EGPO",
    type: "medium_airport",
    name: "Stornoway Airport",
    elevation_ft: "26",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Stornoway",
    gps_code: "EGPO",
    iata_code: "SYY",
    coordinates: "-6.331110000610352, 58.215599060058594",
  },
  {
    ident: "EGPR",
    type: "medium_airport",
    name: "Barra Airport",
    elevation_ft: "5",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Eoligarry",
    gps_code: "EGPR",
    iata_code: "BRR",
    coordinates: "-7.44306, 57.0228",
  },
  {
    ident: "EGPT",
    type: "small_airport",
    name: "Perth/Scone Airport",
    elevation_ft: "397",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Perth",
    gps_code: "EGPT",
    iata_code: "PSL",
    coordinates: "-3.372220039367676, 56.43920135498047",
  },
  {
    ident: "EGPU",
    type: "medium_airport",
    name: "Tiree Airport",
    elevation_ft: "38",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Balemartine",
    gps_code: "EGPU",
    iata_code: "TRE",
    coordinates: "-6.869170188903809, 56.49919891357422",
  },
  {
    ident: "EGPW",
    type: "small_airport",
    name: "Unst Airport",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Shetland Islands",
    gps_code: "EGPW",
    iata_code: "UNT",
    coordinates: "-0.8538500070571899, 60.74720001220703",
  },
  {
    ident: "EGQK",
    type: "medium_airport",
    name: "RAF Kinloss",
    elevation_ft: "22",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Kinloss",
    gps_code: "EGQK",
    iata_code: "FSS",
    coordinates: "-3.56064009666, 57.6493988037",
  },
  {
    ident: "EGQL",
    type: "medium_airport",
    name: "RAF Leuchars",
    elevation_ft: "38",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "St. Andrews",
    gps_code: "EGQL",
    iata_code: "ADX",
    coordinates: "-2.8684399127960205, 56.37289810180664",
  },
  {
    ident: "EGQS",
    type: "medium_airport",
    name: "RAF Lossiemouth",
    elevation_ft: "42",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Lossiemouth",
    gps_code: "EGQS",
    iata_code: "LMO",
    coordinates: "-3.339169979095459, 57.7052001953125",
  },
  {
    ident: "EGSC",
    type: "medium_airport",
    name: "Cambridge Airport",
    elevation_ft: "47",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Cambridge",
    gps_code: "EGSC",
    iata_code: "CBG",
    coordinates: "0.175, 52.205002",
  },
  {
    ident: "EGSH",
    type: "large_airport",
    name: "Norwich International Airport",
    elevation_ft: "117",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Norwich",
    gps_code: "EGSH",
    iata_code: "NWI",
    coordinates: "1.28278005123, 52.6758003235",
  },
  {
    ident: "EGSS",
    type: "large_airport",
    name: "London Stansted Airport",
    elevation_ft: "348",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "London",
    gps_code: "EGSS",
    iata_code: "STN",
    coordinates: "0.234999999404, 51.8849983215",
  },
  {
    ident: "EGTB",
    type: "small_airport",
    name: "Wycombe Air Park",
    elevation_ft: "515",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "High Wycombe",
    gps_code: "EGTB",
    iata_code: "HYC",
    coordinates: "-0.8083329796790001, 51.6116981506",
  },
  {
    ident: "EGTE",
    type: "large_airport",
    name: "Exeter International Airport",
    elevation_ft: "102",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Exeter",
    gps_code: "EGTE",
    iata_code: "EXT",
    coordinates: "-3.4138898849487305, 50.73440170288086",
  },
  {
    ident: "EGTG",
    type: "closed",
    name: "Bristol Filton Airport",
    elevation_ft: "226",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Bristol",
    gps_code: "EGTG",
    iata_code: "FZO",
    coordinates: "-2.59083008766, 51.5194015503",
  },
  {
    ident: "EGTK",
    type: "medium_airport",
    name: "Oxford (Kidlington) Airport",
    elevation_ft: "270",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Kidlington",
    gps_code: "EGTK",
    iata_code: "OXF",
    coordinates: "-1.32000005245, 51.8368988037",
  },
  {
    ident: "EGTO",
    type: "small_airport",
    name: "Rochester Airport",
    elevation_ft: "436",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Rochester",
    gps_code: "EGTO",
    iata_code: "RCS",
    coordinates: "0.5033329725265503, 51.351898193359375",
  },
  {
    ident: "EGUA",
    type: "closed",
    name: "RAF Upper Heyford",
    elevation_ft: "436",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Upper Heyford",
    gps_code: "EGUA",
    iata_code: "UHF",
    coordinates: "-1.2488900423, 51.9375",
  },
  {
    ident: "EGUB",
    type: "medium_airport",
    name: "RAF Benson",
    elevation_ft: "226",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Benson",
    gps_code: "EGUB",
    iata_code: "BEX",
    coordinates: "-1.09582996368, 51.616401672399995",
  },
  {
    ident: "EGUL",
    type: "large_airport",
    name: "RAF Lakenheath",
    elevation_ft: "32",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Lakenheath",
    gps_code: "EGUL",
    iata_code: "LKZ",
    coordinates: "0.56099998951, 52.409301757799994",
  },
  {
    ident: "EGUN",
    type: "large_airport",
    name: "RAF Mildenhall",
    elevation_ft: "33",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Mildenhall",
    gps_code: "EGUN",
    iata_code: "MHZ",
    coordinates: "0.48640599846839905, 52.361900329589844",
  },
  {
    ident: "EGUY",
    type: "medium_airport",
    name: "RAF Wyton",
    elevation_ft: "135",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "St. Ives",
    gps_code: "EGUY",
    iata_code: "QUY",
    coordinates: "-0.107832998037, 52.3572006226",
  },
  {
    ident: "EGVA",
    type: "large_airport",
    name: "RAF Fairford",
    elevation_ft: "286",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Fairford",
    gps_code: "EGVA",
    iata_code: "FFD",
    coordinates: "-1.7900300025900002, 51.6822013855",
  },
  {
    ident: "EGVF",
    type: "heliport",
    name: "Fleetlands Heliport",
    elevation_ft: "3",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Gosport",
    gps_code: "EGVF",
    iata_code: "PME",
    coordinates: "-1.1691700220099999, 50.8353004456",
  },
  {
    ident: "EGVJ",
    type: "closed",
    name: "RAF Bentwaters",
    elevation_ft: "85",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Woodbridge",
    gps_code: "EGVJ",
    iata_code: "BWY",
    coordinates: "1.43472003937, 52.1274986267",
  },
  {
    ident: "EGVN",
    type: "large_airport",
    name: "RAF Brize Norton",
    elevation_ft: "288",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Brize Norton",
    gps_code: "EGVN",
    iata_code: "BZZ",
    coordinates: "-1.58362, 51.75",
  },
  {
    ident: "EGVO",
    type: "medium_airport",
    name: "RAF Odiham",
    elevation_ft: "405",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Odiham",
    gps_code: "EGVO",
    iata_code: "ODH",
    coordinates: "-0.94282501936, 51.2341003418",
  },
  {
    ident: "EGWU",
    type: "medium_airport",
    name: "RAF Northolt",
    elevation_ft: "124",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "London",
    gps_code: "EGWU",
    iata_code: "NHT",
    coordinates: "-0.418166995049, 51.553001403799996",
  },
  {
    ident: "EGWZ",
    type: "closed",
    name: "RAF Alconbury",
    elevation_ft: "157",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Huntingdon",
    gps_code: "EGWZ",
    iata_code: "AYH",
    coordinates: "-0.219722002745, 52.374401092499994",
  },
  {
    ident: "EGXB",
    type: "closed",
    name: "Binbrook Airfield",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Grimsby",
    gps_code: "EGXB",
    iata_code: "GSY",
    coordinates: "-0.209999993443, 53.4467010498",
  },
  {
    ident: "EGXC",
    type: "medium_airport",
    name: "RAF Coningsby",
    elevation_ft: "25",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Coningsby",
    gps_code: "EGXC",
    iata_code: "QCY",
    coordinates: "-0.166014000773, 53.0929985046",
  },
  {
    ident: "EGXH",
    type: "medium_airport",
    name: "RAF Honington",
    elevation_ft: "174",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Thetford",
    gps_code: "EGXH",
    iata_code: "BEQ",
    coordinates: "0.7729390263557434, 52.34260177612305",
  },
  {
    ident: "EGXJ",
    type: "closed",
    name: "RAF Cottesmore",
    elevation_ft: "461",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Cottesmore",
    gps_code: "EGXJ",
    iata_code: "OKH",
    coordinates: "-0.648769, 52.735699",
  },
  {
    ident: "EGXP",
    type: "medium_airport",
    name: "RAF Scampton",
    elevation_ft: "202",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Scampton",
    gps_code: "EGXP",
    iata_code: "SQZ",
    coordinates: "-0.550832986832, 53.307800293",
  },
  {
    ident: "EGXU",
    type: "medium_airport",
    name: "RAF Linton-On-Ouse",
    elevation_ft: "53",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Linton-On-Ouse",
    gps_code: "EGXU",
    iata_code: "HRT",
    coordinates: "-1.2527500391, 54.0489006042",
  },
  {
    ident: "EGXW",
    type: "medium_airport",
    name: "RAF Waddington",
    elevation_ft: "231",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Waddington",
    gps_code: "EGXW",
    iata_code: "WTN",
    coordinates: "-0.523810982704, 53.1661987305",
  },
  {
    ident: "EGYC",
    type: "closed",
    name: "RAF Coltishall",
    elevation_ft: "66",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Norwich",
    gps_code: "EGYC",
    iata_code: "CLF",
    coordinates: "1.35722, 52.7547",
  },
  {
    ident: "EGYM",
    type: "medium_airport",
    name: "RAF Marham",
    elevation_ft: "75",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Marham",
    gps_code: "EGYM",
    iata_code: "KNF",
    coordinates: "0.550692, 52.648395",
  },
  {
    ident: "EGYP",
    type: "medium_airport",
    name: "Mount Pleasant Airport",
    elevation_ft: "244",
    continent: "SA",
    iso_country: "FK",
    iso_region: "FK-U-A",
    municipality: "Mount Pleasant",
    gps_code: "EGYP",
    iata_code: "MPN",
    coordinates: "-58.447200775146484, -51.82279968261719",
  },
  {
    ident: "EHAM",
    type: "large_airport",
    name: "Amsterdam Airport Schiphol",
    elevation_ft: "-11",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-NH",
    municipality: "Amsterdam",
    gps_code: "EHAM",
    iata_code: "AMS",
    coordinates: "4.76389, 52.308601",
  },
  {
    ident: "EHBK",
    type: "medium_airport",
    name: "Maastricht Aachen Airport",
    elevation_ft: "375",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-LI",
    municipality: "Maastricht",
    gps_code: "EHBK",
    iata_code: "MST",
    coordinates: "5.77014, 50.911701",
  },
  {
    ident: "EHEH",
    type: "large_airport",
    name: "Eindhoven Airport",
    elevation_ft: "74",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-NB",
    municipality: "Eindhoven",
    gps_code: "EHEH",
    iata_code: "EIN",
    coordinates: "5.37452983856, 51.4500999451",
  },
  {
    ident: "EHGG",
    type: "medium_airport",
    name: "Eelde Airport",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-DR",
    municipality: "Groningen",
    gps_code: "EHGG",
    iata_code: "GRQ",
    coordinates: "6.57944011688, 53.1197013855",
  },
  {
    ident: "EHGR",
    type: "medium_airport",
    name: "Gilze Rijen Air Base",
    elevation_ft: "49",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-NB",
    municipality: "Breda",
    gps_code: "EHGR",
    iata_code: "GLZ",
    coordinates: "4.931829929351807, 51.56740188598633",
  },
  {
    ident: "EHKD",
    type: "medium_airport",
    name: "De Kooy Airport",
    elevation_ft: "3",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-NH",
    municipality: "Den Helder",
    gps_code: "EHKD",
    iata_code: "DHR",
    coordinates: "4.780620098114014, 52.92340087890625",
  },
  {
    ident: "EHLE",
    type: "medium_airport",
    name: "Lelystad Airport",
    elevation_ft: "-13",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-FL",
    municipality: "Lelystad",
    gps_code: "EHLE",
    iata_code: "LEY",
    coordinates: "5.52722, 52.4603",
  },
  {
    ident: "EHLW",
    type: "medium_airport",
    name: "Leeuwarden Air Base",
    elevation_ft: "3",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-FR",
    municipality: "Leeuwarden",
    gps_code: "EHLW",
    iata_code: "LWR",
    coordinates: "5.760560035705566, 53.228599548339844",
  },
  {
    ident: "EHRD",
    type: "medium_airport",
    name: "Rotterdam The Hague Airport",
    elevation_ft: "-15",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-ZH",
    municipality: "Rotterdam",
    gps_code: "EHRD",
    iata_code: "RTM",
    coordinates: "4.43722, 51.956902",
  },
  {
    ident: "EHSB",
    type: "closed",
    name: "Soesterberg Air Base",
    elevation_ft: "66",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-UT",
    municipality: "Soesterberg",
    gps_code: "EHSB",
    iata_code: "UTC",
    coordinates: "5.27619, 52.1273",
  },
  {
    ident: "EHTW",
    type: "medium_airport",
    name: "Twente Airport",
    elevation_ft: "114",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-OV",
    municipality: "Enschede",
    gps_code: "EHTW",
    iata_code: "ENS",
    coordinates: "6.889167, 52.275833",
  },
  {
    ident: "EHVB",
    type: "closed",
    name: "Valkenburg Naval Air Base",
    elevation_ft: "1",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-ZH",
    municipality: "Leiden",
    gps_code: "EHVB",
    iata_code: "LID",
    coordinates: "4.41794013977, 52.166099548300004",
  },
  {
    ident: "EHWO",
    type: "medium_airport",
    name: "Woensdrecht Air Base",
    elevation_ft: "63",
    continent: "EU",
    iso_country: "NL",
    iso_region: "NL-NB",
    municipality: "Bergen Op Zoom",
    gps_code: "EHWO",
    iata_code: "WOE",
    coordinates: "4.34203, 51.4491",
  },
  {
    ident: "EIBN",
    type: "small_airport",
    name: "Bantry Aerodrome",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-C",
    municipality: "Bantry",
    gps_code: "EIBN",
    iata_code: "BYT",
    coordinates: "-9.484169960021973, 51.66859817504883",
  },
  {
    ident: "EIBT",
    type: "small_airport",
    name: "Belmullet Aerodrome",
    elevation_ft: "150",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-MO",
    municipality: "Belmullet",
    gps_code: "EIBT",
    iata_code: "BLY",
    coordinates: "-10.0307998657, 54.2228012085",
  },
  {
    ident: "EICA",
    type: "small_airport",
    name: "Connemara Regional Airport",
    elevation_ft: "70",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-G",
    municipality: "Inverin",
    gps_code: "EICA",
    iata_code: "NNR",
    coordinates: "-9.467780113220215, 53.23030090332031",
  },
  {
    ident: "EICB",
    type: "closed",
    name: "Castlebar Airport",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-MO",
    municipality: "Castlebar",
    gps_code: "EICB",
    iata_code: "CLB",
    coordinates: "-9.28037, 53.8484",
  },
  {
    ident: "EICK",
    type: "large_airport",
    name: "Cork Airport",
    elevation_ft: "502",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-C",
    municipality: "Cork",
    gps_code: "EICK",
    iata_code: "ORK",
    coordinates: "-8.491109848022461, 51.84130096435547",
  },
  {
    ident: "EICM",
    type: "medium_airport",
    name: "Galway Airport",
    elevation_ft: "81",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-G",
    municipality: "Galway",
    gps_code: "EICM",
    iata_code: "GWY",
    coordinates: "-8.941590309143066, 53.300201416015625",
  },
  {
    ident: "EIDL",
    type: "medium_airport",
    name: "Donegal Airport",
    elevation_ft: "30",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-DL",
    municipality: "Donegal",
    gps_code: "EIDL",
    iata_code: "CFN",
    coordinates: "-8.340999603271484, 55.0442008972168",
  },
  {
    ident: "EIDW",
    type: "large_airport",
    name: "Dublin Airport",
    elevation_ft: "242",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-D",
    municipality: "Dublin",
    gps_code: "EIDW",
    iata_code: "DUB",
    coordinates: "-6.27007, 53.421299",
  },
  {
    ident: "EIIM",
    type: "small_airport",
    name: "Inishmore Aerodrome",
    elevation_ft: "24",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-G",
    municipality: "Inis M����r",
    gps_code: "EIIM",
    iata_code: "IOR",
    coordinates: "-9.653610229492188, 53.1067008972168",
  },
  {
    ident: "EIIR",
    type: "small_airport",
    name: "Inisheer Aerodrome",
    elevation_ft: "40",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-G",
    municipality: "Inis O����rr",
    gps_code: "EIIR",
    iata_code: "INQ",
    coordinates: "-9.510899543762207, 53.064701080322266",
  },
  {
    ident: "EIKK",
    type: "small_airport",
    name: "Kilkenny Airport",
    elevation_ft: "319",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-KK",
    municipality: "Kilkenny",
    gps_code: "EIKK",
    iata_code: "KKY",
    coordinates: "-7.296110153198242, 52.65079879760742",
  },
  {
    ident: "EIKN",
    type: "medium_airport",
    name: "Ireland West Knock Airport",
    elevation_ft: "665",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-MO",
    municipality: "Charleston",
    gps_code: "EIKN",
    iata_code: "NOC",
    coordinates: "-8.818490028381348, 53.910301208496094",
  },
  {
    ident: "EIKY",
    type: "medium_airport",
    name: "Kerry Airport",
    elevation_ft: "112",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-KY",
    municipality: "Killarney",
    gps_code: "EIKY",
    iata_code: "KIR",
    coordinates: "-9.52377986907959, 52.18090057373047",
  },
  {
    ident: "EILT",
    type: "small_airport",
    name: "Letterkenny Airfield",
    elevation_ft: "20",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-DL",
    municipality: "Letterkenny",
    gps_code: "EILT",
    iata_code: "LTR",
    coordinates: "-7.67283, 54.951302",
  },
  {
    ident: "EIMN",
    type: "small_airport",
    name: "Inishmaan Aerodrome",
    elevation_ft: "15",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-G",
    municipality: "Inis Me����in",
    gps_code: "EIMN",
    iata_code: "IIA",
    coordinates: "-9.568059921264648, 53.09299850463867",
  },
  {
    ident: "EINN",
    type: "large_airport",
    name: "Shannon Airport",
    elevation_ft: "46",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-CE",
    municipality: "Shannon",
    gps_code: "EINN",
    iata_code: "SNN",
    coordinates: "-8.92482, 52.702",
  },
  {
    ident: "EISG",
    type: "medium_airport",
    name: "Sligo Airport",
    elevation_ft: "11",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-SO",
    municipality: "Sligo",
    gps_code: "EISG",
    iata_code: "SXL",
    coordinates: "-8.5992097854614, 54.280200958252",
  },
  {
    ident: "EIWF",
    type: "medium_airport",
    name: "Waterford Airport",
    elevation_ft: "119",
    continent: "EU",
    iso_country: "IE",
    iso_region: "IE-WD",
    municipality: "Waterford",
    gps_code: "EIWF",
    iata_code: "WAT",
    coordinates: "-7.0869598388671875, 52.187198638916016",
  },
  {
    ident: "EJN",
    type: "small_airport",
    name: "Ejin Banner-Taolai Airport",
    elevation_ft: "3077",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Ejin Banner",
    gps_code: "ZBEN",
    iata_code: "EJN",
    coordinates: "101.0005, 42.0155",
  },
  {
    ident: "EJT",
    type: "small_airport",
    name: "Enejit Airport",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-MIL",
    municipality: "Enejit Island",
    iata_code: "EJT",
    coordinates: "171.9846, 6.0404",
  },
  {
    ident: "EKAH",
    type: "medium_airport",
    name: "Aarhus Airport",
    elevation_ft: "82",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-82",
    municipality: "Aarhus",
    gps_code: "EKAH",
    iata_code: "AAR",
    coordinates: "10.619000434899998, 56.2999992371",
  },
  {
    ident: "EKBI",
    type: "large_airport",
    name: "Billund Airport",
    elevation_ft: "247",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-83",
    municipality: "Billund",
    gps_code: "EKBI",
    iata_code: "BLL",
    coordinates: "9.15178012848, 55.7402992249",
  },
  {
    ident: "EKCH",
    type: "large_airport",
    name: "Copenhagen Kastrup Airport",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-84",
    municipality: "Copenhagen",
    gps_code: "EKCH",
    iata_code: "CPH",
    coordinates: "12.656000137329, 55.617900848389",
  },
  {
    ident: "EKEB",
    type: "medium_airport",
    name: "Esbjerg Airport",
    elevation_ft: "97",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-83",
    municipality: "Esbjerg",
    gps_code: "EKEB",
    iata_code: "EBJ",
    coordinates: "8.5534, 55.525902",
  },
  {
    ident: "EKKA",
    type: "medium_airport",
    name: "Karup Airport",
    elevation_ft: "170",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-82",
    municipality: "Karup",
    gps_code: "EKKA",
    iata_code: "KRP",
    coordinates: "9.124629974365234, 56.29750061035156",
  },
  {
    ident: "EKLS",
    type: "small_airport",
    name: "L����s���� Airport",
    elevation_ft: "25",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-81",
    municipality: "L����s����",
    gps_code: "EKLS",
    iata_code: "BYR",
    coordinates: "11.000100135803223, 57.277198791503906",
  },
  {
    ident: "EKMB",
    type: "medium_airport",
    name: "Lolland Falster Maribo Airport",
    elevation_ft: "16",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-85",
    municipality: "Lolland Falster / Maribo",
    gps_code: "EKMB",
    iata_code: "MRW",
    coordinates: "11.4401, 54.699299",
  },
  {
    ident: "EKOD",
    type: "medium_airport",
    name: "Odense Airport",
    elevation_ft: "56",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-83",
    municipality: "Odense",
    gps_code: "EKOD",
    iata_code: "ODE",
    coordinates: "10.330900192260742, 55.47669982910156",
  },
  {
    ident: "EKRK",
    type: "medium_airport",
    name: "Copenhagen Roskilde Airport",
    elevation_ft: "146",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-85",
    municipality: "Copenhagen",
    gps_code: "EKRK",
    iata_code: "RKE",
    coordinates: "12.131400108337402, 55.585601806640625",
  },
  {
    ident: "EKRN",
    type: "medium_airport",
    name: "Bornholm Airport",
    elevation_ft: "52",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-84",
    municipality: "R����nne",
    gps_code: "EKRN",
    iata_code: "RNN",
    coordinates: "14.759599685668945, 55.06330108642578",
  },
  {
    ident: "EKSB",
    type: "medium_airport",
    name: "S����nderborg Airport",
    elevation_ft: "24",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-83",
    municipality: "S����nderborg",
    gps_code: "EKSB",
    iata_code: "SGD",
    coordinates: "9.791729927062988, 54.96440124511719",
  },
  {
    ident: "EKSN",
    type: "medium_airport",
    name: "Sindal Airport",
    elevation_ft: "92",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-81",
    municipality: "Sindal",
    gps_code: "EKSN",
    iata_code: "CNL",
    coordinates: "10.2294, 57.503502",
  },
  {
    ident: "EKSP",
    type: "medium_airport",
    name: "Skrydstrup Air Base",
    elevation_ft: "141",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-83",
    municipality: "Vojens",
    gps_code: "EKSP",
    iata_code: "SKS",
    coordinates: "9.26702, 55.221048",
  },
  {
    ident: "EKSV",
    type: "medium_airport",
    name: "Skive Airport",
    elevation_ft: "74",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-82",
    municipality: "Skive",
    gps_code: "EKSV",
    iata_code: "SQW",
    coordinates: "9.172980308532715, 56.550201416015625",
  },
  {
    ident: "EKTS",
    type: "medium_airport",
    name: "Thisted Airport",
    elevation_ft: "23",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-81",
    municipality: "Thisted",
    gps_code: "EKTS",
    iata_code: "TED",
    coordinates: "8.705220222473145, 57.06880187988281",
  },
  {
    ident: "EKVG",
    type: "medium_airport",
    name: "Vagar Airport",
    elevation_ft: "280",
    continent: "EU",
    iso_country: "FO",
    iso_region: "FO-U-A",
    municipality: "Vagar",
    gps_code: "EKVG",
    iata_code: "FAE",
    coordinates: "-7.277219772338867, 62.0635986328125",
  },
  {
    ident: "EKVJ",
    type: "medium_airport",
    name: "Stauning Airport",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-82",
    municipality: "Skjern / Ringk����bing",
    gps_code: "EKVJ",
    iata_code: "STA",
    coordinates: "8.353910446166992, 55.9901008605957",
  },
  {
    ident: "EKYT",
    type: "large_airport",
    name: "Aalborg Airport",
    elevation_ft: "10",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-81",
    municipality: "Aalborg",
    gps_code: "EKYT",
    iata_code: "AAL",
    coordinates: "9.84924316406, 57.0927589138",
  },
  {
    ident: "EK_4",
    type: "small_airport",
    name: "Vejr����",
    continent: "EU",
    iso_country: "DK",
    iso_region: "DK-85",
    gps_code: "EK_4",
    iata_code: "-",
    local_code: "-",
    coordinates: "11.375, 55.035",
  },
  {
    ident: "ELLX",
    type: "large_airport",
    name: "Luxembourg-Findel International Airport",
    elevation_ft: "1234",
    continent: "EU",
    iso_country: "LU",
    iso_region: "LU-L",
    municipality: "Luxembourg",
    gps_code: "ELLX",
    iata_code: "LUX",
    coordinates: "6.2044444, 49.6233333",
  },
  {
    ident: "EMR",
    type: "heliport",
    name: "El Mirador Heliport",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-PE",
    municipality: "El Mirador",
    iata_code: "EMR",
    coordinates: "-89.924916, 17.752077",
  },
  {
    ident: "ENAL",
    type: "medium_airport",
    name: "����lesund Airport",
    elevation_ft: "69",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-15",
    municipality: "����lesund",
    gps_code: "ENAL",
    iata_code: "AES",
    coordinates: "6.119699954986572, 62.5625",
  },
  {
    ident: "ENAN",
    type: "medium_airport",
    name: "And����ya Airport",
    elevation_ft: "43",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Andenes",
    gps_code: "ENAN",
    iata_code: "ANX",
    coordinates: "16.144199371338, 69.292503356934",
  },
  {
    ident: "ENAT",
    type: "medium_airport",
    name: "Alta Airport",
    elevation_ft: "9",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Alta",
    gps_code: "ENAT",
    iata_code: "ALF",
    coordinates: "23.371700286865, 69.976097106934",
  },
  {
    ident: "ENBL",
    type: "small_airport",
    name: "F����rde Airport",
    elevation_ft: "1046",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-14",
    municipality: "F����rde",
    gps_code: "ENBL",
    iata_code: "FDE",
    coordinates: "5.7569398880005, 61.391101837158",
  },
  {
    ident: "ENBN",
    type: "medium_airport",
    name: "Br����nn����ysund Airport",
    elevation_ft: "25",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Br����nn����y",
    gps_code: "ENBN",
    iata_code: "BNN",
    coordinates: "12.217499732971, 65.461097717285",
  },
  {
    ident: "ENBO",
    type: "large_airport",
    name: "Bod���� Airport",
    elevation_ft: "42",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Bod����",
    gps_code: "ENBO",
    iata_code: "BOO",
    coordinates: "14.365300178527832, 67.26920318603516",
  },
  {
    ident: "ENBR",
    type: "large_airport",
    name: "Bergen Airport Flesland",
    elevation_ft: "170",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-12",
    municipality: "Bergen",
    gps_code: "ENBR",
    iata_code: "BGO",
    coordinates: "5.218140125, 60.29339981",
  },
  {
    ident: "ENBS",
    type: "medium_airport",
    name: "B����tsfjord Airport",
    elevation_ft: "490",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "B����tsfjord",
    gps_code: "ENBS",
    iata_code: "BJF",
    coordinates: "29.691400527954, 70.60050201416",
  },
  {
    ident: "ENBV",
    type: "medium_airport",
    name: "Berlev����g Airport",
    elevation_ft: "42",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Berlev����g",
    gps_code: "ENBV",
    iata_code: "BVG",
    coordinates: "29.034201, 70.871399",
  },
  {
    ident: "ENCN",
    type: "medium_airport",
    name: "Kristiansand Airport",
    elevation_ft: "57",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-10",
    municipality: "Kjevik",
    gps_code: "ENCN",
    iata_code: "KRS",
    coordinates: "8.08537, 58.204201",
  },
  {
    ident: "ENDI",
    type: "small_airport",
    name: "Geilo Airport Dagali",
    elevation_ft: "2618",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-06",
    municipality: "Dagali",
    gps_code: "ENDI",
    iata_code: "DLD",
    coordinates: "8.518349647521973, 60.417301177978516",
  },
  {
    ident: "ENDU",
    type: "medium_airport",
    name: "Bardufoss Airport",
    elevation_ft: "252",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-19",
    municipality: "M����lselv",
    gps_code: "ENDU",
    iata_code: "BDU",
    coordinates: "18.540399551392, 69.055801391602",
  },
  {
    ident: "ENEV",
    type: "medium_airport",
    name: "Harstad/Narvik Airport, Evenes",
    elevation_ft: "84",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Evenes",
    gps_code: "ENEV",
    iata_code: "EVE",
    coordinates: "16.678100585938, 68.491302490234",
  },
  {
    ident: "ENFB",
    type: "closed",
    name: "Oslo, Fornebu Airport",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-03",
    municipality: "Oslo",
    gps_code: "ENFB",
    iata_code: "FBU",
    coordinates: "10.617199897766113, 59.89580154418945",
  },
  {
    ident: "ENFG",
    type: "medium_airport",
    name: "Leirin Airport",
    elevation_ft: "2697",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-05",
    gps_code: "ENFG",
    iata_code: "VDB",
    coordinates: "9.2880601882935, 61.015598297119",
  },
  {
    ident: "ENFL",
    type: "medium_airport",
    name: "Flor���� Airport",
    elevation_ft: "37",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-14",
    municipality: "Flor����",
    gps_code: "ENFL",
    iata_code: "FRO",
    coordinates: "5.0247201919556, 61.583599090576",
  },
  {
    ident: "ENGM",
    type: "large_airport",
    name: "Oslo Gardermoen Airport",
    elevation_ft: "681",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-02",
    municipality: "Oslo",
    gps_code: "ENGM",
    iata_code: "OSL",
    coordinates: "11.100399971008, 60.193901062012",
  },
  {
    ident: "ENHA",
    type: "small_airport",
    name: "Stafsberg Airport",
    elevation_ft: "713",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-04",
    municipality: "Hamar",
    gps_code: "ENHA",
    iata_code: "HMR",
    coordinates: "11.067999839782715, 60.81809997558594",
  },
  {
    ident: "ENHD",
    type: "medium_airport",
    name: "Haugesund Airport",
    elevation_ft: "86",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-11",
    municipality: "Karm����y",
    gps_code: "ENHD",
    iata_code: "HAU",
    coordinates: "5.2083601951599, 59.34529876709",
  },
  {
    ident: "ENHF",
    type: "medium_airport",
    name: "Hammerfest Airport",
    elevation_ft: "266",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Hammerfest",
    gps_code: "ENHF",
    iata_code: "HFT",
    coordinates: "23.668600082397, 70.679702758789",
  },
  {
    ident: "ENHK",
    type: "small_airport",
    name: "Hasvik Airport",
    elevation_ft: "21",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Hasvik",
    gps_code: "ENHK",
    iata_code: "HAA",
    coordinates: "22.139699935913, 70.486701965332",
  },
  {
    ident: "ENHV",
    type: "medium_airport",
    name: "Valan Airport",
    elevation_ft: "44",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Honningsv����g",
    gps_code: "ENHV",
    iata_code: "HVG",
    coordinates: "25.983600616455, 71.009696960449",
  },
  {
    ident: "ENKA",
    type: "small_airport",
    name: "Kautokeino Air Base",
    elevation_ft: "1165",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    gps_code: "ENKA",
    iata_code: "QKX",
    coordinates: "23.034000396728516, 69.04029846191406",
  },
  {
    ident: "ENKB",
    type: "medium_airport",
    name: "Kristiansund Airport (Kvernberget)",
    elevation_ft: "204",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-15",
    municipality: "Kvernberget",
    gps_code: "ENKB",
    iata_code: "KSU",
    coordinates: "7.824520111084, 63.111801147461",
  },
  {
    ident: "ENKL",
    type: "small_airport",
    name: "Gol Airport",
    elevation_ft: "2770",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-06",
    municipality: "Klanten flyplass",
    gps_code: "ENKL",
    iata_code: "GLL",
    coordinates: "9.048672, 60.791066",
  },
  {
    ident: "ENKR",
    type: "medium_airport",
    name: "Kirkenes Airport (H����ybuktmoen)",
    elevation_ft: "283",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Kirkenes",
    gps_code: "ENKR",
    iata_code: "KKN",
    coordinates: "29.891300201416, 69.725799560547",
  },
  {
    ident: "ENLI",
    type: "small_airport",
    name: "Lista Airport",
    elevation_ft: "29",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-10",
    municipality: "Farsund",
    gps_code: "ENLI",
    iata_code: "FAN",
    coordinates: "6.626049995422363, 58.0994987487793",
  },
  {
    ident: "ENLK",
    type: "medium_airport",
    name: "Leknes Airport",
    elevation_ft: "78",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Leknes",
    gps_code: "ENLK",
    iata_code: "LKN",
    coordinates: "13.609399795532, 68.152496337891",
  },
  {
    ident: "ENMH",
    type: "medium_airport",
    name: "Mehamn Airport",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Mehamn",
    gps_code: "ENMH",
    iata_code: "MEH",
    coordinates: "27.826700210571, 71.02970123291",
  },
  {
    ident: "ENML",
    type: "medium_airport",
    name: "Molde Airport",
    elevation_ft: "10",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-15",
    municipality: "����r����",
    gps_code: "ENML",
    iata_code: "MOL",
    coordinates: "7.2624998092651, 62.744701385498",
  },
  {
    ident: "ENMS",
    type: "medium_airport",
    name: "Mosj����en Airport (Kj����rstad)",
    elevation_ft: "237",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    gps_code: "ENMS",
    iata_code: "MJF",
    coordinates: "13.214900016785, 65.783996582031",
  },
  {
    ident: "ENNA",
    type: "medium_airport",
    name: "Banak Airport",
    elevation_ft: "25",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Lakselv",
    gps_code: "ENNA",
    iata_code: "LKL",
    coordinates: "24.973499298096, 70.068801879883",
  },
  {
    ident: "ENNK",
    type: "medium_airport",
    name: "Narvik Framnes Airport",
    elevation_ft: "95",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Narvik",
    gps_code: "ENNK",
    iata_code: "NVK",
    coordinates: "17.386699676514, 68.436897277832",
  },
  {
    ident: "ENNM",
    type: "small_airport",
    name: "Namsos H����knes����ra Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-17",
    municipality: "Namsos",
    gps_code: "ENNM",
    iata_code: "OSY",
    coordinates: "11.57859992981, 64.472198486328",
  },
  {
    ident: "ENNO",
    type: "medium_airport",
    name: "Notodden Airport",
    elevation_ft: "63",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-08",
    municipality: "Notodden",
    gps_code: "ENNO",
    iata_code: "NTB",
    coordinates: "9.21222, 59.565701",
  },
  {
    ident: "ENOL",
    type: "medium_airport",
    name: "����rland Airport",
    elevation_ft: "28",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-16",
    municipality: "����rland",
    gps_code: "ENOL",
    iata_code: "OLA",
    coordinates: "9.604000091552734, 63.69889831542969",
  },
  {
    ident: "ENOV",
    type: "medium_airport",
    name: "����rsta-Volda Airport, Hovden",
    elevation_ft: "243",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-15",
    municipality: "����rsta",
    gps_code: "ENOV",
    iata_code: "HOV",
    coordinates: "6.0741000175476, 62.180000305176",
  },
  {
    ident: "ENRA",
    type: "medium_airport",
    name: "Mo i Rana Airport, R����ssvoll",
    elevation_ft: "229",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Mo i Rana",
    gps_code: "ENRA",
    iata_code: "MQN",
    coordinates: "14.301400184631, 66.363899230957",
  },
  {
    ident: "ENRM",
    type: "medium_airport",
    name: "R����rvik Airport, Ryum",
    elevation_ft: "14",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-17",
    municipality: "R����rvik",
    gps_code: "ENRM",
    iata_code: "RVK",
    coordinates: "11.14610004425, 64.838302612305",
  },
  {
    ident: "ENRO",
    type: "medium_airport",
    name: "R����ros Airport",
    elevation_ft: "2054",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-16",
    municipality: "R����ros",
    gps_code: "ENRO",
    iata_code: "RRS",
    coordinates: "11.342300415039, 62.578399658203",
  },
  {
    ident: "ENRS",
    type: "small_airport",
    name: "R����st Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    gps_code: "ENRS",
    iata_code: "RET",
    coordinates: "12.103300094604, 67.527801513672",
  },
  {
    ident: "ENRY",
    type: "medium_airport",
    name: "Moss Airport, Rygge",
    elevation_ft: "174",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-01",
    municipality: "Oslo",
    gps_code: "ENRY",
    iata_code: "RYG",
    coordinates: "10.785439, 59.378817",
  },
  {
    ident: "ENSB",
    type: "medium_airport",
    name: "Svalbard Airport, Longyear",
    elevation_ft: "88",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-21",
    municipality: "Longyearbyen",
    gps_code: "ENSB",
    iata_code: "LYR",
    coordinates: "15.465600013733, 78.246101379395",
  },
  {
    ident: "ENSD",
    type: "small_airport",
    name: "Sandane Airport (Anda)",
    elevation_ft: "196",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-14",
    municipality: "Sandane",
    gps_code: "ENSD",
    iata_code: "SDN",
    coordinates: "6.1058301925659, 61.830001831055",
  },
  {
    ident: "ENSG",
    type: "small_airport",
    name: "Sogndal Airport",
    elevation_ft: "1633",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-14",
    municipality: "Sogndal",
    gps_code: "ENSG",
    iata_code: "SOG",
    coordinates: "7.13778, 61.156101",
  },
  {
    ident: "ENSH",
    type: "medium_airport",
    name: "Svolv����r Helle Airport",
    elevation_ft: "27",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Svolv����r",
    gps_code: "ENSH",
    iata_code: "SVJ",
    coordinates: "14.669199943542, 68.243301391602",
  },
  {
    ident: "ENSK",
    type: "medium_airport",
    name: "Stokmarknes Skagen Airport",
    elevation_ft: "11",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Hadsel",
    gps_code: "ENSK",
    iata_code: "SKN",
    coordinates: "15.033416748047, 68.578826904297",
  },
  {
    ident: "ENSN",
    type: "medium_airport",
    name: "Skien Airport",
    elevation_ft: "463",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-08",
    municipality: "Geiteryggen",
    gps_code: "ENSN",
    iata_code: "SKE",
    coordinates: "9.566940307617188, 59.185001373291016",
  },
  {
    ident: "ENSO",
    type: "medium_airport",
    name: "Stord Airport",
    elevation_ft: "160",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-12",
    municipality: "Leirvik",
    gps_code: "ENSO",
    iata_code: "SRP",
    coordinates: "5.340849876403809, 59.791900634765625",
  },
  {
    ident: "ENSR",
    type: "medium_airport",
    name: "S����rkjosen Airport",
    elevation_ft: "16",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-19",
    municipality: "S����rkjosen",
    gps_code: "ENSR",
    iata_code: "SOJ",
    coordinates: "20.959400177002, 69.786796569824",
  },
  {
    ident: "ENSS",
    type: "medium_airport",
    name: "Vard���� Airport, Svartnes",
    elevation_ft: "42",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Vard����",
    gps_code: "ENSS",
    iata_code: "VAW",
    coordinates: "31.044900894165, 70.355400085449",
  },
  {
    ident: "ENST",
    type: "medium_airport",
    name: "Sandnessj����en Airport (Stokka)",
    elevation_ft: "56",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "Alstahaug",
    gps_code: "ENST",
    iata_code: "SSJ",
    coordinates: "12.468899726868, 65.956802368164",
  },
  {
    ident: "ENTC",
    type: "large_airport",
    name: "Troms���� Airport",
    elevation_ft: "31",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-19",
    municipality: "Troms����",
    gps_code: "ENTC",
    iata_code: "TOS",
    coordinates: "18.918899536132812, 69.68329620361328",
  },
  {
    ident: "ENTO",
    type: "medium_airport",
    name: "Sandefjord Airport, Torp",
    elevation_ft: "286",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-07",
    municipality: "Torp",
    gps_code: "ENTO",
    iata_code: "TRF",
    coordinates: "10.258600235, 59.1866989136",
  },
  {
    ident: "ENVA",
    type: "large_airport",
    name: "Trondheim Airport V����rnes",
    elevation_ft: "56",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-17",
    municipality: "Trondheim",
    gps_code: "ENVA",
    iata_code: "TRD",
    coordinates: "10.9239998, 63.4578018",
  },
  {
    ident: "ENVD",
    type: "medium_airport",
    name: "Vads���� Airport",
    elevation_ft: "127",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-20",
    municipality: "Vads����",
    gps_code: "ENVD",
    iata_code: "VDS",
    coordinates: "29.844699859619, 70.065299987793",
  },
  {
    ident: "ENVR",
    type: "heliport",
    name: "V����r����y Heliport",
    elevation_ft: "12",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-18",
    municipality: "V����r����y",
    gps_code: "ENVR",
    iata_code: "VRY",
    coordinates: "12.727257, 67.654555",
  },
  {
    ident: "ENZV",
    type: "large_airport",
    name: "Stavanger Airport Sola",
    elevation_ft: "29",
    continent: "EU",
    iso_country: "NO",
    iso_region: "NO-11",
    municipality: "Stavanger",
    gps_code: "ENZV",
    iata_code: "SVG",
    coordinates: "5.6377801895, 58.876701354",
  },
  {
    ident: "EPBP",
    type: "medium_airport",
    name: "Bia����a Podlaska Airfield",
    elevation_ft: "485",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-LU",
    municipality: "Bia����a Podlaska",
    gps_code: "EPBP",
    iata_code: "BXP",
    coordinates: "23.1325278, 52.00078",
  },
  {
    ident: "EPBY",
    type: "medium_airport",
    name: "Bydgoszcz Ignacy Jan Paderewski Airport",
    elevation_ft: "235",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-KP",
    municipality: "Bydgoszcz",
    gps_code: "EPBY",
    iata_code: "BZG",
    coordinates: "17.9776992798, 53.096801757799994",
  },
  {
    ident: "EPCH",
    type: "small_airport",
    name: "Cz����stochowa-Rudniki",
    elevation_ft: "860",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-SL",
    municipality: "Cz����stochowa",
    gps_code: "EPRU",
    iata_code: "CZW",
    coordinates: "19.2047, 50.884998",
  },
  {
    ident: "EPGD",
    type: "large_airport",
    name: "Gda����sk Lech Wa��������sa Airport",
    elevation_ft: "489",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-PM",
    municipality: "Gda����sk",
    gps_code: "EPGD",
    iata_code: "GDN",
    coordinates: "18.46619987487793, 54.377601623535156",
  },
  {
    ident: "EPKK",
    type: "large_airport",
    name: "Krak����w John Paul II International Airport",
    elevation_ft: "791",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-MA",
    municipality: "Krak����w",
    gps_code: "EPKK",
    iata_code: "KRK",
    coordinates: "19.7848, 50.077702",
  },
  {
    ident: "EPKO",
    type: "small_airport",
    name: "Koszalin Zegrze Pomorskie",
    elevation_ft: "249",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-ZP",
    municipality: "Zegrze Pomorskie",
    gps_code: "EPKZ",
    iata_code: "OSZ",
    coordinates: "16.2656, 54.0425",
  },
  {
    ident: "EPKT",
    type: "large_airport",
    name: "Katowice International Airport",
    elevation_ft: "995",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-SL",
    municipality: "Katowice",
    gps_code: "EPKT",
    iata_code: "KTW",
    coordinates: "19.08, 50.4743",
  },
  {
    ident: "EPKW",
    type: "small_airport",
    name: "Bielsko-Bialo Kaniow Airfield",
    elevation_ft: "1316",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-SL",
    municipality: "Czechowice-Dziedzice",
    gps_code: "EPKW",
    iata_code: "QEO",
    coordinates: "19.021999359099997, 49.9399986267",
  },
  {
    ident: "EPLL",
    type: "medium_airport",
    name: "��������d���� W����adys����aw Reymont Airport",
    elevation_ft: "604",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-LD",
    municipality: "��������d����",
    gps_code: "EPLL",
    iata_code: "LCJ",
    coordinates: "19.3980998993, 51.721900939899996",
  },
  {
    ident: "EPMO",
    type: "large_airport",
    name: "Modlin Airport",
    elevation_ft: "341",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-MZ",
    municipality: "Warsaw",
    gps_code: "EPMO",
    iata_code: "WMI",
    coordinates: "20.6518, 52.451099",
  },
  {
    ident: "EPOK",
    type: "medium_airport",
    name: "Oksywie Military Air Base",
    elevation_ft: "144",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-PM",
    municipality: "Gdynia",
    gps_code: "EPOK",
    iata_code: "QYD",
    coordinates: "18.51720047, 54.57970047",
  },
  {
    ident: "EPPO",
    type: "large_airport",
    name: "Pozna����-����awica Airport",
    elevation_ft: "308",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-WP",
    municipality: "Pozna����",
    gps_code: "EPPO",
    iata_code: "POZ",
    coordinates: "16.8262996674, 52.421001434299995",
  },
  {
    ident: "EPRA",
    type: "medium_airport",
    name: "Radom Airport",
    elevation_ft: "610",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-MZ",
    municipality: "Radom",
    gps_code: "EPRA",
    iata_code: "RDO",
    coordinates: "21.213300705, 51.3891983032",
  },
  {
    ident: "EPRZ",
    type: "medium_airport",
    name: "Rzesz����w-Jasionka Airport",
    elevation_ft: "693",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-PK",
    municipality: "Rzesz����w",
    gps_code: "EPRZ",
    iata_code: "RZE",
    coordinates: "22.018999, 50.110001",
  },
  {
    ident: "EPSC",
    type: "medium_airport",
    name: 'Szczecin-Goleni����w "Solidarno��������" Airport',
    elevation_ft: "154",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-ZP",
    municipality: "Goleniow",
    gps_code: "EPSC",
    iata_code: "SZZ",
    coordinates: "14.902199745199999, 53.584701538100006",
  },
  {
    ident: "EPSK",
    type: "closed",
    name: "Redzikowo Air Base",
    elevation_ft: "217",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-PM",
    gps_code: "EPSK",
    iata_code: "OSP",
    coordinates: "17.107500076293945, 54.47890090942383",
  },
  {
    ident: "EPSY",
    type: "medium_airport",
    name: "Olsztyn-Mazury Airport",
    elevation_ft: "463",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-WN",
    municipality: "Olsztyn",
    gps_code: "EPSY",
    iata_code: "SZY",
    coordinates: "20.9377, 53.481899",
  },
  {
    ident: "EPWA",
    type: "large_airport",
    name: "Warsaw Chopin Airport",
    elevation_ft: "362",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-MZ",
    municipality: "Warsaw",
    gps_code: "EPWA",
    iata_code: "WAW",
    coordinates: "20.967100143399996, 52.1656990051",
  },
  {
    ident: "EPWR",
    type: "large_airport",
    name: "Copernicus Wroc����aw Airport",
    elevation_ft: "404",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-DS",
    municipality: "Wroc����aw",
    gps_code: "EPWR",
    iata_code: "WRO",
    coordinates: "16.885799408, 51.1026992798",
  },
  {
    ident: "EPZG",
    type: "medium_airport",
    name: "Zielona G����ra-Babimost Airport",
    elevation_ft: "194",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-LB",
    municipality: "Babimost",
    gps_code: "EPZG",
    iata_code: "IEG",
    coordinates: "15.7986001968, 52.138500213600004",
  },
  {
    ident: "ERT",
    type: "small_airport",
    name: "Erdenet Airport",
    elevation_ft: "4200",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-035",
    municipality: "Erdenet",
    iata_code: "ERT",
    coordinates: "104.150555556, 48.9830555556",
  },
  {
    ident: "ESDF",
    type: "medium_airport",
    name: "Ronneby Airport",
    elevation_ft: "191",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-K",
    gps_code: "ESDF",
    iata_code: "RNB",
    coordinates: "15.265000343323, 56.266700744629",
  },
  {
    ident: "ESGG",
    type: "large_airport",
    name: "Gothenburg-Landvetter Airport",
    elevation_ft: "506",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Q",
    municipality: "Gothenburg",
    gps_code: "ESGG",
    iata_code: "GOT",
    coordinates: "12.279800415039, 57.662799835205",
  },
  {
    ident: "ESGJ",
    type: "medium_airport",
    name: "J����nk����ping Airport",
    elevation_ft: "741",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-F",
    municipality: "J����nk����ping",
    gps_code: "ESGJ",
    iata_code: "JKG",
    coordinates: "14.068699836730957, 57.757598876953125",
  },
  {
    ident: "ESGL",
    type: "small_airport",
    name: "Lidk����ping-Hovby Airport",
    elevation_ft: "200",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Q",
    municipality: "Lidk����ping",
    gps_code: "ESGL",
    iata_code: "LDK",
    coordinates: "13.1744, 58.4655",
  },
  {
    ident: "ESGP",
    type: "medium_airport",
    name: "Gothenburg City Airport",
    elevation_ft: "59",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Q",
    municipality: "Gothenburg",
    gps_code: "ESGP",
    iata_code: "GSE",
    coordinates: "11.8704, 57.7747",
  },
  {
    ident: "ESGR",
    type: "medium_airport",
    name: "Sk����vde Airport",
    elevation_ft: "324",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Q",
    municipality: "Sk����vde",
    gps_code: "ESGR",
    iata_code: "KVB",
    coordinates: "13.972700119018555, 58.45640182495117",
  },
  {
    ident: "ESGT",
    type: "medium_airport",
    name: "Trollh����ttan-V����nersborg Airport",
    elevation_ft: "137",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Q",
    municipality: "Trollh����ttan",
    gps_code: "ESGT",
    iata_code: "THN",
    coordinates: "12.345000267028809, 58.31809997558594",
  },
  {
    ident: "ESKK",
    type: "medium_airport",
    name: "Karlskoga Airport",
    elevation_ft: "400",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-T",
    gps_code: "ESKK",
    iata_code: "KSK",
    coordinates: "14.49590015411377, 59.34590148925781",
  },
  {
    ident: "ESKM",
    type: "medium_airport",
    name: "Mora Airport",
    elevation_ft: "634",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-W",
    municipality: "Mora",
    gps_code: "ESKM",
    iata_code: "MXX",
    coordinates: "14.5114, 60.957901",
  },
  {
    ident: "ESKN",
    type: "medium_airport",
    name: "Stockholm Skavsta Airport",
    elevation_ft: "140",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-D",
    municipality: "Stockholm / Nyk����ping",
    gps_code: "ESKN",
    iata_code: "NYO",
    coordinates: "16.912200927734375, 58.78860092163086",
  },
  {
    ident: "ESMK",
    type: "medium_airport",
    name: "Kristianstad Airport",
    elevation_ft: "76",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-M",
    municipality: "Kristianstad",
    gps_code: "ESMK",
    iata_code: "KID",
    coordinates: "14.08549976348877, 55.92169952392578",
  },
  {
    ident: "ESMO",
    type: "small_airport",
    name: "Oskarshamn Airport",
    elevation_ft: "96",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-H",
    gps_code: "ESMO",
    iata_code: "OSK",
    coordinates: "16.497999, 57.350498",
  },
  {
    ident: "ESMQ",
    type: "medium_airport",
    name: "Kalmar Airport",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-H",
    gps_code: "ESMQ",
    iata_code: "KLR",
    coordinates: "16.287599563598633, 56.68550109863281",
  },
  {
    ident: "ESMS",
    type: "large_airport",
    name: "Malm���� Sturup Airport",
    elevation_ft: "236",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-M",
    municipality: "Malm����",
    gps_code: "ESMS",
    iata_code: "MMX",
    coordinates: "13.376197814900001, 55.536305364",
  },
  {
    ident: "ESMT",
    type: "medium_airport",
    name: "Halmstad Airport",
    elevation_ft: "101",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-N",
    municipality: "Halmstad",
    gps_code: "ESMT",
    iata_code: "HAD",
    coordinates: "12.820199966430664, 56.69110107421875",
  },
  {
    ident: "ESMX",
    type: "medium_airport",
    name: "V����xj���� Kronoberg Airport",
    elevation_ft: "610",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-G",
    municipality: "V����xj����",
    gps_code: "ESMX",
    iata_code: "VXO",
    coordinates: "14.727999687194824, 56.929100036621094",
  },
  {
    ident: "ESND",
    type: "medium_airport",
    name: "Sveg Airport",
    elevation_ft: "1178",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Z",
    gps_code: "ESND",
    iata_code: "EVG",
    coordinates: "14.422900199890137, 62.04779815673828",
  },
  {
    ident: "ESNG",
    type: "medium_airport",
    name: "G����llivare Airport",
    elevation_ft: "1027",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-BD",
    municipality: "G����llivare",
    gps_code: "ESNG",
    iata_code: "GEV",
    coordinates: "20.814599990844727, 67.13240051269531",
  },
  {
    ident: "ESNH",
    type: "closed",
    name: "Hudiksvall Airport",
    elevation_ft: "95",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-X",
    municipality: "Hudiksvall",
    gps_code: "ESNH",
    iata_code: "HUV",
    coordinates: "17.0806999207, 61.7681007385",
  },
  {
    ident: "ESNK",
    type: "medium_airport",
    name: "Kramfors Sollefte���� Airport",
    elevation_ft: "34",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Y",
    municipality: "Kramfors / Sollefte����",
    gps_code: "ESNK",
    iata_code: "KRF",
    coordinates: "17.76889991760254, 63.04859924316406",
  },
  {
    ident: "ESNL",
    type: "medium_airport",
    name: "Lycksele Airport",
    elevation_ft: "705",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-AC",
    gps_code: "ESNL",
    iata_code: "LYC",
    coordinates: "18.71619987487793, 64.54830169677734",
  },
  {
    ident: "ESNN",
    type: "medium_airport",
    name: "Sundsvall-H����rn����sand Airport",
    elevation_ft: "16",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Y",
    municipality: "Sundsvall/ H����rn����sand",
    gps_code: "ESNN",
    iata_code: "SDL",
    coordinates: "17.443899154663086, 62.528099060058594",
  },
  {
    ident: "ESNO",
    type: "medium_airport",
    name: "����rnsk����ldsvik Airport",
    elevation_ft: "354",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Y",
    municipality: "����rnsk����ldsvik",
    gps_code: "ESNO",
    iata_code: "OER",
    coordinates: "18.989999771118164, 63.40829849243164",
  },
  {
    ident: "ESNQ",
    type: "medium_airport",
    name: "Kiruna Airport",
    elevation_ft: "1508",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-BD",
    municipality: "Kiruna",
    gps_code: "ESNQ",
    iata_code: "KRN",
    coordinates: "20.336799621582, 67.821998596191",
  },
  {
    ident: "ESNS",
    type: "medium_airport",
    name: "Skellefte���� Airport",
    elevation_ft: "157",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-AC",
    municipality: "Skellefte����",
    gps_code: "ESNS",
    iata_code: "SFT",
    coordinates: "21.076900482177734, 64.62480163574219",
  },
  {
    ident: "ESNU",
    type: "medium_airport",
    name: "Ume���� Airport",
    elevation_ft: "24",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-AC",
    municipality: "Ume����",
    gps_code: "ESNU",
    iata_code: "UME",
    coordinates: "20.282800674438, 63.791801452637",
  },
  {
    ident: "ESNV",
    type: "medium_airport",
    name: "Vilhelmina Airport",
    elevation_ft: "1140",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-AC",
    gps_code: "ESNV",
    iata_code: "VHM",
    coordinates: "16.833599090576172, 64.5791015625",
  },
  {
    ident: "ESNX",
    type: "medium_airport",
    name: "Arvidsjaur Airport",
    elevation_ft: "1245",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-BD",
    municipality: "Arvidsjaur",
    gps_code: "ESNX",
    iata_code: "AJR",
    coordinates: "19.28190040588379, 65.59030151367188",
  },
  {
    ident: "ESNY",
    type: "medium_airport",
    name: "S����derhamn Airport",
    elevation_ft: "88",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-X",
    municipality: "S����derhamn",
    gps_code: "ESNY",
    iata_code: "SOO",
    coordinates: "17.09910011291504, 61.26150131225586",
  },
  {
    ident: "ESNZ",
    type: "medium_airport",
    name: "����re ����stersund Airport",
    elevation_ft: "1233",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-Z",
    municipality: "����stersund",
    gps_code: "ESNZ",
    iata_code: "OSD",
    coordinates: "14.50030040741, 63.194400787354",
  },
  {
    ident: "ESOE",
    type: "medium_airport",
    name: "����rebro Airport",
    elevation_ft: "188",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-T",
    municipality: "����rebro",
    gps_code: "ESOE",
    iata_code: "ORB",
    coordinates: "15.038000106811523, 59.22370147705078",
  },
  {
    ident: "ESOH",
    type: "medium_airport",
    name: "Hagfors Airport",
    elevation_ft: "474",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-S",
    gps_code: "ESOH",
    iata_code: "HFS",
    coordinates: "13.578900337219238, 60.02009963989258",
  },
  {
    ident: "ESOK",
    type: "medium_airport",
    name: "Karlstad Airport",
    elevation_ft: "352",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-S",
    municipality: "Karlstad",
    gps_code: "ESOK",
    iata_code: "KSD",
    coordinates: "13.337400436400001, 59.444698333699996",
  },
  {
    ident: "ESOW",
    type: "medium_airport",
    name: "Stockholm V����ster����s Airport",
    elevation_ft: "21",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-U",
    municipality: "Stockholm / V����ster����s",
    gps_code: "ESOW",
    iata_code: "VST",
    coordinates: "16.63360023498535, 59.58940124511719",
  },
  {
    ident: "ESP",
    type: "closed",
    name: "Birchwood-Pocono Airport",
    elevation_ft: "965",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "East Stroudsburg",
    iata_code: "ESP",
    coordinates: "-75.2521, 41.0643",
  },
  {
    ident: "ESPA",
    type: "large_airport",
    name: "Lule���� Airport",
    elevation_ft: "65",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-BD",
    municipality: "Lule����",
    gps_code: "ESPA",
    iata_code: "LLA",
    coordinates: "22.121999740601, 65.543800354004",
  },
  {
    ident: "ESSA",
    type: "large_airport",
    name: "Stockholm-Arlanda Airport",
    elevation_ft: "137",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-AB",
    municipality: "Stockholm",
    gps_code: "ESSA",
    iata_code: "ARN",
    coordinates: "17.918600082397, 59.651901245117",
  },
  {
    ident: "ESSB",
    type: "medium_airport",
    name: "Stockholm-Bromma Airport",
    elevation_ft: "47",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-AB",
    municipality: "Stockholm",
    gps_code: "ESSB",
    iata_code: "BMA",
    coordinates: "17.941699981689453, 59.354400634765625",
  },
  {
    ident: "ESSD",
    type: "medium_airport",
    name: "Borlange Airport",
    elevation_ft: "503",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-W",
    gps_code: "ESSD",
    iata_code: "BLE",
    coordinates: "15.515199661254883, 60.422000885009766",
  },
  {
    ident: "ESSF",
    type: "small_airport",
    name: "Hultsfred Airport",
    elevation_ft: "366",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-H",
    gps_code: "ESSF",
    iata_code: "HLF",
    coordinates: "15.8233, 57.525799",
  },
  {
    ident: "ESSK",
    type: "medium_airport",
    name: "G����vle Sandviken Airport",
    elevation_ft: "224",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-X",
    municipality: "G����vle / Sandviken",
    gps_code: "ESSK",
    iata_code: "GVX",
    coordinates: "16.951400756835938, 60.593299865722656",
  },
  {
    ident: "ESSL",
    type: "medium_airport",
    name: "Link����ping City Airport",
    elevation_ft: "172",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-E",
    municipality: "Link����ping",
    gps_code: "ESSL",
    iata_code: "LPI",
    coordinates: "15.680500030500001, 58.4062004089",
  },
  {
    ident: "ESSP",
    type: "medium_airport",
    name: "Norrk����ping Airport",
    elevation_ft: "32",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-E",
    municipality: "Norrk����ping",
    gps_code: "ESSP",
    iata_code: "NRK",
    coordinates: "16.250600814819336, 58.586299896240234",
  },
  {
    ident: "ESST",
    type: "medium_airport",
    name: "Torsby Airport",
    elevation_ft: "393",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-S",
    gps_code: "ESST",
    iata_code: "TYF",
    coordinates: "12.991299629199998, 60.1576004028",
  },
  {
    ident: "ESSU",
    type: "medium_airport",
    name: "Eskilstuna Airport",
    elevation_ft: "139",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-D",
    municipality: "Eskilstuna",
    gps_code: "ESSU",
    iata_code: "EKT",
    coordinates: "16.70840072631836, 59.35110092163086",
  },
  {
    ident: "ESSV",
    type: "medium_airport",
    name: "Visby Airport",
    elevation_ft: "164",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-I",
    municipality: "Visby",
    gps_code: "ESSV",
    iata_code: "VBY",
    coordinates: "18.346200942993, 57.662799835205",
  },
  {
    ident: "ESSW",
    type: "small_airport",
    name: "V����stervik Airport",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-H",
    municipality: "V����stervik",
    gps_code: "ESSW",
    iata_code: "VVK",
    coordinates: "16.52359962463379, 57.779998779296875",
  },
  {
    ident: "ESTA",
    type: "medium_airport",
    name: "����ngelholm-Helsingborg Airport",
    elevation_ft: "68",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-M",
    municipality: "����ngelholm",
    gps_code: "ESTA",
    iata_code: "AGH",
    coordinates: "12.847100257873535, 56.29610061645508",
  },
  {
    ident: "ESUD",
    type: "medium_airport",
    name: "Storuman Airport",
    elevation_ft: "915",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-AC",
    gps_code: "ESUD",
    iata_code: "SQO",
    coordinates: "17.69659996032715, 64.96089935302734",
  },
  {
    ident: "ESUE",
    type: "small_airport",
    name: "Idre Airport",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-W",
    municipality: "Idre",
    gps_code: "ESUE",
    iata_code: "IDB",
    coordinates: "12.689399719238281, 61.86970138549805",
  },
  {
    ident: "ESUP",
    type: "medium_airport",
    name: "Pajala Airport",
    elevation_ft: "542",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-BD",
    gps_code: "ESUP",
    iata_code: "PJA",
    coordinates: "23.068899154663086, 67.24559783935547",
  },
  {
    ident: "ESUT",
    type: "medium_airport",
    name: "Hemavan Airport",
    elevation_ft: "1503",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-AC",
    municipality: "Hemavan",
    gps_code: "ESUT",
    iata_code: "HMV",
    coordinates: "15.0828, 65.806099",
  },
  {
    ident: "ET-GLC",
    type: "small_airport",
    name: "Geladi Airport",
    elevation_ft: "1383",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SO",
    municipality: "Geladi",
    gps_code: "HAGL",
    iata_code: "GLC",
    coordinates: "46.4213981628, 6.984439849849999",
  },
  {
    ident: "ET-SHC",
    type: "small_airport",
    name: "Shire Inda Selassie Airport",
    elevation_ft: "6207",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-TI",
    municipality: "Shire Indasilase",
    iata_code: "SHC",
    coordinates: "38.272499084472656, 14.078100204467773",
  },
  {
    ident: "ETAD",
    type: "medium_airport",
    name: "Spangdahlem Air Base",
    elevation_ft: "1197",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-RP",
    municipality: "Trier",
    gps_code: "ETAD",
    iata_code: "SPM",
    coordinates: "6.69250011444, 49.9726982117",
  },
  {
    ident: "ETAR",
    type: "large_airport",
    name: "Ramstein Air Base",
    elevation_ft: "776",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-RP",
    municipality: "Ramstein",
    gps_code: "ETAR",
    iata_code: "RMS",
    coordinates: "7.600279808044434, 49.4369010925293",
  },
  {
    ident: "ETAS",
    type: "closed",
    name: "Sembach Air Base",
    elevation_ft: "1050",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-RP",
    municipality: "Sembach",
    gps_code: "ETAS",
    iata_code: "SEX",
    coordinates: "7.866, 49.507",
  },
  {
    ident: "ETHF",
    type: "medium_airport",
    name: "Fritzlar Army Airfield",
    elevation_ft: "1345",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-HE",
    municipality: "Fritzlar",
    gps_code: "ETHF",
    iata_code: "FRZ",
    coordinates: "9.286, 51.1146",
  },
  {
    ident: "ETIK",
    type: "heliport",
    name: "Illesheim Air Base",
    elevation_ft: "1079",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Storck Barracks",
    gps_code: "ETIK",
    iata_code: "ILH",
    coordinates: "10.3880996704, 49.473899841299996",
  },
  {
    ident: "ETIN",
    type: "small_airport",
    name: "Flugplatz Kitzingen",
    elevation_ft: "689",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Kitzingen",
    gps_code: "EDGY",
    iata_code: "KZG",
    coordinates: "10.2006, 49.743099",
  },
  {
    ident: "ETMN",
    type: "medium_airport",
    name: "Nordholz Naval Airbase",
    elevation_ft: "74",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NI",
    municipality: "Cuxhaven",
    gps_code: "ETMN",
    iata_code: "FCN",
    coordinates: "8.658499717709999, 53.7677001953",
  },
  {
    ident: "ETNG",
    type: "medium_airport",
    name: "Geilenkirchen Air Base",
    elevation_ft: "296",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    gps_code: "ETNG",
    iata_code: "GKE",
    coordinates: "6.04242, 50.9608",
  },
  {
    ident: "ETNL",
    type: "medium_airport",
    name: "Rostock-Laage Airport",
    elevation_ft: "138",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-MV",
    municipality: "Rostock",
    gps_code: "ETNL",
    iata_code: "RLG",
    coordinates: "12.278300285299999, 53.9182014465",
  },
  {
    ident: "ETNS",
    type: "medium_airport",
    name: "Schleswig Air Base",
    elevation_ft: "70",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-SH",
    municipality: "Jagel",
    gps_code: "ETNS",
    iata_code: "WBG",
    coordinates: "9.51633, 54.459301",
  },
  {
    ident: "ETOU",
    type: "medium_airport",
    name: "Wiesbaden Army Airfield",
    elevation_ft: "461",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-HE",
    municipality: "Wiesbaden",
    gps_code: "ETOU",
    iata_code: "WIE",
    coordinates: "8.325400352478027, 50.049800872802734",
  },
  {
    ident: "ETSF",
    type: "closed",
    name: "F����rstenfeldbruck Air Base",
    elevation_ft: "1703",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "F����rstenfeldbruck",
    gps_code: "ETSF",
    iata_code: "FEL",
    coordinates: "11.266944, 48.205555",
  },
  {
    ident: "ETSI",
    type: "medium_airport",
    name: "Ingolstadt Manching Airport",
    elevation_ft: "1202",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Manching",
    gps_code: "ETSI",
    iata_code: "IGS",
    coordinates: "11.534000396728516, 48.7156982421875",
  },
  {
    ident: "ETUO",
    type: "closed",
    name: "G����tersloh Air Base",
    elevation_ft: "236",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "G����tersloh",
    gps_code: "ETUO",
    iata_code: "GUT",
    coordinates: "8.30633, 51.922798",
  },
  {
    ident: "EVDA",
    type: "medium_airport",
    name: "Daugavpils Intrenational Airport",
    elevation_ft: "398",
    continent: "EU",
    iso_country: "LV",
    iso_region: "LV-DA",
    municipality: "Daugavpils",
    gps_code: "EVDA",
    iata_code: "DGP",
    coordinates: "26.6650009155, 55.944721221900004",
  },
  {
    ident: "EVLA",
    type: "medium_airport",
    name: "Liep����ja International Airport",
    elevation_ft: "16",
    continent: "EU",
    iso_country: "LV",
    iso_region: "LV-LE",
    municipality: "Liep����ja",
    gps_code: "EVLA",
    iata_code: "LPX",
    coordinates: "21.096900939941406, 56.51750183105469",
  },
  {
    ident: "EVRA",
    type: "large_airport",
    name: "Riga International Airport",
    elevation_ft: "36",
    continent: "EU",
    iso_country: "LV",
    iso_region: "LV-RIX",
    municipality: "Riga",
    gps_code: "EVRA",
    iata_code: "RIX",
    coordinates: "23.971099853515625, 56.92359924316406",
  },
  {
    ident: "EVVA",
    type: "medium_airport",
    name: "Ventspils International Airport",
    elevation_ft: "19",
    continent: "EU",
    iso_country: "LV",
    iso_region: "LV-VEN",
    municipality: "Ventspils",
    gps_code: "EVVA",
    iata_code: "VNT",
    coordinates: "21.5442008972, 57.35779953",
  },
  {
    ident: "EWY",
    type: "closed",
    name: "RAF Greenham Common",
    elevation_ft: "400",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-ENG",
    municipality: "Berkshire",
    gps_code: "EGVI",
    iata_code: "EWY",
    coordinates: "-1.281, 51.379",
  },
  {
    ident: "EXI",
    type: "seaplane_base",
    name: "Excursion Inlet Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Excursion Inlet",
    iata_code: "EXI",
    local_code: "EXI",
    coordinates: "-135.449005, 58.420502",
  },
  {
    ident: "EYKA",
    type: "medium_airport",
    name: "Kaunas International Airport",
    elevation_ft: "256",
    continent: "EU",
    iso_country: "LT",
    iso_region: "LT-KU",
    municipality: "Kaunas",
    gps_code: "EYKA",
    iata_code: "KUN",
    coordinates: "24.084800720214844, 54.96390151977539",
  },
  {
    ident: "EYPA",
    type: "medium_airport",
    name: "Palanga International Airport",
    elevation_ft: "33",
    continent: "EU",
    iso_country: "LT",
    iso_region: "LT-KL",
    municipality: "Palanga",
    gps_code: "EYPA",
    iata_code: "PLQ",
    coordinates: "21.093900680541992, 55.973201751708984",
  },
  {
    ident: "EYPP",
    type: "medium_airport",
    name: "Panev��������ys Air Base",
    elevation_ft: "197",
    continent: "EU",
    iso_country: "LT",
    iso_region: "LT-PN",
    municipality: "Panev��������ys",
    gps_code: "EYPP",
    iata_code: "PNV",
    coordinates: "24.460800170898438, 55.729400634765625",
  },
  {
    ident: "EYSA",
    type: "medium_airport",
    name: "����iauliai International Airport",
    elevation_ft: "443",
    continent: "EU",
    iso_country: "LT",
    iso_region: "LT-SA",
    municipality: "����iauliai",
    gps_code: "EYSA",
    iata_code: "SQQ",
    coordinates: "23.395000457763672, 55.89390182495117",
  },
  {
    ident: "EYVI",
    type: "large_airport",
    name: "Vilnius International Airport",
    elevation_ft: "648",
    continent: "EU",
    iso_country: "LT",
    iso_region: "LT-VL",
    municipality: "Vilnius",
    gps_code: "EYVI",
    iata_code: "VNO",
    coordinates: "25.285801, 54.634102",
  },
  {
    ident: "F23",
    type: "small_airport",
    name: "Ranger Municipal Airport",
    elevation_ft: "1470",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Ranger",
    gps_code: "F23",
    iata_code: "RGR",
    local_code: "F23",
    coordinates: "-98.682800293, 32.4525985718",
  },
  {
    ident: "FAAB",
    type: "medium_airport",
    name: "Alexander Bay Airport",
    elevation_ft: "98",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Alexander Bay",
    gps_code: "FAAB",
    iata_code: "ALJ",
    coordinates: "16.5333, -28.575001",
  },
  {
    ident: "FAAG",
    type: "medium_airport",
    name: "Aggeneys Airport",
    elevation_ft: "2648",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Aggeneys",
    gps_code: "FAAG",
    iata_code: "AGZ",
    coordinates: "18.813899993896484, -29.28179931640625",
  },
  {
    ident: "FAAL",
    type: "small_airport",
    name: "Alldays Airport",
    elevation_ft: "2600",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Alldays",
    gps_code: "FAAL",
    iata_code: "ADY",
    coordinates: "29.0555000305, -22.6790008545",
  },
  {
    ident: "FABE",
    type: "medium_airport",
    name: "Bisho Airport",
    elevation_ft: "1950",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "Bisho",
    gps_code: "FABE",
    iata_code: "BIY",
    coordinates: "27.279100418099997, -32.8970985413",
  },
  {
    ident: "FABL",
    type: "medium_airport",
    name: "Bram Fischer International Airport",
    elevation_ft: "4457",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-FS",
    municipality: "Bloemfontain",
    gps_code: "FABL",
    iata_code: "BFN",
    coordinates: "26.302401, -29.092699",
  },
  {
    ident: "FABU",
    type: "small_airport",
    name: "Bultfontein Airport",
    elevation_ft: "4400",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-FS",
    municipality: "Bultfontein",
    gps_code: "FABU",
    iata_code: "UTE",
    coordinates: "26.135835, -28.273369",
  },
  {
    ident: "FACC",
    type: "small_airport",
    name: "Arathusa Safari Lodge Airport",
    elevation_ft: "1289",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Arathusa",
    gps_code: "FAAR",
    iata_code: "ASS",
    coordinates: "31.522499, -24.744165",
  },
  {
    ident: "FACD",
    type: "small_airport",
    name: "Cradock Airport",
    elevation_ft: "3110",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "Cradock",
    gps_code: "FACD",
    iata_code: "CDO",
    coordinates: "25.6455993652, -32.1567001343",
  },
  {
    ident: "FACT",
    type: "large_airport",
    name: "Cape Town International Airport",
    elevation_ft: "151",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-WC",
    municipality: "Cape Town",
    gps_code: "FACT",
    iata_code: "CPT",
    coordinates: "18.6016998291, -33.9648017883",
  },
  {
    ident: "FADK",
    type: "small_airport",
    name: "Mubatuba Airport",
    elevation_ft: "210",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Mubatuba",
    gps_code: "FADK",
    iata_code: "DUK",
    local_code: "FA65",
    coordinates: "32.24810028076172, -28.36840057373047",
  },
  {
    ident: "FADQ",
    type: "small_airport",
    name: "Zulu Inyala Airport",
    elevation_ft: "160",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Phinda",
    gps_code: "FADQ",
    iata_code: "PZL",
    coordinates: "32.30970001220703, -27.84939956665039",
  },
  {
    ident: "FAEL",
    type: "medium_airport",
    name: "Ben Schoeman Airport",
    elevation_ft: "435",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "East London",
    gps_code: "FAEL",
    iata_code: "ELS",
    coordinates: "27.825899124099998, -33.0355987549",
  },
  {
    ident: "FAEM",
    type: "small_airport",
    name: "Empangeni Airport",
    elevation_ft: "251",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Empangeni",
    gps_code: "FAEM",
    iata_code: "EMG",
    coordinates: "31.889999389648438, -28.719999313354492",
  },
  {
    ident: "FAER",
    type: "small_airport",
    name: "Ellisras Matimba Airport",
    elevation_ft: "2799",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Ellisras",
    gps_code: "FAER",
    iata_code: "ELL",
    coordinates: "27.68829917907715, -23.726699829101562",
  },
  {
    ident: "FAFB",
    type: "medium_airport",
    name: "Ficksburg Sentraoes Airport",
    elevation_ft: "5315",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-FS",
    municipality: "Ficksburg",
    gps_code: "FAFB",
    iata_code: "FCB",
    coordinates: "27.908899307250977, -28.82309913635254",
  },
  {
    ident: "FAGC",
    type: "medium_airport",
    name: "Grand Central Airport",
    elevation_ft: "5325",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-GT",
    municipality: "Midrand",
    gps_code: "FAGC",
    iata_code: "GCJ",
    coordinates: "28.1401004791, -25.986299514799995",
  },
  {
    ident: "FAGG",
    type: "large_airport",
    name: "George Airport",
    elevation_ft: "648",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-WC",
    municipality: "George",
    gps_code: "FAGG",
    iata_code: "GRJ",
    coordinates: "22.378902, -34.0056",
  },
  {
    ident: "FAGI",
    type: "small_airport",
    name: "Giyani Airport",
    elevation_ft: "1584",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Giyani",
    gps_code: "FAGI",
    iata_code: "GIY",
    coordinates: "30.649999618530273, -23.283300399780273",
  },
  {
    ident: "FAGM",
    type: "medium_airport",
    name: "Rand Airport",
    elevation_ft: "5483",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-GT",
    municipality: "Johannesburg",
    gps_code: "FAGM",
    iata_code: "QRA",
    coordinates: "28.1511993408, -26.2425003052",
  },
  {
    ident: "FAHL",
    type: "small_airport",
    name: "Hluhluwe Airport",
    elevation_ft: "249",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Hluhluwe",
    gps_code: "FAHL",
    iata_code: "HLW",
    coordinates: "32.2751712799, -28.0166049887",
  },
  {
    ident: "FAHR",
    type: "medium_airport",
    name: "Harrismith Airport",
    elevation_ft: "5585",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-FS",
    municipality: "Harrismith",
    gps_code: "FAHR",
    iata_code: "HRS",
    coordinates: "29.106199264526367, -28.23509979248047",
  },
  {
    ident: "FAHS",
    type: "small_airport",
    name: "Hoedspruit Air Force Base Airport",
    elevation_ft: "1743",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Hoedspruit",
    gps_code: "FAHS",
    iata_code: "HDS",
    coordinates: "31.0487003326, -24.368600845299998",
  },
  {
    ident: "FAKD",
    type: "medium_airport",
    name: "P C Pelser Airport",
    elevation_ft: "4444",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NW",
    municipality: "Klerksdorp",
    gps_code: "FAKD",
    iata_code: "KXE",
    coordinates: "26.718000411987305, -26.8710994720459",
  },
  {
    ident: "FAKM",
    type: "medium_airport",
    name: "Kimberley Airport",
    elevation_ft: "3950",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Kimberley",
    gps_code: "FAKM",
    iata_code: "KIM",
    coordinates: "24.7651996613, -28.802799224900003",
  },
  {
    ident: "FAKN",
    type: "medium_airport",
    name: "Kruger Mpumalanga International Airport",
    elevation_ft: "2829",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Mpumalanga",
    gps_code: "FAKN",
    iata_code: "MQP",
    coordinates: "31.1056003571, -25.3831996918",
  },
  {
    ident: "FAKP",
    type: "small_airport",
    name: "Komatipoort Airport",
    elevation_ft: "541",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Komatipoort",
    gps_code: "FAKP",
    iata_code: "KOF",
    coordinates: "31.93000030517578, -25.44029998779297",
  },
  {
    ident: "FAKU",
    type: "medium_airport",
    name: "Johan Pienaar Airport",
    elevation_ft: "4382",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Kuruman",
    gps_code: "FAKU",
    iata_code: "KMH",
    coordinates: "23.411399841308594, -27.45669937133789",
  },
  {
    ident: "FAKZ",
    type: "medium_airport",
    name: "Kleinsee Airport",
    elevation_ft: "270",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Kleinsee",
    gps_code: "FAKZ",
    iata_code: "KLZ",
    coordinates: "17.093999862700002, -29.6884002686",
  },
  {
    ident: "FAL",
    type: "closed",
    name: "Falcon State Airport",
    elevation_ft: "358",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Roma",
    iata_code: "FAL",
    coordinates: "-99.13985, 26.5856",
  },
  {
    ident: "FALA",
    type: "medium_airport",
    name: "Lanseria Airport",
    elevation_ft: "4517",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-GT",
    municipality: "Johannesburg",
    gps_code: "FALA",
    iata_code: "HLA",
    coordinates: "27.9260997772, -25.938499450699997",
  },
  {
    ident: "FALC",
    type: "medium_airport",
    name: "Lime Acres Finsch Mine Airport",
    elevation_ft: "4900",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Lime Acres",
    gps_code: "FALC",
    iata_code: "LMR",
    coordinates: "23.43910026550293, -28.36009979248047",
  },
  {
    ident: "FALD",
    type: "small_airport",
    name: "Londolozi Airport",
    elevation_ft: "1300",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Londolozi",
    gps_code: "FALD",
    iata_code: "LDZ",
    coordinates: "31.4743, -24.7478",
  },
  {
    ident: "FALE",
    type: "large_airport",
    name: "King Shaka International Airport",
    elevation_ft: "295",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Durban",
    gps_code: "FALE",
    iata_code: "DUR",
    coordinates: "31.1197222222, -29.6144444444",
  },
  {
    ident: "FALK",
    type: "small_airport",
    name: "Lusikisiki Airport",
    elevation_ft: "1831",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "Lusikisiki",
    gps_code: "FALK",
    iata_code: "LUJ",
    coordinates: "29.58329963684082, -31.36669921875",
  },
  {
    ident: "FALO",
    type: "small_airport",
    name: "Louis Trichardt Airport",
    elevation_ft: "3025",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Louis Trichardt",
    gps_code: "FALO",
    iata_code: "LCD",
    coordinates: "29.864700317382812, -23.061899185180664",
  },
  {
    ident: "FALW",
    type: "medium_airport",
    name: "Langebaanweg Airport",
    elevation_ft: "108",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-WC",
    municipality: "Langebaanweg",
    gps_code: "FALW",
    iata_code: "SDB",
    coordinates: "18.1602993011, -32.968898773199996",
  },
  {
    ident: "FALY",
    type: "medium_airport",
    name: "Ladysmith Airport",
    elevation_ft: "3548",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Ladysmith",
    gps_code: "FALY",
    iata_code: "LAY",
    coordinates: "29.749700546299998, -28.5816993713",
  },
  {
    ident: "FAMD",
    type: "medium_airport",
    name: "Malamala Airport",
    elevation_ft: "1124",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Malamala",
    gps_code: "FAMD",
    iata_code: "AAM",
    coordinates: "31.544599533081055, -24.818099975585938",
  },
  {
    ident: "FAMG",
    type: "medium_airport",
    name: "Margate Airport",
    elevation_ft: "495",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Margate",
    gps_code: "FAMG",
    iata_code: "MGH",
    coordinates: "30.343000412, -30.8574008942",
  },
  {
    ident: "FAMH",
    type: "small_airport",
    name: "Musina(Messina) Airport",
    elevation_ft: "1904",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Musina",
    gps_code: "FAMH",
    iata_code: "MEZ",
    coordinates: "29.9862003326, -22.356000900299996",
  },
  {
    ident: "FAMM",
    type: "medium_airport",
    name: "Mmabatho International Airport",
    elevation_ft: "4181",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NW",
    municipality: "Mafeking",
    gps_code: "FAMM",
    iata_code: "MBD",
    coordinates: "25.548000335699996, -25.798400878900004",
  },
  {
    ident: "FAMN",
    type: "medium_airport",
    name: "Riverside Airport",
    elevation_ft: "1024",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Malelane",
    gps_code: "FAMN",
    iata_code: "LLE",
    coordinates: "31.5767002106, -25.4300003052",
  },
  {
    ident: "FAMO",
    type: "small_airport",
    name: "Mossel Bay Airport",
    elevation_ft: "531",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-WC",
    municipality: "Mossel Bay",
    gps_code: "FAMO",
    iata_code: "MZY",
    coordinates: "22.058599, -34.158298",
  },
  {
    ident: "FAMU",
    type: "medium_airport",
    name: "Mkuze Airport",
    elevation_ft: "400",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Mkuze",
    gps_code: "FAMU",
    iata_code: "MZQ",
    coordinates: "32.0443000793457, -27.626100540161133",
  },
  {
    ident: "FANC",
    type: "medium_airport",
    name: "Newcastle Airport",
    elevation_ft: "4074",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Newcastle",
    gps_code: "FANC",
    iata_code: "NCS",
    coordinates: "29.976900100699996, -27.7705993652",
  },
  {
    ident: "FANG",
    type: "small_airport",
    name: "Ngala Airport",
    elevation_ft: "3357",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Ngala",
    gps_code: "FANG",
    iata_code: "NGL",
    coordinates: "31.326, -24.389",
  },
  {
    ident: "FANS",
    type: "small_airport",
    name: "Nelspruit Airport",
    elevation_ft: "2875",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Nelspruit",
    gps_code: "FANS",
    iata_code: "NLP",
    coordinates: "30.9137992859, -25.5",
  },
  {
    ident: "FAOB",
    type: "small_airport",
    name: "Overberg Airport",
    elevation_ft: "52",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-WC",
    municipality: "Overberg",
    gps_code: "FAOB",
    iata_code: "OVG",
    coordinates: "20.250699996900003, -34.554901123",
  },
  {
    ident: "FAOH",
    type: "medium_airport",
    name: "Oudtshoorn Airport",
    elevation_ft: "1063",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-WC",
    municipality: "Oudtshoorn",
    gps_code: "FAOH",
    iata_code: "OUH",
    local_code: "FAOH",
    coordinates: "22.188999176, -33.6069984436",
  },
  {
    ident: "FAOR",
    type: "large_airport",
    name: "OR Tambo International Airport",
    elevation_ft: "5558",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-GT",
    municipality: "Johannesburg",
    gps_code: "FAOR",
    iata_code: "JNB",
    coordinates: "28.246, -26.1392",
  },
  {
    ident: "FAPA",
    type: "small_airport",
    name: "Port Alfred Airport",
    elevation_ft: "275",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "Port Alfred",
    gps_code: "FAPA",
    iata_code: "AFD",
    coordinates: "26.877700805699998, -33.5541992188",
  },
  {
    ident: "FAPE",
    type: "medium_airport",
    name: "Port Elizabeth Airport",
    elevation_ft: "226",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "Port Elizabeth",
    gps_code: "FAPE",
    iata_code: "PLZ",
    coordinates: "25.6173000336, -33.9849014282",
  },
  {
    ident: "FAPG",
    type: "medium_airport",
    name: "Plettenberg Bay Airport",
    elevation_ft: "465",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-WC",
    municipality: "Plettenberg Bay",
    gps_code: "FAPG",
    iata_code: "PBZ",
    coordinates: "23.3287234306, -34.0881601675",
  },
  {
    ident: "FAPH",
    type: "medium_airport",
    name: "Hendrik Van Eck Airport",
    elevation_ft: "1432",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Phalaborwa",
    gps_code: "FAPH",
    iata_code: "PHW",
    coordinates: "31.1553993225, -23.937200546299998",
  },
  {
    ident: "FAPJ",
    type: "medium_airport",
    name: "Port St Johns Airport",
    elevation_ft: "1227",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "Port St Johns",
    gps_code: "FAPJ",
    iata_code: "JOH",
    coordinates: "29.519800186157227, -31.605899810791016",
  },
  {
    ident: "FAPK",
    type: "small_airport",
    name: "Prieska Airport",
    elevation_ft: "3100",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Prieska",
    gps_code: "FAPK",
    iata_code: "PRK",
    coordinates: "22.770599365234375, -29.6835994720459",
  },
  {
    ident: "FAPM",
    type: "medium_airport",
    name: "Pietermaritzburg Airport",
    elevation_ft: "2423",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Pietermaritzburg",
    gps_code: "FAPM",
    iata_code: "PZB",
    coordinates: "30.3987007141, -29.649000167799997",
  },
  {
    ident: "FAPN",
    type: "small_airport",
    name: "Pilanesberg International Airport",
    elevation_ft: "3412",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NW",
    municipality: "Pilanesberg",
    gps_code: "FAPN",
    iata_code: "NTY",
    coordinates: "27.173401, -25.333799",
  },
  {
    ident: "FAPP",
    type: "medium_airport",
    name: "Polokwane International Airport",
    elevation_ft: "4076",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Polokwane",
    gps_code: "FAPP",
    iata_code: "PTG",
    coordinates: "29.458615, -23.845269",
  },
  {
    ident: "FAPS",
    type: "medium_airport",
    name: "Potchefstroom Airport",
    elevation_ft: "4520",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NW",
    municipality: "Potchefstroom",
    gps_code: "FAPS",
    iata_code: "PCF",
    coordinates: "27.0818996429, -26.670999527",
  },
  {
    ident: "FAQT",
    type: "medium_airport",
    name: "Queenstown Airport",
    elevation_ft: "3637",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "Queenstown",
    gps_code: "FAQT",
    iata_code: "UTW",
    coordinates: "26.882200241088867, -31.92020034790039",
  },
  {
    ident: "FARB",
    type: "medium_airport",
    name: "Richards Bay Airport",
    elevation_ft: "109",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Richards Bay",
    gps_code: "FARB",
    iata_code: "RCB",
    coordinates: "32.0920982361, -28.740999221800003",
  },
  {
    ident: "FARI",
    type: "small_airport",
    name: "Reivilo Airport",
    elevation_ft: "4715",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NW",
    municipality: "Reivilo",
    gps_code: "FARI",
    iata_code: "RVO",
    coordinates: "24.172500610351562, -27.547199249267578",
  },
  {
    ident: "FARS",
    type: "medium_airport",
    name: "Robertson Airport",
    elevation_ft: "640",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-WC",
    municipality: "Robertson",
    gps_code: "FARS",
    iata_code: "ROD",
    coordinates: "19.902799606323242, -33.812198638916016",
  },
  {
    ident: "FASB",
    type: "medium_airport",
    name: "Springbok Airport",
    elevation_ft: "2690",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Springbok",
    gps_code: "FASB",
    iata_code: "SBU",
    coordinates: "17.939599990844727, -29.689300537109375",
  },
  {
    ident: "FASC",
    type: "medium_airport",
    name: "Secunda Airport",
    elevation_ft: "5250",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Secunda",
    gps_code: "FASC",
    iata_code: "ZEC",
    coordinates: "29.170099258399997, -26.52409935",
  },
  {
    ident: "FASE",
    type: "small_airport",
    name: "Sabi Sabi Airport",
    elevation_ft: "1276",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Belfast",
    gps_code: "FASE",
    iata_code: "GSS",
    coordinates: "31.4488477707, -24.947375434199998",
  },
  {
    ident: "FASS",
    type: "medium_airport",
    name: "Sishen Airport",
    elevation_ft: "3848",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Sishen",
    gps_code: "FASS",
    iata_code: "SIS",
    coordinates: "22.9993000031, -27.6485996246",
  },
  {
    ident: "FASZ",
    type: "medium_airport",
    name: "Skukuza Airport",
    elevation_ft: "1020",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Skukuza",
    gps_code: "FASZ",
    iata_code: "SZK",
    coordinates: "31.5886993408, -24.960899353",
  },
  {
    ident: "FATH",
    type: "small_airport",
    name: "Thohoyandou Airport",
    elevation_ft: "2021",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Thohoyandou",
    gps_code: "FATH",
    iata_code: "THY",
    coordinates: "30.38360023498535, -23.076900482177734",
  },
  {
    ident: "FATN",
    type: "small_airport",
    name: "Thaba Nchu Tar Airport",
    elevation_ft: "4941",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-FS",
    municipality: "Homeward",
    gps_code: "FATN",
    iata_code: "TCU",
    coordinates: "26.822799682617188, -29.317800521850586",
  },
  {
    ident: "FATZ",
    type: "medium_airport",
    name: "Tzaneen Airport",
    elevation_ft: "1914",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Tzaneen",
    gps_code: "FATZ",
    iata_code: "LTA",
    coordinates: "30.329299926799997, -23.8243999481",
  },
  {
    ident: "FAUL",
    type: "medium_airport",
    name: "Prince Mangosuthu Buthelezi Airport",
    elevation_ft: "1720",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Ulundi",
    gps_code: "FAUL",
    iata_code: "ULD",
    coordinates: "31.4165000916, -28.3206005096",
  },
  {
    ident: "FAUP",
    type: "medium_airport",
    name: "Pierre Van Ryneveld Airport",
    elevation_ft: "2782",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Upington",
    gps_code: "FAUP",
    iata_code: "UTN",
    coordinates: "21.260200500499998, -28.39909935",
  },
  {
    ident: "FAUT",
    type: "medium_airport",
    name: "K. D. Matanzima Airport",
    elevation_ft: "2400",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "Mthatha",
    gps_code: "FAUT",
    iata_code: "UTT",
    coordinates: "28.6733551025, -31.546363184900002",
  },
  {
    ident: "FAVB",
    type: "medium_airport",
    name: "Vryburg Airport",
    elevation_ft: "3920",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NW",
    municipality: "Vyrburg",
    gps_code: "FAVB",
    iata_code: "VRU",
    coordinates: "24.7287998199, -26.9824008942",
  },
  {
    ident: "FAVG",
    type: "medium_airport",
    name: "Virginia Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Durban",
    gps_code: "FAVG",
    iata_code: "VIR",
    coordinates: "31.058399200439453, -29.770599365234375",
  },
  {
    ident: "FAVR",
    type: "medium_airport",
    name: "Vredendal Airport",
    elevation_ft: "330",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-WC",
    municipality: "Vredendal",
    gps_code: "FAVR",
    iata_code: "VRE",
    coordinates: "18.5447998046875, -31.641000747680664",
  },
  {
    ident: "FAVY",
    type: "small_airport",
    name: "Vryheid Airport",
    elevation_ft: "3799",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NL",
    municipality: "Vryheid",
    gps_code: "FAVY",
    iata_code: "VYD",
    coordinates: "30.79640007019043, -27.78689956665039",
  },
  {
    ident: "FAWB",
    type: "medium_airport",
    name: "Wonderboom Airport",
    elevation_ft: "4095",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-GT",
    municipality: "Pretoria",
    gps_code: "FAWB",
    iata_code: "PRY",
    coordinates: "28.224199, -25.6539",
  },
  {
    ident: "FAWK",
    type: "medium_airport",
    name: "Waterkloof Air Force Base",
    elevation_ft: "4940",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-GT",
    municipality: "Pretoria",
    gps_code: "FAWK",
    iata_code: "WKF",
    coordinates: "28.222499847399998, -25.829999923699997",
  },
  {
    ident: "FBFT",
    type: "medium_airport",
    name: "Francistown Airport",
    elevation_ft: "3283",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-NE",
    municipality: "Francistown",
    gps_code: "FBFT",
    iata_code: "FRW",
    coordinates: "27.47450065612793, -21.15959930419922",
  },
  {
    ident: "FBGZ",
    type: "small_airport",
    name: "Ghanzi Airport",
    elevation_ft: "3730",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-GH",
    municipality: "Ghanzi",
    gps_code: "FBGZ",
    iata_code: "GNZ",
    coordinates: "21.658100128173828, -21.6924991607666",
  },
  {
    ident: "FBJW",
    type: "medium_airport",
    name: "Jwaneng Airport",
    elevation_ft: "3900",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-SO",
    gps_code: "FBJW",
    iata_code: "JWA",
    coordinates: "24.69099998474121, -24.6023006439209",
  },
  {
    ident: "FBKE",
    type: "medium_airport",
    name: "Kasane Airport",
    elevation_ft: "3289",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-NW",
    municipality: "Kasane",
    gps_code: "FBKE",
    iata_code: "BBK",
    coordinates: "25.162399291992188, -17.83289909362793",
  },
  {
    ident: "FBKR",
    type: "small_airport",
    name: "Khwai River Lodge Airport",
    elevation_ft: "3000",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-NW",
    municipality: "Khwai River Lodge",
    gps_code: "FBKR",
    iata_code: "KHW",
    coordinates: "23.783000946044922, -19.149999618530273",
  },
  {
    ident: "FBLO",
    type: "small_airport",
    name: "Lobatse Airport",
    elevation_ft: "3823",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-SE",
    municipality: "Lobatse",
    gps_code: "FBLO",
    iata_code: "LOQ",
    coordinates: "25.7139, -25.1981",
  },
  {
    ident: "FBMN",
    type: "medium_airport",
    name: "Maun Airport",
    elevation_ft: "3093",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-NW",
    municipality: "Maun",
    gps_code: "FBMN",
    iata_code: "MUB",
    coordinates: "23.431100845336914, -19.97260093688965",
  },
  {
    ident: "FBOR",
    type: "small_airport",
    name: "Orapa Airport",
    elevation_ft: "3100",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-CE",
    municipality: "Orapa",
    gps_code: "FBOR",
    iata_code: "ORP",
    coordinates: "25.3167, -21.266701",
  },
  {
    ident: "FBPY",
    type: "small_airport",
    name: "Palapye Airport",
    elevation_ft: "3000",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-CE",
    municipality: "Palapye",
    gps_code: "FBPY",
    iata_code: "QPH",
    coordinates: "27.149999618530273, -22.566999435424805",
  },
  {
    ident: "FBSK",
    type: "large_airport",
    name: "Sir Seretse Khama International Airport",
    elevation_ft: "3299",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-SE",
    municipality: "Gaborone",
    gps_code: "FBSK",
    iata_code: "GBE",
    coordinates: "25.9182, -24.555201",
  },
  {
    ident: "FBSN",
    type: "medium_airport",
    name: "Sua Pan Airport",
    elevation_ft: "2985",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-CE",
    municipality: "Sowa",
    gps_code: "FBSN",
    iata_code: "SXN",
    coordinates: "26.115801, -20.5534",
  },
  {
    ident: "FBSP",
    type: "medium_airport",
    name: "Selebi Phikwe Airport",
    elevation_ft: "2925",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-CE",
    gps_code: "FBSP",
    iata_code: "PKW",
    coordinates: "27.8288, -22.0583",
  },
  {
    ident: "FBSV",
    type: "small_airport",
    name: "Savuti Airport",
    elevation_ft: "3150",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-NW",
    municipality: "Savuti",
    gps_code: "FBSV",
    iata_code: "SVT",
    coordinates: "24.076700210571, -18.520599365234",
  },
  {
    ident: "FBSW",
    type: "small_airport",
    name: "Shakawe Airport",
    elevation_ft: "3379",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-NW",
    municipality: "Shakawe",
    gps_code: "FBSW",
    iata_code: "SWX",
    coordinates: "21.832599639892578, -18.373899459838867",
  },
  {
    ident: "FBTL",
    type: "small_airport",
    name: "Limpopo Valley Airport",
    elevation_ft: "1772",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-CE",
    municipality: "Tuli Lodge",
    gps_code: "FBLV",
    iata_code: "TLD",
    coordinates: "29.126899719199997, -22.189199447599997",
  },
  {
    ident: "FBTS",
    type: "small_airport",
    name: "Tshabong Airport",
    elevation_ft: "3179",
    continent: "AF",
    iso_country: "BW",
    iso_region: "BW-KG",
    municipality: "Tshabong",
    gps_code: "FBTS",
    iata_code: "TBY",
    coordinates: "22.399999618530273, -26.033300399780273",
  },
  {
    ident: "FCBB",
    type: "medium_airport",
    name: "Maya-Maya Airport",
    elevation_ft: "1048",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-12",
    municipality: "Brazzaville",
    gps_code: "FCBB",
    iata_code: "BZV",
    coordinates: "15.253000259399414, -4.251699924468994",
  },
  {
    ident: "FCBD",
    type: "small_airport",
    name: "Djambala Airport",
    elevation_ft: "2595",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-14",
    municipality: "Djambala",
    gps_code: "FCBD",
    iata_code: "DJM",
    coordinates: "14.754403, -2.516883",
  },
  {
    ident: "FCBK",
    type: "small_airport",
    name: "Kindamba Airport",
    elevation_ft: "1460",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-12",
    municipality: "Kindamba",
    gps_code: "FCBK",
    iata_code: "KNJ",
    coordinates: "14.517000198364258, -3.950000047683716",
  },
  {
    ident: "FCBL",
    type: "small_airport",
    name: "Lague Airport",
    elevation_ft: "2756",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-14",
    municipality: "Lague",
    gps_code: "FCBL",
    iata_code: "LCO",
    coordinates: "14.532999992370605, -2.450000047683716",
  },
  {
    ident: "FCBM",
    type: "small_airport",
    name: "Mouyondzi Airport",
    elevation_ft: "1670",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-11",
    municipality: "Mouyondzi",
    gps_code: "FCBM",
    iata_code: "MUY",
    coordinates: "13.96611213684082, -4.01487398147583",
  },
  {
    ident: "FCBS",
    type: "small_airport",
    name: "Sibiti Airport",
    elevation_ft: "1883",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-2",
    municipality: "Sibiti",
    gps_code: "FCBS",
    iata_code: "SIB",
    coordinates: "13.350000381469727, -3.683000087738037",
  },
  {
    ident: "FCBY",
    type: "small_airport",
    name: "Yokangassi Airport",
    elevation_ft: "541",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-11",
    municipality: "Nkayi",
    gps_code: "FCBY",
    iata_code: "NKY",
    coordinates: "13.286347, -4.223077",
  },
  {
    ident: "FCBZ",
    type: "small_airport",
    name: "Zanaga Airport",
    elevation_ft: "1870",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-2",
    municipality: "Zanaga",
    gps_code: "FCBZ",
    iata_code: "ANJ",
    coordinates: "13.817000389099121, -2.8499999046325684",
  },
  {
    ident: "FCMM",
    type: "small_airport",
    name: "Mossendjo Airport",
    elevation_ft: "1519",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-9",
    municipality: "Mossendjo",
    gps_code: "FCMM",
    iata_code: "MSX",
    coordinates: "12.699999809265137, -2.950000047683716",
  },
  {
    ident: "FCOB",
    type: "small_airport",
    name: "Boundji Airport",
    elevation_ft: "1247",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-8",
    municipality: "Boundji",
    gps_code: "FCOB",
    iata_code: "BOE",
    coordinates: "15.383000373840332, -1.0329999923706055",
  },
  {
    ident: "FCOD",
    type: "medium_airport",
    name: "Oyo Ollombo Airport",
    elevation_ft: "1073",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-14",
    municipality: "Oyo",
    gps_code: "FCOD",
    iata_code: "OLL",
    coordinates: "15.91, -1.226666",
  },
  {
    ident: "FCOE",
    type: "small_airport",
    name: "Ewo Airport",
    elevation_ft: "1503",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-8",
    municipality: "Ewo",
    gps_code: "FCOE",
    iata_code: "EWO",
    coordinates: "14.800000190734863, -0.8830000162124634",
  },
  {
    ident: "FCOG",
    type: "small_airport",
    name: "Gamboma Airport",
    elevation_ft: "1509",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-14",
    municipality: "Gamboma",
    gps_code: "FCOG",
    iata_code: "GMM",
    coordinates: "15.885237, -1.829403",
  },
  {
    ident: "FCOI",
    type: "small_airport",
    name: "Impfondo Airport",
    elevation_ft: "1099",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-7",
    municipality: "Impfondo",
    gps_code: "FCOI",
    iata_code: "ION",
    coordinates: "18.066999435424805, 1.6169999837875366",
  },
  {
    ident: "FCOK",
    type: "small_airport",
    name: "Kelle Airport",
    elevation_ft: "1526",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-8",
    municipality: "Kelle",
    gps_code: "FCOK",
    iata_code: "KEE",
    coordinates: "14.532999992370605, -0.08299999684095383",
  },
  {
    ident: "FCOM",
    type: "small_airport",
    name: "Makoua Airport",
    elevation_ft: "1293",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-8",
    municipality: "Makoua",
    gps_code: "FCOM",
    iata_code: "MKJ",
    coordinates: "15.583000183105469, -0.017000000923871994",
  },
  {
    ident: "FCOO",
    type: "medium_airport",
    name: "Owando Airport",
    elevation_ft: "1214",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-8",
    municipality: "Owando",
    gps_code: "FCOO",
    iata_code: "FTX",
    coordinates: "15.95009994506836, -0.5313500165939331",
  },
  {
    ident: "FCOS",
    type: "small_airport",
    name: "Souanke Airport",
    elevation_ft: "1722",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-13",
    municipality: "Souanke",
    gps_code: "FCOS",
    iata_code: "SOE",
    coordinates: "14.133000373840332, 2.066999912261963",
  },
  {
    ident: "FCOT",
    type: "small_airport",
    name: "Betou Airport",
    elevation_ft: "1168",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-7",
    municipality: "Betou",
    gps_code: "FCOT",
    iata_code: "BTB",
    coordinates: "18.5, 3.049999952316284",
  },
  {
    ident: "FCOU",
    type: "medium_airport",
    name: "Ouesso Airport",
    elevation_ft: "1158",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-13",
    gps_code: "FCOU",
    iata_code: "OUE",
    coordinates: "16.0379009247, 1.6159900426899998",
  },
  {
    ident: "FCPA",
    type: "small_airport",
    name: "Makabana Airport",
    elevation_ft: "495",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-9",
    municipality: "Makabana",
    gps_code: "FCPA",
    iata_code: "KMK",
    coordinates: "12.616999626159668, -3.4830000400543213",
  },
  {
    ident: "FCPL",
    type: "medium_airport",
    name: "Ngot Nzoungou Airport",
    elevation_ft: "1079",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-9",
    municipality: "Dolisie",
    gps_code: "FCPD",
    iata_code: "DIS",
    coordinates: "12.6599, -4.20635",
  },
  {
    ident: "FCPP",
    type: "medium_airport",
    name: "Pointe Noire Airport",
    elevation_ft: "55",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-5",
    municipality: "Pointe Noire",
    gps_code: "FCPP",
    iata_code: "PNR",
    coordinates: "11.88659954071045, -4.816030025482178",
  },
  {
    ident: "FDMS",
    type: "medium_airport",
    name: "Matsapha Airport",
    elevation_ft: "2075",
    continent: "AF",
    iso_country: "SZ",
    iso_region: "SZ-MA",
    municipality: "Manzini",
    gps_code: "FDMS",
    iata_code: "MTS",
    coordinates: "31.307501, -26.528999",
  },
  {
    ident: "FDSK",
    type: "large_airport",
    name: "King Mswati III International Airport",
    elevation_ft: "1092",
    continent: "AF",
    iso_country: "SZ",
    iso_region: "SZ-LU",
    gps_code: "FDSK",
    iata_code: "SHO",
    coordinates: "31.716944, -26.358611",
  },
  {
    ident: "FEA",
    type: "small_airport",
    name: "Fetlar Airport",
    elevation_ft: "263",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Fetlar Island",
    iata_code: "FEA",
    coordinates: "-0.872777777778, 60.6033333333",
  },
  {
    ident: "FEFC",
    type: "small_airport",
    name: "Carnot Airport",
    elevation_ft: "1985",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-HS",
    municipality: "Carnot",
    gps_code: "FEFC",
    iata_code: "CRF",
    coordinates: "15.894000053405762, 4.936999797821045",
  },
  {
    ident: "FEFF",
    type: "medium_airport",
    name: "Bangui M'Poko International Airport",
    elevation_ft: "1208",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-MP",
    municipality: "Bangui",
    gps_code: "FEFF",
    iata_code: "BGF",
    coordinates: "18.518800735473633, 4.39847993850708",
  },
  {
    ident: "FEFG",
    type: "small_airport",
    name: "Bangassou Airport",
    elevation_ft: "1706",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-MB",
    municipality: "Bangassou",
    gps_code: "FEFG",
    iata_code: "BGU",
    coordinates: "22.7810001373291, 4.784999847412109",
  },
  {
    ident: "FEFI",
    type: "small_airport",
    name: "Birao Airport",
    elevation_ft: "1696",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-VR",
    municipality: "Birao",
    gps_code: "FEFI",
    iata_code: "IRO",
    coordinates: "22.716899871826172, 10.23639965057373",
  },
  {
    ident: "FEFM",
    type: "small_airport",
    name: "Bambari Airport",
    elevation_ft: "1549",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-UK",
    municipality: "Bambari",
    gps_code: "FEFM",
    iata_code: "BBY",
    coordinates: "20.647499084472656, 5.846940040588379",
  },
  {
    ident: "FEFN",
    type: "small_airport",
    name: "N'D����l���� Airport",
    elevation_ft: "1631",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-BB",
    municipality: "N'D����l����",
    gps_code: "FEFN",
    iata_code: "NDL",
    coordinates: "20.635156631469727, 8.427206039428711",
  },
  {
    ident: "FEFO",
    type: "small_airport",
    name: "Bouar Airport",
    elevation_ft: "3360",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-NM",
    municipality: "Bouar",
    gps_code: "FEFO",
    iata_code: "BOP",
    coordinates: "15.63700008392334, 5.958000183105469",
  },
  {
    ident: "FEFR",
    type: "small_airport",
    name: "Bria Airport",
    elevation_ft: "1975",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-HK",
    municipality: "Bria",
    gps_code: "FEFR",
    iata_code: "BIV",
    coordinates: "21.98940086364746, 6.527780055999756",
  },
  {
    ident: "FEFS",
    type: "small_airport",
    name: "Bossangoa Airport",
    elevation_ft: "1637",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-AC",
    municipality: "Bossangoa",
    gps_code: "FEFS",
    iata_code: "BSN",
    coordinates: "17.429000854492188, 6.492000102996826",
  },
  {
    ident: "FEFT",
    type: "medium_airport",
    name: "Berb����rati Airport",
    elevation_ft: "1929",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-HS",
    municipality: "Berb����rati",
    gps_code: "FEFT",
    iata_code: "BBT",
    coordinates: "15.786399841308594, 4.2215800285339355",
  },
  {
    ident: "FEFW",
    type: "small_airport",
    name: "Ouadda Airport",
    elevation_ft: "2461",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-HK",
    municipality: "Ouadda",
    gps_code: "FEFW",
    iata_code: "ODA",
    coordinates: "22.39859962463379, 8.010560035705566",
  },
  {
    ident: "FEFY",
    type: "small_airport",
    name: "Yalinga Airport",
    elevation_ft: "1975",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-HK",
    municipality: "Yalinga",
    gps_code: "FEFY",
    iata_code: "AIG",
    coordinates: "23.260000228881836, 6.519999980926514",
  },
  {
    ident: "FEFZ",
    type: "small_airport",
    name: "Zemio Airport",
    elevation_ft: "1995",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-HM",
    municipality: "Zemio",
    gps_code: "FEFZ",
    iata_code: "IMO",
    coordinates: "25.149999618530273, 5.050000190734863",
  },
  {
    ident: "FEGE",
    type: "small_airport",
    name: "M'Boki Airport",
    elevation_ft: "1969",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-HM",
    municipality: "Mboki",
    gps_code: "FEGE",
    iata_code: "MKI",
    coordinates: "25.931900024399997, 5.33301019669",
  },
  {
    ident: "FEGF",
    type: "small_airport",
    name: "Batangafo Airport",
    elevation_ft: "1378",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-AC",
    municipality: "Batangafo",
    gps_code: "FEGF",
    iata_code: "BTG",
    coordinates: "18.308799743652344, 7.314109802246094",
  },
  {
    ident: "FEGL",
    type: "small_airport",
    name: "Gordil Airport",
    elevation_ft: "1427",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-BB",
    municipality: "Melle",
    gps_code: "FEGL",
    iata_code: "GDI",
    coordinates: "21.728172, 9.581117",
  },
  {
    ident: "FEGM",
    type: "small_airport",
    name: "Bakouma Airport",
    elevation_ft: "1640",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-MB",
    municipality: "Bakouma",
    gps_code: "FEGM",
    iata_code: "BMF",
    coordinates: "22.801000595092773, 5.693999767303467",
  },
  {
    ident: "FEGO",
    type: "small_airport",
    name: "Ouanda Djall���� Airport",
    elevation_ft: "1985",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-VR",
    municipality: "Ouanda Djall����",
    gps_code: "FEGO",
    iata_code: "ODJ",
    coordinates: "22.783000946044922, 8.899999618530273",
  },
  {
    ident: "FEGR",
    type: "small_airport",
    name: "Rafa���� Airport",
    elevation_ft: "1759",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-MB",
    municipality: "Rafa����",
    gps_code: "FEGR",
    iata_code: "RFA",
    coordinates: "23.927799224853516, 4.988609790802002",
  },
  {
    ident: "FEGU",
    type: "small_airport",
    name: "Bouca Airport",
    elevation_ft: "1532",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-AC",
    municipality: "Bouca",
    gps_code: "FEGU",
    iata_code: "BCF",
    coordinates: "18.267000198364258, 6.517000198364258",
  },
  {
    ident: "FEGZ",
    type: "small_airport",
    name: "Bozoum Airport",
    elevation_ft: "2215",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-OP",
    municipality: "Bozoum",
    gps_code: "FEGZ",
    iata_code: "BOZ",
    coordinates: "16.3218994140625, 6.344170093536377",
  },
  {
    ident: "FGAB",
    type: "small_airport",
    name: "Annob����n Airport",
    elevation_ft: "82",
    continent: "AF",
    iso_country: "GQ",
    iso_region: "GQ-AN",
    municipality: "San Antonio de Pal����",
    gps_code: "FGAB",
    iata_code: "NBN",
    coordinates: "5.621944, -1.410277",
  },
  {
    ident: "FGBT",
    type: "medium_airport",
    name: "Bata Airport",
    elevation_ft: "13",
    continent: "AF",
    iso_country: "GQ",
    iso_region: "GQ-LI",
    gps_code: "FGBT",
    iata_code: "BSG",
    coordinates: "9.805680274963379, 1.9054700136184692",
  },
  {
    ident: "FGMY",
    type: "small_airport",
    name: "President Obiang Nguema International Airport",
    elevation_ft: "2165",
    continent: "AF",
    iso_country: "GQ",
    iso_region: "GQ-WN",
    municipality: "Mengomey����n",
    gps_code: "FGMY",
    iata_code: "GEM",
    coordinates: "11.024394, 1.685334",
  },
  {
    ident: "FGSL",
    type: "medium_airport",
    name: "Malabo Airport",
    elevation_ft: "76",
    continent: "AF",
    iso_country: "GQ",
    iso_region: "GQ-BN",
    municipality: "Malabo",
    gps_code: "FGSL",
    iata_code: "SSG",
    coordinates: "8.708720207214355, 3.755270004272461",
  },
  {
    ident: "FHAW",
    type: "medium_airport",
    name: "RAF Ascension Island",
    elevation_ft: "278",
    continent: "AF",
    iso_country: "SH",
    iso_region: "SH-AC",
    municipality: "Ascension Island",
    gps_code: "FHAW",
    iata_code: "ASI",
    coordinates: "-14.3937, -7.9696",
  },
  {
    ident: "FHSH",
    type: "small_airport",
    name: "St. Helena Airport",
    elevation_ft: "1017",
    continent: "AF",
    iso_country: "SH",
    iso_region: "SH-SH",
    municipality: "Jamestown",
    gps_code: "FHSH",
    iata_code: "HLE",
    coordinates: "-5.645943, -15.957725",
  },
  {
    ident: "FIMP",
    type: "large_airport",
    name: "Sir Seewoosagur Ramgoolam International Airport",
    elevation_ft: "186",
    continent: "AF",
    iso_country: "MU",
    iso_region: "MU-GP",
    municipality: "Port Louis",
    gps_code: "FIMP",
    iata_code: "MRU",
    coordinates: "57.683601, -20.430201",
  },
  {
    ident: "FIMR",
    type: "medium_airport",
    name: "Sir Charles Gaetan Duval Airport",
    elevation_ft: "95",
    continent: "AF",
    iso_country: "MU",
    iso_region: "MU-VP",
    municipality: "Port Mathurin",
    gps_code: "FIMR",
    iata_code: "RRG",
    coordinates: "63.361, -19.7577",
  },
  {
    ident: "FIN",
    type: "small_airport",
    name: "Finschhafen Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Buki",
    gps_code: "AYFI",
    iata_code: "FIN",
    local_code: "FIN",
    coordinates: "147.85405, -6.621750109",
  },
  {
    ident: "FJDG",
    type: "medium_airport",
    name: "Diego Garcia Naval Support Facility",
    elevation_ft: "9",
    continent: "AS",
    iso_country: "IO",
    iso_region: "IO-U-A",
    municipality: "Diego Garcia",
    gps_code: "FJDG",
    iata_code: "NKW",
    coordinates: "72.411102, -7.31327",
  },
  {
    ident: "FKAN",
    type: "small_airport",
    name: "Nkongsamba Airport",
    elevation_ft: "2641",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-LT",
    municipality: "Nkongsamba",
    gps_code: "FKAN",
    iata_code: "NKS",
    coordinates: "9.932999610899998, 4.9499998092699995",
  },
  {
    ident: "FKKB",
    type: "small_airport",
    name: "Kribi Airport",
    elevation_ft: "148",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-SU",
    municipality: "Kribi",
    gps_code: "FKKB",
    iata_code: "KBI",
    coordinates: "9.9777803421, 2.8738899231",
  },
  {
    ident: "FKKC",
    type: "medium_airport",
    name: "Tiko Airport",
    elevation_ft: "151",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-SW",
    municipality: "Tiko",
    gps_code: "FKKC",
    iata_code: "TKC",
    coordinates: "9.360529899600001, 4.08919000626",
  },
  {
    ident: "FKKD",
    type: "medium_airport",
    name: "Douala International Airport",
    elevation_ft: "33",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-LT",
    municipality: "Douala",
    gps_code: "FKKD",
    iata_code: "DLA",
    coordinates: "9.719479560849999, 4.0060801506",
  },
  {
    ident: "FKKF",
    type: "small_airport",
    name: "Mamfe Airport",
    elevation_ft: "413",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-SW",
    municipality: "Mamfe",
    gps_code: "FKKF",
    iata_code: "MMF",
    coordinates: "9.306389808654785, 5.704170227050781",
  },
  {
    ident: "FKKG",
    type: "small_airport",
    name: "Bali Airport",
    elevation_ft: "4465",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-NW",
    municipality: "Bali",
    gps_code: "FKKG",
    iata_code: "BLC",
    coordinates: "10.0338888889, 5.89527777778",
  },
  {
    ident: "FKKH",
    type: "small_airport",
    name: "Ka����l���� Airport",
    elevation_ft: "1276",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-EN",
    municipality: "Ka����l����",
    gps_code: "FKKH",
    iata_code: "KLE",
    coordinates: "14.445599555969238, 10.092499732971191",
  },
  {
    ident: "FKKI",
    type: "small_airport",
    name: "Batouri Airport",
    elevation_ft: "2152",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-ES",
    municipality: "Batouri",
    gps_code: "FKKI",
    iata_code: "OUR",
    coordinates: "14.362500190734863, 4.474999904632568",
  },
  {
    ident: "FKKJ",
    type: "small_airport",
    name: "Yagoua Airport",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-EN",
    municipality: "Yagoua",
    gps_code: "FKKJ",
    iata_code: "GXX",
    coordinates: "15.237199783325195, 10.356100082397461",
  },
  {
    ident: "FKKL",
    type: "medium_airport",
    name: "Salak Airport",
    elevation_ft: "1390",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-EN",
    municipality: "Maroua",
    gps_code: "FKKL",
    iata_code: "MVR",
    coordinates: "14.257399559020996, 10.451399803161621",
  },
  {
    ident: "FKKM",
    type: "medium_airport",
    name: "Foumban Nkounja Airport",
    elevation_ft: "3963",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-OU",
    municipality: "Foumban",
    gps_code: "FKKM",
    iata_code: "FOM",
    coordinates: "10.750800132751465, 5.636919975280762",
  },
  {
    ident: "FKKN",
    type: "medium_airport",
    name: "N'Gaound����r���� Airport",
    elevation_ft: "3655",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-AD",
    municipality: "N'Gaound����r����",
    gps_code: "FKKN",
    iata_code: "NGE",
    coordinates: "13.559200286865234, 7.3570098876953125",
  },
  {
    ident: "FKKO",
    type: "small_airport",
    name: "Bertoua Airport",
    elevation_ft: "2133",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-ES",
    municipality: "Bertoua",
    gps_code: "FKKO",
    iata_code: "BTA",
    coordinates: "13.726099967956543, 4.548610210418701",
  },
  {
    ident: "FKKR",
    type: "medium_airport",
    name: "Garoua International Airport",
    elevation_ft: "794",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-NO",
    municipality: "Garoua",
    gps_code: "FKKR",
    iata_code: "GOU",
    coordinates: "13.370100021362305, 9.33588981628418",
  },
  {
    ident: "FKKS",
    type: "small_airport",
    name: "Dschang Airport",
    elevation_ft: "4593",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-OU",
    municipality: "Dschang",
    gps_code: "FKKS",
    iata_code: "DSC",
    coordinates: "10.067000389099121, 5.449999809265137",
  },
  {
    ident: "FKKU",
    type: "medium_airport",
    name: "Bafoussam Airport",
    elevation_ft: "4347",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-OU",
    municipality: "Bafoussam",
    gps_code: "FKKU",
    iata_code: "BFX",
    coordinates: "10.354599952700001, 5.536920070650001",
  },
  {
    ident: "FKKV",
    type: "medium_airport",
    name: "Bamenda Airport",
    elevation_ft: "4065",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-NW",
    municipality: "Bamenda",
    gps_code: "FKKV",
    iata_code: "BPC",
    coordinates: "10.122599601745605, 6.039239883422852",
  },
  {
    ident: "FKKW",
    type: "small_airport",
    name: "Ebolowa Airport",
    elevation_ft: "1975",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-SU",
    municipality: "Ebolowa",
    gps_code: "FKKW",
    iata_code: "EBW",
    coordinates: "11.1850004196167, 2.875999927520752",
  },
  {
    ident: "FKKY",
    type: "medium_airport",
    name: "Yaound���� Airport",
    elevation_ft: "2464",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-CE",
    municipality: "Yaound����",
    gps_code: "FKKY",
    iata_code: "YAO",
    coordinates: "11.523500442504883, 3.8360400199890137",
  },
  {
    ident: "FKYS",
    type: "medium_airport",
    name: "Yaound���� Nsimalen International Airport",
    elevation_ft: "2278",
    continent: "AF",
    iso_country: "CM",
    iso_region: "CM-CE",
    municipality: "Yaound����",
    gps_code: "FKYS",
    iata_code: "NSI",
    coordinates: "11.553299903869629, 3.722559928894043",
  },
  {
    ident: "FLBA",
    type: "small_airport",
    name: "Mbala Airport",
    elevation_ft: "5454",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-05",
    municipality: "Mbala",
    gps_code: "FLBA",
    iata_code: "MMQ",
    coordinates: "31.33639907836914, -8.859169960021973",
  },
  {
    ident: "FLCP",
    type: "small_airport",
    name: "Chipata Airport",
    elevation_ft: "3360",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-03",
    municipality: "Chipata",
    gps_code: "FLCP",
    iata_code: "CIP",
    coordinates: "32.58720016479492, -13.558300018310547",
  },
  {
    ident: "FLE",
    type: "closed",
    name: "Fort Lee Army Airfield",
    elevation_ft: "96",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Fort Lee",
    iata_code: "FLE",
    coordinates: "-77.3448, 37.284",
  },
  {
    ident: "FLJK",
    type: "small_airport",
    name: "Jeki Airport",
    elevation_ft: "1165",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-09",
    municipality: "Lower Zambezi Natational Park",
    gps_code: "FLJK",
    iata_code: "JEK",
    coordinates: "29.6036, -15.6334",
  },
  {
    ident: "FLKE",
    type: "medium_airport",
    name: "Kasompe Airport",
    elevation_ft: "4636",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-08",
    municipality: "Chingola",
    gps_code: "FLKE",
    iata_code: "CGJ",
    coordinates: "27.893899917603, -12.572799682617",
  },
  {
    ident: "FLKL",
    type: "small_airport",
    name: "Kalabo Airport",
    elevation_ft: "3450",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-01",
    municipality: "Kalabo",
    gps_code: "FLKL",
    iata_code: "KLB",
    coordinates: "22.64539909362793, -14.998299598693848",
  },
  {
    ident: "FLKO",
    type: "small_airport",
    name: "Kaoma Airport",
    elevation_ft: "3670",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-01",
    municipality: "Kaoma",
    gps_code: "FLKO",
    iata_code: "KMZ",
    coordinates: "24.783000946044922, -14.800000190734863",
  },
  {
    ident: "FLKS",
    type: "small_airport",
    name: "Kasama Airport",
    elevation_ft: "4541",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-05",
    municipality: "Kasama",
    gps_code: "FLKS",
    iata_code: "KAA",
    coordinates: "31.13330078125, -10.216699600219727",
  },
  {
    ident: "FLKY",
    type: "small_airport",
    name: "Kasaba Bay Airport",
    elevation_ft: "2780",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-05",
    municipality: "Kasaba Bay",
    gps_code: "FLKY",
    iata_code: "ZKB",
    coordinates: "30.663000106811523, -8.524999618530273",
  },
  {
    ident: "FLLI",
    type: "medium_airport",
    name: "Harry Mwanga Nkumbula International Airport",
    elevation_ft: "3302",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-07",
    municipality: "Livingstone",
    gps_code: "FLHN",
    iata_code: "LVI",
    coordinates: "25.822701, -17.8218",
  },
  {
    ident: "FLLK",
    type: "small_airport",
    name: "Lukulu Airport",
    elevation_ft: "3480",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-01",
    municipality: "Lukulu",
    gps_code: "FLLK",
    iata_code: "LXU",
    coordinates: "23.249500274658203, -14.374799728393555",
  },
  {
    ident: "FLLS",
    type: "large_airport",
    name: "Kenneth Kaunda International Airport",
    elevation_ft: "3779",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-09",
    municipality: "Lusaka",
    gps_code: "FLKK",
    iata_code: "LUN",
    coordinates: "28.4526, -15.3308",
  },
  {
    ident: "FLMA",
    type: "small_airport",
    name: "Mansa Airport",
    elevation_ft: "4101",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-04",
    municipality: "Mansa",
    gps_code: "FLMA",
    iata_code: "MNS",
    coordinates: "28.872600555419922, -11.13700008392334",
  },
  {
    ident: "FLMF",
    type: "medium_airport",
    name: "Mfuwe Airport",
    elevation_ft: "1853",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-03",
    municipality: "Mfuwe",
    gps_code: "FLMF",
    iata_code: "MFU",
    coordinates: "31.936599731445312, -13.258899688720703",
  },
  {
    ident: "FLMG",
    type: "medium_airport",
    name: "Mongu Airport",
    elevation_ft: "3488",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-01",
    municipality: "Mongu",
    gps_code: "FLMG",
    iata_code: "MNR",
    coordinates: "23.16230010986328, -15.254500389099121",
  },
  {
    ident: "FLNA",
    type: "small_airport",
    name: "Ngoma Airport",
    elevation_ft: "3400",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-07",
    municipality: "Ngoma",
    gps_code: "FLNA",
    iata_code: "ZGM",
    coordinates: "25.933300018310547, -15.965800285339355",
  },
  {
    ident: "FLND",
    type: "medium_airport",
    name: "Simon Mwansa Kapwepwe International Airport",
    elevation_ft: "4167",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-08",
    municipality: "Ndola",
    gps_code: "FLSK",
    iata_code: "NLA",
    coordinates: "28.66489982605, -12.998100280762",
  },
  {
    ident: "FLRZ",
    type: "small_airport",
    name: "Royal Zambesi Lodge Airstrip",
    elevation_ft: "1243",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-09",
    gps_code: "FLRZ",
    iata_code: "RYL",
    coordinates: "29.3021, -15.7255",
  },
  {
    ident: "FLSN",
    type: "small_airport",
    name: "Senanga Airport",
    elevation_ft: "3347",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-01",
    municipality: "Senanga",
    gps_code: "FLSN",
    iata_code: "SXG",
    coordinates: "23.2982, -16.113",
  },
  {
    ident: "FLSO",
    type: "medium_airport",
    name: "Southdowns Airport",
    elevation_ft: "4145",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-08",
    municipality: "Kitwe",
    gps_code: "FLSO",
    iata_code: "KIW",
    coordinates: "28.149900436401367, -12.900500297546387",
  },
  {
    ident: "FLSS",
    type: "small_airport",
    name: "Sesheke Airport",
    elevation_ft: "3100",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-01",
    municipality: "Sesheke",
    gps_code: "FLSS",
    iata_code: "SJQ",
    coordinates: "24.30470085144043, -17.476299285888672",
  },
  {
    ident: "FLSW",
    type: "small_airport",
    name: "Solwesi Airport",
    elevation_ft: "4551",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-06",
    municipality: "Solwesi",
    gps_code: "FLSW",
    iata_code: "SLI",
    coordinates: "26.365100860595703, -12.173700332641602",
  },
  {
    ident: "FLT",
    type: "small_airport",
    name: "Flat Airport",
    elevation_ft: "309",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Flat",
    gps_code: "FLT",
    iata_code: "FLT",
    local_code: "FLT",
    coordinates: "-157.988998413, 62.452598571799996",
  },
  {
    ident: "FLZB",
    type: "small_airport",
    name: "Zambezi Airport",
    elevation_ft: "3538",
    continent: "AF",
    iso_country: "ZM",
    iso_region: "ZM-06",
    municipality: "Zambezi",
    gps_code: "FLZB",
    iata_code: "BBZ",
    coordinates: "23.108092, -13.537018",
  },
  {
    ident: "FM-ULI",
    type: "small_airport",
    name: "Ulithi Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "FM",
    iso_region: "FM-YAP",
    municipality: "Falalop Island",
    iata_code: "ULI",
    local_code: "TT02",
    coordinates: "139.789993, 10.0198",
  },
  {
    ident: "FMCH",
    type: "medium_airport",
    name: "Prince Said Ibrahim International Airport",
    elevation_ft: "93",
    continent: "AF",
    iso_country: "KM",
    iso_region: "KM-G",
    municipality: "Moroni",
    gps_code: "FMCH",
    iata_code: "HAH",
    coordinates: "43.2719, -11.5337",
  },
  {
    ident: "FMCI",
    type: "medium_airport",
    name: "Moh����li Bandar Es Eslam Airport",
    elevation_ft: "46",
    continent: "AF",
    iso_country: "KM",
    iso_region: "KM-M",
    gps_code: "FMCI",
    iata_code: "NWA",
    coordinates: "43.76639938354492, -12.298100471496582",
  },
  {
    ident: "FMCN",
    type: "small_airport",
    name: "Iconi Airport",
    elevation_ft: "33",
    continent: "AF",
    iso_country: "KM",
    iso_region: "KM-G",
    municipality: "Moroni",
    gps_code: "FMCN",
    iata_code: "YVA",
    coordinates: "43.2439002991, -11.710800170899999",
  },
  {
    ident: "FMCV",
    type: "medium_airport",
    name: "Ouani Airport",
    elevation_ft: "62",
    continent: "AF",
    iso_country: "KM",
    iso_region: "KM-A",
    municipality: "Ouani",
    gps_code: "FMCV",
    iata_code: "AJN",
    coordinates: "44.430301666259766, -12.131699562072754",
  },
  {
    ident: "FMCZ",
    type: "medium_airport",
    name: "Dzaoudzi Pamandzi International Airport",
    elevation_ft: "23",
    continent: "AF",
    iso_country: "YT",
    iso_region: "YT-U-A",
    municipality: "Dzaoudzi",
    gps_code: "FMCZ",
    iata_code: "DZA",
    coordinates: "45.28110122680664, -12.804699897766113",
  },
  {
    ident: "FMEE",
    type: "medium_airport",
    name: "Roland Garros Airport",
    elevation_ft: "66",
    continent: "AF",
    iso_country: "RE",
    iso_region: "RE-U-A",
    municipality: "St Denis",
    gps_code: "FMEE",
    iata_code: "RUN",
    coordinates: "55.51029968261719, -20.887100219726562",
  },
  {
    ident: "FMEP",
    type: "medium_airport",
    name: "Pierrefonds Airport",
    elevation_ft: "59",
    continent: "AF",
    iso_country: "RE",
    iso_region: "RE-U-A",
    municipality: "St Pierre",
    gps_code: "FMEP",
    iata_code: "ZSE",
    coordinates: "55.42499923706055, -21.320899963378906",
  },
  {
    ident: "FMMC",
    type: "small_airport",
    name: "Malaimbandy Airport",
    elevation_ft: "597",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Malaimbandy",
    gps_code: "FMMC",
    iata_code: "WML",
    coordinates: "45.5433726311, -20.355390292099997",
  },
  {
    ident: "FMME",
    type: "small_airport",
    name: "Antsirabe Airport",
    elevation_ft: "4997",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-T",
    municipality: "Antsirabe",
    gps_code: "FMME",
    iata_code: "ATJ",
    coordinates: "47.063713073699994, -19.8392214824",
  },
  {
    ident: "FMMG",
    type: "small_airport",
    name: "Antsalova Airport",
    elevation_ft: "551",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Antsalova",
    gps_code: "FMMG",
    iata_code: "WAQ",
    coordinates: "44.614921, -18.701273",
  },
  {
    ident: "FMMH",
    type: "small_airport",
    name: "Mahanoro Airport",
    elevation_ft: "16",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-A",
    municipality: "Mahanoro",
    gps_code: "FMMH",
    iata_code: "VVB",
    coordinates: "48.79999923706055, -19.83300018310547",
  },
  {
    ident: "FMMI",
    type: "large_airport",
    name: "Ivato Airport",
    elevation_ft: "4198",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-T",
    municipality: "Antananarivo",
    gps_code: "FMMI",
    iata_code: "TNR",
    coordinates: "47.478802, -18.7969",
  },
  {
    ident: "FMMK",
    type: "small_airport",
    name: "Ankavandra Airport",
    elevation_ft: "427",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Ankavandra",
    gps_code: "FMMK",
    iata_code: "JVA",
    coordinates: "45.273467, -18.80501",
  },
  {
    ident: "FMML",
    type: "small_airport",
    name: "Belo sur Tsiribihina Airport",
    elevation_ft: "154",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Belo sur Tsiribihina",
    gps_code: "FMML",
    iata_code: "BMD",
    coordinates: "44.541900634799994, -19.6867008209",
  },
  {
    ident: "FMMN",
    type: "medium_airport",
    name: "Miandrivazo Airport",
    elevation_ft: "203",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    gps_code: "FMMN",
    iata_code: "ZVA",
    coordinates: "45.450801849365234, -19.56279945373535",
  },
  {
    ident: "FMMO",
    type: "small_airport",
    name: "Maintirano Airport",
    elevation_ft: "95",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Maintirano",
    gps_code: "FMMO",
    iata_code: "MXT",
    coordinates: "44.033001, -18.049999",
  },
  {
    ident: "FMMQ",
    type: "small_airport",
    name: "Atsinanana Airport",
    elevation_ft: "33",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-A",
    municipality: "Ilaka",
    gps_code: "FMMQ",
    iata_code: "ILK",
    coordinates: "48.803001403808594, -19.58300018310547",
  },
  {
    ident: "FMMR",
    type: "small_airport",
    name: "Morafenobe Airport",
    elevation_ft: "748",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Morafenobe",
    gps_code: "FMMR",
    iata_code: "TVA",
    coordinates: "44.920467, -17.850083",
  },
  {
    ident: "FMMS",
    type: "medium_airport",
    name: "Sainte Marie Airport",
    elevation_ft: "7",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-A",
    gps_code: "FMMS",
    iata_code: "SMS",
    coordinates: "49.815799713134766, -17.093900680541992",
  },
  {
    ident: "FMMT",
    type: "medium_airport",
    name: "Toamasina Airport",
    elevation_ft: "22",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-A",
    gps_code: "FMMT",
    iata_code: "TMM",
    coordinates: "49.39250183105469, -18.109500885009766",
  },
  {
    ident: "FMMU",
    type: "small_airport",
    name: "Tambohorano Airport",
    elevation_ft: "23",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Tambohorano",
    gps_code: "FMMU",
    iata_code: "WTA",
    coordinates: "43.972801208496094, -17.47610092163086",
  },
  {
    ident: "FMMV",
    type: "medium_airport",
    name: "Morondava Airport",
    elevation_ft: "30",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    gps_code: "FMMV",
    iata_code: "MOQ",
    coordinates: "44.31760025024414, -20.284700393676758",
  },
  {
    ident: "FMMX",
    type: "small_airport",
    name: "Tsiroanomandidy Airport",
    elevation_ft: "2776",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-T",
    municipality: "Tsiroanomandidy",
    gps_code: "FMMX",
    iata_code: "WTS",
    coordinates: "46.054065, -18.759677",
  },
  {
    ident: "FMMY",
    type: "small_airport",
    name: "Vatomandry Airport",
    elevation_ft: "39",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-A",
    municipality: "Vatomandry",
    gps_code: "FMMY",
    iata_code: "VAT",
    coordinates: "48.95, -19.383333",
  },
  {
    ident: "FMMZ",
    type: "small_airport",
    name: "Ambatondrazaka Airport",
    elevation_ft: "2513",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-A",
    municipality: "Ambatondrazaka",
    gps_code: "FMMZ",
    iata_code: "WAM",
    coordinates: "48.442583, -17.795378",
  },
  {
    ident: "FMNA",
    type: "medium_airport",
    name: "Arrachart Airport",
    elevation_ft: "374",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-D",
    gps_code: "FMNA",
    iata_code: "DIE",
    coordinates: "49.29169845581055, -12.34939956665039",
  },
  {
    ident: "FMNB",
    type: "small_airport",
    name: "Ankaizina Airport",
    elevation_ft: "3675",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Bealanana",
    gps_code: "FMNB",
    iata_code: "WBE",
    coordinates: "48.691413, -14.544277",
  },
  {
    ident: "FMNC",
    type: "medium_airport",
    name: "Mananara Nord Airport",
    elevation_ft: "9",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-A",
    municipality: "Mananara Nord",
    gps_code: "FMNC",
    iata_code: "WMR",
    coordinates: "49.773799896240234, -16.16390037536621",
  },
  {
    ident: "FMND",
    type: "medium_airport",
    name: "Andapa Airport",
    elevation_ft: "1552",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-D",
    gps_code: "FMND",
    iata_code: "ZWA",
    coordinates: "49.620601654052734, -14.651700019836426",
  },
  {
    ident: "FMNE",
    type: "small_airport",
    name: "Ambilobe Airport",
    elevation_ft: "72",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-D",
    gps_code: "FMNE",
    iata_code: "AMB",
    coordinates: "48.987998962402344, -13.188400268554688",
  },
  {
    ident: "FMNF",
    type: "small_airport",
    name: "Avaratra Airport",
    elevation_ft: "820",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Befandriana",
    gps_code: "FMNF",
    iata_code: "WBD",
    coordinates: "48.483001708984375, -15.199999809265137",
  },
  {
    ident: "FMNG",
    type: "small_airport",
    name: "Port Berg���� Airport",
    elevation_ft: "213",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Port Berg����",
    gps_code: "FMNG",
    iata_code: "WPB",
    coordinates: "47.623587, -15.584286",
  },
  {
    ident: "FMNH",
    type: "medium_airport",
    name: "Antsirabato Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-D",
    gps_code: "FMNH",
    iata_code: "ANM",
    coordinates: "50.3202018737793, -14.99940013885498",
  },
  {
    ident: "FMNL",
    type: "medium_airport",
    name: "Analalava Airport",
    elevation_ft: "345",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    gps_code: "FMNL",
    iata_code: "HVA",
    coordinates: "47.76380157470703, -14.62969970703125",
  },
  {
    ident: "FMNM",
    type: "medium_airport",
    name: "Amborovy Airport",
    elevation_ft: "87",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    gps_code: "FMNM",
    iata_code: "MJN",
    coordinates: "46.351232528699995, -15.6668417421",
  },
  {
    ident: "FMNN",
    type: "medium_airport",
    name: "Fascene Airport",
    elevation_ft: "36",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-D",
    municipality: "Nosy Be",
    gps_code: "FMNN",
    iata_code: "NOS",
    coordinates: "48.3148002625, -13.3121004105",
  },
  {
    ident: "FMNO",
    type: "small_airport",
    name: "Soalala Airport",
    elevation_ft: "141",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Soalala",
    gps_code: "FMNO",
    iata_code: "DWB",
    coordinates: "45.358837, -16.10169",
  },
  {
    ident: "FMNP",
    type: "small_airport",
    name: "Mampikony Airport",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Mampikony",
    gps_code: "FMNP",
    iata_code: "WMP",
    coordinates: "47.644164562200004, -16.0722693402",
  },
  {
    ident: "FMNQ",
    type: "medium_airport",
    name: "Besalampy Airport",
    elevation_ft: "125",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    gps_code: "FMNQ",
    iata_code: "BPY",
    coordinates: "44.4824838638, -16.744530296500002",
  },
  {
    ident: "FMNR",
    type: "medium_airport",
    name: "Maroantsetra Airport",
    elevation_ft: "13",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-A",
    gps_code: "FMNR",
    iata_code: "WMN",
    coordinates: "49.68830108642578, -15.436699867248535",
  },
  {
    ident: "FMNS",
    type: "medium_airport",
    name: "Sambava Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-D",
    gps_code: "FMNS",
    iata_code: "SVB",
    coordinates: "50.17470169067383, -14.278599739074707",
  },
  {
    ident: "FMNT",
    type: "small_airport",
    name: "Tsaratanana Airport",
    elevation_ft: "1073",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Tsaratanana",
    gps_code: "FMNT",
    iata_code: "TTS",
    coordinates: "47.619016, -16.751064",
  },
  {
    ident: "FMNV",
    type: "medium_airport",
    name: "Vohimarina Airport",
    elevation_ft: "19",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-D",
    gps_code: "FMNV",
    iata_code: "VOH",
    coordinates: "50.00279998779297, -13.375800132751465",
  },
  {
    ident: "FMNW",
    type: "medium_airport",
    name: "Ambalabe Airport",
    elevation_ft: "92",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Antsohihy",
    gps_code: "FMNW",
    iata_code: "WAI",
    coordinates: "47.993900299072266, -14.898799896240234",
  },
  {
    ident: "FMNX",
    type: "small_airport",
    name: "Mandritsara Airport",
    elevation_ft: "1007",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Mandritsara",
    gps_code: "FMNX",
    iata_code: "WMA",
    coordinates: "48.833284, -15.833049",
  },
  {
    ident: "FMNZ",
    type: "small_airport",
    name: "Ampampamena Airport",
    elevation_ft: "49",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-D",
    gps_code: "FMNZ",
    iata_code: "IVA",
    coordinates: "48.632702, -13.484816",
  },
  {
    ident: "FMSB",
    type: "small_airport",
    name: "Antsoa Airport",
    elevation_ft: "820",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Beroroha",
    gps_code: "FMSB",
    iata_code: "WBO",
    coordinates: "45.136020183599996, -21.606983764699997",
  },
  {
    ident: "FMSC",
    type: "small_airport",
    name: "Mandabe Airport",
    elevation_ft: "951",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Mandabe",
    gps_code: "FMSC",
    iata_code: "WMD",
    coordinates: "44.9404120445, -21.0463049303",
  },
  {
    ident: "FMSD",
    type: "medium_airport",
    name: "T����lanaro Airport",
    elevation_ft: "29",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "T����lanaro",
    gps_code: "FMSD",
    iata_code: "FTU",
    coordinates: "46.95610046386719, -25.03809928894043",
  },
  {
    ident: "FMSF",
    type: "medium_airport",
    name: "Fianarantsoa Airport",
    elevation_ft: "3658",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-F",
    gps_code: "FMSF",
    iata_code: "WFI",
    coordinates: "47.111698150634766, -21.441600799560547",
  },
  {
    ident: "FMSG",
    type: "small_airport",
    name: "Farafangana Airport",
    elevation_ft: "26",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-F",
    gps_code: "FMSG",
    iata_code: "RVA",
    coordinates: "47.82059860229492, -22.805299758911133",
  },
  {
    ident: "FMSI",
    type: "small_airport",
    name: "Ihosy Airport",
    elevation_ft: "2500",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-F",
    municipality: "Ihosy",
    gps_code: "FMSI",
    iata_code: "IHO",
    coordinates: "46.1649370193, -22.404720202399997",
  },
  {
    ident: "FMSJ",
    type: "small_airport",
    name: "Manja Airport",
    elevation_ft: "787",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Manja",
    gps_code: "FMSJ",
    iata_code: "MJA",
    coordinates: "44.316509, -21.426105",
  },
  {
    ident: "FMSK",
    type: "medium_airport",
    name: "Manakara Airport",
    elevation_ft: "33",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-F",
    gps_code: "FMSK",
    iata_code: "WVK",
    coordinates: "48.02170181274414, -22.119699478149414",
  },
  {
    ident: "FMSL",
    type: "small_airport",
    name: "Bekily Airport",
    elevation_ft: "1270",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Bekily",
    gps_code: "FMSL",
    iata_code: "OVA",
    coordinates: "45.3045272827, -24.235694754699995",
  },
  {
    ident: "FMSM",
    type: "medium_airport",
    name: "Mananjary Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-F",
    gps_code: "FMSM",
    iata_code: "MNJ",
    coordinates: "48.358299255371094, -21.201799392700195",
  },
  {
    ident: "FMSN",
    type: "small_airport",
    name: "Samangoky Airport",
    elevation_ft: "89",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Tanandava",
    gps_code: "FMSN",
    iata_code: "TDV",
    coordinates: "43.733001708984375, -21.700000762939453",
  },
  {
    ident: "FMSR",
    type: "medium_airport",
    name: "Morombe Airport",
    elevation_ft: "16",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    gps_code: "FMSR",
    iata_code: "MXM",
    coordinates: "43.3754997253418, -21.7539005279541",
  },
  {
    ident: "FMST",
    type: "medium_airport",
    name: "Toliara Airport",
    elevation_ft: "29",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    gps_code: "FMST",
    iata_code: "TLE",
    coordinates: "43.72850036621094, -23.383399963378906",
  },
  {
    ident: "FMSU",
    type: "small_airport",
    name: "Vangaindrano Airport",
    elevation_ft: "45",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-F",
    municipality: "Vangaindrano",
    gps_code: "FMSU",
    iata_code: "VND",
    coordinates: "47.581701278699995, -23.350766591499998",
  },
  {
    ident: "FMSV",
    type: "small_airport",
    name: "Betioky Airport",
    elevation_ft: "919",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Betioky",
    gps_code: "FMSV",
    iata_code: "BKU",
    coordinates: "44.388999938964844, -23.732999801635742",
  },
  {
    ident: "FMSY",
    type: "small_airport",
    name: "Ampanihy Airport",
    elevation_ft: "771",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Ampanihy",
    gps_code: "FMSY",
    iata_code: "AMP",
    coordinates: "44.73419952392578, -24.69969940185547",
  },
  {
    ident: "FMSZ",
    type: "small_airport",
    name: "Ankazoabo Airport",
    elevation_ft: "1411",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Ankazoabo",
    gps_code: "FMSZ",
    iata_code: "WAK",
    coordinates: "44.5315361023, -22.2964423522",
  },
  {
    ident: "FNAM",
    type: "small_airport",
    name: "Ambriz Airport",
    elevation_ft: "144",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-BGO",
    municipality: "Ambriz",
    gps_code: "FNAM",
    iata_code: "AZZ",
    coordinates: "13.116100311279297, -7.86221981048584",
  },
  {
    ident: "FNBC",
    type: "medium_airport",
    name: "Mbanza Congo Airport",
    elevation_ft: "1860",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-ZAI",
    municipality: "Mbanza Congo",
    gps_code: "FNBC",
    iata_code: "SSY",
    coordinates: "14.246999740600586, -6.269899845123291",
  },
  {
    ident: "FNBG",
    type: "medium_airport",
    name: "Benguela Airport",
    elevation_ft: "118",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-BGU",
    municipality: "Benguela",
    gps_code: "FNBG",
    iata_code: "BUG",
    coordinates: "13.4036998749, -12.609000206",
  },
  {
    ident: "FNBL",
    type: "small_airport",
    name: "Lumbala Airport",
    elevation_ft: "3700",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-MOX",
    municipality: "Lumbala N'guimbo",
    gps_code: "FNBL",
    iata_code: "GGC",
    coordinates: "21.45083, -14.105106",
  },
  {
    ident: "FNCA",
    type: "medium_airport",
    name: "Cabinda Airport",
    elevation_ft: "66",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CAB",
    municipality: "Cabinda",
    gps_code: "FNCA",
    iata_code: "CAB",
    coordinates: "12.188400268554688, -5.59699010848999",
  },
  {
    ident: "FNCF",
    type: "small_airport",
    name: "Cafunfo Airport",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-LNO",
    municipality: "Cafunfo",
    gps_code: "FNCF",
    iata_code: "CFF",
    coordinates: "17.989700317382812, -8.783610343933105",
  },
  {
    ident: "FNCH",
    type: "small_airport",
    name: "Chitato Airport",
    elevation_ft: "2500",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-LNO",
    municipality: "Chitato",
    gps_code: "FNCH",
    iata_code: "PGI",
    coordinates: "20.80470085144043, -7.358890056610107",
  },
  {
    ident: "FNCT",
    type: "medium_airport",
    name: "Catumbela Airport",
    elevation_ft: "23",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-BGU",
    municipality: "Catumbela",
    gps_code: "FNCT",
    iata_code: "CBT",
    coordinates: "13.4869, -12.4792",
  },
  {
    ident: "FNCV",
    type: "small_airport",
    name: "Cuito Cuanavale Airport",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CCU",
    municipality: "Cuito Cuanavale",
    gps_code: "FNCV",
    iata_code: "CTI",
    coordinates: "19.156099319458008, -15.160300254821777",
  },
  {
    ident: "FNCX",
    type: "small_airport",
    name: "Camaxilo Airport",
    elevation_ft: "3957",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-LNO",
    municipality: "Camaxilo",
    gps_code: "FNCX",
    iata_code: "CXM",
    coordinates: "18.923599243164062, -8.37360954284668",
  },
  {
    ident: "FNCZ",
    type: "small_airport",
    name: "Cazombo Airport",
    elevation_ft: "3700",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-MOX",
    municipality: "Cazombo",
    gps_code: "FNCZ",
    iata_code: "CAV",
    coordinates: "22.916400909423828, -11.893099784851074",
  },
  {
    ident: "FNDU",
    type: "medium_airport",
    name: "Dundo Airport",
    elevation_ft: "2451",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-LNO",
    municipality: "Chitato",
    gps_code: "FNDU",
    iata_code: "DUE",
    coordinates: "20.818500518798828, -7.400889873504639",
  },
  {
    ident: "FNE",
    type: "small_airport",
    name: "Fane Airport",
    elevation_ft: "4500",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Fane Mission",
    gps_code: "AYFA",
    iata_code: "FNE",
    local_code: "FANE",
    coordinates: "147.085833333, -8.54927777778",
  },
  {
    ident: "FNGI",
    type: "medium_airport",
    name: "Ngjiva Pereira Airport",
    elevation_ft: "3566",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CNN",
    municipality: "Ngiva",
    gps_code: "FNGI",
    iata_code: "VPE",
    coordinates: "15.683799743700002, -17.0435009003",
  },
  {
    ident: "FNHU",
    type: "medium_airport",
    name: "Nova Lisboa Airport",
    elevation_ft: "5587",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-HUA",
    municipality: "Huambo",
    gps_code: "FNHU",
    iata_code: "NOV",
    coordinates: "15.760499954223633, -12.808899879455566",
  },
  {
    ident: "FNKU",
    type: "medium_airport",
    name: "Kuito Airport",
    elevation_ft: "5618",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-BIE",
    municipality: "Kuito",
    gps_code: "FNKU",
    iata_code: "SVP",
    coordinates: "16.947399139404, -12.404600143433",
  },
  {
    ident: "FNLK",
    type: "small_airport",
    name: "Lucapa Airport",
    elevation_ft: "3029",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-LNO",
    municipality: "Lucapa",
    gps_code: "FNLK",
    iata_code: "LBZ",
    coordinates: "20.7320861816, -8.445727348330001",
  },
  {
    ident: "FNLU",
    type: "large_airport",
    name: "Quatro de Fevereiro Airport",
    elevation_ft: "243",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-LUA",
    municipality: "Luanda",
    gps_code: "FNLU",
    iata_code: "LAD",
    coordinates: "13.2312, -8.85837",
  },
  {
    ident: "FNLZ",
    type: "small_airport",
    name: "Luzamba Airport",
    elevation_ft: "2904",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-LNO",
    municipality: "Luzamba",
    gps_code: "FNLZ",
    iata_code: "LZM",
    coordinates: "18.049299240099998, -9.11596012115",
  },
  {
    ident: "FNMA",
    type: "medium_airport",
    name: "Malanje Airport",
    elevation_ft: "3868",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-MAL",
    municipality: "Malanje",
    gps_code: "FNMA",
    iata_code: "MEG",
    coordinates: "16.312400817871094, -9.525090217590332",
  },
  {
    ident: "FNME",
    type: "medium_airport",
    name: "Menongue Airport",
    elevation_ft: "4469",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CCU",
    municipality: "Menongue",
    gps_code: "FNME",
    iata_code: "SPP",
    coordinates: "17.71980094909668, -14.657600402832031",
  },
  {
    ident: "FNMO",
    type: "medium_airport",
    name: "Namibe Airport",
    elevation_ft: "210",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-NAM",
    municipality: "Namibe",
    gps_code: "FNMO",
    iata_code: "MSZ",
    coordinates: "12.14680004119873, -15.261199951171875",
  },
  {
    ident: "FNNG",
    type: "medium_airport",
    name: "Negage Airport",
    elevation_ft: "4105",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-UIG",
    municipality: "Negage",
    gps_code: "FNNG",
    iata_code: "GXG",
    coordinates: "15.287699699401855, -7.754509925842285",
  },
  {
    ident: "FNPA",
    type: "medium_airport",
    name: "Porto Amboim Airport",
    elevation_ft: "16",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CUS",
    municipality: "Port Amboim",
    gps_code: "FNPA",
    iata_code: "PBN",
    coordinates: "13.76550006866455, -10.722000122070312",
  },
  {
    ident: "FNSA",
    type: "medium_airport",
    name: "Saurimo Airport",
    elevation_ft: "3584",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-LSU",
    municipality: "Saurimo",
    gps_code: "FNSA",
    iata_code: "VHC",
    coordinates: "20.431900024414062, -9.689069747924805",
  },
  {
    ident: "FNSO",
    type: "medium_airport",
    name: "Soyo Airport",
    elevation_ft: "15",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-ZAI",
    municipality: "Soyo",
    gps_code: "FNSO",
    iata_code: "SZA",
    coordinates: "12.371800422668457, -6.141089916229248",
  },
  {
    ident: "FNSU",
    type: "medium_airport",
    name: "Sumbe Airport",
    elevation_ft: "36",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CUS",
    municipality: "Sumbe",
    gps_code: "FNSU",
    iata_code: "NDD",
    coordinates: "13.84749984741211, -11.167900085449219",
  },
  {
    ident: "FNUA",
    type: "small_airport",
    name: "Luau Airport",
    elevation_ft: "3609",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-MOX",
    municipality: "Luau",
    gps_code: "FNUA",
    iata_code: "UAL",
    coordinates: "22.23110008239746, -10.715800285339355",
  },
  {
    ident: "FNUB",
    type: "medium_airport",
    name: "Lubango Airport",
    elevation_ft: "5778",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-HUI",
    municipality: "Lubango",
    gps_code: "FNUB",
    iata_code: "SDD",
    coordinates: "13.574999809265137, -14.924699783325195",
  },
  {
    ident: "FNUE",
    type: "medium_airport",
    name: "Luena Airport",
    elevation_ft: "4360",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-MOX",
    municipality: "Luena",
    gps_code: "FNUE",
    iata_code: "LUO",
    coordinates: "19.8976993560791, -11.768099784851074",
  },
  {
    ident: "FNUG",
    type: "medium_airport",
    name: "Uige Airport",
    elevation_ft: "2720",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-UIG",
    municipality: "Uige",
    gps_code: "FNUG",
    iata_code: "UGO",
    coordinates: "15.027799606323242, -7.60306978225708",
  },
  {
    ident: "FNWK",
    type: "small_airport",
    name: "Waco Kungo Airport",
    elevation_ft: "4324",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CUS",
    municipality: "Waco Kungo",
    gps_code: "FNWK",
    iata_code: "CEO",
    coordinates: "15.101400375366211, -11.426400184631348",
  },
  {
    ident: "FNXA",
    type: "medium_airport",
    name: "Xangongo Airport",
    elevation_ft: "3635",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-CNN",
    municipality: "Xangongo",
    gps_code: "FNXA",
    iata_code: "XGN",
    coordinates: "14.965299606323242, -16.755399703979492",
  },
  {
    ident: "FNZE",
    type: "small_airport",
    name: "N'zeto Airport",
    elevation_ft: "69",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-ZAI",
    municipality: "N'zeto",
    gps_code: "FNZE",
    iata_code: "ARZ",
    coordinates: "12.863100051879883, -7.259439945220947",
  },
  {
    ident: "FNZG",
    type: "small_airport",
    name: "Nzagi Airport",
    elevation_ft: "2431",
    continent: "AF",
    iso_country: "AO",
    iso_region: "AO-LNO",
    municipality: "Nzagi",
    gps_code: "FNZG",
    iata_code: "NZA",
    coordinates: "21.358200073200003, -7.716939926149999",
  },
  {
    ident: "FOGB",
    type: "small_airport",
    name: "Booue Airport",
    elevation_ft: "604",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-6",
    municipality: "Booue",
    gps_code: "FOGB",
    iata_code: "BGB",
    coordinates: "11.9438, -0.1075",
  },
  {
    ident: "FOGE",
    type: "small_airport",
    name: "Ndende Airport",
    elevation_ft: "417",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-4",
    municipality: "Ndende",
    gps_code: "FOGE",
    iata_code: "KDN",
    coordinates: "11.366999626159668, -2.4000000953674316",
  },
  {
    ident: "FOGF",
    type: "small_airport",
    name: "Fougamou Airport",
    elevation_ft: "263",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-4",
    municipality: "Fougamou",
    gps_code: "FOGF",
    iata_code: "FOU",
    coordinates: "10.616999626159668, -1.2829999923706055",
  },
  {
    ident: "FOGG",
    type: "small_airport",
    name: "M'Bigou Airport",
    elevation_ft: "2346",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-4",
    municipality: "M'Bigou",
    gps_code: "FOGG",
    iata_code: "MBC",
    coordinates: "11.932999610900879, -1.8830000162124634",
  },
  {
    ident: "FOGI",
    type: "small_airport",
    name: "Moabi Airport",
    elevation_ft: "787",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-5",
    municipality: "Moabi",
    gps_code: "FOGI",
    iata_code: "MGX",
    coordinates: "11, -2.433000087738037",
  },
  {
    ident: "FOGJ",
    type: "small_airport",
    name: "Ville Airport",
    elevation_ft: "164",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-3",
    municipality: "N'Djol����",
    gps_code: "FOGJ",
    iata_code: "KDJ",
    coordinates: "10.75, -0.18299999833106995",
  },
  {
    ident: "FOGK",
    type: "medium_airport",
    name: "Koulamoutou Mabimbi Airport",
    elevation_ft: "1070",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-7",
    municipality: "Koulamoutou",
    gps_code: "FOGK",
    iata_code: "KOU",
    coordinates: "12.441300392151, -1.1846100091934",
  },
  {
    ident: "FOGM",
    type: "medium_airport",
    name: "Mouilla Ville Airport",
    elevation_ft: "295",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-4",
    municipality: "Mouila",
    gps_code: "FOGM",
    iata_code: "MJL",
    coordinates: "11.056699752807617, -1.845139980316162",
  },
  {
    ident: "FOGO",
    type: "medium_airport",
    name: "Oyem Airport",
    elevation_ft: "2158",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-9",
    municipality: "Oyem",
    gps_code: "FOGO",
    iata_code: "OYE",
    coordinates: "11.581399917602539, 1.5431100130081177",
  },
  {
    ident: "FOGQ",
    type: "medium_airport",
    name: "Okondja Airport",
    elevation_ft: "1325",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-2",
    municipality: "Okondja",
    gps_code: "FOGQ",
    iata_code: "OKN",
    coordinates: "13.673100471496582, -0.6652140021324158",
  },
  {
    ident: "FOGR",
    type: "medium_airport",
    name: "Lambarene Airport",
    elevation_ft: "82",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-3",
    municipality: "Lambarene",
    gps_code: "FOGR",
    iata_code: "LBQ",
    coordinates: "10.245699882507324, -0.7043889760971069",
  },
  {
    ident: "FOGV",
    type: "small_airport",
    name: "Minvoul Airport",
    elevation_ft: "1969",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-9",
    municipality: "Minvoul",
    gps_code: "FOGV",
    iata_code: "MVX",
    coordinates: "12.133000373840332, 2.1500000953674316",
  },
  {
    ident: "FOOB",
    type: "medium_airport",
    name: "Bitam Airport",
    elevation_ft: "1969",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-9",
    municipality: "Bitam",
    gps_code: "FOOB",
    iata_code: "BMM",
    coordinates: "11.493200302124023, 2.0756399631500244",
  },
  {
    ident: "FOOD",
    type: "small_airport",
    name: "Moanda Airport",
    elevation_ft: "1877",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-2",
    municipality: "Moanda",
    gps_code: "FOOD",
    iata_code: "MFF",
    coordinates: "13.2670001984, -1.53299999237",
  },
  {
    ident: "FOOE",
    type: "small_airport",
    name: "Mekambo Airport",
    elevation_ft: "1686",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-6",
    municipality: "Mekambo",
    gps_code: "FOOE",
    iata_code: "MKB",
    coordinates: "13.932999610900879, 1.0169999599456787",
  },
  {
    ident: "FOOG",
    type: "medium_airport",
    name: "Port Gentil Airport",
    elevation_ft: "13",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-8",
    municipality: "Port Gentil",
    gps_code: "FOOG",
    iata_code: "POG",
    coordinates: "8.754380226135254, -0.7117390036582947",
  },
  {
    ident: "FOOH",
    type: "medium_airport",
    name: "Omboue Hopital Airport",
    elevation_ft: "33",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-8",
    municipality: "Omboue",
    gps_code: "FOOH",
    iata_code: "OMB",
    coordinates: "9.262689590454102, -1.5747300386428833",
  },
  {
    ident: "FOOI",
    type: "small_airport",
    name: "Tchongorove Airport",
    elevation_ft: "48",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-8",
    municipality: "Iguela",
    gps_code: "FOOI",
    iata_code: "IGE",
    coordinates: "9.3092, -1.9223",
  },
  {
    ident: "FOOK",
    type: "medium_airport",
    name: "Makokou Airport",
    elevation_ft: "1726",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-6",
    municipality: "Makokou",
    gps_code: "FOOK",
    iata_code: "MKU",
    coordinates: "12.890899658203125, 0.5792109966278076",
  },
  {
    ident: "FOOL",
    type: "medium_airport",
    name: "Libreville Leon M'ba International Airport",
    elevation_ft: "39",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-1",
    municipality: "Libreville",
    gps_code: "FOOL",
    iata_code: "LBV",
    coordinates: "9.412280082699999, 0.458600014448",
  },
  {
    ident: "FOOM",
    type: "small_airport",
    name: "Mitzic Airport",
    elevation_ft: "1913",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-9",
    municipality: "Mitzic",
    gps_code: "FOOM",
    iata_code: "MZC",
    coordinates: "11.550000190734863, 0.7829999923706055",
  },
  {
    ident: "FOON",
    type: "medium_airport",
    name: "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
    elevation_ft: "1450",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-2",
    municipality: "Franceville",
    gps_code: "FOON",
    iata_code: "MVB",
    coordinates: "13.437999725341797, -1.6561599969863892",
  },
  {
    ident: "FOOR",
    type: "small_airport",
    name: "Lastourville Airport",
    elevation_ft: "1585",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-7",
    municipality: "Lastourville",
    gps_code: "FOOR",
    iata_code: "LTL",
    coordinates: "12.748600006103516, -0.8266670107841492",
  },
  {
    ident: "FOOS",
    type: "small_airport",
    name: "Sette Cama Airport",
    elevation_ft: "10",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-8",
    municipality: "Sette Cama",
    gps_code: "FOOS",
    iata_code: "ZKM",
    coordinates: "9.767000198364258, -2.5329999923706055",
  },
  {
    ident: "FOOT",
    type: "small_airport",
    name: "Tchibanga Airport",
    elevation_ft: "269",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-5",
    municipality: "Tchibanga",
    gps_code: "FOOT",
    iata_code: "TCH",
    coordinates: "11.017000198364258, -2.8499999046325684",
  },
  {
    ident: "FOOY",
    type: "small_airport",
    name: "Mayumba Airport",
    elevation_ft: "13",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-5",
    municipality: "Mayumba",
    gps_code: "FOOY",
    iata_code: "MYB",
    coordinates: "10.674076080322266, -3.4584197998046875",
  },
  {
    ident: "FOY",
    type: "small_airport",
    name: "Foya Airport",
    elevation_ft: "1480",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-LO",
    municipality: "Foya",
    iata_code: "FOY",
    coordinates: "-10.2269, 8.3513",
  },
  {
    ident: "FPPR",
    type: "medium_airport",
    name: "Principe Airport",
    elevation_ft: "591",
    continent: "AF",
    iso_country: "ST",
    iso_region: "ST-P",
    gps_code: "FPPR",
    iata_code: "PCP",
    coordinates: "7.411739826202393, 1.6629400253295898",
  },
  {
    ident: "FPST",
    type: "medium_airport",
    name: "S����o Tom���� International Airport",
    elevation_ft: "33",
    continent: "AF",
    iso_country: "ST",
    iso_region: "ST-S",
    municipality: "S����o Tom����",
    gps_code: "FPST",
    iata_code: "TMS",
    coordinates: "6.7121500968933105, 0.3781749904155731",
  },
  {
    ident: "FQAG",
    type: "small_airport",
    name: "Angoche Airport",
    elevation_ft: "121",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-N",
    municipality: "Angoche",
    gps_code: "FQAG",
    iata_code: "ANO",
    coordinates: "39.94521713256836, -16.181869506835938",
  },
  {
    ident: "FQBR",
    type: "medium_airport",
    name: "Beira Airport",
    elevation_ft: "33",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-S",
    municipality: "Beira",
    gps_code: "FQBR",
    iata_code: "BEW",
    coordinates: "34.90760040283203, -19.79640007019043",
  },
  {
    ident: "FQCB",
    type: "small_airport",
    name: "Cuamba Airport",
    elevation_ft: "1919",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-A",
    municipality: "Cuamba",
    gps_code: "FQCB",
    iata_code: "FXO",
    coordinates: "36.529999, -14.815",
  },
  {
    ident: "FQCH",
    type: "medium_airport",
    name: "Chimoio Airport",
    elevation_ft: "2287",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-B",
    municipality: "Chimoio",
    gps_code: "FQCH",
    iata_code: "VPY",
    coordinates: "33.42900085449219, -19.15130043029785",
  },
  {
    ident: "FQIA",
    type: "small_airport",
    name: "Inhaca Airport",
    elevation_ft: "10",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-L",
    municipality: "Inhaca",
    gps_code: "FQIA",
    iata_code: "IHC",
    coordinates: "32.929351806599996, -25.9971446991",
  },
  {
    ident: "FQIN",
    type: "medium_airport",
    name: "Inhambane Airport",
    elevation_ft: "30",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-I",
    municipality: "Inhambabe",
    gps_code: "FQIN",
    iata_code: "INH",
    coordinates: "35.40850067138672, -23.876399993896484",
  },
  {
    ident: "FQLC",
    type: "medium_airport",
    name: "Lichinga Airport",
    elevation_ft: "4505",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-A",
    municipality: "Lichinga",
    gps_code: "FQLC",
    iata_code: "VXC",
    coordinates: "35.2663, -13.274",
  },
  {
    ident: "FQLU",
    type: "small_airport",
    name: "Lumbo Airport",
    elevation_ft: "33",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-N",
    municipality: "Lumbo",
    gps_code: "FQLU",
    iata_code: "LFB",
    coordinates: "40.671699523899996, -15.0331001282",
  },
  {
    ident: "FQMA",
    type: "large_airport",
    name: "Maputo Airport",
    elevation_ft: "145",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-MPM",
    municipality: "Maputo",
    gps_code: "FQMA",
    iata_code: "MPM",
    coordinates: "32.572601, -25.920799",
  },
  {
    ident: "FQMD",
    type: "medium_airport",
    name: "Mueda Airport",
    elevation_ft: "2789",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-P",
    municipality: "Mueda",
    gps_code: "FQMD",
    iata_code: "MUD",
    coordinates: "39.5630989074707, -11.672900199890137",
  },
  {
    ident: "FQMP",
    type: "medium_airport",
    name: "Moc����mboa da Praia Airport",
    elevation_ft: "89",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-P",
    municipality: "Moc����mboa da Praia",
    gps_code: "FQMP",
    iata_code: "MZB",
    coordinates: "40.35490036010742, -11.361800193786621",
  },
  {
    ident: "FQNC",
    type: "small_airport",
    name: "Nacala Airport",
    elevation_ft: "410",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-N",
    municipality: "Nacala",
    gps_code: "FQNC",
    iata_code: "MNC",
    coordinates: "40.71220016479492, -14.488200187683105",
  },
  {
    ident: "FQNP",
    type: "medium_airport",
    name: "Nampula Airport",
    elevation_ft: "1444",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-N",
    municipality: "Nampula",
    gps_code: "FQNP",
    iata_code: "APL",
    coordinates: "39.28179931640625, -15.105600357055664",
  },
  {
    ident: "FQPB",
    type: "medium_airport",
    name: "Pemba Airport",
    elevation_ft: "331",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-P",
    municipality: "Pemba / Porto Amelia",
    gps_code: "FQPB",
    iata_code: "POL",
    coordinates: "40.52401351928711, -12.991762161254883",
  },
  {
    ident: "FQPO",
    type: "small_airport",
    name: "Ponta do Ouro Airport",
    elevation_ft: "92",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-L",
    municipality: "Ponta do Ouro",
    gps_code: "FQPO",
    iata_code: "PDD",
    coordinates: "32.837707519531, -26.828552246094",
  },
  {
    ident: "FQQL",
    type: "medium_airport",
    name: "Quelimane Airport",
    elevation_ft: "36",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-Q",
    municipality: "Quelimane",
    gps_code: "FQQL",
    iata_code: "UEL",
    coordinates: "36.86909866333008, -17.855499267578125",
  },
  {
    ident: "FQTT",
    type: "medium_airport",
    name: "Chingozi Airport",
    elevation_ft: "525",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-T",
    municipality: "Tete",
    gps_code: "FQTT",
    iata_code: "TET",
    coordinates: "33.640201568603516, -16.104799270629883",
  },
  {
    ident: "FQVL",
    type: "medium_airport",
    name: "Vilankulo Airport",
    elevation_ft: "46",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-I",
    municipality: "Vilanculo",
    gps_code: "FQVL",
    iata_code: "VNX",
    coordinates: "35.31330108642578, -22.018400192260742",
  },
  {
    ident: "FQXA",
    type: "small_airport",
    name: "Xai-Xai Airport",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-G",
    municipality: "Xai-Xai",
    gps_code: "FQXA",
    iata_code: "VJB",
    coordinates: "33.62739944458008, -25.037799835205078",
  },
  {
    ident: "FR-JCA",
    type: "heliport",
    name: "Croisette Heliport",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Cannes",
    iata_code: "JCA",
    coordinates: "7.037360191345215, 43.5359992980957",
  },
  {
    ident: "FSDR",
    type: "small_airport",
    name: "Desroches Airport",
    elevation_ft: "10",
    continent: "AF",
    iso_country: "SC",
    iso_region: "SC-15",
    municipality: "Desroches Island",
    gps_code: "FSDR",
    iata_code: "DES",
    coordinates: "53.6558, -5.6967",
  },
  {
    ident: "FSIA",
    type: "large_airport",
    name: "Seychelles International Airport",
    elevation_ft: "10",
    continent: "AF",
    iso_country: "SC",
    iso_region: "SC-20",
    municipality: "Mahe Island",
    gps_code: "FSIA",
    iata_code: "SEZ",
    coordinates: "55.521801, -4.67434",
  },
  {
    ident: "FSL",
    type: "small_airport",
    name: "Fossil Downs Airport",
    elevation_ft: "414",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Fossil Downs Station",
    iata_code: "FSL",
    coordinates: "125.7873, -18.1321",
  },
  {
    ident: "FSN",
    type: "closed",
    name: "Haley Army Airfield",
    elevation_ft: "685",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Fort Sheridan",
    iata_code: "FSN",
    coordinates: "-87.817, 42.221",
  },
  {
    ident: "FSPP",
    type: "medium_airport",
    name: "Praslin Airport",
    elevation_ft: "10",
    continent: "AF",
    iso_country: "SC",
    iso_region: "SC-14",
    municipality: "Praslin Island",
    gps_code: "FSPP",
    iata_code: "PRI",
    coordinates: "55.69139862060547, -4.3192901611328125",
  },
  {
    ident: "FSSB",
    type: "small_airport",
    name: "Bird Island Airport",
    elevation_ft: "6",
    continent: "AF",
    iso_country: "SC",
    iso_region: "SC-15",
    municipality: "Bird Island",
    gps_code: "FSSB",
    iata_code: "BDI",
    coordinates: "55.205299, -3.72472",
  },
  {
    ident: "FSSD",
    type: "small_airport",
    name: "Denis Island Airport",
    elevation_ft: "10",
    continent: "AF",
    iso_country: "SC",
    iso_region: "SC-15",
    municipality: "Denis Island",
    gps_code: "FSSD",
    iata_code: "DEI",
    coordinates: "55.666901, -3.80222",
  },
  {
    ident: "FSSF",
    type: "small_airport",
    name: "Fr����gate Island Airport",
    elevation_ft: "610",
    continent: "AF",
    iso_country: "SC",
    iso_region: "SC-15",
    municipality: "Fr����gate Island",
    gps_code: "FSSF",
    iata_code: "FRK",
    coordinates: "55.950001, -4.583",
  },
  {
    ident: "FTTA",
    type: "small_airport",
    name: "Sarh Airport",
    elevation_ft: "1198",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-MC",
    municipality: "Sarh",
    gps_code: "FTTA",
    iata_code: "SRH",
    coordinates: "18.374399, 9.14444",
  },
  {
    ident: "FTTB",
    type: "small_airport",
    name: "Bongor Airport",
    elevation_ft: "1076",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-MK",
    municipality: "Bongor",
    gps_code: "FTTB",
    iata_code: "OGR",
    coordinates: "15.381099700927734, 10.288100242614746",
  },
  {
    ident: "FTTC",
    type: "medium_airport",
    name: "Abeche Airport",
    elevation_ft: "1788",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-OD",
    gps_code: "FTTC",
    iata_code: "AEH",
    coordinates: "20.84429931640625, 13.847000122070312",
  },
  {
    ident: "FTTD",
    type: "medium_airport",
    name: "Moundou Airport",
    elevation_ft: "1407",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-LO",
    gps_code: "FTTD",
    iata_code: "MQQ",
    coordinates: "16.071399688720703, 8.624409675598145",
  },
  {
    ident: "FTTH",
    type: "small_airport",
    name: "Lai Airport",
    elevation_ft: "1171",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-TA",
    municipality: "Lai",
    gps_code: "FTTH",
    iata_code: "LTC",
    coordinates: "16.3125, 9.3979",
  },
  {
    ident: "FTTI",
    type: "small_airport",
    name: "Ati Airport",
    elevation_ft: "1089",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-BA",
    municipality: "Ati",
    gps_code: "FTTI",
    iata_code: "ATV",
    coordinates: "18.31329917907715, 13.238900184631348",
  },
  {
    ident: "FTTJ",
    type: "large_airport",
    name: "N'Djamena International Airport",
    elevation_ft: "968",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-CB",
    municipality: "N'Djamena",
    gps_code: "FTTJ",
    iata_code: "NDJ",
    coordinates: "15.034, 12.1337",
  },
  {
    ident: "FTTK",
    type: "small_airport",
    name: "Bokoro Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-CB",
    municipality: "Bokoro",
    gps_code: "FTTK",
    iata_code: "BKR",
    coordinates: "17.066999435424805, 12.383000373840332",
  },
  {
    ident: "FTTL",
    type: "small_airport",
    name: "Bol Airport",
    elevation_ft: "955",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-LC",
    municipality: "Bol",
    gps_code: "FTTL",
    iata_code: "OTC",
    coordinates: "14.739399909973145, 13.443300247192383",
  },
  {
    ident: "FTTM",
    type: "small_airport",
    name: "Mongo Airport",
    elevation_ft: "1414",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-GR",
    municipality: "Mongo",
    gps_code: "FTTM",
    iata_code: "MVO",
    coordinates: "18.674999237060547, 12.166999816894531",
  },
  {
    ident: "FTTN",
    type: "small_airport",
    name: "Am Timan Airport",
    elevation_ft: "1420",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-SA",
    municipality: "Am Timan",
    gps_code: "FTTN",
    iata_code: "AMC",
    coordinates: "20.274000167799997, 11.0340003967",
  },
  {
    ident: "FTTP",
    type: "small_airport",
    name: "Pala Airport",
    elevation_ft: "1532",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-MK",
    municipality: "Pala",
    gps_code: "FTTP",
    iata_code: "PLF",
    coordinates: "14.925000190734863, 9.378060340881348",
  },
  {
    ident: "FTTS",
    type: "small_airport",
    name: "Bousso Airport",
    elevation_ft: "1099",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-CB",
    municipality: "Bousso",
    gps_code: "FTTS",
    iata_code: "OUT",
    coordinates: "16.716999053955078, 10.482999801635742",
  },
  {
    ident: "FTTU",
    type: "small_airport",
    name: "Mao Airport",
    elevation_ft: "1072",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-KA",
    municipality: "Mao",
    gps_code: "FTTU",
    iata_code: "AMO",
    coordinates: "15.314399719238281, 14.145600318908691",
  },
  {
    ident: "FTTY",
    type: "medium_airport",
    name: "Faya Largeau Airport",
    elevation_ft: "771",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-BET",
    gps_code: "FTTY",
    iata_code: "FYT",
    coordinates: "19.111099243164062, 17.91710090637207",
  },
  {
    ident: "FUB",
    type: "small_airport",
    name: "Fulleborn Airport",
    elevation_ft: "36",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Fulleborn",
    iata_code: "FUB",
    local_code: "FUL",
    coordinates: "150.6264, -6.1518",
  },
  {
    ident: "FVBM",
    type: "small_airport",
    name: "Bumi Airport",
    elevation_ft: "1650",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MW",
    municipality: "Bumi",
    gps_code: "FVBM",
    iata_code: "BZH",
    coordinates: "28.3500003815, -16.8169994354",
  },
  {
    ident: "FVBU",
    type: "medium_airport",
    name: "Joshua Mqabuko Nkomo International Airport",
    elevation_ft: "4359",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-BU",
    municipality: "Bulawayo",
    gps_code: "FVJN",
    iata_code: "BUQ",
    coordinates: "28.617901, -20.017401",
  },
  {
    ident: "FVCH",
    type: "small_airport",
    name: "Chipinge Airport",
    elevation_ft: "3720",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MA",
    municipality: "Chipinge",
    gps_code: "FVCH",
    iata_code: "CHJ",
    coordinates: "32.628299713134766, -20.20669937133789",
  },
  {
    ident: "FVCZ",
    type: "medium_airport",
    name: "Buffalo Range Airport",
    elevation_ft: "1421",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MV",
    municipality: "Chiredzi",
    gps_code: "FVCZ",
    iata_code: "BFO",
    coordinates: "31.5786, -21.008101",
  },
  {
    ident: "FVFA",
    type: "medium_airport",
    name: "Victoria Falls International Airport",
    elevation_ft: "3490",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MN",
    municipality: "Victoria Falls",
    gps_code: "FVFA",
    iata_code: "VFA",
    coordinates: "25.839000701904297, -18.09589958190918",
  },
  {
    ident: "FVHA",
    type: "large_airport",
    name: "Robert Gabriel Mugabe International Airport",
    elevation_ft: "4887",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-HA",
    municipality: "Harare",
    gps_code: "FVRG",
    iata_code: "HRE",
    coordinates: "31.0928, -17.931801",
  },
  {
    ident: "FVKB",
    type: "medium_airport",
    name: "Kariba International Airport",
    elevation_ft: "1706",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MW",
    municipality: "Kariba",
    gps_code: "FVKB",
    iata_code: "KAB",
    coordinates: "28.885000228881836, -16.519800186157227",
  },
  {
    ident: "FVMH",
    type: "small_airport",
    name: "Mahenye Airport",
    elevation_ft: "930",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MA",
    municipality: "Gonarezhou National Park",
    gps_code: "FVMH",
    iata_code: "MJW",
    coordinates: "32.3336, -21.231",
  },
  {
    ident: "FVMU",
    type: "small_airport",
    name: "Mutare Airport",
    elevation_ft: "3410",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MA",
    municipality: "Mutare",
    gps_code: "FVMU",
    iata_code: "UTA",
    coordinates: "32.627201080322, -18.997499465942",
  },
  {
    ident: "FVMV",
    type: "medium_airport",
    name: "Masvingo International Airport",
    elevation_ft: "3595",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MV",
    municipality: "Masvingo",
    gps_code: "FVMV",
    iata_code: "MVZ",
    coordinates: "30.859100341796875, -20.055299758911133",
  },
  {
    ident: "FVTL",
    type: "medium_airport",
    name: "Thornhill Air Base",
    elevation_ft: "4680",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MI",
    municipality: "Gweru",
    gps_code: "FVTL",
    iata_code: "GWE",
    coordinates: "29.861900329589844, -19.436399459838867",
  },
  {
    ident: "FVWN",
    type: "medium_airport",
    name: "Hwange National Park Airport",
    elevation_ft: "3543",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MN",
    municipality: "Hwange",
    gps_code: "FVWN",
    iata_code: "HWN",
    coordinates: "27.020999908447266, -18.629899978637695",
  },
  {
    ident: "FVWT",
    type: "small_airport",
    name: "Hwange (Town) Airport",
    elevation_ft: "2500",
    continent: "AF",
    iso_country: "ZW",
    iso_region: "ZW-MN",
    municipality: "Hwange",
    gps_code: "FVWT",
    iata_code: "WKI",
    coordinates: "26.519791, -18.362967",
  },
  {
    ident: "FWCD",
    type: "small_airport",
    name: "Chelinda Malawi Airport",
    elevation_ft: "7759",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-RU",
    gps_code: "FWCD",
    iata_code: "CEH",
    coordinates: "33.799999237099996, -10.5500001907",
  },
  {
    ident: "FWCL",
    type: "medium_airport",
    name: "Chileka International Airport",
    elevation_ft: "2555",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-BL",
    municipality: "Blantyre",
    gps_code: "FWCL",
    iata_code: "BLZ",
    coordinates: "34.9739990234375, -15.679100036621094",
  },
  {
    ident: "FWCM",
    type: "small_airport",
    name: "Club Makokola Airport",
    elevation_ft: "1587",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-MG",
    municipality: "Club Makokola",
    gps_code: "FWCM",
    iata_code: "CMK",
    coordinates: "35.13249969482422, -14.306900024414062",
  },
  {
    ident: "FWDW",
    type: "medium_airport",
    name: "Dwangwa Airport",
    elevation_ft: "1605",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-NK",
    municipality: "Dwangwa",
    gps_code: "FWDW",
    iata_code: "DWA",
    coordinates: "34.131901, -12.5183",
  },
  {
    ident: "FWKA",
    type: "medium_airport",
    name: "Karonga Airport",
    elevation_ft: "1765",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-KR",
    municipality: "Karonga",
    gps_code: "FWKA",
    iata_code: "KGJ",
    coordinates: "33.893001556396484, -9.953570365905762",
  },
  {
    ident: "FWKG",
    type: "small_airport",
    name: "Kasungu Airport",
    elevation_ft: "3470",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-KS",
    municipality: "Kasungu",
    gps_code: "FWKG",
    iata_code: "KBQ",
    coordinates: "33.46860122680664, -13.014599800109863",
  },
  {
    ident: "FWKI",
    type: "medium_airport",
    name: "Lilongwe International Airport",
    elevation_ft: "4035",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-LI",
    municipality: "Lilongwe",
    gps_code: "FWKI",
    iata_code: "LLW",
    coordinates: "33.78099823, -13.7894001007",
  },
  {
    ident: "FWLK",
    type: "small_airport",
    name: "Likoma Island Airport",
    elevation_ft: "1600",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-LK",
    municipality: "Likoma Island",
    gps_code: "FWLK",
    iata_code: "LIX",
    coordinates: "34.737222, -12.075833",
  },
  {
    ident: "FWMG",
    type: "medium_airport",
    name: "Mangochi Airport",
    elevation_ft: "1580",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-MG",
    municipality: "Mangochi",
    gps_code: "FWMG",
    iata_code: "MAI",
    coordinates: "35.266998291015625, -14.482999801635742",
  },
  {
    ident: "FWMY",
    type: "small_airport",
    name: "Monkey Bay Airport",
    elevation_ft: "1580",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-MG",
    municipality: "Monkey Bay",
    gps_code: "FWMY",
    iata_code: "MYZ",
    coordinates: "34.9197006226, -14.083600044299999",
  },
  {
    ident: "FWSM",
    type: "small_airport",
    name: "Salima Airport",
    elevation_ft: "1688",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-SA",
    municipality: "Salima",
    gps_code: "FWSM",
    iata_code: "LMB",
    coordinates: "34.5841884613, -13.755892702500002",
  },
  {
    ident: "FWUU",
    type: "medium_airport",
    name: "Mzuzu Airport",
    elevation_ft: "4115",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-MZ",
    municipality: "Mzuzu",
    gps_code: "FWUU",
    iata_code: "ZZU",
    coordinates: "34.01179885864258, -11.444700241088867",
  },
  {
    ident: "FXLK",
    type: "small_airport",
    name: "Lebakeng Airport",
    elevation_ft: "6000",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-H",
    municipality: "Lebakeng",
    gps_code: "FXLK",
    iata_code: "LEF",
    coordinates: "28.65559959411621, -29.89080047607422",
  },
  {
    ident: "FXLR",
    type: "small_airport",
    name: "Leribe Airport",
    elevation_ft: "5350",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-C",
    municipality: "Leribe",
    gps_code: "FXLR",
    iata_code: "LRB",
    coordinates: "28.052799224853516, -28.855600357055664",
  },
  {
    ident: "FXLS",
    type: "small_airport",
    name: "Lesobeng Airport",
    elevation_ft: "7130",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-F",
    municipality: "Lesobeng",
    gps_code: "FXLS",
    iata_code: "LES",
    coordinates: "28.316699981689453, -29.782899856567383",
  },
  {
    ident: "FXMF",
    type: "small_airport",
    name: "Mafeteng Airport",
    elevation_ft: "5350",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-E",
    municipality: "Mafeteng",
    gps_code: "FXMF",
    iata_code: "MFC",
    coordinates: "27.243600845336914, -29.80109977722168",
  },
  {
    ident: "FXMK",
    type: "small_airport",
    name: "Mokhotlong Airport",
    elevation_ft: "7200",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-J",
    municipality: "Mokhotlong",
    gps_code: "FXMK",
    iata_code: "MKH",
    coordinates: "29.072799682617188, -29.28179931640625",
  },
  {
    ident: "FXMM",
    type: "medium_airport",
    name: "Moshoeshoe I International Airport",
    elevation_ft: "5348",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-A",
    municipality: "Maseru",
    gps_code: "FXMM",
    iata_code: "MSU",
    coordinates: "27.552499771118164, -29.462299346923828",
  },
  {
    ident: "FXNK",
    type: "small_airport",
    name: "Nkaus Airport",
    elevation_ft: "5621",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-F",
    municipality: "Nkaus",
    gps_code: "FXNK",
    iata_code: "NKU",
    coordinates: "28.196899414062, -30.021699905396",
  },
  {
    ident: "FXPG",
    type: "small_airport",
    name: "Pelaneng Airport",
    elevation_ft: "7200",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-C",
    municipality: "Pelaneng",
    gps_code: "FXPG",
    iata_code: "PEL",
    coordinates: "28.505300521850586, -29.1205997467041",
  },
  {
    ident: "FXQG",
    type: "small_airport",
    name: "Quthing Airport",
    elevation_ft: "5350",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-G",
    municipality: "Quthing",
    gps_code: "FXQG",
    iata_code: "UTG",
    coordinates: "27.693300247192383, -30.407499313354492",
  },
  {
    ident: "FXQN",
    type: "small_airport",
    name: "Qacha's Nek Airport",
    elevation_ft: "6100",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-H",
    municipality: "Qacha's Nek",
    gps_code: "FXQN",
    iata_code: "UNE",
    coordinates: "28.671899795532227, -30.1117000579834",
  },
  {
    ident: "FXSH",
    type: "small_airport",
    name: "Sehonghong Airport",
    elevation_ft: "6500",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-K",
    municipality: "Sehonghong",
    gps_code: "FXSH",
    iata_code: "SHK",
    coordinates: "28.76889991760254, -29.730899810791016",
  },
  {
    ident: "FXSK",
    type: "small_airport",
    name: "Sekakes Airport",
    elevation_ft: "5700",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-H",
    municipality: "Sekakes",
    gps_code: "FXSK",
    iata_code: "SKQ",
    coordinates: "28.37030029296875, -30.03890037536621",
  },
  {
    ident: "FXSM",
    type: "small_airport",
    name: "Semonkong Airport",
    elevation_ft: "7200",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-A",
    municipality: "Semonkong",
    gps_code: "FXSM",
    iata_code: "SOK",
    coordinates: "28.059999465942383, -29.838600158691406",
  },
  {
    ident: "FXSS",
    type: "small_airport",
    name: "Seshutes Airport",
    elevation_ft: "7000",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-C",
    municipality: "Seshutes",
    gps_code: "FXSS",
    iata_code: "SHZ",
    coordinates: "28.55229949951172, -29.26759910583496",
  },
  {
    ident: "FXTA",
    type: "small_airport",
    name: "Thaba-Tseka Airport",
    elevation_ft: "7500",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-K",
    municipality: "Thaba-Tseka",
    gps_code: "FXTA",
    iata_code: "THB",
    coordinates: "28.615800857543945, -29.52280044555664",
  },
  {
    ident: "FXTK",
    type: "small_airport",
    name: "Tlokoeng Airport",
    elevation_ft: "7000",
    continent: "AF",
    iso_country: "LS",
    iso_region: "LS-J",
    municipality: "Tlokoeng",
    gps_code: "FXTK",
    iata_code: "TKO",
    coordinates: "28.882999420166016, -29.232999801635742",
  },
  {
    ident: "FYAA",
    type: "small_airport",
    name: "Ai-Ais Airport",
    elevation_ft: "2000",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KU",
    municipality: "Ai-Ais",
    gps_code: "FYAA",
    iata_code: "AIW",
    coordinates: "17.5966, -27.995",
  },
  {
    ident: "FYAR",
    type: "medium_airport",
    name: "Arandis Airport",
    elevation_ft: "1905",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-ER",
    municipality: "Arandis",
    gps_code: "FYAR",
    iata_code: "ADI",
    coordinates: "14.979999542236328, -22.462200164794922",
  },
  {
    ident: "FYGB",
    type: "small_airport",
    name: "Gobabis Airport",
    elevation_ft: "4731",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-OH",
    municipality: "Gobabis",
    gps_code: "FYGB",
    iata_code: "GOG",
    coordinates: "18.973100662231445, -22.5044002532959",
  },
  {
    ident: "FYGF",
    type: "medium_airport",
    name: "Grootfontein Airport",
    elevation_ft: "4636",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-OD",
    municipality: "Grootfontein",
    gps_code: "FYGF",
    iata_code: "GFY",
    coordinates: "18.122699737548828, -19.60219955444336",
  },
  {
    ident: "FYHI",
    type: "small_airport",
    name: "Halali Airport",
    elevation_ft: "3639",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-OT",
    municipality: "Halali",
    gps_code: "FYHI",
    iata_code: "HAL",
    coordinates: "16.4585, -19.0285",
  },
  {
    ident: "FYKB",
    type: "small_airport",
    name: "Karasburg Airport",
    elevation_ft: "3275",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KA",
    municipality: "Karasburg",
    gps_code: "FYKB",
    iata_code: "KAS",
    coordinates: "18.7385, -28.0297",
  },
  {
    ident: "FYKM",
    type: "small_airport",
    name: "Katima Mulilo Airport",
    elevation_ft: "3144",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-CA",
    municipality: "Mpacha",
    gps_code: "FYKM",
    iata_code: "MPA",
    coordinates: "24.176701, -17.634399",
  },
  {
    ident: "FYKT",
    type: "medium_airport",
    name: "Keetmanshoop Airport",
    elevation_ft: "3506",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KA",
    municipality: "Keetmanshoop",
    gps_code: "FYKT",
    iata_code: "KMP",
    coordinates: "18.111400604248047, -26.5398006439209",
  },
  {
    ident: "FYLS",
    type: "small_airport",
    name: "Lianshulu Airport",
    elevation_ft: "3143",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-CA",
    municipality: "Lianshulu Lodge",
    gps_code: "FYLS",
    iata_code: "LHU",
    coordinates: "23.393299102800004, -18.116699218799997",
  },
  {
    ident: "FYLZ",
    type: "medium_airport",
    name: "Luderitz Airport",
    elevation_ft: "457",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KA",
    municipality: "Luderitz",
    gps_code: "FYLZ",
    iata_code: "LUD",
    coordinates: "15.242899894714355, -26.687400817871094",
  },
  {
    ident: "FYME",
    type: "small_airport",
    name: "Mount Etjo Airport",
    elevation_ft: "5000",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-OD",
    municipality: "Mount Etjo Safari Lodge",
    gps_code: "FYME",
    iata_code: "MJO",
    coordinates: "16.4528007507, -21.0233001709",
  },
  {
    ident: "FYMG",
    type: "small_airport",
    name: "Midgard Airport",
    elevation_ft: "5125",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-OD",
    municipality: "Midgard",
    gps_code: "FYMG",
    iata_code: "MQG",
    coordinates: "17.37, -22.0106",
  },
  {
    ident: "FYMO",
    type: "small_airport",
    name: "Mokuti Lodge Airport",
    elevation_ft: "3665",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-OT",
    municipality: "Mokuti Lodge",
    gps_code: "FYMO",
    iata_code: "OKU",
    coordinates: "17.05940055847168, -18.81279945373535",
  },
  {
    ident: "FYNA",
    type: "small_airport",
    name: "Namutoni Airport",
    elevation_ft: "3579",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-OT",
    municipality: "Namutoni",
    gps_code: "FYNA",
    iata_code: "NNI",
    coordinates: "16.9272, -18.8064",
  },
  {
    ident: "FYOA",
    type: "medium_airport",
    name: "Ondangwa Airport",
    elevation_ft: "3599",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-ON",
    municipality: "Ondangwa",
    gps_code: "FYOA",
    iata_code: "OND",
    coordinates: "15.9526, -17.878201",
  },
  {
    ident: "FYOE",
    type: "small_airport",
    name: "Omega Airport",
    elevation_ft: "3346",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KE",
    municipality: "Omega",
    gps_code: "FYOE",
    iata_code: "OMG",
    coordinates: "22.189699, -18.0303",
  },
  {
    ident: "FYOG",
    type: "medium_airport",
    name: "Oranjemund Airport",
    elevation_ft: "14",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KA",
    municipality: "Oranjemund",
    gps_code: "FYOG",
    iata_code: "OMD",
    coordinates: "16.446699, -28.5847",
  },
  {
    ident: "FYOO",
    type: "small_airport",
    name: "Okaukuejo Airport",
    elevation_ft: "3911",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KU",
    municipality: "Okaukuejo",
    gps_code: "FYOO",
    iata_code: "OKF",
    coordinates: "15.91189956665039, -19.149200439453125",
  },
  {
    ident: "FYOP",
    type: "small_airport",
    name: "Opuwa Airport",
    elevation_ft: "3770",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KU",
    municipality: "Opuwa",
    gps_code: "FYOP",
    iata_code: "OPW",
    coordinates: "13.85047, -18.061424",
  },
  {
    ident: "FYOS",
    type: "small_airport",
    name: "Oshakati Airport",
    elevation_ft: "3616",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-ON",
    municipality: "Oshakati",
    gps_code: "FYOS",
    iata_code: "OHI",
    coordinates: "15.6993, -17.797",
  },
  {
    ident: "FYOW",
    type: "small_airport",
    name: "Otjiwarongo Airport",
    elevation_ft: "4859",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-OH",
    municipality: "Otjiwarongo",
    gps_code: "FYOW",
    iata_code: "OTJ",
    coordinates: "16.660800933838, -20.434700012207",
  },
  {
    ident: "FYRU",
    type: "medium_airport",
    name: "Rundu Airport",
    elevation_ft: "3627",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KE",
    municipality: "Rundu",
    gps_code: "FYRU",
    iata_code: "NDU",
    coordinates: "19.719400405884, -17.956499099731",
  },
  {
    ident: "FYSA",
    type: "small_airport",
    name: "Skorpion Mine Airport",
    elevation_ft: "1870",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KA",
    municipality: "Rosh Pinah",
    gps_code: "FYSA",
    iata_code: "RHN",
    coordinates: "16.6478004456, -27.8763999939",
  },
  {
    ident: "FYSM",
    type: "small_airport",
    name: "Swakopmund Airport",
    elevation_ft: "207",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-ER",
    municipality: "Swakopmund",
    gps_code: "FYSM",
    iata_code: "SWP",
    coordinates: "14.568099975586, -22.66189956665",
  },
  {
    ident: "FYSS",
    type: "small_airport",
    name: "Sesriem Airstrip",
    elevation_ft: "2454",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-HA",
    gps_code: "FYSS",
    iata_code: "SZM",
    coordinates: "15.746700286865, -24.512800216675",
  },
  {
    ident: "FYTE",
    type: "small_airport",
    name: "Terrace Bay Airport",
    elevation_ft: "32",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KU",
    municipality: "Terrace Bay",
    gps_code: "FYTE",
    iata_code: "TCY",
    coordinates: "13.0249, -19.9713",
  },
  {
    ident: "FYTM",
    type: "medium_airport",
    name: "Tsumeb Airport",
    elevation_ft: "4353",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-OT",
    municipality: "Tsumeb",
    gps_code: "FYTM",
    iata_code: "TSB",
    coordinates: "17.732500076294, -19.26189994812",
  },
  {
    ident: "FYWB",
    type: "medium_airport",
    name: "Walvis Bay Airport",
    elevation_ft: "299",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-ER",
    municipality: "Walvis Bay",
    gps_code: "FYWB",
    iata_code: "WVB",
    coordinates: "14.6453, -22.9799",
  },
  {
    ident: "FYWE",
    type: "medium_airport",
    name: "Eros Airport",
    elevation_ft: "5575",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KH",
    municipality: "Windhoek",
    gps_code: "FYWE",
    iata_code: "ERS",
    coordinates: "17.080400466918945, -22.612199783325195",
  },
  {
    ident: "FYWH",
    type: "large_airport",
    name: "Hosea Kutako International Airport",
    elevation_ft: "5640",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KH",
    municipality: "Windhoek",
    gps_code: "FYWH",
    iata_code: "WDH",
    coordinates: "17.4709, -22.4799",
  },
  {
    ident: "FZAA",
    type: "large_airport",
    name: "Ndjili International Airport",
    elevation_ft: "1027",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KN",
    municipality: "Kinshasa",
    gps_code: "FZAA",
    iata_code: "FIH",
    coordinates: "15.4446, -4.38575",
  },
  {
    ident: "FZAB",
    type: "medium_airport",
    name: "Ndolo Airport",
    elevation_ft: "915",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KN",
    municipality: "N'dolo",
    gps_code: "FZAB",
    iata_code: "NLO",
    coordinates: "15.327500343323, -4.32666015625",
  },
  {
    ident: "FZAG",
    type: "small_airport",
    name: "Muanda Airport",
    elevation_ft: "89",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BC",
    gps_code: "FZAG",
    iata_code: "MNB",
    coordinates: "12.351799964904785, -5.9308600425720215",
  },
  {
    ident: "FZAJ",
    type: "small_airport",
    name: "Boma Airport",
    elevation_ft: "26",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BC",
    municipality: "Boma",
    gps_code: "FZAJ",
    iata_code: "BOA",
    coordinates: "13.064000129699707, -5.854000091552734",
  },
  {
    ident: "FZAL",
    type: "small_airport",
    name: "Luozi Airport",
    elevation_ft: "722",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BC",
    municipality: "Luozi",
    gps_code: "FZAL",
    iata_code: "LZI",
    coordinates: "14.133000373840332, -4.949999809265137",
  },
  {
    ident: "FZAM",
    type: "small_airport",
    name: "Tshimpi Airport",
    elevation_ft: "1115",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BC",
    municipality: "Matadi",
    gps_code: "FZAM",
    iata_code: "MAT",
    coordinates: "13.440400123596191, -5.799610137939453",
  },
  {
    ident: "FZAR",
    type: "small_airport",
    name: "N'Kolo-Fuma Airport",
    elevation_ft: "1476",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BC",
    municipality: "N'Kolo-Fuma",
    gps_code: "FZAR",
    iata_code: "NKL",
    coordinates: "14.8169, -5.421",
  },
  {
    ident: "FZBA",
    type: "small_airport",
    name: "Inongo Airport",
    elevation_ft: "1040",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BN",
    municipality: "Inongo",
    gps_code: "FZBA",
    iata_code: "INO",
    coordinates: "18.28580093383789, -1.947219967842102",
  },
  {
    ident: "FZBI",
    type: "small_airport",
    name: "Nioki Airport",
    elevation_ft: "1043",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BN",
    municipality: "Nioki",
    gps_code: "FZBI",
    iata_code: "NIO",
    coordinates: "17.68470001220703, -2.7174999713897705",
  },
  {
    ident: "FZBO",
    type: "medium_airport",
    name: "Bandundu Airport",
    elevation_ft: "1063",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BN",
    gps_code: "FZBO",
    iata_code: "FDU",
    coordinates: "17.38170051574707, -3.3113200664520264",
  },
  {
    ident: "FZBT",
    type: "small_airport",
    name: "Basango Mboliasa Airport",
    elevation_ft: "1013",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BN",
    municipality: "Kiri",
    gps_code: "FZBT",
    iata_code: "KRZ",
    coordinates: "19.02400016784668, -1.434999942779541",
  },
  {
    ident: "FZCA",
    type: "medium_airport",
    name: "Kikwit Airport",
    elevation_ft: "1572",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BN",
    gps_code: "FZCA",
    iata_code: "KKW",
    coordinates: "18.785600662231445, -5.035769939422607",
  },
  {
    ident: "FZCB",
    type: "small_airport",
    name: "Idiofa Airport",
    elevation_ft: "2299",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BN",
    municipality: "Idiofa",
    gps_code: "FZCB",
    iata_code: "IDF",
    coordinates: "19.600000381469727, -5",
  },
  {
    ident: "FZCE",
    type: "small_airport",
    name: "Lusanga Airport",
    elevation_ft: "1365",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BN",
    municipality: "Lusanga",
    gps_code: "FZCE",
    iata_code: "LUS",
    coordinates: "18.716999053955078, -4.800000190734863",
  },
  {
    ident: "FZCV",
    type: "small_airport",
    name: "Masi Manimba Airport",
    elevation_ft: "1952",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-BN",
    municipality: "Masi Manimba",
    gps_code: "FZCV",
    iata_code: "MSM",
    coordinates: "17.850000381469727, -4.7829999923706055",
  },
  {
    ident: "FZEA",
    type: "medium_airport",
    name: "Mbandaka Airport",
    elevation_ft: "1040",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    municipality: "Mbandaka",
    gps_code: "FZEA",
    iata_code: "MDK",
    coordinates: "18.2887001038, 0.0226000007242",
  },
  {
    ident: "FZEN",
    type: "small_airport",
    name: "Basankusu Airport",
    elevation_ft: "1217",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    municipality: "Basankusu",
    gps_code: "FZEN",
    iata_code: "BSU",
    coordinates: "19.7889, 1.22472",
  },
  {
    ident: "FZFA",
    type: "small_airport",
    name: "Libenge Airport",
    elevation_ft: "1125",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    municipality: "Libenge",
    gps_code: "FZFA",
    iata_code: "LIE",
    coordinates: "18.632999420166016, 3.632999897003174",
  },
  {
    ident: "FZFD",
    type: "medium_airport",
    name: "Gbadolite Airport",
    elevation_ft: "1509",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    gps_code: "FZFD",
    iata_code: "BDT",
    coordinates: "20.975299835205078, 4.253210067749023",
  },
  {
    ident: "FZFK",
    type: "medium_airport",
    name: "Gemena Airport",
    elevation_ft: "1378",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    municipality: "Gemena",
    gps_code: "FZFK",
    iata_code: "GMA",
    coordinates: "19.771299362199997, 3.2353699207299997",
  },
  {
    ident: "FZFP",
    type: "small_airport",
    name: "Kotakoli Airport",
    elevation_ft: "1801",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    gps_code: "FZFP",
    iata_code: "KLI",
    coordinates: "21.65089988708496, 4.157639980316162",
  },
  {
    ident: "FZFU",
    type: "small_airport",
    name: "Bumbar Airport",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    municipality: "Bumbar",
    gps_code: "FZFU",
    iata_code: "BMB",
    coordinates: "22.481701, 2.18278",
  },
  {
    ident: "FZGA",
    type: "medium_airport",
    name: "Lisala Airport",
    elevation_ft: "1509",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    gps_code: "FZGA",
    iata_code: "LIQ",
    coordinates: "21.496901, 2.17066",
  },
  {
    ident: "FZGN",
    type: "small_airport",
    name: "Boende Airport",
    elevation_ft: "1168",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    municipality: "Boende",
    gps_code: "FZGN",
    iata_code: "BNB",
    coordinates: "20.850000381469727, -0.21699999272823334",
  },
  {
    ident: "FZGV",
    type: "small_airport",
    name: "Ikela Airport",
    elevation_ft: "1283",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-EQ",
    municipality: "Ikela",
    gps_code: "FZGV",
    iata_code: "IKL",
    coordinates: "23.372501, -1.048109",
  },
  {
    ident: "FZIC",
    type: "medium_airport",
    name: "Bangoka International Airport",
    elevation_ft: "1417",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-OR",
    municipality: "Kisangani",
    gps_code: "FZIC",
    iata_code: "FKI",
    coordinates: "25.3379993439, 0.481638997793",
  },
  {
    ident: "FZIR",
    type: "small_airport",
    name: "Yangambi Airport",
    elevation_ft: "1378",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-OR",
    municipality: "Yangambi",
    gps_code: "FZIR",
    iata_code: "YAN",
    coordinates: "24.466999053955078, 0.7829999923706055",
  },
  {
    ident: "FZJH",
    type: "medium_airport",
    name: "Matari Airport",
    elevation_ft: "2438",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-OR",
    gps_code: "FZJH",
    iata_code: "IRP",
    coordinates: "27.588300704956055, 2.8276100158691406",
  },
  {
    ident: "FZKA",
    type: "medium_airport",
    name: "Bunia Airport",
    elevation_ft: "4045",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-OR",
    gps_code: "FZKA",
    iata_code: "BUX",
    coordinates: "30.220800399780273, 1.5657199621200562",
  },
  {
    ident: "FZKJ",
    type: "medium_airport",
    name: "Buta Zega Airport",
    elevation_ft: "1378",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-OR",
    gps_code: "FZKJ",
    iata_code: "BZU",
    coordinates: "24.793699264526367, 2.818350076675415",
  },
  {
    ident: "FZMA",
    type: "medium_airport",
    name: "Bukavu Kavumu Airport",
    elevation_ft: "5643",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-SK",
    gps_code: "FZMA",
    iata_code: "BKY",
    coordinates: "28.808799743652344, -2.3089799880981445",
  },
  {
    ident: "FZMB",
    type: "small_airport",
    name: "Rughenda Airfield",
    elevation_ft: "5757",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-NK",
    municipality: "Butembo",
    gps_code: "FZMB",
    iata_code: "RUE",
    coordinates: "29.312992, 0.117142",
  },
  {
    ident: "FZNA",
    type: "medium_airport",
    name: "Goma International Airport",
    elevation_ft: "5089",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-NK",
    municipality: "Goma",
    gps_code: "FZNA",
    iata_code: "GOM",
    coordinates: "29.238500595092773, -1.6708099842071533",
  },
  {
    ident: "FZNP",
    type: "small_airport",
    name: "Beni Airport",
    elevation_ft: "3517",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-NK",
    municipality: "Beni",
    gps_code: "FZNP",
    iata_code: "BNC",
    coordinates: "29.4739, 0.575",
  },
  {
    ident: "FZOA",
    type: "medium_airport",
    name: "Kindu Airport",
    elevation_ft: "1630",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-MA",
    municipality: "Kindu",
    gps_code: "FZOA",
    iata_code: "KND",
    coordinates: "25.915399551399997, -2.91917991638",
  },
  {
    ident: "FZOD",
    type: "small_airport",
    name: "Kinkungwa Airport",
    elevation_ft: "1808",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-MA",
    municipality: "Kalima",
    gps_code: "FZOD",
    iata_code: "KLY",
    coordinates: "26.733999252319336, -2.578000068664551",
  },
  {
    ident: "FZOP",
    type: "small_airport",
    name: "Punia Airport",
    elevation_ft: "1742",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-MA",
    municipality: "Punia",
    gps_code: "FZOP",
    iata_code: "PUN",
    coordinates: "26.33300018310547, -1.3669999837875366",
  },
  {
    ident: "FZQA",
    type: "medium_airport",
    name: "Lubumbashi International Airport",
    elevation_ft: "4295",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Lubumbashi",
    gps_code: "FZQA",
    iata_code: "FBM",
    coordinates: "27.5308990479, -11.5913000107",
  },
  {
    ident: "FZQC",
    type: "small_airport",
    name: "Pweto Airport",
    elevation_ft: "3425",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Pweto",
    gps_code: "FZQC",
    iata_code: "PWO",
    coordinates: "28.882999420166016, -8.467000007629395",
  },
  {
    ident: "FZQG",
    type: "small_airport",
    name: "Kasenga Airport",
    elevation_ft: "3146",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Kasenga",
    gps_code: "FZQG",
    iata_code: "KEC",
    coordinates: "28.632999420166016, -10.350000381469727",
  },
  {
    ident: "FZQM",
    type: "medium_airport",
    name: "Kolwezi Airport",
    elevation_ft: "5007",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    gps_code: "FZQM",
    iata_code: "KWZ",
    coordinates: "25.505699157714844, -10.765899658203125",
  },
  {
    ident: "FZRA",
    type: "small_airport",
    name: "Manono Airport",
    elevation_ft: "2077",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Manono",
    gps_code: "FZRA",
    iata_code: "MNO",
    coordinates: "27.394399642944336, -7.2888898849487305",
  },
  {
    ident: "FZRB",
    type: "small_airport",
    name: "Moba Airport",
    elevation_ft: "2953",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Moba",
    gps_code: "FZRB",
    iata_code: "BDV",
    coordinates: "29.783000946044922, -7.066999912261963",
  },
  {
    ident: "FZRF",
    type: "medium_airport",
    name: "Kalemie Airport",
    elevation_ft: "2569",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    gps_code: "FZRF",
    iata_code: "FMI",
    coordinates: "29.25, -5.8755598068237305",
  },
  {
    ident: "FZRM",
    type: "small_airport",
    name: "Kabalo Airport",
    elevation_ft: "1840",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Kabalo",
    gps_code: "FZRM",
    iata_code: "KBO",
    coordinates: "26.91699981689453, -6.083000183105469",
  },
  {
    ident: "FZRQ",
    type: "small_airport",
    name: "Kongolo Airport",
    elevation_ft: "1850",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Kongolo",
    gps_code: "FZRQ",
    iata_code: "KOO",
    coordinates: "26.99, -5.39444",
  },
  {
    ident: "FZSA",
    type: "medium_airport",
    name: "Kamina Base Airport",
    elevation_ft: "3543",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    gps_code: "FZSA",
    iata_code: "KMN",
    coordinates: "25.252899169921875, -8.642020225524902",
  },
  {
    ident: "FZSK",
    type: "small_airport",
    name: "Kapanga Airport",
    elevation_ft: "3025",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Kapanga",
    gps_code: "FZSK",
    iata_code: "KAP",
    coordinates: "22.58300018310547, -8.350000381469727",
  },
  {
    ident: "FZTK",
    type: "small_airport",
    name: "Kaniama Airport",
    elevation_ft: "2772",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Kaniama",
    gps_code: "FZTK",
    iata_code: "KNM",
    coordinates: "24.149999618530273, -7.583000183105469",
  },
  {
    ident: "FZUA",
    type: "medium_airport",
    name: "Kananga Airport",
    elevation_ft: "2139",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KW",
    municipality: "Kananga",
    gps_code: "FZUA",
    iata_code: "KGA",
    coordinates: "22.4692001343, -5.90005016327",
  },
  {
    ident: "FZUG",
    type: "small_airport",
    name: "Luiza Airport",
    elevation_ft: "2890",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KW",
    municipality: "Luiza",
    gps_code: "FZUG",
    iata_code: "LZA",
    coordinates: "22.399999618530273, -7.183000087738037",
  },
  {
    ident: "FZUK",
    type: "small_airport",
    name: "Tshikapa Airport",
    elevation_ft: "1595",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KW",
    municipality: "Tshikapa",
    gps_code: "FZUK",
    iata_code: "TSH",
    coordinates: "20.794701, -6.43833",
  },
  {
    ident: "FZVA",
    type: "small_airport",
    name: "Lodja Airport",
    elevation_ft: "1647",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KE",
    municipality: "Lodja",
    gps_code: "FZVA",
    iata_code: "LJA",
    coordinates: "23.450000762939453, -3.4170000553131104",
  },
  {
    ident: "FZVI",
    type: "small_airport",
    name: "Lusambo Airport",
    elevation_ft: "1407",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KE",
    municipality: "Lusambo",
    gps_code: "FZVI",
    iata_code: "LBO",
    coordinates: "23.378299713134766, -4.961669921875",
  },
  {
    ident: "FZVM",
    type: "small_airport",
    name: "Mweka Airport",
    elevation_ft: "1968",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KW",
    municipality: "Mweka",
    gps_code: "FZVM",
    iata_code: "MEW",
    coordinates: "21.549999237060547, -4.849999904632568",
  },
  {
    ident: "FZVR",
    type: "small_airport",
    name: "Basongo Airport",
    elevation_ft: "1640",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KW",
    municipality: "Basongo",
    gps_code: "FZVR",
    iata_code: "BAN",
    coordinates: "20.414891, -4.315802",
  },
  {
    ident: "FZVS",
    type: "small_airport",
    name: "Ilebo Airport",
    elevation_ft: "1450",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KW",
    municipality: "Ilebo",
    gps_code: "FZVS",
    iata_code: "PFR",
    coordinates: "20.590124, -4.329919",
  },
  {
    ident: "FZWA",
    type: "medium_airport",
    name: "Mbuji Mayi Airport",
    elevation_ft: "2221",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KE",
    municipality: "Mbuji Mayi",
    gps_code: "FZWA",
    iata_code: "MJM",
    coordinates: "23.569000244099996, -6.121240139010001",
  },
  {
    ident: "FZWC",
    type: "small_airport",
    name: "Gandajika Airport",
    elevation_ft: "2618",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KE",
    municipality: "Gandajika",
    gps_code: "FZWC",
    iata_code: "GDJ",
    coordinates: "23.950001, -6.733",
  },
  {
    ident: "FZWT",
    type: "small_airport",
    name: "Tunta Airport",
    elevation_ft: "2766",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KE",
    municipality: "Kabinda",
    gps_code: "FZWT",
    iata_code: "KBN",
    coordinates: "24.4829998016, -6.132999897",
  },
  {
    ident: "GA-AKE",
    type: "small_airport",
    name: "Akieni Airport",
    elevation_ft: "1660",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-2",
    municipality: "Akieni",
    gps_code: "FOGA",
    iata_code: "AKE",
    coordinates: "13.9035997391, -1.13967001438",
  },
  {
    ident: "GA-GAX",
    type: "small_airport",
    name: "Gamba Airport",
    elevation_ft: "30",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-8",
    municipality: "Gamba",
    gps_code: "FOGX",
    iata_code: "GAX",
    coordinates: "10.047222, -2.785278",
  },
  {
    ident: "GAB",
    type: "small_airport",
    name: "Gabbs Airport",
    elevation_ft: "4700",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Gabbs",
    gps_code: "KGAB",
    iata_code: "GAB",
    local_code: "GAB",
    coordinates: "-117.958999634, 38.924098968500005",
  },
  {
    ident: "GABS",
    type: "large_airport",
    name: "Modibo Keita International Airport",
    elevation_ft: "1247",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-2",
    municipality: "Bamako",
    gps_code: "GABS",
    iata_code: "BKO",
    coordinates: "-7.94994, 12.5335",
  },
  {
    ident: "GAGM",
    type: "small_airport",
    name: "Goundam Airport",
    elevation_ft: "866",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-6",
    municipality: "Goundam",
    gps_code: "GAGM",
    iata_code: "GUD",
    coordinates: "-3.599720001220703, 16.361400604248047",
  },
  {
    ident: "GAGO",
    type: "medium_airport",
    name: "Gao Airport",
    elevation_ft: "870",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-7",
    gps_code: "GAGO",
    iata_code: "GAQ",
    coordinates: "-0.005456000100821257, 16.24839973449707",
  },
  {
    ident: "GAKA",
    type: "small_airport",
    name: "Kenieba Airport",
    elevation_ft: "449",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-1",
    municipality: "Kenieba",
    gps_code: "GAKA",
    iata_code: "KNZ",
    coordinates: "-11.25, 12.833000183105469",
  },
  {
    ident: "GAKO",
    type: "small_airport",
    name: "Koutiala Airport",
    elevation_ft: "1240",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-3",
    municipality: "Koutiala",
    gps_code: "GAKO",
    iata_code: "KTX",
    coordinates: "-5.4670000076293945, 12.383000373840332",
  },
  {
    ident: "GAKY",
    type: "medium_airport",
    name: "Kayes Dag Dag Airport",
    elevation_ft: "164",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-1",
    gps_code: "GAKY",
    iata_code: "KYS",
    coordinates: "-11.404399871826172, 14.481200218200684",
  },
  {
    ident: "GAMB",
    type: "medium_airport",
    name: "Mopti Airport",
    elevation_ft: "906",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-5",
    gps_code: "GAMB",
    iata_code: "MZI",
    coordinates: "-4.0795598030099995, 14.5128002167",
  },
  {
    ident: "GANK",
    type: "small_airport",
    name: "Nara Airport",
    elevation_ft: "889",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-2",
    municipality: "Nara",
    gps_code: "GANK",
    iata_code: "NRM",
    coordinates: "-7.267000198364258, 15.217000007629395",
  },
  {
    ident: "GANR",
    type: "small_airport",
    name: "Nioro du Sahel Airport",
    elevation_ft: "778",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-1",
    municipality: "Nioro du Sahel",
    gps_code: "GANR",
    iata_code: "NIX",
    coordinates: "-9.576109886169434, 15.238100051879883",
  },
  {
    ident: "GASK",
    type: "small_airport",
    name: "Sikasso Airport",
    elevation_ft: "1378",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-3",
    municipality: "Sikasso",
    gps_code: "GASK",
    iata_code: "KSS",
    coordinates: "-5.699999809265137, 11.333000183105469",
  },
  {
    ident: "GATB",
    type: "medium_airport",
    name: "Timbuktu Airport",
    elevation_ft: "863",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-7",
    municipality: "Timbuktu",
    gps_code: "GATB",
    iata_code: "TOM",
    coordinates: "-3.007580041885376, 16.730499267578125",
  },
  {
    ident: "GAYE",
    type: "small_airport",
    name: "Y����liman���� Airport",
    elevation_ft: "325",
    continent: "AF",
    iso_country: "ML",
    iso_region: "ML-1",
    municipality: "Y����liman����",
    gps_code: "GAYE",
    iata_code: "EYL",
    coordinates: "-10.567000389099121, 15.133000373840332",
  },
  {
    ident: "GAZ",
    type: "small_airport",
    name: "Guasopa Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Woodlark (Muyua) Island",
    gps_code: "AYGJ",
    iata_code: "GAZ",
    local_code: "GPA",
    coordinates: "152.944352, -9.225918",
  },
  {
    ident: "GB-0070",
    type: "closed",
    name: "Hoy/Longhope Airfield",
    elevation_ft: "30",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Orkney Isle",
    iata_code: "HOY",
    coordinates: "-3.216076, 58.791779",
  },
  {
    ident: "GB-DOC",
    type: "small_airport",
    name: "Dornoch Airfield",
    elevation_ft: "1",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Dornoch",
    gps_code: "EGZJ",
    iata_code: "DOC",
    coordinates: "-4.023, 57.868999",
  },
  {
    ident: "GB-FLH",
    type: "small_airport",
    name: "Flotta Isle Airport",
    elevation_ft: "50",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Flotta Isle",
    gps_code: "EGZR",
    iata_code: "FLH",
    coordinates: "-3.1427800655365, 58.825801849365",
  },
  {
    ident: "GB-FOA",
    type: "small_airport",
    name: "Foula Airfield",
    elevation_ft: "150",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Foula",
    gps_code: "EGFO",
    iata_code: "FOA",
    coordinates: "-2.053202, 60.121725",
  },
  {
    ident: "GB-OUK",
    type: "medium_airport",
    name: "Outer Skerries Airport",
    elevation_ft: "76",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Grunay Island",
    gps_code: "EGOU",
    iata_code: "OUK",
    coordinates: "-0.75, 60.4252",
  },
  {
    ident: "GB-PSV",
    type: "small_airport",
    name: "Papa Stour Airport",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-SCT",
    municipality: "Papa Stour Island",
    gps_code: "EGSZ",
    iata_code: "PSV",
    coordinates: "-1.69306, 60.321701",
  },
  {
    ident: "GBM",
    type: "small_airport",
    name: "Garbaharey Airport",
    elevation_ft: "755",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-GE",
    municipality: "Garbaharey",
    iata_code: "GBM",
    coordinates: "42.21309, 3.32294",
  },
  {
    ident: "GBYD",
    type: "medium_airport",
    name: "Banjul International Airport",
    elevation_ft: "95",
    continent: "AF",
    iso_country: "GM",
    iso_region: "GM-W",
    municipality: "Banjul",
    gps_code: "GBYD",
    iata_code: "BJL",
    coordinates: "-16.65220069885254, 13.338000297546387",
  },
  {
    ident: "GCFV",
    type: "medium_airport",
    name: "Fuerteventura Airport",
    elevation_ft: "85",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CN",
    municipality: "Fuerteventura Island",
    gps_code: "GCFV",
    iata_code: "FUE",
    coordinates: "-13.863800048828125, 28.452699661254883",
  },
  {
    ident: "GCGM",
    type: "small_airport",
    name: "La Gomera Airport",
    elevation_ft: "716",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CN",
    municipality: "Alajero, La Gomera Island",
    gps_code: "GCGM",
    iata_code: "GMZ",
    coordinates: "-17.214599609375, 28.029600143432617",
  },
  {
    ident: "GCHI",
    type: "medium_airport",
    name: "Hierro Airport",
    elevation_ft: "103",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CN",
    municipality: "El Hierro Island",
    gps_code: "GCHI",
    iata_code: "VDE",
    coordinates: "-17.887100219726562, 27.814800262451172",
  },
  {
    ident: "GCLA",
    type: "large_airport",
    name: "La Palma Airport",
    elevation_ft: "107",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CN",
    municipality: "Sta Cruz de la Palma, La Palma Island",
    gps_code: "GCLA",
    iata_code: "SPC",
    coordinates: "-17.7556, 28.626499",
  },
  {
    ident: "GCLP",
    type: "large_airport",
    name: "Gran Canaria Airport",
    elevation_ft: "78",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CN",
    municipality: "Gran Canaria Island",
    gps_code: "GCLP",
    iata_code: "LPA",
    coordinates: "-15.38659954071045, 27.931900024414062",
  },
  {
    ident: "GCRR",
    type: "medium_airport",
    name: "Lanzarote Airport",
    elevation_ft: "46",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CN",
    municipality: "Lanzarote Island",
    gps_code: "GCRR",
    iata_code: "ACE",
    coordinates: "-13.6052, 28.945499",
  },
  {
    ident: "GCTS",
    type: "large_airport",
    name: "Tenerife South Airport",
    elevation_ft: "209",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CN",
    municipality: "Tenerife Island",
    gps_code: "GCTS",
    iata_code: "TFS",
    coordinates: "-16.5725002289, 28.044500351",
  },
  {
    ident: "GCV",
    type: "small_airport",
    name: "Gravatai Airport",
    elevation_ft: "25",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Gravatai",
    iata_code: "GCV",
    coordinates: "-50.98505, -29.9494",
  },
  {
    ident: "GCXO",
    type: "large_airport",
    name: "Tenerife Norte Airport",
    elevation_ft: "2076",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CN",
    municipality: "Tenerife Island",
    gps_code: "GCXO",
    iata_code: "TFN",
    coordinates: "-16.3414993286, 28.4827003479",
  },
  {
    ident: "GDA",
    type: "closed",
    name: "Gounda Airport",
    elevation_ft: "1378",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-BB",
    municipality: "Gounda",
    iata_code: "GDA",
    coordinates: "21.185, 9.316703",
  },
  {
    ident: "GECE",
    type: "heliport",
    name: "Ceuta Heliport",
    elevation_ft: "8",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CE",
    municipality: "Ceuta",
    gps_code: "GECE",
    iata_code: "JCU",
    coordinates: "-5.306389808654785, 35.892799377441406",
  },
  {
    ident: "GEML",
    type: "medium_airport",
    name: "Melilla Airport",
    elevation_ft: "156",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-ML",
    municipality: "Melilla",
    gps_code: "GEML",
    iata_code: "MLN",
    coordinates: "-2.9562599659, 35.279800415",
  },
  {
    ident: "GEW",
    type: "small_airport",
    name: "Gewoia Airport",
    elevation_ft: "340",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Gewoia",
    gps_code: "AYGC",
    iata_code: "GEW",
    local_code: "GEW",
    coordinates: "148.4949, -9.2263",
  },
  {
    ident: "GFBN",
    type: "medium_airport",
    name: "Sherbro International Airport",
    elevation_ft: "14",
    continent: "AF",
    iso_country: "SL",
    iso_region: "SL-S",
    municipality: "Bonthe",
    gps_code: "GFBN",
    iata_code: "BTE",
    coordinates: "-12.518899917602539, 7.5324201583862305",
  },
  {
    ident: "GFBO",
    type: "medium_airport",
    name: "Bo Airport",
    elevation_ft: "328",
    continent: "AF",
    iso_country: "SL",
    iso_region: "SL-S",
    municipality: "Bo",
    gps_code: "GFBO",
    iata_code: "KBS",
    coordinates: "-11.76099967956543, 7.944399833679199",
  },
  {
    ident: "GFD",
    type: "small_airport",
    name: "Pope Field",
    elevation_ft: "895",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Greenfield",
    gps_code: "KGFD",
    iata_code: "GFD",
    local_code: "GFD",
    coordinates: "-85.7360992432, 39.790298461899994",
  },
  {
    ident: "GFGK",
    type: "small_airport",
    name: "Gbangbatok Airport",
    elevation_ft: "75",
    continent: "AF",
    iso_country: "SL",
    iso_region: "SL-S",
    municipality: "Gbangbatok",
    gps_code: "GFGK",
    iata_code: "GBK",
    coordinates: "-12.383000373840332, 7.767000198364258",
  },
  {
    ident: "GFHA",
    type: "small_airport",
    name: "Hastings Airport",
    elevation_ft: "60",
    continent: "AF",
    iso_country: "SL",
    iso_region: "SL-W",
    municipality: "Freetown",
    gps_code: "GFHA",
    iata_code: "HGS",
    coordinates: "-13.12909984588623, 8.397130012512207",
  },
  {
    ident: "GFKB",
    type: "small_airport",
    name: "Kabala Airport",
    elevation_ft: "1012",
    continent: "AF",
    iso_country: "SL",
    iso_region: "SL-N",
    municipality: "Kabala",
    gps_code: "GFKB",
    iata_code: "KBA",
    coordinates: "-11.5155601501, 9.638322913429999",
  },
  {
    ident: "GFKE",
    type: "medium_airport",
    name: "Kenema Airport",
    elevation_ft: "485",
    continent: "AF",
    iso_country: "SL",
    iso_region: "SL-E",
    municipality: "Kenema",
    gps_code: "GFKE",
    iata_code: "KEN",
    coordinates: "-11.176600456237793, 7.891290187835693",
  },
  {
    ident: "GFLL",
    type: "large_airport",
    name: "Lungi International Airport",
    elevation_ft: "84",
    continent: "AF",
    iso_country: "SL",
    iso_region: "SL-N",
    municipality: "Freetown",
    gps_code: "GFLL",
    iata_code: "FNA",
    coordinates: "-13.1955, 8.61644",
  },
  {
    ident: "GFYE",
    type: "medium_airport",
    name: "Yengema Airport",
    elevation_ft: "1300",
    continent: "AF",
    iso_country: "SL",
    iso_region: "SL-E",
    municipality: "Yengema",
    gps_code: "GFYE",
    iata_code: "WYE",
    coordinates: "-11.04539966583252, 8.610469818115234",
  },
  {
    ident: "GGBU",
    type: "small_airport",
    name: "Bubaque Airport",
    continent: "AF",
    iso_country: "GW",
    iso_region: "GW-BL",
    municipality: "Bubaque",
    gps_code: "GGBU",
    iata_code: "BQE",
    coordinates: "-15.838079452514648, 11.297355651855469",
  },
  {
    ident: "GGOV",
    type: "medium_airport",
    name: "Osvaldo Vieira International Airport",
    elevation_ft: "129",
    continent: "AF",
    iso_country: "GW",
    iso_region: "GW-BS",
    municipality: "Bissau",
    gps_code: "GGOV",
    iata_code: "OXB",
    coordinates: "-15.65369987487793, 11.894800186157227",
  },
  {
    ident: "GHE",
    type: "small_airport",
    name: "Garachin���� Airport",
    elevation_ft: "42",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-5",
    municipality: "Garachin����",
    iata_code: "GHE",
    coordinates: "-78.3673, 8.0644",
  },
  {
    ident: "GL-JGR",
    type: "heliport",
    name: "Groennedal Heliport",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Groennedal",
    iata_code: "JGR",
    coordinates: "-48.099998, 61.233299",
  },
  {
    ident: "GL-QCU",
    type: "heliport",
    name: "Akunaq Heliport",
    elevation_ft: "59",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    gps_code: "BGAK",
    iata_code: "QCU",
    coordinates: "-52.340369224499995, 68.7442728315",
  },
  {
    ident: "GLBU",
    type: "small_airport",
    name: "Buchanan Airport",
    elevation_ft: "41",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-GB",
    municipality: "Buchanan",
    gps_code: "GLBU",
    iata_code: "UCN",
    coordinates: "-10.058333, 5.904167",
  },
  {
    ident: "GLCP",
    type: "small_airport",
    name: "Cape Palmas Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-MY",
    municipality: "Harper",
    gps_code: "GLCP",
    iata_code: "CPA",
    coordinates: "-7.6969499588012695, 4.3790202140808105",
  },
  {
    ident: "GLGE",
    type: "small_airport",
    name: "Greenville Sinoe Airport",
    elevation_ft: "10",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-SI",
    municipality: "Greenville",
    gps_code: "GLGE",
    iata_code: "SNI",
    coordinates: "-9.066800117492676, 5.0343098640441895",
  },
  {
    ident: "GLMR",
    type: "medium_airport",
    name: "Spriggs Payne Airport",
    elevation_ft: "25",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-MO",
    municipality: "Monrovia",
    gps_code: "GLMR",
    iata_code: "MLW",
    coordinates: "-10.758700370788574, 6.289060115814209",
  },
  {
    ident: "GLNA",
    type: "small_airport",
    name: "Nimba Airport",
    elevation_ft: "1632",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-NI",
    municipality: "Nimba",
    gps_code: "GLNA",
    iata_code: "NIA",
    coordinates: "-8.600000381469727, 7.5",
  },
  {
    ident: "GLP",
    type: "small_airport",
    name: "Gulgubip Airport",
    elevation_ft: "4900",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WHM",
    municipality: "Gulgubip",
    gps_code: "AYUP",
    iata_code: "GLP",
    local_code: "GUP",
    coordinates: "141.5411, -5.2808",
  },
  {
    ident: "GLRB",
    type: "large_airport",
    name: "Roberts International Airport",
    elevation_ft: "31",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-MG",
    municipality: "Monrovia",
    gps_code: "GLRB",
    iata_code: "ROB",
    coordinates: "-10.3623, 6.23379",
  },
  {
    ident: "GLST",
    type: "small_airport",
    name: "Sasstown Airport",
    elevation_ft: "6",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-GK",
    municipality: "Sasstown",
    gps_code: "GLST",
    iata_code: "SAZ",
    coordinates: "-8.433333396911621, 4.6666669845581055",
  },
  {
    ident: "GLTN",
    type: "small_airport",
    name: "Tchien Airport",
    elevation_ft: "790",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-GG",
    municipality: "Tchien",
    gps_code: "GLTN",
    iata_code: "THC",
    coordinates: "-8.138723373413086, 6.045650482177734",
  },
  {
    ident: "GLVA",
    type: "small_airport",
    name: "Voinjama Airport",
    elevation_ft: "1400",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-LO",
    municipality: "Voinjama",
    gps_code: "GLVA",
    iata_code: "VOI",
    coordinates: "-9.767000198364258, 8.399999618530273",
  },
  {
    ident: "GMAD",
    type: "medium_airport",
    name: "Al Massira Airport",
    elevation_ft: "250",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-AGD",
    municipality: "Agadir",
    gps_code: "GMAD",
    iata_code: "AGA",
    coordinates: "-9.413069725036621, 30.325000762939453",
  },
  {
    ident: "GMAT",
    type: "medium_airport",
    name: "Tan Tan Airport",
    elevation_ft: "653",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-TNT",
    municipality: "Tan Tan",
    gps_code: "GMAT",
    iata_code: "TTA",
    coordinates: "-11.161299705505371, 28.448200225830078",
  },
  {
    ident: "GMAZ",
    type: "small_airport",
    name: "Zagora Airport",
    elevation_ft: "2631",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-TIZ",
    municipality: "Zagora",
    gps_code: "GMAZ",
    iata_code: "OZG",
    coordinates: "-5.86667013168, 30.3202991486",
  },
  {
    ident: "GMFB",
    type: "medium_airport",
    name: "Bouarfa Airport",
    elevation_ft: "3630",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-FIG",
    municipality: "Bouarfa",
    gps_code: "GMFB",
    iata_code: "UAR",
    coordinates: "-1.98305555556, 32.5143055556",
  },
  {
    ident: "GMFF",
    type: "medium_airport",
    name: "Sa����ss Airport",
    elevation_ft: "1900",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-FES",
    municipality: "Fes",
    gps_code: "GMFF",
    iata_code: "FEZ",
    coordinates: "-4.977960109709999, 33.9272994995",
  },
  {
    ident: "GMFK",
    type: "medium_airport",
    name: "Moulay Ali Cherif Airport",
    elevation_ft: "3428",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-ERR",
    municipality: "Errachidia",
    gps_code: "GMFK",
    iata_code: "ERH",
    coordinates: "-4.39833021164, 31.9475002289",
  },
  {
    ident: "GMFM",
    type: "medium_airport",
    name: "Bassatine Airport",
    elevation_ft: "1890",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-MEK",
    municipality: "Meknes",
    gps_code: "GMFM",
    iata_code: "MEK",
    coordinates: "-5.515120029449463, 33.87910079956055",
  },
  {
    ident: "GMFO",
    type: "medium_airport",
    name: "Angads Airport",
    elevation_ft: "1535",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-OUJ",
    municipality: "Oujda",
    gps_code: "GMFO",
    iata_code: "OUD",
    coordinates: "-1.92399001121521, 34.787200927734375",
  },
  {
    ident: "GMMA",
    type: "medium_airport",
    name: "Smara Airport",
    elevation_ft: "350",
    continent: "AF",
    iso_country: "EH",
    iso_region: "EH-U-A",
    municipality: "Smara",
    gps_code: "GMMA",
    iata_code: "SMW",
    coordinates: "-11.6847, 26.7318",
  },
  {
    ident: "GMMB",
    type: "small_airport",
    name: "Ben Slimane Airport",
    elevation_ft: "627",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-CAS",
    municipality: "Ben Slimane",
    gps_code: "GMMB",
    iata_code: "GMD",
    coordinates: "-7.22145, 33.655399",
  },
  {
    ident: "GMMC",
    type: "closed",
    name: "Anfa Airport",
    elevation_ft: "200",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-CAS",
    municipality: "Casablanca",
    gps_code: "GMMC",
    iata_code: "CAS",
    coordinates: "-7.661389827730001, 33.5532989502",
  },
  {
    ident: "GMMD",
    type: "small_airport",
    name: "Beni Mellal Airport",
    elevation_ft: "1694",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-BEM",
    municipality: "Beni Mellal",
    gps_code: "GMMD",
    iata_code: "BEM",
    coordinates: "-6.315905, 32.401895",
  },
  {
    ident: "GMME",
    type: "medium_airport",
    name: "Rabat-Sal���� Airport",
    elevation_ft: "276",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-RBA",
    municipality: "Rabat",
    gps_code: "GMME",
    iata_code: "RBA",
    coordinates: "-6.75152, 34.051498",
  },
  {
    ident: "GMMF",
    type: "small_airport",
    name: "Sidi Ifni Xx Airport",
    elevation_ft: "190",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-BAH",
    municipality: "Sidi Ifni",
    gps_code: "GMMF",
    iata_code: "SII",
    coordinates: "-10.187800407409668, 29.36669921875",
  },
  {
    ident: "GMMH",
    type: "medium_airport",
    name: "Dakhla Airport",
    elevation_ft: "36",
    continent: "AF",
    iso_country: "EH",
    iso_region: "EH-U-A",
    municipality: "Dakhla",
    gps_code: "GMMH",
    iata_code: "VIL",
    coordinates: "-15.932, 23.7183",
  },
  {
    ident: "GMMI",
    type: "small_airport",
    name: "Mogador Airport",
    elevation_ft: "384",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-ESI",
    municipality: "Essaouira",
    gps_code: "GMMI",
    iata_code: "ESU",
    coordinates: "-9.6816701889, 31.3974990845",
  },
  {
    ident: "GMML",
    type: "medium_airport",
    name: "Hassan I Airport",
    elevation_ft: "207",
    continent: "AF",
    iso_country: "EH",
    iso_region: "EH-U-A",
    municipality: "El Aai����n",
    gps_code: "GMML",
    iata_code: "EUN",
    coordinates: "-13.2192, 27.151699",
  },
  {
    ident: "GMMN",
    type: "large_airport",
    name: "Mohammed V International Airport",
    elevation_ft: "656",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-CAS",
    municipality: "Casablanca",
    gps_code: "GMMN",
    iata_code: "CMN",
    coordinates: "-7.589970111846924, 33.36750030517578",
  },
  {
    ident: "GMMS",
    type: "small_airport",
    name: "Safi Airport",
    elevation_ft: "171",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-TIZ",
    municipality: "Safi",
    gps_code: "GMMS",
    iata_code: "SFI",
    coordinates: "-9.233329772949219, 32.28329849243164",
  },
  {
    ident: "GMMW",
    type: "medium_airport",
    name: "Nador International Airport",
    elevation_ft: "574",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-NAD",
    municipality: "Nador",
    gps_code: "GMMW",
    iata_code: "NDR",
    coordinates: "-3.0282099247, 34.988800048799995",
  },
  {
    ident: "GMMX",
    type: "medium_airport",
    name: "Menara Airport",
    elevation_ft: "1545",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-MAR",
    municipality: "Marrakech",
    gps_code: "GMMX",
    iata_code: "RAK",
    coordinates: "-8.03629970551, 31.606899261499997",
  },
  {
    ident: "GMMY",
    type: "medium_airport",
    name: "Kenitra Airport",
    elevation_ft: "16",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-KEN",
    gps_code: "GMMY",
    iata_code: "NNA",
    coordinates: "-6.595880031585693, 34.29890060424805",
  },
  {
    ident: "GMMZ",
    type: "medium_airport",
    name: "Ouarzazate Airport",
    elevation_ft: "3782",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-OUA",
    municipality: "Ouarzazate",
    gps_code: "GMMZ",
    iata_code: "OZZ",
    coordinates: "-6.909430027010001, 30.9391002655",
  },
  {
    ident: "GMQ",
    type: "medium_airport",
    name: "Golog Maqin Airport",
    elevation_ft: "12426",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-63",
    municipality: "Golog",
    gps_code: "ZLGL",
    iata_code: "GMQ",
    coordinates: "100.301144, 34.418066",
  },
  {
    ident: "GMTA",
    type: "medium_airport",
    name: "Cherif Al Idrissi Airport",
    elevation_ft: "95",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-HOC",
    municipality: "Al Hoceima",
    gps_code: "GMTA",
    iata_code: "AHU",
    coordinates: "-3.83951997756958, 35.177101135253906",
  },
  {
    ident: "GMTN",
    type: "medium_airport",
    name: "Saniat R'mel Airport",
    elevation_ft: "10",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-TET",
    municipality: "T����touan",
    gps_code: "GMTN",
    iata_code: "TTU",
    coordinates: "-5.320020198822, 35.594299316406",
  },
  {
    ident: "GMTT",
    type: "medium_airport",
    name: "Ibn Batouta Airport",
    elevation_ft: "62",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-TNG",
    municipality: "Tangier",
    gps_code: "GMTT",
    iata_code: "TNG",
    coordinates: "-5.91689014435, 35.726898193400004",
  },
  {
    ident: "GNE",
    type: "closed",
    name: "Sint-Denijs-Westrem Airport",
    elevation_ft: "30",
    continent: "EU",
    iso_country: "BE",
    iso_region: "BE-U-A",
    gps_code: "EBGT",
    iata_code: "GNE",
    coordinates: "3.688231, 51.025956",
  },
  {
    ident: "GNU",
    type: "small_airport",
    name: "Goodnews Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Goodnews",
    gps_code: "GNU",
    iata_code: "GNU",
    local_code: "GNU",
    coordinates: "-161.57699585, 59.117401123",
  },
  {
    ident: "GOBD",
    type: "large_airport",
    name: "Blaise Diagne International Airport",
    elevation_ft: "290",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-DK",
    municipality: "Dakar",
    gps_code: "GOBD",
    iata_code: "DSS",
    coordinates: "-17.073333, 14.67",
  },
  {
    ident: "GOC",
    type: "small_airport",
    name: "Gora Airstrip",
    elevation_ft: "2497",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Gora",
    gps_code: "AYGX",
    iata_code: "GOC",
    local_code: "GORA",
    coordinates: "148.2364, -9.0021",
  },
  {
    ident: "GODK",
    type: "small_airport",
    name: "Kolda North Airport",
    elevation_ft: "33",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-KD",
    municipality: "Kolda",
    gps_code: "GODK",
    iata_code: "KDA",
    local_code: "GODK",
    coordinates: "-14.968099594116211, 12.898500442504883",
  },
  {
    ident: "GOGG",
    type: "medium_airport",
    name: "Ziguinchor Airport",
    elevation_ft: "75",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-ZG",
    municipality: "Ziguinchor",
    gps_code: "GOGG",
    iata_code: "ZIG",
    coordinates: "-16.281799, 12.5556",
  },
  {
    ident: "GOGS",
    type: "medium_airport",
    name: "Cap Skirring Airport",
    elevation_ft: "52",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-ZG",
    municipality: "Cap Skirring",
    gps_code: "GOGS",
    iata_code: "CSK",
    coordinates: "-16.748, 12.39533",
  },
  {
    ident: "GOOK",
    type: "medium_airport",
    name: "Kaolack Airport",
    elevation_ft: "26",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-FK",
    municipality: "Kaolack",
    gps_code: "GOOK",
    iata_code: "KLC",
    coordinates: "-16.051300048828125, 14.146900177001953",
  },
  {
    ident: "GOOY",
    type: "large_airport",
    name: "L����opold S����dar Senghor International Airport",
    elevation_ft: "85",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-DK",
    municipality: "Dakar",
    gps_code: "GOOY",
    iata_code: "DKR",
    coordinates: "-17.49020004272461, 14.739700317382812",
  },
  {
    ident: "GOSM",
    type: "medium_airport",
    name: "Ouro Sogui Airport",
    elevation_ft: "85",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-MT",
    municipality: "Ouro Sogui",
    gps_code: "GOSM",
    iata_code: "MAX",
    coordinates: "-13.3228, 15.5936",
  },
  {
    ident: "GOSP",
    type: "small_airport",
    name: "Podor Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-SL",
    municipality: "Podor",
    gps_code: "GOSP",
    iata_code: "POD",
    coordinates: "-14.967000007629395, 16.683000564575195",
  },
  {
    ident: "GOSR",
    type: "small_airport",
    name: "Richard Toll Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-SL",
    municipality: "Richard Toll",
    gps_code: "GOSR",
    iata_code: "RDT",
    coordinates: "-15.649999618530273, 16.433000564575195",
  },
  {
    ident: "GOSS",
    type: "medium_airport",
    name: "Saint Louis Airport",
    elevation_ft: "9",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-SL",
    municipality: "Saint Louis",
    gps_code: "GOSS",
    iata_code: "XLS",
    coordinates: "-16.463199615478516, 16.050800323486328",
  },
  {
    ident: "GOTB",
    type: "medium_airport",
    name: "Bakel Airport",
    elevation_ft: "98",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-TC",
    municipality: "Bakel",
    gps_code: "GOTB",
    iata_code: "BXE",
    coordinates: "-12.468299865722656, 14.847299575805664",
  },
  {
    ident: "GOTK",
    type: "medium_airport",
    name: "K����dougou Airport",
    elevation_ft: "584",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-TC",
    municipality: "K����dougou",
    gps_code: "GOTK",
    iata_code: "KGG",
    coordinates: "-12.22029972076416, 12.57229995727539",
  },
  {
    ident: "GOTS",
    type: "small_airport",
    name: "Simenti Airport",
    elevation_ft: "171",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-TC",
    municipality: "Simenti",
    gps_code: "GOTS",
    iata_code: "SMY",
    coordinates: "-13.29539966583252, 13.046799659729004",
  },
  {
    ident: "GOTT",
    type: "medium_airport",
    name: "Tambacounda Airport",
    elevation_ft: "161",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-TC",
    municipality: "Tambacounda",
    gps_code: "GOTT",
    iata_code: "TUD",
    coordinates: "-13.65310001373291, 13.736800193786621",
  },
  {
    ident: "GQNA",
    type: "small_airport",
    name: "Aioun el Atrouss Airport",
    elevation_ft: "951",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-02",
    municipality: "Aioun El Atrouss",
    gps_code: "GQNA",
    iata_code: "AEO",
    coordinates: "-9.637880325317383, 16.711299896240234",
  },
  {
    ident: "GQNB",
    type: "small_airport",
    name: "Boutilimit Airport",
    elevation_ft: "121",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-06",
    municipality: "Boutilimit",
    gps_code: "GQNB",
    iata_code: "OTL",
    coordinates: "-14.682999610900879, 17.533000946044922",
  },
  {
    ident: "GQNC",
    type: "small_airport",
    name: "Tichitt Airport",
    elevation_ft: "561",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-09",
    municipality: "Tichitt",
    gps_code: "GQNC",
    iata_code: "THI",
    coordinates: "-9.517000198364258, 18.450000762939453",
  },
  {
    ident: "GQND",
    type: "small_airport",
    name: "Tidjikja Airport",
    elevation_ft: "1342",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-09",
    municipality: "Tidjikja",
    gps_code: "GQND",
    iata_code: "TIY",
    coordinates: "-11.4235, 18.570101",
  },
  {
    ident: "GQNE",
    type: "small_airport",
    name: "Abbaye Airport",
    elevation_ft: "66",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-05",
    municipality: "Boghe",
    gps_code: "GQNE",
    iata_code: "BGH",
    coordinates: "-14.200000762939453, 16.63331413269043",
  },
  {
    ident: "GQNF",
    type: "small_airport",
    name: "Kiffa Airport",
    elevation_ft: "430",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-03",
    municipality: "Kiffa",
    gps_code: "GQNF",
    iata_code: "KFA",
    coordinates: "-11.4062, 16.59",
  },
  {
    ident: "GQNH",
    type: "small_airport",
    name: "Timbedra Airport",
    elevation_ft: "692",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-01",
    municipality: "Timbedra",
    gps_code: "GQNH",
    iata_code: "TMD",
    coordinates: "-8.166999816894531, 16.232999801635742",
  },
  {
    ident: "GQNI",
    type: "small_airport",
    name: "N����ma Airport",
    elevation_ft: "745",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-01",
    municipality: "N����ma",
    gps_code: "GQNI",
    iata_code: "EMN",
    coordinates: "-7.3166, 16.622",
  },
  {
    ident: "GQNJ",
    type: "small_airport",
    name: "Akjoujt Airport",
    elevation_ft: "403",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-12",
    municipality: "Akjoujt",
    gps_code: "GQNJ",
    iata_code: "AJJ",
    coordinates: "-14.383249282836914, 19.733016967773438",
  },
  {
    ident: "GQNK",
    type: "small_airport",
    name: "Ka����di Airport",
    elevation_ft: "75",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-04",
    municipality: "Ka����di",
    gps_code: "GQNK",
    iata_code: "KED",
    coordinates: "-13.5076, 16.1595",
  },
  {
    ident: "GQNL",
    type: "small_airport",
    name: "Letfotar Airport",
    elevation_ft: "256",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-05",
    municipality: "Moudjeria",
    gps_code: "GQNL",
    iata_code: "MOM",
    coordinates: "-12.5, 17.75",
  },
  {
    ident: "GQNO",
    type: "large_airport",
    name: "Nouakchott������Oumtounsy International Airport",
    elevation_ft: "9",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-NKC",
    municipality: "Nouakchott",
    gps_code: "GQNO",
    iata_code: "NKC",
    coordinates: "-15.9697222, 18.31",
  },
  {
    ident: "GQNS",
    type: "small_airport",
    name: "S����libaby Airport",
    elevation_ft: "219",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-10",
    municipality: "S����libaby",
    gps_code: "GQNS",
    iata_code: "SEY",
    coordinates: "-12.207300186157227, 15.179699897766113",
  },
  {
    ident: "GQNT",
    type: "small_airport",
    name: "Tamchakett Airport",
    elevation_ft: "620",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-02",
    municipality: "Tamchakett",
    gps_code: "GQNT",
    iata_code: "THT",
    coordinates: "-10.817000389099121, 17.232999801635742",
  },
  {
    ident: "GQPA",
    type: "medium_airport",
    name: "Atar International Airport",
    elevation_ft: "734",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-07",
    municipality: "Atar",
    gps_code: "GQPA",
    iata_code: "ATR",
    coordinates: "-13.04319953918457, 20.506799697875977",
  },
  {
    ident: "GQPF",
    type: "small_airport",
    name: "Fderik Airport",
    elevation_ft: "961",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-11",
    municipality: "Fderik",
    gps_code: "GQPF",
    iata_code: "FGD",
    coordinates: "-12.732999801635742, 22.66699981689453",
  },
  {
    ident: "GQPP",
    type: "medium_airport",
    name: "Nouadhibou International Airport",
    elevation_ft: "24",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-08",
    municipality: "Nouadhibou",
    gps_code: "GQPP",
    iata_code: "NDB",
    coordinates: "-17.030000686645508, 20.9330997467041",
  },
  {
    ident: "GQPZ",
    type: "small_airport",
    name: "Tazadit Airport",
    elevation_ft: "1119",
    continent: "AF",
    iso_country: "MR",
    iso_region: "MR-11",
    municipality: "Zou����rate",
    gps_code: "GQPZ",
    iata_code: "OUZ",
    coordinates: "-12.4836, 22.756399",
  },
  {
    ident: "GRC",
    type: "small_airport",
    name: "Grand Cess Airport",
    elevation_ft: "28",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-GK",
    municipality: "Grand Cess",
    iata_code: "GRC",
    coordinates: "-8.2076, 4.571",
  },
  {
    ident: "GRH",
    type: "closed",
    name: "Garuahi Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Garuahi",
    iata_code: "GRH",
    coordinates: "150.487667, -10.225149",
  },
  {
    ident: "GSZ",
    type: "small_airport",
    name: "Granite Mountain Air Station",
    elevation_ft: "1313",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Granite Mountain",
    gps_code: "PAGZ",
    iata_code: "GMT",
    local_code: "GSZ",
    coordinates: "-161.2810059, 65.40209961",
  },
  {
    ident: "GT-0001",
    type: "small_airport",
    name: "Chiquimula Airport",
    elevation_ft: "1484",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-CQ",
    municipality: "Chiquimula",
    gps_code: "MGCQ",
    iata_code: "CIQ",
    coordinates: "-89.520938, 14.83092",
  },
  {
    ident: "GT-0002",
    type: "small_airport",
    name: "Dos Lagunas Airport",
    elevation_ft: "1057",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-PE",
    municipality: "Dos Lagunas",
    gps_code: "MGDL",
    iata_code: "DON",
    coordinates: "-89.6884002686, 17.6124000549",
  },
  {
    ident: "GT-0003",
    type: "small_airport",
    name: "El Naranjo Airport",
    elevation_ft: "140",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-ES",
    municipality: "El Naranjo",
    iata_code: "ENJ",
    coordinates: "-90.817496, 14.106931",
  },
  {
    ident: "GT-0004",
    type: "small_airport",
    name: "Paso Caballos Airport",
    elevation_ft: "213",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-PE",
    municipality: "Paso Caballos",
    gps_code: "MGPC",
    iata_code: "PCG",
    coordinates: "-90.25630187990001, 17.2639007568",
  },
  {
    ident: "GT-0006",
    type: "small_airport",
    name: "El Pet����n Airport",
    elevation_ft: "774",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-PE",
    municipality: "Tikal",
    iata_code: "TKM",
    coordinates: "-89.6050033569336, 17.226388931274414",
  },
  {
    ident: "GT-0007",
    type: "small_airport",
    name: "Uaxactun Airport",
    elevation_ft: "573",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-PE",
    municipality: "Uaxactun",
    gps_code: "MGUX",
    iata_code: "UAX",
    coordinates: "-89.63274383539999, 17.3938884735",
  },
  {
    ident: "GT-PKJ",
    type: "small_airport",
    name: "Playa Grande Airport",
    elevation_ft: "577",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-QC",
    municipality: "Playa Grande",
    gps_code: "MGPG",
    iata_code: "PKJ",
    coordinates: "-90.74169921880001, 15.997500419600001",
  },
  {
    ident: "GTZ",
    type: "small_airport",
    name: "Kirawira B Aerodrome",
    elevation_ft: "3970",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-13",
    municipality: "Grumeti Game Reserve",
    gps_code: "HTGR",
    iata_code: "GTZ",
    coordinates: "34.225556, -2.160833",
  },
  {
    ident: "GUCY",
    type: "medium_airport",
    name: "Conakry International Airport",
    elevation_ft: "72",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-C",
    municipality: "Conakry",
    gps_code: "GUCY",
    iata_code: "CKY",
    coordinates: "-13.612, 9.57689",
  },
  {
    ident: "GUE",
    type: "small_airport",
    name: "Guriaso (Keraso) Airport",
    elevation_ft: "400",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Guriaso",
    iata_code: "GUE",
    local_code: "KSO",
    coordinates: "141.5895, -3.583167",
  },
  {
    ident: "GUFA",
    type: "small_airport",
    name: "Fria Airport",
    elevation_ft: "499",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-B",
    municipality: "Fria",
    gps_code: "GUFA",
    iata_code: "FIG",
    coordinates: "-13.569199562073, 10.350600242615",
  },
  {
    ident: "GUFH",
    type: "small_airport",
    name: "Faranah Airport",
    elevation_ft: "1476",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-F",
    municipality: "Faranah",
    gps_code: "GUFH",
    iata_code: "FAA",
    coordinates: "-10.7698001862, 10.0354995728",
  },
  {
    ident: "GUKU",
    type: "small_airport",
    name: "Kissidougou Airport",
    elevation_ft: "1808",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-F",
    municipality: "Kissidougou",
    gps_code: "GUKU",
    iata_code: "KSI",
    coordinates: "-10.124400138855, 9.1605596542358",
  },
  {
    ident: "GULB",
    type: "small_airport",
    name: "Tata Airport",
    elevation_ft: "3396",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-L",
    municipality: "Lab����",
    gps_code: "GULB",
    iata_code: "LEK",
    coordinates: "-12.286800384521, 11.326100349426",
  },
  {
    ident: "GUMA",
    type: "small_airport",
    name: "Macenta Airport",
    elevation_ft: "1690",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-N",
    municipality: "Macenta",
    gps_code: "GUMA",
    iata_code: "MCA",
    coordinates: "-9.525071, 8.481857",
  },
  {
    ident: "GUNZ",
    type: "small_airport",
    name: "Nz����r����kor���� Airport",
    elevation_ft: "1657",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-N",
    municipality: "Nz����r����kor����",
    gps_code: "GUNZ",
    iata_code: "NZE",
    coordinates: "-8.7017974853516, 7.8060193061829",
  },
  {
    ident: "GUOK",
    type: "small_airport",
    name: "Bok���� Baralande Airport",
    elevation_ft: "164",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-B",
    municipality: "Bok����",
    gps_code: "GUOK",
    iata_code: "BKJ",
    coordinates: "-14.2811, 10.9658",
  },
  {
    ident: "GUSB",
    type: "small_airport",
    name: "Sambailo Airport",
    elevation_ft: "295",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-B",
    municipality: "Koundara",
    gps_code: "GUSB",
    iata_code: "SBI",
    coordinates: "-13.358499526978, 12.572699546814",
  },
  {
    ident: "GUSI",
    type: "small_airport",
    name: "Siguiri Airport",
    elevation_ft: "1296",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-K",
    municipality: "Siguiri",
    gps_code: "GUSI",
    iata_code: "GII",
    coordinates: "-9.1669998168945, 11.432999610901",
  },
  {
    ident: "GUXN",
    type: "small_airport",
    name: "Kankan Airport",
    elevation_ft: "1234",
    continent: "AF",
    iso_country: "GN",
    iso_region: "GN-K",
    municipality: "Kankan",
    gps_code: "GUXN",
    iata_code: "KNN",
    coordinates: "-9.228757, 10.448438",
  },
  {
    ident: "GVAC",
    type: "large_airport",
    name: "Am����lcar Cabral International Airport",
    elevation_ft: "177",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-B",
    municipality: "Espargos",
    gps_code: "GVAC",
    iata_code: "SID",
    coordinates: "-22.9494, 16.7414",
  },
  {
    ident: "GVAN",
    type: "small_airport",
    name: "Agostinho Neto Airport",
    elevation_ft: "32",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-B",
    municipality: "Ponta do Sol",
    gps_code: "GVAN",
    iata_code: "NTO",
    coordinates: "-25.090599060058594, 17.202800750732422",
  },
  {
    ident: "GVBA",
    type: "medium_airport",
    name: "Rabil Airport",
    elevation_ft: "69",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-B",
    municipality: "Rabil",
    gps_code: "GVBA",
    iata_code: "BVC",
    coordinates: "-22.888900756835938, 16.136499404907227",
  },
  {
    ident: "GVE",
    type: "small_airport",
    name: "Gordonsville Municipal Airport",
    elevation_ft: "454",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Gordonsville",
    gps_code: "KGVE",
    iata_code: "GVE",
    local_code: "GVE",
    coordinates: "-78.165802002, 38.15599823",
  },
  {
    ident: "GVMA",
    type: "medium_airport",
    name: "Maio Airport",
    elevation_ft: "36",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-S",
    municipality: "Vila do Maio",
    gps_code: "GVMA",
    iata_code: "MMO",
    coordinates: "-23.213699340820312, 15.155900001525879",
  },
  {
    ident: "GVMT",
    type: "small_airport",
    name: "Mosteiros Airport",
    elevation_ft: "66",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-S",
    municipality: "Vila do Mosteiros",
    gps_code: "GVMT",
    iata_code: "MTI",
    coordinates: "-24.33919906616211, 15.045000076293945",
  },
  {
    ident: "GVNP",
    type: "medium_airport",
    name: "Praia International Airport",
    elevation_ft: "230",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-S",
    municipality: "Praia",
    gps_code: "GVNP",
    iata_code: "RAI",
    coordinates: "-23.493499755859375, 14.924500465393066",
  },
  {
    ident: "GVSF",
    type: "small_airport",
    name: "S����o Filipe Airport",
    elevation_ft: "617",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-S",
    municipality: "S����o Filipe",
    gps_code: "GVSF",
    iata_code: "SFL",
    coordinates: "-24.4799995422, 14.8850002289",
  },
  {
    ident: "GVSN",
    type: "medium_airport",
    name: "Pregui����a Airport",
    elevation_ft: "669",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-B",
    municipality: "Pregui����a",
    gps_code: "GVSN",
    iata_code: "SNE",
    coordinates: "-24.284700393676758, 16.58839988708496",
  },
  {
    ident: "GVSV",
    type: "medium_airport",
    name: "S����o Pedro Airport",
    elevation_ft: "66",
    continent: "AF",
    iso_country: "CV",
    iso_region: "CV-B",
    municipality: "S����o Pedro",
    gps_code: "GVSV",
    iata_code: "VXE",
    coordinates: "-25.055299758911133, 16.833200454711914",
  },
  {
    ident: "GWN",
    type: "closed",
    name: "Gnarowein Airport",
    elevation_ft: "525",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Gnarowein",
    iata_code: "GWN",
    coordinates: "146.248, -6.555",
  },
  {
    ident: "GWW",
    type: "closed",
    name: "Gatow Airport",
    elevation_ft: "161",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BE",
    municipality: "Spandau",
    gps_code: "EDBG",
    iata_code: "GWW",
    coordinates: "13.1380996704, 52.474399566699994",
  },
  {
    ident: "GY-BCG",
    type: "small_airport",
    name: "Bemichi Airport",
    elevation_ft: "94",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-BA",
    municipality: "Bemichi",
    gps_code: "SYBE",
    iata_code: "BCG",
    coordinates: "-59.1666603088, 7.6999998092699995",
  },
  {
    ident: "GY-BTO",
    type: "small_airport",
    name: "Botopasi Airport",
    elevation_ft: "242",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-SI",
    municipality: "Botopasi",
    gps_code: "SMBO",
    iata_code: "BTO",
    coordinates: "-55.4470539093, 4.21751109352",
  },
  {
    ident: "GY-DOE",
    type: "small_airport",
    name: "Djumu-Djomoe Airport",
    elevation_ft: "290",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-SI",
    municipality: "Djumu-Djomoe",
    gps_code: "SMDJ",
    iata_code: "DOE",
    coordinates: "-55.4816436768, 4.00571261057",
  },
  {
    ident: "GY-LDO",
    type: "small_airport",
    name: "Ladouanie Airport",
    elevation_ft: "236",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-SI",
    municipality: "Aurora",
    gps_code: "SMDO",
    iata_code: "LDO",
    coordinates: "-55.407056808499995, 4.37610828345",
  },
  {
    ident: "GY-WSO",
    type: "small_airport",
    name: "Washabo Airport",
    elevation_ft: "68",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-SI",
    municipality: "Washabo",
    gps_code: "SMWS",
    iata_code: "WSO",
    coordinates: "-57.185302, 5.215277",
  },
  {
    ident: "HAAB",
    type: "large_airport",
    name: "Addis Ababa Bole International Airport",
    elevation_ft: "7630",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AA",
    municipality: "Addis Ababa",
    gps_code: "HAAB",
    iata_code: "ADD",
    coordinates: "38.799301147499996, 8.97789001465",
  },
  {
    ident: "HAAM",
    type: "medium_airport",
    name: "Arba Minch Airport",
    elevation_ft: "3901",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SN",
    gps_code: "HAAM",
    iata_code: "AMH",
    coordinates: "37.59049987792969, 6.0393900871276855",
  },
  {
    ident: "HAAX",
    type: "medium_airport",
    name: "Axum Airport",
    elevation_ft: "6959",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-TI",
    gps_code: "HAAX",
    iata_code: "AXU",
    coordinates: "38.77280044555664, 14.14680004119873",
  },
  {
    ident: "HABC",
    type: "small_airport",
    name: "Baco Airport",
    elevation_ft: "4580",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SN",
    municipality: "Baco",
    gps_code: "HABC",
    iata_code: "BCO",
    coordinates: "36.562, 5.78287",
  },
  {
    ident: "HABD",
    type: "medium_airport",
    name: "Bahir Dar Airport",
    elevation_ft: "5978",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AM",
    municipality: "Bahir Dar",
    gps_code: "HABD",
    iata_code: "BJR",
    coordinates: "37.32160186767578, 11.608099937438965",
  },
  {
    ident: "HABE",
    type: "small_airport",
    name: "Beica Airport",
    elevation_ft: "5410",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-BE",
    municipality: "Beica",
    gps_code: "HABE",
    iata_code: "BEI",
    coordinates: "34.52190017700195, 9.38638973236084",
  },
  {
    ident: "HADB",
    type: "closed",
    name: "Degah Bur Airport",
    elevation_ft: "3550",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SO",
    municipality: "Degah Bur",
    gps_code: "HADB",
    iata_code: "DGC",
    coordinates: "43.5673, 8.234",
  },
  {
    ident: "HADC",
    type: "small_airport",
    name: "Combolcha Airport",
    elevation_ft: "6117",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AM",
    municipality: "Dessie",
    gps_code: "HADC",
    iata_code: "DSE",
    coordinates: "39.71139907836914, 11.082500457763672",
  },
  {
    ident: "HADD",
    type: "small_airport",
    name: "Dembidollo Airport",
    elevation_ft: "5200",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-GA",
    municipality: "Dembidollo",
    gps_code: "HADD",
    iata_code: "DEM",
    coordinates: "34.858001708984375, 8.553999900817871",
  },
  {
    ident: "HADM",
    type: "small_airport",
    name: "Debra Marcos Airport",
    elevation_ft: "8136",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AM",
    municipality: "Debra Marcos",
    gps_code: "HADM",
    iata_code: "DBM",
    coordinates: "37.71699905395508, 10.350000381469727",
  },
  {
    ident: "HADR",
    type: "medium_airport",
    name: "Aba Tenna Dejazmach Yilma International Airport",
    elevation_ft: "3827",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-DD",
    municipality: "Dire Dawa",
    gps_code: "HADR",
    iata_code: "DIR",
    coordinates: "41.85419845581055, 9.624699592590332",
  },
  {
    ident: "HADT",
    type: "small_airport",
    name: "Debre Tabor Airport",
    elevation_ft: "8490",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AM",
    municipality: "Debre Tabor",
    gps_code: "HADT",
    iata_code: "DBT",
    coordinates: "38, 11.967000007629395",
  },
  {
    ident: "HAFN",
    type: "small_airport",
    name: "Fincha Airport",
    elevation_ft: "7600",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Fincha",
    gps_code: "HAFN",
    iata_code: "FNH",
    coordinates: "37.349998474121094, 9.583000183105469",
  },
  {
    ident: "HAGB",
    type: "small_airport",
    name: "Robe Airport",
    elevation_ft: "7892",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Goba",
    gps_code: "HAGB",
    iata_code: "GOB",
    coordinates: "40.0463033, 7.1160634",
  },
  {
    ident: "HAGH",
    type: "small_airport",
    name: "Ghinnir Airport",
    elevation_ft: "6499",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Ghinnir",
    gps_code: "HAGH",
    iata_code: "GNN",
    coordinates: "40.716999054, 7.15000009537",
  },
  {
    ident: "HAGM",
    type: "medium_airport",
    name: "Gambella Airport",
    elevation_ft: "1614",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-GA",
    municipality: "Gambela",
    gps_code: "HAGM",
    iata_code: "GMB",
    coordinates: "34.5630989074707, 8.12876033782959",
  },
  {
    ident: "HAGN",
    type: "medium_airport",
    name: "Gonder Airport",
    elevation_ft: "6449",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AM",
    municipality: "Gondar",
    gps_code: "HAGN",
    iata_code: "GDQ",
    coordinates: "37.433998107910156, 12.51990032196045",
  },
  {
    ident: "HAGO",
    type: "medium_airport",
    name: "Gode Airport",
    elevation_ft: "834",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SO",
    municipality: "Gode",
    gps_code: "HAGO",
    iata_code: "GDE",
    coordinates: "43.5786018372, 5.93513011932",
  },
  {
    ident: "HAGR",
    type: "small_airport",
    name: "Gore Airport",
    elevation_ft: "6580",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-GA",
    municipality: "Gore",
    gps_code: "HAGR",
    iata_code: "GOR",
    coordinates: "35.5529, 8.1614",
  },
  {
    ident: "HAHM",
    type: "medium_airport",
    name: "Harar Meda Airport",
    elevation_ft: "6201",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Debre Zeyit",
    gps_code: "HAHM",
    iata_code: "QHR",
    coordinates: "39.0059, 8.7163",
  },
  {
    ident: "HAHU",
    type: "small_airport",
    name: "Humera Airport",
    elevation_ft: "1930",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-TI",
    municipality: "Humera",
    gps_code: "HAHU",
    iata_code: "HUE",
    coordinates: "36.58300018310547, 14.25",
  },
  {
    ident: "HAJJ",
    type: "medium_airport",
    name: "Wilwal International Airport",
    elevation_ft: "5954",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SO",
    municipality: "Jijiga",
    gps_code: "HAJJ",
    iata_code: "JIJ",
    coordinates: "42.9121, 9.3325",
  },
  {
    ident: "HAJM",
    type: "medium_airport",
    name: "Jimma Airport",
    elevation_ft: "5500",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Jimma",
    gps_code: "HAJM",
    iata_code: "JIM",
    coordinates: "36.81660079956055, 7.66609001159668",
  },
  {
    ident: "HAKD",
    type: "small_airport",
    name: "Kabri Dehar Airport",
    elevation_ft: "1800",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SO",
    municipality: "Kabri Dehar",
    gps_code: "HAKD",
    iata_code: "ABK",
    coordinates: "44.252998, 6.734",
  },
  {
    ident: "HAKL",
    type: "small_airport",
    name: "Kelafo East Airport",
    elevation_ft: "1730",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SO",
    municipality: "Kelafo",
    gps_code: "HAKL",
    iata_code: "LFO",
    coordinates: "44.349998474121094, 5.6570000648498535",
  },
  {
    ident: "HALA",
    type: "small_airport",
    name: "Awassa Airport",
    elevation_ft: "5450",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Awassa",
    gps_code: "HALA",
    iata_code: "AWA",
    coordinates: "38.5, 7.066999912261963",
  },
  {
    ident: "HALL",
    type: "small_airport",
    name: "Lalibella Airport",
    elevation_ft: "6506",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AM",
    municipality: "Lalibela",
    gps_code: "HALL",
    iata_code: "LLI",
    coordinates: "38.97999954223633, 11.975000381469727",
  },
  {
    ident: "HAMA",
    type: "small_airport",
    name: "Mekane Selam Airport",
    elevation_ft: "8405",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AM",
    municipality: "Mekane Selam",
    gps_code: "HAMA",
    iata_code: "MKS",
    coordinates: "38.7415, 10.7254",
  },
  {
    ident: "HAMK",
    type: "medium_airport",
    name: "Mekele Airport",
    elevation_ft: "7396",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-TI",
    gps_code: "HAMK",
    iata_code: "MQX",
    coordinates: "39.53350067138672, 13.467399597167969",
  },
  {
    ident: "HAMM",
    type: "small_airport",
    name: "Metema Airport",
    elevation_ft: "2650",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AM",
    municipality: "Metema",
    gps_code: "HAMM",
    iata_code: "ETE",
    coordinates: "36.1669998169, 12.932999610899998",
  },
  {
    ident: "HAMN",
    type: "small_airport",
    name: "Mendi Airport",
    elevation_ft: "5500",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-BE",
    municipality: "Mendi",
    gps_code: "HAMN",
    iata_code: "NDM",
    coordinates: "35.099998474121094, 9.767000198364258",
  },
  {
    ident: "HAMR",
    type: "small_airport",
    name: "Mui River Airport",
    elevation_ft: "1834",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SN",
    municipality: "Omo National Park",
    gps_code: "HAMR",
    iata_code: "MUJ",
    coordinates: "35.7485, 5.8646",
  },
  {
    ident: "HAMT",
    type: "small_airport",
    name: "Mizan Teferi Airport",
    elevation_ft: "4396",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-GA",
    municipality: "Mizan Teferi",
    gps_code: "HAMT",
    iata_code: "MTF",
    coordinates: "35.5547, 6.9571",
  },
  {
    ident: "HANG",
    type: "small_airport",
    name: "Negele Airport",
    elevation_ft: "5230",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Negele Borana",
    gps_code: "HANG",
    iata_code: "EGL",
    coordinates: "39.7023, 5.2897",
  },
  {
    ident: "HANJ",
    type: "small_airport",
    name: "Nejjo Airport",
    elevation_ft: "6150",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-BE",
    municipality: "Nejjo",
    gps_code: "HANJ",
    iata_code: "NEJ",
    coordinates: "35.466995, 9.5500001",
  },
  {
    ident: "HANK",
    type: "small_airport",
    name: "Nekemte Airport",
    elevation_ft: "6500",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Nekemte",
    gps_code: "HANK",
    iata_code: "NEK",
    coordinates: "36.599998474121094, 9.050000190734863",
  },
  {
    ident: "HAPW",
    type: "small_airport",
    name: "Beles Airport",
    elevation_ft: "3695",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-BE",
    municipality: "Pawe",
    gps_code: "HAPW",
    iata_code: "PWI",
    coordinates: "36.4164, 11.3126",
  },
  {
    ident: "HASD",
    type: "small_airport",
    name: "Soddu Airport",
    elevation_ft: "6400",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Soddu",
    gps_code: "HASD",
    iata_code: "SXU",
    coordinates: "37.75, 6.816999912261963",
  },
  {
    ident: "HASK",
    type: "small_airport",
    name: "Shakiso Airport",
    elevation_ft: "5815",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Shakiso",
    gps_code: "HASK",
    iata_code: "SKR",
    coordinates: "38.9764, 5.6923",
  },
  {
    ident: "HASM",
    type: "small_airport",
    name: "Semera Airport",
    elevation_ft: "1390",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-AF",
    municipality: "Semera",
    gps_code: "HASM",
    iata_code: "SZE",
    coordinates: "40.9915, 11.7875",
  },
  {
    ident: "HASO",
    type: "medium_airport",
    name: "Asosa Airport",
    elevation_ft: "5100",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-BE",
    municipality: "Asosa",
    gps_code: "HASO",
    iata_code: "ASO",
    coordinates: "34.586299896240234, 10.018500328063965",
  },
  {
    ident: "HATP",
    type: "small_airport",
    name: "Tippi Airport",
    elevation_ft: "1100",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-GA",
    municipality: "Tippi",
    gps_code: "HATP",
    iata_code: "TIE",
    coordinates: "35.415, 7.2024",
  },
  {
    ident: "HAWC",
    type: "small_airport",
    name: "Waca Airport",
    elevation_ft: "4200",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Waca",
    gps_code: "HAWC",
    iata_code: "WAC",
    coordinates: "37.16699981689453, 7.166999816894531",
  },
  {
    ident: "HAWR",
    type: "small_airport",
    name: "Warder Airport",
    elevation_ft: "1850",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SO",
    municipality: "Warder",
    gps_code: "HAWR",
    iata_code: "WRA",
    coordinates: "45.3334, 6.9724",
  },
  {
    ident: "HAY",
    type: "small_airport",
    name: "Haycock Airport",
    elevation_ft: "175",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Haycock",
    gps_code: "HAY",
    iata_code: "HAY",
    local_code: "HAY",
    coordinates: "-161.156997681, 65.20099639889999",
  },
  {
    ident: "HAZ",
    type: "small_airport",
    name: "Hatzfeldhaven Airport",
    elevation_ft: "26",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Hatzfeldhaven",
    iata_code: "HAZ",
    local_code: "HTZ",
    coordinates: "145.2056, -4.4033",
  },
  {
    ident: "HBBA",
    type: "medium_airport",
    name: "Bujumbura International Airport",
    elevation_ft: "2582",
    continent: "AF",
    iso_country: "BI",
    iso_region: "BI-BM",
    municipality: "Bujumbura",
    gps_code: "HBBA",
    iata_code: "BJM",
    coordinates: "29.318500518798828, -3.3240199089050293",
  },
  {
    ident: "HBBE",
    type: "small_airport",
    name: "Gitega Airport",
    elevation_ft: "5741",
    continent: "AF",
    iso_country: "BI",
    iso_region: "BI-GI",
    municipality: "Gitega",
    gps_code: "HBBE",
    iata_code: "GID",
    coordinates: "29.911308, -3.417209",
  },
  {
    ident: "HBBO",
    type: "small_airport",
    name: "Kirundo Airport",
    elevation_ft: "4511",
    continent: "AF",
    iso_country: "BI",
    iso_region: "BI-KI",
    municipality: "Kirundo",
    gps_code: "HBBO",
    iata_code: "KRE",
    coordinates: "30.094575, -2.544772",
  },
  {
    ident: "HBQ",
    type: "medium_airport",
    name: "Qilian Airport",
    elevation_ft: "10377",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-63",
    municipality: "Qilian",
    iata_code: "HBQ",
    coordinates: "100.644, 38.012",
  },
  {
    ident: "HBT",
    type: "seaplane_base",
    name: "Hambantota Seaplane Base",
    elevation_ft: "0",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-3",
    municipality: "Hambantota",
    iata_code: "HBT",
    coordinates: "81.103, 6.124043",
  },
  {
    ident: "HCMA",
    type: "small_airport",
    name: "Alula Airport",
    elevation_ft: "6",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-BR",
    municipality: "Alula",
    gps_code: "HCMA",
    iata_code: "ALU",
    coordinates: "50.748, 11.9582",
  },
  {
    ident: "HCMB",
    type: "small_airport",
    name: "Baidoa Airport",
    elevation_ft: "1820",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-BY",
    municipality: "Baidoa",
    gps_code: "HCMB",
    iata_code: "BIB",
    coordinates: "43.62860107421875, 3.102220058441162",
  },
  {
    ident: "HCMC",
    type: "small_airport",
    name: "Candala Airport",
    elevation_ft: "30",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-BR",
    municipality: "Candala",
    gps_code: "HCMC",
    iata_code: "CXN",
    coordinates: "49.9085, 11.494",
  },
  {
    ident: "HCMD",
    type: "small_airport",
    name: "Bardera Airport",
    elevation_ft: "4200",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-GE",
    gps_code: "HCMD",
    iata_code: "BSY",
    coordinates: "42.307778, 2.336111",
  },
  {
    ident: "HCME",
    type: "small_airport",
    name: "Eil Airport",
    elevation_ft: "879",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-NU",
    municipality: "Eyl",
    gps_code: "HCME",
    iata_code: "HCM",
    coordinates: "49.82, 8.104",
  },
  {
    ident: "HCMF",
    type: "small_airport",
    name: "Bosaso Airport",
    elevation_ft: "3",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-BR",
    municipality: "Bosaso",
    gps_code: "HCMF",
    iata_code: "BSA",
    coordinates: "49.14939880371094, 11.275300025939941",
  },
  {
    ident: "HCMG",
    type: "small_airport",
    name: "Gardo Airport",
    elevation_ft: "2632",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-BR",
    municipality: "Gardo",
    gps_code: "HCMG",
    iata_code: "GSR",
    coordinates: "49.08300018310547, 9.517000198364258",
  },
  {
    ident: "HCMH",
    type: "large_airport",
    name: "Egal International Airport",
    elevation_ft: "4471",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-WO",
    municipality: "Hargeisa",
    gps_code: "HCMH",
    iata_code: "HGA",
    coordinates: "44.082389, 9.513207",
  },
  {
    ident: "HCMI",
    type: "medium_airport",
    name: "Berbera Airport",
    elevation_ft: "30",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-WO",
    municipality: "Berbera",
    gps_code: "HCMI",
    iata_code: "BBO",
    coordinates: "44.94110107421875, 10.389200210571289",
  },
  {
    ident: "HCMJ",
    type: "small_airport",
    name: "Lugh Ganane Airport",
    elevation_ft: "507",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-GE",
    municipality: "Luuq",
    gps_code: "HCMJ",
    iata_code: "LGX",
    coordinates: "42.5459, 3.8124",
  },
  {
    ident: "HCMK",
    type: "medium_airport",
    name: "Kisimayu Airport",
    elevation_ft: "49",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-JH",
    gps_code: "HCMK",
    iata_code: "KMU",
    coordinates: "42.45920181274414, -0.3773530125617981",
  },
  {
    ident: "HCMM",
    type: "medium_airport",
    name: "Aden Adde International Airport",
    elevation_ft: "29",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-BN",
    municipality: "Mogadishu",
    gps_code: "HCMM",
    iata_code: "MGQ",
    coordinates: "45.3046989440918, 2.0144400596618652",
  },
  {
    ident: "HCMN",
    type: "small_airport",
    name: "Beletwene Airport",
    elevation_ft: "559",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-HI",
    municipality: "Beledweyne",
    gps_code: "HCMN",
    iata_code: "BLW",
    coordinates: "45.2388, 4.766976",
  },
  {
    ident: "HCMO",
    type: "small_airport",
    name: "Obbia Airport",
    elevation_ft: "65",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-MU",
    municipality: "Obbia",
    gps_code: "HCMO",
    iata_code: "CMO",
    coordinates: "48.516666412353516, 5.366666793823242",
  },
  {
    ident: "HCMR",
    type: "small_airport",
    name: "Galcaio Airport",
    elevation_ft: "975",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-MU",
    municipality: "Galcaio",
    gps_code: "HCMR",
    iata_code: "GLK",
    coordinates: "47.45470047, 6.78082990646",
  },
  {
    ident: "HCMS",
    type: "small_airport",
    name: "Scusciuban Airport",
    elevation_ft: "1121",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-BR",
    municipality: "Scusciuban",
    gps_code: "HCMS",
    iata_code: "CMS",
    coordinates: "50.233001708984375, 10.300000190734863",
  },
  {
    ident: "HCMU",
    type: "small_airport",
    name: "Erigavo Airport",
    elevation_ft: "5720",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-SA",
    municipality: "Erigavo",
    gps_code: "HCMU",
    iata_code: "ERA",
    coordinates: "47.3879814148, 10.642050549",
  },
  {
    ident: "HCMV",
    type: "small_airport",
    name: "Burao Airport",
    elevation_ft: "3400",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-TO",
    municipality: "Burao",
    gps_code: "HCMV",
    iata_code: "BUO",
    coordinates: "45.5549, 9.5275",
  },
  {
    ident: "HCMW",
    type: "small_airport",
    name: "Garowe Airport",
    elevation_ft: "1465",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-NU",
    municipality: "Garowe",
    gps_code: "HCMW",
    iata_code: "GGR",
    coordinates: "48.567407, 8.457942",
  },
  {
    ident: "HDAM",
    type: "medium_airport",
    name: "Djibouti-Ambouli Airport",
    elevation_ft: "49",
    continent: "AF",
    iso_country: "DJ",
    iso_region: "DJ-DJ",
    municipality: "Djibouti City",
    gps_code: "HDAM",
    iata_code: "JIB",
    coordinates: "43.15950012207031, 11.547300338745117",
  },
  {
    ident: "HDAS",
    type: "small_airport",
    name: "Ali-Sabieh Airport",
    elevation_ft: "2313",
    continent: "AF",
    iso_country: "DJ",
    iso_region: "DJ-AS",
    municipality: "Ali-Sabieh",
    gps_code: "HDAS",
    iata_code: "AII",
    coordinates: "42.72, 11.146889",
  },
  {
    ident: "HDMO",
    type: "small_airport",
    name: "Moucha Airport",
    continent: "AF",
    iso_country: "DJ",
    iso_region: "DJ-DJ",
    municipality: "Moucha Island",
    gps_code: "HDMO",
    iata_code: "MHI",
    coordinates: "43.20000076293945, 11.716667175292969",
  },
  {
    ident: "HDOB",
    type: "small_airport",
    name: "Obock Airport",
    elevation_ft: "69",
    continent: "AF",
    iso_country: "DJ",
    iso_region: "DJ-OB",
    municipality: "Obock",
    gps_code: "HDOB",
    iata_code: "OBC",
    coordinates: "43.266998291015625, 11.967000007629395",
  },
  {
    ident: "HDTJ",
    type: "small_airport",
    name: "Tadjoura Airport",
    elevation_ft: "246",
    continent: "AF",
    iso_country: "DJ",
    iso_region: "DJ-TA",
    municipality: "Tadjoura",
    gps_code: "HDTJ",
    iata_code: "TDJ",
    coordinates: "42.91699981689453, 11.782999992370605",
  },
  {
    ident: "HE13",
    type: "medium_airport",
    name: "Capital International Airport",
    elevation_ft: "816",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-C",
    gps_code: "HECP",
    iata_code: "CCE",
    coordinates: "31.837643, 30.064358",
  },
  {
    ident: "HE24",
    type: "small_airport",
    name: "Siwa Oasis North Airport",
    elevation_ft: "330",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-MT",
    municipality: "Siwa",
    gps_code: "HE24",
    iata_code: "SEW",
    coordinates: "25.50670051574707, 29.345500946044922",
  },
  {
    ident: "HE25",
    type: "small_airport",
    name: "El Minya Airport",
    elevation_ft: "121",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-MN",
    municipality: "Minya",
    gps_code: "HEMN",
    iata_code: "EMY",
    coordinates: "30.730300903299998, 28.1012992859",
  },
  {
    ident: "HE40",
    type: "small_airport",
    name: "Sidi Barrani Airport",
    elevation_ft: "320",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-MT",
    municipality: "Sidi Barrani",
    iata_code: "SQK",
    coordinates: "25.878000259399, 31.466600418091",
  },
  {
    ident: "HEAL",
    type: "medium_airport",
    name: "El Alamein International Airport",
    elevation_ft: "143",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-MT",
    municipality: "El Alamein",
    gps_code: "HEAL",
    iata_code: "DBB",
    coordinates: "28.46139907836914, 30.92449951171875",
  },
  {
    ident: "HEAR",
    type: "medium_airport",
    name: "El Arish International Airport",
    elevation_ft: "121",
    continent: "AS",
    iso_country: "EG",
    iso_region: "EG-SIN",
    municipality: "El Arish",
    gps_code: "HEAR",
    iata_code: "AAC",
    coordinates: "33.8358001709, 31.073299408",
  },
  {
    ident: "HEAT",
    type: "medium_airport",
    name: "Asyut International Airport",
    elevation_ft: "772",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-AST",
    municipality: "Asyut",
    gps_code: "HEAT",
    iata_code: "ATZ",
    coordinates: "31.011999, 27.046499",
  },
  {
    ident: "HEAX",
    type: "medium_airport",
    name: "El Nouzha Airport",
    elevation_ft: "-6",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-ALX",
    municipality: "Alexandria",
    gps_code: "HEAX",
    iata_code: "ALY",
    coordinates: "29.94890022277832, 31.183900833129883",
  },
  {
    ident: "HEBA",
    type: "medium_airport",
    name: "Borg El Arab International Airport",
    elevation_ft: "177",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-ALX",
    municipality: "Alexandria",
    gps_code: "HEBA",
    iata_code: "HBE",
    coordinates: "29.696399688720703, 30.917699813842773",
  },
  {
    ident: "HEBL",
    type: "medium_airport",
    name: "Abu Simbel Airport",
    elevation_ft: "616",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-ASN",
    municipality: "Abu Simbel",
    gps_code: "HEBL",
    iata_code: "ABS",
    coordinates: "31.611700058, 22.375999450699997",
  },
  {
    ident: "HECA",
    type: "large_airport",
    name: "Cairo International Airport",
    elevation_ft: "382",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-C",
    municipality: "Cairo",
    gps_code: "HECA",
    iata_code: "CAI",
    coordinates: "31.40559959411621, 30.12190055847168",
  },
  {
    ident: "HEDK",
    type: "small_airport",
    name: "Dakhla Airport",
    elevation_ft: "613",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-WAD",
    gps_code: "HEDK",
    iata_code: "DAK",
    coordinates: "29.00309944152832, 25.41160011291504",
  },
  {
    ident: "HEGN",
    type: "large_airport",
    name: "Hurghada International Airport",
    elevation_ft: "52",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-BA",
    municipality: "Hurghada",
    gps_code: "HEGN",
    iata_code: "HRG",
    coordinates: "33.799400329589844, 27.178300857543945",
  },
  {
    ident: "HEGR",
    type: "medium_airport",
    name: "El Gora Airport",
    elevation_ft: "324",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-SIN",
    municipality: "El Gora",
    gps_code: "HEGR",
    iata_code: "EGH",
    coordinates: "34.129629, 31.068559",
  },
  {
    ident: "HEKG",
    type: "small_airport",
    name: "El Kharga Airport",
    elevation_ft: "192",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-WAD",
    gps_code: "HEKG",
    iata_code: "UVL",
    coordinates: "30.590700149536133, 25.473600387573242",
  },
  {
    ident: "HELX",
    type: "large_airport",
    name: "Luxor International Airport",
    elevation_ft: "294",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-KN",
    municipality: "Luxor",
    gps_code: "HELX",
    iata_code: "LXR",
    coordinates: "32.7066, 25.671",
  },
  {
    ident: "HEMA",
    type: "medium_airport",
    name: "Marsa Alam International Airport",
    elevation_ft: "251",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-BA",
    municipality: "Marsa Alam",
    gps_code: "HEMA",
    iata_code: "RMF",
    coordinates: "34.5836982727, 25.557100296",
  },
  {
    ident: "HEMK",
    type: "medium_airport",
    name: "Sohag International Airport",
    elevation_ft: "310",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-SHG",
    municipality: "Sohag",
    gps_code: "HESG",
    iata_code: "HMB",
    coordinates: "31.742778, 26.342778",
  },
  {
    ident: "HEMM",
    type: "medium_airport",
    name: "Mersa Matruh Airport",
    elevation_ft: "94",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-MT",
    municipality: "Mersa Matruh",
    gps_code: "HEMM",
    iata_code: "MUH",
    coordinates: "27.221700668300002, 31.3253993988",
  },
  {
    ident: "HEO",
    type: "small_airport",
    name: "Haelogo Airport",
    elevation_ft: "3217",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Haelogo",
    gps_code: "AYHG",
    iata_code: "HEO",
    local_code: "HLG",
    coordinates: "147.598434, -9.13658",
  },
  {
    ident: "HEOW",
    type: "small_airport",
    name: "El Oweinat East International Airport",
    elevation_ft: "859",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-WAD",
    gps_code: "HEOW",
    iata_code: "GSQ",
    coordinates: "28.7166, 22.585699",
  },
  {
    ident: "HEPS",
    type: "medium_airport",
    name: "Port Said Airport",
    elevation_ft: "8",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-SHR",
    municipality: "Port Said",
    gps_code: "HEPS",
    iata_code: "PSD",
    coordinates: "32.2400016784668, 31.279399871826172",
  },
  {
    ident: "HESC",
    type: "medium_airport",
    name: "St Catherine International Airport",
    elevation_ft: "4368",
    continent: "AS",
    iso_country: "EG",
    iso_region: "EG-JS",
    gps_code: "HESC",
    iata_code: "SKV",
    coordinates: "34.0625, 28.685300827",
  },
  {
    ident: "HESH",
    type: "medium_airport",
    name: "Sharm El Sheikh International Airport",
    elevation_ft: "143",
    continent: "AS",
    iso_country: "EG",
    iso_region: "EG-JS",
    municipality: "Sharm el-Sheikh",
    gps_code: "HESH",
    iata_code: "SSH",
    coordinates: "34.3950004578, 27.9773006439",
  },
  {
    ident: "HESN",
    type: "medium_airport",
    name: "Aswan International Airport",
    elevation_ft: "662",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-ASN",
    municipality: "Aswan",
    gps_code: "HESN",
    iata_code: "ASW",
    coordinates: "32.8199996948, 23.9643993378",
  },
  {
    ident: "HESX",
    type: "medium_airport",
    name: "Sphinx International Airport",
    elevation_ft: "553",
    continent: "AF",
    iso_country: "EG",
    iso_region: "EG-GZ",
    municipality: "Giza",
    gps_code: "HESX",
    iata_code: "SPX",
    coordinates: "30.89569, 30.108148",
  },
  {
    ident: "HETB",
    type: "medium_airport",
    name: "Taba International Airport",
    elevation_ft: "2415",
    continent: "AS",
    iso_country: "EG",
    iso_region: "EG-SIN",
    municipality: "Taba",
    gps_code: "HETB",
    iata_code: "TCP",
    coordinates: "34.7780990601, 29.587799072299998",
  },
  {
    ident: "HETR",
    type: "small_airport",
    name: "El Tor Airport",
    elevation_ft: "115",
    continent: "AS",
    iso_country: "EG",
    iso_region: "EG-JS",
    gps_code: "HETR",
    iata_code: "ELT",
    coordinates: "33.64550018310547, 28.208999633789062",
  },
  {
    ident: "HEY",
    type: "heliport",
    name: "Hanchey Army (Fort Rucker) Heliport",
    elevation_ft: "317",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Fort Rucker Ozark",
    gps_code: "KHEY",
    iata_code: "HEY",
    local_code: "HEY",
    coordinates: "-85.65429688, 31.34600067",
  },
  {
    ident: "HGT",
    type: "heliport",
    name: "Tusi AHP (Hunter Liggett) Heliport",
    elevation_ft: "1017",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Fort Hunter Ligget Jolon",
    gps_code: "KHGT",
    iata_code: "HGT",
    local_code: "HGT",
    coordinates: "-121.2369995, 35.99349976",
  },
  {
    ident: "HHAS",
    type: "medium_airport",
    name: "Asmara International Airport",
    elevation_ft: "7661",
    continent: "AF",
    iso_country: "ER",
    iso_region: "ER-SK",
    municipality: "Asmara",
    gps_code: "HHAS",
    iata_code: "ASM",
    coordinates: "38.910701751708984, 15.291899681091309",
  },
  {
    ident: "HHMS",
    type: "medium_airport",
    name: "Massawa International Airport",
    elevation_ft: "194",
    continent: "AF",
    iso_country: "ER",
    iso_region: "ER-SK",
    municipality: "Massawa",
    gps_code: "HHMS",
    iata_code: "MSW",
    coordinates: "39.37009811401367, 15.670000076293945",
  },
  {
    ident: "HHSB",
    type: "medium_airport",
    name: "Assab International Airport",
    elevation_ft: "46",
    continent: "AF",
    iso_country: "ER",
    iso_region: "ER-DK",
    municipality: "Asab",
    gps_code: "HHSB",
    iata_code: "ASA",
    coordinates: "42.64500045776367, 13.071800231933594",
  },
  {
    ident: "HHTS",
    type: "small_airport",
    name: "Tessenei Airport",
    elevation_ft: "2018",
    continent: "AF",
    iso_country: "ER",
    iso_region: "ER-GB",
    municipality: "Tessenei",
    gps_code: "HHTS",
    iata_code: "TES",
    coordinates: "36.681711, 15.104322",
  },
  {
    ident: "HI01",
    type: "small_airport",
    name: "Princeville Airport",
    elevation_ft: "344",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Hanalei",
    gps_code: "HI01",
    iata_code: "HPV",
    local_code: "HI01",
    coordinates: "-159.445999146, 22.209199905400002",
  },
  {
    ident: "HI07",
    type: "heliport",
    name: "Waikoloa Heliport",
    elevation_ft: "119",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Waikoloa Village",
    gps_code: "HI07",
    iata_code: "WKL",
    local_code: "HI07",
    coordinates: "-155.8607, 19.9205",
  },
  {
    ident: "HIA",
    type: "medium_airport",
    name: "Lianshui Airport",
    elevation_ft: "28",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Huai'an",
    gps_code: "ZSSH",
    iata_code: "HIA",
    coordinates: "119.125, 33.7908333333",
  },
  {
    ident: "HIL",
    type: "small_airport",
    name: "Shilavo Airport",
    elevation_ft: "1296",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SO",
    municipality: "Shilavo",
    gps_code: "HASL",
    iata_code: "HIL",
    coordinates: "44.766700744599994, 6.08333015442",
  },
  {
    ident: "HKAM",
    type: "medium_airport",
    name: "Amboseli Airport",
    elevation_ft: "3755",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Amboseli National Park",
    gps_code: "HKAM",
    iata_code: "ASV",
    coordinates: "37.25310134887695, -2.645050048828125",
  },
  {
    ident: "HKB",
    type: "small_airport",
    name: "Healy Lake Airport",
    elevation_ft: "1180",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Healy Lake",
    iata_code: "HKB",
    coordinates: "-144.6926, 63.9958",
  },
  {
    ident: "HKEL",
    type: "medium_airport",
    name: "Eldoret International Airport",
    elevation_ft: "6941",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Eldoret",
    gps_code: "HKEL",
    iata_code: "EDL",
    coordinates: "35.23889923095703, 0.4044579863548279",
  },
  {
    ident: "HKES",
    type: "small_airport",
    name: "Eliye Springs Airport",
    elevation_ft: "1395",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Eliye Springs",
    gps_code: "HKES",
    iata_code: "EYS",
    coordinates: "35.96670150756836, 3.216670036315918",
  },
  {
    ident: "HKFG",
    type: "small_airport",
    name: "Kalokol Airport",
    elevation_ft: "1245",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Kalokol",
    gps_code: "HKFG",
    iata_code: "KLK",
    coordinates: "35.836777, 3.49161",
  },
  {
    ident: "HKGA",
    type: "small_airport",
    name: "Garissa Airport",
    elevation_ft: "475",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-500",
    municipality: "Garissa",
    gps_code: "HKGA",
    iata_code: "GAS",
    coordinates: "39.64830017089844, -0.4635080099105835",
  },
  {
    ident: "HKHO",
    type: "small_airport",
    name: "Hola Airport",
    elevation_ft: "195",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    municipality: "Hola",
    gps_code: "HKHO",
    iata_code: "HOA",
    coordinates: "40.00400161743164, -1.5219999551773071",
  },
  {
    ident: "HKJK",
    type: "large_airport",
    name: "Jomo Kenyatta International Airport",
    elevation_ft: "5330",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-110",
    municipality: "Nairobi",
    gps_code: "HKJK",
    iata_code: "NBO",
    coordinates: "36.9277992249, -1.31923997402",
  },
  {
    ident: "HKKE",
    type: "small_airport",
    name: "Keekorok Airport",
    elevation_ft: "5800",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Keekorok",
    gps_code: "HKKE",
    iata_code: "KEU",
    coordinates: "35.25, -1.583",
  },
  {
    ident: "HKKG",
    type: "small_airport",
    name: "Kakamega Airport",
    elevation_ft: "5020",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-900",
    municipality: "Kakamega",
    gps_code: "HKKG",
    iata_code: "GGM",
    coordinates: "34.7873001099, 0.271342009306",
  },
  {
    ident: "HKKI",
    type: "medium_airport",
    name: "Kisumu Airport",
    elevation_ft: "3734",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-600",
    municipality: "Kisumu",
    gps_code: "HKKI",
    iata_code: "KIS",
    coordinates: "34.72890090942383, -0.0861390009522438",
  },
  {
    ident: "HKKL",
    type: "small_airport",
    name: "Kilaguni Airport",
    elevation_ft: "2750",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    municipality: "Kilaguni",
    gps_code: "HKKL",
    iata_code: "ILU",
    coordinates: "38.06520080566406, -2.9106099605560303",
  },
  {
    ident: "HKKR",
    type: "small_airport",
    name: "Kericho Airport",
    elevation_ft: "6446",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Kericho",
    gps_code: "HKKR",
    iata_code: "KEY",
    coordinates: "35.242093, -0.3899",
  },
  {
    ident: "HKKT",
    type: "medium_airport",
    name: "Kitale Airport",
    elevation_ft: "6070",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Kitale",
    gps_code: "HKKT",
    iata_code: "KTL",
    coordinates: "34.95859909057617, 0.9719889760017395",
  },
  {
    ident: "HKLK",
    type: "medium_airport",
    name: "Lokichoggio Airport",
    elevation_ft: "2074",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Lokichoggio",
    gps_code: "HKLK",
    iata_code: "LKG",
    coordinates: "34.348201751708984, 4.20412015914917",
  },
  {
    ident: "HKLO",
    type: "medium_airport",
    name: "Lodwar Airport",
    elevation_ft: "1715",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Lodwar",
    gps_code: "HKLO",
    iata_code: "LOK",
    coordinates: "35.608699798583984, 3.1219699382781982",
  },
  {
    ident: "HKLU",
    type: "medium_airport",
    name: "Manda Airstrip",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    municipality: "Lamu",
    gps_code: "HKLU",
    iata_code: "LAU",
    coordinates: "40.91310119628906, -2.252419948577881",
  },
  {
    ident: "HKLY",
    type: "small_airport",
    name: "Loyengalani Airport",
    elevation_ft: "1195",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-400",
    municipality: "Loyengalani",
    gps_code: "HKLY",
    iata_code: "LOY",
    coordinates: "36.71699905395508, 2.75",
  },
  {
    ident: "HKMA",
    type: "small_airport",
    name: "Mandera Airport",
    elevation_ft: "805",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-500",
    municipality: "Mandera",
    gps_code: "HKMA",
    iata_code: "NDE",
    coordinates: "41.849998474121094, 3.933000087738037",
  },
  {
    ident: "HKMB",
    type: "small_airport",
    name: "Marsabit Airport",
    elevation_ft: "4395",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-400",
    municipality: "Marsabit",
    gps_code: "HKMB",
    iata_code: "RBT",
    coordinates: "37.999996, 2.344254",
  },
  {
    ident: "HKML",
    type: "medium_airport",
    name: "Malindi Airport",
    elevation_ft: "80",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    municipality: "Malindi",
    gps_code: "HKML",
    iata_code: "MYD",
    coordinates: "40.10169982910156, -3.2293100357055664",
  },
  {
    ident: "HKMO",
    type: "large_airport",
    name: "Mombasa Moi International Airport",
    elevation_ft: "200",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    municipality: "Mombasa",
    gps_code: "HKMO",
    iata_code: "MBA",
    coordinates: "39.594200134277344, -4.034830093383789",
  },
  {
    ident: "HKMS",
    type: "medium_airport",
    name: "Mara Serena Lodge Airstrip",
    elevation_ft: "5200",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Masai Mara",
    gps_code: "HKMS",
    iata_code: "MRE",
    coordinates: "35.008057, -1.406111",
  },
  {
    ident: "HKMY",
    type: "small_airport",
    name: "Moyale Airport",
    elevation_ft: "2790",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-400",
    municipality: "Moyale (Lower)",
    gps_code: "HKMY",
    iata_code: "OYL",
    coordinates: "39.101398, 3.46972",
  },
  {
    ident: "HKNI",
    type: "small_airport",
    name: "Nyeri Airport",
    elevation_ft: "5830",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-200",
    municipality: "Nyeri",
    gps_code: "HKNI",
    iata_code: "NYE",
    coordinates: "36.978485107421875, -0.3644140064716339",
  },
  {
    ident: "HKNK",
    type: "small_airport",
    name: "Nakuru Airport",
    elevation_ft: "6234",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Nakuru",
    gps_code: "HKNK",
    iata_code: "NUU",
    coordinates: "36.159302, -0.298067",
  },
  {
    ident: "HKNW",
    type: "medium_airport",
    name: "Nairobi Wilson Airport",
    elevation_ft: "5536",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-110",
    municipality: "Nairobi",
    gps_code: "HKNW",
    iata_code: "WIL",
    coordinates: "36.81480026245117, -1.321720004081726",
  },
  {
    ident: "HKNY",
    type: "medium_airport",
    name: "Nanyuki Airport",
    elevation_ft: "6250",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-200",
    municipality: "Nanyuki",
    gps_code: "HKNL",
    iata_code: "NYK",
    coordinates: "37.041008, -0.062399",
  },
  {
    ident: "HKSB",
    type: "small_airport",
    name: "Buffalo Spring",
    elevation_ft: "3295",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Samburu",
    gps_code: "HKSB",
    iata_code: "UAS",
    coordinates: "37.534195, 0.530583",
  },
  {
    ident: "HKUK",
    type: "small_airport",
    name: "Ukunda Airstrip",
    elevation_ft: "98",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    municipality: "Ukunda",
    gps_code: "HKUK",
    iata_code: "UKA",
    coordinates: "39.571098, -4.29333",
  },
  {
    ident: "HKWJ",
    type: "medium_airport",
    name: "Wajir Airport",
    elevation_ft: "770",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-500",
    municipality: "Wajir",
    gps_code: "HKWJ",
    iata_code: "WJR",
    coordinates: "40.091599, 1.73324",
  },
  {
    ident: "HLGD",
    type: "medium_airport",
    name: "Gardabya Airport",
    elevation_ft: "267",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-SR",
    municipality: "Sirt",
    gps_code: "HLGD",
    iata_code: "SRX",
    coordinates: "16.5949993134, 31.063499450699997",
  },
  {
    ident: "HLGN",
    type: "small_airport",
    name: "Gamal Abdel Nasser Airport",
    elevation_ft: "519",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-BU",
    municipality: "Tobruk",
    gps_code: "HLGN",
    iata_code: "TOB",
    coordinates: "23.907, 31.861",
  },
  {
    ident: "HLGT",
    type: "medium_airport",
    name: "Ghat Airport",
    elevation_ft: "2296",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-GT",
    municipality: "Ghat",
    gps_code: "HLGT",
    iata_code: "GHT",
    coordinates: "10.142600059500001, 25.1455993652",
  },
  {
    ident: "HLKF",
    type: "medium_airport",
    name: "Kufra Airport",
    elevation_ft: "1367",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-KF",
    municipality: "Kufra",
    gps_code: "HLKF",
    iata_code: "AKF",
    coordinates: "23.31399917602539, 24.178699493408203",
  },
  {
    ident: "HLLB",
    type: "medium_airport",
    name: "Benina International Airport",
    elevation_ft: "433",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-BA",
    municipality: "Benghazi",
    gps_code: "HLLB",
    iata_code: "BEN",
    coordinates: "20.2695007324, 32.096801757799994",
  },
  {
    ident: "HLLM",
    type: "medium_airport",
    name: "Mitiga Airport",
    elevation_ft: "36",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-TB",
    municipality: "Tripoli",
    gps_code: "HLLM",
    iata_code: "MJI",
    coordinates: "13.276000022888184, 32.894100189208984",
  },
  {
    ident: "HLLQ",
    type: "medium_airport",
    name: "La Abraq Airport",
    elevation_ft: "2157",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-JA",
    municipality: "Al Bayda'",
    gps_code: "HLLQ",
    iata_code: "LAQ",
    coordinates: "21.96430015563965, 32.788700103759766",
  },
  {
    ident: "HLLS",
    type: "medium_airport",
    name: "Sabha Airport",
    elevation_ft: "1427",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-SB",
    municipality: "Sabha",
    gps_code: "HLLS",
    iata_code: "SEB",
    coordinates: "14.47249984741211, 26.98699951171875",
  },
  {
    ident: "HLLT",
    type: "large_airport",
    name: "Tripoli International Airport",
    elevation_ft: "263",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-TB",
    municipality: "Tripoli",
    gps_code: "HLLT",
    iata_code: "TIP",
    coordinates: "13.1590003967, 32.6635017395",
  },
  {
    ident: "HLMB",
    type: "medium_airport",
    name: "Marsa Brega Airport",
    elevation_ft: "50",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-WA",
    gps_code: "HLMB",
    iata_code: "LMQ",
    coordinates: "19.576400756835938, 30.37809944152832",
  },
  {
    ident: "HLNR",
    type: "small_airport",
    name: "Nafurah 1 Airport",
    elevation_ft: "125",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-WA",
    municipality: "Nafurah 1",
    gps_code: "HLNR",
    iata_code: "NFR",
    coordinates: "21.5924, 29.2132",
  },
  {
    ident: "HLON",
    type: "small_airport",
    name: "Hon Airport",
    elevation_ft: "919",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-JU",
    gps_code: "HLON",
    iata_code: "HUQ",
    coordinates: "15.9656, 29.1101",
  },
  {
    ident: "HLTD",
    type: "medium_airport",
    name: "Ghadames East Airport",
    elevation_ft: "1122",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-NL",
    municipality: "Ghadames",
    gps_code: "HLTD",
    iata_code: "LTD",
    coordinates: "9.715310096740723, 30.15169906616211",
  },
  {
    ident: "HLZN",
    type: "small_airport",
    name: "Alzintan Airport",
    elevation_ft: "2080",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-JG",
    municipality: "Zintan",
    gps_code: "HLZN",
    iata_code: "ZIS",
    coordinates: "12.25006, 31.774878",
  },
  {
    ident: "HLZW",
    type: "small_airport",
    name: "Zwara Airport",
    elevation_ft: "9",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-NQ",
    municipality: "Zuwara",
    gps_code: "HLZW",
    iata_code: "WAX",
    local_code: "HL75",
    coordinates: "12.0155, 32.952301",
  },
  {
    ident: "HN-MUG",
    type: "small_airport",
    name: "Erandique Airport",
    elevation_ft: "4199",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-LE",
    municipality: "Erandique",
    gps_code: "MHGU",
    iata_code: "EDQ",
    coordinates: "-88.437225, 14.235833",
  },
  {
    ident: "HOO",
    type: "small_airport",
    name: "Nhon Co Airfield",
    elevation_ft: "2270",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-U-A",
    municipality: "Quang Duc",
    iata_code: "HOO",
    coordinates: "107.5638, 11.9787",
  },
  {
    ident: "HRC",
    type: "small_airport",
    name: "Sary Su Airport",
    elevation_ft: "1307",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-KAR",
    municipality: "Zhayrem",
    iata_code: "HRC",
    coordinates: "70.1915, 48.3974",
  },
  {
    ident: "HRYG",
    type: "medium_airport",
    name: "Gisenyi Airport",
    elevation_ft: "5082",
    continent: "AF",
    iso_country: "RW",
    iso_region: "RW-04",
    municipality: "Gisenyi",
    gps_code: "HRYG",
    iata_code: "GYI",
    coordinates: "29.258899688720703, -1.6771999597549438",
  },
  {
    ident: "HRYI",
    type: "small_airport",
    name: "Butare Airport",
    elevation_ft: "5801",
    continent: "AF",
    iso_country: "RW",
    iso_region: "RW-05",
    municipality: "Butare",
    gps_code: "HRYI",
    iata_code: "BTQ",
    coordinates: "29.7367000579834, -2.595829963684082",
  },
  {
    ident: "HRYR",
    type: "large_airport",
    name: "Kigali International Airport",
    elevation_ft: "4859",
    continent: "AF",
    iso_country: "RW",
    iso_region: "RW-01",
    municipality: "Kigali",
    gps_code: "HRYR",
    iata_code: "KGL",
    coordinates: "30.1395, -1.96863",
  },
  {
    ident: "HRYU",
    type: "small_airport",
    name: "Ruhengeri Airport",
    elevation_ft: "6102",
    continent: "AF",
    iso_country: "RW",
    iso_region: "RW-03",
    municipality: "Ruhengeri",
    gps_code: "HRYU",
    iata_code: "RHG",
    coordinates: "29.632999420166016, -1.5",
  },
  {
    ident: "HRZA",
    type: "medium_airport",
    name: "Kamembe Airport",
    elevation_ft: "5192",
    continent: "AF",
    iso_country: "RW",
    iso_region: "RW-04",
    municipality: "Kamembe",
    gps_code: "HRZA",
    iata_code: "KME",
    coordinates: "28.907899856567383, -2.462239980697632",
  },
  {
    ident: "HSAT",
    type: "small_airport",
    name: "Atbara Airport",
    elevation_ft: "1181",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-04",
    municipality: "Atbara",
    gps_code: "HSAT",
    iata_code: "ATB",
    coordinates: "34.0570182800293, 17.710344314575195",
  },
  {
    ident: "HSDB",
    type: "small_airport",
    name: "El Debba Airport",
    elevation_ft: "843",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-01",
    municipality: "El Debba",
    gps_code: "HSDB",
    iata_code: "EDB",
    coordinates: "30.9595, 18.0146",
  },
  {
    ident: "HSDN",
    type: "medium_airport",
    name: "Dongola Airport",
    elevation_ft: "772",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-01",
    municipality: "Dongola",
    gps_code: "HSDN",
    iata_code: "DOG",
    coordinates: "30.430099487299998, 19.153900146499996",
  },
  {
    ident: "HSDZ",
    type: "small_airport",
    name: "Damazin Airport",
    elevation_ft: "1582",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-24",
    municipality: "Ad Damazin",
    gps_code: "HSDZ",
    iata_code: "RSS",
    coordinates: "34.3367, 11.7859",
  },
  {
    ident: "HSFA",
    type: "medium_airport",
    name: "Paloich Airport, Heliport",
    elevation_ft: "1290",
    continent: "AF",
    iso_country: "SS",
    iso_region: "SS-23",
    municipality: "Higleig",
    gps_code: "HSFA",
    iata_code: "HGI",
    coordinates: "32.500556, 10.529167",
  },
  {
    ident: "HSFS",
    type: "medium_airport",
    name: "El Fasher Airport",
    elevation_ft: "2393",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-02",
    municipality: "El Fasher",
    gps_code: "HSFS",
    iata_code: "ELF",
    coordinates: "25.324600219726562, 13.614899635314941",
  },
  {
    ident: "HSGF",
    type: "small_airport",
    name: "Azaza Airport",
    elevation_ft: "1640",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-06",
    municipality: "Gedaref",
    gps_code: "HSGF",
    iata_code: "GSU",
    coordinates: "35.31700134277344, 14.133000373840332",
  },
  {
    ident: "HSGG",
    type: "small_airport",
    name: "Galegu Airport",
    elevation_ft: "1640",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-25",
    municipality: "Dinder",
    gps_code: "HSGG",
    iata_code: "DNX",
    coordinates: "35.06700134277344, 12.532999992370605",
  },
  {
    ident: "HSGN",
    type: "small_airport",
    name: "Geneina Airport",
    elevation_ft: "2650",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-12",
    municipality: "Geneina",
    gps_code: "HSGN",
    iata_code: "EGN",
    coordinates: "22.467199325561523, 13.48169994354248",
  },
  {
    ident: "HSHG",
    type: "small_airport",
    name: "Heglig Airport",
    elevation_ft: "1325",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-13",
    municipality: "Heglig Oilfield",
    gps_code: "HSHG",
    iata_code: "HEG",
    coordinates: "29.397718, 9.994933",
  },
  {
    ident: "HSJ",
    type: "small_airport",
    name: "Shangjie Airport",
    elevation_ft: "450",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-41",
    municipality: "Zhengzhou",
    iata_code: "HSJ",
    coordinates: "113.273983, 34.842153",
  },
  {
    ident: "HSKA",
    type: "medium_airport",
    name: "Kassala Airport",
    elevation_ft: "1671",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-05",
    municipality: "Kassala",
    gps_code: "HSKA",
    iata_code: "KSL",
    coordinates: "36.328800201416016, 15.387499809265137",
  },
  {
    ident: "HSKG",
    type: "small_airport",
    name: "Khashm El Girba Airport",
    elevation_ft: "1560",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-06",
    municipality: "Khashm El Girba",
    gps_code: "HSKG",
    iata_code: "GBU",
    coordinates: "35.87799835205078, 14.925000190734863",
  },
  {
    ident: "HSKI",
    type: "closed",
    name: "Kosti Airport",
    elevation_ft: "1289",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-08",
    municipality: "Kosti",
    gps_code: "HSKI",
    iata_code: "KST",
    coordinates: "32.733002, 13.183",
  },
  {
    ident: "HSLI",
    type: "small_airport",
    name: "Kadugli Airport",
    elevation_ft: "1848",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-13",
    municipality: "Kadugli",
    gps_code: "HSLI",
    iata_code: "KDX",
    coordinates: "29.7010993958, 11.137999534600002",
  },
  {
    ident: "HSMK",
    type: "small_airport",
    name: "Rumbek Airport",
    elevation_ft: "1378",
    continent: "AF",
    iso_country: "SS",
    iso_region: "SS-18",
    municipality: "Rumbek",
    gps_code: "HSMK",
    iata_code: "RBX",
    coordinates: "29.6690006256, 6.8249998092699995",
  },
  {
    ident: "HSMN",
    type: "medium_airport",
    name: "Merowe New Airport",
    elevation_ft: "897",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-01",
    municipality: "Merowe",
    gps_code: "HSMN",
    iata_code: "MWE",
    coordinates: "31.8433333333, 18.4433333333",
  },
  {
    ident: "HSNH",
    type: "small_airport",
    name: "En Nahud Airport",
    elevation_ft: "1955",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-09",
    municipality: "En Nahud",
    gps_code: "HSNH",
    iata_code: "NUD",
    coordinates: "28.33300018310547, 12.666999816894531",
  },
  {
    ident: "HSNN",
    type: "small_airport",
    name: "Nyala Airport",
    elevation_ft: "2106",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-11",
    municipality: "Nyala",
    gps_code: "HSNN",
    iata_code: "UYL",
    coordinates: "24.956199645996094, 12.053500175476074",
  },
  {
    ident: "HSNW",
    type: "small_airport",
    name: "New Halfa Airport",
    elevation_ft: "1480",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-05",
    municipality: "New Halfa",
    gps_code: "HSNW",
    iata_code: "NHF",
    coordinates: "35.727798, 15.3556",
  },
  {
    ident: "HSOB",
    type: "medium_airport",
    name: "El Obeid Airport",
    elevation_ft: "1927",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-09",
    municipality: "Al-Ubayyid",
    gps_code: "HSOB",
    iata_code: "EBD",
    coordinates: "30.23270034790039, 13.153200149536133",
  },
  {
    ident: "HSPN",
    type: "medium_airport",
    name: "Port Sudan New International Airport",
    elevation_ft: "135",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-26",
    municipality: "Port Sudan",
    gps_code: "HSPN",
    iata_code: "PZU",
    coordinates: "37.234100341796875, 19.4335994720459",
  },
  {
    ident: "HSSJ",
    type: "large_airport",
    name: "Juba International Airport",
    elevation_ft: "1513",
    continent: "AF",
    iso_country: "SS",
    iso_region: "SS-17",
    municipality: "Juba",
    gps_code: "HSSJ",
    iata_code: "JUB",
    coordinates: "31.6011009216, 4.87201023102",
  },
  {
    ident: "HSSM",
    type: "medium_airport",
    name: "Malakal Airport",
    elevation_ft: "1291",
    continent: "AF",
    iso_country: "SS",
    iso_region: "SS-23",
    municipality: "Malakal",
    gps_code: "HSSM",
    iata_code: "MAK",
    coordinates: "31.65220069885254, 9.55897045135498",
  },
  {
    ident: "HSSS",
    type: "large_airport",
    name: "Khartoum International Airport",
    elevation_ft: "1265",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-03",
    municipality: "Khartoum",
    gps_code: "HSSS",
    iata_code: "KRT",
    coordinates: "32.553199768066406, 15.589500427246094",
  },
  {
    ident: "HSSW",
    type: "small_airport",
    name: "Wadi Halfa Airport",
    elevation_ft: "933",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-01",
    municipality: "Wadi Halfa",
    gps_code: "HSSW",
    iata_code: "WHF",
    coordinates: "31.521578, 21.802698",
  },
  {
    ident: "HSWD",
    type: "small_airport",
    name: "Wad Medani Airport",
    elevation_ft: "1350",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-07",
    municipality: "Wad Medani",
    gps_code: "HSWD",
    iata_code: "DNI",
    coordinates: "33.526387, 14.383605",
  },
  {
    ident: "HSWW",
    type: "medium_airport",
    name: "Wau Airport",
    elevation_ft: "1529",
    continent: "AF",
    iso_country: "SS",
    iso_region: "SS-14",
    municipality: "Wau",
    gps_code: "HSWW",
    iata_code: "WUU",
    coordinates: "27.9750003815, 7.7258300781199996",
  },
  {
    ident: "HSZA",
    type: "small_airport",
    name: "Zalingei Airport",
    elevation_ft: "2953",
    continent: "AF",
    iso_country: "SD",
    iso_region: "SD-12",
    municipality: "Zalingei",
    gps_code: "HSZA",
    iata_code: "ZLX",
    coordinates: "23.5631, 12.9437",
  },
  {
    ident: "HTAR",
    type: "medium_airport",
    name: "Arusha Airport",
    elevation_ft: "4550",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-01",
    municipality: "Arusha",
    gps_code: "HTAR",
    iata_code: "ARK",
    coordinates: "36.63330078125, -3.3677899837493896",
  },
  {
    ident: "HTBU",
    type: "small_airport",
    name: "Bukoba Airport",
    elevation_ft: "3784",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-05",
    municipality: "Bukoba",
    gps_code: "HTBU",
    iata_code: "BKZ",
    coordinates: "31.8212, -1.332",
  },
  {
    ident: "HTDA",
    type: "large_airport",
    name: "Julius Nyerere International Airport",
    elevation_ft: "182",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-02",
    municipality: "Dar es Salaam",
    gps_code: "HTDA",
    iata_code: "DAR",
    coordinates: "39.202599, -6.87811",
  },
  {
    ident: "HTDO",
    type: "medium_airport",
    name: "Dodoma Airport",
    elevation_ft: "3673",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-03",
    municipality: "Dodoma",
    gps_code: "HTDO",
    iata_code: "DOD",
    coordinates: "35.752601623535156, -6.170440196990967",
  },
  {
    ident: "HTGW",
    type: "medium_airport",
    name: "Songwe Airport",
    elevation_ft: "4412",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-14",
    municipality: "Mbeya",
    gps_code: "HTGW",
    iata_code: "MBI",
    coordinates: "33.273981, -8.919942",
  },
  {
    ident: "HTIR",
    type: "medium_airport",
    name: "Iringa Airport",
    elevation_ft: "4678",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-04",
    municipality: "Nduli",
    gps_code: "HTIR",
    iata_code: "IRI",
    coordinates: "35.75210189819336, -7.668630123138428",
  },
  {
    ident: "HTKA",
    type: "small_airport",
    name: "Kigoma Airport",
    elevation_ft: "2700",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-08",
    municipality: "Kigoma",
    gps_code: "HTKA",
    iata_code: "TKQ",
    coordinates: "29.6709, -4.8862",
  },
  {
    ident: "HTKI",
    type: "small_airport",
    name: "Kilwa Masoko Airport",
    elevation_ft: "50",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-12",
    municipality: "Kilwa Masoko",
    gps_code: "HTKI",
    iata_code: "KIY",
    coordinates: "39.508619, -8.91123",
  },
  {
    ident: "HTKJ",
    type: "medium_airport",
    name: "Kilimanjaro International Airport",
    elevation_ft: "2932",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-26",
    municipality: "Arusha",
    gps_code: "HTKJ",
    iata_code: "JRO",
    coordinates: "37.0745010376, -3.42940998077",
  },
  {
    ident: "HTLI",
    type: "small_airport",
    name: "Lindi Airport",
    elevation_ft: "100",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-12",
    municipality: "Lindi",
    gps_code: "HTLI",
    iata_code: "LDI",
    coordinates: "39.757801, -9.85111",
  },
  {
    ident: "HTLM",
    type: "medium_airport",
    name: "Lake Manyara Airport",
    elevation_ft: "4150",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-26",
    municipality: "Lake Manyara National Park",
    gps_code: "HTLM",
    iata_code: "LKY",
    coordinates: "35.81829833984375, -3.376310110092163",
  },
  {
    ident: "HTM",
    type: "small_airport",
    name: "Khatgal Airport",
    elevation_ft: "5500",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-041",
    municipality: "Hatgal",
    gps_code: "ZMHG",
    iata_code: "HTM",
    coordinates: "100.139532, 50.435988",
  },
  {
    ident: "HTMA",
    type: "small_airport",
    name: "Mafia Island Airport",
    elevation_ft: "60",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-19",
    municipality: "Mafia Island",
    gps_code: "HTMA",
    iata_code: "MFA",
    coordinates: "39.668502, -7.917478",
  },
  {
    ident: "HTMD",
    type: "small_airport",
    name: "Mwadui Airport",
    elevation_ft: "3970",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-22",
    municipality: "Mwadui",
    gps_code: "HTMD",
    iata_code: "MWN",
    coordinates: "33.615542, -3.521332",
  },
  {
    ident: "HTMI",
    type: "small_airport",
    name: "Masasi Airport",
    elevation_ft: "1700",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-17",
    municipality: "Masasi",
    gps_code: "HTMI",
    iata_code: "XMI",
    coordinates: "38.766998291015625, -10.732999801635742",
  },
  {
    ident: "HTMP",
    type: "small_airport",
    name: "Mpanda Airport",
    elevation_ft: "3520",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-20",
    municipality: "Mpanda",
    gps_code: "HTMP",
    iata_code: "NPY",
    coordinates: "31.084116, -6.355374",
  },
  {
    ident: "HTMS",
    type: "small_airport",
    name: "Moshi Airport",
    elevation_ft: "2801",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-09",
    municipality: "Moshi",
    gps_code: "HTMS",
    iata_code: "QSI",
    coordinates: "37.326900482177734, -3.3633298873901367",
  },
  {
    ident: "HTMT",
    type: "medium_airport",
    name: "Mtwara Airport",
    elevation_ft: "371",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-17",
    municipality: "Mtwara",
    gps_code: "HTMT",
    iata_code: "MYW",
    coordinates: "40.181800842285156, -10.339099884033203",
  },
  {
    ident: "HTMU",
    type: "small_airport",
    name: "Musoma Airport",
    elevation_ft: "3806",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-13",
    municipality: "Musoma",
    gps_code: "HTMU",
    iata_code: "MUZ",
    coordinates: "33.8021, -1.503",
  },
  {
    ident: "HTMW",
    type: "medium_airport",
    name: "Mwanza Airport",
    elevation_ft: "3763",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-18",
    municipality: "Mwanza",
    gps_code: "HTMW",
    iata_code: "MWZ",
    coordinates: "32.932701110839844, -2.4444899559020996",
  },
  {
    ident: "HTNA",
    type: "small_airport",
    name: "Nachingwea Airport",
    elevation_ft: "1400",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-12",
    municipality: "Nachingwea",
    gps_code: "HTNA",
    iata_code: "NCH",
    coordinates: "38.77920150756836, -10.357500076293945",
  },
  {
    ident: "HTNJ",
    type: "small_airport",
    name: "Njombe Airport",
    elevation_ft: "6400",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-04",
    municipality: "Njombe",
    gps_code: "HTNJ",
    iata_code: "JOM",
    coordinates: "34.79999923706055, -9.350000381469727",
  },
  {
    ident: "HTPE",
    type: "medium_airport",
    name: "Pemba Airport",
    elevation_ft: "80",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-25",
    municipality: "Chake",
    gps_code: "HTPE",
    iata_code: "PMA",
    coordinates: "39.8114013671875, -5.257259845733643",
  },
  {
    ident: "HTSN",
    type: "small_airport",
    name: "Seronera Airport",
    elevation_ft: "5080",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-13",
    municipality: "Seronera",
    gps_code: "HTSN",
    iata_code: "SEU",
    coordinates: "34.822498, -2.45806",
  },
  {
    ident: "HTSO",
    type: "small_airport",
    name: "Songea Airport",
    elevation_ft: "3445",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-21",
    municipality: "Songea",
    gps_code: "HTSO",
    iata_code: "SGX",
    coordinates: "35.58300018310547, -10.682999610900879",
  },
  {
    ident: "HTSU",
    type: "small_airport",
    name: "Sumbawanga Airport",
    elevation_ft: "5960",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-20",
    municipality: "Sumbawanga",
    gps_code: "HTSU",
    iata_code: "SUT",
    coordinates: "31.610278, -7.948889",
  },
  {
    ident: "HTSY",
    type: "small_airport",
    name: "Shinyanga Airport",
    elevation_ft: "3800",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-22",
    municipality: "Shinyanga",
    gps_code: "HTSY",
    iata_code: "SHY",
    coordinates: "33.5035, -3.6093",
  },
  {
    ident: "HTTB",
    type: "small_airport",
    name: "Tabora Airport",
    elevation_ft: "3868",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-24",
    municipality: "Tabora",
    gps_code: "HTTB",
    iata_code: "TBO",
    coordinates: "32.83330154418945, -5.076389789581299",
  },
  {
    ident: "HTTG",
    type: "medium_airport",
    name: "Tanga Airport",
    elevation_ft: "129",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-25",
    municipality: "Tanga",
    gps_code: "HTTG",
    iata_code: "TGT",
    coordinates: "39.07120132446289, -5.092360019683838",
  },
  {
    ident: "HTZA",
    type: "large_airport",
    name: "Abeid Amani Karume International Airport",
    elevation_ft: "54",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-07",
    municipality: "Zanzibar",
    gps_code: "HTZA",
    iata_code: "ZNZ",
    coordinates: "39.224899, -6.22202",
  },
  {
    ident: "HUAR",
    type: "medium_airport",
    name: "Arua Airport",
    elevation_ft: "3951",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-N",
    municipality: "Arua",
    gps_code: "HUAR",
    iata_code: "RUA",
    coordinates: "30.91699981689453, 3.049999952316284",
  },
  {
    ident: "HUEN",
    type: "large_airport",
    name: "Entebbe International Airport",
    elevation_ft: "3782",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-C",
    municipality: "Kampala",
    gps_code: "HUEN",
    iata_code: "EBB",
    coordinates: "32.443501, 0.042386",
  },
  {
    ident: "HUGU",
    type: "medium_airport",
    name: "Gulu Airport",
    elevation_ft: "3510",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-N",
    municipality: "Gulu",
    gps_code: "HUGU",
    iata_code: "ULU",
    coordinates: "32.27180099487305, 2.8055601119995117",
  },
  {
    ident: "HUJI",
    type: "small_airport",
    name: "Jinja Airport",
    elevation_ft: "3855",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-E",
    municipality: "Jinja",
    gps_code: "HUJI",
    iata_code: "JIN",
    coordinates: "33.200000762900004, 0.449999988079",
  },
  {
    ident: "HUKC",
    type: "closed",
    name: "Kololo Airstrip",
    elevation_ft: "3939",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-C",
    municipality: "Kampala",
    gps_code: "HUKC",
    iata_code: "KLA",
    coordinates: "32.594, 0.3262",
  },
  {
    ident: "HUKF",
    type: "small_airport",
    name: "Pakuba Airfield",
    elevation_ft: "2365",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-W",
    municipality: "Kabalega Falls",
    gps_code: "HUPA",
    iata_code: "PAF",
    coordinates: "31.497801, 2.32639",
  },
  {
    ident: "HUKS",
    type: "small_airport",
    name: "Kasese Airport",
    elevation_ft: "3146",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-W",
    municipality: "Kasese",
    gps_code: "HUKS",
    iata_code: "KSE",
    coordinates: "30.100000381469727, 0.18299999833106995",
  },
  {
    ident: "HUMA",
    type: "small_airport",
    name: "Mbarara Airport",
    elevation_ft: "4600",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-W",
    municipality: "Mbarara",
    gps_code: "HUMA",
    iata_code: "MBQ",
    coordinates: "30.5993995667, -0.555278003216",
  },
  {
    ident: "HUMI",
    type: "small_airport",
    name: "Masindi Airport",
    elevation_ft: "3850",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-W",
    municipality: "Masindi",
    gps_code: "HUMI",
    iata_code: "KCU",
    coordinates: "31.7367000579834, 1.7580599784851074",
  },
  {
    ident: "HUSO",
    type: "medium_airport",
    name: "Soroti Airport",
    elevation_ft: "3697",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-E",
    municipality: "Soroti",
    gps_code: "HUSO",
    iata_code: "SRT",
    coordinates: "33.622798919677734, 1.7276899814605713",
  },
  {
    ident: "HUTO",
    type: "small_airport",
    name: "Tororo Airport",
    elevation_ft: "3840",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-E",
    municipality: "Tororo",
    gps_code: "HUTO",
    iata_code: "TRY",
    coordinates: "34.16699981689453, 0.6830000281333923",
  },
  {
    ident: "HWA",
    type: "small_airport",
    name: "Hawabango Airport",
    elevation_ft: "4563",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Hawabango",
    gps_code: "AYHU",
    iata_code: "HWA",
    local_code: "HBA",
    coordinates: "146.003487, -7.392994",
  },
  {
    ident: "HYL",
    type: "seaplane_base",
    name: "Hollis Clark Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Hollis",
    gps_code: "HYL",
    iata_code: "HYL",
    local_code: "HYL",
    coordinates: "-132.645996094, 55.4816017151",
  },
  {
    ident: "IBI",
    type: "small_airport",
    name: "Iboki Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Iboki",
    iata_code: "IBI",
    local_code: "IBK",
    coordinates: "149.19, -5.5536",
  },
  {
    ident: "IBL",
    type: "medium_airport",
    name: "Indigo Bay Lodge Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-I",
    municipality: "Bazaruto Island",
    iata_code: "IBL",
    coordinates: "35.452801, -21.708",
  },
  {
    ident: "ICO",
    type: "small_airport",
    name: "Sicogon Airstrip",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ILI",
    municipality: "Sicogon Island",
    iata_code: "ICO",
    coordinates: "123.2506, 11.4595",
  },
  {
    ident: "ID-0003",
    type: "small_airport",
    name: "Pulau Panjang Airport",
    elevation_ft: "155",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JB",
    municipality: "Jakarta",
    gps_code: "WIHG",
    iata_code: "PPJ",
    coordinates: "106.5625, -5.644444",
  },
  {
    ident: "ID-0006",
    type: "small_airport",
    name: "Noto Hadinegoro, Jember Airport",
    elevation_ft: "281",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JI",
    municipality: "Jember",
    gps_code: "WARE",
    iata_code: "JBB",
    coordinates: "113.694439, -8.238056",
  },
  {
    ident: "ID-0011",
    type: "small_airport",
    name: "Blimbingsari Airport",
    elevation_ft: "112",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JI",
    municipality: "Banyuwangi",
    gps_code: "WADY",
    iata_code: "BWX",
    local_code: "BWI",
    coordinates: "114.3401, -8.31015",
  },
  {
    ident: "ID-0017",
    type: "large_airport",
    name: "Aji Pangeran Tumenggung Pranoto International Airport",
    elevation_ft: "73",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Samarinda",
    gps_code: "WALS",
    iata_code: "AAP",
    coordinates: "117.249392, -0.374448",
  },
  {
    ident: "ID-0169",
    type: "small_airport",
    name: "Bawean Airport",
    elevation_ft: "93",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JI",
    municipality: "Bawean",
    gps_code: "WARW",
    iata_code: "BXW",
    coordinates: "112.679592, -5.723676",
  },
  {
    ident: "ID-AAS",
    type: "small_airport",
    name: "Apalapsili Airport",
    elevation_ft: "3100",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Apalapsili",
    iata_code: "AAS",
    local_code: "APA",
    coordinates: "139.3108, -3.8832",
  },
  {
    ident: "ID-AGD",
    type: "small_airport",
    name: "Anggi Airport",
    elevation_ft: "6644",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Anggi-Papua Island",
    gps_code: "WASG",
    iata_code: "AGD",
    coordinates: "133.8737, -1.3858",
  },
  {
    ident: "ID-AKQ",
    type: "small_airport",
    name: "Gunung Batin Airport",
    elevation_ft: "99",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-LA",
    municipality: "Astraksetra-Sumatra Island",
    iata_code: "AKQ",
    coordinates: "105.23200225830078, -4.61113977432251",
  },
  {
    ident: "ID-AYW",
    type: "small_airport",
    name: "Ayawasi Airport",
    elevation_ft: "1560",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Ayawasi-Papua Island",
    gps_code: "WASA",
    iata_code: "AYW",
    coordinates: "132.4633, -1.1593",
  },
  {
    ident: "ID-BJG",
    type: "small_airport",
    name: "Boalang Airport",
    elevation_ft: "146",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-ST",
    municipality: "Boalang-Celebes Island",
    iata_code: "BJG",
    coordinates: "122.1449966430664, -0.9728959798812866",
  },
  {
    ident: "ID-BXM",
    type: "small_airport",
    name: "Batom Airport",
    elevation_ft: "576",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Batom-Papua Island",
    gps_code: "WAJG",
    iata_code: "BXM",
    coordinates: "140.850006104, -4.16666984558",
  },
  {
    ident: "ID-DRH",
    type: "small_airport",
    name: "Dabra Airport",
    elevation_ft: "208",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Dabra-Papua Island",
    gps_code: "WAJC",
    iata_code: "DRH",
    coordinates: "138.6132, -3.2705",
  },
  {
    ident: "ID-ELR",
    type: "small_airport",
    name: "Elelim Airport",
    elevation_ft: "1490",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Elelim-Papua Island",
    gps_code: "WAJN",
    iata_code: "ELR",
    coordinates: "139.3861, -3.7826",
  },
  {
    ident: "ID-EWE",
    type: "small_airport",
    name: "Ewer Airport",
    elevation_ft: "27",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Asmat",
    iata_code: "EWE",
    coordinates: "138.083, -5.494",
  },
  {
    ident: "ID-FOO",
    type: "small_airport",
    name: "Kornasoren Airfield",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Kornasoren-Numfoor Island",
    gps_code: "WABF",
    iata_code: "FOO",
    local_code: "WA23",
    coordinates: "134.871994019, -0.936325013638",
  },
  {
    ident: "ID-GAV",
    type: "small_airport",
    name: "Gag Island Airport",
    elevation_ft: "1",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Gag Island",
    iata_code: "GAV",
    coordinates: "129.89500427246094, -0.4005559980869293",
  },
  {
    ident: "ID-IUL",
    type: "small_airport",
    name: "Ilu Airport",
    elevation_ft: "6408",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Ilu-Papua Island",
    gps_code: "WABE",
    iata_code: "IUL",
    coordinates: "138.2002, -3.7051",
  },
  {
    ident: "ID-KBF",
    type: "small_airport",
    name: "Karubaga Airport",
    elevation_ft: "5200",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Karubaga-Papua Island",
    gps_code: "WABK",
    iata_code: "KBF",
    coordinates: "138.479, -3.684",
  },
  {
    ident: "ID-KBX",
    type: "small_airport",
    name: "Kambuaya Airport",
    elevation_ft: "1422",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Kambuaya-Papua Island",
    gps_code: "WASU",
    iata_code: "KBX",
    coordinates: "132.2857, -1.3169",
  },
  {
    ident: "ID-KCD",
    type: "small_airport",
    name: "Kamur Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Kamur-Papua Island",
    gps_code: "WAKM",
    iata_code: "KCD",
    coordinates: "138.6372, -6.1851",
  },
  {
    ident: "ID-KCI",
    type: "small_airport",
    name: "Kon Airport",
    elevation_ft: "150",
    continent: "AS",
    iso_country: "TL",
    iso_region: "TL-LA",
    municipality: "Kon",
    iata_code: "KCI",
    coordinates: "127.050793, -8.349193",
  },
  {
    ident: "ID-KEA",
    type: "small_airport",
    name: "Keisah Airport",
    elevation_ft: "81",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Keisah-Papua Island",
    iata_code: "KEA",
    coordinates: "140.5, -7.666669845581055",
  },
  {
    ident: "ID-KMM",
    type: "small_airport",
    name: "Kiman Airport",
    elevation_ft: "3250",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Kiman-Papua Island",
    iata_code: "KMM",
    coordinates: "136.1666717529297, -3.666670083999634",
  },
  {
    ident: "ID-KOD",
    type: "small_airport",
    name: "Kotabangun Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Kotabangun-Borneo Island",
    iata_code: "KOD",
    coordinates: "116.583335876, -0.266669988632",
  },
  {
    ident: "ID-KRC",
    type: "small_airport",
    name: "Departi Parbo Airport",
    elevation_ft: "2600",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JA",
    municipality: "Sungai Penuh",
    gps_code: "WIPH",
    iata_code: "KRC",
    coordinates: "101.4683, -2.093",
  },
  {
    ident: "ID-KWB",
    type: "small_airport",
    name: "Dewadaru Airport",
    elevation_ft: "35",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JT",
    municipality: "Karimunjawa-Karimunjawa Island",
    gps_code: "WARU",
    iata_code: "KWB",
    coordinates: "110.47838, -5.80091",
  },
  {
    ident: "ID-LLN",
    type: "small_airport",
    name: "Kelila Airport",
    elevation_ft: "9092",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Kelila-Papua Island",
    iata_code: "LLN",
    coordinates: "138.6666717529297, -3.75",
  },
  {
    ident: "ID-LWE",
    type: "small_airport",
    name: "Lewoleba Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Lewoleba-Lembata Island",
    gps_code: "WATW",
    iata_code: "LWE",
    coordinates: "123.438, -8.3629",
  },
  {
    ident: "ID-LYK",
    type: "small_airport",
    name: "Lunyuk Airport",
    elevation_ft: "44",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NB",
    municipality: "Lunyuk-Simbawa Island",
    gps_code: "WADU",
    iata_code: "LYK",
    coordinates: "117.2158, -8.9889",
  },
  {
    ident: "ID-MJY",
    type: "small_airport",
    name: "Mangunjaya Airport",
    elevation_ft: "76",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SS",
    municipality: "Mangungaya-Sumatra Island",
    iata_code: "MJY",
    coordinates: "103.56700134277344, -2.733330011367798",
  },
  {
    ident: "ID-MPT",
    type: "small_airport",
    name: "Maliana airport",
    elevation_ft: "957",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Maliana-Alor Island",
    iata_code: "MPT",
    coordinates: "125.00900268554688, -8.167739868164062",
  },
  {
    ident: "ID-MSI",
    type: "small_airport",
    name: "Masalembo Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JI",
    municipality: "Masalembo Island",
    iata_code: "MSI",
    coordinates: "114.43299865722656, -5.583330154418945",
  },
  {
    ident: "ID-MUF",
    type: "small_airport",
    name: "Muting Airport",
    elevation_ft: "70",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Muting",
    iata_code: "MUF",
    coordinates: "140.5668, -7.3147",
  },
  {
    ident: "ID-NAF",
    type: "small_airport",
    name: "Banaina Airport",
    elevation_ft: "60",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KU",
    municipality: "Banaina-Borneo Island",
    iata_code: "NAF",
    coordinates: "117.125999451, 2.72305011749",
  },
  {
    ident: "ID-OBD",
    type: "small_airport",
    name: "Obano Airport",
    elevation_ft: "5800",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Obano-Papua Island",
    gps_code: "WABR",
    iata_code: "OBD",
    coordinates: "136.2306, -3.9106",
  },
  {
    ident: "ID-PUM",
    type: "small_airport",
    name: "Pomala Airport",
    elevation_ft: "88",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SG",
    municipality: "Kolaka",
    gps_code: "WAWP",
    iata_code: "PUM",
    coordinates: "121.61799621582, -4.1810898780823",
  },
  {
    ident: "ID-PWL",
    type: "small_airport",
    name: "Purwokerto Airport",
    elevation_ft: "225",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JT",
    municipality: "Purwokerto-Java Island",
    gps_code: "WICP",
    iata_code: "PWL",
    coordinates: "109.416999817, -7.46166992188",
  },
  {
    ident: "ID-RAQ",
    type: "small_airport",
    name: "Sugimanuru Airport",
    elevation_ft: "132",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SG",
    municipality: "Raha-Muna Island",
    gps_code: "WAWR",
    iata_code: "RAQ",
    coordinates: "122.569371, -4.760557",
  },
  {
    ident: "ID-RKI",
    type: "small_airport",
    name: "Rokot Airport",
    elevation_ft: "4975",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SB",
    municipality: "Rokot-Sumatra Island",
    iata_code: "RKI",
    coordinates: "100.75, -0.949999988079071",
  },
  {
    ident: "ID-RTI",
    type: "small_airport",
    name: "Roti Airport",
    elevation_ft: "65",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Roti-Rote Island",
    iata_code: "RTI",
    coordinates: "123.3740005493164, -10.484299659729004",
  },
  {
    ident: "ID-RUF",
    type: "small_airport",
    name: "Yuruf Airport",
    elevation_ft: "1844",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Amgotro",
    gps_code: "WAJE",
    iata_code: "RUF",
    coordinates: "140.958, -3.6333",
  },
  {
    ident: "ID-RZS",
    type: "small_airport",
    name: "Sawan Airport",
    elevation_ft: "680",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-BA",
    municipality: "Sawan-Bali Island",
    iata_code: "RZS",
    coordinates: "115.1729965209961, -8.132780075073242",
  },
  {
    ident: "ID-SAE",
    type: "small_airport",
    name: "Sangir Airport",
    elevation_ft: "175",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NB",
    municipality: "Sangir-Simbawa Island",
    iata_code: "SAE",
    coordinates: "118.33335876464844, -8.366669654846191",
  },
  {
    ident: "ID-TBM",
    type: "small_airport",
    name: "Tumbang Samba Airport",
    elevation_ft: "160",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KT",
    municipality: "Tumbang Samba-Borneo Island",
    gps_code: "WAOW",
    iata_code: "TBM",
    coordinates: "113.0833, -1.4694",
  },
  {
    ident: "ID-TMY",
    type: "small_airport",
    name: "Tiom Airport",
    elevation_ft: "6931",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Tiom-Papua Island",
    iata_code: "TMY",
    local_code: "TMI",
    coordinates: "138.4555, -3.9256",
  },
  {
    ident: "ID-ZEG",
    type: "small_airport",
    name: "Senggo Airport",
    elevation_ft: "52",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Senggo-Papua Island",
    gps_code: "WAKQ",
    iata_code: "ZEG",
    coordinates: "139.3502, -5.6908",
  },
  {
    ident: "ID-ZGP",
    type: "medium_airport",
    name: "Bilogai-Sugapa Airport",
    elevation_ft: "7348",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Sugapa-Papua Island",
    gps_code: "WABV",
    iata_code: "UGU",
    local_code: "ZGP",
    coordinates: "137.031998, -3.73956",
  },
  {
    ident: "IDN",
    type: "small_airport",
    name: "Indagen Airport",
    elevation_ft: "5788",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Indagen",
    gps_code: "AYID",
    iata_code: "IDN",
    local_code: "IGN",
    coordinates: "147.244, -6.22663",
  },
  {
    ident: "ILX",
    type: "closed",
    name: "Ileg Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Ileg",
    iata_code: "ILX",
    coordinates: "145.8022, -5.4917",
  },
  {
    ident: "IMA",
    type: "small_airport",
    name: "Iamalele Airport",
    elevation_ft: "40",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Iamalele, Fergusson Island",
    iata_code: "IMA",
    local_code: "IAM",
    coordinates: "150.5246, -9.5107",
  },
  {
    ident: "IMG",
    type: "small_airport",
    name: "Inhaminga Airport",
    elevation_ft: "1072",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-S",
    municipality: "Inhaminga",
    iata_code: "IMG",
    coordinates: "35.0045, -18.41",
  },
  {
    ident: "IN-0070",
    type: "small_airport",
    name: "Vijayanagar Aerodrome (JSW)",
    elevation_ft: "1670",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KA",
    gps_code: "VOJV",
    iata_code: "VDY",
    coordinates: "76.6349472222, 15.1749666667",
  },
  {
    ident: "IN-JGB",
    type: "small_airport",
    name: "Jagdalpur Airport",
    elevation_ft: "1822",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-CT",
    municipality: "Jagdalpur",
    iata_code: "JGB",
    coordinates: "82.03679656982422, 19.07430076599121",
  },
  {
    ident: "IN-NVY",
    type: "small_airport",
    name: "Neyveli Airport",
    elevation_ft: "184",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TN",
    municipality: "Neyveli",
    gps_code: "VONV",
    iata_code: "NVY",
    coordinates: "79.527381897, 11.6129560471",
  },
  {
    ident: "IN-RJI",
    type: "small_airport",
    name: "Rajouri Airport",
    elevation_ft: "3000",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-JK",
    municipality: "Rajouri",
    iata_code: "RJI",
    coordinates: "74.31520080566406, 33.377899169921875",
  },
  {
    ident: "IN-TEI",
    type: "small_airport",
    name: "Tezu Airport",
    elevation_ft: "800",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AR",
    municipality: "Tezu",
    gps_code: "VETJ",
    iata_code: "TEI",
    coordinates: "96.1343994141, 27.9412002563",
  },
  {
    ident: "INE",
    type: "small_airport",
    name: "Chinde Airport",
    elevation_ft: "22",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-Q",
    municipality: "Chinde",
    iata_code: "INE",
    coordinates: "36.4489, -18.59",
  },
  {
    ident: "IOK",
    type: "small_airport",
    name: "Iokea Airport",
    elevation_ft: "39",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Iokea",
    iata_code: "IOK",
    local_code: "IOK",
    coordinates: "146.277, -8.401",
  },
  {
    ident: "IOP",
    type: "small_airport",
    name: "Ioma Airport",
    elevation_ft: "95",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Ioma",
    iata_code: "IOP",
    local_code: "IOMA",
    coordinates: "147.84, -8.3614",
  },
  {
    ident: "IR-0001",
    type: "small_airport",
    name: "Khaneh Airport",
    elevation_ft: "4804",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-02",
    municipality: "Khaneh",
    gps_code: "OITH",
    iata_code: "KHA",
    coordinates: "45.1500015259, 36.733341217",
  },
  {
    ident: "IR-GSM",
    type: "small_airport",
    name: "Qeshm International Airport",
    elevation_ft: "45",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-23",
    municipality: "Qeshm",
    gps_code: "OIKQ",
    iata_code: "GSM",
    coordinates: "55.902353, 26.754639",
  },
  {
    ident: "IRU",
    type: "seaplane_base",
    name: "Iranamadu Seaplane Base",
    elevation_ft: "102",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-4",
    municipality: "Iranamadu",
    iata_code: "IRU",
    coordinates: "80.448627, 9.299746",
  },
  {
    ident: "ISG",
    type: "medium_airport",
    name: "New Ishigaki Airport",
    elevation_ft: "102",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Ishigaki",
    gps_code: "ROIG",
    iata_code: "ISG",
    coordinates: "124.245, 24.396389",
  },
  {
    ident: "IT-TQR",
    type: "heliport",
    name: "San Domino Island Heliport",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-75",
    municipality: "Tremiti Islands",
    gps_code: "LINI",
    iata_code: "TQR",
    coordinates: "15.490655, 42.117775",
  },
  {
    ident: "ITK",
    type: "small_airport",
    name: "Itokama Airport",
    elevation_ft: "2563",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Itokama",
    gps_code: "AYIK",
    iata_code: "ITK",
    local_code: "ITK",
    coordinates: "148.264261, -9.201527",
  },
  {
    ident: "IUS",
    type: "closed",
    name: "Inus Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Inus",
    iata_code: "IUS",
    coordinates: "155.1498, -5.7568",
  },
  {
    ident: "IVH",
    type: "closed",
    name: "Ivishak Airport",
    elevation_ft: "677",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ivishak River",
    iata_code: "IVH",
    coordinates: "-148.2881, 69.4066",
  },
  {
    ident: "IVI",
    type: "small_airport",
    name: "Viveros Island Airport",
    elevation_ft: "100",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-8",
    municipality: "Isla Viveros",
    iata_code: "IVI",
    coordinates: "-79.0016, 8.4693",
  },
  {
    ident: "JGD",
    type: "medium_airport",
    name: "Jiagedaqi Airport",
    elevation_ft: "1205",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Jiagedaqi",
    gps_code: "ZYJD",
    iata_code: "JGD",
    coordinates: "124.1175, 50.371389",
  },
  {
    ident: "JIC",
    type: "small_airport",
    name: "Jinchuan Airport",
    elevation_ft: "4740",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-62",
    municipality: "Jinchang",
    gps_code: "ZLJC",
    iata_code: "JIC",
    coordinates: "102.348333333, 38.542222222199996",
  },
  {
    ident: "JIO",
    type: "small_airport",
    name: "Jos Orno Imsula Airport",
    elevation_ft: "43",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Tiakur",
    iata_code: "JIO",
    coordinates: "127.9075, -8.13911",
  },
  {
    ident: "JIQ",
    type: "medium_airport",
    name: "Qianjiang Wulingshan Airport",
    elevation_ft: "2075",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-50",
    municipality: "Qianjiang",
    gps_code: "ZUQJ",
    iata_code: "JIQ",
    coordinates: "108.831111111, 29.5133333333",
  },
  {
    ident: "JJD",
    type: "medium_airport",
    name: "Comandante Ariston Pessoa Airport",
    elevation_ft: "89",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-CE",
    municipality: "Jijoca de Jericoacoara",
    gps_code: "SBJE",
    iata_code: "JJD",
    local_code: "CE0003",
    coordinates: "-40.357338, -2.906425",
  },
  {
    ident: "JLA",
    type: "small_airport",
    name: "Quartz Creek Airport",
    elevation_ft: "450",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Cooper Landing",
    gps_code: "JLA",
    iata_code: "JLA",
    local_code: "JLA",
    coordinates: "-149.718994141, 60.48270034789999",
  },
  {
    ident: "JOP",
    type: "small_airport",
    name: "Josephstaal Airport",
    elevation_ft: "250",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Josephstaal",
    gps_code: "AYJS",
    iata_code: "JOP",
    local_code: "JSL",
    coordinates: "145.007083333, -4.74708333333",
  },
  {
    ident: "JPB",
    type: "closed",
    name: "Pan Am Building Heliport",
    elevation_ft: "870",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "New York",
    iata_code: "JPB",
    coordinates: "-73.9765, 40.7533",
  },
  {
    ident: "JPN",
    type: "heliport",
    name: "Pentagon Army Heliport",
    elevation_ft: "40",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-DC",
    municipality: "Washington",
    gps_code: "KJPN",
    iata_code: "JPN",
    local_code: "JPN",
    coordinates: "-77.0575027466, 38.8740997314",
  },
  {
    ident: "JRA",
    type: "heliport",
    name: "West 30th St. Heliport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "New York",
    gps_code: "KJRA",
    iata_code: "JRA",
    local_code: "JRA",
    coordinates: "-74.007103, 40.754501",
  },
  {
    ident: "JRB",
    type: "heliport",
    name: "Downtown-Manhattan/Wall St Heliport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "New York",
    gps_code: "KJRB",
    iata_code: "JRB",
    local_code: "JRB",
    coordinates: "-74.00900269, 40.70119858",
  },
  {
    ident: "JUH",
    type: "small_airport",
    name: "Jiuhuashan Airport",
    elevation_ft: "60",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-34",
    municipality: "Chizhou",
    gps_code: "ZSJH",
    iata_code: "JUH",
    coordinates: "117.6856, 30.7403",
  },
  {
    ident: "K00C",
    type: "small_airport",
    name: "Animas Air Park",
    elevation_ft: "6684",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Durango",
    iata_code: "AMK",
    local_code: "00C",
    coordinates: "-107.869003296, 37.203201293899994",
  },
  {
    ident: "K00F",
    type: "small_airport",
    name: "Broadus Airport",
    elevation_ft: "3280",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Broadus",
    gps_code: "00F",
    iata_code: "BDX",
    local_code: "00F",
    coordinates: "-105.4540024, 45.47249985",
  },
  {
    ident: "K05U",
    type: "small_airport",
    name: "Eureka Airport",
    elevation_ft: "5954",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Eureka",
    gps_code: "05U",
    iata_code: "EUE",
    local_code: "05U",
    coordinates: "-116.004997253, 39.604198455799995",
  },
  {
    ident: "K06U",
    type: "small_airport",
    name: "Jackpot Airport/Hayden Field",
    elevation_ft: "5213",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Jackpot",
    gps_code: "06U",
    iata_code: "KPT",
    local_code: "06U",
    coordinates: "-114.657997131, 41.97600173949999",
  },
  {
    ident: "K07",
    type: "small_airport",
    name: "Rolla Downtown Airport",
    elevation_ft: "987",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Rolla",
    gps_code: "K07",
    iata_code: "RLA",
    local_code: "K07",
    coordinates: "-91.8134994506836, 37.935699462890625",
  },
  {
    ident: "K0B8",
    type: "small_airport",
    name: "Elizabeth Field",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Fishers Island",
    gps_code: "0B8",
    iata_code: "FID",
    local_code: "0B8",
    coordinates: "-72.0316009521, 41.25130081179999",
  },
  {
    ident: "K0K7",
    type: "small_airport",
    name: "Humboldt Municipal Airport",
    elevation_ft: "1093",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Humboldt",
    gps_code: "0K7",
    iata_code: "HUD",
    local_code: "0K7",
    coordinates: "-94.2452011108, 42.736099243199995",
  },
  {
    ident: "K0S9",
    type: "small_airport",
    name: "Jefferson County International Airport",
    elevation_ft: "108",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Port Townsend",
    gps_code: "K0S9",
    iata_code: "TWD",
    local_code: "0S9",
    coordinates: "-122.810997009, 48.0537986755",
  },
  {
    ident: "K0V7",
    type: "small_airport",
    name: "Kayenta Airport",
    elevation_ft: "5688",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Kayenta",
    iata_code: "MVM",
    local_code: "0V7",
    coordinates: "-110.228444, 36.716444",
  },
  {
    ident: "K1B1",
    type: "small_airport",
    name: "Columbia County Airport",
    elevation_ft: "198",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Hudson",
    gps_code: "1B1",
    iata_code: "HCC",
    local_code: "1B1",
    coordinates: "-73.7102966309, 42.2913017273",
  },
  {
    ident: "K1F0",
    type: "small_airport",
    name: "Ardmore Downtown Executive Airport",
    elevation_ft: "844",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Ardmore",
    iata_code: "AHD",
    local_code: "1F0",
    coordinates: "-97.1227035522, 34.1469993591",
  },
  {
    ident: "K1G4",
    type: "small_airport",
    name: "Grand Canyon West Airport",
    elevation_ft: "4825",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Peach Springs",
    gps_code: "K1G4",
    iata_code: "GCW",
    local_code: "1G4",
    coordinates: "-113.816001892, 35.990398407",
  },
  {
    ident: "K1O2",
    type: "small_airport",
    name: "Lampson Field",
    elevation_ft: "1379",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Lakeport",
    gps_code: "1O2",
    iata_code: "CKE",
    local_code: "1O2",
    coordinates: "-122.901000977, 38.9906005859",
  },
  {
    ident: "K1O5",
    type: "small_airport",
    name: "Montague-Yreka Rohrer Field",
    elevation_ft: "2527",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Montague",
    gps_code: "K1O5",
    iata_code: "ROF",
    local_code: "1O5",
    coordinates: "-122.54599762, 41.7304000854",
  },
  {
    ident: "K1V6",
    type: "small_airport",
    name: "Fremont County Airport",
    elevation_ft: "5439",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Ca����on City",
    gps_code: "1V6",
    iata_code: "CNE",
    local_code: "1V6",
    coordinates: "-105.106002808, 38.428001403799996",
  },
  {
    ident: "K21",
    type: "seaplane_base",
    name: "Rouses Point Seaplane Base",
    elevation_ft: "95",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Rouses Point",
    gps_code: "K21",
    iata_code: "RSX",
    local_code: "K21",
    coordinates: "-73.363502502441, 44.99169921875",
  },
  {
    ident: "K23",
    type: "small_airport",
    name: "Cooperstown-Westville Airport",
    elevation_ft: "1260",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Cooperstown",
    gps_code: "K23",
    iata_code: "COP",
    local_code: "K23",
    coordinates: "-74.89099884030001, 42.6291999817",
  },
  {
    ident: "K29",
    type: "small_airport",
    name: "Council Airport",
    elevation_ft: "85",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Council",
    gps_code: "K29",
    iata_code: "CIL",
    local_code: "K29",
    coordinates: "-163.70300293, 64.89790344240001",
  },
  {
    ident: "K2F0",
    type: "small_airport",
    name: "Iraan Municipal Airport",
    elevation_ft: "2200",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Iraan",
    iata_code: "IRB",
    local_code: "2F0",
    coordinates: "-101.891998291, 30.9057006836",
  },
  {
    ident: "K2O1",
    type: "small_airport",
    name: "Gansner Field",
    elevation_ft: "3415",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Quincy",
    iata_code: "GNF",
    local_code: "2O1",
    coordinates: "-120.945, 39.943902",
  },
  {
    ident: "K2S7",
    type: "small_airport",
    name: "Chiloquin State Airport",
    elevation_ft: "4217",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Chiloquin",
    gps_code: "K2S7",
    iata_code: "CHZ",
    local_code: "2S7",
    coordinates: "-121.879062653, 42.579440493",
  },
  {
    ident: "K2W6",
    type: "small_airport",
    name: "St. Mary's County Regional Airport",
    elevation_ft: "142",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Leonardtown",
    gps_code: "2W6",
    iata_code: "LTW",
    local_code: "2W6",
    coordinates: "-76.5501022339, 38.3153991699",
  },
  {
    ident: "K37V",
    type: "small_airport",
    name: "Arapahoe Municipal Airport",
    elevation_ft: "2270",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Arapahoe",
    iata_code: "AHF",
    local_code: "37V",
    coordinates: "-99.90650177, 40.339500427199994",
  },
  {
    ident: "K39N",
    type: "small_airport",
    name: "Princeton Airport",
    elevation_ft: "128",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Princeton/Rocky Hill",
    gps_code: "39N",
    iata_code: "PCT",
    local_code: "39N",
    coordinates: "-74.6588973999, 40.3992004395",
  },
  {
    ident: "K3C8",
    type: "small_airport",
    name: "Calverton Executive Airpark",
    elevation_ft: "75",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Calverton",
    gps_code: "3C8",
    iata_code: "CTO",
    local_code: "3C8",
    coordinates: "-72.7919006348, 40.9151000977",
  },
  {
    ident: "K3O9",
    type: "small_airport",
    name: "Grand Lake Regional Airport",
    elevation_ft: "792",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Afton",
    gps_code: "3O9",
    iata_code: "NRI",
    local_code: "3O9",
    coordinates: "-94.86190032959, 36.577598571777",
  },
  {
    ident: "K3S8",
    type: "small_airport",
    name: "Grants Pass Airport",
    elevation_ft: "1126",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Grants Pass",
    gps_code: "KSXT",
    iata_code: "GTP",
    local_code: "3S8",
    coordinates: "-123.388, 42.510101",
  },
  {
    ident: "K3TR",
    type: "small_airport",
    name: "Jerry Tyler Memorial Airport",
    elevation_ft: "750",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Niles",
    gps_code: "3TR",
    iata_code: "NLE",
    local_code: "3TR",
    coordinates: "-86.225196838379, 41.835899353027",
  },
  {
    ident: "K3W7",
    type: "small_airport",
    name: "Grand Coulee Dam Airport",
    elevation_ft: "1588",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Electric City",
    gps_code: "3W7",
    iata_code: "GCD",
    local_code: "3W7",
    coordinates: "-119.083000183, 47.922000885",
  },
  {
    ident: "K40G",
    type: "small_airport",
    name: "Valle Airport",
    elevation_ft: "5999",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Grand Canyon",
    gps_code: "40G",
    iata_code: "VLE",
    local_code: "40G",
    coordinates: "-112.14800262451, 35.65060043335",
  },
  {
    ident: "K40J",
    type: "small_airport",
    name: "Perry-Foley Airport",
    elevation_ft: "44",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Perry",
    gps_code: "KFPY",
    iata_code: "FPY",
    local_code: "FPY",
    coordinates: "-83.580597, 30.0693",
  },
  {
    ident: "K41U",
    type: "small_airport",
    name: "Manti-Ephraim Airport",
    elevation_ft: "5500",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Manti",
    gps_code: "41U",
    iata_code: "NTJ",
    local_code: "41U",
    coordinates: "-111.6149979, 39.32910156",
  },
  {
    ident: "K43U",
    type: "closed",
    name: "Mount Pleasant Airport",
    elevation_ft: "5830",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Mount Pleasant",
    iata_code: "MSD",
    coordinates: "-111.475978, 39.526651",
  },
  {
    ident: "K44U",
    type: "small_airport",
    name: "Salina Gunnison Airport",
    elevation_ft: "5159",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Salina",
    gps_code: "44U",
    iata_code: "SBO",
    local_code: "44U",
    coordinates: "-111.837997437, 39.029098510699995",
  },
  {
    ident: "K47N",
    type: "small_airport",
    name: "Central Jersey Regional Airport",
    elevation_ft: "86",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Manville",
    gps_code: "47N",
    iata_code: "JVI",
    local_code: "47N",
    coordinates: "-74.59839630130001, 40.5243988037",
  },
  {
    ident: "K4R7",
    type: "small_airport",
    name: "Eunice Airport",
    elevation_ft: "42",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Eunice",
    gps_code: "4R7",
    iata_code: "UCE",
    local_code: "4R7",
    coordinates: "-92.423797607422, 30.466299057007",
  },
  {
    ident: "K4S1",
    type: "small_airport",
    name: "Gold Beach Municipal Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Gold Beach",
    gps_code: "K4S1",
    iata_code: "GOL",
    local_code: "4S1",
    coordinates: "-124.4240036, 42.41339874",
  },
  {
    ident: "K50I",
    type: "small_airport",
    name: "Kentland Municipal Airport",
    elevation_ft: "698",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Kentland",
    gps_code: "50I",
    iata_code: "KKT",
    local_code: "50I",
    coordinates: "-87.42819976810001, 40.7587013245",
  },
  {
    ident: "K5N2",
    type: "small_airport",
    name: "Prentice Airport",
    elevation_ft: "1578",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Prentice",
    iata_code: "PRW",
    local_code: "5N2",
    coordinates: "-90.279297, 45.542999",
  },
  {
    ident: "K5T9",
    type: "small_airport",
    name: "Maverick County Memorial International Airport",
    elevation_ft: "887",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Eagle Pass",
    gps_code: "5T9",
    iata_code: "EGP",
    local_code: "5T9",
    coordinates: "-100.512001, 28.85720062",
  },
  {
    ident: "K61B",
    type: "small_airport",
    name: "Boulder City Municipal Airport",
    elevation_ft: "2201",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Boulder City",
    gps_code: "KBVU",
    iata_code: "BLD",
    local_code: "BVU",
    coordinates: "-114.861, 35.947498",
  },
  {
    ident: "K67L",
    type: "small_airport",
    name: "Mesquite Airport",
    elevation_ft: "1978",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Mesquite",
    gps_code: "67L",
    iata_code: "MFH",
    local_code: "67L",
    coordinates: "-114.0550003, 36.83499908",
  },
  {
    ident: "K6D9",
    type: "small_airport",
    name: "Iosco County Airport",
    elevation_ft: "606",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "East Tawas",
    iata_code: "ECA",
    local_code: "6D9",
    coordinates: "-83.422302, 44.312801",
  },
  {
    ident: "K6S2",
    type: "small_airport",
    name: "Florence Municipal Airport",
    elevation_ft: "51",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Florence",
    iata_code: "FMU",
    local_code: "6S2",
    coordinates: "-124.111000061, 43.98279953",
  },
  {
    ident: "K74S",
    type: "small_airport",
    name: "Anacortes Airport",
    elevation_ft: "241",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Anacortes",
    iata_code: "OTS",
    local_code: "74S",
    coordinates: "-122.662003, 48.499",
  },
  {
    ident: "K74V",
    type: "small_airport",
    name: "Roosevelt Municipal Airport",
    elevation_ft: "5172",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Roosevelt",
    iata_code: "ROL",
    local_code: "74V",
    coordinates: "-110.051003, 40.278301",
  },
  {
    ident: "K7G9",
    type: "small_airport",
    name: "Canton Municipal Airport",
    elevation_ft: "1290",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Canton",
    iata_code: "CTK",
    local_code: "7G9",
    coordinates: "-96.570999, 43.308899",
  },
  {
    ident: "K7V2",
    type: "small_airport",
    name: "North Fork Valley Airport",
    elevation_ft: "5798",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Paonia",
    gps_code: "K7V2",
    iata_code: "WPO",
    local_code: "7V2",
    coordinates: "-107.646003723, 38.8316993713",
  },
  {
    ident: "K80F",
    type: "small_airport",
    name: "Antlers Municipal Airport",
    elevation_ft: "575",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Antlers",
    gps_code: "80F",
    iata_code: "ATE",
    local_code: "80F",
    coordinates: "-95.6499023438, 34.1926002502",
  },
  {
    ident: "K8A6",
    type: "small_airport",
    name: "Wilgrove Air Park",
    elevation_ft: "799",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Charlotte",
    gps_code: "K8A6",
    iata_code: "QWG",
    local_code: "8A6",
    coordinates: "-80.67009735110001, 35.2137985229",
  },
  {
    ident: "K9U3",
    type: "small_airport",
    name: "Austin Airport",
    elevation_ft: "5730",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Austin",
    gps_code: "KTMT",
    iata_code: "ASQ",
    local_code: "TMT",
    coordinates: "-117.195, 39.467998",
  },
  {
    ident: "KAAF",
    type: "small_airport",
    name: "Apalachicola Regional Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Apalachicola",
    gps_code: "KAAF",
    iata_code: "AAF",
    local_code: "AAF",
    coordinates: "-85.02749634, 29.72750092",
  },
  {
    ident: "KABE",
    type: "medium_airport",
    name: "Lehigh Valley International Airport",
    elevation_ft: "393",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Allentown",
    gps_code: "KABE",
    iata_code: "ABE",
    local_code: "ABE",
    coordinates: "-75.44080352783203, 40.652099609375",
  },
  {
    ident: "KABI",
    type: "medium_airport",
    name: "Abilene Regional Airport",
    elevation_ft: "1791",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Abilene",
    gps_code: "KABI",
    iata_code: "ABI",
    local_code: "ABI",
    coordinates: "-99.68190002440001, 32.4113006592",
  },
  {
    ident: "KABQ",
    type: "large_airport",
    name: "Albuquerque International Sunport",
    elevation_ft: "5355",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Albuquerque",
    gps_code: "KABQ",
    iata_code: "ABQ",
    local_code: "ABQ",
    coordinates: "-106.609001, 35.040199",
  },
  {
    ident: "KABR",
    type: "medium_airport",
    name: "Aberdeen Regional Airport",
    elevation_ft: "1302",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Aberdeen",
    gps_code: "KABR",
    iata_code: "ABR",
    local_code: "ABR",
    coordinates: "-98.42179870605469, 45.449100494384766",
  },
  {
    ident: "KABY",
    type: "medium_airport",
    name: "Southwest Georgia Regional Airport",
    elevation_ft: "197",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Albany",
    gps_code: "KABY",
    iata_code: "ABY",
    local_code: "ABY",
    coordinates: "-84.19450378417969, 31.535499572753906",
  },
  {
    ident: "KACB",
    type: "small_airport",
    name: "Antrim County Airport",
    elevation_ft: "623",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Bellaire",
    gps_code: "KACB",
    iata_code: "ACB",
    local_code: "ACB",
    coordinates: "-85.198402, 44.988602",
  },
  {
    ident: "KACK",
    type: "medium_airport",
    name: "Nantucket Memorial Airport",
    elevation_ft: "47",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Nantucket",
    gps_code: "KACK",
    iata_code: "ACK",
    local_code: "ACK",
    coordinates: "-70.06020355, 41.25310135",
  },
  {
    ident: "KACT",
    type: "medium_airport",
    name: "Waco Regional Airport",
    elevation_ft: "516",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Waco",
    gps_code: "KACT",
    iata_code: "ACT",
    local_code: "ACT",
    coordinates: "-97.23049926757812, 31.611299514770508",
  },
  {
    ident: "KACV",
    type: "medium_airport",
    name: "California Redwood Coast-Humboldt County Airport",
    elevation_ft: "221",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Arcata/Eureka",
    gps_code: "KACV",
    iata_code: "ACV",
    local_code: "ACV",
    coordinates: "-124.109, 40.978101",
  },
  {
    ident: "KACY",
    type: "medium_airport",
    name: "Atlantic City International Airport",
    elevation_ft: "75",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Atlantic City",
    gps_code: "KACY",
    iata_code: "ACY",
    local_code: "ACY",
    coordinates: "-74.57720184326172, 39.45759963989258",
  },
  {
    ident: "KADG",
    type: "small_airport",
    name: "Lenawee County Airport",
    elevation_ft: "798",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Adrian",
    gps_code: "KADG",
    iata_code: "ADG",
    local_code: "ADG",
    coordinates: "-84.07730102539999, 41.8676986694",
  },
  {
    ident: "KADH",
    type: "small_airport",
    name: "Ada Regional Airport",
    elevation_ft: "1016",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Ada",
    gps_code: "KADH",
    iata_code: "ADT",
    local_code: "ADH",
    coordinates: "-96.671303, 34.804298",
  },
  {
    ident: "KADM",
    type: "small_airport",
    name: "Ardmore Municipal Airport",
    elevation_ft: "777",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Ardmore",
    gps_code: "KADM",
    iata_code: "ADM",
    local_code: "ADM",
    coordinates: "-97.0196342, 34.30301",
  },
  {
    ident: "KADS",
    type: "small_airport",
    name: "Addison Airport",
    elevation_ft: "644",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Dallas",
    gps_code: "KADS",
    iata_code: "ADS",
    local_code: "ADS",
    coordinates: "-96.8364028931, 32.9686012268",
  },
  {
    ident: "KADW",
    type: "large_airport",
    name: "Joint Base Andrews",
    elevation_ft: "280",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Camp Springs",
    gps_code: "KADW",
    iata_code: "ADW",
    local_code: "ADW",
    coordinates: "-76.866997, 38.810799",
  },
  {
    ident: "KAE",
    type: "seaplane_base",
    name: "Kake Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kake",
    iata_code: "KAE",
    local_code: "KAE",
    coordinates: "-133.945999, 56.973",
  },
  {
    ident: "KAEL",
    type: "small_airport",
    name: "Albert Lea Municipal Airport",
    elevation_ft: "1260",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Albert Lea",
    gps_code: "KAEL",
    iata_code: "AEL",
    local_code: "AEL",
    coordinates: "-93.36720276, 43.68149948",
  },
  {
    ident: "KAEX",
    type: "medium_airport",
    name: "Alexandria International Airport",
    elevation_ft: "89",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Alexandria",
    gps_code: "KAEX",
    iata_code: "AEX",
    local_code: "AEX",
    coordinates: "-92.54979705810547, 31.32740020751953",
  },
  {
    ident: "KAF",
    type: "small_airport",
    name: "Karato Airport",
    elevation_ft: "109",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Karato",
    iata_code: "KAF",
    local_code: "KRT",
    coordinates: "155.3052, -6.2655",
  },
  {
    ident: "KAFF",
    type: "small_airport",
    name: "USAF Academy Airfield",
    elevation_ft: "6572",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Colorado Springs",
    gps_code: "KAFF",
    iata_code: "AFF",
    local_code: "AFF",
    coordinates: "-104.8130035, 38.96969986",
  },
  {
    ident: "KAFJ",
    type: "small_airport",
    name: "Washington County Airport",
    elevation_ft: "1184",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Washington",
    gps_code: "KAFJ",
    iata_code: "WSG",
    local_code: "AFJ",
    coordinates: "-80.29019927979999, 40.1365013123",
  },
  {
    ident: "KAFN",
    type: "small_airport",
    name: "Jaffrey Airport Silver Ranch Airport",
    elevation_ft: "1040",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Jaffrey",
    gps_code: "KAFN",
    iata_code: "AFN",
    local_code: "AFN",
    coordinates: "-72.0029983521, 42.805099487300005",
  },
  {
    ident: "KAFO",
    type: "small_airport",
    name: "Afton Municipal Airport",
    elevation_ft: "6221",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Afton",
    gps_code: "KAFO",
    iata_code: "AFO",
    local_code: "AFO",
    coordinates: "-110.942001343, 42.7112007141",
  },
  {
    ident: "KAFW",
    type: "large_airport",
    name: "Fort Worth Alliance Airport",
    elevation_ft: "722",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Fort Worth",
    gps_code: "KAFW",
    iata_code: "AFW",
    local_code: "AFW",
    coordinates: "-97.31880187990001, 32.9875984192",
  },
  {
    ident: "KAGC",
    type: "medium_airport",
    name: "Allegheny County Airport",
    elevation_ft: "1252",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Pittsburgh",
    gps_code: "KAGC",
    iata_code: "AGC",
    local_code: "AGC",
    coordinates: "-79.9301986694336, 40.354400634765625",
  },
  {
    ident: "KAGO",
    type: "small_airport",
    name: "Ralph C Weiser Field",
    elevation_ft: "319",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Magnolia",
    gps_code: "KAGO",
    iata_code: "AGO",
    local_code: "AGO",
    coordinates: "-93.217002, 33.228001",
  },
  {
    ident: "KAGS",
    type: "large_airport",
    name: "Augusta Regional At Bush Field",
    elevation_ft: "144",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Augusta",
    gps_code: "KAGS",
    iata_code: "AGS",
    local_code: "AGS",
    coordinates: "-81.9645004272461, 33.36989974975586",
  },
  {
    ident: "KAHC",
    type: "small_airport",
    name: "Amedee Army Air Field",
    elevation_ft: "4012",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Herlong",
    gps_code: "KAHC",
    iata_code: "AHC",
    local_code: "AHC",
    coordinates: "-120.1529999, 40.26620102",
  },
  {
    ident: "KAHH",
    type: "small_airport",
    name: "Amery Municipal Airport",
    elevation_ft: "1088",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Amery",
    gps_code: "KAHH",
    iata_code: "AHH",
    local_code: "AHH",
    coordinates: "-92.37539672850001, 45.2811012268",
  },
  {
    ident: "KAHN",
    type: "medium_airport",
    name: "Athens Ben Epps Airport",
    elevation_ft: "808",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Athens",
    gps_code: "KAHN",
    iata_code: "AHN",
    local_code: "AHN",
    coordinates: "-83.32630157470703, 33.94860076904297",
  },
  {
    ident: "KAIA",
    type: "small_airport",
    name: "Alliance Municipal Airport",
    elevation_ft: "3931",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Alliance",
    gps_code: "KAIA",
    iata_code: "AIA",
    local_code: "AIA",
    coordinates: "-102.804000854, 42.0531997681",
  },
  {
    ident: "KAID",
    type: "small_airport",
    name: "Anderson Municipal Darlington Field",
    elevation_ft: "919",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Anderson",
    gps_code: "KAID",
    iata_code: "AID",
    local_code: "AID",
    coordinates: "-85.6129989624, 40.10860061649999",
  },
  {
    ident: "KAIK",
    type: "small_airport",
    name: "Aiken Regional Airport",
    elevation_ft: "528",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Aiken",
    gps_code: "KAIK",
    iata_code: "AIK",
    local_code: "AIK",
    coordinates: "-81.684998, 33.649399",
  },
  {
    ident: "KAIO",
    type: "small_airport",
    name: "Atlantic Municipal Airport",
    elevation_ft: "1165",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Atlantic",
    gps_code: "KAIO",
    iata_code: "AIO",
    local_code: "AIO",
    coordinates: "-95.04689789, 41.40729904",
  },
  {
    ident: "KAIV",
    type: "small_airport",
    name: "George Downer Airport",
    elevation_ft: "150",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Aliceville",
    gps_code: "KAIV",
    iata_code: "AIV",
    local_code: "AIV",
    coordinates: "-88.1978, 33.106499",
  },
  {
    ident: "KAIY",
    type: "closed",
    name: "Atlantic City Municipal Bader Field",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Atlantic City",
    gps_code: "KAIY",
    iata_code: "AIY",
    local_code: "AIY",
    coordinates: "-74.4561004639, 39.3600006104",
  },
  {
    ident: "KAIZ",
    type: "small_airport",
    name: "Lee C Fine Memorial Airport",
    elevation_ft: "869",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Kaiser Lake Ozark",
    gps_code: "KAIZ",
    iata_code: "AIZ",
    local_code: "AIZ",
    coordinates: "-92.54949951170002, 38.0960006714",
  },
  {
    ident: "KAKO",
    type: "small_airport",
    name: "Colorado Plains Regional Airport",
    elevation_ft: "4714",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Akron",
    gps_code: "KAKO",
    iata_code: "AKO",
    local_code: "AKO",
    coordinates: "-103.222000122, 40.1755981445",
  },
  {
    ident: "KAKR",
    type: "medium_airport",
    name: "Akron Fulton International Airport",
    elevation_ft: "1067",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Akron",
    gps_code: "KAKR",
    iata_code: "AKC",
    local_code: "AKR",
    coordinates: "-81.4669036865, 41.0374984741",
  },
  {
    ident: "KALB",
    type: "medium_airport",
    name: "Albany International Airport",
    elevation_ft: "285",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Albany",
    gps_code: "KALB",
    iata_code: "ALB",
    local_code: "ALB",
    coordinates: "-73.80169677734375, 42.74829864501953",
  },
  {
    ident: "KALI",
    type: "medium_airport",
    name: "Alice International Airport",
    elevation_ft: "178",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Alice",
    gps_code: "KALI",
    iata_code: "ALI",
    local_code: "ALI",
    coordinates: "-98.02690124510002, 27.740900039699998",
  },
  {
    ident: "KALM",
    type: "medium_airport",
    name: "Alamogordo White Sands Regional Airport",
    elevation_ft: "4200",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Alamogordo",
    gps_code: "KALM",
    iata_code: "ALM",
    local_code: "ALM",
    coordinates: "-105.990997314, 32.8399009705",
  },
  {
    ident: "KALN",
    type: "medium_airport",
    name: "St Louis Regional Airport",
    elevation_ft: "544",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Alton/St Louis",
    gps_code: "KALN",
    iata_code: "ALN",
    local_code: "ALN",
    coordinates: "-90.0459976196, 38.89030075069999",
  },
  {
    ident: "KALO",
    type: "medium_airport",
    name: "Waterloo Regional Airport",
    elevation_ft: "873",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Waterloo",
    gps_code: "KALO",
    iata_code: "ALO",
    local_code: "ALO",
    coordinates: "-92.40029907226562, 42.557098388671875",
  },
  {
    ident: "KALS",
    type: "medium_airport",
    name: "San Luis Valley Regional Bergman Field",
    elevation_ft: "7539",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Alamosa",
    gps_code: "KALS",
    iata_code: "ALS",
    local_code: "ALS",
    coordinates: "-105.866997, 37.434898",
  },
  {
    ident: "KALW",
    type: "medium_airport",
    name: "Walla Walla Regional Airport",
    elevation_ft: "1194",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Walla Walla",
    gps_code: "KALW",
    iata_code: "ALW",
    local_code: "ALW",
    coordinates: "-118.288002, 46.09489822",
  },
  {
    ident: "KALX",
    type: "small_airport",
    name: "Thomas C Russell Field",
    elevation_ft: "686",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Alexander City",
    gps_code: "KALX",
    iata_code: "ALX",
    local_code: "ALX",
    coordinates: "-85.9629974365, 32.914699554399995",
  },
  {
    ident: "KAMA",
    type: "large_airport",
    name: "Rick Husband Amarillo International Airport",
    elevation_ft: "3607",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Amarillo",
    gps_code: "KAMA",
    iata_code: "AMA",
    local_code: "AMA",
    coordinates: "-101.706001, 35.219398",
  },
  {
    ident: "KAMN",
    type: "small_airport",
    name: "Gratiot Community Airport",
    elevation_ft: "754",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Alma",
    gps_code: "KAMN",
    iata_code: "AMN",
    local_code: "AMN",
    coordinates: "-84.68800354, 43.322101593",
  },
  {
    ident: "KAMW",
    type: "small_airport",
    name: "Ames Municipal Airport",
    elevation_ft: "956",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Ames",
    gps_code: "KAMW",
    iata_code: "AMW",
    local_code: "AMW",
    coordinates: "-93.621803, 41.992001",
  },
  {
    ident: "KANB",
    type: "medium_airport",
    name: "Anniston Regional Airport",
    elevation_ft: "612",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Anniston",
    gps_code: "KANB",
    iata_code: "ANB",
    local_code: "ANB",
    coordinates: "-85.8581, 33.5882",
  },
  {
    ident: "KAND",
    type: "medium_airport",
    name: "Anderson Regional Airport",
    elevation_ft: "782",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Anderson",
    gps_code: "KAND",
    iata_code: "AND",
    local_code: "AND",
    coordinates: "-82.70939636230001, 34.4945983887",
  },
  {
    ident: "KANK",
    type: "small_airport",
    name: "Salida Airport Harriett Alexander Field",
    elevation_ft: "7523",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Salida",
    gps_code: "KANK",
    iata_code: "SLT",
    local_code: "ANK",
    coordinates: "-106.049004, 38.5383",
  },
  {
    ident: "KANP",
    type: "small_airport",
    name: "Lee Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Annapolis",
    gps_code: "KANP",
    iata_code: "ANP",
    local_code: "ANP",
    coordinates: "-76.568398, 38.942902",
  },
  {
    ident: "KANQ",
    type: "small_airport",
    name: "Tri State Steuben County Airport",
    elevation_ft: "995",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Angola",
    gps_code: "KANQ",
    iata_code: "ANQ",
    local_code: "ANQ",
    coordinates: "-85.083504, 41.639702",
  },
  {
    ident: "KANW",
    type: "small_airport",
    name: "Ainsworth Regional Airport",
    elevation_ft: "2589",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Ainsworth",
    gps_code: "KANW",
    iata_code: "ANW",
    local_code: "ANW",
    coordinates: "-99.992995, 42.579201",
  },
  {
    ident: "KANY",
    type: "small_airport",
    name: "Anthony Municipal Airport",
    elevation_ft: "1340",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Anthony",
    gps_code: "KANY",
    iata_code: "ANY",
    local_code: "ANY",
    coordinates: "-98.079597, 37.158501",
  },
  {
    ident: "KAOH",
    type: "small_airport",
    name: "Lima Allen County Airport",
    elevation_ft: "975",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Lima",
    gps_code: "KAOH",
    iata_code: "AOH",
    local_code: "AOH",
    coordinates: "-84.026703, 40.706902",
  },
  {
    ident: "KAOO",
    type: "medium_airport",
    name: "Altoona Blair County Airport",
    elevation_ft: "1503",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Altoona",
    gps_code: "KAOO",
    iata_code: "AOO",
    local_code: "AOO",
    coordinates: "-78.31999969, 40.29639816",
  },
  {
    ident: "KAPA",
    type: "medium_airport",
    name: "Centennial Airport",
    elevation_ft: "5885",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Denver",
    gps_code: "KAPA",
    iata_code: "APA",
    local_code: "APA",
    coordinates: "-104.848999, 39.57009888",
  },
  {
    ident: "KAPC",
    type: "small_airport",
    name: "Napa County Airport",
    elevation_ft: "35",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Napa",
    gps_code: "KAPC",
    iata_code: "APC",
    local_code: "APC",
    coordinates: "-122.280998, 38.2132",
  },
  {
    ident: "KAPF",
    type: "medium_airport",
    name: "Naples Municipal Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Naples",
    gps_code: "KAPF",
    iata_code: "APF",
    local_code: "APF",
    coordinates: "-81.7752990723, 26.1525993347",
  },
  {
    ident: "KAPG",
    type: "medium_airport",
    name: "Phillips Army Air Field",
    elevation_ft: "57",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Aberdeen Proving Grounds(Aberdeen)",
    gps_code: "KAPG",
    iata_code: "APG",
    local_code: "APG",
    coordinates: "-76.1688, 39.466202",
  },
  {
    ident: "KAPH",
    type: "small_airport",
    name: "A P Hill AAF (Fort A P Hill) Airport",
    elevation_ft: "220",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Fort A. P. Hill",
    gps_code: "KAPH",
    iata_code: "APH",
    local_code: "APH",
    coordinates: "-77.318298, 38.068902",
  },
  {
    ident: "KAPN",
    type: "medium_airport",
    name: "Alpena County Regional Airport",
    elevation_ft: "690",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Alpena",
    gps_code: "KAPN",
    iata_code: "APN",
    local_code: "APN",
    coordinates: "-83.56030273, 45.0780983",
  },
  {
    ident: "KAPT",
    type: "small_airport",
    name: "Marion County Brown Field",
    elevation_ft: "641",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Jasper",
    gps_code: "KAPT",
    iata_code: "APT",
    local_code: "APT",
    coordinates: "-85.585297, 35.060699",
  },
  {
    ident: "KAPV",
    type: "small_airport",
    name: "Apple Valley Airport",
    elevation_ft: "3062",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Apple Valley",
    gps_code: "KAPV",
    iata_code: "APV",
    local_code: "APV",
    coordinates: "-117.185997009, 34.575298309299995",
  },
  {
    ident: "KAQ",
    type: "small_airport",
    name: "Kamulai Airport",
    elevation_ft: "5800",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Kamulai Mission",
    gps_code: "AYKH",
    iata_code: "KAQ",
    local_code: "KML",
    coordinates: "146.834, -8.150694444440001",
  },
  {
    ident: "KARA",
    type: "medium_airport",
    name: "Acadiana Regional Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "New Iberia",
    gps_code: "KARA",
    iata_code: "ARA",
    local_code: "ARA",
    coordinates: "-91.883904, 30.0378",
  },
  {
    ident: "KARB",
    type: "small_airport",
    name: "Ann Arbor Municipal Airport",
    elevation_ft: "839",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Ann Arbor",
    gps_code: "KARB",
    iata_code: "ARB",
    local_code: "ARB",
    coordinates: "-83.74559783939999, 42.2229995728",
  },
  {
    ident: "KARG",
    type: "small_airport",
    name: "Walnut Ridge Regional Airport",
    elevation_ft: "279",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Walnut Ridge",
    gps_code: "KARG",
    iata_code: "ARG",
    local_code: "ARG",
    coordinates: "-90.925111, 36.124667",
  },
  {
    ident: "KARM",
    type: "small_airport",
    name: "Wharton Regional Airport",
    elevation_ft: "100",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Wharton",
    gps_code: "KARM",
    iata_code: "WHT",
    local_code: "ARM",
    coordinates: "-96.1544036865, 29.254299163800003",
  },
  {
    ident: "KARR",
    type: "small_airport",
    name: "Aurora Municipal Airport",
    elevation_ft: "712",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Chicago/Aurora",
    gps_code: "KARR",
    iata_code: "AUZ",
    local_code: "ARR",
    coordinates: "-88.47570037839999, 41.771900177",
  },
  {
    ident: "KART",
    type: "medium_airport",
    name: "Watertown International Airport",
    elevation_ft: "325",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Watertown",
    gps_code: "KART",
    iata_code: "ART",
    local_code: "ART",
    coordinates: "-76.02169799804688, 43.99190139770508",
  },
  {
    ident: "KARV",
    type: "small_airport",
    name: "Lakeland-Noble F. Lee Memorial field",
    elevation_ft: "1629",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Minocqua-Woodruff",
    gps_code: "KARV",
    iata_code: "ARV",
    local_code: "ARV",
    coordinates: "-89.73090363, 45.92789841",
  },
  {
    ident: "KARW",
    type: "small_airport",
    name: "Beaufort County Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Beaufort",
    gps_code: "KARW",
    iata_code: "BFT",
    local_code: "ARW",
    coordinates: "-80.6343994141, 32.4122009277",
  },
  {
    ident: "KASE",
    type: "medium_airport",
    name: "Aspen-Pitkin Co/Sardy Field",
    elevation_ft: "7820",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Aspen",
    gps_code: "KASE",
    iata_code: "ASE",
    local_code: "ASE",
    coordinates: "-106.8690033, 39.22320175",
  },
  {
    ident: "KASG",
    type: "small_airport",
    name: "Springdale Municipal Airport",
    elevation_ft: "1353",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Springdale",
    gps_code: "KASG",
    iata_code: "SPZ",
    local_code: "ASG",
    coordinates: "-94.1193008423, 36.176399231",
  },
  {
    ident: "KASH",
    type: "small_airport",
    name: "Boire Field",
    elevation_ft: "199",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Nashua",
    gps_code: "KASH",
    iata_code: "ASH",
    local_code: "ASH",
    coordinates: "-71.51480102539999, 42.7817001343",
  },
  {
    ident: "KASL",
    type: "small_airport",
    name: "Harrison County Airport",
    elevation_ft: "357",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Marshall",
    gps_code: "KASL",
    iata_code: "ASL",
    local_code: "ASL",
    coordinates: "-94.307800293, 32.5205001831",
  },
  {
    ident: "KASN",
    type: "small_airport",
    name: "Talladega Municipal Airport",
    elevation_ft: "529",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Talladega",
    gps_code: "KASN",
    iata_code: "ASN",
    local_code: "ASN",
    coordinates: "-86.05090332030001, 33.569900512699995",
  },
  {
    ident: "KAST",
    type: "medium_airport",
    name: "Astoria Regional Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Astoria",
    gps_code: "KAST",
    iata_code: "AST",
    local_code: "AST",
    coordinates: "-123.878997803, 46.158000946",
  },
  {
    ident: "KASX",
    type: "small_airport",
    name: "John F Kennedy Memorial Airport",
    elevation_ft: "827",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Ashland",
    gps_code: "KASX",
    iata_code: "ASX",
    local_code: "ASX",
    coordinates: "-90.91899872, 46.54850006",
  },
  {
    ident: "KASY",
    type: "small_airport",
    name: "Ashley Municipal Airport",
    elevation_ft: "2032",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Ashley",
    gps_code: "KASY",
    iata_code: "ASY",
    local_code: "ASY",
    coordinates: "-99.3526000977, 46.0238990784",
  },
  {
    ident: "KATL",
    type: "large_airport",
    name: "Hartsfield Jackson Atlanta International Airport",
    elevation_ft: "1026",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Atlanta",
    gps_code: "KATL",
    iata_code: "ATL",
    local_code: "ATL",
    coordinates: "-84.428101, 33.6367",
  },
  {
    ident: "KATS",
    type: "small_airport",
    name: "Artesia Municipal Airport",
    elevation_ft: "3541",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Artesia",
    gps_code: "KATS",
    iata_code: "ATS",
    local_code: "ATS",
    coordinates: "-104.468002319, 32.8525009155",
  },
  {
    ident: "KATW",
    type: "small_airport",
    name: "Appleton International Airport",
    elevation_ft: "918",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Appleton",
    gps_code: "KATW",
    iata_code: "ATW",
    local_code: "ATW",
    coordinates: "-88.5190963745, 44.258098602299995",
  },
  {
    ident: "KATY",
    type: "medium_airport",
    name: "Watertown Regional Airport",
    elevation_ft: "1749",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Watertown",
    gps_code: "KATY",
    iata_code: "ATY",
    local_code: "ATY",
    coordinates: "-97.15470123, 44.91400146",
  },
  {
    ident: "KAUG",
    type: "medium_airport",
    name: "Augusta State Airport",
    elevation_ft: "352",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Augusta",
    gps_code: "KAUG",
    iata_code: "AUG",
    local_code: "AUG",
    coordinates: "-69.7973022461, 44.320598602299995",
  },
  {
    ident: "KAUM",
    type: "small_airport",
    name: "Austin Municipal Airport",
    elevation_ft: "1234",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Austin",
    gps_code: "KAUM",
    iata_code: "AUM",
    local_code: "AUM",
    coordinates: "-92.93340302, 43.66500092",
  },
  {
    ident: "KAUN",
    type: "small_airport",
    name: "Auburn Municipal Airport",
    elevation_ft: "1539",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Auburn",
    gps_code: "KAUN",
    iata_code: "AUN",
    local_code: "AUN",
    coordinates: "-121.0820007, 38.95479965",
  },
  {
    ident: "KAUO",
    type: "small_airport",
    name: "Auburn University Regional Airport",
    elevation_ft: "777",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Auburn",
    gps_code: "KAUO",
    iata_code: "AUO",
    local_code: "AUO",
    coordinates: "-85.433998, 32.615101",
  },
  {
    ident: "KAUS",
    type: "large_airport",
    name: "Austin Bergstrom International Airport",
    elevation_ft: "542",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Austin",
    gps_code: "KAUS",
    iata_code: "AUS",
    local_code: "AUS",
    coordinates: "-97.6698989868164, 30.194499969482422",
  },
  {
    ident: "KAUW",
    type: "medium_airport",
    name: "Wausau Downtown Airport",
    elevation_ft: "1201",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Wausau",
    gps_code: "KAUW",
    iata_code: "AUW",
    local_code: "AUW",
    coordinates: "-89.6266021729, 44.9262008667",
  },
  {
    ident: "KAVL",
    type: "large_airport",
    name: "Asheville Regional Airport",
    elevation_ft: "2165",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Asheville",
    gps_code: "KAVL",
    iata_code: "AVL",
    local_code: "AVL",
    coordinates: "-82.541801, 35.436199",
  },
  {
    ident: "KAVO",
    type: "small_airport",
    name: "Avon Park Executive Airport",
    elevation_ft: "160",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Avon Park",
    gps_code: "KAVO",
    iata_code: "AVO",
    local_code: "AVO",
    coordinates: "-81.52780151, 27.59119987",
  },
  {
    ident: "KAVP",
    type: "medium_airport",
    name: "Wilkes Barre Scranton International Airport",
    elevation_ft: "962",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Wilkes-Barre/Scranton",
    gps_code: "KAVP",
    iata_code: "AVP",
    local_code: "AVP",
    coordinates: "-75.72339630130001, 41.338500976599995",
  },
  {
    ident: "KAVQ",
    type: "small_airport",
    name: "Marana Regional Airport",
    elevation_ft: "2031",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Tucson",
    gps_code: "KAVQ",
    iata_code: "AVW",
    local_code: "AVQ",
    coordinates: "-111.218002319, 32.4095993042",
  },
  {
    ident: "KAVX",
    type: "small_airport",
    name: "Catalina Airport",
    elevation_ft: "1602",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Avalon",
    gps_code: "KAVX",
    iata_code: "AVX",
    local_code: "AVX",
    coordinates: "-118.416, 33.4049",
  },
  {
    ident: "KAWM",
    type: "small_airport",
    name: "West Memphis Municipal Airport",
    elevation_ft: "212",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "West Memphis",
    gps_code: "KAWM",
    iata_code: "AWM",
    local_code: "AWM",
    coordinates: "-90.2343978882, 35.1351013184",
  },
  {
    ident: "KAXA",
    type: "small_airport",
    name: "Algona Municipal Airport",
    elevation_ft: "1219",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Algona",
    gps_code: "KAXA",
    iata_code: "AXG",
    local_code: "AXA",
    coordinates: "-94.2720031738, 43.0778999329",
  },
  {
    ident: "KAXN",
    type: "medium_airport",
    name: "Chandler Field",
    elevation_ft: "1425",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Alexandria",
    gps_code: "KAXN",
    iata_code: "AXN",
    local_code: "AXN",
    coordinates: "-95.39469909670001, 45.8662986755",
  },
  {
    ident: "KAXS",
    type: "small_airport",
    name: "Altus Quartz Mountain Regional Airport",
    elevation_ft: "1433",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Altus",
    gps_code: "KAXS",
    iata_code: "AXS",
    local_code: "AXS",
    coordinates: "-99.3385, 34.697952",
  },
  {
    ident: "KAXV",
    type: "small_airport",
    name: "Neil Armstrong Airport",
    elevation_ft: "913",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Wapakoneta",
    gps_code: "KAXV",
    iata_code: "AXV",
    local_code: "AXV",
    coordinates: "-84.29889679, 40.49340057",
  },
  {
    ident: "KAXX",
    type: "small_airport",
    name: "Angel Fire Airport",
    elevation_ft: "8380",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Angel Fire",
    gps_code: "KAXX",
    iata_code: "AXX",
    local_code: "AXX",
    coordinates: "-105.290000916, 36.422000885",
  },
  {
    ident: "KAYE",
    type: "closed",
    name: "Ft Devens Moore Army Air Field",
    elevation_ft: "269",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    gps_code: "KAYE",
    iata_code: "AYE",
    coordinates: "-71.60279846191406, 42.56999969482422",
  },
  {
    ident: "KAYS",
    type: "small_airport",
    name: "Waycross Ware County Airport",
    elevation_ft: "142",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Waycross",
    gps_code: "KAYS",
    iata_code: "AYS",
    local_code: "AYS",
    coordinates: "-82.39550018310001, 31.2490997314",
  },
  {
    ident: "KAYX",
    type: "small_airport",
    name: "Arnold Air Force Base",
    elevation_ft: "1067",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Tullahoma",
    gps_code: "KAYX",
    iata_code: "TUH",
    local_code: "AYX",
    coordinates: "-86.08580017, 35.39260101",
  },
  {
    ident: "KAZO",
    type: "medium_airport",
    name: "Kalamazoo Battle Creek International Airport",
    elevation_ft: "874",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Kalamazoo",
    gps_code: "KAZO",
    iata_code: "AZO",
    local_code: "AZO",
    coordinates: "-85.5521011352539, 42.234901428222656",
  },
  {
    ident: "KBAB",
    type: "large_airport",
    name: "Beale Air Force Base",
    elevation_ft: "113",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Marysville",
    gps_code: "KBAB",
    iata_code: "BAB",
    local_code: "BAB",
    coordinates: "-121.43699646, 39.136100769",
  },
  {
    ident: "KBAD",
    type: "large_airport",
    name: "Barksdale Air Force Base",
    elevation_ft: "166",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Bossier City",
    gps_code: "KBAD",
    iata_code: "BAD",
    local_code: "BAD",
    coordinates: "-93.6626968384, 32.5018005371",
  },
  {
    ident: "KBAF",
    type: "medium_airport",
    name: "Westfield-Barnes Regional Airport",
    elevation_ft: "271",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Westfield/Springfield",
    gps_code: "KBAF",
    iata_code: "BAF",
    local_code: "BAF",
    coordinates: "-72.715599, 42.157799",
  },
  {
    ident: "KBAK",
    type: "medium_airport",
    name: "Columbus Municipal Airport",
    elevation_ft: "656",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Columbus",
    gps_code: "KBAK",
    iata_code: "CLU",
    local_code: "BAK",
    coordinates: "-85.8963012695, 39.2619018555",
  },
  {
    ident: "KBAM",
    type: "small_airport",
    name: "Battle Mountain Airport",
    elevation_ft: "4532",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Battle Mountain",
    gps_code: "KBAM",
    iata_code: "BAM",
    local_code: "BAM",
    coordinates: "-116.874000549, 40.598999023400005",
  },
  {
    ident: "KBBB",
    type: "small_airport",
    name: "Benson Municipal Airport",
    elevation_ft: "1039",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Benson",
    gps_code: "KBBB",
    iata_code: "BBB",
    local_code: "BBB",
    coordinates: "-95.6505966187, 45.331901550299996",
  },
  {
    ident: "KBBD",
    type: "medium_airport",
    name: "Curtis Field",
    elevation_ft: "1827",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Brady",
    gps_code: "KBBD",
    iata_code: "BBD",
    local_code: "BBD",
    coordinates: "-99.3238983154, 31.1793003082",
  },
  {
    ident: "KBBP",
    type: "small_airport",
    name: "Marlboro County Jetport H.E. Avent Field",
    elevation_ft: "147",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Bennettsville",
    gps_code: "KBBP",
    iata_code: "BTN",
    local_code: "BBP",
    coordinates: "-79.73439789, 34.62170029",
  },
  {
    ident: "KBBW",
    type: "small_airport",
    name: "Broken Bow Municipal Airport",
    elevation_ft: "2547",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Broken Bow",
    gps_code: "KBBW",
    iata_code: "BBW",
    local_code: "BBW",
    coordinates: "-99.6421966553, 41.4365005493",
  },
  {
    ident: "KBCB",
    type: "small_airport",
    name: "Virginia Tech Montgomery Executive Airport",
    elevation_ft: "2132",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Blacksburg",
    gps_code: "KBCB",
    iata_code: "BCB",
    local_code: "BCB",
    coordinates: "-80.4077987671, 37.207599639899996",
  },
  {
    ident: "KBCE",
    type: "medium_airport",
    name: "Bryce Canyon Airport",
    elevation_ft: "7590",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Bryce Canyon",
    gps_code: "KBCE",
    iata_code: "BCE",
    local_code: "BCE",
    coordinates: "-112.144996643, 37.706401825",
  },
  {
    ident: "KBCT",
    type: "small_airport",
    name: "Boca Raton Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Boca Raton",
    gps_code: "KBCT",
    iata_code: "BCT",
    local_code: "BCT",
    coordinates: "-80.1076965332, 26.3784999847",
  },
  {
    ident: "KBDE",
    type: "medium_airport",
    name: "Baudette International Airport",
    elevation_ft: "1086",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Baudette",
    gps_code: "KBDE",
    iata_code: "BDE",
    local_code: "BDE",
    coordinates: "-94.612197876, 48.7284011841",
  },
  {
    ident: "KBDG",
    type: "small_airport",
    name: "Blanding Municipal Airport",
    elevation_ft: "5868",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Blanding",
    gps_code: "KBDG",
    iata_code: "BDG",
    local_code: "BDG",
    coordinates: "-109.4830017, 37.58330154",
  },
  {
    ident: "KBDL",
    type: "large_airport",
    name: "Bradley International Airport",
    elevation_ft: "173",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CT",
    municipality: "Hartford",
    gps_code: "KBDL",
    iata_code: "BDL",
    local_code: "BDL",
    coordinates: "-72.68319702149999, 41.9388999939",
  },
  {
    ident: "KBDR",
    type: "medium_airport",
    name: "Igor I Sikorsky Memorial Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CT",
    municipality: "Bridgeport",
    gps_code: "KBDR",
    iata_code: "BDR",
    local_code: "BDR",
    coordinates: "-73.1261978149414, 41.16350173950195",
  },
  {
    ident: "KBDU",
    type: "small_airport",
    name: "Boulder Municipal Airport",
    elevation_ft: "5288",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Boulder",
    gps_code: "KBDU",
    iata_code: "WBU",
    local_code: "BDU",
    coordinates: "-105.225997925, 40.0393981934",
  },
  {
    ident: "KBE",
    type: "seaplane_base",
    name: "Bell Island Hot Springs Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Bell Island",
    gps_code: "KBE",
    iata_code: "KBE",
    local_code: "KBE",
    coordinates: "-131.572006226, 55.9291000366",
  },
  {
    ident: "KBEC",
    type: "small_airport",
    name: "Beech Factory Airport",
    elevation_ft: "1408",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Wichita",
    gps_code: "KBEC",
    iata_code: "BEC",
    local_code: "BEC",
    coordinates: "-97.21499633790002, 37.694499969499994",
  },
  {
    ident: "KBED",
    type: "medium_airport",
    name: "Laurence G Hanscom Field",
    elevation_ft: "133",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Bedford",
    gps_code: "KBED",
    iata_code: "BED",
    local_code: "BED",
    coordinates: "-71.28900146, 42.47000122",
  },
  {
    ident: "KBEH",
    type: "small_airport",
    name: "Southwest Michigan Regional Airport",
    elevation_ft: "643",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Benton Harbor",
    gps_code: "KBEH",
    iata_code: "BEH",
    local_code: "BEH",
    coordinates: "-86.4284973145, 42.128601074200006",
  },
  {
    ident: "KBFD",
    type: "medium_airport",
    name: "Bradford Regional Airport",
    elevation_ft: "2143",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Bradford",
    gps_code: "KBFD",
    iata_code: "BFD",
    local_code: "BFD",
    coordinates: "-78.64009857177734, 41.8031005859375",
  },
  {
    ident: "KBFF",
    type: "medium_airport",
    name: "Western Neb. Rgnl/William B. Heilig Airport",
    elevation_ft: "3967",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Scottsbluff",
    gps_code: "KBFF",
    iata_code: "BFF",
    local_code: "BFF",
    coordinates: "-103.5960007, 41.87400055",
  },
  {
    ident: "KBFI",
    type: "large_airport",
    name: "Boeing Field King County International Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Seattle",
    gps_code: "KBFI",
    iata_code: "BFI",
    local_code: "BFI",
    coordinates: "-122.302001953125, 47.529998779296875",
  },
  {
    ident: "KBFL",
    type: "medium_airport",
    name: "Meadows Field",
    elevation_ft: "510",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Bakersfield",
    gps_code: "KBFL",
    iata_code: "BFL",
    local_code: "BFL",
    coordinates: "-119.0569992, 35.43360138",
  },
  {
    ident: "KBFM",
    type: "medium_airport",
    name: "Mobile Downtown Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Mobile",
    gps_code: "KBFM",
    iata_code: "BFM",
    local_code: "BFM",
    coordinates: "-88.06809997559999, 30.626800537100003",
  },
  {
    ident: "KBFR",
    type: "small_airport",
    name: "Virgil I Grissom Municipal Airport",
    elevation_ft: "727",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Bedford",
    gps_code: "KBFR",
    iata_code: "BFR",
    local_code: "BFR",
    coordinates: "-86.44539642, 38.84000015",
  },
  {
    ident: "KBGD",
    type: "small_airport",
    name: "Hutchinson County Airport",
    elevation_ft: "3055",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Borger",
    gps_code: "KBGD",
    iata_code: "BGD",
    local_code: "BGD",
    coordinates: "-101.393997192, 35.700901031499995",
  },
  {
    ident: "KBGE",
    type: "small_airport",
    name: "Decatur County Industrial Air Park",
    elevation_ft: "141",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Bainbridge",
    gps_code: "KBGE",
    iata_code: "BGE",
    local_code: "BGE",
    coordinates: "-84.63739777, 30.9715004",
  },
  {
    ident: "KBGM",
    type: "medium_airport",
    name: "Greater Binghamton/Edwin A Link field",
    elevation_ft: "1636",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Binghamton",
    gps_code: "KBGM",
    iata_code: "BGM",
    local_code: "BGM",
    coordinates: "-75.97979736, 42.20869827",
  },
  {
    ident: "KBGR",
    type: "large_airport",
    name: "Bangor International Airport",
    elevation_ft: "192",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Bangor",
    gps_code: "KBGR",
    iata_code: "BGR",
    local_code: "BGR",
    coordinates: "-68.8281021118164, 44.80739974975586",
  },
  {
    ident: "KBHB",
    type: "medium_airport",
    name: "Hancock County-Bar Harbor Airport",
    elevation_ft: "83",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Bar Harbor",
    gps_code: "KBHB",
    iata_code: "BHB",
    local_code: "BHB",
    coordinates: "-68.3615036, 44.45000076",
  },
  {
    ident: "KBHM",
    type: "large_airport",
    name: "Birmingham-Shuttlesworth International Airport",
    elevation_ft: "650",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Birmingham",
    gps_code: "KBHM",
    iata_code: "BHM",
    local_code: "BHM",
    coordinates: "-86.75350189, 33.56290054",
  },
  {
    ident: "KBID",
    type: "small_airport",
    name: "Block Island State Airport",
    elevation_ft: "108",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-RI",
    municipality: "Block Island",
    gps_code: "KBID",
    iata_code: "BID",
    local_code: "BID",
    coordinates: "-71.577796936, 41.1680984497",
  },
  {
    ident: "KBIE",
    type: "small_airport",
    name: "Beatrice Municipal Airport",
    elevation_ft: "1324",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Beatrice",
    gps_code: "KBIE",
    iata_code: "BIE",
    local_code: "BIE",
    coordinates: "-96.75409698490002, 40.301300048799995",
  },
  {
    ident: "KBIF",
    type: "medium_airport",
    name: "Biggs Army Air Field (Fort Bliss)",
    elevation_ft: "3946",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Fort Bliss/El Paso",
    gps_code: "KBIF",
    iata_code: "BIF",
    local_code: "BIF",
    coordinates: "-106.3799973, 31.84950066",
  },
  {
    ident: "KBIH",
    type: "medium_airport",
    name: "Eastern Sierra Regional Airport",
    elevation_ft: "4124",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Bishop",
    gps_code: "KBIH",
    iata_code: "BIH",
    local_code: "BIH",
    coordinates: "-118.363998413, 37.3731002808",
  },
  {
    ident: "KBIL",
    type: "large_airport",
    name: "Billings Logan International Airport",
    elevation_ft: "3652",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Billings",
    gps_code: "KBIL",
    iata_code: "BIL",
    local_code: "BIL",
    coordinates: "-108.54299926757812, 45.807701110839844",
  },
  {
    ident: "KBIS",
    type: "medium_airport",
    name: "Bismarck Municipal Airport",
    elevation_ft: "1661",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Bismarck",
    gps_code: "KBIS",
    iata_code: "BIS",
    local_code: "BIS",
    coordinates: "-100.74600219726562, 46.772701263427734",
  },
  {
    ident: "KBIX",
    type: "medium_airport",
    name: "Keesler Air Force Base",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Biloxi",
    gps_code: "KBIX",
    iata_code: "BIX",
    local_code: "BIX",
    coordinates: "-88.92440032959999, 30.4104003906",
  },
  {
    ident: "KBJC",
    type: "medium_airport",
    name: "Rocky Mountain Metropolitan Airport",
    elevation_ft: "5673",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Denver",
    gps_code: "KBJC",
    iata_code: "BJC",
    local_code: "BJC",
    coordinates: "-105.1169968, 39.90879822",
  },
  {
    ident: "KBJI",
    type: "medium_airport",
    name: "Bemidji Regional Airport",
    elevation_ft: "1391",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Bemidji",
    gps_code: "KBJI",
    iata_code: "BJI",
    local_code: "BJI",
    coordinates: "-94.93370056, 47.50939941",
  },
  {
    ident: "KBJJ",
    type: "small_airport",
    name: "Wayne County Airport",
    elevation_ft: "1136",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Wooster",
    gps_code: "KBJJ",
    iata_code: "BJJ",
    local_code: "BJJ",
    coordinates: "-81.88829803470001, 40.874801635699995",
  },
  {
    ident: "KBKD",
    type: "small_airport",
    name: "Stephens County Airport",
    elevation_ft: "1284",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Breckenridge",
    gps_code: "KBKD",
    iata_code: "BKD",
    local_code: "BKD",
    coordinates: "-98.89099884030001, 32.71900177",
  },
  {
    ident: "KBKE",
    type: "medium_airport",
    name: "Baker City Municipal Airport",
    elevation_ft: "3373",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Baker City",
    gps_code: "KBKE",
    iata_code: "BKE",
    local_code: "BKE",
    coordinates: "-117.808998108, 44.837299346900004",
  },
  {
    ident: "KBKF",
    type: "medium_airport",
    name: "Buckley Air Force Base",
    elevation_ft: "5662",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Aurora",
    gps_code: "KBKF",
    iata_code: "BFK",
    local_code: "BKF",
    coordinates: "-104.751998901, 39.701698303200004",
  },
  {
    ident: "KBKL",
    type: "medium_airport",
    name: "Burke Lakefront Airport",
    elevation_ft: "583",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Cleveland",
    gps_code: "KBKL",
    iata_code: "BKL",
    local_code: "BKL",
    coordinates: "-81.68329620361328, 41.51750183105469",
  },
  {
    ident: "KBKT",
    type: "small_airport",
    name: "Allen C Perkinson Blackstone Army Air Field",
    elevation_ft: "439",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Blackstone",
    gps_code: "KBKT",
    iata_code: "BKT",
    local_code: "BKT",
    coordinates: "-77.9574966431, 37.0741996765",
  },
  {
    ident: "KBKW",
    type: "medium_airport",
    name: "Raleigh County Memorial Airport",
    elevation_ft: "2504",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Beckley",
    gps_code: "KBKW",
    iata_code: "BKW",
    local_code: "BKW",
    coordinates: "-81.1241989136, 37.787300109899995",
  },
  {
    ident: "KBKX",
    type: "small_airport",
    name: "Brookings Regional Airport",
    elevation_ft: "1648",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Brookings",
    gps_code: "KBKX",
    iata_code: "BKX",
    local_code: "BKX",
    coordinates: "-96.816902, 44.304798",
  },
  {
    ident: "KBLF",
    type: "medium_airport",
    name: "Mercer County Airport",
    elevation_ft: "2857",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Bluefield",
    gps_code: "KBLF",
    iata_code: "BLF",
    local_code: "BLF",
    coordinates: "-81.20770263671875, 37.295799255371094",
  },
  {
    ident: "KBLH",
    type: "medium_airport",
    name: "Blythe Airport",
    elevation_ft: "399",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Blythe",
    gps_code: "KBLH",
    iata_code: "BLH",
    local_code: "BLH",
    coordinates: "-114.717002869, 33.6192016602",
  },
  {
    ident: "KBLI",
    type: "medium_airport",
    name: "Bellingham International Airport",
    elevation_ft: "170",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Bellingham",
    gps_code: "KBLI",
    iata_code: "BLI",
    local_code: "BLI",
    coordinates: "-122.53800201416016, 48.79280090332031",
  },
  {
    ident: "KBLM",
    type: "small_airport",
    name: "Monmouth Executive Airport",
    elevation_ft: "153",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Belmar/Farmingdale",
    gps_code: "KBLM",
    iata_code: "BLM",
    local_code: "BLM",
    coordinates: "-74.12490082, 40.18690109",
  },
  {
    ident: "KBLU",
    type: "small_airport",
    name: "Blue Canyon Nyack Airport",
    elevation_ft: "5284",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Emigrant Gap",
    gps_code: "KBLU",
    iata_code: "BLU",
    local_code: "BLU",
    coordinates: "-120.709999084, 39.2750015259",
  },
  {
    ident: "KBLV",
    type: "large_airport",
    name: "Scott AFB/Midamerica Airport",
    elevation_ft: "459",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Belleville",
    gps_code: "KBLV",
    iata_code: "BLV",
    local_code: "BLV",
    coordinates: "-89.835197, 38.5452",
  },
  {
    ident: "KBM",
    type: "small_airport",
    name: "Kabwum",
    elevation_ft: "4450",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    gps_code: "AYKB",
    iata_code: "KBM",
    local_code: "KBM",
    coordinates: "147.191472222, -6.15547222222",
  },
  {
    ident: "KBMC",
    type: "small_airport",
    name: "Brigham City Regional Airport",
    elevation_ft: "4229",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Brigham City",
    gps_code: "KBMC",
    iata_code: "BMC",
    local_code: "BMC",
    coordinates: "-112.061996, 41.552399",
  },
  {
    ident: "KBMG",
    type: "medium_airport",
    name: "Monroe County Airport",
    elevation_ft: "846",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Bloomington",
    gps_code: "KBMG",
    iata_code: "BMG",
    local_code: "BMG",
    coordinates: "-86.61669921875, 39.145999908447266",
  },
  {
    ident: "KBMI",
    type: "large_airport",
    name: "Central Illinois Regional Airport at Bloomington-Normal",
    elevation_ft: "871",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Bloomington/Normal",
    gps_code: "KBMI",
    iata_code: "BMI",
    local_code: "BMI",
    coordinates: "-88.91590118, 40.47710037",
  },
  {
    ident: "KBML",
    type: "small_airport",
    name: "Berlin Regional Airport",
    elevation_ft: "1161",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Berlin",
    gps_code: "KBML",
    iata_code: "BML",
    local_code: "BML",
    coordinates: "-71.17590332, 44.57540131",
  },
  {
    ident: "KBMT",
    type: "small_airport",
    name: "Beaumont Municipal Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Beaumont",
    gps_code: "KBMT",
    iata_code: "BMT",
    local_code: "BMT",
    coordinates: "-94.215746, 30.070635",
  },
  {
    ident: "KBNA",
    type: "large_airport",
    name: "Nashville International Airport",
    elevation_ft: "599",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Nashville",
    gps_code: "KBNA",
    iata_code: "BNA",
    local_code: "BNA",
    coordinates: "-86.6781997680664, 36.1245002746582",
  },
  {
    ident: "KBNG",
    type: "small_airport",
    name: "Banning Municipal Airport",
    elevation_ft: "2219",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Banning",
    gps_code: "KBNG",
    iata_code: "BNG",
    local_code: "BNG",
    coordinates: "-116.850672, 33.922548",
  },
  {
    ident: "KBNL",
    type: "small_airport",
    name: "Barnwell Regional Airport",
    elevation_ft: "246",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Barnwell",
    gps_code: "KBNL",
    iata_code: "BNL",
    local_code: "BNL",
    coordinates: "-81.38829803, 33.25780106",
  },
  {
    ident: "KBNO",
    type: "medium_airport",
    name: "Burns Municipal Airport",
    elevation_ft: "4148",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Burns",
    gps_code: "KBNO",
    iata_code: "BNO",
    local_code: "BNO",
    coordinates: "-118.955001831, 43.5918998718",
  },
  {
    ident: "KBNW",
    type: "small_airport",
    name: "Boone Municipal Airport",
    elevation_ft: "1160",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Boone",
    gps_code: "KBNW",
    iata_code: "BNW",
    local_code: "BNW",
    coordinates: "-93.8476028442, 42.0495986938",
  },
  {
    ident: "KBOI",
    type: "large_airport",
    name: "Boise Air Terminal/Gowen Field",
    elevation_ft: "2871",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Boise",
    gps_code: "KBOI",
    iata_code: "BOI",
    local_code: "BOI",
    coordinates: "-116.223, 43.5644",
  },
  {
    ident: "KBOS",
    type: "large_airport",
    name: "General Edward Lawrence Logan International Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Boston",
    gps_code: "KBOS",
    iata_code: "BOS",
    local_code: "BOS",
    coordinates: "-71.00520325, 42.36429977",
  },
  {
    ident: "KBOW",
    type: "small_airport",
    name: "Bartow Executive Airport",
    elevation_ft: "125",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Bartow",
    gps_code: "KBOW",
    iata_code: "BOW",
    local_code: "BOW",
    coordinates: "-81.783401, 27.943399",
  },
  {
    ident: "KBPG",
    type: "small_airport",
    name: "Big Spring Mc Mahon-Wrinkle Airport",
    elevation_ft: "2573",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Big Spring",
    gps_code: "KBPG",
    iata_code: "HCA",
    local_code: "BPG",
    coordinates: "-101.522003, 32.212601",
  },
  {
    ident: "KBPI",
    type: "medium_airport",
    name: "Miley Memorial Field",
    elevation_ft: "6990",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Big Piney",
    gps_code: "KBPI",
    iata_code: "BPI",
    local_code: "BPI",
    coordinates: "-110.1110001, 42.58509827",
  },
  {
    ident: "KBPK",
    type: "medium_airport",
    name: "Ozark Regional Airport",
    elevation_ft: "928",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Mountain Home",
    gps_code: "KBPK",
    iata_code: "WMH",
    local_code: "BPK",
    coordinates: "-92.47049713130001, 36.3689002991",
  },
  {
    ident: "KBPT",
    type: "medium_airport",
    name: "Southeast Texas Regional Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Beaumont/Port Arthur",
    gps_code: "KBPT",
    iata_code: "BPT",
    local_code: "BPT",
    coordinates: "-94.02069854736328, 29.9507999420166",
  },
  {
    ident: "KBQK",
    type: "medium_airport",
    name: "Brunswick Golden Isles Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Brunswick",
    gps_code: "KBQK",
    iata_code: "BQK",
    local_code: "BQK",
    coordinates: "-81.46649932861328, 31.258800506591797",
  },
  {
    ident: "KBRD",
    type: "medium_airport",
    name: "Brainerd Lakes Regional Airport",
    elevation_ft: "1232",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Brainerd",
    gps_code: "KBRD",
    iata_code: "BRD",
    local_code: "BRD",
    coordinates: "-94.13809967, 46.39830017",
  },
  {
    ident: "KBRL",
    type: "medium_airport",
    name: "Southeast Iowa Regional Airport",
    elevation_ft: "698",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Burlington",
    gps_code: "KBRL",
    iata_code: "BRL",
    local_code: "BRL",
    coordinates: "-91.12550354003906, 40.783199310302734",
  },
  {
    ident: "KBRO",
    type: "medium_airport",
    name: "Brownsville South Padre Island International Airport",
    elevation_ft: "22",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Brownsville",
    gps_code: "KBRO",
    iata_code: "BRO",
    local_code: "BRO",
    coordinates: "-97.4259033203125, 25.90679931640625",
  },
  {
    ident: "KBRY",
    type: "small_airport",
    name: "Samuels Field",
    elevation_ft: "669",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Bardstown",
    gps_code: "KBRY",
    iata_code: "BRY",
    local_code: "BRY",
    coordinates: "-85.4996032715, 37.8143005371",
  },
  {
    ident: "KBTF",
    type: "small_airport",
    name: "Skypark Airport",
    elevation_ft: "4234",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Bountiful",
    gps_code: "KBTF",
    iata_code: "BTF",
    local_code: "BTF",
    coordinates: "-111.927001953, 40.8694000244",
  },
  {
    ident: "KBTL",
    type: "medium_airport",
    name: "W K Kellogg Airport",
    elevation_ft: "952",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Battle Creek",
    gps_code: "KBTL",
    iata_code: "BTL",
    local_code: "BTL",
    coordinates: "-85.2515029907, 42.307300567599995",
  },
  {
    ident: "KBTM",
    type: "medium_airport",
    name: "Bert Mooney Airport",
    elevation_ft: "5550",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Butte",
    gps_code: "KBTM",
    iata_code: "BTM",
    local_code: "BTM",
    coordinates: "-112.49700164794922, 45.95479965209961",
  },
  {
    ident: "KBTN",
    type: "small_airport",
    name: "Britton Municipal Airport",
    elevation_ft: "1318",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Britton",
    gps_code: "KBTN",
    iata_code: "TTO",
    local_code: "BTN",
    coordinates: "-97.743103027344, 45.815200805664",
  },
  {
    ident: "KBTP",
    type: "small_airport",
    name: "Pittsburgh/Butler Regional Airport",
    elevation_ft: "1248",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Butler",
    gps_code: "KBTP",
    iata_code: "BTP",
    local_code: "BTP",
    coordinates: "-79.949699, 40.776901",
  },
  {
    ident: "KBTR",
    type: "medium_airport",
    name: "Baton Rouge Metropolitan Airport",
    elevation_ft: "70",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Baton Rouge",
    gps_code: "KBTR",
    iata_code: "BTR",
    local_code: "BTR",
    coordinates: "-91.149597, 30.533199",
  },
  {
    ident: "KBTV",
    type: "medium_airport",
    name: "Burlington International Airport",
    elevation_ft: "335",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VT",
    municipality: "Burlington",
    gps_code: "KBTV",
    iata_code: "BTV",
    local_code: "BTV",
    coordinates: "-73.15329742429999, 44.471900939899996",
  },
  {
    ident: "KBTY",
    type: "small_airport",
    name: "Beatty Airport",
    elevation_ft: "3170",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Beatty",
    gps_code: "KBTY",
    iata_code: "BTY",
    local_code: "BTY",
    coordinates: "-116.787002563, 36.8610992432",
  },
  {
    ident: "KBUB",
    type: "small_airport",
    name: "Cram Field",
    elevation_ft: "2182",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Burwell",
    gps_code: "KBUB",
    iata_code: "BUB",
    local_code: "BUB",
    coordinates: "-99.14969635010002, 41.776699066199996",
  },
  {
    ident: "KBUF",
    type: "large_airport",
    name: "Buffalo Niagara International Airport",
    elevation_ft: "728",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Buffalo",
    gps_code: "KBUF",
    iata_code: "BUF",
    local_code: "BUF",
    coordinates: "-78.73220062, 42.94049835",
  },
  {
    ident: "KBUM",
    type: "small_airport",
    name: "Butler Memorial Airport",
    elevation_ft: "892",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Butler",
    gps_code: "KBUM",
    iata_code: "BUM",
    local_code: "BUM",
    coordinates: "-94.3401031494, 38.2897987366",
  },
  {
    ident: "KBUR",
    type: "medium_airport",
    name: "Bob Hope Airport",
    elevation_ft: "778",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Burbank",
    gps_code: "KBUR",
    iata_code: "BUR",
    local_code: "BUR",
    coordinates: "-118.35900115966797, 34.20069885253906",
  },
  {
    ident: "KBVI",
    type: "medium_airport",
    name: "Beaver County Airport",
    elevation_ft: "1253",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Beaver Falls",
    gps_code: "KBVI",
    iata_code: "BFP",
    local_code: "BVI",
    coordinates: "-80.39140319820001, 40.7724990845",
  },
  {
    ident: "KBVO",
    type: "small_airport",
    name: "Bartlesville Municipal Airport",
    elevation_ft: "711",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Bartlesville",
    gps_code: "KBVO",
    iata_code: "BVO",
    local_code: "BVO",
    coordinates: "-96.01119995, 36.76250076",
  },
  {
    ident: "KBVS",
    type: "small_airport",
    name: "Skagit Regional Airport",
    elevation_ft: "144",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Burlington/Mount Vernon",
    gps_code: "KBVS",
    iata_code: "MVW",
    local_code: "BVS",
    coordinates: "-122.42099762, 48.4709014893",
  },
  {
    ident: "KBVX",
    type: "small_airport",
    name: "Batesville Regional Airport",
    elevation_ft: "465",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Batesville",
    gps_code: "KBVX",
    iata_code: "BVX",
    local_code: "BVX",
    coordinates: "-91.64730072, 35.7262001",
  },
  {
    ident: "KBVY",
    type: "medium_airport",
    name: "Beverly Municipal Airport",
    elevation_ft: "107",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Beverly",
    gps_code: "KBVY",
    iata_code: "BVY",
    local_code: "BVY",
    coordinates: "-70.91649627689999, 42.584201812699995",
  },
  {
    ident: "KBWC",
    type: "small_airport",
    name: "Brawley Municipal Airport",
    elevation_ft: "-128",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Brawley",
    gps_code: "KBWC",
    iata_code: "BWC",
    local_code: "BWC",
    coordinates: "-115.5169983, 32.99290085",
  },
  {
    ident: "KBWD",
    type: "small_airport",
    name: "Brownwood Regional Airport",
    elevation_ft: "1387",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Brownwood",
    gps_code: "KBWD",
    iata_code: "BWD",
    local_code: "BWD",
    coordinates: "-98.9564971924, 31.793600082399998",
  },
  {
    ident: "KBWG",
    type: "medium_airport",
    name: "Bowling Green Warren County Regional Airport",
    elevation_ft: "547",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Bowling Green",
    gps_code: "KBWG",
    iata_code: "BWG",
    local_code: "BWG",
    coordinates: "-86.41970062259999, 36.964500427199994",
  },
  {
    ident: "KBWI",
    type: "large_airport",
    name: "Baltimore/Washington International Thurgood Marshall Airport",
    elevation_ft: "146",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Baltimore",
    gps_code: "KBWI",
    iata_code: "BWI",
    local_code: "BWI",
    coordinates: "-76.668297, 39.1754",
  },
  {
    ident: "KBWP",
    type: "small_airport",
    name: "Harry Stern Airport",
    elevation_ft: "968",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Wahpeton",
    gps_code: "KBWP",
    iata_code: "WAH",
    local_code: "BWP",
    coordinates: "-96.6073989868, 46.2440986633",
  },
  {
    ident: "KBWW",
    type: "small_airport",
    name: "Bowman Regional Airport",
    elevation_ft: "2965",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Bowman",
    gps_code: "KBWW",
    iata_code: "BWM",
    local_code: "BWW",
    coordinates: "-103.30075, 46.1655193",
  },
  {
    ident: "KBXA",
    type: "small_airport",
    name: "George R Carr Memorial Air Field",
    elevation_ft: "119",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Bogalusa",
    gps_code: "KBXA",
    iata_code: "BXA",
    local_code: "BXA",
    coordinates: "-89.8649978638, 30.813699722299997",
  },
  {
    ident: "KBXK",
    type: "small_airport",
    name: "Buckeye Municipal Airport",
    elevation_ft: "1033",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Buckeye",
    gps_code: "KBXK",
    iata_code: "BXK",
    local_code: "BXK",
    coordinates: "-112.686317, 33.422397",
  },
  {
    ident: "KBYG",
    type: "small_airport",
    name: "Johnson County Airport",
    elevation_ft: "4968",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Buffalo",
    gps_code: "KBYG",
    iata_code: "BYG",
    local_code: "BYG",
    coordinates: "-106.722000122, 44.381099700899995",
  },
  {
    ident: "KBYH",
    type: "medium_airport",
    name: "Arkansas International Airport",
    elevation_ft: "254",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Blytheville",
    gps_code: "KBYH",
    iata_code: "BYH",
    local_code: "BYH",
    coordinates: "-89.94400024410001, 35.9642982483",
  },
  {
    ident: "KBYI",
    type: "medium_airport",
    name: "Burley Municipal Airport",
    elevation_ft: "4150",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Burley",
    gps_code: "KBYI",
    iata_code: "BYI",
    local_code: "BYI",
    coordinates: "-113.772003174, 42.542598724399994",
  },
  {
    ident: "KBYS",
    type: "medium_airport",
    name: "Bicycle Lake Army Air Field",
    elevation_ft: "2350",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Fort Irwin/Barstow",
    gps_code: "KBYS",
    iata_code: "BYS",
    local_code: "BYS",
    coordinates: "-116.629997253, 35.2804985046",
  },
  {
    ident: "KBYY",
    type: "small_airport",
    name: "Bay City Municipal Airport",
    elevation_ft: "45",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Bay City",
    gps_code: "KBYY",
    iata_code: "BBC",
    local_code: "BYY",
    coordinates: "-95.8635025024, 28.9733009338",
  },
  {
    ident: "KBZN",
    type: "medium_airport",
    name: "Gallatin Field",
    elevation_ft: "4473",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Bozeman",
    gps_code: "KBZN",
    iata_code: "BZN",
    local_code: "BZN",
    coordinates: "-111.1529999, 45.77750015",
  },
  {
    ident: "KC02",
    type: "small_airport",
    name: "Grand Geneva Resort Airport",
    elevation_ft: "835",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Lake Geneva",
    iata_code: "XES",
    local_code: "C02",
    coordinates: "-88.389603, 42.614899",
  },
  {
    ident: "KC65",
    type: "small_airport",
    name: "Plymouth Municipal Airport",
    elevation_ft: "800",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Plymouth",
    gps_code: "C65",
    iata_code: "PLY",
    local_code: "C65",
    coordinates: "-86.300498962402, 41.365100860596",
  },
  {
    ident: "KC80",
    type: "small_airport",
    name: "New Coalinga Municipal Airport",
    elevation_ft: "622",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Coalinga",
    iata_code: "CLG",
    local_code: "C80",
    coordinates: "-120.29399871826172, 36.16310119628906",
  },
  {
    ident: "KCAD",
    type: "small_airport",
    name: "Wexford County Airport",
    elevation_ft: "1307",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Cadillac",
    gps_code: "KCAD",
    iata_code: "CAD",
    local_code: "CAD",
    coordinates: "-85.4188995361, 44.2752990723",
  },
  {
    ident: "KCAE",
    type: "large_airport",
    name: "Columbia Metropolitan Airport",
    elevation_ft: "236",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Columbia",
    gps_code: "KCAE",
    iata_code: "CAE",
    local_code: "CAE",
    coordinates: "-81.11949920654297, 33.93880081176758",
  },
  {
    ident: "KCAG",
    type: "small_airport",
    name: "Craig Moffat Airport",
    elevation_ft: "6193",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Craig",
    gps_code: "KCAG",
    iata_code: "CIG",
    local_code: "CAG",
    coordinates: "-107.522003174, 40.4952011108",
  },
  {
    ident: "KCAK",
    type: "medium_airport",
    name: "Akron Canton Regional Airport",
    elevation_ft: "1228",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Akron",
    gps_code: "KCAK",
    iata_code: "CAK",
    local_code: "CAK",
    coordinates: "-81.44219970703125, 40.916099548339844",
  },
  {
    ident: "KCAO",
    type: "small_airport",
    name: "Clayton Municipal Airpark",
    elevation_ft: "4965",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Clayton",
    gps_code: "KCAO",
    iata_code: "CAO",
    local_code: "CAO",
    coordinates: "-103.166999817, 36.4462013245",
  },
  {
    ident: "KCAR",
    type: "medium_airport",
    name: "Caribou Municipal Airport",
    elevation_ft: "626",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Caribou",
    gps_code: "KCAR",
    iata_code: "CAR",
    local_code: "CAR",
    coordinates: "-68.0178985596, 46.871498107899995",
  },
  {
    ident: "KCBE",
    type: "small_airport",
    name: "Greater Cumberland Regional Airport",
    elevation_ft: "775",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Cumberland",
    gps_code: "KCBE",
    iata_code: "CBE",
    local_code: "CBE",
    coordinates: "-78.7609024048, 39.615398407",
  },
  {
    ident: "KCBF",
    type: "small_airport",
    name: "Council Bluffs Municipal Airport",
    elevation_ft: "1253",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Council Bluffs",
    gps_code: "KCBF",
    iata_code: "CBF",
    local_code: "CBF",
    coordinates: "-95.760597229, 41.2592010498",
  },
  {
    ident: "KCBK",
    type: "small_airport",
    name: "Shalz Field",
    elevation_ft: "3187",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Colby",
    gps_code: "KCBK",
    iata_code: "CBK",
    local_code: "CBK",
    coordinates: "-101.0469971, 39.42750168",
  },
  {
    ident: "KCBM",
    type: "large_airport",
    name: "Columbus Air Force Base",
    elevation_ft: "219",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Columbus",
    gps_code: "KCBM",
    iata_code: "CBM",
    local_code: "CBM",
    coordinates: "-88.44380187990001, 33.6437988281",
  },
  {
    ident: "KCC",
    type: "seaplane_base",
    name: "Coffman Cove Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Coffman Cove",
    gps_code: "KCC",
    iata_code: "KCC",
    local_code: "KCC",
    coordinates: "-132.841995239, 56.003200531",
  },
  {
    ident: "KCCB",
    type: "small_airport",
    name: "Cable Airport",
    elevation_ft: "1444",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Upland",
    gps_code: "KCCB",
    iata_code: "CCB",
    local_code: "CCB",
    coordinates: "-117.68800354, 34.1115989685",
  },
  {
    ident: "KCCR",
    type: "small_airport",
    name: "Buchanan Field",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Concord",
    gps_code: "KCCR",
    iata_code: "CCR",
    local_code: "CCR",
    coordinates: "-122.056999207, 37.9897003174",
  },
  {
    ident: "KCCY",
    type: "medium_airport",
    name: "Northeast Iowa Regional Airport",
    elevation_ft: "1125",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Charles City",
    gps_code: "KCCY",
    iata_code: "CCY",
    local_code: "CCY",
    coordinates: "-92.6108016968, 43.0726013184",
  },
  {
    ident: "KCDA",
    type: "small_airport",
    name: "Caledonia County Airport",
    elevation_ft: "1188",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VT",
    municipality: "Lyndonville",
    gps_code: "KCDA",
    iata_code: "LLX",
    local_code: "CDA",
    coordinates: "-72.0179977417, 44.5690994263",
  },
  {
    ident: "KCDC",
    type: "medium_airport",
    name: "Cedar City Regional Airport",
    elevation_ft: "5622",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Cedar City",
    gps_code: "KCDC",
    iata_code: "CDC",
    local_code: "CDC",
    coordinates: "-113.0989990234375, 37.70100021362305",
  },
  {
    ident: "KCDH",
    type: "small_airport",
    name: "Harrell Field",
    elevation_ft: "130",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Camden",
    gps_code: "KCDH",
    iata_code: "CDH",
    local_code: "CDH",
    coordinates: "-92.7633972168, 33.622798919699996",
  },
  {
    ident: "KCDN",
    type: "small_airport",
    name: "Woodward Field",
    elevation_ft: "302",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Camden",
    gps_code: "KCDN",
    iata_code: "CDN",
    local_code: "CDN",
    coordinates: "-80.56490325930001, 34.2835998535",
  },
  {
    ident: "KCDR",
    type: "medium_airport",
    name: "Chadron Municipal Airport",
    elevation_ft: "3297",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Chadron",
    gps_code: "KCDR",
    iata_code: "CDR",
    local_code: "CDR",
    coordinates: "-103.095001221, 42.837600708",
  },
  {
    ident: "KCDS",
    type: "medium_airport",
    name: "Childress Municipal Airport",
    elevation_ft: "1954",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Childress",
    gps_code: "KCDS",
    iata_code: "CDS",
    local_code: "CDS",
    coordinates: "-100.288002014, 34.4337997437",
  },
  {
    ident: "KCDW",
    type: "small_airport",
    name: "Essex County Airport",
    elevation_ft: "173",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Caldwell",
    gps_code: "KCDW",
    iata_code: "CDW",
    local_code: "CDW",
    coordinates: "-74.2814025879, 40.875198364300005",
  },
  {
    ident: "KCEA",
    type: "small_airport",
    name: "Cessna Aircraft Field",
    elevation_ft: "1378",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Wichita",
    gps_code: "KCEA",
    iata_code: "CEA",
    local_code: "CEA",
    coordinates: "-97.2506027222, 37.648601532",
  },
  {
    ident: "KCEC",
    type: "medium_airport",
    name: "Jack Mc Namara Field Airport",
    elevation_ft: "61",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Crescent City",
    gps_code: "KCEC",
    iata_code: "CEC",
    local_code: "CEC",
    coordinates: "-124.2369995, 41.78020096",
  },
  {
    ident: "KCEF",
    type: "medium_airport",
    name: "Westover ARB/Metropolitan Airport",
    elevation_ft: "241",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Springfield/Chicopee",
    gps_code: "KCEF",
    iata_code: "CEF",
    local_code: "CEF",
    coordinates: "-72.53479767, 42.19400024",
  },
  {
    ident: "KCEU",
    type: "small_airport",
    name: "Oconee County Regional Airport",
    elevation_ft: "892",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Clemson",
    gps_code: "KCEU",
    iata_code: "CEU",
    local_code: "CEU",
    coordinates: "-82.8864975, 34.6719017",
  },
  {
    ident: "KCEV",
    type: "small_airport",
    name: "Mettel Field",
    elevation_ft: "867",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Connersville",
    gps_code: "KCEV",
    iata_code: "CEV",
    local_code: "CEV",
    coordinates: "-85.129699707, 39.6985015869",
  },
  {
    ident: "KCEW",
    type: "medium_airport",
    name: "Bob Sikes Airport",
    elevation_ft: "213",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Crestview",
    gps_code: "KCEW",
    iata_code: "CEW",
    local_code: "CEW",
    coordinates: "-86.522102356, 30.778799057",
  },
  {
    ident: "KCEY",
    type: "small_airport",
    name: "Kyle Oakley Field",
    elevation_ft: "577",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Murray",
    gps_code: "KCEY",
    iata_code: "CEY",
    local_code: "CEY",
    coordinates: "-88.37280273, 36.66460037",
  },
  {
    ident: "KCEZ",
    type: "small_airport",
    name: "Cortez Municipal Airport",
    elevation_ft: "5918",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Cortez",
    gps_code: "KCEZ",
    iata_code: "CEZ",
    local_code: "CEZ",
    coordinates: "-108.627998352, 37.3030014038",
  },
  {
    ident: "KCFD",
    type: "small_airport",
    name: "Coulter Field",
    elevation_ft: "367",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Bryan",
    gps_code: "KCFD",
    iata_code: "CFD",
    local_code: "CFD",
    coordinates: "-96.3313980103, 30.715700149499998",
  },
  {
    ident: "KCFS",
    type: "small_airport",
    name: "Tuscola Area Airport",
    elevation_ft: "701",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Caro",
    gps_code: "KCFS",
    iata_code: "TZC",
    local_code: "CFS",
    coordinates: "-83.445503234863, 43.458801269531",
  },
  {
    ident: "KCFT",
    type: "small_airport",
    name: "Greenlee County Airport",
    elevation_ft: "3798",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Clifton/Morenci",
    gps_code: "KCFT",
    iata_code: "CFT",
    local_code: "CFT",
    coordinates: "-109.211397, 32.957039",
  },
  {
    ident: "KCFV",
    type: "small_airport",
    name: "Coffeyville Municipal Airport",
    elevation_ft: "754",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Coffeyville",
    gps_code: "KCFV",
    iata_code: "CFV",
    local_code: "CFV",
    coordinates: "-95.5718994141, 37.09400177",
  },
  {
    ident: "KCG",
    type: "closed",
    name: "Chignik Fisheries Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chignik",
    iata_code: "KCG",
    coordinates: "-158.589706, 56.317643",
  },
  {
    ident: "KCGE",
    type: "small_airport",
    name: "Cambridge Dorchester Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Cambridge",
    gps_code: "KCGE",
    iata_code: "CGE",
    local_code: "CGE",
    coordinates: "-76.03040314, 38.53929901",
  },
  {
    ident: "KCGF",
    type: "medium_airport",
    name: "Cuyahoga County Airport",
    elevation_ft: "879",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Cleveland",
    gps_code: "KCGF",
    iata_code: "CGF",
    local_code: "CGF",
    coordinates: "-81.4863967896, 41.5651016235",
  },
  {
    ident: "KCGI",
    type: "medium_airport",
    name: "Cape Girardeau Regional Airport",
    elevation_ft: "342",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Cape Girardeau",
    gps_code: "KCGI",
    iata_code: "CGI",
    local_code: "CGI",
    coordinates: "-89.57080078125, 37.22529983520508",
  },
  {
    ident: "KCGS",
    type: "small_airport",
    name: "College Park Airport",
    elevation_ft: "48",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "College Park",
    gps_code: "KCGS",
    iata_code: "CGS",
    local_code: "CGS",
    coordinates: "-76.9223022461, 38.9805984497",
  },
  {
    ident: "KCGX",
    type: "closed",
    name: "Chicago Meigs Airport",
    elevation_ft: "593",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Chicago",
    gps_code: "KCGX",
    iata_code: "CGX",
    coordinates: "-87.60790252685547, 41.85879898071289",
  },
  {
    ident: "KCGZ",
    type: "small_airport",
    name: "Casa Grande Municipal Airport",
    elevation_ft: "1464",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Casa Grande",
    gps_code: "KCGZ",
    iata_code: "CGZ",
    local_code: "CGZ",
    coordinates: "-111.766998, 32.954899",
  },
  {
    ident: "KCHA",
    type: "large_airport",
    name: "Lovell Field",
    elevation_ft: "683",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Chattanooga",
    gps_code: "KCHA",
    iata_code: "CHA",
    local_code: "CHA",
    coordinates: "-85.20379638671875, 35.035301208496094",
  },
  {
    ident: "KCHK",
    type: "small_airport",
    name: "Chickasha Municipal Airport",
    elevation_ft: "1152",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Chickasha",
    gps_code: "KCHK",
    iata_code: "CHK",
    local_code: "CHK",
    coordinates: "-97.96769714, 35.09740067",
  },
  {
    ident: "KCHO",
    type: "medium_airport",
    name: "Charlottesville Albemarle Airport",
    elevation_ft: "639",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Charlottesville",
    gps_code: "KCHO",
    iata_code: "CHO",
    local_code: "CHO",
    coordinates: "-78.4529037475586, 38.13859939575195",
  },
  {
    ident: "KCHS",
    type: "large_airport",
    name: "Charleston Air Force Base-International Airport",
    elevation_ft: "46",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Charleston",
    gps_code: "KCHS",
    iata_code: "CHS",
    local_code: "CHS",
    coordinates: "-80.04049683, 32.89860153",
  },
  {
    ident: "KCIC",
    type: "small_airport",
    name: "Chico Municipal Airport",
    elevation_ft: "240",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Chico",
    gps_code: "KCIC",
    iata_code: "CIC",
    local_code: "CIC",
    coordinates: "-121.8580017, 39.79539871",
  },
  {
    ident: "KCID",
    type: "large_airport",
    name: "The Eastern Iowa Airport",
    elevation_ft: "869",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Cedar Rapids",
    gps_code: "KCID",
    iata_code: "CID",
    local_code: "CID",
    coordinates: "-91.71080017089844, 41.884700775146484",
  },
  {
    ident: "KCIN",
    type: "small_airport",
    name: "Arthur N Neu Airport",
    elevation_ft: "1204",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Carroll",
    gps_code: "KCIN",
    iata_code: "CIN",
    local_code: "CIN",
    coordinates: "-94.78900146480001, 42.0461997986",
  },
  {
    ident: "KCIR",
    type: "small_airport",
    name: "Cairo Regional Airport",
    elevation_ft: "321",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Cairo",
    gps_code: "KCIR",
    iata_code: "CIR",
    local_code: "CIR",
    coordinates: "-89.2195968628, 37.0644989014",
  },
  {
    ident: "KCIU",
    type: "medium_airport",
    name: "Chippewa County International Airport",
    elevation_ft: "800",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Sault Ste Marie",
    gps_code: "KCIU",
    iata_code: "CIU",
    local_code: "CIU",
    coordinates: "-84.47239685058594, 46.25080108642578",
  },
  {
    ident: "KCKA",
    type: "small_airport",
    name: "Kegelman AF Aux Field",
    elevation_ft: "1202",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Cherokee",
    gps_code: "KCKA",
    iata_code: "CKA",
    local_code: "CKA",
    coordinates: "-98.1231002808, 36.7439002991",
  },
  {
    ident: "KCKB",
    type: "medium_airport",
    name: "North Central West Virginia Airport",
    elevation_ft: "1217",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Clarksburg",
    gps_code: "KCKB",
    iata_code: "CKB",
    local_code: "CKB",
    coordinates: "-80.2281036377, 39.2966003418",
  },
  {
    ident: "KCKC",
    type: "small_airport",
    name: "Grand Marais Cook County Airport",
    elevation_ft: "1799",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Grand Marais",
    gps_code: "KCKC",
    iata_code: "GRM",
    local_code: "CKC",
    coordinates: "-90.38289642330001, 47.8382987976",
  },
  {
    ident: "KCKM",
    type: "small_airport",
    name: "Fletcher Field",
    elevation_ft: "173",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Clarksdale",
    gps_code: "KCKM",
    iata_code: "CKM",
    local_code: "CKM",
    coordinates: "-90.512298584, 34.2997016907",
  },
  {
    ident: "KCKN",
    type: "small_airport",
    name: "Crookston Municipal Kirkwood Field",
    elevation_ft: "899",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Crookston",
    gps_code: "KCKN",
    iata_code: "CKN",
    local_code: "CKN",
    coordinates: "-96.62159729, 47.8417015076",
  },
  {
    ident: "KCKV",
    type: "small_airport",
    name: "Clarksville������Montgomery County Regional Airport",
    elevation_ft: "550",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Clarksville",
    gps_code: "KCKV",
    iata_code: "CKV",
    local_code: "CKV",
    coordinates: "-87.4150009155, 36.6218986511",
  },
  {
    ident: "KCL",
    type: "small_airport",
    name: "Chignik Lagoon Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chignik Flats",
    gps_code: "KCL",
    iata_code: "KCL",
    local_code: "KCL",
    coordinates: "-158.5359955, 56.31119919",
  },
  {
    ident: "KCLE",
    type: "large_airport",
    name: "Cleveland Hopkins International Airport",
    elevation_ft: "791",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Cleveland",
    gps_code: "KCLE",
    iata_code: "CLE",
    local_code: "CLE",
    coordinates: "-81.8498001099, 41.4117012024",
  },
  {
    ident: "KCLI",
    type: "small_airport",
    name: "Clintonville Municipal Airport",
    elevation_ft: "822",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Clintonville",
    gps_code: "KCLI",
    iata_code: "CLI",
    local_code: "CLI",
    coordinates: "-88.731300354, 44.613800048799995",
  },
  {
    ident: "KCLK",
    type: "small_airport",
    name: "Clinton Regional Airport",
    elevation_ft: "1616",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Clinton",
    gps_code: "KCLK",
    iata_code: "CLK",
    local_code: "CLK",
    coordinates: "-98.93270111, 35.53829956",
  },
  {
    ident: "KCLL",
    type: "medium_airport",
    name: "Easterwood Field",
    elevation_ft: "320",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "College Station",
    gps_code: "KCLL",
    iata_code: "CLL",
    local_code: "CLL",
    coordinates: "-96.36380005, 30.58860016",
  },
  {
    ident: "KCLM",
    type: "medium_airport",
    name: "William R Fairchild International Airport",
    elevation_ft: "291",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Port Angeles",
    gps_code: "KCLM",
    iata_code: "CLM",
    local_code: "CLM",
    coordinates: "-123.5, 48.120201110839844",
  },
  {
    ident: "KCLR",
    type: "small_airport",
    name: "Cliff Hatfield Memorial Airport",
    elevation_ft: "-182",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Calipatria",
    gps_code: "KCLR",
    iata_code: "CLR",
    local_code: "CLR",
    coordinates: "-115.521003723, 33.131500244099996",
  },
  {
    ident: "KCLS",
    type: "small_airport",
    name: "Chehalis Centralia Airport",
    elevation_ft: "176",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Chehalis",
    gps_code: "KCLS",
    iata_code: "CLS",
    local_code: "CLS",
    coordinates: "-122.983001709, 46.676998138399995",
  },
  {
    ident: "KCLT",
    type: "large_airport",
    name: "Charlotte Douglas International Airport",
    elevation_ft: "748",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Charlotte",
    gps_code: "KCLT",
    iata_code: "CLT",
    local_code: "CLT",
    coordinates: "-80.94309997558594, 35.2140007019043",
  },
  {
    ident: "KCLW",
    type: "small_airport",
    name: "Clearwater Air Park",
    elevation_ft: "71",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Clearwater",
    gps_code: "KCLW",
    iata_code: "CLW",
    local_code: "CLW",
    coordinates: "-82.7586975098, 27.9766998291",
  },
  {
    ident: "KCMH",
    type: "large_airport",
    name: "John Glenn Columbus International Airport",
    elevation_ft: "815",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Columbus",
    gps_code: "KCMH",
    iata_code: "CMH",
    local_code: "CMH",
    coordinates: "-82.891899, 39.998001",
  },
  {
    ident: "KCMI",
    type: "medium_airport",
    name: "University of Illinois Willard Airport",
    elevation_ft: "755",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Champaign/Urbana",
    gps_code: "KCMI",
    iata_code: "CMI",
    local_code: "CMI",
    coordinates: "-88.27809906, 40.03919983",
  },
  {
    ident: "KCMX",
    type: "medium_airport",
    name: "Houghton County Memorial Airport",
    elevation_ft: "1095",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Hancock",
    gps_code: "KCMX",
    iata_code: "CMX",
    local_code: "CMX",
    coordinates: "-88.48909759521484, 47.168399810791016",
  },
  {
    ident: "KCMY",
    type: "small_airport",
    name: "Sparta Fort Mc Coy Airport",
    elevation_ft: "837",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Sparta",
    gps_code: "KCMY",
    iata_code: "CMY",
    local_code: "CMY",
    coordinates: "-90.7379, 43.958302",
  },
  {
    ident: "KCN",
    type: "seaplane_base",
    name: "Chernofski Harbor Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chernofski Harbor",
    gps_code: "KCN",
    iata_code: "KCN",
    local_code: "KCN",
    coordinates: "-167.52027154, 53.4028993416",
  },
  {
    ident: "KCNH",
    type: "small_airport",
    name: "Claremont Municipal Airport",
    elevation_ft: "545",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Claremont",
    gps_code: "KCNH",
    iata_code: "CNH",
    local_code: "CNH",
    coordinates: "-72.36869812009999, 43.3703994751",
  },
  {
    ident: "KCNK",
    type: "small_airport",
    name: "Blosser Municipal Airport",
    elevation_ft: "1486",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Concordia",
    gps_code: "KCNK",
    iata_code: "CNK",
    local_code: "CNK",
    coordinates: "-97.6522979736, 39.549301147499996",
  },
  {
    ident: "KCNM",
    type: "medium_airport",
    name: "Cavern City Air Terminal",
    elevation_ft: "3295",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Carlsbad",
    gps_code: "KCNM",
    iata_code: "CNM",
    local_code: "CNM",
    coordinates: "-104.26300048828125, 32.337501525878906",
  },
  {
    ident: "KCNO",
    type: "small_airport",
    name: "Chino Airport",
    elevation_ft: "650",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Chino",
    gps_code: "KCNO",
    iata_code: "CNO",
    local_code: "CNO",
    coordinates: "-117.637001, 33.97470093",
  },
  {
    ident: "KCNU",
    type: "medium_airport",
    name: "Chanute Martin Johnson Airport",
    elevation_ft: "1002",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Chanute",
    gps_code: "KCNU",
    iata_code: "CNU",
    local_code: "CNU",
    coordinates: "-95.4850997925, 37.668800354",
  },
  {
    ident: "KCNW",
    type: "small_airport",
    name: "TSTC Waco Airport",
    elevation_ft: "470",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Waco",
    gps_code: "KCNW",
    iata_code: "CNW",
    local_code: "CNW",
    coordinates: "-97.0740966797, 31.637800216699997",
  },
  {
    ident: "KCNY",
    type: "small_airport",
    name: "Canyonlands Field",
    elevation_ft: "4557",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Moab",
    gps_code: "KCNY",
    iata_code: "CNY",
    local_code: "CNY",
    coordinates: "-109.7549973, 38.75500107",
  },
  {
    ident: "KCOD",
    type: "medium_airport",
    name: "Yellowstone Regional Airport",
    elevation_ft: "5102",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Cody",
    gps_code: "KCOD",
    iata_code: "COD",
    local_code: "COD",
    coordinates: "-109.024002075, 44.520198822",
  },
  {
    ident: "KCOE",
    type: "medium_airport",
    name: "Coeur D'Alene - Pappy Boyington Field",
    elevation_ft: "2320",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Coeur d'Alene",
    gps_code: "KCOE",
    iata_code: "COE",
    local_code: "COE",
    coordinates: "-116.8199997, 47.77429962",
  },
  {
    ident: "KCOF",
    type: "medium_airport",
    name: "Patrick Air Force Base",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Cocoa Beach",
    gps_code: "KCOF",
    iata_code: "COF",
    local_code: "COF",
    coordinates: "-80.6100997925, 28.2348995209",
  },
  {
    ident: "KCOI",
    type: "small_airport",
    name: "Merritt Island Airport",
    elevation_ft: "6",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Merritt Island",
    gps_code: "KCOI",
    iata_code: "COI",
    local_code: "COI",
    coordinates: "-80.6855010986, 28.341600418099997",
  },
  {
    ident: "KCOM",
    type: "small_airport",
    name: "Coleman Municipal Airport",
    elevation_ft: "1697",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Coleman",
    gps_code: "KCOM",
    iata_code: "COM",
    local_code: "COM",
    coordinates: "-99.4036026001, 31.841100692699996",
  },
  {
    ident: "KCON",
    type: "medium_airport",
    name: "Concord Municipal Airport",
    elevation_ft: "342",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Concord",
    gps_code: "KCON",
    iata_code: "CON",
    local_code: "CON",
    coordinates: "-71.50229645, 43.20270157",
  },
  {
    ident: "KCOS",
    type: "large_airport",
    name: "City of Colorado Springs Municipal Airport",
    elevation_ft: "6187",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Colorado Springs",
    gps_code: "KCOS",
    iata_code: "COS",
    local_code: "COS",
    coordinates: "-104.700996, 38.805801",
  },
  {
    ident: "KCOT",
    type: "small_airport",
    name: "Cotulla-La Salle County Airport",
    elevation_ft: "474",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Cotulla",
    gps_code: "KCOT",
    iata_code: "COT",
    local_code: "COT",
    coordinates: "-99.22029877, 28.45669937",
  },
  {
    ident: "KCOU",
    type: "medium_airport",
    name: "Columbia Regional Airport",
    elevation_ft: "889",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Columbia",
    gps_code: "KCOU",
    iata_code: "COU",
    local_code: "COU",
    coordinates: "-92.219597, 38.8181",
  },
  {
    ident: "KCPM",
    type: "small_airport",
    name: "Compton Woodley Airport",
    elevation_ft: "97",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Compton",
    gps_code: "KCPM",
    iata_code: "CPM",
    local_code: "CPM",
    coordinates: "-118.244003296, 33.8899993896",
  },
  {
    ident: "KCPR",
    type: "medium_airport",
    name: "Casper-Natrona County International Airport",
    elevation_ft: "5350",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Casper",
    gps_code: "KCPR",
    iata_code: "CPR",
    local_code: "CPR",
    coordinates: "-106.463997, 42.908001",
  },
  {
    ident: "KCPS",
    type: "small_airport",
    name: "St Louis Downtown Airport",
    elevation_ft: "413",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Cahokia/St Louis",
    gps_code: "KCPS",
    iata_code: "CPS",
    local_code: "CPS",
    coordinates: "-90.1561965942, 38.570701599100005",
  },
  {
    ident: "KCQW",
    type: "small_airport",
    name: "Cheraw Municipal Airport/Lynch Bellinger Field",
    elevation_ft: "239",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Cheraw",
    gps_code: "KCQW",
    iata_code: "HCW",
    local_code: "CQW",
    coordinates: "-79.95700073, 34.71289825",
  },
  {
    ident: "KCR",
    type: "small_airport",
    name: "Colorado Creek Airport",
    elevation_ft: "860",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Colorado Creek",
    gps_code: "KCR",
    iata_code: "KCR",
    local_code: "KCR",
    coordinates: "-155.988998413, 63.5676994324",
  },
  {
    ident: "KCRE",
    type: "medium_airport",
    name: "Grand Strand Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "North Myrtle Beach",
    gps_code: "KCRE",
    iata_code: "CRE",
    local_code: "CRE",
    coordinates: "-78.72389984130001, 33.8116989136",
  },
  {
    ident: "KCRG",
    type: "medium_airport",
    name: "Jacksonville Executive at Craig Airport",
    elevation_ft: "41",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Jacksonville",
    gps_code: "KCRG",
    iata_code: "CRG",
    local_code: "CRG",
    coordinates: "-81.51439666750001, 30.3362998962",
  },
  {
    ident: "KCRO",
    type: "small_airport",
    name: "Corcoran Airport",
    elevation_ft: "197",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Corcoran",
    iata_code: "CRO",
    local_code: "43CN",
    coordinates: "-119.595001, 36.102502",
  },
  {
    ident: "KCRP",
    type: "large_airport",
    name: "Corpus Christi International Airport",
    elevation_ft: "44",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Corpus Christi",
    gps_code: "KCRP",
    iata_code: "CRP",
    local_code: "CRP",
    coordinates: "-97.5011978149414, 27.77039909362793",
  },
  {
    ident: "KCRQ",
    type: "medium_airport",
    name: "Mc Clellan-Palomar Airport",
    elevation_ft: "331",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Carlsbad",
    gps_code: "KCRQ",
    iata_code: "CLD",
    local_code: "CRQ",
    coordinates: "-117.2799988, 33.12829971",
  },
  {
    ident: "KCRS",
    type: "small_airport",
    name: "C David Campbell Field Corsicana Municipal Airport",
    elevation_ft: "449",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Corsicana",
    gps_code: "KCRS",
    iata_code: "CRS",
    local_code: "CRS",
    coordinates: "-96.4005966187, 32.0280990601",
  },
  {
    ident: "KCRT",
    type: "small_airport",
    name: "Z M Jack Stell Field",
    elevation_ft: "184",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Crossett",
    gps_code: "KCRT",
    iata_code: "CRT",
    local_code: "CRT",
    coordinates: "-91.8802032471, 33.1782989502",
  },
  {
    ident: "KCRW",
    type: "large_airport",
    name: "Yeager Airport",
    elevation_ft: "981",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Charleston",
    gps_code: "KCRW",
    iata_code: "CRW",
    local_code: "CRW",
    coordinates: "-81.59320068359375, 38.37310028076172",
  },
  {
    ident: "KCRX",
    type: "small_airport",
    name: "Roscoe Turner Airport",
    elevation_ft: "425",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Corinth",
    gps_code: "KCRX",
    iata_code: "CRX",
    local_code: "CRX",
    coordinates: "-88.6035003662, 34.9150009155",
  },
  {
    ident: "KCSG",
    type: "medium_airport",
    name: "Columbus Metropolitan Airport",
    elevation_ft: "397",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Columbus",
    gps_code: "KCSG",
    iata_code: "CSG",
    local_code: "CSG",
    coordinates: "-84.93890380859375, 32.516300201416016",
  },
  {
    ident: "KCSM",
    type: "small_airport",
    name: "Clinton Sherman Airport",
    elevation_ft: "1922",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Clinton",
    gps_code: "KCSM",
    iata_code: "CSM",
    local_code: "CSM",
    coordinates: "-99.20050048830001, 35.3398017883",
  },
  {
    ident: "KCSQ",
    type: "small_airport",
    name: "Creston Municipal Airport",
    elevation_ft: "1300",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Creston",
    gps_code: "KCSQ",
    iata_code: "CSQ",
    local_code: "CSQ",
    coordinates: "-94.36329650879999, 41.021400451699996",
  },
  {
    ident: "KCSV",
    type: "medium_airport",
    name: "Crossville Memorial Whitson Field",
    elevation_ft: "1881",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Crossville",
    gps_code: "KCSV",
    iata_code: "CSV",
    local_code: "CSV",
    coordinates: "-85.08499908450001, 35.9513015747",
  },
  {
    ident: "KCTB",
    type: "medium_airport",
    name: "Cut Bank International Airport",
    elevation_ft: "3854",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Cut Bank",
    gps_code: "KCTB",
    iata_code: "CTB",
    local_code: "CTB",
    coordinates: "-112.375999451, 48.6083984375",
  },
  {
    ident: "KCTY",
    type: "small_airport",
    name: "Cross City Airport",
    elevation_ft: "42",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Cross City",
    gps_code: "KCTY",
    iata_code: "CTY",
    local_code: "CTY",
    coordinates: "-83.10479736330001, 29.6354999542",
  },
  {
    ident: "KCTZ",
    type: "small_airport",
    name: "Sampson County Airport",
    elevation_ft: "148",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Clinton",
    gps_code: "KCTZ",
    iata_code: "CTZ",
    local_code: "CTZ",
    coordinates: "-78.3646011353, 34.9756011963",
  },
  {
    ident: "KCUB",
    type: "medium_airport",
    name: "Jim Hamilton L.B. Owens Airport",
    elevation_ft: "193",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Columbia",
    gps_code: "KCUB",
    iata_code: "CUB",
    local_code: "CUB",
    coordinates: "-80.9952011108, 33.970500946",
  },
  {
    ident: "KCUH",
    type: "small_airport",
    name: "Cushing Municipal Airport",
    elevation_ft: "916",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Cushing",
    gps_code: "KCUH",
    iata_code: "CUH",
    local_code: "CUH",
    coordinates: "-96.7731018066, 35.9499015808",
  },
  {
    ident: "KCVG",
    type: "large_airport",
    name: "Cincinnati Northern Kentucky International Airport",
    elevation_ft: "896",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Cincinnati / Covington",
    gps_code: "KCVG",
    iata_code: "CVG",
    local_code: "CVG",
    coordinates: "-84.667801, 39.048801",
  },
  {
    ident: "KCVK",
    type: "small_airport",
    name: "Sharp County Regional Airport",
    elevation_ft: "716",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Ash Flat",
    gps_code: "KCVK",
    iata_code: "CKK",
    local_code: "CVK",
    coordinates: "-91.56259918, 36.26490021",
  },
  {
    ident: "KCVN",
    type: "small_airport",
    name: "Clovis Municipal Airport",
    elevation_ft: "4216",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Clovis",
    gps_code: "KCVN",
    iata_code: "CVN",
    local_code: "CVN",
    coordinates: "-103.07900238, 34.4250984192",
  },
  {
    ident: "KCVO",
    type: "medium_airport",
    name: "Corvallis Municipal Airport",
    elevation_ft: "250",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Corvallis",
    gps_code: "KCVO",
    iata_code: "CVO",
    local_code: "CVO",
    coordinates: "-123.2900009, 44.49720001",
  },
  {
    ident: "KCVS",
    type: "large_airport",
    name: "Cannon Air Force Base",
    elevation_ft: "4295",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Clovis",
    gps_code: "KCVS",
    iata_code: "CVS",
    local_code: "CVS",
    coordinates: "-103.321998596, 34.3828010559",
  },
  {
    ident: "KCWA",
    type: "medium_airport",
    name: "Central Wisconsin Airport",
    elevation_ft: "1277",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Mosinee",
    gps_code: "KCWA",
    iata_code: "CWA",
    local_code: "CWA",
    coordinates: "-89.6668014526, 44.7775993347",
  },
  {
    ident: "KCWC",
    type: "small_airport",
    name: "Kickapoo Downtown Airport",
    elevation_ft: "1003",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Wichita Falls",
    gps_code: "KCWC",
    iata_code: "KIP",
    local_code: "CWC",
    coordinates: "-98.49040222, 33.85779953",
  },
  {
    ident: "KCWF",
    type: "small_airport",
    name: "Chennault International Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Lake Charles",
    gps_code: "KCWF",
    iata_code: "CWF",
    local_code: "CWF",
    coordinates: "-93.14320373540001, 30.2105998993",
  },
  {
    ident: "KCWI",
    type: "small_airport",
    name: "Clinton Municipal Airport",
    elevation_ft: "708",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Clinton",
    gps_code: "KCWI",
    iata_code: "CWI",
    local_code: "CWI",
    coordinates: "-90.3291015625, 41.8311004639",
  },
  {
    ident: "KCXL",
    type: "small_airport",
    name: "Calexico International Airport",
    elevation_ft: "4",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Calexico",
    gps_code: "KCXL",
    iata_code: "CXL",
    local_code: "CXL",
    coordinates: "-115.513000488, 32.6694984436",
  },
  {
    ident: "KCXO",
    type: "medium_airport",
    name: "Conroe-North Houston Regional Airport",
    elevation_ft: "245",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Houston",
    gps_code: "KCXO",
    iata_code: "CXO",
    local_code: "CXO",
    coordinates: "-95.414497, 30.351801",
  },
  {
    ident: "KCXP",
    type: "small_airport",
    name: "Carson Airport",
    elevation_ft: "4697",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Carson City",
    gps_code: "KCXP",
    iata_code: "CSN",
    local_code: "CXP",
    coordinates: "-119.73400116, 39.192199707",
  },
  {
    ident: "KCXY",
    type: "small_airport",
    name: "Capital City Airport",
    elevation_ft: "347",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Harrisburg",
    gps_code: "KCXY",
    iata_code: "HAR",
    local_code: "CXY",
    coordinates: "-76.85150146480001, 40.2170982361",
  },
  {
    ident: "KCYS",
    type: "medium_airport",
    name: "Cheyenne Regional Jerry Olson Field",
    elevation_ft: "6159",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Cheyenne",
    gps_code: "KCYS",
    iata_code: "CYS",
    local_code: "CYS",
    coordinates: "-104.8119965, 41.15570068",
  },
  {
    ident: "KCZ",
    type: "medium_airport",
    name: "Kochi Airport (������������������������)",
    elevation_ft: "29",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-39",
    municipality: "Nankoku",
    gps_code: "RJOK",
    iata_code: "KCZ",
    coordinates: "133.668938, 33.546259",
  },
  {
    ident: "KCZT",
    type: "small_airport",
    name: "Dimmit County Airport",
    elevation_ft: "599",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Carrizo Springs",
    gps_code: "KCZT",
    iata_code: "CZT",
    local_code: "CZT",
    coordinates: "-99.823600769, 28.522199630699998",
  },
  {
    ident: "KD60",
    type: "small_airport",
    name: "Tioga Municipal Airport",
    elevation_ft: "2271",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Tioga",
    gps_code: "D60",
    iata_code: "VEX",
    local_code: "D60",
    coordinates: "-102.8980026, 48.38050079",
  },
  {
    ident: "KDAA",
    type: "medium_airport",
    name: "Davison Army Air Field",
    elevation_ft: "73",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Fort Belvoir",
    gps_code: "KDAA",
    iata_code: "DAA",
    local_code: "DAA",
    coordinates: "-77.1809997559, 38.715000152600005",
  },
  {
    ident: "KDAB",
    type: "large_airport",
    name: "Daytona Beach International Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Daytona Beach",
    gps_code: "KDAB",
    iata_code: "DAB",
    local_code: "DAB",
    coordinates: "-81.058098, 29.179899",
  },
  {
    ident: "KDAG",
    type: "medium_airport",
    name: "Barstow Daggett Airport",
    elevation_ft: "1930",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Daggett",
    gps_code: "KDAG",
    iata_code: "DAG",
    local_code: "DAG",
    coordinates: "-116.7870026, 34.85369873",
  },
  {
    ident: "KDAL",
    type: "large_airport",
    name: "Dallas Love Field",
    elevation_ft: "487",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Dallas",
    gps_code: "KDAL",
    iata_code: "DAL",
    local_code: "DAL",
    coordinates: "-96.851799, 32.847099",
  },
  {
    ident: "KDAN",
    type: "medium_airport",
    name: "Danville Regional Airport",
    elevation_ft: "571",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Danville",
    gps_code: "KDAN",
    iata_code: "DAN",
    local_code: "DAN",
    coordinates: "-79.33609771728516, 36.572898864746094",
  },
  {
    ident: "KDAY",
    type: "large_airport",
    name: "James M Cox Dayton International Airport",
    elevation_ft: "1009",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Dayton",
    gps_code: "KDAY",
    iata_code: "DAY",
    local_code: "DAY",
    coordinates: "-84.21939849853516, 39.902400970458984",
  },
  {
    ident: "KDBN",
    type: "small_airport",
    name: "W H 'Bud' Barron Airport",
    elevation_ft: "309",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Dublin",
    gps_code: "KDBN",
    iata_code: "DBN",
    local_code: "DBN",
    coordinates: "-82.98529816, 32.56439972",
  },
  {
    ident: "KDBQ",
    type: "large_airport",
    name: "Dubuque Regional Airport",
    elevation_ft: "1077",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Dubuque",
    gps_code: "KDBQ",
    iata_code: "DBQ",
    local_code: "DBQ",
    coordinates: "-90.70950317, 42.40200043",
  },
  {
    ident: "KDCA",
    type: "large_airport",
    name: "Ronald Reagan Washington National Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-DC",
    municipality: "Washington",
    gps_code: "KDCA",
    iata_code: "DCA",
    local_code: "DCA",
    coordinates: "-77.037697, 38.8521",
  },
  {
    ident: "KDCU",
    type: "small_airport",
    name: "Pryor Field Regional Airport",
    elevation_ft: "592",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Decatur",
    gps_code: "KDCU",
    iata_code: "DCU",
    local_code: "DCU",
    coordinates: "-86.94539642330001, 34.652698516799994",
  },
  {
    ident: "KDDC",
    type: "medium_airport",
    name: "Dodge City Regional Airport",
    elevation_ft: "2594",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Dodge City",
    gps_code: "KDDC",
    iata_code: "DDC",
    local_code: "DDC",
    coordinates: "-99.9655990600586, 37.76340103149414",
  },
  {
    ident: "KDEC",
    type: "medium_airport",
    name: "Decatur Airport",
    elevation_ft: "682",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Decatur",
    gps_code: "KDEC",
    iata_code: "DEC",
    local_code: "DEC",
    coordinates: "-88.8656997680664, 39.834598541259766",
  },
  {
    ident: "KDEH",
    type: "small_airport",
    name: "Decorah Municipal Airport",
    elevation_ft: "1158",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Decorah",
    gps_code: "KDEH",
    iata_code: "DEH",
    local_code: "DEH",
    coordinates: "-91.73940277, 43.27550125",
  },
  {
    ident: "KDEN",
    type: "large_airport",
    name: "Denver International Airport",
    elevation_ft: "5431",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Denver",
    gps_code: "KDEN",
    iata_code: "DEN",
    local_code: "DEN",
    coordinates: "-104.672996521, 39.861698150635",
  },
  {
    ident: "KDET",
    type: "medium_airport",
    name: "Coleman A. Young Municipal Airport",
    elevation_ft: "626",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Detroit",
    gps_code: "KDET",
    iata_code: "DET",
    local_code: "DET",
    coordinates: "-83.00990295, 42.40919876",
  },
  {
    ident: "KDFI",
    type: "small_airport",
    name: "Defiance Memorial Airport",
    elevation_ft: "707",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Defiance",
    gps_code: "KDFI",
    iata_code: "DFI",
    local_code: "DFI",
    coordinates: "-84.4288024902, 41.3375015259",
  },
  {
    ident: "KDFW",
    type: "large_airport",
    name: "Dallas Fort Worth International Airport",
    elevation_ft: "607",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Dallas-Fort Worth",
    gps_code: "KDFW",
    iata_code: "DFW",
    local_code: "DFW",
    coordinates: "-97.038002, 32.896801",
  },
  {
    ident: "KDGL",
    type: "small_airport",
    name: "Douglas Municipal Airport",
    elevation_ft: "4173",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Douglas",
    gps_code: "KDGL",
    iata_code: "DGL",
    local_code: "DGL",
    coordinates: "-109.505996704, 31.3425998688",
  },
  {
    ident: "KDGW",
    type: "small_airport",
    name: "Converse County Airport",
    elevation_ft: "4933",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Douglas",
    gps_code: "KDGW",
    iata_code: "DGW",
    local_code: "DGW",
    coordinates: "-105.3860016, 42.79719925",
  },
  {
    ident: "KDHN",
    type: "medium_airport",
    name: "Dothan Regional Airport",
    elevation_ft: "401",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Dothan",
    gps_code: "KDHN",
    iata_code: "DHN",
    local_code: "DHN",
    coordinates: "-85.44960021972656, 31.321300506591797",
  },
  {
    ident: "KDHT",
    type: "medium_airport",
    name: "Dalhart Municipal Airport",
    elevation_ft: "3991",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Dalhart",
    gps_code: "KDHT",
    iata_code: "DHT",
    local_code: "DHT",
    coordinates: "-102.54699707, 36.0225982666",
  },
  {
    ident: "KDIK",
    type: "medium_airport",
    name: "Dickinson Theodore Roosevelt Regional Airport",
    elevation_ft: "2592",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Dickinson",
    gps_code: "KDIK",
    iata_code: "DIK",
    local_code: "DIK",
    coordinates: "-102.802001953, 46.7974014282",
  },
  {
    ident: "KDKK",
    type: "small_airport",
    name: "Chautauqua County-Dunkirk Airport",
    elevation_ft: "693",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Dunkirk",
    gps_code: "KDKK",
    iata_code: "DKK",
    local_code: "DKK",
    coordinates: "-79.27200317, 42.49330139",
  },
  {
    ident: "KDLC",
    type: "small_airport",
    name: "Dillon County Airport",
    elevation_ft: "133",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Dillon",
    gps_code: "KDLC",
    iata_code: "DLL",
    local_code: "DLC",
    coordinates: "-79.368598938, 34.4491004944",
  },
  {
    ident: "KDLF",
    type: "large_airport",
    name: "DLF Airport",
    elevation_ft: "1082",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Del Rio",
    gps_code: "KDLF",
    iata_code: "DLF",
    local_code: "DLF",
    coordinates: "-100.778002, 29.359501",
  },
  {
    ident: "KDLH",
    type: "large_airport",
    name: "Duluth International Airport",
    elevation_ft: "1428",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Duluth",
    gps_code: "KDLH",
    iata_code: "DLH",
    local_code: "DLH",
    coordinates: "-92.19360351559999, 46.8420982361",
  },
  {
    ident: "KDLN",
    type: "small_airport",
    name: "Dillon Airport",
    elevation_ft: "5241",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Dillon",
    gps_code: "KDLN",
    iata_code: "DLN",
    local_code: "DLN",
    coordinates: "-112.553001404, 45.255401611299995",
  },
  {
    ident: "KDLS",
    type: "medium_airport",
    name: "Columbia Gorge Regional the Dalles Municipal Airport",
    elevation_ft: "247",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "The Dalles",
    gps_code: "KDLS",
    iata_code: "DLS",
    local_code: "DLS",
    coordinates: "-121.166999817, 45.6184997559",
  },
  {
    ident: "KDMA",
    type: "medium_airport",
    name: "Davis Monthan Air Force Base",
    elevation_ft: "2704",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Tucson",
    gps_code: "KDMA",
    iata_code: "DMA",
    local_code: "DMA",
    coordinates: "-110.883003235, 32.1665000916",
  },
  {
    ident: "KDMN",
    type: "medium_airport",
    name: "Deming Municipal Airport",
    elevation_ft: "4314",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Deming",
    gps_code: "KDMN",
    iata_code: "DMN",
    local_code: "DMN",
    coordinates: "-107.721000671, 32.262298584",
  },
  {
    ident: "KDMO",
    type: "small_airport",
    name: "Sedalia Memorial Airport",
    elevation_ft: "909",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Sedalia",
    gps_code: "KDMO",
    iata_code: "DMO",
    local_code: "DMO",
    coordinates: "-93.17590332030001, 38.70740127559999",
  },
  {
    ident: "KDNL",
    type: "medium_airport",
    name: "Daniel Field",
    elevation_ft: "423",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Augusta",
    gps_code: "KDNL",
    iata_code: "DNL",
    local_code: "DNL",
    coordinates: "-82.0393981934, 33.4664993286",
  },
  {
    ident: "KDNN",
    type: "small_airport",
    name: "Dalton Municipal Airport",
    elevation_ft: "709",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Dalton",
    gps_code: "KDNN",
    iata_code: "DNN",
    local_code: "DNN",
    coordinates: "-84.87020111, 34.72290039",
  },
  {
    ident: "KDNS",
    type: "small_airport",
    name: "Denison Municipal Airport",
    elevation_ft: "1274",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Denison",
    gps_code: "KDNS",
    iata_code: "DNS",
    local_code: "DNS",
    coordinates: "-95.38069916, 41.9864006",
  },
  {
    ident: "KDNV",
    type: "small_airport",
    name: "Vermilion Regional Airport",
    elevation_ft: "697",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Danville",
    gps_code: "KDNV",
    iata_code: "DNV",
    local_code: "DNV",
    coordinates: "-87.59590149, 40.19919968",
  },
  {
    ident: "KDOV",
    type: "large_airport",
    name: "Dover Air Force Base",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-DE",
    municipality: "Dover",
    gps_code: "KDOV",
    iata_code: "DOV",
    local_code: "DOV",
    coordinates: "-75.46600342, 39.12950134",
  },
  {
    ident: "KDP",
    type: "small_airport",
    name: "Kandep Airport",
    elevation_ft: "7710",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EPW",
    municipality: "Kandep",
    gps_code: "AYNN",
    iata_code: "KDP",
    local_code: "KDP",
    coordinates: "143.507222222, -5.84061111111",
  },
  {
    ident: "KDPA",
    type: "medium_airport",
    name: "Dupage Airport",
    elevation_ft: "759",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Chicago/West Chicago",
    gps_code: "KDPA",
    iata_code: "DPA",
    local_code: "DPA",
    coordinates: "-88.24859619, 41.90779877",
  },
  {
    ident: "KDPG",
    type: "small_airport",
    name: "Michael AAF (Dugway Proving Ground) Airport",
    elevation_ft: "4349",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Dugway Proving Ground",
    gps_code: "KDPG",
    iata_code: "DPG",
    local_code: "DPG",
    coordinates: "-112.9369965, 40.19940186",
  },
  {
    ident: "KDQ",
    type: "small_airport",
    name: "Kamberatoro Airport",
    elevation_ft: "1350",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Kamberatoro Mission",
    gps_code: "AYTO",
    iata_code: "KDQ",
    local_code: "KTO",
    coordinates: "141.051667, -3.600556",
  },
  {
    ident: "KDRA",
    type: "medium_airport",
    name: "Desert Rock Airport",
    elevation_ft: "3314",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Mercury",
    gps_code: "KDRA",
    iata_code: "DRA",
    local_code: "NV65",
    coordinates: "-116.032997, 36.6194",
  },
  {
    ident: "KDRI",
    type: "medium_airport",
    name: "Beauregard Regional Airport",
    elevation_ft: "202",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "De Ridder",
    gps_code: "KDRI",
    iata_code: "DRI",
    local_code: "DRI",
    coordinates: "-93.33989715579999, 30.8316993713",
  },
  {
    ident: "KDRM",
    type: "small_airport",
    name: "Drummond Island Airport",
    elevation_ft: "668",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Drummond Island",
    gps_code: "KDRM",
    iata_code: "DRE",
    local_code: "DRM",
    coordinates: "-83.74389648440001, 46.0093002319",
  },
  {
    ident: "KDRO",
    type: "small_airport",
    name: "Durango La Plata County Airport",
    elevation_ft: "6685",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Durango",
    gps_code: "KDRO",
    iata_code: "DRO",
    local_code: "DRO",
    coordinates: "-107.753997803, 37.1515007019",
  },
  {
    ident: "KDRT",
    type: "medium_airport",
    name: "Del Rio International Airport",
    elevation_ft: "1002",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Del Rio",
    gps_code: "KDRT",
    iata_code: "DRT",
    local_code: "DRT",
    coordinates: "-100.927001953, 29.3742008209",
  },
  {
    ident: "KDS",
    type: "small_airport",
    name: "Kamaran Downs Airport",
    elevation_ft: "322",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Kamaran Downs",
    iata_code: "KDS",
    coordinates: "139.2785, -24.3388",
  },
  {
    ident: "KDSM",
    type: "large_airport",
    name: "Des Moines International Airport",
    elevation_ft: "958",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Des Moines",
    gps_code: "KDSM",
    iata_code: "DSM",
    local_code: "DSM",
    coordinates: "-93.66310119628906, 41.534000396728516",
  },
  {
    ident: "KDSV",
    type: "small_airport",
    name: "Dansville Municipal Airport",
    elevation_ft: "662",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Dansville",
    gps_code: "KDSV",
    iata_code: "DSV",
    local_code: "DSV",
    coordinates: "-77.7130966187, 42.570899963399995",
  },
  {
    ident: "KDTA",
    type: "small_airport",
    name: "Delta Municipal Airport",
    elevation_ft: "4759",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Delta",
    gps_code: "KDTA",
    iata_code: "DTA",
    local_code: "DTA",
    coordinates: "-112.508003235, 39.3805999756",
  },
  {
    ident: "KDTL",
    type: "small_airport",
    name: "Detroit Lakes Airport - Wething Field",
    elevation_ft: "1397",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Detroit Lakes",
    gps_code: "KDTL",
    iata_code: "DTL",
    local_code: "DTL",
    coordinates: "-95.88569641, 46.82519913",
  },
  {
    ident: "KDTN",
    type: "small_airport",
    name: "Shreveport Downtown Airport",
    elevation_ft: "179",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Shreveport",
    gps_code: "KDTN",
    iata_code: "DTN",
    local_code: "DTN",
    coordinates: "-93.7450027466, 32.5401992798",
  },
  {
    ident: "KDTS",
    type: "small_airport",
    name: "Destin Executive Airport",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Destin",
    gps_code: "KDTS",
    iata_code: "DSI",
    local_code: "DTS",
    coordinates: "-86.47149658, 30.40010071",
  },
  {
    ident: "KDTW",
    type: "large_airport",
    name: "Detroit Metropolitan Wayne County Airport",
    elevation_ft: "645",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Detroit",
    gps_code: "KDTW",
    iata_code: "DTW",
    local_code: "DTW",
    coordinates: "-83.35340118408203, 42.212398529052734",
  },
  {
    ident: "KDUA",
    type: "small_airport",
    name: "Eaker Field",
    elevation_ft: "699",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Durant",
    gps_code: "KDUA",
    iata_code: "DUA",
    local_code: "DUA",
    coordinates: "-96.39450073, 33.94229889",
  },
  {
    ident: "KDUC",
    type: "small_airport",
    name: "Halliburton Field",
    elevation_ft: "1114",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Duncan",
    gps_code: "KDUC",
    iata_code: "DUC",
    local_code: "DUC",
    coordinates: "-97.9598999, 34.47090149",
  },
  {
    ident: "KDUG",
    type: "medium_airport",
    name: "Bisbee Douglas International Airport",
    elevation_ft: "4154",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Douglas Bisbee",
    gps_code: "KDUG",
    iata_code: "DUG",
    local_code: "DUG",
    coordinates: "-109.603996277, 31.4689998627",
  },
  {
    ident: "KDUJ",
    type: "medium_airport",
    name: "DuBois Regional Airport",
    elevation_ft: "1817",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Dubois",
    gps_code: "KDUJ",
    iata_code: "DUJ",
    local_code: "DUJ",
    coordinates: "-78.8986969, 41.17829895",
  },
  {
    ident: "KDVL",
    type: "small_airport",
    name: "Devils Lake Regional Airport",
    elevation_ft: "1456",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Devils Lake",
    gps_code: "KDVL",
    iata_code: "DVL",
    local_code: "DVL",
    coordinates: "-98.90879822, 48.11420059",
  },
  {
    ident: "KDVN",
    type: "small_airport",
    name: "Davenport Municipal Airport",
    elevation_ft: "751",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Davenport",
    gps_code: "KDVN",
    iata_code: "DVN",
    local_code: "DVN",
    coordinates: "-90.58830261, 41.61029816",
  },
  {
    ident: "KDVO",
    type: "small_airport",
    name: "Marin County Airport - Gnoss Field",
    elevation_ft: "2",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Novato",
    gps_code: "KDVO",
    iata_code: "NOT",
    local_code: "DVO",
    coordinates: "-122.55599975586, 38.143600463867",
  },
  {
    ident: "KDVP",
    type: "small_airport",
    name: "Slayton Municipal Airport",
    elevation_ft: "1623",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Slayton",
    gps_code: "KDVP",
    iata_code: "NSL",
    local_code: "DVP",
    coordinates: "-95.782600402832, 43.986801147461",
  },
  {
    ident: "KDVT",
    type: "small_airport",
    name: "Phoenix Deer Valley Airport",
    elevation_ft: "1478",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Phoenix",
    gps_code: "KDVT",
    iata_code: "DVT",
    local_code: "DVT",
    coordinates: "-112.083000183, 33.6883010864",
  },
  {
    ident: "KDW",
    type: "seaplane_base",
    name: "Victoria Reservoir Seaplane Base",
    elevation_ft: "1402",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-2",
    municipality: "Kandy",
    iata_code: "KDW",
    coordinates: "80.7834, 7.2415",
  },
  {
    ident: "KDWF",
    type: "closed",
    name: "Wright Field",
    elevation_ft: "800",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Dayton",
    gps_code: "KDWF",
    iata_code: "DWF",
    local_code: "DWF",
    coordinates: "-84.10444444439999, 39.78",
  },
  {
    ident: "KDWH",
    type: "small_airport",
    name: "David Wayne Hooks Memorial Airport",
    elevation_ft: "152",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Houston",
    gps_code: "KDWH",
    iata_code: "DWH",
    local_code: "DWH",
    coordinates: "-95.55280303960001, 30.0618000031",
  },
  {
    ident: "KDXR",
    type: "small_airport",
    name: "Danbury Municipal Airport",
    elevation_ft: "458",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CT",
    municipality: "Danbury",
    gps_code: "KDXR",
    iata_code: "DXR",
    local_code: "DXR",
    coordinates: "-73.48220062259999, 41.371498107899995",
  },
  {
    ident: "KDYL",
    type: "small_airport",
    name: "Doylestown Airport",
    elevation_ft: "394",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Doylestown",
    gps_code: "KDYL",
    iata_code: "DYL",
    local_code: "DYL",
    coordinates: "-75.1222991943, 40.3330001831",
  },
  {
    ident: "KDYS",
    type: "large_airport",
    name: "Dyess Air Force Base",
    elevation_ft: "1789",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Abilene",
    gps_code: "KDYS",
    iata_code: "DYS",
    local_code: "DYS",
    coordinates: "-99.854598999, 32.4207992554",
  },
  {
    ident: "KDZ",
    type: "seaplane_base",
    name: "Polgolla Reservoir Seaplane Base",
    elevation_ft: "1473",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-2",
    municipality: "Kandy",
    iata_code: "KDZ",
    coordinates: "80.6422, 7.3251",
  },
  {
    ident: "KE-0060",
    type: "small_airport",
    name: "Mulika Lodge Airport",
    elevation_ft: "2000",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-400",
    municipality: "Meru-Kinna",
    gps_code: "HKMK",
    iata_code: "JJM",
    coordinates: "38.1951408386, 0.165083006024",
  },
  {
    ident: "KE-0069",
    type: "small_airport",
    name: "Vipingo Estate Airport",
    elevation_ft: "86",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    municipality: "Vipingo Estate",
    iata_code: "VPG",
    coordinates: "39.7973888889, -3.80666666667",
  },
  {
    ident: "KE-0133",
    type: "small_airport",
    name: "Kichwa Tembo Airport",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Kichwa Tembo",
    gps_code: "HKTB",
    iata_code: "KTJ",
    coordinates: "35.027533, -1.263497",
  },
  {
    ident: "KE-0182",
    type: "small_airport",
    name: "Ol Seki Airstrip",
    elevation_ft: "6500",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    iata_code: "OSJ",
    coordinates: "35.378453, -1.378382",
  },
  {
    ident: "KE-0208",
    type: "small_airport",
    name: "Olare Orok Airfield",
    elevation_ft: "5779",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    iata_code: "OLG",
    coordinates: "35.246106, -1.324213",
  },
  {
    ident: "KE-6087",
    type: "small_airport",
    name: "Mara North Conservancy Airstrip",
    elevation_ft: "5500",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Mara",
    gps_code: "HKMF",
    iata_code: "HKR",
    coordinates: "35.124922, -1.14542",
  },
  {
    ident: "KE-6112",
    type: "small_airport",
    name: "Angama Airport",
    elevation_ft: "6180",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Maasai Mara",
    gps_code: "HKOM",
    iata_code: "ANA",
    coordinates: "34.955513, -1.27156",
  },
  {
    ident: "KE-6208",
    type: "small_airport",
    name: "Olkiombo Airport",
    elevation_ft: "5011",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Olkiombo",
    gps_code: "HKOK",
    iata_code: "OLX",
    coordinates: "35.110689, -1.408586",
  },
  {
    ident: "KE-6978",
    type: "small_airport",
    name: "Kerio Valley Airport",
    elevation_ft: "4325",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Kimwarer",
    iata_code: "KRV",
    coordinates: "35.662559509277344, 0.3196380138397217",
  },
  {
    ident: "KE-KIU",
    type: "small_airport",
    name: "Kiunga Airport",
    elevation_ft: "1",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    municipality: "Kiunga",
    iata_code: "KIU",
    coordinates: "41.484344482421875, -1.7438290119171143",
  },
  {
    ident: "KE-LBK",
    type: "small_airport",
    name: "Liboi Airport",
    elevation_ft: "280",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-500",
    municipality: "Liboi",
    iata_code: "LBK",
    coordinates: "40.88169860839844, 0.3483330011367798",
  },
  {
    ident: "KE-LBN",
    type: "small_airport",
    name: "Lake Baringo Airport",
    elevation_ft: "3226",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Lake Baringo",
    iata_code: "LBN",
    coordinates: "36.104190826416016, 0.6661030054092407",
  },
  {
    ident: "KE-LKU",
    type: "small_airport",
    name: "Lake Rudolf Airport",
    elevation_ft: "1300",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Lake Rudolf",
    iata_code: "LKU",
    coordinates: "35.883331298828125, 3.4166669845581055",
  },
  {
    ident: "KE-MRE",
    type: "small_airport",
    name: "Mara Lodges Airport",
    elevation_ft: "5706",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-700",
    municipality: "Mara Lodges",
    iata_code: "MRE",
    coordinates: "35.11130905151367, -1.1782759428024292",
  },
  {
    ident: "KE-MUM",
    type: "small_airport",
    name: "Mumias Airport",
    elevation_ft: "4163",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-900",
    municipality: "Mumias",
    iata_code: "MUM",
    coordinates: "34.528234, 0.355399",
  },
  {
    ident: "KE01",
    type: "small_airport",
    name: "Roy Hurd Memorial Airport",
    elevation_ft: "2615",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Monahans",
    gps_code: "E01",
    iata_code: "MIF",
    local_code: "E01",
    coordinates: "-102.90899658203, 31.582500457764",
  },
  {
    ident: "KE13",
    type: "small_airport",
    name: "Crane County Airport",
    elevation_ft: "2552",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Crane",
    gps_code: "E13",
    iata_code: "CCG",
    local_code: "E13",
    coordinates: "-102.362998962, 31.415100097699998",
  },
  {
    ident: "KE14",
    type: "small_airport",
    name: "Ohkay Owingeh Airport",
    elevation_ft: "5790",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Espanola",
    gps_code: "E14",
    iata_code: "ESO",
    local_code: "E14",
    coordinates: "-106.04599762, 36.0299987793",
  },
  {
    ident: "KE24",
    type: "small_airport",
    name: "Whiteriver Airport",
    elevation_ft: "5153",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Whiteriver",
    gps_code: "E24",
    iata_code: "WTR",
    local_code: "E24",
    coordinates: "-109.9869995, 33.8125",
  },
  {
    ident: "KE38",
    type: "small_airport",
    name: "Alpine Casparis Municipal Airport",
    elevation_ft: "4515",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Alpine",
    iata_code: "ALE",
    local_code: "E38",
    coordinates: "-103.683998108, 30.384199142499998",
  },
  {
    ident: "KE51",
    type: "small_airport",
    name: "Bagdad Airport",
    elevation_ft: "4183",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Bagdad",
    gps_code: "E51",
    iata_code: "BGT",
    local_code: "E51",
    coordinates: "-113.169998169, 34.5959014893",
  },
  {
    ident: "KEAN",
    type: "small_airport",
    name: "Phifer Airfield",
    elevation_ft: "4776",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Wheatland",
    gps_code: "KEAN",
    iata_code: "EAN",
    local_code: "EAN",
    coordinates: "-104.929001, 42.0555",
  },
  {
    ident: "KEAR",
    type: "small_airport",
    name: "Kearney Regional Airport",
    elevation_ft: "2131",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Kearney",
    gps_code: "KEAR",
    iata_code: "EAR",
    local_code: "EAR",
    coordinates: "-99.00679779, 40.72700119",
  },
  {
    ident: "KEAT",
    type: "medium_airport",
    name: "Pangborn Memorial Airport",
    elevation_ft: "1249",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Wenatchee",
    gps_code: "KEAT",
    iata_code: "EAT",
    local_code: "EAT",
    coordinates: "-120.207000732, 47.3988990784",
  },
  {
    ident: "KEAU",
    type: "medium_airport",
    name: "Chippewa Valley Regional Airport",
    elevation_ft: "913",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Eau Claire",
    gps_code: "KEAU",
    iata_code: "EAU",
    local_code: "EAU",
    coordinates: "-91.48429870605469, 44.86579895019531",
  },
  {
    ident: "KEB",
    type: "small_airport",
    name: "Nanwalek Airport",
    elevation_ft: "27",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nanwalek",
    gps_code: "KEB",
    iata_code: "KEB",
    local_code: "KEB",
    coordinates: "-151.925003052, 59.3521003723",
  },
  {
    ident: "KEBS",
    type: "small_airport",
    name: "Webster City Municipal Airport",
    elevation_ft: "1122",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Webster City",
    gps_code: "KEBS",
    iata_code: "EBS",
    local_code: "EBS",
    coordinates: "-93.86889648, 42.43659973",
  },
  {
    ident: "KECG",
    type: "medium_airport",
    name: "Elizabeth City Regional Airport & Coast Guard Air Station",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Elizabeth City",
    gps_code: "KECG",
    iata_code: "ECG",
    local_code: "ECG",
    coordinates: "-76.17459869, 36.26060104",
  },
  {
    ident: "KECP",
    type: "medium_airport",
    name: "Northwest Florida Beaches International Airport",
    elevation_ft: "69",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Panama City Beach",
    gps_code: "KECP",
    iata_code: "ECP",
    local_code: "ECP",
    coordinates: "-85.795414, 30.357106",
  },
  {
    ident: "KECS",
    type: "small_airport",
    name: "Mondell Field",
    elevation_ft: "4174",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Newcastle",
    gps_code: "KECS",
    iata_code: "ECS",
    local_code: "ECS",
    coordinates: "-104.318001, 43.885399",
  },
  {
    ident: "KEDE",
    type: "small_airport",
    name: "Northeastern Regional Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Edenton",
    gps_code: "KEDE",
    iata_code: "EDE",
    local_code: "EDE",
    coordinates: "-76.56710052490001, 36.027698516799994",
  },
  {
    ident: "KEDN",
    type: "small_airport",
    name: "Enterprise Municipal Airport",
    elevation_ft: "361",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Enterprise",
    gps_code: "KEDN",
    iata_code: "ETS",
    local_code: "EDN",
    coordinates: "-85.89990234, 31.29969978",
  },
  {
    ident: "KEDW",
    type: "large_airport",
    name: "Edwards Air Force Base",
    elevation_ft: "2312",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Edwards",
    gps_code: "KEDW",
    iata_code: "EDW",
    local_code: "EDW",
    coordinates: "-117.884003, 34.905399",
  },
  {
    ident: "KEED",
    type: "medium_airport",
    name: "Needles Airport",
    elevation_ft: "983",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Needles",
    gps_code: "KEED",
    iata_code: "EED",
    local_code: "EED",
    coordinates: "-114.623001099, 34.7663002014",
  },
  {
    ident: "KEEN",
    type: "medium_airport",
    name: "Dillant Hopkins Airport",
    elevation_ft: "488",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Keene",
    gps_code: "KEEN",
    iata_code: "EEN",
    local_code: "EEN",
    coordinates: "-72.27079772949219, 42.898399353027344",
  },
  {
    ident: "KEFD",
    type: "medium_airport",
    name: "Ellington Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Houston",
    gps_code: "KEFD",
    iata_code: "EFD",
    local_code: "EFD",
    coordinates: "-95.1587982178, 29.607299804700002",
  },
  {
    ident: "KEFK",
    type: "small_airport",
    name: "Northeast Kingdom International Airport",
    elevation_ft: "930",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VT",
    municipality: "Newport",
    gps_code: "KEFK",
    iata_code: "EFK",
    local_code: "EFK",
    coordinates: "-72.229202, 44.888802",
  },
  {
    ident: "KEFW",
    type: "small_airport",
    name: "Jefferson Municipal Airport",
    elevation_ft: "1044",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Jefferson",
    gps_code: "KEFW",
    iata_code: "EFW",
    local_code: "EFW",
    coordinates: "-94.34259796, 42.0102005",
  },
  {
    ident: "KEG",
    type: "small_airport",
    name: "Keglsugl Airport",
    elevation_ft: "8400",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPK",
    municipality: "Denglagu Mission",
    gps_code: "AYLG",
    iata_code: "KEG",
    local_code: "KEG",
    coordinates: "145.097222222, -5.83277777778",
  },
  {
    ident: "KEGE",
    type: "medium_airport",
    name: "Eagle County Regional Airport",
    elevation_ft: "6548",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Eagle",
    gps_code: "KEGE",
    iata_code: "EGE",
    local_code: "EGE",
    coordinates: "-106.9179993, 39.64260101",
  },
  {
    ident: "KEGI",
    type: "medium_airport",
    name: "Duke Field",
    elevation_ft: "191",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Crestview",
    gps_code: "KEGI",
    iata_code: "EGI",
    local_code: "EGI",
    coordinates: "-86.52290344, 30.65040016",
  },
  {
    ident: "KEGV",
    type: "small_airport",
    name: "Eagle River Union Airport",
    elevation_ft: "1642",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Eagle River",
    gps_code: "KEGV",
    iata_code: "EGV",
    local_code: "EGV",
    coordinates: "-89.26830291750001, 45.932300567599995",
  },
  {
    ident: "KEK",
    type: "small_airport",
    name: "Ekwok Airport",
    elevation_ft: "135",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ekwok",
    gps_code: "KEK",
    iata_code: "KEK",
    local_code: "KEK",
    coordinates: "-157.4709930419922, 59.3568000793457",
  },
  {
    ident: "KEKA",
    type: "medium_airport",
    name: "Murray Field",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Eureka",
    gps_code: "KEKA",
    iata_code: "EKA",
    local_code: "EKA",
    coordinates: "-124.112998962, 40.803398132299996",
  },
  {
    ident: "KEKM",
    type: "small_airport",
    name: "Elkhart Municipal Airport",
    elevation_ft: "778",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Elkhart",
    gps_code: "KEKM",
    iata_code: "EKI",
    local_code: "EKM",
    coordinates: "-86.00319671630001, 41.7193984985",
  },
  {
    ident: "KEKN",
    type: "medium_airport",
    name: "Elkins-Randolph Co-Jennings Randolph Field",
    elevation_ft: "1987",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Elkins",
    gps_code: "KEKN",
    iata_code: "EKN",
    local_code: "EKN",
    coordinates: "-79.85710144, 38.88940048",
  },
  {
    ident: "KEKO",
    type: "medium_airport",
    name: "Elko Regional Airport",
    elevation_ft: "5140",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Elko",
    gps_code: "KEKO",
    iata_code: "EKO",
    local_code: "EKO",
    coordinates: "-115.79199981689453, 40.82490158081055",
  },
  {
    ident: "KEKX",
    type: "small_airport",
    name: "Addington Field",
    elevation_ft: "775",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Elizabethtown",
    gps_code: "KEKX",
    iata_code: "EKX",
    local_code: "EKX",
    coordinates: "-85.9250030518, 37.686000824",
  },
  {
    ident: "KELA",
    type: "small_airport",
    name: "Eagle Lake Airport",
    elevation_ft: "184",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Eagle Lake",
    gps_code: "KELA",
    iata_code: "ELA",
    local_code: "ELA",
    coordinates: "-96.3218994141, 29.600599288900003",
  },
  {
    ident: "KELD",
    type: "medium_airport",
    name: "South Arkansas Regional At Goodwin Field",
    elevation_ft: "277",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "El Dorado",
    gps_code: "KELD",
    iata_code: "ELD",
    local_code: "ELD",
    coordinates: "-92.81330108642578, 33.22100067138672",
  },
  {
    ident: "KELK",
    type: "small_airport",
    name: "Elk City Regional Business Airport",
    elevation_ft: "2013",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Elk City",
    gps_code: "KELK",
    iata_code: "ELK",
    local_code: "ELK",
    coordinates: "-99.39430237, 35.43080139",
  },
  {
    ident: "KELM",
    type: "medium_airport",
    name: "Elmira Corning Regional Airport",
    elevation_ft: "954",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Elmira/Corning",
    gps_code: "KELM",
    iata_code: "ELM",
    local_code: "ELM",
    coordinates: "-76.8916015625, 42.1599006652832",
  },
  {
    ident: "KELN",
    type: "small_airport",
    name: "Bowers Field",
    elevation_ft: "1764",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Ellensburg",
    gps_code: "KELN",
    iata_code: "ELN",
    local_code: "ELN",
    coordinates: "-120.5309982, 47.03300095",
  },
  {
    ident: "KELO",
    type: "medium_airport",
    name: "Ely Municipal Airport",
    elevation_ft: "1456",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Ely",
    gps_code: "KELO",
    iata_code: "LYU",
    local_code: "ELO",
    coordinates: "-91.83070374, 47.82450104",
  },
  {
    ident: "KELP",
    type: "medium_airport",
    name: "El Paso International Airport",
    elevation_ft: "3959",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "El Paso",
    gps_code: "KELP",
    iata_code: "ELP",
    local_code: "ELP",
    coordinates: "-106.3779984, 31.80719948",
  },
  {
    ident: "KELY",
    type: "medium_airport",
    name: "Ely Airport Yelland Field",
    elevation_ft: "6259",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Ely",
    gps_code: "KELY",
    iata_code: "ELY",
    local_code: "ELY",
    coordinates: "-114.8420029, 39.29970169",
  },
  {
    ident: "KELZ",
    type: "small_airport",
    name: "Wellsville Municipal Arpt,Tarantine Field",
    elevation_ft: "2124",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Wellsville",
    gps_code: "KELZ",
    iata_code: "ELZ",
    local_code: "ELZ",
    coordinates: "-77.98999786, 42.10950089",
  },
  {
    ident: "KEMM",
    type: "small_airport",
    name: "Kemmerer Municipal Airport",
    elevation_ft: "7285",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Kemmerer",
    gps_code: "KEMM",
    iata_code: "EMM",
    local_code: "EMM",
    coordinates: "-110.556999207, 41.82410049439999",
  },
  {
    ident: "KEMP",
    type: "small_airport",
    name: "Emporia Municipal Airport",
    elevation_ft: "1208",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Emporia",
    gps_code: "KEMP",
    iata_code: "EMP",
    local_code: "EMP",
    coordinates: "-96.19120025630001, 38.3320999146",
  },
  {
    ident: "KEMT",
    type: "small_airport",
    name: "San Gabriel Valley Airport",
    elevation_ft: "296",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "El Monte",
    gps_code: "KEMT",
    iata_code: "EMT",
    local_code: "EMT",
    coordinates: "-118.035004, 34.086102",
  },
  {
    ident: "KEND",
    type: "large_airport",
    name: "Vance Air Force Base",
    elevation_ft: "1307",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Enid",
    gps_code: "KEND",
    iata_code: "END",
    local_code: "END",
    coordinates: "-97.9164962769, 36.339199066199996",
  },
  {
    ident: "KENL",
    type: "small_airport",
    name: "Centralia Municipal Airport",
    elevation_ft: "534",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Centralia",
    gps_code: "KENL",
    iata_code: "ENL",
    local_code: "ENL",
    coordinates: "-89.0911026001, 38.515098571799996",
  },
  {
    ident: "KENV",
    type: "medium_airport",
    name: "Wendover Airport",
    elevation_ft: "4237",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Wendover",
    gps_code: "KENV",
    iata_code: "ENV",
    local_code: "ENV",
    coordinates: "-114.03099823, 40.7187004089",
  },
  {
    ident: "KENW",
    type: "medium_airport",
    name: "Kenosha Regional Airport",
    elevation_ft: "742",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Kenosha",
    gps_code: "KENW",
    iata_code: "ENW",
    local_code: "ENW",
    coordinates: "-87.92780304, 42.59569931",
  },
  {
    ident: "KEOK",
    type: "small_airport",
    name: "Keokuk Municipal Airport",
    elevation_ft: "671",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Keokuk",
    gps_code: "KEOK",
    iata_code: "EOK",
    local_code: "EOK",
    coordinates: "-91.4284973145, 40.459899902299995",
  },
  {
    ident: "KEPH",
    type: "small_airport",
    name: "Ephrata Municipal Airport",
    elevation_ft: "1276",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Ephrata",
    gps_code: "KEPH",
    iata_code: "EPH",
    local_code: "EPH",
    coordinates: "-119.5159988, 47.30759811",
  },
  {
    ident: "KEQA",
    type: "small_airport",
    name: "Captain Jack Thomas El Dorado Airport",
    elevation_ft: "1378",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "El Dorado",
    gps_code: "KEQA",
    iata_code: "EDK",
    local_code: "EQA",
    coordinates: "-96.81759643550001, 37.7741012573",
  },
  {
    ident: "KERI",
    type: "large_airport",
    name: "Erie International Tom Ridge Field",
    elevation_ft: "732",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Erie",
    gps_code: "KERI",
    iata_code: "ERI",
    local_code: "ERI",
    coordinates: "-80.1738667488, 42.0831270134",
  },
  {
    ident: "KERR",
    type: "small_airport",
    name: "Errol Airport",
    elevation_ft: "1245",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Errol",
    gps_code: "KERR",
    iata_code: "ERR",
    local_code: "ERR",
    coordinates: "-71.1641998291, 44.7924995422",
  },
  {
    ident: "KERV",
    type: "small_airport",
    name: "Kerrville Municipal Louis Schreiner Field",
    elevation_ft: "1617",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Kerrville",
    gps_code: "KERV",
    iata_code: "ERV",
    local_code: "ERV",
    coordinates: "-99.08570098879999, 29.9766998291",
  },
  {
    ident: "KESC",
    type: "small_airport",
    name: "Delta County Airport",
    elevation_ft: "609",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Escanaba",
    gps_code: "KESC",
    iata_code: "ESC",
    local_code: "ESC",
    coordinates: "-87.0936965942, 45.7226982117",
  },
  {
    ident: "KESF",
    type: "medium_airport",
    name: "Esler Regional Airport",
    elevation_ft: "112",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Alexandria",
    gps_code: "KESF",
    iata_code: "ESF",
    local_code: "ESF",
    coordinates: "-92.2957992554, 31.3948993683",
  },
  {
    ident: "KESN",
    type: "small_airport",
    name: "Easton Newnam Field",
    elevation_ft: "72",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Easton",
    gps_code: "KESN",
    iata_code: "ESN",
    local_code: "ESN",
    coordinates: "-76.06900024410001, 38.8041992188",
  },
  {
    ident: "KEST",
    type: "small_airport",
    name: "Estherville Municipal Airport",
    elevation_ft: "1319",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Estherville",
    gps_code: "KEST",
    iata_code: "EST",
    local_code: "EST",
    coordinates: "-94.74639893, 43.40739822",
  },
  {
    ident: "KESW",
    type: "small_airport",
    name: "Easton State Airport",
    elevation_ft: "2226",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Easton",
    gps_code: "KESW",
    iata_code: "ESW",
    local_code: "ESW",
    coordinates: "-121.185997009, 47.2541999817",
  },
  {
    ident: "KETB",
    type: "small_airport",
    name: "West Bend Municipal Airport",
    elevation_ft: "887",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "West Bend",
    gps_code: "KETB",
    iata_code: "ETB",
    local_code: "ETB",
    coordinates: "-88.1278991699, 43.4221992493",
  },
  {
    ident: "KETN",
    type: "small_airport",
    name: "Eastland Municipal Airport",
    elevation_ft: "1464",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Eastland",
    gps_code: "KETN",
    iata_code: "ETN",
    local_code: "ETN",
    coordinates: "-98.80979919430001, 32.4135017395",
  },
  {
    ident: "KEUF",
    type: "small_airport",
    name: "Weedon Field",
    elevation_ft: "285",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Eufaula",
    gps_code: "KEUF",
    iata_code: "EUF",
    local_code: "EUF",
    coordinates: "-85.1288986206, 31.9512996674",
  },
  {
    ident: "KEUG",
    type: "medium_airport",
    name: "Mahlon Sweet Field",
    elevation_ft: "374",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Eugene",
    gps_code: "KEUG",
    iata_code: "EUG",
    local_code: "EUG",
    coordinates: "-123.21199798583984, 44.12459945678711",
  },
  {
    ident: "KEVM",
    type: "small_airport",
    name: "Eveleth Virginia Municipal Airport",
    elevation_ft: "1379",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Eveleth",
    gps_code: "KEVM",
    iata_code: "EVM",
    local_code: "EVM",
    coordinates: "-92.49849701, 47.42509842",
  },
  {
    ident: "KEVV",
    type: "medium_airport",
    name: "Evansville Regional Airport",
    elevation_ft: "418",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Evansville",
    gps_code: "KEVV",
    iata_code: "EVV",
    local_code: "EVV",
    coordinates: "-87.5324020386, 38.0369987488",
  },
  {
    ident: "KEVW",
    type: "medium_airport",
    name: "Evanston-Uinta County Airport-Burns Field",
    elevation_ft: "7143",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Evanston",
    gps_code: "KEVW",
    iata_code: "EVW",
    local_code: "EVW",
    coordinates: "-111.0350037, 41.27479935",
  },
  {
    ident: "KEWB",
    type: "medium_airport",
    name: "New Bedford Regional Airport",
    elevation_ft: "80",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "New Bedford",
    gps_code: "KEWB",
    iata_code: "EWB",
    local_code: "EWB",
    coordinates: "-70.95690155029297, 41.67610168457031",
  },
  {
    ident: "KEWK",
    type: "small_airport",
    name: "Newton City-County Airport",
    elevation_ft: "1533",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Newton",
    gps_code: "KEWK",
    iata_code: "EWK",
    local_code: "EWK",
    coordinates: "-97.2744979858, 38.058200836199994",
  },
  {
    ident: "KEWN",
    type: "medium_airport",
    name: "Coastal Carolina Regional Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "New Bern",
    gps_code: "KEWN",
    iata_code: "EWN",
    local_code: "EWN",
    coordinates: "-77.04290008539999, 35.0730018616",
  },
  {
    ident: "KEWR",
    type: "large_airport",
    name: "Newark Liberty International Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Newark",
    gps_code: "KEWR",
    iata_code: "EWR",
    local_code: "EWR",
    coordinates: "-74.168701171875, 40.692501068115234",
  },
  {
    ident: "KEX",
    type: "small_airport",
    name: "Kanabea Airport",
    elevation_ft: "4288",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Kanabea",
    gps_code: "AYNB",
    iata_code: "KEX",
    local_code: "KEA",
    coordinates: "145.905, -7.538888888890001",
  },
  {
    ident: "KEYW",
    type: "medium_airport",
    name: "Key West International Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Key West",
    gps_code: "KEYW",
    iata_code: "EYW",
    local_code: "EYW",
    coordinates: "-81.75959777832031, 24.556100845336914",
  },
  {
    ident: "KEZ",
    type: "seaplane_base",
    name: "Kelani-Peliyagoda Seaplane Base",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-1",
    municipality: "Colombo",
    iata_code: "KEZ",
    coordinates: "79.93, 6.95",
  },
  {
    ident: "KF05",
    type: "small_airport",
    name: "Wilbarger County Airport",
    elevation_ft: "1265",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Vernon",
    gps_code: "F05",
    iata_code: "WIB",
    local_code: "F05",
    coordinates: "-99.2837982178, 34.2257003784",
  },
  {
    ident: "KF70",
    type: "small_airport",
    name: "French Valley Airport",
    elevation_ft: "1350",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Murrieta/Temecula",
    gps_code: "F70",
    iata_code: "RBK",
    local_code: "F70",
    coordinates: "-117.127998352, 33.5741996765",
  },
  {
    ident: "KFAF",
    type: "medium_airport",
    name: "Felker Army Air Field",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Fort Eustis",
    gps_code: "KFAF",
    iata_code: "FAF",
    local_code: "FAF",
    coordinates: "-76.60880279540001, 37.132499694799996",
  },
  {
    ident: "KFAM",
    type: "small_airport",
    name: "Farmington Regional Airport",
    elevation_ft: "946",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Farmington",
    gps_code: "KFAM",
    iata_code: "FAM",
    local_code: "FAM",
    coordinates: "-90.4285965, 37.76110077",
  },
  {
    ident: "KFAR",
    type: "medium_airport",
    name: "Hector International Airport",
    elevation_ft: "902",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Fargo",
    gps_code: "KFAR",
    iata_code: "FAR",
    local_code: "FAR",
    coordinates: "-96.81580352783203, 46.92070007324219",
  },
  {
    ident: "KFAT",
    type: "medium_airport",
    name: "Fresno Yosemite International Airport",
    elevation_ft: "336",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Fresno",
    gps_code: "KFAT",
    iata_code: "FAT",
    local_code: "FAT",
    coordinates: "-119.718002, 36.776199",
  },
  {
    ident: "KFAY",
    type: "medium_airport",
    name: "Fayetteville Regional Grannis Field",
    elevation_ft: "189",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Fayetteville",
    gps_code: "KFAY",
    iata_code: "FAY",
    local_code: "FAY",
    coordinates: "-78.88030242919922, 34.9911994934082",
  },
  {
    ident: "KFBG",
    type: "medium_airport",
    name: "Simmons Army Air Field",
    elevation_ft: "244",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Fort Bragg",
    gps_code: "KFBG",
    iata_code: "FBG",
    local_code: "FBG",
    coordinates: "-78.93669891, 35.13180161",
  },
  {
    ident: "KFBL",
    type: "small_airport",
    name: "Faribault Municipal Airport-Liz Wall Strohfus Field",
    elevation_ft: "1060",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Faribault",
    gps_code: "KFBL",
    iata_code: "FBL",
    local_code: "FBL",
    coordinates: "-93.312534, 44.32844",
  },
  {
    ident: "KFBR",
    type: "small_airport",
    name: "Fort Bridger Airport",
    elevation_ft: "7034",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Fort Bridger",
    gps_code: "KFBR",
    iata_code: "FBR",
    local_code: "FBR",
    coordinates: "-110.406997681, 41.3918991089",
  },
  {
    ident: "KFBY",
    type: "small_airport",
    name: "Fairbury Municipal Airport",
    elevation_ft: "1479",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Fairbury",
    gps_code: "KFBY",
    iata_code: "FBY",
    local_code: "FBY",
    coordinates: "-97.16929626459999, 40.182998657199995",
  },
  {
    ident: "KFCH",
    type: "small_airport",
    name: "Fresno Chandler Executive Airport",
    elevation_ft: "279",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Fresno",
    gps_code: "KFCH",
    iata_code: "FCH",
    local_code: "FCH",
    coordinates: "-119.82, 36.732399",
  },
  {
    ident: "KFCM",
    type: "small_airport",
    name: "Flying Cloud Airport",
    elevation_ft: "906",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Minneapolis",
    gps_code: "KFCM",
    iata_code: "FCM",
    local_code: "FCM",
    coordinates: "-93.45709991460001, 44.8272018433",
  },
  {
    ident: "KFCS",
    type: "medium_airport",
    name: "Butts AAF (Fort Carson) Air Field",
    elevation_ft: "5838",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Fort Carson",
    gps_code: "KFCS",
    iata_code: "FCS",
    local_code: "FCS",
    coordinates: "-104.7570038, 38.67839813",
  },
  {
    ident: "KFCY",
    type: "small_airport",
    name: "Forrest City Municipal Airport",
    elevation_ft: "249",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Forrest City",
    gps_code: "KFCY",
    iata_code: "FCY",
    local_code: "FCY",
    coordinates: "-90.7750015259, 34.942001342800005",
  },
  {
    ident: "KFDK",
    type: "small_airport",
    name: "Frederick Municipal Airport",
    elevation_ft: "303",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Frederick",
    gps_code: "KFDK",
    iata_code: "FDK",
    local_code: "FDK",
    coordinates: "-77.3742980957, 39.417598724399994",
  },
  {
    ident: "KFDR",
    type: "small_airport",
    name: "Frederick Regional Airport",
    elevation_ft: "1258",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Frederick",
    gps_code: "KFDR",
    iata_code: "FDR",
    local_code: "FDR",
    coordinates: "-98.98390198, 34.35200119",
  },
  {
    ident: "KFDY",
    type: "medium_airport",
    name: "Findlay Airport",
    elevation_ft: "813",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Findlay",
    gps_code: "KFDY",
    iata_code: "FDY",
    local_code: "FDY",
    coordinates: "-83.66870117190001, 41.013500213600004",
  },
  {
    ident: "KFEP",
    type: "small_airport",
    name: "Albertus Airport",
    elevation_ft: "859",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Freeport",
    gps_code: "KFEP",
    iata_code: "FEP",
    local_code: "FEP",
    coordinates: "-89.58200073239999, 42.2462005615",
  },
  {
    ident: "KFET",
    type: "small_airport",
    name: "Fremont Municipal Airport",
    elevation_ft: "1204",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Fremont",
    gps_code: "KFET",
    iata_code: "FET",
    local_code: "FET",
    coordinates: "-96.52020264, 41.44910049",
  },
  {
    ident: "KFFA",
    type: "small_airport",
    name: "First Flight Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Kill Devil Hills",
    gps_code: "KFFA",
    iata_code: "FFA",
    local_code: "FFA",
    coordinates: "-75.67130279540001, 36.0181999207",
  },
  {
    ident: "KFFL",
    type: "small_airport",
    name: "Fairfield Municipal Airport",
    elevation_ft: "799",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Fairfield",
    gps_code: "KFFL",
    iata_code: "FFL",
    local_code: "FFL",
    coordinates: "-91.9788970947, 41.0532989502",
  },
  {
    ident: "KFFM",
    type: "small_airport",
    name: "Fergus Falls Municipal Airport - Einar Mickelson Field",
    elevation_ft: "1183",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Fergus Falls",
    gps_code: "KFFM",
    iata_code: "FFM",
    local_code: "FFM",
    coordinates: "-96.15670013, 46.28440094",
  },
  {
    ident: "KFFO",
    type: "large_airport",
    name: "Wright-Patterson Air Force Base",
    elevation_ft: "823",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Dayton",
    gps_code: "KFFO",
    iata_code: "FFO",
    local_code: "FFO",
    coordinates: "-84.0483016968, 39.8260993958",
  },
  {
    ident: "KFFT",
    type: "small_airport",
    name: "Capital City Airport",
    elevation_ft: "806",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Frankfort",
    gps_code: "KFFT",
    iata_code: "FFT",
    local_code: "FFT",
    coordinates: "-84.90470123, 38.18249893",
  },
  {
    ident: "KFFZ",
    type: "small_airport",
    name: "Falcon Field",
    elevation_ft: "1394",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Mesa",
    gps_code: "KFFZ",
    iata_code: "MSC",
    local_code: "FFZ",
    coordinates: "-111.727996826, 33.4608001709",
  },
  {
    ident: "KFHR",
    type: "small_airport",
    name: "Friday Harbor Airport",
    elevation_ft: "113",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Friday Harbor",
    gps_code: "KFHR",
    iata_code: "FRD",
    local_code: "FHR",
    coordinates: "-123.024002075, 48.5219993591",
  },
  {
    ident: "KFHU",
    type: "medium_airport",
    name: "Sierra Vista Municipal Airport / Libby Army Air Field",
    elevation_ft: "4719",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Fort Huachuca / Sierra Vista",
    gps_code: "KFHU",
    iata_code: "FHU",
    local_code: "FHU",
    coordinates: "-110.348225, 31.587383",
  },
  {
    ident: "KFKL",
    type: "medium_airport",
    name: "Venango Regional Airport",
    elevation_ft: "1540",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Franklin",
    gps_code: "KFKL",
    iata_code: "FKL",
    local_code: "FKL",
    coordinates: "-79.8603973389, 41.3778991699",
  },
  {
    ident: "KFKN",
    type: "small_airport",
    name: "Franklin Regional Airport",
    elevation_ft: "41",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Franklin",
    gps_code: "KFKN",
    iata_code: "FKN",
    local_code: "FKN",
    coordinates: "-76.903801, 36.698101",
  },
  {
    ident: "KFLD",
    type: "small_airport",
    name: "Fond du Lac County Airport",
    elevation_ft: "808",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Fond du Lac",
    gps_code: "KFLD",
    iata_code: "FLD",
    local_code: "FLD",
    coordinates: "-88.48840332030001, 43.7711982727",
  },
  {
    ident: "KFLG",
    type: "medium_airport",
    name: "Flagstaff Pulliam Airport",
    elevation_ft: "7014",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Flagstaff",
    gps_code: "KFLG",
    iata_code: "FLG",
    local_code: "FLG",
    coordinates: "-111.6709976, 35.13850021",
  },
  {
    ident: "KFLL",
    type: "large_airport",
    name: "Fort Lauderdale Hollywood International Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Fort Lauderdale",
    gps_code: "KFLL",
    iata_code: "FLL",
    local_code: "FLL",
    coordinates: "-80.152702, 26.072599",
  },
  {
    ident: "KFLO",
    type: "medium_airport",
    name: "Florence Regional Airport",
    elevation_ft: "146",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Florence",
    gps_code: "KFLO",
    iata_code: "FLO",
    local_code: "FLO",
    coordinates: "-79.7238998413086, 34.18539810180664",
  },
  {
    ident: "KFLP",
    type: "small_airport",
    name: "Marion County Regional Airport",
    elevation_ft: "719",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Flippin",
    gps_code: "KFLP",
    iata_code: "FLP",
    local_code: "FLP",
    coordinates: "-92.59030151, 36.29090118",
  },
  {
    ident: "KFLU",
    type: "closed",
    name: "Flushing Airport",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Queens",
    gps_code: "KFLU",
    iata_code: "FLU",
    coordinates: "-73.83260345458984, 40.77870178222656",
  },
  {
    ident: "KFLV",
    type: "small_airport",
    name: "Sherman Army Air Field",
    elevation_ft: "772",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Fort Leavenworth",
    gps_code: "KFLV",
    iata_code: "FLV",
    local_code: "FLV",
    coordinates: "-94.9147033691, 39.3683013916",
  },
  {
    ident: "KFLX",
    type: "small_airport",
    name: "Fallon Municipal Airport",
    elevation_ft: "3963",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Fallon",
    gps_code: "KFLX",
    iata_code: "FLX",
    local_code: "FLX",
    coordinates: "-118.749000549, 39.4990997314",
  },
  {
    ident: "KFME",
    type: "medium_airport",
    name: "Tipton Airport",
    elevation_ft: "150",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Fort Meade(Odenton)",
    gps_code: "KFME",
    iata_code: "FME",
    local_code: "FME",
    coordinates: "-76.7593994141, 39.08539962769999",
  },
  {
    ident: "KFMH",
    type: "small_airport",
    name: "Cape Cod Coast Guard Air Station",
    elevation_ft: "131",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Falmouth",
    gps_code: "KFMH",
    iata_code: "FMH",
    local_code: "FMH",
    coordinates: "-70.5214004517, 41.6584014893",
  },
  {
    ident: "KFMN",
    type: "medium_airport",
    name: "Four Corners Regional Airport",
    elevation_ft: "5506",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Farmington",
    gps_code: "KFMN",
    iata_code: "FMN",
    local_code: "FMN",
    coordinates: "-108.230003357, 36.741199493399996",
  },
  {
    ident: "KFMY",
    type: "medium_airport",
    name: "Page Field",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Fort Myers",
    gps_code: "KFMY",
    iata_code: "FMY",
    local_code: "FMY",
    coordinates: "-81.86329650879999, 26.58659935",
  },
  {
    ident: "KFNL",
    type: "small_airport",
    name: "Northern Colorado Regional Airport",
    elevation_ft: "5016",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Fort Collins/Loveland",
    gps_code: "KFNL",
    iata_code: "FNL",
    local_code: "FNL",
    coordinates: "-105.011, 40.451804",
  },
  {
    ident: "KFNT",
    type: "medium_airport",
    name: "Bishop International Airport",
    elevation_ft: "782",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Flint",
    gps_code: "KFNT",
    iata_code: "FNT",
    local_code: "FNT",
    coordinates: "-83.74359893798828, 42.96540069580078",
  },
  {
    ident: "KFOD",
    type: "medium_airport",
    name: "Fort Dodge Regional Airport",
    elevation_ft: "1156",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Fort Dodge",
    gps_code: "KFOD",
    iata_code: "FOD",
    local_code: "FOD",
    coordinates: "-94.19259644, 42.55149841",
  },
  {
    ident: "KFOE",
    type: "medium_airport",
    name: "Topeka Regional Airport - Forbes Field",
    elevation_ft: "1078",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Topeka",
    gps_code: "KFOE",
    iata_code: "FOE",
    local_code: "FOE",
    coordinates: "-95.66359710690001, 38.950901031499995",
  },
  {
    ident: "KFOK",
    type: "small_airport",
    name: "Francis S Gabreski Airport",
    elevation_ft: "67",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Westhampton Beach",
    gps_code: "KFOK",
    iata_code: "FOK",
    local_code: "FOK",
    coordinates: "-72.6317977905, 40.8437004089",
  },
  {
    ident: "KFOM",
    type: "small_airport",
    name: "Fillmore Municipal Airport",
    elevation_ft: "4985",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Fillmore",
    gps_code: "KFOM",
    iata_code: "FIL",
    local_code: "FOM",
    coordinates: "-112.362999, 38.95830154",
  },
  {
    ident: "KFPR",
    type: "medium_airport",
    name: "St Lucie County International Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Fort Pierce",
    gps_code: "KFPR",
    iata_code: "FPR",
    local_code: "FPR",
    coordinates: "-80.36830139, 27.49510002",
  },
  {
    ident: "KFRG",
    type: "medium_airport",
    name: "Republic Airport",
    elevation_ft: "82",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Farmingdale",
    gps_code: "KFRG",
    iata_code: "FRG",
    local_code: "FRG",
    coordinates: "-73.4133987427, 40.7288017273",
  },
  {
    ident: "KFRH",
    type: "small_airport",
    name: "French Lick Municipal Airport",
    elevation_ft: "792",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "French Lick",
    gps_code: "KFRH",
    iata_code: "FRH",
    local_code: "FRH",
    coordinates: "-86.63690185550001, 38.5061988831",
  },
  {
    ident: "KFRI",
    type: "medium_airport",
    name: "Marshall Army Air Field",
    elevation_ft: "1065",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Fort Riley(Junction City)",
    gps_code: "KFRI",
    iata_code: "FRI",
    local_code: "FRI",
    coordinates: "-96.76450348, 39.05530167",
  },
  {
    ident: "KFRM",
    type: "small_airport",
    name: "Fairmont Municipal Airport",
    elevation_ft: "1162",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Fairmont",
    gps_code: "KFRM",
    iata_code: "FRM",
    local_code: "FRM",
    coordinates: "-94.4156036377, 43.643901825",
  },
  {
    ident: "KFRR",
    type: "small_airport",
    name: "Front Royal Warren County Airport",
    elevation_ft: "709",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Front Royal",
    gps_code: "KFRR",
    iata_code: "FRR",
    local_code: "FRR",
    coordinates: "-78.25350189210002, 38.9174995422",
  },
  {
    ident: "KFSD",
    type: "medium_airport",
    name: "Joe Foss Field Airport",
    elevation_ft: "1429",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Sioux Falls",
    gps_code: "KFSD",
    iata_code: "FSD",
    local_code: "FSD",
    coordinates: "-96.741897583, 43.582000732400004",
  },
  {
    ident: "KFSI",
    type: "medium_airport",
    name: "Henry Post Army Air Field (Fort Sill)",
    elevation_ft: "1189",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Fort Sill",
    gps_code: "KFSI",
    iata_code: "FSI",
    local_code: "FSI",
    coordinates: "-98.40219879, 34.64979935",
  },
  {
    ident: "KFSK",
    type: "small_airport",
    name: "Fort Scott Municipal Airport",
    elevation_ft: "918",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Fort Scott",
    gps_code: "KFSK",
    iata_code: "FSK",
    local_code: "FSK",
    coordinates: "-94.7694015503, 37.7984008789",
  },
  {
    ident: "KFSM",
    type: "large_airport",
    name: "Fort Smith Regional Airport",
    elevation_ft: "469",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Fort Smith",
    gps_code: "KFSM",
    iata_code: "FSM",
    local_code: "FSM",
    coordinates: "-94.36740112304688, 35.33660125732422",
  },
  {
    ident: "KFST",
    type: "medium_airport",
    name: "Fort Stockton Pecos County Airport",
    elevation_ft: "3011",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Fort Stockton",
    gps_code: "KFST",
    iata_code: "FST",
    local_code: "FST",
    coordinates: "-102.916000366, 30.9157009125",
  },
  {
    ident: "KFSU",
    type: "small_airport",
    name: "Fort Sumner Municipal Airport",
    elevation_ft: "4165",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Fort Sumner",
    gps_code: "KFSU",
    iata_code: "FSU",
    local_code: "FSU",
    coordinates: "-104.217002869, 34.4833984375",
  },
  {
    ident: "KFSW",
    type: "small_airport",
    name: "Fort Madison Municipal Airport",
    elevation_ft: "724",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Fort Madison",
    gps_code: "KFSW",
    iata_code: "FMS",
    local_code: "FSW",
    coordinates: "-91.326797, 40.659302",
  },
  {
    ident: "KFTK",
    type: "medium_airport",
    name: "Godman Army Air Field",
    elevation_ft: "756",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Fort Knox",
    gps_code: "KFTK",
    iata_code: "FTK",
    local_code: "FTK",
    coordinates: "-85.9720993042, 37.907100677500004",
  },
  {
    ident: "KFTW",
    type: "large_airport",
    name: "Fort Worth Meacham International Airport",
    elevation_ft: "710",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Fort Worth",
    gps_code: "KFTW",
    iata_code: "FTW",
    local_code: "FTW",
    coordinates: "-97.362396, 32.819801",
  },
  {
    ident: "KFTY",
    type: "medium_airport",
    name: "Fulton County Airport Brown Field",
    elevation_ft: "841",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Atlanta",
    gps_code: "KFTY",
    iata_code: "FTY",
    local_code: "FTY",
    coordinates: "-84.5214004517, 33.7790985107",
  },
  {
    ident: "KFUL",
    type: "small_airport",
    name: "Fullerton Municipal Airport",
    elevation_ft: "96",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Fullerton",
    gps_code: "KFUL",
    iata_code: "FUL",
    local_code: "FUL",
    coordinates: "-117.980003357, 33.8720016479",
  },
  {
    ident: "KFVE",
    type: "small_airport",
    name: "Northern Aroostook Regional Airport",
    elevation_ft: "988",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Frenchville",
    gps_code: "KFVE",
    iata_code: "WFK",
    local_code: "FVE",
    coordinates: "-68.31279754639999, 47.2854995728",
  },
  {
    ident: "KFWA",
    type: "large_airport",
    name: "Fort Wayne International Airport",
    elevation_ft: "814",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Fort Wayne",
    gps_code: "KFWA",
    iata_code: "FWA",
    local_code: "FWA",
    coordinates: "-85.19509888, 40.97850037",
  },
  {
    ident: "KFXE",
    type: "medium_airport",
    name: "Fort Lauderdale Executive Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Fort Lauderdale",
    gps_code: "KFXE",
    iata_code: "FXE",
    local_code: "FXE",
    coordinates: "-80.1707000732, 26.1972999573",
  },
  {
    ident: "KFXY",
    type: "small_airport",
    name: "Forest City Municipal Airport",
    elevation_ft: "1229",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Forest City",
    gps_code: "KFXY",
    iata_code: "FXY",
    local_code: "FXY",
    coordinates: "-93.62409973, 43.23469925",
  },
  {
    ident: "KFYM",
    type: "small_airport",
    name: "Fayetteville Municipal Airport",
    elevation_ft: "984",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Fayetteville",
    gps_code: "KFYM",
    iata_code: "FYM",
    local_code: "FYM",
    coordinates: "-86.5640029907, 35.059700012200004",
  },
  {
    ident: "KFYV",
    type: "medium_airport",
    name: "Drake Field",
    elevation_ft: "1251",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Fayetteville",
    gps_code: "KFYV",
    iata_code: "FYV",
    local_code: "FYV",
    coordinates: "-94.17009735107422, 36.00510025024414",
  },
  {
    ident: "KGAD",
    type: "small_airport",
    name: "Northeast Alabama Regional Airport",
    elevation_ft: "569",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Gadsden",
    gps_code: "KGAD",
    iata_code: "GAD",
    local_code: "GAD",
    coordinates: "-86.088996, 33.972599",
  },
  {
    ident: "KGAG",
    type: "small_airport",
    name: "Gage Airport",
    elevation_ft: "2223",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Gage",
    gps_code: "KGAG",
    iata_code: "GAG",
    local_code: "GAG",
    coordinates: "-99.7763977051, 36.295501709",
  },
  {
    ident: "KGAI",
    type: "small_airport",
    name: "Montgomery County Airpark",
    elevation_ft: "539",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Gaithersburg",
    gps_code: "KGAI",
    iata_code: "GAI",
    local_code: "GAI",
    coordinates: "-77.1660003662, 39.168300628699996",
  },
  {
    ident: "KGBD",
    type: "small_airport",
    name: "Great Bend Municipal Airport",
    elevation_ft: "1887",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Great Bend",
    gps_code: "KGBD",
    iata_code: "GBD",
    local_code: "GBD",
    coordinates: "-98.8591995239, 38.3442993164",
  },
  {
    ident: "KGBG",
    type: "small_airport",
    name: "Galesburg Municipal Airport",
    elevation_ft: "764",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Galesburg",
    gps_code: "KGBG",
    iata_code: "GBG",
    local_code: "GBG",
    coordinates: "-90.431098938, 40.937999725299996",
  },
  {
    ident: "KGBR",
    type: "small_airport",
    name: "Walter J. Koladza Airport",
    elevation_ft: "739",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Great Barrington",
    gps_code: "KGBR",
    iata_code: "GBR",
    local_code: "GBR",
    coordinates: "-73.40319824, 42.18420029",
  },
  {
    ident: "KGCC",
    type: "medium_airport",
    name: "Gillette Campbell County Airport",
    elevation_ft: "4365",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Gillette",
    gps_code: "KGCC",
    iata_code: "GCC",
    local_code: "GCC",
    coordinates: "-105.539001465, 44.348899841299996",
  },
  {
    ident: "KGCD",
    type: "small_airport",
    name: "Grant Co Regional/Ogilvie Field",
    elevation_ft: "3703",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "John Day",
    gps_code: "KGCD",
    iata_code: "JDA",
    local_code: "GCD",
    coordinates: "-118.9629974, 44.40420151",
  },
  {
    ident: "KGCK",
    type: "medium_airport",
    name: "Garden City Regional Airport",
    elevation_ft: "2891",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Garden City",
    gps_code: "KGCK",
    iata_code: "GCK",
    local_code: "GCK",
    coordinates: "-100.723999023, 37.9275016785",
  },
  {
    ident: "KGCN",
    type: "medium_airport",
    name: "Grand Canyon National Park Airport",
    elevation_ft: "6609",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Grand Canyon",
    gps_code: "KGCN",
    iata_code: "GCN",
    local_code: "GCN",
    coordinates: "-112.14700317382812, 35.95240020751953",
  },
  {
    ident: "KGCY",
    type: "small_airport",
    name: "Greeneville Municipal Airport",
    elevation_ft: "1608",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Greeneville",
    gps_code: "KGCY",
    iata_code: "GCY",
    local_code: "GCY",
    coordinates: "-82.815102, 36.193001",
  },
  {
    ident: "KGDM",
    type: "small_airport",
    name: "Gardner Municipal Airport",
    elevation_ft: "955",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Gardner",
    gps_code: "KGDM",
    iata_code: "GDM",
    local_code: "GDM",
    coordinates: "-72.0160980225, 42.5499992371",
  },
  {
    ident: "KGDV",
    type: "medium_airport",
    name: "Dawson Community Airport",
    elevation_ft: "2458",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Glendive",
    gps_code: "KGDV",
    iata_code: "GDV",
    local_code: "GDV",
    coordinates: "-104.8069992, 47.13869858",
  },
  {
    ident: "KGDW",
    type: "small_airport",
    name: "Gladwin Zettel Memorial Airport",
    elevation_ft: "776",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Gladwin",
    gps_code: "KGDW",
    iata_code: "GDW",
    local_code: "GDW",
    coordinates: "-84.47499847410002, 43.9706001282",
  },
  {
    ident: "KGED",
    type: "small_airport",
    name: "Sussex County Airport",
    elevation_ft: "53",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-DE",
    municipality: "Georgetown",
    gps_code: "KGED",
    iata_code: "GED",
    local_code: "GED",
    coordinates: "-75.35890198, 38.68920135",
  },
  {
    ident: "KGEG",
    type: "large_airport",
    name: "Spokane International Airport",
    elevation_ft: "2376",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Spokane",
    gps_code: "KGEG",
    iata_code: "GEG",
    local_code: "GEG",
    coordinates: "-117.53399658203125, 47.61989974975586",
  },
  {
    ident: "KGEY",
    type: "small_airport",
    name: "South Big Horn County Airport",
    elevation_ft: "3939",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Greybull",
    gps_code: "KGEY",
    iata_code: "GEY",
    local_code: "GEY",
    coordinates: "-108.0830002, 44.51679993",
  },
  {
    ident: "KGFA",
    type: "heliport",
    name: "Malmstrom Air Force Base",
    elevation_ft: "3472",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Great Falls",
    gps_code: "KGFA",
    iata_code: "GFA",
    local_code: "GFA",
    coordinates: "-111.18699646, 47.504699707",
  },
  {
    ident: "KGFK",
    type: "medium_airport",
    name: "Grand Forks International Airport",
    elevation_ft: "845",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Grand Forks",
    gps_code: "KGFK",
    iata_code: "GFK",
    local_code: "GFK",
    coordinates: "-97.176102, 47.949299",
  },
  {
    ident: "KGFL",
    type: "medium_airport",
    name: "Floyd Bennett Memorial Airport",
    elevation_ft: "328",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Glens Falls",
    gps_code: "KGFL",
    iata_code: "GFL",
    local_code: "GFL",
    coordinates: "-73.6102981567, 43.3412017822",
  },
  {
    ident: "KGGE",
    type: "small_airport",
    name: "Georgetown County Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Georgetown",
    gps_code: "KGGE",
    iata_code: "GGE",
    local_code: "GGE",
    coordinates: "-79.3196029663, 33.3116989136",
  },
  {
    ident: "KGGG",
    type: "medium_airport",
    name: "East Texas Regional Airport",
    elevation_ft: "365",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Longview",
    gps_code: "KGGG",
    iata_code: "GGG",
    local_code: "GGG",
    coordinates: "-94.71150207519531, 32.38399887084961",
  },
  {
    ident: "KGGW",
    type: "medium_airport",
    name: "Wokal Field/Glasgow-Valley County Airport",
    elevation_ft: "2296",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Glasgow",
    gps_code: "KGGW",
    iata_code: "GGW",
    local_code: "GGW",
    coordinates: "-106.614998, 48.212502",
  },
  {
    ident: "KGHM",
    type: "small_airport",
    name: "Centerville Municipal Airport",
    elevation_ft: "768",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Centerville",
    gps_code: "KGHM",
    iata_code: "GHM",
    local_code: "GHM",
    coordinates: "-87.44539642330001, 35.8373985291",
  },
  {
    ident: "KGIF",
    type: "small_airport",
    name: "Winter Haven Regional Airport - Gilbert Field",
    elevation_ft: "145",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Winter Haven",
    gps_code: "KGIF",
    iata_code: "GIF",
    local_code: "GIF",
    coordinates: "-81.753304, 28.062901",
  },
  {
    ident: "KGJT",
    type: "medium_airport",
    name: "Grand Junction Regional Airport",
    elevation_ft: "4858",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Grand Junction",
    gps_code: "KGJT",
    iata_code: "GJT",
    local_code: "GJT",
    coordinates: "-108.527000427, 39.1223983765",
  },
  {
    ident: "KGKJ",
    type: "small_airport",
    name: "Port Meadville Airport",
    elevation_ft: "1399",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Meadville",
    gps_code: "KGKJ",
    iata_code: "MEJ",
    local_code: "GKJ",
    coordinates: "-80.21469879, 41.62649918",
  },
  {
    ident: "KGKT",
    type: "small_airport",
    name: "Gatlinburg-Pigeon Forge Airport",
    elevation_ft: "1014",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Sevierville",
    gps_code: "KGKT",
    iata_code: "GKT",
    local_code: "GKT",
    coordinates: "-83.52870178219999, 35.85779953",
  },
  {
    ident: "KGLD",
    type: "medium_airport",
    name: "Renner Field-Goodland Municipal Airport",
    elevation_ft: "3656",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Goodland",
    gps_code: "KGLD",
    iata_code: "GLD",
    local_code: "GLD",
    coordinates: "-101.6989975, 39.37060165",
  },
  {
    ident: "KGLE",
    type: "small_airport",
    name: "Gainesville Municipal Airport",
    elevation_ft: "845",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Gainesville",
    gps_code: "KGLE",
    iata_code: "GLE",
    local_code: "GLE",
    coordinates: "-97.1969985962, 33.651401519800004",
  },
  {
    ident: "KGLH",
    type: "medium_airport",
    name: "Mid Delta Regional Airport",
    elevation_ft: "131",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Greenville",
    gps_code: "KGLH",
    iata_code: "GLH",
    local_code: "GLH",
    coordinates: "-90.98560333251953, 33.4828987121582",
  },
  {
    ident: "KGLR",
    type: "small_airport",
    name: "Gaylord Regional Airport",
    elevation_ft: "1328",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Gaylord",
    gps_code: "KGLR",
    iata_code: "GLR",
    local_code: "GLR",
    coordinates: "-84.7035980225, 45.013500213600004",
  },
  {
    ident: "KGLS",
    type: "medium_airport",
    name: "Scholes International At Galveston Airport",
    elevation_ft: "6",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Galveston",
    gps_code: "KGLS",
    iata_code: "GLS",
    local_code: "GLS",
    coordinates: "-94.86039733886719, 29.265300750732422",
  },
  {
    ident: "KGLW",
    type: "small_airport",
    name: "Glasgow Municipal Airport",
    elevation_ft: "716",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Glasgow",
    gps_code: "KGLW",
    iata_code: "GLW",
    local_code: "GLW",
    coordinates: "-85.9536972, 37.03179932",
  },
  {
    ident: "KGMU",
    type: "medium_airport",
    name: "Greenville Downtown Airport",
    elevation_ft: "1048",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Greenville",
    gps_code: "KGMU",
    iata_code: "GMU",
    local_code: "GMU",
    coordinates: "-82.34999847410002, 34.847900390599996",
  },
  {
    ident: "KGNG",
    type: "small_airport",
    name: "Gooding Municipal Airport",
    elevation_ft: "3732",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Gooding",
    gps_code: "KGNG",
    iata_code: "GNG",
    local_code: "GNG",
    coordinates: "-114.7649994, 42.91719818",
  },
  {
    ident: "KGNT",
    type: "small_airport",
    name: "Grants-Milan Municipal Airport",
    elevation_ft: "6537",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Grants",
    gps_code: "KGNT",
    iata_code: "GNT",
    local_code: "GNT",
    coordinates: "-107.902000427, 35.167301178",
  },
  {
    ident: "KGNV",
    type: "medium_airport",
    name: "Gainesville Regional Airport",
    elevation_ft: "152",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Gainesville",
    gps_code: "KGNV",
    iata_code: "GNV",
    local_code: "GNV",
    coordinates: "-82.2717971802, 29.6900997162",
  },
  {
    ident: "KGOK",
    type: "small_airport",
    name: "Guthrie-Edmond Regional Airport",
    elevation_ft: "1069",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Guthrie",
    gps_code: "KGOK",
    iata_code: "GOK",
    local_code: "GOK",
    coordinates: "-97.41560364, 35.84980011",
  },
  {
    ident: "KGON",
    type: "medium_airport",
    name: "Groton New London Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CT",
    municipality: "Groton (New London)",
    gps_code: "KGON",
    iata_code: "GON",
    local_code: "GON",
    coordinates: "-72.04509735107422, 41.330101013183594",
  },
  {
    ident: "KGPI",
    type: "medium_airport",
    name: "Glacier Park International Airport",
    elevation_ft: "2977",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Kalispell",
    gps_code: "KGPI",
    iata_code: "FCA",
    local_code: "GPI",
    coordinates: "-114.25599670410156, 48.31050109863281",
  },
  {
    ident: "KGPT",
    type: "large_airport",
    name: "Gulfport Biloxi International Airport",
    elevation_ft: "28",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Gulfport",
    gps_code: "KGPT",
    iata_code: "GPT",
    local_code: "GPT",
    coordinates: "-89.07009887695312, 30.40730094909668",
  },
  {
    ident: "KGPZ",
    type: "small_airport",
    name: "Grand Rapids Itasca Co-Gordon Newstrom field",
    elevation_ft: "1355",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Grand Rapids",
    gps_code: "KGPZ",
    iata_code: "GPZ",
    local_code: "GPZ",
    coordinates: "-93.50980377, 47.21110153",
  },
  {
    ident: "KGQQ",
    type: "small_airport",
    name: "Galion Municipal Airport",
    elevation_ft: "1224",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Galion",
    gps_code: "KGQQ",
    iata_code: "GQQ",
    local_code: "GQQ",
    coordinates: "-82.7238006592, 40.7533988953",
  },
  {
    ident: "KGRB",
    type: "large_airport",
    name: "Austin Straubel International Airport",
    elevation_ft: "695",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Green Bay",
    gps_code: "KGRB",
    iata_code: "GRB",
    local_code: "GRB",
    coordinates: "-88.12960052490234, 44.48509979248047",
  },
  {
    ident: "KGRD",
    type: "small_airport",
    name: "Greenwood County Airport",
    elevation_ft: "631",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Greenwood",
    gps_code: "KGRD",
    iata_code: "GRD",
    local_code: "GRD",
    coordinates: "-82.15910339359999, 34.2486991882",
  },
  {
    ident: "KGRE",
    type: "small_airport",
    name: "Greenville Airport",
    elevation_ft: "541",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Greenville",
    gps_code: "KGRE",
    iata_code: "GRE",
    local_code: "GRE",
    coordinates: "-89.37840271, 38.8362007141",
  },
  {
    ident: "KGRF",
    type: "medium_airport",
    name: "Gray Army Air Field",
    elevation_ft: "300",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Fort Lewis/Tacoma",
    gps_code: "KGRF",
    iata_code: "GRF",
    local_code: "GRF",
    coordinates: "-122.5810013, 47.07920074",
  },
  {
    ident: "KGRI",
    type: "medium_airport",
    name: "Central Nebraska Regional Airport",
    elevation_ft: "1847",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Grand Island",
    gps_code: "KGRI",
    iata_code: "GRI",
    local_code: "GRI",
    coordinates: "-98.30960083007812, 40.967498779296875",
  },
  {
    ident: "KGRK",
    type: "medium_airport",
    name: "Robert Gray  Army Air Field Airport",
    elevation_ft: "1015",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Fort Hood/Killeen",
    gps_code: "KGRK",
    iata_code: "GRK",
    local_code: "GRK",
    coordinates: "-97.82890319820001, 31.067199707",
  },
  {
    ident: "KGRN",
    type: "small_airport",
    name: "Gordon Municipal Airport",
    elevation_ft: "3562",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Gordon",
    gps_code: "KGRN",
    iata_code: "GRN",
    local_code: "GRN",
    coordinates: "-102.175003052, 42.8059997559",
  },
  {
    ident: "KGRR",
    type: "medium_airport",
    name: "Gerald R. Ford International Airport",
    elevation_ft: "794",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Grand Rapids",
    gps_code: "KGRR",
    iata_code: "GRR",
    local_code: "GRR",
    coordinates: "-85.52279663, 42.88079834",
  },
  {
    ident: "KGSB",
    type: "large_airport",
    name: "Seymour Johnson Air Force Base",
    elevation_ft: "109",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Goldsboro",
    gps_code: "KGSB",
    iata_code: "GSB",
    local_code: "GSB",
    coordinates: "-77.96060181, 35.33940125",
  },
  {
    ident: "KGSH",
    type: "small_airport",
    name: "Goshen Municipal Airport",
    elevation_ft: "827",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Goshen",
    gps_code: "KGSH",
    iata_code: "GSH",
    local_code: "GSH",
    coordinates: "-85.79290008539999, 41.526401519800004",
  },
  {
    ident: "KGSO",
    type: "large_airport",
    name: "Piedmont Triad International Airport",
    elevation_ft: "925",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Greensboro",
    gps_code: "KGSO",
    iata_code: "GSO",
    local_code: "GSO",
    coordinates: "-79.93730163574219, 36.097801208496094",
  },
  {
    ident: "KGSP",
    type: "large_airport",
    name: "Greenville Spartanburg International Airport",
    elevation_ft: "964",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Greenville",
    gps_code: "KGSP",
    iata_code: "GSP",
    local_code: "GSP",
    coordinates: "-82.2189025879, 34.8956985474",
  },
  {
    ident: "KGSW",
    type: "closed",
    name: "Greater Southwest International Airport-Amon Carter Field",
    elevation_ft: "568",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Fort Worth",
    gps_code: "KGSW",
    iata_code: "GSW",
    local_code: "GSW",
    coordinates: "-97.04918861390001, 32.8308104547",
  },
  {
    ident: "KGTF",
    type: "medium_airport",
    name: "Great Falls International Airport",
    elevation_ft: "3680",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Great Falls",
    gps_code: "KGTF",
    iata_code: "GTF",
    local_code: "GTF",
    coordinates: "-111.3710022, 47.48199844",
  },
  {
    ident: "KGTG",
    type: "small_airport",
    name: "Grantsburg Municipal Airport",
    elevation_ft: "927",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Grantsburg",
    gps_code: "KGTG",
    iata_code: "GTG",
    local_code: "GTG",
    coordinates: "-92.6643981934, 45.7980995178",
  },
  {
    ident: "KGTR",
    type: "medium_airport",
    name: "Golden Triangle Regional Airport",
    elevation_ft: "264",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Columbus/W Point/Starkville",
    gps_code: "KGTR",
    iata_code: "GTR",
    local_code: "GTR",
    coordinates: "-88.5914001465, 33.450298309299995",
  },
  {
    ident: "KGUC",
    type: "small_airport",
    name: "Gunnison Crested Butte Regional Airport",
    elevation_ft: "7680",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Gunnison",
    gps_code: "KGUC",
    iata_code: "GUC",
    local_code: "GUC",
    coordinates: "-106.9329987, 38.53390121",
  },
  {
    ident: "KGUP",
    type: "medium_airport",
    name: "Gallup Municipal Airport",
    elevation_ft: "6472",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Gallup",
    gps_code: "KGUP",
    iata_code: "GUP",
    local_code: "GUP",
    coordinates: "-108.789001465, 35.511100769",
  },
  {
    ident: "KGUS",
    type: "large_airport",
    name: "Grissom Air Reserve Base",
    elevation_ft: "812",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Peru",
    gps_code: "KGUS",
    iata_code: "GUS",
    local_code: "GUS",
    coordinates: "-86.1520996094, 40.648101806599996",
  },
  {
    ident: "KGUY",
    type: "medium_airport",
    name: "Guymon Municipal Airport",
    elevation_ft: "3123",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Guymon",
    gps_code: "KGUY",
    iata_code: "GUY",
    local_code: "GUY",
    coordinates: "-101.508003235, 36.6851005554",
  },
  {
    ident: "KGVL",
    type: "small_airport",
    name: "Lee Gilmer Memorial Airport",
    elevation_ft: "1276",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Gainesville",
    gps_code: "KGVL",
    iata_code: "GVL",
    local_code: "GVL",
    coordinates: "-83.8302002, 34.27259827",
  },
  {
    ident: "KGVT",
    type: "small_airport",
    name: "Majors Airport",
    elevation_ft: "535",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Greenville",
    gps_code: "KGVT",
    iata_code: "GVT",
    local_code: "GVT",
    coordinates: "-96.0652999878, 33.0677986145",
  },
  {
    ident: "KGVW",
    type: "closed",
    name: "Richards-Gebaur Air Force Base",
    elevation_ft: "1093",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Belton",
    gps_code: "KGVW",
    iata_code: "GVW",
    coordinates: "-94.55999755859375, 38.844200134277344",
  },
  {
    ident: "KGW",
    type: "small_airport",
    name: "Kagi Airport",
    elevation_ft: "4200",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Kagi",
    gps_code: "AYKQ",
    iata_code: "KGW",
    local_code: "KAGI",
    coordinates: "147.669444444, -9.135916666670001",
  },
  {
    ident: "KGWO",
    type: "medium_airport",
    name: "Greenwood������Leflore Airport",
    elevation_ft: "162",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Greenwood",
    gps_code: "KGWO",
    iata_code: "GWO",
    local_code: "GWO",
    coordinates: "-90.0847015381, 33.4943008423",
  },
  {
    ident: "KGWS",
    type: "small_airport",
    name: "Glenwood Springs Municipal Airport",
    elevation_ft: "5916",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Glenwood Springs",
    gps_code: "KGWS",
    iata_code: "GWS",
    local_code: "GWS",
    coordinates: "-107.310997009, 39.5083007812",
  },
  {
    ident: "KGX",
    type: "small_airport",
    name: "Grayling Airport",
    elevation_ft: "99",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Grayling",
    gps_code: "PAGX",
    iata_code: "KGX",
    local_code: "KGX",
    coordinates: "-160.066289, 62.895187",
  },
  {
    ident: "KGXY",
    type: "small_airport",
    name: "Greeley������Weld County Airport",
    elevation_ft: "4697",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Greeley",
    gps_code: "KGXY",
    iata_code: "GXY",
    local_code: "GXY",
    coordinates: "-104.633003235, 40.4374008179",
  },
  {
    ident: "KGYH",
    type: "small_airport",
    name: "Donaldson Field Airport",
    elevation_ft: "955",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Greenville",
    gps_code: "KGYH",
    iata_code: "GDC",
    local_code: "GYH",
    coordinates: "-82.376404, 34.758301",
  },
  {
    ident: "KGYI",
    type: "small_airport",
    name: "North Texas Regional Airport/Perrin Field",
    elevation_ft: "749",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Sherman/Denison",
    gps_code: "KGYI",
    iata_code: "PNX",
    local_code: "GYI",
    coordinates: "-96.6736984253, 33.714099884",
  },
  {
    ident: "KGYR",
    type: "small_airport",
    name: "Phoenix Goodyear Airport",
    elevation_ft: "968",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Goodyear",
    gps_code: "KGYR",
    iata_code: "GYR",
    local_code: "GYR",
    coordinates: "-112.375999451, 33.4225006104",
  },
  {
    ident: "KGYY",
    type: "medium_airport",
    name: "Gary Chicago International Airport",
    elevation_ft: "591",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Gary",
    gps_code: "KGYY",
    iata_code: "GYY",
    local_code: "GYY",
    coordinates: "-87.41280364990234, 41.61629867553711",
  },
  {
    ident: "KGZ",
    type: "small_airport",
    name: "Glacier Creek Airport",
    elevation_ft: "2380",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Glacier Creek",
    gps_code: "KGZ",
    iata_code: "KGZ",
    local_code: "KGZ",
    coordinates: "-142.380996704, 61.4551010132",
  },
  {
    ident: "KHAB",
    type: "small_airport",
    name: "Marion County Rankin Fite Airport",
    elevation_ft: "436",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Hamilton",
    gps_code: "KHAB",
    iata_code: "HAB",
    local_code: "HAB",
    coordinates: "-87.99819946, 34.11759949",
  },
  {
    ident: "KHAF",
    type: "small_airport",
    name: "Half Moon Bay Airport",
    elevation_ft: "66",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Half Moon Bay",
    gps_code: "KHAF",
    iata_code: "HAF",
    local_code: "HAF",
    coordinates: "-122.500999451, 37.513401031499995",
  },
  {
    ident: "KHAI",
    type: "small_airport",
    name: "Three Rivers Municipal Dr Haines Airport",
    elevation_ft: "824",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Three Rivers",
    gps_code: "KHAI",
    iata_code: "HAI",
    local_code: "HAI",
    coordinates: "-85.59339904790001, 41.9598007202",
  },
  {
    ident: "KHAO",
    type: "small_airport",
    name: "Butler Co Regional Airport - Hogan Field",
    elevation_ft: "633",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Hamilton",
    gps_code: "KHAO",
    iata_code: "HAO",
    local_code: "HAO",
    coordinates: "-84.5220031738, 39.363800048799995",
  },
  {
    ident: "KHAX",
    type: "closed",
    name: "Hatbox Field",
    elevation_ft: "627",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Muskogee",
    gps_code: "KHAX",
    iata_code: "HAX",
    local_code: "HAX",
    coordinates: "-95.4128036499, 35.7458992004",
  },
  {
    ident: "KHBG",
    type: "medium_airport",
    name: "Hattiesburg Bobby L Chain Municipal Airport",
    elevation_ft: "151",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Hattiesburg",
    gps_code: "KHBG",
    iata_code: "HBG",
    local_code: "HBG",
    coordinates: "-89.25279999, 31.26479912",
  },
  {
    ident: "KHBR",
    type: "medium_airport",
    name: "Hobart Regional Airport",
    elevation_ft: "1563",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Hobart",
    gps_code: "KHBR",
    iata_code: "HBR",
    local_code: "HBR",
    coordinates: "-99.051313, 34.991317",
  },
  {
    ident: "KHDE",
    type: "small_airport",
    name: "Brewster Field",
    elevation_ft: "2313",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Holdrege",
    gps_code: "KHDE",
    iata_code: "HDE",
    local_code: "HDE",
    coordinates: "-99.336502, 40.452099",
  },
  {
    ident: "KHDN",
    type: "small_airport",
    name: "Yampa Valley Airport",
    elevation_ft: "6606",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Hayden",
    gps_code: "KHDN",
    iata_code: "HDN",
    local_code: "HDN",
    coordinates: "-107.2180023, 40.48120117",
  },
  {
    ident: "KHEE",
    type: "small_airport",
    name: "Thompson-Robbins Airport",
    elevation_ft: "242",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Helena/West Helena",
    gps_code: "KHEE",
    iata_code: "HEE",
    local_code: "HEE",
    coordinates: "-90.67616, 34.576571",
  },
  {
    ident: "KHEF",
    type: "small_airport",
    name: "Manassas Regional Airport/Harry P. Davis Field",
    elevation_ft: "192",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Manassas",
    gps_code: "KHEF",
    iata_code: "MNZ",
    local_code: "HEF",
    coordinates: "-77.51540375, 38.72140121",
  },
  {
    ident: "KHEZ",
    type: "small_airport",
    name: "Hardy-Anders Field / Natchez-Adams County Airport",
    elevation_ft: "272",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Natchez",
    gps_code: "KHEZ",
    iata_code: "HEZ",
    local_code: "HEZ",
    coordinates: "-91.297313, 31.613738",
  },
  {
    ident: "KHFD",
    type: "medium_airport",
    name: "Hartford Brainard Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CT",
    municipality: "Hartford",
    gps_code: "KHFD",
    iata_code: "HFD",
    local_code: "HFD",
    coordinates: "-72.649398803711, 41.736698150635",
  },
  {
    ident: "KHFF",
    type: "small_airport",
    name: "Mackall Army Air Field",
    elevation_ft: "376",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Camp Mackall",
    gps_code: "KHFF",
    iata_code: "HFF",
    local_code: "HFF",
    coordinates: "-79.497755, 35.036288",
  },
  {
    ident: "KHGR",
    type: "medium_airport",
    name: "Hagerstown Regional Richard A Henson Field",
    elevation_ft: "703",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Hagerstown",
    gps_code: "KHGR",
    iata_code: "HGR",
    local_code: "HGR",
    coordinates: "-77.72949982, 39.707901",
  },
  {
    ident: "KHHR",
    type: "medium_airport",
    name: "Jack Northrop Field Hawthorne Municipal Airport",
    elevation_ft: "66",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Hawthorne",
    gps_code: "KHHR",
    iata_code: "HHR",
    local_code: "HHR",
    coordinates: "-118.334999, 33.922798",
  },
  {
    ident: "KHHW",
    type: "small_airport",
    name: "Stan Stamper Municipal Airport",
    elevation_ft: "572",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Hugo",
    gps_code: "KHHW",
    iata_code: "HUJ",
    local_code: "HHW",
    coordinates: "-95.54190063, 34.03480148",
  },
  {
    ident: "KHIB",
    type: "large_airport",
    name: "Range Regional Airport",
    elevation_ft: "1354",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Hibbing",
    gps_code: "KHIB",
    iata_code: "HIB",
    local_code: "HIB",
    coordinates: "-92.83899689, 47.38660049",
  },
  {
    ident: "KHIE",
    type: "small_airport",
    name: "Mount Washington Regional Airport",
    elevation_ft: "1074",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Whitefield",
    gps_code: "KHIE",
    iata_code: "HIE",
    local_code: "HIE",
    coordinates: "-71.544502, 44.367637",
  },
  {
    ident: "KHIF",
    type: "medium_airport",
    name: "Hill Air Force Base",
    elevation_ft: "4789",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Ogden",
    gps_code: "KHIF",
    iata_code: "HIF",
    local_code: "HIF",
    coordinates: "-111.973086, 41.12403",
  },
  {
    ident: "KHII",
    type: "small_airport",
    name: "Lake Havasu City Airport",
    elevation_ft: "783",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Lake Havasu City",
    gps_code: "KHII",
    iata_code: "HII",
    local_code: "HII",
    coordinates: "-114.358002, 34.571098",
  },
  {
    ident: "KHIO",
    type: "medium_airport",
    name: "Portland Hillsboro Airport",
    elevation_ft: "208",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Portland",
    gps_code: "KHIO",
    iata_code: "HIO",
    local_code: "HIO",
    coordinates: "-122.949997, 45.540401",
  },
  {
    ident: "KHKA",
    type: "small_airport",
    name: "Blytheville Municipal Airport",
    elevation_ft: "255",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Blytheville",
    gps_code: "KHKA",
    iata_code: "HKA",
    local_code: "HKA",
    coordinates: "-89.83080291750001, 35.940399169900004",
  },
  {
    ident: "KHKS",
    type: "small_airport",
    name: "Hawkins Field",
    elevation_ft: "341",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Jackson",
    gps_code: "KHKS",
    iata_code: "HKS",
    local_code: "HKS",
    coordinates: "-90.22219849, 32.33449936",
  },
  {
    ident: "KHKY",
    type: "medium_airport",
    name: "Hickory Regional Airport",
    elevation_ft: "1190",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Hickory",
    gps_code: "KHKY",
    iata_code: "HKY",
    local_code: "HKY",
    coordinates: "-81.38950348, 35.74110031",
  },
  {
    ident: "KHL",
    type: "closed",
    name: "Khan Jahan Ali Airport",
    elevation_ft: "21",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-4",
    municipality: "Khulna",
    iata_code: "KHL",
    coordinates: "89.6454, 22.6486",
  },
  {
    ident: "KHLB",
    type: "small_airport",
    name: "Hillenbrand Industries Airport",
    elevation_ft: "973",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Batesville",
    gps_code: "KHLB",
    iata_code: "HLB",
    local_code: "HLB",
    coordinates: "-85.25830078119999, 39.344501495399996",
  },
  {
    ident: "KHLC",
    type: "small_airport",
    name: "Hill City Municipal Airport",
    elevation_ft: "2238",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Hill City",
    gps_code: "KHLC",
    iata_code: "HLC",
    local_code: "HLC",
    coordinates: "-99.83149719, 39.37879944",
  },
  {
    ident: "KHLG",
    type: "medium_airport",
    name: "Wheeling Ohio County Airport",
    elevation_ft: "1195",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Wheeling",
    gps_code: "KHLG",
    iata_code: "HLG",
    local_code: "HLG",
    coordinates: "-80.6463012695, 40.1749992371",
  },
  {
    ident: "KHLM",
    type: "small_airport",
    name: "Park Township Airport",
    elevation_ft: "603",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Holland",
    gps_code: "KHLM",
    iata_code: "HLM",
    local_code: "HLM",
    coordinates: "-86.1620025635, 42.7958984375",
  },
  {
    ident: "KHLN",
    type: "medium_airport",
    name: "Helena Regional Airport",
    elevation_ft: "3877",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Helena",
    gps_code: "KHLN",
    iata_code: "HLN",
    local_code: "HLN",
    coordinates: "-111.98300170898438, 46.6068000793457",
  },
  {
    ident: "KHLR",
    type: "medium_airport",
    name: "Hood Army Air Field",
    elevation_ft: "924",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Fort Hood(Killeen)",
    gps_code: "KHLR",
    iata_code: "HLR",
    local_code: "HLR",
    coordinates: "-97.71450042720001, 31.138700485199998",
  },
  {
    ident: "KHMN",
    type: "large_airport",
    name: "Holloman Air Force Base",
    elevation_ft: "4093",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Alamogordo",
    gps_code: "KHMN",
    iata_code: "HMN",
    local_code: "HMN",
    coordinates: "-106.107002258, 32.8525009155",
  },
  {
    ident: "KHMT",
    type: "small_airport",
    name: "Hemet Ryan Airport",
    elevation_ft: "1512",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Hemet",
    gps_code: "KHMT",
    iata_code: "HMT",
    local_code: "HMT",
    coordinates: "-117.023002625, 33.7340011597",
  },
  {
    ident: "KHNB",
    type: "small_airport",
    name: "Huntingburg Airport",
    elevation_ft: "529",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Huntingburg",
    gps_code: "KHNB",
    iata_code: "HNB",
    local_code: "HNB",
    coordinates: "-86.95369720459999, 38.2490005493",
  },
  {
    ident: "KHND",
    type: "small_airport",
    name: "Henderson Executive Airport",
    elevation_ft: "2492",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Las Vegas",
    gps_code: "KHND",
    iata_code: "HSH",
    local_code: "HND",
    coordinates: "-115.134002686, 35.9728012085",
  },
  {
    ident: "KHOB",
    type: "medium_airport",
    name: "Lea County Regional Airport",
    elevation_ft: "3661",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Hobbs",
    gps_code: "KHOB",
    iata_code: "HOB",
    local_code: "HOB",
    coordinates: "-103.2170029, 32.6875",
  },
  {
    ident: "KHON",
    type: "medium_airport",
    name: "Huron Regional Airport",
    elevation_ft: "1289",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Huron",
    gps_code: "KHON",
    iata_code: "HON",
    local_code: "HON",
    coordinates: "-98.22850036621094, 44.38520050048828",
  },
  {
    ident: "KHOP",
    type: "medium_airport",
    name: "Campbell AAF (Fort Campbell) Air Field",
    elevation_ft: "573",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Fort Campbell/Hopkinsville",
    gps_code: "KHOP",
    iata_code: "HOP",
    local_code: "HOP",
    coordinates: "-87.49620056150002, 36.668598175",
  },
  {
    ident: "KHOT",
    type: "medium_airport",
    name: "Memorial Field",
    elevation_ft: "540",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Hot Springs",
    gps_code: "KHOT",
    iata_code: "HOT",
    local_code: "HOT",
    coordinates: "-93.09619903564453, 34.47800064086914",
  },
  {
    ident: "KHOU",
    type: "large_airport",
    name: "William P Hobby Airport",
    elevation_ft: "46",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Houston",
    gps_code: "KHOU",
    iata_code: "HOU",
    local_code: "HOU",
    coordinates: "-95.27890015, 29.64539909",
  },
  {
    ident: "KHPN",
    type: "medium_airport",
    name: "Westchester County Airport",
    elevation_ft: "439",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "White Plains",
    gps_code: "KHPN",
    iata_code: "HPN",
    local_code: "HPN",
    coordinates: "-73.70760345458984, 41.06700134277344",
  },
  {
    ident: "KHPT",
    type: "small_airport",
    name: "Hampton Municipal Airport",
    elevation_ft: "1176",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Hampton",
    gps_code: "KHPT",
    iata_code: "HPT",
    local_code: "HPT",
    coordinates: "-93.22630310059999, 42.7237014771",
  },
  {
    ident: "KHPY",
    type: "small_airport",
    name: "Baytown Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Baytown",
    gps_code: "KHPY",
    iata_code: "HPY",
    local_code: "HPY",
    coordinates: "-94.95269775390001, 29.786100387599998",
  },
  {
    ident: "KHQM",
    type: "medium_airport",
    name: "Bowerman Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Hoquiam",
    gps_code: "KHQM",
    iata_code: "HQM",
    local_code: "HQM",
    coordinates: "-123.93699646, 46.971199035599994",
  },
  {
    ident: "KHRI",
    type: "small_airport",
    name: "Hermiston Municipal Airport",
    elevation_ft: "644",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Hermiston",
    gps_code: "KHRI",
    iata_code: "HES",
    local_code: "HRI",
    coordinates: "-119.259024, 45.828223",
  },
  {
    ident: "KHRL",
    type: "medium_airport",
    name: "Valley International Airport",
    elevation_ft: "36",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Harlingen",
    gps_code: "KHRL",
    iata_code: "HRL",
    local_code: "HRL",
    coordinates: "-97.65440368652344, 26.228500366210938",
  },
  {
    ident: "KHRO",
    type: "medium_airport",
    name: "Boone County Airport",
    elevation_ft: "1365",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Harrison",
    gps_code: "KHRO",
    iata_code: "HRO",
    local_code: "HRO",
    coordinates: "-93.15470123291016, 36.26150131225586",
  },
  {
    ident: "KHSB",
    type: "small_airport",
    name: "Harrisburg-Raleigh Airport",
    elevation_ft: "398",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Harrisburg",
    gps_code: "KHSB",
    iata_code: "HSB",
    local_code: "HSB",
    coordinates: "-88.5503006, 37.81129837",
  },
  {
    ident: "KHSE",
    type: "small_airport",
    name: "Billy Mitchell Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Hatteras",
    gps_code: "KHSE",
    iata_code: "HNC",
    local_code: "HSE",
    coordinates: "-75.617797851562, 35.232799530029",
  },
  {
    ident: "KHSG",
    type: "small_airport",
    name: "Hot Springs County Airport",
    elevation_ft: "4895",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Thermopolis",
    gps_code: "KHSG",
    iata_code: "THP",
    local_code: "HSG",
    coordinates: "-108.389687, 43.713602",
  },
  {
    ident: "KHSI",
    type: "small_airport",
    name: "Hastings Municipal Airport",
    elevation_ft: "1961",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Hastings",
    gps_code: "KHSI",
    iata_code: "HSI",
    local_code: "HSI",
    coordinates: "-98.42790222170001, 40.6053009033",
  },
  {
    ident: "KHSP",
    type: "small_airport",
    name: "Ingalls Field",
    elevation_ft: "3793",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Hot Springs",
    gps_code: "KHSP",
    iata_code: "HSP",
    local_code: "HSP",
    coordinates: "-79.83390045, 37.95140076",
  },
  {
    ident: "KHST",
    type: "medium_airport",
    name: "Homestead ARB Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Homestead",
    gps_code: "KHST",
    iata_code: "HST",
    local_code: "HST",
    coordinates: "-80.38359833, 25.48859978",
  },
  {
    ident: "KHSV",
    type: "large_airport",
    name: "Huntsville International Carl T Jones Field",
    elevation_ft: "629",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Huntsville",
    gps_code: "KHSV",
    iata_code: "HSV",
    local_code: "HSV",
    coordinates: "-86.775100708008, 34.637199401855",
  },
  {
    ident: "KHTH",
    type: "small_airport",
    name: "Hawthorne Industrial Airport",
    elevation_ft: "4215",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Hawthorne",
    gps_code: "KHTH",
    iata_code: "HTH",
    local_code: "HTH",
    coordinates: "-118.634002, 38.544399",
  },
  {
    ident: "KHTL",
    type: "small_airport",
    name: "Roscommon County - Blodgett Memorial Airport",
    elevation_ft: "1150",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Houghton Lake",
    gps_code: "KHTL",
    iata_code: "HTL",
    local_code: "HTL",
    coordinates: "-84.671095, 44.359798",
  },
  {
    ident: "KHTO",
    type: "small_airport",
    name: "East Hampton Airport",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "East Hampton",
    gps_code: "KHTO",
    iata_code: "HTO",
    local_code: "HTO",
    coordinates: "-72.25180054, 40.95959854",
  },
  {
    ident: "KHTS",
    type: "large_airport",
    name: "Tri-State/Milton J. Ferguson Field",
    elevation_ft: "828",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Huntington",
    gps_code: "KHTS",
    iata_code: "HTS",
    local_code: "HTS",
    coordinates: "-82.55799866, 38.36669922",
  },
  {
    ident: "KHTW",
    type: "small_airport",
    name: "Lawrence County Airpark",
    elevation_ft: "568",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Chesapeake/Huntington Wva",
    gps_code: "KHTW",
    iata_code: "HTW",
    local_code: "HTW",
    coordinates: "-82.494301, 38.4193",
  },
  {
    ident: "KHUA",
    type: "medium_airport",
    name: "Redstone Army Air Field",
    elevation_ft: "684",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Redstone Arsnl Huntsville",
    gps_code: "KHUA",
    iata_code: "HUA",
    local_code: "HUA",
    coordinates: "-86.68479919, 34.67869949",
  },
  {
    ident: "KHUF",
    type: "medium_airport",
    name: "Terre Haute Regional Airport, Hulman Field",
    elevation_ft: "589",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Terre Haute",
    gps_code: "KHUF",
    iata_code: "HUF",
    local_code: "HUF",
    coordinates: "-87.307602, 39.4515",
  },
  {
    ident: "KHUL",
    type: "medium_airport",
    name: "Houlton International Airport",
    elevation_ft: "489",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Houlton",
    gps_code: "KHUL",
    iata_code: "HUL",
    local_code: "HUL",
    coordinates: "-67.792098999, 46.1231002808",
  },
  {
    ident: "KHUM",
    type: "small_airport",
    name: "Houma Terrebonne Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Houma",
    gps_code: "KHUM",
    iata_code: "HUM",
    local_code: "HUM",
    coordinates: "-90.6604003906, 29.5664997101",
  },
  {
    ident: "KHUT",
    type: "medium_airport",
    name: "Hutchinson Municipal Airport",
    elevation_ft: "1543",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Hutchinson",
    gps_code: "KHUT",
    iata_code: "HUT",
    local_code: "HUT",
    coordinates: "-97.86060333250002, 38.0654983521",
  },
  {
    ident: "KHVE",
    type: "small_airport",
    name: "Hanksville Airport",
    elevation_ft: "4444",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Hanksville",
    gps_code: "KHVE",
    iata_code: "HVE",
    local_code: "HVE",
    coordinates: "-110.70400238, 38.417999267599996",
  },
  {
    ident: "KHVN",
    type: "medium_airport",
    name: "Tweed New Haven Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CT",
    municipality: "New Haven",
    gps_code: "KHVN",
    iata_code: "HVN",
    local_code: "HVN",
    coordinates: "-72.88680267, 41.26369858",
  },
  {
    ident: "KHVR",
    type: "medium_airport",
    name: "Havre City County Airport",
    elevation_ft: "2591",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Havre",
    gps_code: "KHVR",
    iata_code: "HVR",
    local_code: "HVR",
    coordinates: "-109.762001, 48.54299927",
  },
  {
    ident: "KHVS",
    type: "small_airport",
    name: "Hartsville Regional Airport",
    elevation_ft: "364",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Hartsville",
    gps_code: "KHVS",
    iata_code: "HVS",
    local_code: "HVS",
    coordinates: "-80.11920166019999, 34.4030990601",
  },
  {
    ident: "KHWD",
    type: "small_airport",
    name: "Hayward Executive Airport",
    elevation_ft: "52",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Hayward",
    gps_code: "KHWD",
    iata_code: "HWD",
    local_code: "HWD",
    coordinates: "-122.122001648, 37.659198761",
  },
  {
    ident: "KHWO",
    type: "small_airport",
    name: "North Perry Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Hollywood",
    gps_code: "KHWO",
    iata_code: "HWO",
    local_code: "HWO",
    coordinates: "-80.2407, 26.0012",
  },
  {
    ident: "KHWV",
    type: "small_airport",
    name: "Brookhaven Airport",
    elevation_ft: "81",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Shirley",
    gps_code: "KHWV",
    iata_code: "WSH",
    local_code: "HWV",
    coordinates: "-72.86940002440001, 40.8218994141",
  },
  {
    ident: "KHXD",
    type: "small_airport",
    name: "Hilton Head Airport",
    elevation_ft: "19",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Hilton Head Island",
    gps_code: "KHXD",
    iata_code: "HHH",
    local_code: "HXD",
    coordinates: "-80.6975021362, 32.2243995667",
  },
  {
    ident: "KHYA",
    type: "medium_airport",
    name: "Barnstable Municipal Boardman Polando Field",
    elevation_ft: "54",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Hyannis",
    gps_code: "KHYA",
    iata_code: "HYA",
    local_code: "HYA",
    coordinates: "-70.28040314, 41.66930008",
  },
  {
    ident: "KHYR",
    type: "medium_airport",
    name: "Sawyer County Airport",
    elevation_ft: "1216",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Hayward",
    gps_code: "KHYR",
    iata_code: "HYR",
    local_code: "HYR",
    coordinates: "-91.44429779050002, 46.025199890100005",
  },
  {
    ident: "KHYS",
    type: "medium_airport",
    name: "Hays Regional Airport",
    elevation_ft: "1999",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Hays",
    gps_code: "KHYS",
    iata_code: "HYS",
    local_code: "HYS",
    coordinates: "-99.27320099, 38.84220123",
  },
  {
    ident: "KHZL",
    type: "small_airport",
    name: "Hazleton Municipal Airport",
    elevation_ft: "1603",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Hazleton",
    gps_code: "KHZL",
    iata_code: "HZL",
    local_code: "HZL",
    coordinates: "-75.9949035645, 40.986801147499996",
  },
  {
    ident: "KHZY",
    type: "small_airport",
    name: "Northeast Ohio Regional Airport",
    elevation_ft: "924",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Ashtabula",
    gps_code: "KHZY",
    iata_code: "JFN",
    local_code: "HZY",
    coordinates: "-80.6955032349, 41.7779998779",
  },
  {
    ident: "KIA",
    type: "closed",
    name: "Kaiapit Airport",
    elevation_ft: "1030",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Kaiapit",
    iata_code: "KIA",
    coordinates: "146.27, -6.275",
  },
  {
    ident: "KIAB",
    type: "medium_airport",
    name: "Mc Connell Air Force Base",
    elevation_ft: "1371",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Wichita",
    gps_code: "KIAB",
    iata_code: "IAB",
    local_code: "IAB",
    coordinates: "-97.26820374, 37.62189865",
  },
  {
    ident: "KIAD",
    type: "large_airport",
    name: "Washington Dulles International Airport",
    elevation_ft: "312",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-DC",
    municipality: "Washington",
    gps_code: "KIAD",
    iata_code: "IAD",
    local_code: "IAD",
    coordinates: "-77.45580292, 38.94449997",
  },
  {
    ident: "KIAG",
    type: "medium_airport",
    name: "Niagara Falls International Airport",
    elevation_ft: "589",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Niagara Falls",
    gps_code: "KIAG",
    iata_code: "IAG",
    local_code: "IAG",
    coordinates: "-78.94619750976562, 43.1072998046875",
  },
  {
    ident: "KIAH",
    type: "large_airport",
    name: "George Bush Intercontinental Houston Airport",
    elevation_ft: "97",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Houston",
    gps_code: "KIAH",
    iata_code: "IAH",
    local_code: "IAH",
    coordinates: "-95.34140014648438, 29.984399795532227",
  },
  {
    ident: "KIB",
    type: "seaplane_base",
    name: "Ivanof Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ivanof Bay",
    gps_code: "KIB",
    iata_code: "KIB",
    local_code: "KIB",
    coordinates: "-159.488998413, 55.8974990845",
  },
  {
    ident: "KICL",
    type: "small_airport",
    name: "Schenck Field",
    elevation_ft: "996",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Clarinda",
    gps_code: "KICL",
    iata_code: "ICL",
    local_code: "ICL",
    coordinates: "-95.02639771, 40.72180176",
  },
  {
    ident: "KICT",
    type: "large_airport",
    name: "Wichita Eisenhower National Airport",
    elevation_ft: "1333",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Wichita",
    gps_code: "KICT",
    iata_code: "ICT",
    local_code: "ICT",
    coordinates: "-97.433098, 37.649899",
  },
  {
    ident: "KIDA",
    type: "medium_airport",
    name: "Idaho Falls Regional Airport",
    elevation_ft: "4744",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Idaho Falls",
    gps_code: "KIDA",
    iata_code: "IDA",
    local_code: "IDA",
    coordinates: "-112.070999, 43.514599",
  },
  {
    ident: "KIDI",
    type: "small_airport",
    name: "Indiana County/Jimmy Stewart Fld/ Airport",
    elevation_ft: "1405",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Indiana",
    gps_code: "KIDI",
    iata_code: "IDI",
    local_code: "IDI",
    coordinates: "-79.10549927, 40.63219833",
  },
  {
    ident: "KIDP",
    type: "small_airport",
    name: "Independence Municipal Airport",
    elevation_ft: "825",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Independence",
    gps_code: "KIDP",
    iata_code: "IDP",
    local_code: "IDP",
    coordinates: "-95.77839660640001, 37.1584014893",
  },
  {
    ident: "KIEN",
    type: "small_airport",
    name: "Pine Ridge Airport",
    elevation_ft: "3333",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Pine Ridge",
    gps_code: "KIEN",
    iata_code: "XPR",
    local_code: "IEN",
    coordinates: "-102.511001587, 43.0224990845",
  },
  {
    ident: "KIFA",
    type: "small_airport",
    name: "Iowa Falls Municipal Airport",
    elevation_ft: "1137",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Iowa Falls",
    gps_code: "KIFA",
    iata_code: "IFA",
    local_code: "IFA",
    coordinates: "-93.2699966431, 42.4707984924",
  },
  {
    ident: "KIFP",
    type: "small_airport",
    name: "Laughlin Bullhead International Airport",
    elevation_ft: "701",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Bullhead City",
    gps_code: "KIFP",
    iata_code: "IFP",
    local_code: "IFP",
    coordinates: "-114.5599976, 35.15739822",
  },
  {
    ident: "KIGM",
    type: "small_airport",
    name: "Kingman Airport",
    elevation_ft: "3449",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Kingman",
    gps_code: "KIGM",
    iata_code: "IGM",
    local_code: "IGM",
    coordinates: "-113.93800354003906, 35.259498596191406",
  },
  {
    ident: "KIKK",
    type: "medium_airport",
    name: "Greater Kankakee Airport",
    elevation_ft: "630",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Kankakee",
    gps_code: "KIKK",
    iata_code: "IKK",
    local_code: "IKK",
    coordinates: "-87.8462982178, 41.07139968869999",
  },
  {
    ident: "KIL",
    type: "small_airport",
    name: "Kilwa Airport",
    elevation_ft: "3120",
    continent: "AF",
    iso_country: "CD",
    iso_region: "CD-KA",
    municipality: "Kilwa",
    iata_code: "KIL",
    coordinates: "28.3269, -9.2886",
  },
  {
    ident: "KILE",
    type: "small_airport",
    name: "Skylark Field",
    elevation_ft: "848",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Killeen",
    gps_code: "KILE",
    iata_code: "ILE",
    local_code: "ILE",
    coordinates: "-97.6865005493, 31.0858001709",
  },
  {
    ident: "KILG",
    type: "medium_airport",
    name: "New Castle Airport",
    elevation_ft: "80",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-DE",
    municipality: "Wilmington",
    gps_code: "KILG",
    iata_code: "ILG",
    local_code: "ILG",
    coordinates: "-75.60649872, 39.67869949",
  },
  {
    ident: "KILM",
    type: "medium_airport",
    name: "Wilmington International Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Wilmington",
    gps_code: "KILM",
    iata_code: "ILM",
    local_code: "ILM",
    coordinates: "-77.90260314941406, 34.270599365234375",
  },
  {
    ident: "KILN",
    type: "medium_airport",
    name: "Wilmington Airpark",
    elevation_ft: "1077",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Wilmington",
    gps_code: "KILN",
    iata_code: "ILN",
    local_code: "ILN",
    coordinates: "-83.792098999, 39.427898407",
  },
  {
    ident: "KIML",
    type: "small_airport",
    name: "Imperial Municipal Airport",
    elevation_ft: "3275",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Imperial",
    gps_code: "KIML",
    iata_code: "IML",
    local_code: "IML",
    coordinates: "-101.6210022, 40.50930023",
  },
  {
    ident: "KIMM",
    type: "small_airport",
    name: "Immokalee Regional Airport",
    elevation_ft: "37",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Immokalee",
    gps_code: "KIMM",
    iata_code: "IMM",
    local_code: "IMM",
    coordinates: "-81.40100098, 26.43320084",
  },
  {
    ident: "KIMS",
    type: "small_airport",
    name: "Madison Municipal Airport",
    elevation_ft: "819",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Madison",
    gps_code: "KIMS",
    iata_code: "MDN",
    local_code: "IMS",
    coordinates: "-85.46549988, 38.75889969",
  },
  {
    ident: "KIMT",
    type: "small_airport",
    name: "Ford Airport",
    elevation_ft: "1182",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Iron Mountain / Kingsford",
    gps_code: "KIMT",
    iata_code: "IMT",
    local_code: "IMT",
    coordinates: "-88.1145019531, 45.8184013367",
  },
  {
    ident: "KIND",
    type: "large_airport",
    name: "Indianapolis International Airport",
    elevation_ft: "797",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Indianapolis",
    gps_code: "KIND",
    iata_code: "IND",
    local_code: "IND",
    coordinates: "-86.294403, 39.7173",
  },
  {
    ident: "KINK",
    type: "medium_airport",
    name: "Winkler County Airport",
    elevation_ft: "2822",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Wink",
    gps_code: "KINK",
    iata_code: "INK",
    local_code: "INK",
    coordinates: "-103.200996399, 31.779600143399996",
  },
  {
    ident: "KINL",
    type: "medium_airport",
    name: "Falls International Airport",
    elevation_ft: "1185",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "International Falls",
    gps_code: "KINL",
    iata_code: "INL",
    local_code: "INL",
    coordinates: "-93.4030990600586, 48.566200256347656",
  },
  {
    ident: "KINS",
    type: "small_airport",
    name: "Creech Air Force Base",
    elevation_ft: "3133",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Indian Springs",
    gps_code: "KINS",
    iata_code: "INS",
    local_code: "INS",
    coordinates: "-115.672996521, 36.587200164799995",
  },
  {
    ident: "KINT",
    type: "medium_airport",
    name: "Smith Reynolds Airport",
    elevation_ft: "969",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Winston Salem",
    gps_code: "KINT",
    iata_code: "INT",
    local_code: "INT",
    coordinates: "-80.22200012207031, 36.13370132446289",
  },
  {
    ident: "KINW",
    type: "medium_airport",
    name: "Winslow Lindbergh Regional Airport",
    elevation_ft: "4941",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Winslow",
    gps_code: "KINW",
    iata_code: "INW",
    local_code: "INW",
    coordinates: "-110.722999573, 35.021900177",
  },
  {
    ident: "KIOW",
    type: "small_airport",
    name: "Iowa City Municipal Airport",
    elevation_ft: "668",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Iowa City",
    gps_code: "KIOW",
    iata_code: "IOW",
    local_code: "IOW",
    coordinates: "-91.5465011597, 41.639198303200004",
  },
  {
    ident: "KIPL",
    type: "medium_airport",
    name: "Imperial County Airport",
    elevation_ft: "-54",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Imperial",
    gps_code: "KIPL",
    iata_code: "IPL",
    local_code: "IPL",
    coordinates: "-115.57900238, 32.834201812699995",
  },
  {
    ident: "KIPT",
    type: "medium_airport",
    name: "Williamsport Regional Airport",
    elevation_ft: "529",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Williamsport",
    gps_code: "KIPT",
    iata_code: "IPT",
    local_code: "IPT",
    coordinates: "-76.92109680175781, 41.241798400878906",
  },
  {
    ident: "KIQ",
    type: "small_airport",
    name: "Kira Airport",
    elevation_ft: "1700",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Kira",
    gps_code: "AYRA",
    iata_code: "KIQ",
    local_code: "KIRA",
    coordinates: "147.332027778, -8.065111111110001",
  },
  {
    ident: "KIRK",
    type: "medium_airport",
    name: "Kirksville Regional Airport",
    elevation_ft: "966",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Kirksville",
    gps_code: "KIRK",
    iata_code: "IRK",
    local_code: "IRK",
    coordinates: "-92.5448989868164, 40.09349822998047",
  },
  {
    ident: "KIRS",
    type: "small_airport",
    name: "Kirsch Municipal Airport",
    elevation_ft: "924",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Sturgis",
    gps_code: "KIRS",
    iata_code: "IRS",
    local_code: "IRS",
    coordinates: "-85.43900299, 41.81330109",
  },
  {
    ident: "KISM",
    type: "medium_airport",
    name: "Kissimmee Gateway Airport",
    elevation_ft: "82",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Orlando",
    gps_code: "KISM",
    iata_code: "ISM",
    local_code: "ISM",
    coordinates: "-81.4371032715, 28.2898006439",
  },
  {
    ident: "KISN",
    type: "medium_airport",
    name: "Sloulin Field International Airport",
    elevation_ft: "1982",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Williston",
    gps_code: "KISN",
    iata_code: "ISN",
    local_code: "ISN",
    coordinates: "-103.641998291, 48.177898407",
  },
  {
    ident: "KISO",
    type: "medium_airport",
    name: "Kinston Regional Jetport At Stallings Field",
    elevation_ft: "93",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Kinston",
    gps_code: "KISO",
    iata_code: "ISO",
    local_code: "ISO",
    coordinates: "-77.60880279540001, 35.331401825",
  },
  {
    ident: "KISP",
    type: "medium_airport",
    name: "Long Island Mac Arthur Airport",
    elevation_ft: "99",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Islip",
    gps_code: "KISP",
    iata_code: "ISP",
    local_code: "ISP",
    coordinates: "-73.10019684, 40.79520035",
  },
  {
    ident: "KISQ",
    type: "small_airport",
    name: "Schoolcraft County Airport",
    elevation_ft: "684",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Manistique",
    gps_code: "KISQ",
    iata_code: "ISQ",
    local_code: "ISQ",
    coordinates: "-86.17179871, 45.97460175",
  },
  {
    ident: "KISW",
    type: "small_airport",
    name: "Alexander Field South Wood County Airport",
    elevation_ft: "1021",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Wisconsin Rapids",
    gps_code: "KISW",
    iata_code: "ISW",
    local_code: "ISW",
    coordinates: "-89.83899688720001, 44.3602981567",
  },
  {
    ident: "KITH",
    type: "medium_airport",
    name: "Ithaca Tompkins Regional Airport",
    elevation_ft: "1099",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Ithaca",
    gps_code: "KITH",
    iata_code: "ITH",
    local_code: "ITH",
    coordinates: "-76.4583969116211, 42.49100112915039",
  },
  {
    ident: "KIWA",
    type: "medium_airport",
    name: "Phoenix-Mesa-Gateway Airport",
    elevation_ft: "1382",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Phoenix",
    gps_code: "KIWA",
    iata_code: "AZA",
    local_code: "IWA",
    coordinates: "-111.6549988, 33.30780029",
  },
  {
    ident: "KIWD",
    type: "small_airport",
    name: "Gogebic Iron County Airport",
    elevation_ft: "1230",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Ironwood",
    gps_code: "KIWD",
    iata_code: "IWD",
    local_code: "IWD",
    coordinates: "-90.131401062, 46.527500152600005",
  },
  {
    ident: "KIWI",
    type: "small_airport",
    name: "Wiscasset Airport",
    elevation_ft: "70",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Wiscasset",
    gps_code: "KIWI",
    iata_code: "ISS",
    local_code: "IWI",
    coordinates: "-69.712600708, 43.9613990784",
  },
  {
    ident: "KIWS",
    type: "small_airport",
    name: "West Houston Airport",
    elevation_ft: "111",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Houston",
    gps_code: "KIWS",
    iata_code: "IWS",
    local_code: "IWS",
    coordinates: "-95.67259979250001, 29.818199157699997",
  },
  {
    ident: "KIXD",
    type: "small_airport",
    name: "New Century Aircenter Airport",
    elevation_ft: "1087",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Olathe",
    gps_code: "KIXD",
    iata_code: "JCI",
    local_code: "IXD",
    coordinates: "-94.890296936, 38.8308982849",
  },
  {
    ident: "KIYK",
    type: "small_airport",
    name: "Inyokern Airport",
    elevation_ft: "2457",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Inyokern",
    gps_code: "KIYK",
    iata_code: "IYK",
    local_code: "IYK",
    coordinates: "-117.8300018, 35.65879822",
  },
  {
    ident: "KIZ",
    type: "closed",
    name: "Kikinonda Airport",
    elevation_ft: "95",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Kikinonda",
    iata_code: "KIZ",
    coordinates: "147.9309, -8.5284",
  },
  {
    ident: "KIZA",
    type: "small_airport",
    name: "Santa Ynez Airport",
    elevation_ft: "674",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Santa Ynez",
    gps_code: "KIZA",
    iata_code: "SQA",
    local_code: "IZA",
    coordinates: "-120.0759964, 34.60680008",
  },
  {
    ident: "KIZG",
    type: "small_airport",
    name: "Eastern Slopes Regional Airport",
    elevation_ft: "454",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Fryeburg",
    gps_code: "KIZG",
    iata_code: "FRY",
    local_code: "IZG",
    coordinates: "-70.9478988647, 43.991100311299995",
  },
  {
    ident: "KJAC",
    type: "medium_airport",
    name: "Jackson Hole Airport",
    elevation_ft: "6451",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Jackson",
    gps_code: "KJAC",
    iata_code: "JAC",
    local_code: "JAC",
    coordinates: "-110.73799896240234, 43.6072998046875",
  },
  {
    ident: "KJAN",
    type: "large_airport",
    name: "Jackson-Medgar Wiley Evers International Airport",
    elevation_ft: "346",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Jackson",
    gps_code: "KJAN",
    iata_code: "JAN",
    local_code: "JAN",
    coordinates: "-90.0758972168, 32.3111991882",
  },
  {
    ident: "KJAS",
    type: "small_airport",
    name: "Jasper County Airport-Bell Field",
    elevation_ft: "213",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Jasper",
    gps_code: "KJAS",
    iata_code: "JAS",
    local_code: "JAS",
    coordinates: "-94.03489685, 30.88570023",
  },
  {
    ident: "KJAX",
    type: "large_airport",
    name: "Jacksonville International Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Jacksonville",
    gps_code: "KJAX",
    iata_code: "JAX",
    local_code: "JAX",
    coordinates: "-81.68789672851562, 30.49410057067871",
  },
  {
    ident: "KJBR",
    type: "medium_airport",
    name: "Jonesboro Municipal Airport",
    elevation_ft: "262",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Jonesboro",
    gps_code: "KJBR",
    iata_code: "JBR",
    local_code: "JBR",
    coordinates: "-90.64640045166016, 35.83169937133789",
  },
  {
    ident: "KJCT",
    type: "medium_airport",
    name: "Kimble County Airport",
    elevation_ft: "1749",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Junction",
    gps_code: "KJCT",
    iata_code: "JCT",
    local_code: "JCT",
    coordinates: "-99.7634963989, 30.5112991333",
  },
  {
    ident: "KJDN",
    type: "small_airport",
    name: "Jordan Airport",
    elevation_ft: "2662",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Jordan",
    gps_code: "KJDN",
    iata_code: "JDN",
    local_code: "JDN",
    coordinates: "-106.95300293, 47.3288002014",
  },
  {
    ident: "KJEF",
    type: "small_airport",
    name: "Jefferson City Memorial Airport",
    elevation_ft: "549",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Jefferson City",
    gps_code: "KJEF",
    iata_code: "JEF",
    local_code: "JEF",
    coordinates: "-92.15609741210001, 38.5912017822",
  },
  {
    ident: "KJFK",
    type: "large_airport",
    name: "John F Kennedy International Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "New York",
    gps_code: "KJFK",
    iata_code: "JFK",
    local_code: "JFK",
    coordinates: "-73.7789, 40.639801",
  },
  {
    ident: "KJHW",
    type: "medium_airport",
    name: "Chautauqua County-Jamestown Airport",
    elevation_ft: "1723",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Jamestown",
    gps_code: "KJHW",
    iata_code: "JHW",
    local_code: "JHW",
    coordinates: "-79.25800323, 42.15340042",
  },
  {
    ident: "KJKA",
    type: "small_airport",
    name: "Jack Edwards Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Gulf Shores",
    gps_code: "KJKA",
    iata_code: "GUF",
    local_code: "JKA",
    coordinates: "-87.67179871, 30.29050064",
  },
  {
    ident: "KJLN",
    type: "large_airport",
    name: "Joplin Regional Airport",
    elevation_ft: "981",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Joplin",
    gps_code: "KJLN",
    iata_code: "JLN",
    local_code: "JLN",
    coordinates: "-94.49829864501953, 37.151798248291016",
  },
  {
    ident: "KJMS",
    type: "medium_airport",
    name: "Jamestown Regional Airport",
    elevation_ft: "1500",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Jamestown",
    gps_code: "KJMS",
    iata_code: "JMS",
    local_code: "JMS",
    coordinates: "-98.67819977, 46.92969894",
  },
  {
    ident: "KJOT",
    type: "small_airport",
    name: "Joliet Regional Airport",
    elevation_ft: "582",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Joliet",
    gps_code: "KJOT",
    iata_code: "JOT",
    local_code: "JOT",
    coordinates: "-88.17549896, 41.51779938",
  },
  {
    ident: "KJQF",
    type: "small_airport",
    name: "Concord-Padgett Regional Airport",
    elevation_ft: "705",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Concord",
    gps_code: "KJQF",
    iata_code: "USA",
    local_code: "JQF",
    coordinates: "-80.709099, 35.387798",
  },
  {
    ident: "KJSO",
    type: "small_airport",
    name: "Cherokee County Airport",
    elevation_ft: "677",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Jacksonville",
    gps_code: "KJSO",
    iata_code: "JKV",
    local_code: "JSO",
    coordinates: "-95.2173995972, 31.8693008423",
  },
  {
    ident: "KJST",
    type: "medium_airport",
    name: "John Murtha Johnstown Cambria County Airport",
    elevation_ft: "2284",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Johnstown",
    gps_code: "KJST",
    iata_code: "JST",
    local_code: "JST",
    coordinates: "-78.83390045166016, 40.31610107421875",
  },
  {
    ident: "KJU",
    type: "closed",
    name: "Kamiraba Airport",
    elevation_ft: "40",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Kamiraba",
    iata_code: "KJU",
    coordinates: "151.9077, -3.1995",
  },
  {
    ident: "KJVL",
    type: "small_airport",
    name: "Southern Wisconsin Regional Airport",
    elevation_ft: "808",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Janesville",
    gps_code: "KJVL",
    iata_code: "JVL",
    local_code: "JVL",
    coordinates: "-89.0416030884, 42.620300293",
  },
  {
    ident: "KJXN",
    type: "medium_airport",
    name: "Jackson County Reynolds Field",
    elevation_ft: "1001",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Jackson",
    gps_code: "KJXN",
    iata_code: "JXN",
    local_code: "JXN",
    coordinates: "-84.45939636230001, 42.259799957300004",
  },
  {
    ident: "KKB",
    type: "seaplane_base",
    name: "Kitoi Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kitoi Bay",
    gps_code: "KKB",
    iata_code: "KKB",
    local_code: "KKB",
    coordinates: "-152.369995117, 58.1908988953",
  },
  {
    ident: "KKIC",
    type: "small_airport",
    name: "Mesa Del Rey Airport",
    elevation_ft: "370",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "King City",
    gps_code: "KKIC",
    iata_code: "KIC",
    local_code: "KIC",
    coordinates: "-121.122001648, 36.2280006409",
  },
  {
    ident: "KKL",
    type: "seaplane_base",
    name: "Karluk Lake Seaplane Base",
    elevation_ft: "368",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Karluk Lake",
    gps_code: "KKL",
    iata_code: "KKL",
    local_code: "KKL",
    coordinates: "-154.027999878, 57.3670005798",
  },
  {
    ident: "KKLS",
    type: "medium_airport",
    name: "Southwest Washington Regional Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Kelso",
    gps_code: "KKLS",
    iata_code: "KLS",
    local_code: "KLS",
    coordinates: "-122.898002625, 46.11800003049999",
  },
  {
    ident: "KKU",
    type: "small_airport",
    name: "Ekuk Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ekuk",
    gps_code: "KKU",
    iata_code: "KKU",
    local_code: "KKU",
    coordinates: "-158.559005737, 58.8111991882",
  },
  {
    ident: "KL06",
    type: "small_airport",
    name: "Furnace Creek Airport",
    elevation_ft: "-210",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Death Valley National Park",
    iata_code: "DTH",
    local_code: "L06",
    coordinates: "-116.880997, 36.463799",
  },
  {
    ident: "KL08",
    type: "small_airport",
    name: "Borrego Valley Airport",
    elevation_ft: "520",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Borrego Springs",
    gps_code: "L08",
    iata_code: "BXS",
    local_code: "L08",
    coordinates: "-116.320999146, 33.2589988708",
  },
  {
    ident: "KL35",
    type: "small_airport",
    name: "Big Bear City Airport",
    elevation_ft: "6752",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Big Bear",
    gps_code: "L35",
    iata_code: "RBF",
    local_code: "L35",
    coordinates: "-116.856002808, 34.2638015747",
  },
  {
    ident: "KL72",
    type: "small_airport",
    name: "Trona Airport",
    elevation_ft: "1716",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Trona",
    gps_code: "L72",
    iata_code: "TRH",
    local_code: "L72",
    coordinates: "-117.327003479, 35.8125",
  },
  {
    ident: "KLAA",
    type: "small_airport",
    name: "Southeast Colorado Regional Airport",
    elevation_ft: "3706",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Lamar",
    gps_code: "KLAA",
    iata_code: "LAA",
    local_code: "LAA",
    coordinates: "-102.683201, 38.064852",
  },
  {
    ident: "KLAF",
    type: "medium_airport",
    name: "Purdue University Airport",
    elevation_ft: "606",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Lafayette",
    gps_code: "KLAF",
    iata_code: "LAF",
    local_code: "LAF",
    coordinates: "-86.93689727783203, 40.41230010986328",
  },
  {
    ident: "KLAL",
    type: "medium_airport",
    name: "Lakeland Linder International Airport",
    elevation_ft: "142",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Lakeland",
    gps_code: "KLAL",
    iata_code: "LAL",
    local_code: "LAL",
    coordinates: "-82.018602, 27.988899",
  },
  {
    ident: "KLAM",
    type: "small_airport",
    name: "Los Alamos Airport",
    elevation_ft: "7171",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Los Alamos",
    gps_code: "KLAM",
    iata_code: "LAM",
    local_code: "LAM",
    coordinates: "-106.268997192, 35.8797988892",
  },
  {
    ident: "KLAN",
    type: "medium_airport",
    name: "Capital City Airport",
    elevation_ft: "861",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Lansing",
    gps_code: "KLAN",
    iata_code: "LAN",
    local_code: "LAN",
    coordinates: "-84.58740234375, 42.77870178222656",
  },
  {
    ident: "KLAR",
    type: "medium_airport",
    name: "Laramie Regional Airport",
    elevation_ft: "7284",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Laramie",
    gps_code: "KLAR",
    iata_code: "LAR",
    local_code: "LAR",
    coordinates: "-105.67500305175781, 41.31209945678711",
  },
  {
    ident: "KLAS",
    type: "large_airport",
    name: "McCarran International Airport",
    elevation_ft: "2181",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Las Vegas",
    gps_code: "KLAS",
    iata_code: "LAS",
    local_code: "LAS",
    coordinates: "-115.1520004, 36.08010101",
  },
  {
    ident: "KLAW",
    type: "medium_airport",
    name: "Lawton Fort Sill Regional Airport",
    elevation_ft: "1110",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Lawton",
    gps_code: "KLAW",
    iata_code: "LAW",
    local_code: "LAW",
    coordinates: "-98.4166030884, 34.5676994324",
  },
  {
    ident: "KLAX",
    type: "large_airport",
    name: "Los Angeles International Airport",
    elevation_ft: "125",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Los Angeles",
    gps_code: "KLAX",
    iata_code: "LAX",
    local_code: "LAX",
    coordinates: "-118.407997, 33.942501",
  },
  {
    ident: "KLBB",
    type: "large_airport",
    name: "Lubbock Preston Smith International Airport",
    elevation_ft: "3282",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Lubbock",
    gps_code: "KLBB",
    iata_code: "LBB",
    local_code: "LBB",
    coordinates: "-101.822998, 33.663601",
  },
  {
    ident: "KLBE",
    type: "medium_airport",
    name: "Arnold Palmer Regional Airport",
    elevation_ft: "1199",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Latrobe",
    gps_code: "KLBE",
    iata_code: "LBE",
    local_code: "LBE",
    coordinates: "-79.40480042, 40.27590179",
  },
  {
    ident: "KLBF",
    type: "medium_airport",
    name: "North Platte Regional Airport Lee Bird Field",
    elevation_ft: "2777",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "North Platte",
    gps_code: "KLBF",
    iata_code: "LBF",
    local_code: "LBF",
    coordinates: "-100.6839981, 41.12620163",
  },
  {
    ident: "KLBL",
    type: "medium_airport",
    name: "Liberal Mid-America Regional Airport",
    elevation_ft: "2885",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Liberal",
    gps_code: "KLBL",
    iata_code: "LBL",
    local_code: "LBL",
    coordinates: "-100.9599991, 37.0442009",
  },
  {
    ident: "KLBT",
    type: "medium_airport",
    name: "Lumberton Regional Airport",
    elevation_ft: "126",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Lumberton",
    gps_code: "KLBT",
    iata_code: "LBT",
    local_code: "LBT",
    coordinates: "-79.05940246579999, 34.6099014282",
  },
  {
    ident: "KLBX",
    type: "medium_airport",
    name: "Texas Gulf Coast Regional Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Angleton/Lake Jackson",
    gps_code: "KLBX",
    iata_code: "LJN",
    local_code: "LBX",
    coordinates: "-95.462097168, 29.1086006165",
  },
  {
    ident: "KLCH",
    type: "medium_airport",
    name: "Lake Charles Regional Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Lake Charles",
    gps_code: "KLCH",
    iata_code: "LCH",
    local_code: "LCH",
    coordinates: "-93.22329711914062, 30.126100540161133",
  },
  {
    ident: "KLCI",
    type: "small_airport",
    name: "Laconia Municipal Airport",
    elevation_ft: "545",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Laconia",
    gps_code: "KLCI",
    iata_code: "LCI",
    local_code: "LCI",
    coordinates: "-71.4188995361, 43.5727005005",
  },
  {
    ident: "KLCK",
    type: "large_airport",
    name: "Rickenbacker International Airport",
    elevation_ft: "744",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Columbus",
    gps_code: "KLCK",
    iata_code: "LCK",
    local_code: "LCK",
    coordinates: "-82.927803, 39.813801",
  },
  {
    ident: "KLCQ",
    type: "small_airport",
    name: "Lake City Gateway Airport",
    elevation_ft: "201",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Lake City",
    gps_code: "KLCQ",
    iata_code: "LCQ",
    local_code: "LCQ",
    coordinates: "-82.57689666750001, 30.1819992065",
  },
  {
    ident: "KLDJ",
    type: "small_airport",
    name: "Linden Airport",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Linden",
    gps_code: "KLDJ",
    iata_code: "LDJ",
    local_code: "LDJ",
    coordinates: "-74.2445983887, 40.617401123",
  },
  {
    ident: "KLDM",
    type: "small_airport",
    name: "Mason County Airport",
    elevation_ft: "646",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Ludington",
    gps_code: "KLDM",
    iata_code: "LDM",
    local_code: "LDM",
    coordinates: "-86.40789795, 43.96250153",
  },
  {
    ident: "KLEB",
    type: "medium_airport",
    name: "Lebanon Municipal Airport",
    elevation_ft: "603",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Lebanon",
    gps_code: "KLEB",
    iata_code: "LEB",
    local_code: "LEB",
    coordinates: "-72.30419921880001, 43.626098632799994",
  },
  {
    ident: "KLEE",
    type: "medium_airport",
    name: "Leesburg International Airport",
    elevation_ft: "76",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Leesburg",
    gps_code: "KLEE",
    iata_code: "LEE",
    local_code: "LEE",
    coordinates: "-81.80870056, 28.82309914",
  },
  {
    ident: "KLEM",
    type: "small_airport",
    name: "Lemmon Municipal Airport",
    elevation_ft: "2571",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Lemmon",
    gps_code: "KLEM",
    iata_code: "LEM",
    local_code: "LEM",
    coordinates: "-102.106002808, 45.9187011719",
  },
  {
    ident: "KLEW",
    type: "small_airport",
    name: "Auburn Lewiston Municipal Airport",
    elevation_ft: "288",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Auburn/Lewiston",
    gps_code: "KLEW",
    iata_code: "LEW",
    local_code: "LEW",
    coordinates: "-70.2835006714, 44.048500061",
  },
  {
    ident: "KLEX",
    type: "large_airport",
    name: "Blue Grass Airport",
    elevation_ft: "979",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Lexington",
    gps_code: "KLEX",
    iata_code: "LEX",
    local_code: "LEX",
    coordinates: "-84.60590362548828, 38.0364990234375",
  },
  {
    ident: "KLFI",
    type: "large_airport",
    name: "Langley Air Force Base",
    elevation_ft: "11",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Hampton",
    gps_code: "KLFI",
    iata_code: "LFI",
    local_code: "LFI",
    coordinates: "-76.360496521, 37.082901001",
  },
  {
    ident: "KLFK",
    type: "medium_airport",
    name: "Angelina County Airport",
    elevation_ft: "296",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Lufkin",
    gps_code: "KLFK",
    iata_code: "LFK",
    local_code: "LFK",
    coordinates: "-94.75, 31.2339992523",
  },
  {
    ident: "KLFT",
    type: "large_airport",
    name: "Lafayette Regional Airport",
    elevation_ft: "42",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Lafayette",
    gps_code: "KLFT",
    iata_code: "LFT",
    local_code: "LFT",
    coordinates: "-91.98760223, 30.20529938",
  },
  {
    ident: "KLGA",
    type: "large_airport",
    name: "La Guardia Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "New York",
    gps_code: "KLGA",
    iata_code: "LGA",
    local_code: "LGA",
    coordinates: "-73.872597, 40.777199",
  },
  {
    ident: "KLGB",
    type: "medium_airport",
    name: "Long Beach /Daugherty Field/ Airport",
    elevation_ft: "60",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Long Beach",
    gps_code: "KLGB",
    iata_code: "LGB",
    local_code: "LGB",
    coordinates: "-118.1520004, 33.81769943",
  },
  {
    ident: "KLGC",
    type: "small_airport",
    name: "LaGrange Callaway Airport",
    elevation_ft: "693",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Lagrange",
    gps_code: "KLGC",
    iata_code: "LGC",
    local_code: "LGC",
    coordinates: "-85.072601, 33.0089",
  },
  {
    ident: "KLGD",
    type: "small_airport",
    name: "La Grande/Union County Airport",
    elevation_ft: "2717",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "La Grande",
    gps_code: "KLGD",
    iata_code: "LGD",
    local_code: "LGD",
    coordinates: "-118.007003784, 45.2901992798",
  },
  {
    ident: "KLGF",
    type: "small_airport",
    name: "Laguna Army Airfield",
    elevation_ft: "433",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Yuma Proving Ground(Yuma)",
    gps_code: "KLGF",
    iata_code: "LGF",
    local_code: "LGF",
    coordinates: "-114.3970032, 32.86000061",
  },
  {
    ident: "KLGU",
    type: "medium_airport",
    name: "Logan-Cache Airport",
    elevation_ft: "4457",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Logan",
    gps_code: "KLGU",
    iata_code: "LGU",
    local_code: "LGU",
    coordinates: "-111.851997375, 41.7911987305",
  },
  {
    ident: "KLHC",
    type: "closed",
    name: "Arlington Municipal Airport",
    elevation_ft: "308",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Arlington",
    gps_code: "KLHC",
    iata_code: "LHC",
    coordinates: "-89.67250061035156, 35.28310012817383",
  },
  {
    ident: "KLHV",
    type: "small_airport",
    name: "William T. Piper Memorial Airport",
    elevation_ft: "556",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Lock Haven",
    gps_code: "KLHV",
    iata_code: "LHV",
    local_code: "LHV",
    coordinates: "-77.42230225, 41.13560104",
  },
  {
    ident: "KLHW",
    type: "small_airport",
    name: "Wright AAF (Fort Stewart)/Midcoast Regional Airport",
    elevation_ft: "45",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Fort Stewart(Hinesville)",
    gps_code: "KLHW",
    iata_code: "LIY",
    local_code: "LHW",
    coordinates: "-81.562303, 31.889099",
  },
  {
    ident: "KLHZ",
    type: "small_airport",
    name: "Triangle North Executive Airport",
    elevation_ft: "369",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Louisburg",
    gps_code: "KLHZ",
    iata_code: "LFN",
    local_code: "LHZ",
    coordinates: "-78.330299, 36.0233",
  },
  {
    ident: "KLIC",
    type: "small_airport",
    name: "Limon Municipal Airport",
    elevation_ft: "5374",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Limon",
    gps_code: "KLIC",
    iata_code: "LIC",
    local_code: "LIC",
    coordinates: "-103.666000366, 39.274799346900004",
  },
  {
    ident: "KLIT",
    type: "large_airport",
    name: "Bill & Hillary Clinton National Airport/Adams Field",
    elevation_ft: "262",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Little Rock",
    gps_code: "KLIT",
    iata_code: "LIT",
    local_code: "LIT",
    coordinates: "-92.2242965698, 34.729400634799994",
  },
  {
    ident: "KLKP",
    type: "small_airport",
    name: "Lake Placid Airport",
    elevation_ft: "1747",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Lake Placid",
    gps_code: "KLKP",
    iata_code: "LKP",
    local_code: "LKP",
    coordinates: "-73.96189880370001, 44.2644996643",
  },
  {
    ident: "KLKU",
    type: "small_airport",
    name: "Louisa County Airport/Freeman Field",
    elevation_ft: "493",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Louisa",
    gps_code: "KLKU",
    iata_code: "LOW",
    local_code: "LKU",
    coordinates: "-77.9701004, 38.00979996",
  },
  {
    ident: "KLKV",
    type: "small_airport",
    name: "Lake County Airport",
    elevation_ft: "4733",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Lakeview",
    gps_code: "KLKV",
    iata_code: "LKV",
    local_code: "LKV",
    coordinates: "-120.399002075, 42.161098480199996",
  },
  {
    ident: "KLLJ",
    type: "small_airport",
    name: "Challis Airport",
    elevation_ft: "5072",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Challis",
    gps_code: "KLLJ",
    iata_code: "CHL",
    local_code: "LLJ",
    coordinates: "-114.218002, 44.522999",
  },
  {
    ident: "KLMS",
    type: "small_airport",
    name: "Louisville Winston County Airport",
    elevation_ft: "575",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Louisville",
    gps_code: "KLMS",
    iata_code: "LMS",
    local_code: "LMS",
    coordinates: "-89.0625, 33.1461982727",
  },
  {
    ident: "KLMT",
    type: "medium_airport",
    name: "Crater Lake-Klamath Regional Airport",
    elevation_ft: "4095",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Klamath Falls",
    gps_code: "KLMT",
    iata_code: "LMT",
    local_code: "LMT",
    coordinates: "-121.733002, 42.156101",
  },
  {
    ident: "KLNA",
    type: "small_airport",
    name: "Palm Beach County Park Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "West Palm Beach",
    gps_code: "KLNA",
    iata_code: "LNA",
    local_code: "LNA",
    coordinates: "-80.08509827, 26.59300041",
  },
  {
    ident: "KLND",
    type: "medium_airport",
    name: "Hunt Field",
    elevation_ft: "5586",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Lander",
    gps_code: "KLND",
    iata_code: "LND",
    local_code: "LND",
    coordinates: "-108.730003357, 42.8152008057",
  },
  {
    ident: "KLNK",
    type: "medium_airport",
    name: "Lincoln Airport",
    elevation_ft: "1219",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Lincoln",
    gps_code: "KLNK",
    iata_code: "LNK",
    local_code: "LNK",
    coordinates: "-96.75920104980469, 40.85100173950195",
  },
  {
    ident: "KLNN",
    type: "small_airport",
    name: "Lake County Executive Airport",
    elevation_ft: "626",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Willoughby",
    gps_code: "KLNN",
    iata_code: "LNN",
    local_code: "LNN",
    coordinates: "-81.389702, 41.683998",
  },
  {
    ident: "KLNP",
    type: "small_airport",
    name: "Lonesome Pine Airport",
    elevation_ft: "2684",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Wise",
    gps_code: "KLNP",
    iata_code: "LNP",
    local_code: "LNP",
    coordinates: "-82.5299987793, 36.9874992371",
  },
  {
    ident: "KLNR",
    type: "small_airport",
    name: "Tri-County Regional Airport",
    elevation_ft: "717",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Lone Rock",
    gps_code: "KLNR",
    iata_code: "LNR",
    local_code: "LNR",
    coordinates: "-90.181602478, 43.2117004395",
  },
  {
    ident: "KLNS",
    type: "medium_airport",
    name: "Lancaster Airport",
    elevation_ft: "403",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Lancaster",
    gps_code: "KLNS",
    iata_code: "LNS",
    local_code: "LNS",
    coordinates: "-76.29609680175781, 40.121700286865234",
  },
  {
    ident: "KLOL",
    type: "medium_airport",
    name: "Derby Field",
    elevation_ft: "3904",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Lovelock",
    gps_code: "KLOL",
    iata_code: "LOL",
    local_code: "LOL",
    coordinates: "-118.565002441, 40.0663986206",
  },
  {
    ident: "KLOM",
    type: "small_airport",
    name: "Wings Field",
    elevation_ft: "302",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Philadelphia",
    gps_code: "KLOM",
    iata_code: "BBX",
    local_code: "LOM",
    coordinates: "-75.2650985718, 40.1375007629",
  },
  {
    ident: "KLOT",
    type: "small_airport",
    name: "Lewis University Airport",
    elevation_ft: "679",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Chicago/Romeoville",
    gps_code: "KLOT",
    iata_code: "LOT",
    local_code: "LOT",
    coordinates: "-88.09619904, 41.6072998",
  },
  {
    ident: "KLOU",
    type: "medium_airport",
    name: "Bowman Field",
    elevation_ft: "546",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Louisville",
    gps_code: "KLOU",
    iata_code: "LOU",
    local_code: "LOU",
    coordinates: "-85.6636962891, 38.2280006409",
  },
  {
    ident: "KLOZ",
    type: "medium_airport",
    name: "London-Corbin Airport/Magee Field",
    elevation_ft: "1212",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "London",
    gps_code: "KLOZ",
    iata_code: "LOZ",
    local_code: "LOZ",
    coordinates: "-84.08489990230001, 37.0821990967",
  },
  {
    ident: "KLPC",
    type: "small_airport",
    name: "Lompoc Airport",
    elevation_ft: "88",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Lompoc",
    gps_code: "KLPC",
    iata_code: "LPC",
    local_code: "LPC",
    coordinates: "-120.468002319, 34.665599823",
  },
  {
    ident: "KLQK",
    type: "small_airport",
    name: "Pickens County Airport",
    elevation_ft: "1013",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Pickens",
    gps_code: "KLQK",
    iata_code: "LQK",
    local_code: "LQK",
    coordinates: "-82.70290374759999, 34.8100013733",
  },
  {
    ident: "KLRD",
    type: "medium_airport",
    name: "Laredo International Airport",
    elevation_ft: "508",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Laredo",
    gps_code: "KLRD",
    iata_code: "LRD",
    local_code: "LRD",
    coordinates: "-99.46160125732422, 27.543800354003906",
  },
  {
    ident: "KLRF",
    type: "medium_airport",
    name: "Little Rock Air Force Base",
    elevation_ft: "311",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Jacksonville",
    gps_code: "KLRF",
    iata_code: "LRF",
    local_code: "LRF",
    coordinates: "-92.14969635010002, 34.916900634799994",
  },
  {
    ident: "KLRJ",
    type: "small_airport",
    name: "Le Mars Municipal Airport",
    elevation_ft: "1197",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Le Mars",
    gps_code: "KLRJ",
    iata_code: "LRJ",
    local_code: "LRJ",
    coordinates: "-96.1937027, 42.77799988",
  },
  {
    ident: "KLRU",
    type: "medium_airport",
    name: "Las Cruces International Airport",
    elevation_ft: "4456",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Las Cruces",
    gps_code: "KLRU",
    iata_code: "LRU",
    local_code: "LRU",
    coordinates: "-106.9219970703125, 32.289398193359375",
  },
  {
    ident: "KLSB",
    type: "small_airport",
    name: "Lordsburg Municipal Airport",
    elevation_ft: "4289",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Lordsburg",
    gps_code: "KLSB",
    iata_code: "LSB",
    local_code: "LSB",
    coordinates: "-108.692001343, 32.3334999084",
  },
  {
    ident: "KLSE",
    type: "medium_airport",
    name: "La Crosse Municipal Airport",
    elevation_ft: "655",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "La Crosse",
    gps_code: "KLSE",
    iata_code: "LSE",
    local_code: "LSE",
    coordinates: "-91.256699, 43.879002",
  },
  {
    ident: "KLSF",
    type: "medium_airport",
    name: "Lawson Army Air Field (Fort Benning)",
    elevation_ft: "232",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Fort Benning(Columbus)",
    gps_code: "KLSF",
    iata_code: "LSF",
    local_code: "LSF",
    coordinates: "-84.9913024902, 32.337299346900004",
  },
  {
    ident: "KLSK",
    type: "small_airport",
    name: "Lusk Municipal Airport",
    elevation_ft: "4964",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Lusk",
    gps_code: "KLSK",
    iata_code: "LSK",
    local_code: "LSK",
    coordinates: "-104.404998779, 42.753799438499996",
  },
  {
    ident: "KLSN",
    type: "small_airport",
    name: "Los Banos Municipal Airport",
    elevation_ft: "121",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Los Banos",
    gps_code: "KLSN",
    iata_code: "LSN",
    local_code: "LSN",
    coordinates: "-120.8690033, 37.06290054",
  },
  {
    ident: "KLSV",
    type: "medium_airport",
    name: "Nellis Air Force Base",
    elevation_ft: "1870",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Las Vegas",
    gps_code: "KLSV",
    iata_code: "LSV",
    local_code: "LSV",
    coordinates: "-115.033996582, 36.2361984253",
  },
  {
    ident: "KLTS",
    type: "large_airport",
    name: "Altus Air Force Base",
    elevation_ft: "1382",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Altus",
    gps_code: "KLTS",
    iata_code: "LTS",
    local_code: "LTS",
    coordinates: "-99.2667007446, 34.667098999",
  },
  {
    ident: "KLUF",
    type: "large_airport",
    name: "Luke Air Force Base",
    elevation_ft: "1085",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Glendale",
    gps_code: "KLUF",
    iata_code: "LUF",
    local_code: "LUF",
    coordinates: "-112.383003235, 33.534999847399995",
  },
  {
    ident: "KLUK",
    type: "medium_airport",
    name: "Cincinnati Municipal Airport Lunken Field",
    elevation_ft: "483",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Cincinnati",
    gps_code: "KLUK",
    iata_code: "LUK",
    local_code: "LUK",
    coordinates: "-84.41860199, 39.10329819",
  },
  {
    ident: "KLUL",
    type: "small_airport",
    name: "Hesler Noble Field",
    elevation_ft: "238",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Laurel",
    gps_code: "KLUL",
    iata_code: "LUL",
    local_code: "LUL",
    coordinates: "-89.172203064, 31.672599792499998",
  },
  {
    ident: "KLVK",
    type: "small_airport",
    name: "Livermore Municipal Airport",
    elevation_ft: "400",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Livermore",
    gps_code: "KLVK",
    iata_code: "LVK",
    local_code: "LVK",
    coordinates: "-121.819999695, 37.6934013367",
  },
  {
    ident: "KLVL",
    type: "small_airport",
    name: "Brunswick Municipal Airport",
    elevation_ft: "329",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Lawrenceville",
    gps_code: "KLVL",
    iata_code: "LVL",
    local_code: "LVL",
    coordinates: "-77.794296, 36.7728",
  },
  {
    ident: "KLVM",
    type: "medium_airport",
    name: "Mission Field",
    elevation_ft: "4660",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Livingston",
    gps_code: "KLVM",
    iata_code: "LVM",
    local_code: "LVM",
    coordinates: "-110.447998047, 45.6994018555",
  },
  {
    ident: "KLVS",
    type: "medium_airport",
    name: "Las Vegas Municipal Airport",
    elevation_ft: "6877",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Las Vegas",
    gps_code: "KLVS",
    iata_code: "LVS",
    local_code: "LVS",
    coordinates: "-105.141998291, 35.6542015076",
  },
  {
    ident: "KLWB",
    type: "medium_airport",
    name: "Greenbrier Valley Airport",
    elevation_ft: "2302",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Lewisburg",
    gps_code: "KLWB",
    iata_code: "LWB",
    local_code: "LWB",
    coordinates: "-80.3994979858, 37.8582992554",
  },
  {
    ident: "KLWC",
    type: "small_airport",
    name: "Lawrence Municipal Airport",
    elevation_ft: "833",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Lawrence",
    gps_code: "KLWC",
    iata_code: "LWC",
    local_code: "LWC",
    coordinates: "-95.21659851, 39.01119995",
  },
  {
    ident: "KLWL",
    type: "small_airport",
    name: "Wells Municipal Airport/Harriet Field",
    elevation_ft: "5772",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Wells",
    gps_code: "KLWL",
    iata_code: "LWL",
    local_code: "LWL",
    coordinates: "-114.92199707, 41.117099762",
  },
  {
    ident: "KLWM",
    type: "medium_airport",
    name: "Lawrence Municipal Airport",
    elevation_ft: "148",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Lawrence",
    gps_code: "KLWM",
    iata_code: "LWM",
    local_code: "LWM",
    coordinates: "-71.1233978271, 42.717201232899995",
  },
  {
    ident: "KLWS",
    type: "medium_airport",
    name: "Lewiston Nez Perce County Airport",
    elevation_ft: "1442",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Lewiston",
    gps_code: "KLWS",
    iata_code: "LWS",
    local_code: "LWS",
    coordinates: "-117.01499938964844, 46.3745002746582",
  },
  {
    ident: "KLWT",
    type: "medium_airport",
    name: "Lewistown Municipal Airport",
    elevation_ft: "4170",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Lewistown",
    gps_code: "KLWT",
    iata_code: "LWT",
    local_code: "LWT",
    coordinates: "-109.46700286865234, 47.04930114746094",
  },
  {
    ident: "KLWV",
    type: "small_airport",
    name: "Lawrenceville Vincennes International Airport",
    elevation_ft: "430",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Lawrenceville",
    gps_code: "KLWV",
    iata_code: "LWV",
    local_code: "LWV",
    coordinates: "-87.6054992676, 38.7643013",
  },
  {
    ident: "KLXN",
    type: "small_airport",
    name: "Jim Kelly Field",
    elevation_ft: "2413",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Lexington",
    gps_code: "KLXN",
    iata_code: "LXN",
    local_code: "LXN",
    coordinates: "-99.7772979736, 40.791000366199995",
  },
  {
    ident: "KLXV",
    type: "small_airport",
    name: "Lake County Airport",
    elevation_ft: "9927",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Leadville",
    gps_code: "KLXV",
    iata_code: "LXV",
    local_code: "LXV",
    coordinates: "-106.317001343, 39.220298767100005",
  },
  {
    ident: "KLYH",
    type: "medium_airport",
    name: "Lynchburg Regional Preston Glenn Field",
    elevation_ft: "938",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Lynchburg",
    gps_code: "KLYH",
    iata_code: "LYH",
    local_code: "LYH",
    coordinates: "-79.20040130615234, 37.326698303222656",
  },
  {
    ident: "KLYO",
    type: "small_airport",
    name: "Lyons-Rice County Municipal Airport",
    elevation_ft: "1691",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Lyons",
    gps_code: "KLYO",
    iata_code: "LYO",
    local_code: "LYO",
    coordinates: "-98.22689819, 38.34280014",
  },
  {
    ident: "KLZU",
    type: "small_airport",
    name: "Gwinnett County Briscoe Field",
    elevation_ft: "1061",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Lawrenceville",
    gps_code: "KLZU",
    iata_code: "LZU",
    local_code: "LZU",
    coordinates: "-83.96240234, 33.97809982",
  },
  {
    ident: "KM13",
    type: "small_airport",
    name: "Poplarville Pearl River County Airport",
    elevation_ft: "320",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Poplarville",
    gps_code: "M13",
    iata_code: "PCU",
    local_code: "M13",
    coordinates: "-89.504501342773, 30.785999298096",
  },
  {
    ident: "KM75",
    type: "small_airport",
    name: "Malta Airport",
    elevation_ft: "2254",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Malta",
    gps_code: "M75",
    iata_code: "MLK",
    local_code: "M75",
    coordinates: "-107.918998718, 48.366901397700005",
  },
  {
    ident: "KMAC",
    type: "small_airport",
    name: "Macon Downtown Airport",
    elevation_ft: "437",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Macon",
    gps_code: "KMAC",
    iata_code: "MAC",
    local_code: "MAC",
    coordinates: "-83.56199646, 32.82210159",
  },
  {
    ident: "KMAE",
    type: "small_airport",
    name: "Madera Municipal Airport",
    elevation_ft: "255",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Madera",
    gps_code: "KMAE",
    iata_code: "MAE",
    local_code: "MAE",
    coordinates: "-120.111999512, 36.9886016846",
  },
  {
    ident: "KMAF",
    type: "medium_airport",
    name: "Midland International Airport",
    elevation_ft: "2871",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Midland",
    gps_code: "KMAF",
    iata_code: "MAF",
    local_code: "MAF",
    coordinates: "-102.2020034790039, 31.9424991607666",
  },
  {
    ident: "KMAW",
    type: "small_airport",
    name: "Malden Regional Airport",
    elevation_ft: "294",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Malden",
    gps_code: "KMAW",
    iata_code: "MAW",
    local_code: "MAW",
    coordinates: "-89.99220276, 36.6006012",
  },
  {
    ident: "KMB",
    type: "small_airport",
    name: "Koinambe Airport",
    elevation_ft: "3000",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-JI",
    municipality: "Konambe",
    gps_code: "AYON",
    iata_code: "KMB",
    local_code: "KOE",
    coordinates: "144.606944444, -5.4875",
  },
  {
    ident: "KMBG",
    type: "medium_airport",
    name: "Mobridge Municipal Airport",
    elevation_ft: "1716",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Mobridge",
    gps_code: "KMBG",
    iata_code: "MBG",
    local_code: "MBG",
    coordinates: "-100.4079971, 45.54650116",
  },
  {
    ident: "KMBL",
    type: "small_airport",
    name: "Manistee Co Blacker Airport",
    elevation_ft: "621",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Manistee",
    gps_code: "KMBL",
    iata_code: "MBL",
    local_code: "MBL",
    coordinates: "-86.24690247, 44.2723999",
  },
  {
    ident: "KMBO",
    type: "small_airport",
    name: "Bruce Campbell Field",
    elevation_ft: "326",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Madison",
    gps_code: "KMBO",
    iata_code: "DXE",
    local_code: "MBO",
    coordinates: "-90.103104, 32.438702",
  },
  {
    ident: "KMBS",
    type: "large_airport",
    name: "MBS International Airport",
    elevation_ft: "668",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Saginaw",
    gps_code: "KMBS",
    iata_code: "MBS",
    local_code: "MBS",
    coordinates: "-84.07959747314453, 43.532901763916016",
  },
  {
    ident: "KMBY",
    type: "small_airport",
    name: "Omar N Bradley Airport",
    elevation_ft: "867",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Moberly",
    gps_code: "KMBY",
    iata_code: "MBY",
    local_code: "MBY",
    coordinates: "-92.4270019531, 39.463901519800004",
  },
  {
    ident: "KMCB",
    type: "medium_airport",
    name: "Mc Comb/Pike County Airport/John E Lewis Field",
    elevation_ft: "413",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Mc Comb",
    gps_code: "KMCB",
    iata_code: "MCB",
    local_code: "MCB",
    coordinates: "-90.47190094, 31.17849922",
  },
  {
    ident: "KMCC",
    type: "medium_airport",
    name: "Mc Clellan Airfield",
    elevation_ft: "77",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Sacramento",
    gps_code: "KMCC",
    iata_code: "MCC",
    local_code: "MCC",
    coordinates: "-121.401001, 38.66759872",
  },
  {
    ident: "KMCD",
    type: "small_airport",
    name: "Mackinac Island Airport",
    elevation_ft: "739",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Mackinac Island",
    gps_code: "KMCD",
    iata_code: "MCD",
    local_code: "MCD",
    coordinates: "-84.63729858, 45.86489868",
  },
  {
    ident: "KMCE",
    type: "medium_airport",
    name: "Merced Regional Macready Field",
    elevation_ft: "155",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Merced",
    gps_code: "KMCE",
    iata_code: "MCE",
    local_code: "MCE",
    coordinates: "-120.5139999, 37.28469849",
  },
  {
    ident: "KMCF",
    type: "large_airport",
    name: "Mac Dill Air Force Base",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Tampa",
    gps_code: "KMCF",
    iata_code: "MCF",
    local_code: "MCF",
    coordinates: "-82.52120209, 27.84930038",
  },
  {
    ident: "KMCI",
    type: "large_airport",
    name: "Kansas City International Airport",
    elevation_ft: "1026",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Kansas City",
    gps_code: "KMCI",
    iata_code: "MCI",
    local_code: "MCI",
    coordinates: "-94.713898, 39.2976",
  },
  {
    ident: "KMCK",
    type: "medium_airport",
    name: "Mc Cook Ben Nelson Regional Airport",
    elevation_ft: "2583",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Mc Cook",
    gps_code: "KMCK",
    iata_code: "MCK",
    local_code: "MCK",
    coordinates: "-100.5920029, 40.20629883",
  },
  {
    ident: "KMCN",
    type: "medium_airport",
    name: "Middle Georgia Regional Airport",
    elevation_ft: "354",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Macon",
    gps_code: "KMCN",
    iata_code: "MCN",
    local_code: "MCN",
    coordinates: "-83.64920043945312, 32.69279861450195",
  },
  {
    ident: "KMCO",
    type: "large_airport",
    name: "Orlando International Airport",
    elevation_ft: "96",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Orlando",
    gps_code: "KMCO",
    iata_code: "MCO",
    local_code: "MCO",
    coordinates: "-81.30899810791016, 28.429399490356445",
  },
  {
    ident: "KMCW",
    type: "medium_airport",
    name: "Mason City Municipal Airport",
    elevation_ft: "1213",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Mason City",
    gps_code: "KMCW",
    iata_code: "MCW",
    local_code: "MCW",
    coordinates: "-93.3312988281, 43.157798767100005",
  },
  {
    ident: "KMDA",
    type: "heliport",
    name: "Martindale Army Heliport",
    elevation_ft: "674",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "San Antonio",
    gps_code: "KMDA",
    iata_code: "MDA",
    local_code: "MDA",
    coordinates: "-98.3777999878, 29.4312992096",
  },
  {
    ident: "KMDD",
    type: "small_airport",
    name: "Midland Airpark",
    elevation_ft: "2803",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Midland",
    gps_code: "KMDD",
    iata_code: "MDD",
    local_code: "MDD",
    coordinates: "-102.100997925, 32.0364990234",
  },
  {
    ident: "KMDH",
    type: "medium_airport",
    name: "Southern Illinois Airport",
    elevation_ft: "411",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Carbondale/Murphysboro",
    gps_code: "KMDH",
    iata_code: "MDH",
    local_code: "MDH",
    coordinates: "-89.25199890136719, 37.778099060058594",
  },
  {
    ident: "KMDS",
    type: "small_airport",
    name: "Madison Municipal Airport",
    elevation_ft: "1718",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Madison",
    gps_code: "KMDS",
    iata_code: "XMD",
    local_code: "MDS",
    coordinates: "-97.08589935, 44.01599884",
  },
  {
    ident: "KMDT",
    type: "medium_airport",
    name: "Harrisburg International Airport",
    elevation_ft: "310",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Harrisburg",
    gps_code: "KMDT",
    iata_code: "MDT",
    local_code: "MDT",
    coordinates: "-76.7633972168, 40.1935005188",
  },
  {
    ident: "KMDW",
    type: "large_airport",
    name: "Chicago Midway International Airport",
    elevation_ft: "620",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Chicago",
    gps_code: "KMDW",
    iata_code: "MDW",
    local_code: "MDW",
    coordinates: "-87.752403, 41.785999",
  },
  {
    ident: "KMDZ",
    type: "small_airport",
    name: "Taylor County Airport",
    elevation_ft: "1478",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Medford",
    gps_code: "KMDZ",
    iata_code: "MDF",
    local_code: "MDZ",
    coordinates: "-90.30329895, 45.10100174",
  },
  {
    ident: "KMEB",
    type: "small_airport",
    name: "Laurinburg Maxton Airport",
    elevation_ft: "216",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Maxton",
    gps_code: "KMEB",
    iata_code: "MXE",
    local_code: "MEB",
    coordinates: "-79.365799, 34.791901",
  },
  {
    ident: "KMEI",
    type: "medium_airport",
    name: "Key Field",
    elevation_ft: "297",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Meridian",
    gps_code: "KMEI",
    iata_code: "MEI",
    local_code: "MEI",
    coordinates: "-88.75189971923828, 32.33259963989258",
  },
  {
    ident: "KMEM",
    type: "large_airport",
    name: "Memphis International Airport",
    elevation_ft: "341",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Memphis",
    gps_code: "KMEM",
    iata_code: "MEM",
    local_code: "MEM",
    coordinates: "-89.97669982910156, 35.04240036010742",
  },
  {
    ident: "KMER",
    type: "medium_airport",
    name: "Castle Airport",
    elevation_ft: "191",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Merced",
    gps_code: "KMER",
    iata_code: "MER",
    local_code: "MER",
    coordinates: "-120.5680008, 37.38050079",
  },
  {
    ident: "KMEV",
    type: "small_airport",
    name: "Minden-Tahoe Airport",
    elevation_ft: "4722",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Minden",
    gps_code: "KMEV",
    iata_code: "MEV",
    local_code: "MEV",
    coordinates: "-119.7509995, 39.00030136",
  },
  {
    ident: "KMF",
    type: "small_airport",
    name: "Kamina Airport",
    elevation_ft: "2157",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Hoieti",
    gps_code: "AYKD",
    iata_code: "KMF",
    local_code: "KMN",
    coordinates: "145.956944444, -7.64925",
  },
  {
    ident: "KMFD",
    type: "medium_airport",
    name: "Mansfield Lahm Regional Airport",
    elevation_ft: "1297",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Mansfield",
    gps_code: "KMFD",
    iata_code: "MFD",
    local_code: "MFD",
    coordinates: "-82.5166015625, 40.82139968869999",
  },
  {
    ident: "KMFE",
    type: "medium_airport",
    name: "Mc Allen Miller International Airport",
    elevation_ft: "107",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Mc Allen",
    gps_code: "KMFE",
    iata_code: "MFE",
    local_code: "MFE",
    coordinates: "-98.23860168, 26.17580032",
  },
  {
    ident: "KMFI",
    type: "small_airport",
    name: "Marshfield Municipal Airport",
    elevation_ft: "1277",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Marshfield",
    gps_code: "KMFI",
    iata_code: "MFI",
    local_code: "MFI",
    coordinates: "-90.18930053710001, 44.6369018555",
  },
  {
    ident: "KMFR",
    type: "medium_airport",
    name: "Rogue Valley International Medford Airport",
    elevation_ft: "1335",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Medford",
    gps_code: "KMFR",
    iata_code: "MFR",
    local_code: "MFR",
    coordinates: "-122.87300109863281, 42.37419891357422",
  },
  {
    ident: "KMFV",
    type: "small_airport",
    name: "Accomack County Airport",
    elevation_ft: "47",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Melfa",
    gps_code: "KMFV",
    iata_code: "MFV",
    local_code: "MFV",
    coordinates: "-75.761100769, 37.646900177",
  },
  {
    ident: "KMGC",
    type: "small_airport",
    name: "Michigan City Municipal Airport",
    elevation_ft: "655",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Michigan City",
    gps_code: "KMGC",
    iata_code: "MGC",
    local_code: "MGC",
    coordinates: "-86.8211975098, 41.703300476100004",
  },
  {
    ident: "KMGE",
    type: "large_airport",
    name: "Dobbins Air Reserve Base",
    elevation_ft: "1068",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Marietta",
    gps_code: "KMGE",
    iata_code: "MGE",
    local_code: "MGE",
    coordinates: "-84.51629639, 33.91540146",
  },
  {
    ident: "KMGJ",
    type: "small_airport",
    name: "Orange County Airport",
    elevation_ft: "364",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Montgomery",
    gps_code: "KMGJ",
    iata_code: "MGJ",
    local_code: "MGJ",
    coordinates: "-74.26460266, 41.50999832",
  },
  {
    ident: "KMGM",
    type: "large_airport",
    name: "Montgomery Regional (Dannelly Field) Airport",
    elevation_ft: "221",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Montgomery",
    gps_code: "KMGM",
    iata_code: "MGM",
    local_code: "MGM",
    coordinates: "-86.39399719, 32.30059814",
  },
  {
    ident: "KMGR",
    type: "small_airport",
    name: "Moultrie Municipal Airport",
    elevation_ft: "294",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Moultrie",
    gps_code: "KMGR",
    iata_code: "MGR",
    local_code: "MGR",
    coordinates: "-83.8032989502, 31.084899902300002",
  },
  {
    ident: "KMGW",
    type: "medium_airport",
    name: "Morgantown Municipal Walter L. Bill Hart Field",
    elevation_ft: "1248",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Morgantown",
    gps_code: "KMGW",
    iata_code: "MGW",
    local_code: "MGW",
    coordinates: "-79.91629791, 39.64289856",
  },
  {
    ident: "KMGY",
    type: "small_airport",
    name: "Dayton-Wright Brothers Airport",
    elevation_ft: "957",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Dayton",
    gps_code: "KMGY",
    iata_code: "MGY",
    local_code: "MGY",
    coordinates: "-84.224899292, 39.5890007019",
  },
  {
    ident: "KMHE",
    type: "small_airport",
    name: "Mitchell Municipal Airport",
    elevation_ft: "1304",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Mitchell",
    gps_code: "KMHE",
    iata_code: "MHE",
    local_code: "MHE",
    coordinates: "-98.03859710690001, 43.774799346900004",
  },
  {
    ident: "KMHK",
    type: "medium_airport",
    name: "Manhattan Regional Airport",
    elevation_ft: "1057",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Manhattan",
    gps_code: "KMHK",
    iata_code: "MHK",
    local_code: "MHK",
    coordinates: "-96.6707992553711, 39.14099884033203",
  },
  {
    ident: "KMHL",
    type: "small_airport",
    name: "Marshall Memorial Municipal Airport",
    elevation_ft: "779",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Marshall",
    gps_code: "KMHL",
    iata_code: "MHL",
    local_code: "MHL",
    coordinates: "-93.20290374759999, 39.0957984924",
  },
  {
    ident: "KMHR",
    type: "medium_airport",
    name: "Sacramento Mather Airport",
    elevation_ft: "98",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Sacramento",
    gps_code: "KMHR",
    iata_code: "MHR",
    local_code: "MHR",
    coordinates: "-121.2979965, 38.55390167",
  },
  {
    ident: "KMHT",
    type: "large_airport",
    name: "Manchester-Boston Regional Airport",
    elevation_ft: "266",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Manchester",
    gps_code: "KMHT",
    iata_code: "MHT",
    local_code: "MHT",
    coordinates: "-71.435699, 42.932598",
  },
  {
    ident: "KMHV",
    type: "small_airport",
    name: "Mojave Airport",
    elevation_ft: "2801",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Mojave",
    gps_code: "KMHV",
    iata_code: "MHV",
    local_code: "MHV",
    coordinates: "-118.1520004, 35.05939865",
  },
  {
    ident: "KMIA",
    type: "large_airport",
    name: "Miami International Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Miami",
    gps_code: "KMIA",
    iata_code: "MIA",
    local_code: "MIA",
    coordinates: "-80.29060363769531, 25.79319953918457",
  },
  {
    ident: "KMIB",
    type: "medium_airport",
    name: "Minot Air Force Base",
    elevation_ft: "1667",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Minot",
    gps_code: "KMIB",
    iata_code: "MIB",
    local_code: "MIB",
    coordinates: "-101.358002, 48.4156",
  },
  {
    ident: "KMIE",
    type: "medium_airport",
    name: "Delaware County Johnson Field",
    elevation_ft: "937",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Muncie",
    gps_code: "KMIE",
    iata_code: "MIE",
    local_code: "MIE",
    coordinates: "-85.3958969116211, 40.2422981262207",
  },
  {
    ident: "KMIT",
    type: "small_airport",
    name: "Shafter Airport - Minter Field",
    elevation_ft: "424",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Shafter",
    gps_code: "KMIT",
    iata_code: "MIT",
    local_code: "MIT",
    coordinates: "-119.192002, 35.507401",
  },
  {
    ident: "KMIV",
    type: "medium_airport",
    name: "Millville Municipal Airport",
    elevation_ft: "85",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Millville",
    gps_code: "KMIV",
    iata_code: "MIV",
    local_code: "MIV",
    coordinates: "-75.072197, 39.367802",
  },
  {
    ident: "KMJX",
    type: "small_airport",
    name: "Ocean County Airport",
    elevation_ft: "82",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Toms River",
    gps_code: "KMJX",
    iata_code: "MJX",
    local_code: "MJX",
    coordinates: "-74.29239655, 39.92750168",
  },
  {
    ident: "KMKC",
    type: "medium_airport",
    name: "Charles B. Wheeler Downtown Airport",
    elevation_ft: "759",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Kansas City",
    gps_code: "KMKC",
    iata_code: "MKC",
    local_code: "MKC",
    coordinates: "-94.5927963256836, 39.123199462890625",
  },
  {
    ident: "KMKE",
    type: "large_airport",
    name: "General Mitchell International Airport",
    elevation_ft: "723",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Milwaukee",
    gps_code: "KMKE",
    iata_code: "MKE",
    local_code: "MKE",
    coordinates: "-87.89659881591797, 42.947200775146484",
  },
  {
    ident: "KMKG",
    type: "medium_airport",
    name: "Muskegon County Airport",
    elevation_ft: "629",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Muskegon",
    gps_code: "KMKG",
    iata_code: "MKG",
    local_code: "MKG",
    coordinates: "-86.238197, 43.169498",
  },
  {
    ident: "KMKL",
    type: "medium_airport",
    name: "McKellar-Sipes Regional Airport",
    elevation_ft: "434",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Jackson",
    gps_code: "KMKL",
    iata_code: "MKL",
    local_code: "MKL",
    coordinates: "-88.915604, 35.599899",
  },
  {
    ident: "KMKY",
    type: "small_airport",
    name: "Marco Island Executive Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Marco Island",
    gps_code: "KMKY",
    iata_code: "MRK",
    local_code: "MKY",
    coordinates: "-81.672501, 25.995001",
  },
  {
    ident: "KMLB",
    type: "medium_airport",
    name: "Melbourne International Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Melbourne",
    gps_code: "KMLB",
    iata_code: "MLB",
    local_code: "MLB",
    coordinates: "-80.64530181884766, 28.102800369262695",
  },
  {
    ident: "KMLC",
    type: "medium_airport",
    name: "Mc Alester Regional Airport",
    elevation_ft: "770",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Mc Alester",
    gps_code: "KMLC",
    iata_code: "MLC",
    local_code: "MLC",
    coordinates: "-95.783501, 34.882401",
  },
  {
    ident: "KMLI",
    type: "large_airport",
    name: "Quad City International Airport",
    elevation_ft: "590",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Moline",
    gps_code: "KMLI",
    iata_code: "MLI",
    local_code: "MLI",
    coordinates: "-90.50749969482422, 41.44850158691406",
  },
  {
    ident: "KMLS",
    type: "medium_airport",
    name: "Frank Wiley Field",
    elevation_ft: "2630",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Miles City",
    gps_code: "KMLS",
    iata_code: "MLS",
    local_code: "MLS",
    coordinates: "-105.88600158691406, 46.428001403808594",
  },
  {
    ident: "KMLU",
    type: "large_airport",
    name: "Monroe Regional Airport",
    elevation_ft: "79",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Monroe",
    gps_code: "KMLU",
    iata_code: "MLU",
    local_code: "MLU",
    coordinates: "-92.0376968383789, 32.51089859008789",
  },
  {
    ident: "KMM",
    type: "small_airport",
    name: "Kimaam Airport",
    elevation_ft: "26",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Kimaam",
    iata_code: "KMM",
    coordinates: "138.852005005, -7.9781999588",
  },
  {
    ident: "KMMH",
    type: "small_airport",
    name: "Mammoth Yosemite Airport",
    elevation_ft: "7135",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Mammoth Lakes",
    gps_code: "KMMH",
    iata_code: "MMH",
    local_code: "MMH",
    coordinates: "-118.8379974, 37.62409973",
  },
  {
    ident: "KMMI",
    type: "small_airport",
    name: "McMinn County Airport",
    elevation_ft: "875",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Athens",
    gps_code: "KMMI",
    iata_code: "MMI",
    local_code: "MMI",
    coordinates: "-84.56259918, 35.39730072",
  },
  {
    ident: "KMML",
    type: "small_airport",
    name: "Southwest Minnesota Regional Airport - Marshall/Ryan Field",
    elevation_ft: "1183",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Marshall",
    gps_code: "KMML",
    iata_code: "MML",
    local_code: "MML",
    coordinates: "-95.82189941, 44.45050049",
  },
  {
    ident: "KMMS",
    type: "small_airport",
    name: "Selfs Airport",
    elevation_ft: "162",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Marks",
    gps_code: "KMMS",
    iata_code: "MMS",
    local_code: "MMS",
    coordinates: "-90.28959655759999, 34.2314987183",
  },
  {
    ident: "KMMT",
    type: "medium_airport",
    name: "Mc Entire Joint National Guard Base",
    elevation_ft: "254",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Eastover",
    gps_code: "KMMT",
    iata_code: "MMT",
    local_code: "MMT",
    coordinates: "-80.80130005, 33.92079926",
  },
  {
    ident: "KMMU",
    type: "medium_airport",
    name: "Morristown Municipal Airport",
    elevation_ft: "187",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Morristown",
    gps_code: "KMMU",
    iata_code: "MMU",
    local_code: "MMU",
    coordinates: "-74.41490173339844, 40.799400329589844",
  },
  {
    ident: "KMNM",
    type: "small_airport",
    name: "Menominee Regional Airport",
    elevation_ft: "625",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Menominee",
    gps_code: "KMNM",
    iata_code: "MNM",
    local_code: "MNM",
    coordinates: "-87.638397, 45.126701",
  },
  {
    ident: "KMNN",
    type: "small_airport",
    name: "Marion Municipal Airport",
    elevation_ft: "993",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Marion",
    gps_code: "KMNN",
    iata_code: "MNN",
    local_code: "MNN",
    coordinates: "-83.06349945070001, 40.6161994934",
  },
  {
    ident: "KMOB",
    type: "large_airport",
    name: "Mobile Regional Airport",
    elevation_ft: "219",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Mobile",
    gps_code: "KMOB",
    iata_code: "MOB",
    local_code: "MOB",
    coordinates: "-88.242797851562, 30.691200256348",
  },
  {
    ident: "KMOD",
    type: "medium_airport",
    name: "Modesto City Co-Harry Sham Field",
    elevation_ft: "97",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Modesto",
    gps_code: "KMOD",
    iata_code: "MOD",
    local_code: "MOD",
    coordinates: "-120.9540024, 37.62580109",
  },
  {
    ident: "KMOT",
    type: "medium_airport",
    name: "Minot International Airport",
    elevation_ft: "1716",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Minot",
    gps_code: "KMOT",
    iata_code: "MOT",
    local_code: "MOT",
    coordinates: "-101.27999877929688, 48.2593994140625",
  },
  {
    ident: "KMPI",
    type: "small_airport",
    name: "Mariposa Yosemite Airport",
    elevation_ft: "2254",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Mariposa",
    gps_code: "KMPI",
    iata_code: "RMY",
    local_code: "MPI",
    coordinates: "-120.040000916, 37.5108985901",
  },
  {
    ident: "KMPJ",
    type: "small_airport",
    name: "Petit Jean Park Airport",
    elevation_ft: "923",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Morrilton",
    gps_code: "KMPJ",
    iata_code: "MPJ",
    local_code: "MPJ",
    coordinates: "-92.909202, 35.138901",
  },
  {
    ident: "KMPO",
    type: "small_airport",
    name: "Pocono Mountains Municipal Airport",
    elevation_ft: "1915",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Mount Pocono",
    gps_code: "KMPO",
    iata_code: "MPO",
    local_code: "MPO",
    coordinates: "-75.378897, 41.137503",
  },
  {
    ident: "KMPV",
    type: "medium_airport",
    name: "Edward F Knapp State Airport",
    elevation_ft: "1166",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VT",
    municipality: "Barre/Montpelier",
    gps_code: "KMPV",
    iata_code: "MPV",
    local_code: "MPV",
    coordinates: "-72.56230164, 44.20349884",
  },
  {
    ident: "KMPZ",
    type: "small_airport",
    name: "Mount Pleasant Municipal Airport",
    elevation_ft: "730",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Mount Pleasant",
    gps_code: "KMPZ",
    iata_code: "MPZ",
    local_code: "MPZ",
    coordinates: "-91.51110077, 40.94660187",
  },
  {
    ident: "KMQB",
    type: "small_airport",
    name: "Macomb Municipal Airport",
    elevation_ft: "707",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Macomb",
    gps_code: "KMQB",
    iata_code: "MQB",
    local_code: "MQB",
    coordinates: "-90.65239715579999, 40.520099639899996",
  },
  {
    ident: "KMQI",
    type: "small_airport",
    name: "Dare County Regional Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Manteo",
    gps_code: "KMQI",
    iata_code: "MEO",
    local_code: "MQI",
    coordinates: "-75.69550323, 35.91899872",
  },
  {
    ident: "KMQS",
    type: "small_airport",
    name: "Chester County G O Carlson Airport",
    elevation_ft: "660",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Coatesville",
    gps_code: "KMQS",
    iata_code: "CTH",
    local_code: "MQS",
    coordinates: "-75.8655014, 39.97900009",
  },
  {
    ident: "KMQY",
    type: "medium_airport",
    name: "Smyrna Airport",
    elevation_ft: "543",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Smyrna",
    gps_code: "KMQY",
    iata_code: "MQY",
    local_code: "MQY",
    coordinates: "-86.5201034546, 36.0089988708",
  },
  {
    ident: "KMRB",
    type: "medium_airport",
    name: "Eastern WV Regional Airport/Shepherd Field",
    elevation_ft: "565",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Martinsburg",
    gps_code: "KMRB",
    iata_code: "MRB",
    local_code: "MRB",
    coordinates: "-77.98459625, 39.40190125",
  },
  {
    ident: "KMRC",
    type: "small_airport",
    name: "Maury County Airport",
    elevation_ft: "681",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Columbia/Mount Pleasant",
    gps_code: "KMRC",
    iata_code: "MRC",
    local_code: "MRC",
    coordinates: "-87.17890167239999, 35.5541000366",
  },
  {
    ident: "KMRF",
    type: "small_airport",
    name: "Marfa Municipal Airport",
    elevation_ft: "4849",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Marfa",
    gps_code: "KMRF",
    iata_code: "MRF",
    local_code: "MRF",
    coordinates: "-104.017997, 30.371099",
  },
  {
    ident: "KMRN",
    type: "small_airport",
    name: "Foothills Regional Airport",
    elevation_ft: "1270",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Morganton",
    gps_code: "KMRN",
    iata_code: "MRN",
    local_code: "MRN",
    coordinates: "-81.611397, 35.820202",
  },
  {
    ident: "KMRY",
    type: "medium_airport",
    name: "Monterey Peninsula Airport",
    elevation_ft: "257",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Monterey",
    gps_code: "KMRY",
    iata_code: "MRY",
    local_code: "MRY",
    coordinates: "-121.84300231933594, 36.58700180053711",
  },
  {
    ident: "KMSL",
    type: "medium_airport",
    name: "Northwest Alabama Regional Airport",
    elevation_ft: "551",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Muscle Shoals",
    gps_code: "KMSL",
    iata_code: "MSL",
    local_code: "MSL",
    coordinates: "-87.61019897, 34.74530029",
  },
  {
    ident: "KMSN",
    type: "large_airport",
    name: "Dane County Regional Truax Field",
    elevation_ft: "887",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Madison",
    gps_code: "KMSN",
    iata_code: "MSN",
    local_code: "MSN",
    coordinates: "-89.3375015258789, 43.13990020751953",
  },
  {
    ident: "KMSO",
    type: "medium_airport",
    name: "Missoula International Airport",
    elevation_ft: "3206",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Missoula",
    gps_code: "KMSO",
    iata_code: "MSO",
    local_code: "MSO",
    coordinates: "-114.0910034, 46.91630173",
  },
  {
    ident: "KMSP",
    type: "large_airport",
    name: "Minneapolis-St Paul International/Wold-Chamberlain Airport",
    elevation_ft: "841",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Minneapolis",
    gps_code: "KMSP",
    iata_code: "MSP",
    local_code: "MSP",
    coordinates: "-93.221802, 44.882",
  },
  {
    ident: "KMSS",
    type: "medium_airport",
    name: "Massena International Richards Field",
    elevation_ft: "215",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Massena",
    gps_code: "KMSS",
    iata_code: "MSS",
    local_code: "MSS",
    coordinates: "-74.84559631347656, 44.93579864501953",
  },
  {
    ident: "KMSV",
    type: "small_airport",
    name: "Sullivan County International Airport",
    elevation_ft: "1403",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Monticello",
    gps_code: "KMSV",
    iata_code: "MSV",
    local_code: "MSV",
    coordinates: "-74.794997, 41.701596",
  },
  {
    ident: "KMSY",
    type: "large_airport",
    name: "Louis Armstrong New Orleans International Airport",
    elevation_ft: "4",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "New Orleans",
    gps_code: "KMSY",
    iata_code: "MSY",
    local_code: "MSY",
    coordinates: "-90.25800323486328, 29.99340057373047",
  },
  {
    ident: "KMT",
    type: "closed",
    name: "Kampot Airport",
    elevation_ft: "44",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-7",
    municipality: "Kampot",
    iata_code: "KMT",
    coordinates: "104.1617, 10.6343",
  },
  {
    ident: "KMTC",
    type: "medium_airport",
    name: "Selfridge Air National Guard Base Airport",
    elevation_ft: "580",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Mount Clemens",
    gps_code: "KMTC",
    iata_code: "MTC",
    local_code: "MTC",
    coordinates: "-82.836919, 42.613463",
  },
  {
    ident: "KMTH",
    type: "medium_airport",
    name: "The Florida Keys Marathon Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Marathon",
    gps_code: "KMTH",
    iata_code: "MTH",
    local_code: "MTH",
    coordinates: "-81.051399, 24.726101",
  },
  {
    ident: "KMTJ",
    type: "medium_airport",
    name: "Montrose Regional Airport",
    elevation_ft: "5759",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Montrose",
    gps_code: "KMTJ",
    iata_code: "MTJ",
    local_code: "MTJ",
    coordinates: "-107.893997192, 38.509799957300004",
  },
  {
    ident: "KMTN",
    type: "medium_airport",
    name: "Martin State Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Baltimore",
    gps_code: "KMTN",
    iata_code: "MTN",
    local_code: "MTN",
    coordinates: "-76.413803, 39.325699",
  },
  {
    ident: "KMTO",
    type: "small_airport",
    name: "Coles County Memorial Airport",
    elevation_ft: "722",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Mattoon/Charleston",
    gps_code: "KMTO",
    iata_code: "MTO",
    local_code: "MTO",
    coordinates: "-88.279198, 39.477901",
  },
  {
    ident: "KMTP",
    type: "small_airport",
    name: "Montauk Airport",
    elevation_ft: "6",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Montauk",
    gps_code: "KMTP",
    iata_code: "MTP",
    local_code: "MTP",
    coordinates: "-71.920797, 41.0765",
  },
  {
    ident: "KMTW",
    type: "small_airport",
    name: "Manitowoc County Airport",
    elevation_ft: "651",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Manitowoc",
    gps_code: "KMTW",
    iata_code: "MTW",
    local_code: "MTW",
    coordinates: "-87.680602, 44.128799",
  },
  {
    ident: "KMUI",
    type: "medium_airport",
    name: "Muir Army Air Field (Fort Indiantown Gap) Airport",
    elevation_ft: "488",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Fort Indiantown Gap(Annville)",
    gps_code: "KMUI",
    iata_code: "MUI",
    local_code: "MUI",
    coordinates: "-76.569397, 40.434799",
  },
  {
    ident: "KMUO",
    type: "large_airport",
    name: "Mountain Home Air Force Base",
    elevation_ft: "2996",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Mountain Home",
    gps_code: "KMUO",
    iata_code: "MUO",
    local_code: "MUO",
    coordinates: "-115.872002, 43.043598",
  },
  {
    ident: "KMUT",
    type: "small_airport",
    name: "Muscatine Municipal Airport",
    elevation_ft: "547",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Muscatine",
    gps_code: "KMUT",
    iata_code: "MUT",
    local_code: "MUT",
    coordinates: "-91.148201, 41.367803",
  },
  {
    ident: "KMVC",
    type: "small_airport",
    name: "Monroe County Aeroplex Airport",
    elevation_ft: "419",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Monroeville",
    gps_code: "KMVC",
    iata_code: "MVC",
    local_code: "MVC",
    coordinates: "-87.350996, 31.458",
  },
  {
    ident: "KMVE",
    type: "small_airport",
    name: "Montevideo Chippewa County Airport",
    elevation_ft: "1034",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Montevideo",
    gps_code: "KMVE",
    iata_code: "MVE",
    local_code: "MVE",
    coordinates: "-95.710297, 44.969101",
  },
  {
    ident: "KMVL",
    type: "small_airport",
    name: "Morrisville Stowe State Airport",
    elevation_ft: "732",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VT",
    municipality: "Morrisville",
    gps_code: "KMVL",
    iata_code: "MVL",
    local_code: "MVL",
    coordinates: "-72.6139984131, 44.53459930419999",
  },
  {
    ident: "KMVY",
    type: "small_airport",
    name: "Martha's Vineyard Airport",
    elevation_ft: "67",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Martha's Vineyard",
    gps_code: "KMVY",
    iata_code: "MVY",
    local_code: "MVY",
    coordinates: "-70.6143035889, 41.3931007385",
  },
  {
    ident: "KMWA",
    type: "small_airport",
    name: "Williamson County Regional Airport",
    elevation_ft: "472",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Marion",
    gps_code: "KMWA",
    iata_code: "MWA",
    local_code: "MWA",
    coordinates: "-89.01110077, 37.75500107",
  },
  {
    ident: "KMWC",
    type: "small_airport",
    name: "Lawrence J Timmerman Airport",
    elevation_ft: "745",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Milwaukee",
    gps_code: "KMWC",
    iata_code: "MWC",
    local_code: "MWC",
    coordinates: "-88.034401, 43.110401",
  },
  {
    ident: "KMWH",
    type: "medium_airport",
    name: "Grant County International Airport",
    elevation_ft: "1189",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Moses Lake",
    gps_code: "KMWH",
    iata_code: "MWH",
    local_code: "MWH",
    coordinates: "-119.3199997, 47.20769882",
  },
  {
    ident: "KMWL",
    type: "small_airport",
    name: "Mineral Wells Regional Airport",
    elevation_ft: "974",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Mineral Wells",
    gps_code: "KMWL",
    iata_code: "MWL",
    local_code: "MWL",
    coordinates: "-98.060204, 32.781601",
  },
  {
    ident: "KMWO",
    type: "small_airport",
    name: "Middletown Regional Airport",
    elevation_ft: "650",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Middletown",
    gps_code: "KMWO",
    iata_code: "MWO",
    local_code: "MWO",
    coordinates: "-84.395302, 39.530998",
  },
  {
    ident: "KMXA",
    type: "small_airport",
    name: "Manila Municipal Airport",
    elevation_ft: "242",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Manila",
    gps_code: "KMXA",
    iata_code: "MXA",
    local_code: "MXA",
    coordinates: "-90.154602, 35.894402",
  },
  {
    ident: "KMXF",
    type: "medium_airport",
    name: "Maxwell Air Force Base",
    elevation_ft: "171",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Montgomery",
    gps_code: "KMXF",
    iata_code: "MXF",
    local_code: "MXF",
    coordinates: "-86.365799, 32.3829",
  },
  {
    ident: "KMYF",
    type: "small_airport",
    name: "Montgomery-Gibbs Executive Airport",
    elevation_ft: "427",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Diego",
    gps_code: "KMYF",
    iata_code: "MYF",
    local_code: "MYF",
    coordinates: "-117.139999, 32.8157",
  },
  {
    ident: "KMYL",
    type: "medium_airport",
    name: "McCall Municipal Airport",
    elevation_ft: "5024",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "McCall",
    gps_code: "KMYL",
    iata_code: "MYL",
    local_code: "MYL",
    coordinates: "-116.1009979, 44.88970184",
  },
  {
    ident: "KMYR",
    type: "medium_airport",
    name: "Myrtle Beach International Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Myrtle Beach",
    gps_code: "KMYR",
    iata_code: "MYR",
    local_code: "MYR",
    coordinates: "-78.9282989502, 33.6796989441",
  },
  {
    ident: "KMYV",
    type: "medium_airport",
    name: "Yuba County Airport",
    elevation_ft: "64",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Marysville",
    gps_code: "KMYV",
    iata_code: "MYV",
    local_code: "MYV",
    coordinates: "-121.5699997, 39.09780121",
  },
  {
    ident: "KMZJ",
    type: "small_airport",
    name: "Pinal Airpark",
    elevation_ft: "1893",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Marana",
    gps_code: "KMZJ",
    iata_code: "MZJ",
    local_code: "MZJ",
    coordinates: "-111.32800293, 32.5106010437",
  },
  {
    ident: "KMZZ",
    type: "small_airport",
    name: "Marion Municipal Airport",
    elevation_ft: "859",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Marion",
    gps_code: "KMZZ",
    iata_code: "MZZ",
    local_code: "MZZ",
    coordinates: "-85.6797027588, 40.4898986816",
  },
  {
    ident: "KN03",
    type: "small_airport",
    name: "Cortland County Chase Field",
    elevation_ft: "1198",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Cortland",
    gps_code: "KN03",
    iata_code: "CTX",
    local_code: "N03",
    coordinates: "-76.21489716, 42.59260178",
  },
  {
    ident: "KN23",
    type: "small_airport",
    name: "Sidney Municipal Airport",
    elevation_ft: "1027",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Sidney",
    gps_code: "N23",
    iata_code: "SXY",
    local_code: "N23",
    coordinates: "-75.416000366211, 42.302600860596",
  },
  {
    ident: "KN53",
    type: "small_airport",
    name: "Stroudsburg Pocono Airport",
    elevation_ft: "480",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "East Stroudsburg",
    gps_code: "KN53",
    iata_code: "ESP",
    local_code: "N53",
    coordinates: "-75.1605987549, 41.0358009338",
  },
  {
    ident: "KNBG",
    type: "medium_airport",
    name: "New Orleans NAS JRB/Alvin Callender Field",
    elevation_ft: "2",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "New Orleans",
    gps_code: "KNBG",
    iata_code: "NBG",
    local_code: "NBG",
    coordinates: "-90.03500366, 29.82530022",
  },
  {
    ident: "KNBJ",
    type: "small_airport",
    name: "Naval Outlying Field Barin",
    elevation_ft: "54",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Foley",
    gps_code: "KNBJ",
    iata_code: "NHX",
    local_code: "NBJ",
    coordinates: "-87.63529968259999, 30.389099121100003",
  },
  {
    ident: "KNDY",
    type: "small_airport",
    name: "Dahlgren Naval Surface Warfare Center Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Dahlgren",
    gps_code: "KNDY",
    iata_code: "DGN",
    local_code: "NDY",
    coordinates: "-77.03720093, 38.33250046",
  },
  {
    ident: "KNEL",
    type: "medium_airport",
    name: "Lakehurst Maxfield Field Airport",
    elevation_ft: "101",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Lakehurst",
    gps_code: "KNEL",
    iata_code: "NEL",
    local_code: "NEL",
    coordinates: "-74.353302, 40.03329849",
  },
  {
    ident: "KNEN",
    type: "small_airport",
    name: "Whitehouse Naval Outlying Field",
    elevation_ft: "99",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Jacksonville",
    gps_code: "KNEN",
    iata_code: "NEN",
    local_code: "NEN",
    coordinates: "-81.87190246582, 30.353900909424",
  },
  {
    ident: "KNEW",
    type: "medium_airport",
    name: "Lakefront Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "New Orleans",
    gps_code: "KNEW",
    iata_code: "NEW",
    local_code: "NEW",
    coordinates: "-90.028297424316, 30.042400360107",
  },
  {
    ident: "KNFL",
    type: "medium_airport",
    name: "Fallon Naval Air Station",
    elevation_ft: "3934",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Fallon",
    gps_code: "KNFL",
    iata_code: "NFL",
    local_code: "NFL",
    coordinates: "-118.7009964, 39.41659927",
  },
  {
    ident: "KNFW",
    type: "medium_airport",
    name: "NAS Fort Worth JRB/Carswell Field",
    elevation_ft: "650",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Fort Worth",
    gps_code: "KNFW",
    iata_code: "FWH",
    local_code: "NFW",
    coordinates: "-97.4414978, 32.76919937",
  },
  {
    ident: "KNGU",
    type: "medium_airport",
    name: "Norfolk Naval Station (Chambers Field)",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Norfolk",
    gps_code: "KNGU",
    iata_code: "NGU",
    local_code: "NGU",
    coordinates: "-76.289299, 36.937599",
  },
  {
    ident: "KNGZ",
    type: "closed",
    name: "Alameda Naval Air Station",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Alameda",
    gps_code: "KNGZ",
    iata_code: "NGZ",
    coordinates: "-122.31999969482422, 37.78889846801758",
  },
  {
    ident: "KNHK",
    type: "medium_airport",
    name: "Patuxent River Naval Air Station (Trapnell Field)",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Patuxent River",
    gps_code: "KNHK",
    iata_code: "NHK",
    local_code: "NHK",
    coordinates: "-76.411797, 38.285999",
  },
  {
    ident: "KNHZ",
    type: "medium_airport",
    name: "Brunswick Executive Airport",
    elevation_ft: "72",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Brunswick",
    gps_code: "KBXM",
    iata_code: "NHZ",
    local_code: "BXM",
    coordinates: "-69.93859863, 43.89220047",
  },
  {
    ident: "KNIP",
    type: "medium_airport",
    name: "Jacksonville Naval Air Station (Towers Field)",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Jacksonville",
    gps_code: "KNIP",
    iata_code: "NIP",
    local_code: "NIP",
    coordinates: "-81.680603, 30.2358",
  },
  {
    ident: "KNJK",
    type: "medium_airport",
    name: "El Centro NAF Airport (Vraciu Field)",
    elevation_ft: "-42",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "El Centro",
    gps_code: "KNJK",
    iata_code: "NJK",
    local_code: "NJK",
    coordinates: "-115.671996, 32.829201",
  },
  {
    ident: "KNKX",
    type: "medium_airport",
    name: "Miramar Marine Corps Air Station - Mitscher Field",
    elevation_ft: "477",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Diego",
    gps_code: "KNKX",
    iata_code: "NKX",
    local_code: "NKX",
    coordinates: "-117.1429977, 32.86840057",
  },
  {
    ident: "KNL",
    type: "closed",
    name: "Kelanoa Airport",
    elevation_ft: "40",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Kelanoa",
    iata_code: "KNL",
    coordinates: "147.49, -6.01",
  },
  {
    ident: "KNLC",
    type: "medium_airport",
    name: "Lemoore Naval Air Station (Reeves Field) Airport",
    elevation_ft: "232",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Lemoore",
    gps_code: "KNLC",
    iata_code: "NLC",
    local_code: "NLC",
    coordinates: "-119.9520035, 36.33300018",
  },
  {
    ident: "KNPA",
    type: "medium_airport",
    name: "Pensacola Naval Air Station/Forrest Sherman Field",
    elevation_ft: "28",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Pensacola",
    gps_code: "KNPA",
    iata_code: "NPA",
    local_code: "NPA",
    coordinates: "-87.31860352, 30.35269928",
  },
  {
    ident: "KNQA",
    type: "medium_airport",
    name: "Millington-Memphis Airport",
    elevation_ft: "320",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Millington",
    gps_code: "KNQA",
    iata_code: "NQA",
    local_code: "NQA",
    coordinates: "-89.8703, 35.356701",
  },
  {
    ident: "KNQI",
    type: "medium_airport",
    name: "Kingsville Naval Air Station",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Kingsville",
    gps_code: "KNQI",
    iata_code: "NQI",
    local_code: "NQI",
    coordinates: "-97.8097000122, 27.5072002411",
  },
  {
    ident: "KNQX",
    type: "medium_airport",
    name: "Naval Air Station Key West/Boca Chica Field",
    elevation_ft: "6",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Key West",
    gps_code: "KNQX",
    iata_code: "NQX",
    local_code: "NQX",
    coordinates: "-81.68890381, 24.57579994",
  },
  {
    ident: "KNRB",
    type: "medium_airport",
    name: "Naval Station Mayport (Admiral David L. Mcdonald Field)",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Mayport",
    gps_code: "KNRB",
    iata_code: "NRB",
    local_code: "NRB",
    coordinates: "-81.42469788, 30.39109993",
  },
  {
    ident: "KNRS",
    type: "small_airport",
    name: "Naval Outlying Field Imperial Beach (Ream Field)",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Imperial Beach",
    gps_code: "KNRS",
    iata_code: "NRS",
    local_code: "NRS",
    coordinates: "-117.116997, 32.5667",
  },
  {
    ident: "KNSE",
    type: "medium_airport",
    name: "Whiting Field Naval Air Station - North",
    elevation_ft: "199",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Milton",
    gps_code: "KNSE",
    iata_code: "NSE",
    local_code: "NSE",
    coordinates: "-87.02189636, 30.7241993",
  },
  {
    ident: "KNTD",
    type: "medium_airport",
    name: "Point Mugu Naval Air Station (Naval Base Ventura Co)",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Point Mugu",
    gps_code: "KNTD",
    iata_code: "NTD",
    local_code: "NTD",
    coordinates: "-119.121002197, 34.120300293",
  },
  {
    ident: "KNTU",
    type: "medium_airport",
    name: "Oceana Naval Air Station",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Virginia Beach",
    gps_code: "KNTU",
    iata_code: "NTU",
    local_code: "NTU",
    coordinates: "-76.033501, 36.820702",
  },
  {
    ident: "KNUQ",
    type: "medium_airport",
    name: "Moffett Federal Airfield",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Mountain View",
    gps_code: "KNUQ",
    iata_code: "NUQ",
    local_code: "NUQ",
    coordinates: "-122.049004, 37.4161",
  },
  {
    ident: "KNUW",
    type: "medium_airport",
    name: "Whidbey Island Naval Air Station (Ault Field)",
    elevation_ft: "47",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Oak Harbor",
    gps_code: "KNUW",
    iata_code: "NUW",
    local_code: "NUW",
    coordinates: "-122.655998, 48.351799",
  },
  {
    ident: "KNYL",
    type: "medium_airport",
    name: "Yuma MCAS/Yuma International Airport",
    elevation_ft: "213",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Yuma",
    gps_code: "KNYL",
    iata_code: "YUM",
    local_code: "NYL",
    coordinates: "-114.6060028, 32.65660095",
  },
  {
    ident: "KNZY",
    type: "medium_airport",
    name: "North Island Naval Air Station-Halsey Field",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Diego",
    gps_code: "KNZY",
    iata_code: "NZY",
    local_code: "NZY",
    coordinates: "-117.2149963, 32.69919968",
  },
  {
    ident: "KO02",
    type: "small_airport",
    name: "Nervino Airport",
    elevation_ft: "4900",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Beckwourth",
    gps_code: "KO02",
    iata_code: "NVN",
    local_code: "O02",
    coordinates: "-120.352996826, 39.8185005188",
  },
  {
    ident: "KO22",
    type: "small_airport",
    name: "Columbia Airport",
    elevation_ft: "2118",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Columbia",
    gps_code: "O22",
    iata_code: "COA",
    local_code: "O22",
    coordinates: "-120.415000916, 38.030399322499996",
  },
  {
    ident: "KO27",
    type: "small_airport",
    name: "Oakdale Airport",
    elevation_ft: "237",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Oakdale",
    gps_code: "O27",
    iata_code: "ODC",
    local_code: "O27",
    coordinates: "-120.8000031, 37.75630188",
  },
  {
    ident: "KO43",
    type: "small_airport",
    name: "Yerington Municipal Airport",
    elevation_ft: "4378",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Yerington",
    gps_code: "O43",
    iata_code: "EYR",
    local_code: "O43",
    coordinates: "-119.157997131, 39.0041007996",
  },
  {
    ident: "KOAJ",
    type: "medium_airport",
    name: "Albert J Ellis Airport",
    elevation_ft: "94",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Jacksonville",
    gps_code: "KOAJ",
    iata_code: "OAJ",
    local_code: "OAJ",
    coordinates: "-77.61209869380001, 34.8292007446",
  },
  {
    ident: "KOAK",
    type: "large_airport",
    name: "Metropolitan Oakland International Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Oakland",
    gps_code: "KOAK",
    iata_code: "OAK",
    local_code: "OAK",
    coordinates: "-122.221001, 37.721298",
  },
  {
    ident: "KOAR",
    type: "small_airport",
    name: "Marina Municipal Airport",
    elevation_ft: "137",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Marina",
    gps_code: "KOAR",
    iata_code: "OAR",
    local_code: "OAR",
    coordinates: "-121.762001, 36.68190002",
  },
  {
    ident: "KOBE",
    type: "small_airport",
    name: "Okeechobee County Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Okeechobee",
    gps_code: "KOBE",
    iata_code: "OBE",
    local_code: "OBE",
    coordinates: "-80.8498001099, 27.262800216699997",
  },
  {
    ident: "KOCF",
    type: "small_airport",
    name: "Ocala International Airport - Jim Taylor Field",
    elevation_ft: "90",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Ocala",
    gps_code: "KOCF",
    iata_code: "OCF",
    local_code: "OCF",
    coordinates: "-82.22419739, 29.17259979",
  },
  {
    ident: "KOCH",
    type: "small_airport",
    name: "A L Mangham Jr. Regional Airport",
    elevation_ft: "355",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Nacogdoches",
    gps_code: "KOCH",
    iata_code: "OCH",
    local_code: "OCH",
    coordinates: "-94.70950317, 31.57799911",
  },
  {
    ident: "KOCW",
    type: "small_airport",
    name: "Warren Field",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Washington",
    gps_code: "KOCW",
    iata_code: "OCW",
    local_code: "OCW",
    coordinates: "-77.049797058105, 35.570499420166",
  },
  {
    ident: "KOEA",
    type: "small_airport",
    name: "O'Neal Airport",
    elevation_ft: "414",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Vincennes",
    gps_code: "KOEA",
    iata_code: "OEA",
    local_code: "OEA",
    coordinates: "-87.5522003174, 38.6913986206",
  },
  {
    ident: "KOEO",
    type: "small_airport",
    name: "L O Simenstad Municipal Airport",
    elevation_ft: "903",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Osceola",
    gps_code: "KOEO",
    iata_code: "OEO",
    local_code: "OEO",
    coordinates: "-92.691902160645, 45.310001373291",
  },
  {
    ident: "KOFF",
    type: "medium_airport",
    name: "Offutt Air Force Base",
    elevation_ft: "1052",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Omaha",
    gps_code: "KOFF",
    iata_code: "OFF",
    local_code: "OFF",
    coordinates: "-95.912498474121, 41.118301391602",
  },
  {
    ident: "KOFK",
    type: "medium_airport",
    name: "Karl Stefan Memorial Airport",
    elevation_ft: "1573",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Norfolk",
    gps_code: "KOFK",
    iata_code: "OFK",
    local_code: "OFK",
    coordinates: "-97.435096740723, 41.985500335693",
  },
  {
    ident: "KOGA",
    type: "small_airport",
    name: "Searle Field",
    elevation_ft: "3279",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Ogallala",
    gps_code: "KOGA",
    iata_code: "OGA",
    local_code: "OGA",
    coordinates: "-101.7699966, 41.11949921",
  },
  {
    ident: "KOGB",
    type: "medium_airport",
    name: "Orangeburg Municipal Airport",
    elevation_ft: "195",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Orangeburg",
    gps_code: "KOGB",
    iata_code: "OGB",
    local_code: "OGB",
    coordinates: "-80.859497070312, 33.456798553467",
  },
  {
    ident: "KOGD",
    type: "medium_airport",
    name: "Ogden Hinckley Airport",
    elevation_ft: "4473",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Ogden",
    gps_code: "KOGD",
    iata_code: "OGD",
    local_code: "OGD",
    coordinates: "-112.0120010376, 41.195899963379",
  },
  {
    ident: "KOGS",
    type: "small_airport",
    name: "Ogdensburg International Airport",
    elevation_ft: "297",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Ogdensburg",
    gps_code: "KOGS",
    iata_code: "OGS",
    local_code: "OGS",
    coordinates: "-75.46549987790002, 44.6819000244",
  },
  {
    ident: "KOIC",
    type: "small_airport",
    name: "Lt Warren Eaton Airport",
    elevation_ft: "1025",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Norwich",
    gps_code: "KOIC",
    iata_code: "OIC",
    local_code: "OIC",
    coordinates: "-75.524101257324, 42.566600799561",
  },
  {
    ident: "KOIL",
    type: "closed",
    name: "Splane Memorial Airport",
    elevation_ft: "1552",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Oil City",
    gps_code: "KOIL",
    iata_code: "OIL",
    local_code: "OIL",
    coordinates: "-79.7449, 41.4813",
  },
  {
    ident: "KOJC",
    type: "small_airport",
    name: "Johnson County Executive Airport",
    elevation_ft: "1096",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Olathe",
    gps_code: "KOJC",
    iata_code: "OJC",
    local_code: "OJC",
    coordinates: "-94.73760223, 38.84759903",
  },
  {
    ident: "KOKB",
    type: "small_airport",
    name: "Oceanside Municipal Airport",
    elevation_ft: "28",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Oceanside",
    gps_code: "KOKB",
    iata_code: "OCN",
    local_code: "OKB",
    coordinates: "-117.35399627686, 33.217300415039",
  },
  {
    ident: "KOKC",
    type: "large_airport",
    name: "Will Rogers World Airport",
    elevation_ft: "1295",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Oklahoma City",
    gps_code: "KOKC",
    iata_code: "OKC",
    local_code: "OKC",
    coordinates: "-97.60070037841797, 35.39310073852539",
  },
  {
    ident: "KOKH",
    type: "small_airport",
    name: "AJ Eisenberg Airport",
    elevation_ft: "193",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Oak Harbor",
    gps_code: "KOKH",
    iata_code: "ODW",
    local_code: "OKH",
    coordinates: "-122.674003601, 48.251499176",
  },
  {
    ident: "KOKK",
    type: "small_airport",
    name: "Kokomo Municipal Airport",
    elevation_ft: "830",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Kokomo",
    gps_code: "KOKK",
    iata_code: "OKK",
    local_code: "OKK",
    coordinates: "-86.05899810791, 40.528198242188",
  },
  {
    ident: "KOKM",
    type: "small_airport",
    name: "Okmulgee Regional Airport",
    elevation_ft: "720",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Okmulgee",
    gps_code: "KOKM",
    iata_code: "OKM",
    local_code: "OKM",
    coordinates: "-95.948699951172, 35.668098449707",
  },
  {
    ident: "KOKS",
    type: "small_airport",
    name: "Garden County Airport/King Rhiley Field",
    elevation_ft: "3394",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Oshkosh",
    gps_code: "KOKS",
    iata_code: "OKS",
    local_code: "OKS",
    coordinates: "-102.355003, 41.401001",
  },
  {
    ident: "KOKV",
    type: "small_airport",
    name: "Winchester Regional Airport",
    elevation_ft: "726",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Winchester",
    gps_code: "KOKV",
    iata_code: "WGO",
    local_code: "OKV",
    coordinates: "-78.14440155, 39.14350128",
  },
  {
    ident: "KOL",
    type: "closed",
    name: "Koumala Airport",
    elevation_ft: "2175",
    continent: "AF",
    iso_country: "CF",
    iso_region: "CF-BB",
    municipality: "Koumala",
    iata_code: "KOL",
    coordinates: "21.2565, 8.4965",
  },
  {
    ident: "KOLD",
    type: "small_airport",
    name: "Dewitt Field,Old Town Municipal Airport",
    elevation_ft: "126",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Old Town",
    gps_code: "KOLD",
    iata_code: "OLD",
    local_code: "OLD",
    coordinates: "-68.67430115, 44.95280075",
  },
  {
    ident: "KOLF",
    type: "medium_airport",
    name: "L M Clayton Airport",
    elevation_ft: "1986",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Wolf Point",
    gps_code: "KOLF",
    iata_code: "OLF",
    local_code: "OLF",
    coordinates: "-105.574996948, 48.094501495399996",
  },
  {
    ident: "KOLM",
    type: "medium_airport",
    name: "Olympia Regional Airport",
    elevation_ft: "209",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Olympia",
    gps_code: "KOLM",
    iata_code: "OLM",
    local_code: "OLM",
    coordinates: "-122.9029999, 46.9693985",
  },
  {
    ident: "KOLS",
    type: "medium_airport",
    name: "Nogales International Airport",
    elevation_ft: "3955",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Nogales",
    gps_code: "KOLS",
    iata_code: "OLS",
    local_code: "OLS",
    coordinates: "-110.848, 31.4177",
  },
  {
    ident: "KOLV",
    type: "small_airport",
    name: "Olive Branch Airport",
    elevation_ft: "402",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Olive Branch",
    gps_code: "KOLV",
    iata_code: "OLV",
    local_code: "OLV",
    coordinates: "-89.78690338130001, 34.9786987305",
  },
  {
    ident: "KOM",
    type: "small_airport",
    name: "Komo-Manda Airport",
    elevation_ft: "5057",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-HE",
    municipality: "Komo",
    gps_code: "AYOO",
    iata_code: "KOM",
    local_code: "KOMO",
    coordinates: "142.8598, -6.0682",
  },
  {
    ident: "KOMA",
    type: "large_airport",
    name: "Eppley Airfield",
    elevation_ft: "984",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Omaha",
    gps_code: "KOMA",
    iata_code: "OMA",
    local_code: "OMA",
    coordinates: "-95.894096, 41.3032",
  },
  {
    ident: "KOMK",
    type: "small_airport",
    name: "Omak Airport",
    elevation_ft: "1305",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Omak",
    gps_code: "KOMK",
    iata_code: "OMK",
    local_code: "OMK",
    coordinates: "-119.517997742, 48.4644012451",
  },
  {
    ident: "KONL",
    type: "small_airport",
    name: "The O'Neill Municipal John L Baker Field",
    elevation_ft: "2031",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "O'Neill",
    gps_code: "KONL",
    iata_code: "ONL",
    local_code: "ONL",
    coordinates: "-98.688103, 42.469898",
  },
  {
    ident: "KONO",
    type: "small_airport",
    name: "Ontario Municipal Airport",
    elevation_ft: "2193",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Ontario",
    gps_code: "KONO",
    iata_code: "ONO",
    local_code: "ONO",
    coordinates: "-117.01399993896, 44.020500183105",
  },
  {
    ident: "KONP",
    type: "medium_airport",
    name: "Newport Municipal Airport",
    elevation_ft: "160",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Newport",
    gps_code: "KONP",
    iata_code: "ONP",
    local_code: "ONP",
    coordinates: "-124.057999, 44.580399",
  },
  {
    ident: "KONT",
    type: "large_airport",
    name: "Ontario International Airport",
    elevation_ft: "944",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Ontario",
    gps_code: "KONT",
    iata_code: "ONT",
    local_code: "ONT",
    coordinates: "-117.60099792480469, 34.055999755859375",
  },
  {
    ident: "KOPF",
    type: "medium_airport",
    name: "Miami-Opa Locka Executive Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Miami",
    gps_code: "KOPF",
    iata_code: "OPF",
    local_code: "OPF",
    coordinates: "-80.278397, 25.907",
  },
  {
    ident: "KOQU",
    type: "medium_airport",
    name: "Quonset State Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-RI",
    municipality: "North Kingstown",
    gps_code: "KOQU",
    iata_code: "NCO",
    local_code: "OQU",
    coordinates: "-71.412101745605, 41.597099304199",
  },
  {
    ident: "KOR",
    type: "small_airport",
    name: "Kakoro(Koroko) Airstrip",
    elevation_ft: "230",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Kakoro",
    gps_code: "AYRO",
    iata_code: "KOR",
    local_code: "KKO",
    coordinates: "146.5335, -7.8346666666700004",
  },
  {
    ident: "KORD",
    type: "large_airport",
    name: "Chicago O'Hare International Airport",
    elevation_ft: "672",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Chicago",
    gps_code: "KORD",
    iata_code: "ORD",
    local_code: "ORD",
    coordinates: "-87.9048, 41.9786",
  },
  {
    ident: "KORF",
    type: "large_airport",
    name: "Norfolk International Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Norfolk",
    gps_code: "KORF",
    iata_code: "ORF",
    local_code: "ORF",
    coordinates: "-76.20120239257812, 36.89459991455078",
  },
  {
    ident: "KORH",
    type: "medium_airport",
    name: "Worcester Regional Airport",
    elevation_ft: "1009",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Worcester",
    gps_code: "KORH",
    iata_code: "ORH",
    local_code: "ORH",
    coordinates: "-71.87570190429688, 42.26729965209961",
  },
  {
    ident: "KORL",
    type: "small_airport",
    name: "Orlando Executive Airport",
    elevation_ft: "113",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Orlando",
    gps_code: "KORL",
    iata_code: "ORL",
    local_code: "ORL",
    coordinates: "-81.332901, 28.5455",
  },
  {
    ident: "KORS",
    type: "small_airport",
    name: "Orcas Island Airport",
    elevation_ft: "31",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Eastsound",
    gps_code: "KORS",
    iata_code: "ESD",
    local_code: "ORS",
    coordinates: "-122.910003662, 48.7081985474",
  },
  {
    ident: "KOSC",
    type: "small_airport",
    name: "Oscoda Wurtsmith Airport",
    elevation_ft: "633",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Oscoda",
    gps_code: "KOSC",
    iata_code: "OSC",
    local_code: "OSC",
    coordinates: "-83.394096, 44.451599",
  },
  {
    ident: "KOSH",
    type: "medium_airport",
    name: "Wittman Regional Airport",
    elevation_ft: "808",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Oshkosh",
    gps_code: "KOSH",
    iata_code: "OSH",
    local_code: "OSH",
    coordinates: "-88.55699920654297, 43.98440170288086",
  },
  {
    ident: "KOSU",
    type: "medium_airport",
    name: "The Ohio State University Airport - Don Scott Field",
    elevation_ft: "905",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Columbus",
    gps_code: "KOSU",
    iata_code: "OSU",
    local_code: "OSU",
    coordinates: "-83.072998, 40.0798",
  },
  {
    ident: "KOTH",
    type: "medium_airport",
    name: "Southwest Oregon Regional Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "North Bend",
    gps_code: "KOTH",
    iata_code: "OTH",
    local_code: "OTH",
    coordinates: "-124.24600219726562, 43.41709899902344",
  },
  {
    ident: "KOTM",
    type: "medium_airport",
    name: "Ottumwa Regional Airport",
    elevation_ft: "845",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Ottumwa",
    gps_code: "KOTM",
    iata_code: "OTM",
    local_code: "OTM",
    coordinates: "-92.44789886, 41.10660172",
  },
  {
    ident: "KOUN",
    type: "small_airport",
    name: "University of Oklahoma Westheimer Airport",
    elevation_ft: "1182",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Norman",
    gps_code: "KOUN",
    iata_code: "OUN",
    local_code: "OUN",
    coordinates: "-97.472099, 35.2456",
  },
  {
    ident: "KOVE",
    type: "small_airport",
    name: "Oroville Municipal Airport",
    elevation_ft: "192",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Oroville",
    gps_code: "KOVE",
    iata_code: "OVE",
    local_code: "OVE",
    coordinates: "-121.62200164795, 39.487800598145",
  },
  {
    ident: "KOWA",
    type: "small_airport",
    name: "Owatonna Degner Regional Airport",
    elevation_ft: "1145",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Owatonna",
    gps_code: "KOWA",
    iata_code: "OWA",
    local_code: "OWA",
    coordinates: "-93.26059723, 44.12340164",
  },
  {
    ident: "KOWB",
    type: "medium_airport",
    name: "Owensboro Daviess County Airport",
    elevation_ft: "407",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Owensboro",
    gps_code: "KOWB",
    iata_code: "OWB",
    local_code: "OWB",
    coordinates: "-87.16680145, 37.74010086",
  },
  {
    ident: "KOWD",
    type: "medium_airport",
    name: "Norwood Memorial Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Norwood",
    gps_code: "KOWD",
    iata_code: "OWD",
    local_code: "OWD",
    coordinates: "-71.1728973389, 42.1904983521",
  },
  {
    ident: "KOWK",
    type: "small_airport",
    name: "Central Maine Airport of Norridgewock",
    elevation_ft: "270",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Norridgewock",
    gps_code: "KOWK",
    iata_code: "OWK",
    local_code: "OWK",
    coordinates: "-69.86650085, 44.71549988",
  },
  {
    ident: "KOXB",
    type: "small_airport",
    name: "Ocean City Municipal Airport",
    elevation_ft: "11",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Ocean City",
    gps_code: "KOXB",
    iata_code: "OCE",
    local_code: "OXB",
    coordinates: "-75.124000549316, 38.310398101807",
  },
  {
    ident: "KOXC",
    type: "small_airport",
    name: "Waterbury Oxford Airport",
    elevation_ft: "726",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CT",
    municipality: "Oxford",
    gps_code: "KOXC",
    iata_code: "OXC",
    local_code: "OXC",
    coordinates: "-73.135200500488, 41.47859954834",
  },
  {
    ident: "KOXD",
    type: "small_airport",
    name: "Miami University Airport",
    elevation_ft: "1041",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Oxford",
    gps_code: "KOXD",
    iata_code: "OXD",
    local_code: "OXD",
    coordinates: "-84.78440094, 39.50230026",
  },
  {
    ident: "KOXR",
    type: "medium_airport",
    name: "Oxnard Airport",
    elevation_ft: "45",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Oxnard",
    gps_code: "KOXR",
    iata_code: "OXR",
    local_code: "OXR",
    coordinates: "-119.20700073242, 34.200801849365",
  },
  {
    ident: "KOY",
    type: "seaplane_base",
    name: "Olga Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Olga Bay",
    gps_code: "KOY",
    iata_code: "KOY",
    local_code: "KOY",
    coordinates: "-154.229995728, 57.161499023400005",
  },
  {
    ident: "KOYM",
    type: "small_airport",
    name: "St Marys Municipal Airport",
    elevation_ft: "1934",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "St Marys",
    gps_code: "KOYM",
    iata_code: "STQ",
    local_code: "OYM",
    coordinates: "-78.502601623535, 41.412498474121",
  },
  {
    ident: "KOZA",
    type: "small_airport",
    name: "Ozona Municipal Airport",
    elevation_ft: "2381",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Ozona",
    gps_code: "KOZA",
    iata_code: "OZA",
    local_code: "OZA",
    coordinates: "-101.20300292969, 30.735300064087",
  },
  {
    ident: "KOZR",
    type: "medium_airport",
    name: "Cairns AAF (Fort Rucker) Air Field",
    elevation_ft: "301",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Fort Rucker/Ozark",
    gps_code: "KOZR",
    iata_code: "OZR",
    local_code: "OZR",
    coordinates: "-85.71340179, 31.27569962",
  },
  {
    ident: "KP-0029",
    type: "small_airport",
    name: "Samjiy����n Airport",
    elevation_ft: "4547",
    continent: "AS",
    iso_country: "KP",
    iso_region: "KP-10",
    municipality: "Samjiy����n",
    gps_code: "ZKSE",
    iata_code: "YJS",
    coordinates: "128.409834, 41.907132",
  },
  {
    ident: "KP-0032",
    type: "medium_airport",
    name: "Orang Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "KP",
    iso_region: "KP-09",
    municipality: "Hoemun-ri",
    gps_code: "ZKHM",
    iata_code: "RGO",
    coordinates: "129.647555, 41.428538",
  },
  {
    ident: "KP04",
    type: "small_airport",
    name: "Bisbee Municipal Airport",
    elevation_ft: "4780",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Bisbee",
    gps_code: "P04",
    iata_code: "BSQ",
    local_code: "P04",
    coordinates: "-109.883003235, 31.3640003204",
  },
  {
    ident: "KP10",
    type: "small_airport",
    name: "Polacca Airport",
    elevation_ft: "5573",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Polacca",
    gps_code: "P10",
    iata_code: "PXL",
    local_code: "P10",
    coordinates: "-110.422996521, 35.7916984558",
  },
  {
    ident: "KP13",
    type: "small_airport",
    name: "San Carlos Apache Airport",
    elevation_ft: "3261",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Globe",
    gps_code: "P13",
    iata_code: "GLB",
    local_code: "P13",
    coordinates: "-110.666999817, 33.353099823",
  },
  {
    ident: "KP14",
    type: "small_airport",
    name: "Holbrook Municipal Airport",
    elevation_ft: "5262",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Holbrook",
    gps_code: "P14",
    iata_code: "HBK",
    local_code: "P14",
    coordinates: "-110.138000488, 34.940700531",
  },
  {
    ident: "KP33",
    type: "small_airport",
    name: "Cochise County Airport",
    elevation_ft: "4187",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Willcox",
    gps_code: "P33",
    iata_code: "CWX",
    local_code: "P33",
    coordinates: "-109.8949966, 32.24539948",
  },
  {
    ident: "KPAE",
    type: "medium_airport",
    name: "Snohomish County (Paine Field) Airport",
    elevation_ft: "606",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Everett",
    gps_code: "KPAE",
    iata_code: "PAE",
    local_code: "PAE",
    coordinates: "-122.2819977, 47.90629959",
  },
  {
    ident: "KPAH",
    type: "medium_airport",
    name: "Barkley Regional Airport",
    elevation_ft: "410",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Paducah",
    gps_code: "KPAH",
    iata_code: "PAH",
    local_code: "PAH",
    coordinates: "-88.7738037109375, 37.06079864501953",
  },
  {
    ident: "KPAM",
    type: "large_airport",
    name: "Tyndall Air Force Base",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Panama City",
    gps_code: "KPAM",
    iata_code: "PAM",
    local_code: "PAM",
    coordinates: "-85.57540130619999, 30.0695991516",
  },
  {
    ident: "KPAN",
    type: "small_airport",
    name: "Payson Airport",
    elevation_ft: "5157",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Payson",
    gps_code: "KPAN",
    iata_code: "PJB",
    local_code: "PAN",
    coordinates: "-111.33899688721, 34.256801605225",
  },
  {
    ident: "KPAO",
    type: "small_airport",
    name: "Palo Alto Airport of Santa Clara County",
    elevation_ft: "4",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Palo Alto",
    gps_code: "KPAO",
    iata_code: "PAO",
    local_code: "PAO",
    coordinates: "-122.114997864, 37.461101532",
  },
  {
    ident: "KPB",
    type: "seaplane_base",
    name: "Point Baker Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Point Baker",
    gps_code: "KPB",
    iata_code: "KPB",
    local_code: "KPB",
    coordinates: "-133.623001099, 56.3518981934",
  },
  {
    ident: "KPBF",
    type: "medium_airport",
    name: "Pine Bluff Regional Airport, Grider Field",
    elevation_ft: "206",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Pine Bluff",
    gps_code: "KPBF",
    iata_code: "PBF",
    local_code: "PBF",
    coordinates: "-91.9356, 34.1731",
  },
  {
    ident: "KPBG",
    type: "medium_airport",
    name: "Plattsburgh International Airport",
    elevation_ft: "234",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Plattsburgh",
    gps_code: "KPBG",
    iata_code: "PBG",
    local_code: "PBG",
    coordinates: "-73.46810150146484, 44.650901794433594",
  },
  {
    ident: "KPBI",
    type: "large_airport",
    name: "Palm Beach International Airport",
    elevation_ft: "19",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "West Palm Beach",
    gps_code: "KPBI",
    iata_code: "PBI",
    local_code: "PBI",
    coordinates: "-80.09559631347656, 26.68320083618164",
  },
  {
    ident: "KPBX",
    type: "small_airport",
    name: "Pike County-Hatcher Field",
    elevation_ft: "1473",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Pikeville",
    gps_code: "KPBX",
    iata_code: "PVL",
    local_code: "PBX",
    coordinates: "-82.56639862, 37.5617981",
  },
  {
    ident: "KPDC",
    type: "small_airport",
    name: "Prairie Du Chien Municipal Airport",
    elevation_ft: "661",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Prairie Du Chien",
    gps_code: "KPDC",
    iata_code: "PCD",
    local_code: "PDC",
    coordinates: "-91.12370300293, 43.019298553467",
  },
  {
    ident: "KPDK",
    type: "medium_airport",
    name: "DeKalb Peachtree Airport",
    elevation_ft: "1003",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Atlanta",
    gps_code: "KPDK",
    iata_code: "PDK",
    local_code: "PDK",
    coordinates: "-84.3020019531, 33.8755989075",
  },
  {
    ident: "KPDT",
    type: "medium_airport",
    name: "Eastern Oregon Regional At Pendleton Airport",
    elevation_ft: "1497",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Pendleton",
    gps_code: "KPDT",
    iata_code: "PDT",
    local_code: "PDT",
    coordinates: "-118.841003418, 45.695098877",
  },
  {
    ident: "KPDX",
    type: "large_airport",
    name: "Portland International Airport",
    elevation_ft: "31",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Portland",
    gps_code: "KPDX",
    iata_code: "PDX",
    local_code: "PDX",
    coordinates: "-122.5979996, 45.58869934",
  },
  {
    ident: "KPE",
    type: "small_airport",
    name: "Yapsiei Airport",
    elevation_ft: "600",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    gps_code: "AYYP",
    iata_code: "KPE",
    local_code: "YAP",
    coordinates: "141.094166667, -4.6280555555600005",
  },
  {
    ident: "KPEQ",
    type: "small_airport",
    name: "Pecos Municipal Airport",
    elevation_ft: "2613",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Pecos",
    gps_code: "KPEQ",
    iata_code: "PEQ",
    local_code: "PEQ",
    coordinates: "-103.51100158691, 31.382400512695",
  },
  {
    ident: "KPGA",
    type: "medium_airport",
    name: "Page Municipal Airport",
    elevation_ft: "4316",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Page",
    gps_code: "KPGA",
    iata_code: "PGA",
    local_code: "PGA",
    coordinates: "-111.447998, 36.92610168",
  },
  {
    ident: "KPGD",
    type: "small_airport",
    name: "Charlotte County Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Punta Gorda",
    gps_code: "KPGD",
    iata_code: "PGD",
    local_code: "PGD",
    coordinates: "-81.9905014, 26.92020035",
  },
  {
    ident: "KPGR",
    type: "small_airport",
    name: "Kirk Field",
    elevation_ft: "290",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Paragould",
    gps_code: "KPGR",
    iata_code: "PGR",
    local_code: "PGR",
    coordinates: "-90.50779724, 36.06290054",
  },
  {
    ident: "KPGV",
    type: "medium_airport",
    name: "Pitt Greenville Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Greenville",
    gps_code: "KPGV",
    iata_code: "PGV",
    local_code: "PGV",
    coordinates: "-77.38529968, 35.6352005",
  },
  {
    ident: "KPHD",
    type: "small_airport",
    name: "Harry Clever Field",
    elevation_ft: "894",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "New Philadelphia",
    gps_code: "KPHD",
    iata_code: "PHD",
    local_code: "PHD",
    coordinates: "-81.419700622559, 40.470901489258",
  },
  {
    ident: "KPHF",
    type: "large_airport",
    name: "Newport News Williamsburg International Airport",
    elevation_ft: "42",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Newport News",
    gps_code: "KPHF",
    iata_code: "PHF",
    local_code: "PHF",
    coordinates: "-76.49299622, 37.13190079",
  },
  {
    ident: "KPHH",
    type: "small_airport",
    name: "Robert F Swinnie Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Andrews",
    gps_code: "KPHH",
    iata_code: "ADR",
    local_code: "PHH",
    coordinates: "-79.5261993408, 33.4516983032",
  },
  {
    ident: "KPHK",
    type: "small_airport",
    name: "Palm Beach County Glades Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Pahokee",
    gps_code: "KPHK",
    iata_code: "PHK",
    local_code: "PHK",
    coordinates: "-80.69339752, 26.78499985",
  },
  {
    ident: "KPHL",
    type: "large_airport",
    name: "Philadelphia International Airport",
    elevation_ft: "36",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Philadelphia",
    gps_code: "KPHL",
    iata_code: "PHL",
    local_code: "PHL",
    coordinates: "-75.24109649658203, 39.87189865112305",
  },
  {
    ident: "KPHN",
    type: "small_airport",
    name: "St Clair County International Airport",
    elevation_ft: "650",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Port Huron",
    gps_code: "KPHN",
    iata_code: "PHN",
    local_code: "PHN",
    coordinates: "-82.52890015, 42.9109993",
  },
  {
    ident: "KPHP",
    type: "small_airport",
    name: "Philip Airport",
    elevation_ft: "2207",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Philip",
    gps_code: "KPHP",
    iata_code: "PHP",
    local_code: "PHP",
    coordinates: "-101.59899902344, 44.048599243164",
  },
  {
    ident: "KPHT",
    type: "small_airport",
    name: "Henry County Airport",
    elevation_ft: "580",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Paris",
    gps_code: "KPHT",
    iata_code: "PHT",
    local_code: "PHT",
    coordinates: "-88.38289642330001, 36.338199615499995",
  },
  {
    ident: "KPHX",
    type: "large_airport",
    name: "Phoenix Sky Harbor International Airport",
    elevation_ft: "1135",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Phoenix",
    gps_code: "KPHX",
    iata_code: "PHX",
    local_code: "PHX",
    coordinates: "-112.01200103759766, 33.43429946899414",
  },
  {
    ident: "KPIA",
    type: "large_airport",
    name: "General Wayne A. Downing Peoria International Airport",
    elevation_ft: "660",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Peoria",
    gps_code: "KPIA",
    iata_code: "PIA",
    local_code: "PIA",
    coordinates: "-89.6932983398, 40.664199829100006",
  },
  {
    ident: "KPIB",
    type: "medium_airport",
    name: "Hattiesburg Laurel Regional Airport",
    elevation_ft: "298",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Hattiesburg/Laurel",
    gps_code: "KPIB",
    iata_code: "PIB",
    local_code: "PIB",
    coordinates: "-89.33709716796875, 31.467100143432617",
  },
  {
    ident: "KPIE",
    type: "medium_airport",
    name: "St Petersburg Clearwater International Airport",
    elevation_ft: "11",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "St Petersburg-Clearwater",
    gps_code: "KPIE",
    iata_code: "PIE",
    local_code: "PIE",
    coordinates: "-82.68740082, 27.91020012",
  },
  {
    ident: "KPIH",
    type: "medium_airport",
    name: "Pocatello Regional Airport",
    elevation_ft: "4452",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Pocatello",
    gps_code: "KPIH",
    iata_code: "PIH",
    local_code: "PIH",
    coordinates: "-112.59600067138672, 42.9098014831543",
  },
  {
    ident: "KPIM",
    type: "small_airport",
    name: "Harris County Airport",
    elevation_ft: "902",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Pine Mountain",
    gps_code: "KPIM",
    iata_code: "PIM",
    local_code: "PIM",
    coordinates: "-84.8824005127, 32.8406982422",
  },
  {
    ident: "KPIR",
    type: "medium_airport",
    name: "Pierre Regional Airport",
    elevation_ft: "1744",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Pierre",
    gps_code: "KPIR",
    iata_code: "PIR",
    local_code: "PIR",
    coordinates: "-100.2860031, 44.38270187",
  },
  {
    ident: "KPIT",
    type: "large_airport",
    name: "Pittsburgh International Airport",
    elevation_ft: "1203",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Pittsburgh",
    gps_code: "KPIT",
    iata_code: "PIT",
    local_code: "PIT",
    coordinates: "-80.23290253, 40.49150085",
  },
  {
    ident: "KPKB",
    type: "medium_airport",
    name: "Mid Ohio Valley Regional Airport",
    elevation_ft: "858",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Parkersburg",
    gps_code: "KPKB",
    iata_code: "PKB",
    local_code: "PKB",
    coordinates: "-81.43920135498047, 39.34510040283203",
  },
  {
    ident: "KPKD",
    type: "small_airport",
    name: "Park Rapids Municipal Konshok Field",
    elevation_ft: "1445",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Park Rapids",
    gps_code: "KPKD",
    iata_code: "PKD",
    local_code: "PKD",
    coordinates: "-95.073095, 46.9006",
  },
  {
    ident: "KPKF",
    type: "small_airport",
    name: "Park Falls Municipal Airport",
    elevation_ft: "1500",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Park Falls",
    gps_code: "KPKF",
    iata_code: "PKF",
    local_code: "PKF",
    coordinates: "-90.42440032959, 45.955001831055",
  },
  {
    ident: "KPL",
    type: "small_airport",
    name: "Kapal Airport",
    elevation_ft: "170",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Kapal",
    iata_code: "KPL",
    local_code: "KPL",
    coordinates: "142.823756, -8.630184",
  },
  {
    ident: "KPLB",
    type: "closed",
    name: "Clinton County Airport",
    elevation_ft: "371",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Plattsburgh",
    gps_code: "KPLB",
    iata_code: "PLB",
    local_code: "PLB",
    coordinates: "-73.52449798583984, 44.6875",
  },
  {
    ident: "KPLK",
    type: "small_airport",
    name: "M. Graham Clark Downtown Airport",
    elevation_ft: "940",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Branson / Hollister",
    gps_code: "KPLK",
    iata_code: "PLK",
    local_code: "PLK",
    coordinates: "-93.22889709, 36.62590027",
  },
  {
    ident: "KPLN",
    type: "medium_airport",
    name: "Pellston Regional Airport of Emmet County Airport",
    elevation_ft: "721",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Pellston",
    gps_code: "KPLN",
    iata_code: "PLN",
    local_code: "PLN",
    coordinates: "-84.79669952, 45.57089996",
  },
  {
    ident: "KPLR",
    type: "small_airport",
    name: "St Clair County Airport",
    elevation_ft: "485",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Pell City",
    gps_code: "KPLR",
    iata_code: "PLR",
    local_code: "PLR",
    coordinates: "-86.249099731445, 33.558799743652",
  },
  {
    ident: "KPMB",
    type: "small_airport",
    name: "Pembina Municipal Airport",
    elevation_ft: "795",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Pembina",
    gps_code: "KPMB",
    iata_code: "PMB",
    local_code: "PMB",
    coordinates: "-97.2407989502, 48.9425010681",
  },
  {
    ident: "KPMD",
    type: "medium_airport",
    name: "Palmdale Regional/USAF Plant 42 Airport",
    elevation_ft: "2543",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Palmdale",
    gps_code: "KPMD",
    iata_code: "PMD",
    local_code: "PMD",
    coordinates: "-118.0849991, 34.62939835",
  },
  {
    ident: "KPMH",
    type: "small_airport",
    name: "Greater Portsmouth Regional Airport",
    elevation_ft: "663",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Portsmouth",
    gps_code: "KPMH",
    iata_code: "PMH",
    local_code: "PMH",
    coordinates: "-82.84729766850002, 38.8404998779",
  },
  {
    ident: "KPMP",
    type: "small_airport",
    name: "Pompano Beach Airpark",
    elevation_ft: "19",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Pompano Beach",
    gps_code: "KPMP",
    iata_code: "PPM",
    local_code: "PMP",
    coordinates: "-80.111099243164, 26.247100830078",
  },
  {
    ident: "KPNA",
    type: "medium_airport",
    name: "Ralph Wenz Field",
    elevation_ft: "7102",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Pinedale",
    gps_code: "KPNA",
    iata_code: "PWY",
    local_code: "PNA",
    coordinates: "-109.8069992, 42.79550171",
  },
  {
    ident: "KPNC",
    type: "medium_airport",
    name: "Ponca City Regional Airport",
    elevation_ft: "1008",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Ponca City",
    gps_code: "KPNC",
    iata_code: "PNC",
    local_code: "PNC",
    coordinates: "-97.09980011, 36.73199844",
  },
  {
    ident: "KPNE",
    type: "medium_airport",
    name: "Northeast Philadelphia Airport",
    elevation_ft: "120",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Philadelphia",
    gps_code: "KPNE",
    iata_code: "PNE",
    local_code: "PNE",
    coordinates: "-75.010597, 40.081902",
  },
  {
    ident: "KPNN",
    type: "small_airport",
    name: "Princeton Municipal Airport",
    elevation_ft: "266",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Princeton",
    gps_code: "KPNN",
    iata_code: "PNN",
    local_code: "PNN",
    coordinates: "-67.564399719238, 45.200698852539",
  },
  {
    ident: "KPNS",
    type: "medium_airport",
    name: "Pensacola International Airport",
    elevation_ft: "121",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Pensacola",
    gps_code: "KPNS",
    iata_code: "PNS",
    local_code: "PNS",
    coordinates: "-87.1866, 30.4734",
  },
  {
    ident: "KPOB",
    type: "medium_airport",
    name: "Pope Field",
    elevation_ft: "217",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Fayetteville",
    gps_code: "KPOB",
    iata_code: "POB",
    local_code: "POB",
    coordinates: "-79.014503479004, 35.1708984375",
  },
  {
    ident: "KPOC",
    type: "small_airport",
    name: "Brackett Field",
    elevation_ft: "1011",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "La Verne",
    gps_code: "KPOC",
    iata_code: "POC",
    local_code: "POC",
    coordinates: "-117.78199768066, 34.091598510742",
  },
  {
    ident: "KPOE",
    type: "medium_airport",
    name: "Polk Army Air Field",
    elevation_ft: "330",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Fort Polk",
    gps_code: "KPOE",
    iata_code: "POE",
    local_code: "POE",
    coordinates: "-93.1917038, 31.0447998",
  },
  {
    ident: "KPOF",
    type: "small_airport",
    name: "Poplar Bluff Municipal Airport",
    elevation_ft: "331",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Poplar Bluff",
    gps_code: "KPOF",
    iata_code: "POF",
    local_code: "POF",
    coordinates: "-90.324897766113, 36.773899078369",
  },
  {
    ident: "KPOU",
    type: "medium_airport",
    name: "Dutchess County Airport",
    elevation_ft: "165",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Poughkeepsie",
    gps_code: "KPOU",
    iata_code: "POU",
    local_code: "POU",
    coordinates: "-73.88420104980469, 41.6265983581543",
  },
  {
    ident: "KPOY",
    type: "small_airport",
    name: "Powell Municipal Airport",
    elevation_ft: "5092",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Powell",
    gps_code: "KPOY",
    iata_code: "POY",
    local_code: "POY",
    coordinates: "-108.79299926758, 44.867198944092",
  },
  {
    ident: "KPPA",
    type: "small_airport",
    name: "Perry Lefors Field",
    elevation_ft: "3245",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Pampa",
    gps_code: "KPPA",
    iata_code: "PPA",
    local_code: "PPA",
    coordinates: "-100.99600219727, 35.612998962402",
  },
  {
    ident: "KPPF",
    type: "small_airport",
    name: "Tri-City Airport",
    elevation_ft: "900",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Parsons",
    gps_code: "KPPF",
    iata_code: "PPF",
    local_code: "PPF",
    coordinates: "-95.5062027, 37.32989883",
  },
  {
    ident: "KPPO",
    type: "small_airport",
    name: "La Porte Municipal Airport",
    elevation_ft: "812",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "La Porte",
    gps_code: "KPPO",
    iata_code: "LPO",
    local_code: "PPO",
    coordinates: "-86.73449707030001, 41.5724983215",
  },
  {
    ident: "KPQI",
    type: "medium_airport",
    name: "Presque Isle International Airport",
    elevation_ft: "534",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Presque Isle",
    gps_code: "KPQI",
    iata_code: "PQI",
    local_code: "PQI",
    coordinates: "-68.0448, 46.688999",
  },
  {
    ident: "KPQL",
    type: "small_airport",
    name: "Trent Lott International Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Pascagoula",
    gps_code: "KPQL",
    iata_code: "PGL",
    local_code: "PQL",
    coordinates: "-88.529197692871, 30.462799072266",
  },
  {
    ident: "KPR",
    type: "seaplane_base",
    name: "Port Williams Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Williams",
    gps_code: "KPR",
    iata_code: "KPR",
    local_code: "KPR",
    coordinates: "-152.582000732, 58.4901008606",
  },
  {
    ident: "KPRB",
    type: "medium_airport",
    name: "Paso Robles Municipal Airport",
    elevation_ft: "840",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Paso Robles",
    gps_code: "KPRB",
    iata_code: "PRB",
    local_code: "PRB",
    coordinates: "-120.6269989, 35.67290115",
  },
  {
    ident: "KPRC",
    type: "medium_airport",
    name: "Prescott Regional Airport - Ernest A. Love Field",
    elevation_ft: "5045",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Prescott",
    gps_code: "KPRC",
    iata_code: "PRC",
    local_code: "PRC",
    coordinates: "-112.419998, 34.654499",
  },
  {
    ident: "KPRO",
    type: "small_airport",
    name: "Perry Municipal Airport",
    elevation_ft: "1013",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Perry",
    gps_code: "KPRO",
    iata_code: "PRO",
    local_code: "PRO",
    coordinates: "-94.15989685, 41.82799911",
  },
  {
    ident: "KPRX",
    type: "medium_airport",
    name: "Cox Field",
    elevation_ft: "547",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Paris",
    gps_code: "KPRX",
    iata_code: "PRX",
    local_code: "PRX",
    coordinates: "-95.450798034668, 33.636600494385",
  },
  {
    ident: "KPSC",
    type: "medium_airport",
    name: "Tri Cities Airport",
    elevation_ft: "410",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Pasco",
    gps_code: "KPSC",
    iata_code: "PSC",
    local_code: "PSC",
    coordinates: "-119.11900329589844, 46.26470184326172",
  },
  {
    ident: "KPSF",
    type: "small_airport",
    name: "Pittsfield Municipal Airport",
    elevation_ft: "1194",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Pittsfield",
    gps_code: "KPSF",
    iata_code: "PSF",
    local_code: "PSF",
    coordinates: "-73.2929, 42.4268",
  },
  {
    ident: "KPSK",
    type: "small_airport",
    name: "New River Valley Airport",
    elevation_ft: "2105",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Dublin",
    gps_code: "KPSK",
    iata_code: "PSK",
    local_code: "PSK",
    coordinates: "-80.678497314453, 37.137298583984",
  },
  {
    ident: "KPSM",
    type: "medium_airport",
    name: "Portsmouth International at Pease Airport",
    elevation_ft: "100",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NH",
    municipality: "Portsmouth",
    gps_code: "KPSM",
    iata_code: "PSM",
    local_code: "PSM",
    coordinates: "-70.8233032227, 43.0778999329",
  },
  {
    ident: "KPSN",
    type: "small_airport",
    name: "Palestine Municipal Airport",
    elevation_ft: "423",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Palestine",
    gps_code: "KPSN",
    iata_code: "PSN",
    local_code: "PSN",
    coordinates: "-95.706298828125, 31.779699325562",
  },
  {
    ident: "KPSO",
    type: "small_airport",
    name: "Stevens Field",
    elevation_ft: "7664",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Pagosa Springs",
    gps_code: "KPSO",
    iata_code: "PGO",
    local_code: "PSO",
    coordinates: "-107.0559998, 37.28630066",
  },
  {
    ident: "KPSP",
    type: "medium_airport",
    name: "Palm Springs International Airport",
    elevation_ft: "477",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Palm Springs",
    gps_code: "KPSP",
    iata_code: "PSP",
    local_code: "PSP",
    coordinates: "-116.50700378417969, 33.8297004699707",
  },
  {
    ident: "KPSX",
    type: "small_airport",
    name: "Palacios Municipal Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Palacios",
    gps_code: "KPSX",
    iata_code: "PSX",
    local_code: "PSX",
    coordinates: "-96.250999450684, 28.727500915527",
  },
  {
    ident: "KPTB",
    type: "small_airport",
    name: "Dinwiddie County Airport",
    elevation_ft: "193",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Petersburg",
    gps_code: "KPTB",
    iata_code: "PTB",
    local_code: "PTB",
    coordinates: "-77.507400512695, 37.183799743652",
  },
  {
    ident: "KPTK",
    type: "medium_airport",
    name: "Oakland County International Airport",
    elevation_ft: "980",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Pontiac",
    gps_code: "KPTK",
    iata_code: "PTK",
    local_code: "PTK",
    coordinates: "-83.420097351074, 42.665500640869",
  },
  {
    ident: "KPTN",
    type: "small_airport",
    name: "Harry P Williams Memorial Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Patterson",
    gps_code: "KPTN",
    iata_code: "PTN",
    local_code: "PTN",
    coordinates: "-91.338996887207, 29.709499359131",
  },
  {
    ident: "KPTT",
    type: "small_airport",
    name: "Pratt Regional Airport",
    elevation_ft: "1953",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Pratt",
    gps_code: "KPTT",
    iata_code: "PTT",
    local_code: "PTT",
    coordinates: "-98.74690247, 37.70159912",
  },
  {
    ident: "KPTV",
    type: "small_airport",
    name: "Porterville Municipal Airport",
    elevation_ft: "442",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Porterville",
    gps_code: "KPTV",
    iata_code: "PTV",
    local_code: "PTV",
    coordinates: "-119.06300354004, 36.029598236084",
  },
  {
    ident: "KPTW",
    type: "small_airport",
    name: "Heritage Field",
    elevation_ft: "309",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Pottstown",
    gps_code: "KPTW",
    iata_code: "PTW",
    local_code: "PTW",
    coordinates: "-75.556702, 40.239601",
  },
  {
    ident: "KPUB",
    type: "small_airport",
    name: "Pueblo Memorial Airport",
    elevation_ft: "4726",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Pueblo",
    gps_code: "KPUB",
    iata_code: "PUB",
    local_code: "PUB",
    coordinates: "-104.49700164794922, 38.289100646972656",
  },
  {
    ident: "KPUC",
    type: "small_airport",
    name: "Carbon County Regional/Buck Davis Field",
    elevation_ft: "5957",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Price",
    gps_code: "KPUC",
    iata_code: "PUC",
    local_code: "PUC",
    coordinates: "-110.7509995, 39.61389923",
  },
  {
    ident: "KPUW",
    type: "medium_airport",
    name: "Pullman Moscow Regional Airport",
    elevation_ft: "2556",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Pullman/Moscow",
    gps_code: "KPUW",
    iata_code: "PUW",
    local_code: "PUW",
    coordinates: "-117.110001, 46.7439",
  },
  {
    ident: "KPVC",
    type: "small_airport",
    name: "Provincetown Municipal Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Provincetown",
    gps_code: "KPVC",
    iata_code: "PVC",
    local_code: "PVC",
    coordinates: "-70.2213973999, 42.0718994141",
  },
  {
    ident: "KPVD",
    type: "large_airport",
    name: "Theodore Francis Green State Airport",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-RI",
    municipality: "Providence",
    gps_code: "KPVD",
    iata_code: "PVD",
    local_code: "PVD",
    coordinates: "-71.420403, 41.732601",
  },
  {
    ident: "KPVF",
    type: "small_airport",
    name: "Placerville Airport",
    elevation_ft: "2585",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Placerville",
    gps_code: "KPVF",
    iata_code: "PVF",
    local_code: "PVF",
    coordinates: "-120.75299835205, 38.724201202393",
  },
  {
    ident: "KPVU",
    type: "medium_airport",
    name: "Provo Municipal Airport",
    elevation_ft: "4497",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Provo",
    gps_code: "KPVU",
    iata_code: "PVU",
    local_code: "PVU",
    coordinates: "-111.72299957275, 40.219200134277",
  },
  {
    ident: "KPVW",
    type: "small_airport",
    name: "Hale County Airport",
    elevation_ft: "3374",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Plainview",
    gps_code: "KPVW",
    iata_code: "PVW",
    local_code: "PVW",
    coordinates: "-101.71700286865, 34.168098449707",
  },
  {
    ident: "KPVZ",
    type: "closed",
    name: "Casement Airport",
    elevation_ft: "699",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Plainesville",
    gps_code: "KPVZ",
    iata_code: "PVZ",
    local_code: "PVZ",
    coordinates: "-81.21920013430001, 41.73360061649999",
  },
  {
    ident: "KPWA",
    type: "small_airport",
    name: "Wiley Post Airport",
    elevation_ft: "1300",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Oklahoma City",
    gps_code: "KPWA",
    iata_code: "PWA",
    local_code: "PWA",
    coordinates: "-97.64710236, 35.53419876",
  },
  {
    ident: "KPWD",
    type: "small_airport",
    name: "Sher-Wood Airport",
    elevation_ft: "2250",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Plentywood",
    gps_code: "KPWD",
    iata_code: "PWD",
    local_code: "PWD",
    coordinates: "-104.53399658203, 48.790298461914",
  },
  {
    ident: "KPWK",
    type: "medium_airport",
    name: "Chicago Executive Airport",
    elevation_ft: "647",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Chicago/Prospect Heights/Wheeling",
    gps_code: "KPWK",
    iata_code: "PWK",
    local_code: "PWK",
    coordinates: "-87.901494, 42.114222",
  },
  {
    ident: "KPWM",
    type: "large_airport",
    name: "Portland International Jetport",
    elevation_ft: "76",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Portland",
    gps_code: "KPWM",
    iata_code: "PWM",
    local_code: "PWM",
    coordinates: "-70.309303, 43.646198",
  },
  {
    ident: "KPWT",
    type: "medium_airport",
    name: "Bremerton National Airport",
    elevation_ft: "444",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Bremerton",
    gps_code: "KPWT",
    iata_code: "PWT",
    local_code: "PWT",
    coordinates: "-122.76499938965, 47.490200042725",
  },
  {
    ident: "KPY",
    type: "seaplane_base",
    name: "Port Bailey Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Bailey",
    gps_code: "KPY",
    iata_code: "KPY",
    local_code: "KPY",
    coordinates: "-153.041000366, 57.930099487300005",
  },
  {
    ident: "KPYM",
    type: "small_airport",
    name: "Plymouth Municipal Airport",
    elevation_ft: "148",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Plymouth",
    gps_code: "KPYM",
    iata_code: "PYM",
    local_code: "PYM",
    coordinates: "-70.728798, 41.909",
  },
  {
    ident: "KQA",
    type: "seaplane_base",
    name: "Akutan Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Akutan",
    gps_code: "KQA",
    iata_code: "KQA",
    local_code: "KQA",
    coordinates: "-165.778895617, 54.1337704415",
  },
  {
    ident: "KQL",
    type: "small_airport",
    name: "Kol Airport",
    elevation_ft: "5350",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-JI",
    municipality: "Kol",
    gps_code: "AYOL",
    iata_code: "KQL",
    local_code: "KOL",
    coordinates: "144.846, -5.73116666667",
  },
  {
    ident: "KR-0018",
    type: "heliport",
    name: "Chuja Port Heliport",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-49",
    municipality: "Chuja, Hach������uja-do (Chujado Islands)",
    iata_code: "JCJ",
    coordinates: "126.324899, 33.941188",
  },
  {
    ident: "KRAC",
    type: "small_airport",
    name: "John H Batten Airport",
    elevation_ft: "674",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Racine",
    gps_code: "KRAC",
    iata_code: "RAC",
    local_code: "RAC",
    coordinates: "-87.8152008057, 42.7606010437",
  },
  {
    ident: "KRAL",
    type: "medium_airport",
    name: "Riverside Municipal Airport",
    elevation_ft: "819",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Riverside",
    gps_code: "KRAL",
    iata_code: "RAL",
    local_code: "RAL",
    coordinates: "-117.4449997, 33.95190048",
  },
  {
    ident: "KRAP",
    type: "medium_airport",
    name: "Rapid City Regional Airport",
    elevation_ft: "3204",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Rapid City",
    gps_code: "KRAP",
    iata_code: "RAP",
    local_code: "RAP",
    coordinates: "-103.05699920654297, 44.0452995300293",
  },
  {
    ident: "KRBD",
    type: "small_airport",
    name: "Dallas Executive Airport",
    elevation_ft: "660",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Dallas",
    gps_code: "KRBD",
    iata_code: "RBD",
    local_code: "RBD",
    coordinates: "-96.8682022095, 32.6809005737",
  },
  {
    ident: "KRBG",
    type: "small_airport",
    name: "Roseburg Regional Airport",
    elevation_ft: "529",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Roseburg",
    gps_code: "KRBG",
    iata_code: "RBG",
    local_code: "RBG",
    coordinates: "-123.356002808, 43.238800048799995",
  },
  {
    ident: "KRBL",
    type: "medium_airport",
    name: "Red Bluff Municipal Airport",
    elevation_ft: "352",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Red Bluff",
    gps_code: "KRBL",
    iata_code: "RBL",
    local_code: "RBL",
    coordinates: "-122.251998901, 40.1506996155",
  },
  {
    ident: "KRBW",
    type: "small_airport",
    name: "Lowcountry Regional Airport",
    elevation_ft: "101",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Walterboro",
    gps_code: "KRBW",
    iata_code: "RBW",
    local_code: "RBW",
    coordinates: "-80.6406021118, 32.921001434299995",
  },
  {
    ident: "KRCA",
    type: "medium_airport",
    name: "Ellsworth Air Force Base",
    elevation_ft: "3276",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Rapid City",
    gps_code: "KRCA",
    iata_code: "RCA",
    local_code: "RCA",
    coordinates: "-103.1039963, 44.14500046",
  },
  {
    ident: "KRCK",
    type: "small_airport",
    name: "H H Coffield Regional Airport",
    elevation_ft: "474",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Rockdale",
    gps_code: "KRCK",
    iata_code: "RCK",
    local_code: "RCK",
    coordinates: "-96.9897003174, 30.6315994263",
  },
  {
    ident: "KRCR",
    type: "small_airport",
    name: "Fulton County Airport",
    elevation_ft: "790",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Rochester",
    gps_code: "KRCR",
    iata_code: "RCR",
    local_code: "RCR",
    coordinates: "-86.18170166019999, 41.065601348899996",
  },
  {
    ident: "KRCT",
    type: "small_airport",
    name: "Nartron Field",
    elevation_ft: "1055",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Reed City",
    gps_code: "KRCT",
    iata_code: "RCT",
    local_code: "RCT",
    coordinates: "-85.5167007446, 43.9000015259",
  },
  {
    ident: "KRDD",
    type: "medium_airport",
    name: "Redding Municipal Airport",
    elevation_ft: "505",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Redding",
    gps_code: "KRDD",
    iata_code: "RDD",
    local_code: "RDD",
    coordinates: "-122.2929993, 40.50899887",
  },
  {
    ident: "KRDG",
    type: "medium_airport",
    name: "Reading Regional Carl A Spaatz Field",
    elevation_ft: "344",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Reading",
    gps_code: "KRDG",
    iata_code: "RDG",
    local_code: "RDG",
    coordinates: "-75.96520233154297, 40.378501892089844",
  },
  {
    ident: "KRDM",
    type: "medium_airport",
    name: "Roberts Field",
    elevation_ft: "3080",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Redmond",
    gps_code: "KRDM",
    iata_code: "RDM",
    local_code: "RDM",
    coordinates: "-121.1500015, 44.2541008",
  },
  {
    ident: "KRDR",
    type: "medium_airport",
    name: "Grand Forks Air Force Base",
    elevation_ft: "913",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Grand Forks",
    gps_code: "KRDR",
    iata_code: "RDR",
    local_code: "RDR",
    coordinates: "-97.4011993408, 47.961101532",
  },
  {
    ident: "KRDU",
    type: "large_airport",
    name: "Raleigh Durham International Airport",
    elevation_ft: "435",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Raleigh/Durham",
    gps_code: "KRDU",
    iata_code: "RDU",
    local_code: "RDU",
    coordinates: "-78.7874984741211, 35.877601623535156",
  },
  {
    ident: "KREO",
    type: "small_airport",
    name: "Rome State Airport",
    elevation_ft: "4053",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Rome",
    gps_code: "KREO",
    iata_code: "REO",
    local_code: "REO",
    coordinates: "-117.885002136, 42.5777015686",
  },
  {
    ident: "KRFD",
    type: "large_airport",
    name: "Chicago Rockford International Airport",
    elevation_ft: "742",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Chicago/Rockford",
    gps_code: "KRFD",
    iata_code: "RFD",
    local_code: "RFD",
    coordinates: "-89.09719848632812, 42.19540023803711",
  },
  {
    ident: "KRFG",
    type: "small_airport",
    name: "Rooke Field",
    elevation_ft: "56",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Refugio",
    gps_code: "KRFG",
    iata_code: "RFG",
    local_code: "RFG",
    coordinates: "-97.322998, 28.2936",
  },
  {
    ident: "KRHI",
    type: "medium_airport",
    name: "Rhinelander Oneida County Airport",
    elevation_ft: "1624",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Rhinelander",
    gps_code: "KRHI",
    iata_code: "RHI",
    local_code: "RHI",
    coordinates: "-89.46749877929688, 45.63119888305664",
  },
  {
    ident: "KRHV",
    type: "small_airport",
    name: "Reid-Hillview Airport of Santa Clara County",
    elevation_ft: "135",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Jose",
    gps_code: "KRHV",
    iata_code: "RHV",
    local_code: "RHV",
    coordinates: "-121.819000244, 37.332901001",
  },
  {
    ident: "KRIC",
    type: "large_airport",
    name: "Richmond International Airport",
    elevation_ft: "167",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Richmond",
    gps_code: "KRIC",
    iata_code: "RIC",
    local_code: "RIC",
    coordinates: "-77.3197021484375, 37.50519943237305",
  },
  {
    ident: "KRIL",
    type: "medium_airport",
    name: "Garfield County Regional Airport",
    elevation_ft: "5548",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Rifle",
    gps_code: "KRIL",
    iata_code: "RIL",
    local_code: "RIL",
    coordinates: "-107.726997, 39.526299",
  },
  {
    ident: "KRIV",
    type: "medium_airport",
    name: "March ARB Airport",
    elevation_ft: "1536",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Riverside",
    gps_code: "KRIV",
    iata_code: "RIV",
    local_code: "RIV",
    coordinates: "-117.259003, 33.880699",
  },
  {
    ident: "KRIW",
    type: "medium_airport",
    name: "Riverton Regional Airport",
    elevation_ft: "5525",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Riverton",
    gps_code: "KRIW",
    iata_code: "RIW",
    local_code: "RIW",
    coordinates: "-108.459999084, 43.064201355",
  },
  {
    ident: "KRJ",
    type: "small_airport",
    name: "Karawari Airstrip",
    elevation_ft: "70",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Amboin",
    gps_code: "AYQA",
    iata_code: "KRJ",
    local_code: "KRR",
    coordinates: "143.5225, -4.59666666667",
  },
  {
    ident: "KRKD",
    type: "small_airport",
    name: "Knox County Regional Airport",
    elevation_ft: "56",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Rockland",
    gps_code: "KRKD",
    iata_code: "RKD",
    local_code: "RKD",
    coordinates: "-69.09919739, 44.06010056",
  },
  {
    ident: "KRKP",
    type: "small_airport",
    name: "Aransas County Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Rockport",
    gps_code: "KRKP",
    iata_code: "RKP",
    local_code: "RKP",
    coordinates: "-97.0446014404, 28.0867996216",
  },
  {
    ident: "KRKS",
    type: "medium_airport",
    name: "Southwest Wyoming Regional Airport",
    elevation_ft: "6764",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Rock Springs",
    gps_code: "KRKS",
    iata_code: "RKS",
    local_code: "RKS",
    coordinates: "-109.065001, 41.5942",
  },
  {
    ident: "KRKW",
    type: "small_airport",
    name: "Rockwood Municipal Airport",
    elevation_ft: "1664",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Rockwood",
    gps_code: "KRKW",
    iata_code: "RKW",
    local_code: "RKW",
    coordinates: "-84.6896972656, 35.9222984314",
  },
  {
    ident: "KRME",
    type: "medium_airport",
    name: "Griffiss International Airport",
    elevation_ft: "504",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Rome",
    gps_code: "KRME",
    iata_code: "RME",
    local_code: "RME",
    coordinates: "-75.40699768, 43.23379898",
  },
  {
    ident: "KRMG",
    type: "medium_airport",
    name: "Richard B Russell Airport",
    elevation_ft: "644",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Rome",
    gps_code: "KRMG",
    iata_code: "RMG",
    local_code: "RMG",
    coordinates: "-85.15799713130001, 34.3506011963",
  },
  {
    ident: "KRNC",
    type: "small_airport",
    name: "Warren County Memorial Airport",
    elevation_ft: "1032",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Mc Minnville",
    gps_code: "KRNC",
    iata_code: "RNC",
    local_code: "RNC",
    coordinates: "-85.84380341, 35.69869995",
  },
  {
    ident: "KRND",
    type: "large_airport",
    name: "Randolph Air Force Base",
    elevation_ft: "761",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Universal City",
    gps_code: "KRND",
    iata_code: "RND",
    local_code: "RND",
    coordinates: "-98.27890015, 29.52969933",
  },
  {
    ident: "KRNO",
    type: "large_airport",
    name: "Reno Tahoe International Airport",
    elevation_ft: "4415",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Reno",
    gps_code: "KRNO",
    iata_code: "RNO",
    local_code: "RNO",
    coordinates: "-119.76799774169922, 39.49909973144531",
  },
  {
    ident: "KRNT",
    type: "small_airport",
    name: "Renton Municipal Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Renton",
    gps_code: "KRNT",
    iata_code: "RNT",
    local_code: "RNT",
    coordinates: "-122.216003418, 47.4930992126",
  },
  {
    ident: "KROA",
    type: "large_airport",
    name: "Roanoke������Blacksburg Regional Airport",
    elevation_ft: "1175",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Roanoke",
    gps_code: "KROA",
    iata_code: "ROA",
    local_code: "ROA",
    coordinates: "-79.975403, 37.3255",
  },
  {
    ident: "KROC",
    type: "large_airport",
    name: "Greater Rochester International Airport",
    elevation_ft: "559",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Rochester",
    gps_code: "KROC",
    iata_code: "ROC",
    local_code: "ROC",
    coordinates: "-77.67240142822266, 43.118900299072266",
  },
  {
    ident: "KROG",
    type: "small_airport",
    name: "Rogers Municipal Airport-Carter Field",
    elevation_ft: "1359",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Rogers",
    gps_code: "KROG",
    iata_code: "ROG",
    local_code: "ROG",
    coordinates: "-94.10690308, 36.37229919",
  },
  {
    ident: "KROW",
    type: "medium_airport",
    name: "Roswell Air Center Airport",
    elevation_ft: "3671",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Roswell",
    gps_code: "KROW",
    iata_code: "ROW",
    local_code: "ROW",
    coordinates: "-104.530998, 33.301601",
  },
  {
    ident: "KROX",
    type: "small_airport",
    name: "Roseau Municipal Rudy Billberg Field",
    elevation_ft: "1060",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Roseau",
    gps_code: "KROX",
    iata_code: "ROX",
    local_code: "ROX",
    coordinates: "-95.6969986, 48.85599899",
  },
  {
    ident: "KRPD",
    type: "small_airport",
    name: "Rice Lake Regional Airport - Carl's Field",
    elevation_ft: "1109",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Rice Lake",
    gps_code: "KRPD",
    iata_code: "RIE",
    local_code: "RPD",
    coordinates: "-91.773499, 45.418999",
  },
  {
    ident: "KRPX",
    type: "small_airport",
    name: "Roundup Airport",
    elevation_ft: "3500",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Roundup",
    gps_code: "KRPX",
    iata_code: "RPX",
    local_code: "RPX",
    coordinates: "-108.541497, 46.475095",
  },
  {
    ident: "KRQB",
    type: "small_airport",
    name: "Roben Hood Airport",
    elevation_ft: "990",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Big Rapids",
    gps_code: "KRQB",
    iata_code: "WBR",
    local_code: "RQB",
    coordinates: "-85.50409698490002, 43.7225990295",
  },
  {
    ident: "KRQO",
    type: "small_airport",
    name: "El Reno Regional Airport",
    elevation_ft: "1420",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "El Reno",
    gps_code: "KRQO",
    iata_code: "RQO",
    local_code: "RQO",
    coordinates: "-98.00579834, 35.47269821",
  },
  {
    ident: "KRRL",
    type: "small_airport",
    name: "Merrill Municipal Airport",
    elevation_ft: "1318",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Merrill",
    gps_code: "KRRL",
    iata_code: "RRL",
    local_code: "RRL",
    coordinates: "-89.7128982544, 45.1988983154",
  },
  {
    ident: "KRRT",
    type: "small_airport",
    name: "Warroad International Memorial Airport",
    elevation_ft: "1076",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Warroad",
    gps_code: "KRRT",
    iata_code: "RRT",
    local_code: "RRT",
    coordinates: "-95.3483963, 48.94139862",
  },
  {
    ident: "KRSL",
    type: "medium_airport",
    name: "Russell Municipal Airport",
    elevation_ft: "1862",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Russell",
    gps_code: "KRSL",
    iata_code: "RSL",
    local_code: "RSL",
    coordinates: "-98.811798095703, 38.872100830078",
  },
  {
    ident: "KRSN",
    type: "small_airport",
    name: "Ruston Regional Airport",
    elevation_ft: "311",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Ruston",
    gps_code: "KRSN",
    iata_code: "RSN",
    local_code: "RSN",
    coordinates: "-92.591697692871, 32.514400482178",
  },
  {
    ident: "KRST",
    type: "large_airport",
    name: "Rochester International Airport",
    elevation_ft: "1317",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Rochester",
    gps_code: "KRST",
    iata_code: "RST",
    local_code: "RST",
    coordinates: "-92.5, 43.90829849243164",
  },
  {
    ident: "KRSW",
    type: "large_airport",
    name: "Southwest Florida International Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Fort Myers",
    gps_code: "KRSW",
    iata_code: "RSW",
    local_code: "RSW",
    coordinates: "-81.75520324707031, 26.53619956970215",
  },
  {
    ident: "KRTN",
    type: "small_airport",
    name: "Raton Municipal-Crews Field",
    elevation_ft: "6352",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Raton",
    gps_code: "KRTN",
    iata_code: "RTN",
    local_code: "RTN",
    coordinates: "-104.5019989, 36.74150085",
  },
  {
    ident: "KRU",
    type: "small_airport",
    name: "Kerau Airport",
    elevation_ft: "7360",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Gunim",
    gps_code: "AYEA",
    iata_code: "KRU",
    local_code: "KEU",
    coordinates: "147.071899, -8.271612",
  },
  {
    ident: "KRUQ",
    type: "small_airport",
    name: "Mid-Carolina Regional Airport",
    elevation_ft: "772",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Salisbury",
    gps_code: "KRUQ",
    iata_code: "SRW",
    local_code: "RUQ",
    coordinates: "-80.520302, 35.645901",
  },
  {
    ident: "KRUT",
    type: "medium_airport",
    name: "Rutland - Southern Vermont Regional Airport",
    elevation_ft: "787",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VT",
    municipality: "Rutland",
    gps_code: "KRUT",
    iata_code: "RUT",
    local_code: "RUT",
    coordinates: "-72.94960022, 43.52939987",
  },
  {
    ident: "KRVL",
    type: "small_airport",
    name: "Mifflin County Airport",
    elevation_ft: "819",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Reedsville",
    gps_code: "KRVL",
    iata_code: "RED",
    local_code: "RVL",
    coordinates: "-77.6268005371, 40.6773986816",
  },
  {
    ident: "KRVS",
    type: "medium_airport",
    name: "Richard Lloyd Jones Jr Airport",
    elevation_ft: "638",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Tulsa",
    gps_code: "KRVS",
    iata_code: "RVS",
    local_code: "RVS",
    coordinates: "-95.984596252441, 36.039600372314",
  },
  {
    ident: "KRWF",
    type: "medium_airport",
    name: "Redwood Falls Municipal Airport",
    elevation_ft: "1024",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Redwood Falls",
    gps_code: "KRWF",
    iata_code: "RWF",
    local_code: "RWF",
    coordinates: "-95.08229828, 44.54719925",
  },
  {
    ident: "KRWI",
    type: "medium_airport",
    name: "Rocky Mount Wilson Regional Airport",
    elevation_ft: "159",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Rocky Mount",
    gps_code: "KRWI",
    iata_code: "RWI",
    local_code: "RWI",
    coordinates: "-77.89189910888672, 35.856300354003906",
  },
  {
    ident: "KRWL",
    type: "medium_airport",
    name: "Rawlins Municipal Airport/Harvey Field",
    elevation_ft: "6813",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Rawlins",
    gps_code: "KRWL",
    iata_code: "RWL",
    local_code: "RWL",
    coordinates: "-107.1999969, 41.80559921",
  },
  {
    ident: "KRXE",
    type: "small_airport",
    name: "Rexburg Madison County Airport",
    elevation_ft: "4858",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Rexburg",
    gps_code: "KRXE",
    iata_code: "RXE",
    local_code: "RXE",
    coordinates: "-111.805002, 43.8339",
  },
  {
    ident: "KRZL",
    type: "small_airport",
    name: "Jasper County Airport",
    elevation_ft: "698",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Rensselaer",
    gps_code: "KRZL",
    iata_code: "RNZ",
    local_code: "RZL",
    coordinates: "-87.1826019287, 40.9478988647",
  },
  {
    ident: "KRZZ",
    type: "closed",
    name: "Halifax County Airport",
    elevation_ft: "256",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Roanoke Rapids",
    gps_code: "KRZZ",
    iata_code: "RZZ",
    local_code: "RZZ",
    coordinates: "-77.7092971802, 36.4394989014",
  },
  {
    ident: "KS03",
    type: "small_airport",
    name: "Ashland Municipal Sumner Parker Field",
    elevation_ft: "1885",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Ashland",
    gps_code: "KS03",
    iata_code: "AHM",
    local_code: "S03",
    coordinates: "-122.661003113, 42.190299987799996",
  },
  {
    ident: "KS05",
    type: "small_airport",
    name: "Bandon State Airport",
    elevation_ft: "122",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Bandon",
    gps_code: "S05",
    iata_code: "BDY",
    local_code: "S05",
    coordinates: "-124.4079971, 43.08649826",
  },
  {
    ident: "KS21",
    type: "small_airport",
    name: "Sunriver Airport",
    elevation_ft: "4164",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Sunriver",
    gps_code: "S21",
    iata_code: "SUO",
    local_code: "S21",
    coordinates: "-121.45300293, 43.8763008118",
  },
  {
    ident: "KS33",
    type: "small_airport",
    name: "Madras Municipal Airport",
    elevation_ft: "2437",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Madras",
    gps_code: "KS33",
    iata_code: "MDJ",
    local_code: "S33",
    coordinates: "-121.1549988, 44.67020035",
  },
  {
    ident: "KS39",
    type: "small_airport",
    name: "Prineville Airport",
    elevation_ft: "3250",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Prineville",
    gps_code: "S39",
    iata_code: "PRZ",
    local_code: "S39",
    coordinates: "-120.90399932861, 44.286998748779",
  },
  {
    ident: "KS80",
    type: "small_airport",
    name: "Idaho County Airport",
    elevation_ft: "3314",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Grangeville",
    gps_code: "KGIC",
    iata_code: "IDH",
    local_code: "GIC",
    coordinates: "-116.123001, 45.9426",
  },
  {
    ident: "KSAA",
    type: "small_airport",
    name: "Shively Field",
    elevation_ft: "7012",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Saratoga",
    gps_code: "KSAA",
    iata_code: "SAA",
    local_code: "SAA",
    coordinates: "-106.823997, 41.444901",
  },
  {
    ident: "KSAC",
    type: "medium_airport",
    name: "Sacramento Executive Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Sacramento",
    gps_code: "KSAC",
    iata_code: "SAC",
    local_code: "SAC",
    coordinates: "-121.492996216, 38.5125007629",
  },
  {
    ident: "KSAD",
    type: "small_airport",
    name: "Safford Regional Airport",
    elevation_ft: "3179",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Safford",
    gps_code: "KSAD",
    iata_code: "SAD",
    local_code: "SAD",
    coordinates: "-109.6350021, 32.85480118",
  },
  {
    ident: "KSAF",
    type: "medium_airport",
    name: "Santa Fe Municipal Airport",
    elevation_ft: "6348",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Santa Fe",
    gps_code: "KSAF",
    iata_code: "SAF",
    local_code: "SAF",
    coordinates: "-106.088996887, 35.617099762",
  },
  {
    ident: "KSAN",
    type: "large_airport",
    name: "San Diego International Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Diego",
    gps_code: "KSAN",
    iata_code: "SAN",
    local_code: "SAN",
    coordinates: "-117.190002441, 32.7336006165",
  },
  {
    ident: "KSAR",
    type: "small_airport",
    name: "Sparta Community Hunter Field",
    elevation_ft: "538",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Sparta",
    gps_code: "KSAR",
    iata_code: "SAR",
    local_code: "SAR",
    coordinates: "-89.6986999512, 38.1488990784",
  },
  {
    ident: "KSAT",
    type: "large_airport",
    name: "San Antonio International Airport",
    elevation_ft: "809",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "San Antonio",
    gps_code: "KSAT",
    iata_code: "SAT",
    local_code: "SAT",
    coordinates: "-98.469803, 29.533701",
  },
  {
    ident: "KSAV",
    type: "large_airport",
    name: "Savannah Hilton Head International Airport",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Savannah",
    gps_code: "KSAV",
    iata_code: "SAV",
    local_code: "SAV",
    coordinates: "-81.20210266, 32.12760162",
  },
  {
    ident: "KSAW",
    type: "medium_airport",
    name: "Sawyer International Airport",
    elevation_ft: "1221",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Marquette",
    gps_code: "KSAW",
    iata_code: "MQT",
    local_code: "SAW",
    coordinates: "-87.395401001, 46.353599548300004",
  },
  {
    ident: "KSBA",
    type: "medium_airport",
    name: "Santa Barbara Municipal Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Santa Barbara",
    gps_code: "KSBA",
    iata_code: "SBA",
    local_code: "SBA",
    coordinates: "-119.8399963, 34.42620087",
  },
  {
    ident: "KSBD",
    type: "medium_airport",
    name: "San Bernardino International Airport",
    elevation_ft: "1159",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Bernardino",
    gps_code: "KSBD",
    iata_code: "SBD",
    local_code: "SBD",
    coordinates: "-117.23500061, 34.0954017639",
  },
  {
    ident: "KSBM",
    type: "small_airport",
    name: "Sheboygan County Memorial Airport",
    elevation_ft: "755",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Sheboygan",
    gps_code: "KSBM",
    iata_code: "SBM",
    local_code: "SBM",
    coordinates: "-87.85140228, 43.76959991",
  },
  {
    ident: "KSBN",
    type: "large_airport",
    name: "South Bend Regional Airport",
    elevation_ft: "799",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "South Bend",
    gps_code: "KSBN",
    iata_code: "SBN",
    local_code: "SBN",
    coordinates: "-86.31729888916016, 41.70869827270508",
  },
  {
    ident: "KSBP",
    type: "medium_airport",
    name: "San Luis County Regional Airport",
    elevation_ft: "212",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Luis Obispo",
    gps_code: "KSBP",
    iata_code: "SBP",
    local_code: "SBP",
    coordinates: "-120.641998291, 35.236801147499996",
  },
  {
    ident: "KSBS",
    type: "small_airport",
    name: "Steamboat Springs Bob Adams Field",
    elevation_ft: "6882",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Steamboat Springs",
    gps_code: "KSBS",
    iata_code: "SBS",
    local_code: "SBS",
    coordinates: "-106.8659973, 40.5163002",
  },
  {
    ident: "KSBX",
    type: "small_airport",
    name: "Shelby Airport",
    elevation_ft: "3443",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Shelby",
    gps_code: "KSBX",
    iata_code: "SBX",
    local_code: "SBX",
    coordinates: "-111.871002197, 48.5406990051",
  },
  {
    ident: "KSBY",
    type: "medium_airport",
    name: "Salisbury Ocean City Wicomico Regional Airport",
    elevation_ft: "52",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MD",
    municipality: "Salisbury",
    gps_code: "KSBY",
    iata_code: "SBY",
    local_code: "SBY",
    coordinates: "-75.51029968261719, 38.34049987792969",
  },
  {
    ident: "KSCB",
    type: "small_airport",
    name: "Scribner State Airport",
    elevation_ft: "1325",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Scribner",
    gps_code: "KSCB",
    iata_code: "SCB",
    local_code: "SCB",
    coordinates: "-96.629898071289, 41.610298156738",
  },
  {
    ident: "KSCH",
    type: "medium_airport",
    name: "Schenectady County Airport",
    elevation_ft: "378",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Schenectady",
    gps_code: "KSCH",
    iata_code: "SCH",
    local_code: "SCH",
    coordinates: "-73.928901672363, 42.852500915527",
  },
  {
    ident: "KSCK",
    type: "medium_airport",
    name: "Stockton Metropolitan Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Stockton",
    gps_code: "KSCK",
    iata_code: "SCK",
    local_code: "SCK",
    coordinates: "-121.2379989624, 37.894199371338",
  },
  {
    ident: "KSDF",
    type: "large_airport",
    name: "Louisville Muhammad Ali International Airport",
    elevation_ft: "501",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Louisville",
    gps_code: "KSDF",
    iata_code: "SDF",
    local_code: "SDF",
    coordinates: "-85.736, 38.1744",
  },
  {
    ident: "KSDL",
    type: "small_airport",
    name: "Scottsdale Airport",
    elevation_ft: "1510",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Scottsdale",
    gps_code: "KSDL",
    iata_code: "SCF",
    local_code: "SDL",
    coordinates: "-111.91100311279, 33.622898101807",
  },
  {
    ident: "KSDM",
    type: "medium_airport",
    name: "Brown Field Municipal Airport",
    elevation_ft: "526",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Diego",
    gps_code: "KSDM",
    iata_code: "SDM",
    local_code: "SDM",
    coordinates: "-116.98000335693, 32.572299957275",
  },
  {
    ident: "KSDY",
    type: "medium_airport",
    name: "Sidney - Richland Regional Airport",
    elevation_ft: "1985",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Sidney",
    gps_code: "KSDY",
    iata_code: "SDY",
    local_code: "SDY",
    coordinates: "-104.193001, 47.706902",
  },
  {
    ident: "KSEA",
    type: "large_airport",
    name: "Seattle Tacoma International Airport",
    elevation_ft: "433",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Seattle",
    gps_code: "KSEA",
    iata_code: "SEA",
    local_code: "SEA",
    coordinates: "-122.308998, 47.449001",
  },
  {
    ident: "KSEE",
    type: "small_airport",
    name: "Gillespie Field",
    elevation_ft: "388",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Diego/El Cajon",
    gps_code: "KSEE",
    iata_code: "SEE",
    local_code: "SEE",
    coordinates: "-116.97200012207, 32.826198577881",
  },
  {
    ident: "KSEF",
    type: "small_airport",
    name: "Sebring Regional Airport",
    elevation_ft: "62",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Sebring",
    gps_code: "KSEF",
    iata_code: "SEF",
    local_code: "SEF",
    coordinates: "-81.3423996, 27.45639992",
  },
  {
    ident: "KSEG",
    type: "small_airport",
    name: "Penn Valley Airport",
    elevation_ft: "450",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Selinsgrove",
    gps_code: "KSEG",
    iata_code: "SEG",
    local_code: "SEG",
    coordinates: "-76.863899230957, 40.820598602295",
  },
  {
    ident: "KSEM",
    type: "small_airport",
    name: "Craig Field",
    elevation_ft: "166",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Selma",
    gps_code: "KSEM",
    iata_code: "SEM",
    local_code: "SEM",
    coordinates: "-86.987800598145, 32.343898773193",
  },
  {
    ident: "KSEP",
    type: "small_airport",
    name: "Stephenville Clark Regional Airport",
    elevation_ft: "1321",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Stephenville",
    gps_code: "KSEP",
    iata_code: "SEP",
    local_code: "SEP",
    coordinates: "-98.177696228027, 32.215301513672",
  },
  {
    ident: "KSER",
    type: "small_airport",
    name: "Freeman Municipal Airport",
    elevation_ft: "583",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Seymour",
    gps_code: "KSER",
    iata_code: "SER",
    local_code: "SER",
    coordinates: "-85.907402038574, 38.923599243164",
  },
  {
    ident: "KSEZ",
    type: "small_airport",
    name: "Sedona Airport",
    elevation_ft: "4830",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Sedona",
    gps_code: "KSEZ",
    iata_code: "SDX",
    local_code: "SEZ",
    coordinates: "-111.78800201416, 34.848598480225",
  },
  {
    ident: "KSFB",
    type: "large_airport",
    name: "Orlando Sanford International Airport",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Orlando",
    gps_code: "KSFB",
    iata_code: "SFB",
    local_code: "SFB",
    coordinates: "-81.23750305175781, 28.777599334716797",
  },
  {
    ident: "KSFF",
    type: "medium_airport",
    name: "Felts Field",
    elevation_ft: "1953",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Spokane",
    gps_code: "KSFF",
    iata_code: "SFF",
    local_code: "SFF",
    coordinates: "-117.32299804688, 47.682800292969",
  },
  {
    ident: "KSFM",
    type: "small_airport",
    name: "Sanford Seacoast Regional Airport",
    elevation_ft: "244",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Sanford",
    gps_code: "KSFM",
    iata_code: "SFM",
    local_code: "SFM",
    coordinates: "-70.708000183105, 43.393901824951",
  },
  {
    ident: "KSFO",
    type: "large_airport",
    name: "San Francisco International Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Francisco",
    gps_code: "KSFO",
    iata_code: "SFO",
    local_code: "SFO",
    coordinates: "-122.375, 37.61899948120117",
  },
  {
    ident: "KSFZ",
    type: "small_airport",
    name: "North Central State Airport",
    elevation_ft: "441",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-RI",
    municipality: "Pawtucket",
    gps_code: "KSFZ",
    iata_code: "SFZ",
    local_code: "SFZ",
    coordinates: "-71.49140167239999, 41.9207992554",
  },
  {
    ident: "KSG",
    type: "closed",
    name: "Kisengam Airport",
    elevation_ft: "3700",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Kisenden",
    iata_code: "KSG",
    coordinates: "146.71, -6.362",
  },
  {
    ident: "KSGF",
    type: "large_airport",
    name: "Springfield Branson National Airport",
    elevation_ft: "1268",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Springfield",
    gps_code: "KSGF",
    iata_code: "SGF",
    local_code: "SGF",
    coordinates: "-93.38860321, 37.24570084",
  },
  {
    ident: "KSGH",
    type: "medium_airport",
    name: "Springfield-Beckley Municipal Airport",
    elevation_ft: "1051",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Springfield",
    gps_code: "KSGH",
    iata_code: "SGH",
    local_code: "SGH",
    coordinates: "-83.840202331543, 39.840301513672",
  },
  {
    ident: "KSGJ",
    type: "medium_airport",
    name: "Northeast Florida Regional Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "St Augustine",
    gps_code: "KSGJ",
    iata_code: "UST",
    local_code: "SGJ",
    coordinates: "-81.339798, 29.9592",
  },
  {
    ident: "KSGR",
    type: "medium_airport",
    name: "Sugar Land Regional Airport",
    elevation_ft: "82",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Houston",
    gps_code: "KSGR",
    iata_code: "SGR",
    local_code: "SGR",
    coordinates: "-95.65650177002, 29.622299194336",
  },
  {
    ident: "KSGT",
    type: "small_airport",
    name: "Stuttgart Municipal Airport / Carl Humphrey Field",
    elevation_ft: "224",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Stuttgart",
    gps_code: "KSGT",
    iata_code: "SGT",
    local_code: "SGT",
    coordinates: "-91.574997, 34.599499",
  },
  {
    ident: "KSGU",
    type: "medium_airport",
    name: "St George Municipal Airport",
    elevation_ft: "2941",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "St George",
    gps_code: "KSGU",
    iata_code: "SGU",
    local_code: "SGU",
    coordinates: "-113.510306, 37.036389",
  },
  {
    ident: "KSHD",
    type: "small_airport",
    name: "Shenandoah Valley Regional Airport",
    elevation_ft: "1201",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Staunton/Waynesboro/Harrisonburg",
    gps_code: "KSHD",
    iata_code: "SHD",
    local_code: "SHD",
    coordinates: "-78.8964004517, 38.2638015747",
  },
  {
    ident: "KSHN",
    type: "small_airport",
    name: "Sanderson Field",
    elevation_ft: "273",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Shelton",
    gps_code: "KSHN",
    iata_code: "SHN",
    local_code: "SHN",
    coordinates: "-123.14800262451, 47.233600616455",
  },
  {
    ident: "KSHR",
    type: "medium_airport",
    name: "Sheridan County Airport",
    elevation_ft: "4021",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Sheridan",
    gps_code: "KSHR",
    iata_code: "SHR",
    local_code: "SHR",
    coordinates: "-106.9800033569336, 44.76919937133789",
  },
  {
    ident: "KSHV",
    type: "medium_airport",
    name: "Shreveport Regional Airport",
    elevation_ft: "258",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-LA",
    municipality: "Shreveport",
    gps_code: "KSHV",
    iata_code: "SHV",
    local_code: "SHV",
    coordinates: "-93.8256, 32.446602",
  },
  {
    ident: "KSIK",
    type: "small_airport",
    name: "Sikeston Memorial Municipal Airport",
    elevation_ft: "315",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Sikeston",
    gps_code: "KSIK",
    iata_code: "SIK",
    local_code: "SIK",
    coordinates: "-89.561798095703, 36.898899078369",
  },
  {
    ident: "KSIV",
    type: "small_airport",
    name: "Sullivan County Airport",
    elevation_ft: "540",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Monticello",
    gps_code: "KSIV",
    iata_code: "SIV",
    local_code: "SIV",
    coordinates: "-87.448303222656, 39.114700317383",
  },
  {
    ident: "KSJC",
    type: "large_airport",
    name: "Norman Y. Mineta San Jose International Airport",
    elevation_ft: "62",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Jose",
    gps_code: "KSJC",
    iata_code: "SJC",
    local_code: "SJC",
    coordinates: "-121.929001, 37.362598",
  },
  {
    ident: "KSJN",
    type: "small_airport",
    name: "St Johns Industrial Air Park",
    elevation_ft: "5737",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "St Johns",
    gps_code: "KSJN",
    iata_code: "SJN",
    local_code: "SJN",
    coordinates: "-109.3789978, 34.51860046",
  },
  {
    ident: "KSJT",
    type: "medium_airport",
    name: "San Angelo Regional Mathis Field",
    elevation_ft: "1919",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "San Angelo",
    gps_code: "KSJT",
    iata_code: "SJT",
    local_code: "SJT",
    coordinates: "-100.49600219726562, 31.35770034790039",
  },
  {
    ident: "KSKA",
    type: "large_airport",
    name: "Fairchild Air Force Base",
    elevation_ft: "2461",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Spokane",
    gps_code: "KSKA",
    iata_code: "SKA",
    local_code: "SKA",
    coordinates: "-117.65599823, 47.6151008606",
  },
  {
    ident: "KSKF",
    type: "medium_airport",
    name: "Lackland Air Force Base",
    elevation_ft: "691",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "San Antonio",
    gps_code: "KSKF",
    iata_code: "SKF",
    local_code: "SKF",
    coordinates: "-98.58110046, 29.38419914",
  },
  {
    ident: "KSKX",
    type: "small_airport",
    name: "Taos Regional Airport",
    elevation_ft: "7095",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Taos",
    gps_code: "KSKX",
    iata_code: "TSM",
    local_code: "SKX",
    coordinates: "-105.6719971, 36.45819855",
  },
  {
    ident: "KSKY",
    type: "closed",
    name: "Griffing Sandusky Airport",
    elevation_ft: "580",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Sandusky",
    gps_code: "KSKY",
    iata_code: "SKY",
    local_code: "SKY",
    coordinates: "-82.6522979736, 41.4333992004",
  },
  {
    ident: "KSLB",
    type: "small_airport",
    name: "Storm Lake Municipal Airport",
    elevation_ft: "1488",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Storm Lake",
    gps_code: "KSLB",
    iata_code: "SLB",
    local_code: "SLB",
    coordinates: "-95.24069976810001, 42.5973014832",
  },
  {
    ident: "KSLC",
    type: "large_airport",
    name: "Salt Lake City International Airport",
    elevation_ft: "4227",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Salt Lake City",
    gps_code: "KSLC",
    iata_code: "SLC",
    local_code: "SLC",
    coordinates: "-111.97799682617188, 40.78839874267578",
  },
  {
    ident: "KSLE",
    type: "medium_airport",
    name: "Salem Municipal Airport/McNary Field",
    elevation_ft: "214",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Salem",
    gps_code: "KSLE",
    iata_code: "SLE",
    local_code: "SLE",
    coordinates: "-123.0029984, 44.90950012",
  },
  {
    ident: "KSLG",
    type: "small_airport",
    name: "Smith Field",
    elevation_ft: "1191",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Siloam Springs",
    gps_code: "KSLG",
    iata_code: "SLG",
    local_code: "SLG",
    coordinates: "-94.48999786, 36.19189835",
  },
  {
    ident: "KSLK",
    type: "medium_airport",
    name: "Adirondack Regional Airport",
    elevation_ft: "1663",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Saranac Lake",
    gps_code: "KSLK",
    iata_code: "SLK",
    local_code: "SLK",
    coordinates: "-74.2061996459961, 44.38529968261719",
  },
  {
    ident: "KSLN",
    type: "medium_airport",
    name: "Salina Municipal Airport",
    elevation_ft: "1288",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Salina",
    gps_code: "KSLN",
    iata_code: "SLN",
    local_code: "SLN",
    coordinates: "-97.6521987915039, 38.79100036621094",
  },
  {
    ident: "KSLO",
    type: "small_airport",
    name: "Salem Leckrone Airport",
    elevation_ft: "573",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Salem",
    gps_code: "KSLO",
    iata_code: "SLO",
    local_code: "SLO",
    coordinates: "-88.964202880859, 38.64289855957",
  },
  {
    ident: "KSLR",
    type: "small_airport",
    name: "Sulphur Springs Municipal Airport",
    elevation_ft: "489",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Sulphur Springs",
    gps_code: "KSLR",
    iata_code: "SLR",
    local_code: "SLR",
    coordinates: "-95.621101379395, 33.159801483154",
  },
  {
    ident: "KSMD",
    type: "small_airport",
    name: "Smith Field",
    elevation_ft: "835",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Fort Wayne",
    gps_code: "KSMD",
    iata_code: "SMD",
    local_code: "SMD",
    coordinates: "-85.15280151, 41.14339828",
  },
  {
    ident: "KSME",
    type: "medium_airport",
    name: "Lake Cumberland Regional Airport",
    elevation_ft: "927",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KY",
    municipality: "Somerset",
    gps_code: "KSME",
    iata_code: "SME",
    local_code: "SME",
    coordinates: "-84.6158981323, 37.053398132299996",
  },
  {
    ident: "KSMF",
    type: "large_airport",
    name: "Sacramento International Airport",
    elevation_ft: "27",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Sacramento",
    gps_code: "KSMF",
    iata_code: "SMF",
    local_code: "SMF",
    coordinates: "-121.59100341796875, 38.69540023803711",
  },
  {
    ident: "KSMN",
    type: "medium_airport",
    name: "Lemhi County Airport",
    elevation_ft: "4043",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Salmon",
    gps_code: "KSMN",
    iata_code: "SMN",
    local_code: "SMN",
    coordinates: "-113.880996704, 45.1237983704",
  },
  {
    ident: "KSMO",
    type: "small_airport",
    name: "Santa Monica Municipal Airport",
    elevation_ft: "170",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Santa Monica",
    gps_code: "KSMO",
    iata_code: "SMO",
    local_code: "SMO",
    coordinates: "-118.450996, 34.0158",
  },
  {
    ident: "KSMS",
    type: "small_airport",
    name: "Sumter Airport",
    elevation_ft: "182",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Sumter",
    gps_code: "KSMS",
    iata_code: "SUM",
    local_code: "SMS",
    coordinates: "-80.361297607422, 33.994998931885",
  },
  {
    ident: "KSMX",
    type: "medium_airport",
    name: "Santa Maria Pub/Capt G Allan Hancock Field",
    elevation_ft: "261",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Santa Maria",
    gps_code: "KSMX",
    iata_code: "SMX",
    local_code: "SMX",
    coordinates: "-120.4570007, 34.89889908",
  },
  {
    ident: "KSNA",
    type: "large_airport",
    name: "John Wayne Airport-Orange County Airport",
    elevation_ft: "56",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Santa Ana",
    gps_code: "KSNA",
    iata_code: "SNA",
    local_code: "SNA",
    coordinates: "-117.8679962, 33.67570114",
  },
  {
    ident: "KSNK",
    type: "small_airport",
    name: "Winston Field",
    elevation_ft: "2430",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Snyder",
    gps_code: "KSNK",
    iata_code: "SNK",
    local_code: "SNK",
    coordinates: "-100.94999694824, 32.69340133667",
  },
  {
    ident: "KSNL",
    type: "small_airport",
    name: "Shawnee Regional Airport",
    elevation_ft: "1073",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Shawnee",
    gps_code: "KSNL",
    iata_code: "SNL",
    local_code: "SNL",
    coordinates: "-96.942802429199, 35.357898712158",
  },
  {
    ident: "KSNS",
    type: "medium_airport",
    name: "Salinas Municipal Airport",
    elevation_ft: "85",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Salinas",
    gps_code: "KSNS",
    iata_code: "SNS",
    local_code: "SNS",
    coordinates: "-121.60600280762, 36.662799835205",
  },
  {
    ident: "KSNY",
    type: "medium_airport",
    name: "Sidney Municipal-Lloyd W Carr Field",
    elevation_ft: "4313",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Sidney",
    gps_code: "KSNY",
    iata_code: "SNY",
    local_code: "SNY",
    coordinates: "-102.9850006, 41.10129929",
  },
  {
    ident: "KSOP",
    type: "small_airport",
    name: "Moore County Airport",
    elevation_ft: "455",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Pinehurst/Southern Pines",
    gps_code: "KSOP",
    iata_code: "SOP",
    local_code: "SOP",
    coordinates: "-79.3911972, 35.23740005",
  },
  {
    ident: "KSOW",
    type: "small_airport",
    name: "Show Low Regional Airport",
    elevation_ft: "6415",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Show Low",
    gps_code: "KSOW",
    iata_code: "SOW",
    local_code: "SOW",
    coordinates: "-110.005996704, 34.265499115",
  },
  {
    ident: "KSP",
    type: "small_airport",
    name: "Kosipe Airport",
    elevation_ft: "6350",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Kosipe Mission",
    gps_code: "AYOP",
    iata_code: "KSP",
    coordinates: "147.209195, -8.450716",
  },
  {
    ident: "KSPA",
    type: "small_airport",
    name: "Spartanburg Downtown Memorial Airport",
    elevation_ft: "801",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Spartanburg",
    gps_code: "KSPA",
    iata_code: "SPA",
    local_code: "SPA",
    coordinates: "-81.956497192383, 34.915699005127",
  },
  {
    ident: "KSPF",
    type: "small_airport",
    name: "Black Hills Airport-Clyde Ice Field",
    elevation_ft: "3931",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Spearfish",
    gps_code: "KSPF",
    iata_code: "SPF",
    local_code: "SPF",
    coordinates: "-103.78299713135, 44.48030090332",
  },
  {
    ident: "KSPG",
    type: "small_airport",
    name: "Albert Whitted Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "St Petersburg",
    gps_code: "KSPG",
    iata_code: "SPG",
    local_code: "SPG",
    coordinates: "-82.626999, 27.7651",
  },
  {
    ident: "KSPI",
    type: "large_airport",
    name: "Abraham Lincoln Capital Airport",
    elevation_ft: "598",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Springfield",
    gps_code: "KSPI",
    iata_code: "SPI",
    local_code: "SPI",
    coordinates: "-89.67790222, 39.84410095",
  },
  {
    ident: "KSPS",
    type: "large_airport",
    name: "Sheppard Air Force Base-Wichita Falls Municipal Airport",
    elevation_ft: "1019",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Wichita Falls",
    gps_code: "KSPS",
    iata_code: "SPS",
    local_code: "SPS",
    coordinates: "-98.491898, 33.9888",
  },
  {
    ident: "KSPW",
    type: "small_airport",
    name: "Spencer Municipal Airport",
    elevation_ft: "1339",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Spencer",
    gps_code: "KSPW",
    iata_code: "SPW",
    local_code: "SPW",
    coordinates: "-95.202796936035, 43.165500640869",
  },
  {
    ident: "KSQI",
    type: "small_airport",
    name: "Whiteside County Airport-Joseph H Bittorf Field",
    elevation_ft: "648",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Sterling/Rockfalls",
    gps_code: "KSQI",
    iata_code: "SQI",
    local_code: "SQI",
    coordinates: "-89.67630005, 41.74280167",
  },
  {
    ident: "KSQL",
    type: "small_airport",
    name: "San Carlos Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Carlos",
    gps_code: "KSQL",
    iata_code: "SQL",
    local_code: "SQL",
    coordinates: "-122.25, 37.511901855469",
  },
  {
    ident: "KSRQ",
    type: "large_airport",
    name: "Sarasota Bradenton International Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Sarasota/Bradenton",
    gps_code: "KSRQ",
    iata_code: "SRQ",
    local_code: "SRQ",
    coordinates: "-82.55439758300781, 27.39539909362793",
  },
  {
    ident: "KSRR",
    type: "medium_airport",
    name: "Sierra Blanca Regional Airport",
    elevation_ft: "6814",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Ruidoso",
    gps_code: "KSRR",
    iata_code: "RUI",
    local_code: "SRR",
    coordinates: "-105.53500366211, 33.462799072266",
  },
  {
    ident: "KSSC",
    type: "large_airport",
    name: "Shaw Air Force Base",
    elevation_ft: "241",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Sumter",
    gps_code: "KSSC",
    iata_code: "SSC",
    local_code: "SSC",
    coordinates: "-80.47059631, 33.97269821",
  },
  {
    ident: "KSSF",
    type: "medium_airport",
    name: "Stinson Municipal Airport",
    elevation_ft: "577",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "San Antonio",
    gps_code: "KSSF",
    iata_code: "SSF",
    local_code: "SSF",
    coordinates: "-98.471099853516, 29.336999893188",
  },
  {
    ident: "KSSI",
    type: "medium_airport",
    name: "St Simons Island Airport",
    elevation_ft: "19",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "St Simons Island",
    gps_code: "KSSI",
    iata_code: "SSI",
    local_code: "SSI",
    coordinates: "-81.391296, 31.1518",
  },
  {
    ident: "KSTC",
    type: "medium_airport",
    name: "St Cloud Regional Airport",
    elevation_ft: "1031",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "St Cloud",
    gps_code: "KSTC",
    iata_code: "STC",
    local_code: "STC",
    coordinates: "-94.05989837646484, 45.546600341796875",
  },
  {
    ident: "KSTE",
    type: "small_airport",
    name: "Stevens Point Municipal Airport",
    elevation_ft: "1110",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Stevens Point",
    gps_code: "KSTE",
    iata_code: "STE",
    local_code: "STE",
    coordinates: "-89.530296325684, 44.5452003479",
  },
  {
    ident: "KSTJ",
    type: "medium_airport",
    name: "Rosecrans Memorial Airport",
    elevation_ft: "826",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "St Joseph",
    gps_code: "KSTJ",
    iata_code: "STJ",
    local_code: "STJ",
    coordinates: "-94.909698486328, 39.771900177002",
  },
  {
    ident: "KSTK",
    type: "small_airport",
    name: "Sterling Municipal Airport",
    elevation_ft: "4040",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Sterling",
    gps_code: "KSTK",
    iata_code: "STK",
    local_code: "STK",
    coordinates: "-103.2649994, 40.61529922",
  },
  {
    ident: "KSTL",
    type: "large_airport",
    name: "St Louis Lambert International Airport",
    elevation_ft: "618",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "St Louis",
    gps_code: "KSTL",
    iata_code: "STL",
    local_code: "STL",
    coordinates: "-90.370003, 38.748697",
  },
  {
    ident: "KSTP",
    type: "medium_airport",
    name: "St Paul Downtown Holman Field",
    elevation_ft: "705",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "St Paul",
    gps_code: "KSTP",
    iata_code: "STP",
    local_code: "STP",
    coordinates: "-93.05999755859375, 44.93450164794922",
  },
  {
    ident: "KSTS",
    type: "medium_airport",
    name: "Charles M. Schulz Sonoma County Airport",
    elevation_ft: "128",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Santa Rosa",
    gps_code: "KSTS",
    iata_code: "STS",
    local_code: "STS",
    coordinates: "-122.8130035, 38.50899887",
  },
  {
    ident: "KSUA",
    type: "small_airport",
    name: "Witham Field",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Stuart",
    gps_code: "KSUA",
    iata_code: "SUA",
    local_code: "SUA",
    coordinates: "-80.22109985, 27.18169975",
  },
  {
    ident: "KSUD",
    type: "small_airport",
    name: "Stroud Municipal Airport",
    elevation_ft: "900",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Stroud",
    gps_code: "KSUD",
    iata_code: "SUD",
    local_code: "SUD",
    coordinates: "-96.655700683594, 35.789600372314",
  },
  {
    ident: "KSUE",
    type: "small_airport",
    name: "Door County Cherryland Airport",
    elevation_ft: "725",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Sturgeon Bay",
    gps_code: "KSUE",
    iata_code: "SUE",
    local_code: "SUE",
    coordinates: "-87.42150116, 44.84370041",
  },
  {
    ident: "KSUN",
    type: "medium_airport",
    name: "Friedman Memorial Airport",
    elevation_ft: "5318",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Hailey",
    gps_code: "KSUN",
    iata_code: "SUN",
    local_code: "SUN",
    coordinates: "-114.2959976, 43.50439835",
  },
  {
    ident: "KSUS",
    type: "large_airport",
    name: "Spirit of St Louis Airport",
    elevation_ft: "463",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "St Louis",
    gps_code: "KSUS",
    iata_code: "SUS",
    local_code: "SUS",
    coordinates: "-90.652000427246, 38.662101745605",
  },
  {
    ident: "KSUU",
    type: "large_airport",
    name: "Travis Air Force Base",
    elevation_ft: "62",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Fairfield",
    gps_code: "KSUU",
    iata_code: "SUU",
    local_code: "SUU",
    coordinates: "-121.92700195312, 38.262699127197",
  },
  {
    ident: "KSUW",
    type: "small_airport",
    name: "Richard I Bong Airport",
    elevation_ft: "674",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Superior",
    gps_code: "KSUW",
    iata_code: "SUW",
    local_code: "SUW",
    coordinates: "-92.094703674316, 46.689701080322",
  },
  {
    ident: "KSUX",
    type: "large_airport",
    name: "Sioux Gateway Airport/Brigadier General Bud Day Field",
    elevation_ft: "1098",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Sioux City",
    gps_code: "KSUX",
    iata_code: "SUX",
    local_code: "SUX",
    coordinates: "-96.384399, 42.402599",
  },
  {
    ident: "KSVC",
    type: "small_airport",
    name: "Grant County Airport",
    elevation_ft: "5446",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Silver City",
    gps_code: "KSVC",
    iata_code: "SVC",
    local_code: "SVC",
    coordinates: "-108.154263, 32.632293",
  },
  {
    ident: "KSVE",
    type: "small_airport",
    name: "Susanville Municipal Airport",
    elevation_ft: "4149",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Susanville",
    gps_code: "KSVE",
    iata_code: "SVE",
    local_code: "SVE",
    coordinates: "-120.57299804688, 40.375701904297",
  },
  {
    ident: "KSVH",
    type: "small_airport",
    name: "Statesville Regional Airport",
    elevation_ft: "968",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "Statesville",
    gps_code: "KSVH",
    iata_code: "SVH",
    local_code: "SVH",
    coordinates: "-80.953903198242, 35.765300750732",
  },
  {
    ident: "KSVN",
    type: "medium_airport",
    name: "Hunter Army Air Field",
    elevation_ft: "41",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Savannah",
    gps_code: "KSVN",
    iata_code: "SVN",
    local_code: "SVN",
    coordinates: "-81.14569855, 32.00999832",
  },
  {
    ident: "KSWF",
    type: "medium_airport",
    name: "New York Stewart International Airport",
    elevation_ft: "491",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Newburgh",
    gps_code: "KSWF",
    iata_code: "SWF",
    local_code: "SWF",
    coordinates: "-74.104797, 41.504101",
  },
  {
    ident: "KSWO",
    type: "medium_airport",
    name: "Stillwater Regional Airport",
    elevation_ft: "1000",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Stillwater",
    gps_code: "KSWO",
    iata_code: "SWO",
    local_code: "SWO",
    coordinates: "-97.08570098877, 36.161201477051",
  },
  {
    ident: "KSWW",
    type: "small_airport",
    name: "Avenger Field",
    elevation_ft: "2380",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Sweetwater",
    gps_code: "KSWW",
    iata_code: "SWW",
    local_code: "SWW",
    coordinates: "-100.46700286865, 32.467399597168",
  },
  {
    ident: "KSYI",
    type: "small_airport",
    name: "Bomar Field Shelbyville Municipal Airport",
    elevation_ft: "801",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Shelbyville",
    gps_code: "KSYI",
    iata_code: "SYI",
    local_code: "SYI",
    coordinates: "-86.44249725, 35.56010056",
  },
  {
    ident: "KSYR",
    type: "large_airport",
    name: "Syracuse Hancock International Airport",
    elevation_ft: "421",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Syracuse",
    gps_code: "KSYR",
    iata_code: "SYR",
    local_code: "SYR",
    coordinates: "-76.1063003540039, 43.11119842529297",
  },
  {
    ident: "KSYV",
    type: "small_airport",
    name: "Sylvester Airport",
    elevation_ft: "403",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Sylvester",
    gps_code: "KSYV",
    iata_code: "SYV",
    local_code: "SYV",
    coordinates: "-83.895698547363, 31.558500289917",
  },
  {
    ident: "KSZL",
    type: "large_airport",
    name: "Whiteman Air Force Base",
    elevation_ft: "870",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Knob Noster",
    gps_code: "KSZL",
    iata_code: "SZL",
    local_code: "SZL",
    coordinates: "-93.547897338867, 38.73030090332",
  },
  {
    ident: "KT03",
    type: "small_airport",
    name: "Tuba City Airport",
    elevation_ft: "4513",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Tuba City",
    gps_code: "KT03",
    iata_code: "TBC",
    local_code: "T03",
    coordinates: "-111.383003235, 36.0928001404",
  },
  {
    ident: "KTAD",
    type: "small_airport",
    name: "Perry Stokes Airport",
    elevation_ft: "5762",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Trinidad",
    gps_code: "KTAD",
    iata_code: "TAD",
    local_code: "TAD",
    coordinates: "-104.341003418, 37.2593994141",
  },
  {
    ident: "KTB",
    type: "seaplane_base",
    name: "Thorne Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Thorne Bay",
    gps_code: "KTB",
    iata_code: "KTB",
    local_code: "KTB",
    coordinates: "-132.537002563, 55.687999725299996",
  },
  {
    ident: "KTBN",
    type: "medium_airport",
    name: "Waynesville-St. Robert Regional Forney field",
    elevation_ft: "1159",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Fort Leonard Wood",
    gps_code: "KTBN",
    iata_code: "TBN",
    local_code: "TBN",
    coordinates: "-92.14070129, 37.74160004",
  },
  {
    ident: "KTBR",
    type: "small_airport",
    name: "Statesboro Bulloch County Airport",
    elevation_ft: "187",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Statesboro",
    gps_code: "KTBR",
    iata_code: "TBR",
    local_code: "TBR",
    coordinates: "-81.73690032959999, 32.4827003479",
  },
  {
    ident: "KTC",
    type: "small_airport",
    name: "Katiola Airport",
    elevation_ft: "1026",
    continent: "AF",
    iso_country: "CI",
    iso_region: "CI-04",
    municipality: "Katiola",
    iata_code: "KTC",
    coordinates: "-5.0657, 8.1329",
  },
  {
    ident: "KTCC",
    type: "medium_airport",
    name: "Tucumcari Municipal Airport",
    elevation_ft: "4065",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Tucumcari",
    gps_code: "KTCC",
    iata_code: "TCC",
    local_code: "TCC",
    coordinates: "-103.602996826, 35.182800293",
  },
  {
    ident: "KTCL",
    type: "medium_airport",
    name: "Tuscaloosa Regional Airport",
    elevation_ft: "170",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Tuscaloosa",
    gps_code: "KTCL",
    iata_code: "TCL",
    local_code: "TCL",
    coordinates: "-87.611396789551, 33.220600128174",
  },
  {
    ident: "KTCM",
    type: "large_airport",
    name: "McChord Air Force Base",
    elevation_ft: "322",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Tacoma",
    gps_code: "KTCM",
    iata_code: "TCM",
    local_code: "TCM",
    coordinates: "-122.475997925, 47.1376991272",
  },
  {
    ident: "KTCS",
    type: "medium_airport",
    name: "Truth Or Consequences Municipal Airport",
    elevation_ft: "4853",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Truth Or Consequences",
    gps_code: "KTCS",
    iata_code: "TCS",
    local_code: "TCS",
    coordinates: "-107.272003174, 33.2369003296",
  },
  {
    ident: "KTDO",
    type: "small_airport",
    name: "Ed Carlson Memorial Field South Lewis County Airport",
    elevation_ft: "374",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Toledo",
    gps_code: "KTDO",
    iata_code: "TDO",
    local_code: "TDO",
    coordinates: "-122.805999756, 46.4771995544",
  },
  {
    ident: "KTDW",
    type: "small_airport",
    name: "Tradewind Airport",
    elevation_ft: "3649",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Amarillo",
    gps_code: "KTDW",
    iata_code: "TDW",
    local_code: "TDW",
    coordinates: "-101.825996399, 35.1698989868",
  },
  {
    ident: "KTDZ",
    type: "small_airport",
    name: "Toledo Executive Airport",
    elevation_ft: "623",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Toledo",
    gps_code: "KTDZ",
    iata_code: "TDZ",
    local_code: "TDZ",
    coordinates: "-83.4822998, 41.56489944",
  },
  {
    ident: "KTEB",
    type: "medium_airport",
    name: "Teterboro Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Teterboro",
    gps_code: "KTEB",
    iata_code: "TEB",
    local_code: "TEB",
    coordinates: "-74.060798645, 40.85010147089999",
  },
  {
    ident: "KTEX",
    type: "small_airport",
    name: "Telluride Regional Airport",
    elevation_ft: "9070",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CO",
    municipality: "Telluride",
    gps_code: "KTEX",
    iata_code: "TEX",
    local_code: "TEX",
    coordinates: "-107.9079971, 37.9538002",
  },
  {
    ident: "KTHA",
    type: "small_airport",
    name: "Tullahoma Regional Arpt/Wm Northern Field",
    elevation_ft: "1083",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Tullahoma",
    gps_code: "KTHA",
    iata_code: "THA",
    local_code: "THA",
    coordinates: "-86.24639893, 35.38010025",
  },
  {
    ident: "KTHM",
    type: "small_airport",
    name: "Thompson Falls Airport",
    elevation_ft: "2467",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Thompson Falls",
    gps_code: "KTHM",
    iata_code: "THM",
    local_code: "THM",
    coordinates: "-115.28099822998, 47.573501586914",
  },
  {
    ident: "KTHV",
    type: "small_airport",
    name: "York Airport",
    elevation_ft: "495",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "York",
    gps_code: "KTHV",
    iata_code: "THV",
    local_code: "THV",
    coordinates: "-76.8730011, 39.91699982",
  },
  {
    ident: "KTIK",
    type: "large_airport",
    name: "Tinker Air Force Base",
    elevation_ft: "1291",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Oklahoma City",
    gps_code: "KTIK",
    iata_code: "TIK",
    local_code: "TIK",
    coordinates: "-97.386596679688, 35.414699554443",
  },
  {
    ident: "KTIW",
    type: "medium_airport",
    name: "Tacoma Narrows Airport",
    elevation_ft: "294",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Tacoma",
    gps_code: "KTIW",
    iata_code: "TIW",
    local_code: "TIW",
    coordinates: "-122.5780029, 47.26789856",
  },
  {
    ident: "KTIX",
    type: "medium_airport",
    name: "Space Coast Regional Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Titusville",
    gps_code: "KTIX",
    iata_code: "TIX",
    local_code: "TIX",
    coordinates: "-80.799201965332, 28.514799118042",
  },
  {
    ident: "KTK",
    type: "closed",
    name: "Kunua Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Kunua",
    iata_code: "KTK",
    coordinates: "154.74, -5.7828",
  },
  {
    ident: "KTKX",
    type: "small_airport",
    name: "Kennett Memorial Airport",
    elevation_ft: "262",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Kennett",
    gps_code: "KTKX",
    iata_code: "KNT",
    local_code: "TKX",
    coordinates: "-90.0365982056, 36.2258987427",
  },
  {
    ident: "KTLH",
    type: "large_airport",
    name: "Tallahassee Regional Airport",
    elevation_ft: "81",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Tallahassee",
    gps_code: "KTLH",
    iata_code: "TLH",
    local_code: "TLH",
    coordinates: "-84.35030364990234, 30.396499633789062",
  },
  {
    ident: "KTLR",
    type: "small_airport",
    name: "Mefford Field",
    elevation_ft: "265",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Tulare",
    gps_code: "KTLR",
    iata_code: "TLR",
    local_code: "TLR",
    coordinates: "-119.3259964, 36.15629959",
  },
  {
    ident: "KTMA",
    type: "small_airport",
    name: "Henry Tift Myers Airport",
    elevation_ft: "355",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Tifton",
    gps_code: "KTMA",
    iata_code: "TMA",
    local_code: "TMA",
    coordinates: "-83.4885025024, 31.4290008545",
  },
  {
    ident: "KTMB",
    type: "medium_airport",
    name: "Miami Executive Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Miami",
    gps_code: "KTMB",
    iata_code: "TMB",
    local_code: "TMB",
    coordinates: "-80.4328, 25.6479",
  },
  {
    ident: "KTMK",
    type: "small_airport",
    name: "Tillamook Airport",
    elevation_ft: "36",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Tillamook",
    gps_code: "KTMK",
    iata_code: "OTK",
    local_code: "TMK",
    coordinates: "-123.814002991, 45.4182014465",
  },
  {
    ident: "KTNP",
    type: "small_airport",
    name: "Twentynine Palms Airport",
    elevation_ft: "1888",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Twentynine Palms",
    gps_code: "KTNP",
    iata_code: "TNP",
    local_code: "TNP",
    coordinates: "-115.9459991, 34.13159943",
  },
  {
    ident: "KTNT",
    type: "small_airport",
    name: "Dade Collier Training and Transition Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Miami",
    gps_code: "KTNT",
    iata_code: "TNT",
    local_code: "TNT",
    coordinates: "-80.897003173828, 25.861799240112",
  },
  {
    ident: "KTNU",
    type: "small_airport",
    name: "Newton Municipal Airport",
    elevation_ft: "953",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Newton",
    gps_code: "KTNU",
    iata_code: "TNU",
    local_code: "TNU",
    coordinates: "-93.021697998047, 41.67440032959",
  },
  {
    ident: "KTNX",
    type: "small_airport",
    name: "Tonopah Test Range Airport",
    elevation_ft: "5549",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Tonopah",
    gps_code: "KTNX",
    iata_code: "XSD",
    local_code: "TNX",
    coordinates: "-116.78099823, 37.7988014221",
  },
  {
    ident: "KTOA",
    type: "small_airport",
    name: "Zamperini Field",
    elevation_ft: "103",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Torrance",
    gps_code: "KTOA",
    iata_code: "TOA",
    local_code: "TOA",
    coordinates: "-118.33999633789, 33.803398132324",
  },
  {
    ident: "KTOC",
    type: "small_airport",
    name: "Toccoa Airport - R.G. Letourneau Field",
    elevation_ft: "996",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Toccoa",
    gps_code: "KTOC",
    iata_code: "TOC",
    local_code: "TOC",
    coordinates: "-83.29579926, 34.59379959",
  },
  {
    ident: "KTOI",
    type: "medium_airport",
    name: "Troy Municipal Airport at N Kenneth Campbell Field",
    elevation_ft: "398",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Troy",
    gps_code: "KTOI",
    iata_code: "TOI",
    local_code: "TOI",
    coordinates: "-86.012101, 31.860399",
  },
  {
    ident: "KTOL",
    type: "large_airport",
    name: "Toledo Express Airport",
    elevation_ft: "683",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Toledo",
    gps_code: "KTOL",
    iata_code: "TOL",
    local_code: "TOL",
    coordinates: "-83.80780029, 41.58679962",
  },
  {
    ident: "KTOP",
    type: "medium_airport",
    name: "Philip Billard Municipal Airport",
    elevation_ft: "881",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Topeka",
    gps_code: "KTOP",
    iata_code: "TOP",
    local_code: "TOP",
    coordinates: "-95.622497558594, 39.068698883057",
  },
  {
    ident: "KTOR",
    type: "small_airport",
    name: "Torrington Municipal Airport",
    elevation_ft: "4207",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Torrington",
    gps_code: "KTOR",
    iata_code: "TOR",
    local_code: "TOR",
    coordinates: "-104.1529999, 42.0644989",
  },
  {
    ident: "KTPA",
    type: "large_airport",
    name: "Tampa International Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Tampa",
    gps_code: "KTPA",
    iata_code: "TPA",
    local_code: "TPA",
    coordinates: "-82.533203125, 27.975500106811523",
  },
  {
    ident: "KTPF",
    type: "small_airport",
    name: "Peter O Knight Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Tampa",
    gps_code: "KTPF",
    iata_code: "TPF",
    local_code: "TPF",
    coordinates: "-82.44930267334, 27.915599822998",
  },
  {
    ident: "KTPH",
    type: "medium_airport",
    name: "Tonopah Airport",
    elevation_ft: "5430",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Tonopah",
    gps_code: "KTPH",
    iata_code: "TPH",
    local_code: "TPH",
    coordinates: "-117.086998, 38.06019974",
  },
  {
    ident: "KTPL",
    type: "medium_airport",
    name: "Draughon Miller Central Texas Regional Airport",
    elevation_ft: "682",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Temple",
    gps_code: "KTPL",
    iata_code: "TPL",
    local_code: "TPL",
    coordinates: "-97.40779876708984, 31.15250015258789",
  },
  {
    ident: "KTRI",
    type: "large_airport",
    name: "Tri-Cities Regional TN/VA Airport",
    elevation_ft: "1519",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Bristol/Johnson/Kingsport",
    gps_code: "KTRI",
    iata_code: "TRI",
    local_code: "TRI",
    coordinates: "-82.407401, 36.475201",
  },
  {
    ident: "KTRK",
    type: "medium_airport",
    name: "Truckee Tahoe Airport",
    elevation_ft: "5900",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Truckee",
    gps_code: "KTRK",
    iata_code: "TKF",
    local_code: "TRK",
    coordinates: "-120.13999939, 39.319999694799996",
  },
  {
    ident: "KTRL",
    type: "small_airport",
    name: "Terrell Municipal Airport",
    elevation_ft: "474",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Terrell",
    gps_code: "KTRL",
    iata_code: "TRL",
    local_code: "TRL",
    coordinates: "-96.267402648926, 32.709201812744",
  },
  {
    ident: "KTRM",
    type: "medium_airport",
    name: "Jacqueline Cochran Regional Airport",
    elevation_ft: "-115",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Palm Springs",
    gps_code: "KTRM",
    iata_code: "TRM",
    local_code: "TRM",
    coordinates: "-116.16000366211, 33.62670135498",
  },
  {
    ident: "KTSP",
    type: "small_airport",
    name: "Tehachapi Municipal Airport",
    elevation_ft: "4001",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Tehachapi",
    gps_code: "KTSP",
    iata_code: "TSP",
    local_code: "TSP",
    coordinates: "-118.43900299072, 35.134998321533",
  },
  {
    ident: "KTTD",
    type: "medium_airport",
    name: "Portland Troutdale Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Portland",
    gps_code: "KTTD",
    iata_code: "TTD",
    local_code: "TTD",
    coordinates: "-122.40100097656, 45.54940032959",
  },
  {
    ident: "KTTN",
    type: "medium_airport",
    name: "Trenton Mercer Airport",
    elevation_ft: "213",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Trenton",
    gps_code: "KTTN",
    iata_code: "TTN",
    local_code: "TTN",
    coordinates: "-74.8134994506836, 40.27669906616211",
  },
  {
    ident: "KTUL",
    type: "large_airport",
    name: "Tulsa International Airport",
    elevation_ft: "677",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Tulsa",
    gps_code: "KTUL",
    iata_code: "TUL",
    local_code: "TUL",
    coordinates: "-95.88809967041016, 36.19839859008789",
  },
  {
    ident: "KTUP",
    type: "medium_airport",
    name: "Tupelo Regional Airport",
    elevation_ft: "346",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Tupelo",
    gps_code: "KTUP",
    iata_code: "TUP",
    local_code: "TUP",
    coordinates: "-88.7698974609375, 34.26810073852539",
  },
  {
    ident: "KTUS",
    type: "large_airport",
    name: "Tucson International Airport / Morris Air National Guard Base",
    elevation_ft: "2643",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Tucson",
    gps_code: "KTUS",
    iata_code: "TUS",
    local_code: "TUS",
    coordinates: "-110.938053, 32.115004",
  },
  {
    ident: "KTVC",
    type: "medium_airport",
    name: "Cherry Capital Airport",
    elevation_ft: "624",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Traverse City",
    gps_code: "KTVC",
    iata_code: "TVC",
    local_code: "TVC",
    coordinates: "-85.58219909667969, 44.74140167236328",
  },
  {
    ident: "KTVF",
    type: "small_airport",
    name: "Thief River Falls Regional Airport",
    elevation_ft: "1119",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Thief River Falls",
    gps_code: "KTVF",
    iata_code: "TVF",
    local_code: "TVF",
    coordinates: "-96.18499756, 48.06570053",
  },
  {
    ident: "KTVI",
    type: "small_airport",
    name: "Thomasville Regional Airport",
    elevation_ft: "264",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Thomasville",
    gps_code: "KTVI",
    iata_code: "TVI",
    local_code: "TVI",
    coordinates: "-83.881301879883, 30.901599884033",
  },
  {
    ident: "KTVL",
    type: "medium_airport",
    name: "Lake Tahoe Airport",
    elevation_ft: "6264",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "South Lake Tahoe",
    gps_code: "KTVL",
    iata_code: "TVL",
    local_code: "TVL",
    coordinates: "-119.99500274658203, 38.89390182495117",
  },
  {
    ident: "KTWF",
    type: "medium_airport",
    name: "Joslin Field Magic Valley Regional Airport",
    elevation_ft: "4154",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Twin Falls",
    gps_code: "KTWF",
    iata_code: "TWF",
    local_code: "TWF",
    coordinates: "-114.487999, 42.4818",
  },
  {
    ident: "KTXK",
    type: "medium_airport",
    name: "Texarkana Regional Webb Field",
    elevation_ft: "390",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Texarkana",
    gps_code: "KTXK",
    iata_code: "TXK",
    local_code: "TXK",
    coordinates: "-93.99099731445312, 33.45370101928711",
  },
  {
    ident: "KTYL",
    type: "small_airport",
    name: "Taylor Airport",
    elevation_ft: "5823",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    municipality: "Taylor",
    gps_code: "KTYL",
    iata_code: "TYZ",
    local_code: "TYL",
    coordinates: "-110.1149979, 34.45280075",
  },
  {
    ident: "KTYR",
    type: "medium_airport",
    name: "Tyler Pounds Regional Airport",
    elevation_ft: "544",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Tyler",
    gps_code: "KTYR",
    iata_code: "TYR",
    local_code: "TYR",
    coordinates: "-95.40239715576172, 32.35409927368164",
  },
  {
    ident: "KTYS",
    type: "large_airport",
    name: "McGhee Tyson Airport",
    elevation_ft: "981",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Knoxville",
    gps_code: "KTYS",
    iata_code: "TYS",
    local_code: "TYS",
    coordinates: "-83.9940033, 35.81100082",
  },
  {
    ident: "KU07",
    type: "small_airport",
    name: "Bullfrog Basin Airport",
    elevation_ft: "4167",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Bullfrog",
    gps_code: "KU07",
    iata_code: "BFG",
    local_code: "U07",
    coordinates: "-110.712898, 37.547827",
  },
  {
    ident: "KU14",
    type: "small_airport",
    name: "Nephi Municipal Airport",
    elevation_ft: "5022",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Nephi",
    gps_code: "U14",
    iata_code: "NPH",
    local_code: "U14",
    coordinates: "-111.8720016, 39.73880005",
  },
  {
    ident: "KU34",
    type: "small_airport",
    name: "Green River Municipal Airport",
    elevation_ft: "4225",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Green River",
    gps_code: "KU34",
    iata_code: "RVR",
    local_code: "U34",
    coordinates: "-110.226997375, 38.9613990784",
  },
  {
    ident: "KU55",
    type: "small_airport",
    name: "Panguitch Municipal Airport",
    elevation_ft: "6763",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Panguitch",
    gps_code: "U55",
    iata_code: "PNU",
    local_code: "U55",
    coordinates: "-112.3919983, 37.84519958",
  },
  {
    ident: "KU70",
    type: "small_airport",
    name: "Cascade Airport",
    elevation_ft: "4742",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Cascade",
    gps_code: "KU70",
    iata_code: "ICS",
    local_code: "U70",
    coordinates: "-116.01599884, 44.4938011169",
  },
  {
    ident: "KUBS",
    type: "small_airport",
    name: "Columbus Lowndes County Airport",
    elevation_ft: "188",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Columbus",
    gps_code: "KUBS",
    iata_code: "UBS",
    local_code: "UBS",
    coordinates: "-88.3803024292, 33.4654006958",
  },
  {
    ident: "KUCY",
    type: "small_airport",
    name: "Everett-Stewart Regional Airport",
    elevation_ft: "336",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Union City",
    gps_code: "KUCY",
    iata_code: "UCY",
    local_code: "UCY",
    coordinates: "-88.98539734, 36.38180161",
  },
  {
    ident: "KUDD",
    type: "small_airport",
    name: "Bermuda Dunes Airport",
    elevation_ft: "73",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Palm Springs",
    gps_code: "KUDD",
    iata_code: "UDD",
    local_code: "UDD",
    coordinates: "-116.27500152588, 33.748401641846",
  },
  {
    ident: "KUES",
    type: "small_airport",
    name: "Waukesha County Airport",
    elevation_ft: "911",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Waukesha",
    gps_code: "KUES",
    iata_code: "UES",
    local_code: "UES",
    coordinates: "-88.237098693848, 43.041000366211",
  },
  {
    ident: "KUGN",
    type: "small_airport",
    name: "Waukegan National Airport",
    elevation_ft: "727",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Chicago/Waukegan",
    gps_code: "KUGN",
    iata_code: "UGN",
    local_code: "UGN",
    coordinates: "-87.867897033691, 42.422199249268",
  },
  {
    ident: "KUIL",
    type: "small_airport",
    name: "Quillayute Airport",
    elevation_ft: "194",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Quillayute",
    gps_code: "KUIL",
    iata_code: "UIL",
    local_code: "UIL",
    coordinates: "-124.56300354004, 47.936599731445",
  },
  {
    ident: "KUIN",
    type: "medium_airport",
    name: "Quincy Regional Baldwin Field",
    elevation_ft: "768",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Quincy",
    gps_code: "KUIN",
    iata_code: "UIN",
    local_code: "UIN",
    coordinates: "-91.19460297, 39.94269943",
  },
  {
    ident: "KUIZ",
    type: "closed",
    name: "Berz-Macomb Airport",
    elevation_ft: "610",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    gps_code: "KUIZ",
    iata_code: "UIZ",
    local_code: "UIZ",
    coordinates: "-82.96540069580078, 42.66389846801758",
  },
  {
    ident: "KUKF",
    type: "small_airport",
    name: "Wilkes County Airport",
    elevation_ft: "1301",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NC",
    municipality: "North Wilkesboro",
    gps_code: "KUKF",
    iata_code: "IKB",
    local_code: "UKF",
    coordinates: "-81.09829711910001, 36.2228012085",
  },
  {
    ident: "KUKI",
    type: "medium_airport",
    name: "Ukiah Municipal Airport",
    elevation_ft: "614",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Ukiah",
    gps_code: "KUKI",
    iata_code: "UKI",
    local_code: "UKI",
    coordinates: "-123.20099639893, 39.125999450684",
  },
  {
    ident: "KUKT",
    type: "small_airport",
    name: "Quakertown Airport",
    elevation_ft: "526",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Quakertown",
    gps_code: "KUKT",
    iata_code: "UKT",
    local_code: "UKT",
    coordinates: "-75.381896972656, 40.435199737549",
  },
  {
    ident: "KULM",
    type: "small_airport",
    name: "New Ulm Municipal Airport",
    elevation_ft: "1011",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "New Ulm",
    gps_code: "KULM",
    iata_code: "ULM",
    local_code: "ULM",
    coordinates: "-94.502296447754, 44.319599151611",
  },
  {
    ident: "KUNI",
    type: "small_airport",
    name: "Ohio University Snyder Field",
    elevation_ft: "766",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Athens/Albany",
    gps_code: "KUNI",
    iata_code: "ATO",
    local_code: "UNI",
    coordinates: "-82.23139953610001, 39.2109985352",
  },
  {
    ident: "KUNU",
    type: "small_airport",
    name: "Dodge County Airport",
    elevation_ft: "934",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Juneau",
    gps_code: "KUNU",
    iata_code: "UNU",
    local_code: "UNU",
    coordinates: "-88.70320129, 43.42660141",
  },
  {
    ident: "KUNV",
    type: "medium_airport",
    name: "University Park Airport",
    elevation_ft: "1239",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "State College",
    gps_code: "KUNV",
    iata_code: "SCE",
    local_code: "UNV",
    coordinates: "-77.84870147710001, 40.8493003845",
  },
  {
    ident: "KUOS",
    type: "small_airport",
    name: "Franklin County Airport",
    elevation_ft: "1953",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TN",
    municipality: "Sewanee",
    gps_code: "KUOS",
    iata_code: "UOS",
    local_code: "UOS",
    coordinates: "-85.898101806641, 35.205101013184",
  },
  {
    ident: "KUOX",
    type: "medium_airport",
    name: "University Oxford Airport",
    elevation_ft: "452",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Oxford",
    gps_code: "KUOX",
    iata_code: "UOX",
    local_code: "UOX",
    coordinates: "-89.536796569824, 34.384300231934",
  },
  {
    ident: "KUP",
    type: "small_airport",
    name: "Kupiano Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Kupiano",
    iata_code: "KUP",
    local_code: "KPN",
    coordinates: "148.218, -10.0736",
  },
  {
    ident: "KUTA",
    type: "small_airport",
    name: "Tunica Municipal Airport",
    elevation_ft: "194",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Tunica",
    gps_code: "KUTA",
    iata_code: "UTM",
    local_code: "UTA",
    coordinates: "-90.346702575684, 34.680999755859",
  },
  {
    ident: "KUTS",
    type: "medium_airport",
    name: "Huntsville Regional Airport",
    elevation_ft: "363",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Huntsville",
    gps_code: "KUTS",
    iata_code: "HTV",
    local_code: "UTS",
    coordinates: "-95.5871963501, 30.7469005585",
  },
  {
    ident: "KUUU",
    type: "small_airport",
    name: "Newport State Airport",
    elevation_ft: "172",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-RI",
    municipality: "Newport",
    gps_code: "KUUU",
    iata_code: "NPT",
    local_code: "UUU",
    coordinates: "-71.28150177, 41.5323982239",
  },
  {
    ident: "KUVA",
    type: "small_airport",
    name: "Garner Field",
    elevation_ft: "942",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Uvalde",
    gps_code: "KUVA",
    iata_code: "UVA",
    local_code: "UVA",
    coordinates: "-99.743598938, 29.2112998962",
  },
  {
    ident: "KUX",
    type: "small_airport",
    name: "Kuyol Airport",
    elevation_ft: "3290",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    gps_code: "AYUY",
    iata_code: "KUX",
    local_code: "KYL",
    coordinates: "141.623888889, -5.372361111110001",
  },
  {
    ident: "KUZA",
    type: "small_airport",
    name: "Rock Hill - York County Airport",
    elevation_ft: "666",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SC",
    municipality: "Rock Hill",
    gps_code: "KUZA",
    iata_code: "RKH",
    local_code: "UZA",
    coordinates: "-81.05719757, 34.9878006",
  },
  {
    ident: "KVAD",
    type: "medium_airport",
    name: "Moody Air Force Base",
    elevation_ft: "233",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Valdosta",
    gps_code: "KVAD",
    iata_code: "VAD",
    local_code: "VAD",
    coordinates: "-83.1930007935, 30.9678001404",
  },
  {
    ident: "KVAY",
    type: "small_airport",
    name: "South Jersey Regional Airport",
    elevation_ft: "53",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Mount Holly",
    gps_code: "KVAY",
    iata_code: "LLY",
    local_code: "VAY",
    coordinates: "-74.845703125, 39.942901611299995",
  },
  {
    ident: "KVBG",
    type: "large_airport",
    name: "Vandenberg Air Force Base",
    elevation_ft: "369",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Lompoc",
    gps_code: "KVBG",
    iata_code: "VBG",
    local_code: "VBG",
    coordinates: "-120.583999634, 34.7373008728",
  },
  {
    ident: "KVCT",
    type: "medium_airport",
    name: "Victoria Regional Airport",
    elevation_ft: "115",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Victoria",
    gps_code: "KVCT",
    iata_code: "VCT",
    local_code: "VCT",
    coordinates: "-96.91850280761719, 28.85260009765625",
  },
  {
    ident: "KVCV",
    type: "small_airport",
    name: "Southern California Logistics Airport",
    elevation_ft: "2885",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Victorville",
    gps_code: "KVCV",
    iata_code: "VCV",
    local_code: "VCV",
    coordinates: "-117.383003235, 34.597499847399995",
  },
  {
    ident: "KVDI",
    type: "small_airport",
    name: "Vidalia Regional Airport",
    elevation_ft: "275",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Vidalia",
    gps_code: "KVDI",
    iata_code: "VDI",
    local_code: "VDI",
    coordinates: "-82.371200561523, 32.192699432373",
  },
  {
    ident: "KVE",
    type: "small_airport",
    name: "Kitava Airport",
    elevation_ft: "194",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Kitava Island",
    iata_code: "KVE",
    local_code: "KTV",
    coordinates: "151.327, -8.6285",
  },
  {
    ident: "KVEL",
    type: "medium_airport",
    name: "Vernal Regional Airport",
    elevation_ft: "5278",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Vernal",
    gps_code: "KVEL",
    iata_code: "VEL",
    local_code: "VEL",
    coordinates: "-109.5100021, 40.4408989",
  },
  {
    ident: "KVGT",
    type: "medium_airport",
    name: "North Las Vegas Airport",
    elevation_ft: "2205",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Las Vegas",
    gps_code: "KVGT",
    iata_code: "VGT",
    local_code: "VGT",
    coordinates: "-115.19400024414, 36.21070098877",
  },
  {
    ident: "KVHN",
    type: "small_airport",
    name: "Culberson County Airport",
    elevation_ft: "3957",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Van Horn",
    gps_code: "KVHN",
    iata_code: "VHN",
    local_code: "VHN",
    coordinates: "-104.78399658203, 31.057800292969",
  },
  {
    ident: "KVIH",
    type: "small_airport",
    name: "Rolla National Airport",
    elevation_ft: "1148",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MO",
    municipality: "Rolla/Vichy",
    gps_code: "KVIH",
    iata_code: "VIH",
    local_code: "VIH",
    coordinates: "-91.7695007324, 38.1273994446",
  },
  {
    ident: "KVIS",
    type: "small_airport",
    name: "Visalia Municipal Airport",
    elevation_ft: "295",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Visalia",
    gps_code: "KVIS",
    iata_code: "VIS",
    local_code: "VIS",
    coordinates: "-119.392997742, 36.3186988831",
  },
  {
    ident: "KVJI",
    type: "small_airport",
    name: "Virginia Highlands Airport",
    elevation_ft: "2087",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Abingdon",
    gps_code: "KVJI",
    iata_code: "VJI",
    local_code: "VJI",
    coordinates: "-82.033302307129, 36.687099456787",
  },
  {
    ident: "KVKS",
    type: "small_airport",
    name: "Vicksburg Municipal Airport",
    elevation_ft: "106",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MS",
    municipality: "Vicksburg",
    gps_code: "KVKS",
    iata_code: "VKS",
    local_code: "VKS",
    coordinates: "-90.928398132324, 32.23929977417",
  },
  {
    ident: "KVLA",
    type: "small_airport",
    name: "Vandalia Municipal Airport",
    elevation_ft: "537",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Vandalia",
    gps_code: "KVLA",
    iata_code: "VLA",
    local_code: "VLA",
    coordinates: "-89.166198730469, 38.991500854492",
  },
  {
    ident: "KVLD",
    type: "medium_airport",
    name: "Valdosta Regional Airport",
    elevation_ft: "203",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Valdosta",
    gps_code: "KVLD",
    iata_code: "VLD",
    local_code: "VLD",
    coordinates: "-83.27670288085938, 30.782499313354492",
  },
  {
    ident: "KVNC",
    type: "small_airport",
    name: "Venice Municipal Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Venice",
    gps_code: "KVNC",
    iata_code: "VNC",
    local_code: "VNC",
    coordinates: "-82.440299987793, 27.071599960327",
  },
  {
    ident: "KVNY",
    type: "medium_airport",
    name: "Van Nuys Airport",
    elevation_ft: "802",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Van Nuys",
    gps_code: "KVNY",
    iata_code: "VNY",
    local_code: "VNY",
    coordinates: "-118.48999786377, 34.209800720215",
  },
  {
    ident: "KVOK",
    type: "medium_airport",
    name: "Volk Field",
    elevation_ft: "912",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WI",
    municipality: "Camp Douglas",
    gps_code: "KVOK",
    iata_code: "VOK",
    local_code: "VOK",
    coordinates: "-90.253402709961, 43.938999176025",
  },
  {
    ident: "KVPS",
    type: "large_airport",
    name: "Destin-Ft Walton Beach Airport",
    elevation_ft: "87",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Valparaiso",
    gps_code: "KVPS",
    iata_code: "VPS",
    local_code: "VPS",
    coordinates: "-86.525398, 30.4832",
  },
  {
    ident: "KVPZ",
    type: "medium_airport",
    name: "Porter County Municipal Airport",
    elevation_ft: "770",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IN",
    municipality: "Valparaiso",
    gps_code: "KVPZ",
    iata_code: "VPZ",
    local_code: "VPZ",
    coordinates: "-87.00710297, 41.45399857",
  },
  {
    ident: "KVQQ",
    type: "small_airport",
    name: "Cecil Airport",
    elevation_ft: "81",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Jacksonville",
    gps_code: "KVQQ",
    iata_code: "VQQ",
    local_code: "VQQ",
    coordinates: "-81.876701355, 30.2187004089",
  },
  {
    ident: "KVRB",
    type: "medium_airport",
    name: "Vero Beach Regional Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Vero Beach",
    gps_code: "KVRB",
    iata_code: "VRB",
    local_code: "VRB",
    coordinates: "-80.417901, 27.6556",
  },
  {
    ident: "KVSF",
    type: "small_airport",
    name: "Hartness State (Springfield) Airport",
    elevation_ft: "577",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VT",
    municipality: "Springfield",
    gps_code: "KVSF",
    iata_code: "VSF",
    local_code: "VSF",
    coordinates: "-72.51730347, 43.34360123",
  },
  {
    ident: "KVTN",
    type: "medium_airport",
    name: "Miller Field",
    elevation_ft: "2596",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Valentine",
    gps_code: "KVTN",
    iata_code: "VTN",
    local_code: "VTN",
    coordinates: "-100.5479965, 42.85779953",
  },
  {
    ident: "KVYS",
    type: "small_airport",
    name: "Illinois Valley Regional Airport-Walter A Duncan Field",
    elevation_ft: "654",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Peru",
    gps_code: "KVYS",
    iata_code: "VYS",
    local_code: "VYS",
    coordinates: "-89.153099, 41.351898",
  },
  {
    ident: "KW05",
    type: "small_airport",
    name: "Gettysburg Regional Airport",
    elevation_ft: "590",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Gettysburg",
    gps_code: "W05",
    iata_code: "GTY",
    local_code: "W05",
    coordinates: "-77.27420044, 39.84090042",
  },
  {
    ident: "KW28",
    type: "small_airport",
    name: "Sequim Valley Airport",
    elevation_ft: "144",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Sequim",
    gps_code: "W28",
    iata_code: "SQV",
    local_code: "W28",
    coordinates: "-123.18699645996, 48.098098754883",
  },
  {
    ident: "KW99",
    type: "small_airport",
    name: "Grant County Airport",
    elevation_ft: "963",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Petersburg",
    gps_code: "W99",
    iata_code: "PGC",
    local_code: "W99",
    coordinates: "-79.1458969116, 38.9948997498",
  },
  {
    ident: "KWAL",
    type: "small_airport",
    name: "Wallops Flight Facility Airport",
    elevation_ft: "40",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-VA",
    municipality: "Wallops Island",
    gps_code: "KWAL",
    iata_code: "WAL",
    local_code: "WAL",
    coordinates: "-75.4664001465, 37.9402008057",
  },
  {
    ident: "KWAY",
    type: "small_airport",
    name: "Greene County Airport",
    elevation_ft: "1069",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Waynesburg",
    gps_code: "KWAY",
    iata_code: "WAY",
    local_code: "WAY",
    coordinates: "-80.13310242, 39.90010071",
  },
  {
    ident: "KWBW",
    type: "small_airport",
    name: "Wilkes Barre Wyoming Valley Airport",
    elevation_ft: "545",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Wilkes-Barre",
    gps_code: "KWBW",
    iata_code: "WBW",
    local_code: "WBW",
    coordinates: "-75.8511962891, 41.2971992493",
  },
  {
    ident: "KWDG",
    type: "small_airport",
    name: "Enid Woodring Regional Airport",
    elevation_ft: "1167",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Enid",
    gps_code: "KWDG",
    iata_code: "WDG",
    local_code: "WDG",
    coordinates: "-97.7910995483, 36.3791999817",
  },
  {
    ident: "KWDR",
    type: "small_airport",
    name: "Barrow County Airport",
    elevation_ft: "943",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Winder",
    gps_code: "KWDR",
    iata_code: "WDR",
    local_code: "WDR",
    coordinates: "-83.66739655, 33.98289871",
  },
  {
    ident: "KWF",
    type: "seaplane_base",
    name: "Waterfall Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Waterfall",
    gps_code: "KWF",
    iata_code: "KWF",
    local_code: "KWF",
    coordinates: "-133.242996216, 55.296298980699994",
  },
  {
    ident: "KWHP",
    type: "small_airport",
    name: "Whiteman Airport",
    elevation_ft: "1003",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Los Angeles",
    gps_code: "KWHP",
    iata_code: "WHP",
    local_code: "WHP",
    coordinates: "-118.413002014, 34.2593002319",
  },
  {
    ident: "KWJF",
    type: "medium_airport",
    name: "General WM J Fox Airfield",
    elevation_ft: "2351",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Lancaster",
    gps_code: "KWJF",
    iata_code: "WJF",
    local_code: "WJF",
    coordinates: "-118.2190018, 34.74110031",
  },
  {
    ident: "KWLD",
    type: "small_airport",
    name: "Strother Field",
    elevation_ft: "1160",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-KS",
    municipality: "Winfield/Arkansas City",
    gps_code: "KWLD",
    iata_code: "WLD",
    local_code: "WLD",
    coordinates: "-97.0375976562, 37.168598175",
  },
  {
    ident: "KWLW",
    type: "small_airport",
    name: "Willows Glenn County Airport",
    elevation_ft: "141",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Willows",
    gps_code: "KWLW",
    iata_code: "WLW",
    local_code: "WLW",
    coordinates: "-122.2180023, 39.51639938",
  },
  {
    ident: "KWMC",
    type: "medium_airport",
    name: "Winnemucca Municipal Airport",
    elevation_ft: "4308",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Winnemucca",
    gps_code: "KWMC",
    iata_code: "WMC",
    local_code: "WMC",
    coordinates: "-117.805999756, 40.8965988159",
  },
  {
    ident: "KWP",
    type: "seaplane_base",
    name: "West Point Village Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "West Point",
    gps_code: "KWP",
    iata_code: "KWP",
    local_code: "KWP",
    coordinates: "-153.548995972, 57.770099639899996",
  },
  {
    ident: "KWRB",
    type: "large_airport",
    name: "Robins Air Force Base",
    elevation_ft: "294",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-GA",
    municipality: "Warner Robins",
    gps_code: "KWRB",
    iata_code: "WRB",
    local_code: "WRB",
    coordinates: "-83.5919036865, 32.6400985718",
  },
  {
    ident: "KWRI",
    type: "medium_airport",
    name: "Mc Guire Air Force Base",
    elevation_ft: "131",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Wrightstown",
    gps_code: "KWRI",
    iata_code: "WRI",
    local_code: "WRI",
    coordinates: "-74.59169769, 40.0155983",
  },
  {
    ident: "KWRL",
    type: "medium_airport",
    name: "Worland Municipal Airport",
    elevation_ft: "4227",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WY",
    municipality: "Worland",
    gps_code: "KWRL",
    iata_code: "WRL",
    local_code: "WRL",
    coordinates: "-107.95099639892578, 43.9656982421875",
  },
  {
    ident: "KWSD",
    type: "small_airport",
    name: "Condron Army Air Field",
    elevation_ft: "3934",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "White Sands",
    gps_code: "KWSD",
    iata_code: "WSD",
    local_code: "WSD",
    coordinates: "-106.4029999, 32.34149933",
  },
  {
    ident: "KWST",
    type: "small_airport",
    name: "Westerly State Airport",
    elevation_ft: "81",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-RI",
    municipality: "Westerly",
    gps_code: "KWST",
    iata_code: "WST",
    local_code: "WST",
    coordinates: "-71.8033981323, 41.3496017456",
  },
  {
    ident: "KWV",
    type: "closed",
    name: "Kurwina Airport",
    elevation_ft: "65",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Kurwina",
    iata_code: "KWV",
    coordinates: "155.3604, -5.965",
  },
  {
    ident: "KWVI",
    type: "small_airport",
    name: "Watsonville Municipal Airport",
    elevation_ft: "163",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Watsonville",
    gps_code: "KWVI",
    iata_code: "WVI",
    local_code: "WVI",
    coordinates: "-121.790000916, 36.9356994629",
  },
  {
    ident: "KWVL",
    type: "small_airport",
    name: "Waterville Robert Lafleur Airport",
    elevation_ft: "333",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Waterville",
    gps_code: "KWVL",
    iata_code: "WVL",
    local_code: "WVL",
    coordinates: "-69.6754989624, 44.5331993103",
  },
  {
    ident: "KWWD",
    type: "medium_airport",
    name: "Cape May County Airport",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NJ",
    municipality: "Wildwood",
    gps_code: "KWWD",
    iata_code: "WWD",
    local_code: "WWD",
    coordinates: "-74.9083023071, 39.008499145500004",
  },
  {
    ident: "KWWR",
    type: "medium_airport",
    name: "West Woodward Airport",
    elevation_ft: "2189",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Woodward",
    gps_code: "KWWR",
    iata_code: "WWR",
    local_code: "WWR",
    coordinates: "-99.5226667, 36.438",
  },
  {
    ident: "KWX",
    type: "closed",
    name: "Kiwai Airport",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Kiwai Island",
    iata_code: "KWX",
    coordinates: "143.618, -8.6883",
  },
  {
    ident: "KWY",
    type: "medium_airport",
    name: "Kiwayu Airport",
    elevation_ft: "21",
    continent: "AF",
    iso_country: "KE",
    iso_region: "KE-300",
    municipality: "Kiwayu",
    iata_code: "KWY",
    coordinates: "41.29750061035156, -1.9605599641799927",
  },
  {
    ident: "KWYS",
    type: "medium_airport",
    name: "Yellowstone Airport",
    elevation_ft: "6649",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "West Yellowstone",
    gps_code: "KWYS",
    iata_code: "WYS",
    local_code: "WYS",
    coordinates: "-111.1179962, 44.68840027",
  },
  {
    ident: "KX39",
    type: "small_airport",
    name: "Tampa North Aero Park Airport",
    elevation_ft: "68",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Tampa",
    gps_code: "X39",
    iata_code: "KYO",
    local_code: "X39",
    coordinates: "-82.37449646, 28.2213001251",
  },
  {
    ident: "KXA",
    type: "seaplane_base",
    name: "Kasaan Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kasaan",
    gps_code: "KXA",
    iata_code: "KXA",
    local_code: "KXA",
    coordinates: "-132.397994995, 55.537399292",
  },
  {
    ident: "KXNA",
    type: "medium_airport",
    name: "Northwest Arkansas Regional Airport",
    elevation_ft: "1287",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AR",
    municipality: "Fayetteville/Springdale/Rogers",
    gps_code: "KXNA",
    iata_code: "XNA",
    local_code: "XNA",
    coordinates: "-94.306801, 36.281898",
  },
  {
    ident: "KY31",
    type: "small_airport",
    name: "West Branch Community Airport",
    elevation_ft: "882",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "West Branch",
    iata_code: "WBK",
    local_code: "Y31",
    coordinates: "-84.179802, 44.244801",
  },
  {
    ident: "KYIP",
    type: "medium_airport",
    name: "Willow Run Airport",
    elevation_ft: "716",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MI",
    municipality: "Detroit",
    gps_code: "KYIP",
    iata_code: "YIP",
    local_code: "YIP",
    coordinates: "-83.53040314, 42.23789978",
  },
  {
    ident: "KYKM",
    type: "medium_airport",
    name: "Yakima Air Terminal McAllister Field",
    elevation_ft: "1099",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Yakima",
    gps_code: "KYKM",
    iata_code: "YKM",
    local_code: "YKM",
    coordinates: "-120.5439987, 46.56819916",
  },
  {
    ident: "KYKN",
    type: "medium_airport",
    name: "Chan Gurney Municipal Airport",
    elevation_ft: "1306",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-SD",
    municipality: "Yankton",
    gps_code: "KYKN",
    iata_code: "YKN",
    local_code: "YKN",
    coordinates: "-97.385902404785, 42.916698455811",
  },
  {
    ident: "KYL",
    type: "closed",
    name: "Port Largo Airport",
    elevation_ft: "4",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Key Largo",
    iata_code: "KYL",
    coordinates: "-80.42992, 25.09334",
  },
  {
    ident: "KYNG",
    type: "medium_airport",
    name: "Youngstown Warren Regional Airport",
    elevation_ft: "1192",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Youngstown/Warren",
    gps_code: "KYNG",
    iata_code: "YNG",
    local_code: "YNG",
    coordinates: "-80.67910004, 41.26070023",
  },
  {
    ident: "KZ-0016",
    type: "medium_airport",
    name: "Taldykorgan Airport",
    elevation_ft: "1925",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-ALM",
    municipality: "Taldy Kurgan",
    gps_code: "UAAT",
    iata_code: "TDK",
    coordinates: "78.4469985961914, 45.12620162963867",
  },
  {
    ident: "KZ-0067",
    type: "medium_airport",
    name: "Krainiy Airport",
    elevation_ft: "317",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-BAY",
    municipality: "Baikonur",
    gps_code: "UAOL",
    iata_code: "BXY",
    coordinates: "63.210773, 45.621994",
  },
  {
    ident: "KZ-0082",
    type: "small_airport",
    name: "Usharal Airport",
    elevation_ft: "1288",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-ALM",
    municipality: "Usharal",
    gps_code: "UAAL",
    iata_code: "USJ",
    coordinates: "80.830922, 46.190767",
  },
  {
    ident: "KZ-ATX",
    type: "small_airport",
    name: "Atbasar Airport",
    elevation_ft: "1010",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-AKM",
    municipality: "Atbasar",
    iata_code: "ATX",
    coordinates: "68.36329650878906, 51.85169982910156",
  },
  {
    ident: "KZB",
    type: "seaplane_base",
    name: "Zachar Bay Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Zachar Bay",
    iata_code: "KZB",
    local_code: "KZB",
    coordinates: "-153.746052, 57.553001",
  },
  {
    ident: "KZF",
    type: "small_airport",
    name: "Kaintiba Airport",
    elevation_ft: "2050",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Kaintiba",
    gps_code: "AYKT",
    iata_code: "KZF",
    local_code: "KNT",
    coordinates: "146.033833333, -7.50025",
  },
  {
    ident: "KZPH",
    type: "small_airport",
    name: "Zephyrhills Municipal Airport",
    elevation_ft: "90",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Zephyrhills",
    gps_code: "KZPH",
    iata_code: "ZPH",
    local_code: "ZPH",
    coordinates: "-82.15589904790001, 28.2282009125",
  },
  {
    ident: "KZZV",
    type: "medium_airport",
    name: "Zanesville Municipal Airport",
    elevation_ft: "900",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OH",
    municipality: "Zanesville",
    gps_code: "KZZV",
    iata_code: "ZZV",
    local_code: "ZZV",
    coordinates: "-81.89209747310001, 39.9444007874",
  },
  {
    ident: "LAC",
    type: "small_airport",
    name: "Layang-Layang Airport",
    elevation_ft: "7",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Spratley Islands",
    iata_code: "LAC",
    coordinates: "113.84400177, 7.37157011032",
  },
  {
    ident: "LATI",
    type: "large_airport",
    name: "Tirana International Airport Mother Teresa",
    elevation_ft: "126",
    continent: "EU",
    iso_country: "AL",
    iso_region: "AL-11",
    municipality: "Tirana",
    gps_code: "LATI",
    iata_code: "TIA",
    coordinates: "19.7206001282, 41.4146995544",
  },
  {
    ident: "LBBG",
    type: "large_airport",
    name: "Burgas Airport",
    elevation_ft: "135",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-02",
    municipality: "Burgas",
    gps_code: "LBBG",
    iata_code: "BOJ",
    coordinates: "27.515199661254883, 42.56959915161133",
  },
  {
    ident: "LBGO",
    type: "medium_airport",
    name: "Gorna Oryahovitsa Airport",
    elevation_ft: "285",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-04",
    municipality: "Gorna Oryahovitsa",
    gps_code: "LBGO",
    iata_code: "GOZ",
    coordinates: "25.712900161743164, 43.15140151977539",
  },
  {
    ident: "LBH",
    type: "seaplane_base",
    name: "Palm Beach Seaplane Base",
    elevation_ft: "0",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Sydney",
    iata_code: "LBH",
    coordinates: "151.3234, -33.5871",
  },
  {
    ident: "LBM",
    type: "small_airport",
    name: "Luabo Airport",
    elevation_ft: "36",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-Q",
    municipality: "Luabo",
    iata_code: "LBM",
    coordinates: "36.1068, -18.4139",
  },
  {
    ident: "LBPD",
    type: "medium_airport",
    name: "Plovdiv International Airport",
    elevation_ft: "597",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-16",
    municipality: "Plovdiv",
    gps_code: "LBPD",
    iata_code: "PDV",
    coordinates: "24.8508, 42.067799",
  },
  {
    ident: "LBRS",
    type: "closed",
    name: "Ruse Airport",
    elevation_ft: "614",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-18",
    municipality: "Ruse",
    gps_code: "LBRS",
    iata_code: "ROU",
    coordinates: "26.056699752800004, 43.6948013306",
  },
  {
    ident: "LBSF",
    type: "large_airport",
    name: "Sofia Airport",
    elevation_ft: "1742",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-23",
    municipality: "Sofia",
    gps_code: "LBSF",
    iata_code: "SOF",
    coordinates: "23.411436080932617, 42.696693420410156",
  },
  {
    ident: "LBSS",
    type: "closed",
    name: "Silistra Polkovnik Lambrinovo Air Base",
    elevation_ft: "351",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-19",
    municipality: "Silistra",
    gps_code: "LBSS",
    iata_code: "SLS",
    coordinates: "27.178801, 44.055199",
  },
  {
    ident: "LBSZ",
    type: "small_airport",
    name: "Stara Zagora Airport",
    elevation_ft: "558",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-24",
    municipality: "Stara Zagora",
    gps_code: "LBSZ",
    iata_code: "SZR",
    coordinates: "25.655, 42.3766667",
  },
  {
    ident: "LBTG",
    type: "small_airport",
    name: "Bukhovtsi Airfield",
    elevation_ft: "666",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-25",
    municipality: "Targovishte",
    gps_code: "LBTG",
    iata_code: "TGV",
    coordinates: "26.700899124099998, 43.3065986633",
  },
  {
    ident: "LBWN",
    type: "large_airport",
    name: "Varna Airport",
    elevation_ft: "230",
    continent: "EU",
    iso_country: "BG",
    iso_region: "BG-03",
    municipality: "Varna",
    gps_code: "LBWN",
    iata_code: "VAR",
    coordinates: "27.8251, 43.232101",
  },
  {
    ident: "LCEN",
    type: "medium_airport",
    name: "Ercan International Airport",
    elevation_ft: "404",
    continent: "AS",
    iso_country: "CY",
    iso_region: "CY-02",
    municipality: "Nicosia",
    gps_code: "LCEN",
    iata_code: "ECN",
    coordinates: "33.49610137939453, 35.154701232910156",
  },
  {
    ident: "LCLK",
    type: "large_airport",
    name: "Larnaca International Airport",
    elevation_ft: "8",
    continent: "AS",
    iso_country: "CY",
    iso_region: "CY-04",
    municipality: "Larnarca",
    gps_code: "LCLK",
    iata_code: "LCA",
    coordinates: "33.624900817871094, 34.875099182128906",
  },
  {
    ident: "LCP",
    type: "small_airport",
    name: "Loncopue Airport",
    elevation_ft: "3627",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Q",
    municipality: "Loncopue",
    iata_code: "LCP",
    local_code: "LCP",
    coordinates: "-70.643898, -38.081902",
  },
  {
    ident: "LCPH",
    type: "large_airport",
    name: "Paphos International Airport",
    elevation_ft: "41",
    continent: "AS",
    iso_country: "CY",
    iso_region: "CY-06",
    municipality: "Paphos",
    gps_code: "LCPH",
    iata_code: "PFO",
    coordinates: "32.48569869995117, 34.71799850463867",
  },
  {
    ident: "LCRA",
    type: "large_airport",
    name: "RAF Akrotiri",
    elevation_ft: "76",
    continent: "EU",
    iso_country: "GB",
    iso_region: "GB-U-A",
    municipality: "Akrotiri",
    gps_code: "LCRA",
    iata_code: "AKT",
    coordinates: "32.9879, 34.590401",
  },
  {
    ident: "LDDU",
    type: "medium_airport",
    name: "Dubrovnik Airport",
    elevation_ft: "527",
    continent: "EU",
    iso_country: "HR",
    iso_region: "HR-19",
    municipality: "Dubrovnik",
    gps_code: "LDDU",
    iata_code: "DBV",
    coordinates: "18.268199920654297, 42.5614013671875",
  },
  {
    ident: "LDLO",
    type: "small_airport",
    name: "Lo����inj Island Airport",
    elevation_ft: "151",
    continent: "EU",
    iso_country: "HR",
    iso_region: "HR-08",
    municipality: "Lo����inj",
    gps_code: "LDLO",
    iata_code: "LSZ",
    coordinates: "14.3930997849, 44.5657997131",
  },
  {
    ident: "LDOS",
    type: "medium_airport",
    name: "Osijek Airport",
    elevation_ft: "290",
    continent: "EU",
    iso_country: "HR",
    iso_region: "HR-14",
    municipality: "Osijek",
    gps_code: "LDOS",
    iata_code: "OSI",
    coordinates: "18.810199737548828, 45.46269989013672",
  },
  {
    ident: "LDPL",
    type: "medium_airport",
    name: "Pula Airport",
    elevation_ft: "274",
    continent: "EU",
    iso_country: "HR",
    iso_region: "HR-18",
    municipality: "Pula",
    gps_code: "LDPL",
    iata_code: "PUY",
    coordinates: "13.922200202941895, 44.89350128173828",
  },
  {
    ident: "LDRI",
    type: "medium_airport",
    name: "Rijeka Airport",
    elevation_ft: "278",
    continent: "EU",
    iso_country: "HR",
    iso_region: "HR-08",
    municipality: "Rijeka",
    gps_code: "LDRI",
    iata_code: "RJK",
    coordinates: "14.570300102233887, 45.21689987182617",
  },
  {
    ident: "LDSB",
    type: "medium_airport",
    name: "Bol Airport",
    elevation_ft: "1776",
    continent: "EU",
    iso_country: "HR",
    iso_region: "HR-17",
    municipality: "Bra���� Island",
    gps_code: "LDSB",
    iata_code: "BWK",
    coordinates: "16.67970085144043, 43.285701751708984",
  },
  {
    ident: "LDSP",
    type: "medium_airport",
    name: "Split Airport",
    elevation_ft: "79",
    continent: "EU",
    iso_country: "HR",
    iso_region: "HR-17",
    municipality: "Split",
    gps_code: "LDSP",
    iata_code: "SPU",
    coordinates: "16.29800033569336, 43.53889846801758",
  },
  {
    ident: "LDW",
    type: "small_airport",
    name: "Lansdowne Airport",
    elevation_ft: "1190",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Lansdowne Station",
    iata_code: "LDW",
    coordinates: "126.743, -17.6128",
  },
  {
    ident: "LDZA",
    type: "large_airport",
    name: "Zagreb Airport",
    elevation_ft: "353",
    continent: "EU",
    iso_country: "HR",
    iso_region: "HR-21",
    municipality: "Zagreb",
    gps_code: "LDZA",
    iata_code: "ZAG",
    coordinates: "16.0687999725, 45.7429008484",
  },
  {
    ident: "LDZD",
    type: "medium_airport",
    name: "Zadar Airport",
    elevation_ft: "289",
    continent: "EU",
    iso_country: "HR",
    iso_region: "HR-13",
    municipality: "Zemunik (Zadar)",
    gps_code: "LDZD",
    iata_code: "ZAD",
    coordinates: "15.3467, 44.108299",
  },
  {
    ident: "LEAB",
    type: "medium_airport",
    name: "Albacete-Los Llanos Airport",
    elevation_ft: "2302",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CM",
    municipality: "Albacete",
    gps_code: "LEAB",
    iata_code: "ABC",
    coordinates: "-1.8635200262099998, 38.9485015869",
  },
  {
    ident: "LEAL",
    type: "large_airport",
    name: "Alicante International Airport",
    elevation_ft: "142",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-V",
    municipality: "Alicante",
    gps_code: "LEAL",
    iata_code: "ALC",
    coordinates: "-0.5581560134887695, 38.28219985961914",
  },
  {
    ident: "LEAM",
    type: "medium_airport",
    name: "Almer����a International Airport",
    elevation_ft: "70",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AN",
    municipality: "Almer����a",
    gps_code: "LEAM",
    iata_code: "LEI",
    coordinates: "-2.3701000213623047, 36.84389877319336",
  },
  {
    ident: "LEAS",
    type: "medium_airport",
    name: "Asturias Airport",
    elevation_ft: "416",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-O",
    municipality: "Ran����n",
    gps_code: "LEAS",
    iata_code: "OVD",
    coordinates: "-6.0346198081970215, 43.5635986328125",
  },
  {
    ident: "LEBA",
    type: "medium_airport",
    name: "C����rdoba Airport",
    elevation_ft: "297",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AN",
    municipality: "C����rdoba",
    gps_code: "LEBA",
    iata_code: "ODB",
    coordinates: "-4.848879814147949, 37.84199905395508",
  },
  {
    ident: "LEBB",
    type: "medium_airport",
    name: "Bilbao Airport",
    elevation_ft: "138",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-PV",
    municipality: "Bilbao",
    gps_code: "LEBB",
    iata_code: "BIO",
    coordinates: "-2.9106099605560303, 43.30110168457031",
  },
  {
    ident: "LEBG",
    type: "medium_airport",
    name: "Burgos Airport",
    elevation_ft: "2945",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CL",
    municipality: "Burgos",
    gps_code: "LEBG",
    iata_code: "RGS",
    coordinates: "-3.62076, 42.357601",
  },
  {
    ident: "LEBL",
    type: "large_airport",
    name: "Barcelona International Airport",
    elevation_ft: "12",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CT",
    municipality: "Barcelona",
    gps_code: "LEBL",
    iata_code: "BCN",
    coordinates: "2.07846, 41.2971",
  },
  {
    ident: "LEBZ",
    type: "medium_airport",
    name: "Badajoz Airport",
    elevation_ft: "609",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-EX",
    municipality: "Badajoz",
    gps_code: "LEBZ",
    iata_code: "BJZ",
    coordinates: "-6.8213300704956055, 38.891300201416016",
  },
  {
    ident: "LECH",
    type: "medium_airport",
    name: "Castell����n-Costa Azahar Airport",
    elevation_ft: "1145",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CS",
    municipality: "Castell����n de la Plana",
    gps_code: "LECH",
    iata_code: "CDT",
    coordinates: "0.073333, 40.213889",
  },
  {
    ident: "LECO",
    type: "medium_airport",
    name: "A Coru����a Airport",
    elevation_ft: "326",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-GA",
    municipality: "Culleredo",
    gps_code: "LECO",
    iata_code: "LCG",
    coordinates: "-8.37726, 43.302101",
  },
  {
    ident: "LECU",
    type: "medium_airport",
    name: "Cuatro Vientos Airport",
    elevation_ft: "2269",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-M",
    municipality: "Madrid",
    gps_code: "LECU",
    iata_code: "ECV",
    coordinates: "-3.78514, 40.370701",
  },
  {
    ident: "LEDA",
    type: "medium_airport",
    name: "Lleida-Alguaire Airport",
    elevation_ft: "1152",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CT",
    municipality: "Lleida",
    gps_code: "LEDA",
    iata_code: "ILD",
    coordinates: "0.535023, 41.728185",
  },
  {
    ident: "LEGE",
    type: "medium_airport",
    name: "Girona Airport",
    elevation_ft: "468",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-GE",
    municipality: "Girona",
    gps_code: "LEGE",
    iata_code: "GRO",
    coordinates: "2.7605500221, 41.901000977",
  },
  {
    ident: "LEGR",
    type: "medium_airport",
    name: "Federico Garcia Lorca Airport",
    elevation_ft: "1860",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AN",
    municipality: "Granada",
    gps_code: "LEGR",
    iata_code: "GRX",
    coordinates: "-3.777359962463379, 37.18870162963867",
  },
  {
    ident: "LEHC",
    type: "small_airport",
    name: "Huesca/Pirineos Airport",
    elevation_ft: "1768",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AR",
    municipality: "Monflorite/Alcal���� del Obispo",
    gps_code: "LEHC",
    iata_code: "HSK",
    coordinates: "-0.316666990519, 42.0760993958",
  },
  {
    ident: "LEIB",
    type: "medium_airport",
    name: "Ibiza Airport",
    elevation_ft: "24",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-PM",
    municipality: "Ibiza",
    gps_code: "LEIB",
    iata_code: "IBZ",
    coordinates: "1.3731199502899998, 38.872898101800004",
  },
  {
    ident: "LEJR",
    type: "medium_airport",
    name: "Jerez Airport",
    elevation_ft: "93",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AN",
    municipality: "Jerez de la Forntera",
    gps_code: "LEJR",
    iata_code: "XRY",
    coordinates: "-6.060110092163086, 36.744598388671875",
  },
  {
    ident: "LELC",
    type: "medium_airport",
    name: "San Javier Airport",
    elevation_ft: "11",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-MU",
    municipality: "San Javier",
    gps_code: "LELC",
    iata_code: "MJV",
    coordinates: "-0.8123890161514282, 37.775001525878906",
  },
  {
    ident: "LELL",
    type: "small_airport",
    name: "Sabadell Airport",
    elevation_ft: "485",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CT",
    municipality: "Sabadell",
    gps_code: "LELL",
    iata_code: "QSA",
    coordinates: "2.1050798892974854, 41.52090072631836",
  },
  {
    ident: "LELN",
    type: "medium_airport",
    name: "Leon Airport",
    elevation_ft: "3006",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CL",
    municipality: "Le����n",
    gps_code: "LELN",
    iata_code: "LEN",
    coordinates: "-5.65556001663208, 42.5890007019043",
  },
  {
    ident: "LELO",
    type: "small_airport",
    name: "Logro����o-Agoncillo Airport",
    elevation_ft: "1161",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-LO",
    municipality: "Logro����o",
    gps_code: "LERJ",
    iata_code: "RJL",
    coordinates: "-2.32223510742, 42.4609534888",
  },
  {
    ident: "LEMD",
    type: "large_airport",
    name: "Adolfo Su����rez Madrid������Barajas Airport",
    elevation_ft: "1998",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-M",
    municipality: "Madrid",
    gps_code: "LEMD",
    iata_code: "MAD",
    coordinates: "-3.56264, 40.471926",
  },
  {
    ident: "LEMG",
    type: "large_airport",
    name: "M����laga Airport",
    elevation_ft: "53",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AN",
    municipality: "M����laga",
    gps_code: "LEMG",
    iata_code: "AGP",
    coordinates: "-4.499110221862793, 36.67490005493164",
  },
  {
    ident: "LEMH",
    type: "medium_airport",
    name: "Menorca Airport",
    elevation_ft: "302",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-PM",
    municipality: "Menorca Island",
    gps_code: "LEMH",
    iata_code: "MAH",
    coordinates: "4.218649864196777, 39.86259841918945",
  },
  {
    ident: "LEMO",
    type: "medium_airport",
    name: "Moron Air Base",
    elevation_ft: "285",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AN",
    municipality: "Mor����n",
    gps_code: "LEMO",
    iata_code: "OZP",
    coordinates: "-5.615940093994141, 37.17490005493164",
  },
  {
    ident: "LEO",
    type: "small_airport",
    name: "Lekoni Airport",
    elevation_ft: "2036",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-2",
    municipality: "Lekoni",
    iata_code: "LEO",
    coordinates: "14.2878, -1.5724",
  },
  {
    ident: "LEPA",
    type: "large_airport",
    name: "Palma De Mallorca Airport",
    elevation_ft: "27",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-PM",
    municipality: "Palma De Mallorca",
    gps_code: "LEPA",
    iata_code: "PMI",
    coordinates: "2.73881006241, 39.551700592",
  },
  {
    ident: "LEPP",
    type: "medium_airport",
    name: "Pamplona Airport",
    elevation_ft: "1504",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-NA",
    municipality: "Pamplona",
    gps_code: "LEPP",
    iata_code: "PNA",
    coordinates: "-1.6463299989700317, 42.77000045776367",
  },
  {
    ident: "LERL",
    type: "closed",
    name: "Ciudad Real Central Airport",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CM",
    municipality: "Ciudad Real",
    gps_code: "LERL",
    iata_code: "CQM",
    coordinates: "-3.97, 38.8563888889",
  },
  {
    ident: "LERS",
    type: "medium_airport",
    name: "Reus Air Base",
    elevation_ft: "233",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CT",
    municipality: "Reus",
    gps_code: "LERS",
    iata_code: "REU",
    coordinates: "1.1671700477600098, 41.14739990234375",
  },
  {
    ident: "LERT",
    type: "medium_airport",
    name: "Rota Naval Station Airport",
    elevation_ft: "86",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AN",
    municipality: "Rota",
    gps_code: "LERT",
    iata_code: "ROZ",
    coordinates: "-6.34946012497, 36.645198822",
  },
  {
    ident: "LESA",
    type: "medium_airport",
    name: "Salamanca Airport",
    elevation_ft: "2595",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CL",
    municipality: "Salamanca",
    gps_code: "LESA",
    iata_code: "SLM",
    coordinates: "-5.501989841461182, 40.95209884643555",
  },
  {
    ident: "LESO",
    type: "medium_airport",
    name: "San Sebastian Airport",
    elevation_ft: "16",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-PV",
    municipality: "Hondarribia",
    gps_code: "LESO",
    iata_code: "EAS",
    coordinates: "-1.7906099557876587, 43.35649871826172",
  },
  {
    ident: "LEST",
    type: "large_airport",
    name: "Santiago de Compostela Airport",
    elevation_ft: "1213",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-GA",
    municipality: "Santiago de Compostela",
    gps_code: "LEST",
    iata_code: "SCQ",
    coordinates: "-8.415140151977539, 42.89630126953125",
  },
  {
    ident: "LESU",
    type: "small_airport",
    name: "Pirineus - la Seu d'Urgel Airport",
    elevation_ft: "2625",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CT",
    municipality: "La Seu d'Urgell Pyrenees and Andorra",
    gps_code: "LESU",
    iata_code: "LEU",
    coordinates: "1.40917, 42.3386",
  },
  {
    ident: "LETL",
    type: "medium_airport",
    name: "Teruel Airport",
    elevation_ft: "3380",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AR",
    municipality: "Teruel",
    gps_code: "LETL",
    iata_code: "TEV",
    coordinates: "-1.2183, 40.403",
  },
  {
    ident: "LETO",
    type: "medium_airport",
    name: "Torrej����n Airport",
    elevation_ft: "2026",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-M",
    municipality: "Madrid",
    gps_code: "LETO",
    iata_code: "TOJ",
    coordinates: "-3.4458699226379395, 40.496700286865234",
  },
  {
    ident: "LEVC",
    type: "medium_airport",
    name: "Valencia Airport",
    elevation_ft: "240",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-V",
    municipality: "Valencia",
    gps_code: "LEVC",
    iata_code: "VLC",
    coordinates: "-0.481625, 39.4893",
  },
  {
    ident: "LEVD",
    type: "medium_airport",
    name: "Valladolid Airport",
    elevation_ft: "2776",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-CL",
    municipality: "Valladolid",
    gps_code: "LEVD",
    iata_code: "VLL",
    coordinates: "-4.85194015503, 41.7061004639",
  },
  {
    ident: "LEVT",
    type: "medium_airport",
    name: "Vitoria/Foronda Airport",
    elevation_ft: "1682",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-PV",
    municipality: "Alava",
    gps_code: "LEVT",
    iata_code: "VIT",
    coordinates: "-2.7244699001312256, 42.8828010559082",
  },
  {
    ident: "LEVX",
    type: "medium_airport",
    name: "Vigo Airport",
    elevation_ft: "856",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-GA",
    municipality: "Vigo",
    gps_code: "LEVX",
    iata_code: "VGO",
    coordinates: "-8.62677001953125, 42.2318000793457",
  },
  {
    ident: "LEXJ",
    type: "medium_airport",
    name: "Santander Airport",
    elevation_ft: "16",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-S",
    municipality: "Santander",
    gps_code: "LEXJ",
    iata_code: "SDR",
    coordinates: "-3.82000994682312, 43.427101135253906",
  },
  {
    ident: "LEZG",
    type: "medium_airport",
    name: "Zaragoza Air Base",
    elevation_ft: "863",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AR",
    municipality: "Zaragoza",
    gps_code: "LEZG",
    iata_code: "ZAZ",
    coordinates: "-1.0415500402450562, 41.66619873046875",
  },
  {
    ident: "LEZL",
    type: "medium_airport",
    name: "Sevilla Airport",
    elevation_ft: "112",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-AN",
    municipality: "Sevilla",
    gps_code: "LEZL",
    iata_code: "SVQ",
    coordinates: "-5.8931097984313965, 37.417999267578125",
  },
  {
    ident: "LFAB",
    type: "small_airport",
    name: "St Aubin Airport",
    elevation_ft: "344",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NOR",
    municipality: "Dieppe",
    gps_code: "LFAB",
    iata_code: "DPE",
    coordinates: "1.085279941558838, 49.88249969482422",
  },
  {
    ident: "LFAC",
    type: "medium_airport",
    name: "Calais-Dunkerque Airport",
    elevation_ft: "12",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-HDF",
    municipality: "Calais/Dunkerque",
    gps_code: "LFAC",
    iata_code: "CQF",
    coordinates: "1.954759955406189, 50.962100982666016",
  },
  {
    ident: "LFAQ",
    type: "small_airport",
    name: "Albert-Bray Airport",
    elevation_ft: "364",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-HDF",
    municipality: "Albert/Bray",
    gps_code: "LFAQ",
    iata_code: "BYF",
    coordinates: "2.69765996933, 49.9715003967",
  },
  {
    ident: "LFAT",
    type: "medium_airport",
    name: "Le Touquet-C����te d'Opale Airport",
    elevation_ft: "36",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-HDF",
    municipality: "Le Touquet-Paris-Plage",
    gps_code: "LFAT",
    iata_code: "LTQ",
    coordinates: "1.6205899715423584, 50.517398834228516",
  },
  {
    ident: "LFAV",
    type: "medium_airport",
    name: "Valenciennes-Denain Airport",
    elevation_ft: "165",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-HDF",
    municipality: "Valenciennes/Denain",
    gps_code: "LFAV",
    iata_code: "XVS",
    coordinates: "3.46126, 50.325802",
  },
  {
    ident: "LFBA",
    type: "medium_airport",
    name: "Agen-La Garenne Airport",
    elevation_ft: "204",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Agen/La Garenne",
    gps_code: "LFBA",
    iata_code: "AGF",
    coordinates: "0.5905560255050659, 44.17470169067383",
  },
  {
    ident: "LFBD",
    type: "large_airport",
    name: "Bordeaux-M����rignac Airport",
    elevation_ft: "162",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Bordeaux/M����rignac",
    gps_code: "LFBD",
    iata_code: "BOD",
    coordinates: "-0.715556025505, 44.828300476100004",
  },
  {
    ident: "LFBE",
    type: "medium_airport",
    name: "Bergerac-Roumani����re Airport",
    elevation_ft: "171",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Bergerac/Roumani����re",
    gps_code: "LFBE",
    iata_code: "EGC",
    coordinates: "0.5186110138893127, 44.82529830932617",
  },
  {
    ident: "LFBG",
    type: "medium_airport",
    name: "Cognac-Ch����teaubernard (BA 709) Air Base",
    elevation_ft: "102",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Cognac/Ch����teaubernard",
    gps_code: "LFBG",
    iata_code: "CNG",
    coordinates: "-0.3174999952316284, 45.65829849243164",
  },
  {
    ident: "LFBH",
    type: "medium_airport",
    name: "La Rochelle-����le de R���� Airport",
    elevation_ft: "74",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "La Rochelle/����le de R����",
    gps_code: "LFBH",
    iata_code: "LRH",
    coordinates: "-1.1952799558639526, 46.17919921875",
  },
  {
    ident: "LFBI",
    type: "medium_airport",
    name: "Poitiers-Biard Airport",
    elevation_ft: "423",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Poitiers/Biard",
    gps_code: "LFBI",
    iata_code: "PIS",
    coordinates: "0.30666598677635193, 46.58769989013672",
  },
  {
    ident: "LFBK",
    type: "medium_airport",
    name: "Montlu����on-Gu����ret Airport",
    elevation_ft: "1497",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Montlu����on/Gu����ret",
    gps_code: "LFBK",
    iata_code: "MCU",
    coordinates: "2.363960027694702, 46.222599029541016",
  },
  {
    ident: "LFBL",
    type: "medium_airport",
    name: "Limoges Airport",
    elevation_ft: "1300",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Limoges/Bellegarde",
    gps_code: "LFBL",
    iata_code: "LIG",
    coordinates: "1.1794400215148926, 45.86280059814453",
  },
  {
    ident: "LFBN",
    type: "medium_airport",
    name: "Niort-Souch���� Airport",
    elevation_ft: "203",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Niort/Souch����",
    gps_code: "LFBN",
    iata_code: "NIT",
    coordinates: "-0.394529, 46.313477",
  },
  {
    ident: "LFBO",
    type: "large_airport",
    name: "Toulouse-Blagnac Airport",
    elevation_ft: "499",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Toulouse/Blagnac",
    gps_code: "LFBO",
    iata_code: "TLS",
    coordinates: "1.36382, 43.629101",
  },
  {
    ident: "LFBP",
    type: "medium_airport",
    name: "Pau Pyr����n����es Airport",
    elevation_ft: "616",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Pau/Pyr����n����es (Uzein)",
    gps_code: "LFBP",
    iata_code: "PUF",
    coordinates: "-0.41861099004745483, 43.380001068115234",
  },
  {
    ident: "LFBT",
    type: "medium_airport",
    name: "Tarbes-Lourdes-Pyr����n����es Airport",
    elevation_ft: "1260",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Tarbes/Lourdes/Pyr����n����es",
    gps_code: "LFBT",
    iata_code: "LDE",
    coordinates: "-0.006438999902456999, 43.1786994934082",
  },
  {
    ident: "LFBU",
    type: "medium_airport",
    name: "Angoul����me-Brie-Champniers Airport",
    elevation_ft: "436",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Angoul����me/Brie/Champniers",
    gps_code: "LFBU",
    iata_code: "ANG",
    coordinates: "0.22145600616931915, 45.72919845581055",
  },
  {
    ident: "LFBX",
    type: "medium_airport",
    name: "P����rigueux-Bassillac Airport",
    elevation_ft: "328",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "P����rigueux/Bassillac",
    gps_code: "LFBX",
    iata_code: "PGX",
    coordinates: "0.815555989742279, 45.19810104370117",
  },
  {
    ident: "LFBY",
    type: "small_airport",
    name: "Dax Seyresse Airport",
    elevation_ft: "106",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Perigueux",
    gps_code: "LFBY",
    iata_code: "XDA",
    coordinates: "-1.0688899755477905, 43.68920135498047",
  },
  {
    ident: "LFBZ",
    type: "medium_airport",
    name: "Biarritz-Anglet-Bayonne Airport",
    elevation_ft: "245",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Biarritz/Anglet/Bayonne",
    gps_code: "LFBZ",
    iata_code: "BIQ",
    coordinates: "-1.5311111, 43.4683333",
  },
  {
    ident: "LFCC",
    type: "medium_airport",
    name: "Cahors-Lalbenque Airport",
    elevation_ft: "912",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Cahors/Lalbenque",
    gps_code: "LFCC",
    iata_code: "ZAO",
    coordinates: "1.4752800464630127, 44.35139846801758",
  },
  {
    ident: "LFCI",
    type: "medium_airport",
    name: "Albi-Le S����questre Airport",
    elevation_ft: "564",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Albi/Le S����questre",
    gps_code: "LFCI",
    iata_code: "LBI",
    coordinates: "2.1130599975585938, 43.91389846801758",
  },
  {
    ident: "LFCK",
    type: "medium_airport",
    name: "Castres-Mazamet Airport",
    elevation_ft: "788",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Castres/Mazamet",
    gps_code: "LFCK",
    iata_code: "DCM",
    coordinates: "2.289180040359497, 43.55630111694336",
  },
  {
    ident: "LFCR",
    type: "medium_airport",
    name: "Rodez-Marcillac Airport",
    elevation_ft: "1910",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Rodez/Marcillac",
    gps_code: "LFCR",
    iata_code: "RDZ",
    coordinates: "2.4826700687408447, 44.407901763916016",
  },
  {
    ident: "LFCY",
    type: "medium_airport",
    name: "Royan-M����dis Airport",
    elevation_ft: "72",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Royan/M����dis",
    gps_code: "LFCY",
    iata_code: "RYN",
    coordinates: "-0.9725000262260437, 45.62810134887695",
  },
  {
    ident: "LFDN",
    type: "medium_airport",
    name: "Rochefort-Saint-Agnant (BA 721) Airport",
    elevation_ft: "60",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Rochefort/Saint-Agnant",
    gps_code: "LFDN",
    iata_code: "RCO",
    coordinates: "-0.9830560088157654, 45.88779830932617",
  },
  {
    ident: "LFEY",
    type: "medium_airport",
    name: "����le d'Yeu Airport",
    elevation_ft: "79",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "����le d'Yeu",
    gps_code: "LFEY",
    iata_code: "IDY",
    coordinates: "-2.3911099433898926, 46.71860122680664",
  },
  {
    ident: "LFGA",
    type: "medium_airport",
    name: "Colmar-Houssen Airport",
    elevation_ft: "628",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "Colmar/Houssen",
    gps_code: "LFGA",
    iata_code: "CMR",
    coordinates: "7.359010219573975, 48.109901428222656",
  },
  {
    ident: "LFGJ",
    type: "medium_airport",
    name: "Dole-Tavaux Airport",
    elevation_ft: "645",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BFC",
    municipality: "Dole/Tavaux",
    gps_code: "LFGJ",
    iata_code: "DLE",
    coordinates: "5.435063, 47.042686",
  },
  {
    ident: "LFHM",
    type: "small_airport",
    name: "Meg����ve Altiport",
    elevation_ft: "4823",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Meg����ve",
    gps_code: "LFHM",
    iata_code: "MVV",
    coordinates: "6.649406, 45.823005",
  },
  {
    ident: "LFHO",
    type: "medium_airport",
    name: "Aubenas-Ard����che M����ridional Airport",
    elevation_ft: "923",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Aubenas/Ard����che M����ridional",
    gps_code: "LFHO",
    iata_code: "OBS",
    coordinates: "4.372192, 44.544203",
  },
  {
    ident: "LFHP",
    type: "medium_airport",
    name: "Le Puy-Loudes Airport",
    elevation_ft: "2731",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Le Puy/Loudes",
    gps_code: "LFHP",
    iata_code: "LPY",
    coordinates: "3.762890100479126, 45.0806999206543",
  },
  {
    ident: "LFHU",
    type: "small_airport",
    name: "Altiport L'Alpe d'Huez - Henri GIRAUD",
    elevation_ft: "6102",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "L'Alpe d'Huez",
    gps_code: "LFHU",
    iata_code: "AHZ",
    coordinates: "6.08472, 45.088299",
  },
  {
    ident: "LFJL",
    type: "medium_airport",
    name: "Metz-Nancy-Lorraine Airport",
    elevation_ft: "870",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "Metz / Nancy",
    gps_code: "LFJL",
    iata_code: "ETZ",
    coordinates: "6.25131988525, 48.9821014404",
  },
  {
    ident: "LFJR",
    type: "medium_airport",
    name: "Angers-Loire Airport",
    elevation_ft: "194",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "Angers/Marc����",
    gps_code: "LFJR",
    iata_code: "ANE",
    coordinates: "-0.312222, 47.560299",
  },
  {
    ident: "LFKB",
    type: "medium_airport",
    name: "Bastia-Poretta Airport",
    elevation_ft: "26",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-COR",
    municipality: "Bastia/Poretta",
    gps_code: "LFKB",
    iata_code: "BIA",
    coordinates: "9.48373031616211, 42.55270004272461",
  },
  {
    ident: "LFKC",
    type: "medium_airport",
    name: "Calvi-Sainte-Catherine Airport",
    elevation_ft: "209",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-COR",
    municipality: "Calvi/Sainte-Catherine",
    gps_code: "LFKC",
    iata_code: "CLY",
    coordinates: "8.7930556, 42.5244444",
  },
  {
    ident: "LFKF",
    type: "medium_airport",
    name: "Figari Sud-Corse Airport",
    elevation_ft: "87",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-COR",
    municipality: "Figari Sud-Corse",
    gps_code: "LFKF",
    iata_code: "FSC",
    coordinates: "9.097780227661133, 41.5005989074707",
  },
  {
    ident: "LFKJ",
    type: "medium_airport",
    name: "Ajaccio-Napol����on Bonaparte Airport",
    elevation_ft: "18",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-COR",
    municipality: "Ajaccio/Napol����on Bonaparte",
    gps_code: "LFKJ",
    iata_code: "AJA",
    coordinates: "8.8029203414917, 41.92359924316406",
  },
  {
    ident: "LFKO",
    type: "small_airport",
    name: "Propriano Airport",
    elevation_ft: "13",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-COR",
    municipality: "Propriano",
    gps_code: "LFKO",
    iata_code: "PRP",
    coordinates: "8.889749526977539, 41.66059875488281",
  },
  {
    ident: "LFKS",
    type: "medium_airport",
    name: "Solenzara (BA 126) Air Base",
    elevation_ft: "28",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-COR",
    municipality: "Solenzara",
    gps_code: "LFKS",
    iata_code: "SOZ",
    coordinates: "9.406000137329102, 41.924400329589844",
  },
  {
    ident: "LFKX",
    type: "small_airport",
    name: "M����ribel Altiport",
    elevation_ft: "5636",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    gps_code: "LFKX",
    iata_code: "MFX",
    coordinates: "6.577942, 45.407003",
  },
  {
    ident: "LFLA",
    type: "medium_airport",
    name: "Auxerre-Branches Airport",
    elevation_ft: "523",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BFC",
    municipality: "Auxerre/Branches",
    gps_code: "LFLA",
    iata_code: "AUF",
    coordinates: "3.497109889984131, 47.85020065307617",
  },
  {
    ident: "LFLB",
    type: "medium_airport",
    name: "Chamb����ry-Savoie Airport",
    elevation_ft: "779",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Chamb����ry/Aix-les-Bains",
    gps_code: "LFLB",
    iata_code: "CMF",
    coordinates: "5.880229949951172, 45.638099670410156",
  },
  {
    ident: "LFLC",
    type: "medium_airport",
    name: "Clermont-Ferrand Auvergne Airport",
    elevation_ft: "1090",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Clermont-Ferrand/Auvergne",
    gps_code: "LFLC",
    iata_code: "CFE",
    coordinates: "3.1691699028, 45.7867012024",
  },
  {
    ident: "LFLD",
    type: "medium_airport",
    name: "Bourges Airport",
    elevation_ft: "529",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-CVL",
    municipality: "Bourges",
    gps_code: "LFLD",
    iata_code: "BOU",
    coordinates: "2.3702800273895264, 47.058101654052734",
  },
  {
    ident: "LFLI",
    type: "medium_airport",
    name: "Annemasse Airport",
    elevation_ft: "1620",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Annemasse",
    gps_code: "LFLI",
    iata_code: "QNJ",
    coordinates: "6.268390178680001, 46.1920013428",
  },
  {
    ident: "LFLJ",
    type: "small_airport",
    name: "Courchevel Airport",
    elevation_ft: "6583",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Courchevel",
    gps_code: "LFLJ",
    iata_code: "CVF",
    coordinates: "6.63472, 45.396702",
  },
  {
    ident: "LFLL",
    type: "large_airport",
    name: "Lyon Saint-Exup����ry Airport",
    elevation_ft: "821",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Lyon",
    gps_code: "LFLL",
    iata_code: "LYS",
    coordinates: "5.081111, 45.725556",
  },
  {
    ident: "LFLN",
    type: "medium_airport",
    name: "Saint-Yan Airport",
    elevation_ft: "796",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BFC",
    municipality: "Saint-Yan",
    gps_code: "LFLN",
    iata_code: "SYT",
    coordinates: "4.0132598876953125, 46.412498474121094",
  },
  {
    ident: "LFLO",
    type: "medium_airport",
    name: "Roanne-Renaison Airport",
    elevation_ft: "1106",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Roanne/Renaison",
    gps_code: "LFLO",
    iata_code: "RNE",
    coordinates: "4.001389980316162, 46.05830001831055",
  },
  {
    ident: "LFLP",
    type: "medium_airport",
    name: "Annecy-Haute-Savoie-Mont Blanc Airport",
    elevation_ft: "1521",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Annecy/Meythet",
    gps_code: "LFLP",
    iata_code: "NCY",
    coordinates: "6.1063889, 45.9308333",
  },
  {
    ident: "LFLS",
    type: "medium_airport",
    name: "Grenoble-Is����re Airport",
    elevation_ft: "1302",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-U-A",
    municipality: "Saint-����tienne-de-Saint-Geoirs",
    gps_code: "LFLS",
    iata_code: "GNB",
    coordinates: "5.32937, 45.3629",
  },
  {
    ident: "LFLU",
    type: "medium_airport",
    name: "Valence-Chabeuil Airport",
    elevation_ft: "525",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Valence/Chabeuil",
    gps_code: "LFLU",
    iata_code: "VAF",
    coordinates: "4.9699, 44.9216",
  },
  {
    ident: "LFLV",
    type: "medium_airport",
    name: "Vichy-Charmeil Airport",
    elevation_ft: "817",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Vichy/Charmeil",
    gps_code: "LFLV",
    iata_code: "VHY",
    coordinates: "3.4037399291992188, 46.169700622558594",
  },
  {
    ident: "LFLW",
    type: "medium_airport",
    name: "Aurillac Airport",
    elevation_ft: "2096",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Aurillac",
    gps_code: "LFLW",
    iata_code: "AUR",
    coordinates: "2.4219400882720947, 44.89139938354492",
  },
  {
    ident: "LFLX",
    type: "medium_airport",
    name: 'Ch����teauroux-D����ols "Marcel Dassault" Airport',
    elevation_ft: "529",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-CVL",
    municipality: "Ch����teauroux/D����ols",
    gps_code: "LFLX",
    iata_code: "CHR",
    coordinates: "1.721111, 46.860278",
  },
  {
    ident: "LFLY",
    type: "medium_airport",
    name: "Lyon-Bron Airport",
    elevation_ft: "659",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Lyon/Bron",
    gps_code: "LFLY",
    iata_code: "LYN",
    coordinates: "4.944270133972168, 45.72719955444336",
  },
  {
    ident: "LFMD",
    type: "medium_airport",
    name: "Cannes-Mandelieu Airport",
    elevation_ft: "13",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Cannes/Mandelieu",
    gps_code: "LFMD",
    iata_code: "CEQ",
    coordinates: "6.95348, 43.542",
  },
  {
    ident: "LFMH",
    type: "medium_airport",
    name: "Saint-����tienne-Bouth����on Airport",
    elevation_ft: "1325",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-ARA",
    municipality: "Saint-����tienne/Bouth����on",
    gps_code: "LFMH",
    iata_code: "EBU",
    coordinates: "4.296390056610107, 45.54059982299805",
  },
  {
    ident: "LFMK",
    type: "medium_airport",
    name: "Carcassonne Airport",
    elevation_ft: "433",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Carcassonne/Salvaza",
    gps_code: "LFMK",
    iata_code: "CCF",
    coordinates: "2.3063199520111084, 43.215999603271484",
  },
  {
    ident: "LFML",
    type: "large_airport",
    name: "Marseille Provence Airport",
    elevation_ft: "74",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Marseille",
    gps_code: "LFML",
    iata_code: "MRS",
    coordinates: "5.22142410278, 43.439271922",
  },
  {
    ident: "LFMN",
    type: "large_airport",
    name: "Nice-C����te d'Azur Airport",
    elevation_ft: "12",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Nice",
    gps_code: "LFMN",
    iata_code: "NCE",
    coordinates: "7.215869903560001, 43.6584014893",
  },
  {
    ident: "LFMO",
    type: "medium_airport",
    name: "Orange-Caritat (BA 115) Air Base",
    elevation_ft: "197",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Orange/Caritat",
    gps_code: "LFMO",
    iata_code: "XOG",
    coordinates: "4.866720199584961, 44.140499114990234",
  },
  {
    ident: "LFMP",
    type: "medium_airport",
    name: "Perpignan-Rivesaltes (Llaban����re) Airport",
    elevation_ft: "144",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Perpignan/Rivesaltes",
    gps_code: "LFMP",
    iata_code: "PGF",
    coordinates: "2.8706700801849365, 42.74039840698242",
  },
  {
    ident: "LFMQ",
    type: "medium_airport",
    name: "Le Castellet Airport",
    elevation_ft: "1391",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Le Castellet",
    gps_code: "LFMQ",
    iata_code: "CTT",
    coordinates: "5.785190105438232, 43.252498626708984",
  },
  {
    ident: "LFMR",
    type: "small_airport",
    name: "Barcelonnette - Saint-Pons Airport",
    elevation_ft: "3714",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Le Castellet",
    gps_code: "LFMR",
    iata_code: "BAE",
    coordinates: "6.609186, 44.387198",
  },
  {
    ident: "LFMT",
    type: "medium_airport",
    name: "Montpellier-M����diterran����e Airport",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Montpellier/M����diterran����e",
    gps_code: "LFMT",
    iata_code: "MPL",
    coordinates: "3.96301007270813, 43.57619857788086",
  },
  {
    ident: "LFMU",
    type: "medium_airport",
    name: "B����ziers-Vias Airport",
    elevation_ft: "56",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "B����ziers/Vias",
    gps_code: "LFMU",
    iata_code: "BZR",
    coordinates: "3.3538999557495117, 43.32350158691406",
  },
  {
    ident: "LFMV",
    type: "medium_airport",
    name: "Avignon-Caumont Airport",
    elevation_ft: "124",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Avignon/Caumont",
    gps_code: "LFMV",
    iata_code: "AVN",
    coordinates: "4.901830196380615, 43.90729904174805",
  },
  {
    ident: "LFNA",
    type: "small_airport",
    name: "Gap - Tallard Airfield",
    elevation_ft: "1986",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    gps_code: "LFNA",
    iata_code: "GAT",
    coordinates: "6.03778, 44.455002",
  },
  {
    ident: "LFNB",
    type: "medium_airport",
    name: "Mende-Brenoux Airfield",
    elevation_ft: "3362",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "Mende/Br����noux",
    gps_code: "LFNB",
    iata_code: "MEN",
    coordinates: "3.53282, 44.502102",
  },
  {
    ident: "LFNC",
    type: "small_airport",
    name: "Mont-Dauphin - St-Cr����pin Airport",
    elevation_ft: "2963",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Mende",
    gps_code: "LFNC",
    iata_code: "SCP",
    coordinates: "6.60028, 44.701698",
  },
  {
    ident: "LFOB",
    type: "medium_airport",
    name: "Paris Beauvais Till���� Airport",
    elevation_ft: "359",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-HDF",
    municipality: "Beauvais/Till����",
    gps_code: "LFOB",
    iata_code: "BVA",
    coordinates: "2.1127800941467285, 49.45439910888672",
  },
  {
    ident: "LFOE",
    type: "medium_airport",
    name: "����vreux-Fauville (BA 105) Air Base",
    elevation_ft: "464",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NOR",
    municipality: "����vreux/Fauville",
    gps_code: "LFOE",
    iata_code: "EVX",
    coordinates: "1.2198599576950073, 49.02870178222656",
  },
  {
    ident: "LFOH",
    type: "medium_airport",
    name: "Le Havre Octeville Airport",
    elevation_ft: "313",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NOR",
    municipality: "Le Havre/Octeville",
    gps_code: "LFOH",
    iata_code: "LEH",
    coordinates: "0.08805599808692932, 49.53390121459961",
  },
  {
    ident: "LFOJ",
    type: "medium_airport",
    name: "Orl����ans-Bricy (BA 123) Air Base",
    elevation_ft: "412",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-CVL",
    municipality: "Orl����ans/Bricy",
    gps_code: "LFOJ",
    iata_code: "ORE",
    coordinates: "1.7605600357100002, 47.9878005981",
  },
  {
    ident: "LFOK",
    type: "medium_airport",
    name: "Ch����lons-Vatry Airport",
    elevation_ft: "587",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "Vatry",
    gps_code: "LFOK",
    iata_code: "XCR",
    coordinates: "4.206111111, 48.7733333333",
  },
  {
    ident: "LFOO",
    type: "small_airport",
    name: "Les Sables-d'Olonne Talmont Airport",
    elevation_ft: "105",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "Les Sables-d'Olonne",
    gps_code: "LFOO",
    iata_code: "LSO",
    coordinates: "-1.7227799892425537, 46.476898193359375",
  },
  {
    ident: "LFOP",
    type: "medium_airport",
    name: "Rouen Airport",
    elevation_ft: "512",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NOR",
    municipality: "Rouen/Vall����e de Seine",
    gps_code: "LFOP",
    iata_code: "URO",
    coordinates: "1.1748000383377075, 49.38420104980469",
  },
  {
    ident: "LFOT",
    type: "medium_airport",
    name: "Tours-Val-de-Loire Airport",
    elevation_ft: "357",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-CVL",
    municipality: "Tours/Val de Loire (Loire Valley)",
    gps_code: "LFOT",
    iata_code: "TUF",
    coordinates: "0.727605998516, 47.4322013855",
  },
  {
    ident: "LFOU",
    type: "medium_airport",
    name: "Cholet Le Pontreau Airport",
    elevation_ft: "443",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "Cholet/Le Pontreau",
    gps_code: "LFOU",
    iata_code: "CET",
    coordinates: "-0.8770639896392822, 47.08209991455078",
  },
  {
    ident: "LFOV",
    type: "medium_airport",
    name: "Laval-Entrammes Airport",
    elevation_ft: "330",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "Laval/Entrammes",
    gps_code: "LFOV",
    iata_code: "LVA",
    coordinates: "-0.7429860234260559, 48.03139877319336",
  },
  {
    ident: "LFPB",
    type: "medium_airport",
    name: "Paris-Le Bourget Airport",
    elevation_ft: "218",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-IDF",
    municipality: "Paris",
    gps_code: "LFPB",
    iata_code: "LBG",
    coordinates: "2.441390037536621, 48.969398498535156",
  },
  {
    ident: "LFPC",
    type: "medium_airport",
    name: "Creil Air Base",
    elevation_ft: "291",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-HDF",
    municipality: "Creil",
    gps_code: "LFPC",
    iata_code: "CSF",
    coordinates: "2.5191400051116943, 49.253501892089844",
  },
  {
    ident: "LFPG",
    type: "large_airport",
    name: "Charles de Gaulle International Airport",
    elevation_ft: "392",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-IDF",
    municipality: "Paris",
    gps_code: "LFPG",
    iata_code: "CDG",
    coordinates: "2.55, 49.012798",
  },
  {
    ident: "LFPI",
    type: "heliport",
    name: "Paris Issy-les-Moulineaux Heliport",
    elevation_ft: "112",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-IDF",
    municipality: "Issy-les-Moulineaux",
    gps_code: "LFPI",
    iata_code: "JDP",
    coordinates: "2.272779941558838, 48.83330154418945",
  },
  {
    ident: "LFPN",
    type: "medium_airport",
    name: "Toussus-le-Noble Airport",
    elevation_ft: "538",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-IDF",
    municipality: "Toussus-le-Noble",
    gps_code: "LFPN",
    iata_code: "TNF",
    coordinates: "2.1061899662017822, 48.75189971923828",
  },
  {
    ident: "LFPO",
    type: "large_airport",
    name: "Paris-Orly Airport",
    elevation_ft: "291",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-IDF",
    municipality: "Paris",
    gps_code: "LFPO",
    iata_code: "ORY",
    coordinates: "2.3794444, 48.7233333",
  },
  {
    ident: "LFPT",
    type: "medium_airport",
    name: "A����rodrome de Pontoise - Cormeilles en Vexin",
    elevation_ft: "325",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-IDF",
    municipality: "Cormeilles-en-Vexin",
    gps_code: "LFPT",
    iata_code: "POX",
    coordinates: "2.040833, 49.096667",
  },
  {
    ident: "LFPV",
    type: "medium_airport",
    name: "Villacoublay-V����lizy (BA 107) Air Base",
    elevation_ft: "584",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-IDF",
    municipality: "Villacoublay/V����lizy",
    gps_code: "LFPV",
    iata_code: "VIY",
    coordinates: "2.1916667, 48.7741667",
  },
  {
    ident: "LFQG",
    type: "medium_airport",
    name: "Nevers-Fourchambault Airport",
    elevation_ft: "602",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BFC",
    municipality: "Nevers/Fourchambault",
    gps_code: "LFQG",
    iata_code: "NVS",
    coordinates: "3.1133298873901367, 47.002601623535156",
  },
  {
    ident: "LFQJ",
    type: "medium_airport",
    name: "Maubeuge-����lesmes Airport",
    elevation_ft: "452",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-HDF",
    municipality: "Maubeuge/����lesmes",
    gps_code: "LFQJ",
    iata_code: "XME",
    coordinates: "4.033120155334473, 50.31050109863281",
  },
  {
    ident: "LFQQ",
    type: "medium_airport",
    name: "Lille-Lesquin Airport",
    elevation_ft: "157",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-HDF",
    municipality: "Lille/Lesquin",
    gps_code: "LFQQ",
    iata_code: "LIL",
    coordinates: "3.086886, 50.563332",
  },
  {
    ident: "LFQT",
    type: "medium_airport",
    name: "Merville-Calonne Airport",
    elevation_ft: "61",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-HDF",
    municipality: "Merville/Calonne",
    gps_code: "LFQT",
    iata_code: "HZB",
    coordinates: "2.642240047454834, 50.61840057373047",
  },
  {
    ident: "LFQV",
    type: "medium_airport",
    name: "Charleville-M����zi����res Airport",
    elevation_ft: "492",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "Charleville-M����zi����res",
    gps_code: "LFQV",
    iata_code: "XCZ",
    coordinates: "4.647079944610596, 49.78390121459961",
  },
  {
    ident: "LFRB",
    type: "medium_airport",
    name: "Brest Bretagne Airport",
    elevation_ft: "325",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Brest/Guipavas",
    gps_code: "LFRB",
    iata_code: "BES",
    coordinates: "-4.418540000915527, 48.447898864746094",
  },
  {
    ident: "LFRC",
    type: "medium_airport",
    name: "Cherbourg-Maupertus Airport",
    elevation_ft: "459",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NOR",
    municipality: "Cherbourg/Maupertus",
    gps_code: "LFRC",
    iata_code: "CER",
    coordinates: "-1.4702800512313843, 49.65010070800781",
  },
  {
    ident: "LFRD",
    type: "medium_airport",
    name: "Dinard-Pleurtuit-Saint-Malo Airport",
    elevation_ft: "219",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Dinard/Pleurtuit/Saint-Malo",
    gps_code: "LFRD",
    iata_code: "DNR",
    coordinates: "-2.0799601078033447, 48.58769989013672",
  },
  {
    ident: "LFRE",
    type: "medium_airport",
    name: "La Baule-Escoublac Airport",
    elevation_ft: "105",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "La Baule-Escoublac",
    gps_code: "LFRE",
    iata_code: "LBY",
    coordinates: "-2.3463900089263916, 47.289398193359375",
  },
  {
    ident: "LFRF",
    type: "medium_airport",
    name: "Granville Airport",
    elevation_ft: "45",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NOR",
    municipality: "Granville",
    gps_code: "LFRF",
    iata_code: "GFR",
    coordinates: "-1.564170002937317, 48.88309860229492",
  },
  {
    ident: "LFRG",
    type: "medium_airport",
    name: "Deauville-Saint-Gatien Airport",
    elevation_ft: "479",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NOR",
    municipality: "Deauville",
    gps_code: "LFRG",
    iata_code: "DOL",
    coordinates: "0.154305994511, 49.3652992249",
  },
  {
    ident: "LFRH",
    type: "medium_airport",
    name: "Lorient South Brittany (Bretagne Sud) Airport",
    elevation_ft: "160",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Lorient/Lann/Bihou����",
    gps_code: "LFRH",
    iata_code: "LRT",
    coordinates: "-3.440000057220459, 47.76060104370117",
  },
  {
    ident: "LFRI",
    type: "medium_airport",
    name: "La Roche-sur-Yon Airport",
    elevation_ft: "299",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "La Roche-sur-Yon/Les Ajoncs",
    gps_code: "LFRI",
    iata_code: "EDM",
    coordinates: "-1.3786300420761108, 46.701900482177734",
  },
  {
    ident: "LFRJ",
    type: "medium_airport",
    name: "Landivisiau Air Base",
    elevation_ft: "348",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Landivisiau",
    gps_code: "LFRJ",
    iata_code: "LDV",
    coordinates: "-4.151639938354492, 48.53030014038086",
  },
  {
    ident: "LFRK",
    type: "medium_airport",
    name: "Caen-Carpiquet Airport",
    elevation_ft: "256",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NOR",
    municipality: "Caen/Carpiquet",
    gps_code: "LFRK",
    iata_code: "CFR",
    coordinates: "-0.44999998807907104, 49.173301696777344",
  },
  {
    ident: "LFRM",
    type: "medium_airport",
    name: "Le Mans-Arnage Airport",
    elevation_ft: "194",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "Le Mans/Arnage",
    gps_code: "LFRM",
    iata_code: "LME",
    coordinates: "0.20166699588298798, 47.94860076904297",
  },
  {
    ident: "LFRN",
    type: "medium_airport",
    name: "Rennes-Saint-Jacques Airport",
    elevation_ft: "124",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Rennes/Saint-Jacques",
    gps_code: "LFRN",
    iata_code: "RNS",
    coordinates: "-1.73479, 48.0695",
  },
  {
    ident: "LFRO",
    type: "medium_airport",
    name: "Lannion-C����te de Granit Airport",
    elevation_ft: "290",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Lannion",
    gps_code: "LFRO",
    iata_code: "LAI",
    coordinates: "-3.47166, 48.754398",
  },
  {
    ident: "LFRQ",
    type: "medium_airport",
    name: "Quimper-Cornouaille Airport",
    elevation_ft: "297",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Quimper/Pluguffan",
    gps_code: "LFRQ",
    iata_code: "UIP",
    coordinates: "-4.167789936065674, 47.974998474121094",
  },
  {
    ident: "LFRS",
    type: "medium_airport",
    name: "Nantes Atlantique Airport",
    elevation_ft: "90",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "Nantes",
    gps_code: "LFRS",
    iata_code: "NTE",
    coordinates: "-1.61073005199, 47.153198242200006",
  },
  {
    ident: "LFRT",
    type: "medium_airport",
    name: "Saint-Brieuc-Armor Airport",
    elevation_ft: "453",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Saint-Brieuc/Armor",
    gps_code: "LFRT",
    iata_code: "SBK",
    coordinates: "-2.85444, 48.5378",
  },
  {
    ident: "LFRU",
    type: "medium_airport",
    name: "Morlaix-Ploujean Airport",
    elevation_ft: "272",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Morlaix/Ploujean",
    gps_code: "LFRU",
    iata_code: "MXN",
    coordinates: "-3.81577992439, 48.6031990051",
  },
  {
    ident: "LFRV",
    type: "medium_airport",
    name: "Vannes-Meucon Airport",
    elevation_ft: "446",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BRE",
    municipality: "Vannes/Meucon",
    gps_code: "LFRV",
    iata_code: "VNE",
    coordinates: "-2.718559980392456, 47.72330093383789",
  },
  {
    ident: "LFRZ",
    type: "medium_airport",
    name: "Saint-Nazaire-Montoir Airport",
    elevation_ft: "13",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PDL",
    municipality: "Saint-Nazaire/Montoir",
    gps_code: "LFRZ",
    iata_code: "SNR",
    coordinates: "-2.1566667, 47.3105556",
  },
  {
    ident: "LFSB",
    type: "large_airport",
    name: "EuroAirport Basel-Mulhouse-Freiburg Airport",
    elevation_ft: "885",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "B����le/Mulhouse",
    gps_code: "LFSB",
    iata_code: "BSL",
    coordinates: "7.529167, 47.59",
  },
  {
    ident: "LFSD",
    type: "medium_airport",
    name: "Dijon-Bourgogne Airport",
    elevation_ft: "726",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BFC",
    municipality: "Dijon/Longvic",
    gps_code: "LFSD",
    iata_code: "DIJ",
    coordinates: "5.09, 47.268902",
  },
  {
    ident: "LFSF",
    type: "closed",
    name: "Metz-Frescaty (BA 128) Air Base",
    elevation_ft: "629",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "Metz/Frescaty",
    gps_code: "LFSF",
    iata_code: "MZM",
    coordinates: "6.13167, 49.071701",
  },
  {
    ident: "LFSG",
    type: "medium_airport",
    name: "����pinal-Mirecourt Airport",
    elevation_ft: "1084",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "����pinal/Mirecourt",
    gps_code: "LFSG",
    iata_code: "EPL",
    coordinates: "6.06998, 48.325001",
  },
  {
    ident: "LFSL",
    type: "medium_airport",
    name: "Brive-Souillac",
    elevation_ft: "1016",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-NAQ",
    municipality: "Brive la Gaillarde",
    gps_code: "LFSL",
    iata_code: "BVE",
    coordinates: "1.485556, 45.039722",
  },
  {
    ident: "LFSN",
    type: "medium_airport",
    name: "Nancy-Essey Airport",
    elevation_ft: "751",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "Nancy/Essey",
    gps_code: "LFSN",
    iata_code: "ENC",
    coordinates: "6.230460166931152, 48.692100524902344",
  },
  {
    ident: "LFSQ",
    type: "closed",
    name: "Fontaine Airport",
    elevation_ft: "1208",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-BFC",
    municipality: "Belfort",
    gps_code: "LFSQ",
    iata_code: "BOR",
    coordinates: "7.0108299255371, 47.655601501465",
  },
  {
    ident: "LFSR",
    type: "closed",
    name: "Reims-Champagne (BA 112) Air Base",
    elevation_ft: "312",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "Reims/Champagne",
    gps_code: "LFSR",
    iata_code: "RHE",
    coordinates: "4.05, 49.310001",
  },
  {
    ident: "LFST",
    type: "medium_airport",
    name: "Strasbourg Airport",
    elevation_ft: "505",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-GES",
    municipality: "Strasbourg",
    gps_code: "LFST",
    iata_code: "SXB",
    coordinates: "7.628230094909668, 48.538299560546875",
  },
  {
    ident: "LFTH",
    type: "medium_airport",
    name: "Toulon-Hy����res Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Toulon/Hy����res/Le Palyvestre",
    gps_code: "LFTH",
    iata_code: "TLN",
    coordinates: "6.14602994919, 43.0973014832",
  },
  {
    ident: "LFTU",
    type: "closed",
    name: "Fr����jus Airport",
    elevation_ft: "33",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "Fr����jus",
    gps_code: "LFTU",
    iata_code: "FRJ",
    coordinates: "6.7357, 43.4175",
  },
  {
    ident: "LFTW",
    type: "medium_airport",
    name: "N����mes-Arles-Camargue Airport",
    elevation_ft: "309",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-OCC",
    municipality: "N����mes/Garons",
    gps_code: "LFTW",
    iata_code: "FNI",
    coordinates: "4.4163498878479, 43.75740051269531",
  },
  {
    ident: "LFTZ",
    type: "small_airport",
    name: "La M����le Airport",
    elevation_ft: "59",
    continent: "EU",
    iso_country: "FR",
    iso_region: "FR-PAC",
    municipality: "La M����le",
    gps_code: "LFTZ",
    iata_code: "LTT",
    coordinates: "6.482, 43.205399",
  },
  {
    ident: "LFVM",
    type: "medium_airport",
    name: "Miquelon Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "PM",
    iso_region: "PM-ML",
    municipality: "Miquelon",
    gps_code: "LFVM",
    iata_code: "MQC",
    coordinates: "-56.3802986145, 47.095500946",
  },
  {
    ident: "LFVP",
    type: "medium_airport",
    name: "St Pierre Airport",
    elevation_ft: "27",
    continent: "NA",
    iso_country: "PM",
    iso_region: "PM-SP",
    municipality: "Saint-Pierre",
    gps_code: "LFVP",
    iata_code: "FSP",
    coordinates: "-56.173099517822266, 46.762901306152344",
  },
  {
    ident: "LGAD",
    type: "medium_airport",
    name: "Andravida Air Base",
    elevation_ft: "55",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-14",
    municipality: "Andravida",
    gps_code: "LGAD",
    iata_code: "PYR",
    coordinates: "21.292601, 37.9207",
  },
  {
    ident: "LGAG",
    type: "closed",
    name: "Agrinion Air Base",
    elevation_ft: "154",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-01",
    municipality: "Agrinion",
    gps_code: "LGAG",
    iata_code: "AGQ",
    coordinates: "21.3512001, 38.602001",
  },
  {
    ident: "LGAL",
    type: "medium_airport",
    name: "Dimokritos Airport",
    elevation_ft: "24",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-71",
    municipality: "Alexandroupolis",
    gps_code: "LGAL",
    iata_code: "AXD",
    coordinates: "25.956300735473633, 40.855899810791016",
  },
  {
    ident: "LGAT",
    type: "closed",
    name: "Athen Helenikon Airport",
    elevation_ft: "69",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-A1",
    municipality: "Athens",
    gps_code: "LGAT",
    iata_code: "HEW",
    coordinates: "23.726101, 37.893299",
  },
  {
    ident: "LGAV",
    type: "large_airport",
    name: "Eleftherios Venizelos International Airport",
    elevation_ft: "308",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-A1",
    municipality: "Athens",
    gps_code: "LGAV",
    iata_code: "ATH",
    coordinates: "23.9444999695, 37.9364013672",
  },
  {
    ident: "LGBL",
    type: "medium_airport",
    name: "Nea Anchialos Airport",
    elevation_ft: "83",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-43",
    municipality: "Nea Anchialos",
    gps_code: "LGBL",
    iata_code: "VOL",
    coordinates: "22.794300079345703, 39.219600677490234",
  },
  {
    ident: "LGE",
    type: "small_airport",
    name: "Mulan Airport",
    elevation_ft: "978",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Lake Gregory",
    iata_code: "LGE",
    local_code: "YUAN",
    coordinates: "127.619, -20.1089",
  },
  {
    ident: "LGHI",
    type: "medium_airport",
    name: "Chios Island National Airport",
    elevation_ft: "15",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-85",
    municipality: "Chios Island",
    gps_code: "LGHI",
    iata_code: "JKH",
    coordinates: "26.140600204467773, 38.34320068359375",
  },
  {
    ident: "LGHL",
    type: "closed",
    name: "Porto Cheli Airport",
    elevation_ft: "69",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-11",
    municipality: "Porto Cheli",
    gps_code: "LGHL",
    iata_code: "PKH",
    coordinates: "23.148923, 37.29891",
  },
  {
    ident: "LGIK",
    type: "small_airport",
    name: "Ikaria Airport",
    elevation_ft: "79",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-84",
    municipality: "Ikaria Island",
    gps_code: "LGIK",
    iata_code: "JIK",
    coordinates: "26.3470993042, 37.6827011108",
  },
  {
    ident: "LGIO",
    type: "medium_airport",
    name: "Ioannina Airport",
    elevation_ft: "1558",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-33",
    municipality: "Ioannina",
    gps_code: "LGIO",
    iata_code: "IOA",
    coordinates: "20.822500228881836, 39.6963996887207",
  },
  {
    ident: "LGIR",
    type: "large_airport",
    name: "Heraklion International Nikos Kazantzakis Airport",
    elevation_ft: "115",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-91",
    municipality: "Heraklion",
    gps_code: "LGIR",
    iata_code: "HER",
    coordinates: "25.180299758900002, 35.3396987915",
  },
  {
    ident: "LGKA",
    type: "small_airport",
    name: "Kastoria National Airport",
    elevation_ft: "2167",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-56",
    municipality: "Kastoria",
    gps_code: "LGKA",
    iata_code: "KSO",
    coordinates: "21.2821998596, 40.4463005066",
  },
  {
    ident: "LGKC",
    type: "small_airport",
    name: "Kithira Airport",
    elevation_ft: "1045",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-A1",
    municipality: "Kithira Island",
    gps_code: "LGKC",
    iata_code: "KIT",
    coordinates: "23.0170001984, 36.2742996216",
  },
  {
    ident: "LGKF",
    type: "medium_airport",
    name: "Kefallinia Airport",
    elevation_ft: "59",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-23",
    municipality: "Kefallinia Island",
    gps_code: "LGKF",
    iata_code: "EFL",
    coordinates: "20.500499725341797, 38.12009811401367",
  },
  {
    ident: "LGKJ",
    type: "small_airport",
    name: "Kastelorizo Airport",
    elevation_ft: "489",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-81",
    municipality: "Kastelorizo Island",
    gps_code: "LGKJ",
    iata_code: "KZS",
    coordinates: "29.576400756799995, 36.1417007446",
  },
  {
    ident: "LGKL",
    type: "medium_airport",
    name: "Kalamata Airport",
    elevation_ft: "26",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-17",
    municipality: "Kalamata",
    gps_code: "LGKL",
    iata_code: "KLX",
    coordinates: "22.02549934387207, 37.06829833984375",
  },
  {
    ident: "LGKO",
    type: "medium_airport",
    name: "Kos Airport",
    elevation_ft: "412",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-81",
    municipality: "Kos Island",
    gps_code: "LGKO",
    iata_code: "KGS",
    coordinates: "27.091699600219727, 36.79330062866211",
  },
  {
    ident: "LGKP",
    type: "medium_airport",
    name: "Karpathos Airport",
    elevation_ft: "66",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-81",
    municipality: "Karpathos Island",
    gps_code: "LGKP",
    iata_code: "AOK",
    coordinates: "27.145999908447266, 35.4213981628418",
  },
  {
    ident: "LGKR",
    type: "medium_airport",
    name: "Ioannis Kapodistrias International Airport",
    elevation_ft: "6",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-22",
    municipality: "Kerkyra Island",
    gps_code: "LGKR",
    iata_code: "CFU",
    coordinates: "19.911699295043945, 39.601898193359375",
  },
  {
    ident: "LGKS",
    type: "small_airport",
    name: "Kasos Airport",
    elevation_ft: "35",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-81",
    municipality: "Kasos Island",
    gps_code: "LGKS",
    iata_code: "KSJ",
    coordinates: "26.909999847399998, 35.4213981628",
  },
  {
    ident: "LGKV",
    type: "medium_airport",
    name: "Alexander the Great International Airport",
    elevation_ft: "18",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-55",
    municipality: "Kavala",
    gps_code: "LGKV",
    iata_code: "KVA",
    coordinates: "24.619199752807617, 40.913299560546875",
  },
  {
    ident: "LGKY",
    type: "small_airport",
    name: "Kalymnos Airport",
    elevation_ft: "771",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-81",
    municipality: "Kalymnos Island",
    gps_code: "LGKY",
    iata_code: "JKL",
    coordinates: "26.9405994415, 36.9632987976",
  },
  {
    ident: "LGKZ",
    type: "medium_airport",
    name: "Filippos Airport",
    elevation_ft: "2059",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-58",
    municipality: "Kozani",
    gps_code: "LGKZ",
    iata_code: "KZI",
    coordinates: "21.84079933166504, 40.28609848022461",
  },
  {
    ident: "LGLE",
    type: "small_airport",
    name: "Leros Airport",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-81",
    municipality: "Leros Island",
    gps_code: "LGLE",
    iata_code: "LRS",
    coordinates: "26.800301, 37.184898",
  },
  {
    ident: "LGLM",
    type: "small_airport",
    name: "Limnos Airport",
    elevation_ft: "14",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-83",
    municipality: "Limnos Island",
    gps_code: "LGLM",
    iata_code: "LXS",
    coordinates: "25.236299514799995, 39.917098999",
  },
  {
    ident: "LGLR",
    type: "small_airport",
    name: "Larisa Air Base",
    elevation_ft: "241",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-42",
    municipality: "Larisa",
    gps_code: "LGLR",
    iata_code: "LRA",
    coordinates: "22.4655, 39.650253",
  },
  {
    ident: "LGM",
    type: "closed",
    name: "Laiagam Airport",
    elevation_ft: "7267",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EPW",
    municipality: "Laiagam",
    iata_code: "LGM",
    coordinates: "143.488, -5.4923",
  },
  {
    ident: "LGMK",
    type: "medium_airport",
    name: "Mikonos Airport",
    elevation_ft: "405",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-82",
    municipality: "Mykonos Island",
    gps_code: "LGMK",
    iata_code: "JMK",
    coordinates: "25.348100662231445, 37.43510055541992",
  },
  {
    ident: "LGML",
    type: "small_airport",
    name: "Milos Airport",
    elevation_ft: "10",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-82",
    municipality: "Milos Island",
    gps_code: "LGML",
    iata_code: "MLO",
    coordinates: "24.4769, 36.696899",
  },
  {
    ident: "LGMT",
    type: "medium_airport",
    name: "Mytilene International Airport",
    elevation_ft: "60",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-83",
    municipality: "Mytilene",
    gps_code: "LGMT",
    iata_code: "MJT",
    coordinates: "26.5983009338, 39.0567016602",
  },
  {
    ident: "LGN",
    type: "small_airport",
    name: "Linga Linga Airport",
    elevation_ft: "17",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Linga Linga",
    gps_code: "AYLL",
    iata_code: "LGN",
    local_code: "LLA",
    coordinates: "149.734166667, -5.5319444444400006",
  },
  {
    ident: "LGNX",
    type: "small_airport",
    name: "Naxos Airport",
    elevation_ft: "10",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-82",
    municipality: "Naxos Island",
    gps_code: "LGNX",
    iata_code: "JNX",
    coordinates: "25.3680992126, 37.0811004639",
  },
  {
    ident: "LGPA",
    type: "small_airport",
    name: "Paros National Airport",
    elevation_ft: "131",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-82",
    municipality: "Paros Island",
    gps_code: "LGPA",
    iata_code: "PAS",
    coordinates: "25.113195, 37.020495",
  },
  {
    ident: "LGPL",
    type: "small_airport",
    name: "Astypalaia Airport",
    elevation_ft: "165",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-81",
    municipality: "Astypalaia Island",
    gps_code: "LGPL",
    iata_code: "JTY",
    coordinates: "26.3757991791, 36.5798988342",
  },
  {
    ident: "LGPZ",
    type: "medium_airport",
    name: "Aktion National Airport",
    elevation_ft: "11",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-34",
    municipality: "Preveza/Lefkada",
    gps_code: "LGPZ",
    iata_code: "PVK",
    coordinates: "20.765300750732422, 38.925498962402344",
  },
  {
    ident: "LGRP",
    type: "medium_airport",
    name: "Diagoras Airport",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-81",
    municipality: "Rodes Island",
    gps_code: "LGRP",
    iata_code: "RHO",
    coordinates: "28.086200714111328, 36.405399322509766",
  },
  {
    ident: "LGRX",
    type: "medium_airport",
    name: "Araxos Airport",
    elevation_ft: "46",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-13",
    municipality: "Patras",
    gps_code: "LGRX",
    iata_code: "GPA",
    coordinates: "21.4256, 38.1511",
  },
  {
    ident: "LGSA",
    type: "medium_airport",
    name: "Chania International Airport",
    elevation_ft: "490",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-94",
    municipality: "Souda",
    gps_code: "LGSA",
    iata_code: "CHQ",
    coordinates: "24.149700164794922, 35.531700134277344",
  },
  {
    ident: "LGSK",
    type: "medium_airport",
    name: "Skiathos Island National Airport",
    elevation_ft: "54",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-43",
    municipality: "Skiathos",
    gps_code: "LGSK",
    iata_code: "JSI",
    coordinates: "23.503700256347656, 39.177101135253906",
  },
  {
    ident: "LGSM",
    type: "medium_airport",
    name: "Samos Airport",
    elevation_ft: "19",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-84",
    municipality: "Samos Island",
    gps_code: "LGSM",
    iata_code: "SMI",
    coordinates: "26.911699295043945, 37.689998626708984",
  },
  {
    ident: "LGSO",
    type: "small_airport",
    name: "Syros Airport",
    elevation_ft: "236",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-82",
    municipality: "Syros Island",
    gps_code: "LGSO",
    iata_code: "JSY",
    coordinates: "24.950899124099998, 37.4227981567",
  },
  {
    ident: "LGSP",
    type: "small_airport",
    name: "Sparti Airport",
    elevation_ft: "500",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-16",
    municipality: "Sparti",
    gps_code: "LGSP",
    iata_code: "SPJ",
    coordinates: "22.52630043029785, 36.973899841308594",
  },
  {
    ident: "LGSR",
    type: "medium_airport",
    name: "Santorini Airport",
    elevation_ft: "127",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-82",
    municipality: "Santorini Island",
    gps_code: "LGSR",
    iata_code: "JTR",
    coordinates: "25.479299545288086, 36.399200439453125",
  },
  {
    ident: "LGST",
    type: "medium_airport",
    name: "Sitia Airport",
    elevation_ft: "376",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-92",
    municipality: "Crete Island",
    gps_code: "LGST",
    iata_code: "JSH",
    coordinates: "26.101299285888672, 35.21609878540039",
  },
  {
    ident: "LGSY",
    type: "small_airport",
    name: "Skiros Airport",
    elevation_ft: "44",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-04",
    municipality: "Skiros Island",
    gps_code: "LGSY",
    iata_code: "SKU",
    coordinates: "24.4871997833, 38.9676017761",
  },
  {
    ident: "LGTS",
    type: "large_airport",
    name: "Thessaloniki Macedonia International Airport",
    elevation_ft: "22",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-54",
    municipality: "Thessaloniki",
    gps_code: "LGTS",
    iata_code: "SKG",
    coordinates: "22.97089958190918, 40.51969909667969",
  },
  {
    ident: "LGZA",
    type: "medium_airport",
    name: 'Zakynthos International Airport "Dionysios Solomos"',
    elevation_ft: "15",
    continent: "EU",
    iso_country: "GR",
    iso_region: "GR-21",
    municipality: "Zakynthos Island",
    gps_code: "LGZA",
    iata_code: "ZTH",
    coordinates: "20.8843, 37.7509",
  },
  {
    ident: "LHBP",
    type: "large_airport",
    name: "Budapest Liszt Ferenc International Airport",
    elevation_ft: "495",
    continent: "EU",
    iso_country: "HU",
    iso_region: "HU-PE",
    municipality: "Budapest",
    gps_code: "LHBP",
    iata_code: "BUD",
    coordinates: "19.261093, 47.42976",
  },
  {
    ident: "LHDC",
    type: "medium_airport",
    name: "Debrecen International Airport",
    elevation_ft: "359",
    continent: "EU",
    iso_country: "HU",
    iso_region: "HU-HB",
    municipality: "Debrecen",
    gps_code: "LHDC",
    iata_code: "DEB",
    coordinates: "21.615299224853516, 47.48889923095703",
  },
  {
    ident: "LHMC",
    type: "small_airport",
    name: "Miskolc Airport",
    elevation_ft: "390",
    continent: "EU",
    iso_country: "HU",
    iso_region: "HU-BZ",
    municipality: "Miskolc",
    gps_code: "LHMC",
    iata_code: "MCQ",
    coordinates: "20.791400909423828, 48.13690185546875",
  },
  {
    ident: "LHP",
    type: "closed",
    name: "Lehu Airport",
    elevation_ft: "1770",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Lehu",
    iata_code: "LHP",
    local_code: "LEHU",
    coordinates: "155.712442, -6.527493",
  },
  {
    ident: "LHPP",
    type: "medium_airport",
    name: "P����cs-Pog����ny Airport",
    elevation_ft: "1000",
    continent: "EU",
    iso_country: "HU",
    iso_region: "HU-BA",
    municipality: "P����cs-Pog����ny",
    gps_code: "LHPP",
    iata_code: "PEV",
    coordinates: "18.240996, 45.990898",
  },
  {
    ident: "LHSM",
    type: "medium_airport",
    name: "H����v����z������Balaton Airport",
    elevation_ft: "408",
    continent: "EU",
    iso_country: "HU",
    iso_region: "HU-ZA",
    municipality: "S����rmell����k",
    gps_code: "LHSM",
    iata_code: "SOB",
    coordinates: "17.159084, 46.686391",
  },
  {
    ident: "LHTA",
    type: "medium_airport",
    name: "Tasz����r Air Base",
    elevation_ft: "531",
    continent: "EU",
    iso_country: "HU",
    iso_region: "HU-SO",
    municipality: "Tasz����r",
    gps_code: "LHTA",
    iata_code: "TZR",
    coordinates: "17.917499542236328, 46.39310073852539",
  },
  {
    ident: "LIAP",
    type: "small_airport",
    name: "L'Aquila������Preturo Airport",
    elevation_ft: "2211",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-65",
    municipality: "L'Aquila",
    gps_code: "LIAP",
    iata_code: "QAQ",
    local_code: "AQ03",
    coordinates: "13.3092, 42.379902",
  },
  {
    ident: "LIBC",
    type: "medium_airport",
    name: "Crotone Airport",
    elevation_ft: "522",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-78",
    municipality: "Crotone",
    gps_code: "LIBC",
    iata_code: "CRV",
    local_code: "KR01",
    coordinates: "17.0802, 38.9972",
  },
  {
    ident: "LIBD",
    type: "large_airport",
    name: "Bari Karol Wojty����a Airport",
    elevation_ft: "177",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-75",
    municipality: "Bari",
    gps_code: "LIBD",
    iata_code: "BRI",
    local_code: "BA02",
    coordinates: "16.760599, 41.138901",
  },
  {
    ident: "LIBF",
    type: "medium_airport",
    name: 'Foggia "Gino Lisa" Airport',
    elevation_ft: "265",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-75",
    municipality: "Foggia",
    gps_code: "LIBF",
    iata_code: "FOG",
    local_code: "FG05",
    coordinates: "15.535, 41.432899",
  },
  {
    ident: "LIBG",
    type: "medium_airport",
    name: 'Taranto-Grottaglie "Marcello Arlotta" Airport',
    elevation_ft: "215",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-75",
    municipality: "Grottaglie",
    gps_code: "LIBG",
    iata_code: "TAR",
    local_code: "TA02",
    coordinates: "17.4032, 40.517502",
  },
  {
    ident: "LIBN",
    type: "medium_airport",
    name: "Lecce Galatina Air Base",
    elevation_ft: "156",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-75",
    gps_code: "LIBN",
    iata_code: "LCC",
    local_code: "LE08",
    coordinates: "18.133301, 40.239201",
  },
  {
    ident: "LIBP",
    type: "medium_airport",
    name: "Pescara International Airport",
    elevation_ft: "48",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-65",
    municipality: "Pescara",
    gps_code: "LIBP",
    iata_code: "PSR",
    local_code: "PE01",
    coordinates: "14.1811, 42.431702",
  },
  {
    ident: "LIBR",
    type: "medium_airport",
    name: "Brindisi ������ Salento Airport",
    elevation_ft: "47",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-75",
    municipality: "Brindisi",
    gps_code: "LIBR",
    iata_code: "BDS",
    local_code: "BR03",
    coordinates: "17.947001, 40.6576",
  },
  {
    ident: "LICA",
    type: "medium_airport",
    name: "Lamezia Terme Airport",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-78",
    municipality: "Lamezia Terme (CZ)",
    gps_code: "LICA",
    iata_code: "SUF",
    local_code: "CZ02",
    coordinates: "16.2423, 38.905399",
  },
  {
    ident: "LICB",
    type: "medium_airport",
    name: "Comiso Airport",
    elevation_ft: "623",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-82",
    municipality: "Comiso",
    gps_code: "LICB",
    iata_code: "CIY",
    local_code: "RG06",
    coordinates: "14.607182, 36.994601",
  },
  {
    ident: "LICC",
    type: "large_airport",
    name: "Catania-Fontanarossa Airport",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-82",
    municipality: "Catania",
    gps_code: "LICC",
    iata_code: "CTA",
    local_code: "CT03",
    coordinates: "15.0664, 37.466801",
  },
  {
    ident: "LICD",
    type: "medium_airport",
    name: "Lampedusa Airport",
    elevation_ft: "70",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-82",
    municipality: "Lampedusa",
    gps_code: "LICD",
    iata_code: "LMP",
    local_code: "AG01",
    coordinates: "12.6181, 35.497898",
  },
  {
    ident: "LICG",
    type: "medium_airport",
    name: "Pantelleria Airport",
    elevation_ft: "628",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-82",
    municipality: "Pantelleria (TP)",
    gps_code: "LICG",
    iata_code: "PNL",
    local_code: "TP02",
    coordinates: "11.9689, 36.816502",
  },
  {
    ident: "LICJ",
    type: "large_airport",
    name: "Falcone������Borsellino Airport",
    elevation_ft: "65",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-82",
    municipality: "Palermo",
    gps_code: "LICJ",
    iata_code: "PMO",
    local_code: "PA03",
    coordinates: "13.091, 38.175999",
  },
  {
    ident: "LICR",
    type: "medium_airport",
    name: "Reggio Calabria Airport",
    elevation_ft: "96",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-78",
    municipality: "Reggio Calabria",
    gps_code: "LICR",
    iata_code: "REG",
    local_code: "RC01",
    coordinates: "15.6516, 38.071201",
  },
  {
    ident: "LICT",
    type: "medium_airport",
    name: "Vincenzo Florio Airport Trapani-Birgi",
    elevation_ft: "25",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-82",
    municipality: "Trapani (TP)",
    gps_code: "LICT",
    iata_code: "TPS",
    local_code: "TP01",
    coordinates: "12.488, 37.9114",
  },
  {
    ident: "LICZ",
    type: "medium_airport",
    name: "Sigonella Navy Air Base",
    elevation_ft: "79",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-82",
    municipality: "Sigonella (CT)",
    gps_code: "LICZ",
    iata_code: "NSY",
    local_code: "CT04",
    coordinates: "14.9224, 37.401699",
  },
  {
    ident: "LIDB",
    type: "small_airport",
    name: "Belluno Airport",
    elevation_ft: "1240",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-34",
    municipality: "Belluno (BL)",
    gps_code: "LIDB",
    iata_code: "BLX",
    local_code: "BL05",
    coordinates: "12.250389, 46.166549",
  },
  {
    ident: "LIDI",
    type: "closed",
    name: "Cortina Airport",
    elevation_ft: "4213",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-34",
    municipality: "Cortina D'Ampezzo",
    gps_code: "LIDI",
    iata_code: "CDF",
    coordinates: "12.118000030500001, 46.573001861600005",
  },
  {
    ident: "LIDR",
    type: "small_airport",
    name: "Ravenna Airport",
    elevation_ft: "1",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-45",
    municipality: "Ravenna",
    gps_code: "LIDR",
    iata_code: "RAN",
    local_code: "RA06",
    coordinates: "12.224978, 44.36391",
  },
  {
    ident: "LIEA",
    type: "medium_airport",
    name: "Alghero-Fertilia Airport",
    elevation_ft: "87",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-88",
    municipality: "Alghero",
    gps_code: "LIEA",
    iata_code: "AHO",
    local_code: "SS02",
    coordinates: "8.29077, 40.632099",
  },
  {
    ident: "LIED",
    type: "medium_airport",
    name: "Decimomannu Air Base",
    elevation_ft: "100",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-88",
    municipality: "Decimomannu",
    gps_code: "LIED",
    iata_code: "DCI",
    local_code: "CA12",
    coordinates: "8.97248, 39.354198",
  },
  {
    ident: "LIEE",
    type: "large_airport",
    name: "Cagliari Elmas Airport",
    elevation_ft: "13",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-88",
    municipality: "Cagliari",
    gps_code: "LIEE",
    iata_code: "CAG",
    local_code: "CA07",
    coordinates: "9.05428, 39.251499",
  },
  {
    ident: "LIEO",
    type: "medium_airport",
    name: "Olbia Costa Smeralda Airport",
    elevation_ft: "37",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-88",
    municipality: "Olbia (SS)",
    gps_code: "LIEO",
    iata_code: "OLB",
    local_code: "SS03",
    coordinates: "9.51763, 40.898701",
  },
  {
    ident: "LIER",
    type: "small_airport",
    name: "Oristano-Fenosu Airport",
    elevation_ft: "36",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-88",
    municipality: "Oristano",
    gps_code: "LIER",
    iata_code: "FNU",
    local_code: "OR01",
    coordinates: "8.642661, 39.895308",
  },
  {
    ident: "LIET",
    type: "medium_airport",
    name: "Tortol���� Airport",
    elevation_ft: "23",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-88",
    municipality: "Arbatax",
    gps_code: "LIET",
    iata_code: "TTB",
    local_code: "NU01",
    coordinates: "9.68298, 39.9188",
  },
  {
    ident: "LIMC",
    type: "large_airport",
    name: "Malpensa International Airport",
    elevation_ft: "768",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-25",
    municipality: "Milan",
    gps_code: "LIMC",
    iata_code: "MXP",
    local_code: "MI12",
    coordinates: "8.72811, 45.6306",
  },
  {
    ident: "LIME",
    type: "large_airport",
    name: "Milan Bergamo Airport",
    elevation_ft: "782",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-25",
    municipality: "Bergamo",
    gps_code: "LIME",
    iata_code: "BGY",
    local_code: "BG01",
    coordinates: "9.70417, 45.673901",
  },
  {
    ident: "LIMF",
    type: "large_airport",
    name: "Turin Airport",
    elevation_ft: "989",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-21",
    municipality: "Torino",
    gps_code: "LIMF",
    iata_code: "TRN",
    local_code: "TO11",
    coordinates: "7.64963, 45.200802",
  },
  {
    ident: "LIMG",
    type: "medium_airport",
    name: "Villanova D'Albenga International Airport",
    elevation_ft: "148",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-42",
    municipality: "Albenga (SV)",
    gps_code: "LIMG",
    iata_code: "ALL",
    local_code: "SV01",
    coordinates: "8.12743, 44.050598",
  },
  {
    ident: "LIMJ",
    type: "large_airport",
    name: "Genoa Cristoforo Colombo Airport",
    elevation_ft: "13",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-42",
    municipality: "Genova",
    gps_code: "LIMJ",
    iata_code: "GOA",
    local_code: "GE01",
    coordinates: "8.8375, 44.4133",
  },
  {
    ident: "LIML",
    type: "large_airport",
    name: "Milano Linate Airport",
    elevation_ft: "353",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-25",
    municipality: "Milan",
    gps_code: "LIML",
    iata_code: "LIN",
    local_code: "MI11",
    coordinates: "9.27674, 45.445099",
  },
  {
    ident: "LIMP",
    type: "medium_airport",
    name: "Parma Airport",
    elevation_ft: "161",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-45",
    municipality: "Parma",
    gps_code: "LIMP",
    iata_code: "PMF",
    local_code: "PR03",
    coordinates: "10.2964, 44.824501",
  },
  {
    ident: "LIMW",
    type: "medium_airport",
    name: "Aosta Airport",
    elevation_ft: "1791",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-23",
    municipality: "Aosta",
    gps_code: "LIMW",
    iata_code: "AOT",
    local_code: "AO01",
    coordinates: "7.36872, 45.738499",
  },
  {
    ident: "LIMZ",
    type: "medium_airport",
    name: "Cuneo International Airport",
    elevation_ft: "1267",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-21",
    municipality: "Cuneo",
    gps_code: "LIMZ",
    iata_code: "CUF",
    local_code: "CN03",
    coordinates: "7.62322, 44.547001",
  },
  {
    ident: "LIPA",
    type: "medium_airport",
    name: "Aviano Air Base",
    elevation_ft: "410",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-36",
    municipality: "Aviano (PN)",
    gps_code: "LIPA",
    iata_code: "AVB",
    local_code: "PN07",
    coordinates: "12.596503, 46.031898",
  },
  {
    ident: "LIPB",
    type: "medium_airport",
    name: "Bolzano Airport",
    elevation_ft: "789",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-32",
    municipality: "Bolzano",
    gps_code: "LIPB",
    iata_code: "BZO",
    local_code: "BZ01",
    coordinates: "11.3264, 46.460201",
  },
  {
    ident: "LIPD",
    type: "small_airport",
    name: "Udine-Campoformido Air Base",
    elevation_ft: "305",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-36",
    municipality: "Udine",
    gps_code: "LIPD",
    iata_code: "UDN",
    local_code: "UD07",
    coordinates: "13.1868, 46.0322",
  },
  {
    ident: "LIPE",
    type: "large_airport",
    name: "Bologna Guglielmo Marconi Airport",
    elevation_ft: "123",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-45",
    municipality: "Bologna",
    gps_code: "LIPE",
    iata_code: "BLQ",
    local_code: "BO08",
    coordinates: "11.2887, 44.5354",
  },
  {
    ident: "LIPH",
    type: "large_airport",
    name: "Treviso-Sant'Angelo Airport",
    elevation_ft: "59",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-34",
    municipality: "Treviso",
    gps_code: "LIPH",
    iata_code: "TSF",
    local_code: "TV01",
    coordinates: "12.1944, 45.648399",
  },
  {
    ident: "LIPK",
    type: "medium_airport",
    name: "Forl���� Airport",
    elevation_ft: "97",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-45",
    municipality: "Forl���� (FC)",
    gps_code: "LIPK",
    iata_code: "FRL",
    local_code: "FC03",
    coordinates: "12.0701, 44.194801",
  },
  {
    ident: "LIPO",
    type: "medium_airport",
    name: "Brescia Airport",
    elevation_ft: "355",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-25",
    municipality: "Montichiari (BS)",
    gps_code: "LIPO",
    iata_code: "VBS",
    local_code: "BS04",
    coordinates: "10.3306, 45.428902",
  },
  {
    ident: "LIPQ",
    type: "medium_airport",
    name: "Trieste������Friuli Venezia Giulia Airport",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-36",
    municipality: "Trieste",
    gps_code: "LIPQ",
    iata_code: "TRS",
    local_code: "GO02",
    coordinates: "13.4722, 45.827499",
  },
  {
    ident: "LIPR",
    type: "medium_airport",
    name: "Federico Fellini International Airport",
    elevation_ft: "40",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-45",
    municipality: "Rimini",
    gps_code: "LIPR",
    iata_code: "RMI",
    local_code: "RN01",
    coordinates: "12.6117, 44.020302",
  },
  {
    ident: "LIPT",
    type: "closed",
    name: "Vicenza Airport",
    elevation_ft: "128",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-34",
    municipality: "Vicenza",
    gps_code: "LIPT",
    iata_code: "VIC",
    coordinates: "11.5295, 45.573399",
  },
  {
    ident: "LIPU",
    type: "medium_airport",
    name: "Padova Airport",
    elevation_ft: "44",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-34",
    municipality: "Padova",
    gps_code: "LIPU",
    iata_code: "QPA",
    local_code: "PD11",
    coordinates: "11.8479, 45.395802",
  },
  {
    ident: "LIPX",
    type: "large_airport",
    name: "Verona Villafranca Airport",
    elevation_ft: "239",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-34",
    municipality: "Verona",
    gps_code: "LIPX",
    iata_code: "VRN",
    local_code: "VR10",
    coordinates: "10.8885, 45.395699",
  },
  {
    ident: "LIPY",
    type: "medium_airport",
    name: "Ancona Falconara Airport",
    elevation_ft: "49",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-57",
    municipality: "Ancona",
    gps_code: "LIPY",
    iata_code: "AOI",
    local_code: "AN01",
    coordinates: "13.3623, 43.616299",
  },
  {
    ident: "LIPZ",
    type: "large_airport",
    name: "Venice Marco Polo Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-34",
    municipality: "Venice",
    gps_code: "LIPZ",
    iata_code: "VCE",
    local_code: "VE05",
    coordinates: "12.3519, 45.505299",
  },
  {
    ident: "LIQL",
    type: "small_airport",
    name: "Lucca-Tassignano Airport",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-52",
    municipality: "Lucca",
    gps_code: "LIQL",
    iata_code: "LCV",
    local_code: "LU02",
    coordinates: "10.577928, 43.825825",
  },
  {
    ident: "LIQN",
    type: "small_airport",
    name: "Rieti Airport",
    elevation_ft: "1278",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-62",
    municipality: "Rieti",
    gps_code: "LIQN",
    iata_code: "QRT",
    local_code: "RI01",
    coordinates: "12.8517, 42.4272",
  },
  {
    ident: "LIQS",
    type: "medium_airport",
    name: "Siena-Ampugnano Airport",
    elevation_ft: "634",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-52",
    municipality: "Siena",
    gps_code: "LIQS",
    iata_code: "SAY",
    local_code: "SI01",
    coordinates: "11.255, 43.256302",
  },
  {
    ident: "LIQW",
    type: "medium_airport",
    name: "Sarzana-Luni Air Base",
    elevation_ft: "45",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-42",
    municipality: "Sarzana (SP)",
    gps_code: "LIQW",
    iata_code: "QLP",
    local_code: "SP01",
    coordinates: "9.98795, 44.088001",
  },
  {
    ident: "LIRA",
    type: "large_airport",
    name: "Ciampino������G. B. Pastine International Airport",
    elevation_ft: "427",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-62",
    municipality: "Rome",
    gps_code: "LIRA",
    iata_code: "CIA",
    local_code: "RM12",
    coordinates: "12.5949, 41.7994",
  },
  {
    ident: "LIRF",
    type: "large_airport",
    name: "Leonardo da Vinci������Fiumicino Airport",
    elevation_ft: "13",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-62",
    municipality: "Rome",
    gps_code: "LIRF",
    iata_code: "FCO",
    local_code: "RM11",
    coordinates: "12.2388889, 41.8002778",
  },
  {
    ident: "LIRI",
    type: "medium_airport",
    name: "Salerno Costa d'Amalfi Airport",
    elevation_ft: "119",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-72",
    municipality: "Salerno",
    gps_code: "LIRI",
    iata_code: "QSR",
    local_code: "SA02",
    coordinates: "14.9113, 40.620399",
  },
  {
    ident: "LIRJ",
    type: "medium_airport",
    name: "Marina Di Campo Airport",
    elevation_ft: "31",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-52",
    municipality: "Marina  Di Campo",
    gps_code: "LIRJ",
    iata_code: "EBA",
    local_code: "LI04",
    coordinates: "10.2394, 42.7603",
  },
  {
    ident: "LIRL",
    type: "medium_airport",
    name: "Latina Air Base",
    elevation_ft: "94",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-62",
    municipality: "Latina",
    gps_code: "LIRL",
    iata_code: "QLT",
    local_code: "LT02",
    coordinates: "12.909, 41.5424",
  },
  {
    ident: "LIRN",
    type: "large_airport",
    name: "Naples International Airport",
    elevation_ft: "294",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-72",
    municipality: "N����poli",
    gps_code: "LIRN",
    iata_code: "NAP",
    local_code: "NA01",
    coordinates: "14.2908, 40.886002",
  },
  {
    ident: "LIRP",
    type: "large_airport",
    name: "Pisa International Airport",
    elevation_ft: "6",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-52",
    municipality: "Pisa",
    gps_code: "LIRP",
    iata_code: "PSA",
    local_code: "PI05",
    coordinates: "10.3927, 43.683899",
  },
  {
    ident: "LIRQ",
    type: "medium_airport",
    name: "Peretola Airport",
    elevation_ft: "142",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-52",
    municipality: "Firenze",
    gps_code: "LIRQ",
    iata_code: "FLR",
    local_code: "FI04",
    coordinates: "11.2051, 43.810001",
  },
  {
    ident: "LIRS",
    type: "medium_airport",
    name: "Grosseto Air Base",
    elevation_ft: "17",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-52",
    municipality: "Grosetto",
    gps_code: "LIRS",
    iata_code: "GRS",
    local_code: "GR06",
    coordinates: "11.0719, 42.759701",
  },
  {
    ident: "LIRZ",
    type: "medium_airport",
    name: "Perugia San Francesco d'Assisi ������ Umbria International Airport",
    elevation_ft: "697",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-55",
    municipality: "Perugia",
    gps_code: "LIRZ",
    iata_code: "PEG",
    local_code: "PG10",
    coordinates: "12.5132, 43.095901",
  },
  {
    ident: "LJLJ",
    type: "large_airport",
    name: "Ljubljana Jo����e Pu����nik Airport",
    elevation_ft: "1273",
    continent: "EU",
    iso_country: "SI",
    iso_region: "SI-061",
    municipality: "Ljubljana",
    gps_code: "LJLJ",
    iata_code: "LJU",
    coordinates: "14.4576, 46.223701",
  },
  {
    ident: "LJMB",
    type: "medium_airport",
    name: "Maribor Airport",
    elevation_ft: "876",
    continent: "EU",
    iso_country: "SI",
    iso_region: "SI-070",
    gps_code: "LJMB",
    iata_code: "MBX",
    coordinates: "15.686100006103516, 46.47990036010742",
  },
  {
    ident: "LJPZ",
    type: "medium_airport",
    name: "Portoroz Airport",
    elevation_ft: "7",
    continent: "EU",
    iso_country: "SI",
    iso_region: "SI-090",
    municipality: "Portoroz",
    gps_code: "LJPZ",
    iata_code: "POW",
    coordinates: "13.614999771118164, 45.4734001159668",
  },
  {
    ident: "LKC",
    type: "small_airport",
    name: "Lekana Airport",
    elevation_ft: "2634",
    continent: "AF",
    iso_country: "CG",
    iso_region: "CG-14",
    municipality: "Lekana",
    iata_code: "LKC",
    coordinates: "14.606, -2.313",
  },
  {
    ident: "LKHO",
    type: "closed",
    name: "Hole����ov Airport",
    elevation_ft: "761",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-ZL",
    municipality: "Hole����ov",
    gps_code: "LKHO",
    iata_code: "GTW",
    coordinates: "17.5688992, 49.3143997",
  },
  {
    ident: "LKKU",
    type: "medium_airport",
    name: "Kunovice Airport",
    elevation_ft: "581",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-ZL",
    municipality: "Uhersk���� Hradi����t����",
    gps_code: "LKKU",
    iata_code: "UHE",
    coordinates: "17.439699172973633, 49.02939987182617",
  },
  {
    ident: "LKKV",
    type: "medium_airport",
    name: "Karlovy Vary International Airport",
    elevation_ft: "1989",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-KA",
    municipality: "Karlovy Vary",
    gps_code: "LKKV",
    iata_code: "KLV",
    coordinates: "12.914999961853027, 50.202999114990234",
  },
  {
    ident: "LKMR",
    type: "small_airport",
    name: "Mari����nsk���� L����zn���� Airport",
    elevation_ft: "1772",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-KA",
    municipality: "Mari����nsk���� L����zn����",
    gps_code: "LKMR",
    iata_code: "MKA",
    coordinates: "12.72469997406, 49.922798156738",
  },
  {
    ident: "LKMT",
    type: "medium_airport",
    name: "Ostrava Leos Jan��������ek Airport",
    elevation_ft: "844",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-MO",
    municipality: "Ostrava",
    gps_code: "LKMT",
    iata_code: "OSR",
    coordinates: "18.111099243164062, 49.6963005065918",
  },
  {
    ident: "LKOL",
    type: "small_airport",
    name: "Olomouc Airport",
    elevation_ft: "869",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-OL",
    municipality: "Olomouc",
    gps_code: "LKOL",
    iata_code: "OLO",
    coordinates: "17.2108, 49.587799",
  },
  {
    ident: "LKPD",
    type: "medium_airport",
    name: "Pardubice Airport",
    elevation_ft: "741",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-PA",
    municipality: "Pardubice",
    gps_code: "LKPD",
    iata_code: "PED",
    coordinates: "15.73859977722168, 50.01340103149414",
  },
  {
    ident: "LKPO",
    type: "medium_airport",
    name: "P����erov Air Base",
    elevation_ft: "676",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-OL",
    municipality: "P����erov",
    gps_code: "LKPO",
    iata_code: "PRV",
    coordinates: "17.404699325561523, 49.42580032348633",
  },
  {
    ident: "LKPR",
    type: "large_airport",
    name: "V����clav Havel Airport Prague",
    elevation_ft: "1247",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-PR",
    municipality: "Prague",
    gps_code: "LKPR",
    iata_code: "PRG",
    coordinates: "14.26, 50.1008",
  },
  {
    ident: "LKTB",
    type: "medium_airport",
    name: "Brno-Tu����any Airport",
    elevation_ft: "778",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-JM",
    municipality: "Brno",
    gps_code: "LKTB",
    iata_code: "BRQ",
    coordinates: "16.694400787353516, 49.15129852294922",
  },
  {
    ident: "LKVO",
    type: "medium_airport",
    name: "Vodochody Airport",
    elevation_ft: "919",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-ST",
    municipality: "Vodochody",
    gps_code: "LKVO",
    iata_code: "VOD",
    coordinates: "14.3958, 50.216599",
  },
  {
    ident: "LKZA",
    type: "small_airport",
    name: "Zabreh Ostrava Airport",
    elevation_ft: "791",
    continent: "EU",
    iso_country: "CZ",
    iso_region: "CZ-MO",
    municipality: "Zabreh",
    gps_code: "LKZA",
    iata_code: "ZBE",
    coordinates: "18.07830047607422, 49.92829895019531",
  },
  {
    ident: "LLBG",
    type: "large_airport",
    name: "Ben Gurion International Airport",
    elevation_ft: "135",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-M",
    municipality: "Tel Aviv",
    gps_code: "LLBG",
    iata_code: "TLV",
    coordinates: "34.88669967651367, 32.01139831542969",
  },
  {
    ident: "LLBS",
    type: "small_airport",
    name: "Beersheba (Teyman) Airport",
    elevation_ft: "656",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-D",
    municipality: "Beersheba",
    gps_code: "LLBS",
    iata_code: "BEV",
    coordinates: "34.722999572754, 31.287000656128",
  },
  {
    ident: "LLER",
    type: "medium_airport",
    name: "Ramon Airport",
    elevation_ft: "288",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-D",
    municipality: "Eilat",
    gps_code: "LLER",
    iata_code: "ETM",
    coordinates: "35.011416, 29.723694",
  },
  {
    ident: "LLET",
    type: "closed",
    name: "Eilat Airport",
    elevation_ft: "42",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-D",
    municipality: "Eilat",
    gps_code: "LLET",
    iata_code: "ETH",
    coordinates: "34.960098, 29.5613",
  },
  {
    ident: "LLEY",
    type: "small_airport",
    name: "Ein Yahav Airfield",
    elevation_ft: "-164",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-D",
    municipality: "Sapir",
    gps_code: "LLEY",
    iata_code: "EIY",
    coordinates: "35.20330047607422, 30.621700286865234",
  },
  {
    ident: "LLH",
    type: "small_airport",
    name: "Reginaldo Hammer Airport",
    elevation_ft: "96",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-CR",
    municipality: "La Lima",
    iata_code: "LLH",
    coordinates: "-87.8988, 15.4422",
  },
  {
    ident: "LLHA",
    type: "medium_airport",
    name: "Haifa International Airport",
    elevation_ft: "28",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-HA",
    municipality: "Haifa",
    gps_code: "LLHA",
    iata_code: "HFA",
    coordinates: "35.04309844970703, 32.80939865112305",
  },
  {
    ident: "LLIB",
    type: "medium_airport",
    name: "Ben Ya'akov Airport",
    elevation_ft: "922",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-Z",
    municipality: "Rosh Pina",
    gps_code: "LLIB",
    iata_code: "RPN",
    coordinates: "35.5718994140625, 32.98099899291992",
  },
  {
    ident: "LLKS",
    type: "small_airport",
    name: "Kiryat Shmona Airport",
    elevation_ft: "381",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-Z",
    municipality: "Kiryat Shmona",
    gps_code: "LLKS",
    iata_code: "KSW",
    coordinates: "35.599998474121094, 33.21670150756836",
  },
  {
    ident: "LLL",
    type: "small_airport",
    name: "Lissadell Airport",
    elevation_ft: "374",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Lissadell Station",
    iata_code: "LLL",
    coordinates: "128.594, -16.661",
  },
  {
    ident: "LLMZ",
    type: "medium_airport",
    name: "Bar Yehuda Airfield",
    elevation_ft: "-1266",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-D",
    municipality: "Masada",
    gps_code: "LLMZ",
    iata_code: "MTZ",
    coordinates: "35.38859939575195, 31.32819938659668",
  },
  {
    ident: "LLNV",
    type: "medium_airport",
    name: "Nevatim Air Base",
    elevation_ft: "1330",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-D",
    municipality: "Beersheba",
    gps_code: "LLNV",
    iata_code: "VTM",
    coordinates: "35.012298584, 31.208299636799996",
  },
  {
    ident: "LLOV",
    type: "large_airport",
    name: "Ovda International Airport",
    elevation_ft: "1492",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-D",
    municipality: "Eilat",
    gps_code: "LLOV",
    iata_code: "VDA",
    coordinates: "34.93579864501953, 29.94029998779297",
  },
  {
    ident: "LLRM",
    type: "medium_airport",
    name: "Ramon Air Base",
    elevation_ft: "2126",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-D",
    municipality: "Beersheba",
    gps_code: "LLRM",
    iata_code: "MIP",
    coordinates: "34.66669845581055, 30.776100158691406",
  },
  {
    ident: "LLSD",
    type: "closed",
    name: "Sde Dov Airport",
    elevation_ft: "43",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-TA",
    municipality: "Tel Aviv",
    gps_code: "LLSD",
    iata_code: "SDV",
    coordinates: "34.7822, 32.1147",
  },
  {
    ident: "LLYO",
    type: "small_airport",
    name: "Yotvata Airfield",
    elevation_ft: "249",
    continent: "AS",
    iso_country: "IL",
    iso_region: "IL-D",
    municipality: "Yotvata",
    gps_code: "LLYT",
    iata_code: "YOT",
    coordinates: "35.0675, 29.901111",
  },
  {
    ident: "LMC",
    type: "small_airport",
    name: "La Macarena Airport",
    elevation_ft: "790",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "La Macarena",
    iata_code: "LMC",
    coordinates: "-73.78674, 2.17565",
  },
  {
    ident: "LMMG",
    type: "heliport",
    name: "Xewkija Heliport",
    continent: "EU",
    iso_country: "MT",
    iso_region: "MT-62",
    municipality: "Gozo",
    gps_code: "LMMG",
    iata_code: "GZM",
    coordinates: "14.2728, 36.027199",
  },
  {
    ident: "LMML",
    type: "large_airport",
    name: "Malta International Airport",
    elevation_ft: "300",
    continent: "EU",
    iso_country: "MT",
    iso_region: "MT-25",
    municipality: "Valletta",
    gps_code: "LMML",
    iata_code: "MLA",
    coordinates: "14.4775, 35.857498",
  },
  {
    ident: "LMZ",
    type: "small_airport",
    name: "Palma Airport",
    elevation_ft: "177",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-P",
    municipality: "Palma",
    iata_code: "LMZ",
    coordinates: "40.4702, -10.7506",
  },
  {
    ident: "LNC",
    type: "small_airport",
    name: "Lengbati Airport",
    elevation_ft: "5750",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    gps_code: "AYLT",
    iata_code: "LNC",
    local_code: "LNG",
    coordinates: "147.368638889, -6.384611111110001",
  },
  {
    ident: "LNF",
    type: "small_airport",
    name: "Munbil Airport",
    elevation_ft: "3126",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    gps_code: "AYYM",
    iata_code: "LNF",
    local_code: "MBL",
    coordinates: "141.220165, -4.855941",
  },
  {
    ident: "LNM",
    type: "small_airport",
    name: "Langimar Airport",
    elevation_ft: "5127",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    gps_code: "AYLN",
    iata_code: "LNM",
    local_code: "LNM",
    coordinates: "146.227083333, -7.22313888889",
  },
  {
    ident: "LNMC",
    type: "heliport",
    name: "Monaco Heliport",
    elevation_ft: "20",
    continent: "EU",
    iso_country: "MC",
    iso_region: "MC-U-A",
    municipality: "Monaco",
    gps_code: "LNMC",
    iata_code: "MCM",
    coordinates: "7.41917, 43.725601",
  },
  {
    ident: "LNQ",
    type: "closed",
    name: "Loani Airport",
    elevation_ft: "150",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Loani",
    iata_code: "LNQ",
    coordinates: "150.5931, -10.5815",
  },
  {
    ident: "LOF",
    type: "closed",
    name: "Loen Airport",
    elevation_ft: "23",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-NMU",
    municipality: "Loen Island",
    iata_code: "LOF",
    coordinates: "168.2357, 7.7575",
  },
  {
    ident: "LOIH",
    type: "small_airport",
    name: "Hohenems-Dornbirn Airfield",
    elevation_ft: "1352",
    continent: "EU",
    iso_country: "AT",
    iso_region: "AT-8",
    municipality: "Hohenems / Dornbirn",
    gps_code: "LOIH",
    iata_code: "HOH",
    coordinates: "9.7, 47.384998",
  },
  {
    ident: "LOM",
    type: "small_airport",
    name: "Francisco Primo de Verdad y Ramos Airport",
    elevation_ft: "6227",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-JAL",
    municipality: "Lagos de Moreno",
    iata_code: "LOM",
    local_code: "LMO",
    coordinates: "-101.9441, 21.2581",
  },
  {
    ident: "LOR",
    type: "heliport",
    name: "Lowe AHP (Fort Rucker) Heliport",
    elevation_ft: "294",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AL",
    municipality: "Fort Rucker Ozark",
    gps_code: "KLOR",
    iata_code: "LOR",
    local_code: "LOR",
    coordinates: "-85.75119781, 31.35580063",
  },
  {
    ident: "LOWG",
    type: "medium_airport",
    name: "Graz Airport",
    elevation_ft: "1115",
    continent: "EU",
    iso_country: "AT",
    iso_region: "AT-6",
    municipality: "Graz",
    gps_code: "LOWG",
    iata_code: "GRZ",
    coordinates: "15.439599990844727, 46.9911003112793",
  },
  {
    ident: "LOWI",
    type: "medium_airport",
    name: "Innsbruck Airport",
    elevation_ft: "1907",
    continent: "EU",
    iso_country: "AT",
    iso_region: "AT-7",
    municipality: "Innsbruck",
    gps_code: "LOWI",
    iata_code: "INN",
    coordinates: "11.344, 47.260201",
  },
  {
    ident: "LOWK",
    type: "medium_airport",
    name: "Klagenfurt Airport",
    elevation_ft: "1472",
    continent: "EU",
    iso_country: "AT",
    iso_region: "AT-2",
    municipality: "Klagenfurt am W����rthersee",
    gps_code: "LOWK",
    iata_code: "KLU",
    coordinates: "14.3377, 46.642502",
  },
  {
    ident: "LOWL",
    type: "medium_airport",
    name: "Linz H����rsching Airport",
    elevation_ft: "980",
    continent: "EU",
    iso_country: "AT",
    iso_region: "AT-4",
    municipality: "Linz",
    gps_code: "LOWL",
    iata_code: "LNZ",
    coordinates: "14.1875, 48.2332",
  },
  {
    ident: "LOWS",
    type: "medium_airport",
    name: "Salzburg Airport",
    elevation_ft: "1411",
    continent: "EU",
    iso_country: "AT",
    iso_region: "AT-5",
    municipality: "Salzburg",
    gps_code: "LOWS",
    iata_code: "SZG",
    coordinates: "13.0043001175, 47.793300628699996",
  },
  {
    ident: "LOWW",
    type: "large_airport",
    name: "Vienna International Airport",
    elevation_ft: "600",
    continent: "EU",
    iso_country: "AT",
    iso_region: "AT-9",
    municipality: "Vienna",
    gps_code: "LOWW",
    iata_code: "VIE",
    coordinates: "16.569700241089, 48.110298156738",
  },
  {
    ident: "LPAR",
    type: "medium_airport",
    name: "Alverca Air Base",
    elevation_ft: "11",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-11",
    municipality: "Vila Franca de Xira",
    gps_code: "LPAR",
    iata_code: "AVR",
    coordinates: "-9.0301, 38.883301",
  },
  {
    ident: "LPAZ",
    type: "medium_airport",
    name: "Santa Maria Airport",
    elevation_ft: "308",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-20",
    municipality: "Vila do Porto",
    gps_code: "LPAZ",
    iata_code: "SMA",
    coordinates: "-25.17060089111328, 36.97140121459961",
  },
  {
    ident: "LPBG",
    type: "medium_airport",
    name: "Bragan����a Airport",
    elevation_ft: "2241",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-04",
    municipality: "Bragan����a",
    gps_code: "LPBG",
    iata_code: "BGC",
    coordinates: "-6.70713, 41.8578",
  },
  {
    ident: "LPBJ",
    type: "medium_airport",
    name: "Beja Airport / Airbase",
    elevation_ft: "636",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-02",
    municipality: "Beja",
    gps_code: "LPBJ",
    iata_code: "BYJ",
    coordinates: "-7.9324, 38.078899",
  },
  {
    ident: "LPBR",
    type: "medium_airport",
    name: "Braga Municipal Aerodrome",
    elevation_ft: "247",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-03",
    municipality: "Braga",
    gps_code: "LPBR",
    iata_code: "BGZ",
    coordinates: "-8.445139884949999, 41.5871009827",
  },
  {
    ident: "LPCH",
    type: "small_airport",
    name: "Aerodromo de Chaves",
    elevation_ft: "1181",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-17",
    municipality: "Chaves",
    gps_code: "LPCH",
    iata_code: "CHV",
    coordinates: "-7.463064, 41.722356",
  },
  {
    ident: "LPCR",
    type: "small_airport",
    name: "Corvo Airport",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-20",
    municipality: "Corvo",
    gps_code: "LPCR",
    iata_code: "CVU",
    coordinates: "-31.1136, 39.671501",
  },
  {
    ident: "LPCS",
    type: "medium_airport",
    name: "Cascais Airport",
    elevation_ft: "325",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-11",
    municipality: "Cascais",
    gps_code: "LPCS",
    iata_code: "CAT",
    coordinates: "-9.35523, 38.724998",
  },
  {
    ident: "LPFL",
    type: "medium_airport",
    name: "Flores Airport",
    elevation_ft: "112",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-20",
    municipality: "Santa Cruz das Flores",
    gps_code: "LPFL",
    iata_code: "FLW",
    coordinates: "-31.131399154663086, 39.455299377441406",
  },
  {
    ident: "LPFR",
    type: "large_airport",
    name: "Faro Airport",
    elevation_ft: "24",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-08",
    municipality: "Faro",
    gps_code: "LPFR",
    iata_code: "FAO",
    coordinates: "-7.96590995789, 37.0144004822",
  },
  {
    ident: "LPGR",
    type: "medium_airport",
    name: "Graciosa Airport",
    elevation_ft: "86",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-20",
    municipality: "Santa Cruz da Graciosa",
    gps_code: "LPGR",
    iata_code: "GRW",
    coordinates: "-28.029800415039062, 39.092201232910156",
  },
  {
    ident: "LPHR",
    type: "medium_airport",
    name: "Horta Airport",
    elevation_ft: "118",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-20",
    municipality: "Horta",
    gps_code: "LPHR",
    iata_code: "HOR",
    coordinates: "-28.715900421142578, 38.519901275634766",
  },
  {
    ident: "LPLA",
    type: "large_airport",
    name: "Lajes Airport",
    elevation_ft: "180",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-20",
    municipality: "Praia da Vit����ria",
    gps_code: "LPLA",
    iata_code: "TER",
    coordinates: "-27.090799, 38.761799",
  },
  {
    ident: "LPMA",
    type: "medium_airport",
    name: "Madeira Airport",
    elevation_ft: "192",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-30",
    municipality: "Funchal",
    gps_code: "LPMA",
    iata_code: "FNC",
    coordinates: "-16.7745, 32.697899",
  },
  {
    ident: "LPMR",
    type: "medium_airport",
    name: "Monte Real Air Base",
    elevation_ft: "187",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-10",
    gps_code: "LPMR",
    iata_code: "QLR",
    coordinates: "-8.8875, 39.828335",
  },
  {
    ident: "LPPD",
    type: "large_airport",
    name: "Jo����o Paulo II Airport",
    elevation_ft: "259",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-20",
    municipality: "Ponta Delgada",
    gps_code: "LPPD",
    iata_code: "PDL",
    coordinates: "-25.6979007721, 37.7411994934",
  },
  {
    ident: "LPPI",
    type: "medium_airport",
    name: "Pico Airport",
    elevation_ft: "109",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-20",
    municipality: "Pico Island",
    gps_code: "LPPI",
    iata_code: "PIX",
    coordinates: "-28.441299, 38.554298",
  },
  {
    ident: "LPPM",
    type: "medium_airport",
    name: "Portim����o Airport",
    elevation_ft: "5",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-08",
    municipality: "Portim����o",
    gps_code: "LPPM",
    iata_code: "PRM",
    coordinates: "-8.58396, 37.1493",
  },
  {
    ident: "LPPR",
    type: "large_airport",
    name: "Francisco de S���� Carneiro Airport",
    elevation_ft: "228",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-13",
    municipality: "Porto",
    gps_code: "LPPR",
    iata_code: "OPO",
    coordinates: "-8.68138980865, 41.2481002808",
  },
  {
    ident: "LPPS",
    type: "medium_airport",
    name: "Porto Santo Airport",
    elevation_ft: "341",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-30",
    municipality: "Vila Baleira",
    gps_code: "LPPS",
    iata_code: "PXO",
    coordinates: "-16.3500003815, 33.0733985901",
  },
  {
    ident: "LPPT",
    type: "large_airport",
    name: "Humberto Delgado Airport (Lisbon Portela Airport)",
    elevation_ft: "374",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-11",
    municipality: "Lisbon",
    gps_code: "LPPT",
    iata_code: "LIS",
    coordinates: "-9.13592, 38.7813",
  },
  {
    ident: "LPSI",
    type: "closed",
    name: "Sines Airport",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-15",
    gps_code: "LPSI",
    iata_code: "SIE",
    coordinates: "-8.817301, 37.941692",
  },
  {
    ident: "LPSJ",
    type: "medium_airport",
    name: "S����o Jorge Airport",
    elevation_ft: "311",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-20",
    municipality: "Velas",
    gps_code: "LPSJ",
    iata_code: "SJZ",
    coordinates: "-28.1758, 38.665501",
  },
  {
    ident: "LPVR",
    type: "medium_airport",
    name: "Vila Real Airport",
    elevation_ft: "1805",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-17",
    municipality: "Vila Real",
    gps_code: "LPVR",
    iata_code: "VRL",
    coordinates: "-7.72047, 41.2743",
  },
  {
    ident: "LPVZ",
    type: "medium_airport",
    name: "Aerodromo Goncalves Lobato (Viseu Airport)",
    elevation_ft: "2060",
    continent: "EU",
    iso_country: "PT",
    iso_region: "PT-18",
    municipality: "Viseu",
    gps_code: "LPVZ",
    iata_code: "VSE",
    coordinates: "-7.88899, 40.725498",
  },
  {
    ident: "LQBK",
    type: "medium_airport",
    name: "Banja Luka International Airport",
    elevation_ft: "400",
    continent: "EU",
    iso_country: "BA",
    iso_region: "BA-SRP",
    municipality: "Banja Luka",
    gps_code: "LQBK",
    iata_code: "BNX",
    coordinates: "17.297500610351562, 44.94139862060547",
  },
  {
    ident: "LQMO",
    type: "medium_airport",
    name: "Mostar International Airport",
    elevation_ft: "156",
    continent: "EU",
    iso_country: "BA",
    iso_region: "BA-BIH",
    municipality: "Mostar",
    gps_code: "LQMO",
    iata_code: "OMO",
    coordinates: "17.84589958190918, 43.282901763916016",
  },
  {
    ident: "LQSA",
    type: "large_airport",
    name: "Sarajevo International Airport",
    elevation_ft: "1708",
    continent: "EU",
    iso_country: "BA",
    iso_region: "BA-BIH",
    municipality: "Sarajevo",
    gps_code: "LQSA",
    iata_code: "SJJ",
    coordinates: "18.331499099731445, 43.82460021972656",
  },
  {
    ident: "LQTZ",
    type: "medium_airport",
    name: "Tuzla International Airport",
    elevation_ft: "784",
    continent: "EU",
    iso_country: "BA",
    iso_region: "BA-BIH",
    municipality: "Tuzla",
    gps_code: "LQTZ",
    iata_code: "TZL",
    coordinates: "18.72480010986328, 44.45869827270508",
  },
  {
    ident: "LRAR",
    type: "medium_airport",
    name: "Arad International Airport",
    elevation_ft: "352",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-AR",
    municipality: "Arad",
    gps_code: "LRAR",
    iata_code: "ARW",
    coordinates: "21.261999130249023, 46.17660140991211",
  },
  {
    ident: "LRBC",
    type: "medium_airport",
    name: "Bac����u Airport",
    elevation_ft: "607",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-BC",
    municipality: "Bac����u",
    gps_code: "LRBC",
    iata_code: "BCM",
    coordinates: "26.91029930114746, 46.52190017700195",
  },
  {
    ident: "LRBM",
    type: "medium_airport",
    name: "Maramure���� International Airport",
    elevation_ft: "605",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-MM",
    municipality: "Baia Mare",
    gps_code: "LRBM",
    iata_code: "BAY",
    coordinates: "23.467252, 47.660598",
  },
  {
    ident: "LRBS",
    type: "medium_airport",
    name: "B����neasa International Airport",
    elevation_ft: "297",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-B",
    municipality: "Bucharest",
    gps_code: "LRBS",
    iata_code: "BBU",
    coordinates: "26.102100372314453, 44.50320053100586",
  },
  {
    ident: "LRCK",
    type: "medium_airport",
    name: "Mihail Kog����lniceanu International Airport",
    elevation_ft: "353",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-CT",
    municipality: "Constan����a",
    gps_code: "LRCK",
    iata_code: "CND",
    coordinates: "28.488300323486328, 44.36220169067383",
  },
  {
    ident: "LRCL",
    type: "medium_airport",
    name: "Cluj-Napoca International Airport",
    elevation_ft: "1039",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-CJ",
    municipality: "Cluj-Napoca",
    gps_code: "LRCL",
    iata_code: "CLJ",
    coordinates: "23.686199, 46.785198",
  },
  {
    ident: "LRCS",
    type: "medium_airport",
    name: "Caransebe���� Airport",
    elevation_ft: "866",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-CS",
    municipality: "Caransebe����",
    gps_code: "LRCS",
    iata_code: "CSB",
    coordinates: "22.253299713134766, 45.41999816894531",
  },
  {
    ident: "LRCV",
    type: "medium_airport",
    name: "Craiova Airport",
    elevation_ft: "626",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-DJ",
    municipality: "Craiova",
    gps_code: "LRCV",
    iata_code: "CRA",
    coordinates: "23.888599395751953, 44.31809997558594",
  },
  {
    ident: "LRIA",
    type: "medium_airport",
    name: "Ia����i Airport",
    elevation_ft: "397",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-IS",
    municipality: "Ia����i",
    gps_code: "LRIA",
    iata_code: "IAS",
    coordinates: "27.6205997467041, 47.17850112915039",
  },
  {
    ident: "LRO",
    type: "closed",
    name: "Sharpe AAF",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Lathrop",
    iata_code: "LRO",
    coordinates: "-121.272805556, 37.837916666699996",
  },
  {
    ident: "LROD",
    type: "medium_airport",
    name: "Oradea International Airport",
    elevation_ft: "465",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-BH",
    municipality: "Oradea",
    gps_code: "LROD",
    iata_code: "OMR",
    coordinates: "21.90250015258789, 47.025299072265625",
  },
  {
    ident: "LROP",
    type: "large_airport",
    name: "Henri Coand���� International Airport",
    elevation_ft: "314",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-B",
    municipality: "Bucharest",
    gps_code: "LROP",
    iata_code: "OTP",
    coordinates: "26.085, 44.5711111",
  },
  {
    ident: "LRSB",
    type: "medium_airport",
    name: "Sibiu International Airport",
    elevation_ft: "1496",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-SB",
    municipality: "Sibiu",
    gps_code: "LRSB",
    iata_code: "SBZ",
    coordinates: "24.091299057006836, 45.78559875488281",
  },
  {
    ident: "LRSM",
    type: "medium_airport",
    name: "Satu Mare Airport",
    elevation_ft: "405",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-SM",
    municipality: "Satu Mare",
    gps_code: "LRSM",
    iata_code: "SUJ",
    coordinates: "22.885700225830078, 47.70330047607422",
  },
  {
    ident: "LRSV",
    type: "medium_airport",
    name: "Suceava Stefan cel Mare Airport",
    elevation_ft: "1375",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-SV",
    municipality: "Suceava",
    gps_code: "LRSV",
    iata_code: "SCV",
    coordinates: "26.35409927368164, 47.6875",
  },
  {
    ident: "LRTC",
    type: "medium_airport",
    name: "Tulcea Airport",
    elevation_ft: "200",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-TL",
    municipality: "Tulcea",
    gps_code: "LRTC",
    iata_code: "TCE",
    coordinates: "28.71430015563965, 45.0625",
  },
  {
    ident: "LRTM",
    type: "medium_airport",
    name: "Transilvania T����rgu Mure���� International Airport",
    elevation_ft: "963",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-MS",
    municipality: "T����rgu Mure����",
    gps_code: "LRTM",
    iata_code: "TGM",
    coordinates: "24.412500381469727, 46.46770095825195",
  },
  {
    ident: "LRTR",
    type: "medium_airport",
    name: "Timi����oara Traian Vuia Airport",
    elevation_ft: "348",
    continent: "EU",
    iso_country: "RO",
    iso_region: "RO-TM",
    municipality: "Timi����oara",
    gps_code: "LRTR",
    iata_code: "TSR",
    coordinates: "21.337900161743164, 45.809898376464844",
  },
  {
    ident: "LSGG",
    type: "large_airport",
    name: "Geneva Cointrin International Airport",
    elevation_ft: "1411",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-GE",
    municipality: "Geneva",
    gps_code: "LSGG",
    iata_code: "GVA",
    coordinates: "6.108950138092041, 46.23809814453125",
  },
  {
    ident: "LSGN",
    type: "small_airport",
    name: "Neuchatel Airport",
    elevation_ft: "1427",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-NE",
    gps_code: "LSGN",
    iata_code: "QNC",
    coordinates: "6.86471986771, 46.9575004578",
  },
  {
    ident: "LSGS",
    type: "medium_airport",
    name: "Sion Airport",
    elevation_ft: "1582",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-VS",
    municipality: "Sion",
    gps_code: "LSGS",
    iata_code: "SIR",
    coordinates: "7.326944, 46.219166",
  },
  {
    ident: "LSME",
    type: "medium_airport",
    name: "Emmen Air Base",
    elevation_ft: "1400",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-LU",
    gps_code: "LSME",
    iata_code: "EML",
    coordinates: "8.305184, 47.092444",
  },
  {
    ident: "LSMI",
    type: "closed",
    name: "Interlaken Air Base",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-BE",
    gps_code: "LSMI",
    iata_code: "ZIN",
    coordinates: "7.8790798, 46.6766014",
  },
  {
    ident: "LSZA",
    type: "medium_airport",
    name: "Lugano Airport",
    elevation_ft: "915",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-TI",
    municipality: "Lugano",
    gps_code: "LSZA",
    iata_code: "LUG",
    coordinates: "8.9105796814, 46.00429916379999",
  },
  {
    ident: "LSZB",
    type: "medium_airport",
    name: "Bern Belp Airport",
    elevation_ft: "1674",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-BE",
    municipality: "Bern",
    gps_code: "LSZB",
    iata_code: "BRN",
    coordinates: "7.497149944309999, 46.914100647",
  },
  {
    ident: "LSZC",
    type: "small_airport",
    name: "Buochs Airport",
    elevation_ft: "1475",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-NW",
    municipality: "Buochs",
    gps_code: "LSZC",
    iata_code: "BXO",
    coordinates: "8.396944, 46.974444",
  },
  {
    ident: "LSZD",
    type: "closed",
    name: "Ascona Airport",
    elevation_ft: "655",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-TI",
    municipality: "Ascona",
    gps_code: "LSZD",
    iata_code: "ACO",
    coordinates: "8.78194046021, 46.157798767100005",
  },
  {
    ident: "LSZG",
    type: "medium_airport",
    name: "Grenchen Airport",
    elevation_ft: "1411",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-SO",
    municipality: "Grenchen",
    gps_code: "LSZG",
    iata_code: "ZHI",
    coordinates: "7.41719, 47.181599",
  },
  {
    ident: "LSZH",
    type: "large_airport",
    name: "Z����rich Airport",
    elevation_ft: "1416",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-ZH",
    municipality: "Zurich",
    gps_code: "LSZH",
    iata_code: "ZRH",
    coordinates: "8.54917, 47.464699",
  },
  {
    ident: "LSZL",
    type: "small_airport",
    name: "Locarno Airport",
    elevation_ft: "650",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-TI",
    municipality: "Locarno",
    gps_code: "LSZL",
    iata_code: "ZJI",
    coordinates: "8.87860965729, 46.160800933800004",
  },
  {
    ident: "LSZR",
    type: "medium_airport",
    name: "St Gallen Altenrhein Airport",
    elevation_ft: "1306",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-SG",
    municipality: "Altenrhein",
    gps_code: "LSZR",
    iata_code: "ACH",
    coordinates: "9.560770034789998, 47.4850006104",
  },
  {
    ident: "LSZS",
    type: "medium_airport",
    name: "Samedan Airport",
    elevation_ft: "5600",
    continent: "EU",
    iso_country: "CH",
    iso_region: "CH-GR",
    gps_code: "LSZS",
    iata_code: "SMV",
    coordinates: "9.884110450744629, 46.53409957885742",
  },
  {
    ident: "LT86",
    type: "small_airport",
    name: "G����k����eada Airport",
    elevation_ft: "73",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-17",
    municipality: "G����k����eada",
    gps_code: "LTFK",
    iata_code: "GKD",
    local_code: "LTFK",
    coordinates: "25.883302, 40.204498",
  },
  {
    ident: "LTAC",
    type: "large_airport",
    name: "Esenbo����a International Airport",
    elevation_ft: "3125",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-06",
    municipality: "Ankara",
    gps_code: "LTAC",
    iata_code: "ESB",
    coordinates: "32.995098114, 40.128101348899996",
  },
  {
    ident: "LTAD",
    type: "medium_airport",
    name: "Etimesgut Air Base",
    elevation_ft: "2653",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-06",
    municipality: "Ankara",
    gps_code: "LTAD",
    iata_code: "ANK",
    coordinates: "32.6885986328, 39.949798584",
  },
  {
    ident: "LTAF",
    type: "large_airport",
    name: "Adana Airport",
    elevation_ft: "65",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-01",
    municipality: "Adana",
    gps_code: "LTAF",
    iata_code: "ADA",
    coordinates: "35.280399322499996, 36.9822006226",
  },
  {
    ident: "LTAG",
    type: "medium_airport",
    name: "����ncirlik Air Base",
    elevation_ft: "238",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-01",
    municipality: "Adana",
    gps_code: "LTAG",
    iata_code: "UAB",
    coordinates: "35.4258995056, 37.002101898199996",
  },
  {
    ident: "LTAH",
    type: "medium_airport",
    name: "Afyon Airport",
    elevation_ft: "3310",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-03",
    municipality: "Afyonkarahisar",
    gps_code: "LTAH",
    iata_code: "AFY",
    coordinates: "30.6011009216, 38.726398468",
  },
  {
    ident: "LTAI",
    type: "large_airport",
    name: "Antalya International Airport",
    elevation_ft: "177",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-07",
    municipality: "Antalya",
    gps_code: "LTAI",
    iata_code: "AYT",
    coordinates: "30.800501, 36.898701",
  },
  {
    ident: "LTAJ",
    type: "large_airport",
    name: "Gaziantep International Airport",
    elevation_ft: "2315",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-27",
    municipality: "Gaziantep",
    gps_code: "LTAJ",
    iata_code: "GZT",
    coordinates: "37.4786987305, 36.9472007751",
  },
  {
    ident: "LTAL",
    type: "small_airport",
    name: "Kastamonu Airport",
    elevation_ft: "3520",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-37",
    municipality: "Kastamonu",
    gps_code: "LTAL",
    iata_code: "KFS",
    coordinates: "33.795799255371094, 41.31420135498047",
  },
  {
    ident: "LTAN",
    type: "medium_airport",
    name: "Konya Airport",
    elevation_ft: "3392",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-42",
    municipality: "Konya",
    gps_code: "LTAN",
    iata_code: "KYA",
    coordinates: "32.561901, 37.979",
  },
  {
    ident: "LTAP",
    type: "small_airport",
    name: "Amasya Merzifon Airport",
    elevation_ft: "1758",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-05",
    municipality: "Amasya",
    gps_code: "LTAP",
    iata_code: "MZH",
    coordinates: "35.521999, 40.829399",
  },
  {
    ident: "LTAR",
    type: "small_airport",
    name: "Sivas Nuri Demira���� Airport",
    elevation_ft: "5239",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-58",
    municipality: "Sivas",
    gps_code: "LTAR",
    iata_code: "VAS",
    coordinates: "36.9035, 39.813801",
  },
  {
    ident: "LTAS",
    type: "small_airport",
    name: "Zonguldak Airport",
    elevation_ft: "39",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-67",
    municipality: "Zonguldak",
    gps_code: "LTAS",
    iata_code: "ONQ",
    coordinates: "32.0886001587, 41.506401062",
  },
  {
    ident: "LTAT",
    type: "medium_airport",
    name: "Malatya Erha���� Airport",
    elevation_ft: "2828",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-44",
    municipality: "Malatya",
    gps_code: "LTAT",
    iata_code: "MLX",
    coordinates: "38.0909996033, 38.435298919699996",
  },
  {
    ident: "LTAU",
    type: "medium_airport",
    name: "Kayseri Erkilet Airport",
    elevation_ft: "3463",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-38",
    municipality: "Kayseri",
    gps_code: "LTAU",
    iata_code: "ASR",
    coordinates: "35.4953994751, 38.770401001",
  },
  {
    ident: "LTAW",
    type: "medium_airport",
    name: "Tokat Airport",
    elevation_ft: "1831",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-60",
    municipality: "Tokat",
    gps_code: "LTAW",
    iata_code: "TJK",
    coordinates: "36.367408752441406, 40.307430267333984",
  },
  {
    ident: "LTAY",
    type: "medium_airport",
    name: "����ardak Airport",
    elevation_ft: "2795",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-20",
    municipality: "Denizli",
    gps_code: "LTAY",
    iata_code: "DNZ",
    coordinates: "29.7012996674, 37.7855987549",
  },
  {
    ident: "LTAZ",
    type: "small_airport",
    name: "Nev����ehir Kapadokya Airport",
    elevation_ft: "3100",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-50",
    municipality: "Nev����ehir",
    gps_code: "LTAZ",
    iata_code: "NAV",
    coordinates: "34.5345, 38.7719",
  },
  {
    ident: "LTB",
    type: "closed",
    name: "Latrobe Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    municipality: "Latrobe",
    iata_code: "LTB",
    coordinates: "146.396169662, -41.235188030699994",
  },
  {
    ident: "LTBA",
    type: "large_airport",
    name: "Atat����rk International Airport",
    elevation_ft: "163",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-34",
    municipality: "Istanbul",
    gps_code: "LTBA",
    iata_code: "ISL",
    coordinates: "28.8146, 40.976898",
  },
  {
    ident: "LTBD",
    type: "small_airport",
    name: "��������ld����r Airport",
    elevation_ft: "102",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-09",
    municipality: "Ayd����n",
    gps_code: "LTBD",
    iata_code: "CII",
    coordinates: "27.895299911499997, 37.8149986267",
  },
  {
    ident: "LTBE",
    type: "small_airport",
    name: "Bursa Airport",
    elevation_ft: "331",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-16",
    municipality: "Bursa",
    gps_code: "LTBE",
    iata_code: "BTZ",
    coordinates: "29.009199142456055, 40.233299255371094",
  },
  {
    ident: "LTBF",
    type: "medium_airport",
    name: "Bal����kesir Merkez Airport",
    elevation_ft: "340",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-10",
    gps_code: "LTBF",
    iata_code: "BZI",
    coordinates: "27.926000595092773, 39.619300842285156",
  },
  {
    ident: "LTBG",
    type: "medium_airport",
    name: "Band����rma Airport",
    elevation_ft: "170",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-10",
    gps_code: "LTBG",
    iata_code: "BDM",
    coordinates: "27.977699279785156, 40.31800079345703",
  },
  {
    ident: "LTBH",
    type: "medium_airport",
    name: "����anakkale Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-17",
    municipality: "����anakkale",
    gps_code: "LTBH",
    iata_code: "CKZ",
    coordinates: "26.4267997742, 40.1376991272",
  },
  {
    ident: "LTBI",
    type: "medium_airport",
    name: "Eski����ehir Air Base",
    elevation_ft: "2581",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-26",
    gps_code: "LTBI",
    iata_code: "ESK",
    coordinates: "30.582099914599997, 39.7840995789",
  },
  {
    ident: "LTBJ",
    type: "large_airport",
    name: "Adnan Menderes International Airport",
    elevation_ft: "412",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-35",
    municipality: "����zmir",
    gps_code: "LTBJ",
    iata_code: "ADB",
    coordinates: "27.156999588, 38.2924003601",
  },
  {
    ident: "LTBL",
    type: "medium_airport",
    name: "����i����li Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-35",
    gps_code: "LTBL",
    iata_code: "IGL",
    coordinates: "27.010099411, 38.513000488299994",
  },
  {
    ident: "LTBO",
    type: "small_airport",
    name: "U����ak Airport",
    elevation_ft: "2897",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-64",
    municipality: "U����ak",
    gps_code: "LTBO",
    iata_code: "USQ",
    coordinates: "29.47170066833496, 38.68149948120117",
  },
  {
    ident: "LTBQ",
    type: "medium_airport",
    name: "Cengiz Topel Airport",
    elevation_ft: "182",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-41",
    gps_code: "LTBQ",
    iata_code: "KCO",
    coordinates: "30.08329963684082, 40.73500061035156",
  },
  {
    ident: "LTBR",
    type: "medium_airport",
    name: "Bursa Yeni����ehir Airport",
    elevation_ft: "764",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-16",
    municipality: "Bursa",
    gps_code: "LTBR",
    iata_code: "YEI",
    coordinates: "29.5625991821, 40.2551994324",
  },
  {
    ident: "LTBS",
    type: "large_airport",
    name: "Dalaman International Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-48",
    municipality: "Dalaman",
    gps_code: "LTBS",
    iata_code: "DLM",
    coordinates: "28.7924995422, 36.7131004333",
  },
  {
    ident: "LTBU",
    type: "medium_airport",
    name: "Tekirda���� ����orlu Airport",
    elevation_ft: "574",
    continent: "EU",
    iso_country: "TR",
    iso_region: "TR-59",
    municipality: "����orlu",
    gps_code: "LTBU",
    iata_code: "TEQ",
    coordinates: "27.919099807739258, 41.13819885253906",
  },
  {
    ident: "LTBV",
    type: "small_airport",
    name: "Ims����k Airport",
    elevation_ft: "202",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-48",
    gps_code: "LTBV",
    iata_code: "BXN",
    coordinates: "27.669700622558594, 37.140098571777344",
  },
  {
    ident: "LTBY",
    type: "medium_airport",
    name: "Anadolu Airport",
    elevation_ft: "2588",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-26",
    municipality: "Eski����ehir",
    gps_code: "LTBY",
    iata_code: "AOE",
    coordinates: "30.5194, 39.809898",
  },
  {
    ident: "LTBZ",
    type: "small_airport",
    name: "Zafer Airport",
    elevation_ft: "3327",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-43",
    municipality: "Alt����nta����",
    gps_code: "LTBZ",
    iata_code: "KZR",
    coordinates: "30.128111, 39.113079",
  },
  {
    ident: "LTCA",
    type: "medium_airport",
    name: "Elaz�������� Airport",
    elevation_ft: "2927",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-23",
    municipality: "Elaz��������",
    gps_code: "LTCA",
    iata_code: "EZS",
    coordinates: "39.2914009094, 38.6068992615",
  },
  {
    ident: "LTCC",
    type: "medium_airport",
    name: "Diyarbakir Airport",
    elevation_ft: "2251",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-21",
    municipality: "Diyarbakir",
    gps_code: "LTCC",
    iata_code: "DIY",
    coordinates: "40.201000213600004, 37.893901825",
  },
  {
    ident: "LTCD",
    type: "medium_airport",
    name: "Erzincan Airport",
    elevation_ft: "3783",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-24",
    municipality: "Erzincan",
    gps_code: "LTCD",
    iata_code: "ERC",
    coordinates: "39.527000427199994, 39.7102012634",
  },
  {
    ident: "LTCE",
    type: "large_airport",
    name: "Erzurum International Airport",
    elevation_ft: "5763",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-25",
    municipality: "Erzurum",
    gps_code: "LTCE",
    iata_code: "ERZ",
    coordinates: "41.17020034789999, 39.9565010071",
  },
  {
    ident: "LTCF",
    type: "medium_airport",
    name: "Kars Airport",
    elevation_ft: "5889",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-36",
    municipality: "Kars",
    gps_code: "LTCF",
    iata_code: "KSY",
    coordinates: "43.1150016784668, 40.562198638916016",
  },
  {
    ident: "LTCG",
    type: "large_airport",
    name: "Trabzon International Airport",
    elevation_ft: "104",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-61",
    municipality: "Trabzon",
    gps_code: "LTCG",
    iata_code: "TZX",
    coordinates: "39.78969955444336, 40.99509811401367",
  },
  {
    ident: "LTCH",
    type: "medium_airport",
    name: "����anl����urfa Airport",
    elevation_ft: "1483",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-63",
    municipality: "����anl����urfa",
    gps_code: "LTCH",
    iata_code: "SFQ",
    coordinates: "38.84709930419922, 37.09429931640625",
  },
  {
    ident: "LTCI",
    type: "medium_airport",
    name: "Van Ferit Melen Airport",
    elevation_ft: "5480",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-65",
    municipality: "Van",
    gps_code: "LTCI",
    iata_code: "VAN",
    coordinates: "43.332298278808594, 38.46820068359375",
  },
  {
    ident: "LTCJ",
    type: "medium_airport",
    name: "Batman Airport",
    elevation_ft: "1822",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-72",
    municipality: "Batman",
    gps_code: "LTCJ",
    iata_code: "BAL",
    coordinates: "41.1166000366, 37.929000854499996",
  },
  {
    ident: "LTCK",
    type: "medium_airport",
    name: "Mu���� Airport",
    elevation_ft: "4157",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-49",
    municipality: "Mu����",
    gps_code: "LTCK",
    iata_code: "MSR",
    coordinates: "41.66120147705078, 38.747798919677734",
  },
  {
    ident: "LTCL",
    type: "medium_airport",
    name: "Siirt Airport",
    elevation_ft: "2001",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-56",
    municipality: "Siirt",
    gps_code: "LTCL",
    iata_code: "SXZ",
    coordinates: "41.84040069580078, 37.97890090942383",
  },
  {
    ident: "LTCM",
    type: "small_airport",
    name: "Sinop Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-57",
    municipality: "Sinop",
    gps_code: "LTCM",
    iata_code: "NOP",
    coordinates: "35.066398620605, 42.015800476074",
  },
  {
    ident: "LTCN",
    type: "medium_airport",
    name: "Kahramanmara���� Airport",
    elevation_ft: "1723",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-46",
    municipality: "Kahramanmara����",
    gps_code: "LTCN",
    iata_code: "KCM",
    coordinates: "36.9535217285, 37.5388259888",
  },
  {
    ident: "LTCO",
    type: "medium_airport",
    name: "A����r���� Airport",
    elevation_ft: "5462",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-04",
    gps_code: "LTCO",
    iata_code: "AJI",
    coordinates: "43.025978088378906, 39.654541015625",
  },
  {
    ident: "LTCP",
    type: "medium_airport",
    name: "Ad����yaman Airport",
    elevation_ft: "2216",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-02",
    municipality: "Ad����yaman",
    gps_code: "LTCP",
    iata_code: "ADF",
    coordinates: "38.4688987732, 37.7313995361",
  },
  {
    ident: "LTCR",
    type: "medium_airport",
    name: "Mardin Airport",
    elevation_ft: "1729",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-47",
    municipality: "Mardin",
    gps_code: "LTCR",
    iata_code: "MQM",
    coordinates: "40.6316986084, 37.223300933800004",
  },
  {
    ident: "LTCS",
    type: "medium_airport",
    name: "����anl����urfa GAP Airport",
    elevation_ft: "2708",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-63",
    municipality: "����anl����urfa",
    gps_code: "LTCS",
    iata_code: "GNY",
    coordinates: "38.895592, 37.445663",
  },
  {
    ident: "LTCV",
    type: "medium_airport",
    name: "��������rnak ����erafettin El����i Airport",
    elevation_ft: "2038",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-73",
    municipality: "��������rnak",
    gps_code: "LTCV",
    iata_code: "NKT",
    coordinates: "42.0582, 37.3647",
  },
  {
    ident: "LTCW",
    type: "medium_airport",
    name: "Hakkari Y����ksekova Airport",
    elevation_ft: "6400",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-30",
    municipality: "Hakkari",
    gps_code: "LTCW",
    iata_code: "YKO",
    coordinates: "44.2381, 37.5497",
  },
  {
    ident: "LTDA",
    type: "medium_airport",
    name: "Hatay Airport",
    elevation_ft: "269",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-31",
    municipality: "Hatay",
    gps_code: "LTDA",
    iata_code: "HTY",
    coordinates: "36.282222747800006, 36.36277771",
  },
  {
    ident: "LTF",
    type: "small_airport",
    name: "Leitre Airport",
    elevation_ft: "32",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Leitre",
    iata_code: "LTF",
    local_code: "LRE",
    coordinates: "141.6257, -2.8337",
  },
  {
    ident: "LTFC",
    type: "large_airport",
    name: "S����leyman Demirel International Airport",
    elevation_ft: "2835",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-32",
    municipality: "Isparta",
    gps_code: "LTFC",
    iata_code: "ISE",
    coordinates: "30.368400573699997, 37.8554000854",
  },
  {
    ident: "LTFD",
    type: "medium_airport",
    name: "Bal����kesir K����rfez Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-10",
    municipality: "Edremit",
    gps_code: "LTFD",
    iata_code: "EDO",
    coordinates: "27.0137996674, 39.554599762",
  },
  {
    ident: "LTFE",
    type: "large_airport",
    name: "Milas Bodrum International Airport",
    elevation_ft: "21",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-48",
    municipality: "Bodrum",
    gps_code: "LTFE",
    iata_code: "BJV",
    coordinates: "27.6643009186, 37.25059890749999",
  },
  {
    ident: "LTFH",
    type: "small_airport",
    name: "Samsun ����ar����amba Airport",
    elevation_ft: "18",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-55",
    municipality: "Samsun",
    gps_code: "LTFH",
    iata_code: "SZF",
    coordinates: "36.567101, 41.254501",
  },
  {
    ident: "LTFJ",
    type: "large_airport",
    name: "Sabiha G����k����en International Airport",
    elevation_ft: "312",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-34",
    municipality: "Istanbul",
    gps_code: "LTFJ",
    iata_code: "SAW",
    coordinates: "29.3092002869, 40.898601532",
  },
  {
    ident: "LTFM",
    type: "large_airport",
    name: "Istanbul Airport",
    elevation_ft: "325",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-34",
    municipality: "Istanbul",
    gps_code: "LTFM",
    iata_code: "IST",
    coordinates: "28.751944, 41.275278",
  },
  {
    ident: "LTGP",
    type: "medium_airport",
    name: "Gazipa����a Airport",
    elevation_ft: "86",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-07",
    municipality: "Gazipa����a",
    gps_code: "LTFG",
    iata_code: "GZP",
    coordinates: "32.300598, 36.299217",
  },
  {
    ident: "LTH",
    type: "closed",
    name: "Lathrop Wells Airport / Jackass Aeropark",
    elevation_ft: "2650",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Amargosa Valley",
    iata_code: "LTH",
    local_code: "U75",
    coordinates: "-116.4135, 36.6342",
  },
  {
    ident: "LUBL",
    type: "medium_airport",
    name: "B����l����i International Airport",
    elevation_ft: "758",
    continent: "EU",
    iso_country: "MD",
    iso_region: "MD-RS",
    municipality: "B����l����i",
    gps_code: "LUBL",
    iata_code: "BZY",
    coordinates: "27.777222, 47.843056",
  },
  {
    ident: "LUKK",
    type: "medium_airport",
    name: "Chi����in����u International Airport",
    elevation_ft: "399",
    continent: "EU",
    iso_country: "MD",
    iso_region: "MD-C",
    municipality: "Chi����in����u",
    gps_code: "LUKK",
    iata_code: "KIV",
    coordinates: "28.930999755859375, 46.92770004272461",
  },
  {
    ident: "LUZ",
    type: "medium_airport",
    name: "Lublin Airport",
    elevation_ft: "633",
    continent: "EU",
    iso_country: "PL",
    iso_region: "PL-LU",
    municipality: "Lublin",
    gps_code: "EPLB",
    iata_code: "LUZ",
    coordinates: "22.713611, 51.240278",
  },
  {
    ident: "LVB",
    type: "closed",
    name: "Santana do Livramento Airport",
    elevation_ft: "1094",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Santana do Livramento",
    iata_code: "LVB",
    coordinates: "-55.62565, -30.8354",
  },
  {
    ident: "LVGZ",
    type: "closed",
    name: "Yasser Arafat International Airport",
    elevation_ft: "320",
    continent: "AS",
    iso_country: "PS",
    iso_region: "PS-RFH",
    municipality: "Rafah",
    gps_code: "LVGZ",
    iata_code: "GZA",
    coordinates: "34.2761, 31.246401",
  },
  {
    ident: "LWA",
    type: "small_airport",
    name: "Lebak Rural Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-SUK",
    municipality: "Lebak",
    iata_code: "LWA",
    coordinates: "124.0581, 6.6739",
  },
  {
    ident: "LWOH",
    type: "medium_airport",
    name: "Ohrid St. Paul the Apostle Airport",
    elevation_ft: "2313",
    continent: "EU",
    iso_country: "MK",
    iso_region: "MK-003",
    municipality: "Ohrid",
    gps_code: "LWOH",
    iata_code: "OHD",
    coordinates: "20.7423, 41.18",
  },
  {
    ident: "LWSK",
    type: "large_airport",
    name: "Skopje International Airport",
    elevation_ft: "781",
    continent: "EU",
    iso_country: "MK",
    iso_region: "MK-004",
    municipality: "Skopje",
    gps_code: "LWSK",
    iata_code: "SKP",
    coordinates: "21.621401, 41.961601",
  },
  {
    ident: "LXGB",
    type: "medium_airport",
    name: "Gibraltar Airport",
    elevation_ft: "15",
    continent: "EU",
    iso_country: "GI",
    iso_region: "GI-U-A",
    municipality: "Gibraltar",
    gps_code: "LXGB",
    iata_code: "GIB",
    coordinates: "-5.3496599197400005, 36.1511993408",
  },
  {
    ident: "LY-BCQ",
    type: "medium_airport",
    name: "Brak Airport",
    elevation_ft: "1056",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-SH",
    municipality: "Brak",
    iata_code: "BCQ",
    coordinates: "14.272, 27.653",
  },
  {
    ident: "LY-DNF",
    type: "small_airport",
    name: "Martubah Airport",
    elevation_ft: "1235",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-DR",
    municipality: "Derna",
    iata_code: "DNF",
    coordinates: "22.7450008392334, 32.54199981689453",
  },
  {
    ident: "LY-MRA",
    type: "small_airport",
    name: "Misratah Airport",
    elevation_ft: "60",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-MI",
    municipality: "Misratah",
    gps_code: "HLMS",
    iata_code: "MRA",
    coordinates: "15.0609998703, 32.325000762939",
  },
  {
    ident: "LY-QUB",
    type: "small_airport",
    name: "Ubari Airport",
    elevation_ft: "1528",
    continent: "AF",
    iso_country: "LY",
    iso_region: "LY-WD",
    municipality: "Ubari",
    gps_code: "HLUB",
    iata_code: "QUB",
    coordinates: "12.8231, 26.567499",
  },
  {
    ident: "LYBE",
    type: "large_airport",
    name: "Belgrade Nikola Tesla Airport",
    elevation_ft: "335",
    continent: "EU",
    iso_country: "RS",
    iso_region: "RS-00",
    municipality: "Belgrade",
    gps_code: "LYBE",
    iata_code: "BEG",
    coordinates: "20.3090991974, 44.8184013367",
  },
  {
    ident: "LYBR",
    type: "small_airport",
    name: "Berane Airport",
    elevation_ft: "2287",
    continent: "EU",
    iso_country: "ME",
    iso_region: "ME-03",
    municipality: "Berane",
    gps_code: "LYBR",
    iata_code: "IVG",
    coordinates: "19.86199951171875, 42.8390007019043",
  },
  {
    ident: "LYBT",
    type: "medium_airport",
    name: "Batajnica Air Base",
    elevation_ft: "265",
    continent: "EU",
    iso_country: "RS",
    iso_region: "RS-07",
    municipality: "Batajnica",
    gps_code: "LYBT",
    iata_code: "BJY",
    coordinates: "20.2575, 44.935299",
  },
  {
    ident: "LYKV",
    type: "small_airport",
    name: "Morava Airport",
    elevation_ft: "686",
    continent: "EU",
    iso_country: "RS",
    iso_region: "RS-18",
    municipality: "Kraljevo",
    gps_code: "LYKV",
    iata_code: "KVO",
    coordinates: "20.5872, 43.818298",
  },
  {
    ident: "LYNI",
    type: "medium_airport",
    name: "Nis Airport",
    elevation_ft: "648",
    continent: "EU",
    iso_country: "RS",
    iso_region: "RS-20",
    municipality: "Nis",
    gps_code: "LYNI",
    iata_code: "INI",
    coordinates: "21.853701, 43.337299",
  },
  {
    ident: "LYNS",
    type: "small_airport",
    name: "Cenej Airport",
    elevation_ft: "266",
    continent: "EU",
    iso_country: "RS",
    iso_region: "RS-06",
    municipality: "Novi Sad",
    gps_code: "LYNS",
    iata_code: "QND",
    coordinates: "19.839199, 45.385799",
  },
  {
    ident: "LYPG",
    type: "large_airport",
    name: "Podgorica Airport",
    elevation_ft: "141",
    continent: "EU",
    iso_country: "ME",
    iso_region: "ME-16",
    municipality: "Podgorica",
    gps_code: "LYPG",
    iata_code: "TGD",
    coordinates: "19.2519, 42.359402",
  },
  {
    ident: "LYTV",
    type: "medium_airport",
    name: "Tivat Airport",
    elevation_ft: "20",
    continent: "EU",
    iso_country: "ME",
    iso_region: "ME-19",
    municipality: "Tivat",
    gps_code: "LYTV",
    iata_code: "TIV",
    coordinates: "18.72330093383789, 42.404701232910156",
  },
  {
    ident: "LZIB",
    type: "large_airport",
    name: "M. R. ����tef����nik Airport",
    elevation_ft: "436",
    continent: "EU",
    iso_country: "SK",
    iso_region: "SK-BL",
    municipality: "Bratislava",
    gps_code: "LZIB",
    iata_code: "BTS",
    coordinates: "17.21269989013672, 48.17020034790039",
  },
  {
    ident: "LZKZ",
    type: "medium_airport",
    name: "Ko����ice Airport",
    elevation_ft: "755",
    continent: "EU",
    iso_country: "SK",
    iso_region: "SK-KI",
    municipality: "Ko����ice",
    gps_code: "LZKZ",
    iata_code: "KSC",
    coordinates: "21.241100311279297, 48.66310119628906",
  },
  {
    ident: "LZLU",
    type: "small_airport",
    name: "Lu����enec Airport",
    continent: "EU",
    iso_country: "SK",
    iso_region: "SK-BC",
    municipality: "Lu����enec",
    gps_code: "LZLU",
    iata_code: "LUE",
    coordinates: "19.73579978942871, 48.33940124511719",
  },
  {
    ident: "LZPP",
    type: "medium_airport",
    name: "Pie��������any Airport",
    elevation_ft: "545",
    continent: "EU",
    iso_country: "SK",
    iso_region: "SK-TA",
    municipality: "Pie��������any",
    gps_code: "LZPP",
    iata_code: "PZY",
    coordinates: "17.828399658203125, 48.62519836425781",
  },
  {
    ident: "LZPW",
    type: "small_airport",
    name: "Pre����ov Air Base",
    elevation_ft: "1060",
    continent: "EU",
    iso_country: "SK",
    iso_region: "SK-PV",
    municipality: "Pre����ov",
    gps_code: "LZPW",
    iata_code: "POV",
    coordinates: "21.315599441528, 49.02970123291",
  },
  {
    ident: "LZSL",
    type: "medium_airport",
    name: "Slia���� Airport",
    elevation_ft: "1043",
    continent: "EU",
    iso_country: "SK",
    iso_region: "SK-BC",
    municipality: "Slia����",
    gps_code: "LZSL",
    iata_code: "SLD",
    coordinates: "19.13409996032715, 48.63779830932617",
  },
  {
    ident: "LZTT",
    type: "medium_airport",
    name: "Poprad-Tatry Airport",
    elevation_ft: "2356",
    continent: "EU",
    iso_country: "SK",
    iso_region: "SK-KI",
    municipality: "Poprad",
    gps_code: "LZTT",
    iata_code: "TAT",
    coordinates: "20.2411003113, 49.073600769",
  },
  {
    ident: "LZZI",
    type: "medium_airport",
    name: "����ilina Airport",
    elevation_ft: "1020",
    continent: "EU",
    iso_country: "SK",
    iso_region: "SK-ZI",
    municipality: "����ilina",
    gps_code: "LZZI",
    iata_code: "ILZ",
    coordinates: "18.6135005951, 49.231498718299996",
  },
  {
    ident: "M26",
    type: "small_airport",
    name: "Drummond Airport",
    elevation_ft: "4245",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MT",
    municipality: "Drummond",
    gps_code: "KDRU",
    iata_code: "DRU",
    local_code: "M26",
    coordinates: "-113.205002, 46.6208",
  },
  {
    ident: "MA-GLN",
    type: "small_airport",
    name: "Goulimime Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-TIZ",
    municipality: "Goulimime",
    gps_code: "GMAG",
    iata_code: "GLN",
    coordinates: "-10.0502996445, 29.0266990662",
  },
  {
    ident: "MA53",
    type: "small_airport",
    name: "Ware Airport",
    elevation_ft: "483",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Ware",
    gps_code: "MA53",
    iata_code: "UWA",
    local_code: "MA53",
    coordinates: "-72.214797973633, 42.282001495361",
  },
  {
    ident: "MAP",
    type: "small_airport",
    name: "Mamai Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Mamai",
    iata_code: "MAP",
    coordinates: "149.519166667, -10.290833333299998",
  },
  {
    ident: "MBGT",
    type: "medium_airport",
    name: "JAGS McCartney International Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "TC",
    iso_region: "TC-GT",
    municipality: "Cockburn Town",
    gps_code: "MBGT",
    iata_code: "GDT",
    coordinates: "-71.14230346679688, 21.444499969482422",
  },
  {
    ident: "MBMC",
    type: "small_airport",
    name: "Middle Caicos Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "TC",
    iso_region: "TC-MC",
    municipality: "Middle Caicos",
    gps_code: "MBMC",
    iata_code: "MDS",
    coordinates: "-71.8025, 21.82602",
  },
  {
    ident: "MBNC",
    type: "medium_airport",
    name: "North Caicos Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "TC",
    iso_region: "TC-NC",
    gps_code: "MBNC",
    iata_code: "NCA",
    coordinates: "-71.939598, 21.9175",
  },
  {
    ident: "MBPI",
    type: "small_airport",
    name: "Pine Cay Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "TC",
    iso_region: "TC-PN",
    municipality: "Pine Cay",
    gps_code: "MBPI",
    iata_code: "PIC",
    coordinates: "-72.092344, 21.874948",
  },
  {
    ident: "MBPV",
    type: "medium_airport",
    name: "Providenciales Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "TC",
    iso_region: "TC-PR",
    municipality: "Providenciales Island",
    gps_code: "MBPV",
    iata_code: "PLS",
    coordinates: "-72.26589965820312, 21.77359962463379",
  },
  {
    ident: "MBSC",
    type: "medium_airport",
    name: "South Caicos Airport",
    elevation_ft: "6",
    continent: "NA",
    iso_country: "TC",
    iso_region: "TC-SC",
    gps_code: "MBSC",
    iata_code: "XSC",
    coordinates: "-71.528503418, 21.515699386599998",
  },
  {
    ident: "MBSY",
    type: "small_airport",
    name: "Salt Cay Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "TC",
    iso_region: "TC-SL",
    municipality: "Salt Cay",
    gps_code: "MBSY",
    iata_code: "SLX",
    coordinates: "-71.1999969482, 21.333000183099998",
  },
  {
    ident: "MDBH",
    type: "medium_airport",
    name: "Maria Montez International Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-04",
    municipality: "Barahona",
    gps_code: "MDBH",
    iata_code: "BRX",
    coordinates: "-71.12039947509766, 18.25149917602539",
  },
  {
    ident: "MDCR",
    type: "medium_airport",
    name: "Cabo Rojo Airport",
    elevation_ft: "262",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-16",
    municipality: "Cabo Rojo",
    gps_code: "MDCR",
    iata_code: "CBJ",
    coordinates: "-71.6447982788086, 17.929000854492188",
  },
  {
    ident: "MDCY",
    type: "medium_airport",
    name: "Saman���� El Catey International Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-20",
    municipality: "Samana",
    gps_code: "MDCY",
    iata_code: "AZS",
    coordinates: "-69.7419967651, 19.2670001984",
  },
  {
    ident: "MDCZ",
    type: "small_airport",
    name: "Constanza - Expedici����n 14 de Junio National Airport",
    elevation_ft: "3950",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-13",
    municipality: "Costanza",
    gps_code: "MDCZ",
    iata_code: "COZ",
    coordinates: "-70.721900939941, 18.907499313354",
  },
  {
    ident: "MDHE",
    type: "closed",
    name: "Herrera Airport",
    elevation_ft: "190",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-01",
    municipality: "Santo Domingo",
    gps_code: "MDHE",
    iata_code: "HEX",
    coordinates: "-69.9693984985, 18.4696998596",
  },
  {
    ident: "MDJB",
    type: "medium_airport",
    name: "La Isabela International Airport",
    elevation_ft: "98",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-01",
    municipality: "La Isabela",
    gps_code: "MDJB",
    iata_code: "JBQ",
    coordinates: "-69.98560333251953, 18.572500228881836",
  },
  {
    ident: "MDLR",
    type: "medium_airport",
    name: "Casa De Campo International Airport",
    elevation_ft: "240",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-12",
    municipality: "La Romana",
    gps_code: "MDLR",
    iata_code: "LRM",
    coordinates: "-68.91179656982422, 18.450700759887695",
  },
  {
    ident: "MDPC",
    type: "large_airport",
    name: "Punta Cana International Airport",
    elevation_ft: "47",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-11",
    municipality: "Punta Cana",
    gps_code: "MDPC",
    iata_code: "PUJ",
    coordinates: "-68.36340332030001, 18.567399978599997",
  },
  {
    ident: "MDPO",
    type: "small_airport",
    name: "Samana El Portillo Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-20",
    municipality: "Samana",
    gps_code: "MDPO",
    iata_code: "EPS",
    coordinates: "-69.4959121943, 19.3214150435",
  },
  {
    ident: "MDPP",
    type: "medium_airport",
    name: "Gregorio Luperon International Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-18",
    municipality: "Puerto Plata",
    gps_code: "MDPP",
    iata_code: "POP",
    coordinates: "-70.56999969482422, 19.75790023803711",
  },
  {
    ident: "MDR",
    type: "small_airport",
    name: "Medfra Airport",
    elevation_ft: "435",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Medfra",
    iata_code: "MDR",
    coordinates: "-154.719429016, 63.1060198183",
  },
  {
    ident: "MDSD",
    type: "large_airport",
    name: "Las Am����ricas International Airport",
    elevation_ft: "59",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-01",
    municipality: "Santo Domingo",
    gps_code: "MDSD",
    iata_code: "SDQ",
    coordinates: "-69.668899536133, 18.42970085144",
  },
  {
    ident: "MDSJ",
    type: "closed",
    name: "San Juan Airport",
    elevation_ft: "1496",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-22",
    municipality: "San Juan",
    gps_code: "MDSJ",
    iata_code: "SJM",
    coordinates: "-71.2333297729, 18.833332061799997",
  },
  {
    ident: "MDST",
    type: "medium_airport",
    name: "Cibao International Airport",
    elevation_ft: "565",
    continent: "NA",
    iso_country: "DO",
    iso_region: "DO-25",
    municipality: "Santiago",
    gps_code: "MDST",
    iata_code: "STI",
    coordinates: "-70.60469818115234, 19.406099319458008",
  },
  {
    ident: "MDV",
    type: "small_airport",
    name: "M����douneu Airport",
    elevation_ft: "2100",
    continent: "AF",
    iso_country: "GQ",
    iso_region: "GQ-CS",
    municipality: "M����douneu, Gabon",
    iata_code: "MDV",
    coordinates: "10.7552, 1.0085",
  },
  {
    ident: "ME16",
    type: "small_airport",
    name: "Loring International Airport",
    elevation_ft: "746",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ME",
    municipality: "Limestone",
    gps_code: "ME16",
    iata_code: "LIZ",
    local_code: "ME16",
    coordinates: "-67.885902, 46.950401",
  },
  {
    ident: "MEF",
    type: "small_airport",
    name: "Melfi Airport",
    elevation_ft: "1293",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-GR",
    municipality: "Melfi",
    iata_code: "MEF",
    coordinates: "17.944495, 11.060192",
  },
  {
    ident: "MG-0001",
    type: "small_airport",
    name: "Ambohibary Airport",
    elevation_ft: "2960",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-A",
    municipality: "Moramanga",
    gps_code: "FMFE",
    iata_code: "OHB",
    local_code: "OMB",
    coordinates: "48.2181944444, -18.9175",
  },
  {
    ident: "MG-DOA",
    type: "small_airport",
    name: "Doany Airport",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-D",
    municipality: "Doany",
    iata_code: "DOA",
    coordinates: "49.510799407958984, -14.3681001663208",
  },
  {
    ident: "MGCB",
    type: "medium_airport",
    name: "Coban Airport",
    elevation_ft: "4339",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-AV",
    municipality: "Coban",
    gps_code: "MGCB",
    iata_code: "CBV",
    coordinates: "-90.40670013427734, 15.468999862670898",
  },
  {
    ident: "MGCR",
    type: "small_airport",
    name: "Carmelita Airport",
    elevation_ft: "753",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-PE",
    municipality: "Carmelita",
    gps_code: "MGCR",
    iata_code: "CMM",
    coordinates: "-90.05370330810547, 17.461200714111328",
  },
  {
    ident: "MGCT",
    type: "small_airport",
    name: "Coatepeque Airport",
    elevation_ft: "1486",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-QZ",
    municipality: "Coatepeque",
    gps_code: "MGCT",
    iata_code: "CTF",
    coordinates: "-91.88249969482422, 14.694199562072754",
  },
  {
    ident: "MGG",
    type: "closed",
    name: "Margarima Airport",
    elevation_ft: "7291",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SHM",
    municipality: "Margarima",
    iata_code: "MGG",
    coordinates: "143.3595, -5.979",
  },
  {
    ident: "MGGT",
    type: "large_airport",
    name: "La Aurora Airport",
    elevation_ft: "4952",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-GU",
    municipality: "Guatemala City",
    gps_code: "MGGT",
    iata_code: "GUA",
    coordinates: "-90.527496, 14.5833",
  },
  {
    ident: "MGHT",
    type: "small_airport",
    name: "Huehuetenango Airport",
    elevation_ft: "6375",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-HU",
    municipality: "Huehuetenango",
    gps_code: "MGHT",
    iata_code: "HUG",
    coordinates: "-91.46240234375, 15.327400207519531",
  },
  {
    ident: "MGI",
    type: "closed",
    name: "Matagorda Island Air Force Base",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Matagorda Island",
    iata_code: "MGI",
    coordinates: "-96.464, 28.323",
  },
  {
    ident: "MGMM",
    type: "small_airport",
    name: "Melchor de Mencos Airport",
    elevation_ft: "4670",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-PE",
    municipality: "Melchor de Mencos",
    iata_code: "MCR",
    coordinates: "-89.1522216797, 17.0686092377",
  },
  {
    ident: "MGO",
    type: "closed",
    name: "Manega Airport",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-8",
    iata_code: "MGO",
    coordinates: "10.2167, -1.73333",
  },
  {
    ident: "MGP",
    type: "small_airport",
    name: "Manga Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Manga Mission",
    iata_code: "MGP",
    local_code: "MNA",
    coordinates: "153.0186, -4.147",
  },
  {
    ident: "MGPB",
    type: "medium_airport",
    name: "Puerto Barrios Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-IZ",
    municipality: "Puerto Barrios",
    gps_code: "MGPB",
    iata_code: "PBR",
    coordinates: "-88.583801, 15.7309",
  },
  {
    ident: "MGPP",
    type: "small_airport",
    name: "Popt����n Airport",
    elevation_ft: "1801",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-PE",
    municipality: "Popt����n",
    gps_code: "MGPP",
    iata_code: "PON",
    coordinates: "-89.41609954834, 16.325799942017",
  },
  {
    ident: "MGQC",
    type: "small_airport",
    name: "Santa Cruz del Quiche Airport",
    elevation_ft: "6631",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-QC",
    municipality: "Santa Cruz del Quiche",
    gps_code: "MGQC",
    iata_code: "AQB",
    coordinates: "-91.15059661865234, 15.012200355529785",
  },
  {
    ident: "MGQZ",
    type: "small_airport",
    name: "Quezaltenango Airport",
    elevation_ft: "7779",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-QZ",
    municipality: "Quezaltenango",
    gps_code: "MGQZ",
    iata_code: "AAZ",
    coordinates: "-91.501999, 14.8656",
  },
  {
    ident: "MGRB",
    type: "medium_airport",
    name: "Rubelsanto Airport",
    elevation_ft: "426",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-AV",
    municipality: "Rubelsanto",
    gps_code: "MGRB",
    iata_code: "RUV",
    coordinates: "-90.44529724121094, 15.991999626159668",
  },
  {
    ident: "MGRD",
    type: "small_airport",
    name: "Las Vegas Airport",
    elevation_ft: "28",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-IZ",
    municipality: "Rio Dulce",
    gps_code: "MGRD",
    iata_code: "LCF",
    coordinates: "-88.961763, 15.668363",
  },
  {
    ident: "MGRT",
    type: "medium_airport",
    name: "Retalhuleu Airport",
    elevation_ft: "656",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-RE",
    municipality: "Retalhuleu",
    gps_code: "MGRT",
    iata_code: "RER",
    coordinates: "-91.69730377197266, 14.520999908447266",
  },
  {
    ident: "MGSJ",
    type: "medium_airport",
    name: "San Jos���� Airport",
    elevation_ft: "29",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-ES",
    municipality: "Puerto San Jos����",
    gps_code: "MGSJ",
    iata_code: "GSJ",
    coordinates: "-90.83580017090001, 13.936200141899999",
  },
  {
    ident: "MGTK",
    type: "medium_airport",
    name: "Mundo Maya International Airport",
    elevation_ft: "427",
    continent: "NA",
    iso_country: "GT",
    iso_region: "GT-PE",
    municipality: "San Benito",
    gps_code: "MGMM",
    iata_code: "FRS",
    coordinates: "-89.86640167239999, 16.913799285899998",
  },
  {
    ident: "MH-AIM",
    type: "small_airport",
    name: "Ailuk Airport",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-ALK",
    municipality: "Ailuk Island",
    iata_code: "AIM",
    coordinates: "169.98300170898438, 10.21679973602295",
  },
  {
    ident: "MH-AUL",
    type: "small_airport",
    name: "Aur Island Airport",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-AUR",
    municipality: "Aur Atoll",
    iata_code: "AUL",
    coordinates: "171.17300415039062, 8.145279884338379",
  },
  {
    ident: "MH-BII",
    type: "small_airport",
    name: "Enyu Airfield",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-BIK",
    municipality: "Bikini Atoll",
    iata_code: "BII",
    coordinates: "165.565002, 11.5225",
  },
  {
    ident: "MH-EBN",
    type: "small_airport",
    name: "Ebadon Airport",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-KWA",
    municipality: "Ebadon Island",
    iata_code: "EBN",
    coordinates: "166.82000732421875, 9.330559730529785",
  },
  {
    ident: "MH-JAT",
    type: "small_airport",
    name: "Jabot Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-ALL",
    municipality: "Ailinglapalap Atoll",
    iata_code: "JAT",
    coordinates: "168.977837563, 7.749774755610001",
  },
  {
    ident: "MH-JEJ",
    type: "small_airport",
    name: "Jeh Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-ALL",
    municipality: "Ailinglapalap Atoll",
    iata_code: "JEJ",
    local_code: "JEJ",
    coordinates: "168.962005615, 7.565350055690001",
  },
  {
    ident: "MH-KBT",
    type: "small_airport",
    name: "Kaben Airport",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-MAL",
    municipality: "Kaben",
    iata_code: "KBT",
    coordinates: "170.843994140625, 8.90056037902832",
  },
  {
    ident: "MH-LIK",
    type: "small_airport",
    name: "Likiep Airport",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-LIK",
    municipality: "Likiep Island",
    iata_code: "LIK",
    coordinates: "169.307999, 9.82316",
  },
  {
    ident: "MH-LML",
    type: "small_airport",
    name: "Lae Island Airport",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-LAE",
    municipality: "Lae Island",
    iata_code: "LML",
    coordinates: "166.26499938964844, 8.921110153198242",
  },
  {
    ident: "MH-MAV",
    type: "small_airport",
    name: "Maloelap Island Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-MAL",
    municipality: "Maloelap Island",
    iata_code: "MAV",
    local_code: "3N1",
    coordinates: "171.229995728, 8.70444011688",
  },
  {
    ident: "MH-MJB",
    type: "small_airport",
    name: "Mejit Atoll Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-MEJ",
    municipality: "Mejit Atoll",
    iata_code: "MJB",
    local_code: "C30",
    coordinates: "170.869, 10.283302",
  },
  {
    ident: "MH-MJE",
    type: "small_airport",
    name: "Majkin Airport",
    elevation_ft: "24",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-NMU",
    municipality: "Majkin",
    iata_code: "MJE",
    coordinates: "168.26576, 7.76291",
  },
  {
    ident: "MH-NDK",
    type: "small_airport",
    name: "Namorik Atoll Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-NMK",
    municipality: "Namorik Atoll",
    iata_code: "NDK",
    local_code: "3N0",
    coordinates: "168.125, 5.631669998168945",
  },
  {
    ident: "MH-RNP",
    type: "small_airport",
    name: "Rongelap Island Airport",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-RON",
    municipality: "Rongelap Island",
    iata_code: "RNP",
    coordinates: "166.886993, 11.1572",
  },
  {
    ident: "MH-TIC",
    type: "small_airport",
    name: "Tinak Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-ARN",
    municipality: "Arno Atoll",
    iata_code: "TIC",
    local_code: "N18",
    coordinates: "171.91700744628906, 7.13332986831665",
  },
  {
    ident: "MH-UIT",
    type: "small_airport",
    name: "Jaluit Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-JAL",
    municipality: "Jabor Jaluit Atoll",
    iata_code: "UIT",
    local_code: "N55",
    coordinates: "169.636993, 5.90924",
  },
  {
    ident: "MH-WJA",
    type: "small_airport",
    name: "Woja Airport",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-ALL",
    municipality: "Woja",
    iata_code: "WJA",
    coordinates: "168.55, 7.45083333333",
  },
  {
    ident: "MH-WTE",
    type: "small_airport",
    name: "Wotje Atoll Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-WTJ",
    municipality: "Wotje Atoll",
    iata_code: "WTE",
    local_code: "N36",
    coordinates: "170.238611, 9.458333",
  },
  {
    ident: "MH-WTO",
    type: "small_airport",
    name: "Wotho Island Airport",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-WTN",
    municipality: "Wotho Island",
    iata_code: "WTO",
    coordinates: "166.0030059814453, 10.173299789428711",
  },
  {
    ident: "MHAH",
    type: "small_airport",
    name: "Ahuas Airport",
    elevation_ft: "249",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-GD",
    municipality: "Ahuas",
    gps_code: "MHAH",
    iata_code: "AHS",
    coordinates: "-84.352203, 15.4722",
  },
  {
    ident: "MHBL",
    type: "small_airport",
    name: "Brus Laguna Airport",
    elevation_ft: "19",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-GD",
    municipality: "Brus Laguna",
    gps_code: "MHBL",
    iata_code: "BHG",
    coordinates: "-84.543602, 15.7631",
  },
  {
    ident: "MHCA",
    type: "small_airport",
    name: "Catacamas Airport",
    elevation_ft: "1489",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-OL",
    municipality: "Catacamas",
    gps_code: "MHCA",
    iata_code: "CAA",
    coordinates: "-85.9000015258789, 14.916999816894531",
  },
  {
    ident: "MHCL",
    type: "closed",
    name: "Colon Airport",
    elevation_ft: "1240",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-LP",
    municipality: "Marcala",
    gps_code: "MHMA",
    iata_code: "MRJ",
    coordinates: "-88.034447, 14.161944",
  },
  {
    ident: "MHCR",
    type: "small_airport",
    name: "Carta Airport",
    elevation_ft: "2625",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-OL",
    municipality: "La Uni����n",
    gps_code: "MHCR",
    iata_code: "LUI",
    coordinates: "-86.692334, 15.033192",
  },
  {
    ident: "MHCS",
    type: "small_airport",
    name: "Coyoles Airport",
    elevation_ft: "492",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-YO",
    municipality: "Coyoles",
    gps_code: "MHCS",
    iata_code: "CYL",
    coordinates: "-86.675278, 15.445556",
  },
  {
    ident: "MHCU",
    type: "small_airport",
    name: "Cauquira Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-GD",
    municipality: "Cauquira",
    gps_code: "MHCU",
    iata_code: "CDD",
    coordinates: "-83.591667, 15.316667",
  },
  {
    ident: "MHEA",
    type: "small_airport",
    name: "El Array����n Airport",
    elevation_ft: "722",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-YO",
    municipality: "Olanchito",
    gps_code: "MHOA",
    iata_code: "OAN",
    coordinates: "-86.574722, 15.505556",
  },
  {
    ident: "MHGS",
    type: "small_airport",
    name: "Celaque Airport",
    elevation_ft: "2995",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-LE",
    municipality: "Gracias",
    gps_code: "MHGS",
    iata_code: "GAC",
    coordinates: "-88.595801, 14.573492",
  },
  {
    ident: "MHIR",
    type: "small_airport",
    name: "Iriona Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-CL",
    municipality: "Iriona",
    gps_code: "MHIR",
    iata_code: "IRN",
    coordinates: "-85.13722229, 15.939167022700001",
  },
  {
    ident: "MHJI",
    type: "small_airport",
    name: "Jicalapa Airport",
    elevation_ft: "2133",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-OL",
    municipality: "Jicalapa",
    gps_code: "MHJI",
    iata_code: "GUO",
    coordinates: "-86.049164, 15.006111",
  },
  {
    ident: "MHJU",
    type: "small_airport",
    name: "Jutigalpa airport",
    elevation_ft: "1314",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-OL",
    municipality: "Jutigalpa",
    gps_code: "MHJU",
    iata_code: "JUT",
    coordinates: "-86.22029876708984, 14.652600288391113",
  },
  {
    ident: "MHLC",
    type: "medium_airport",
    name: "Goloson International Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-AT",
    municipality: "La Ceiba",
    gps_code: "MHLC",
    iata_code: "LCE",
    coordinates: "-86.852997, 15.7425",
  },
  {
    ident: "MHLE",
    type: "small_airport",
    name: "La Esperanza Airport",
    elevation_ft: "5475",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-IN",
    municipality: "La Esperanza",
    gps_code: "MHLE",
    iata_code: "LEZ",
    coordinates: "-88.175003, 14.291111",
  },
  {
    ident: "MHLM",
    type: "medium_airport",
    name: "Ram����n Villeda Morales International Airport",
    elevation_ft: "91",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-CR",
    municipality: "San Pedro Sula",
    gps_code: "MHLM",
    iata_code: "SAP",
    coordinates: "-87.923599, 15.4526",
  },
  {
    ident: "MHN",
    type: "small_airport",
    name: "Hooker County Airport",
    elevation_ft: "3260",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Mullen",
    gps_code: "KMHN",
    iata_code: "MHN",
    local_code: "84NE",
    coordinates: "-101.059113, 42.042155",
  },
  {
    ident: "MHNJ",
    type: "medium_airport",
    name: "La Laguna Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-IB",
    municipality: "Guanaja",
    gps_code: "MHNJ",
    iata_code: "GJA",
    coordinates: "-85.906601, 16.4454",
  },
  {
    ident: "MHPC",
    type: "small_airport",
    name: "Palacios Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-GD",
    municipality: "Palacios",
    gps_code: "MHPC",
    iata_code: "PCH",
    coordinates: "-84.941391, 15.955",
  },
  {
    ident: "MHPL",
    type: "small_airport",
    name: "Puerto Lempira Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-GD",
    municipality: "Puerto Lempira",
    gps_code: "MHPL",
    iata_code: "PEU",
    coordinates: "-83.781197, 15.2622",
  },
  {
    ident: "MHRO",
    type: "medium_airport",
    name: "Juan Manuel Galvez International Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-IB",
    municipality: "Roatan Island",
    gps_code: "MHRO",
    iata_code: "RTB",
    coordinates: "-86.523003, 16.316799",
  },
  {
    ident: "MHRS",
    type: "closed",
    name: "Santa Rosa de Cop����n Airport",
    elevation_ft: "3564",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-CP",
    municipality: "Santa Rosa de Cop����n",
    gps_code: "MHSR",
    iata_code: "SDH",
    coordinates: "-88.775021, 14.777889",
  },
  {
    ident: "MHRU",
    type: "small_airport",
    name: "Cop����n Ruinas Airport",
    elevation_ft: "2336",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-CP",
    municipality: "Cop����n Ruinas",
    gps_code: "MHRU",
    iata_code: "RUY",
    coordinates: "-89.007837, 14.914885",
  },
  {
    ident: "MHSC",
    type: "medium_airport",
    name: "Coronel Enrique Soto Cano Air Base",
    elevation_ft: "2061",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-CM",
    municipality: "Comayagua",
    gps_code: "MHSC",
    iata_code: "XPL",
    coordinates: "-87.621201, 14.3824",
  },
  {
    ident: "MHTE",
    type: "medium_airport",
    name: "Tela Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-AT",
    municipality: "Tela",
    gps_code: "MHTE",
    iata_code: "TEA",
    coordinates: "-87.4758, 15.7759",
  },
  {
    ident: "MHTG",
    type: "medium_airport",
    name: "Toncont����n International Airport",
    elevation_ft: "3294",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-FM",
    municipality: "Tegucigalpa",
    gps_code: "MHTG",
    iata_code: "TGU",
    coordinates: "-87.21720123291016, 14.06089973449707",
  },
  {
    ident: "MHTJ",
    type: "medium_airport",
    name: "Trujillo Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-CL",
    municipality: "Trujillo",
    gps_code: "MHTJ",
    iata_code: "TJI",
    coordinates: "-85.938202, 15.9268",
  },
  {
    ident: "MHUL",
    type: "small_airport",
    name: "Sulaco Airport",
    elevation_ft: "400",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-YO",
    municipality: "Sulaco",
    gps_code: "MHUL",
    iata_code: "SCD",
    coordinates: "-87.26339721679688, 14.90719985961914",
  },
  {
    ident: "MHUT",
    type: "small_airport",
    name: "Utila Airport",
    elevation_ft: "29",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-IB",
    municipality: "Utila Island",
    gps_code: "MHUT",
    iata_code: "UII",
    coordinates: "-86.880302, 16.1131",
  },
  {
    ident: "MHY",
    type: "small_airport",
    name: "Morehead Airport",
    elevation_ft: "100",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Morehead",
    gps_code: "AYEH",
    iata_code: "MHY",
    local_code: "MHD",
    coordinates: "141.644444444, -8.71411111111",
  },
  {
    ident: "MHYR",
    type: "small_airport",
    name: "Yoro Airport",
    elevation_ft: "2215",
    continent: "NA",
    iso_country: "HN",
    iso_region: "HN-YO",
    municipality: "Yoro",
    gps_code: "MHYR",
    iata_code: "ORO",
    coordinates: "-87.135, 15.1275",
  },
  {
    ident: "MIZ",
    type: "small_airport",
    name: "Mainoru Airstrip",
    elevation_ft: "365",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Mainoru",
    iata_code: "MIZ",
    coordinates: "134.0942, -14.0533",
  },
  {
    ident: "MJJ",
    type: "small_airport",
    name: "Moki Airport",
    elevation_ft: "3030",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Moki",
    iata_code: "MJJ",
    local_code: "MOKI",
    coordinates: "145.2404, -5.7181",
  },
  {
    ident: "MJS",
    type: "small_airport",
    name: "Maganja da Costa Airport",
    elevation_ft: "215",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-Q",
    municipality: "Maganja",
    iata_code: "MJS",
    coordinates: "37.5, -17.3087",
  },
  {
    ident: "MKBS",
    type: "medium_airport",
    name: "Boscobel Aerodrome",
    elevation_ft: "90",
    continent: "NA",
    iso_country: "JM",
    iso_region: "JM-05",
    municipality: "Ocho Rios",
    gps_code: "MKBS",
    iata_code: "OCJ",
    coordinates: "-76.96900177001953, 18.404199600219727",
  },
  {
    ident: "MKJP",
    type: "large_airport",
    name: "Norman Manley International Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "JM",
    iso_region: "JM-01",
    municipality: "Kingston",
    gps_code: "MKJP",
    iata_code: "KIN",
    coordinates: "-76.7874984741211, 17.935699462890625",
  },
  {
    ident: "MKJS",
    type: "medium_airport",
    name: "Sangster International Airport",
    elevation_ft: "4",
    continent: "NA",
    iso_country: "JM",
    iso_region: "JM-08",
    municipality: "Montego Bay",
    gps_code: "MKJS",
    iata_code: "MBJ",
    coordinates: "-77.91339874267578, 18.503700256347656",
  },
  {
    ident: "MKKJ",
    type: "medium_airport",
    name: "Ken Jones Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "JM",
    iso_region: "JM-04",
    municipality: "Ken Jones",
    gps_code: "MKKJ",
    iata_code: "POT",
    coordinates: "-76.53450012210001, 18.1987991333",
  },
  {
    ident: "MKN",
    type: "small_airport",
    name: "Malekolon Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Babase Island",
    gps_code: "AYMV",
    iata_code: "MKN",
    local_code: "MKO",
    coordinates: "153.657277778, -4.02343055556",
  },
  {
    ident: "MKNG",
    type: "small_airport",
    name: "Negril Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "JM",
    iso_region: "JM-09",
    municipality: "Negril",
    gps_code: "MKNG",
    iata_code: "NEG",
    coordinates: "-78.33209991455078, 18.34280014038086",
  },
  {
    ident: "MKTP",
    type: "medium_airport",
    name: "Tinson Pen Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "JM",
    iso_region: "JM-02",
    municipality: "Tinson Pen",
    gps_code: "MKTP",
    iata_code: "KTP",
    coordinates: "-76.82379913330078, 17.98859977722168",
  },
  {
    ident: "MLIP",
    type: "small_airport",
    name: "Mili Island Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-MIL",
    municipality: "Mili Island",
    gps_code: "MLIP",
    iata_code: "MIJ",
    local_code: "1Q9",
    coordinates: "171.733002, 6.08333",
  },
  {
    ident: "MLQ",
    type: "small_airport",
    name: "Malalaua Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Malalaua",
    gps_code: "AYMP",
    iata_code: "MLQ",
    local_code: "MLU",
    coordinates: "146.155472222, -8.07138888889",
  },
  {
    ident: "MM-0002",
    type: "small_airport",
    name: "Hinthada Airport",
    elevation_ft: "32",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-07",
    municipality: "Hinthada",
    gps_code: "VYHT",
    iata_code: "HEB",
    coordinates: "95.4666976929, 17.633329391500002",
  },
  {
    ident: "MM72",
    type: "small_airport",
    name: "Cupul Airport",
    elevation_ft: "56",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-YUC",
    municipality: "Tizimin",
    gps_code: "MM72",
    iata_code: "TZM",
    local_code: "MM72",
    coordinates: "-88.172918, 21.155716",
  },
  {
    ident: "MMAA",
    type: "large_airport",
    name: "General Juan N Alvarez International Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-GRO",
    municipality: "Acapulco",
    gps_code: "MMAA",
    iata_code: "ACA",
    coordinates: "-99.75399780273438, 16.757099151611328",
  },
  {
    ident: "MMAN",
    type: "medium_airport",
    name: "Del Norte International Airport",
    elevation_ft: "1476",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-NLE",
    gps_code: "MMAN",
    iata_code: "NTR",
    local_code: "ADN",
    coordinates: "-100.237, 25.865601",
  },
  {
    ident: "MMAS",
    type: "medium_airport",
    name: "Jes����s Ter����n Paredo International Airport",
    elevation_ft: "6112",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-AGU",
    municipality: "Aguascalientes",
    gps_code: "MMAS",
    iata_code: "AGU",
    coordinates: "-102.318001, 21.705601",
  },
  {
    ident: "MMBT",
    type: "medium_airport",
    name: "Bah����as de Huatulco International Airport",
    elevation_ft: "464",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-OAX",
    municipality: "Huatulco",
    gps_code: "MMBT",
    iata_code: "HUX",
    local_code: "HU1",
    coordinates: "-96.262604, 15.7753",
  },
  {
    ident: "MMCA",
    type: "small_airport",
    name: "Cananea National Airport",
    elevation_ft: "4921",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SON",
    municipality: "Cananea",
    gps_code: "MMCA",
    iata_code: "CNA",
    local_code: "CNA",
    coordinates: "-110.097878, 31.06615",
  },
  {
    ident: "MMCB",
    type: "medium_airport",
    name: "General Mariano Matamoros Airport",
    elevation_ft: "4277",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-MOR",
    gps_code: "MMCB",
    iata_code: "CVJ",
    coordinates: "-99.26129913330078, 18.834800720214844",
  },
  {
    ident: "MMCC",
    type: "small_airport",
    name: "Ciudad Acu����a New International Airport",
    elevation_ft: "1410",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-COA",
    municipality: "Ciudad Acu����a",
    gps_code: "MMCC",
    iata_code: "ACN",
    local_code: "EFN",
    coordinates: "-101.098998, 29.332899",
  },
  {
    ident: "MMCE",
    type: "medium_airport",
    name: "Ciudad del Carmen International Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CAM",
    municipality: "Ciudad del Carmen",
    gps_code: "MMCE",
    iata_code: "CME",
    coordinates: "-91.79900360107422, 18.65369987487793",
  },
  {
    ident: "MMCG",
    type: "small_airport",
    name: "Nuevo Casas Grandes Airport",
    elevation_ft: "4850",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CHH",
    gps_code: "MMCG",
    iata_code: "NCG",
    local_code: "NCG",
    coordinates: "-107.875, 30.3974",
  },
  {
    ident: "MMCL",
    type: "medium_airport",
    name: "Bachigualato Federal International Airport",
    elevation_ft: "108",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SIN",
    municipality: "Culiac����n",
    gps_code: "MMCL",
    iata_code: "CUL",
    coordinates: "-107.474998474, 24.7644996643",
  },
  {
    ident: "MMCM",
    type: "medium_airport",
    name: "Chetumal International Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-ROO",
    municipality: "Chetumal",
    gps_code: "MMCM",
    iata_code: "CTM",
    coordinates: "-88.32679748535156, 18.50469970703125",
  },
  {
    ident: "MMCN",
    type: "medium_airport",
    name: "Ciudad Obreg����n International Airport",
    elevation_ft: "243",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SON",
    municipality: "Ciudad Obreg����n",
    gps_code: "MMCN",
    iata_code: "CEN",
    coordinates: "-109.83300018310547, 27.39259910583496",
  },
  {
    ident: "MMCO",
    type: "small_airport",
    name: "San Antonio Copalar Airport",
    elevation_ft: "5161",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CHP",
    municipality: "Comit����n",
    gps_code: "MMCO",
    iata_code: "CJT",
    coordinates: "-92.050598, 16.176701",
  },
  {
    ident: "MMCP",
    type: "medium_airport",
    name: "Ingeniero Alberto Acu����a Ongay International Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CAM",
    municipality: "Campeche",
    gps_code: "MMCP",
    iata_code: "CPE",
    coordinates: "-90.5002975464, 19.816799163800003",
  },
  {
    ident: "MMCS",
    type: "medium_airport",
    name: "Abraham Gonz����lez International Airport",
    elevation_ft: "3904",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CHH",
    municipality: "Ciudad Ju����rez",
    gps_code: "MMCS",
    iata_code: "CJS",
    coordinates: "-106.42900085449219, 31.63610076904297",
  },
  {
    ident: "MMCT",
    type: "medium_airport",
    name: "Chichen Itza International Airport",
    elevation_ft: "102",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-YUC",
    municipality: "Chichen Itza",
    gps_code: "MMCT",
    iata_code: "CZA",
    coordinates: "-88.4461975098, 20.6413002014",
  },
  {
    ident: "MMCU",
    type: "medium_airport",
    name: "General Roberto Fierro Villalobos International Airport",
    elevation_ft: "4462",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CHH",
    municipality: "Chihuahua",
    gps_code: "MMCU",
    iata_code: "CUU",
    coordinates: "-105.964996338, 28.702899932900003",
  },
  {
    ident: "MMCV",
    type: "medium_airport",
    name: "General Pedro Jose Mendez International Airport",
    elevation_ft: "761",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-TAM",
    municipality: "Ciudad Victoria",
    gps_code: "MMCV",
    iata_code: "CVM",
    coordinates: "-98.9564971924, 23.7033004761",
  },
  {
    ident: "MMCY",
    type: "medium_airport",
    name: "Captain Rogelio Castillo National Airport",
    elevation_ft: "5709",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-GUA",
    municipality: "Celaya",
    gps_code: "MMCY",
    iata_code: "CYW",
    local_code: "CYA",
    coordinates: "-100.887001, 20.546",
  },
  {
    ident: "MMCZ",
    type: "medium_airport",
    name: "Cozumel International Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-ROO",
    municipality: "Cozumel",
    gps_code: "MMCZ",
    iata_code: "CZM",
    coordinates: "-86.92559814453125, 20.52239990234375",
  },
  {
    ident: "MMDA",
    type: "medium_airport",
    name: "Ciudad Constituci����n Airport",
    elevation_ft: "213",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "Ciudad Constituci����n",
    gps_code: "MMDA",
    iata_code: "CUA",
    local_code: "CCB",
    coordinates: "-111.614998, 25.053801",
  },
  {
    ident: "MMDM",
    type: "small_airport",
    name: "Ciudad Mante National Airport",
    elevation_ft: "341",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-TAM",
    municipality: "Ciudad Mante",
    gps_code: "MMDM",
    iata_code: "MMC",
    local_code: "CDM",
    coordinates: "-99.017372, 22.743177",
  },
  {
    ident: "MMDO",
    type: "medium_airport",
    name: "General Guadalupe Victoria International Airport",
    elevation_ft: "6104",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-DUR",
    municipality: "Durango",
    gps_code: "MMDO",
    iata_code: "DGO",
    coordinates: "-104.527999878, 24.1242008209",
  },
  {
    ident: "MMEP",
    type: "medium_airport",
    name: "Amado Nervo National Airport",
    elevation_ft: "3020",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-NAY",
    municipality: "Tepic",
    gps_code: "MMEP",
    iata_code: "TPQ",
    local_code: "TNY",
    coordinates: "-104.843002, 21.4195",
  },
  {
    ident: "MMES",
    type: "small_airport",
    name: "Ensenada International Airport / El Cipres Air Base",
    elevation_ft: "66",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCN",
    municipality: "Ensenada",
    gps_code: "MMES",
    iata_code: "ESE",
    local_code: "ENS",
    coordinates: "-116.602997, 31.7953",
  },
  {
    ident: "MMGL",
    type: "large_airport",
    name: "Don Miguel Hidalgo Y Costilla International Airport",
    elevation_ft: "5016",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-JAL",
    municipality: "Guadalajara",
    gps_code: "MMGL",
    iata_code: "GDL",
    coordinates: "-103.31099700927734, 20.521799087524414",
  },
  {
    ident: "MMGM",
    type: "medium_airport",
    name: "General Jos���� Mar����a Y��������ez International Airport",
    elevation_ft: "59",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SON",
    municipality: "Guaymas",
    gps_code: "MMGM",
    iata_code: "GYM",
    coordinates: "-110.92500305175781, 27.9689998626709",
  },
  {
    ident: "MMGR",
    type: "small_airport",
    name: "Guerrero Negro Airport",
    elevation_ft: "59",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCN",
    municipality: "Guerrero Negro",
    gps_code: "MMGR",
    iata_code: "GUB",
    local_code: "GRN",
    coordinates: "-114.024002, 28.0261",
  },
  {
    ident: "MMHC",
    type: "small_airport",
    name: "Tehuacan Airport",
    elevation_ft: "5509",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-PUE",
    gps_code: "MMHC",
    iata_code: "TCN",
    coordinates: "-97.4198989868164, 18.49720001220703",
  },
  {
    ident: "MMHO",
    type: "large_airport",
    name: "General Ignacio P. Garcia International Airport",
    elevation_ft: "627",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SON",
    municipality: "Hermosillo",
    gps_code: "MMHO",
    iata_code: "HMO",
    coordinates: "-111.047996521, 29.095899581900003",
  },
  {
    ident: "MMIA",
    type: "medium_airport",
    name: "Licenciado Miguel de la Madrid Airport",
    elevation_ft: "2467",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-COL",
    municipality: "Colima",
    gps_code: "MMIA",
    iata_code: "CLQ",
    local_code: "COL",
    coordinates: "-103.577002, 19.277",
  },
  {
    ident: "MMIM",
    type: "small_airport",
    name: "Isla Mujeres Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-ROO",
    gps_code: "MMIM",
    iata_code: "ISJ",
    coordinates: "-86.73999786376953, 21.2450008392334",
  },
  {
    ident: "MMIO",
    type: "medium_airport",
    name: "Plan De Guadalupe International Airport",
    elevation_ft: "4778",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-COA",
    municipality: "Saltillo",
    gps_code: "MMIO",
    iata_code: "SLW",
    coordinates: "-100.92900085449219, 25.54949951171875",
  },
  {
    ident: "MMIT",
    type: "medium_airport",
    name: "Ixtepec Airport",
    elevation_ft: "164",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-OAX",
    municipality: "Ixtepec",
    gps_code: "MMIT",
    iata_code: "IZT",
    coordinates: "-95.093697, 16.449301",
  },
  {
    ident: "MMJA",
    type: "medium_airport",
    name: "El Lencero Airport",
    elevation_ft: "3127",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-VER",
    municipality: "Xalapa",
    gps_code: "MMJA",
    iata_code: "JAL",
    coordinates: "-96.7975006104, 19.4750995636",
  },
  {
    ident: "MMJC",
    type: "small_airport",
    name: "Atizapan De Zaragoza Airport",
    elevation_ft: "8120",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-MEX",
    gps_code: "MMJC",
    iata_code: "AZP",
    coordinates: "-99.28880310059999, 19.5748004913",
  },
  {
    ident: "MMLC",
    type: "medium_airport",
    name: "L����zaro C����rdenas Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-MIC",
    municipality: "L����zaro C����rdenas",
    gps_code: "MMLC",
    iata_code: "LZC",
    coordinates: "-102.221000671, 18.0016994476",
  },
  {
    ident: "MMLM",
    type: "medium_airport",
    name: "Valle del Fuerte International Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SIN",
    municipality: "Los Mochis",
    gps_code: "MMLM",
    iata_code: "LMM",
    coordinates: "-109.081001282, 25.6851997375",
  },
  {
    ident: "MMLO",
    type: "medium_airport",
    name: "Del Baj����o International Airport",
    elevation_ft: "5956",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-GUA",
    municipality: "Silao",
    gps_code: "MMLO",
    iata_code: "BJX",
    local_code: "BJ1",
    coordinates: "-101.481003, 20.9935",
  },
  {
    ident: "MMLP",
    type: "medium_airport",
    name: "Manuel M����rquez de Le����n International Airport",
    elevation_ft: "69",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "La Paz",
    gps_code: "MMLP",
    iata_code: "LAP",
    coordinates: "-110.361999512, 24.072700500499998",
  },
  {
    ident: "MMLT",
    type: "medium_airport",
    name: "Loreto International Airport",
    elevation_ft: "34",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "Loreto",
    gps_code: "MMLT",
    iata_code: "LTO",
    coordinates: "-111.3479995727539, 25.989200592041016",
  },
  {
    ident: "MMMA",
    type: "medium_airport",
    name: "General Servando Canales International Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-TAM",
    municipality: "Matamoros",
    gps_code: "MMMA",
    iata_code: "MAM",
    coordinates: "-97.5252990723, 25.7698993683",
  },
  {
    ident: "MMMD",
    type: "medium_airport",
    name: "Licenciado Manuel Crescencio Rejon Int Airport",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-YUC",
    municipality: "M����rida",
    gps_code: "MMMD",
    iata_code: "MID",
    coordinates: "-89.657699585, 20.937000274699997",
  },
  {
    ident: "MMMG",
    type: "small_airport",
    name: "Mulege Airport",
    elevation_ft: "66",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "Mulege",
    gps_code: "MMMG",
    iata_code: "MUG",
    coordinates: "-111.970725, 26.905347",
  },
  {
    ident: "MMML",
    type: "medium_airport",
    name: "General Rodolfo S����nchez Taboada International Airport",
    elevation_ft: "74",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCN",
    municipality: "Mexicali",
    gps_code: "MMML",
    iata_code: "MXL",
    local_code: "M1L",
    coordinates: "-115.241997, 32.6306",
  },
  {
    ident: "MMMM",
    type: "medium_airport",
    name: "General Francisco J. Mujica International Airport",
    elevation_ft: "6033",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-MIC",
    municipality: "Morelia",
    gps_code: "MMMM",
    iata_code: "MLM",
    coordinates: "-101.025001526, 19.849899292",
  },
  {
    ident: "MMMT",
    type: "medium_airport",
    name: "Minatitl����n/Coatzacoalcos National Airport",
    elevation_ft: "36",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-VER",
    municipality: "Minatitl����n",
    gps_code: "MMMT",
    iata_code: "MTT",
    coordinates: "-94.58070373540001, 18.1033992767",
  },
  {
    ident: "MMMV",
    type: "medium_airport",
    name: "Monclova International Airport",
    elevation_ft: "1864",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-COA",
    gps_code: "MMMV",
    iata_code: "LOV",
    local_code: "MOV",
    coordinates: "-101.470001, 26.9557",
  },
  {
    ident: "MMMX",
    type: "large_airport",
    name: "Licenciado Benito Juarez International Airport",
    elevation_ft: "7316",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-DIF",
    municipality: "Mexico City",
    gps_code: "MMMX",
    iata_code: "MEX",
    local_code: "ME1",
    coordinates: "-99.072098, 19.4363",
  },
  {
    ident: "MMMY",
    type: "large_airport",
    name: "General Mariano Escobedo International Airport",
    elevation_ft: "1278",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-NLE",
    municipality: "Monterrey",
    gps_code: "MMMY",
    iata_code: "MTY",
    coordinates: "-100.107002258, 25.7784996033",
  },
  {
    ident: "MMMZ",
    type: "medium_airport",
    name: "General Rafael Buelna International Airport",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SIN",
    municipality: "Mazatl����n",
    gps_code: "MMMZ",
    iata_code: "MZT",
    coordinates: "-106.26599884, 23.1613998413",
  },
  {
    ident: "MMNG",
    type: "small_airport",
    name: "Nogales International Airport",
    elevation_ft: "3990",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SON",
    gps_code: "MMNG",
    iata_code: "NOG",
    coordinates: "-110.97599792480469, 31.22610092163086",
  },
  {
    ident: "MMNL",
    type: "medium_airport",
    name: "Quetzalc����atl International Airport",
    elevation_ft: "484",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-TAM",
    municipality: "Nuevo Laredo",
    gps_code: "MMNL",
    iata_code: "NLD",
    coordinates: "-99.5705032349, 27.4438991547",
  },
  {
    ident: "MMOX",
    type: "medium_airport",
    name: "Xoxocotl����n International Airport",
    elevation_ft: "4989",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-OAX",
    municipality: "Oaxaca",
    gps_code: "MMOX",
    iata_code: "OAX",
    coordinates: "-96.726600647, 16.9999008179",
  },
  {
    ident: "MMPA",
    type: "medium_airport",
    name: "El Taj����n National Airport",
    elevation_ft: "497",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-VER",
    municipality: "Poza Rica",
    gps_code: "MMPA",
    iata_code: "PAZ",
    coordinates: "-97.46080017090001, 20.6026992798",
  },
  {
    ident: "MMPB",
    type: "medium_airport",
    name: "Hermanos Serd����n International Airport",
    elevation_ft: "7361",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-PUE",
    municipality: "Puebla",
    gps_code: "MMPB",
    iata_code: "PBC",
    coordinates: "-98.3713989258, 19.1581001282",
  },
  {
    ident: "MMPG",
    type: "medium_airport",
    name: "Piedras Negras International Airport",
    elevation_ft: "901",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-COA",
    gps_code: "MMPG",
    iata_code: "PDS",
    local_code: "PNG",
    coordinates: "-100.535004, 28.627399",
  },
  {
    ident: "MMPL",
    type: "small_airport",
    name: "Punta Colorada Airport",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "La Ribera",
    gps_code: "MMPL",
    iata_code: "PCO",
    local_code: "PCL",
    coordinates: "-109.535826, 23.575011",
  },
  {
    ident: "MMPN",
    type: "medium_airport",
    name: "Licenciado y General Ignacio Lopez Rayon Airport",
    elevation_ft: "5258",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-MIC",
    gps_code: "MMPN",
    iata_code: "UPN",
    coordinates: "-102.03900146484375, 19.396699905395508",
  },
  {
    ident: "MMPQ",
    type: "small_airport",
    name: "Palenque International Airport",
    elevation_ft: "200",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CHP",
    gps_code: "MMPQ",
    iata_code: "PQM",
    coordinates: "-92.015484, 17.533153",
  },
  {
    ident: "MMPR",
    type: "large_airport",
    name: "Licenciado Gustavo D����az Ordaz International Airport",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-JAL",
    municipality: "Puerto Vallarta",
    gps_code: "MMPR",
    iata_code: "PVR",
    coordinates: "-105.25399780273438, 20.680099487304688",
  },
  {
    ident: "MMPS",
    type: "medium_airport",
    name: "Puerto Escondido International Airport",
    elevation_ft: "294",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-OAX",
    municipality: "Puerto Escondido",
    gps_code: "MMPS",
    iata_code: "PXM",
    local_code: "P1M",
    coordinates: "-97.089103, 15.8769",
  },
  {
    ident: "MMQT",
    type: "medium_airport",
    name: "Quer����taro Intercontinental Airport",
    elevation_ft: "6296",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-QUE",
    municipality: "Quer����taro",
    gps_code: "MMQT",
    iata_code: "QRO",
    local_code: "QET",
    coordinates: "-100.185997, 20.6173",
  },
  {
    ident: "MMRX",
    type: "medium_airport",
    name: "General Lucio Blanco International Airport",
    elevation_ft: "139",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-TAM",
    municipality: "Reynosa",
    gps_code: "MMRX",
    iata_code: "REX",
    local_code: "REI",
    coordinates: "-98.2285, 26.0089",
  },
  {
    ident: "MMSC",
    type: "closed",
    name: "San Cristobal de las Casas Airport",
    elevation_ft: "7707",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CHP",
    municipality: "San Cristobal de las Casas",
    gps_code: "MMSC",
    iata_code: "SZT",
    coordinates: "-92.530097961426, 16.690299987793",
  },
  {
    ident: "MMSD",
    type: "large_airport",
    name: "Los Cabos International Airport",
    elevation_ft: "374",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "San Jos���� del Cabo",
    gps_code: "MMSD",
    iata_code: "SJD",
    coordinates: "-109.72100067138672, 23.15180015563965",
  },
  {
    ident: "MMSF",
    type: "small_airport",
    name: "San Felipe International Airport",
    elevation_ft: "148",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCN",
    municipality: "Mexicali",
    gps_code: "MMSF",
    iata_code: "SFH",
    local_code: "SFE",
    coordinates: "-114.80899810791, 30.930200576782",
  },
  {
    ident: "MMSM",
    type: "small_airport",
    name: "Santa Lucia Air Force Base",
    elevation_ft: "7369",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-MEX",
    municipality: "Reyes Acozac",
    gps_code: "MMSM",
    iata_code: "NLU",
    coordinates: "-99.01640319820001, 19.755300521900004",
  },
  {
    ident: "MMSP",
    type: "medium_airport",
    name: "Ponciano Arriaga International Airport",
    elevation_ft: "6035",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SLP",
    municipality: "San Luis Potos����",
    gps_code: "MMSP",
    iata_code: "SLP",
    coordinates: "-100.930999756, 22.254299163800003",
  },
  {
    ident: "MMTC",
    type: "medium_airport",
    name: "Francisco Sarabia International Airport",
    elevation_ft: "3688",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-COA",
    municipality: "Torre����n",
    gps_code: "MMTC",
    iata_code: "TRC",
    coordinates: "-103.411003113, 25.568300247199996",
  },
  {
    ident: "MMTG",
    type: "medium_airport",
    name: "Angel Albino Corzo International Airport",
    elevation_ft: "1499",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CHP",
    municipality: "Tuxtla Guti����rrez",
    gps_code: "MMTG",
    iata_code: "TGZ",
    local_code: "TGZ",
    coordinates: "-93.02249908450001, 16.5636005402",
  },
  {
    ident: "MMTJ",
    type: "large_airport",
    name: "General Abelardo L. Rodr����guez International Airport",
    elevation_ft: "489",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCN",
    municipality: "Tijuana",
    gps_code: "MMTJ",
    iata_code: "TIJ",
    coordinates: "-116.97000122070312, 32.541099548339844",
  },
  {
    ident: "MMTM",
    type: "medium_airport",
    name: "General Francisco Javier Mina International Airport",
    elevation_ft: "80",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-TAM",
    municipality: "Tampico",
    gps_code: "MMTM",
    iata_code: "TAM",
    coordinates: "-97.8658981323, 22.2964000702",
  },
  {
    ident: "MMTN",
    type: "small_airport",
    name: "Tamuin Airport",
    elevation_ft: "164",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SLP",
    gps_code: "MMTN",
    iata_code: "TSL",
    local_code: "TMN",
    coordinates: "-98.806502, 22.0383",
  },
  {
    ident: "MMTO",
    type: "medium_airport",
    name: "Licenciado Adolfo Lopez Mateos International Airport",
    elevation_ft: "8466",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-MEX",
    municipality: "Toluca",
    gps_code: "MMTO",
    iata_code: "TLC",
    coordinates: "-99.56600189210002, 19.3370990753",
  },
  {
    ident: "MMTP",
    type: "medium_airport",
    name: "Tapachula International Airport",
    elevation_ft: "97",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-CHP",
    municipality: "Tapachula",
    gps_code: "MMTP",
    iata_code: "TAP",
    coordinates: "-92.3700027466, 14.7943000793",
  },
  {
    ident: "MMUN",
    type: "large_airport",
    name: "Canc����n International Airport",
    elevation_ft: "22",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-ROO",
    municipality: "Canc����n",
    gps_code: "MMUN",
    iata_code: "CUN",
    coordinates: "-86.8770980835, 21.036500930800003",
  },
  {
    ident: "MMV",
    type: "small_airport",
    name: "Mal Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Mal Island",
    gps_code: "AYMM",
    iata_code: "MMV",
    local_code: "MAL",
    coordinates: "144.171111, -1.394444",
  },
  {
    ident: "MMVA",
    type: "medium_airport",
    name: "Carlos Rovirosa P����rez International Airport",
    elevation_ft: "46",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-TAB",
    municipality: "Villahermosa",
    gps_code: "MMVA",
    iata_code: "VSA",
    coordinates: "-92.81739807128906, 17.996999740600586",
  },
  {
    ident: "MMVR",
    type: "medium_airport",
    name: "General Heriberto Jara International Airport",
    elevation_ft: "90",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-VER",
    municipality: "Veracruz",
    gps_code: "MMVR",
    iata_code: "VER",
    coordinates: "-96.1873016357, 19.1459007263",
  },
  {
    ident: "MMZC",
    type: "medium_airport",
    name: "General Leobardo C. Ruiz International Airport",
    elevation_ft: "7141",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-ZAC",
    municipality: "Zacatecas",
    gps_code: "MMZC",
    iata_code: "ZCL",
    coordinates: "-102.68699646, 22.8971004486",
  },
  {
    ident: "MMZH",
    type: "medium_airport",
    name: "Ixtapa Zihuatanejo International Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-GRO",
    municipality: "Ixtapa",
    gps_code: "MMZH",
    iata_code: "ZIH",
    coordinates: "-101.460998535, 17.601600647",
  },
  {
    ident: "MMZM",
    type: "small_airport",
    name: "Zamora Airport",
    elevation_ft: "5141",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-MIC",
    gps_code: "MMZM",
    iata_code: "ZMM",
    local_code: "ZAM",
    coordinates: "-102.276001, 20.045",
  },
  {
    ident: "MMZO",
    type: "medium_airport",
    name: "Playa De Oro International Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-COL",
    municipality: "Manzanillo",
    gps_code: "MMZO",
    iata_code: "ZLO",
    coordinates: "-104.558998108, 19.144800186199998",
  },
  {
    ident: "MN-MXW",
    type: "small_airport",
    name: "Mandalgobi Airport",
    elevation_ft: "4550",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-059",
    municipality: "Mandalgobi",
    gps_code: "ZMMG",
    iata_code: "MXW",
    coordinates: "106.268997192, 45.7380981445",
  },
  {
    ident: "MNBL",
    type: "medium_airport",
    name: "Bluefields Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-AS",
    municipality: "Bluefileds",
    gps_code: "MNBL",
    iata_code: "BEF",
    coordinates: "-83.77410125732422, 11.991000175476074",
  },
  {
    ident: "MNBZ",
    type: "small_airport",
    name: "San Pedro Airport",
    elevation_ft: "600",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-AN",
    municipality: "Bonanza",
    gps_code: "MNBZ",
    iata_code: "BZA",
    coordinates: "-84.624311, 14.031524",
  },
  {
    ident: "MNCE",
    type: "small_airport",
    name: "Costa Esmeralda Airport",
    elevation_ft: "84",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-RI",
    municipality: "Tola",
    gps_code: "MNCE",
    iata_code: "ECI",
    local_code: "ECI",
    coordinates: "-86.033361, 11.427542",
  },
  {
    ident: "MNCI",
    type: "small_airport",
    name: "Corn Island",
    elevation_ft: "1",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-AS",
    municipality: "Corn Island",
    gps_code: "MNCI",
    iata_code: "RNI",
    coordinates: "-83.06379699707031, 12.1628999710083",
  },
  {
    ident: "MNMG",
    type: "medium_airport",
    name: "Augusto C. Sandino (Managua) International Airport",
    elevation_ft: "194",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-MN",
    municipality: "Managua",
    gps_code: "MNMG",
    iata_code: "MGA",
    coordinates: "-86.16819763183594, 12.141500473022461",
  },
  {
    ident: "MNNG",
    type: "small_airport",
    name: "Nueva Guinea Airport",
    elevation_ft: "606",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-AS",
    municipality: "Nueva Guinea",
    gps_code: "MNNG",
    iata_code: "NVG",
    coordinates: "-84.44999694824219, 11.666666984558105",
  },
  {
    ident: "MNP",
    type: "closed",
    name: "Maron Island Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MRL",
    municipality: "Hermit Islands",
    iata_code: "MNP",
    coordinates: "145.0168, -1.5504",
  },
  {
    ident: "MNPC",
    type: "medium_airport",
    name: "Puerto Cabezas Airport",
    elevation_ft: "52",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-AN",
    municipality: "Puerto Cabezas",
    gps_code: "MNPC",
    iata_code: "PUZ",
    coordinates: "-83.38670349121094, 14.047200202941895",
  },
  {
    ident: "MNRT",
    type: "small_airport",
    name: "Rosita Airport",
    elevation_ft: "193",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-AN",
    municipality: "La Rosita",
    gps_code: "MNRT",
    iata_code: "RFS",
    coordinates: "-84.40889739990234, 13.889699935913086",
  },
  {
    ident: "MNSC",
    type: "small_airport",
    name: "San Carlos",
    elevation_ft: "91",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-SJ",
    municipality: "San Carlos",
    gps_code: "MNSC",
    iata_code: "NCR",
    coordinates: "-84.7699966430664, 11.133399963378906",
  },
  {
    ident: "MNSI",
    type: "small_airport",
    name: "Siuna",
    elevation_ft: "606",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-AN",
    municipality: "Siuna",
    gps_code: "MNSI",
    iata_code: "SIU",
    coordinates: "-84.77777862548828, 13.727222442626953",
  },
  {
    ident: "MNWP",
    type: "small_airport",
    name: "Waspam Airport",
    elevation_ft: "98",
    continent: "NA",
    iso_country: "NI",
    iso_region: "NI-AN",
    municipality: "Waspam",
    gps_code: "MNWP",
    iata_code: "WSP",
    coordinates: "-83.96939849853516, 14.7391996383667",
  },
  {
    ident: "MOH",
    type: "small_airport",
    name: "Maleo Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-ST",
    municipality: "Morowali",
    iata_code: "MOH",
    local_code: "MOH",
    coordinates: "121.660278, -2.203333",
  },
  {
    ident: "MP00",
    type: "small_airport",
    name: "Capt Justiniano Montenegro Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-7",
    municipality: "Pedasi",
    gps_code: "MP00",
    iata_code: "PDM",
    local_code: "MP00",
    coordinates: "-80.023300170898, 7.5568799972534",
  },
  {
    ident: "MPBO",
    type: "medium_airport",
    name: "Bocas Del Toro International Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-1",
    municipality: "Isla Col����n",
    gps_code: "MPBO",
    iata_code: "BOC",
    coordinates: "-82.25080108642578, 9.340849876403809",
  },
  {
    ident: "MPCE",
    type: "medium_airport",
    name: "Alonso Valderrama Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-6",
    municipality: "Chitr����",
    gps_code: "MPCE",
    iata_code: "CTD",
    coordinates: "-80.40969848632812, 7.987840175628662",
  },
  {
    ident: "MPCH",
    type: "medium_airport",
    name: "Cap Manuel Ni����o International Airport",
    elevation_ft: "19",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-1",
    municipality: "Changuinola",
    gps_code: "MPCH",
    iata_code: "CHX",
    coordinates: "-82.515062, 9.458962",
  },
  {
    ident: "MPDA",
    type: "medium_airport",
    name: "Enrique Malek International Airport",
    elevation_ft: "89",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-4",
    municipality: "David",
    gps_code: "MPDA",
    iata_code: "DAV",
    coordinates: "-82.43499755859375, 8.390999794006348",
  },
  {
    ident: "MPEJ",
    type: "medium_airport",
    name: "Enrique Adolfo Jimenez Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-3",
    municipality: "Col����n",
    gps_code: "MPEJ",
    iata_code: "ONX",
    coordinates: "-79.86740112304688, 9.356639862060547",
  },
  {
    ident: "MPG",
    type: "small_airport",
    name: "Makini Airport",
    elevation_ft: "2530",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Makini",
    gps_code: "AYMJ",
    iata_code: "MPG",
    local_code: "MAK",
    coordinates: "147.651166667, -6.532222222220001",
  },
  {
    ident: "MPHO",
    type: "small_airport",
    name: "Panama Pacific International Airport",
    elevation_ft: "52",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-10",
    municipality: "Panam���� City",
    gps_code: "MPPA",
    iata_code: "BLB",
    coordinates: "-79.599602, 8.91479",
  },
  {
    ident: "MPI",
    type: "small_airport",
    name: "Mamitupo Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Mamitupo",
    iata_code: "MPI",
    coordinates: "-77.9841, 9.1851",
  },
  {
    ident: "MPJE",
    type: "small_airport",
    name: "Jaqu���� Airport",
    elevation_ft: "29",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-5",
    municipality: "Jaqu����",
    gps_code: "MPJE",
    iata_code: "JQE",
    coordinates: "-78.1572036743164, 7.51777982711792",
  },
  {
    ident: "MPLP",
    type: "closed",
    name: "Captain Ramon Xatruch Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-5",
    municipality: "La Palma",
    gps_code: "MPLP",
    iata_code: "PLP",
    coordinates: "-78.141701, 8.40667",
  },
  {
    ident: "MPMG",
    type: "medium_airport",
    name: "Marcos A. Gelabert International Airport",
    elevation_ft: "31",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-8",
    municipality: "Albrook",
    gps_code: "MPMG",
    iata_code: "PAC",
    coordinates: "-79.55560302734375, 8.973340034484863",
  },
  {
    ident: "MPOA",
    type: "small_airport",
    name: "Puerto Obaldia Airport",
    elevation_ft: "223",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Puerto Obaldia",
    gps_code: "MPOA",
    iata_code: "PUE",
    coordinates: "-77.418, 8.667",
  },
  {
    ident: "MPRH",
    type: "small_airport",
    name: "Scarlett Martinez International Airport",
    elevation_ft: "105",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-2",
    municipality: "R����o Hato",
    gps_code: "MPSM",
    iata_code: "RIH",
    coordinates: "-80.127899169922, 8.375880241394",
  },
  {
    ident: "MPSA",
    type: "medium_airport",
    name: "Ruben Cantu Airport",
    elevation_ft: "272",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-9",
    municipality: "Santiago",
    gps_code: "MPSA",
    iata_code: "SYP",
    coordinates: "-80.94529724121094, 8.085599899291992",
  },
  {
    ident: "MPTO",
    type: "large_airport",
    name: "Tocumen International Airport",
    elevation_ft: "135",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-8",
    municipality: "Tocumen",
    gps_code: "MPTO",
    iata_code: "PTY",
    coordinates: "-79.3834991455, 9.0713596344",
  },
  {
    ident: "MPU",
    type: "small_airport",
    name: "Mapua(Mabua) Airport",
    elevation_ft: "40",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Tatau Island",
    gps_code: "AYMZ",
    iata_code: "MPU",
    local_code: "MBU",
    coordinates: "151.991111111, -2.81138888889",
  },
  {
    ident: "MPVR",
    type: "small_airport",
    name: "El Porvenir Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "El Porvenir",
    gps_code: "MPVR",
    iata_code: "PVE",
    coordinates: "-78.947, 9.558",
  },
  {
    ident: "MPWN",
    type: "small_airport",
    name: "San Blas Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Wannukandi",
    gps_code: "MPWN",
    iata_code: "NBL",
    coordinates: "-78.97949981689453, 9.449600219726562",
  },
  {
    ident: "MPX",
    type: "small_airport",
    name: "Miyanmin Airport",
    elevation_ft: "2500",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Miyanmin",
    gps_code: "AYIY",
    iata_code: "MPX",
    local_code: "MIM",
    coordinates: "141.620833333, -4.903055555560001",
  },
  {
    ident: "MQO",
    type: "small_airport",
    name: "Malam Airport",
    elevation_ft: "126",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Malam",
    gps_code: "AYMQ",
    iata_code: "MQO",
    local_code: "MAA",
    coordinates: "142.649722222, -8.70916666667",
  },
  {
    ident: "MRAN",
    type: "small_airport",
    name: "Arenal Airport",
    elevation_ft: "342",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-A",
    municipality: "La Fortuna/San Carlos",
    gps_code: "MRAN",
    iata_code: "FON",
    coordinates: "-84.634499, 10.478",
  },
  {
    ident: "MRAO",
    type: "medium_airport",
    name: "Aerotortuguero Airport",
    elevation_ft: "92",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-L",
    municipality: "Roxana",
    gps_code: "MRAO",
    iata_code: "TTQ",
    coordinates: "-83.6095, 10.42",
  },
  {
    ident: "MRBA",
    type: "medium_airport",
    name: "Buenos Aires Airport",
    elevation_ft: "1214",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Punta Arenas",
    gps_code: "MRBA",
    iata_code: "BAI",
    coordinates: "-83.330171, 9.163949",
  },
  {
    ident: "MRBC",
    type: "medium_airport",
    name: "Barra del Colorado Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-L",
    municipality: "Pococi",
    gps_code: "MRBC",
    iata_code: "BCL",
    coordinates: "-83.58560180664062, 10.768699645996094",
  },
  {
    ident: "MRCC",
    type: "medium_airport",
    name: "Coto 47 Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Corredores",
    gps_code: "MRCC",
    iata_code: "OTR",
    coordinates: "-82.96859741210938, 8.60155963897705",
  },
  {
    ident: "MRCH",
    type: "small_airport",
    name: "Chacarita Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Puntarenas",
    gps_code: "MRCH",
    iata_code: "JAP",
    coordinates: "-84.7726974487, 9.98141002655",
  },
  {
    ident: "MRCR",
    type: "small_airport",
    name: "Playa Samara/Carrillo Airport",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-G",
    municipality: "Carrillo",
    gps_code: "MRCR",
    iata_code: "PLD",
    coordinates: "-85.481399536133, 9.8705101013184",
  },
  {
    ident: "MRDK",
    type: "small_airport",
    name: "Drake Bay Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Puntarenas",
    gps_code: "MRDK",
    iata_code: "DRK",
    coordinates: "-83.6417007446, 8.71889019012",
  },
  {
    ident: "MRFL",
    type: "small_airport",
    name: "Flamingo Airport",
    elevation_ft: "270",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-G",
    municipality: "Brasilito",
    gps_code: "MRFL",
    iata_code: "FMG",
    coordinates: "-85.78269958496094, 10.418600082397461",
  },
  {
    ident: "MRGF",
    type: "medium_airport",
    name: "Golfito Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Golfito",
    gps_code: "MRGF",
    iata_code: "GLF",
    coordinates: "-83.18219757080078, 8.654009819030762",
  },
  {
    ident: "MRGP",
    type: "medium_airport",
    name: "Guapiles Airport",
    elevation_ft: "883",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-L",
    municipality: "Pococi",
    gps_code: "MRGP",
    iata_code: "GPL",
    coordinates: "-83.79699707030001, 10.2172002792",
  },
  {
    ident: "MRIA",
    type: "small_airport",
    name: "Islita Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-G",
    municipality: "Nandayure",
    gps_code: "MRIA",
    iata_code: "PBP",
    coordinates: "-85.37079620361328, 9.856109619140625",
  },
  {
    ident: "MRLB",
    type: "large_airport",
    name: "Daniel Oduber Quiros International Airport",
    elevation_ft: "270",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-G",
    municipality: "Liberia",
    gps_code: "MRLB",
    iata_code: "LIR",
    coordinates: "-85.544403, 10.5933",
  },
  {
    ident: "MRLC",
    type: "medium_airport",
    name: "Los Chiles Airport",
    elevation_ft: "131",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-A",
    municipality: "Los Chiles",
    gps_code: "MRLC",
    iata_code: "LSL",
    coordinates: "-84.70610046386719, 11.035300254821777",
  },
  {
    ident: "MRLM",
    type: "medium_airport",
    name: "Limon International Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-L",
    municipality: "Puerto Limon",
    gps_code: "MRLM",
    iata_code: "LIO",
    coordinates: "-83.02200317382812, 9.95796012878418",
  },
  {
    ident: "MRMJ",
    type: "small_airport",
    name: "Mojica Airport",
    elevation_ft: "87",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-G",
    municipality: "Ca����as",
    gps_code: "MRMJ",
    iata_code: "CSC",
    coordinates: "-85.17459869380001, 10.4307003021",
  },
  {
    ident: "MRNC",
    type: "small_airport",
    name: "Guanacaste Airport",
    elevation_ft: "365",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-G",
    municipality: "Nicoya/Guanacate",
    gps_code: "MRNC",
    iata_code: "NCT",
    coordinates: "-85.44580078125, 10.139399528503418",
  },
  {
    ident: "MRNS",
    type: "medium_airport",
    name: "Nosara Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-G",
    municipality: "Nicoya",
    gps_code: "MRNS",
    iata_code: "NOB",
    coordinates: "-85.65299987790002, 9.976490020750001",
  },
  {
    ident: "MROC",
    type: "medium_airport",
    name: "Juan Santamaria International Airport",
    elevation_ft: "3021",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-A",
    municipality: "San Jose",
    gps_code: "MROC",
    iata_code: "SJO",
    coordinates: "-84.20880126953125, 9.993860244750977",
  },
  {
    ident: "MRPJ",
    type: "medium_airport",
    name: "Puerto Jimenez Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Puerto Jimenez",
    gps_code: "MRPJ",
    iata_code: "PJM",
    coordinates: "-83.30000305175781, 8.533329963684082",
  },
  {
    ident: "MRPM",
    type: "medium_airport",
    name: "Palmar Sur Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Palmar Sur",
    gps_code: "MRPM",
    iata_code: "PMZ",
    coordinates: "-83.46859741210938, 8.951029777526855",
  },
  {
    ident: "MRPV",
    type: "medium_airport",
    name: "Tobias Bolanos International Airport",
    elevation_ft: "3287",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-SJ",
    municipality: "San Jose",
    gps_code: "MRPV",
    iata_code: "SYQ",
    coordinates: "-84.13980102539062, 9.957050323486328",
  },
  {
    ident: "MRQP",
    type: "medium_airport",
    name: "Quepos Managua Airport",
    elevation_ft: "85",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Quepos",
    gps_code: "MRQP",
    iata_code: "XQP",
    coordinates: "-84.12979888916016, 9.443160057067871",
  },
  {
    ident: "MRRF",
    type: "small_airport",
    name: "Rio Frio / Progreso Airport",
    elevation_ft: "350",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-H",
    municipality: "Rio Frio / Progreso",
    gps_code: "MRRF",
    iata_code: "RFR",
    coordinates: "-83.88760375976562, 10.327400207519531",
  },
  {
    ident: "MRSI",
    type: "small_airport",
    name: "San Isidro del General Airport",
    elevation_ft: "2100",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-SJ",
    municipality: "P����rez Zeled����n",
    gps_code: "MRSI",
    iata_code: "IPZ",
    coordinates: "-83.713097, 9.35262",
  },
  {
    ident: "MRSV",
    type: "medium_airport",
    name: "San Vito De Java Airport",
    elevation_ft: "3228",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Coto Brus",
    gps_code: "MRSV",
    iata_code: "TOO",
    coordinates: "-82.95890045166016, 8.826109886169434",
  },
  {
    ident: "MRT",
    type: "small_airport",
    name: "Moroak Airport",
    elevation_ft: "227",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Moroak",
    iata_code: "MRT",
    coordinates: "133.700594, -14.818133",
  },
  {
    ident: "MRTM",
    type: "small_airport",
    name: "Tamarindo Airport",
    elevation_ft: "41",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-G",
    municipality: "Tamarindo",
    gps_code: "MRTM",
    iata_code: "TNO",
    coordinates: "-85.815498352051, 10.313500404358",
  },
  {
    ident: "MRTR",
    type: "small_airport",
    name: "Tambor Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-P",
    municipality: "Nicoya",
    gps_code: "MRTR",
    iata_code: "TMU",
    coordinates: "-85.013802, 9.73852",
  },
  {
    ident: "MRUP",
    type: "medium_airport",
    name: "Upala Airport",
    elevation_ft: "184",
    continent: "NA",
    iso_country: "CR",
    iso_region: "CR-A",
    municipality: "Upala",
    gps_code: "MRUP",
    iata_code: "UPL",
    coordinates: "-85.016197, 10.8922",
  },
  {
    ident: "MSB",
    type: "seaplane_base",
    name: "Marigot Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "MF",
    iso_region: "MF-U-A",
    municipality: "Saint Martin",
    iata_code: "MSB",
    coordinates: "-63.087, 18.0696",
  },
  {
    ident: "MSLP",
    type: "large_airport",
    name: "Monse����or ����scar Arnulfo Romero International Airport",
    elevation_ft: "101",
    continent: "NA",
    iso_country: "SV",
    iso_region: "SV-PA",
    municipality: "San Salvador (San Luis Talpa)",
    gps_code: "MSLP",
    iata_code: "SAL",
    coordinates: "-89.055702, 13.4409",
  },
  {
    ident: "MTCA",
    type: "medium_airport",
    name: "Les Cayes Airport",
    elevation_ft: "203",
    continent: "NA",
    iso_country: "HT",
    iso_region: "HT-SD",
    municipality: "Les Cayes",
    gps_code: "MTCA",
    iata_code: "CYA",
    coordinates: "-73.78829956054688, 18.271099090576172",
  },
  {
    ident: "MTCH",
    type: "medium_airport",
    name: "Cap Haitien International Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "HT",
    iso_region: "HT-ND",
    municipality: "Cap Haitien",
    gps_code: "MTCH",
    iata_code: "CAP",
    coordinates: "-72.194702, 19.733",
  },
  {
    ident: "MTF",
    type: "small_airport",
    name: "Metro Field",
    elevation_ft: "432",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Fairbanks",
    gps_code: "MTF",
    iata_code: "MTX",
    local_code: "MTF",
    coordinates: "-147.761993408, 64.80680084229999",
  },
  {
    ident: "MTJA",
    type: "medium_airport",
    name: "Jacmel Airport",
    elevation_ft: "167",
    continent: "NA",
    iso_country: "HT",
    iso_region: "HT-SE",
    municipality: "Jacmel",
    gps_code: "MTJA",
    iata_code: "JAK",
    coordinates: "-72.51850128173828, 18.241100311279297",
  },
  {
    ident: "MTJE",
    type: "medium_airport",
    name: "J����r����mie Airport",
    elevation_ft: "147",
    continent: "NA",
    iso_country: "HT",
    iso_region: "HT-GA",
    municipality: "Jeremie",
    gps_code: "MTJE",
    iata_code: "JEE",
    coordinates: "-74.17030334472656, 18.66309928894043",
  },
  {
    ident: "MTPP",
    type: "medium_airport",
    name: "Toussaint Louverture International Airport",
    elevation_ft: "122",
    continent: "NA",
    iso_country: "HT",
    iso_region: "HT-OU",
    municipality: "Port-au-Prince",
    gps_code: "MTPP",
    iata_code: "PAP",
    coordinates: "-72.2925033569336, 18.579999923706055",
  },
  {
    ident: "MTPX",
    type: "medium_airport",
    name: "Port-de-Paix Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "HT",
    iso_region: "HT-NO",
    municipality: "Port-de-Paix",
    gps_code: "MTPX",
    iata_code: "PAX",
    coordinates: "-72.84860229492188, 19.9335994720459",
  },
  {
    ident: "MTU",
    type: "small_airport",
    name: "Montepuez Airport",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-P",
    municipality: "Montepuez",
    iata_code: "MTU",
    coordinates: "39.052799224853516, -13.121899604797363",
  },
  {
    ident: "MUBA",
    type: "medium_airport",
    name: "Gustavo Rizo Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-14",
    municipality: "Baracoa",
    gps_code: "MUBA",
    iata_code: "BCA",
    coordinates: "-74.5062026977539, 20.365299224853516",
  },
  {
    ident: "MUBR",
    type: "small_airport",
    name: "Las Brujas Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-07",
    municipality: "Cayo Santa Maria",
    gps_code: "MUBR",
    iata_code: "BWW",
    coordinates: "-79.1472015381, 22.621299743699996",
  },
  {
    ident: "MUBY",
    type: "medium_airport",
    name: "Carlos Manuel de Cespedes Airport",
    elevation_ft: "203",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-12",
    municipality: "Bayamo",
    gps_code: "MUBY",
    iata_code: "BYM",
    coordinates: "-76.62139892578125, 20.396400451660156",
  },
  {
    ident: "MUCA",
    type: "medium_airport",
    name: "Maximo Gomez Airport",
    elevation_ft: "335",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-08",
    municipality: "Ciego de Avila",
    gps_code: "MUCA",
    iata_code: "AVI",
    coordinates: "-78.78959655761719, 22.027099609375",
  },
  {
    ident: "MUCC",
    type: "medium_airport",
    name: "Jardines Del Rey Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-08",
    municipality: "Cayo Coco",
    gps_code: "MUCC",
    iata_code: "CCC",
    coordinates: "-78.32839965820001, 22.461000442499998",
  },
  {
    ident: "MUCF",
    type: "medium_airport",
    name: "Jaime Gonzalez Airport",
    elevation_ft: "102",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-06",
    municipality: "Cienfuegos",
    gps_code: "MUCF",
    iata_code: "CFG",
    coordinates: "-80.41419982910156, 22.149999618530273",
  },
  {
    ident: "MUCL",
    type: "medium_airport",
    name: "Vilo Acu����a International Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-99",
    municipality: "Cayo Largo del Sur",
    gps_code: "MUCL",
    iata_code: "CYO",
    coordinates: "-81.5459976196, 21.6165008545",
  },
  {
    ident: "MUCM",
    type: "medium_airport",
    name: "Ignacio Agramonte International Airport",
    elevation_ft: "413",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-09",
    municipality: "Camaguey",
    gps_code: "MUCM",
    iata_code: "CMW",
    coordinates: "-77.84750366210938, 21.420299530029297",
  },
  {
    ident: "MUCO",
    type: "small_airport",
    name: "Col����n Airport",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-04",
    municipality: "Col����n",
    gps_code: "MUCO",
    iata_code: "QCO",
    coordinates: "-80.92279815670001, 22.7110996246",
  },
  {
    ident: "MUCU",
    type: "medium_airport",
    name: "Antonio Maceo International Airport",
    elevation_ft: "249",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-13",
    municipality: "Santiago",
    gps_code: "MUCU",
    iata_code: "SCU",
    coordinates: "-75.83540344238281, 19.96980094909668",
  },
  {
    ident: "MUGM",
    type: "medium_airport",
    name: "Leeward Point Field",
    elevation_ft: "56",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-14",
    municipality: "Guantanamo Bay Naval Station",
    gps_code: "MUGM",
    iata_code: "NBW",
    coordinates: "-75.207099914551, 19.906499862671",
  },
  {
    ident: "MUGT",
    type: "medium_airport",
    name: "Mariana Grajales Airport",
    elevation_ft: "56",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-14",
    municipality: "Guant����namo",
    gps_code: "MUGT",
    iata_code: "GAO",
    coordinates: "-75.1583023071289, 20.08530044555664",
  },
  {
    ident: "MUHA",
    type: "large_airport",
    name: "Jos���� Mart���� International Airport",
    elevation_ft: "210",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-03",
    municipality: "Havana",
    gps_code: "MUHA",
    iata_code: "HAV",
    coordinates: "-82.40910339355469, 22.989200592041016",
  },
  {
    ident: "MUHG",
    type: "medium_airport",
    name: "Frank Pais International Airport",
    elevation_ft: "361",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-11",
    municipality: "Holguin",
    gps_code: "MUHG",
    iata_code: "HOG",
    coordinates: "-76.31510162353516, 20.785600662231445",
  },
  {
    ident: "MUKW",
    type: "medium_airport",
    name: "Kawama Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-04",
    municipality: "Varadero",
    gps_code: "MUKW",
    iata_code: "VRO",
    coordinates: "-81.301598, 23.124001",
  },
  {
    ident: "MULM",
    type: "medium_airport",
    name: "La Coloma Airport",
    elevation_ft: "131",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-01",
    municipality: "Pinar del Rio",
    gps_code: "MULM",
    iata_code: "LCL",
    coordinates: "-83.64189910888672, 22.33609962463379",
  },
  {
    ident: "MUMA",
    type: "small_airport",
    name: "Punta de Maisi Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-14",
    municipality: "Maisi",
    gps_code: "MUMA",
    iata_code: "UMA",
    coordinates: "-74.1504974365, 20.250600814800002",
  },
  {
    ident: "MUMJ",
    type: "small_airport",
    name: "Mayajigua Airport",
    elevation_ft: "173",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-07",
    municipality: "Mayajigua",
    gps_code: "MUMJ",
    iata_code: "MJG",
    coordinates: "-79.06220245361328, 22.23080062866211",
  },
  {
    ident: "MUMO",
    type: "medium_airport",
    name: "Orestes Acosta Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-11",
    municipality: "Moa",
    gps_code: "MUMO",
    iata_code: "MOA",
    coordinates: "-74.92220306396484, 20.653900146484375",
  },
  {
    ident: "MUMZ",
    type: "medium_airport",
    name: "Sierra Maestra Airport",
    elevation_ft: "112",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-12",
    municipality: "Manzanillo",
    gps_code: "MUMZ",
    iata_code: "MZO",
    coordinates: "-77.08920288085938, 20.28809928894043",
  },
  {
    ident: "MUNB",
    type: "medium_airport",
    name: "San Nicolas De Bari Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-02",
    municipality: "San Nicol����s",
    gps_code: "MUNB",
    iata_code: "QSN",
    coordinates: "-81.9208984375, 22.756099700927734",
  },
  {
    ident: "MUNC",
    type: "small_airport",
    name: "Nicaro Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-11",
    municipality: "Nicaro",
    gps_code: "MUNC",
    iata_code: "ICR",
    coordinates: "-75.53150177001953, 20.688600540161133",
  },
  {
    ident: "MUNG",
    type: "medium_airport",
    name: "Rafael Cabrera Airport",
    elevation_ft: "79",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-99",
    municipality: "Nueva Gerona",
    gps_code: "MUNG",
    iata_code: "GER",
    coordinates: "-82.78379821777344, 21.834699630737305",
  },
  {
    ident: "MUPB",
    type: "medium_airport",
    name: "Playa Baracoa Airport",
    elevation_ft: "102",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-02",
    municipality: "Havana",
    gps_code: "MUPB",
    iata_code: "UPB",
    coordinates: "-82.5793991089, 23.032800674399997",
  },
  {
    ident: "MUPR",
    type: "medium_airport",
    name: "Pinar Del Rio Airport",
    elevation_ft: "131",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-01",
    municipality: "Pinar del Rio",
    gps_code: "MUPR",
    iata_code: "QPD",
    coordinates: "-83.67839813232422, 22.42140007019043",
  },
  {
    ident: "MUSC",
    type: "medium_airport",
    name: "Abel Santamaria Airport",
    elevation_ft: "338",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-05",
    municipality: "Santa Clara",
    gps_code: "MUSC",
    iata_code: "SNU",
    coordinates: "-79.943603515625, 22.49220085144043",
  },
  {
    ident: "MUSJ",
    type: "medium_airport",
    name: "San Julian Air Base",
    elevation_ft: "98",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-01",
    municipality: "Pinar Del Rio",
    gps_code: "MUSJ",
    iata_code: "SNJ",
    coordinates: "-84.1520004272461, 22.095300674438477",
  },
  {
    ident: "MUSN",
    type: "medium_airport",
    name: "Siguanea Airport",
    elevation_ft: "39",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-99",
    municipality: "Isla de la Juventud",
    gps_code: "MUSN",
    iata_code: "SZJ",
    coordinates: "-82.9551010131836, 21.642499923706055",
  },
  {
    ident: "MUSS",
    type: "small_airport",
    name: "Sancti Spiritus Airport",
    elevation_ft: "295",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-07",
    municipality: "Sancti Spiritus",
    gps_code: "MUSS",
    iata_code: "USS",
    coordinates: "-79.442703, 21.9704",
  },
  {
    ident: "MUTD",
    type: "medium_airport",
    name: "Alberto Delgado Airport",
    elevation_ft: "125",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-07",
    municipality: "Trinidad",
    gps_code: "MUTD",
    iata_code: "TND",
    coordinates: "-79.99720001220703, 21.788299560546875",
  },
  {
    ident: "MUVR",
    type: "large_airport",
    name: "Juan Gualberto Gomez International Airport",
    elevation_ft: "210",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-04",
    municipality: "Varadero",
    gps_code: "MUVR",
    iata_code: "VRA",
    coordinates: "-81.435302734375, 23.034400939941406",
  },
  {
    ident: "MUVT",
    type: "medium_airport",
    name: "Hermanos Ameijeiras Airport",
    elevation_ft: "328",
    continent: "NA",
    iso_country: "CU",
    iso_region: "CU-10",
    municipality: "Las Tunas",
    gps_code: "MUVT",
    iata_code: "VTU",
    coordinates: "-76.93579864501953, 20.987600326538086",
  },
  {
    ident: "MVI",
    type: "closed",
    name: "Manetai Airport",
    elevation_ft: "170",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Manetai",
    iata_code: "MVI",
    coordinates: "155.39, -6.12",
  },
  {
    ident: "MWCB",
    type: "medium_airport",
    name: "Gerrard Smith International Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "KY",
    iso_region: "KY-U-A",
    municipality: "Cayman Brac",
    gps_code: "MWCB",
    iata_code: "CYB",
    coordinates: "-79.88279724121094, 19.687000274658203",
  },
  {
    ident: "MWCL",
    type: "medium_airport",
    name: "Edward Bodden Airfield",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "KY",
    iso_region: "KY-U-A",
    municipality: "Little Cayman",
    gps_code: "MWCL",
    iata_code: "LYB",
    coordinates: "-80.088826, 19.660161",
  },
  {
    ident: "MWCR",
    type: "large_airport",
    name: "Owen Roberts International Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "KY",
    iso_region: "KY-U-A",
    municipality: "Georgetown",
    gps_code: "MWCR",
    iata_code: "GCM",
    coordinates: "-81.3576965332, 19.292800903299998",
  },
  {
    ident: "MWR",
    type: "small_airport",
    name: "Motswari Airport",
    elevation_ft: "1160",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Motswari Private Game Reserve",
    iata_code: "MWR",
    coordinates: "31.3864, -24.1903",
  },
  {
    ident: "MX-AJS",
    type: "small_airport",
    name: "Abreojos Airport",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "Abreojos",
    iata_code: "AJS",
    coordinates: "-113.55899810791016, 26.72719955444336",
  },
  {
    ident: "MX-AZG",
    type: "small_airport",
    name: "Pablo L. Sidar Airport",
    elevation_ft: "1033",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-MIC",
    municipality: "Apatzing����n",
    gps_code: "MMAG",
    iata_code: "AZG",
    local_code: "AZG",
    coordinates: "-102.393997, 19.093399",
  },
  {
    ident: "MX-PCM",
    type: "small_airport",
    name: "Playa del Carmen Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-ROO",
    municipality: "Solidaridad",
    iata_code: "PCM",
    local_code: "PCE",
    coordinates: "-87.08219909668, 20.622499465942",
  },
  {
    ident: "MX-PCV",
    type: "small_airport",
    name: "Punta Chivato Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "Punta Chivato",
    iata_code: "PCV",
    local_code: "PCH",
    coordinates: "-111.96199798584, 27.069200515747",
  },
  {
    ident: "MX-SCX",
    type: "small_airport",
    name: "Salina Cruz Naval Air Station",
    elevation_ft: "75",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-OAX",
    municipality: "Salina Cruz",
    gps_code: "MMSZ",
    iata_code: "SCX",
    local_code: "MM57",
    coordinates: "-95.20159912110002, 16.212600708",
  },
  {
    ident: "MX-SGM",
    type: "small_airport",
    name: "San Ignacio Airport",
    elevation_ft: "800",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "San Ignacio",
    iata_code: "SGM",
    local_code: "SIY",
    coordinates: "-112.93800354, 27.2966003418",
  },
  {
    ident: "MX-TUY",
    type: "small_airport",
    name: "Tulum Naval Air Station",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-ROO",
    municipality: "Tulum",
    gps_code: "MMTU",
    iata_code: "TUY",
    local_code: "TUY",
    coordinates: "-87.438203, 20.227301",
  },
  {
    ident: "MX-UAC",
    type: "small_airport",
    name: "San Luis R����o Colorado Airport",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SON",
    municipality: "San Luis R����o Colorado",
    iata_code: "UAC",
    local_code: "SLR",
    coordinates: "-114.797996521, 32.445301055908",
  },
  {
    ident: "MX-XAL",
    type: "small_airport",
    name: "����lamos Airport",
    elevation_ft: "1312",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-SON",
    municipality: "����lamos",
    iata_code: "XAL",
    local_code: "ALA",
    coordinates: "-108.947998, 27.035701",
  },
  {
    ident: "MXC",
    type: "small_airport",
    name: "Monticello Airport",
    elevation_ft: "6966",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Monticello",
    iata_code: "MXC",
    local_code: "U64",
    coordinates: "-109.341225, 37.93243",
  },
  {
    ident: "MXK",
    type: "small_airport",
    name: "Mindik Airport",
    elevation_ft: "4200",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Mindik",
    gps_code: "AYMI",
    iata_code: "MXK",
    local_code: "MIK",
    coordinates: "147.441138889, -6.47166666667",
  },
  {
    ident: "MXR",
    type: "small_airport",
    name: "Moussoro Airport",
    elevation_ft: "984",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-KA",
    municipality: "Moussoro",
    iata_code: "MXR",
    coordinates: "16.501386, 13.645663",
  },
  {
    ident: "MY-GTB",
    type: "closed",
    name: "Genting Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Genting",
    iata_code: "GTB",
    coordinates: "111.699996948, 2.11666703224",
  },
  {
    ident: "MY-GTK",
    type: "small_airport",
    name: "Sungei Tekai Airport",
    elevation_ft: "160",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-01",
    municipality: "Sungei Tekai",
    iata_code: "GTK",
    coordinates: "102.91666412353516, 2.5999999046325684",
  },
  {
    ident: "MY-LBP",
    type: "small_airport",
    name: "Long Banga Airport",
    elevation_ft: "750",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Long Banga",
    iata_code: "LBP",
    coordinates: "115.4018, 3.202",
  },
  {
    ident: "MY-LLM",
    type: "small_airport",
    name: "Long Lama Airport",
    elevation_ft: "286",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Long Lama",
    iata_code: "LLM",
    coordinates: "114.46666717529297, 3.766669988632202",
  },
  {
    ident: "MY-MZS",
    type: "small_airport",
    name: "Mostyn Airport",
    elevation_ft: "200",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Mostyn",
    iata_code: "MZS",
    coordinates: "118.1500015258789, 4.616666793823242",
  },
  {
    ident: "MY-SPT",
    type: "small_airport",
    name: "Sipitang Airport",
    elevation_ft: "44",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Sipitang",
    iata_code: "SPT",
    coordinates: "115.55000305175781, 5.0833330154418945",
  },
  {
    ident: "MYAB",
    type: "medium_airport",
    name: "Clarence A. Bain Airport",
    elevation_ft: "19",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-KB",
    municipality: "Mangrove Cay",
    gps_code: "MYAB",
    iata_code: "MAY",
    coordinates: "-77.68460083007812, 24.287700653076172",
  },
  {
    ident: "MYAF",
    type: "medium_airport",
    name: "Andros Town Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-NB",
    gps_code: "MYAF",
    iata_code: "ASD",
    coordinates: "-77.79560089111328, 24.697900772094727",
  },
  {
    ident: "MYAK",
    type: "medium_airport",
    name: "Congo Town Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-KB",
    municipality: "Andros",
    gps_code: "MYAK",
    iata_code: "TZN",
    local_code: "COX",
    coordinates: "-77.589798, 24.158701",
  },
  {
    ident: "MYAM",
    type: "medium_airport",
    name: "Leonard M Thompson International Airport",
    elevation_ft: "6",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-MH",
    municipality: "Marsh Harbour",
    gps_code: "MYAM",
    iata_code: "MHH",
    coordinates: "-77.083503, 26.5114",
  },
  {
    ident: "MYAN",
    type: "medium_airport",
    name: "San Andros Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-NB",
    municipality: "Andros Island",
    gps_code: "MYAN",
    iata_code: "SAQ",
    coordinates: "-78.04900360107422, 25.053800582885742",
  },
  {
    ident: "MYAP",
    type: "medium_airport",
    name: "Spring Point Airport",
    elevation_ft: "11",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-AC",
    municipality: "Spring Point",
    gps_code: "MYAP",
    iata_code: "AXP",
    coordinates: "-73.97090148930002, 22.441799163800003",
  },
  {
    ident: "MYAT",
    type: "medium_airport",
    name: "Treasure Cay Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-MH",
    municipality: "Treasure Cay",
    gps_code: "MYAT",
    iata_code: "TCB",
    coordinates: "-77.3912963867, 26.745300293",
  },
  {
    ident: "MYAW",
    type: "small_airport",
    name: "Abaco I Walker C Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-GT",
    gps_code: "MYAW",
    iata_code: "WKR",
    coordinates: "-78.39969635009766, 27.266700744628906",
  },
  {
    ident: "MYBC",
    type: "medium_airport",
    name: "Chub Cay Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-NB",
    gps_code: "MYBC",
    iata_code: "CCZ",
    coordinates: "-77.88089752197266, 25.41710090637207",
  },
  {
    ident: "MYBG",
    type: "medium_airport",
    name: "Great Harbour Cay Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-NB",
    municipality: "Bullocks Harbour",
    gps_code: "MYBG",
    iata_code: "GHC",
    coordinates: "-77.840103, 25.7383",
  },
  {
    ident: "MYBS",
    type: "medium_airport",
    name: "South Bimini Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-BI",
    municipality: "South Bimini",
    gps_code: "MYBS",
    iata_code: "BIM",
    coordinates: "-79.2647018433, 25.6998996735",
  },
  {
    ident: "MYCA",
    type: "medium_airport",
    name: "Arthur's Town Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-CI",
    municipality: "Arthur's Town",
    gps_code: "MYCA",
    iata_code: "ATC",
    coordinates: "-75.673797, 24.6294",
  },
  {
    ident: "MYCB",
    type: "medium_airport",
    name: "New Bight Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-CI",
    municipality: "Cat Island",
    gps_code: "MYCB",
    iata_code: "TBI",
    coordinates: "-75.452301, 24.3153",
  },
  {
    ident: "MYCC",
    type: "small_airport",
    name: "Cat Cay Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-BI",
    municipality: "North Cat Cay",
    gps_code: "MYCC",
    iata_code: "CXY",
    coordinates: "-79.275151, 25.554555",
  },
  {
    ident: "MYCI",
    type: "medium_airport",
    name: "Colonel Hill Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-AC",
    municipality: "Colonel Hill",
    gps_code: "MYCI",
    iata_code: "CRI",
    coordinates: "-74.1824035645, 22.745599746699998",
  },
  {
    ident: "MYCP",
    type: "small_airport",
    name: "Pitts Town Airport",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-AC",
    municipality: "Pitts Town",
    gps_code: "MYCP",
    iata_code: "PWN",
    coordinates: "-74.34609985351562, 22.829700469970703",
  },
  {
    ident: "MYEF",
    type: "medium_airport",
    name: "Exuma International Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-EX",
    municipality: "George Town",
    gps_code: "MYEF",
    iata_code: "GGT",
    coordinates: "-75.8779983521, 23.5625991821",
  },
  {
    ident: "MYEH",
    type: "medium_airport",
    name: "North Eleuthera Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-HI",
    municipality: "North Eleuthera",
    gps_code: "MYEH",
    iata_code: "ELH",
    coordinates: "-76.6835021973, 25.474899292",
  },
  {
    ident: "MYEM",
    type: "medium_airport",
    name: "Governor's Harbour Airport",
    elevation_ft: "26",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-GH",
    municipality: "Governor's Harbour",
    gps_code: "MYEM",
    iata_code: "GHB",
    coordinates: "-76.3310012817, 25.2847003937",
  },
  {
    ident: "MYEN",
    type: "medium_airport",
    name: "Normans Cay Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-EX",
    gps_code: "MYEN",
    iata_code: "NMC",
    coordinates: "-76.82019805908203, 24.59429931640625",
  },
  {
    ident: "MYER",
    type: "medium_airport",
    name: "Rock Sound Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-RS",
    municipality: "Rock Sound",
    gps_code: "MYER",
    iata_code: "RSD",
    coordinates: "-76.1768817902, 24.8950787333",
  },
  {
    ident: "MYES",
    type: "medium_airport",
    name: "Staniel Cay Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-EX",
    gps_code: "MYES",
    iata_code: "TYM",
    coordinates: "-76.43910217285156, 24.169099807739258",
  },
  {
    ident: "MYGF",
    type: "medium_airport",
    name: "Grand Bahama International Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-FP",
    municipality: "Freeport",
    gps_code: "MYGF",
    iata_code: "FPO",
    coordinates: "-78.695602417, 26.5587005615",
  },
  {
    ident: "MYGM",
    type: "small_airport",
    name: "Auxiliary Airfield",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-HR",
    municipality: "Grand Bahama",
    gps_code: "MYGM",
    iata_code: "GBI",
    coordinates: "-78.3591995239, 26.6319007874",
  },
  {
    ident: "MYGW",
    type: "small_airport",
    name: "West End Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-FP",
    municipality: "West End",
    gps_code: "MYGW",
    iata_code: "WTD",
    coordinates: "-78.97499847410002, 26.685300827",
  },
  {
    ident: "MYIG",
    type: "medium_airport",
    name: "Inagua Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-IN",
    municipality: "Matthew Town",
    gps_code: "MYIG",
    iata_code: "IGA",
    coordinates: "-73.66690063476562, 20.975000381469727",
  },
  {
    ident: "MYK",
    type: "small_airport",
    name: "May Creek Airport",
    elevation_ft: "1650",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "May Creek",
    gps_code: "MYK",
    iata_code: "MYK",
    local_code: "MYK",
    coordinates: "-142.68699646, 61.3357009888",
  },
  {
    ident: "MYLD",
    type: "medium_airport",
    name: "Deadman's Cay Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-LI",
    municipality: "Deadman's Cay",
    gps_code: "MYLD",
    iata_code: "LGI",
    coordinates: "-75.09359741210001, 23.1790008545",
  },
  {
    ident: "MYLS",
    type: "medium_airport",
    name: "Stella Maris Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-LI",
    municipality: "Stella Maris",
    gps_code: "MYLS",
    iata_code: "SML",
    coordinates: "-75.268621, 23.582317",
  },
  {
    ident: "MYMM",
    type: "medium_airport",
    name: "Mayaguana Airport",
    elevation_ft: "11",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-MG",
    municipality: "Abrahams Bay",
    gps_code: "MYMM",
    iata_code: "MYG",
    coordinates: "-73.013494, 22.379499",
  },
  {
    ident: "MYNN",
    type: "large_airport",
    name: "Lynden Pindling International Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-NP",
    municipality: "Nassau",
    gps_code: "MYNN",
    iata_code: "NAS",
    coordinates: "-77.466202, 25.039",
  },
  {
    ident: "MYPI",
    type: "closed",
    name: "Nassau Paradise Island Airport",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-NP",
    municipality: "Nassau",
    gps_code: "MYPI",
    iata_code: "PID",
    coordinates: "-77.300003, 25.083",
  },
  {
    ident: "MYRD",
    type: "medium_airport",
    name: "Duncan Town Airport",
    elevation_ft: "6",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-RI",
    gps_code: "MYRD",
    iata_code: "DCT",
    coordinates: "-75.72949981689453, 22.181800842285156",
  },
  {
    ident: "MYRP",
    type: "small_airport",
    name: "Rum Cay Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-SR",
    gps_code: "MYRP",
    iata_code: "RCY",
    coordinates: "-74.83619689941406, 23.68440055847168",
  },
  {
    ident: "MYS",
    type: "small_airport",
    name: "Moyale Airport",
    elevation_ft: "3887",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-OR",
    municipality: "Moyale",
    iata_code: "MYS",
    coordinates: "39.0433, 3.5623",
  },
  {
    ident: "MYSM",
    type: "medium_airport",
    name: "San Salvador Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-SR",
    municipality: "San Salvador",
    gps_code: "MYSM",
    iata_code: "ZSA",
    coordinates: "-74.52400207519531, 24.06329917907715",
  },
  {
    ident: "MYX",
    type: "small_airport",
    name: "Menyamya Airport",
    elevation_ft: "3880",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Menyamya",
    gps_code: "AYMC",
    iata_code: "MYX",
    local_code: "MYY",
    coordinates: "146.019361111, -7.21166666667",
  },
  {
    ident: "MYZ2",
    type: "closed",
    name: "Cape Eleuthera Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "BS",
    iso_region: "BS-RS",
    municipality: "Cape Eleuthera",
    gps_code: "MYEC",
    iata_code: "CEL",
    coordinates: "-76.2962, 24.7861",
  },
  {
    ident: "MZ-0004",
    type: "closed",
    name: "Magaruque Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-I",
    municipality: "Magaruque Island",
    iata_code: "MFW",
    coordinates: "35.4247283936, -21.9672451019",
  },
  {
    ident: "MZ-0005",
    type: "small_airport",
    name: "Paradise Island Airport",
    elevation_ft: "30",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-I",
    municipality: "Santa Carolina",
    iata_code: "NTC",
    coordinates: "35.338001251221, -21.614999771118",
  },
  {
    ident: "MZ-0012",
    type: "small_airport",
    name: "Ibo Airport",
    elevation_ft: "27",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-P",
    municipality: "Ibo",
    gps_code: "FQIB",
    iata_code: "IBO",
    coordinates: "40.602297, -12.350174",
  },
  {
    ident: "MZ-TGS",
    type: "small_airport",
    name: "Chokw���� Airport",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-G",
    municipality: "Chokw����",
    iata_code: "TGS",
    coordinates: "32.965301513671875, -24.520599365234375",
  },
  {
    ident: "MZBZ",
    type: "large_airport",
    name: "Philip S. W. Goldson International Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-BZ",
    municipality: "Belize City",
    gps_code: "MZBZ",
    iata_code: "BZE",
    coordinates: "-88.30819702148438, 17.539100646972656",
  },
  {
    ident: "MZE",
    type: "small_airport",
    name: "Manatee Airport",
    elevation_ft: "343",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-CY",
    iata_code: "MZE",
    coordinates: "-89.0238, 17.27846",
  },
  {
    ident: "MZMF",
    type: "small_airport",
    name: "San Ignacio Town (Maya Flats) Airstrip",
    elevation_ft: "351",
    continent: "NA",
    iso_country: "BZ",
    iso_region: "BZ-CY",
    municipality: "Maya Flats",
    gps_code: "MZMF",
    iata_code: "CYD",
    coordinates: "-89.101129, 17.104872",
  },
  {
    ident: "N04",
    type: "closed",
    name: "Griswold Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CT",
    municipality: "Madison",
    gps_code: "N04",
    iata_code: "MPE",
    local_code: "N04",
    coordinates: "-72.549697876, 41.2711982727",
  },
  {
    ident: "N20",
    type: "small_airport",
    name: "Ine Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-ARN",
    municipality: "Arno Atoll",
    iata_code: "IMI",
    local_code: "N20",
    coordinates: "171.656959, 7.005553",
  },
  {
    ident: "NA-0012",
    type: "small_airport",
    name: "Bagani Airport",
    elevation_ft: "3314",
    continent: "AF",
    iso_country: "NA",
    iso_region: "NA-KE",
    municipality: "Bagani",
    gps_code: "FYBG",
    iata_code: "BQI",
    coordinates: "21.6243991852, -18.1180992126",
  },
  {
    ident: "NBS",
    type: "medium_airport",
    name: "Changbaishan Airport",
    elevation_ft: "2874",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-22",
    municipality: "Baishan",
    gps_code: "ZYBS",
    iata_code: "NBS",
    coordinates: "127.602222, 42.066944",
  },
  {
    ident: "NCAI",
    type: "small_airport",
    name: "Aitutaki Airport",
    elevation_ft: "14",
    continent: "OC",
    iso_country: "CK",
    iso_region: "CK-U-A",
    municipality: "Aitutaki",
    gps_code: "NCAI",
    iata_code: "AIT",
    coordinates: "-159.76400756835938, -18.830900192260742",
  },
  {
    ident: "NCAT",
    type: "small_airport",
    name: "Enua Airport",
    elevation_ft: "36",
    continent: "OC",
    iso_country: "CK",
    iso_region: "CK-U-A",
    municipality: "Atiu Island",
    gps_code: "NCAT",
    iata_code: "AIU",
    coordinates: "-158.11900329589844, -19.96780014038086",
  },
  {
    ident: "NCMG",
    type: "small_airport",
    name: "Mangaia Island Airport",
    elevation_ft: "45",
    continent: "OC",
    iso_country: "CK",
    iso_region: "CK-U-A",
    municipality: "Mangaia Island",
    gps_code: "NCMG",
    iata_code: "MGS",
    coordinates: "-157.9066619873047, -21.895986557006836",
  },
  {
    ident: "NCMH",
    type: "small_airport",
    name: "Manihiki Island Airport",
    continent: "OC",
    iso_country: "CK",
    iso_region: "CK-U-A",
    municipality: "Manihiki Island",
    gps_code: "NCMH",
    iata_code: "MHX",
    coordinates: "-161.0019989013672, -10.376700401306152",
  },
  {
    ident: "NCMK",
    type: "small_airport",
    name: "Mauke Airport",
    elevation_ft: "26",
    continent: "OC",
    iso_country: "CK",
    iso_region: "CK-U-A",
    municipality: "Mauke Island",
    gps_code: "NCMK",
    iata_code: "MUK",
    coordinates: "-157.34500122070312, -20.13610076904297",
  },
  {
    ident: "NCMR",
    type: "small_airport",
    name: "Mitiaro Island Airport",
    elevation_ft: "25",
    continent: "OC",
    iso_country: "CK",
    iso_region: "CK-U-A",
    municipality: "Mitiaro Island",
    gps_code: "NCMR",
    iata_code: "MOI",
    coordinates: "-157.7030029296875, -19.842500686645508",
  },
  {
    ident: "NCPK",
    type: "small_airport",
    name: "Pukapuka Island Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "CK",
    iso_region: "CK-U-A",
    municipality: "Pukapuka Atoll",
    gps_code: "NCPK",
    iata_code: "PZK",
    coordinates: "-165.8393, -10.9145",
  },
  {
    ident: "NCPY",
    type: "small_airport",
    name: "Tongareva Airport",
    elevation_ft: "8",
    continent: "OC",
    iso_country: "CK",
    iso_region: "CK-U-A",
    municipality: "Penrhyn Island",
    gps_code: "NCPY",
    iata_code: "PYE",
    coordinates: "-158.03240966796875, -9.01436996459961",
  },
  {
    ident: "NCRG",
    type: "large_airport",
    name: "Rarotonga International Airport",
    elevation_ft: "19",
    continent: "OC",
    iso_country: "CK",
    iso_region: "CK-U-A",
    municipality: "Avarua",
    gps_code: "NCRG",
    iata_code: "RAR",
    coordinates: "-159.805999756, -21.2026996613",
  },
  {
    ident: "NDI",
    type: "small_airport",
    name: "Namudi Airport",
    elevation_ft: "1044",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Namudi",
    gps_code: "AYNJ",
    iata_code: "NDI",
    local_code: "NAM",
    coordinates: "148.329416667, -9.47375",
  },
  {
    ident: "NDN",
    type: "small_airport",
    name: "Nadunumu Airport",
    elevation_ft: "5100",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Nadunumu",
    gps_code: "AYNC",
    iata_code: "NDN",
    local_code: "NDM",
    coordinates: "147.68425, -9.14355555556",
  },
  {
    ident: "NE69",
    type: "small_airport",
    name: "Browns Airport",
    elevation_ft: "1183",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NE",
    municipality: "Weeping Water",
    gps_code: "NE69",
    iata_code: "EPG",
    local_code: "NE69",
    coordinates: "-96.1100006104, 40.8675003052",
  },
  {
    ident: "NFBG",
    type: "closed",
    name: "Biaugunu Airport",
    elevation_ft: "32",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-N",
    municipality: "Saqani, Cakaudrove",
    gps_code: "NFBG",
    iata_code: "AQS",
    coordinates: "179.740104675, -16.4493799329",
  },
  {
    ident: "NFCI",
    type: "small_airport",
    name: "Cicia Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Cicia",
    gps_code: "NFCI",
    iata_code: "ICI",
    coordinates: "-179.341995239, -17.7432994843",
  },
  {
    ident: "NFCS",
    type: "seaplane_base",
    name: "Castaway Island Seaplane Base",
    elevation_ft: "0",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Castaway Island",
    gps_code: "NFCS",
    iata_code: "CST",
    coordinates: "177.129, -17.7358",
  },
  {
    ident: "NFFN",
    type: "medium_airport",
    name: "Nadi International Airport",
    elevation_ft: "59",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Nadi",
    gps_code: "NFFN",
    iata_code: "NAN",
    coordinates: "177.4429931640625, -17.755399703979492",
  },
  {
    ident: "NFFO",
    type: "small_airport",
    name: "Malolo Lailai Island Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Malolo Lailai Island",
    gps_code: "NFFO",
    iata_code: "PTF",
    coordinates: "177.197006226, -17.7779006958",
  },
  {
    ident: "NFFR",
    type: "small_airport",
    name: "Rabi Island Airport",
    elevation_ft: "88",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-N",
    municipality: "Rabi Island",
    gps_code: "NFFR",
    iata_code: "RBI",
    coordinates: "179.9757, -16.5337",
  },
  {
    ident: "NFKD",
    type: "small_airport",
    name: "Vunisea Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Vunisea",
    gps_code: "NFKD",
    iata_code: "KDV",
    coordinates: "178.156997681, -19.058099746699998",
  },
  {
    ident: "NFMA",
    type: "small_airport",
    name: "Mana Island Airport",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Mana Island",
    gps_code: "NFMA",
    iata_code: "MNF",
    coordinates: "177.098007202, -17.6730995178",
  },
  {
    ident: "NFMO",
    type: "small_airport",
    name: "Moala Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Moala",
    gps_code: "NFMO",
    iata_code: "MFJ",
    coordinates: "179.951004028, -18.566699981699998",
  },
  {
    ident: "NFNA",
    type: "medium_airport",
    name: "Nausori International Airport",
    elevation_ft: "17",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-C",
    municipality: "Nausori",
    gps_code: "NFNA",
    iata_code: "SUV",
    coordinates: "178.5590057373047, -18.04330062866211",
  },
  {
    ident: "NFNB",
    type: "small_airport",
    name: "Levuka Airfield",
    elevation_ft: "11",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Bureta",
    gps_code: "NFNB",
    iata_code: "LEV",
    coordinates: "178.759002686, -17.7110996246",
  },
  {
    ident: "NFNG",
    type: "small_airport",
    name: "Ngau Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Ngau",
    gps_code: "NFNG",
    iata_code: "NGI",
    coordinates: "179.339996338, -18.115600585899998",
  },
  {
    ident: "NFNH",
    type: "small_airport",
    name: "Laucala Island Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-N",
    municipality: "Laucala Island",
    gps_code: "NFNH",
    iata_code: "LUC",
    coordinates: "-179.66700744628906, -16.74810028076172",
  },
  {
    ident: "NFNK",
    type: "small_airport",
    name: "Lakeba Island Airport",
    elevation_ft: "280",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Lakeba Island",
    gps_code: "NFNK",
    iata_code: "LKB",
    coordinates: "-178.817001343, -18.1991996765",
  },
  {
    ident: "NFNL",
    type: "medium_airport",
    name: "Labasa Airport",
    elevation_ft: "44",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-N",
    gps_code: "NFNL",
    iata_code: "LBS",
    coordinates: "179.33999633789062, -16.466699600219727",
  },
  {
    ident: "NFNM",
    type: "small_airport",
    name: "Matei Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-N",
    municipality: "Matei",
    gps_code: "NFNM",
    iata_code: "TVU",
    coordinates: "-179.876998901, -16.6905994415",
  },
  {
    ident: "NFNO",
    type: "small_airport",
    name: "Koro Island Airport",
    elevation_ft: "358",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Koro Island",
    gps_code: "NFNO",
    iata_code: "KXF",
    coordinates: "179.42199707, -17.3458003998",
  },
  {
    ident: "NFNR",
    type: "small_airport",
    name: "Rotuma Airport",
    elevation_ft: "22",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-R",
    municipality: "Rotuma",
    gps_code: "NFNR",
    iata_code: "RTA",
    coordinates: "177.0709991455078, -12.482500076293945",
  },
  {
    ident: "NFNS",
    type: "small_airport",
    name: "Savusavu Airport",
    elevation_ft: "17",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-N",
    municipality: "Savusavu",
    gps_code: "NFNS",
    iata_code: "SVU",
    coordinates: "179.341003418, -16.8027992249",
  },
  {
    ident: "NFNU",
    type: "closed",
    name: "Bui-Dama Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-N",
    municipality: "Bua",
    gps_code: "NFNU",
    iata_code: "BVF",
    coordinates: "178.6232, -16.8598",
  },
  {
    ident: "NFNV",
    type: "small_airport",
    name: "Vatukoula Airport",
    elevation_ft: "156",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Vatukoula",
    gps_code: "NFNV",
    iata_code: "VAU",
    coordinates: "177.8419952392578, -17.5",
  },
  {
    ident: "NFNW",
    type: "small_airport",
    name: "Wakaya Island Airport",
    elevation_ft: "130",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Wakaya Island",
    gps_code: "NFNW",
    iata_code: "KAY",
    coordinates: "179.01699829101562, -17.617000579833984",
  },
  {
    ident: "NFOL",
    type: "small_airport",
    name: "Ono-i-Lau Airport",
    elevation_ft: "110",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Ono-i-Lau",
    gps_code: "NFOL",
    iata_code: "ONU",
    coordinates: "-178.7411, -20.6589",
  },
  {
    ident: "NFSW",
    type: "small_airport",
    name: "Yasawa Island Airport",
    elevation_ft: "37",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Yasawa Island",
    gps_code: "NFSW",
    iata_code: "YAS",
    coordinates: "177.5449981689453, -16.758899688720703",
  },
  {
    ident: "NFTE",
    type: "small_airport",
    name: "Kaufana Airport",
    elevation_ft: "325",
    continent: "OC",
    iso_country: "TO",
    iso_region: "TO-01",
    municipality: "Eua Island",
    gps_code: "NFTE",
    iata_code: "EUA",
    coordinates: "-174.957992554, -21.378299713100002",
  },
  {
    ident: "NFTF",
    type: "medium_airport",
    name: "Fua'amotu International Airport",
    elevation_ft: "126",
    continent: "OC",
    iso_country: "TO",
    iso_region: "TO-04",
    municipality: "Nuku'alofa",
    gps_code: "NFTF",
    iata_code: "TBU",
    coordinates: "-175.14999389648438, -21.241199493408203",
  },
  {
    ident: "NFTL",
    type: "medium_airport",
    name: "Lifuka Island Airport",
    elevation_ft: "31",
    continent: "OC",
    iso_country: "TO",
    iso_region: "TO-02",
    municipality: "Lifuka",
    gps_code: "NFTL",
    iata_code: "HPA",
    coordinates: "-174.34100341796875, -19.777000427246094",
  },
  {
    ident: "NFTO",
    type: "small_airport",
    name: "Mata'aho Airport",
    elevation_ft: "160",
    continent: "OC",
    iso_country: "TO",
    iso_region: "TO-03",
    municipality: "Angaha, Niuafo'ou Island",
    gps_code: "NFTO",
    iata_code: "NFO",
    coordinates: "-175.632995605, -15.5707998276",
  },
  {
    ident: "NFTP",
    type: "small_airport",
    name: "Kuini Lavenia Airport",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "TO",
    iso_region: "TO-03",
    municipality: "Niuatoputapu",
    gps_code: "NFTP",
    iata_code: "NTT",
    coordinates: "-173.791089, -15.977297",
  },
  {
    ident: "NFTV",
    type: "medium_airport",
    name: "Vava'u International Airport",
    elevation_ft: "236",
    continent: "OC",
    iso_country: "TO",
    iso_region: "TO-05",
    municipality: "Vava'u Island",
    gps_code: "NFTV",
    iata_code: "VAV",
    coordinates: "-173.96200561523438, -18.58530044555664",
  },
  {
    ident: "NFUL",
    type: "seaplane_base",
    name: "Turtle Island Seaplane Base",
    elevation_ft: "0",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Nanuya Levu Island",
    gps_code: "NFUL",
    iata_code: "TTL",
    coordinates: "177.368, -16.966",
  },
  {
    ident: "NFVB",
    type: "small_airport",
    name: "Vanua Balavu Airport",
    elevation_ft: "76",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-E",
    municipality: "Vanua Balavu",
    gps_code: "NFVB",
    iata_code: "VBV",
    coordinates: "-178.9759979248047, -17.268999099731445",
  },
  {
    ident: "NFVL",
    type: "small_airport",
    name: "Vatulele Airport",
    elevation_ft: "36",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Vatulele",
    gps_code: "NFVL",
    iata_code: "VTF",
    coordinates: "177.63900756835938, -18.512500762939453",
  },
  {
    ident: "NG-0003",
    type: "medium_airport",
    name: "Gombe Lawanti International Airport",
    elevation_ft: "1590",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-GO",
    municipality: "Gombe",
    gps_code: "DNGO",
    iata_code: "GMO",
    coordinates: "10.896388888899999, 10.298333333299999",
  },
  {
    ident: "NG-0004",
    type: "small_airport",
    name: "Port Harcourt City Airport",
    elevation_ft: "57",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-RI",
    municipality: "Port Harcourt",
    iata_code: "PHG",
    coordinates: "7.021388888890001, 4.846111111110001",
  },
  {
    ident: "NG-QRW",
    type: "medium_airport",
    name: "Warri Airport",
    elevation_ft: "242",
    continent: "AF",
    iso_country: "NG",
    iso_region: "NG-DE",
    municipality: "Warri",
    gps_code: "DNSU",
    iata_code: "QRW",
    coordinates: "5.81778, 5.59611",
  },
  {
    ident: "NGAB",
    type: "small_airport",
    name: "Abaiang Airport",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Abaiang",
    gps_code: "NGAB",
    iata_code: "ABF",
    coordinates: "173.04100036621094, 1.798609972000122",
  },
  {
    ident: "NGBR",
    type: "small_airport",
    name: "Beru Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Beru",
    gps_code: "NGBR",
    iata_code: "BEZ",
    coordinates: "176.0070037841797, -1.3547199964523315",
  },
  {
    ident: "NGFU",
    type: "medium_airport",
    name: "Funafuti International Airport",
    elevation_ft: "9",
    continent: "OC",
    iso_country: "TV",
    iso_region: "TV-FUN",
    municipality: "Funafuti",
    gps_code: "NGFU",
    iata_code: "FUN",
    coordinates: "179.195999, -8.525",
  },
  {
    ident: "NGKT",
    type: "small_airport",
    name: "Kuria Airport",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Kuria",
    gps_code: "NGKT",
    iata_code: "KUC",
    coordinates: "173.44200134277344, 0.2186110019683838",
  },
  {
    ident: "NGMA",
    type: "small_airport",
    name: "Maiana Airport",
    elevation_ft: "8",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Maiana",
    gps_code: "NGMA",
    iata_code: "MNK",
    coordinates: "173.031005859375, 1.0036100149154663",
  },
  {
    ident: "NGMK",
    type: "small_airport",
    name: "Marakei Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Marakei",
    gps_code: "NGMK",
    iata_code: "MZK",
    coordinates: "173.27099609375, 2.058609962463379",
  },
  {
    ident: "NGMN",
    type: "small_airport",
    name: "Makin Island Airport",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Makin Island",
    gps_code: "NGMN",
    iata_code: "MTK",
    coordinates: "172.99200439453125, 3.3744399547576904",
  },
  {
    ident: "NGNU",
    type: "small_airport",
    name: "Nikunau Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Nikunau",
    gps_code: "NGNU",
    iata_code: "NIG",
    coordinates: "176.410003662, -1.31444001198",
  },
  {
    ident: "NGON",
    type: "small_airport",
    name: "Onotoa Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Onotoa",
    gps_code: "NGON",
    iata_code: "OOT",
    coordinates: "175.5260009765625, -1.7961100339889526",
  },
  {
    ident: "NGTA",
    type: "medium_airport",
    name: "Bonriki International Airport",
    elevation_ft: "9",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Tarawa",
    gps_code: "NGTA",
    iata_code: "TRW",
    coordinates: "173.14700317382812, 1.3816399574279785",
  },
  {
    ident: "NGTB",
    type: "small_airport",
    name: "Abemama Atoll Airport",
    elevation_ft: "8",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Abemama Atoll",
    gps_code: "NGTB",
    iata_code: "AEA",
    coordinates: "173.82899475097656, 0.49083301424980164",
  },
  {
    ident: "NGTE",
    type: "medium_airport",
    name: "Tabiteuea North Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    gps_code: "NGTE",
    iata_code: "TBF",
    coordinates: "174.7760009765625, -1.2244700193405151",
  },
  {
    ident: "NGTM",
    type: "small_airport",
    name: "Tamana Island Airport",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Tamana Island",
    gps_code: "NGTM",
    iata_code: "TMN",
    coordinates: "175.97000122070312, -2.485830068588257",
  },
  {
    ident: "NGTO",
    type: "small_airport",
    name: "Nonouti Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Nonouti",
    gps_code: "NGTO",
    iata_code: "NON",
    coordinates: "174.42799377441406, -0.6397219896316528",
  },
  {
    ident: "NGTR",
    type: "small_airport",
    name: "Arorae Island Airport",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Arorae Island",
    gps_code: "NGTR",
    iata_code: "AIS",
    coordinates: "176.80299377441406, -2.61611008644104",
  },
  {
    ident: "NGTS",
    type: "small_airport",
    name: "Tabiteuea South Airport",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Tabiteuea South",
    gps_code: "NGTS",
    iata_code: "TSU",
    coordinates: "175.06399536132812, -1.4744399785995483",
  },
  {
    ident: "NGTU",
    type: "small_airport",
    name: "Butaritari Atoll Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Butaritari Atoll",
    gps_code: "NGTU",
    iata_code: "BBG",
    coordinates: "172.811005, 3.08583",
  },
  {
    ident: "NGUK",
    type: "small_airport",
    name: "Aranuka Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-G",
    municipality: "Buariki",
    gps_code: "NGUK",
    iata_code: "AAK",
    coordinates: "173.636993, 0.185278",
  },
  {
    ident: "NIK",
    type: "small_airport",
    name: "Niokolo-Koba Airport",
    elevation_ft: "285",
    continent: "AF",
    iso_country: "SN",
    iso_region: "SN-TC",
    municipality: "Niokolo-Koba National Park",
    iata_code: "NIK",
    coordinates: "-12.727162, 13.052014",
  },
  {
    ident: "NIUE",
    type: "medium_airport",
    name: "Niue International Airport",
    elevation_ft: "209",
    continent: "OC",
    iso_country: "NU",
    iso_region: "NU-U-A",
    municipality: "Alofi",
    gps_code: "NIUE",
    iata_code: "IUE",
    coordinates: "-169.92559814453125, -19.079030990600586",
  },
  {
    ident: "NKD",
    type: "small_airport",
    name: "Sinak Airport",
    elevation_ft: "7334",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Sinak",
    iata_code: "NKD",
    local_code: "SIN",
    coordinates: "137.8406, -3.822",
  },
  {
    ident: "NLH",
    type: "medium_airport",
    name: "Ninglang Luguhu Airport",
    elevation_ft: "10804",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Ninglang",
    gps_code: "ZPNL",
    iata_code: "NLH",
    coordinates: "100.7593, 27.5403",
  },
  {
    ident: "NLWF",
    type: "medium_airport",
    name: "Pointe Vele Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "WF",
    iso_region: "WF-U-A",
    municipality: "Futuna Island",
    gps_code: "NLWF",
    iata_code: "FUT",
    coordinates: "-178.065994263, -14.3114004135",
  },
  {
    ident: "NLWW",
    type: "medium_airport",
    name: "Hihifo Airport",
    elevation_ft: "79",
    continent: "OC",
    iso_country: "WF",
    iso_region: "WF-U-A",
    municipality: "Wallis Island",
    gps_code: "NLWW",
    iata_code: "WLS",
    coordinates: "-176.199005127, -13.2383003235",
  },
  {
    ident: "NM83",
    type: "small_airport",
    name: "Industrial Airpark",
    elevation_ft: "3707",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NM",
    municipality: "Hobbs",
    gps_code: "NM83",
    iata_code: "HBB",
    local_code: "NM83",
    coordinates: "-103.209, 32.7668",
  },
  {
    ident: "NND",
    type: "small_airport",
    name: "Nangade Airport",
    elevation_ft: "825",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-P",
    municipality: "Nangade",
    iata_code: "NND",
    coordinates: "39.68, -11.073",
  },
  {
    ident: "NOM",
    type: "small_airport",
    name: "Nomad River Airport",
    elevation_ft: "305",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Nomad River",
    iata_code: "NOM",
    local_code: "NDR",
    coordinates: "142.234166667, -6.294",
  },
  {
    ident: "NOO",
    type: "small_airport",
    name: "Naoro Airport",
    elevation_ft: "2372",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Naoro Vilage",
    iata_code: "NOO",
    local_code: "NAO",
    coordinates: "147.621352, -9.254455",
  },
  {
    ident: "NP-MWP",
    type: "small_airport",
    name: "Mountain Airport",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-BA",
    municipality: "Mountain",
    iata_code: "MWP",
    coordinates: "85.33300018310547, 28",
  },
  {
    ident: "NPG",
    type: "small_airport",
    name: "Nipa Airport",
    elevation_ft: "5765",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SHM",
    municipality: "Nipa",
    iata_code: "NPG",
    coordinates: "143.4531, -6.1454",
  },
  {
    ident: "NRC",
    type: "closed",
    name: "NASA Crows Landing Airport",
    elevation_ft: "166",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Crows Landing",
    gps_code: "KNRC",
    iata_code: "NRC",
    local_code: "NRC",
    coordinates: "-121.10900115967, 37.408000946045",
  },
  {
    ident: "NRY",
    type: "small_airport",
    name: "Newry Airport",
    elevation_ft: "304",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Newry",
    iata_code: "NRY",
    coordinates: "129.2638, -16.0442",
  },
  {
    ident: "NSAS",
    type: "small_airport",
    name: "Ofu Village Airport",
    elevation_ft: "9",
    continent: "OC",
    iso_country: "AS",
    iso_region: "AS-U-A",
    municipality: "Ofu Village",
    gps_code: "NSAS",
    iata_code: "OFU",
    local_code: "Z08",
    coordinates: "-169.669998, -14.1844",
  },
  {
    ident: "NSAU",
    type: "small_airport",
    name: "Asau Airport",
    continent: "OC",
    iso_country: "WS",
    iso_region: "WS-VS",
    municipality: "Asau",
    gps_code: "NSAU",
    iata_code: "AAU",
    coordinates: "-172.627888, -13.505132",
  },
  {
    ident: "NSFA",
    type: "medium_airport",
    name: "Faleolo International Airport",
    elevation_ft: "58",
    continent: "OC",
    iso_country: "WS",
    iso_region: "WS-AA",
    municipality: "Apia",
    gps_code: "NSFA",
    iata_code: "APW",
    coordinates: "-172.00799560546875, -13.829999923706055",
  },
  {
    ident: "NSFI",
    type: "closed",
    name: "Fagali'i Airport",
    elevation_ft: "131",
    continent: "OC",
    iso_country: "WS",
    iso_region: "WS-TU",
    municipality: "Apia",
    gps_code: "NSFI",
    iata_code: "FGI",
    coordinates: "-171.740005, -13.8487",
  },
  {
    ident: "NSFQ",
    type: "medium_airport",
    name: "Fitiuta Airport",
    elevation_ft: "110",
    continent: "OC",
    iso_country: "AS",
    iso_region: "AS-U-A",
    municipality: "Fitiuta Village",
    gps_code: "NSFQ",
    iata_code: "FTI",
    local_code: "FAQ",
    coordinates: "-169.425003, -14.2172",
  },
  {
    ident: "NSMA",
    type: "small_airport",
    name: "Maota Airport",
    continent: "OC",
    iso_country: "WS",
    iso_region: "WS-PA",
    municipality: "Maota",
    gps_code: "NSMA",
    iata_code: "MXS",
    coordinates: "-172.25799560546875, -13.742300033569336",
  },
  {
    ident: "NSTU",
    type: "medium_airport",
    name: "Pago Pago International Airport",
    elevation_ft: "32",
    continent: "OC",
    iso_country: "AS",
    iso_region: "AS-U-A",
    municipality: "Pago Pago",
    gps_code: "NSTU",
    iata_code: "PPG",
    local_code: "PPG",
    coordinates: "-170.710006714, -14.3310003281",
  },
  {
    ident: "NTA",
    type: "closed",
    name: "Natadola Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "FJ",
    iso_region: "FJ-W",
    municipality: "Mbatiri",
    iata_code: "NTA",
    coordinates: "177.315, -18.0677",
  },
  {
    ident: "NTAA",
    type: "large_airport",
    name: "Faa'a International Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Papeete",
    gps_code: "NTAA",
    iata_code: "PPT",
    coordinates: "-149.606995, -17.553699",
  },
  {
    ident: "NTAM",
    type: "small_airport",
    name: "Rimatara Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Rimatara Island",
    gps_code: "NTAM",
    iata_code: "RMT",
    coordinates: "-152.8059, -22.63725",
  },
  {
    ident: "NTAR",
    type: "medium_airport",
    name: "Rurutu Airport",
    elevation_ft: "18",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTAR",
    iata_code: "RUR",
    coordinates: "-151.36099243164062, -22.434099197387695",
  },
  {
    ident: "NTAT",
    type: "medium_airport",
    name: "Tubuai Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTAT",
    iata_code: "TUB",
    coordinates: "-149.5240020751953, -23.365400314331055",
  },
  {
    ident: "NTAV",
    type: "small_airport",
    name: "Raivavae Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTAV",
    iata_code: "RVV",
    coordinates: "-147.662002563, -23.885200500499998",
  },
  {
    ident: "NTGA",
    type: "medium_airport",
    name: "Anaa Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGA",
    iata_code: "AAA",
    coordinates: "-145.50999450683594, -17.35260009765625",
  },
  {
    ident: "NTGB",
    type: "medium_airport",
    name: "Fangatau Airport",
    elevation_ft: "9",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGB",
    iata_code: "FGU",
    coordinates: "-140.88699340820312, -15.819899559020996",
  },
  {
    ident: "NTGC",
    type: "medium_airport",
    name: "Tikehau Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGC",
    iata_code: "TIH",
    coordinates: "-148.2310028076172, -15.119600296020508",
  },
  {
    ident: "NTGD",
    type: "small_airport",
    name: "Apataki Airport",
    elevation_ft: "8",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Apataki",
    gps_code: "NTGD",
    iata_code: "APK",
    coordinates: "-146.414993, -15.5736",
  },
  {
    ident: "NTGE",
    type: "medium_airport",
    name: "Reao Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGE",
    iata_code: "REA",
    coordinates: "-136.44000244140625, -18.465900421142578",
  },
  {
    ident: "NTGF",
    type: "medium_airport",
    name: "Fakarava Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGF",
    iata_code: "FAV",
    coordinates: "-145.65699768066406, -16.054100036621094",
  },
  {
    ident: "NTGH",
    type: "small_airport",
    name: "Hikueru Atoll Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Hikueru Atoll",
    gps_code: "NTGH",
    iata_code: "HHZ",
    coordinates: "-142.61399841308594, -17.544700622558594",
  },
  {
    ident: "NTGI",
    type: "medium_airport",
    name: "Manihi Airport",
    elevation_ft: "14",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGI",
    iata_code: "XMH",
    coordinates: "-146.07000732421875, -14.436800003051758",
  },
  {
    ident: "NTGJ",
    type: "medium_airport",
    name: "Totegegie Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGJ",
    iata_code: "GMR",
    coordinates: "-134.88999938964844, -23.07990074157715",
  },
  {
    ident: "NTGK",
    type: "medium_airport",
    name: "Kaukura Airport",
    elevation_ft: "11",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGK",
    iata_code: "KKR",
    coordinates: "-146.88499450683594, -15.663299560546875",
  },
  {
    ident: "NTGM",
    type: "medium_airport",
    name: "Makemo Airport",
    elevation_ft: "3",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGM",
    iata_code: "MKP",
    coordinates: "-143.6580047607422, -16.583900451660156",
  },
  {
    ident: "NTGN",
    type: "small_airport",
    name: "Napuka Island Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Napuka Island",
    gps_code: "NTGN",
    iata_code: "NAU",
    coordinates: "-141.26699829101562, -14.176799774169922",
  },
  {
    ident: "NTGO",
    type: "small_airport",
    name: "Tatakoto Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Tatakoto",
    gps_code: "NTGO",
    iata_code: "TKV",
    coordinates: "-138.44500732421875, -17.355300903320312",
  },
  {
    ident: "NTGP",
    type: "medium_airport",
    name: "Puka Puka Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGP",
    iata_code: "PKP",
    coordinates: "-138.81300354003906, -14.809499740600586",
  },
  {
    ident: "NTGQ",
    type: "small_airport",
    name: "Pukarua Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Pukarua",
    gps_code: "NTGQ",
    iata_code: "PUK",
    coordinates: "-137.01699829101562, -18.29560089111328",
  },
  {
    ident: "NTGT",
    type: "medium_airport",
    name: "Takapoto Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGT",
    iata_code: "TKP",
    coordinates: "-145.24600219726562, -14.709500312805176",
  },
  {
    ident: "NTGU",
    type: "medium_airport",
    name: "Arutua Airport",
    elevation_ft: "9",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGU",
    iata_code: "AXR",
    coordinates: "-146.61700439453125, -15.248299598693848",
  },
  {
    ident: "NTGV",
    type: "medium_airport",
    name: "Mataiva Airport",
    elevation_ft: "11",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGV",
    iata_code: "MVT",
    coordinates: "-148.7169952392578, -14.8681001663208",
  },
  {
    ident: "NTGW",
    type: "small_airport",
    name: "Nukutavake Airport",
    elevation_ft: "17",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Nukutavake",
    gps_code: "NTGW",
    iata_code: "NUK",
    coordinates: "-138.77200317382812, -19.28499984741211",
  },
  {
    ident: "NTGY",
    type: "small_airport",
    name: "Tureia Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTGY",
    iata_code: "ZTA",
    coordinates: "-138.57000732421875, -20.78969955444336",
  },
  {
    ident: "NTHE",
    type: "medium_airport",
    name: "Ahe Airport",
    elevation_ft: "11",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Ahe Atoll",
    gps_code: "NTHE",
    iata_code: "AHE",
    coordinates: "-146.2570037841797, -14.428099632263184",
  },
  {
    ident: "NTKA",
    type: "small_airport",
    name: "Kauehi Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Kauehi",
    gps_code: "NTKA",
    iata_code: "KHZ",
    coordinates: "-145.12399291992188, -15.780799865722656",
  },
  {
    ident: "NTKF",
    type: "small_airport",
    name: "Faaite Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTKF",
    iata_code: "FAC",
    coordinates: "-145.32899475097656, -16.68670082092285",
  },
  {
    ident: "NTKH",
    type: "small_airport",
    name: "Fakahina Airport",
    elevation_ft: "3",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Fakahina",
    gps_code: "NTKH",
    iata_code: "FHZ",
    coordinates: "-140.1649932861328, -15.992199897766113",
  },
  {
    ident: "NTKK",
    type: "small_airport",
    name: "Aratika Nord Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTKK",
    iata_code: "RKA",
    coordinates: "-145.470001221, -15.4853000641",
  },
  {
    ident: "NTKM",
    type: "small_airport",
    name: "Takume Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Takume",
    gps_code: "NTKM",
    iata_code: "TJN",
    coordinates: "-142.268005371, -15.854700088500001",
  },
  {
    ident: "NTKN",
    type: "small_airport",
    name: "Naiu Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Naiu Atoll",
    gps_code: "NTKN",
    iata_code: "NIU",
    coordinates: "-146.3683, -16.1191",
  },
  {
    ident: "NTKO",
    type: "small_airport",
    name: "Raroia Airport",
    elevation_ft: "18",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTKO",
    iata_code: "RRR",
    coordinates: "-142.476944, -16.045",
  },
  {
    ident: "NTKR",
    type: "medium_airport",
    name: "Takaroa Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTKR",
    iata_code: "TKX",
    coordinates: "-145.02499389648438, -14.45580005645752",
  },
  {
    ident: "NTKT",
    type: "small_airport",
    name: "Katiu Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Katiu",
    gps_code: "NTKT",
    iata_code: "KXU",
    coordinates: "-144.402999878, -16.3393993378",
  },
  {
    ident: "NTKU",
    type: "small_airport",
    name: "Nukutepipi Airport",
    elevation_ft: "32",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Nukutepipi",
    gps_code: "NTKU",
    iata_code: "NKP",
    coordinates: "-143.047, -20.7",
  },
  {
    ident: "NTMD",
    type: "medium_airport",
    name: "Nuku Hiva Airport",
    elevation_ft: "220",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTMD",
    iata_code: "NHV",
    coordinates: "-140.22900390625, -8.795599937438965",
  },
  {
    ident: "NTMN",
    type: "medium_airport",
    name: "Hiva Oa-Atuona Airport",
    elevation_ft: "1481",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Hiva Oa Island",
    gps_code: "NTMN",
    iata_code: "AUQ",
    coordinates: "-139.011001587, -9.76879024506",
  },
  {
    ident: "NTMP",
    type: "small_airport",
    name: "Ua Pou Airport",
    elevation_ft: "16",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Ua Pou",
    gps_code: "NTMP",
    iata_code: "UAP",
    coordinates: "-140.078003, -9.35167",
  },
  {
    ident: "NTMU",
    type: "small_airport",
    name: "Ua Huka Airport",
    elevation_ft: "160",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Ua Huka",
    gps_code: "NTMU",
    iata_code: "UAH",
    coordinates: "-139.552002, -8.93611",
  },
  {
    ident: "NTTB",
    type: "medium_airport",
    name: "Bora Bora Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Motu Mute",
    gps_code: "NTTB",
    iata_code: "BOB",
    coordinates: "-151.75100708007812, -16.444400787353516",
  },
  {
    ident: "NTTE",
    type: "small_airport",
    name: "Tetiaroa Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Tetiaroa",
    gps_code: "NTTE",
    iata_code: "TTI",
    coordinates: "-149.58700561523438, -17.0132999420166",
  },
  {
    ident: "NTTG",
    type: "medium_airport",
    name: "Rangiroa Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTTG",
    iata_code: "RGI",
    coordinates: "-147.66099548339844, -14.954299926757812",
  },
  {
    ident: "NTTH",
    type: "medium_airport",
    name: "Huahine-Fare Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Fare",
    gps_code: "NTTH",
    iata_code: "HUH",
    coordinates: "-151.02200317382812, -16.68720054626465",
  },
  {
    ident: "NTTM",
    type: "medium_airport",
    name: "Moorea Airport",
    elevation_ft: "9",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTTM",
    iata_code: "MOZ",
    coordinates: "-149.761993, -17.49",
  },
  {
    ident: "NTTO",
    type: "medium_airport",
    name: "Hao Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTTO",
    iata_code: "HOI",
    coordinates: "-140.9459991455078, -18.074800491333008",
  },
  {
    ident: "NTTP",
    type: "medium_airport",
    name: "Maupiti Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    gps_code: "NTTP",
    iata_code: "MAU",
    coordinates: "-152.24400329589844, -16.42650032043457",
  },
  {
    ident: "NTTR",
    type: "medium_airport",
    name: "Raiatea Airport",
    elevation_ft: "3",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Uturoa",
    gps_code: "NTTR",
    iata_code: "RFP",
    coordinates: "-151.466003, -16.7229",
  },
  {
    ident: "NTTU",
    type: "small_airport",
    name: "Tupai Airport",
    elevation_ft: "32",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Tupai Atoll",
    gps_code: "NTTU",
    iata_code: "TPX",
    coordinates: "-151.8338, -16.2423",
  },
  {
    ident: "NTTX",
    type: "small_airport",
    name: "Mururoa Atoll Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Mururoa Atoll",
    gps_code: "NTTX",
    iata_code: "UOA",
    coordinates: "-138.8202, -21.8586",
  },
  {
    ident: "NTUV",
    type: "small_airport",
    name: "Vahitahi Airport",
    elevation_ft: "9",
    continent: "OC",
    iso_country: "PF",
    iso_region: "PF-U-A",
    municipality: "Vahitahi",
    gps_code: "NTUV",
    iata_code: "VHZ",
    coordinates: "-138.85299682617188, -18.780000686645508",
  },
  {
    ident: "NUF",
    type: "seaplane_base",
    name: "Castlereagh Lake Seaplane Base",
    elevation_ft: "3580",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-2",
    municipality: "Hatton",
    iata_code: "NUF",
    coordinates: "80.584, 6.86",
  },
  {
    ident: "NUG",
    type: "small_airport",
    name: "Nuguria Airstrip",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Nuguria Island",
    gps_code: "AYNI",
    iata_code: "NUG",
    local_code: "NGA",
    coordinates: "154.7383, -3.4075",
  },
  {
    ident: "NV11",
    type: "small_airport",
    name: "Yucca Airstrip",
    elevation_ft: "3919",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NV",
    municipality: "Mercury",
    gps_code: "NV11",
    iata_code: "UCC",
    local_code: "NV11",
    coordinates: "-116.03800201416, 36.94580078125",
  },
  {
    ident: "NVSA",
    type: "small_airport",
    name: "Mota Lava Airport",
    elevation_ft: "63",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TOB",
    municipality: "Ablow",
    gps_code: "NVSA",
    iata_code: "MTV",
    coordinates: "167.712005615, -13.6660003662",
  },
  {
    ident: "NVSC",
    type: "small_airport",
    name: "Sola Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TOB",
    municipality: "Sola",
    gps_code: "NVSC",
    iata_code: "SLH",
    coordinates: "167.537002563, -13.8516998291",
  },
  {
    ident: "NVSD",
    type: "small_airport",
    name: "Torres Airstrip",
    elevation_ft: "75",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TOB",
    municipality: "Loh/Linua",
    gps_code: "NVSD",
    iata_code: "TOH",
    coordinates: "166.638000488, -13.3280000687",
  },
  {
    ident: "NVSE",
    type: "small_airport",
    name: "Siwo Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-SEE",
    municipality: "Emae Island",
    gps_code: "NVSE",
    iata_code: "EAE",
    coordinates: "168.343002319, -17.0902996063",
  },
  {
    ident: "NVSF",
    type: "small_airport",
    name: "Craig Cove Airport",
    elevation_ft: "69",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-MAP",
    municipality: "Craig Cove",
    gps_code: "NVSF",
    iata_code: "CCV",
    coordinates: "167.923996, -16.264999",
  },
  {
    ident: "NVSG",
    type: "small_airport",
    name: "Longana Airport",
    elevation_ft: "167",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-PAM",
    municipality: "Longana",
    gps_code: "NVSG",
    iata_code: "LOD",
    coordinates: "167.966995239, -15.3066997528",
  },
  {
    ident: "NVSH",
    type: "small_airport",
    name: "Sara Airport",
    elevation_ft: "493",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-PAM",
    municipality: "Pentecost Island",
    gps_code: "NVSH",
    iata_code: "SSR",
    coordinates: "168.151992798, -15.4708003998",
  },
  {
    ident: "NVSI",
    type: "small_airport",
    name: "Tavie Airport",
    elevation_ft: "160",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-MAP",
    municipality: "Paama Island",
    gps_code: "NVSI",
    iata_code: "PBJ",
    coordinates: "168.257003784, -16.438999176",
  },
  {
    ident: "NVSL",
    type: "small_airport",
    name: "Lamap Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-MAP",
    municipality: "Lamap",
    gps_code: "NVSL",
    iata_code: "LPM",
    coordinates: "167.829253, -16.4611228",
  },
  {
    ident: "NVSM",
    type: "small_airport",
    name: "Lamen Bay Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-SEE",
    municipality: "Lamen Bay",
    gps_code: "NVSM",
    iata_code: "LNB",
    coordinates: "168.158996582, -16.584199905400002",
  },
  {
    ident: "NVSN",
    type: "small_airport",
    name: "Maewo-Naone Airport",
    elevation_ft: "509",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-PAM",
    municipality: "Maewo Island",
    gps_code: "NVSN",
    iata_code: "MWF",
    coordinates: "168.082992554, -15",
  },
  {
    ident: "NVSO",
    type: "small_airport",
    name: "Lonorore Airport",
    elevation_ft: "43",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-PAM",
    municipality: "Lonorore",
    gps_code: "NVSO",
    iata_code: "LNE",
    coordinates: "168.17199707, -15.865599632299999",
  },
  {
    ident: "NVSP",
    type: "small_airport",
    name: "Norsup Airport",
    elevation_ft: "23",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-MAP",
    municipality: "Norsup",
    gps_code: "NVSP",
    iata_code: "NUS",
    coordinates: "167.401001, -16.0797",
  },
  {
    ident: "NVSQ",
    type: "medium_airport",
    name: "Gaua Island Airport",
    elevation_ft: "100",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TOB",
    municipality: "Gaua Island",
    gps_code: "NVSQ",
    iata_code: "ZGU",
    coordinates: "167.587005615, -14.218099594099998",
  },
  {
    ident: "NVSR",
    type: "small_airport",
    name: "Redcliffe Airport",
    elevation_ft: "36",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-PAM",
    municipality: "Redcliffe",
    gps_code: "NVSR",
    iata_code: "RCL",
    coordinates: "167.835006714, -15.472000122099999",
  },
  {
    ident: "NVSS",
    type: "medium_airport",
    name: "Santo Pekoa International Airport",
    elevation_ft: "184",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-SAM",
    municipality: "Luganville",
    gps_code: "NVSS",
    iata_code: "SON",
    coordinates: "167.220001221, -15.505000114399998",
  },
  {
    ident: "NVST",
    type: "small_airport",
    name: "Tongoa Airport",
    elevation_ft: "443",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-SEE",
    municipality: "Tongoa Island",
    gps_code: "NVST",
    iata_code: "TGH",
    coordinates: "168.550994873, -16.8910999298",
  },
  {
    ident: "NVSU",
    type: "small_airport",
    name: "Ul����i Airport",
    elevation_ft: "170",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-MAP",
    municipality: "Ambryn Island",
    gps_code: "NVSU",
    iata_code: "ULB",
    coordinates: "168.3011, -16.3297",
  },
  {
    ident: "NVSV",
    type: "small_airport",
    name: "Valesdir Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-SEE",
    municipality: "Epi Island",
    gps_code: "NVSV",
    iata_code: "VLS",
    coordinates: "168.177001953, -16.796100616500002",
  },
  {
    ident: "NVSW",
    type: "small_airport",
    name: "Walaha Airport",
    elevation_ft: "151",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-PAM",
    municipality: "Walaha",
    gps_code: "NVSW",
    iata_code: "WLH",
    coordinates: "167.690994263, -15.411999702500001",
  },
  {
    ident: "NVSX",
    type: "small_airport",
    name: "Southwest Bay Airport",
    elevation_ft: "68",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-MAP",
    municipality: "Malekula Island",
    gps_code: "NVSX",
    iata_code: "SWJ",
    coordinates: "167.4472, -16.4864",
  },
  {
    ident: "NVSZ",
    type: "small_airport",
    name: "North West Santo Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-SAM",
    municipality: "Olpoi",
    gps_code: "NVSZ",
    iata_code: "OLJ",
    coordinates: "166.557998657, -14.881699562099998",
  },
  {
    ident: "NVVA",
    type: "small_airport",
    name: "Aneityum Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TAE",
    municipality: "Anatom Island",
    gps_code: "NVVA",
    iata_code: "AUY",
    coordinates: "169.770996094, -20.2492008209",
  },
  {
    ident: "NVVB",
    type: "small_airport",
    name: "Aniwa Airport",
    elevation_ft: "69",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TAE",
    municipality: "Aniwa",
    gps_code: "NVVB",
    iata_code: "AWD",
    coordinates: "169.6009, -19.2346",
  },
  {
    ident: "NVVD",
    type: "small_airport",
    name: "Dillon's Bay Airport",
    elevation_ft: "630",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TAE",
    municipality: "Dillon's Bay",
    gps_code: "NVVD",
    iata_code: "DLY",
    coordinates: "169.00100708, -18.7693996429",
  },
  {
    ident: "NVVF",
    type: "small_airport",
    name: "Futuna Airport",
    elevation_ft: "95",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TAE",
    municipality: "Futuna Island",
    gps_code: "NVVF",
    iata_code: "FTA",
    coordinates: "170.231994629, -19.516399383499998",
  },
  {
    ident: "NVVI",
    type: "small_airport",
    name: "Ipota Airport",
    elevation_ft: "23",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TAE",
    municipality: "Ipota",
    gps_code: "NVVI",
    iata_code: "IPA",
    coordinates: "169.283333, -18.856389",
  },
  {
    ident: "NVVQ",
    type: "small_airport",
    name: "Quion Hill Airport",
    elevation_ft: "16",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-SEE",
    municipality: "Quion Hill",
    gps_code: "NVVQ",
    iata_code: "UIQ",
    coordinates: "168.44200134277344, -17.540000915527344",
  },
  {
    ident: "NVVV",
    type: "medium_airport",
    name: "Bauerfield International Airport",
    elevation_ft: "70",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-SEE",
    municipality: "Port Vila",
    gps_code: "NVVV",
    iata_code: "VLI",
    coordinates: "168.32000732422, -17.699300765991",
  },
  {
    ident: "NVVW",
    type: "medium_airport",
    name: "Tanna Airport",
    elevation_ft: "19",
    continent: "OC",
    iso_country: "VU",
    iso_region: "VU-TAE",
    gps_code: "NVVW",
    iata_code: "TAH",
    coordinates: "169.2239990234375, -19.45509910583496",
  },
  {
    ident: "NWT",
    type: "small_airport",
    name: "Nowata Airport",
    elevation_ft: "2040",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Nowata",
    gps_code: "AYNW",
    iata_code: "NWT",
    local_code: "NTA",
    coordinates: "149.729166667, -9.984166666669998",
  },
  {
    ident: "NWWA",
    type: "medium_airport",
    name: "Tiga Airport",
    elevation_ft: "128",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Tiga",
    gps_code: "NWWA",
    iata_code: "TGJ",
    coordinates: "167.8040008544922, -21.096099853515625",
  },
  {
    ident: "NWWC",
    type: "medium_airport",
    name: "����le Art - Waala Airport",
    elevation_ft: "306",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Waala",
    gps_code: "NWWC",
    iata_code: "BMY",
    coordinates: "163.66099548339844, -19.720600128173828",
  },
  {
    ident: "NWWD",
    type: "medium_airport",
    name: "Kon���� Airport",
    elevation_ft: "23",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Kon����",
    gps_code: "NWWD",
    iata_code: "KNQ",
    coordinates: "164.83700561523438, -21.05430030822754",
  },
  {
    ident: "NWWE",
    type: "medium_airport",
    name: "����le des Pins Airport",
    elevation_ft: "315",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "����le des Pins",
    gps_code: "NWWE",
    iata_code: "ILP",
    coordinates: "167.45599365234375, -22.588899612426758",
  },
  {
    ident: "NWWH",
    type: "small_airport",
    name: "Nesson Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Houailou",
    gps_code: "NWWH",
    iata_code: "HLU",
    coordinates: "165.61700439453125, -21.256399154663086",
  },
  {
    ident: "NWWK",
    type: "medium_airport",
    name: "Koumac Airport",
    elevation_ft: "42",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Koumac",
    gps_code: "NWWK",
    iata_code: "KOC",
    coordinates: "164.25599670410156, -20.546300888061523",
  },
  {
    ident: "NWWL",
    type: "medium_airport",
    name: "Lifou Airport",
    elevation_ft: "92",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Lifou",
    gps_code: "NWWL",
    iata_code: "LIF",
    coordinates: "167.24000549316406, -20.774799346923828",
  },
  {
    ident: "NWWM",
    type: "medium_airport",
    name: "Noum����a Magenta Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Noum����a",
    gps_code: "NWWM",
    iata_code: "GEA",
    coordinates: "166.47300720214844, -22.25830078125",
  },
  {
    ident: "NWWO",
    type: "small_airport",
    name: "Edmond Can���� Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "����le Ouen",
    gps_code: "NWWO",
    iata_code: "IOU",
    coordinates: "166.7830047607422, -22.459999084472656",
  },
  {
    ident: "NWWP",
    type: "small_airport",
    name: "Poum Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Poum",
    gps_code: "NWWP",
    iata_code: "PUV",
    coordinates: "164.0989990234375, -20.289199829101562",
  },
  {
    ident: "NWWQ",
    type: "small_airport",
    name: "Mueo Airport",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Mueo",
    gps_code: "NWWQ",
    iata_code: "PDC",
    coordinates: "164.99899291992188, -21.3164005279541",
  },
  {
    ident: "NWWR",
    type: "medium_airport",
    name: "Mar���� Airport",
    elevation_ft: "141",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Mar����",
    gps_code: "NWWR",
    iata_code: "MEE",
    coordinates: "168.03799438476562, -21.481700897216797",
  },
  {
    ident: "NWWU",
    type: "medium_airport",
    name: "Touho Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Touho",
    gps_code: "NWWU",
    iata_code: "TOU",
    coordinates: "165.25900268554688, -20.790000915527344",
  },
  {
    ident: "NWWV",
    type: "medium_airport",
    name: "Ouv����a Airport",
    elevation_ft: "23",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Ouv����a",
    gps_code: "NWWV",
    iata_code: "UVE",
    coordinates: "166.572998046875, -20.640600204467773",
  },
  {
    ident: "NWWW",
    type: "medium_airport",
    name: "La Tontouta International Airport",
    elevation_ft: "52",
    continent: "OC",
    iso_country: "NC",
    iso_region: "NC-U-A",
    municipality: "Noum����a",
    gps_code: "NWWW",
    iata_code: "NOU",
    coordinates: "166.21299743652344, -22.01460075378418",
  },
  {
    ident: "NZAA",
    type: "large_airport",
    name: "Auckland International Airport",
    elevation_ft: "23",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-AUK",
    municipality: "Auckland",
    gps_code: "NZAA",
    iata_code: "AKL",
    coordinates: "174.792007446, -37.008098602299995",
  },
  {
    ident: "NZAP",
    type: "medium_airport",
    name: "Taupo Airport",
    elevation_ft: "1335",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WKO",
    municipality: "Taupo",
    gps_code: "NZAP",
    iata_code: "TUO",
    coordinates: "176.08399963378906, -38.73970031738281",
  },
  {
    ident: "NZAR",
    type: "medium_airport",
    name: "Ardmore Airport",
    elevation_ft: "111",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-AUK",
    municipality: "Manurewa",
    gps_code: "NZAR",
    iata_code: "AMZ",
    coordinates: "174.97300720214844, -37.029701232910156",
  },
  {
    ident: "NZAS",
    type: "small_airport",
    name: "Ashburton Aerodrome",
    elevation_ft: "302",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-CAN",
    gps_code: "NZAS",
    iata_code: "ASG",
    coordinates: "171.7969970703125, -43.90330123901367",
  },
  {
    ident: "NZCH",
    type: "large_airport",
    name: "Christchurch International Airport",
    elevation_ft: "123",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-CAN",
    municipality: "Christchurch",
    gps_code: "NZCH",
    iata_code: "CHC",
    coordinates: "172.53199768066406, -43.48939895629883",
  },
  {
    ident: "NZCI",
    type: "medium_airport",
    name: "Chatham Islands-Tuuta Airport",
    elevation_ft: "43",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WGN",
    municipality: "Waitangi",
    gps_code: "NZCI",
    iata_code: "CHT",
    coordinates: "-176.45700073242188, -43.810001373291016",
  },
  {
    ident: "NZCX",
    type: "small_airport",
    name: "Coromandel Aerodrome",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WKO",
    gps_code: "NZCX",
    iata_code: "CMV",
    coordinates: "175.50900268554688, -36.79169845581055",
  },
  {
    ident: "NZDA",
    type: "small_airport",
    name: "Dargaville Aerodrome",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-NTL",
    gps_code: "NZDA",
    iata_code: "DGR",
    coordinates: "173.8939971923828, -35.939701080322266",
  },
  {
    ident: "NZDN",
    type: "medium_airport",
    name: "Dunedin Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-OTA",
    municipality: "Dunedin",
    gps_code: "NZDN",
    iata_code: "DUD",
    coordinates: "170.197998046875, -45.9281005859375",
  },
  {
    ident: "NZFJ",
    type: "small_airport",
    name: "Franz Josef Aerodrome",
    elevation_ft: "240",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WTC",
    gps_code: "NZFJ",
    iata_code: "WHO",
    coordinates: "170.13400268554688, -43.36309814453125",
  },
  {
    ident: "NZGB",
    type: "small_airport",
    name: "Great Barrier Aerodrome",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-AUK",
    municipality: "Claris",
    gps_code: "NZGB",
    iata_code: "GBZ",
    coordinates: "175.4720001220703, -36.24140167236328",
  },
  {
    ident: "NZGM",
    type: "small_airport",
    name: "Greymouth Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WTC",
    gps_code: "NZGM",
    iata_code: "GMN",
    coordinates: "171.19000244140625, -42.461700439453125",
  },
  {
    ident: "NZGS",
    type: "medium_airport",
    name: "Gisborne Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-GIS",
    municipality: "Gisborne",
    gps_code: "NZGS",
    iata_code: "GIS",
    coordinates: "177.97799682617188, -38.663299560546875",
  },
  {
    ident: "NZGT",
    type: "medium_airport",
    name: "Glentanner Airport",
    elevation_ft: "1824",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-CAN",
    municipality: "Glentanner Station",
    gps_code: "NZGT",
    iata_code: "GTN",
    coordinates: "170.1280059814453, -43.906700134277344",
  },
  {
    ident: "NZHK",
    type: "medium_airport",
    name: "Hokitika Airfield",
    elevation_ft: "146",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WTC",
    gps_code: "NZHK",
    iata_code: "HKK",
    coordinates: "170.98500061035156, -42.713600158691406",
  },
  {
    ident: "NZHN",
    type: "medium_airport",
    name: "Hamilton International Airport",
    elevation_ft: "172",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WKO",
    municipality: "Hamilton",
    gps_code: "NZHN",
    iata_code: "HLZ",
    coordinates: "175.332000732, -37.8666992188",
  },
  {
    ident: "NZKE",
    type: "small_airport",
    name: "Waiheke Reeve Airport",
    elevation_ft: "226",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-AUK",
    gps_code: "NZKE",
    iata_code: "WIK",
    coordinates: "175.08599853515625, -36.80889892578125",
  },
  {
    ident: "NZKI",
    type: "small_airport",
    name: "Kaikoura Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-CAN",
    gps_code: "NZKI",
    iata_code: "KBZ",
    coordinates: "173.60499572753906, -42.42499923706055",
  },
  {
    ident: "NZKK",
    type: "medium_airport",
    name: "Kerikeri Airport",
    elevation_ft: "492",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-NTL",
    municipality: "Kerikeri",
    gps_code: "NZKK",
    iata_code: "KKE",
    coordinates: "173.91200256347656, -35.26279830932617",
  },
  {
    ident: "NZKO",
    type: "small_airport",
    name: "Kaikohe Airport",
    elevation_ft: "571",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-NTL",
    gps_code: "NZKO",
    iata_code: "KKO",
    coordinates: "173.81700134277344, -35.45109939575195",
  },
  {
    ident: "NZKT",
    type: "medium_airport",
    name: "Kaitaia Airport",
    elevation_ft: "270",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-NTL",
    municipality: "Kaitaia",
    gps_code: "NZKT",
    iata_code: "KAT",
    coordinates: "173.28500366210938, -35.06999969482422",
  },
  {
    ident: "NZLX",
    type: "medium_airport",
    name: "Alexandra Airport",
    elevation_ft: "752",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-OTA",
    municipality: "Alexandra",
    gps_code: "NZLX",
    iata_code: "ALR",
    coordinates: "169.3730010986328, -45.211700439453125",
  },
  {
    ident: "NZMA",
    type: "small_airport",
    name: "Matamata Glider Airport",
    elevation_ft: "180",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WKO",
    gps_code: "NZMA",
    iata_code: "MTA",
    coordinates: "175.74200439453125, -37.73440170288086",
  },
  {
    ident: "NZMB",
    type: "heliport",
    name: "Mechanics Bay Heliport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-AUK",
    municipality: "Auckland City",
    gps_code: "NZMB",
    iata_code: "MHB",
    coordinates: "174.787872434, -36.846525628100004",
  },
  {
    ident: "NZMC",
    type: "medium_airport",
    name: "Mount Cook Airport",
    elevation_ft: "2153",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-CAN",
    gps_code: "NZMC",
    iata_code: "MON",
    coordinates: "170.13299560546875, -43.76499938964844",
  },
  {
    ident: "NZMF",
    type: "small_airport",
    name: "Milford Sound Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-STL",
    gps_code: "NZMF",
    iata_code: "MFN",
    coordinates: "167.92300415039062, -44.673301696777344",
  },
  {
    ident: "NZMK",
    type: "small_airport",
    name: "Motueka Airport",
    elevation_ft: "39",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-TAS",
    gps_code: "NZMK",
    iata_code: "MZP",
    coordinates: "172.98899841308594, -41.12329864501953",
  },
  {
    ident: "NZMO",
    type: "medium_airport",
    name: "Manapouri Airport",
    elevation_ft: "687",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-STL",
    gps_code: "NZMO",
    iata_code: "TEU",
    coordinates: "167.64999389648438, -45.53310012817383",
  },
  {
    ident: "NZMS",
    type: "medium_airport",
    name: "Hood Airport",
    elevation_ft: "364",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WGN",
    municipality: "Masterton",
    gps_code: "NZMS",
    iata_code: "MRO",
    coordinates: "175.63400268554688, -40.97330093383789",
  },
  {
    ident: "NZNP",
    type: "medium_airport",
    name: "New Plymouth Airport",
    elevation_ft: "97",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-TKI",
    municipality: "New Plymouth",
    gps_code: "NZNP",
    iata_code: "NPL",
    coordinates: "174.1790008544922, -39.00859832763672",
  },
  {
    ident: "NZNR",
    type: "medium_airport",
    name: "Hawke's Bay Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-HKB",
    municipality: "Napier",
    gps_code: "NZNR",
    iata_code: "NPE",
    coordinates: "176.869995, -39.465801",
  },
  {
    ident: "NZNS",
    type: "medium_airport",
    name: "Nelson Airport",
    elevation_ft: "17",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-NSN",
    municipality: "Nelson",
    gps_code: "NZNS",
    iata_code: "NSN",
    coordinates: "173.2209930419922, -41.298301696777344",
  },
  {
    ident: "NZNV",
    type: "medium_airport",
    name: "Invercargill Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-STL",
    municipality: "Invercargill",
    gps_code: "NZNV",
    iata_code: "IVC",
    coordinates: "168.31300354003906, -46.41239929199219",
  },
  {
    ident: "NZOH",
    type: "medium_airport",
    name: "RNZAF Base Ohakea",
    elevation_ft: "164",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-MWT",
    gps_code: "NZOH",
    iata_code: "OHA",
    coordinates: "175.38800048828125, -40.20600128173828",
  },
  {
    ident: "NZOU",
    type: "medium_airport",
    name: "Oamaru Airport",
    elevation_ft: "99",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-OTA",
    gps_code: "NZOU",
    iata_code: "OAM",
    coordinates: "171.08200073242188, -44.970001220703125",
  },
  {
    ident: "NZPM",
    type: "medium_airport",
    name: "Palmerston North Airport",
    elevation_ft: "151",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-MWT",
    gps_code: "NZPM",
    iata_code: "PMR",
    coordinates: "175.61700439453125, -40.32059860229492",
  },
  {
    ident: "NZPN",
    type: "small_airport",
    name: "Picton Aerodrome",
    elevation_ft: "161",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-MBH",
    municipality: "Koromiko",
    gps_code: "NZPN",
    iata_code: "PCN",
    coordinates: "173.95599365234, -41.346099853516",
  },
  {
    ident: "NZPP",
    type: "medium_airport",
    name: "Paraparaumu Airport",
    elevation_ft: "22",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WGN",
    gps_code: "NZPP",
    iata_code: "PPQ",
    coordinates: "174.98899841308594, -40.904701232910156",
  },
  {
    ident: "NZQN",
    type: "medium_airport",
    name: "Queenstown International Airport",
    elevation_ft: "1171",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-OTA",
    municipality: "Queenstown",
    gps_code: "NZQN",
    iata_code: "ZQN",
    coordinates: "168.738998413, -45.0210990906",
  },
  {
    ident: "NZRA",
    type: "small_airport",
    name: "Raglan Airfield",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WKO",
    gps_code: "NZRA",
    iata_code: "RAG",
    coordinates: "174.86000061035156, -37.8046989440918",
  },
  {
    ident: "NZRC",
    type: "small_airport",
    name: "Ryan's Creek Aerodrome",
    elevation_ft: "62",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-STL",
    municipality: "Oban",
    gps_code: "NZRC",
    iata_code: "SZS",
    coordinates: "168.100998, -46.8997",
  },
  {
    ident: "NZRO",
    type: "medium_airport",
    name: "Rotorua Regional Airport",
    elevation_ft: "935",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-BOP",
    municipality: "Rotorua",
    gps_code: "NZRO",
    iata_code: "ROT",
    coordinates: "176.31700134277344, -38.10919952392578",
  },
  {
    ident: "NZTG",
    type: "medium_airport",
    name: "Tauranga Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-BOP",
    municipality: "Tauranga",
    gps_code: "NZTG",
    iata_code: "TRG",
    coordinates: "176.1959991455078, -37.67190170288086",
  },
  {
    ident: "NZTH",
    type: "small_airport",
    name: "Thames Aerodrome",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WKO",
    gps_code: "NZTH",
    iata_code: "TMZ",
    coordinates: "175.5500030517578, -37.156700134277344",
  },
  {
    ident: "NZTK",
    type: "small_airport",
    name: "Takaka Airport",
    elevation_ft: "102",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-TAS",
    gps_code: "NZTK",
    iata_code: "KTF",
    coordinates: "172.77499389648438, -40.81330108642578",
  },
  {
    ident: "NZTO",
    type: "small_airport",
    name: "Tokoroa Airfield",
    elevation_ft: "1220",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WKO",
    municipality: "Tokoroa",
    gps_code: "NZTO",
    iata_code: "TKZ",
    coordinates: "175.89199829101562, -38.236698150634766",
  },
  {
    ident: "NZTS",
    type: "small_airport",
    name: "Taharoa Aerodrome",
    elevation_ft: "69",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WKO",
    municipality: "Taharoa",
    gps_code: "NZTS",
    iata_code: "THH",
    coordinates: "174.70799255371094, -38.18109893798828",
  },
  {
    ident: "NZTU",
    type: "medium_airport",
    name: "Timaru Airport",
    elevation_ft: "89",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-CAN",
    gps_code: "NZTU",
    iata_code: "TIU",
    coordinates: "171.22500610351562, -44.302799224853516",
  },
  {
    ident: "NZUK",
    type: "medium_airport",
    name: "Pukaki Airport",
    elevation_ft: "1575",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-CAN",
    municipality: "Twitzel",
    gps_code: "NZUK",
    iata_code: "TWZ",
    coordinates: "170.117996216, -44.2350006104",
  },
  {
    ident: "NZWB",
    type: "medium_airport",
    name: "Woodbourne Airport",
    elevation_ft: "109",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-MBH",
    municipality: "Blenheim",
    gps_code: "NZWB",
    iata_code: "BHE",
    coordinates: "173.8699951171875, -41.5182991027832",
  },
  {
    ident: "NZWF",
    type: "medium_airport",
    name: "Wanaka Airport",
    elevation_ft: "1142",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-OTA",
    gps_code: "NZWF",
    iata_code: "WKA",
    coordinates: "169.24600219727, -44.722198486328",
  },
  {
    ident: "NZWK",
    type: "medium_airport",
    name: "Whakatane Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-BOP",
    gps_code: "NZWK",
    iata_code: "WHK",
    coordinates: "176.91400146484375, -37.92060089111328",
  },
  {
    ident: "NZWN",
    type: "large_airport",
    name: "Wellington International Airport",
    elevation_ft: "41",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WGN",
    municipality: "Wellington",
    gps_code: "NZWN",
    iata_code: "WLG",
    coordinates: "174.804992676, -41.3272018433",
  },
  {
    ident: "NZWO",
    type: "medium_airport",
    name: "Wairoa Airport",
    elevation_ft: "42",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-HKB",
    municipality: "Wairoa",
    gps_code: "NZWO",
    iata_code: "WIR",
    coordinates: "177.40699768066406, -39.006900787353516",
  },
  {
    ident: "NZWR",
    type: "medium_airport",
    name: "Whangarei Airport",
    elevation_ft: "133",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-NTL",
    gps_code: "NZWR",
    iata_code: "WRE",
    coordinates: "174.36500549316406, -35.7682991027832",
  },
  {
    ident: "NZWS",
    type: "medium_airport",
    name: "Westport Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WTC",
    gps_code: "NZWS",
    iata_code: "WSZ",
    coordinates: "171.58099365234375, -41.73809814453125",
  },
  {
    ident: "NZWT",
    type: "small_airport",
    name: "Whitianga Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-WKO",
    gps_code: "NZWT",
    iata_code: "WTZ",
    coordinates: "175.6790008544922, -36.83169937133789",
  },
  {
    ident: "NZWU",
    type: "medium_airport",
    name: "Wanganui Airport",
    elevation_ft: "27",
    continent: "OC",
    iso_country: "NZ",
    iso_region: "NZ-MWT",
    municipality: "Wanganui",
    gps_code: "NZWU",
    iata_code: "WAG",
    coordinates: "175.02499389648438, -39.96220016479492",
  },
  {
    ident: "O19",
    type: "small_airport",
    name: "Kneeland Airport",
    elevation_ft: "2737",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Eureka",
    gps_code: "O19",
    iata_code: "NLN",
    local_code: "O19",
    coordinates: "-123.92800140381, 40.719299316406",
  },
  {
    ident: "O85",
    type: "small_airport",
    name: "Benton Field",
    elevation_ft: "719",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Redding",
    gps_code: "O85",
    iata_code: "BZF",
    local_code: "O85",
    coordinates: "-122.407997131, 40.5749015808",
  },
  {
    ident: "OAA",
    type: "small_airport",
    name: "Shank Air Base",
    elevation_ft: "6890",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-LOW",
    gps_code: "OASH",
    iata_code: "OAA",
    coordinates: "69.07722222219999, 33.9225",
  },
  {
    ident: "OABN",
    type: "small_airport",
    name: "Bamiyan Airport",
    elevation_ft: "8367",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-BAM",
    municipality: "Bamiyan",
    gps_code: "OABN",
    iata_code: "BIN",
    coordinates: "67.81700134277344, 34.81700134277344",
  },
  {
    ident: "OABT",
    type: "small_airport",
    name: "Bost Airport",
    elevation_ft: "2464",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-HEL",
    municipality: "Bost",
    gps_code: "OABT",
    iata_code: "BST",
    coordinates: "64.36499786376953, 31.55970001220703",
  },
  {
    ident: "OACC",
    type: "small_airport",
    name: "Chakcharan Airport",
    elevation_ft: "7383",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-GHO",
    municipality: "Chakcharan",
    gps_code: "OACC",
    iata_code: "CCN",
    coordinates: "65.27102, 34.526465",
  },
  {
    ident: "OADS",
    type: "small_airport",
    name: "Sardeh Band Airport",
    elevation_ft: "6971",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-GHA",
    municipality: "Sardeh Band",
    gps_code: "OADS",
    iata_code: "SBF",
    coordinates: "68.6364974976, 33.3207015991",
  },
  {
    ident: "OADZ",
    type: "small_airport",
    name: "Darwaz Airport",
    elevation_ft: "5250",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-BDS",
    municipality: "Darwaz",
    gps_code: "OADZ",
    iata_code: "DAZ",
    coordinates: "70.88249969482422, 38.46110153198242",
  },
  {
    ident: "OAFR",
    type: "small_airport",
    name: "Farah Airport",
    elevation_ft: "3083",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-FRA",
    municipality: "Farah",
    gps_code: "OAFR",
    iata_code: "FAH",
    coordinates: "62.18299865722656, 32.367000579833984",
  },
  {
    ident: "OAFZ",
    type: "small_airport",
    name: "Fayzabad Airport",
    elevation_ft: "3872",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-BDS",
    municipality: "Fayzabad",
    gps_code: "OAFZ",
    iata_code: "FBD",
    coordinates: "70.518097, 37.121101",
  },
  {
    ident: "OAGN",
    type: "small_airport",
    name: "Ghazni Airport",
    elevation_ft: "7150",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-GHA",
    municipality: "Ghazni",
    gps_code: "OAGN",
    iata_code: "GZI",
    coordinates: "68.412902832, 33.5312004089",
  },
  {
    ident: "OAHN",
    type: "small_airport",
    name: "Khwahan Airport",
    elevation_ft: "3593",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-BDS",
    municipality: "Khwahan",
    gps_code: "OAHN",
    iata_code: "KWH",
    coordinates: "70.21700286865234, 37.882999420166016",
  },
  {
    ident: "OAHR",
    type: "medium_airport",
    name: "Herat Airport",
    elevation_ft: "3206",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-HER",
    gps_code: "OAHR",
    iata_code: "HEA",
    coordinates: "62.22829818725586, 34.209999084472656",
  },
  {
    ident: "OAIX",
    type: "medium_airport",
    name: "Bagram Air Base",
    elevation_ft: "4895",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-PAR",
    municipality: "Bagram",
    gps_code: "OAIX",
    iata_code: "OAI",
    coordinates: "69.26499938959999, 34.9460983276",
  },
  {
    ident: "OAJL",
    type: "medium_airport",
    name: "Jalalabad Airport",
    elevation_ft: "1814",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-NAN",
    gps_code: "OAJL",
    iata_code: "JAA",
    coordinates: "70.49859619140625, 34.39979934692383",
  },
  {
    ident: "OAKA",
    type: "small_airport",
    name: "Gardez Airport",
    elevation_ft: "7790",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-PIA",
    municipality: "Gardez",
    gps_code: "OAGZ",
    iata_code: "GRG",
    coordinates: "69.239402771, 33.631500244099996",
  },
  {
    ident: "OAKB",
    type: "medium_airport",
    name: "Hamid Karzai International Airport",
    elevation_ft: "5877",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-KAB",
    municipality: "Kabul",
    gps_code: "OAKB",
    iata_code: "KBL",
    coordinates: "69.212303, 34.565899",
  },
  {
    ident: "OAKN",
    type: "medium_airport",
    name: "Kandahar Airport",
    elevation_ft: "3337",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-KAN",
    gps_code: "OAKN",
    iata_code: "KDH",
    coordinates: "65.8478012084961, 31.505800247192383",
  },
  {
    ident: "OAKS",
    type: "small_airport",
    name: "Khost Chapman Airport",
    elevation_ft: "3756",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-KHO",
    municipality: "Khost",
    iata_code: "KHT",
    coordinates: "69.952003, 33.333401",
  },
  {
    ident: "OAMN",
    type: "medium_airport",
    name: "Maimana Airport",
    elevation_ft: "2743",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-FYB",
    gps_code: "OAMN",
    iata_code: "MMZ",
    coordinates: "64.76090240478516, 35.93080139160156",
  },
  {
    ident: "OAMS",
    type: "medium_airport",
    name: "Mazar I Sharif Airport",
    elevation_ft: "1284",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-BAL",
    gps_code: "OAMS",
    iata_code: "MZR",
    coordinates: "67.20970153808594, 36.70690155029297",
  },
  {
    ident: "OAOG",
    type: "small_airport",
    name: "Urgun Airport",
    elevation_ft: "7300",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-PKA",
    municipality: "Urgun",
    gps_code: "OAOG",
    iata_code: "URN",
    coordinates: "69.1563034058, 32.9318008423",
  },
  {
    ident: "OAQN",
    type: "small_airport",
    name: "Qala-I-Naw Airport",
    elevation_ft: "2999",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-BDG",
    municipality: "Qala-I-Naw",
    gps_code: "OAQN",
    iata_code: "LQN",
    coordinates: "63.117801666259766, 34.98500061035156",
  },
  {
    ident: "OASA",
    type: "small_airport",
    name: "Sharana Airstrip",
    elevation_ft: "7340",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-PKA",
    municipality: "Sharana",
    gps_code: "OASA",
    iata_code: "OAS",
    coordinates: "68.838517, 33.12575",
  },
  {
    ident: "OASD",
    type: "small_airport",
    name: "Shindand Airport",
    elevation_ft: "3773",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-FRA",
    gps_code: "OASD",
    iata_code: "OAH",
    coordinates: "62.2610015869, 33.3913002014",
  },
  {
    ident: "OASN",
    type: "small_airport",
    name: "Sheghnan Airport",
    elevation_ft: "6700",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-BDS",
    municipality: "Sheghnan",
    gps_code: "OASN",
    iata_code: "SGA",
    coordinates: "71.5, 37.56700134277344",
  },
  {
    ident: "OATN",
    type: "small_airport",
    name: "Tarin Kowt Airport",
    elevation_ft: "4429",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-ORU",
    municipality: "Tarin Kowt",
    gps_code: "OATN",
    iata_code: "TII",
    coordinates: "65.8657989502, 32.604198455799995",
  },
  {
    ident: "OATQ",
    type: "small_airport",
    name: "Talolqan Airport",
    elevation_ft: "2606",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-TAK",
    municipality: "Taloqan",
    gps_code: "OATQ",
    iata_code: "TQN",
    coordinates: "69.5319976807, 36.7706985474",
  },
  {
    ident: "OAUZ",
    type: "medium_airport",
    name: "Konduz Airport",
    elevation_ft: "1457",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-KDZ",
    gps_code: "OAUZ",
    iata_code: "UND",
    coordinates: "68.91079711914062, 36.66510009765625",
  },
  {
    ident: "OAZI",
    type: "small_airport",
    name: "Camp Bastion Airport",
    elevation_ft: "2943",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-HEL",
    gps_code: "OAZI",
    iata_code: "OAZ",
    coordinates: "64.2246017456, 31.863800048799998",
  },
  {
    ident: "OAZJ",
    type: "small_airport",
    name: "Zaranj Airport",
    elevation_ft: "1572",
    continent: "AS",
    iso_country: "AF",
    iso_region: "AF-NIM",
    municipality: "Zaranj",
    gps_code: "OAZJ",
    iata_code: "ZAJ",
    coordinates: "61.865833, 30.972222",
  },
  {
    ident: "OBBI",
    type: "large_airport",
    name: "Bahrain International Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "BH",
    iso_region: "BH-15",
    municipality: "Manama",
    gps_code: "OBBI",
    iata_code: "BAH",
    coordinates: "50.63359832763672, 26.27079963684082",
  },
  {
    ident: "OBK",
    type: "closed",
    name: "Sky Harbor Airport",
    elevation_ft: "675",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IL",
    municipality: "Northbrook",
    iata_code: "OBK",
    coordinates: "-87.85555555559999, 42.1438888889",
  },
  {
    ident: "OCS",
    type: "small_airport",
    name: "Corisco International Airport",
    elevation_ft: "55",
    continent: "AF",
    iso_country: "GQ",
    iso_region: "GQ-LI",
    municipality: "Corisco Island",
    iata_code: "OCS",
    coordinates: "9.3304, 0.9125",
  },
  {
    ident: "OEAB",
    type: "medium_airport",
    name: "Abha Regional Airport",
    elevation_ft: "6858",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-14",
    municipality: "Abha",
    gps_code: "OEAB",
    iata_code: "AHB",
    coordinates: "42.65660095210001, 18.240400314299997",
  },
  {
    ident: "OEAH",
    type: "medium_airport",
    name: "Al Ahsa Airport",
    elevation_ft: "588",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-04",
    gps_code: "OEAH",
    iata_code: "HOF",
    coordinates: "49.485198974609375, 25.28529930114746",
  },
  {
    ident: "OEBA",
    type: "medium_airport",
    name: "Al Baha Airport",
    elevation_ft: "5486",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-11",
    gps_code: "OEBA",
    iata_code: "ABT",
    coordinates: "41.6343002319, 20.2961006165",
  },
  {
    ident: "OEBH",
    type: "medium_airport",
    name: "Bisha Airport",
    elevation_ft: "3887",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-14",
    gps_code: "OEBH",
    iata_code: "BHH",
    coordinates: "42.62089920043945, 19.984399795532227",
  },
  {
    ident: "OEDF",
    type: "large_airport",
    name: "King Fahd International Airport",
    elevation_ft: "72",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-04",
    municipality: "Ad Dammam",
    gps_code: "OEDF",
    iata_code: "DMM",
    coordinates: "49.79790115356445, 26.471200942993164",
  },
  {
    ident: "OEDM",
    type: "medium_airport",
    name: "King Salman Abdulaziz Airport",
    elevation_ft: "3026",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-01",
    municipality: "Dawadmi",
    gps_code: "OEDM",
    iata_code: "DWD",
    coordinates: "44.121201, 24.4499",
  },
  {
    ident: "OEDR",
    type: "large_airport",
    name: "King Abdulaziz Air Base",
    elevation_ft: "84",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-04",
    gps_code: "OEDR",
    iata_code: "DHA",
    coordinates: "50.152000427199994, 26.265399932900003",
  },
  {
    ident: "OEGN",
    type: "medium_airport",
    name: "Jizan Regional Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-09",
    municipality: "Jizan",
    gps_code: "OEGN",
    iata_code: "GIZ",
    coordinates: "42.58580017089844, 16.901100158691406",
  },
  {
    ident: "OEGS",
    type: "medium_airport",
    name: "Gassim Airport",
    elevation_ft: "2126",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-05",
    gps_code: "OEGS",
    iata_code: "ELQ",
    coordinates: "43.77439880371094, 26.302799224853516",
  },
  {
    ident: "OEGT",
    type: "medium_airport",
    name: "Gurayat Domestic Airport",
    elevation_ft: "1672",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-08",
    municipality: "Gurayat",
    gps_code: "OEGT",
    iata_code: "URY",
    coordinates: "37.278898, 31.412413",
  },
  {
    ident: "OEHL",
    type: "medium_airport",
    name: "Ha'il Airport",
    elevation_ft: "3331",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-06",
    municipality: "Ha'il",
    gps_code: "OEHL",
    iata_code: "HAS",
    coordinates: "41.686298, 27.437901",
  },
  {
    ident: "OEJB",
    type: "medium_airport",
    name: "Jubail Airport",
    elevation_ft: "26",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-04",
    municipality: "Jubail",
    gps_code: "OEJB",
    iata_code: "QJB",
    coordinates: "49.40510177612305, 27.038999557495117",
  },
  {
    ident: "OEJN",
    type: "large_airport",
    name: "King Abdulaziz International Airport",
    elevation_ft: "48",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-02",
    municipality: "Jeddah",
    gps_code: "OEJN",
    iata_code: "JED",
    coordinates: "39.156502, 21.6796",
  },
  {
    ident: "OEKK",
    type: "medium_airport",
    name: "King Khaled Military City Airport",
    elevation_ft: "1352",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-04",
    municipality: "King Khaled Military City",
    gps_code: "OEKK",
    iata_code: "KMC",
    coordinates: "45.528198, 27.9009",
  },
  {
    ident: "OEKM",
    type: "medium_airport",
    name: "King Khaled Air Base",
    elevation_ft: "6778",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-14",
    gps_code: "OEKM",
    iata_code: "KMX",
    coordinates: "42.80350112915039, 18.297300338745117",
  },
  {
    ident: "OEMA",
    type: "large_airport",
    name: "Prince Mohammad Bin Abdulaziz Airport",
    elevation_ft: "2151",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-03",
    municipality: "Medina",
    gps_code: "OEMA",
    iata_code: "MED",
    coordinates: "39.705101, 24.5534",
  },
  {
    ident: "OENG",
    type: "medium_airport",
    name: "Nejran Airport",
    elevation_ft: "3982",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-10",
    gps_code: "OENG",
    iata_code: "EAM",
    coordinates: "44.4192008972168, 17.611400604248047",
  },
  {
    ident: "OENN",
    type: "medium_airport",
    name: "Neom Airport",
    elevation_ft: "29",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-07",
    municipality: "Neom",
    gps_code: "OENN",
    iata_code: "NUM",
    coordinates: "35.28874, 27.927598",
  },
  {
    ident: "OEPA",
    type: "medium_airport",
    name: "Al Qaisumah/Hafr Al Batin Airport",
    elevation_ft: "1174",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-04",
    municipality: "Qaisumah",
    gps_code: "OEPA",
    iata_code: "AQI",
    coordinates: "46.125099, 28.335199",
  },
  {
    ident: "OEPS",
    type: "medium_airport",
    name: "Prince Sultan Air Base",
    elevation_ft: "1651",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-01",
    gps_code: "OEPS",
    iata_code: "AKH",
    coordinates: "47.580501556396484, 24.062700271606445",
  },
  {
    ident: "OERF",
    type: "medium_airport",
    name: "Rafha Domestic Airport",
    elevation_ft: "1474",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-08",
    municipality: "Rafha",
    gps_code: "OERF",
    iata_code: "RAH",
    coordinates: "43.4906005859375, 29.626399993896484",
  },
  {
    ident: "OERK",
    type: "large_airport",
    name: "King Khaled International Airport",
    elevation_ft: "2049",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-01",
    municipality: "Riyadh",
    gps_code: "OERK",
    iata_code: "RUH",
    coordinates: "46.69879913330078, 24.957599639892578",
  },
  {
    ident: "OERR",
    type: "medium_airport",
    name: "Arar Domestic Airport",
    elevation_ft: "1813",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-08",
    municipality: "Arar",
    gps_code: "OERR",
    iata_code: "RAE",
    coordinates: "41.13819885253906, 30.906600952148438",
  },
  {
    ident: "OERY",
    type: "medium_airport",
    name: "Riyadh Air Base",
    elevation_ft: "2082",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-01",
    municipality: "Riyadh",
    gps_code: "OERY",
    iata_code: "XXN",
    coordinates: "46.72520065307617, 24.709800720214844",
  },
  {
    ident: "OESH",
    type: "medium_airport",
    name: "Sharurah Airport",
    elevation_ft: "2363",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-10",
    gps_code: "OESH",
    iata_code: "SHW",
    coordinates: "47.12139892578125, 17.466899871826172",
  },
  {
    ident: "OESK",
    type: "medium_airport",
    name: "Al-Jawf Domestic Airport",
    elevation_ft: "2261",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-12",
    municipality: "Al-Jawf",
    gps_code: "OESK",
    iata_code: "AJF",
    coordinates: "40.099998474121094, 29.78510093688965",
  },
  {
    ident: "OESL",
    type: "small_airport",
    name: "Sulayel Airport",
    elevation_ft: "2021",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-01",
    gps_code: "OESL",
    iata_code: "SLF",
    coordinates: "45.619598388671875, 20.46470069885254",
  },
  {
    ident: "OETB",
    type: "medium_airport",
    name: "Tabuk Airport",
    elevation_ft: "2551",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-07",
    municipality: "Tabuk",
    gps_code: "OETB",
    iata_code: "TUU",
    coordinates: "36.6189, 28.3654",
  },
  {
    ident: "OETF",
    type: "medium_airport",
    name: "Ta������if Regional Airport",
    elevation_ft: "4848",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-02",
    municipality: "Ta������if",
    gps_code: "OETF",
    iata_code: "TIF",
    coordinates: "40.543442, 21.483001",
  },
  {
    ident: "OETR",
    type: "medium_airport",
    name: "Turaif Domestic Airport",
    elevation_ft: "2803",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-08",
    municipality: "Turaif",
    gps_code: "OETR",
    iata_code: "TUI",
    coordinates: "38.731544, 31.692188",
  },
  {
    ident: "OEWD",
    type: "medium_airport",
    name: "Wadi Al Dawasir Airport",
    elevation_ft: "2062",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-01",
    gps_code: "OEWD",
    iata_code: "WAE",
    coordinates: "45.199600219699995, 20.504299163800003",
  },
  {
    ident: "OEWJ",
    type: "medium_airport",
    name: "Al Wajh Domestic Airport",
    elevation_ft: "66",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-07",
    municipality: "Al Wajh",
    gps_code: "OEWJ",
    iata_code: "EJH",
    coordinates: "36.47639846801758, 26.19860076904297",
  },
  {
    ident: "OEYN",
    type: "medium_airport",
    name: "Prince Abdulmohsin Bin Abdulaziz Airport",
    elevation_ft: "26",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-03",
    municipality: "Yanbu",
    gps_code: "OEYN",
    iata_code: "YNB",
    coordinates: "38.0634, 24.144199",
  },
  {
    ident: "OEZL",
    type: "small_airport",
    name: "Zilfi Airport",
    elevation_ft: "1900",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-01",
    municipality: "Zilfi",
    gps_code: "OEZL",
    iata_code: "ZUL",
    coordinates: "44.83300018310547, 26.350000381469727",
  },
  {
    ident: "OGE",
    type: "small_airport",
    name: "Ogeranang Airport",
    elevation_ft: "5850",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    gps_code: "AYOG",
    iata_code: "OGE",
    local_code: "OGN",
    coordinates: "147.364166667, -6.46675",
  },
  {
    ident: "OGM",
    type: "small_airport",
    name: "Ogubsucum Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Ustupo",
    iata_code: "OGM",
    coordinates: "-77.93385, 9.1383",
  },
  {
    ident: "OI20",
    type: "small_airport",
    name: "Bahregan Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-06",
    gps_code: "OI20",
    iata_code: "IAQ",
    local_code: "OI20",
    coordinates: "50.272701, 29.840099",
  },
  {
    ident: "OIAA",
    type: "medium_airport",
    name: "Abadan Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-10",
    municipality: "Abadan",
    gps_code: "OIAA",
    iata_code: "ABD",
    coordinates: "48.2282981873, 30.371099472",
  },
  {
    ident: "OIAD",
    type: "small_airport",
    name: "Dezful Airport",
    elevation_ft: "474",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-10",
    gps_code: "OIAD",
    iata_code: "DEF",
    coordinates: "48.397598, 32.434399",
  },
  {
    ident: "OIAG",
    type: "medium_airport",
    name: "Aghajari Airport",
    elevation_ft: "88",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-10",
    municipality: "Aghajari",
    gps_code: "OIAG",
    iata_code: "AKW",
    coordinates: "49.6772, 30.7444",
  },
  {
    ident: "OIAH",
    type: "medium_airport",
    name: "Gachsaran Airport",
    elevation_ft: "2414",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-18",
    municipality: "Gachsaran",
    gps_code: "OIAH",
    iata_code: "GCH",
    coordinates: "50.827999115, 30.337600708",
  },
  {
    ident: "OIAJ",
    type: "small_airport",
    name: "Omidiyeh Airport",
    elevation_ft: "85",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-10",
    municipality: "Omidiyeh",
    gps_code: "OIAJ",
    iata_code: "OMI",
    coordinates: "49.5349006652832, 30.8351993560791",
  },
  {
    ident: "OIAM",
    type: "medium_airport",
    name: "Mahshahr Airport",
    elevation_ft: "8",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-10",
    gps_code: "OIAM",
    iata_code: "MRX",
    coordinates: "49.15190124511719, 30.55620002746582",
  },
  {
    ident: "OIAW",
    type: "medium_airport",
    name: "Ahwaz Airport",
    elevation_ft: "66",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-10",
    municipality: "Ahwaz",
    gps_code: "OIAW",
    iata_code: "AWZ",
    coordinates: "48.7620010376, 31.337400436399996",
  },
  {
    ident: "OIBA",
    type: "medium_airport",
    name: "Abu Musa Island Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-23",
    municipality: "Abu Musa",
    gps_code: "OIBA",
    iata_code: "AEU",
    coordinates: "55.033001, 25.8757",
  },
  {
    ident: "OIBB",
    type: "medium_airport",
    name: "Bushehr Airport",
    elevation_ft: "68",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-06",
    municipality: "Bushehr",
    gps_code: "OIBB",
    iata_code: "BUZ",
    coordinates: "50.8345985413, 28.9447994232",
  },
  {
    ident: "OIBI",
    type: "closed",
    name: "Asaloyeh Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-06",
    municipality: "Asaloyeh",
    gps_code: "OIBI",
    iata_code: "YEH",
    local_code: "OI15",
    coordinates: "52.615501, 27.4814",
  },
  {
    ident: "OIBJ",
    type: "medium_airport",
    name: "Jam Airport",
    elevation_ft: "2173",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-06",
    municipality: "Kangan",
    gps_code: "OIBJ",
    iata_code: "KNR",
    coordinates: "52.35219955444336, 27.820499420166016",
  },
  {
    ident: "OIBK",
    type: "medium_airport",
    name: "Kish International Airport",
    elevation_ft: "101",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-23",
    municipality: "Kish Island",
    gps_code: "OIBK",
    iata_code: "KIH",
    coordinates: "53.980201721200004, 26.5261993408",
  },
  {
    ident: "OIBL",
    type: "medium_airport",
    name: "Bandar Lengeh Airport",
    elevation_ft: "67",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-23",
    municipality: "Bandar Lengeh",
    gps_code: "OIBL",
    iata_code: "BDH",
    coordinates: "54.824798584, 26.531999588",
  },
  {
    ident: "OIBP",
    type: "medium_airport",
    name: "Persian Gulf International Airport",
    elevation_ft: "27",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-06",
    municipality: "Asalouyeh",
    gps_code: "OIBP",
    iata_code: "PGU",
    coordinates: "52.737701, 27.379601",
  },
  {
    ident: "OIBQ",
    type: "medium_airport",
    name: "Khark Island Airport",
    elevation_ft: "17",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-06",
    gps_code: "OIBQ",
    iata_code: "KHK",
    coordinates: "50.32389831542969, 29.260299682617188",
  },
  {
    ident: "OIBS",
    type: "medium_airport",
    name: "Sirri Island Airport",
    elevation_ft: "43",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-23",
    gps_code: "OIBS",
    iata_code: "SXI",
    coordinates: "54.539398193359375, 25.908899307250977",
  },
  {
    ident: "OIBV",
    type: "medium_airport",
    name: "Lavan Island Airport",
    elevation_ft: "76",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-23",
    gps_code: "OIBV",
    iata_code: "LVP",
    coordinates: "53.356300354003906, 26.810300827026367",
  },
  {
    ident: "OICC",
    type: "medium_airport",
    name: "Shahid Ashrafi Esfahani Airport",
    elevation_ft: "4307",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-17",
    municipality: "Kermanshah",
    gps_code: "OICC",
    iata_code: "KSH",
    coordinates: "47.1581001282, 34.3459014893",
  },
  {
    ident: "OICI",
    type: "medium_airport",
    name: "Ilam Airport",
    elevation_ft: "4404",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-05",
    municipality: "Ilam",
    gps_code: "OICI",
    iata_code: "IIL",
    coordinates: "46.40480041503906, 33.58660125732422",
  },
  {
    ident: "OICK",
    type: "medium_airport",
    name: "Khoram Abad Airport",
    elevation_ft: "3782",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-20",
    gps_code: "OICK",
    iata_code: "KHD",
    coordinates: "48.282901763916016, 33.43539810180664",
  },
  {
    ident: "OICS",
    type: "medium_airport",
    name: "Sanandaj Airport",
    elevation_ft: "4522",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-16",
    gps_code: "OICS",
    iata_code: "SDG",
    coordinates: "47.00920104980469, 35.24589920043945",
  },
  {
    ident: "OIFE",
    type: "small_airport",
    name: "Hesa Airport",
    elevation_ft: "5256",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-04",
    municipality: "Hesa",
    gps_code: "OIFE",
    iata_code: "IFH",
    coordinates: "51.561100006103516, 32.92890167236328",
  },
  {
    ident: "OIFK",
    type: "medium_airport",
    name: "Kashan Airport",
    elevation_ft: "3465",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-04",
    municipality: "Kashan",
    gps_code: "OIFK",
    iata_code: "KKS",
    coordinates: "51.577, 33.895302",
  },
  {
    ident: "OIFM",
    type: "medium_airport",
    name: "Esfahan Shahid Beheshti International Airport",
    elevation_ft: "5059",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-04",
    municipality: "Isfahan",
    gps_code: "OIFM",
    iata_code: "IFN",
    coordinates: "51.86130142211914, 32.75080108642578",
  },
  {
    ident: "OIFS",
    type: "medium_airport",
    name: "Shahrekord Airport",
    elevation_ft: "6723",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-08",
    municipality: "Shahrekord",
    gps_code: "OIFS",
    iata_code: "CQD",
    coordinates: "50.842201232899995, 32.2971992493",
  },
  {
    ident: "OIGG",
    type: "medium_airport",
    name: "Sardar-e-Jangal Airport",
    elevation_ft: "-40",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-19",
    municipality: "Rasht",
    gps_code: "OIGG",
    iata_code: "RAS",
    coordinates: "49.617778, 37.323333",
  },
  {
    ident: "OIHH",
    type: "medium_airport",
    name: "Hamadan Airport",
    elevation_ft: "5755",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-24",
    municipality: "Hamadan",
    gps_code: "OIHH",
    iata_code: "HDM",
    coordinates: "48.5525016784668, 34.86920166015625",
  },
  {
    ident: "OIHR",
    type: "small_airport",
    name: "Arak Airport",
    elevation_ft: "5440",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-22",
    municipality: "Araak",
    gps_code: "OIHR",
    iata_code: "AJK",
    coordinates: "49.8473014831543, 34.138099670410156",
  },
  {
    ident: "OIIE",
    type: "large_airport",
    name: "Imam Khomeini International Airport",
    elevation_ft: "3305",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-07",
    municipality: "Tehran",
    gps_code: "OIIE",
    iata_code: "IKA",
    coordinates: "51.152198791503906, 35.416099548339844",
  },
  {
    ident: "OIII",
    type: "large_airport",
    name: "Mehrabad International Airport",
    elevation_ft: "3962",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-07",
    municipality: "Tehran",
    gps_code: "OIII",
    iata_code: "THR",
    coordinates: "51.31340026855469, 35.68920135498047",
  },
  {
    ident: "OIIK",
    type: "medium_airport",
    name: "Qazvin Airport",
    elevation_ft: "4184",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-28",
    municipality: "Qazvin",
    gps_code: "OIIK",
    iata_code: "GZW",
    coordinates: "50.0471, 36.240101",
  },
  {
    ident: "OIIP",
    type: "medium_airport",
    name: "Payam International Airport",
    elevation_ft: "4170",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-U-A",
    municipality: "Karaj",
    gps_code: "OIIP",
    iata_code: "PYK",
    coordinates: "50.826698303223, 35.776100158691",
  },
  {
    ident: "OIIS",
    type: "small_airport",
    name: "Semnan Municipal Airport",
    elevation_ft: "3665",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-12",
    municipality: "Semnan",
    gps_code: "OIIS",
    iata_code: "SNX",
    coordinates: "53.495098, 35.591099",
  },
  {
    ident: "OIKB",
    type: "medium_airport",
    name: "Bandar Abbas International Airport",
    elevation_ft: "22",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-23",
    municipality: "Bandar Abbas",
    gps_code: "OIKB",
    iata_code: "BND",
    coordinates: "56.37779998779297, 27.218299865722656",
  },
  {
    ident: "OIKJ",
    type: "medium_airport",
    name: "Jiroft Airport",
    elevation_ft: "2663",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-15",
    municipality: "Jiroft",
    gps_code: "OIKJ",
    iata_code: "JYR",
    coordinates: "57.67029953, 28.726900100699996",
  },
  {
    ident: "OIKK",
    type: "medium_airport",
    name: "Kerman Airport",
    elevation_ft: "5741",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-15",
    municipality: "Kerman",
    gps_code: "OIKK",
    iata_code: "KER",
    coordinates: "56.9510993958, 30.274400711099997",
  },
  {
    ident: "OIKM",
    type: "medium_airport",
    name: "Bam Airport",
    elevation_ft: "3231",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-15",
    gps_code: "OIKM",
    iata_code: "BXR",
    coordinates: "58.45000076293945, 29.084199905395508",
  },
  {
    ident: "OIKP",
    type: "small_airport",
    name: "Havadarya Airport",
    elevation_ft: "19",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-23",
    municipality: "Havadarya",
    gps_code: "OIKP",
    iata_code: "HDR",
    coordinates: "56.17250061035156, 27.158300399780273",
  },
  {
    ident: "OIKR",
    type: "medium_airport",
    name: "Rafsanjan Airport",
    elevation_ft: "5298",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-15",
    gps_code: "OIKR",
    iata_code: "RJN",
    coordinates: "56.05110168457031, 30.297700881958008",
  },
  {
    ident: "OIKY",
    type: "medium_airport",
    name: "Sirjan Airport",
    elevation_ft: "5846",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-15",
    gps_code: "OIKY",
    iata_code: "SYJ",
    coordinates: "55.672698974609375, 29.550899505615234",
  },
  {
    ident: "OIMB",
    type: "medium_airport",
    name: "Birjand Airport",
    elevation_ft: "4952",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-29",
    municipality: "Birjand",
    gps_code: "OIMB",
    iata_code: "XBJ",
    coordinates: "59.2661018371582, 32.898101806640625",
  },
  {
    ident: "OIMC",
    type: "medium_airport",
    name: "Sarakhs Airport",
    elevation_ft: "945",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-30",
    municipality: "Sarakhs",
    gps_code: "OIMC",
    iata_code: "CKT",
    coordinates: "61.06489944458008, 36.50120162963867",
  },
  {
    ident: "OIMJ",
    type: "small_airport",
    name: "Shahroud Airport",
    elevation_ft: "4215",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-12",
    gps_code: "OIMJ",
    iata_code: "RUD",
    coordinates: "55.104198455799995, 36.4253005981",
  },
  {
    ident: "OIMM",
    type: "large_airport",
    name: "Mashhad International Airport",
    elevation_ft: "3263",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-30",
    municipality: "Mashhad",
    gps_code: "OIMM",
    iata_code: "MHD",
    coordinates: "59.64099884033203, 36.235198974609375",
  },
  {
    ident: "OIMN",
    type: "medium_airport",
    name: "Bojnord Airport",
    elevation_ft: "3499",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-31",
    municipality: "Bojnord",
    gps_code: "OIMN",
    iata_code: "BJB",
    coordinates: "57.30820083618164, 37.49300003051758",
  },
  {
    ident: "OIMS",
    type: "medium_airport",
    name: "Sabzevar National Airport",
    elevation_ft: "3010",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-30",
    municipality: "Sabzevar",
    gps_code: "OIMS",
    iata_code: "AFZ",
    coordinates: "57.59519958496094, 36.16809844970703",
  },
  {
    ident: "OIMT",
    type: "medium_airport",
    name: "Tabas Airport",
    elevation_ft: "2312",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-25",
    municipality: "Tabas",
    gps_code: "OIMT",
    iata_code: "TCX",
    coordinates: "56.8927001953, 33.6678009033",
  },
  {
    ident: "OINE",
    type: "small_airport",
    name: "Kalaleh Airport",
    elevation_ft: "425",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-27",
    municipality: "Kalaleh",
    gps_code: "OINE",
    iata_code: "KLM",
    coordinates: "55.4519996643, 37.3833007812",
  },
  {
    ident: "OING",
    type: "medium_airport",
    name: "Gorgan Airport",
    elevation_ft: "-24",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-27",
    municipality: "Gorgan",
    gps_code: "OING",
    iata_code: "GBT",
    coordinates: "54.4012985229, 36.909400939899996",
  },
  {
    ident: "OINJ",
    type: "small_airport",
    name: "Bishe Kola Air Base",
    elevation_ft: "-79",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-21",
    municipality: "Amol",
    gps_code: "OINJ",
    iata_code: "BSM",
    coordinates: "52.34960174560547, 36.65510177612305",
  },
  {
    ident: "OINN",
    type: "medium_airport",
    name: "Noshahr Airport",
    elevation_ft: "-61",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-21",
    gps_code: "OINN",
    iata_code: "NSH",
    coordinates: "51.464698791503906, 36.663299560546875",
  },
  {
    ident: "OINR",
    type: "medium_airport",
    name: "Ramsar Airport",
    elevation_ft: "-70",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-21",
    gps_code: "OINR",
    iata_code: "RZR",
    coordinates: "50.67959976196289, 36.9099006652832",
  },
  {
    ident: "OINZ",
    type: "medium_airport",
    name: "Dasht-e Naz Airport",
    elevation_ft: "35",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-21",
    municipality: "Sari",
    gps_code: "OINZ",
    iata_code: "SRY",
    coordinates: "53.193599700899995, 36.635799408",
  },
  {
    ident: "OISF",
    type: "medium_airport",
    name: "Fasa Airport",
    elevation_ft: "4261",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-14",
    municipality: "Fasa",
    gps_code: "OISF",
    iata_code: "FAZ",
    coordinates: "53.72330093383789, 28.891799926757812",
  },
  {
    ident: "OISJ",
    type: "small_airport",
    name: "Jahrom Airport",
    elevation_ft: "3358",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-14",
    municipality: "Jahrom",
    gps_code: "OISJ",
    iata_code: "JAR",
    coordinates: "53.5791015625, 28.586700439499996",
  },
  {
    ident: "OISL",
    type: "medium_airport",
    name: "Lar Airport",
    elevation_ft: "2641",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-14",
    municipality: "Lar",
    gps_code: "OISL",
    iata_code: "LRR",
    coordinates: "54.3833007812, 27.6746997833",
  },
  {
    ident: "OISR",
    type: "small_airport",
    name: "Lamerd Airport",
    elevation_ft: "1337",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-14",
    municipality: "Lamerd",
    gps_code: "OISR",
    iata_code: "LFM",
    coordinates: "53.18880081179999, 27.3726997375",
  },
  {
    ident: "OISS",
    type: "large_airport",
    name: "Shiraz Shahid Dastghaib International Airport",
    elevation_ft: "4927",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-14",
    municipality: "Shiraz",
    gps_code: "OISS",
    iata_code: "SYZ",
    coordinates: "52.589802, 29.5392",
  },
  {
    ident: "OISY",
    type: "medium_airport",
    name: "Yasouj Airport",
    elevation_ft: "5939",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-18",
    municipality: "Yasouj",
    gps_code: "OISY",
    iata_code: "YES",
    coordinates: "51.545101165771, 30.700500488281",
  },
  {
    ident: "OITK",
    type: "small_airport",
    name: "Khoy Airport",
    elevation_ft: "3981",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-02",
    municipality: "Khoy",
    gps_code: "OITK",
    iata_code: "KHY",
    coordinates: "44.97359848022461, 38.4275016784668",
  },
  {
    ident: "OITL",
    type: "medium_airport",
    name: "Ardabil Airport",
    elevation_ft: "4315",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-03",
    municipality: "Ardabil",
    gps_code: "OITL",
    iata_code: "ADU",
    coordinates: "48.4244003296, 38.3256988525",
  },
  {
    ident: "OITM",
    type: "small_airport",
    name: "Sahand Airport",
    elevation_ft: "4396",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-01",
    municipality: "Maragheh",
    gps_code: "OITM",
    iata_code: "ACP",
    coordinates: "46.127899169921875, 37.347999572753906",
  },
  {
    ident: "OITP",
    type: "small_airport",
    name: "Parsabade Moghan Airport",
    elevation_ft: "251",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-03",
    municipality: "Parsabad",
    gps_code: "OITP",
    iata_code: "PFQ",
    coordinates: "47.881500244141, 39.60359954834",
  },
  {
    ident: "OITR",
    type: "medium_airport",
    name: "Urmia Airport",
    elevation_ft: "4343",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-02",
    municipality: "Urmia",
    gps_code: "OITR",
    iata_code: "OMH",
    coordinates: "45.0686988831, 37.6680984497",
  },
  {
    ident: "OITT",
    type: "large_airport",
    name: "Tabriz International Airport",
    elevation_ft: "4459",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-01",
    municipality: "Tabriz",
    gps_code: "OITT",
    iata_code: "TBZ",
    coordinates: "46.23500061035156, 38.1338996887207",
  },
  {
    ident: "OITU",
    type: "small_airport",
    name: "Maku National Airport",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-02",
    municipality: "Showt",
    gps_code: "OITU",
    iata_code: "IMQ",
    coordinates: "44.43, 39.330002",
  },
  {
    ident: "OITZ",
    type: "medium_airport",
    name: "Zanjan Airport",
    elevation_ft: "5382",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-11",
    municipality: "Zanjan",
    gps_code: "OITZ",
    iata_code: "JWN",
    coordinates: "48.3594017029, 36.7737007141",
  },
  {
    ident: "OIYY",
    type: "medium_airport",
    name: "Shahid Sadooghi Airport",
    elevation_ft: "4054",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-25",
    municipality: "Yazd",
    gps_code: "OIYY",
    iata_code: "AZD",
    coordinates: "54.2765007019, 31.9048995972",
  },
  {
    ident: "OIZB",
    type: "medium_airport",
    name: "Zabol Airport",
    elevation_ft: "1628",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-13",
    gps_code: "OIZB",
    iata_code: "ACZ",
    coordinates: "61.54389953613281, 31.09830093383789",
  },
  {
    ident: "OIZC",
    type: "medium_airport",
    name: "Konarak Airport",
    elevation_ft: "43",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-13",
    municipality: "Chabahar",
    gps_code: "OIZC",
    iata_code: "ZBR",
    coordinates: "60.3820991516, 25.443300247199996",
  },
  {
    ident: "OIZH",
    type: "medium_airport",
    name: "Zahedan International Airport",
    elevation_ft: "4564",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-13",
    municipality: "Zahedan",
    gps_code: "OIZH",
    iata_code: "ZAH",
    coordinates: "60.90620040893555, 29.47570037841797",
  },
  {
    ident: "OIZI",
    type: "medium_airport",
    name: "Iran Shahr Airport",
    elevation_ft: "2040",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-13",
    municipality: "Iranshahr",
    gps_code: "OIZI",
    iata_code: "IHR",
    coordinates: "60.7200012207, 27.2360992432",
  },
  {
    ident: "OIZJ",
    type: "small_airport",
    name: "Jask Airport",
    elevation_ft: "19",
    continent: "AS",
    iso_country: "IR",
    iso_region: "IR-23",
    municipality: "Jask",
    gps_code: "OIZJ",
    iata_code: "JSK",
    coordinates: "57.799301, 25.653601",
  },
  {
    ident: "OJAI",
    type: "large_airport",
    name: "Queen Alia International Airport",
    elevation_ft: "2395",
    continent: "AS",
    iso_country: "JO",
    iso_region: "JO-AM",
    municipality: "Amman",
    gps_code: "OJAI",
    iata_code: "AMM",
    coordinates: "35.9931983948, 31.7226009369",
  },
  {
    ident: "OJAM",
    type: "medium_airport",
    name: "Amman-Marka International Airport",
    elevation_ft: "2555",
    continent: "AS",
    iso_country: "JO",
    iso_region: "JO-AM",
    municipality: "Amman",
    gps_code: "OJAM",
    iata_code: "ADJ",
    coordinates: "35.991600036621094, 31.972700119018555",
  },
  {
    ident: "OJAQ",
    type: "medium_airport",
    name: "Aqaba King Hussein International Airport",
    elevation_ft: "175",
    continent: "AS",
    iso_country: "JO",
    iso_region: "JO-AQ",
    municipality: "Aqaba",
    gps_code: "OJAQ",
    iata_code: "AQJ",
    coordinates: "35.01810073852539, 29.611600875854492",
  },
  {
    ident: "OJJR",
    type: "closed",
    name: "Jerusalem Airport",
    elevation_ft: "2485",
    continent: "AS",
    iso_country: "PS",
    iso_region: "PS-JEM",
    municipality: "Jerusalem",
    gps_code: "LLJR",
    iata_code: "JRS",
    coordinates: "35.2192, 31.8647",
  },
  {
    ident: "OJMF",
    type: "small_airport",
    name: "King Hussein Air College",
    elevation_ft: "2240",
    continent: "AS",
    iso_country: "JO",
    iso_region: "JO-MA",
    municipality: "Mafraq",
    gps_code: "OJMF",
    iata_code: "OMF",
    coordinates: "36.259201, 32.3564",
  },
  {
    ident: "OK03",
    type: "closed",
    name: "Downtown Airpark",
    elevation_ft: "1180",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OK",
    municipality: "Oklahoma City",
    iata_code: "DWN",
    coordinates: "-97.532989, 35.449183",
  },
  {
    ident: "OKAJ",
    type: "medium_airport",
    name: "Ahmed Al Jaber Air Base",
    elevation_ft: "409",
    continent: "AS",
    iso_country: "KW",
    iso_region: "KW-AH",
    municipality: "Ahmed Al Jaber AB",
    gps_code: "OKAJ",
    iata_code: "XIJ",
    coordinates: "47.791900634799994, 28.9347991943",
  },
  {
    ident: "OKBK",
    type: "large_airport",
    name: "Kuwait International Airport",
    elevation_ft: "206",
    continent: "AS",
    iso_country: "KW",
    iso_region: "KW-FA",
    municipality: "Kuwait City",
    gps_code: "OKBK",
    iata_code: "KWI",
    coordinates: "47.96889877319336, 29.226600646972656",
  },
  {
    ident: "OKV",
    type: "small_airport",
    name: "Okao Airport",
    elevation_ft: "450",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Okao",
    gps_code: "AYOF",
    iata_code: "OKV",
    local_code: "OKO",
    coordinates: "141.032777778, -5.55666666667",
  },
  {
    ident: "OLBA",
    type: "large_airport",
    name: "Beirut Rafic Hariri International Airport",
    elevation_ft: "87",
    continent: "AS",
    iso_country: "LB",
    iso_region: "LB-JL",
    municipality: "Beirut",
    gps_code: "OLBA",
    iata_code: "BEY",
    coordinates: "35.488399505615234, 33.820899963378906",
  },
  {
    ident: "OLKA",
    type: "medium_airport",
    name: "Rene Mouawad Air Base",
    elevation_ft: "75",
    continent: "AS",
    iso_country: "LB",
    iso_region: "LB-AS",
    municipality: "Tripoli",
    gps_code: "OLKA",
    iata_code: "KYE",
    coordinates: "36.01129913330078, 34.589298248291016",
  },
  {
    ident: "OLQ",
    type: "small_airport",
    name: "Olsobip Airport",
    elevation_ft: "1500",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Olsobip",
    gps_code: "AYOV",
    iata_code: "OLQ",
    local_code: "OLS",
    coordinates: "141.515277778, -5.3897222222200005",
  },
  {
    ident: "OM-0001",
    type: "small_airport",
    name: "Dibba Airport",
    elevation_ft: "45",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-MU",
    municipality: "Dibba Al-Baya",
    iata_code: "BYB",
    coordinates: "56.2444000244, 25.6142292023",
  },
  {
    ident: "OM-0002",
    type: "small_airport",
    name: "Adam Airport",
    elevation_ft: "1075",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-DA",
    municipality: "Adam",
    iata_code: "AOM",
    coordinates: "57.3838888889, 22.4919444444",
  },
  {
    ident: "OM-0003",
    type: "large_airport",
    name: "Duqm International Airport",
    elevation_ft: "364",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-WU",
    municipality: "Duqm",
    gps_code: "OODQ",
    iata_code: "DQM",
    coordinates: "57.634167, 19.501944",
  },
  {
    ident: "OM-0004",
    type: "large_airport",
    name: "Rustaq Airport",
    elevation_ft: "349",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-BA",
    municipality: "Al Masna'ah",
    gps_code: "OORQ",
    iata_code: "MNH",
    coordinates: "57.4875, 23.640556",
  },
  {
    ident: "OMAA",
    type: "large_airport",
    name: "Abu Dhabi International Airport",
    elevation_ft: "88",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-AZ",
    municipality: "Abu Dhabi",
    gps_code: "OMAA",
    iata_code: "AUH",
    coordinates: "54.651100158691406, 24.433000564575195",
  },
  {
    ident: "OMAD",
    type: "medium_airport",
    name: "Bateen Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-AZ",
    gps_code: "OMAD",
    iata_code: "AZI",
    coordinates: "54.458099365234375, 24.428300857543945",
  },
  {
    ident: "OMAL",
    type: "medium_airport",
    name: "Al Ain International Airport",
    elevation_ft: "869",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-AZ",
    municipality: "Al Ain",
    gps_code: "OMAL",
    iata_code: "AAN",
    coordinates: "55.60919952392578, 24.261699676513672",
  },
  {
    ident: "OMAM",
    type: "medium_airport",
    name: "Al Dhafra Air Base",
    elevation_ft: "77",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-AZ",
    gps_code: "OMAM",
    iata_code: "DHF",
    coordinates: "54.547698974599996, 24.248199462900004",
  },
  {
    ident: "OMBY",
    type: "medium_airport",
    name: "Sir Bani Yas Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-AZ",
    gps_code: "OMBY",
    iata_code: "XSB",
    local_code: "OMBY",
    coordinates: "52.580278, 24.283611",
  },
  {
    ident: "OMDB",
    type: "large_airport",
    name: "Dubai International Airport",
    elevation_ft: "62",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-DU",
    municipality: "Dubai",
    gps_code: "OMDB",
    iata_code: "DXB",
    coordinates: "55.3643989563, 25.2527999878",
  },
  {
    ident: "OMDM",
    type: "medium_airport",
    name: "Al Minhad Air Base",
    elevation_ft: "165",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-DU",
    municipality: "Dubai",
    gps_code: "OMDM",
    iata_code: "NHD",
    coordinates: "55.3661994934, 25.0268001556",
  },
  {
    ident: "OMDW",
    type: "large_airport",
    name: "Al Maktoum International Airport",
    elevation_ft: "114",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-DU",
    municipality: "Jebel Ali",
    gps_code: "OMDW",
    iata_code: "DWC",
    coordinates: "55.161389, 24.896356",
  },
  {
    ident: "OMFJ",
    type: "medium_airport",
    name: "Fujairah International Airport",
    elevation_ft: "152",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-FU",
    gps_code: "OMFJ",
    iata_code: "FJR",
    coordinates: "56.32400131225586, 25.112199783325195",
  },
  {
    ident: "OML",
    type: "closed",
    name: "Omkalai Airport",
    elevation_ft: "5700",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPK",
    municipality: "Omkalai",
    iata_code: "OML",
    coordinates: "144.96, -6.179",
  },
  {
    ident: "OMN",
    type: "small_airport",
    name: "Osmanabad Airport",
    elevation_ft: "2277",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Osmanabad",
    iata_code: "OMN",
    coordinates: "76.0574, 18.281",
  },
  {
    ident: "OMRK",
    type: "medium_airport",
    name: "Ras Al Khaimah International Airport",
    elevation_ft: "102",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-RK",
    municipality: "Ras Al Khaimah",
    gps_code: "OMRK",
    iata_code: "RKT",
    coordinates: "55.93880081176758, 25.613500595092773",
  },
  {
    ident: "OMSJ",
    type: "large_airport",
    name: "Sharjah International Airport",
    elevation_ft: "111",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-SH",
    municipality: "Sharjah",
    gps_code: "OMSJ",
    iata_code: "SHJ",
    coordinates: "55.5172004699707, 25.32859992980957",
  },
  {
    ident: "OMY",
    type: "small_airport",
    name: "Preah Vinhear Airport",
    elevation_ft: "350",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-13",
    municipality: "Tbeng Meanchey",
    iata_code: "OMY",
    coordinates: "104.97173, 13.7597",
  },
  {
    ident: "ONB",
    type: "small_airport",
    name: "Ononge Airport",
    elevation_ft: "5800",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Onange Mission",
    gps_code: "AYQQ",
    iata_code: "ONB",
    local_code: "ONG",
    coordinates: "147.262416667, -8.67436111111",
  },
  {
    ident: "OOBR",
    type: "small_airport",
    name: "Buraimi Airport",
    elevation_ft: "970",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-BU",
    municipality: "Buraimi",
    gps_code: "OOBR",
    iata_code: "RMB",
    coordinates: "55.784722, 24.241111",
  },
  {
    ident: "OOFD",
    type: "small_airport",
    name: "Fahud Airport",
    elevation_ft: "552",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-ZA",
    municipality: "Fahud",
    gps_code: "OOFD",
    iata_code: "FAU",
    coordinates: "56.4841461182, 22.354759318",
  },
  {
    ident: "OOGB",
    type: "small_airport",
    name: "Qarn Alam Airport",
    elevation_ft: "443",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-WU",
    municipality: "Ghaba",
    gps_code: "OOGB",
    iata_code: "RNM",
    coordinates: "57.0499992371, 21.3829994202",
  },
  {
    ident: "OOJA",
    type: "small_airport",
    name: "Ja'Aluni Airport",
    elevation_ft: "560",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-WU",
    municipality: "Duqm",
    gps_code: "OOJA",
    iata_code: "JNJ",
    coordinates: "57.3083, 19.4749",
  },
  {
    ident: "OOKB",
    type: "medium_airport",
    name: "Khasab Air Base",
    elevation_ft: "100",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-MU",
    municipality: "Khasab",
    gps_code: "OOKB",
    iata_code: "KHS",
    coordinates: "56.2406005859375, 26.17099952697754",
  },
  {
    ident: "OOLK",
    type: "small_airport",
    name: "Lekhwair Airport",
    elevation_ft: "354",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-ZA",
    gps_code: "OOLK",
    iata_code: "LKW",
    coordinates: "55.3733682632, 22.8049846844",
  },
  {
    ident: "OOMA",
    type: "medium_airport",
    name: "Masirah Air Base",
    elevation_ft: "64",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-SH",
    municipality: "Masirah",
    gps_code: "OOMA",
    iata_code: "MSH",
    coordinates: "58.890499114990234, 20.675399780273438",
  },
  {
    ident: "OOMS",
    type: "large_airport",
    name: "Muscat International Airport",
    elevation_ft: "48",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-MA",
    municipality: "Muscat",
    gps_code: "OOMS",
    iata_code: "MCT",
    coordinates: "58.284400939941406, 23.593299865722656",
  },
  {
    ident: "OOMX",
    type: "small_airport",
    name: "Marmul Airport",
    elevation_ft: "925",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-ZU",
    municipality: "Marmul",
    gps_code: "OOMX",
    iata_code: "OMM",
    coordinates: "55.182098388671875, 18.13599967956543",
  },
  {
    ident: "OOSA",
    type: "medium_airport",
    name: "Salalah Airport",
    elevation_ft: "73",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-ZU",
    municipality: "Salalah",
    gps_code: "OOSA",
    iata_code: "SLL",
    coordinates: "54.09130096435547, 17.038700103759766",
  },
  {
    ident: "OOSH",
    type: "small_airport",
    name: "Sohar Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-BA",
    municipality: "Sohar",
    gps_code: "OOSH",
    iata_code: "OHS",
    coordinates: "56.62541, 24.38604",
  },
  {
    ident: "OOSR",
    type: "small_airport",
    name: "Sur Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-SH",
    municipality: "Sur",
    gps_code: "OOSR",
    iata_code: "SUH",
    coordinates: "59.483001708984375, 22.533000946044922",
  },
  {
    ident: "OOTH",
    type: "medium_airport",
    name: "Thumrait Air Base",
    elevation_ft: "1570",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-ZU",
    municipality: "Thumrait",
    gps_code: "OOTH",
    iata_code: "TTH",
    coordinates: "54.024600982666016, 17.666000366210938",
  },
  {
    ident: "OP17",
    type: "small_airport",
    name: "Dadu West Airport",
    elevation_ft: "128",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Dadu",
    gps_code: "OP17",
    iata_code: "DDU",
    local_code: "OP17",
    coordinates: "67.6666030883789, 26.740800857543945",
  },
  {
    ident: "OPBG",
    type: "small_airport",
    name: "Bhagatanwala Airport",
    elevation_ft: "600",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Bhagatanwala",
    gps_code: "OPBG",
    iata_code: "BHW",
    coordinates: "72.94840240479999, 32.056098938",
  },
  {
    ident: "OPBN",
    type: "small_airport",
    name: "Bannu Airport",
    elevation_ft: "1325",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NW",
    municipality: "Bannu",
    gps_code: "OPBN",
    iata_code: "BNP",
    coordinates: "70.527901, 32.9729",
  },
  {
    ident: "OPBR",
    type: "small_airport",
    name: "Bahawalnagar Airport",
    elevation_ft: "500",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Bahawalnagar",
    gps_code: "OPBR",
    iata_code: "WGB",
    coordinates: "73.24909973144531, 29.946300506591797",
  },
  {
    ident: "OPBW",
    type: "medium_airport",
    name: "Bahawalpur Airport",
    elevation_ft: "392",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Bahawalpur",
    gps_code: "OPBW",
    iata_code: "BHV",
    coordinates: "71.71800231933594, 29.348100662231445",
  },
  {
    ident: "OPCH",
    type: "medium_airport",
    name: "Chitral Airport",
    elevation_ft: "4920",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NW",
    municipality: "Chitral",
    gps_code: "OPCH",
    iata_code: "CJL",
    coordinates: "71.80059814453125, 35.886600494384766",
  },
  {
    ident: "OPCL",
    type: "small_airport",
    name: "Chilas Airport",
    elevation_ft: "4146",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NA",
    municipality: "Chilas",
    gps_code: "OPCL",
    iata_code: "CHB",
    coordinates: "74.081703186, 35.426700592",
  },
  {
    ident: "OPDB",
    type: "small_airport",
    name: "Dalbandin Airport",
    elevation_ft: "2800",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Dalbandin",
    gps_code: "OPDB",
    iata_code: "DBA",
    coordinates: "64.3998031616, 28.878299713100002",
  },
  {
    ident: "OPDD",
    type: "small_airport",
    name: "Dadu Airport",
    elevation_ft: "121",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Dadu",
    gps_code: "OPDD",
    iata_code: "DDU",
    coordinates: "67.67459869380001, 26.5545005798",
  },
  {
    ident: "OPDG",
    type: "medium_airport",
    name: "Dera Ghazi Khan Airport",
    elevation_ft: "492",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Dera Ghazi Khan",
    gps_code: "OPDG",
    iata_code: "DEA",
    coordinates: "70.48590087890625, 29.961000442504883",
  },
  {
    ident: "OPDI",
    type: "medium_airport",
    name: "Dera Ismael Khan Airport",
    elevation_ft: "594",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NW",
    municipality: "Dera Ismael Khan",
    gps_code: "OPDI",
    iata_code: "DSK",
    coordinates: "70.89659881591797, 31.909400939941406",
  },
  {
    ident: "OPFA",
    type: "medium_airport",
    name: "Faisalabad International Airport",
    elevation_ft: "591",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Faisalabad",
    gps_code: "OPFA",
    iata_code: "LYP",
    coordinates: "72.99479675292969, 31.364999771118164",
  },
  {
    ident: "OPGD",
    type: "medium_airport",
    name: "Gwadar International Airport",
    elevation_ft: "36",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Gwadar",
    gps_code: "OPGD",
    iata_code: "GWD",
    coordinates: "62.329498291015625, 25.233299255371094",
  },
  {
    ident: "OPGT",
    type: "medium_airport",
    name: "Gilgit Airport",
    elevation_ft: "4796",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NA",
    municipality: "Gilgit",
    gps_code: "OPGT",
    iata_code: "GIL",
    coordinates: "74.33360290527344, 35.918800354003906",
  },
  {
    ident: "OPIS",
    type: "large_airport",
    name: "Islamabad International Airport",
    elevation_ft: "1761",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Islamabad",
    gps_code: "OPIS",
    iata_code: "ISB",
    coordinates: "72.82566, 33.549",
  },
  {
    ident: "OPJA",
    type: "medium_airport",
    name: "Shahbaz Air Base",
    elevation_ft: "185",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Jacobabad",
    gps_code: "OPJA",
    iata_code: "JAG",
    coordinates: "68.44969940185547, 28.28420066833496",
  },
  {
    ident: "OPJI",
    type: "small_airport",
    name: "Jiwani Airport",
    elevation_ft: "186",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Jiwani",
    gps_code: "OPJI",
    iata_code: "JIW",
    coordinates: "61.8054008484, 25.067800521900004",
  },
  {
    ident: "OPKC",
    type: "medium_airport",
    name: "Jinnah International Airport",
    elevation_ft: "100",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Karachi",
    gps_code: "OPKC",
    iata_code: "KHI",
    coordinates: "67.160797, 24.9065",
  },
  {
    ident: "OPKD",
    type: "small_airport",
    name: "Hyderabad Airport",
    elevation_ft: "130",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Hyderabad",
    gps_code: "OPKD",
    iata_code: "HDD",
    coordinates: "68.366096, 25.3181",
  },
  {
    ident: "OPKH",
    type: "small_airport",
    name: "Khuzdar Airport",
    elevation_ft: "4012",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Khuzdar",
    gps_code: "OPKH",
    iata_code: "KDD",
    coordinates: "66.6473007202, 27.790599823",
  },
  {
    ident: "OPKL",
    type: "small_airport",
    name: "Kalat Airport",
    elevation_ft: "6100",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Kalat",
    gps_code: "OPKL",
    iata_code: "KBH",
    coordinates: "66.51667022705078, 29.133333206176758",
  },
  {
    ident: "OPKT",
    type: "small_airport",
    name: "Kohat Airport",
    elevation_ft: "1650",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NW",
    municipality: "Kohat",
    gps_code: "OPKT",
    iata_code: "OHT",
    coordinates: "71.4400024414, 33.5699996948",
  },
  {
    ident: "OPLA",
    type: "medium_airport",
    name: "Alama Iqbal International Airport",
    elevation_ft: "712",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Lahore",
    gps_code: "OPLA",
    iata_code: "LHE",
    coordinates: "74.40360260009766, 31.5216007232666",
  },
  {
    ident: "OPLL",
    type: "small_airport",
    name: "Loralai Airport",
    elevation_ft: "4631",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Loralai",
    gps_code: "OPLL",
    iata_code: "LRG",
    coordinates: "68.6135025024414, 30.355499267578125",
  },
  {
    ident: "OPMA",
    type: "medium_airport",
    name: "Mangla Airport",
    elevation_ft: "902",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Mangla",
    gps_code: "OPMA",
    iata_code: "XJM",
    coordinates: "73.63839721679688, 33.05009841918945",
  },
  {
    ident: "OPMF",
    type: "medium_airport",
    name: "Muzaffarabad Airport",
    elevation_ft: "2691",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-JK",
    municipality: "Muzaffarabad",
    gps_code: "OPMF",
    iata_code: "MFG",
    coordinates: "73.50859832763672, 34.3390007019043",
  },
  {
    ident: "OPMI",
    type: "medium_airport",
    name: "Mianwali Air Base",
    elevation_ft: "690",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Mianwali",
    gps_code: "OPMI",
    iata_code: "MWD",
    coordinates: "71.5707015991211, 32.5630989074707",
  },
  {
    ident: "OPMJ",
    type: "medium_airport",
    name: "Moenjodaro Airport",
    elevation_ft: "154",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Moenjodaro",
    gps_code: "OPMJ",
    iata_code: "MJD",
    coordinates: "68.14309692382812, 27.3351993560791",
  },
  {
    ident: "OPMP",
    type: "small_airport",
    name: "Sindhri Tharparkar Airport",
    elevation_ft: "51",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Sindhri",
    gps_code: "OPMP",
    iata_code: "MPD",
    coordinates: "69.07279968261719, 25.68280029296875",
  },
  {
    ident: "OPMS",
    type: "medium_airport",
    name: "Minhas Air Base",
    elevation_ft: "1023",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Kamra",
    gps_code: "OPMS",
    iata_code: "ATG",
    coordinates: "72.4009017944, 33.8690986633",
  },
  {
    ident: "OPMT",
    type: "medium_airport",
    name: "Multan International Airport",
    elevation_ft: "403",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Multan",
    gps_code: "OPMT",
    iata_code: "MUX",
    coordinates: "71.41909790039062, 30.20319938659668",
  },
  {
    ident: "OPNH",
    type: "medium_airport",
    name: "Shaheed Benazirabad Airport",
    elevation_ft: "95",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Nawabashah",
    gps_code: "OPNH",
    iata_code: "WNS",
    coordinates: "68.390099, 26.2194",
  },
  {
    ident: "OPNK",
    type: "small_airport",
    name: "Nushki Airport",
    elevation_ft: "3200",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Nushki",
    gps_code: "OPNK",
    iata_code: "NHS",
    coordinates: "66.02330017089844, 29.538999557495117",
  },
  {
    ident: "OPOR",
    type: "small_airport",
    name: "Ormara Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Ormara Raik",
    gps_code: "OPOR",
    iata_code: "ORW",
    coordinates: "64.58599853519999, 25.274700164799995",
  },
  {
    ident: "OPPC",
    type: "small_airport",
    name: "Parachinar Airport",
    elevation_ft: "5800",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-TA",
    municipality: "Parachinar",
    gps_code: "OPPC",
    iata_code: "PAJ",
    coordinates: "70.0716018677, 33.902099609400004",
  },
  {
    ident: "OPPG",
    type: "medium_airport",
    name: "Panjgur Airport",
    elevation_ft: "3289",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Panjgur",
    gps_code: "OPPG",
    iata_code: "PJG",
    coordinates: "64.13249969482422, 26.954500198364258",
  },
  {
    ident: "OPPI",
    type: "medium_airport",
    name: "Pasni Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Pasni",
    gps_code: "OPPI",
    iata_code: "PSI",
    coordinates: "63.34510040283203, 25.29050064086914",
  },
  {
    ident: "OPPS",
    type: "medium_airport",
    name: "Peshawar International Airport",
    elevation_ft: "1158",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NW",
    municipality: "Peshawar",
    gps_code: "OPPS",
    iata_code: "PEW",
    coordinates: "71.51460266113281, 33.993900299072266",
  },
  {
    ident: "OPQT",
    type: "medium_airport",
    name: "Quetta International Airport",
    elevation_ft: "5267",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Quetta",
    gps_code: "OPQT",
    iata_code: "UET",
    coordinates: "66.93779754638672, 30.251399993896484",
  },
  {
    ident: "OPRK",
    type: "medium_airport",
    name: "Shaikh Zaid Airport",
    elevation_ft: "271",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Rahim Yar Khan",
    gps_code: "OPRK",
    iata_code: "RYK",
    coordinates: "70.27960205078125, 28.383899688720703",
  },
  {
    ident: "OPRT",
    type: "medium_airport",
    name: "Rawalakot Airport",
    elevation_ft: "5479",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-JK",
    municipality: "Rawalakot",
    gps_code: "OPRT",
    iata_code: "RAZ",
    coordinates: "73.79810333251953, 33.849700927734375",
  },
  {
    ident: "OPSB",
    type: "small_airport",
    name: "Sibi Airport",
    elevation_ft: "436",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Sibi",
    gps_code: "OPSB",
    iata_code: "SBQ",
    coordinates: "67.847900390625, 29.571199417114258",
  },
  {
    ident: "OPSD",
    type: "medium_airport",
    name: "Skardu Airport",
    elevation_ft: "7316",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NA",
    municipality: "Skardu",
    gps_code: "OPSD",
    iata_code: "KDU",
    coordinates: "75.53600311279297, 35.33549880981445",
  },
  {
    ident: "OPSK",
    type: "medium_airport",
    name: "Sukkur Airport",
    elevation_ft: "196",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Mirpur Khas",
    gps_code: "OPSK",
    iata_code: "SKZ",
    coordinates: "68.79170227050781, 27.722000122070312",
  },
  {
    ident: "OPSN",
    type: "medium_airport",
    name: "Sehwan Sharif Airport",
    elevation_ft: "121",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Sehwan Sharif",
    gps_code: "OPSN",
    iata_code: "SYW",
    coordinates: "67.71720123291016, 26.473100662231445",
  },
  {
    ident: "OPSR",
    type: "medium_airport",
    name: "Mushaf Air Base",
    elevation_ft: "614",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Sargodha",
    gps_code: "OPSR",
    iata_code: "SGI",
    coordinates: "72.66500091552734, 32.04859924316406",
  },
  {
    ident: "OPSS",
    type: "medium_airport",
    name: "Saidu Sharif Airport",
    elevation_ft: "3183",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NW",
    municipality: "Saidu Sharif",
    gps_code: "OPSS",
    iata_code: "SDT",
    coordinates: "72.35279846191406, 34.8135986328125",
  },
  {
    ident: "OPST",
    type: "large_airport",
    name: "Sialkot Airport",
    elevation_ft: "837",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Sialkot",
    gps_code: "OPST",
    iata_code: "SKT",
    coordinates: "74.3638916016, 32.5355567932",
  },
  {
    ident: "OPSU",
    type: "medium_airport",
    name: "Sui Airport",
    elevation_ft: "763",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Sui",
    gps_code: "OPSU",
    iata_code: "SUL",
    coordinates: "69.1769027709961, 28.645099639892578",
  },
  {
    ident: "OPSW",
    type: "small_airport",
    name: "Sahiwal Airport",
    elevation_ft: "570",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Sahiwal",
    gps_code: "OPSW",
    iata_code: "SWN",
    coordinates: "72.39167022705078, 31.88944435119629",
  },
  {
    ident: "OPTA",
    type: "small_airport",
    name: "Tarbela Dam Airport",
    elevation_ft: "1114",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NW",
    municipality: "Tarbela",
    gps_code: "OPTA",
    iata_code: "TLB",
    coordinates: "72.61139678960001, 33.9860992432",
  },
  {
    ident: "OPTH",
    type: "small_airport",
    name: "Talhar Airport",
    elevation_ft: "28",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Badin",
    gps_code: "OPTH",
    iata_code: "BDN",
    coordinates: "68.8384017944336, 24.84149932861328",
  },
  {
    ident: "OPTT",
    type: "small_airport",
    name: "Taftan Airport",
    elevation_ft: "2742",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Taftan",
    gps_code: "OPTT",
    iata_code: "TFT",
    coordinates: "61.595401763916016, 28.96419906616211",
  },
  {
    ident: "OPTU",
    type: "medium_airport",
    name: "Turbat International Airport",
    elevation_ft: "498",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Turbat",
    gps_code: "OPTU",
    iata_code: "TUK",
    coordinates: "63.03020095825195, 25.986400604248047",
  },
  {
    ident: "OPWN",
    type: "small_airport",
    name: "Wana Airport",
    elevation_ft: "4550",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-TA",
    municipality: "Waana",
    gps_code: "OPWN",
    iata_code: "WAF",
    coordinates: "69.57039642333984, 32.3046989440918",
  },
  {
    ident: "OPZB",
    type: "medium_airport",
    name: "Zhob Airport",
    elevation_ft: "4728",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Fort Sandeman",
    gps_code: "OPZB",
    iata_code: "PZH",
    coordinates: "69.4636001586914, 31.358400344848633",
  },
  {
    ident: "ORAA",
    type: "medium_airport",
    name: "Al Asad Air Base",
    elevation_ft: "618",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-AN",
    municipality: "H����t",
    gps_code: "ORAA",
    iata_code: "IQA",
    coordinates: "42.4412002563, 33.7855987549",
  },
  {
    ident: "ORAT",
    type: "medium_airport",
    name: "Al Taqaddum Air Base",
    elevation_ft: "275",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-AN",
    municipality: "Al Habbaniyah",
    gps_code: "ORAT",
    iata_code: "TQD",
    coordinates: "43.597099304199, 33.33810043335",
  },
  {
    ident: "ORBB",
    type: "small_airport",
    name: "Bamarni Airport",
    elevation_ft: "3455",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-DA",
    municipality: "Bamarni",
    gps_code: "ORBB",
    iata_code: "BMN",
    coordinates: "43.2666015625, 37.09880065917969",
  },
  {
    ident: "ORBI",
    type: "large_airport",
    name: "Baghdad International Airport",
    elevation_ft: "114",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-BG",
    municipality: "Baghdad",
    gps_code: "ORBI",
    iata_code: "BGW",
    coordinates: "44.2346000671, 33.262500762900004",
  },
  {
    ident: "ORBM",
    type: "medium_airport",
    name: "Mosul International Airport",
    elevation_ft: "719",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-NI",
    municipality: "Mosul",
    gps_code: "ORBM",
    iata_code: "OSM",
    coordinates: "43.1474, 36.305801",
  },
  {
    ident: "ORER",
    type: "medium_airport",
    name: "Erbil International Airport",
    elevation_ft: "1341",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-AR",
    municipality: "Arbil",
    gps_code: "ORER",
    iata_code: "EBL",
    coordinates: "43.963199615478516, 36.23759841918945",
  },
  {
    ident: "ORKK",
    type: "medium_airport",
    name: "Kirkuk Air Base",
    elevation_ft: "1061",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-TS",
    municipality: "Kirkuk",
    gps_code: "ORKK",
    iata_code: "KIK",
    coordinates: "44.348899841308594, 35.46950149536133",
  },
  {
    ident: "ORMM",
    type: "large_airport",
    name: "Basrah International Airport",
    elevation_ft: "11",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-BA",
    municipality: "Basrah",
    gps_code: "ORMM",
    iata_code: "BSR",
    coordinates: "47.66210174560547, 30.549100875854492",
  },
  {
    ident: "ORNI",
    type: "small_airport",
    name: "Al Najaf International Airport",
    elevation_ft: "103",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-NA",
    municipality: "Najaf",
    gps_code: "ORNI",
    iata_code: "NJF",
    coordinates: "44.404317, 31.989853",
  },
  {
    ident: "ORQW",
    type: "medium_airport",
    name: "Qayyarah West Airport",
    elevation_ft: "749",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-NI",
    municipality: "Qayyarah",
    gps_code: "ORQW",
    iata_code: "RQW",
    coordinates: "43.125099182099994, 35.76720047",
  },
  {
    ident: "ORSU",
    type: "medium_airport",
    name: "Sulaymaniyah International Airport",
    elevation_ft: "2494",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-SW",
    municipality: "Sulaymaniyah",
    gps_code: "ORSU",
    iata_code: "ISU",
    local_code: "OSO",
    coordinates: "45.316738128699996, 35.5617485046",
  },
  {
    ident: "ORTL",
    type: "medium_airport",
    name: "Ali Air Base",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "IQ",
    iso_region: "IQ-DQ",
    municipality: "Nasiriyah",
    gps_code: "ORTL",
    iata_code: "XNH",
    coordinates: "46.090099, 30.935801",
  },
  {
    ident: "OSAP",
    type: "large_airport",
    name: "Aleppo International Airport",
    elevation_ft: "1276",
    continent: "AS",
    iso_country: "SY",
    iso_region: "SY-HL",
    municipality: "Aleppo",
    gps_code: "OSAP",
    iata_code: "ALP",
    coordinates: "37.22439956665039, 36.18069839477539",
  },
  {
    ident: "OSDI",
    type: "large_airport",
    name: "Damascus International Airport",
    elevation_ft: "2020",
    continent: "AS",
    iso_country: "SY",
    iso_region: "SY-DI",
    municipality: "Damascus",
    gps_code: "OSDI",
    iata_code: "DAM",
    coordinates: "36.51559829711914, 33.4114990234375",
  },
  {
    ident: "OSDZ",
    type: "medium_airport",
    name: "Deir ez-Zor Air Base",
    elevation_ft: "700",
    continent: "AS",
    iso_country: "SY",
    iso_region: "SY-DY",
    municipality: "Deir ez-Zor",
    gps_code: "OSDZ",
    iata_code: "DEZ",
    coordinates: "40.175999, 35.2854",
  },
  {
    ident: "OSE",
    type: "small_airport",
    name: "Omora Airport",
    elevation_ft: "2540",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Omora",
    gps_code: "AYOM",
    iata_code: "OSE",
    local_code: "OOA",
    coordinates: "147.080638889, -7.82777777778",
  },
  {
    ident: "OSG",
    type: "small_airport",
    name: "Ossima Airport",
    elevation_ft: "205",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Ossima",
    gps_code: "AYZS",
    iata_code: "OSG",
    local_code: "OSA",
    coordinates: "141.297305556, -2.91555555556",
  },
  {
    ident: "OSKL",
    type: "medium_airport",
    name: "Qamishli Airport",
    elevation_ft: "1480",
    continent: "AS",
    iso_country: "SY",
    iso_region: "SY-HA",
    municipality: "Qamishly",
    gps_code: "OSKL",
    iata_code: "KAC",
    coordinates: "41.191399, 37.020599",
  },
  {
    ident: "OSLK",
    type: "large_airport",
    name: "Bassel Al-Assad International Airport",
    elevation_ft: "157",
    continent: "AS",
    iso_country: "SY",
    iso_region: "SY-LA",
    municipality: "Latakia",
    gps_code: "OSLK",
    iata_code: "LTK",
    coordinates: "35.948699951171875, 35.401100158691406",
  },
  {
    ident: "OSPR",
    type: "medium_airport",
    name: "Palmyra Airport",
    elevation_ft: "1322",
    continent: "AS",
    iso_country: "SY",
    iso_region: "SY-HI",
    municipality: "Tadmur",
    gps_code: "OSPR",
    iata_code: "PMS",
    coordinates: "38.316898, 34.5574",
  },
  {
    ident: "OTBD",
    type: "medium_airport",
    name: "Doha International Airport",
    elevation_ft: "35",
    continent: "AS",
    iso_country: "QA",
    iso_region: "QA-DA",
    municipality: "Doha",
    gps_code: "OTBD",
    iata_code: "DIA",
    coordinates: "51.565102, 25.261101",
  },
  {
    ident: "OTBH",
    type: "medium_airport",
    name: "Al Udeid Air Base",
    elevation_ft: "130",
    continent: "AS",
    iso_country: "QA",
    iso_region: "QA-RA",
    municipality: "Ar Rayyan",
    gps_code: "OTBH",
    iata_code: "XJD",
    coordinates: "51.3149986267, 25.1173000336",
  },
  {
    ident: "OTHH",
    type: "large_airport",
    name: "Hamad International Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "QA",
    iso_region: "QA-DA",
    municipality: "Doha",
    gps_code: "OTHH",
    iata_code: "DOH",
    coordinates: "51.608056, 25.273056",
  },
  {
    ident: "OTT",
    type: "small_airport",
    name: "Andre Maggi Airport",
    elevation_ft: "900",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Cotrigua����u",
    iata_code: "OTT",
    coordinates: "-58.581944, -9.898611",
  },
  {
    ident: "OTY",
    type: "small_airport",
    name: "Oria Airport",
    elevation_ft: "551",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Oria",
    iata_code: "OTY",
    local_code: "ORIA",
    coordinates: "155.771022, -6.577613",
  },
  {
    ident: "OUM",
    type: "small_airport",
    name: "Oum Hadjer Airport",
    elevation_ft: "1198",
    continent: "AF",
    iso_country: "TD",
    iso_region: "TD-BA",
    municipality: "Oum Hadjer",
    iata_code: "OUM",
    coordinates: "19.710256, 13.278244",
  },
  {
    ident: "OXO",
    type: "small_airport",
    name: "Orientos Airport",
    elevation_ft: "375",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Orientos",
    iata_code: "OXO",
    coordinates: "141.5361, -28.0598",
  },
  {
    ident: "OYAA",
    type: "medium_airport",
    name: "Aden International Airport",
    elevation_ft: "7",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-AD",
    municipality: "Aden",
    gps_code: "OYAA",
    iata_code: "ADE",
    coordinates: "45.02880096435547, 12.829500198364258",
  },
  {
    ident: "OYAB",
    type: "small_airport",
    name: "Abbse Airport",
    elevation_ft: "651",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-SN",
    municipality: "Abbse",
    gps_code: "OYAB",
    iata_code: "EAB",
    coordinates: "43.176667, 16.010833",
  },
  {
    ident: "OYAT",
    type: "medium_airport",
    name: "Ataq Airport",
    elevation_ft: "3735",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-AB",
    gps_code: "OYAT",
    iata_code: "AXK",
    coordinates: "46.82619857788086, 14.551300048828125",
  },
  {
    ident: "OYBI",
    type: "small_airport",
    name: "Al-Bayda Airport",
    elevation_ft: "6120",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-BA",
    municipality: "Al-Bayda",
    gps_code: "OYBI",
    iata_code: "BYD",
    coordinates: "45.441079, 14.105974",
  },
  {
    ident: "OYBN",
    type: "medium_airport",
    name: "Beihan Airport",
    elevation_ft: "3800",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-AB",
    gps_code: "OYBN",
    iata_code: "BHN",
    coordinates: "45.72010040283203, 14.781999588012695",
  },
  {
    ident: "OYBQ",
    type: "small_airport",
    name: "Al-Bough Airport",
    elevation_ft: "3800",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-SD",
    municipality: "Al-Bough",
    gps_code: "OYBQ",
    iata_code: "BUK",
    coordinates: "44.621700286899994, 17.3467006683",
  },
  {
    ident: "OYGD",
    type: "medium_airport",
    name: "Al Ghaidah International Airport",
    elevation_ft: "134",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-MR",
    gps_code: "OYGD",
    iata_code: "AAY",
    coordinates: "52.17499923706055, 16.191699981689453",
  },
  {
    ident: "OYHD",
    type: "medium_airport",
    name: "Hodeidah International Airport",
    elevation_ft: "41",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-HU",
    municipality: "Hodeida",
    gps_code: "OYHD",
    iata_code: "HOD",
    coordinates: "42.97629928588867, 14.753000259399414",
  },
  {
    ident: "OYKM",
    type: "small_airport",
    name: "Kamaran Airport",
    elevation_ft: "51",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-AD",
    municipality: "Kamaran",
    gps_code: "OYKM",
    iata_code: "KAM",
    coordinates: "42.6049995422, 15.3633003235",
  },
  {
    ident: "OYMB",
    type: "small_airport",
    name: "Mareb Airport",
    elevation_ft: "3300",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-MA",
    municipality: "Mareb",
    gps_code: "OYMB",
    iata_code: "MYN",
    coordinates: "45.326900482177734, 15.469200134277344",
  },
  {
    ident: "OYMS",
    type: "small_airport",
    name: "Mukeiras Airport",
    elevation_ft: "7080",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-BA",
    municipality: "Mukayras",
    gps_code: "OYMS",
    iata_code: "UKR",
    coordinates: "45.657, 13.9368",
  },
  {
    ident: "OYQN",
    type: "small_airport",
    name: "Qishn Airport",
    elevation_ft: "100",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-MR",
    municipality: "Qishn",
    gps_code: "OYQN",
    iata_code: "IHN",
    coordinates: "51.682999, 15.417",
  },
  {
    ident: "OYRN",
    type: "medium_airport",
    name: "Riyan Mukalla International Airport",
    elevation_ft: "54",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-HD",
    municipality: "Riyan",
    gps_code: "OYRN",
    iata_code: "RIY",
    coordinates: "49.375, 14.6626",
  },
  {
    ident: "OYSH",
    type: "small_airport",
    name: "Sadah Airport",
    elevation_ft: "5938",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-SD",
    municipality: "Sadah",
    gps_code: "OYSH",
    iata_code: "SYE",
    coordinates: "43.733002, 16.966999",
  },
  {
    ident: "OYSN",
    type: "medium_airport",
    name: "Sana'a International Airport",
    elevation_ft: "7216",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-SN",
    municipality: "Sana'a",
    gps_code: "OYSN",
    iata_code: "SAH",
    coordinates: "44.21969985961914, 15.476300239562988",
  },
  {
    ident: "OYSQ",
    type: "medium_airport",
    name: "Socotra International Airport",
    elevation_ft: "146",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-HD",
    municipality: "Socotra Islands",
    gps_code: "OYSQ",
    iata_code: "SCT",
    coordinates: "53.905799865722656, 12.63070011138916",
  },
  {
    ident: "OYSY",
    type: "medium_airport",
    name: "Sayun International Airport",
    elevation_ft: "2097",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-HD",
    municipality: "Sayun",
    gps_code: "OYSY",
    iata_code: "GXF",
    coordinates: "48.78829956049999, 15.9660997391",
  },
  {
    ident: "OYTZ",
    type: "medium_airport",
    name: "Ta'izz International Airport",
    elevation_ft: "4838",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-TA",
    municipality: "Ta'izz",
    gps_code: "OYTZ",
    iata_code: "TAI",
    coordinates: "44.139099121099996, 13.6859998703",
  },
  {
    ident: "PA-0001",
    type: "small_airport",
    name: "Achutupo Airport",
    elevation_ft: "1047",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-5",
    municipality: "Achutupo",
    iata_code: "ACU",
    coordinates: "-77.96520233150001, 8.43165969849",
  },
  {
    ident: "PA-0003",
    type: "small_airport",
    name: "Alligandi Airport",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Alligandi",
    iata_code: "AIL",
    coordinates: "-78.0236, 9.2226",
  },
  {
    ident: "PA-0004",
    type: "small_airport",
    name: "Carti Airport",
    elevation_ft: "785",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Carti",
    iata_code: "CTE",
    coordinates: "-79.101600647, 9.31346988678",
  },
  {
    ident: "PA-0011",
    type: "small_airport",
    name: "Mulatupo Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Mulatupo",
    iata_code: "MPP",
    coordinates: "-77.7331, 8.9453",
  },
  {
    ident: "PA-0013",
    type: "small_airport",
    name: "Play����n Chico Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Play����n Chico",
    iata_code: "PYC",
    coordinates: "-78.235874176, 9.305184161299998",
  },
  {
    ident: "PA-0017",
    type: "small_airport",
    name: "San Miguel Airport",
    elevation_ft: "70",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-8",
    municipality: "Isla del Rey",
    iata_code: "NMG",
    coordinates: "-78.9339, 8.4566",
  },
  {
    ident: "PA-0022",
    type: "small_airport",
    name: "Yaviza Airport",
    elevation_ft: "75",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-5",
    municipality: "Yaviza",
    iata_code: "PYV",
    coordinates: "-77.687, 8.1528",
  },
  {
    ident: "PA-AML",
    type: "closed",
    name: "Puerto Armuelles Airport",
    elevation_ft: "42",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-4",
    municipality: "Puerto Armuelles",
    iata_code: "AML",
    coordinates: "-82.864898681641, 8.2680597305298",
  },
  {
    ident: "PA-BFQ",
    type: "small_airport",
    name: "Bahia Pi����a Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-5",
    municipality: "Bahia Pi����a",
    iata_code: "BFQ",
    coordinates: "-78.19999694824219, 7.583000183105469",
  },
  {
    ident: "PA-ELE",
    type: "small_airport",
    name: "EL Real Airport",
    elevation_ft: "65",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-5",
    municipality: "El Real de Santa Mar����a",
    iata_code: "ELE",
    coordinates: "-77.7252, 8.1072",
  },
  {
    ident: "PA-OTD",
    type: "small_airport",
    name: "Contadora Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-8",
    municipality: "Contadora Island",
    iata_code: "OTD",
    coordinates: "-79.034698486328, 8.6287603378296",
  },
  {
    ident: "PA-SAX",
    type: "small_airport",
    name: "Sambu Airport",
    elevation_ft: "32",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-5",
    municipality: "Boca de S����balo",
    iata_code: "SAX",
    coordinates: "-78.19999694824219, 8.017000198364258",
  },
  {
    ident: "PAAK",
    type: "small_airport",
    name: "Atka Airport",
    elevation_ft: "57",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Atka",
    gps_code: "PAAK",
    iata_code: "AKB",
    local_code: "AKA",
    coordinates: "-174.2059937, 52.22029877",
  },
  {
    ident: "PAAL",
    type: "small_airport",
    name: "Port Moller Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Cold Bay",
    gps_code: "PAAL",
    iata_code: "PML",
    local_code: "1AK3",
    coordinates: "-160.561004639, 56.0060005188",
  },
  {
    ident: "PAAP",
    type: "seaplane_base",
    name: "Port Alexander Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Alexander",
    gps_code: "PAAP",
    iata_code: "PTD",
    local_code: "AHP",
    coordinates: "-134.647994995, 56.246799469",
  },
  {
    ident: "PAAQ",
    type: "medium_airport",
    name: 'Warren "Bud" Woods Palmer Municipal Airport',
    elevation_ft: "242",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Palmer",
    gps_code: "PAAQ",
    iata_code: "PAQ",
    local_code: "PAQ",
    coordinates: "-149.08901, 61.594898",
  },
  {
    ident: "PAAT",
    type: "closed",
    name: "Casco Cove Coast Guard Station",
    elevation_ft: "40",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Attu",
    gps_code: "PAAT",
    iata_code: "ATU",
    local_code: "ATU",
    coordinates: "173.179992676, 52.8283996582",
  },
  {
    ident: "PABA",
    type: "medium_airport",
    name: "Barter Island LRRS Airport",
    elevation_ft: "2",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Barter Island Lrrs",
    gps_code: "PABA",
    iata_code: "BTI",
    local_code: "BTI",
    coordinates: "-143.582000732, 70.1340026855",
  },
  {
    ident: "PABE",
    type: "medium_airport",
    name: "Bethel Airport",
    elevation_ft: "126",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Bethel",
    gps_code: "PABE",
    iata_code: "BET",
    local_code: "BET",
    coordinates: "-161.8379974, 60.77980042",
  },
  {
    ident: "PABG",
    type: "small_airport",
    name: "Beluga Airport",
    elevation_ft: "130",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Beluga",
    gps_code: "PABG",
    iata_code: "BVU",
    local_code: "BLG",
    coordinates: "-151.044006348, 61.1721992493",
  },
  {
    ident: "PABI",
    type: "medium_airport",
    name: "Allen Army Airfield",
    elevation_ft: "1291",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Delta Junction Ft Greely",
    gps_code: "PABI",
    iata_code: "BIG",
    local_code: "BIG",
    coordinates: "-145.722000122, 63.9944992065",
  },
  {
    ident: "PABL",
    type: "medium_airport",
    name: "Buckland Airport",
    elevation_ft: "31",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Buckland",
    gps_code: "PABL",
    iata_code: "BKC",
    local_code: "BVK",
    coordinates: "-161.149002, 65.981598",
  },
  {
    ident: "PABM",
    type: "small_airport",
    name: "Big Mountain Airport",
    elevation_ft: "663",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Big Mountain",
    gps_code: "PABM",
    iata_code: "BMX",
    local_code: "37AK",
    coordinates: "-155.259002686, 59.3611984253",
  },
  {
    ident: "PABR",
    type: "medium_airport",
    name: "Wiley Post Will Rogers Memorial Airport",
    elevation_ft: "44",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Utqia����vik",
    gps_code: "PABR",
    iata_code: "BRW",
    local_code: "BRW",
    coordinates: "-156.766008, 71.285402",
  },
  {
    ident: "PABT",
    type: "medium_airport",
    name: "Bettles Airport",
    elevation_ft: "647",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Bettles",
    gps_code: "PABT",
    iata_code: "BTT",
    local_code: "BTT",
    coordinates: "-151.529007, 66.91390228",
  },
  {
    ident: "PACD",
    type: "medium_airport",
    name: "Cold Bay Airport",
    elevation_ft: "96",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Cold Bay",
    gps_code: "PACD",
    iata_code: "CDB",
    local_code: "CDB",
    coordinates: "-162.72500610351562, 55.20610046386719",
  },
  {
    ident: "PACE",
    type: "small_airport",
    name: "Central Airport",
    elevation_ft: "937",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Central",
    gps_code: "PACE",
    iata_code: "CEM",
    local_code: "CEM",
    coordinates: "-144.7830048, 65.57379913",
  },
  {
    ident: "PACI",
    type: "small_airport",
    name: "Chalkyitsik Airport",
    elevation_ft: "544",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chalkyitsik",
    gps_code: "PACI",
    iata_code: "CIK",
    local_code: "CIK",
    coordinates: "-143.740005493, 66.6449966431",
  },
  {
    ident: "PACK",
    type: "small_airport",
    name: "Chefornak Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chefornak",
    gps_code: "PACK",
    iata_code: "CYF",
    local_code: "CFK",
    coordinates: "-164.279167, 60.136667",
  },
  {
    ident: "PACM",
    type: "medium_airport",
    name: "Scammon Bay Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Scammon Bay",
    gps_code: "PACM",
    iata_code: "SCM",
    local_code: "SCM",
    coordinates: "-165.570999146, 61.845298767100005",
  },
  {
    ident: "PACR",
    type: "small_airport",
    name: "Circle City /New/ Airport",
    elevation_ft: "613",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Circle",
    gps_code: "PACR",
    iata_code: "IRC",
    local_code: "CRC",
    coordinates: "-144.076008, 65.830498",
  },
  {
    ident: "PACV",
    type: "medium_airport",
    name: "Merle K (Mudhole) Smith Airport",
    elevation_ft: "54",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Cordova",
    gps_code: "PACV",
    iata_code: "CDV",
    local_code: "CDV",
    coordinates: "-145.4779968, 60.4917984",
  },
  {
    ident: "PACX",
    type: "small_airport",
    name: "Coldfoot Airport",
    elevation_ft: "1042",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Coldfoot",
    gps_code: "PACX",
    iata_code: "CXF",
    local_code: "CXF",
    coordinates: "-150.203994751, 67.25219726559999",
  },
  {
    ident: "PACY",
    type: "small_airport",
    name: "Yakataga Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Yakataga",
    gps_code: "PACY",
    iata_code: "CYT",
    local_code: "0AA1",
    coordinates: "-142.494541, 60.080974",
  },
  {
    ident: "PACZ",
    type: "medium_airport",
    name: "Cape Romanzof LRRS Airport",
    elevation_ft: "464",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Cape Romanzof",
    gps_code: "PACZ",
    iata_code: "CZF",
    local_code: "CZF",
    coordinates: "-166.0390015, 61.78030014",
  },
  {
    ident: "PADE",
    type: "medium_airport",
    name: "Deering Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Deering",
    gps_code: "PADE",
    iata_code: "DRG",
    local_code: "DEE",
    coordinates: "-162.766006, 66.069603",
  },
  {
    ident: "PADG",
    type: "small_airport",
    name: "Red Dog Airport",
    elevation_ft: "969",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Red Dog",
    gps_code: "PADG",
    iata_code: "RDB",
    local_code: "DGG",
    coordinates: "-162.899002075, 68.03209686279999",
  },
  {
    ident: "PADK",
    type: "medium_airport",
    name: "Adak Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Adak Island",
    gps_code: "PADK",
    iata_code: "ADK",
    local_code: "ADK",
    coordinates: "-176.64599609375, 51.87799835205078",
  },
  {
    ident: "PADL",
    type: "medium_airport",
    name: "Dillingham Airport",
    elevation_ft: "81",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Dillingham",
    gps_code: "PADL",
    iata_code: "DLG",
    local_code: "DLG",
    coordinates: "-158.5050049, 59.04470062",
  },
  {
    ident: "PADM",
    type: "medium_airport",
    name: "Marshall Don Hunter Sr Airport",
    elevation_ft: "103",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Marshall",
    gps_code: "PADM",
    iata_code: "MLL",
    local_code: "MDM",
    coordinates: "-162.026000977, 61.8642997742",
  },
  {
    ident: "PADQ",
    type: "medium_airport",
    name: "Kodiak Airport",
    elevation_ft: "78",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kodiak",
    gps_code: "PADQ",
    iata_code: "ADQ",
    local_code: "ADQ",
    coordinates: "-152.4940033, 57.75",
  },
  {
    ident: "PADU",
    type: "medium_airport",
    name: "Unalaska Airport",
    elevation_ft: "22",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Unalaska",
    gps_code: "PADU",
    iata_code: "DUT",
    local_code: "DUT",
    coordinates: "-166.544006348, 53.900100708",
  },
  {
    ident: "PADY",
    type: "small_airport",
    name: "Kongiganak Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kongiganak",
    gps_code: "PADY",
    iata_code: "KKH",
    local_code: "DUY",
    coordinates: "-162.880996704, 59.960800170899994",
  },
  {
    ident: "PAED",
    type: "medium_airport",
    name: "Elmendorf Air Force Base",
    elevation_ft: "212",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Anchorage",
    gps_code: "PAED",
    iata_code: "EDF",
    local_code: "EDF",
    coordinates: "-149.8070068359375, 61.250999450683594",
  },
  {
    ident: "PAEE",
    type: "small_airport",
    name: "Eek Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Eek",
    gps_code: "PAEE",
    iata_code: "EEK",
    local_code: "EEK",
    coordinates: "-162.0438843, 60.21367264",
  },
  {
    ident: "PAEG",
    type: "small_airport",
    name: "Eagle Airport",
    elevation_ft: "908",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Eagle",
    gps_code: "PAEG",
    iata_code: "EAA",
    local_code: "EAA",
    coordinates: "-141.151001, 64.77639771",
  },
  {
    ident: "PAEH",
    type: "medium_airport",
    name: "Cape Newenham LRRS Airport",
    elevation_ft: "541",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Cape Newenham",
    gps_code: "PAEH",
    iata_code: "EHM",
    local_code: "EHM",
    coordinates: "-162.06300354, 58.646400451699996",
  },
  {
    ident: "PAEI",
    type: "medium_airport",
    name: "Eielson Air Force Base",
    elevation_ft: "547",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Fairbanks",
    gps_code: "PAEI",
    iata_code: "EIL",
    local_code: "EIL",
    coordinates: "-147.102005, 64.66570282",
  },
  {
    ident: "PAEL",
    type: "seaplane_base",
    name: "Elfin Cove Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Elfin Cove",
    gps_code: "PAEL",
    iata_code: "ELV",
    local_code: "ELV",
    coordinates: "-136.347000122, 58.195201873799995",
  },
  {
    ident: "PAEM",
    type: "medium_airport",
    name: "Emmonak Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Emmonak",
    gps_code: "PAEM",
    iata_code: "EMK",
    local_code: "ENM",
    coordinates: "-164.4909973, 62.78609848",
  },
  {
    ident: "PAEN",
    type: "medium_airport",
    name: "Kenai Municipal Airport",
    elevation_ft: "99",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kenai",
    gps_code: "PAEN",
    iata_code: "ENA",
    local_code: "ENA",
    coordinates: "-151.2449951171875, 60.57310104370117",
  },
  {
    ident: "PAEW",
    type: "small_airport",
    name: "Newtok Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Newtok",
    gps_code: "PAEW",
    iata_code: "WWT",
    local_code: "EWU",
    coordinates: "-164.64100646973, 60.939098358154",
  },
  {
    ident: "PAFA",
    type: "large_airport",
    name: "Fairbanks International Airport",
    elevation_ft: "439",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Fairbanks",
    gps_code: "PAFA",
    iata_code: "FAI",
    local_code: "FAI",
    coordinates: "-147.8560028, 64.81510162",
  },
  {
    ident: "PAFB",
    type: "medium_airport",
    name: "Ladd AAF Airfield",
    elevation_ft: "454",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Fairbanks/Ft Wainwright",
    gps_code: "PAFB",
    iata_code: "FBK",
    local_code: "FBK",
    coordinates: "-147.6139984, 64.83750153",
  },
  {
    ident: "PAFM",
    type: "medium_airport",
    name: "Ambler Airport",
    elevation_ft: "334",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ambler",
    gps_code: "PAFM",
    iata_code: "ABL",
    local_code: "AFM",
    coordinates: "-157.856989, 67.1063",
  },
  {
    ident: "PAFR",
    type: "heliport",
    name: "Bryant Army Heliport",
    elevation_ft: "378",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Fort Richardson(Anchorage)",
    gps_code: "PAFR",
    iata_code: "FRN",
    local_code: "FRN",
    coordinates: "-149.6529999, 61.26639938",
  },
  {
    ident: "PAFS",
    type: "small_airport",
    name: "Nikolai Airport",
    elevation_ft: "441",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nikolai",
    gps_code: "PAFS",
    iata_code: "NIB",
    local_code: "FSP",
    coordinates: "-154.358002, 63.0186",
  },
  {
    ident: "PAFV",
    type: "small_airport",
    name: "Five Mile Airport",
    elevation_ft: "510",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Five Mile",
    gps_code: "PAFV",
    iata_code: "FMC",
    local_code: "FVM",
    coordinates: "-149.839996338, 65.9270019531",
  },
  {
    ident: "PAFW",
    type: "small_airport",
    name: "Farewell Airport",
    elevation_ft: "1535",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Farewell",
    gps_code: "PAFW",
    iata_code: "FWL",
    local_code: "0AA4",
    coordinates: "-153.892279, 62.509327",
  },
  {
    ident: "PAGA",
    type: "medium_airport",
    name: "Edward G. Pitka Sr Airport",
    elevation_ft: "153",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Galena",
    gps_code: "PAGA",
    iata_code: "GAL",
    local_code: "GAL",
    coordinates: "-156.9369965, 64.73619843",
  },
  {
    ident: "PAGB",
    type: "small_airport",
    name: "Galbraith Lake Airport",
    elevation_ft: "2663",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Galbraith Lake",
    gps_code: "PAGB",
    iata_code: "GBH",
    local_code: "GBH",
    coordinates: "-149.490005493, 68.4796981812",
  },
  {
    ident: "PAGG",
    type: "small_airport",
    name: "Kwigillingok Airport",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kwigillingok",
    gps_code: "PAGG",
    iata_code: "KWK",
    local_code: "GGV",
    coordinates: "-163.169005, 59.876499",
  },
  {
    ident: "PAGH",
    type: "small_airport",
    name: "Shungnak Airport",
    elevation_ft: "197",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Shungnak",
    gps_code: "PAGH",
    iata_code: "SHG",
    local_code: "SHG",
    coordinates: "-157.16200256348, 66.88809967041",
  },
  {
    ident: "PAGK",
    type: "medium_airport",
    name: "Gulkana Airport",
    elevation_ft: "1586",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Gulkana",
    gps_code: "PAGK",
    iata_code: "GKN",
    local_code: "GKN",
    coordinates: "-145.4570007, 62.1548996",
  },
  {
    ident: "PAGL",
    type: "small_airport",
    name: "Golovin Airport",
    elevation_ft: "59",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Golovin",
    gps_code: "PAGL",
    iata_code: "GLV",
    local_code: "GLV",
    coordinates: "-163.007003784, 64.5504989624",
  },
  {
    ident: "PAGM",
    type: "medium_airport",
    name: "Gambell Airport",
    elevation_ft: "27",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Gambell",
    gps_code: "PAGM",
    iata_code: "GAM",
    local_code: "GAM",
    coordinates: "-171.73300170898438, 63.76679992675781",
  },
  {
    ident: "PAGN",
    type: "seaplane_base",
    name: "Angoon Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Angoon",
    gps_code: "PAGN",
    iata_code: "AGN",
    local_code: "AGN",
    coordinates: "-134.585007, 57.503601",
  },
  {
    ident: "PAGQ",
    type: "small_airport",
    name: "Big Lake Airport",
    elevation_ft: "158",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Big Lake",
    gps_code: "PAGQ",
    iata_code: "BGQ",
    local_code: "BGQ",
    coordinates: "-149.813995361, 61.536098480199996",
  },
  {
    ident: "PAGS",
    type: "medium_airport",
    name: "Gustavus Airport",
    elevation_ft: "35",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Gustavus",
    gps_code: "PAGS",
    iata_code: "GST",
    local_code: "GST",
    coordinates: "-135.7070007, 58.4253006",
  },
  {
    ident: "PAGT",
    type: "small_airport",
    name: "Nightmute Airport",
    elevation_ft: "4",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nightmute",
    gps_code: "PAGT",
    iata_code: "NME",
    local_code: "IGT",
    coordinates: "-164.70100402832, 60.471000671387",
  },
  {
    ident: "PAGY",
    type: "small_airport",
    name: "Skagway Airport",
    elevation_ft: "44",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Skagway",
    gps_code: "PAGY",
    iata_code: "SGY",
    local_code: "SGY",
    coordinates: "-135.3159942626953, 59.46009826660156",
  },
  {
    ident: "PAHC",
    type: "medium_airport",
    name: "Holy Cross Airport",
    elevation_ft: "70",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Holy Cross",
    gps_code: "PAHC",
    iata_code: "HCR",
    local_code: "HCA",
    coordinates: "-159.77499389648438, 62.18830108642578",
  },
  {
    ident: "PAHL",
    type: "medium_airport",
    name: "Huslia Airport",
    elevation_ft: "220",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Huslia",
    gps_code: "PAHL",
    iata_code: "HSL",
    local_code: "HLA",
    coordinates: "-156.3509979, 65.69789886",
  },
  {
    ident: "PAHN",
    type: "medium_airport",
    name: "Haines Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Haines",
    gps_code: "PAHN",
    iata_code: "HNS",
    local_code: "HNS",
    coordinates: "-135.5240020751953, 59.24380111694336",
  },
  {
    ident: "PAHO",
    type: "medium_airport",
    name: "Homer Airport",
    elevation_ft: "84",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Homer",
    gps_code: "PAHO",
    iata_code: "HOM",
    local_code: "HOM",
    coordinates: "-151.4770050048828, 59.645599365234375",
  },
  {
    ident: "PAHP",
    type: "medium_airport",
    name: "Hooper Bay Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Hooper Bay",
    gps_code: "PAHP",
    iata_code: "HPB",
    local_code: "HPB",
    coordinates: "-166.1470032, 61.52389908",
  },
  {
    ident: "PAHU",
    type: "small_airport",
    name: "Hughes Airport",
    elevation_ft: "299",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Hughes",
    gps_code: "PAHU",
    iata_code: "HUS",
    local_code: "HUS",
    coordinates: "-154.2630005, 66.04109955",
  },
  {
    ident: "PAHX",
    type: "small_airport",
    name: "Shageluk Airport",
    elevation_ft: "79",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Shageluk",
    gps_code: "PAHX",
    iata_code: "SHX",
    local_code: "SHX",
    coordinates: "-159.569000244, 62.6922988892",
  },
  {
    ident: "PAHY",
    type: "seaplane_base",
    name: "Hydaburg Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Hydaburg",
    gps_code: "PAHY",
    iata_code: "HYG",
    local_code: "HYG",
    coordinates: "-132.8280029296875, 55.206298828125",
  },
  {
    ident: "PAIG",
    type: "small_airport",
    name: "Igiugig Airport",
    elevation_ft: "90",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Igiugig",
    gps_code: "PAIG",
    iata_code: "IGG",
    local_code: "IGG",
    coordinates: "-155.90199279785156, 59.32400131225586",
  },
  {
    ident: "PAII",
    type: "medium_airport",
    name: "Egegik Airport",
    elevation_ft: "92",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Egegik",
    gps_code: "PAII",
    iata_code: "EGX",
    local_code: "EII",
    coordinates: "-157.375, 58.1855010986",
  },
  {
    ident: "PAIK",
    type: "medium_airport",
    name: "Bob Baker Memorial Airport",
    elevation_ft: "166",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kiana",
    gps_code: "PAIK",
    iata_code: "IAN",
    local_code: "IAN",
    coordinates: "-160.43699646, 66.9759979248",
  },
  {
    ident: "PAIL",
    type: "medium_airport",
    name: "Iliamna Airport",
    elevation_ft: "192",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Iliamna",
    gps_code: "PAIL",
    iata_code: "ILI",
    local_code: "ILI",
    coordinates: "-154.9109955, 59.75439835",
  },
  {
    ident: "PAIM",
    type: "medium_airport",
    name: "Indian Mountain LRRS Airport",
    elevation_ft: "1273",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Utopia Creek",
    gps_code: "PAIM",
    iata_code: "UTO",
    local_code: "UTO",
    coordinates: "-153.7039948, 65.99279785",
  },
  {
    ident: "PAIN",
    type: "small_airport",
    name: "McKinley National Park Airport",
    elevation_ft: "1720",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "McKinley Park",
    gps_code: "PAIN",
    iata_code: "MCL",
    local_code: "INR",
    coordinates: "-148.910995483, 63.7326011658",
  },
  {
    ident: "PAIW",
    type: "small_airport",
    name: "Wales Airport",
    elevation_ft: "22",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Wales",
    gps_code: "PAIW",
    iata_code: "WAA",
    local_code: "IWK",
    coordinates: "-168.095, 65.622593",
  },
  {
    ident: "PAJN",
    type: "medium_airport",
    name: "Juneau International Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Juneau",
    gps_code: "PAJN",
    iata_code: "JNU",
    local_code: "JNU",
    coordinates: "-134.5760040283203, 58.35499954223633",
  },
  {
    ident: "PAJZ",
    type: "small_airport",
    name: "Koliganek Airport",
    elevation_ft: "269",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Koliganek",
    gps_code: "PAJZ",
    iata_code: "KGK",
    local_code: "JZZ",
    coordinates: "-157.259002686, 59.726600647",
  },
  {
    ident: "PAKD",
    type: "small_airport",
    name: "Kodiak Municipal Airport",
    elevation_ft: "139",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kodiak",
    gps_code: "PAKD",
    iata_code: "KDK",
    local_code: "KDK",
    coordinates: "-152.37399292, 57.8059005737",
  },
  {
    ident: "PAKF",
    type: "small_airport",
    name: "False Pass Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "False Pass",
    gps_code: "PAKF",
    iata_code: "KFP",
    local_code: "KFP",
    coordinates: "-163.41000366210938, 54.8474006652832",
  },
  {
    ident: "PAKH",
    type: "small_airport",
    name: "Akhiok Airport",
    elevation_ft: "44",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Akhiok",
    gps_code: "PAKH",
    iata_code: "AKK",
    local_code: "AKK",
    coordinates: "-154.182998657, 56.9387016296",
  },
  {
    ident: "PAKI",
    type: "small_airport",
    name: "Kipnuk Airport",
    elevation_ft: "11",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kipnuk",
    gps_code: "PAKI",
    iata_code: "KPN",
    local_code: "IIK",
    coordinates: "-164.031005859, 59.932998657199995",
  },
  {
    ident: "PAKK",
    type: "small_airport",
    name: "Koyuk Alfred Adams Airport",
    elevation_ft: "154",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Koyuk",
    gps_code: "PAKK",
    iata_code: "KKA",
    local_code: "KKA",
    coordinates: "-161.154006958, 64.9394989014",
  },
  {
    ident: "PAKL",
    type: "small_airport",
    name: "Kulik Lake Airport",
    elevation_ft: "717",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kulik Lake",
    gps_code: "PAKL",
    iata_code: "LKK",
    local_code: "LKK",
    coordinates: "-155.121002197, 58.9821014404",
  },
  {
    ident: "PAKN",
    type: "medium_airport",
    name: "King Salmon Airport",
    elevation_ft: "73",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "King Salmon",
    gps_code: "PAKN",
    iata_code: "AKN",
    local_code: "AKN",
    coordinates: "-156.6490021, 58.67679977",
  },
  {
    ident: "PAKO",
    type: "small_airport",
    name: "Nikolski Air Station",
    elevation_ft: "77",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nikolski",
    gps_code: "PAKO",
    iata_code: "IKO",
    local_code: "IKO",
    coordinates: "-168.8489990234375, 52.94160079956055",
  },
  {
    ident: "PAKP",
    type: "medium_airport",
    name: "Anaktuvuk Pass Airport",
    elevation_ft: "2102",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Anaktuvuk Pass",
    gps_code: "PAKP",
    iata_code: "AKP",
    local_code: "AKP",
    coordinates: "-151.7429962, 68.13359833",
  },
  {
    ident: "PAKT",
    type: "medium_airport",
    name: "Ketchikan International Airport",
    elevation_ft: "89",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ketchikan",
    gps_code: "PAKT",
    iata_code: "KTN",
    local_code: "KTN",
    coordinates: "-131.7140045, 55.35559845",
  },
  {
    ident: "PAKU",
    type: "small_airport",
    name: "Ugnu-Kuparuk Airport",
    elevation_ft: "67",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kuparuk",
    gps_code: "PAKU",
    iata_code: "UUK",
    local_code: "UBW",
    coordinates: "-149.598007202, 70.33080291750001",
  },
  {
    ident: "PAKV",
    type: "small_airport",
    name: "Kaltag Airport",
    elevation_ft: "181",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kaltag",
    gps_code: "PAKV",
    iata_code: "KAL",
    local_code: "KAL",
    coordinates: "-158.7409973, 64.31909943",
  },
  {
    ident: "PAKW",
    type: "medium_airport",
    name: "Klawock Airport",
    elevation_ft: "80",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Klawock",
    gps_code: "PAKW",
    iata_code: "KLW",
    local_code: "AKW",
    coordinates: "-133.076004028, 55.579200744599994",
  },
  {
    ident: "PAKY",
    type: "small_airport",
    name: "Karluk Airport",
    elevation_ft: "137",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Karluk",
    gps_code: "PAKY",
    iata_code: "KYK",
    local_code: "KYK",
    coordinates: "-154.449996948, 57.5671005249",
  },
  {
    ident: "PALB",
    type: "small_airport",
    name: "Larsen Bay Airport",
    elevation_ft: "87",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Larsen Bay",
    gps_code: "PALB",
    iata_code: "KLN",
    local_code: "2A3",
    coordinates: "-153.977996826, 57.5350990295",
  },
  {
    ident: "PALG",
    type: "small_airport",
    name: "Kalskag Airport",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kalskag",
    gps_code: "PALG",
    iata_code: "KLG",
    local_code: "KLG",
    coordinates: "-160.34100341796875, 61.53630065917969",
  },
  {
    ident: "PALP",
    type: "small_airport",
    name: "Alpine Airstrip",
    elevation_ft: "18",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nuiqsut",
    gps_code: "PALP",
    iata_code: "DQH",
    local_code: "AK15",
    coordinates: "-150.945007, 70.344299",
  },
  {
    ident: "PALR",
    type: "small_airport",
    name: "Chandalar Lake Airport",
    elevation_ft: "1920",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chandalar Lake",
    gps_code: "PALR",
    iata_code: "WCR",
    local_code: "WCR",
    coordinates: "-148.483001709, 67.5045013428",
  },
  {
    ident: "PALU",
    type: "medium_airport",
    name: "Cape Lisburne LRRS Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Cape Lisburne",
    gps_code: "PALU",
    iata_code: "LUR",
    local_code: "LUR",
    coordinates: "-166.1100006, 68.87509918",
  },
  {
    ident: "PAMB",
    type: "small_airport",
    name: "Manokotak Airport",
    elevation_ft: "100",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Manokotak",
    gps_code: "PAMB",
    iata_code: "KMO",
    local_code: "MBA",
    coordinates: "-159.050003052, 58.990200042699996",
  },
  {
    ident: "PAMC",
    type: "medium_airport",
    name: "McGrath Airport",
    elevation_ft: "341",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "McGrath",
    gps_code: "PAMC",
    iata_code: "MCG",
    local_code: "MCG",
    coordinates: "-155.6060028, 62.95289993",
  },
  {
    ident: "PAMD",
    type: "small_airport",
    name: "Middleton Island Airport",
    elevation_ft: "100",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Middleton Island",
    gps_code: "PAMD",
    iata_code: "MDO",
    local_code: "MDO",
    coordinates: "-146.307006836, 59.4499015808",
  },
  {
    ident: "PAMH",
    type: "small_airport",
    name: "Minchumina Airport",
    elevation_ft: "678",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Minchumina",
    gps_code: "PAMH",
    iata_code: "LMA",
    local_code: "MHM",
    coordinates: "-152.302002, 63.886002",
  },
  {
    ident: "PAMK",
    type: "small_airport",
    name: "St Michael Airport",
    elevation_ft: "98",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "St Michael",
    gps_code: "PAMK",
    iata_code: "SMK",
    local_code: "SMK",
    coordinates: "-162.1100006, 63.49010086",
  },
  {
    ident: "PAML",
    type: "small_airport",
    name: "Manley Hot Springs Airport",
    elevation_ft: "270",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Manley Hot Springs",
    gps_code: "PAML",
    iata_code: "MLY",
    local_code: "MLY",
    coordinates: "-150.643997192, 64.99759674069999",
  },
  {
    ident: "PAMM",
    type: "seaplane_base",
    name: "Metlakatla Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Metlakatla",
    gps_code: "PAMM",
    iata_code: "MTM",
    local_code: "MTM",
    coordinates: "-131.578003, 55.131001",
  },
  {
    ident: "PAMO",
    type: "small_airport",
    name: "Mountain Village Airport",
    elevation_ft: "337",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Mountain Village",
    gps_code: "PAMO",
    iata_code: "MOU",
    local_code: "MOU",
    coordinates: "-163.6820068359375, 62.095401763916016",
  },
  {
    ident: "PAMR",
    type: "medium_airport",
    name: "Merrill Field",
    elevation_ft: "137",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Anchorage",
    gps_code: "PAMR",
    iata_code: "MRI",
    local_code: "MRI",
    coordinates: "-149.843994140625, 61.2135009765625",
  },
  {
    ident: "PAMX",
    type: "small_airport",
    name: "Mc Carthy Airport",
    elevation_ft: "1531",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Mccarthy",
    gps_code: "PAMX",
    iata_code: "MXY",
    local_code: "15Z",
    coordinates: "-142.904006958, 61.4370994568",
  },
  {
    ident: "PAMY",
    type: "medium_airport",
    name: "Mekoryuk Airport",
    elevation_ft: "48",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Mekoryuk",
    gps_code: "PAMY",
    iata_code: "MYU",
    local_code: "MYU",
    coordinates: "-166.27099609375, 60.37139892578125",
  },
  {
    ident: "PANA",
    type: "small_airport",
    name: "Napakiak Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Napakiak",
    gps_code: "PANA",
    iata_code: "WNA",
    local_code: "WNA",
    coordinates: "-161.97900390625, 60.69029998779297",
  },
  {
    ident: "PANC",
    type: "large_airport",
    name: "Ted Stevens Anchorage International Airport",
    elevation_ft: "152",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Anchorage",
    gps_code: "PANC",
    iata_code: "ANC",
    local_code: "ANC",
    coordinates: "-149.99600219726562, 61.174400329589844",
  },
  {
    ident: "PANI",
    type: "medium_airport",
    name: "Aniak Airport",
    elevation_ft: "88",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Aniak",
    gps_code: "PANI",
    iata_code: "ANI",
    local_code: "ANI",
    coordinates: "-159.54299926757812, 61.581600189208984",
  },
  {
    ident: "PANN",
    type: "medium_airport",
    name: "Nenana Municipal Airport",
    elevation_ft: "362",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nenana",
    gps_code: "PANN",
    iata_code: "ENN",
    local_code: "ENN",
    coordinates: "-149.07400512695312, 64.54730224609375",
  },
  {
    ident: "PANO",
    type: "small_airport",
    name: "Nondalton Airport",
    elevation_ft: "314",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nondalton",
    gps_code: "PANO",
    iata_code: "NNL",
    local_code: "5NN",
    coordinates: "-154.8390045166, 59.980201721191",
  },
  {
    ident: "PANR",
    type: "seaplane_base",
    name: "Funter Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Funter Bay",
    gps_code: "PANR",
    iata_code: "FNR",
    local_code: "FNR",
    coordinates: "-134.897994995, 58.2543983459",
  },
  {
    ident: "PANT",
    type: "medium_airport",
    name: "Annette Island Airport",
    elevation_ft: "119",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Annette",
    gps_code: "PANT",
    iata_code: "ANN",
    local_code: "ANN",
    coordinates: "-131.57200622558594, 55.04240036010742",
  },
  {
    ident: "PANU",
    type: "small_airport",
    name: "Nulato Airport",
    elevation_ft: "399",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nulato",
    gps_code: "PANU",
    iata_code: "NUL",
    local_code: "NUL",
    coordinates: "-158.074005, 64.729301",
  },
  {
    ident: "PANV",
    type: "medium_airport",
    name: "Anvik Airport",
    elevation_ft: "291",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Anvik",
    gps_code: "PANV",
    iata_code: "ANV",
    local_code: "ANV",
    coordinates: "-160.190994, 62.646702",
  },
  {
    ident: "PANW",
    type: "small_airport",
    name: "New Stuyahok Airport",
    elevation_ft: "364",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "New Stuyahok",
    gps_code: "PANW",
    iata_code: "KNW",
    local_code: "KNW",
    coordinates: "-157.32800293, 59.4499015808",
  },
  {
    ident: "PAOB",
    type: "small_airport",
    name: "Kobuk Airport",
    elevation_ft: "137",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kobuk",
    gps_code: "PAOB",
    iata_code: "OBU",
    local_code: "OBU",
    coordinates: "-156.897003174, 66.9123001099",
  },
  {
    ident: "PAOC",
    type: "small_airport",
    name: "Portage Creek Airport",
    elevation_ft: "137",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Portage Creek",
    gps_code: "PAOC",
    iata_code: "PCA",
    local_code: "A14",
    coordinates: "-157.714, 58.9065",
  },
  {
    ident: "PAOH",
    type: "small_airport",
    name: "Hoonah Airport",
    elevation_ft: "19",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Hoonah",
    gps_code: "PAOH",
    iata_code: "HNH",
    local_code: "HNH",
    coordinates: "-135.410111, 58.0961",
  },
  {
    ident: "PAOM",
    type: "medium_airport",
    name: "Nome Airport",
    elevation_ft: "37",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nome",
    gps_code: "PAOM",
    iata_code: "OME",
    local_code: "OME",
    coordinates: "-165.44500732421875, 64.51219940185547",
  },
  {
    ident: "PAOO",
    type: "small_airport",
    name: "Toksook Bay Airport",
    elevation_ft: "59",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Toksook Bay",
    gps_code: "PAOO",
    iata_code: "OOK",
    local_code: "OOK",
    coordinates: "-165.0870056, 60.54140091",
  },
  {
    ident: "PAOR",
    type: "medium_airport",
    name: "Northway Airport",
    elevation_ft: "1715",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Northway",
    gps_code: "PAOR",
    iata_code: "ORT",
    local_code: "ORT",
    coordinates: "-141.9290009, 62.9612999",
  },
  {
    ident: "PAOT",
    type: "medium_airport",
    name: "Ralph Wien Memorial Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kotzebue",
    gps_code: "PAOT",
    iata_code: "OTZ",
    local_code: "OTZ",
    coordinates: "-162.598999, 66.88469696",
  },
  {
    ident: "PAOU",
    type: "small_airport",
    name: "Nelson Lagoon Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nelson Lagoon",
    gps_code: "PAOU",
    iata_code: "NLG",
    local_code: "OUL",
    coordinates: "-161.16000366211, 56.007499694824",
  },
  {
    ident: "PAPB",
    type: "medium_airport",
    name: "St George Airport",
    elevation_ft: "125",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "St George",
    gps_code: "PAPB",
    iata_code: "STG",
    local_code: "PBV",
    coordinates: "-169.662002563, 56.578300476100004",
  },
  {
    ident: "PAPC",
    type: "medium_airport",
    name: "Port Clarence Coast Guard Station",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Clarence",
    gps_code: "PAPC",
    iata_code: "KPC",
    local_code: "KPC",
    coordinates: "-166.85899353, 65.2537002563",
  },
  {
    ident: "PAPE",
    type: "small_airport",
    name: "Perryville Airport",
    elevation_ft: "29",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Perryville",
    gps_code: "PAPE",
    iata_code: "KPV",
    local_code: "PEV",
    coordinates: "-159.162993, 55.905998",
  },
  {
    ident: "PAPG",
    type: "medium_airport",
    name: "Petersburg James A Johnson Airport",
    elevation_ft: "111",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Petersburg",
    gps_code: "PAPG",
    iata_code: "PSG",
    local_code: "PSG",
    coordinates: "-132.9450073, 56.80170059",
  },
  {
    ident: "PAPH",
    type: "medium_airport",
    name: "Port Heiden Airport",
    elevation_ft: "95",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Heiden",
    gps_code: "PAPH",
    iata_code: "PTH",
    local_code: "PTH",
    coordinates: "-158.63299560546875, 56.95909881591797",
  },
  {
    ident: "PAPK",
    type: "small_airport",
    name: "Napaskiak Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Napaskiak",
    gps_code: "PAPK",
    iata_code: "PKA",
    local_code: "PKA",
    coordinates: "-161.7779999, 60.70289993",
  },
  {
    ident: "PAPM",
    type: "medium_airport",
    name: "Platinum Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Platinum",
    gps_code: "PAPM",
    iata_code: "PTU",
    local_code: "PTU",
    coordinates: "-161.82000732421875, 59.01139831542969",
  },
  {
    ident: "PAPN",
    type: "small_airport",
    name: "Pilot Point Airport",
    elevation_ft: "57",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Pilot Point",
    gps_code: "PAPN",
    iata_code: "PIP",
    local_code: "PNP",
    coordinates: "-157.572006226, 57.5803985596",
  },
  {
    ident: "PAPO",
    type: "small_airport",
    name: "Point Hope Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Point Hope",
    gps_code: "PAPO",
    iata_code: "PHO",
    local_code: "PHO",
    coordinates: "-166.7989959716797, 68.34880065917969",
  },
  {
    ident: "PAPR",
    type: "small_airport",
    name: "Prospect Creek Airport",
    elevation_ft: "1095",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Prospect Creek",
    gps_code: "PAPR",
    iata_code: "PPC",
    local_code: "PPC",
    coordinates: "-150.64399719238, 66.814102172852",
  },
  {
    ident: "PAQH",
    type: "small_airport",
    name: "Quinhagak Airport",
    elevation_ft: "42",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Quinhagak",
    gps_code: "PAQH",
    iata_code: "KWN",
    local_code: "AQH",
    coordinates: "-161.845, 59.7551",
  },
  {
    ident: "PAQT",
    type: "medium_airport",
    name: "Nuiqsut Airport",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nuiqsut",
    gps_code: "PAQT",
    iata_code: "NUI",
    local_code: "AQT",
    coordinates: "-151.005996704, 70.2099990845",
  },
  {
    ident: "PARC",
    type: "medium_airport",
    name: "Arctic Village Airport",
    elevation_ft: "2092",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Arctic Village",
    gps_code: "PARC",
    iata_code: "ARC",
    local_code: "ARC",
    coordinates: "-145.578995, 68.1147",
  },
  {
    ident: "PARS",
    type: "small_airport",
    name: "Russian Mission Airport",
    elevation_ft: "51",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Russian Mission",
    gps_code: "PARS",
    iata_code: "RSH",
    local_code: "RSH",
    coordinates: "-161.319458008, 61.7788848877",
  },
  {
    ident: "PARY",
    type: "medium_airport",
    name: "Ruby Airport",
    elevation_ft: "658",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Ruby",
    gps_code: "PARY",
    iata_code: "RBY",
    local_code: "RBY",
    coordinates: "-155.4700012, 64.72720337",
  },
  {
    ident: "PASA",
    type: "medium_airport",
    name: "Savoonga Airport",
    elevation_ft: "53",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Savoonga",
    gps_code: "PASA",
    iata_code: "SVA",
    local_code: "SVA",
    coordinates: "-170.4929962158203, 63.6864013671875",
  },
  {
    ident: "PASC",
    type: "medium_airport",
    name: "Deadhorse Airport",
    elevation_ft: "65",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Deadhorse",
    gps_code: "PASC",
    iata_code: "SCC",
    local_code: "SCC",
    coordinates: "-148.4649963, 70.19470215",
  },
  {
    ident: "PASD",
    type: "medium_airport",
    name: "Sand Point Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Sand Point",
    gps_code: "PASD",
    iata_code: "SDP",
    local_code: "SDP",
    coordinates: "-160.5229949951172, 55.314998626708984",
  },
  {
    ident: "PASH",
    type: "medium_airport",
    name: "Shishmaref Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Shishmaref",
    gps_code: "PASH",
    iata_code: "SHH",
    local_code: "SHH",
    coordinates: "-166.089112, 66.249604",
  },
  {
    ident: "PASI",
    type: "medium_airport",
    name: "Sitka Rocky Gutierrez Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Sitka",
    gps_code: "PASI",
    iata_code: "SIT",
    local_code: "SIT",
    coordinates: "-135.36199951171875, 57.04710006713867",
  },
  {
    ident: "PASK",
    type: "medium_airport",
    name: "Selawik Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Selawik",
    gps_code: "PASK",
    iata_code: "WLK",
    local_code: "WLK",
    coordinates: "-159.9859924, 66.60009766",
  },
  {
    ident: "PASL",
    type: "small_airport",
    name: "Sleetmute Airport",
    elevation_ft: "190",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Sleetmute",
    gps_code: "PASL",
    iata_code: "SLQ",
    local_code: "SLQ",
    coordinates: "-157.166000366, 61.7005004883",
  },
  {
    ident: "PASM",
    type: "medium_airport",
    name: "St Mary's Airport",
    elevation_ft: "312",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "St Mary's",
    gps_code: "PASM",
    iata_code: "KSM",
    local_code: "KSM",
    coordinates: "-163.302002, 62.0605011",
  },
  {
    ident: "PASN",
    type: "medium_airport",
    name: "St Paul Island Airport",
    elevation_ft: "63",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "St Paul Island",
    gps_code: "PASN",
    iata_code: "SNP",
    local_code: "SNP",
    coordinates: "-170.22000122070312, 57.167301177978516",
  },
  {
    ident: "PASO",
    type: "small_airport",
    name: "Seldovia Airport",
    elevation_ft: "29",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Seldovia",
    gps_code: "PASO",
    iata_code: "SOV",
    local_code: "SOV",
    coordinates: "-151.70399475098, 59.442401885986",
  },
  {
    ident: "PASP",
    type: "small_airport",
    name: "Sheep Mountain Airport",
    elevation_ft: "2750",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Sheep Mountain",
    gps_code: "PASP",
    iata_code: "SMU",
    local_code: "SMU",
    coordinates: "-147.507003784, 61.812000274700004",
  },
  {
    ident: "PAST",
    type: "small_airport",
    name: "Summit Airport",
    elevation_ft: "2409",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Summit",
    gps_code: "PAST",
    iata_code: "UMM",
    local_code: "UMM",
    coordinates: "-149.126998901, 63.3315010071",
  },
  {
    ident: "PASV",
    type: "medium_airport",
    name: "Sparrevohn LRRS Airport",
    elevation_ft: "1585",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Sparrevohn",
    gps_code: "PASV",
    iata_code: "SVW",
    local_code: "SVW",
    coordinates: "-155.5740051, 61.09740067",
  },
  {
    ident: "PASW",
    type: "small_airport",
    name: "Skwentna Airport",
    elevation_ft: "148",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Skwentna",
    gps_code: "PASW",
    iata_code: "SKW",
    local_code: "SKW",
    coordinates: "-151.190994263, 61.9653015137",
  },
  {
    ident: "PASX",
    type: "medium_airport",
    name: "Soldotna Airport",
    elevation_ft: "113",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Soldotna",
    gps_code: "PASX",
    iata_code: "SXQ",
    local_code: "SXQ",
    coordinates: "-151.03399658203125, 60.47570037841797",
  },
  {
    ident: "PASY",
    type: "medium_airport",
    name: "Eareckson Air Station",
    elevation_ft: "95",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Shemya",
    gps_code: "PASY",
    iata_code: "SYA",
    local_code: "SYA",
    coordinates: "174.1139984, 52.71229935",
  },
  {
    ident: "PATA",
    type: "small_airport",
    name: "Ralph M Calhoun Memorial Airport",
    elevation_ft: "236",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tanana",
    gps_code: "PATA",
    iata_code: "TAL",
    local_code: "TAL",
    coordinates: "-152.10899353, 65.1744003296",
  },
  {
    ident: "PATC",
    type: "small_airport",
    name: "Tin City Long Range Radar Station Airport",
    elevation_ft: "271",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tin City",
    gps_code: "PATC",
    iata_code: "TNC",
    local_code: "TNC",
    coordinates: "-167.9219971, 65.56310272",
  },
  {
    ident: "PATE",
    type: "small_airport",
    name: "Teller Airport",
    elevation_ft: "294",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Teller",
    gps_code: "PATE",
    iata_code: "TLA",
    local_code: "TER",
    coordinates: "-166.339004517, 65.2404022217",
  },
  {
    ident: "PATG",
    type: "medium_airport",
    name: "Togiak Airport",
    elevation_ft: "21",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Togiak Village",
    gps_code: "PATG",
    iata_code: "TOG",
    local_code: "TOG",
    coordinates: "-160.39700317382812, 59.052799224853516",
  },
  {
    ident: "PATK",
    type: "medium_airport",
    name: "Talkeetna Airport",
    elevation_ft: "358",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Talkeetna",
    gps_code: "PATK",
    iata_code: "TKA",
    local_code: "TKA",
    coordinates: "-150.09399414062, 62.320499420166",
  },
  {
    ident: "PATL",
    type: "medium_airport",
    name: "Tatalina LRRS Airport",
    elevation_ft: "964",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Takotna",
    gps_code: "PATL",
    iata_code: "TLJ",
    local_code: "TLJ",
    coordinates: "-155.977005005, 62.894401550299996",
  },
  {
    ident: "PATQ",
    type: "medium_airport",
    name: "Atqasuk Edward Burnell Sr Memorial Airport",
    elevation_ft: "96",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Atqasuk",
    gps_code: "PATQ",
    iata_code: "ATK",
    local_code: "ATK",
    coordinates: "-157.436013, 70.46704",
  },
  {
    ident: "PAUK",
    type: "small_airport",
    name: "Alakanuk Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Alakanuk",
    gps_code: "PAUK",
    iata_code: "AUK",
    local_code: "AUK",
    coordinates: "-164.659927368, 62.680042266799994",
  },
  {
    ident: "PAUM",
    type: "small_airport",
    name: "Umiat Airport",
    elevation_ft: "267",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Umiat",
    gps_code: "PAUM",
    iata_code: "UMT",
    local_code: "UMT",
    coordinates: "-152.1360016, 69.37110138",
  },
  {
    ident: "PAUN",
    type: "small_airport",
    name: "Unalakleet Airport",
    elevation_ft: "27",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Unalakleet",
    gps_code: "PAUN",
    iata_code: "UNK",
    local_code: "UNK",
    coordinates: "-160.798996, 63.88840103",
  },
  {
    ident: "PAUO",
    type: "small_airport",
    name: "Willow Airport",
    elevation_ft: "221",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Willow",
    gps_code: "PAUO",
    iata_code: "WOW",
    local_code: "UUO",
    coordinates: "-150.052001953, 61.7541999817",
  },
  {
    ident: "PAVA",
    type: "small_airport",
    name: "Chevak Airport",
    elevation_ft: "75",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chevak",
    gps_code: "PAVA",
    iata_code: "VAK",
    local_code: "VAK",
    coordinates: "-165.6005, 61.5409",
  },
  {
    ident: "PAVC",
    type: "small_airport",
    name: "King Cove Airport",
    elevation_ft: "155",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "King Cove",
    gps_code: "PAVC",
    iata_code: "KVC",
    local_code: "KVC",
    coordinates: "-162.26600646972656, 55.11629867553711",
  },
  {
    ident: "PAVD",
    type: "medium_airport",
    name: "Valdez Pioneer Field",
    elevation_ft: "121",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Valdez",
    gps_code: "PAVD",
    iata_code: "VDZ",
    local_code: "VDZ",
    coordinates: "-146.2480011, 61.13389969",
  },
  {
    ident: "PAVE",
    type: "small_airport",
    name: "Venetie Airport",
    elevation_ft: "574",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Venetie",
    gps_code: "PAVE",
    iata_code: "VEE",
    local_code: "VEE",
    coordinates: "-146.365997314, 67.0086975098",
  },
  {
    ident: "PAVL",
    type: "medium_airport",
    name: "Kivalina Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kivalina",
    gps_code: "PAVL",
    iata_code: "KVL",
    local_code: "KVL",
    coordinates: "-164.56300354003906, 67.73619842529297",
  },
  {
    ident: "PAWB",
    type: "small_airport",
    name: "Beaver Airport",
    elevation_ft: "359",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Beaver",
    gps_code: "PAWB",
    iata_code: "WBQ",
    local_code: "WBQ",
    coordinates: "-147.406997681, 66.362197876",
  },
  {
    ident: "PAWD",
    type: "medium_airport",
    name: "Seward Airport",
    elevation_ft: "22",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Seward",
    gps_code: "PAWD",
    iata_code: "SWD",
    local_code: "SWD",
    coordinates: "-149.41900634765625, 60.12689971923828",
  },
  {
    ident: "PAWG",
    type: "medium_airport",
    name: "Wrangell Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Wrangell",
    gps_code: "PAWG",
    iata_code: "WRG",
    local_code: "WRG",
    coordinates: "-132.3699951, 56.48429871",
  },
  {
    ident: "PAWI",
    type: "medium_airport",
    name: "Wainwright Airport",
    elevation_ft: "41",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Wainwright",
    gps_code: "PAWI",
    iata_code: "AIN",
    local_code: "AWI",
    coordinates: "-159.994995117, 70.6380004883",
  },
  {
    ident: "PAWM",
    type: "small_airport",
    name: "White Mountain Airport",
    elevation_ft: "267",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "White Mountain",
    gps_code: "PAWM",
    iata_code: "WMO",
    local_code: "WMO",
    coordinates: "-163.412994385, 64.689201355",
  },
  {
    ident: "PAWN",
    type: "medium_airport",
    name: "Noatak Airport",
    elevation_ft: "88",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Noatak",
    gps_code: "PAWN",
    iata_code: "WTK",
    local_code: "WTK",
    coordinates: "-162.97500610351562, 67.56610107421875",
  },
  {
    ident: "PAWS",
    type: "medium_airport",
    name: "Wasilla Airport",
    elevation_ft: "354",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Wasilla",
    gps_code: "PAWS",
    iata_code: "WWA",
    local_code: "IYS",
    coordinates: "-149.539993286, 61.5717010498",
  },
  {
    ident: "PAYA",
    type: "medium_airport",
    name: "Yakutat Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Yakutat",
    gps_code: "PAYA",
    iata_code: "YAK",
    local_code: "YAK",
    coordinates: "-139.660003662, 59.5032997131",
  },
  {
    ident: "PCIS",
    type: "medium_airport",
    name: "Canton Island Airport",
    elevation_ft: "9",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-P",
    municipality: "Abariringa",
    gps_code: "PCIS",
    iata_code: "CIS",
    coordinates: "-171.710006714, -2.7681200504300003",
  },
  {
    ident: "PCO",
    type: "small_airport",
    name: "Punta Colorada Airport",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "La Ribera",
    gps_code: "MMPL",
    iata_code: "PCO",
    local_code: "PCL",
    coordinates: "-109.535826, 23.575011",
  },
  {
    ident: "PCQ",
    type: "small_airport",
    name: "Boun Neau Airport",
    elevation_ft: "3050",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-PH",
    municipality: "Phongsaly",
    gps_code: "VLFL",
    iata_code: "PCQ",
    coordinates: "101.9, 21.6475",
  },
  {
    ident: "PDI",
    type: "small_airport",
    name: "Pindiu Airport",
    elevation_ft: "2995",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Pindiu",
    gps_code: "AYPD",
    iata_code: "PDI",
    local_code: "PDU",
    coordinates: "147.515833333, -6.445138888890001",
  },
  {
    ident: "PDR",
    type: "small_airport",
    name: "Presidente Jos���� Sarney Airport",
    elevation_ft: "400",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Presidente Dutra",
    gps_code: "SJKE",
    iata_code: "PDR",
    local_code: "SJKE",
    coordinates: "-44.481, -5.3098",
  },
  {
    ident: "PE-MFT",
    type: "small_airport",
    name: "Machu Pichu Airport",
    elevation_ft: "9500",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-HUC",
    municipality: "Machu Pichu",
    iata_code: "MFT",
    coordinates: "-72.5666732788086, -13.116666793823242",
  },
  {
    ident: "PEC",
    type: "seaplane_base",
    name: "Pelican Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Pelican",
    gps_code: "PEC",
    iata_code: "PEC",
    local_code: "PEC",
    coordinates: "-136.23599243164, 57.955200195312",
  },
  {
    ident: "PFAK",
    type: "small_airport",
    name: "Akiak Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Akiak",
    gps_code: "PFAK",
    iata_code: "AKI",
    local_code: "AKI",
    coordinates: "-161.231002808, 60.9029006958",
  },
  {
    ident: "PFAL",
    type: "small_airport",
    name: "Allakaket Airport",
    elevation_ft: "441",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Allakaket",
    gps_code: "PFAL",
    iata_code: "AET",
    local_code: "6A8",
    coordinates: "-152.621994019, 66.5518035889",
  },
  {
    ident: "PFC",
    type: "small_airport",
    name: "Pacific City State Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-OR",
    municipality: "Pacific City",
    gps_code: "KPFC",
    iata_code: "PFC",
    local_code: "PFC",
    coordinates: "-123.961997986, 45.199798584",
  },
  {
    ident: "PFCB",
    type: "small_airport",
    name: "Chenega Bay Airport",
    elevation_ft: "72",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chenega",
    gps_code: "PFCB",
    iata_code: "NCN",
    local_code: "C05",
    coordinates: "-147.992004395, 60.0773010254",
  },
  {
    ident: "PFCL",
    type: "small_airport",
    name: "Clarks Point Airport",
    elevation_ft: "80",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Clarks Point",
    gps_code: "PFCL",
    iata_code: "CLP",
    local_code: "CLP",
    coordinates: "-158.529007, 58.83369827",
  },
  {
    ident: "PFEL",
    type: "small_airport",
    name: "Elim Airport",
    elevation_ft: "162",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Elim",
    gps_code: "PFEL",
    iata_code: "ELI",
    local_code: "ELI",
    coordinates: "-162.2720032, 64.61470032",
  },
  {
    ident: "PFKA",
    type: "small_airport",
    name: "Kasigluk Airport",
    elevation_ft: "48",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kasigluk",
    gps_code: "PFKA",
    iata_code: "KUK",
    local_code: "Z09",
    coordinates: "-162.5240021, 60.87440109",
  },
  {
    ident: "PFKK",
    type: "small_airport",
    name: "Kokhanok Airport",
    elevation_ft: "115",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kokhanok",
    gps_code: "PFKK",
    iata_code: "KNK",
    local_code: "9K2",
    coordinates: "-154.804000854, 59.433200836199994",
  },
  {
    ident: "PFKO",
    type: "small_airport",
    name: "Kotlik Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kotlik",
    gps_code: "PFKO",
    iata_code: "KOT",
    local_code: "2A9",
    coordinates: "-163.533004761, 63.0306015015",
  },
  {
    ident: "PFKT",
    type: "small_airport",
    name: "Brevig Mission Airport",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Brevig Mission",
    gps_code: "PFKT",
    iata_code: "KTS",
    local_code: "KTS",
    coordinates: "-166.466003418, 65.3312988281",
  },
  {
    ident: "PFKU",
    type: "small_airport",
    name: "Koyukuk Airport",
    elevation_ft: "149",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Koyukuk",
    gps_code: "PFKU",
    iata_code: "KYU",
    local_code: "KYU",
    coordinates: "-157.727005005, 64.8760986328",
  },
  {
    ident: "PFKW",
    type: "small_airport",
    name: "Kwethluk Airport",
    elevation_ft: "25",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Kwethluk",
    gps_code: "PFKW",
    iata_code: "KWT",
    local_code: "KWT",
    coordinates: "-161.444000244, 60.790298461899994",
  },
  {
    ident: "PFNO",
    type: "small_airport",
    name: "Robert (Bob) Curtis Memorial Airport",
    elevation_ft: "55",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Noorvik",
    gps_code: "PFNO",
    iata_code: "ORV",
    local_code: "D76",
    coordinates: "-161.0189972, 66.81790161",
  },
  {
    ident: "PFSH",
    type: "small_airport",
    name: "Shaktoolik Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Shaktoolik",
    gps_code: "PFSH",
    iata_code: "SKK",
    local_code: "2C7",
    coordinates: "-161.223999, 64.37110138",
  },
  {
    ident: "PFTO",
    type: "small_airport",
    name: "Tok Junction Airport",
    elevation_ft: "1639",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tok",
    gps_code: "PFTO",
    iata_code: "TKJ",
    local_code: "6K8",
    coordinates: "-142.9539948, 63.32949829",
  },
  {
    ident: "PFWS",
    type: "small_airport",
    name: "South Naknek Nr 2 Airport",
    elevation_ft: "162",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "South Naknek",
    gps_code: "PFWS",
    iata_code: "WSN",
    local_code: "WSN",
    coordinates: "-157.007995605, 58.7033996582",
  },
  {
    ident: "PFYU",
    type: "medium_airport",
    name: "Fort Yukon Airport",
    elevation_ft: "433",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Fort Yukon",
    gps_code: "PFYU",
    iata_code: "FYU",
    local_code: "FYU",
    coordinates: "-145.25, 66.57150268554688",
  },
  {
    ident: "PG-0005",
    type: "small_airport",
    name: "Cape Rodney Airport",
    elevation_ft: "22",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Cape Rodney",
    iata_code: "CPN",
    local_code: "CPR",
    coordinates: "148.379030228, -10.179823004",
  },
  {
    ident: "PG-0006",
    type: "small_airport",
    name: "Emirau Airport",
    elevation_ft: "172",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Emirau Island",
    gps_code: "AYEE",
    iata_code: "EMI",
    local_code: "ERU",
    coordinates: "149.9757667, -1.653949991",
  },
  {
    ident: "PG-0007",
    type: "small_airport",
    name: "Erave Airport",
    elevation_ft: "3650",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SHM",
    municipality: "Erave",
    gps_code: "AYEV",
    iata_code: "ERE",
    local_code: "ERV",
    coordinates: "143.900213242, -6.60646315446",
  },
  {
    ident: "PG-0008",
    type: "small_airport",
    name: "Esa'ala Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Esa'ala",
    iata_code: "ESA",
    local_code: "ESA",
    coordinates: "150.954208374, -9.874327023180001",
  },
  {
    ident: "PG-0009",
    type: "small_airport",
    name: "Garaina Airport",
    elevation_ft: "2497",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Garaina",
    gps_code: "AYGI",
    iata_code: "GAR",
    local_code: "GNA",
    coordinates: "147.141265869, -7.875625027460001",
  },
  {
    ident: "PG-0011",
    type: "small_airport",
    name: "Gonaili Airport",
    elevation_ft: "40",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Gonaili",
    gps_code: "AYGL",
    iata_code: "GOE",
    local_code: "GON",
    coordinates: "151.5733333, -5.527716614",
  },
  {
    ident: "PG-0020",
    type: "small_airport",
    name: "Bapi Airstrip",
    elevation_ft: "2930",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Bapi",
    gps_code: "AYBP",
    iata_code: "BPD",
    local_code: "BAPI",
    coordinates: "147.020555556, -7.743611111110001",
  },
  {
    ident: "PG-0022",
    type: "small_airport",
    name: "Biangabip Airport",
    elevation_ft: "1069",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Biangabip",
    gps_code: "AYBQ",
    iata_code: "BPK",
    local_code: "BIP",
    coordinates: "141.744583333, -5.526388888890001",
  },
  {
    ident: "PG-0035",
    type: "small_airport",
    name: "Nowata Airport",
    elevation_ft: "2040",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    iata_code: "NWT",
    local_code: "NTA",
    coordinates: "149.729166667, -9.984166666669998",
  },
  {
    ident: "PG-0038",
    type: "small_airport",
    name: "Sengapi Airstrip",
    elevation_ft: "6242",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Sengapi",
    gps_code: "AYSK",
    iata_code: "SGK",
    local_code: "SNI",
    coordinates: "144.323611111, -5.12583333333",
  },
  {
    ident: "PG-0048",
    type: "small_airport",
    name: "Kibuli Airstrip",
    elevation_ft: "54",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Kibuli",
    gps_code: "AYLI",
    iata_code: "KII",
    local_code: "KBL",
    coordinates: "142.698056, -9.006528",
  },
  {
    ident: "PG-AKG",
    type: "small_airport",
    name: "Anguganak Airport",
    elevation_ft: "937",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Anguganak",
    gps_code: "AYGU",
    iata_code: "AKG",
    local_code: "AGK",
    coordinates: "142.217307, -3.560504",
  },
  {
    ident: "PG-ATP",
    type: "small_airport",
    name: "Tadji Airport",
    elevation_ft: "33",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Aitape",
    gps_code: "AYTJ",
    iata_code: "TAJ",
    local_code: "TAD",
    coordinates: "142.430963516, -3.19819180354",
  },
  {
    ident: "PG-AWB",
    type: "small_airport",
    name: "Awaba Airport",
    elevation_ft: "32",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Awaba",
    gps_code: "AYAW",
    iata_code: "AWB",
    local_code: "AWB",
    coordinates: "142.748352051, -8.00611019135",
  },
  {
    ident: "PG-BAA",
    type: "small_airport",
    name: "Bialla Airport",
    elevation_ft: "51",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Bialla, Matalilu, Ewase",
    gps_code: "AYBL",
    iata_code: "BAA",
    local_code: "BIA",
    coordinates: "151.007995605, -5.3305602073700005",
  },
  {
    ident: "PG-CVB",
    type: "small_airport",
    name: "Chungribu Airport",
    elevation_ft: "130",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Chungribu",
    gps_code: "AYCB",
    iata_code: "CVB",
    local_code: "CBU",
    coordinates: "144.714996338, -4.806620121",
  },
  {
    ident: "PG-GMI",
    type: "small_airport",
    name: "Gasmata Island Airport",
    elevation_ft: "23",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Gasmata Island",
    gps_code: "AYGT",
    iata_code: "GMI",
    local_code: "GMA",
    coordinates: "150.330993652, -6.27111005783",
  },
  {
    ident: "PG-GVI",
    type: "small_airport",
    name: "Green River Airport",
    elevation_ft: "281",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Green River",
    gps_code: "AYGV",
    iata_code: "GVI",
    local_code: "GRN",
    coordinates: "141.170516968, -3.9022428989400004",
  },
  {
    ident: "PG-HYF",
    type: "small_airport",
    name: "Hayfields Airport",
    elevation_ft: "466",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Bainyik",
    gps_code: "AYHF",
    iata_code: "HYF",
    local_code: "HFD",
    coordinates: "143.057902, -3.69838",
  },
  {
    ident: "PG-IHU",
    type: "small_airport",
    name: "Ihu Airport",
    elevation_ft: "70",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Ihu",
    gps_code: "AYIH",
    iata_code: "IHU",
    local_code: "IHU",
    coordinates: "145.395996094, -7.8975601196300005",
  },
  {
    ident: "PG-IIS",
    type: "small_airport",
    name: "Nissan Island Airport",
    elevation_ft: "52",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Nissan Island",
    gps_code: "AYIA",
    iata_code: "IIS",
    local_code: "NIS",
    coordinates: "154.225997925, -4.49972009659",
  },
  {
    ident: "PG-JAQ",
    type: "small_airport",
    name: "Jacquinot Bay Airport",
    elevation_ft: "136",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Jacquinot Bay",
    gps_code: "AYJB",
    iata_code: "JAQ",
    local_code: "JCB",
    coordinates: "151.507003784, -5.65250015259",
  },
  {
    ident: "PG-KDR",
    type: "small_airport",
    name: "Kandrian Airport",
    elevation_ft: "280",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Kandrian",
    gps_code: "AYKC",
    iata_code: "KDR",
    local_code: "KDR",
    coordinates: "149.54783, -6.19217",
  },
  {
    ident: "PG-KKD",
    type: "small_airport",
    name: "Kokoda Airport",
    elevation_ft: "1240",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Kokoda",
    gps_code: "AYKO",
    iata_code: "KKD",
    local_code: "KOK",
    coordinates: "147.730957031, -8.88467884064",
  },
  {
    ident: "PG-KUY",
    type: "small_airport",
    name: "Kamusi Airport",
    elevation_ft: "97",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Kamusi",
    gps_code: "AYKS",
    iata_code: "KUY",
    local_code: "KSI",
    coordinates: "143.121811, -7.420347",
  },
  {
    ident: "PG-KWO",
    type: "small_airport",
    name: "Kawito Airport",
    elevation_ft: "72",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Kawito",
    gps_code: "AYKW",
    iata_code: "KWO",
    local_code: "KWT",
    coordinates: "142.823577881, -7.977006912229999",
  },
  {
    ident: "PG-KXR",
    type: "closed",
    name: "Karoola Airport",
    elevation_ft: "52",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Buka Island",
    iata_code: "KXR",
    coordinates: "154.5928, -5.1431",
  },
  {
    ident: "PG-LMI",
    type: "small_airport",
    name: "Lumi Airport",
    elevation_ft: "1750",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Lumi",
    gps_code: "AYLU",
    iata_code: "LMI",
    local_code: "LUMI",
    coordinates: "142.041292191, -3.47683951134",
  },
  {
    ident: "PG-LMY",
    type: "small_airport",
    name: "Lake Murray Airport",
    elevation_ft: "52",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Lake Murray",
    gps_code: "AYLM",
    iata_code: "LMY",
    local_code: "LKM",
    coordinates: "141.494003296, -7.00992012024",
  },
  {
    ident: "PG-OBX",
    type: "small_airport",
    name: "Obo Airport",
    elevation_ft: "29",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Obo",
    gps_code: "AYOB",
    iata_code: "OBX",
    local_code: "OBO",
    coordinates: "141.324262619, -7.590622421369999",
  },
  {
    ident: "PG-OPU",
    type: "medium_airport",
    name: "Balimo Airport",
    elevation_ft: "51",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Balimo",
    gps_code: "AYBM",
    iata_code: "OPU",
    local_code: "BLI",
    coordinates: "142.932998657, -8.05000019073",
  },
  {
    ident: "PG-SKC",
    type: "small_airport",
    name: "Suki Airport",
    elevation_ft: "24",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Suki",
    gps_code: "AYSU",
    iata_code: "SKC",
    local_code: "SUKI",
    coordinates: "141.7222, -8.0466",
  },
  {
    ident: "PG-TFI",
    type: "small_airport",
    name: "Tufi Airport",
    elevation_ft: "85",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Tufi",
    gps_code: "AYTU",
    iata_code: "TFI",
    local_code: "TUFI",
    coordinates: "149.319839478, -9.07595443726",
  },
  {
    ident: "PG-TFM",
    type: "small_airport",
    name: "Telefomin Airport",
    elevation_ft: "4950",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Telefomin",
    gps_code: "AYTE",
    iata_code: "TFM",
    local_code: "TLF",
    coordinates: "141.641921997, -5.126080464149999",
  },
  {
    ident: "PG-TLO",
    type: "small_airport",
    name: "Tol Airport",
    elevation_ft: "49",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Tol",
    gps_code: "AYXO",
    iata_code: "TLO",
    local_code: "TOL",
    coordinates: "152.009994507, -4.98083019257",
  },
  {
    ident: "PG-UKU",
    type: "small_airport",
    name: "Nuku Airport",
    elevation_ft: "870",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Nuku",
    gps_code: "AYNU",
    iata_code: "UKU",
    local_code: "NUKU",
    coordinates: "142.484334, -3.676651",
  },
  {
    ident: "PG-ULE",
    type: "small_airport",
    name: "Sule Airport",
    elevation_ft: "100",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WBK",
    municipality: "Sule",
    iata_code: "ULE",
    coordinates: "151.2969970703125, -4.974720001220703",
  },
  {
    ident: "PG-VMU",
    type: "medium_airport",
    name: "Baimuru Airport",
    elevation_ft: "27",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Baimuru",
    gps_code: "AYBA",
    iata_code: "VMU",
    local_code: "BMU",
    coordinates: "144.819900513, -7.49686002731",
  },
  {
    ident: "PG-WPM",
    type: "small_airport",
    name: "Wipim Airport",
    elevation_ft: "173",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Wipim",
    gps_code: "AYXP",
    iata_code: "WPM",
    local_code: "WPM",
    coordinates: "142.882003784, -8.788220405579999",
  },
  {
    ident: "PGE",
    type: "small_airport",
    name: "Yegepa Airport",
    elevation_ft: "4179",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    iata_code: "PGE",
    local_code: "YEG",
    coordinates: "146.156388889, -7.134444444440001",
  },
  {
    ident: "PGM",
    type: "small_airport",
    name: "Port Graham Airport",
    elevation_ft: "93",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Graham",
    gps_code: "PGM",
    iata_code: "PGM",
    local_code: "PGM",
    coordinates: "-151.83200073242, 59.348300933838",
  },
  {
    ident: "PGP",
    type: "closed",
    name: "Ro����a Porto Alegre Airport",
    elevation_ft: "32",
    continent: "AF",
    iso_country: "ST",
    iso_region: "ST-S",
    municipality: "Porto Alegro",
    iata_code: "PGP",
    coordinates: "6.515965, 0.038624",
  },
  {
    ident: "PGRO",
    type: "medium_airport",
    name: "Rota International Airport",
    elevation_ft: "607",
    continent: "OC",
    iso_country: "MP",
    iso_region: "MP-U-A",
    municipality: "Rota Island",
    gps_code: "PGRO",
    iata_code: "ROP",
    local_code: "GRO",
    coordinates: "145.2429962158203, 14.174300193786621",
  },
  {
    ident: "PGSN",
    type: "medium_airport",
    name: "Saipan International Airport",
    elevation_ft: "215",
    continent: "OC",
    iso_country: "MP",
    iso_region: "MP-U-A",
    municipality: "Saipan Island",
    gps_code: "PGSN",
    iata_code: "SPN",
    local_code: "GSN",
    coordinates: "145.729004, 15.119",
  },
  {
    ident: "PGUA",
    type: "medium_airport",
    name: "Andersen Air Force Base",
    elevation_ft: "627",
    continent: "OC",
    iso_country: "GU",
    iso_region: "GU-U-A",
    municipality: "Yigo, Mariana Island",
    gps_code: "PGUA",
    iata_code: "UAM",
    local_code: "UAM",
    coordinates: "144.929998, 13.584",
  },
  {
    ident: "PGUM",
    type: "large_airport",
    name: "Antonio B. Won Pat International Airport",
    elevation_ft: "298",
    continent: "OC",
    iso_country: "GU",
    iso_region: "GU-U-A",
    municipality: "Hag����t����a, Guam International Airport",
    gps_code: "PGUM",
    iata_code: "GUM",
    local_code: "GUM",
    coordinates: "144.796005249, 13.4834003448",
  },
  {
    ident: "PGWT",
    type: "medium_airport",
    name: "Tinian International Airport",
    elevation_ft: "271",
    continent: "OC",
    iso_country: "MP",
    iso_region: "MP-U-A",
    municipality: "Tinian Island",
    gps_code: "PGWT",
    iata_code: "TIQ",
    local_code: "TNI",
    coordinates: "145.61900329589844, 14.999199867248535",
  },
  {
    ident: "PH-0001",
    type: "large_airport",
    name: "Laguindingan Airport",
    elevation_ft: "190",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-MSR",
    municipality: "Cagayan de Oro City",
    gps_code: "RPMY",
    iata_code: "CGY",
    coordinates: "124.456496, 8.612203",
  },
  {
    ident: "PH-ENI",
    type: "small_airport",
    name: "El Nido Airport",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-PLW",
    municipality: "El Nido",
    gps_code: "RPEN",
    iata_code: "ENI",
    coordinates: "119.416087, 11.202399",
  },
  {
    ident: "PHBK",
    type: "medium_airport",
    name: "Barking Sands Airport",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Kekaha",
    gps_code: "PHBK",
    iata_code: "BKH",
    local_code: "BKH",
    coordinates: "-159.785003662, 22.022800445599998",
  },
  {
    ident: "PHDH",
    type: "medium_airport",
    name: "Dillingham Airfield",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Mokuleia",
    gps_code: "PHDH",
    iata_code: "HDH",
    local_code: "HDH",
    coordinates: "-158.197006226, 21.5795001984",
  },
  {
    ident: "PHHI",
    type: "medium_airport",
    name: "Wheeler Army Airfield",
    elevation_ft: "837",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Wahiawa",
    gps_code: "PHHI",
    iata_code: "HHI",
    local_code: "HHI",
    coordinates: "-158.0399933, 21.48349953",
  },
  {
    ident: "PHHN",
    type: "medium_airport",
    name: "Hana Airport",
    elevation_ft: "78",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Hana",
    gps_code: "PHHN",
    iata_code: "HNM",
    local_code: "HNM",
    coordinates: "-156.01400756835938, 20.79560089111328",
  },
  {
    ident: "PHJH",
    type: "medium_airport",
    name: "Kapalua Airport",
    elevation_ft: "256",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Lahaina",
    gps_code: "PHJH",
    iata_code: "JHM",
    local_code: "JHM",
    coordinates: "-156.67300415039062, 20.962900161743164",
  },
  {
    ident: "PHJR",
    type: "medium_airport",
    name: "Kalaeloa Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Kapolei",
    gps_code: "PHJR",
    iata_code: "JRF",
    local_code: "JRF",
    coordinates: "-158.070009, 21.3074",
  },
  {
    ident: "PHKO",
    type: "medium_airport",
    name: "Ellison Onizuka Kona International At Keahole Airport",
    elevation_ft: "47",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Kailua/Kona",
    gps_code: "PHKO",
    iata_code: "KOA",
    local_code: "KOA",
    coordinates: "-156.045603, 19.738783",
  },
  {
    ident: "PHKP",
    type: "closed",
    name: "Kaanapali Airport",
    elevation_ft: "3",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Lahaina",
    gps_code: "PHKP",
    iata_code: "HKP",
    local_code: "HKP",
    coordinates: "-156.69, 20.945",
  },
  {
    ident: "PHLI",
    type: "medium_airport",
    name: "Lihue Airport",
    elevation_ft: "153",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Lihue",
    gps_code: "PHLI",
    iata_code: "LIH",
    local_code: "LIH",
    coordinates: "-159.33900451660156, 21.97599983215332",
  },
  {
    ident: "PHLU",
    type: "small_airport",
    name: "Kalaupapa Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Kalaupapa",
    gps_code: "PHLU",
    iata_code: "LUP",
    local_code: "LUP",
    coordinates: "-156.973999, 21.21100044",
  },
  {
    ident: "PHMK",
    type: "medium_airport",
    name: "Molokai Airport",
    elevation_ft: "454",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Kaunakakai",
    gps_code: "PHMK",
    iata_code: "MKK",
    local_code: "MKK",
    coordinates: "-157.0959930419922, 21.15290069580078",
  },
  {
    ident: "PHMU",
    type: "medium_airport",
    name: "Waimea Kohala Airport",
    elevation_ft: "2671",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Kamuela",
    gps_code: "PHMU",
    iata_code: "MUE",
    local_code: "MUE",
    coordinates: "-155.66799926757812, 20.001300811767578",
  },
  {
    ident: "PHNG",
    type: "medium_airport",
    name: "Kaneohe Bay MCAS (Marion E. Carl Field) Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Kaneohe",
    gps_code: "PHNG",
    iata_code: "NGF",
    local_code: "NGF",
    coordinates: "-157.768005371, 21.4505004883",
  },
  {
    ident: "PHNL",
    type: "large_airport",
    name: "Daniel K Inouye International Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Honolulu",
    gps_code: "PHNL",
    iata_code: "HNL",
    local_code: "HNL",
    coordinates: "-157.924228, 21.32062",
  },
  {
    ident: "PHNY",
    type: "medium_airport",
    name: "Lanai Airport",
    elevation_ft: "1308",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Lanai City",
    gps_code: "PHNY",
    iata_code: "LNY",
    local_code: "LNY",
    coordinates: "-156.9510040283203, 20.785600662231445",
  },
  {
    ident: "PHOG",
    type: "medium_airport",
    name: "Kahului Airport",
    elevation_ft: "54",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Kahului",
    gps_code: "PHOG",
    iata_code: "OGG",
    local_code: "OGG",
    coordinates: "-156.429993, 20.8986",
  },
  {
    ident: "PHPA",
    type: "small_airport",
    name: "Port Allen Airport",
    elevation_ft: "24",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Hanapepe",
    gps_code: "PHPA",
    iata_code: "PAK",
    local_code: "PAK",
    coordinates: "-159.602996826, 21.896900177",
  },
  {
    ident: "PHSF",
    type: "medium_airport",
    name: "Bradshaw Army Airfield",
    elevation_ft: "6190",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Camp Pohakuloa",
    gps_code: "PHSF",
    iata_code: "BSF",
    local_code: "BSF",
    coordinates: "-155.554000854, 19.760099411",
  },
  {
    ident: "PHTO",
    type: "medium_airport",
    name: "Hilo International Airport",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Hilo",
    gps_code: "PHTO",
    iata_code: "ITO",
    local_code: "ITO",
    coordinates: "-155.04800415039062, 19.721399307250977",
  },
  {
    ident: "PHUP",
    type: "medium_airport",
    name: "Upolu Airport",
    elevation_ft: "96",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-HI",
    municipality: "Hawi",
    gps_code: "PHUP",
    iata_code: "UPP",
    local_code: "UPP",
    coordinates: "-155.86000061035156, 20.265300750732422",
  },
  {
    ident: "PJON",
    type: "closed",
    name: "Johnston Atoll Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "UM",
    iso_region: "UM-67",
    municipality: "Johnston Atoll",
    gps_code: "PJON",
    iata_code: "JON",
    coordinates: "-169.533996582, 16.7285995483",
  },
  {
    ident: "PK-BHC",
    type: "small_airport",
    name: "Bhurban Heliport",
    elevation_ft: "5750",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NW",
    municipality: "Bhurban",
    iata_code: "BHC",
    coordinates: "73.4520034790039, 33.96099853515625",
  },
  {
    ident: "PK-CWP",
    type: "small_airport",
    name: "Campbellpore Airport",
    elevation_ft: "1175",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Campbellpore",
    iata_code: "CWP",
    coordinates: "72.36689758300781, 33.766700744628906",
  },
  {
    ident: "PK-GRT",
    type: "small_airport",
    name: "Gujrat Airport",
    elevation_ft: "760",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-PB",
    municipality: "Gujrat",
    iata_code: "GRT",
    coordinates: "74.07710266113281, 32.60309982299805",
  },
  {
    ident: "PK-HRA",
    type: "small_airport",
    name: "Mansehra Airport",
    elevation_ft: "3530",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-NW",
    municipality: "Mansehra",
    iata_code: "HRA",
    coordinates: "73.20020294189453, 34.33332061767578",
  },
  {
    ident: "PK-KCF",
    type: "small_airport",
    name: "Kadanwari Airport",
    elevation_ft: "248",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Kadanwari",
    gps_code: "OPKW",
    iata_code: "KCF",
    coordinates: "69.31670379639999, 27.1667003632",
  },
  {
    ident: "PK-REQ",
    type: "small_airport",
    name: "Reko Diq Airport",
    elevation_ft: "2784",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-BA",
    municipality: "Chagai",
    iata_code: "REQ",
    coordinates: "62.196819, 29.04692",
  },
  {
    ident: "PK-RZS",
    type: "small_airport",
    name: "Sawan Airport",
    elevation_ft: "160",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Sawan",
    gps_code: "OPSW",
    iata_code: "RZS",
    coordinates: "68.878098, 26.9662",
  },
  {
    ident: "PK-SWV",
    type: "closed",
    name: "Shikarpur Airport",
    elevation_ft: "219",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Shikarpur",
    iata_code: "SWV",
    coordinates: "70.433333, 29.166667",
  },
  {
    ident: "PKMA",
    type: "small_airport",
    name: "Eniwetok Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-ENI",
    municipality: "Eniwetok Atoll",
    gps_code: "PKMA",
    iata_code: "ENT",
    coordinates: "162.3280029296875, 11.340700149536133",
  },
  {
    ident: "PKMJ",
    type: "medium_airport",
    name: "Marshall Islands International Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-MAJ",
    municipality: "Majuro Atoll",
    gps_code: "PKMJ",
    iata_code: "MAJ",
    local_code: "MAJ",
    coordinates: "171.27200317382812, 7.064760208129883",
  },
  {
    ident: "PKSA",
    type: "medium_airport",
    name: "Kaieteur International Airport",
    elevation_ft: "1520",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-PT",
    municipality: "Kaieteur Falls",
    gps_code: "SYKA",
    iata_code: "KAI",
    coordinates: "-59.491481781, 5.17275476456",
  },
  {
    ident: "PKWA",
    type: "medium_airport",
    name: "Bucholz Army Air Field",
    elevation_ft: "9",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-KWA",
    municipality: "Kwajalein",
    gps_code: "PKWA",
    iata_code: "KWA",
    local_code: "KWA",
    coordinates: "167.73199462890625, 8.720120429992676",
  },
  {
    ident: "PLCH",
    type: "medium_airport",
    name: "Cassidy International Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-L",
    municipality: "Banana",
    gps_code: "PLCH",
    iata_code: "CXI",
    coordinates: "-157.35000610351562, 1.9861600399017334",
  },
  {
    ident: "PLE",
    type: "small_airport",
    name: "Paiela Airport",
    elevation_ft: "6100",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EPW",
    municipality: "Paiela",
    gps_code: "AYPB",
    iata_code: "PLE",
    local_code: "PAL",
    coordinates: "142.976111111, -5.3725",
  },
  {
    ident: "PLFA",
    type: "small_airport",
    name: "Tabuaeran Island Airport",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-L",
    municipality: "Tabuaeran Island",
    gps_code: "PLFA",
    iata_code: "TNV",
    coordinates: "-159.38900756835938, 3.899440050125122",
  },
  {
    ident: "PLWN",
    type: "small_airport",
    name: "Washington Island Airstrip",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "KI",
    iso_region: "KI-L",
    municipality: "Teraina",
    gps_code: "PLWN",
    iata_code: "TNQ",
    coordinates: "-160.394376, 4.698359",
  },
  {
    ident: "PMDY",
    type: "medium_airport",
    name: "Henderson Field",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "UM",
    iso_region: "UM-71",
    municipality: "Sand Island",
    gps_code: "PMDY",
    iata_code: "MDY",
    local_code: "MDY",
    coordinates: "-177.38099670410156, 28.20170021057129",
  },
  {
    ident: "PMM",
    type: "small_airport",
    name: "Phanom Sarakham Airport",
    elevation_ft: "166",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-24",
    municipality: "Phanom Sarakham",
    iata_code: "PMM",
    coordinates: "101.395, 13.7553",
  },
  {
    ident: "PMP",
    type: "small_airport",
    name: "Pimaga Airport",
    elevation_ft: "2745",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SHM",
    municipality: "Pimaga",
    gps_code: "AYPJ",
    iata_code: "PMP",
    local_code: "PAG",
    coordinates: "143.510277778, -6.49916666667",
  },
  {
    ident: "PPIZ",
    type: "medium_airport",
    name: "Point Lay LRRS Airport",
    elevation_ft: "22",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Point Lay",
    gps_code: "PPIZ",
    iata_code: "PIZ",
    local_code: "PIZ",
    coordinates: "-163.0050049, 69.73290253",
  },
  {
    ident: "PPX",
    type: "small_airport",
    name: "Param Airport",
    elevation_ft: "4490",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Nepesi",
    iata_code: "PPX",
    local_code: "PAM",
    coordinates: "149.477, -9.99",
  },
  {
    ident: "PQD",
    type: "heliport",
    name: "Passikudah Helipad",
    elevation_ft: "26",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-5",
    municipality: "Batticaloa",
    iata_code: "PQD",
    coordinates: "81.567735672, 7.92310659595",
  },
  {
    ident: "PR-DDP",
    type: "closed",
    name: "Dorado Beach Airport",
    elevation_ft: "75",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Dorado",
    iata_code: "DDP",
    coordinates: "-66.295580864, 18.4649657136",
  },
  {
    ident: "PR-HUC",
    type: "small_airport",
    name: "Humacao Airport",
    elevation_ft: "33",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Humacao",
    iata_code: "HUC",
    local_code: "X63",
    coordinates: "-65.8013000488, 18.1380996704",
  },
  {
    ident: "PR-PPD",
    type: "closed",
    name: "Palmas del Mar Airstrip",
    elevation_ft: "100",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Palmas del Mar",
    iata_code: "PPD",
    coordinates: "-65.796501159668, 18.086200714111",
  },
  {
    ident: "PRNO",
    type: "small_airport",
    name: "Siocon Airport",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ZAN",
    gps_code: "PRNO",
    iata_code: "XSO",
    coordinates: "122.161188126, 7.710481327750001",
  },
  {
    ident: "PTKK",
    type: "medium_airport",
    name: "Chuuk International Airport",
    elevation_ft: "11",
    continent: "OC",
    iso_country: "FM",
    iso_region: "FM-TRK",
    municipality: "Weno Island",
    gps_code: "PTKK",
    iata_code: "TKK",
    local_code: "TKK",
    coordinates: "151.84300231933594, 7.461870193481445",
  },
  {
    ident: "PTPN",
    type: "medium_airport",
    name: "Pohnpei International Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "FM",
    iso_region: "FM-PNI",
    municipality: "Pohnpei Island",
    gps_code: "PTPN",
    iata_code: "PNI",
    local_code: "PNI",
    coordinates: "158.20899963378906, 6.985099792480469",
  },
  {
    ident: "PTRO",
    type: "medium_airport",
    name: "Babelthuap Airport",
    elevation_ft: "176",
    continent: "OC",
    iso_country: "PW",
    iso_region: "PW-004",
    municipality: "Babelthuap Island",
    gps_code: "PTRO",
    iata_code: "ROR",
    local_code: "ROR",
    coordinates: "134.544236, 7.36731",
  },
  {
    ident: "PTSA",
    type: "medium_airport",
    name: "Kosrae International Airport",
    elevation_ft: "11",
    continent: "OC",
    iso_country: "FM",
    iso_region: "FM-KSA",
    municipality: "Okat",
    gps_code: "PTSA",
    iata_code: "KSA",
    local_code: "TTK",
    coordinates: "162.957993, 5.35698",
  },
  {
    ident: "PTYA",
    type: "medium_airport",
    name: "Yap International Airport",
    elevation_ft: "91",
    continent: "OC",
    iso_country: "FM",
    iso_region: "FM-YAP",
    municipality: "Yap Island",
    gps_code: "PTYA",
    iata_code: "YAP",
    local_code: "T11",
    coordinates: "138.082993, 9.49891",
  },
  {
    ident: "PUA",
    type: "small_airport",
    name: "Puas Airport",
    elevation_ft: "45",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Puas Mission",
    iata_code: "PUA",
    local_code: "PUAS",
    coordinates: "150.2361, -2.395",
  },
  {
    ident: "PUI",
    type: "closed",
    name: "Pureni Airport",
    elevation_ft: "5512",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Pureni",
    iata_code: "PUI",
    coordinates: "142.8279, -5.8429",
  },
  {
    ident: "PWAK",
    type: "medium_airport",
    name: "Wake Island Airfield",
    elevation_ft: "14",
    continent: "OC",
    iso_country: "UM",
    iso_region: "UM-79",
    municipality: "Wake Island",
    gps_code: "PWAK",
    iata_code: "AWK",
    local_code: "AWK",
    coordinates: "166.63600158691406, 19.282100677490234",
  },
  {
    ident: "PWR",
    type: "seaplane_base",
    name: "Port Walter Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Walter",
    gps_code: "PWR",
    iata_code: "PWR",
    local_code: "PWR",
    coordinates: "-134.651000977, 56.3810005188",
  },
  {
    ident: "PY-BFA",
    type: "small_airport",
    name: "Bah����a Negra Airport",
    elevation_ft: "277",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-16",
    municipality: "Bah����a Negra",
    gps_code: "SGBN",
    iata_code: "BFA",
    coordinates: "-58.1791992188, -20.2245998383",
  },
  {
    ident: "PY-OLK",
    type: "small_airport",
    name: "Fuerte Olimpo Airport",
    elevation_ft: "275",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-16",
    municipality: "Fuerte Olimpo",
    gps_code: "SGOL",
    iata_code: "OLK",
    coordinates: "-57.8825, -21.0452",
  },
  {
    ident: "PY-PBT",
    type: "small_airport",
    name: "Puerto Leda Airport",
    elevation_ft: "260",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-16",
    municipality: "Puerto Leda",
    iata_code: "PBT",
    coordinates: "-57.927101135253906, -20.904600143432617",
  },
  {
    ident: "PY-PCJ",
    type: "small_airport",
    name: "Puerto La Victoria Airport",
    elevation_ft: "286",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-1",
    municipality: "Puerto La Victoria",
    gps_code: "SGLV",
    iata_code: "PCJ",
    coordinates: "-57.866333, -22.295041",
  },
  {
    ident: "PYL",
    type: "seaplane_base",
    name: "Perry Island Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Perry Island",
    gps_code: "PYL",
    iata_code: "PYL",
    local_code: "PYL",
    coordinates: "-147.919006348, 60.685298919699996",
  },
  {
    ident: "Q51",
    type: "small_airport",
    name: "Kili Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-KIL",
    municipality: "Kili Island",
    iata_code: "KIO",
    local_code: "C51",
    coordinates: "169.119507, 5.644515",
  },
  {
    ident: "qfx",
    type: "heliport",
    name: "Igaliku Heliport",
    continent: "NA",
    iso_country: "GL",
    iso_region: "GL-U-A",
    municipality: "Igaliku",
    gps_code: "BGIO",
    iata_code: "QFX",
    coordinates: "-45.4323345423, 60.9920065221",
  },
  {
    ident: "RAA",
    type: "small_airport",
    name: "Rakanda Airport",
    elevation_ft: "38",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Rakanda",
    iata_code: "RAA",
    local_code: "RAK",
    coordinates: "152.4385, -4.2096",
  },
  {
    ident: "RAW",
    type: "small_airport",
    name: "Arawa Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Arawa",
    iata_code: "RAW",
    local_code: "ARA",
    coordinates: "155.571388889, -6.220555555560001",
  },
  {
    ident: "RAX",
    type: "small_airport",
    name: "Oram Airport",
    elevation_ft: "3500",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    gps_code: "AYYO",
    iata_code: "RAX",
    local_code: "ORAM",
    coordinates: "148.048055556, -9.632916666669997",
  },
  {
    ident: "RBP",
    type: "small_airport",
    name: "Raba Raba Airport",
    elevation_ft: "170",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Rabaraba",
    gps_code: "AYRE",
    iata_code: "RBP",
    local_code: "RBA",
    coordinates: "149.832222222, -9.97111111111",
  },
  {
    ident: "RCBS",
    type: "large_airport",
    name: "Kinmen Airport",
    elevation_ft: "93",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-X-KM",
    municipality: "Shang-I",
    gps_code: "RCBS",
    iata_code: "KNH",
    coordinates: "118.359001, 24.4279",
  },
  {
    ident: "RCFG",
    type: "medium_airport",
    name: "Matsu Nangan Airport",
    elevation_ft: "232",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-X-LK",
    municipality: "Nangang Island",
    gps_code: "RCFG",
    iata_code: "LZN",
    coordinates: "119.958, 26.1598",
  },
  {
    ident: "RCFN",
    type: "medium_airport",
    name: "Taitung Airport",
    elevation_ft: "143",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-TTT",
    municipality: "Taitung City",
    gps_code: "RCFN",
    iata_code: "TTT",
    coordinates: "121.10199737548828, 22.7549991607666",
  },
  {
    ident: "RCGI",
    type: "small_airport",
    name: "Lyudao Airport",
    elevation_ft: "28",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-TTT",
    municipality: "Lyudao",
    gps_code: "RCGI",
    iata_code: "GNI",
    coordinates: "121.46600341796875, 22.673900604248047",
  },
  {
    ident: "RCKH",
    type: "large_airport",
    name: "Kaohsiung International Airport",
    elevation_ft: "31",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-KHH",
    municipality: "Kaohsiung City",
    gps_code: "RCKH",
    iata_code: "KHH",
    coordinates: "120.3499984741211, 22.57710075378418",
  },
  {
    ident: "RCKU",
    type: "medium_airport",
    name: "Chiayi Airport",
    elevation_ft: "85",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-CYQ",
    municipality: "Chiayi City",
    gps_code: "RCKU",
    iata_code: "CYI",
    coordinates: "120.39299774169922, 23.46179962158203",
  },
  {
    ident: "RCKW",
    type: "medium_airport",
    name: "Hengchun Airport",
    elevation_ft: "46",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-PIF",
    municipality: "Hengchung",
    gps_code: "RCKW",
    iata_code: "HCN",
    coordinates: "120.7300033569336, 22.041099548339844",
  },
  {
    ident: "RCLG",
    type: "closed",
    name: "Taichung Airport",
    elevation_ft: "369",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-TXG",
    municipality: "Taichung City",
    gps_code: "RCLG",
    iata_code: "TXG",
    coordinates: "120.653999, 24.1863",
  },
  {
    ident: "RCLY",
    type: "medium_airport",
    name: "Lanyu Airport",
    elevation_ft: "44",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-TTT",
    municipality: "Orchid Island",
    gps_code: "RCLY",
    iata_code: "KYD",
    coordinates: "121.53500366210938, 22.027000427246094",
  },
  {
    ident: "RCMQ",
    type: "medium_airport",
    name: "Taichung Ching Chuang Kang Airport",
    elevation_ft: "663",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-TXG",
    municipality: "Taichung City",
    gps_code: "RCMQ",
    iata_code: "RMQ",
    coordinates: "120.62100219726562, 24.264699935913086",
  },
  {
    ident: "RCMT",
    type: "medium_airport",
    name: "Matsu Beigan Airport",
    elevation_ft: "41",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-X-LK",
    municipality: "Beigan Island",
    gps_code: "RCMT",
    iata_code: "MFK",
    coordinates: "120.002998, 26.224199",
  },
  {
    ident: "RCN",
    type: "closed",
    name: "American River Airport",
    elevation_ft: "389",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "American River",
    iata_code: "RCN",
    coordinates: "137.7759, -35.7574",
  },
  {
    ident: "RCNN",
    type: "medium_airport",
    name: "Tainan Airport",
    elevation_ft: "63",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-TNN",
    municipality: "Tainan City",
    gps_code: "RCNN",
    iata_code: "TNN",
    coordinates: "120.20600128173828, 22.95039939880371",
  },
  {
    ident: "RCPO",
    type: "medium_airport",
    name: "Hsinchu Air Base",
    elevation_ft: "26",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-HSZ",
    municipality: "Hsinchu City",
    gps_code: "RCPO",
    iata_code: "HSZ",
    coordinates: "120.939002991, 24.8180007935",
  },
  {
    ident: "RCQC",
    type: "medium_airport",
    name: "Makung Airport",
    elevation_ft: "103",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-PEN",
    municipality: "Makung City",
    gps_code: "RCQC",
    iata_code: "MZG",
    coordinates: "119.62799835205078, 23.568700790405273",
  },
  {
    ident: "RCSQ",
    type: "medium_airport",
    name: "Pingtung North Airport",
    elevation_ft: "97",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-PIF",
    municipality: "Pingtung",
    gps_code: "RCSQ",
    iata_code: "PIF",
    coordinates: "120.48200225830078, 22.700199127197266",
  },
  {
    ident: "RCSS",
    type: "medium_airport",
    name: "Taipei Songshan Airport",
    elevation_ft: "18",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-TPE",
    municipality: "Taipei City",
    gps_code: "RCSS",
    iata_code: "TSA",
    coordinates: "121.552001953125, 25.069400787353516",
  },
  {
    ident: "RCTP",
    type: "large_airport",
    name: "Taiwan Taoyuan International Airport",
    elevation_ft: "106",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-TAO",
    municipality: "Taipei",
    gps_code: "RCTP",
    iata_code: "TPE",
    coordinates: "121.233002, 25.0777",
  },
  {
    ident: "RCWA",
    type: "small_airport",
    name: "Wang-an Airport",
    elevation_ft: "115",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-PEN",
    municipality: "Wang-an",
    gps_code: "RCWA",
    iata_code: "WOT",
    coordinates: "119.50277709960938, 23.367372512817383",
  },
  {
    ident: "RCYU",
    type: "medium_airport",
    name: "Hualien Airport",
    elevation_ft: "52",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-HUA",
    municipality: "Hualien City",
    gps_code: "RCYU",
    iata_code: "HUN",
    coordinates: "121.61799621582031, 24.023099899291992",
  },
  {
    ident: "RDV",
    type: "small_airport",
    name: "Red Devil Airport",
    elevation_ft: "174",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Red Devil",
    gps_code: "RDV",
    iata_code: "RDV",
    local_code: "RDV",
    coordinates: "-157.350006104, 61.7881011963",
  },
  {
    ident: "REH",
    type: "closed",
    name: "Rehoboth Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-DE",
    municipality: "Rehoboth Beach",
    iata_code: "REH",
    coordinates: "-75.122, 38.72",
  },
  {
    ident: "RGE",
    type: "closed",
    name: "Porgera Airport",
    elevation_ft: "7360",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EPW",
    municipality: "Porgera",
    iata_code: "RGE",
    coordinates: "143.1236, -5.4792",
  },
  {
    ident: "RHR",
    type: "seaplane_base",
    name: "Ras al Khaimah Al Hamra Seaplane Base",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-RK",
    municipality: "Ras Al Khaimah",
    iata_code: "RHR",
    coordinates: "55.778, 25.691",
  },
  {
    ident: "RHT",
    type: "small_airport",
    name: "Alxa Right Banner Badanjilin Airport",
    elevation_ft: "4659",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Badanjilin",
    gps_code: "ZBAR",
    iata_code: "RHT",
    coordinates: "101.546, 39.225",
  },
  {
    ident: "RJAA",
    type: "large_airport",
    name: "Narita International Airport",
    elevation_ft: "141",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-12",
    municipality: "Tokyo / Narita",
    gps_code: "RJAA",
    iata_code: "NRT",
    coordinates: "140.386002, 35.764702",
  },
  {
    ident: "RJAF",
    type: "medium_airport",
    name: "Matsumoto Airport",
    elevation_ft: "2182",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-20",
    municipality: "Matsumoto",
    gps_code: "RJAF",
    iata_code: "MMJ",
    coordinates: "137.92300415039062, 36.16680145263672",
  },
  {
    ident: "RJAH",
    type: "medium_airport",
    name: "Ibaraki Airport / JASDF Hyakuri Air Base",
    elevation_ft: "105",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-08",
    municipality: "Omitama",
    gps_code: "RJAH",
    iata_code: "IBR",
    coordinates: "140.414993, 36.181099",
  },
  {
    ident: "RJAM",
    type: "small_airport",
    name: "JMSDF Minami Torishima Air Base",
    elevation_ft: "22",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-13",
    gps_code: "RJAM",
    iata_code: "MUS",
    coordinates: "153.979004, 24.2897",
  },
  {
    ident: "RJAW",
    type: "medium_airport",
    name: "Iwo Jima Airport",
    elevation_ft: "384",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-13",
    gps_code: "RJAW",
    iata_code: "IWO",
    coordinates: "141.322998046875, 24.784000396728516",
  },
  {
    ident: "RJBB",
    type: "large_airport",
    name: "Kansai International Airport",
    elevation_ft: "26",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-27",
    municipality: "Osaka",
    gps_code: "RJBB",
    iata_code: "KIX",
    coordinates: "135.24400329589844, 34.42729949951172",
  },
  {
    ident: "RJBD",
    type: "medium_airport",
    name: "Nanki Shirahama Airport",
    elevation_ft: "298",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-30",
    municipality: "Shirahama",
    gps_code: "RJBD",
    iata_code: "SHM",
    coordinates: "135.363998413, 33.6622009277",
  },
  {
    ident: "RJBE",
    type: "medium_airport",
    name: "Kobe Airport",
    elevation_ft: "22",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-28",
    municipality: "Kobe",
    gps_code: "RJBE",
    iata_code: "UKB",
    coordinates: "135.2239990234375, 34.6328010559082",
  },
  {
    ident: "RJBH",
    type: "heliport",
    name: "Hiroshima Heliport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-34",
    municipality: "Hiroshima",
    gps_code: "RJBH",
    iata_code: "HIW",
    coordinates: "132.417666, 34.371451",
  },
  {
    ident: "RJBT",
    type: "medium_airport",
    name: "Konotori Tajima Airport",
    elevation_ft: "584",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-28",
    municipality: "Toyooka",
    gps_code: "RJBT",
    iata_code: "TJH",
    coordinates: "134.787003, 35.512798",
  },
  {
    ident: "RJCB",
    type: "medium_airport",
    name: "Tokachi-Obihiro Airport",
    elevation_ft: "505",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Obihiro",
    gps_code: "RJCB",
    iata_code: "OBO",
    coordinates: "143.216995239, 42.7332992554",
  },
  {
    ident: "RJCC",
    type: "large_airport",
    name: "New Chitose Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Chitose / Tomakomai",
    gps_code: "RJCC",
    iata_code: "CTS",
    coordinates: "141.69200134277344, 42.77519989013672",
  },
  {
    ident: "RJCH",
    type: "medium_airport",
    name: "Hakodate Airport",
    elevation_ft: "151",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Hakodate",
    gps_code: "RJCH",
    iata_code: "HKD",
    coordinates: "140.822006226, 41.7700004578",
  },
  {
    ident: "RJCK",
    type: "medium_airport",
    name: "Kushiro Airport",
    elevation_ft: "327",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Kushiro",
    gps_code: "RJCK",
    iata_code: "KUH",
    coordinates: "144.192993164, 43.041000366199995",
  },
  {
    ident: "RJCM",
    type: "medium_airport",
    name: "Memanbetsu Airport",
    elevation_ft: "135",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "����zora",
    gps_code: "RJCM",
    iata_code: "MMB",
    coordinates: "144.164001465, 43.8805999756",
  },
  {
    ident: "RJCN",
    type: "medium_airport",
    name: "Nakashibetsu Airport",
    elevation_ft: "234",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Nakashibetsu",
    gps_code: "RJCN",
    iata_code: "SHB",
    coordinates: "144.960006714, 43.5774993896",
  },
  {
    ident: "RJCO",
    type: "medium_airport",
    name: "Okadama Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Sapporo",
    gps_code: "RJCO",
    iata_code: "OKD",
    coordinates: "141.38134, 43.117447",
  },
  {
    ident: "RJCR",
    type: "small_airport",
    name: "Rebun Airport",
    elevation_ft: "92",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Rebun",
    gps_code: "RJCR",
    iata_code: "RBJ",
    coordinates: "141.039001465, 45.4550018311",
  },
  {
    ident: "RJCW",
    type: "medium_airport",
    name: "Wakkanai Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Wakkanai",
    gps_code: "RJCW",
    iata_code: "WKJ",
    coordinates: "141.800994873, 45.4042015076",
  },
  {
    ident: "RJDA",
    type: "small_airport",
    name: "Amakusa Airport",
    elevation_ft: "340",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-43",
    gps_code: "RJDA",
    iata_code: "AXJ",
    coordinates: "130.158997, 32.482498",
  },
  {
    ident: "RJDB",
    type: "medium_airport",
    name: "Iki Airport",
    elevation_ft: "41",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-42",
    municipality: "Iki",
    gps_code: "RJDB",
    iata_code: "IKI",
    coordinates: "129.785003662, 33.7490005493",
  },
  {
    ident: "RJDC",
    type: "medium_airport",
    name: "Yamaguchi Ube Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-35",
    municipality: "Ube",
    gps_code: "RJDC",
    iata_code: "UBJ",
    coordinates: "131.279006958, 33.930000305200004",
  },
  {
    ident: "RJDT",
    type: "medium_airport",
    name: "Tsushima Airport",
    elevation_ft: "213",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-42",
    municipality: "Tsushima",
    gps_code: "RJDT",
    iata_code: "TSJ",
    coordinates: "129.330993652, 34.2849006653",
  },
  {
    ident: "RJEB",
    type: "medium_airport",
    name: "Monbetsu Airport",
    elevation_ft: "80",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Monbetsu",
    gps_code: "RJEB",
    iata_code: "MBE",
    coordinates: "143.404006958, 44.303901672399995",
  },
  {
    ident: "RJEC",
    type: "medium_airport",
    name: "Asahikawa Airport",
    elevation_ft: "721",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Asahikawa / Hokkaid����",
    gps_code: "RJEC",
    iata_code: "AKJ",
    coordinates: "142.44700622558594, 43.670799255371094",
  },
  {
    ident: "RJEO",
    type: "medium_airport",
    name: "Okushiri Airport",
    elevation_ft: "161",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Okushiri Island",
    gps_code: "RJEO",
    iata_code: "OIR",
    coordinates: "139.432998657, 42.0717010498",
  },
  {
    ident: "RJER",
    type: "medium_airport",
    name: "Rishiri Airport",
    elevation_ft: "112",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-01",
    municipality: "Rishiri",
    gps_code: "RJER",
    iata_code: "RIS",
    coordinates: "141.186004639, 45.2420005798",
  },
  {
    ident: "RJFC",
    type: "medium_airport",
    name: "Yakushima Airport",
    elevation_ft: "124",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-46",
    municipality: "Yakushima",
    gps_code: "RJFC",
    iata_code: "KUM",
    coordinates: "130.658996582, 30.3855991364",
  },
  {
    ident: "RJFE",
    type: "medium_airport",
    name: "Fukue Airport",
    elevation_ft: "273",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-42",
    municipality: "Goto",
    gps_code: "RJFE",
    iata_code: "FUJ",
    coordinates: "128.83299255371094, 32.66630172729492",
  },
  {
    ident: "RJFF",
    type: "large_airport",
    name: "Fukuoka Airport",
    elevation_ft: "32",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-40",
    municipality: "Fukuoka",
    gps_code: "RJFF",
    iata_code: "FUK",
    coordinates: "130.4510040283203, 33.585899353027344",
  },
  {
    ident: "RJFG",
    type: "medium_airport",
    name: "New Tanegashima Airport",
    elevation_ft: "768",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-46",
    municipality: "Tanegashima",
    gps_code: "RJFG",
    iata_code: "TNE",
    coordinates: "130.990997314, 30.605100631699997",
  },
  {
    ident: "RJFK",
    type: "large_airport",
    name: "Kagoshima Airport",
    elevation_ft: "906",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-46",
    municipality: "Kagoshima",
    gps_code: "RJFK",
    iata_code: "KOJ",
    coordinates: "130.718994140625, 31.80340003967285",
  },
  {
    ident: "RJFM",
    type: "medium_airport",
    name: "Miyazaki Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-45",
    municipality: "Miyazaki",
    gps_code: "RJFM",
    iata_code: "KMI",
    coordinates: "131.449005127, 31.877199173",
  },
  {
    ident: "RJFO",
    type: "medium_airport",
    name: "Oita Airport",
    elevation_ft: "19",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-44",
    municipality: "Oita",
    gps_code: "RJFO",
    iata_code: "OIT",
    coordinates: "131.736999512, 33.479400634799994",
  },
  {
    ident: "RJFR",
    type: "medium_airport",
    name: "Kitakyushu Airport",
    elevation_ft: "21",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-40",
    municipality: "Kitakyushu",
    gps_code: "RJFR",
    iata_code: "KKJ",
    coordinates: "131.035004, 33.845901",
  },
  {
    ident: "RJFS",
    type: "medium_airport",
    name: "Saga Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-41",
    municipality: "Saga",
    gps_code: "RJFS",
    iata_code: "HSG",
    coordinates: "130.302001953, 33.149700164799995",
  },
  {
    ident: "RJFT",
    type: "medium_airport",
    name: "Kumamoto Airport",
    elevation_ft: "642",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-43",
    municipality: "Kumamoto",
    gps_code: "RJFT",
    iata_code: "KMJ",
    coordinates: "130.85499572753906, 32.83729934692383",
  },
  {
    ident: "RJFU",
    type: "medium_airport",
    name: "Nagasaki Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-42",
    municipality: "Nagasaki",
    gps_code: "RJFU",
    iata_code: "NGS",
    coordinates: "129.914001465, 32.916900634799994",
  },
  {
    ident: "RJGG",
    type: "large_airport",
    name: "Chubu Centrair International Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-23",
    municipality: "Tokoname",
    gps_code: "RJGG",
    iata_code: "NGO",
    coordinates: "136.80499267578125, 34.8583984375",
  },
  {
    ident: "RJKA",
    type: "medium_airport",
    name: "Amami Airport",
    elevation_ft: "27",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-46",
    municipality: "Amami",
    gps_code: "RJKA",
    iata_code: "ASJ",
    coordinates: "129.71299743652344, 28.430599212646484",
  },
  {
    ident: "RJKB",
    type: "medium_airport",
    name: "Okierabu Airport",
    elevation_ft: "101",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-46",
    municipality: "Okinoerabujima",
    gps_code: "RJKB",
    iata_code: "OKE",
    coordinates: "128.701004028, 27.4255008698",
  },
  {
    ident: "RJKI",
    type: "medium_airport",
    name: "Kikai Airport",
    elevation_ft: "21",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-46",
    municipality: "Kikai",
    gps_code: "RJKI",
    iata_code: "KKX",
    coordinates: "129.927993774, 28.321300506599997",
  },
  {
    ident: "RJKN",
    type: "medium_airport",
    name: "Tokunoshima Airport",
    elevation_ft: "17",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-46",
    municipality: "Tokunoshima",
    gps_code: "RJKN",
    iata_code: "TKN",
    coordinates: "128.88099670410156, 27.83639907836914",
  },
  {
    ident: "RJNA",
    type: "medium_airport",
    name: "Nagoya Airport",
    elevation_ft: "52",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-23",
    municipality: "Nagoya",
    gps_code: "RJNA",
    iata_code: "NKM",
    coordinates: "136.9239959716797, 35.255001068115234",
  },
  {
    ident: "RJNF",
    type: "medium_airport",
    name: "Fukui Airport",
    elevation_ft: "19",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-18",
    gps_code: "RJNF",
    iata_code: "FKJ",
    coordinates: "136.223999023, 36.1427993774",
  },
  {
    ident: "RJNG",
    type: "medium_airport",
    name: "Gifu Airport",
    elevation_ft: "128",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-21",
    municipality: "Gifu",
    gps_code: "RJNG",
    iata_code: "QGU",
    coordinates: "136.8699951171875, 35.394100189208984",
  },
  {
    ident: "RJNK",
    type: "medium_airport",
    name: "Komatsu Airport",
    elevation_ft: "36",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-17",
    municipality: "Kanazawa",
    gps_code: "RJNK",
    iata_code: "KMQ",
    coordinates: "136.40699768066406, 36.39459991455078",
  },
  {
    ident: "RJNO",
    type: "medium_airport",
    name: "Oki Global Geopark Airport",
    elevation_ft: "311",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-32",
    municipality: "Okinoshima",
    gps_code: "RJNO",
    iata_code: "OKI",
    coordinates: "133.323566, 36.178388",
  },
  {
    ident: "rjns",
    type: "large_airport",
    name: "Mt. Fuji Shizuoka Airport",
    elevation_ft: "433",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-22",
    municipality: "Makinohara / Shimada",
    gps_code: "RJNS",
    iata_code: "FSZ",
    coordinates: "138.18775177, 34.7960434679",
  },
  {
    ident: "RJNT",
    type: "medium_airport",
    name: "Toyama Airport",
    elevation_ft: "95",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-16",
    municipality: "Toyama",
    gps_code: "RJNT",
    iata_code: "TOY",
    coordinates: "137.18800354003906, 36.64830017089844",
  },
  {
    ident: "RJNW",
    type: "medium_airport",
    name: "Noto Airport",
    elevation_ft: "718",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-17",
    municipality: "Wajima",
    gps_code: "RJNW",
    iata_code: "NTQ",
    coordinates: "136.962005615, 37.2930984497",
  },
  {
    ident: "RJOA",
    type: "medium_airport",
    name: "Hiroshima Airport",
    elevation_ft: "1088",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-34",
    municipality: "Hiroshima",
    gps_code: "RJOA",
    iata_code: "HIJ",
    coordinates: "132.919006, 34.4361",
  },
  {
    ident: "RJOB",
    type: "medium_airport",
    name: "Okayama Momotaro Airport",
    elevation_ft: "806",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-33",
    municipality: "Okayama",
    gps_code: "RJOB",
    iata_code: "OKJ",
    coordinates: "133.854996, 34.756901",
  },
  {
    ident: "RJOC",
    type: "medium_airport",
    name: "Izumo En-Musubi Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-32",
    municipality: "Izumo",
    gps_code: "RJOC",
    iata_code: "IZO",
    coordinates: "132.889999, 35.413601",
  },
  {
    ident: "RJOH",
    type: "medium_airport",
    name: "Yonago Kitaro Airport / JASDF Miho Air Base",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-32",
    municipality: "Yonago",
    gps_code: "RJOH",
    iata_code: "YGJ",
    coordinates: "133.235992, 35.492199",
  },
  {
    ident: "RJOI",
    type: "medium_airport",
    name: "Iwakuni Kintaikyo Airport / Marine Corps Air Station Iwakuni",
    elevation_ft: "7",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-35",
    municipality: "Iwakuni",
    gps_code: "RJOI",
    iata_code: "IWK",
    coordinates: "132.247238, 34.146333",
  },
  {
    ident: "RJOK",
    type: "medium_airport",
    name: "Kochi Ryoma Airport",
    elevation_ft: "42",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-39",
    municipality: "Nankoku",
    gps_code: "RJOK",
    iata_code: "KCZ",
    coordinates: "133.669006, 33.546101",
  },
  {
    ident: "RJOM",
    type: "medium_airport",
    name: "Matsuyama Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-38",
    municipality: "Matsuyama",
    gps_code: "RJOM",
    iata_code: "MYJ",
    coordinates: "132.6999969482422, 33.82720184326172",
  },
  {
    ident: "RJOO",
    type: "large_airport",
    name: "Osaka International Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-27",
    municipality: "Osaka",
    gps_code: "RJOO",
    iata_code: "ITM",
    coordinates: "135.43800354003906, 34.785499572753906",
  },
  {
    ident: "RJOR",
    type: "medium_airport",
    name: "Tottori Sand Dunes Conan Airport",
    elevation_ft: "65",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-31",
    municipality: "Tottori",
    gps_code: "RJOR",
    iata_code: "TTJ",
    coordinates: "134.167007, 35.530102",
  },
  {
    ident: "RJOS",
    type: "medium_airport",
    name: "Tokushima Airport/JMSDF Air Base",
    elevation_ft: "26",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-36",
    municipality: "Tokushima",
    gps_code: "RJOS",
    iata_code: "TKS",
    coordinates: "134.606995, 34.132801",
  },
  {
    ident: "RJOT",
    type: "medium_airport",
    name: "Takamatsu Airport",
    elevation_ft: "607",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-37",
    municipality: "Takamatsu",
    gps_code: "RJOT",
    iata_code: "TAK",
    coordinates: "134.01600647, 34.214199066199996",
  },
  {
    ident: "RJOW",
    type: "medium_airport",
    name: "Iwami Airport",
    elevation_ft: "184",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-32",
    municipality: "Masuda",
    gps_code: "RJOW",
    iata_code: "IWJ",
    coordinates: "131.789993286, 34.676399231",
  },
  {
    ident: "RJSA",
    type: "medium_airport",
    name: "Aomori Airport",
    elevation_ft: "664",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-02",
    municipality: "Aomori",
    gps_code: "RJSA",
    iata_code: "AOJ",
    coordinates: "140.6909942626953, 40.73469924926758",
  },
  {
    ident: "RJSC",
    type: "medium_airport",
    name: "Yamagata Airport",
    elevation_ft: "353",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-06",
    municipality: "Yamagata",
    gps_code: "RJSC",
    iata_code: "GAJ",
    coordinates: "140.371002197, 38.411899566699994",
  },
  {
    ident: "RJSD",
    type: "medium_airport",
    name: "Sado Airport",
    elevation_ft: "88",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-15",
    municipality: "Sado",
    gps_code: "RJSD",
    iata_code: "SDS",
    coordinates: "138.414001465, 38.0601997375",
  },
  {
    ident: "RJSF",
    type: "medium_airport",
    name: "Fukushima Airport",
    elevation_ft: "1221",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-07",
    municipality: "Sukagawa",
    gps_code: "RJSF",
    iata_code: "FKS",
    coordinates: "140.43099975585938, 37.22740173339844",
  },
  {
    ident: "RJSH",
    type: "medium_airport",
    name: "Hachinohe Airport",
    elevation_ft: "152",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-02",
    gps_code: "RJSH",
    iata_code: "HHE",
    coordinates: "141.46600341796875, 40.556400299072266",
  },
  {
    ident: "RJSI",
    type: "medium_airport",
    name: "Hanamaki Airport",
    elevation_ft: "297",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-03",
    gps_code: "RJSI",
    iata_code: "HNA",
    coordinates: "141.13499450683594, 39.4286003112793",
  },
  {
    ident: "RJSK",
    type: "medium_airport",
    name: "Akita Airport",
    elevation_ft: "313",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-05",
    municipality: "Akita",
    gps_code: "RJSK",
    iata_code: "AXT",
    coordinates: "140.218994140625, 39.6156005859375",
  },
  {
    ident: "RJSM",
    type: "medium_airport",
    name: "Misawa Air Base",
    elevation_ft: "119",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-02",
    municipality: "Misawa",
    gps_code: "RJSM",
    iata_code: "MSJ",
    coordinates: "141.367996216, 40.703201293899994",
  },
  {
    ident: "RJSN",
    type: "medium_airport",
    name: "Niigata Airport",
    elevation_ft: "29",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-15",
    municipality: "Niigata",
    gps_code: "RJSN",
    iata_code: "KIJ",
    coordinates: "139.121002197, 37.9558982849",
  },
  {
    ident: "RJSR",
    type: "medium_airport",
    name: "Odate Noshiro Airport",
    elevation_ft: "292",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-05",
    municipality: "Odate",
    gps_code: "RJSR",
    iata_code: "ONJ",
    coordinates: "140.371002197, 40.1918983459",
  },
  {
    ident: "RJSS",
    type: "medium_airport",
    name: "Sendai Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-04",
    municipality: "Sendai",
    gps_code: "RJSS",
    iata_code: "SDJ",
    coordinates: "140.917007446, 38.1397018433",
  },
  {
    ident: "RJSY",
    type: "medium_airport",
    name: "Shonai Airport",
    elevation_ft: "86",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-06",
    municipality: "Shonai",
    gps_code: "RJSY",
    iata_code: "SYO",
    coordinates: "139.787002563, 38.81219863889999",
  },
  {
    ident: "RJTA",
    type: "medium_airport",
    name: "JMSDF Atsugi Air Base / Naval Air Facility Atsugi",
    elevation_ft: "205",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-14",
    municipality: "Ayase / Yamato",
    gps_code: "RJTA",
    iata_code: "NJA",
    coordinates: "139.449997, 35.454601",
  },
  {
    ident: "RJTH",
    type: "medium_airport",
    name: "Hachijojima Airport",
    elevation_ft: "303",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-13",
    municipality: "Hachijojima",
    gps_code: "RJTH",
    iata_code: "HAC",
    coordinates: "139.785995483, 33.1150016785",
  },
  {
    ident: "RJTO",
    type: "medium_airport",
    name: "Oshima Airport",
    elevation_ft: "130",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-13",
    municipality: "Izu Oshima",
    gps_code: "RJTO",
    iata_code: "OIM",
    coordinates: "139.36000061, 34.782001495399996",
  },
  {
    ident: "RJTQ",
    type: "medium_airport",
    name: "Miyakejima Airport",
    elevation_ft: "67",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-13",
    municipality: "Miyakejima",
    gps_code: "RJTQ",
    iata_code: "MYE",
    coordinates: "139.559997559, 34.073600769",
  },
  {
    ident: "RJTT",
    type: "large_airport",
    name: "Tokyo Haneda International Airport",
    elevation_ft: "35",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-13",
    municipality: "Ota, Tokyo",
    gps_code: "RJTT",
    iata_code: "HND",
    coordinates: "139.779999, 35.552299",
  },
  {
    ident: "RJTY",
    type: "large_airport",
    name: "Yokota Air Base",
    elevation_ft: "463",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-13",
    municipality: "Fussa",
    gps_code: "RJTY",
    iata_code: "OKO",
    coordinates: "139.34800720214844, 35.74850082397461",
  },
  {
    ident: "RKJB",
    type: "large_airport",
    name: "Muan International Airport",
    elevation_ft: "35",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-46",
    municipality: "Piseo-ri (Muan)",
    gps_code: "RKJB",
    iata_code: "MWX",
    coordinates: "126.382814, 34.991406",
  },
  {
    ident: "RKJJ",
    type: "medium_airport",
    name: "Gwangju Airport",
    elevation_ft: "39",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-29",
    municipality: "Gwangju",
    gps_code: "RKJJ",
    iata_code: "KWJ",
    coordinates: "126.805444, 35.123173",
  },
  {
    ident: "RKJK",
    type: "large_airport",
    name: "Kunsan Air Base",
    elevation_ft: "29",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-45",
    municipality: "Kunsan",
    gps_code: "RKJK",
    iata_code: "KUV",
    coordinates: "126.61599731445312, 35.90380096435547",
  },
  {
    ident: "RKJM",
    type: "closed",
    name: "Mokpo Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-46",
    municipality: "Gonghang-ro (Mokpo)",
    gps_code: "RKJM",
    iata_code: "MPK",
    coordinates: "126.380324, 34.758979",
  },
  {
    ident: "RKJU",
    type: "small_airport",
    name: "Jeon Ju Airport (G-703)",
    elevation_ft: "96",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-45",
    municipality: "Jeon Ju",
    gps_code: "RKJU",
    iata_code: "CHN",
    coordinates: "127.119112, 35.87808",
  },
  {
    ident: "RKJY",
    type: "medium_airport",
    name: "Yeosu Airport",
    elevation_ft: "53",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-46",
    municipality: "Yeosu",
    gps_code: "RKJY",
    iata_code: "RSU",
    coordinates: "127.61699676513672, 34.84230041503906",
  },
  {
    ident: "RKNC",
    type: "closed",
    name: "Camp Page Airport (A-306)",
    elevation_ft: "245",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-42",
    municipality: "Chun Chon City",
    gps_code: "RKNC",
    iata_code: "QUN",
    coordinates: "127.718002, 37.883801",
  },
  {
    ident: "RKND",
    type: "closed",
    name: "Sokcho (Mulchi Airfield) (G-407/K-50) Airport",
    elevation_ft: "92",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-42",
    municipality: "Jangsan-ri, Ganghyeon-myeon",
    gps_code: "RKND",
    iata_code: "SHO",
    coordinates: "128.598318, 38.142117",
  },
  {
    ident: "RKNN",
    type: "medium_airport",
    name: "Gangneung Airport (K-18)",
    elevation_ft: "35",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-42",
    municipality: "Gangneung",
    gps_code: "RKNN",
    iata_code: "KAG",
    coordinates: "128.943915, 37.753601",
  },
  {
    ident: "RKNW",
    type: "medium_airport",
    name: "Wonju/Hoengseong Air Base (K-38/K-46)",
    elevation_ft: "329",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-42",
    municipality: "Wonju",
    gps_code: "RKNW",
    iata_code: "WJU",
    coordinates: "127.963858, 37.441201",
  },
  {
    ident: "RKNY",
    type: "medium_airport",
    name: "Yangyang International Airport",
    elevation_ft: "241",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-42",
    municipality: "Gonghang-ro",
    gps_code: "RKNY",
    iata_code: "YNY",
    coordinates: "128.669006, 38.061298",
  },
  {
    ident: "RKPC",
    type: "large_airport",
    name: "Jeju International Airport",
    elevation_ft: "118",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-49",
    municipality: "Jeju City",
    gps_code: "RKPC",
    iata_code: "CJU",
    coordinates: "126.49299621582031, 33.51129913330078",
  },
  {
    ident: "RKPD",
    type: "medium_airport",
    name: "Jeongseok Airport",
    elevation_ft: "1171",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-49",
    municipality: "Jeju Island",
    gps_code: "RKPD",
    iata_code: "JDG",
    coordinates: "126.711997986, 33.3996009827",
  },
  {
    ident: "RKPE",
    type: "small_airport",
    name: "Jinhae Airbase/Airport (G-813/K-10)",
    elevation_ft: "8",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-48",
    municipality: "Jinhae",
    gps_code: "RKPE",
    iata_code: "CHF",
    coordinates: "128.696229, 35.140248",
  },
  {
    ident: "RKPK",
    type: "large_airport",
    name: "Gimhae International Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-26",
    municipality: "Busan",
    gps_code: "RKPK",
    iata_code: "PUS",
    coordinates: "128.93800354, 35.1795005798",
  },
  {
    ident: "RKPS",
    type: "medium_airport",
    name: "Sacheon Air Base/Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-48",
    municipality: "Sacheon",
    gps_code: "RKPS",
    iata_code: "HIN",
    coordinates: "128.071747, 35.088591",
  },
  {
    ident: "RKPU",
    type: "medium_airport",
    name: "Ulsan Airport",
    elevation_ft: "45",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-31",
    municipality: "Ulsan",
    gps_code: "RKPU",
    iata_code: "USN",
    coordinates: "129.352005005, 35.59349823",
  },
  {
    ident: "RKRO",
    type: "small_airport",
    name: "Pocheon G 217 Airport",
    elevation_ft: "328",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-41",
    municipality: "Pocheon",
    gps_code: "RKRO",
    iata_code: "QJP",
    coordinates: "127.177327, 37.865283",
  },
  {
    ident: "RKSI",
    type: "large_airport",
    name: "Incheon International Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-28",
    municipality: "Seoul",
    gps_code: "RKSI",
    iata_code: "ICN",
    coordinates: "126.45099639892578, 37.46910095214844",
  },
  {
    ident: "RKSM",
    type: "medium_airport",
    name: "Seoul Air Base (K-16)",
    elevation_ft: "92",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-41",
    municipality: "Simgok-dong",
    gps_code: "RKSM",
    iata_code: "SSN",
    coordinates: "127.112718, 37.444744",
  },
  {
    ident: "RKSO",
    type: "large_airport",
    name: "Osan Air Base",
    elevation_ft: "38",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-41",
    gps_code: "RKSO",
    iata_code: "OSN",
    coordinates: "127.029999, 37.090599",
  },
  {
    ident: "RKSS",
    type: "large_airport",
    name: "Gimpo International Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-11",
    municipality: "Seoul",
    gps_code: "RKSS",
    iata_code: "GMP",
    coordinates: "126.791, 37.5583",
  },
  {
    ident: "RKSW",
    type: "medium_airport",
    name: "Suwon Airport",
    elevation_ft: "88",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-41",
    gps_code: "RKSW",
    iata_code: "SWU",
    coordinates: "127.00700378417969, 37.23939895629883",
  },
  {
    ident: "RKTA",
    type: "small_airport",
    name: "Taean Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-44",
    municipality: "Taean",
    gps_code: "RKTA",
    iata_code: "QDY",
    coordinates: "126.295803, 36.59453",
  },
  {
    ident: "RKTH",
    type: "medium_airport",
    name: "Pohang Airport (G-815/K-3)",
    elevation_ft: "70",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-47",
    municipality: "Pohang",
    gps_code: "RKTH",
    iata_code: "KPO",
    coordinates: "129.420383, 35.987955",
  },
  {
    ident: "RKTN",
    type: "medium_airport",
    name: "Daegu Airport",
    elevation_ft: "116",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-27",
    municipality: "Daegu",
    gps_code: "RKTN",
    iata_code: "TAE",
    coordinates: "128.65531, 35.896872",
  },
  {
    ident: "RKTP",
    type: "small_airport",
    name: "Seosan Air Base",
    elevation_ft: "39",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-44",
    municipality: "Seosan",
    gps_code: "RKTP",
    iata_code: "HMY",
    local_code: "RK6U",
    coordinates: "126.486, 36.703999",
  },
  {
    ident: "RKTU",
    type: "large_airport",
    name: "Cheongju International Airport/Cheongju Air Base (K-59/G-513)",
    elevation_ft: "191",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-43",
    municipality: "Cheongju",
    gps_code: "RKTU",
    iata_code: "CJJ",
    coordinates: "127.498741, 36.717008",
  },
  {
    ident: "RKTY",
    type: "medium_airport",
    name: "Yecheon Airbase",
    elevation_ft: "354",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-47",
    municipality: "Yecheon-ri",
    gps_code: "RKTY",
    iata_code: "YEC",
    coordinates: "128.34971, 36.630373",
  },
  {
    ident: "RKU",
    type: "small_airport",
    name: "Kairuku Airport",
    elevation_ft: "45",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Yule Island",
    gps_code: "AYRK",
    iata_code: "RKU",
    local_code: "KKU",
    coordinates: "146.5243833, -8.817000054",
  },
  {
    ident: "RKY",
    type: "small_airport",
    name: "Rokeby Airport",
    elevation_ft: "362",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Rokeby",
    iata_code: "RKY",
    coordinates: "142.641, -13.6434",
  },
  {
    ident: "RLP",
    type: "small_airport",
    name: "Rosella Plains Airport",
    elevation_ft: "1855",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Rosella Plains",
    iata_code: "RLP",
    coordinates: "144.4587, -18.4253",
  },
  {
    ident: "RMP",
    type: "small_airport",
    name: "Rampart Airport",
    elevation_ft: "302",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Rampart",
    iata_code: "RMP",
    local_code: "RMP",
    coordinates: "-150.141007, 65.507896",
  },
  {
    ident: "RMU",
    type: "medium_airport",
    name: "Regi����n de Murcia International Airport",
    elevation_ft: "632",
    continent: "EU",
    iso_country: "ES",
    iso_region: "ES-MU",
    municipality: "Corvera",
    gps_code: "LEMI",
    iata_code: "RMU",
    coordinates: "-1.125, 37.803",
  },
  {
    ident: "RNR",
    type: "closed",
    name: "Robinson River Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Robinson River",
    iata_code: "RNR",
    local_code: "RNR",
    coordinates: "148.823, -10.171",
  },
  {
    ident: "ROAH",
    type: "large_airport",
    name: "Naha Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Naha",
    gps_code: "ROAH",
    iata_code: "OKA",
    coordinates: "127.646003723, 26.1958007812",
  },
  {
    ident: "RODN",
    type: "large_airport",
    name: "Kadena Air Base",
    elevation_ft: "143",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    gps_code: "RODN",
    iata_code: "DNA",
    coordinates: "127.767998, 26.3556",
  },
  {
    ident: "ROKJ",
    type: "medium_airport",
    name: "Kumejima Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    gps_code: "ROKJ",
    iata_code: "UEO",
    coordinates: "126.71399688720703, 26.363500595092773",
  },
  {
    ident: "ROKR",
    type: "small_airport",
    name: "Kerama Airport",
    elevation_ft: "156",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Kerama",
    gps_code: "ROKR",
    iata_code: "KJP",
    coordinates: "127.292999268, 26.168300628699996",
  },
  {
    ident: "ROMD",
    type: "medium_airport",
    name: "Minami-Daito Airport",
    elevation_ft: "167",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Minamidait����",
    gps_code: "ROMD",
    iata_code: "MMD",
    coordinates: "131.263000488, 25.8465003967",
  },
  {
    ident: "ROMY",
    type: "medium_airport",
    name: "Miyako Airport",
    elevation_ft: "150",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Miyako City",
    gps_code: "ROMY",
    iata_code: "MMY",
    coordinates: "125.294998169, 24.782800674399997",
  },
  {
    ident: "RORA",
    type: "small_airport",
    name: "Aguni Airport",
    elevation_ft: "38",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Aguni",
    gps_code: "RORA",
    iata_code: "AGJ",
    coordinates: "127.240997314, 26.5925006866",
  },
  {
    ident: "RORE",
    type: "medium_airport",
    name: "Ie Jima Airport",
    elevation_ft: "246",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Ie",
    gps_code: "RORE",
    iata_code: "IEJ",
    coordinates: "127.785003662, 26.7220001221",
  },
  {
    ident: "RORH",
    type: "small_airport",
    name: "Hateruma Airport",
    elevation_ft: "43",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Hateruma",
    gps_code: "RORH",
    iata_code: "HTR",
    coordinates: "123.805999756, 24.0589008331",
  },
  {
    ident: "RORK",
    type: "medium_airport",
    name: "Kitadaito Airport",
    elevation_ft: "80",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Kitadait����jima",
    gps_code: "RORK",
    iata_code: "KTD",
    coordinates: "131.32699585, 25.9447002411",
  },
  {
    ident: "RORS",
    type: "medium_airport",
    name: "Shimojishima Airport",
    elevation_ft: "54",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Shimojishima",
    gps_code: "RORS",
    iata_code: "SHI",
    coordinates: "125.144996643, 24.8267002106",
  },
  {
    ident: "RORT",
    type: "medium_airport",
    name: "Tarama Airport",
    elevation_ft: "36",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    municipality: "Tarama",
    gps_code: "RORT",
    iata_code: "TRA",
    coordinates: "124.675003052, 24.653900146499996",
  },
  {
    ident: "RORY",
    type: "medium_airport",
    name: "Yoron Airport",
    elevation_ft: "52",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-46",
    municipality: "Yoronjima",
    gps_code: "RORY",
    iata_code: "RNJ",
    coordinates: "128.401992798, 27.0440006256",
  },
  {
    ident: "ROYN",
    type: "medium_airport",
    name: "Yonaguni Airport",
    elevation_ft: "70",
    continent: "AS",
    iso_country: "JP",
    iso_region: "JP-47",
    gps_code: "ROYN",
    iata_code: "OGN",
    coordinates: "122.97799682617188, 24.466899871826172",
  },
  {
    ident: "RPBL",
    type: "small_airport",
    name: "Balesin Island Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-BAS",
    municipality: "Balesin",
    gps_code: "RPBL",
    iata_code: "BSI",
    coordinates: "122.039402, 14.419365",
  },
  {
    ident: "RPLB",
    type: "medium_airport",
    name: "Subic Bay International Airport",
    elevation_ft: "64",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ZMB",
    municipality: "Olongapo City",
    gps_code: "RPLB",
    iata_code: "SFS",
    coordinates: "120.27100372314453, 14.794400215148926",
  },
  {
    ident: "RPLC",
    type: "large_airport",
    name: "Diosdado Macapagal International Airport",
    elevation_ft: "484",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-PAM",
    municipality: "Angeles/Mabalacat",
    gps_code: "RPLC",
    iata_code: "CRK",
    coordinates: "120.559998, 15.186",
  },
  {
    ident: "RPLI",
    type: "medium_airport",
    name: "Laoag International Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ILN",
    municipality: "Laoag City",
    gps_code: "RPLI",
    iata_code: "LAO",
    coordinates: "120.53199768066406, 18.1781005859375",
  },
  {
    ident: "RPLL",
    type: "large_airport",
    name: "Ninoy Aquino International Airport",
    elevation_ft: "75",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-U-A",
    municipality: "Pasay / Para����aque, Metro Manila",
    gps_code: "RPLL",
    iata_code: "MNL",
    coordinates: "121.019997, 14.5086",
  },
  {
    ident: "RPLO",
    type: "small_airport",
    name: "Cuyo Airport",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-PLW",
    municipality: "Cuyo",
    gps_code: "RPLO",
    iata_code: "CYU",
    coordinates: "121.06900024414062, 10.858099937438965",
  },
  {
    ident: "RPLP",
    type: "medium_airport",
    name: "Legazpi City International Airport",
    elevation_ft: "66",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ALB",
    municipality: "Legazpi City",
    gps_code: "RPLP",
    iata_code: "LGP",
    coordinates: "123.735, 13.1575",
  },
  {
    ident: "RPLS",
    type: "small_airport",
    name: "Sangley Point International Airport / Danilo Atienza Air Base",
    elevation_ft: "8",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-CAV",
    municipality: "Cavite",
    gps_code: "RPLS",
    iata_code: "SGL",
    coordinates: "120.903999, 14.4954",
  },
  {
    ident: "RPLU",
    type: "medium_airport",
    name: "Lubang Airport",
    elevation_ft: "43",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-MDC",
    gps_code: "RPLU",
    iata_code: "LBX",
    coordinates: "120.1050033569336, 13.855400085449219",
  },
  {
    ident: "RPMA",
    type: "medium_airport",
    name: "Allah Valley Airport",
    elevation_ft: "659",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-SCO",
    municipality: "Surallah",
    gps_code: "RPMA",
    iata_code: "AAV",
    coordinates: "124.7509994506836, 6.366819858551025",
  },
  {
    ident: "RPMC",
    type: "medium_airport",
    name: "Awang Airport",
    elevation_ft: "189",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-MAG",
    municipality: "Cotabato City",
    gps_code: "RPMC",
    iata_code: "CBO",
    coordinates: "124.20999908447266, 7.1652398109436035",
  },
  {
    ident: "RPMD",
    type: "large_airport",
    name: "Francisco Bangoy International Airport",
    elevation_ft: "96",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-DAV",
    municipality: "Davao City",
    gps_code: "RPMD",
    iata_code: "DVO",
    coordinates: "125.64600372314453, 7.1255202293396",
  },
  {
    ident: "RPME",
    type: "medium_airport",
    name: "Bancasi Airport",
    elevation_ft: "141",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-AGN",
    municipality: "Butuan City",
    gps_code: "RPME",
    iata_code: "BXU",
    coordinates: "125.4788, 8.9515",
  },
  {
    ident: "RPMF",
    type: "medium_airport",
    name: "Bislig Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-SUR",
    gps_code: "RPMF",
    iata_code: "BPH",
    coordinates: "126.3219985961914, 8.19594955444336",
  },
  {
    ident: "RPMG",
    type: "medium_airport",
    name: "Dipolog Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ZAN",
    municipality: "Dipolog City",
    gps_code: "RPMG",
    iata_code: "DPL",
    coordinates: "123.341875076, 8.60198349877",
  },
  {
    ident: "RPMH",
    type: "medium_airport",
    name: "Camiguin Airport",
    elevation_ft: "53",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-CAM",
    municipality: "Mambajao",
    gps_code: "RPMH",
    iata_code: "CGM",
    coordinates: "124.709115, 9.253894",
  },
  {
    ident: "RPMI",
    type: "small_airport",
    name: "Maria Cristina Airport",
    elevation_ft: "1300",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-LAN",
    municipality: "Iligan",
    gps_code: "RPMI",
    iata_code: "IGN",
    coordinates: "124.214996, 8.13049",
  },
  {
    ident: "RPMJ",
    type: "medium_airport",
    name: "Jolo Airport",
    elevation_ft: "118",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-SLU",
    gps_code: "RPMJ",
    iata_code: "JOL",
    coordinates: "121.01100158691406, 6.0536699295043945",
  },
  {
    ident: "RPMM",
    type: "small_airport",
    name: "Malabang Airport",
    elevation_ft: "27",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-LAS",
    municipality: "Malabang",
    gps_code: "RPMM",
    iata_code: "MLP",
    coordinates: "124.05899810791016, 7.617219924926758",
  },
  {
    ident: "RPMN",
    type: "medium_airport",
    name: "Sanga Sanga Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-TAW",
    gps_code: "RPMN",
    iata_code: "TWT",
    coordinates: "119.742996, 5.046991",
  },
  {
    ident: "RPMO",
    type: "medium_airport",
    name: "Labo Airport",
    elevation_ft: "75",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-MSC",
    municipality: "Ozamiz City",
    gps_code: "RPMO",
    iata_code: "OZC",
    coordinates: "123.84200286865234, 8.178509712219238",
  },
  {
    ident: "RPMP",
    type: "medium_airport",
    name: "Pagadian Airport",
    elevation_ft: "5",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ZAS",
    municipality: "Pagadian City",
    gps_code: "RPMP",
    iata_code: "PAG",
    coordinates: "123.461179733, 7.83073144787",
  },
  {
    ident: "RPMQ",
    type: "medium_airport",
    name: "Mati National Airport",
    elevation_ft: "156",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-DAO",
    municipality: "Mati",
    gps_code: "RPMQ",
    iata_code: "MXI",
    coordinates: "126.27368, 6.949091",
  },
  {
    ident: "RPMR",
    type: "medium_airport",
    name: "General Santos International Airport",
    elevation_ft: "505",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-SCO",
    municipality: "General Santos",
    gps_code: "RPMR",
    iata_code: "GES",
    coordinates: "125.096000671, 6.05800008774",
  },
  {
    ident: "RPMS",
    type: "medium_airport",
    name: "Surigao Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-SUN",
    municipality: "Surigao City",
    gps_code: "RPMS",
    iata_code: "SUG",
    coordinates: "125.480947495, 9.755838325629998",
  },
  {
    ident: "RPMU",
    type: "small_airport",
    name: "Cagayan de Sulu Airport",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-TAW",
    municipality: "Mapun",
    gps_code: "RPMU",
    iata_code: "CDY",
    coordinates: "118.49600219726562, 7.013999938964844",
  },
  {
    ident: "RPMV",
    type: "small_airport",
    name: "Ipil Airport",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ZSI",
    municipality: "Ipil",
    gps_code: "RPMV",
    iata_code: "IPE",
    coordinates: "122.60199737548828, 7.785560131072998",
  },
  {
    ident: "RPMW",
    type: "medium_airport",
    name: "Tandag Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-SUR",
    gps_code: "RPMW",
    iata_code: "TDG",
    coordinates: "126.1709976196289, 9.072110176086426",
  },
  {
    ident: "RPMZ",
    type: "medium_airport",
    name: "Zamboanga International Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ZAS",
    municipality: "Zamboanga City",
    gps_code: "RPMZ",
    iata_code: "ZAM",
    coordinates: "122.05999755859375, 6.922420024871826",
  },
  {
    ident: "RPNS",
    type: "small_airport",
    name: "Siargao Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-SUN",
    municipality: "Del Carmen",
    gps_code: "RPNS",
    iata_code: "IAO",
    coordinates: "126.014, 9.8591",
  },
  {
    ident: "RPSD",
    type: "small_airport",
    name: "Cesar Lim Rodriguez Airport",
    elevation_ft: "80",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-PLW",
    municipality: "Taytay Airport, Sandoval Airport",
    gps_code: "RPSD",
    iata_code: "RZP",
    coordinates: "119.507697, 10.81874",
  },
  {
    ident: "RPSV",
    type: "small_airport",
    name: "San Vicente Airport",
    elevation_ft: "24",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-PLW",
    municipality: "San Vicente",
    gps_code: "RPSV",
    iata_code: "SWL",
    coordinates: "119.273889, 10.525",
  },
  {
    ident: "RPUB",
    type: "medium_airport",
    name: "Loakan Airport",
    elevation_ft: "4251",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-BEN",
    municipality: "Baguio City",
    gps_code: "RPUB",
    iata_code: "BAG",
    coordinates: "120.62000274658203, 16.375099182128906",
  },
  {
    ident: "RPUD",
    type: "medium_airport",
    name: "Daet Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-CAN",
    municipality: "Daet",
    gps_code: "RPUD",
    iata_code: "DTE",
    coordinates: "122.9800033569336, 14.129199981689453",
  },
  {
    ident: "RPUH",
    type: "medium_airport",
    name: "San Jose Airport",
    elevation_ft: "14",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-MDC",
    municipality: "San Jose",
    gps_code: "RPUH",
    iata_code: "SJI",
    coordinates: "121.04699707, 12.361499786399998",
  },
  {
    ident: "RPUM",
    type: "medium_airport",
    name: "Mamburao Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-MDC",
    gps_code: "RPUM",
    iata_code: "MBO",
    coordinates: "120.6050033569336, 13.208100318908691",
  },
  {
    ident: "RPUN",
    type: "medium_airport",
    name: "Naga Airport",
    elevation_ft: "142",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-CAS",
    municipality: "Naga",
    gps_code: "RPUN",
    iata_code: "WNP",
    coordinates: "123.2699966430664, 13.58489990234375",
  },
  {
    ident: "RPUO",
    type: "medium_airport",
    name: "Basco Airport",
    elevation_ft: "291",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-BTN",
    municipality: "Basco",
    gps_code: "RPUO",
    iata_code: "BSO",
    coordinates: "121.980003, 20.4513",
  },
  {
    ident: "RPUR",
    type: "medium_airport",
    name: "Dr.Juan C. Angara Airport",
    elevation_ft: "108",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-AUR",
    municipality: "Baler",
    gps_code: "RPUR",
    iata_code: "BQA",
    coordinates: "121.500034, 15.729309",
  },
  {
    ident: "RPUS",
    type: "medium_airport",
    name: "San Fernando Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-LUN",
    gps_code: "RPUS",
    iata_code: "SFE",
    coordinates: "120.3030014038086, 16.595600128173828",
  },
  {
    ident: "RPUT",
    type: "medium_airport",
    name: "Tuguegarao Airport",
    elevation_ft: "70",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-CAG",
    municipality: "Tuguegarao City",
    gps_code: "RPUT",
    iata_code: "TUG",
    coordinates: "121.733150482, 17.6433676823",
  },
  {
    ident: "RPUV",
    type: "medium_airport",
    name: "Virac Airport",
    elevation_ft: "121",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-CAT",
    municipality: "Virac",
    gps_code: "RPUV",
    iata_code: "VRC",
    coordinates: "124.20600128173828, 13.576399803161621",
  },
  {
    ident: "RPUW",
    type: "medium_airport",
    name: "Marinduque Airport",
    elevation_ft: "32",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-MAD",
    municipality: "Gasan",
    gps_code: "RPUW",
    iata_code: "MRQ",
    coordinates: "121.82599639892578, 13.361000061035156",
  },
  {
    ident: "RPUY",
    type: "medium_airport",
    name: "Cauayan Airport",
    elevation_ft: "200",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ISA",
    municipality: "Cauayan City",
    gps_code: "RPUY",
    iata_code: "CYZ",
    coordinates: "121.752998352, 16.9298992157",
  },
  {
    ident: "RPV",
    type: "small_airport",
    name: "Roper Valley Airport",
    elevation_ft: "237",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Roper Valley",
    iata_code: "RPV",
    coordinates: "134.05, -14.9215",
  },
  {
    ident: "RPVA",
    type: "medium_airport",
    name: "Daniel Z. Romualdez Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-LEY",
    municipality: "Tacloban City",
    gps_code: "RPVA",
    iata_code: "TAC",
    local_code: "DZR",
    coordinates: "125.027761, 11.228035",
  },
  {
    ident: "RPVB",
    type: "medium_airport",
    name: "Bacolod-Silay Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-NEC",
    municipality: "Bacolod City",
    gps_code: "RPVB",
    iata_code: "BCD",
    coordinates: "123.014999, 10.7764",
  },
  {
    ident: "RPVC",
    type: "medium_airport",
    name: "Calbayog Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-WSA",
    municipality: "Calbayog City",
    gps_code: "RPVC",
    iata_code: "CYP",
    coordinates: "124.54499816894531, 12.072699546813965",
  },
  {
    ident: "RPVD",
    type: "medium_airport",
    name: "Sibulan Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-NER",
    municipality: "Dumaguete City",
    gps_code: "RPVD",
    iata_code: "DGT",
    coordinates: "123.300003052, 9.3337097168",
  },
  {
    ident: "RPVE",
    type: "medium_airport",
    name: "Godofredo P. Ramos Airport",
    elevation_ft: "7",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-AKL",
    municipality: "Malay",
    gps_code: "RPVE",
    iata_code: "MPH",
    coordinates: "121.954002, 11.9245",
  },
  {
    ident: "RPVF",
    type: "medium_airport",
    name: "Catarman National Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-NSA",
    municipality: "Catarman",
    gps_code: "RPVF",
    iata_code: "CRM",
    coordinates: "124.63600158691406, 12.502400398254395",
  },
  {
    ident: "RPVI",
    type: "medium_airport",
    name: "Iloilo International Airport",
    elevation_ft: "27",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ILI",
    municipality: "Iloilo City",
    gps_code: "RPVI",
    iata_code: "ILO",
    coordinates: "122.493358, 10.833017",
  },
  {
    ident: "RPVJ",
    type: "medium_airport",
    name: "Moises R. Espinosa Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-MAS",
    municipality: "Masbate",
    gps_code: "RPVJ",
    iata_code: "MBT",
    coordinates: "123.630095, 12.369682",
  },
  {
    ident: "RPVK",
    type: "medium_airport",
    name: "Kalibo International Airport",
    elevation_ft: "14",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-AKL",
    municipality: "Kalibo",
    gps_code: "RPVK",
    iata_code: "KLO",
    coordinates: "122.375999451, 11.679400444",
  },
  {
    ident: "RPVM",
    type: "large_airport",
    name: "Mactan Cebu International Airport",
    elevation_ft: "31",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-CEB",
    municipality: "Lapu-Lapu City",
    gps_code: "RPVM",
    iata_code: "CEB",
    coordinates: "123.97899627686, 10.307499885559",
  },
  {
    ident: "RPVO",
    type: "medium_airport",
    name: "Ormoc Airport",
    elevation_ft: "83",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-LEY",
    municipality: "Ormoc City",
    gps_code: "RPVO",
    iata_code: "OMC",
    coordinates: "124.56500244140625, 11.057999610900879",
  },
  {
    ident: "RPVP",
    type: "medium_airport",
    name: "Puerto Princesa Airport",
    elevation_ft: "71",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-PLW",
    municipality: "Puerto Princesa City",
    gps_code: "RPVP",
    iata_code: "PPS",
    coordinates: "118.75900268554688, 9.742119789123535",
  },
  {
    ident: "RPVR",
    type: "medium_airport",
    name: "Roxas Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-CAP",
    municipality: "Roxas City",
    gps_code: "RPVR",
    iata_code: "RXS",
    coordinates: "122.75199890136719, 11.597700119018555",
  },
  {
    ident: "RPVS",
    type: "medium_airport",
    name: "Evelio Javier Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ANT",
    municipality: "San Jose",
    gps_code: "RPVS",
    iata_code: "EUQ",
    coordinates: "121.932998657, 10.765999794",
  },
  {
    ident: "RPVT",
    type: "medium_airport",
    name: "Tagbilaran Airport",
    elevation_ft: "38",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-BOH",
    municipality: "Tagbilaran City",
    gps_code: "RPVT",
    iata_code: "TAG",
    coordinates: "123.853533, 9.665442",
  },
  {
    ident: "RPVU",
    type: "medium_airport",
    name: "Tugdan Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-ROM",
    municipality: "Tablas Island",
    gps_code: "RPVU",
    iata_code: "TBH",
    coordinates: "122.084999084, 12.3109998703",
  },
  {
    ident: "RPVV",
    type: "medium_airport",
    name: "Francisco B. Reyes Airport",
    elevation_ft: "148",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-PLW",
    municipality: "Coron",
    gps_code: "RPVV",
    iata_code: "USU",
    coordinates: "120.099998474, 12.1215000153",
  },
  {
    ident: "RRM",
    type: "small_airport",
    name: "Marromeu Airport",
    elevation_ft: "43",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-S",
    municipality: "Marromeu",
    iata_code: "RRM",
    coordinates: "35.9345, -18.2929",
  },
  {
    ident: "RSE",
    type: "seaplane_base",
    name: "Rose Bay Seaplane Base",
    elevation_ft: "0",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Sydney",
    iata_code: "RSE",
    coordinates: "151.262, -33.869",
  },
  {
    ident: "RU-0025",
    type: "small_airport",
    name: "Nogliki Airport",
    elevation_ft: "109",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SAK",
    municipality: "Nogliki-Sakhalin Island",
    gps_code: "UHSN",
    iata_code: "NGK",
    coordinates: "143.139008, 51.780102",
  },
  {
    ident: "RU-0035",
    type: "large_airport",
    name: "Grozny North Airport",
    elevation_ft: "548",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-CE",
    municipality: "Grozny",
    gps_code: "URMG",
    iata_code: "GRV",
    coordinates: "45.698601, 43.388302",
  },
  {
    ident: "RU-0200",
    type: "small_airport",
    name: "Typliy Klyuch Airport",
    elevation_ft: "925",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Khandyga",
    gps_code: "UEMH",
    iata_code: "KDY",
    coordinates: "136.854995728, 62.7890014648",
  },
  {
    ident: "RU-0493",
    type: "small_airport",
    name: "Dalnerechensk Airport",
    elevation_ft: "272",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Dalnerechensk",
    iata_code: "DLR",
    coordinates: "133.7363, 45.8783",
  },
  {
    ident: "RU-VUS",
    type: "small_airport",
    name: "Velikiy Ustyug Airport",
    elevation_ft: "331",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-VLG",
    municipality: "Velikiy Ustyug",
    gps_code: "ULWU",
    iata_code: "VUS",
    coordinates: "46.2599983215332, 60.788299560546875",
  },
  {
    ident: "RUU",
    type: "small_airport",
    name: "Ruti Airport",
    elevation_ft: "1710",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WHM",
    municipality: "Kawbenaberi",
    iata_code: "RUU",
    local_code: "RUTI",
    coordinates: "144.256666667, -5.334444444440001",
  },
  {
    ident: "RVC",
    type: "small_airport",
    name: "River Cess Airport/Heliport",
    elevation_ft: "27",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-RI",
    municipality: "River Cess",
    iata_code: "RVC",
    coordinates: "-9.585373, 5.471326",
  },
  {
    ident: "RZH",
    type: "closed",
    name: "Quartz Hill Airport",
    elevation_ft: "2465",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Lancaster",
    iata_code: "RZH",
    coordinates: "-118.206, 34.65",
  },
  {
    ident: "S31",
    type: "small_airport",
    name: "Lopez Island Airport",
    elevation_ft: "209",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Lopez",
    gps_code: "S31",
    iata_code: "LPS",
    local_code: "S31",
    coordinates: "-122.93800354003906, 48.4838981628418",
  },
  {
    ident: "S60",
    type: "seaplane_base",
    name: "Kenmore Air Harbor Inc Seaplane Base",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Kenmore",
    gps_code: "S60",
    iata_code: "KEH",
    local_code: "S60",
    coordinates: "-122.259002686, 47.7547988892",
  },
  {
    ident: "SA14",
    type: "small_airport",
    name: "Miramar Airport",
    elevation_ft: "42",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Miramar",
    gps_code: "SAEM",
    iata_code: "MJR",
    local_code: "IRA",
    coordinates: "-57.8697, -38.2271",
  },
  {
    ident: "SA30",
    type: "closed",
    name: "Colonia Catriel Airport",
    elevation_ft: "1026",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "Colonia Catriel",
    gps_code: "SA30",
    iata_code: "CCT",
    local_code: "CLT",
    coordinates: "-67.8349990845, -37.9101982117",
  },
  {
    ident: "SAAC",
    type: "medium_airport",
    name: "Comodoro Pierrestegui Airport",
    elevation_ft: "112",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-E",
    municipality: "Concordia",
    gps_code: "SAAC",
    iata_code: "COC",
    local_code: "DIA",
    coordinates: "-57.9966, -31.2969",
  },
  {
    ident: "SAAG",
    type: "medium_airport",
    name: "Gualeguaychu Airport",
    elevation_ft: "75",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-E",
    municipality: "Gualeguaychu",
    gps_code: "SAAG",
    iata_code: "GHU",
    local_code: "GUA",
    coordinates: "-58.6131, -33.0103",
  },
  {
    ident: "SAAJ",
    type: "small_airport",
    name: "Junin Airport",
    elevation_ft: "262",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Junin",
    gps_code: "SAAJ",
    iata_code: "JNI",
    local_code: "NIN",
    coordinates: "-60.9306, -34.5459",
  },
  {
    ident: "SAAP",
    type: "medium_airport",
    name: "General Urquiza Airport",
    elevation_ft: "242",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-E",
    municipality: "Parana",
    gps_code: "SAAP",
    iata_code: "PRA",
    local_code: "PAR",
    coordinates: "-60.4804, -31.7948",
  },
  {
    ident: "SAAR",
    type: "medium_airport",
    name: "Islas Malvinas Airport",
    elevation_ft: "85",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-S",
    municipality: "Rosario",
    gps_code: "SAAR",
    iata_code: "ROS",
    local_code: "ROS",
    coordinates: "-60.785, -32.9036",
  },
  {
    ident: "SAAV",
    type: "medium_airport",
    name: "Sauce Viejo Airport",
    elevation_ft: "55",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-S",
    municipality: "Santa Fe",
    gps_code: "SAAV",
    iata_code: "SFN",
    local_code: "SVO",
    coordinates: "-60.8117, -31.7117",
  },
  {
    ident: "SABE",
    type: "medium_airport",
    name: "Jorge Newbery Airpark",
    elevation_ft: "18",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-C",
    municipality: "Buenos Aires",
    gps_code: "SABE",
    iata_code: "AEP",
    local_code: "AER",
    coordinates: "-58.4156, -34.5592",
  },
  {
    ident: "SACC",
    type: "small_airport",
    name: "La Cumbre Airport",
    elevation_ft: "3733",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-X",
    municipality: "La Cumbre",
    gps_code: "SACC",
    iata_code: "LCM",
    local_code: "CUM",
    coordinates: "-64.5318984985, -31.005800247199996",
  },
  {
    ident: "SACO",
    type: "medium_airport",
    name: "Ingeniero Ambrosio Taravella Airport",
    elevation_ft: "1604",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-X",
    municipality: "Cordoba",
    gps_code: "SACO",
    iata_code: "COR",
    local_code: "CBA",
    coordinates: "-64.208, -31.323601",
  },
  {
    ident: "SADF",
    type: "medium_airport",
    name: "San Fernando Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "San Fernando",
    gps_code: "SADF",
    iata_code: "FDO",
    local_code: "FDO",
    coordinates: "-58.5896, -34.4532",
  },
  {
    ident: "SADL",
    type: "medium_airport",
    name: "La Plata Airport",
    elevation_ft: "72",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "La Plata",
    gps_code: "SADL",
    iata_code: "LPG",
    local_code: "PTA",
    coordinates: "-57.8947, -34.9722",
  },
  {
    ident: "SADP",
    type: "medium_airport",
    name: "El Palomar Airport",
    elevation_ft: "59",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "El Palomar",
    gps_code: "SADP",
    iata_code: "EPA",
    local_code: "PAL",
    coordinates: "-58.6126, -34.6099",
  },
  {
    ident: "SAEZ",
    type: "large_airport",
    name: "Ministro Pistarini International Airport",
    elevation_ft: "67",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Buenos Aires",
    gps_code: "SAEZ",
    iata_code: "EZE",
    local_code: "EZE",
    coordinates: "-58.5358, -34.8222",
  },
  {
    ident: "SAFR",
    type: "small_airport",
    name: "Rafaela Airport",
    elevation_ft: "325",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-S",
    municipality: "Rafaela",
    gps_code: "SAFR",
    iata_code: "RAF",
    local_code: "RLA",
    coordinates: "-61.5017, -31.2825",
  },
  {
    ident: "SAHC",
    type: "small_airport",
    name: "Chos Malal Airport",
    elevation_ft: "2788",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Q",
    municipality: "Chos Malal",
    gps_code: "SAHC",
    iata_code: "HOS",
    local_code: "CHM",
    coordinates: "-70.2225036621, -37.444698333699996",
  },
  {
    ident: "SAHE",
    type: "small_airport",
    name: "Caviahue Airport",
    elevation_ft: "5435",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Q",
    municipality: "Lafontaine",
    gps_code: "SAHE",
    iata_code: "CVH",
    local_code: "CVH",
    coordinates: "-71.009201, -37.851398",
  },
  {
    ident: "SAHR",
    type: "small_airport",
    name: "Dr. Arturo H. Illia Airport",
    elevation_ft: "852",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "General Roca",
    gps_code: "SAHR",
    iata_code: "GNR",
    local_code: "GNR",
    coordinates: "-67.6204986572, -39.0007019043",
  },
  {
    ident: "SAHS",
    type: "medium_airport",
    name: "Rincon De Los Sauces Airport",
    elevation_ft: "1968",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Q",
    municipality: "Rincon de los Sauces",
    gps_code: "SAHS",
    iata_code: "RDS",
    local_code: "RIN",
    coordinates: "-68.9041976929, -37.3905982971",
  },
  {
    ident: "SAHZ",
    type: "medium_airport",
    name: "Zapala Airport",
    elevation_ft: "3330",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Q",
    municipality: "Zapala",
    gps_code: "SAHZ",
    iata_code: "APZ",
    local_code: "ZAP",
    coordinates: "-70.113602, -38.975498",
  },
  {
    ident: "SAM",
    type: "small_airport",
    name: "Salamo Airport",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Salamo",
    iata_code: "SAM",
    local_code: "SAO",
    coordinates: "150.7903, -9.6701",
  },
  {
    ident: "SAME",
    type: "medium_airport",
    name: "El Plumerillo Airport",
    elevation_ft: "2310",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-M",
    municipality: "Mendoza",
    gps_code: "SAME",
    iata_code: "MDZ",
    local_code: "DOZ",
    coordinates: "-68.7929000854, -32.8316993713",
  },
  {
    ident: "SAMM",
    type: "medium_airport",
    name: "Comodoro D.R. Salom����n Airport",
    elevation_ft: "4685",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-M",
    municipality: "Malargue",
    gps_code: "SAMM",
    iata_code: "LGS",
    local_code: "MLG",
    coordinates: "-69.5743026733, -35.493598938",
  },
  {
    ident: "SAMR",
    type: "medium_airport",
    name: "Suboficial Ay Santiago Germano Airport",
    elevation_ft: "2470",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-M",
    municipality: "San Rafael",
    gps_code: "SAMR",
    iata_code: "AFA",
    local_code: "SRA",
    coordinates: "-68.4039, -34.588299",
  },
  {
    ident: "SANC",
    type: "medium_airport",
    name: "Catamarca Airport",
    elevation_ft: "1522",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-K",
    municipality: "Catamarca",
    gps_code: "SANC",
    iata_code: "CTC",
    local_code: "CAT",
    coordinates: "-65.751701355, -28.5956001282",
  },
  {
    ident: "SANE",
    type: "medium_airport",
    name: "Vicecomodoro Angel D. La Paz Aragon����s Airport",
    elevation_ft: "656",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-G",
    municipality: "Santiago del Estero",
    gps_code: "SANE",
    iata_code: "SDE",
    local_code: "SDE",
    coordinates: "-64.3099975586, -27.765556335399996",
  },
  {
    ident: "SANL",
    type: "medium_airport",
    name: "Capitan V A Almonacid Airport",
    elevation_ft: "1437",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-F",
    municipality: "La Rioja",
    gps_code: "SANL",
    iata_code: "IRJ",
    local_code: "LAR",
    coordinates: "-66.7957992554, -29.3815994263",
  },
  {
    ident: "SANR",
    type: "medium_airport",
    name: "Termas de R����o Hondo international Airport",
    elevation_ft: "935",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-G",
    municipality: "Termas de R����o Hondo",
    gps_code: "SANR",
    iata_code: "RHD",
    local_code: "TRH",
    coordinates: "-64.93595, -27.4966",
  },
  {
    ident: "SANT",
    type: "medium_airport",
    name: "Teniente Benjamin Matienzo Airport",
    elevation_ft: "1493",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-T",
    municipality: "San Miguel de Tucum����n",
    gps_code: "SANT",
    iata_code: "TUC",
    local_code: "TUC",
    coordinates: "-65.104897, -26.8409",
  },
  {
    ident: "SANU",
    type: "medium_airport",
    name: "Domingo Faustino Sarmiento Airport",
    elevation_ft: "1958",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-J",
    municipality: "San Juan",
    gps_code: "SANU",
    iata_code: "UAQ",
    local_code: "JUA",
    coordinates: "-68.418198, -31.571501",
  },
  {
    ident: "SANW",
    type: "small_airport",
    name: "Ceres Airport",
    elevation_ft: "285",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-S",
    municipality: "Ceres",
    gps_code: "SANW",
    iata_code: "CRR",
    local_code: "ERE",
    coordinates: "-61.927925, -29.872292",
  },
  {
    ident: "SAOC",
    type: "medium_airport",
    name: "Area De Material Airport",
    elevation_ft: "1380",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-X",
    municipality: "Rio Cuarto",
    gps_code: "SAOC",
    iata_code: "RCU",
    local_code: "TRC",
    coordinates: "-64.2612991333, -33.0850982666",
  },
  {
    ident: "SAOD",
    type: "medium_airport",
    name: "Villa Dolores Airport",
    elevation_ft: "1847",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-X",
    municipality: "Villa Dolores",
    gps_code: "SAOD",
    iata_code: "VDR",
    local_code: "LDR",
    coordinates: "-65.1463012695, -31.9451999664",
  },
  {
    ident: "SAOR",
    type: "medium_airport",
    name: "Villa Reynolds Airport",
    elevation_ft: "1591",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-D",
    municipality: "Villa Mercedes",
    gps_code: "SAOR",
    iata_code: "VME",
    local_code: "RYD",
    coordinates: "-65.3873977661, -33.7299003601",
  },
  {
    ident: "SAOS",
    type: "small_airport",
    name: "Valle Del Conlara International Airport",
    elevation_ft: "2021",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-D",
    municipality: "Merlo",
    gps_code: "SAOS",
    iata_code: "RLO",
    local_code: "SRC",
    coordinates: "-65.1865005493, -32.3847007751",
  },
  {
    ident: "SAOU",
    type: "medium_airport",
    name: "Brigadier Mayor D Cesar Raul Ojeda Airport",
    elevation_ft: "2328",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-D",
    municipality: "San Luis",
    gps_code: "SAOU",
    iata_code: "LUQ",
    local_code: "UIS",
    coordinates: "-66.3563995361, -33.2732009888",
  },
  {
    ident: "SARC",
    type: "medium_airport",
    name: "Corrientes Airport",
    elevation_ft: "202",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-W",
    municipality: "Corrientes",
    gps_code: "SARC",
    iata_code: "CNQ",
    local_code: "CRR",
    coordinates: "-58.7619, -27.4455",
  },
  {
    ident: "SARE",
    type: "medium_airport",
    name: "Resistencia International Airport",
    elevation_ft: "173",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-H",
    municipality: "Resistencia",
    gps_code: "SARE",
    iata_code: "RES",
    local_code: "SIS",
    coordinates: "-59.0561, -27.45",
  },
  {
    ident: "SARF",
    type: "medium_airport",
    name: "Formosa Airport",
    elevation_ft: "193",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-P",
    municipality: "Formosa",
    gps_code: "SARF",
    iata_code: "FMA",
    local_code: "FSA",
    coordinates: "-58.2281, -26.2127",
  },
  {
    ident: "SARI",
    type: "medium_airport",
    name: "Cataratas Del Iguaz���� International Airport",
    elevation_ft: "916",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-N",
    municipality: "Puerto Iguazu",
    gps_code: "SARI",
    iata_code: "IGR",
    local_code: "IGU",
    coordinates: "-54.4734, -25.737301",
  },
  {
    ident: "SARL",
    type: "medium_airport",
    name: "Paso De Los Libres Airport",
    elevation_ft: "230",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-W",
    municipality: "Paso de los Libres",
    gps_code: "SARL",
    iata_code: "AOL",
    local_code: "LIB",
    coordinates: "-57.1521, -29.6894",
  },
  {
    ident: "SARM",
    type: "medium_airport",
    name: "Monte Caseros Airport",
    elevation_ft: "170",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-W",
    municipality: "Monte Caseros",
    gps_code: "SARM",
    iata_code: "MCS",
    local_code: "MCS",
    coordinates: "-57.6402, -30.2719",
  },
  {
    ident: "SARP",
    type: "medium_airport",
    name: "Libertador Gral D Jose De San Martin Airport",
    elevation_ft: "430",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-N",
    municipality: "Posadas",
    gps_code: "SARP",
    iata_code: "PSS",
    local_code: "POS",
    coordinates: "-55.9707, -27.3858",
  },
  {
    ident: "SAS",
    type: "small_airport",
    name: "Salton Sea Airport",
    elevation_ft: "-84",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Salton City",
    gps_code: "KSAS",
    iata_code: "SAS",
    local_code: "SAS",
    coordinates: "-115.952003479, 33.2414016724",
  },
  {
    ident: "SASA",
    type: "medium_airport",
    name: "Martin Miguel De Guemes International Airport",
    elevation_ft: "4088",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-A",
    municipality: "Salta",
    gps_code: "SASA",
    iata_code: "SLA",
    local_code: "SAL",
    coordinates: "-65.4861984253, -24.856000900299996",
  },
  {
    ident: "SASJ",
    type: "medium_airport",
    name: "Gobernador Horacio Guzman International Airport",
    elevation_ft: "3019",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Y",
    municipality: "San Salvador de Jujuy",
    gps_code: "SASJ",
    iata_code: "JUJ",
    local_code: "JUJ",
    coordinates: "-65.097801, -24.392799",
  },
  {
    ident: "SASO",
    type: "medium_airport",
    name: "Or����n Airport",
    elevation_ft: "1171",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-A",
    municipality: "Or����n",
    gps_code: "SASO",
    iata_code: "ORA",
    local_code: "ORA",
    coordinates: "-64.3292007446, -23.1527996063",
  },
  {
    ident: "SAST",
    type: "medium_airport",
    name: "General Enrique Mosconi Airport",
    elevation_ft: "1472",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-A",
    municipality: "Tartagal",
    gps_code: "SAST",
    iata_code: "TTG",
    local_code: "TAR",
    coordinates: "-63.7937011719, -22.619600296",
  },
  {
    ident: "SATC",
    type: "small_airport",
    name: "Clorinda Airport",
    elevation_ft: "206",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-P",
    municipality: "Clorinda",
    gps_code: "SATC",
    iata_code: "CLX",
    local_code: "CLO",
    coordinates: "-57.7344, -25.3036",
  },
  {
    ident: "SATD",
    type: "small_airport",
    name: "El Dorado Airport",
    elevation_ft: "685",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-N",
    municipality: "El Dorado",
    gps_code: "SATD",
    iata_code: "ELO",
    local_code: "ELD",
    coordinates: "-54.5746994019, -26.3974990845",
  },
  {
    ident: "SATG",
    type: "medium_airport",
    name: "Goya Airport",
    elevation_ft: "128",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-W",
    municipality: "Goya",
    gps_code: "SATG",
    iata_code: "OYA",
    local_code: "GOY",
    coordinates: "-59.2189, -29.1058",
  },
  {
    ident: "SATK",
    type: "small_airport",
    name: "Alferez Armando Rodriguez Airport",
    elevation_ft: "426",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-P",
    municipality: "Las Lomitas",
    gps_code: "SATK",
    iata_code: "LLS",
    local_code: "ITA",
    coordinates: "-60.5488014221, -24.7213993073",
  },
  {
    ident: "SATM",
    type: "small_airport",
    name: "Mercedes Airport",
    elevation_ft: "358",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-W",
    municipality: "Mercedes",
    gps_code: "SATM",
    iata_code: "MDX",
    local_code: "RCE",
    coordinates: "-58.0877990723, -29.2213993073",
  },
  {
    ident: "SATR",
    type: "medium_airport",
    name: "Reconquista Airport",
    elevation_ft: "160",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-S",
    municipality: "Reconquista",
    gps_code: "SATR",
    iata_code: "RCQ",
    local_code: "RTA",
    coordinates: "-59.68, -29.2103",
  },
  {
    ident: "SATU",
    type: "medium_airport",
    name: "Curuzu Cuatia Airport",
    elevation_ft: "229",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-W",
    municipality: "Curuzu Cuatia",
    gps_code: "SATU",
    iata_code: "UZU",
    local_code: "CCA",
    coordinates: "-57.9789, -29.7706",
  },
  {
    ident: "SAVB",
    type: "medium_airport",
    name: "El Bolson Airport",
    elevation_ft: "1141",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "El Bolson",
    gps_code: "SAVB",
    iata_code: "EHL",
    local_code: "BOL",
    coordinates: "-71.5323028564, -41.9431991577",
  },
  {
    ident: "SAVC",
    type: "medium_airport",
    name: "General E. Mosconi Airport",
    elevation_ft: "189",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-U",
    municipality: "Comodoro Rivadavia",
    gps_code: "SAVC",
    iata_code: "CRD",
    local_code: "CRV",
    coordinates: "-67.4655, -45.7853",
  },
  {
    ident: "SAVD",
    type: "small_airport",
    name: "El Maiten Airport",
    elevation_ft: "2355",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-U",
    municipality: "El Maiten",
    gps_code: "SAVD",
    iata_code: "EMX",
    local_code: "EMA",
    coordinates: "-71.17250061040001, -42.0292015076",
  },
  {
    ident: "SAVE",
    type: "medium_airport",
    name: "Brigadier Antonio Parodi Airport",
    elevation_ft: "2621",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-U",
    municipality: "Esquel",
    gps_code: "SAVE",
    iata_code: "EQS",
    local_code: "ESQ",
    coordinates: "-71.139503479, -42.908000946",
  },
  {
    ident: "SAVH",
    type: "medium_airport",
    name: "Las Heras Airport",
    elevation_ft: "1082",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "Las Heras",
    gps_code: "SAVH",
    iata_code: "LHS",
    local_code: "CLH",
    coordinates: "-68.9653015137, -46.53829956049999",
  },
  {
    ident: "SAVJ",
    type: "small_airport",
    name: "Cabo F.A.A. H. R. Bord����n Airport",
    elevation_ft: "2925",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "Ingeniero Jacobacci",
    gps_code: "SAVJ",
    iata_code: "IGB",
    local_code: "IJC",
    coordinates: "-69.5748977661, -41.320899963399995",
  },
  {
    ident: "SAVN",
    type: "small_airport",
    name: "Antoine de Saint Exup����ry Airport",
    elevation_ft: "85",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "San Antonio Oeste",
    gps_code: "SAVN",
    iata_code: "OES",
    local_code: "SAN",
    coordinates: "-65.0343, -40.7512",
  },
  {
    ident: "SAVQ",
    type: "small_airport",
    name: "Maquinchao Airport",
    elevation_ft: "2912",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "Maquinchao",
    gps_code: "SAVQ",
    iata_code: "MQD",
    local_code: "MAQ",
    coordinates: "-68.7078018188, -41.2430992126",
  },
  {
    ident: "SAVR",
    type: "small_airport",
    name: "D. Casimiro Szlapelis Airport",
    elevation_ft: "2286",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-U",
    municipality: "Alto Rio Senguerr",
    gps_code: "SAVR",
    iata_code: "ARR",
    local_code: "ARS",
    coordinates: "-70.812202, -45.013599",
  },
  {
    ident: "SAVS",
    type: "small_airport",
    name: "Sierra Grande Airport",
    elevation_ft: "688",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "Sierra Grande",
    gps_code: "SAVS",
    iata_code: "SGV",
    local_code: "SGR",
    coordinates: "-65.33940124509999, -41.5917015076",
  },
  {
    ident: "SAVT",
    type: "medium_airport",
    name: "Almirante Marco Andres Zar Airport",
    elevation_ft: "141",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-U",
    municipality: "Rawson",
    gps_code: "SAVT",
    iata_code: "REL",
    local_code: "TRE",
    coordinates: "-65.2703, -43.2105",
  },
  {
    ident: "SAVV",
    type: "medium_airport",
    name: "Gobernador Castello Airport",
    elevation_ft: "20",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "Viedma / Carmen de Patagones",
    gps_code: "SAVV",
    iata_code: "VDM",
    local_code: "VIE",
    coordinates: "-63.0004, -40.8692",
  },
  {
    ident: "SAVY",
    type: "medium_airport",
    name: "El Tehuelche Airport",
    elevation_ft: "427",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-U",
    municipality: "Puerto Madryn",
    gps_code: "SAVY",
    iata_code: "PMY",
    local_code: "DRY",
    coordinates: "-65.1027, -42.7592",
  },
  {
    ident: "SAWA",
    type: "closed",
    name: "Lago Argentino Airport",
    elevation_ft: "732",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "El Calafate",
    gps_code: "SAWA",
    iata_code: "ING",
    coordinates: "-72.248596, -50.336102",
  },
  {
    ident: "SAWC",
    type: "medium_airport",
    name: "El Calafate Airport",
    elevation_ft: "669",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "El Calafate",
    gps_code: "SAWC",
    iata_code: "FTE",
    local_code: "ECA",
    coordinates: "-72.053101, -50.2803",
  },
  {
    ident: "SAWD",
    type: "medium_airport",
    name: "Puerto Deseado Airport",
    elevation_ft: "268",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "Puerto Deseado",
    gps_code: "SAWD",
    iata_code: "PUD",
    local_code: "ADO",
    coordinates: "-65.9041, -47.7353",
  },
  {
    ident: "SAWE",
    type: "medium_airport",
    name: "Hermes Quijada International Airport",
    elevation_ft: "65",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-V",
    municipality: "Rio Grande",
    gps_code: "SAWE",
    iata_code: "RGA",
    local_code: "GRA",
    coordinates: "-67.7494, -53.7777",
  },
  {
    ident: "SAWG",
    type: "medium_airport",
    name: "Piloto Civil N. Fern����ndez Airport",
    elevation_ft: "61",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "Rio Gallegos",
    gps_code: "SAWG",
    iata_code: "RGL",
    local_code: "GAL",
    coordinates: "-69.3126, -51.6089",
  },
  {
    ident: "SAWH",
    type: "medium_airport",
    name: "Malvinas Argentinas Airport",
    elevation_ft: "102",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-V",
    municipality: "Ushuahia",
    gps_code: "SAWH",
    iata_code: "USH",
    local_code: "USU",
    coordinates: "-68.2958, -54.8433",
  },
  {
    ident: "SAWJ",
    type: "medium_airport",
    name: "Capitan D Daniel Vazquez Airport",
    elevation_ft: "203",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "San Julian",
    gps_code: "SAWJ",
    iata_code: "ULA",
    local_code: "SJU",
    coordinates: "-67.8026, -49.3068",
  },
  {
    ident: "SAWM",
    type: "small_airport",
    name: "Rio Mayo Airport",
    elevation_ft: "1784",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-U",
    municipality: "Rio Mayo",
    gps_code: "SAWM",
    iata_code: "ROY",
    local_code: "RMY",
    coordinates: "-70.2455978394, -45.703899383499994",
  },
  {
    ident: "SAWP",
    type: "medium_airport",
    name: "Perito Moreno Airport",
    elevation_ft: "1410",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "Perito Moreno",
    gps_code: "SAWP",
    iata_code: "PMQ",
    local_code: "PTM",
    coordinates: "-70.978699, -46.537899",
  },
  {
    ident: "SAWR",
    type: "small_airport",
    name: "Gobernador Gregores Airport",
    elevation_ft: "356",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "Gobernador Gregores",
    gps_code: "SAWR",
    iata_code: "GGS",
    local_code: "GRE",
    coordinates: "-70.150002, -48.7831",
  },
  {
    ident: "SAWS",
    type: "small_airport",
    name: "Jose De San Martin Airport",
    elevation_ft: "2407",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-U",
    municipality: "Chubut",
    gps_code: "SAWS",
    iata_code: "JSM",
    local_code: "JSM",
    coordinates: "-70.4589004517, -44.048599243199995",
  },
  {
    ident: "SAWT",
    type: "small_airport",
    name: "28 de Noviembre Airport",
    elevation_ft: "909",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "Rio Turbio",
    gps_code: "SAWT",
    iata_code: "RYO",
    local_code: "BIO",
    coordinates: "-72.2203, -51.605",
  },
  {
    ident: "SAWU",
    type: "medium_airport",
    name: "Santa Cruz Airport",
    elevation_ft: "364",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Z",
    municipality: "Santa Cruz",
    gps_code: "SAWU",
    iata_code: "RZA",
    local_code: "SCZ",
    coordinates: "-68.5792, -50.0165",
  },
  {
    ident: "SAZB",
    type: "medium_airport",
    name: "Comandante Espora Airport",
    elevation_ft: "246",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Bahia Blanca",
    gps_code: "SAZB",
    iata_code: "BHI",
    local_code: "BCA",
    coordinates: "-62.1693, -38.725",
  },
  {
    ident: "SAZC",
    type: "small_airport",
    name: "Brigadier D.H.E. Ruiz Airport",
    elevation_ft: "767",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Coronel Suarez",
    gps_code: "SAZC",
    iata_code: "CSZ",
    local_code: "SUZ",
    coordinates: "-61.889301, -37.446098",
  },
  {
    ident: "SAZF",
    type: "small_airport",
    name: "Olavarria Airport",
    elevation_ft: "551",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Olavarria",
    gps_code: "SAZF",
    iata_code: "OVR",
    local_code: "OLA",
    coordinates: "-60.216598510699995, -36.8899993896",
  },
  {
    ident: "SAZG",
    type: "medium_airport",
    name: "General Pico Airport",
    elevation_ft: "459",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-L",
    municipality: "General Pico",
    gps_code: "SAZG",
    iata_code: "GPO",
    local_code: "GPI",
    coordinates: "-63.7583007812, -35.6962013245",
  },
  {
    ident: "SAZH",
    type: "medium_airport",
    name: "Tres Arroyos Airport",
    elevation_ft: "400",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Tres Arroyos",
    gps_code: "SAZH",
    iata_code: "OYO",
    local_code: "YOS",
    coordinates: "-60.3297, -38.3869",
  },
  {
    ident: "SAZL",
    type: "medium_airport",
    name: "Santa Teresita Airport",
    elevation_ft: "9",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Santa Teresita",
    gps_code: "SAZL",
    iata_code: "SST",
    local_code: "STR",
    coordinates: "-56.7218, -36.5423",
  },
  {
    ident: "SAZM",
    type: "medium_airport",
    name: "����stor Piazzola International Airport",
    elevation_ft: "72",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Mar del Plata",
    gps_code: "SAZM",
    iata_code: "MDQ",
    local_code: "MDP",
    coordinates: "-57.5733, -37.9342",
  },
  {
    ident: "SAZN",
    type: "medium_airport",
    name: "Presidente Peron Airport",
    elevation_ft: "895",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Q",
    municipality: "Neuquen",
    gps_code: "SAZN",
    iata_code: "NQN",
    local_code: "NEU",
    coordinates: "-68.155701, -38.949001",
  },
  {
    ident: "SAZO",
    type: "medium_airport",
    name: "Necochea Airport",
    elevation_ft: "72",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Necochea",
    gps_code: "SAZO",
    iata_code: "NEC",
    local_code: "NEC",
    coordinates: "-58.8172, -38.4831",
  },
  {
    ident: "SAZP",
    type: "medium_airport",
    name: "Comodoro Pedro Zanni Airport",
    elevation_ft: "278",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Pehuaj����",
    gps_code: "SAZP",
    iata_code: "PEH",
    local_code: "PEH",
    coordinates: "-61.8576, -35.8446",
  },
  {
    ident: "SAZR",
    type: "medium_airport",
    name: "Santa Rosa Airport",
    elevation_ft: "630",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-L",
    municipality: "Santa Rosa",
    gps_code: "SAZR",
    iata_code: "RSA",
    local_code: "OSA",
    coordinates: "-64.275703, -36.588299",
  },
  {
    ident: "SAZS",
    type: "medium_airport",
    name: "San Carlos De Bariloche Airport",
    elevation_ft: "2774",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-R",
    municipality: "San Carlos de Bariloche",
    gps_code: "SAZS",
    iata_code: "BRC",
    local_code: "BAR",
    coordinates: "-71.157501, -41.151199",
  },
  {
    ident: "SAZT",
    type: "medium_airport",
    name: "H����roes De Malvinas Airport",
    elevation_ft: "574",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Tandil",
    gps_code: "SAZT",
    iata_code: "TDL",
    local_code: "DIL",
    coordinates: "-59.2279014587, -37.2374000549",
  },
  {
    ident: "SAZV",
    type: "medium_airport",
    name: "Villa Gesell Airport",
    elevation_ft: "32",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-B",
    municipality: "Villa Gesell",
    gps_code: "SAZV",
    iata_code: "VLG",
    local_code: "GES",
    coordinates: "-57.0292, -37.2354",
  },
  {
    ident: "SAZW",
    type: "medium_airport",
    name: "Cutral-Co Airport",
    elevation_ft: "2132",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Q",
    municipality: "Cutral-Co",
    gps_code: "SAZW",
    iata_code: "CUT",
    local_code: "CUT",
    coordinates: "-69.2646026611, -38.939701080300004",
  },
  {
    ident: "SAZY",
    type: "medium_airport",
    name: "Aviador C. Campos Airport",
    elevation_ft: "2569",
    continent: "SA",
    iso_country: "AR",
    iso_region: "AR-Q",
    municipality: "Chapelco/San Martin de los Andes",
    gps_code: "SAZY",
    iata_code: "CPC",
    local_code: "CHP",
    coordinates: "-71.137299, -40.075401",
  },
  {
    ident: "SB-VIU",
    type: "small_airport",
    name: "Viru Harbour Airstrip",
    elevation_ft: "130",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Viru",
    gps_code: "AGVH",
    iata_code: "VIU",
    coordinates: "157.69, -8.5085",
  },
  {
    ident: "SB29",
    type: "closed",
    name: "Fazenda Bela Vista Airport",
    elevation_ft: "561",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-TO",
    municipality: "3 Marias",
    iata_code: "SB2",
    coordinates: "-48.5349006652832, -6.870940208435059",
  },
  {
    ident: "SBAA",
    type: "medium_airport",
    name: "Concei��������o do Araguaia Airport",
    elevation_ft: "653",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Concei��������o do Araguaia",
    gps_code: "SBAA",
    iata_code: "CDJ",
    local_code: "PA0008",
    coordinates: "-49.301498, -8.34835",
  },
  {
    ident: "SBAC",
    type: "medium_airport",
    name: "Aracati Airport",
    elevation_ft: "128",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-CE",
    municipality: "Aracati",
    gps_code: "SBAC",
    iata_code: "ARX",
    coordinates: "-37.804722, -4.568611",
  },
  {
    ident: "SBAQ",
    type: "medium_airport",
    name: "Araraquara Airport",
    elevation_ft: "2334",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Araraquara",
    gps_code: "SBAQ",
    iata_code: "AQA",
    coordinates: "-48.1329994202, -21.812000274699997",
  },
  {
    ident: "SBAR",
    type: "medium_airport",
    name: "Santa Maria Airport",
    elevation_ft: "23",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SE",
    municipality: "Aracaju",
    gps_code: "SBAR",
    iata_code: "AJU",
    coordinates: "-37.0703010559, -10.984000206",
  },
  {
    ident: "SBAT",
    type: "medium_airport",
    name: "Piloto Osvaldo Marques Dias Airport",
    elevation_ft: "948",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Alta Floresta",
    gps_code: "SBAT",
    iata_code: "AFL",
    coordinates: "-56.1049995422, -9.8663892746",
  },
  {
    ident: "SBAU",
    type: "medium_airport",
    name: "Ara����atuba Airport",
    elevation_ft: "1361",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Ara����atuba",
    gps_code: "SBAU",
    iata_code: "ARU",
    coordinates: "-50.4247016907, -21.1413002014",
  },
  {
    ident: "SBAX",
    type: "small_airport",
    name: "Romeu Zema Airport",
    elevation_ft: "3276",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Arax����",
    gps_code: "SBAX",
    iata_code: "AAX",
    coordinates: "-46.960399627686, -19.563199996948",
  },
  {
    ident: "SBBE",
    type: "large_airport",
    name: "Val de Cans/J����lio Cezar Ribeiro International Airport",
    elevation_ft: "54",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Bel����m",
    gps_code: "SBBE",
    iata_code: "BEL",
    coordinates: "-48.4762992859, -1.3792500495900002",
  },
  {
    ident: "SBBG",
    type: "medium_airport",
    name: "Comandante Gustavo Kraemer Airport",
    elevation_ft: "600",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Bag����",
    gps_code: "SBBG",
    iata_code: "BGX",
    coordinates: "-54.112201690674, -31.39049911499",
  },
  {
    ident: "SBBH",
    type: "medium_airport",
    name: "Pampulha - Carlos Drummond de Andrade Airport",
    elevation_ft: "2589",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Belo Horizonte",
    gps_code: "SBBH",
    iata_code: "PLU",
    coordinates: "-43.950599670410156, -19.851200103759766",
  },
  {
    ident: "SBBI",
    type: "medium_airport",
    name: "Bacacheri Airport",
    elevation_ft: "3057",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Curitiba",
    gps_code: "SBBI",
    iata_code: "BFH",
    coordinates: "-49.23199844359999, -25.4050998688",
  },
  {
    ident: "SBBP",
    type: "small_airport",
    name: "Estadual Arthur Siqueira Airport",
    elevation_ft: "2887",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Bragan����a Paulista",
    gps_code: "SBBP",
    iata_code: "BJP",
    local_code: "SBBP",
    coordinates: "-46.537508, -22.979162",
  },
  {
    ident: "SBBR",
    type: "large_airport",
    name: "Presidente Juscelino Kubitschek International Airport",
    elevation_ft: "3497",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-DF",
    municipality: "Bras����lia",
    gps_code: "SBBR",
    iata_code: "BSB",
    local_code: "DF0001",
    coordinates: "-47.920834, -15.869167",
  },
  {
    ident: "SBBT",
    type: "medium_airport",
    name: "Chafei Amsei Airport",
    elevation_ft: "1898",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Barretos",
    gps_code: "SNBA",
    iata_code: "BAT",
    local_code: "SNBA",
    coordinates: "-48.594100952148, -20.584499359131",
  },
  {
    ident: "SBBU",
    type: "medium_airport",
    name: "Bauru Airport",
    elevation_ft: "2025",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Bauru",
    gps_code: "SBBU",
    iata_code: "BAU",
    coordinates: "-49.0537986755, -22.3449993134",
  },
  {
    ident: "SBBV",
    type: "medium_airport",
    name: "Atlas Brasil Cantanhede Airport",
    elevation_ft: "276",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RR",
    municipality: "Boa Vista",
    gps_code: "SBBV",
    iata_code: "BVB",
    coordinates: "-60.6922225952, 2.84138894081",
  },
  {
    ident: "SBBW",
    type: "medium_airport",
    name: "Barra do Gar����as Airport",
    elevation_ft: "1147",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Barra Do Gar����as",
    gps_code: "SBBW",
    iata_code: "BPG",
    coordinates: "-52.3889007568, -15.861300468400001",
  },
  {
    ident: "SBBZ",
    type: "medium_airport",
    name: "Umberto Modiano Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Cabo Frio",
    gps_code: "SSBZ",
    iata_code: "BZC",
    local_code: "RJ0012",
    coordinates: "-41.96308, -22.770881",
  },
  {
    ident: "SBCA",
    type: "medium_airport",
    name: "Cascavel Airport",
    elevation_ft: "2473",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Cascavel",
    gps_code: "SBCA",
    iata_code: "CAC",
    coordinates: "-53.500801086399996, -25.0002994537",
  },
  {
    ident: "SBCB",
    type: "small_airport",
    name: "Cabo Frio Airport",
    elevation_ft: "23",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Cabo Frio",
    gps_code: "SBCB",
    iata_code: "CFB",
    coordinates: "-42.074298858599995, -22.921699523900003",
  },
  {
    ident: "SBCD",
    type: "small_airport",
    name: "Ca����ador Airport",
    elevation_ft: "3376",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Ca����ador",
    gps_code: "SBCD",
    iata_code: "CFC",
    coordinates: "-50.9398002625, -26.78840065",
  },
  {
    ident: "SBCF",
    type: "large_airport",
    name: "Tancredo Neves International Airport",
    elevation_ft: "2715",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Belo Horizonte",
    gps_code: "SBCF",
    iata_code: "CNF",
    coordinates: "-43.97194290161133, -19.62444305419922",
  },
  {
    ident: "SBCG",
    type: "medium_airport",
    name: "Campo Grande Airport",
    elevation_ft: "1833",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MS",
    municipality: "Campo Grande",
    gps_code: "SBCG",
    iata_code: "CGR",
    coordinates: "-54.6725006104, -20.468700408900002",
  },
  {
    ident: "SBCH",
    type: "medium_airport",
    name: "Serafin Enoss Bertaso Airport",
    elevation_ft: "2146",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Chapec����",
    gps_code: "SBCH",
    iata_code: "XAP",
    coordinates: "-52.656600952148, -27.134199142456",
  },
  {
    ident: "SBCI",
    type: "medium_airport",
    name: "Brig. Lysias Augusto Rodrigues Airport",
    elevation_ft: "565",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Carolina",
    gps_code: "SBCI",
    iata_code: "CLN",
    coordinates: "-47.45869827270508, -7.32043981552124",
  },
  {
    ident: "SBCJ",
    type: "medium_airport",
    name: "Caraj����s Airport",
    elevation_ft: "2064",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Parauapebas",
    gps_code: "SBCJ",
    iata_code: "CKS",
    coordinates: "-50.0013885498, -6.11527776718",
  },
  {
    ident: "SBCM",
    type: "medium_airport",
    name: "Diom����cio Freitas Airport",
    elevation_ft: "93",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Crici����ma",
    gps_code: "SBCM",
    iata_code: "CCM",
    coordinates: "-49.4213905334, -28.7244434357",
  },
  {
    ident: "SBCN",
    type: "small_airport",
    name: "Nelson Ribeiro Guimar����es Airport",
    elevation_ft: "2247",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Caldas Novas",
    gps_code: "SBCN",
    iata_code: "CLV",
    coordinates: "-48.607498168945, -17.725299835205",
  },
  {
    ident: "SBCP",
    type: "medium_airport",
    name: "Bartolomeu Lisandro Airport",
    elevation_ft: "57",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Campos Dos Goytacazes",
    gps_code: "SBCP",
    iata_code: "CAW",
    coordinates: "-41.301700592, -21.698299408",
  },
  {
    ident: "SBCR",
    type: "medium_airport",
    name: "Corumb���� International Airport",
    elevation_ft: "461",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MS",
    municipality: "Corumb����",
    gps_code: "SBCR",
    iata_code: "CMG",
    coordinates: "-57.6713905334, -19.0119438171",
  },
  {
    ident: "SBCT",
    type: "large_airport",
    name: "Afonso Pena Airport",
    elevation_ft: "2988",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Curitiba",
    gps_code: "SBCT",
    iata_code: "CWB",
    coordinates: "-49.1758003235, -25.5284996033",
  },
  {
    ident: "SBCV",
    type: "medium_airport",
    name: "Caravelas Airport",
    elevation_ft: "36",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Caravelas",
    gps_code: "SSCV",
    iata_code: "CRQ",
    local_code: "BA0012",
    coordinates: "-39.253101, -17.6523",
  },
  {
    ident: "SBCX",
    type: "medium_airport",
    name: "Hugo Cantergiani Regional Airport",
    elevation_ft: "2472",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Caxias Do Sul",
    gps_code: "SBCX",
    iata_code: "CXJ",
    coordinates: "-51.1875, -29.197099685699996",
  },
  {
    ident: "SBCY",
    type: "medium_airport",
    name: "Marechal Rondon Airport",
    elevation_ft: "617",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Cuiab����",
    gps_code: "SBCY",
    iata_code: "CGB",
    coordinates: "-56.1166992188, -15.6528997421",
  },
  {
    ident: "SBCZ",
    type: "medium_airport",
    name: "Cruzeiro do Sul Airport",
    elevation_ft: "637",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AC",
    municipality: "Cruzeiro Do Sul",
    gps_code: "SBCZ",
    iata_code: "CZS",
    local_code: "AC0002",
    coordinates: "-72.769501, -7.59991",
  },
  {
    ident: "SBDB",
    type: "small_airport",
    name: "Bonito Airport",
    elevation_ft: "1180",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MS",
    municipality: "Bonito",
    gps_code: "SBDB",
    iata_code: "BYO",
    coordinates: "-56.4525, -21.2473",
  },
  {
    ident: "SBDN",
    type: "medium_airport",
    name: "Presidente Prudente Airport",
    elevation_ft: "1477",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Presidente Prudente",
    gps_code: "SBDN",
    iata_code: "PPB",
    coordinates: "-51.4245986938, -22.1751003265",
  },
  {
    ident: "SBEG",
    type: "large_airport",
    name: "Eduardo Gomes International Airport",
    elevation_ft: "264",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Manaus",
    gps_code: "SBEG",
    iata_code: "MAO",
    coordinates: "-60.049702, -3.03861",
  },
  {
    ident: "SBEK",
    type: "medium_airport",
    name: "Jacareacanga Airport",
    elevation_ft: "323",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Jacareacanga",
    gps_code: "SBEK",
    iata_code: "JCR",
    coordinates: "-57.77690124511719, -6.233160018920898",
  },
  {
    ident: "SBEP",
    type: "small_airport",
    name: "Espinosa Airport",
    elevation_ft: "1900",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Espinosa",
    gps_code: "SBEP",
    iata_code: "ESI",
    coordinates: "-42.81, -14.9336944444",
  },
  {
    ident: "SBES",
    type: "small_airport",
    name: "S����o Pedro da Aldeia Airport",
    elevation_ft: "61",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "S����o Pedro Da Aldeia",
    gps_code: "SBES",
    iata_code: "0",
    coordinates: "-42.09260177612305, -22.81290054321289",
  },
  {
    ident: "SBFI",
    type: "medium_airport",
    name: "Cataratas International Airport",
    elevation_ft: "786",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Foz Do Igua����u",
    gps_code: "SBFI",
    iata_code: "IGU",
    coordinates: "-54.48500061035156, -25.600278854370117",
  },
  {
    ident: "SBFL",
    type: "large_airport",
    name: "Herc����lio Luz International Airport",
    elevation_ft: "16",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Florian����polis",
    gps_code: "SBFL",
    iata_code: "FLN",
    coordinates: "-48.5525016784668, -27.670278549194336",
  },
  {
    ident: "SBFN",
    type: "medium_airport",
    name: "Fernando de Noronha Airport",
    elevation_ft: "193",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PE",
    municipality: "Fernando De Noronha",
    gps_code: "SBFN",
    iata_code: "FEN",
    coordinates: "-32.423302, -3.85493",
  },
  {
    ident: "SBFZ",
    type: "medium_airport",
    name: "Pinto Martins International Airport",
    elevation_ft: "82",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-CE",
    municipality: "Fortaleza",
    gps_code: "SBFZ",
    iata_code: "FOR",
    coordinates: "-38.53260040283203, -3.776279926300049",
  },
  {
    ident: "SBGL",
    type: "large_airport",
    name: "Rio Gale����o ������ Tom Jobim International Airport",
    elevation_ft: "28",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Rio De Janeiro",
    gps_code: "SBGL",
    iata_code: "GIG",
    coordinates: "-43.2505569458, -22.8099994659",
  },
  {
    ident: "SBGM",
    type: "medium_airport",
    name: "Guajar����-Mirim Airport",
    elevation_ft: "478",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RO",
    municipality: "Guajar����-Mirim",
    gps_code: "SBGM",
    iata_code: "GJM",
    coordinates: "-65.28479766845703, -10.786399841308594",
  },
  {
    ident: "SBGO",
    type: "medium_airport",
    name: "Santa Genoveva Airport",
    elevation_ft: "2450",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Goi����nia",
    gps_code: "SBGO",
    iata_code: "GYN",
    coordinates: "-49.220699310302734, -16.631999969482422",
  },
  {
    ident: "SBGP",
    type: "small_airport",
    name: "EMBRAER - Unidade Gavi����o Peixoto Airport",
    elevation_ft: "1998",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Gavi����o Peixoto",
    gps_code: "SBGP",
    iata_code: "0",
    coordinates: "-48.40510177612305, -21.773700714111328",
  },
  {
    ident: "SBGR",
    type: "large_airport",
    name: "Guarulhos - Governador Andr���� Franco Montoro International Airport",
    elevation_ft: "2459",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "S����o Paulo",
    gps_code: "SBGR",
    iata_code: "GRU",
    coordinates: "-46.47305679321289, -23.435556411743164",
  },
  {
    ident: "SBGU",
    type: "small_airport",
    name: "Tancredo Thomas de Faria Airport",
    elevation_ft: "3494",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Guarapuava",
    gps_code: "SSGG",
    iata_code: "GPB",
    local_code: "PR0009",
    coordinates: "-51.520199, -25.387501",
  },
  {
    ident: "SBGV",
    type: "small_airport",
    name: "Coronel Altino Machado de Oliveira Airport",
    elevation_ft: "561",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Governador Valadares",
    gps_code: "SBGV",
    iata_code: "GVR",
    coordinates: "-41.982200622559, -18.89520072937",
  },
  {
    ident: "SBGW",
    type: "medium_airport",
    name: "Guaratinguet���� Airport",
    elevation_ft: "1761",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Guaratinguet����",
    gps_code: "SBGW",
    iata_code: "GUJ",
    coordinates: "-45.20479965209961, -22.79159927368164",
  },
  {
    ident: "SBHT",
    type: "medium_airport",
    name: "Altamira Airport",
    elevation_ft: "369",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Altamira",
    gps_code: "SBHT",
    iata_code: "ATM",
    coordinates: "-52.254001617432, -3.2539100646973",
  },
  {
    ident: "SBIC",
    type: "medium_airport",
    name: "Itacoatiara Airport",
    elevation_ft: "142",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Itacoatiara",
    gps_code: "SBIC",
    iata_code: "ITA",
    coordinates: "-58.481201171875, -3.1272599697113037",
  },
  {
    ident: "SBIH",
    type: "medium_airport",
    name: "Itaituba Airport",
    elevation_ft: "110",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Itaituba",
    gps_code: "SBIH",
    iata_code: "ITB",
    coordinates: "-56.000701904297, -4.2423400878906",
  },
  {
    ident: "SBIL",
    type: "medium_airport",
    name: "Bahia - Jorge Amado Airport",
    elevation_ft: "15",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Ilh����us",
    gps_code: "SBIL",
    iata_code: "IOS",
    coordinates: "-39.033199310303, -14.815999984741",
  },
  {
    ident: "SBIP",
    type: "medium_airport",
    name: "Usiminas Airport",
    elevation_ft: "784",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Ipatinga",
    gps_code: "SBIP",
    iata_code: "IPN",
    coordinates: "-42.487598419189, -19.470699310303",
  },
  {
    ident: "SBIT",
    type: "small_airport",
    name: "Francisco Vilela do Amaral Airport",
    elevation_ft: "1630",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Itumbiara",
    gps_code: "SBIT",
    iata_code: "ITR",
    coordinates: "-49.2134017944, -18.4447002411",
  },
  {
    ident: "SBIZ",
    type: "medium_airport",
    name: "Prefeito Renato Moreira Airport",
    elevation_ft: "432",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Imperatriz",
    gps_code: "SBIZ",
    iata_code: "IMP",
    coordinates: "-47.459999, -5.53129",
  },
  {
    ident: "SBJA",
    type: "small_airport",
    name: "Humberto Ghizzo Bortoluzzi Regional Airport",
    elevation_ft: "120",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Jaguaruna",
    gps_code: "SBJA",
    iata_code: "JJG",
    coordinates: "-49.0596, -28.6753",
  },
  {
    ident: "SBJF",
    type: "medium_airport",
    name: "Francisco de Assis Airport",
    elevation_ft: "2989",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Juiz De Fora",
    gps_code: "SBJF",
    iata_code: "JDF",
    coordinates: "-43.38679885864258, -21.791500091552734",
  },
  {
    ident: "SBJP",
    type: "medium_airport",
    name: "Presidente Castro Pinto International Airport",
    elevation_ft: "217",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PB",
    municipality: "Jo����o Pessoa",
    gps_code: "SBJP",
    iata_code: "JPA",
    coordinates: "-34.9486122131, -7.145833015440001",
  },
  {
    ident: "SBJU",
    type: "small_airport",
    name: "Orlando Bezerra de Menezes Airport",
    elevation_ft: "1392",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-CE",
    municipality: "Juazeiro Do Norte",
    gps_code: "SBJU",
    iata_code: "JDO",
    coordinates: "-39.270099639899996, -7.21895980835",
  },
  {
    ident: "SBJV",
    type: "medium_airport",
    name: "Lauro Carneiro de Loyola Airport",
    elevation_ft: "15",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Joinville",
    gps_code: "SBJV",
    iata_code: "JOI",
    coordinates: "-48.797401428222656, -26.22450065612793",
  },
  {
    ident: "SBKG",
    type: "medium_airport",
    name: "Presidente Jo����o Suassuna Airport",
    elevation_ft: "1646",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PB",
    municipality: "Campina Grande",
    gps_code: "SBKG",
    iata_code: "CPV",
    coordinates: "-35.8964, -7.26992",
  },
  {
    ident: "SBKP",
    type: "medium_airport",
    name: "Viracopos International Airport",
    elevation_ft: "2170",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Campinas",
    gps_code: "SBKP",
    iata_code: "VCP",
    coordinates: "-47.1344985962, -23.0074005127",
  },
  {
    ident: "SBLE",
    type: "small_airport",
    name: "Coronel Hor����cio de Mattos Airport",
    elevation_ft: "1676",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Len��������is",
    gps_code: "SBLE",
    iata_code: "LEC",
    coordinates: "-41.2770004272, -12.4822998047",
  },
  {
    ident: "SBLJ",
    type: "medium_airport",
    name: "Lages Airport",
    elevation_ft: "3065",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Lages",
    gps_code: "SBLJ",
    iata_code: "LAJ",
    coordinates: "-50.28150177, -27.782100677499997",
  },
  {
    ident: "SBLN",
    type: "medium_airport",
    name: "Lins Airport",
    elevation_ft: "1559",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Lins",
    gps_code: "SWXQ",
    iata_code: "LIP",
    local_code: "SWXQ",
    coordinates: "-49.730499267578, -21.663999557495",
  },
  {
    ident: "SBLO",
    type: "medium_airport",
    name: "Governador Jos���� Richa Airport",
    elevation_ft: "1867",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Londrina",
    gps_code: "SBLO",
    iata_code: "LDB",
    coordinates: "-51.1301002502, -23.333599090599996",
  },
  {
    ident: "SBLP",
    type: "medium_airport",
    name: "Bom Jesus da Lapa Airport",
    elevation_ft: "1454",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Bom Jesus Da Lapa",
    gps_code: "SBLP",
    iata_code: "LAZ",
    coordinates: "-43.4081001282, -13.2621002197",
  },
  {
    ident: "SBLS",
    type: "small_airport",
    name: "Lagoa Santa Airport",
    elevation_ft: "2795",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Lagoa Santa",
    gps_code: "SBLS",
    iata_code: "0",
    coordinates: "-43.896400451660156, -19.66160011291504",
  },
  {
    ident: "SBMA",
    type: "medium_airport",
    name: "Jo����o Correa da Rocha Airport",
    elevation_ft: "357",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Marab����",
    gps_code: "SBMA",
    iata_code: "MAB",
    coordinates: "-49.138000488299994, -5.36858987808",
  },
  {
    ident: "SBMC",
    type: "medium_airport",
    name: "Mina����u Airport",
    elevation_ft: "1401",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Mina����u",
    gps_code: "SWIQ",
    iata_code: "MQH",
    local_code: "SWIQ",
    coordinates: "-48.195301, -13.5491",
  },
  {
    ident: "SBMD",
    type: "medium_airport",
    name: "Monte Dourado Airport",
    elevation_ft: "677",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Almeirim",
    gps_code: "SBMD",
    iata_code: "MEU",
    coordinates: "-52.6022, -0.889839",
  },
  {
    ident: "SBME",
    type: "medium_airport",
    name: "Maca���� Airport",
    elevation_ft: "8",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Maca����",
    gps_code: "SBME",
    iata_code: "MEA",
    coordinates: "-41.7659988403, -22.343000412",
  },
  {
    ident: "SBMG",
    type: "medium_airport",
    name: "Regional de Maring���� - S����lvio Name J����nior Airport",
    elevation_ft: "1788",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Maring����",
    gps_code: "SBMG",
    iata_code: "MGF",
    local_code: "PR0004",
    coordinates: "-52.016187, -23.47606",
  },
  {
    ident: "SBMK",
    type: "medium_airport",
    name: "M����rio Ribeiro Airport",
    elevation_ft: "2191",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Montes Claros",
    gps_code: "SBMK",
    iata_code: "MOC",
    coordinates: "-43.818901062, -16.706899642899998",
  },
  {
    ident: "SBML",
    type: "medium_airport",
    name: "Frank Miloye Milenkowichi������Mar����lia State Airport",
    elevation_ft: "2122",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Mar����lia",
    gps_code: "SBML",
    iata_code: "MII",
    coordinates: "-49.926399231, -22.1968994141",
  },
  {
    ident: "SBMN",
    type: "medium_airport",
    name: "Ponta Pelada Airport",
    elevation_ft: "267",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Manaus",
    gps_code: "SBMN",
    iata_code: "PLL",
    coordinates: "-59.98630142211914, -3.1460399627685547",
  },
  {
    ident: "SBMO",
    type: "medium_airport",
    name: "Zumbi dos Palmares Airport",
    elevation_ft: "387",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AL",
    municipality: "Macei����",
    gps_code: "SBMO",
    iata_code: "MCZ",
    coordinates: "-35.79169845581055, -9.510809898376465",
  },
  {
    ident: "SBMQ",
    type: "medium_airport",
    name: "Alberto Alcolumbre Airport",
    elevation_ft: "56",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AP",
    municipality: "Macap����",
    gps_code: "SBMQ",
    iata_code: "MCP",
    coordinates: "-51.0722007751, 0.0506640002131",
  },
  {
    ident: "SBMS",
    type: "medium_airport",
    name: "Dix-Sept Rosado Airport",
    elevation_ft: "76",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RN",
    municipality: "Mossor����",
    gps_code: "SBMS",
    iata_code: "MVF",
    coordinates: "-37.3642997742, -5.2019200324999995",
  },
  {
    ident: "SBMY",
    type: "medium_airport",
    name: "Manicor���� Airport",
    elevation_ft: "174",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Manicor����",
    gps_code: "SBMY",
    iata_code: "MNX",
    coordinates: "-61.278301239014, -5.8113799095154",
  },
  {
    ident: "SBNF",
    type: "medium_airport",
    name: "Ministro Victor Konder International Airport",
    elevation_ft: "18",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Navegantes",
    gps_code: "SBNF",
    iata_code: "NVT",
    coordinates: "-48.651402, -26.879999",
  },
  {
    ident: "SBNM",
    type: "medium_airport",
    name: "Santo ����ngelo Airport",
    elevation_ft: "1056",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Santo ����ngelo",
    gps_code: "SBNM",
    iata_code: "GEL",
    coordinates: "-54.169102, -28.2817",
  },
  {
    ident: "SBOI",
    type: "medium_airport",
    name: "Oiapoque Airport",
    elevation_ft: "63",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AP",
    municipality: "Oiapoque",
    gps_code: "SBOI",
    iata_code: "OYK",
    coordinates: "-51.79690170288086, 3.85548996925354",
  },
  {
    ident: "SBPA",
    type: "medium_airport",
    name: "Salgado Filho Airport",
    elevation_ft: "11",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Porto Alegre",
    gps_code: "SBPA",
    iata_code: "POA",
    coordinates: "-51.1713981628418, -29.994400024414062",
  },
  {
    ident: "SBPB",
    type: "small_airport",
    name: "Prefeito Doutor Jo����o Silva Filho Airport",
    elevation_ft: "16",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PI",
    municipality: "Parna����ba",
    gps_code: "SBPB",
    iata_code: "PHB",
    coordinates: "-41.73199844359999, -2.89374995232",
  },
  {
    ident: "SBPC",
    type: "medium_airport",
    name: "Po����os de Caldas - Embaixador Walther Moreira Salles Airport",
    elevation_ft: "4135",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Po����os De Caldas",
    gps_code: "SBPC",
    iata_code: "POO",
    coordinates: "-46.567901611328, -21.843000411987",
  },
  {
    ident: "SBPF",
    type: "medium_airport",
    name: "Lauro Kurtz Airport",
    elevation_ft: "2376",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Passo Fundo",
    gps_code: "SBPF",
    iata_code: "PFB",
    coordinates: "-52.326599, -28.243999",
  },
  {
    ident: "SBPJ",
    type: "medium_airport",
    name: "Brigadeiro Lysias Rodrigues Airport",
    elevation_ft: "774",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-TO",
    municipality: "Palmas",
    gps_code: "SBPJ",
    iata_code: "PMW",
    coordinates: "-48.35699844359999, -10.291500091600001",
  },
  {
    ident: "SBPK",
    type: "medium_airport",
    name: "Jo����o Sim����es Lopes Neto International Airport",
    elevation_ft: "59",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Pelotas",
    gps_code: "SBPK",
    iata_code: "PET",
    coordinates: "-52.327702, -31.718399",
  },
  {
    ident: "SBPL",
    type: "medium_airport",
    name: "Senador Nilo Coelho Airport",
    elevation_ft: "1263",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PE",
    municipality: "Petrolina",
    gps_code: "SBPL",
    iata_code: "PNZ",
    coordinates: "-40.56909942626953, -9.362409591674805",
  },
  {
    ident: "SBPN",
    type: "medium_airport",
    name: "Porto Nacional Airport",
    elevation_ft: "870",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-TO",
    municipality: "Porto Nacional",
    gps_code: "SDPE",
    iata_code: "PNB",
    local_code: "TO0003",
    coordinates: "-48.3997, -10.719402",
  },
  {
    ident: "SBPP",
    type: "medium_airport",
    name: "Ponta Por���� Airport",
    elevation_ft: "2156",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MS",
    municipality: "Ponta Por����",
    gps_code: "SBPP",
    iata_code: "PMG",
    coordinates: "-55.702598571777344, -22.54960060119629",
  },
  {
    ident: "SBPS",
    type: "medium_airport",
    name: "Porto Seguro Airport",
    elevation_ft: "168",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Porto Seguro",
    gps_code: "SBPS",
    iata_code: "BPS",
    coordinates: "-39.080898, -16.438601",
  },
  {
    ident: "SBPV",
    type: "medium_airport",
    name: "Governador Jorge Teixeira de Oliveira Airport",
    elevation_ft: "290",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RO",
    municipality: "Porto Velho",
    gps_code: "SBPV",
    iata_code: "PVH",
    coordinates: "-63.90230178833008, -8.70928955078125",
  },
  {
    ident: "SBRB",
    type: "medium_airport",
    name: "Rio Branco-Pl����cido de Castro International Airport",
    elevation_ft: "633",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AC",
    municipality: "Rio Branco",
    gps_code: "SBRB",
    iata_code: "RBR",
    local_code: "AC0001",
    coordinates: "-67.893984, -9.869031",
  },
  {
    ident: "SBRF",
    type: "medium_airport",
    name: "Guararapes - Gilberto Freyre International Airport",
    elevation_ft: "33",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PE",
    municipality: "Recife",
    gps_code: "SBRF",
    iata_code: "REC",
    coordinates: "-34.92359924316406, -8.126489639282227",
  },
  {
    ident: "SBRJ",
    type: "medium_airport",
    name: "Santos Dumont Airport",
    elevation_ft: "11",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Rio De Janeiro",
    gps_code: "SBRJ",
    iata_code: "SDU",
    coordinates: "-43.1631011963, -22.910499572799996",
  },
  {
    ident: "SBRP",
    type: "medium_airport",
    name: "Leite Lopes Airport",
    elevation_ft: "1806",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Ribeir����o Preto",
    gps_code: "SBRP",
    iata_code: "RAO",
    coordinates: "-47.776668548583984, -21.136388778686523",
  },
  {
    ident: "SBRR",
    type: "small_airport",
    name: "Barreirinhas Airport",
    elevation_ft: "40",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    gps_code: "SSRS",
    iata_code: "BRB",
    coordinates: "-42.805666, -2.756628",
  },
  {
    ident: "SBSC",
    type: "medium_airport",
    name: "Santa Cruz Air Force Base",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Rio de Janeiro",
    gps_code: "SBSC",
    iata_code: "SNZ",
    coordinates: "-43.719101, -22.9324",
  },
  {
    ident: "SBSG",
    type: "large_airport",
    name: "Governador Alu����zio Alves International Airport",
    elevation_ft: "272",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RN",
    municipality: "Natal",
    gps_code: "SBSG",
    iata_code: "NAT",
    coordinates: "-35.376111, -5.768056",
  },
  {
    ident: "SBSJ",
    type: "medium_airport",
    name: "Professor Urbano Ernesto Stumpf Airport",
    elevation_ft: "2120",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "S����o Jos���� Dos Campos",
    gps_code: "SBSJ",
    iata_code: "SJK",
    coordinates: "-45.86149978637695, -23.22920036315918",
  },
  {
    ident: "SBSL",
    type: "medium_airport",
    name: "Marechal Cunha Machado International Airport",
    elevation_ft: "178",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "S����o Lu����s",
    gps_code: "SBSL",
    iata_code: "SLZ",
    coordinates: "-44.234100341796875, -2.585360050201416",
  },
  {
    ident: "SBSM",
    type: "medium_airport",
    name: "Santa Maria Airport",
    elevation_ft: "287",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Santa Maria",
    gps_code: "SBSM",
    iata_code: "RIA",
    coordinates: "-53.688202, -29.711399",
  },
  {
    ident: "SBSN",
    type: "medium_airport",
    name: "Maestro Wilson Fonseca Airport",
    elevation_ft: "198",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Santar����m",
    gps_code: "SBSN",
    iata_code: "STM",
    coordinates: "-54.785831451416016, -2.4247219562530518",
  },
  {
    ident: "SBSO",
    type: "small_airport",
    name: "Adolino Bedin Regional Airport",
    elevation_ft: "1266",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Sorriso",
    gps_code: "SBSO",
    iata_code: "SMT",
    coordinates: "-55.672341, -12.479177",
  },
  {
    ident: "SBSP",
    type: "large_airport",
    name: "Congonhas Airport",
    elevation_ft: "2631",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "S����o Paulo",
    gps_code: "SBSP",
    iata_code: "CGH",
    coordinates: "-46.65638732910156, -23.626110076904297",
  },
  {
    ident: "SBSR",
    type: "medium_airport",
    name: "Prof. Eribelto Manoel Reino State Airport",
    elevation_ft: "1784",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "S����o Jos���� Do Rio Preto",
    gps_code: "SBSR",
    iata_code: "SJP",
    coordinates: "-49.40650177, -20.816600799599996",
  },
  {
    ident: "SBST",
    type: "medium_airport",
    name: "Base A����rea de Santos Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Guaruj����",
    gps_code: "SBST",
    iata_code: "SSZ",
    coordinates: "-46.299720764160156, -23.928056716918945",
  },
  {
    ident: "SBSV",
    type: "large_airport",
    name: "Deputado Luiz Eduardo Magalh����es International Airport",
    elevation_ft: "64",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Salvador",
    gps_code: "SBSV",
    iata_code: "SSA",
    coordinates: "-38.3224983215, -12.9086112976",
  },
  {
    ident: "SBTA",
    type: "medium_airport",
    name: "Base de Avia��������o de Taubat���� Airport",
    elevation_ft: "1908",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Taubat����",
    gps_code: "SBTA",
    iata_code: "QHP",
    coordinates: "-45.51599884033203, -23.04010009765625",
  },
  {
    ident: "SBTB",
    type: "medium_airport",
    name: "Trombetas Airport",
    elevation_ft: "287",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Oriximin����",
    gps_code: "SBTB",
    iata_code: "TMT",
    coordinates: "-56.396800994873, -1.489599943161",
  },
  {
    ident: "SBTC",
    type: "small_airport",
    name: "Hotel Transam����rica Airport",
    elevation_ft: "20",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Una",
    gps_code: "SBTC",
    iata_code: "UNA",
    coordinates: "-38.9990005493, -15.355199813799999",
  },
  {
    ident: "SBTD",
    type: "small_airport",
    name: "Toledo Airport",
    elevation_ft: "1843",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Toledo",
    gps_code: "SBTD",
    iata_code: "TOW",
    coordinates: "-53.697498, -24.6863",
  },
  {
    ident: "SBTE",
    type: "medium_airport",
    name: "Senador Petr����nio Portela Airport",
    elevation_ft: "219",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PI",
    municipality: "Teresina",
    gps_code: "SBTE",
    iata_code: "THE",
    coordinates: "-42.8235015869, -5.0599398613",
  },
  {
    ident: "SBTF",
    type: "medium_airport",
    name: "Tef���� Airport",
    elevation_ft: "188",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Tef����",
    gps_code: "SBTF",
    iata_code: "TFF",
    coordinates: "-64.7240982056, -3.38294005394",
  },
  {
    ident: "SBTK",
    type: "medium_airport",
    name: "Tarauac���� Airport",
    elevation_ft: "646",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AC",
    municipality: "Tarauac����",
    gps_code: "SBTK",
    iata_code: "TRQ",
    coordinates: "-70.783302307129, -8.1552600860596",
  },
  {
    ident: "SBTL",
    type: "medium_airport",
    name: "Tel����maco Borba Airport",
    elevation_ft: "2610",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Tel����maco Borba",
    gps_code: "SSVL",
    iata_code: "TEC",
    local_code: "PR0007",
    coordinates: "-50.6516, -24.317801",
  },
  {
    ident: "SBTR",
    type: "small_airport",
    name: "Torres Airport",
    elevation_ft: "26",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Torres",
    gps_code: "SBTR",
    iata_code: "TSQ",
    coordinates: "-49.810001373291, -29.41489982605",
  },
  {
    ident: "SBTT",
    type: "medium_airport",
    name: "Tabatinga Airport",
    elevation_ft: "279",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Tabatinga",
    gps_code: "SBTT",
    iata_code: "TBT",
    coordinates: "-69.93579864502, -4.2556700706482",
  },
  {
    ident: "SBTU",
    type: "medium_airport",
    name: "Tucuru���� Airport",
    elevation_ft: "830",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Tucuru����",
    gps_code: "SBTU",
    iata_code: "TUR",
    coordinates: "-49.72029876709, -3.7860100269318",
  },
  {
    ident: "SBUA",
    type: "medium_airport",
    name: "S����o Gabriel da Cachoeira Airport",
    elevation_ft: "251",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "S����o Gabriel Da Cachoeira",
    gps_code: "SBUA",
    iata_code: "SJL",
    coordinates: "-66.9855, -0.14835",
  },
  {
    ident: "SBUF",
    type: "medium_airport",
    name: "Paulo Afonso Airport",
    elevation_ft: "883",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Paulo Afonso",
    gps_code: "SBUF",
    iata_code: "PAV",
    coordinates: "-38.250598907471, -9.4008798599243",
  },
  {
    ident: "SBUG",
    type: "medium_airport",
    name: "Rubem Berta Airport",
    elevation_ft: "256",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Uruguaiana",
    gps_code: "SBUG",
    iata_code: "URG",
    coordinates: "-57.0382003784, -29.7821998596",
  },
  {
    ident: "SBUL",
    type: "medium_airport",
    name: "Ten. Cel. Aviador C����sar Bombonato Airport",
    elevation_ft: "3094",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Uberl����ndia",
    gps_code: "SBUL",
    iata_code: "UDI",
    coordinates: "-48.225277, -18.883612",
  },
  {
    ident: "SBUR",
    type: "medium_airport",
    name: "M����rio de Almeida Franco Airport",
    elevation_ft: "2655",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Uberaba",
    gps_code: "SBUR",
    iata_code: "UBA",
    coordinates: "-47.966110229492, -19.764722824097",
  },
  {
    ident: "SBVG",
    type: "medium_airport",
    name: "Major Brigadeiro Trompowsky Airport",
    elevation_ft: "3025",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Varginha",
    gps_code: "SBVG",
    iata_code: "VAG",
    coordinates: "-45.4733009338, -21.5900993347",
  },
  {
    ident: "SBVH",
    type: "medium_airport",
    name: "Brigadeiro Camar����o Airport",
    elevation_ft: "2018",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RO",
    municipality: "Vilhena",
    gps_code: "SBVH",
    iata_code: "BVH",
    coordinates: "-60.098300933838, -12.694399833679",
  },
  {
    ident: "SBVT",
    type: "medium_airport",
    name: "Eurico de Aguiar Salles Airport",
    elevation_ft: "11",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-ES",
    municipality: "Vit����ria",
    gps_code: "SBVT",
    iata_code: "VIX",
    coordinates: "-40.286388, -20.258057",
  },
  {
    ident: "SBYS",
    type: "medium_airport",
    name: "Campo Fontenelle Airport",
    elevation_ft: "1968",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Pirassununga",
    gps_code: "SBYS",
    iata_code: "QPS",
    coordinates: "-47.334800720214844, -21.984600067138672",
  },
  {
    ident: "SBZM",
    type: "medium_airport",
    name: "Presidente Itamar Franco Airport",
    elevation_ft: "1348",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Juiz de Fora",
    gps_code: "SBZM",
    iata_code: "IZA",
    coordinates: "-43.173069, -21.513086",
  },
  {
    ident: "SCAC",
    type: "small_airport",
    name: "Pupelde Airport",
    elevation_ft: "315",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Ancud",
    gps_code: "SCAC",
    iata_code: "ZUD",
    coordinates: "-73.796571, -41.904251",
  },
  {
    ident: "SCAN",
    type: "small_airport",
    name: "San Rafael Airport",
    elevation_ft: "2461",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-VS",
    municipality: "Los Andes",
    gps_code: "SCAN",
    iata_code: "LOB",
    coordinates: "-70.64669799804688, -32.81420135498047",
  },
  {
    ident: "SCAP",
    type: "small_airport",
    name: "Alto Palena Airport",
    elevation_ft: "794",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Alto Palena",
    gps_code: "SCAP",
    iata_code: "WAP",
    coordinates: "-71.806098938, -43.6119003296",
  },
  {
    ident: "SCAR",
    type: "medium_airport",
    name: "Chacalluta Airport",
    elevation_ft: "167",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AP",
    municipality: "Arica",
    gps_code: "SCAR",
    iata_code: "ARI",
    coordinates: "-70.338699, -18.348499",
  },
  {
    ident: "SCAS",
    type: "small_airport",
    name: "Cabo 1���� Juan Rom����n Airport",
    elevation_ft: "30",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AI",
    municipality: "Puerto Aysen",
    gps_code: "SCAS",
    iata_code: "WPA",
    coordinates: "-72.67030334472656, -45.399200439453125",
  },
  {
    ident: "SCAT",
    type: "medium_airport",
    name: "Desierto de Atacama Airport",
    elevation_ft: "670",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AT",
    municipality: "Copiapo",
    gps_code: "SCAT",
    iata_code: "CPO",
    coordinates: "-70.7791976929, -27.2611999512",
  },
  {
    ident: "SCBA",
    type: "medium_airport",
    name: "Balmaceda Airport",
    elevation_ft: "1722",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AI",
    municipality: "Balmaceda",
    gps_code: "SCBA",
    iata_code: "BBA",
    coordinates: "-71.68949890136719, -45.916099548339844",
  },
  {
    ident: "SCBE",
    type: "medium_airport",
    name: "Barriles Airport",
    elevation_ft: "3475",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AN",
    municipality: "Tocopilla",
    gps_code: "SCBE",
    iata_code: "TOQ",
    coordinates: "-70.06289672851562, -22.14109992980957",
  },
  {
    ident: "SCBI",
    type: "small_airport",
    name: "Pampa Guanaco Airport",
    elevation_ft: "591",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-MA",
    municipality: "Bahia Inutil",
    gps_code: "SCBI",
    iata_code: "DPB",
    coordinates: "-68.809193, -54.049977",
  },
  {
    ident: "SCCC",
    type: "medium_airport",
    name: "Chile Chico Airport",
    elevation_ft: "1070",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AI",
    municipality: "Chile Chico",
    gps_code: "SCCC",
    iata_code: "CCH",
    coordinates: "-71.6874008178711, -46.58330154418945",
  },
  {
    ident: "SCCF",
    type: "medium_airport",
    name: "El Loa Airport",
    elevation_ft: "7543",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AN",
    municipality: "Calama",
    gps_code: "SCCF",
    iata_code: "CJC",
    coordinates: "-68.90360260009766, -22.498199462890625",
  },
  {
    ident: "SCCH",
    type: "medium_airport",
    name: "Gral. Bernardo O����Higgins Airport",
    elevation_ft: "495",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-BI",
    municipality: "Chillan",
    gps_code: "SCCH",
    iata_code: "YAI",
    coordinates: "-72.03140258789062, -36.58250045776367",
  },
  {
    ident: "SCCI",
    type: "medium_airport",
    name: "Pdte. Carlos Iba����ez del Campo Airport",
    elevation_ft: "139",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-MA",
    municipality: "Punta Arenas",
    gps_code: "SCCI",
    iata_code: "PUQ",
    coordinates: "-70.854599, -53.002602",
  },
  {
    ident: "SCCQ",
    type: "small_airport",
    name: "Tambillos Airport",
    elevation_ft: "656",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-CO",
    municipality: "Coquimbo",
    gps_code: "SCCQ",
    iata_code: "COW",
    coordinates: "-71.246944, -30.198889",
  },
  {
    ident: "SCCY",
    type: "medium_airport",
    name: "Teniente Vidal Airport",
    elevation_ft: "1020",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AI",
    municipality: "Coyhaique",
    gps_code: "SCCY",
    iata_code: "GXQ",
    coordinates: "-72.1061019897461, -45.594200134277344",
  },
  {
    ident: "SCDA",
    type: "medium_airport",
    name: "Diego Aracena Airport",
    elevation_ft: "155",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-TA",
    municipality: "Iquique",
    gps_code: "SCDA",
    iata_code: "IQQ",
    coordinates: "-70.1812973022461, -20.535200119018555",
  },
  {
    ident: "SCEL",
    type: "large_airport",
    name: "Comodoro Arturo Merino Ben����tez International Airport",
    elevation_ft: "1555",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-RM",
    municipality: "Santiago",
    gps_code: "SCEL",
    iata_code: "SCL",
    coordinates: "-70.78579711914062, -33.393001556396484",
  },
  {
    ident: "SCES",
    type: "medium_airport",
    name: "Ricardo Garc����a Posada Airport",
    elevation_ft: "5240",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AT",
    municipality: "El Salvador",
    gps_code: "SCES",
    iata_code: "ESR",
    coordinates: "-69.76519775390625, -26.311100006103516",
  },
  {
    ident: "SCEV",
    type: "small_airport",
    name: "El Avellano Airport",
    elevation_ft: "489",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Frutillar",
    gps_code: "SCEV",
    iata_code: "FRT",
    coordinates: "-73.05000305175781, -41.117000579833984",
  },
  {
    ident: "SCFA",
    type: "medium_airport",
    name: "Andr����s Sabella G����lvez International Airport",
    elevation_ft: "455",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AN",
    municipality: "Antofagasta",
    gps_code: "SCFA",
    iata_code: "ANF",
    coordinates: "-70.445099, -23.444501",
  },
  {
    ident: "SCFM",
    type: "medium_airport",
    name: "Capitan Fuentes Martinez Airport Airport",
    elevation_ft: "104",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-MA",
    municipality: "Porvenir",
    gps_code: "SCFM",
    iata_code: "WPR",
    coordinates: "-70.31919860839844, -53.253700256347656",
  },
  {
    ident: "SCFT",
    type: "small_airport",
    name: "Futaleuf���� Airport",
    elevation_ft: "1148",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Futaleufu",
    gps_code: "SCFT",
    iata_code: "FFU",
    coordinates: "-71.8510971069336, -43.18920135498047",
  },
  {
    ident: "SCGE",
    type: "small_airport",
    name: "Mar����a Dolores Airport",
    elevation_ft: "374",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-BI",
    municipality: "Los Angeles",
    gps_code: "SCGE",
    iata_code: "LSQ",
    coordinates: "-72.42539978027344, -37.40169906616211",
  },
  {
    ident: "SCGZ",
    type: "medium_airport",
    name: "Guardiamarina Za����artu Airport",
    elevation_ft: "88",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-MA",
    municipality: "Puerto Williams",
    gps_code: "SCGZ",
    iata_code: "WPU",
    coordinates: "-67.62629699707031, -54.93109893798828",
  },
  {
    ident: "SCHR",
    type: "medium_airport",
    name: "Cochrane Airport",
    elevation_ft: "643",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AI",
    municipality: "Cochrane",
    gps_code: "SCHR",
    iata_code: "LGR",
    coordinates: "-72.5884017944336, -47.24380111694336",
  },
  {
    ident: "SCIE",
    type: "medium_airport",
    name: "Carriel Sur Airport",
    elevation_ft: "26",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-BI",
    municipality: "Concepcion",
    gps_code: "SCIE",
    iata_code: "CCP",
    coordinates: "-73.063103, -36.772701",
  },
  {
    ident: "SCIP",
    type: "medium_airport",
    name: "Mataveri Airport",
    elevation_ft: "227",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-VS",
    municipality: "Isla De Pascua",
    gps_code: "SCIP",
    iata_code: "IPC",
    coordinates: "-109.42199707, -27.1648006439",
  },
  {
    ident: "SCJO",
    type: "medium_airport",
    name: "Ca����al Bajo Carlos - Hott Siebert Airport",
    elevation_ft: "187",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Osorno",
    gps_code: "SCJO",
    iata_code: "ZOS",
    coordinates: "-73.06099700927734, -40.61119842529297",
  },
  {
    ident: "SCKP",
    type: "small_airport",
    name: "Coposa Airport",
    elevation_ft: "12468",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-TA",
    municipality: "Pica",
    gps_code: "SCKP",
    iata_code: "CPP",
    coordinates: "-68.683502, -20.7505",
  },
  {
    ident: "SCLL",
    type: "medium_airport",
    name: "Vallenar Airport",
    elevation_ft: "1725",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AT",
    municipality: "Vallenar",
    gps_code: "SCLL",
    iata_code: "VLR",
    coordinates: "-70.75599670410156, -28.596399307250977",
  },
  {
    ident: "SCLN",
    type: "small_airport",
    name: "Municipal de Linares Airport",
    elevation_ft: "591",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-ML",
    municipality: "Linares",
    gps_code: "SCLN",
    iata_code: "ZLR",
    coordinates: "-71.54859924316406, -35.861698150634766",
  },
  {
    ident: "SCNT",
    type: "medium_airport",
    name: "Tte. Julio Gallardo Airport",
    elevation_ft: "217",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-MA",
    municipality: "Puerto Natales",
    gps_code: "SCNT",
    iata_code: "PNT",
    coordinates: "-72.52839660644531, -51.67150115966797",
  },
  {
    ident: "SCOV",
    type: "small_airport",
    name: "El Tuqui Airport",
    elevation_ft: "1100",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-CO",
    municipality: "Ovalle",
    gps_code: "SCOV",
    iata_code: "OVL",
    coordinates: "-71.17559814453125, -30.559200286865234",
  },
  {
    ident: "SCPC",
    type: "small_airport",
    name: "Puc����n Airport",
    elevation_ft: "853",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AR",
    municipality: "Pucon",
    gps_code: "SCPC",
    iata_code: "ZPC",
    coordinates: "-71.91590118408203, -39.29280090332031",
  },
  {
    ident: "SCPQ",
    type: "small_airport",
    name: "Mocopulli Airport",
    elevation_ft: "528",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Dalcahue",
    gps_code: "SCPQ",
    iata_code: "MHC",
    coordinates: "-73.715693, -42.340388",
  },
  {
    ident: "SCPV",
    type: "small_airport",
    name: "El Mirador Airport",
    elevation_ft: "430",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Puerto Varas",
    gps_code: "SCPV",
    iata_code: "PUX",
    coordinates: "-72.94670104980469, -41.34939956665039",
  },
  {
    ident: "SCQP",
    type: "medium_airport",
    name: "La Araucan����a Airport",
    elevation_ft: "333",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AR",
    municipality: "Temuco",
    gps_code: "SCQP",
    iata_code: "ZCO",
    coordinates: "-72.6515, -38.9259",
  },
  {
    ident: "SCR",
    type: "closed",
    name: "Scranton Municipal Airport.",
    elevation_ft: "1151",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-PA",
    municipality: "Scranton",
    iata_code: "SCR",
    coordinates: "-75.772, 41.4802",
  },
  {
    ident: "SCRA",
    type: "medium_airport",
    name: "Cha����aral Airport",
    elevation_ft: "97",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AT",
    municipality: "Cha����aral",
    gps_code: "SCRA",
    iata_code: "CNR",
    coordinates: "-70.6072998046875, -26.332500457763672",
  },
  {
    ident: "SCRD",
    type: "small_airport",
    name: "Rodelillo Airport",
    elevation_ft: "1100",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-VS",
    municipality: "Vi����a Del Mar",
    gps_code: "SCRD",
    iata_code: "VAP",
    coordinates: "-71.55750274658203, -33.06809997558594",
  },
  {
    ident: "SCRG",
    type: "medium_airport",
    name: "De La Independencia Airport",
    elevation_ft: "1446",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LI",
    municipality: "Rancagua",
    gps_code: "SCRG",
    iata_code: "QRC",
    coordinates: "-70.77570343017578, -34.17369842529297",
  },
  {
    ident: "SCRM",
    type: "small_airport",
    name: "Teniente Rodolfo Marsh Martin Base",
    elevation_ft: "147",
    continent: "AN",
    iso_country: "AQ",
    iso_region: "AQ-U-A",
    municipality: "Isla Rey Jorge",
    gps_code: "SCRM",
    iata_code: "TNM",
    coordinates: "-58.9866981506, -62.1907997131",
  },
  {
    ident: "SCSB",
    type: "small_airport",
    name: "Franco Bianco Airport",
    elevation_ft: "104",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-MA",
    municipality: "Cerro Sombrero",
    gps_code: "SCSB",
    iata_code: "SMB",
    coordinates: "-69.33360290527344, -52.736698150634766",
  },
  {
    ident: "SCSE",
    type: "medium_airport",
    name: "La Florida Airport",
    elevation_ft: "481",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-CO",
    municipality: "La Serena-Coquimbo",
    gps_code: "SCSE",
    iata_code: "LSC",
    coordinates: "-71.199501, -29.916201",
  },
  {
    ident: "SCSF",
    type: "small_airport",
    name: "V����ctor Laf����n Airport",
    elevation_ft: "2188",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-VS",
    municipality: "San Felipe",
    gps_code: "SCSF",
    iata_code: "SSD",
    coordinates: "-70.70500183105469, -32.74580001831055",
  },
  {
    ident: "SCST",
    type: "small_airport",
    name: "Gamboa Airport",
    elevation_ft: "151",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Castro",
    gps_code: "SCST",
    iata_code: "WCA",
    coordinates: "-73.77279663085938, -42.490299224853516",
  },
  {
    ident: "SCTC",
    type: "medium_airport",
    name: "Maquehue Airport",
    elevation_ft: "304",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AR",
    municipality: "Temuco",
    gps_code: "SCTC",
    iata_code: "PZS",
    coordinates: "-72.637100219727, -38.766799926758",
  },
  {
    ident: "SCTE",
    type: "medium_airport",
    name: "El Tepual Airport",
    elevation_ft: "294",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Puerto Montt",
    gps_code: "SCTE",
    iata_code: "PMC",
    coordinates: "-73.09400177001953, -41.438899993896484",
  },
  {
    ident: "SCTI",
    type: "closed",
    name: "Los Cerrillos Airport",
    elevation_ft: "1680",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-RM",
    municipality: "Santiago",
    gps_code: "SCTI",
    iata_code: "ULC",
    coordinates: "-70.6977996826, -33.493598938",
  },
  {
    ident: "SCTL",
    type: "medium_airport",
    name: "Panguilemo Airport",
    elevation_ft: "371",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-ML",
    municipality: "Talca",
    gps_code: "SCTL",
    iata_code: "TLX",
    coordinates: "-71.60169982910156, -35.37779998779297",
  },
  {
    ident: "SCTN",
    type: "medium_airport",
    name: "Chait����n Airport",
    elevation_ft: "13",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LL",
    municipality: "Chaiten",
    gps_code: "SCTN",
    iata_code: "WCH",
    coordinates: "-72.6990966796875, -42.93280029296875",
  },
  {
    ident: "SCTO",
    type: "medium_airport",
    name: "Victoria Airport",
    elevation_ft: "1148",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AR",
    municipality: "Victoria",
    gps_code: "SCTO",
    iata_code: "ZIC",
    coordinates: "-72.34860229492188, -38.245601654052734",
  },
  {
    ident: "SCTT",
    type: "medium_airport",
    name: "Las Breas Airport",
    elevation_ft: "2580",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-AN",
    municipality: "Taltal",
    gps_code: "SCTT",
    iata_code: "TTC",
    coordinates: "-70.37590026855469, -25.564300537109375",
  },
  {
    ident: "SCVD",
    type: "medium_airport",
    name: "Pichoy Airport",
    elevation_ft: "59",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-LR",
    municipality: "Valdivia",
    gps_code: "SCVD",
    iata_code: "ZAL",
    coordinates: "-73.0860977173, -39.6500015259",
  },
  {
    ident: "SCVM",
    type: "medium_airport",
    name: "Vi����a del mar Airport",
    elevation_ft: "461",
    continent: "SA",
    iso_country: "CL",
    iso_region: "CL-VS",
    municipality: "Vi����a Del Mar",
    gps_code: "SCVM",
    iata_code: "KNA",
    coordinates: "-71.4786, -32.9496",
  },
  {
    ident: "SDAM",
    type: "small_airport",
    name: "Amarais Airport",
    elevation_ft: "2008",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Campinas",
    gps_code: "SDAM",
    iata_code: "CPQ",
    local_code: "SDAM",
    coordinates: "-47.10820007324219, -22.85919952392578",
  },
  {
    ident: "SDBK",
    type: "small_airport",
    name: "Botucatu - Tancredo de Almeida Neves Airport",
    elevation_ft: "3012",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Botucatu",
    gps_code: "SDBK",
    iata_code: "QCJ",
    local_code: "SDBK",
    coordinates: "-48.467999, -22.939501",
  },
  {
    ident: "SDCG",
    type: "small_airport",
    name: "Senadora Eunice Micheles Airport",
    elevation_ft: "335",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "S����o Paulo De Oliven����a",
    gps_code: "SDCG",
    iata_code: "OLC",
    local_code: "SDCG",
    coordinates: "-68.9204120636, -3.46792950765",
  },
  {
    ident: "SDCO",
    type: "small_airport",
    name: "Sorocaba Airport",
    elevation_ft: "2077",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Sorocaba",
    gps_code: "SDCO",
    iata_code: "SOD",
    local_code: "SDCO",
    coordinates: "-47.490002, -23.478001",
  },
  {
    ident: "SDDJ",
    type: "small_airport",
    name: "Fazenda Santa Maria Airport",
    elevation_ft: "1525",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Mon��������es",
    gps_code: "SDDJ",
    iata_code: "0",
    coordinates: "-50.07939910888672, -20.848899841308594",
  },
  {
    ident: "SDDR",
    type: "small_airport",
    name: "Dracena Airport",
    elevation_ft: "1220",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Dracena",
    gps_code: "SDDR",
    iata_code: "QDC",
    local_code: "SDDR",
    coordinates: "-51.60689926147461, -21.460500717163086",
  },
  {
    ident: "SDDV",
    type: "small_airport",
    name: "Usina Catanduva Airport",
    elevation_ft: "1860",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Palmares Paulista",
    gps_code: "SDDV",
    iata_code: "0",
    coordinates: "-48.84469985961914, -21.12689971923828",
  },
  {
    ident: "SDFC",
    type: "small_airport",
    name: "Fazenda Const����ncia Airport",
    elevation_ft: "1673",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Altair",
    gps_code: "SDFC",
    iata_code: "0",
    coordinates: "-49.1880989074707, -20.48859977722168",
  },
  {
    ident: "SDGC",
    type: "small_airport",
    name: "Gar����a Airport",
    elevation_ft: "2182",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Gar����a",
    gps_code: "SDGC",
    iata_code: "0",
    coordinates: "-49.65610122680664, -22.1835994720459",
  },
  {
    ident: "SDI",
    type: "small_airport",
    name: "Saidor Airport",
    elevation_ft: "83",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Saidor",
    gps_code: "AYSD",
    iata_code: "SDI",
    local_code: "SDA",
    coordinates: "146.462777778, -5.62713888889",
  },
  {
    ident: "SDJC",
    type: "small_airport",
    name: "Jaboticabal Airport",
    elevation_ft: "2024",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Jaboticabal",
    gps_code: "SDJC",
    iata_code: "0",
    coordinates: "-48.284698486328125, -21.229999542236328",
  },
  {
    ident: "SDJL",
    type: "small_airport",
    name: "Jales Airport",
    elevation_ft: "1496",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Jales",
    gps_code: "SDJL",
    iata_code: "JLS",
    local_code: "SDJL",
    coordinates: "-50.5463981628418, -20.292999267578125",
  },
  {
    ident: "SDJO",
    type: "small_airport",
    name: "S����o Joaquim da Barra Airport",
    elevation_ft: "2136",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "S����o Joaquim Da Barra",
    gps_code: "SDJO",
    iata_code: "0",
    coordinates: "-47.842201232910156, -20.593299865722656",
  },
  {
    ident: "SDJZ",
    type: "small_airport",
    name: "Fazenda Barra do Agudo Airport",
    elevation_ft: "1608",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Morro Agudo",
    gps_code: "SDJZ",
    iata_code: "0",
    coordinates: "-48.221900939941406, -20.808300018310547",
  },
  {
    ident: "SDKK",
    type: "small_airport",
    name: "Mococa Airport",
    elevation_ft: "2116",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Mococa",
    gps_code: "SDKK",
    iata_code: "QOA",
    coordinates: "-47.034400939941406, -21.48780059814453",
  },
  {
    ident: "SDLC",
    type: "small_airport",
    name: "Luc����lia Airport",
    elevation_ft: "1476",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Luc����lia",
    gps_code: "SDLC",
    iata_code: "0",
    coordinates: "-51.0182991027832, -21.749399185180664",
  },
  {
    ident: "SDLL",
    type: "small_airport",
    name: "Leme Airport",
    elevation_ft: "2024",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Leme",
    gps_code: "SDLL",
    iata_code: "0",
    coordinates: "-47.382198333740234, -22.226900100708008",
  },
  {
    ident: "SDLP",
    type: "small_airport",
    name: "Len��������is Paulista Airport",
    elevation_ft: "2039",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Len��������is Paulista",
    gps_code: "SDLP",
    iata_code: "QGC",
    local_code: "SDLP",
    coordinates: "-48.774600982666016, -22.578399658203125",
  },
  {
    ident: "SDLY",
    type: "small_airport",
    name: "Mat����o Airport",
    elevation_ft: "2083",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Mat����o",
    gps_code: "SDLY",
    iata_code: "0",
    coordinates: "-48.352500915527344, -21.62310028076172",
  },
  {
    ident: "SDMH",
    type: "small_airport",
    name: "Mirassol Airport",
    elevation_ft: "1916",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Mirassol",
    gps_code: "SDMH",
    iata_code: "0",
    coordinates: "-49.48609924316406, -20.807199478149414",
  },
  {
    ident: "SDMJ",
    type: "small_airport",
    name: "Mogi Mirim Airport",
    elevation_ft: "2274",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Mogi Mirim",
    gps_code: "SDMJ",
    iata_code: "0",
    coordinates: "-46.90530014038086, -22.40999984741211",
  },
  {
    ident: "SDNH",
    type: "small_airport",
    name: "Novo Horizonte Airport",
    elevation_ft: "1526",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Novo Horizonte",
    gps_code: "SDNH",
    iata_code: "0",
    coordinates: "-49.23440170288086, -21.497800827026367",
  },
  {
    ident: "SDNY",
    type: "small_airport",
    name: "Aeroclube Airport",
    elevation_ft: "164",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Nova Igua����u",
    gps_code: "SDNY",
    iata_code: "QNV",
    local_code: "SDNY",
    coordinates: "-43.46030044555664, -22.74530029296875",
  },
  {
    ident: "SDOU",
    type: "small_airport",
    name: "Ourinhos Airport",
    elevation_ft: "1532",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Ourinhos",
    gps_code: "SDOU",
    iata_code: "OUS",
    local_code: "SDOU",
    coordinates: "-49.913299560546875, -22.96649932861328",
  },
  {
    ident: "SDOW",
    type: "small_airport",
    name: "Ouril����ndia do Norte Airport",
    elevation_ft: "901",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Ouril����ndia do Norte",
    gps_code: "SDOW",
    iata_code: "OIA",
    local_code: "SDOW",
    coordinates: "-51.0499000549, -6.763100147250001",
  },
  {
    ident: "SDPV",
    type: "small_airport",
    name: "Presidente Venceslau Airport",
    elevation_ft: "1460",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Presidente Venceslau",
    gps_code: "SDPV",
    iata_code: "0",
    coordinates: "-51.8843994140625, -21.893299102783203",
  },
  {
    ident: "SDPW",
    type: "small_airport",
    name: "Piracicaba Airport",
    elevation_ft: "1887",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Piracicaba",
    gps_code: "SDPW",
    iata_code: "QHB",
    local_code: "SDPW",
    coordinates: "-47.61819839477539, -22.71150016784668",
  },
  {
    ident: "SDRA",
    type: "small_airport",
    name: "Fazenda das Represas Airport",
    elevation_ft: "2271",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "S����o Miguel Arcanjo",
    gps_code: "SDRA",
    iata_code: "0",
    coordinates: "-48.05179977416992, -23.875600814819336",
  },
  {
    ident: "SDRK",
    type: "small_airport",
    name: "Rio Claro Airport",
    elevation_ft: "1991",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Rio Claro",
    gps_code: "SDRK",
    iata_code: "QIQ",
    coordinates: "-47.562113, -22.430104",
  },
  {
    ident: "SDRS",
    type: "small_airport",
    name: "Resende Airport",
    elevation_ft: "1320",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Resende",
    gps_code: "SDRS",
    iata_code: "REZ",
    local_code: "SDRS",
    coordinates: "-44.4803009033, -22.4785003662",
  },
  {
    ident: "SDSC",
    type: "small_airport",
    name: "M����rio Pereira Lopes������S����o Carlos Airport",
    elevation_ft: "2649",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "S����o Carlos",
    gps_code: "SDSC",
    iata_code: "QSC",
    local_code: "SDSC",
    coordinates: "-47.903703, -21.875401",
  },
  {
    ident: "SDSJ",
    type: "small_airport",
    name: "Fazenda S����o Jo����o Airport",
    elevation_ft: "2172",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Orl����ndia",
    gps_code: "SDSJ",
    iata_code: "0",
    coordinates: "-47.93360137939453, -20.7455997467041",
  },
  {
    ident: "SDTO",
    type: "small_airport",
    name: "Fazenda Cataco Airport",
    elevation_ft: "1447",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Ubarana",
    gps_code: "SDTO",
    iata_code: "0",
    coordinates: "-49.79309844970703, -21.263599395751953",
  },
  {
    ident: "SDUB",
    type: "small_airport",
    name: "Ubatuba Airport",
    elevation_ft: "13",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Ubatuba",
    gps_code: "SDUB",
    iata_code: "UBT",
    local_code: "SDUB",
    coordinates: "-45.075599670410156, -23.441099166870117",
  },
  {
    ident: "SDUN",
    type: "small_airport",
    name: "Itaperuna Airport",
    elevation_ft: "410",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Itaperuna",
    gps_code: "SDUN",
    iata_code: "ITP",
    local_code: "SDUN",
    coordinates: "-41.8759002686, -21.219299316399997",
  },
  {
    ident: "SDVG",
    type: "small_airport",
    name: "Votuporanga Airport",
    elevation_ft: "1667",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Votuporanga",
    gps_code: "SDVG",
    iata_code: "VOT",
    local_code: "SDVG",
    coordinates: "-50.00450134277344, -20.463199615478516",
  },
  {
    ident: "SDVS",
    type: "small_airport",
    name: "Fazenda Vassoural Airport",
    elevation_ft: "1801",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Pontal",
    gps_code: "SDVS",
    iata_code: "0",
    coordinates: "-48.03419876098633, -21.072799682617188",
  },
  {
    ident: "SDYM",
    type: "small_airport",
    name: "Limeira Airport",
    elevation_ft: "2172",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Limeira",
    gps_code: "SDYM",
    iata_code: "QGB",
    coordinates: "-47.411944, -22.603889",
  },
  {
    ident: "SDZY",
    type: "medium_airport",
    name: "Zona da Mata Regional Airport",
    elevation_ft: "1348",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Juiz De Fora",
    gps_code: "SBZM",
    iata_code: "IZA",
    coordinates: "-43.1730575562, -21.5130558014",
  },
  {
    ident: "SE-0016",
    type: "medium_airport",
    name: "Scandinavian Mountains Airport",
    elevation_ft: "1608",
    continent: "EU",
    iso_country: "SE",
    iso_region: "SE-W",
    municipality: "S����len and Trysil",
    gps_code: "ESKS",
    iata_code: "SCR",
    coordinates: "12.840676, 61.159092",
  },
  {
    ident: "SEAM",
    type: "medium_airport",
    name: "Chacho����n Airport",
    elevation_ft: "8502",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-T",
    municipality: "Ambato",
    gps_code: "SEAM",
    iata_code: "ATF",
    coordinates: "-78.57460021972656, -1.2120699882507324",
  },
  {
    ident: "SECO",
    type: "medium_airport",
    name: "Francisco De Orellana Airport",
    elevation_ft: "834",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-D",
    municipality: "Coca",
    gps_code: "SECO",
    iata_code: "OCC",
    coordinates: "-76.98680114746094, -0.4628860056400299",
  },
  {
    ident: "SECU",
    type: "medium_airport",
    name: "Mariscal Lamar Airport",
    elevation_ft: "8306",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-A",
    municipality: "Cuenca",
    gps_code: "SECU",
    iata_code: "CUE",
    coordinates: "-78.984398, -2.88947",
  },
  {
    ident: "SEGS",
    type: "small_airport",
    name: "Seymour Airport",
    elevation_ft: "207",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-W",
    municipality: "Baltra",
    gps_code: "SEGS",
    iata_code: "GPS",
    coordinates: "-90.26589965820312, -0.45375800132751465",
  },
  {
    ident: "SEGU",
    type: "medium_airport",
    name: "Jos���� Joaqu����n de Olmedo International Airport",
    elevation_ft: "19",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-G",
    municipality: "Guayaquil",
    gps_code: "SEGU",
    iata_code: "GYE",
    coordinates: "-79.88359832760001, -2.1574199199699997",
  },
  {
    ident: "SEII",
    type: "small_airport",
    name: "General Villamil Airport",
    elevation_ft: "35",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-W",
    municipality: "Isabela",
    gps_code: "SEII",
    iata_code: "IBB",
    coordinates: "-90.9530029297, -0.942628026009",
  },
  {
    ident: "SEJI",
    type: "small_airport",
    name: "Jipijapa Airport",
    elevation_ft: "223",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-M",
    municipality: "Jipijapa",
    gps_code: "SEJI",
    iata_code: "JIP",
    coordinates: "-80.66666412353516, -1",
  },
  {
    ident: "SELT",
    type: "large_airport",
    name: "Cotopaxi International Airport",
    elevation_ft: "9205",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-X",
    municipality: "Latacunga",
    gps_code: "SELT",
    iata_code: "LTX",
    coordinates: "-78.615799, -0.906833",
  },
  {
    ident: "SEMA",
    type: "medium_airport",
    name: "Jose Maria Velasco Ibarra Airport",
    elevation_ft: "1508",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-L",
    municipality: "Macar����",
    gps_code: "SEMA",
    iata_code: "MRR",
    coordinates: "-79.94100189210002, -4.37823009491",
  },
  {
    ident: "SEMC",
    type: "medium_airport",
    name: "Coronel E Carvajal Airport",
    elevation_ft: "3452",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-S",
    municipality: "Macas",
    gps_code: "SEMC",
    iata_code: "XMS",
    coordinates: "-78.12079620361328, -2.2991700172424316",
  },
  {
    ident: "SEMH",
    type: "medium_airport",
    name: "General Manuel Serrano Airport",
    elevation_ft: "11",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-O",
    municipality: "Machala",
    gps_code: "SEMH",
    iata_code: "MCH",
    coordinates: "-79.961601, -3.2689",
  },
  {
    ident: "SEMT",
    type: "medium_airport",
    name: "Eloy Alfaro International Airport",
    elevation_ft: "48",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-M",
    municipality: "Manta",
    gps_code: "SEMT",
    iata_code: "MEC",
    coordinates: "-80.67880249023438, -0.9460780024528503",
  },
  {
    ident: "SENL",
    type: "small_airport",
    name: "Nueva Loja Airport",
    elevation_ft: "980",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-U",
    municipality: "Lago Agrio",
    gps_code: "SENL",
    iata_code: "LGQ",
    coordinates: "-76.86750030520001, 0.0930560007691",
  },
  {
    ident: "SEPT",
    type: "small_airport",
    name: "Putumayo Airport",
    elevation_ft: "733",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-U",
    municipality: "Puerto Putumayo",
    gps_code: "SEPT",
    iata_code: "PYO",
    coordinates: "-75.85022, 0.115949",
  },
  {
    ident: "SEPV",
    type: "medium_airport",
    name: "Reales Tamarindos Airport",
    elevation_ft: "130",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-M",
    municipality: "Portoviejo",
    gps_code: "SEPV",
    iata_code: "PVO",
    coordinates: "-80.47219848632812, -1.0416500568389893",
  },
  {
    ident: "SEQM",
    type: "large_airport",
    name: "Mariscal Sucre International Airport",
    elevation_ft: "7841",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-P",
    municipality: "Quito",
    gps_code: "SEQM",
    iata_code: "UIO",
    local_code: "UIO",
    coordinates: "-78.3575, -0.129166666667",
  },
  {
    ident: "SERO",
    type: "medium_airport",
    name: "Santa Rosa International Airport",
    elevation_ft: "20",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-O",
    municipality: "Santa Rosa",
    gps_code: "SERO",
    iata_code: "ETR",
    coordinates: "-79.996957, -3.441986",
  },
  {
    ident: "SESA",
    type: "medium_airport",
    name: "General Ulpiano Paez Airport",
    elevation_ft: "18",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-G",
    municipality: "Salinas",
    gps_code: "SESA",
    iata_code: "SNC",
    coordinates: "-80.988899, -2.20499",
  },
  {
    ident: "SESC",
    type: "small_airport",
    name: "Sucua Airport",
    elevation_ft: "3116",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-S",
    municipality: "Sucua",
    gps_code: "SESC",
    iata_code: "SUQ",
    coordinates: "-78.16699981689453, -2.4830000400543213",
  },
  {
    ident: "SESM",
    type: "small_airport",
    name: "Rio Amazonas Airport",
    elevation_ft: "3465",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-Y",
    municipality: "Shell Mera",
    gps_code: "SESM",
    iata_code: "PTZ",
    coordinates: "-78.0626983643, -1.5052399635299998",
  },
  {
    ident: "SEST",
    type: "small_airport",
    name: "San Crist����bal Airport",
    elevation_ft: "62",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-W",
    municipality: "San Crist����bal",
    gps_code: "SEST",
    iata_code: "SCY",
    coordinates: "-89.617401, -0.910206",
  },
  {
    ident: "SESV",
    type: "small_airport",
    name: "Los Perales Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-M",
    municipality: "Bah����a de Caraquez",
    gps_code: "SESV",
    iata_code: "BHA",
    coordinates: "-80.40270233154297, -0.6081110239028931",
  },
  {
    ident: "SETH",
    type: "small_airport",
    name: "Taisha Airport",
    elevation_ft: "1669",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-S",
    municipality: "Taisha",
    gps_code: "SETH",
    iata_code: "TSC",
    coordinates: "-77.50279998779297, -2.3816699981689453",
  },
  {
    ident: "SETI",
    type: "small_airport",
    name: "Tiputini Airport",
    elevation_ft: "997",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-D",
    municipality: "Tiputini",
    gps_code: "SETI",
    iata_code: "TPN",
    coordinates: "-75.52639770507812, -0.7761110067367554",
  },
  {
    ident: "SETM",
    type: "small_airport",
    name: "Camilo Ponce Enriquez Airport",
    elevation_ft: "4056",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-L",
    municipality: "La Toma (Catamayo)",
    gps_code: "SETM",
    iata_code: "LOH",
    coordinates: "-79.371902, -3.99589",
  },
  {
    ident: "SETN",
    type: "small_airport",
    name: "General Rivadeneira Airport",
    elevation_ft: "32",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-E",
    municipality: "Tachina",
    gps_code: "SETN",
    iata_code: "ESM",
    coordinates: "-79.62660217285156, 0.9785190224647522",
  },
  {
    ident: "SETR",
    type: "medium_airport",
    name: "Tarapoa Airport",
    elevation_ft: "814",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-U",
    municipality: "Tarapoa",
    gps_code: "SETR",
    iata_code: "TPC",
    coordinates: "-76.33779907226562, -0.12295600026845932",
  },
  {
    ident: "SETU",
    type: "medium_airport",
    name: "Teniente Coronel Luis a Mantilla Airport",
    elevation_ft: "9649",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-C",
    municipality: "Tulc����n",
    gps_code: "SETU",
    iata_code: "TUA",
    coordinates: "-77.70809936523438, 0.8095059990882874",
  },
  {
    ident: "SFAL",
    type: "medium_airport",
    name: "Port Stanley Airport",
    elevation_ft: "75",
    continent: "SA",
    iso_country: "FK",
    iso_region: "FK-U-A",
    municipality: "Stanley",
    gps_code: "SFAL",
    iata_code: "PSY",
    coordinates: "-57.777599334717, -51.685699462891",
  },
  {
    ident: "SFR",
    type: "closed",
    name: "San Fernando Airport",
    elevation_ft: "1168",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Los Angeles",
    iata_code: "SFR",
    coordinates: "-118.422, 34.289",
  },
  {
    ident: "SFU",
    type: "small_airport",
    name: "Safia Airport",
    elevation_ft: "430",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Safia",
    gps_code: "AYSF",
    iata_code: "SFU",
    local_code: "SFA",
    coordinates: "148.636111111, -9.59305555556",
  },
  {
    ident: "SGAS",
    type: "medium_airport",
    name: "Silvio Pettirossi International Airport",
    elevation_ft: "292",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-11",
    municipality: "Asunci����n",
    gps_code: "SGAS",
    iata_code: "ASU",
    coordinates: "-57.52000045776367, -25.239999771118164",
  },
  {
    ident: "SGAY",
    type: "medium_airport",
    name: "Juan De Ayolas Airport",
    elevation_ft: "223",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-8",
    municipality: "Ayolas",
    gps_code: "SGAY",
    iata_code: "AYO",
    coordinates: "-56.854064, -27.370554",
  },
  {
    ident: "SGCO",
    type: "small_airport",
    name: "Teniente Col Carmelo Peralta Airport",
    elevation_ft: "253",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-1",
    municipality: "Concepci����n",
    gps_code: "SGCO",
    iata_code: "CIO",
    coordinates: "-57.427253, -23.442363",
  },
  {
    ident: "SGEN",
    type: "small_airport",
    name: "Encarnaci����n Airport",
    elevation_ft: "659",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-7",
    municipality: "Encarnaci����n",
    gps_code: "SGEN",
    iata_code: "ENO",
    coordinates: "-55.837495, -27.227366",
  },
  {
    ident: "SGES",
    type: "medium_airport",
    name: "Guarani International Airport",
    elevation_ft: "846",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-10",
    municipality: "Ciudad del Este",
    gps_code: "SGES",
    iata_code: "AGT",
    coordinates: "-54.842682, -25.454516",
  },
  {
    ident: "SGFI",
    type: "small_airport",
    name: "Filadelfia Airport",
    elevation_ft: "423",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-19",
    municipality: "Filadelfia",
    gps_code: "SGFI",
    iata_code: "FLM",
    coordinates: "-60.053604, -22.360477",
  },
  {
    ident: "SGL",
    type: "heliport",
    name: "Danilo Atienza Air Base",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-CAV",
    municipality: "Cavite",
    gps_code: "RPLS",
    iata_code: "SGL",
    coordinates: "120.906987, 14.49562",
  },
  {
    ident: "SGME",
    type: "medium_airport",
    name: "Dr. Luis Maria Arga����a International Airport",
    elevation_ft: "553",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-19",
    municipality: "Mariscal Estigarribia",
    gps_code: "SGME",
    iata_code: "ESG",
    coordinates: "-60.619998931884766, -22.049999237060547",
  },
  {
    ident: "SGPI",
    type: "medium_airport",
    name: "Carlos Miguel Gimenez Airport",
    elevation_ft: "249",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-12",
    municipality: "Pilar",
    gps_code: "SGPI",
    iata_code: "PIL",
    coordinates: "-58.318026, -26.881224",
  },
  {
    ident: "SGPJ",
    type: "small_airport",
    name: "Dr Augusto Roberto Fuster International Airport",
    elevation_ft: "1873",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-13",
    municipality: "Pedro Juan Caballero",
    gps_code: "SGPJ",
    iata_code: "PJC",
    coordinates: "-55.83000183105469, -22.639999389648438",
  },
  {
    ident: "SIC",
    type: "small_airport",
    name: "San Jos���� Island Airport",
    elevation_ft: "150",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-8",
    municipality: "Las Perlas",
    iata_code: "SIC",
    coordinates: "-79.078, 8.2622",
  },
  {
    ident: "SILC",
    type: "small_airport",
    name: "Municipal Bom Futuro Airport",
    elevation_ft: "1358",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Lucas do Rio Verde",
    gps_code: "SILC",
    iata_code: "LVR",
    local_code: "MT0025",
    coordinates: "-55.95025, -13.037861",
  },
  {
    ident: "SIMK",
    type: "small_airport",
    name: "Tenente Lund Pressoto Airport",
    elevation_ft: "3292",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Franca",
    gps_code: "SIMK",
    iata_code: "FRC",
    local_code: "SP0011",
    coordinates: "-47.3829, -20.592199",
  },
  {
    ident: "SISP",
    type: "small_airport",
    name: "Santa Paula Airport",
    elevation_ft: "1860",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Ipigu����",
    gps_code: "SISP",
    iata_code: "0",
    coordinates: "-49.39139938354492, -20.703899383544922",
  },
  {
    ident: "SIZ",
    type: "small_airport",
    name: "Sissano Airport",
    elevation_ft: "27",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Sissano",
    gps_code: "AYZN",
    iata_code: "SIZ",
    local_code: "SNO",
    coordinates: "142.0445, -2.99944444444",
  },
  {
    ident: "SIZX",
    type: "small_airport",
    name: "In����cio Lu����s do Nascimento Airport",
    elevation_ft: "870",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Juara",
    gps_code: "SIZX",
    iata_code: "JUA",
    coordinates: "-57.5495, -11.2966",
  },
  {
    ident: "SJF",
    type: "seaplane_base",
    name: "Cruz Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "VI",
    iso_region: "VI-U-A",
    municipality: "Saint John Island",
    iata_code: "SJF",
    coordinates: "-64.796, 18.3315",
  },
  {
    ident: "SJHG",
    type: "small_airport",
    name: "Confresa Airport",
    elevation_ft: "781",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Confresa",
    gps_code: "SJHG",
    iata_code: "CFO",
    coordinates: "-51.5635986328125, -10.634400367736816",
  },
  {
    ident: "SJNP",
    type: "small_airport",
    name: "Novo Progresso Airport",
    elevation_ft: "794",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Novo Progresso",
    gps_code: "SJNP",
    iata_code: "NPR",
    coordinates: "-55.400833, -7.125833",
  },
  {
    ident: "SJRG",
    type: "small_airport",
    name: "Rio Grande Regional Airport",
    elevation_ft: "27",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Rio Grande",
    gps_code: "SJRG",
    iata_code: "RIG",
    local_code: "RS0013",
    coordinates: "-52.167184, -32.083065",
  },
  {
    ident: "SJTC",
    type: "medium_airport",
    name: "Bauru - Arealva Airport",
    elevation_ft: "1949",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Bauru",
    gps_code: "SBAE",
    iata_code: "JTC",
    coordinates: "-49.0502866745, -22.166859140899998",
  },
  {
    ident: "SJVO",
    type: "small_airport",
    name: "Aragar����as Airport",
    elevation_ft: "1061",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Aragar����as",
    gps_code: "SJVO",
    iata_code: "ARS",
    coordinates: "-52.2411, -15.8994",
  },
  {
    ident: "SK-001",
    type: "small_airport",
    name: "Arica Airport",
    elevation_ft: "316",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-AMA",
    municipality: "Arica",
    iata_code: "ACM",
    coordinates: "-71.7580604553, -2.14543733597",
  },
  {
    ident: "SK-002",
    type: "small_airport",
    name: "El Encanto Airport",
    elevation_ft: "420",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-AMA",
    municipality: "El Encanto",
    iata_code: "ECO",
    local_code: "ECO",
    coordinates: "-73.20472222219999, -1.7533333333299999",
  },
  {
    ident: "SK-005",
    type: "small_airport",
    name: "Arboletes Airport",
    elevation_ft: "13",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Arboletes",
    iata_code: "ARO",
    local_code: "ARO",
    coordinates: "-76.4244, 8.85705",
  },
  {
    ident: "SK-008",
    type: "small_airport",
    name: "Jurado Airport",
    elevation_ft: "2184",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Jurado",
    gps_code: "SKJU",
    iata_code: "JUO",
    local_code: "JUO",
    coordinates: "-76.6, 6.516667",
  },
  {
    ident: "SK-020",
    type: "small_airport",
    name: "San Juan De Uraba Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "San Juan De Uraba",
    iata_code: "SJR",
    local_code: "SJR",
    coordinates: "-76.533333, 8.766667",
  },
  {
    ident: "SK-021",
    type: "small_airport",
    name: "San Pedro Airport",
    elevation_ft: "420",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "San Pedro de Urab����",
    iata_code: "NPU",
    local_code: "PDA",
    coordinates: "-76.380386, 8.28597",
  },
  {
    ident: "SK-045",
    type: "small_airport",
    name: "Puerto Rico Airport",
    elevation_ft: "980",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAQ",
    municipality: "Puerto Rico",
    iata_code: "PCC",
    local_code: "PCC",
    coordinates: "-75.166667, 1.916667",
  },
  {
    ident: "SK-046",
    type: "small_airport",
    name: "Solano Airport",
    elevation_ft: "860",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAQ",
    municipality: "Solano",
    iata_code: "SQF",
    local_code: "SQF",
    coordinates: "-75.2505, 0.702022",
  },
  {
    ident: "SK-047",
    type: "small_airport",
    name: "Yari Airport",
    elevation_ft: "569",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAQ",
    municipality: "Yari",
    iata_code: "AYI",
    local_code: "AYI",
    coordinates: "-72.266667, -0.383333",
  },
  {
    ident: "SK-048",
    type: "small_airport",
    name: "Aguaclara Airport",
    elevation_ft: "1090",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Aguaclara",
    iata_code: "ACL",
    local_code: "ACL",
    coordinates: "-73.002778, 4.753611",
  },
  {
    ident: "SK-059",
    type: "small_airport",
    name: "Currillo Airport",
    elevation_ft: "495",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Currillo",
    iata_code: "CUI",
    local_code: "CUI",
    coordinates: "-72, 4.666667",
  },
  {
    ident: "SK-109",
    type: "small_airport",
    name: "Monterrey Airport",
    elevation_ft: "1500",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Monterrey",
    iata_code: "MOY",
    local_code: "MOY",
    coordinates: "-72.894809, 4.906926",
  },
  {
    ident: "SK-115",
    type: "small_airport",
    name: "Paratebueno Airport",
    elevation_ft: "820",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Paratebueno",
    iata_code: "EUO",
    local_code: "EUO",
    coordinates: "-73.2, 4.383333",
  },
  {
    ident: "SK-116",
    type: "small_airport",
    name: "Pore Airport",
    elevation_ft: "975",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Pore",
    iata_code: "PRE",
    local_code: "POR",
    coordinates: "-71.983333, 5.733333",
  },
  {
    ident: "SK-120",
    type: "small_airport",
    name: "San Luis De Palenque Airport",
    elevation_ft: "551",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "San Luis De Palenque",
    iata_code: "SQE",
    local_code: "SQE",
    coordinates: "-71.7001, 5.400181",
  },
  {
    ident: "SK-131",
    type: "small_airport",
    name: "Tauramena Airport",
    elevation_ft: "1462",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Tauramena",
    gps_code: "SKTA",
    iata_code: "TAU",
    local_code: "TAU",
    coordinates: "-72.7424, 5.01281",
  },
  {
    ident: "SK-144",
    type: "small_airport",
    name: "Ayacucho Airport",
    elevation_ft: "415",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CES",
    municipality: "Ayacucho",
    iata_code: "AYC",
    local_code: "AYC",
    coordinates: "-73.616667, 8.6",
  },
  {
    ident: "SK-154",
    type: "small_airport",
    name: "Codazzi Airport",
    elevation_ft: "439",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CES",
    municipality: "Hacienda Borrero",
    iata_code: "DZI",
    local_code: "DZI",
    coordinates: "-73.45, 9.6",
  },
  {
    ident: "SK-174",
    type: "small_airport",
    name: "Las Flores Airport",
    elevation_ft: "399",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CES",
    municipality: "Codazzi",
    iata_code: "DZI",
    local_code: "LFL",
    coordinates: "-73.2337, 10.096608",
  },
  {
    ident: "SK-183",
    type: "small_airport",
    name: "San Juan Del C����sar Airport",
    elevation_ft: "3608",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CES",
    municipality: "San Juan Del C����sar",
    iata_code: "SJH",
    local_code: "SJH",
    coordinates: "-73.016667, 10.766667",
  },
  {
    ident: "SK-189",
    type: "small_airport",
    name: "Bahia Cupica Airport",
    elevation_ft: "200",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CHO",
    municipality: "Bah����a Cupica",
    iata_code: "BHF",
    local_code: "BHF",
    coordinates: "-77.3263, 6.55",
  },
  {
    ident: "SK-193",
    type: "small_airport",
    name: "Gilgal Airport",
    elevation_ft: "148",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CHO",
    municipality: "Villa Claret",
    iata_code: "GGL",
    local_code: "GGL",
    coordinates: "-77.083333, 8.333333",
  },
  {
    ident: "SK-197",
    type: "small_airport",
    name: "Unguia Airport",
    elevation_ft: "120",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CHO",
    municipality: "Arquia",
    iata_code: "UNC",
    local_code: "UNC",
    coordinates: "-77.083333, 8.033333",
  },
  {
    ident: "SK-198",
    type: "small_airport",
    name: "Ayapel Airport",
    elevation_ft: "120",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-COR",
    municipality: "Ayapel",
    iata_code: "AYA",
    local_code: "AYA",
    coordinates: "-75.15, 8.3",
  },
  {
    ident: "SK-209",
    type: "small_airport",
    name: "Barranco Minas Airport",
    elevation_ft: "430",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CUN",
    municipality: "Barranco Minas",
    gps_code: "SKBM",
    iata_code: "NBB",
    coordinates: "-69.8, 3.483333",
  },
  {
    ident: "SK-217",
    type: "small_airport",
    name: "Medina Airport",
    elevation_ft: "1315",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CUN",
    municipality: "Medina",
    iata_code: "MND",
    local_code: "MND",
    coordinates: "-73.283333, 4.516667",
  },
  {
    ident: "SK-223",
    type: "small_airport",
    name: "Macanal Airport",
    elevation_ft: "327",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-GUA",
    municipality: "Macanal",
    iata_code: "NAD",
    local_code: "NAD",
    coordinates: "-67.583333, 2.566667",
  },
  {
    ident: "SK-236",
    type: "small_airport",
    name: "Guacamayas Airport",
    elevation_ft: "1500",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-HUI",
    municipality: "Guacamayas",
    iata_code: "GCA",
    local_code: "GCA",
    coordinates: "-74.95, 2.283333",
  },
  {
    ident: "SK-245",
    type: "small_airport",
    name: "Santana Ramos Airport",
    elevation_ft: "6017",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-HUI",
    municipality: "Santana Ramos",
    iata_code: "SRO",
    local_code: "SRO",
    coordinates: "-75.25, 2.216667",
  },
  {
    ident: "SK-272",
    type: "small_airport",
    name: "Barranca De Upia Airport",
    elevation_ft: "670",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "Barranca De Upia",
    iata_code: "BAC",
    local_code: "BAC",
    coordinates: "-72.966667, 4.583333",
  },
  {
    ident: "SK-277",
    type: "small_airport",
    name: "Caquetania Airport",
    elevation_ft: "900",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "Caquetania",
    iata_code: "CQT",
    local_code: "CQT",
    coordinates: "-74.216667, 2.033333",
  },
  {
    ident: "SK-284",
    type: "small_airport",
    name: "El Recreo Airport",
    elevation_ft: "900",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "Ruperto Polania",
    iata_code: "ELJ",
    local_code: "ELJ",
    coordinates: "-74.133333, 2",
  },
  {
    ident: "SK-307",
    type: "small_airport",
    name: "El Refugio/La Macarena Airport",
    elevation_ft: "793",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "La Macarena",
    gps_code: "SKNA",
    iata_code: "LMC",
    local_code: "LMC",
    coordinates: "-73.7862, 2.1736",
  },
  {
    ident: "SK-348",
    type: "small_airport",
    name: "Solita Airport",
    elevation_ft: "652",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "Solita",
    iata_code: "SOH",
    local_code: "SOH",
    coordinates: "-71.75, 3.016667",
  },
  {
    ident: "SK-354",
    type: "small_airport",
    name: "Uribe Airport",
    elevation_ft: "2750",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "Uribe",
    iata_code: "URI",
    local_code: "URI",
    coordinates: "-74.4, 3.216667",
  },
  {
    ident: "SK-360",
    type: "small_airport",
    name: "El Charco Airport",
    elevation_ft: "25",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-NAR",
    municipality: "El Charco",
    gps_code: "SKEH",
    iata_code: "ECR",
    local_code: "ECR",
    coordinates: "-78.0941666667, 2.44944444444",
  },
  {
    ident: "SK-365",
    type: "small_airport",
    name: "Mosquera Airport",
    elevation_ft: "50",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-NAR",
    municipality: "Mosquera",
    iata_code: "MQR",
    local_code: "MQR",
    coordinates: "-78.3361, 2.64955",
  },
  {
    ident: "SK-367",
    type: "small_airport",
    name: "Santa B����rbara Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-NAR",
    municipality: "Iscuand����",
    iata_code: "ISD",
    local_code: "SBN",
    coordinates: "-77.981753, 2.445825",
  },
  {
    ident: "SK-392",
    type: "small_airport",
    name: "Zapatoca Airport",
    elevation_ft: "6377",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SAN",
    municipality: "Zapatoca",
    iata_code: "AZT",
    local_code: "AZT",
    coordinates: "-73.283333, 6.816667",
  },
  {
    ident: "SK-422",
    type: "small_airport",
    name: "Herrera Airport",
    elevation_ft: "5740",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-TOL",
    municipality: "Campi����a",
    iata_code: "HRR",
    local_code: "HRR",
    coordinates: "-75.85, 3.216667",
  },
  {
    ident: "SK-442",
    type: "small_airport",
    name: "Santa Ana Airport",
    elevation_ft: "2192",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-TOL",
    municipality: "Piedras",
    iata_code: "SQB",
    coordinates: "-75.021667, 4.408333",
  },
  {
    ident: "SK-462",
    type: "small_airport",
    name: "La Primavera Airport",
    elevation_ft: "3600",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAC",
    municipality: "Costa Rica",
    iata_code: "LPE",
    local_code: "LPE",
    coordinates: "-76.216667, 3.733333",
  },
  {
    ident: "SK-471",
    type: "small_airport",
    name: "Acaricuara Airport",
    elevation_ft: "587",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAU",
    municipality: "Acaricuara",
    iata_code: "ARF",
    local_code: "ARF",
    coordinates: "-70.133333, 0.533333",
  },
  {
    ident: "SK-481",
    type: "small_airport",
    name: "Monfort Airport",
    elevation_ft: "630",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAU",
    municipality: "Monfort",
    iata_code: "MFB",
    local_code: "MFB",
    coordinates: "-69.75, 0.633333",
  },
  {
    ident: "SK-482",
    type: "small_airport",
    name: "Morichal Airport",
    elevation_ft: "552",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAU",
    municipality: "Morichal",
    iata_code: "MHF",
    local_code: "MHF",
    coordinates: "-69.916667, 1.75",
  },
  {
    ident: "SK-511",
    type: "small_airport",
    name: "Casuarito Airport",
    elevation_ft: "245",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VID",
    municipality: "Casuarito",
    iata_code: "CSR",
    local_code: "CSR",
    coordinates: "-68.133333, 5.833333",
  },
  {
    ident: "SK-521",
    type: "small_airport",
    name: "La Primavera Airport",
    elevation_ft: "390",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VID",
    municipality: "La Primavera",
    gps_code: "SKIM",
    iata_code: "LPE",
    local_code: "PIM",
    coordinates: "-70.4212, 5.4776",
  },
  {
    ident: "SKAC",
    type: "small_airport",
    name: "Araracuara Airport",
    elevation_ft: "1250",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAQ",
    municipality: "Araracuara",
    gps_code: "SKAC",
    iata_code: "ACR",
    local_code: "ACR",
    coordinates: "-72.4083, -0.5833",
  },
  {
    ident: "SKAD",
    type: "small_airport",
    name: "Alcides Fern����ndez Airport",
    elevation_ft: "50",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CHO",
    municipality: "Acand����",
    gps_code: "SKAD",
    iata_code: "ACD",
    local_code: "ACD",
    coordinates: "-77.3, 8.51667",
  },
  {
    ident: "SKAM",
    type: "small_airport",
    name: "Amalfi Airport",
    elevation_ft: "5507",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Amalfi",
    gps_code: "SKAM",
    iata_code: "AFI",
    local_code: "AFI",
    coordinates: "-75.0667, 6.91667",
  },
  {
    ident: "SKAN",
    type: "closed",
    name: "Andes Airport",
    elevation_ft: "3900",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Andes",
    gps_code: "SKAN",
    iata_code: "ADN",
    coordinates: "-75.88038, 5.69764",
  },
  {
    ident: "SKAP",
    type: "medium_airport",
    name: "Gomez Nino Apiay Air Base",
    elevation_ft: "1207",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "Apiay",
    gps_code: "SKAP",
    iata_code: "API",
    local_code: "APY",
    coordinates: "-73.5627, 4.07607",
  },
  {
    ident: "SKAR",
    type: "medium_airport",
    name: "El Eden Airport",
    elevation_ft: "3990",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-QUI",
    municipality: "Armenia",
    gps_code: "SKAR",
    iata_code: "AXM",
    local_code: "AXM",
    coordinates: "-75.7664, 4.45278",
  },
  {
    ident: "SKAS",
    type: "medium_airport",
    name: "Tres De Mayo Airport",
    elevation_ft: "815",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-PUT",
    municipality: "Puerto As����s",
    gps_code: "SKAS",
    iata_code: "PUU",
    local_code: "PUU",
    coordinates: "-76.5008, 0.505228",
  },
  {
    ident: "SKBC",
    type: "medium_airport",
    name: "Las Flores Airport",
    elevation_ft: "111",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MAG",
    municipality: "El Banco",
    gps_code: "SKBC",
    iata_code: "ELB",
    local_code: "ELB",
    coordinates: "-73.9749, 9.04554",
  },
  {
    ident: "SKBG",
    type: "medium_airport",
    name: "Palonegro Airport",
    elevation_ft: "3897",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SAN",
    municipality: "Bucaramanga",
    gps_code: "SKBG",
    iata_code: "BGA",
    local_code: "BGA",
    coordinates: "-73.1848, 7.1265",
  },
  {
    ident: "SKBO",
    type: "large_airport",
    name: "El Dorado International Airport",
    elevation_ft: "8361",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CUN",
    municipality: "Bogota",
    gps_code: "SKBO",
    iata_code: "BOG",
    local_code: "BOG",
    coordinates: "-74.1469, 4.70159",
  },
  {
    ident: "SKBQ",
    type: "medium_airport",
    name: "Ernesto Cortissoz International Airport",
    elevation_ft: "98",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ATL",
    municipality: "Barranquilla",
    gps_code: "SKBQ",
    iata_code: "BAQ",
    local_code: "BAQ",
    coordinates: "-74.7808, 10.8896",
  },
  {
    ident: "SKBS",
    type: "medium_airport",
    name: "Jos���� Celestino Mutis Airport",
    elevation_ft: "80",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CHO",
    municipality: "Bah����a Solano",
    gps_code: "SKBS",
    iata_code: "BSC",
    local_code: "BSC",
    coordinates: "-77.3947, 6.20292",
  },
  {
    ident: "SKBU",
    type: "medium_airport",
    name: "Gerardo Tobar L����pez Airport",
    elevation_ft: "48",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAC",
    municipality: "Buenaventura",
    gps_code: "SKBU",
    iata_code: "BUN",
    local_code: "BUN",
    coordinates: "-76.9898, 3.81963",
  },
  {
    ident: "SKCA",
    type: "small_airport",
    name: "Capurgan���� Airport",
    elevation_ft: "49",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CHO",
    municipality: "Capurgan����",
    gps_code: "SKCA",
    iata_code: "CPB",
    local_code: "CPB",
    coordinates: "-77.35, 8.63333",
  },
  {
    ident: "SKCC",
    type: "medium_airport",
    name: "Camilo Daza International Airport",
    elevation_ft: "1096",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-NSA",
    municipality: "C����cuta",
    gps_code: "SKCC",
    iata_code: "CUC",
    local_code: "CUC",
    coordinates: "-72.5115, 7.92757",
  },
  {
    ident: "SKCD",
    type: "small_airport",
    name: "Mandinga Airport",
    elevation_ft: "213",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CHO",
    municipality: "Condoto",
    gps_code: "SKCD",
    iata_code: "COG",
    local_code: "COG",
    coordinates: "-76.7, 5.08333",
  },
  {
    ident: "SKCG",
    type: "medium_airport",
    name: "Rafael Nu����ez International Airport",
    elevation_ft: "4",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-BOL",
    municipality: "Cartagena",
    gps_code: "SKCG",
    iata_code: "CTG",
    local_code: "CTG",
    coordinates: "-75.513, 10.4424",
  },
  {
    ident: "SKCI",
    type: "small_airport",
    name: "Carimagua Airport",
    elevation_ft: "700",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "Puerto L����pez",
    gps_code: "SKCI",
    iata_code: "CCO",
    local_code: "CCO",
    coordinates: "-71.3364, 4.56417",
  },
  {
    ident: "SKCL",
    type: "medium_airport",
    name: "Alfonso Bonilla Aragon International Airport",
    elevation_ft: "3162",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAC",
    municipality: "Cali",
    gps_code: "SKCL",
    iata_code: "CLO",
    local_code: "CLO",
    coordinates: "-76.3816, 3.54322",
  },
  {
    ident: "SKCM",
    type: "small_airport",
    name: "Cimitarra Airport",
    elevation_ft: "740",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SAN",
    municipality: "Cimitarra",
    gps_code: "SKCM",
    iata_code: "CIM",
    local_code: "CIM",
    coordinates: "-73.967, 6.367",
  },
  {
    ident: "SKCN",
    type: "small_airport",
    name: "Cravo Norte Airport",
    elevation_ft: "341",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ARA",
    municipality: "Cravo Norte",
    gps_code: "SKCN",
    iata_code: "RAV",
    local_code: "RAV",
    coordinates: "-70.2107, 6.31684",
  },
  {
    ident: "SKCO",
    type: "medium_airport",
    name: "La Florida Airport",
    elevation_ft: "8",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-NAR",
    municipality: "Tumaco",
    gps_code: "SKCO",
    iata_code: "TCO",
    local_code: "TCO",
    coordinates: "-78.7492, 1.81442",
  },
  {
    ident: "SKCR",
    type: "small_airport",
    name: "Carur���� Airport",
    elevation_ft: "820",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAU",
    municipality: "Carur����",
    gps_code: "SKCR",
    iata_code: "CUO",
    local_code: "CRU",
    coordinates: "-71.2961, 1.0136",
  },
  {
    ident: "SKCU",
    type: "medium_airport",
    name: "Juan H White Airport",
    elevation_ft: "174",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Caucasia",
    gps_code: "SKCU",
    iata_code: "CAQ",
    local_code: "CAQ",
    coordinates: "-75.1985, 7.96847",
  },
  {
    ident: "SKCV",
    type: "medium_airport",
    name: "Cove����as Airport",
    elevation_ft: "31",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SUC",
    municipality: "Cove����as",
    gps_code: "SKCV",
    iata_code: "CVE",
    local_code: "CVE",
    coordinates: "-75.6913, 9.40092",
  },
  {
    ident: "SKCZ",
    type: "medium_airport",
    name: "Las Brujas Airport",
    elevation_ft: "528",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SUC",
    municipality: "Corozal",
    gps_code: "SKCZ",
    iata_code: "CZU",
    local_code: "CZU",
    coordinates: "-75.2856, 9.33274",
  },
  {
    ident: "SKEB",
    type: "medium_airport",
    name: "El Bagre Airport",
    elevation_ft: "180",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "El Bagre",
    gps_code: "SKEB",
    iata_code: "EBG",
    local_code: "EBG",
    coordinates: "-74.8089, 7.59647",
  },
  {
    ident: "SKEJ",
    type: "medium_airport",
    name: "Yarigu����es Airport",
    elevation_ft: "412",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SAN",
    municipality: "Barrancabermeja",
    gps_code: "SKEJ",
    iata_code: "EJA",
    local_code: "EJA",
    coordinates: "-73.8068, 7.02433",
  },
  {
    ident: "SKFL",
    type: "medium_airport",
    name: "Gustavo Artunduaga Paredes Airport",
    elevation_ft: "803",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAQ",
    municipality: "Florencia",
    gps_code: "SKFL",
    iata_code: "FLA",
    local_code: "FLA",
    coordinates: "-75.5644, 1.58919",
  },
  {
    ident: "SKFU",
    type: "small_airport",
    name: "Fundaci����n Airport",
    elevation_ft: "150",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MAG",
    municipality: "Fundaci����n",
    gps_code: "SKFU",
    iata_code: "FDA",
    local_code: "FDA",
    coordinates: "-74.2, 10.5333",
  },
  {
    ident: "SKGA",
    type: "small_airport",
    name: "La Gaviota Airport",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VID",
    gps_code: "SKGA",
    iata_code: "LGT",
    coordinates: "-70.925, 4.549722",
  },
  {
    ident: "SKGI",
    type: "medium_airport",
    name: "Santiago Vila Airport",
    elevation_ft: "900",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CUN",
    municipality: "Girardot",
    gps_code: "SKGI",
    iata_code: "GIR",
    local_code: "GIR",
    coordinates: "-74.7967, 4.27624",
  },
  {
    ident: "SKGO",
    type: "medium_airport",
    name: "Santa Ana Airport",
    elevation_ft: "2979",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAC",
    municipality: "Cartago",
    gps_code: "SKGO",
    iata_code: "CRC",
    local_code: "CRC",
    coordinates: "-75.9557, 4.75818",
  },
  {
    ident: "SKGP",
    type: "medium_airport",
    name: "Juan Casiano Airport",
    elevation_ft: "164",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAU",
    municipality: "Guapi",
    gps_code: "SKGP",
    iata_code: "GPI",
    local_code: "GPI",
    coordinates: "-77.8986, 2.57013",
  },
  {
    ident: "SKHA",
    type: "small_airport",
    name: "Chaparral Airport",
    elevation_ft: "2730",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-TOL",
    municipality: "Chaparral",
    gps_code: "SKHA",
    iata_code: "CPL",
    local_code: "CPL",
    coordinates: "-75.467, 3.717",
  },
  {
    ident: "SKHC",
    type: "small_airport",
    name: "Hato Corozal Airport",
    elevation_ft: "820",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Hato Corozal",
    gps_code: "SKHC",
    iata_code: "HTZ",
    local_code: "HTZ",
    coordinates: "-71.75, 6.15",
  },
  {
    ident: "SKIB",
    type: "medium_airport",
    name: "Perales Airport",
    elevation_ft: "2999",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-TOL",
    municipality: "Ibagu����",
    gps_code: "SKIB",
    iata_code: "IBE",
    local_code: "IBE",
    coordinates: "-75.1333, 4.42161",
  },
  {
    ident: "SKIG",
    type: "small_airport",
    name: "Chigorod���� Airport",
    elevation_ft: "110",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Chigorod����",
    gps_code: "SKIG",
    iata_code: "IGO",
    local_code: "IGO",
    coordinates: "-76.6865, 7.68038",
  },
  {
    ident: "SKIP",
    type: "medium_airport",
    name: "San Luis Airport",
    elevation_ft: "9765",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-NAR",
    municipality: "Ipiales",
    gps_code: "SKIP",
    iata_code: "IPI",
    local_code: "IPI",
    coordinates: "-77.6718, 0.861925",
  },
  {
    ident: "SKJ",
    type: "closed",
    name: "Sitkinak Airport",
    elevation_ft: "65",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Sitkinak Island",
    iata_code: "SKJ",
    local_code: "SKJ",
    coordinates: "-154.1412, 56.5378",
  },
  {
    ident: "SKLC",
    type: "medium_airport",
    name: "Antonio Roldan Betancourt Airport",
    elevation_ft: "46",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Carepa",
    gps_code: "SKLC",
    iata_code: "APO",
    local_code: "APO",
    coordinates: "-76.7164, 7.81196",
  },
  {
    ident: "SKLG",
    type: "small_airport",
    name: "Caucaya Airport",
    elevation_ft: "573",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-PUT",
    municipality: "Puerto Legu����zamo",
    gps_code: "SKLG",
    iata_code: "LQM",
    local_code: "LQM",
    coordinates: "-74.7708, -0.182278",
  },
  {
    ident: "SKLM",
    type: "medium_airport",
    name: "Jorge Isaac Airport",
    elevation_ft: "281",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-LAG",
    municipality: "La Mina-Maicao",
    gps_code: "SKLM",
    iata_code: "MCJ",
    local_code: "LMA",
    coordinates: "-72.4901, 11.2325",
  },
  {
    ident: "SKLP",
    type: "small_airport",
    name: "La Pedrera Airport",
    elevation_ft: "590",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-AMA",
    municipality: "La Pedrera",
    gps_code: "SKLP",
    iata_code: "LPD",
    local_code: "LPD",
    coordinates: "-69.5797, -1.32861",
  },
  {
    ident: "SKLT",
    type: "medium_airport",
    name: "Alfredo V����squez Cobo International Airport",
    elevation_ft: "277",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-AMA",
    municipality: "Leticia",
    gps_code: "SKLT",
    iata_code: "LET",
    local_code: "LET",
    coordinates: "-69.9432, -4.19355",
  },
  {
    ident: "SKMD",
    type: "medium_airport",
    name: "Enrique Olaya Herrera Airport",
    elevation_ft: "4949",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Medell����n",
    gps_code: "SKMD",
    iata_code: "EOH",
    local_code: "EOH",
    coordinates: "-75.590582, 6.220549",
  },
  {
    ident: "SKMF",
    type: "small_airport",
    name: "Miraflores Airport",
    elevation_ft: "730",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-GUV",
    municipality: "Miraflores",
    gps_code: "SKMF",
    iata_code: "MFS",
    local_code: "MFS",
    coordinates: "-71.9444, 1.35",
  },
  {
    ident: "SKMG",
    type: "medium_airport",
    name: "Baracoa Airport",
    elevation_ft: "178",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-BOL",
    municipality: "Magangu����",
    gps_code: "SKMG",
    iata_code: "MGN",
    local_code: "MGN",
    coordinates: "-74.8461, 9.28474",
  },
  {
    ident: "SKML",
    type: "small_airport",
    name: "Montelibano Airport",
    elevation_ft: "160",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-COR",
    municipality: "Montel����bano",
    gps_code: "SKML",
    iata_code: "MTB",
    local_code: "MTB",
    coordinates: "-75.4325, 7.97174",
  },
  {
    ident: "SKMR",
    type: "medium_airport",
    name: "Los Garzones Airport",
    elevation_ft: "36",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-COR",
    municipality: "Monter����a",
    gps_code: "SKMR",
    iata_code: "MTR",
    local_code: "MTR",
    coordinates: "-75.8258, 8.82374",
  },
  {
    ident: "SKMU",
    type: "medium_airport",
    name: "Fabio Alberto Leon Bentley Airport",
    elevation_ft: "680",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAU",
    municipality: "Mit����",
    gps_code: "SKMU",
    iata_code: "MVP",
    local_code: "MVP",
    coordinates: "-70.2339, 1.25366",
  },
  {
    ident: "SKMZ",
    type: "medium_airport",
    name: "La Nubia Airport",
    elevation_ft: "6871",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAL",
    municipality: "Manizales",
    gps_code: "SKMZ",
    iata_code: "MZL",
    local_code: "MZL",
    coordinates: "-75.4647, 5.0296",
  },
  {
    ident: "SKNC",
    type: "small_airport",
    name: "Necocli Airport",
    elevation_ft: "19",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Necocli",
    gps_code: "SKNC",
    iata_code: "NCI",
    local_code: "NCI",
    coordinates: "-76.7833, 8.45",
  },
  {
    ident: "SKNQ",
    type: "small_airport",
    name: "Reyes Murillo Airport",
    elevation_ft: "12",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CHO",
    municipality: "Nuqu����",
    gps_code: "SKNQ",
    iata_code: "NQU",
    local_code: "NQU",
    coordinates: "-77.2806, 5.6964",
  },
  {
    ident: "SKNV",
    type: "medium_airport",
    name: "Benito Salas Airport",
    elevation_ft: "1464",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-HUI",
    municipality: "Neiva",
    gps_code: "SKNV",
    iata_code: "NVA",
    local_code: "NVA",
    coordinates: "-75.294, 2.95015",
  },
  {
    ident: "SKOC",
    type: "medium_airport",
    name: "Aguas Claras Airport",
    elevation_ft: "3850",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-NSA",
    municipality: "Oca����a",
    gps_code: "SKOC",
    iata_code: "OCV",
    local_code: "OCV",
    coordinates: "-73.3583, 8.31506",
  },
  {
    ident: "SKOE",
    type: "small_airport",
    name: "Orocue Airport",
    elevation_ft: "434",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Orocue",
    gps_code: "SKOE",
    iata_code: "ORC",
    local_code: "ORC",
    coordinates: "-71.3564, 4.79222",
  },
  {
    ident: "SKOT",
    type: "closed",
    name: "Otu Airport",
    elevation_ft: "2060",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Remedios",
    gps_code: "SKOT",
    iata_code: "OTU",
    coordinates: "-74.7155, 7.01037",
  },
  {
    ident: "SKPC",
    type: "medium_airport",
    name: "German Olano Airport",
    elevation_ft: "177",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VID",
    municipality: "Puerto Carre����o",
    gps_code: "SKPC",
    iata_code: "PCR",
    local_code: "PCR",
    coordinates: "-67.4932, 6.18472",
  },
  {
    ident: "SKPD",
    type: "medium_airport",
    name: "Obando Airport",
    elevation_ft: "460",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-GUA",
    municipality: "Puerto In����rida",
    gps_code: "SKPD",
    iata_code: "PDA",
    local_code: "PDA",
    coordinates: "-67.9062, 3.85353",
  },
  {
    ident: "SKPE",
    type: "medium_airport",
    name: "Mateca����a International Airport",
    elevation_ft: "4416",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-RIS",
    municipality: "Pereira",
    gps_code: "SKPE",
    iata_code: "PEI",
    local_code: "PEI",
    coordinates: "-75.7395, 4.81267",
  },
  {
    ident: "SKPI",
    type: "medium_airport",
    name: "Pitalito Airport",
    elevation_ft: "4212",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-HUI",
    municipality: "Pitalito",
    gps_code: "SKPI",
    iata_code: "PTX",
    coordinates: "-76.0857, 1.85777",
  },
  {
    ident: "SKPL",
    type: "small_airport",
    name: "Plato Airport",
    elevation_ft: "69",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MAG",
    municipality: "Plato",
    gps_code: "SKPL",
    iata_code: "PLT",
    local_code: "PLT",
    coordinates: "-74.7833, 9.8",
  },
  {
    ident: "SKPN",
    type: "small_airport",
    name: "Puerto Nare Airport",
    elevation_ft: "476",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Armenia",
    gps_code: "SKPN",
    iata_code: "NAR",
    local_code: "NAR",
    coordinates: "-74.5906, 6.21002",
  },
  {
    ident: "SKPP",
    type: "medium_airport",
    name: "Guillermo Le����n Valencia Airport",
    elevation_ft: "5687",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAU",
    municipality: "Popay����n",
    gps_code: "SKPP",
    iata_code: "PPN",
    local_code: "PPN",
    coordinates: "-76.6093, 2.4544",
  },
  {
    ident: "SKPQ",
    type: "medium_airport",
    name: "German Olano Air Base",
    elevation_ft: "566",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CUN",
    municipality: "La Dorada",
    gps_code: "SKPQ",
    iata_code: "PAL",
    local_code: "PQE",
    coordinates: "-74.6574, 5.48361",
  },
  {
    ident: "SKPR",
    type: "small_airport",
    name: "Puerto Berrio Airport",
    elevation_ft: "445",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Puerto Berrio",
    gps_code: "SKPR",
    iata_code: "PBE",
    local_code: "PBE",
    coordinates: "-74.4105, 6.46034",
  },
  {
    ident: "SKPS",
    type: "medium_airport",
    name: "Antonio Narino Airport",
    elevation_ft: "5951",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-NAR",
    municipality: "Pasto",
    gps_code: "SKPS",
    iata_code: "PSO",
    local_code: "PSO",
    coordinates: "-77.2915, 1.39625",
  },
  {
    ident: "SKPV",
    type: "medium_airport",
    name: "El Embrujo Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SAP",
    municipality: "Providencia",
    gps_code: "SKPV",
    iata_code: "PVA",
    local_code: "PVA",
    coordinates: "-81.3583, 13.3569",
  },
  {
    ident: "SKPZ",
    type: "medium_airport",
    name: "Paz De Ariporo Airport",
    elevation_ft: "900",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Paz De Ariporo",
    gps_code: "SKPZ",
    iata_code: "PZA",
    local_code: "PZA",
    coordinates: "-71.8866, 5.87615",
  },
  {
    ident: "SKQU",
    type: "medium_airport",
    name: "Mariquita Airport",
    elevation_ft: "1531",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-TOL",
    municipality: "Mariquita",
    gps_code: "SKQU",
    iata_code: "MQU",
    local_code: "MQU",
    coordinates: "-74.8836, 5.21256",
  },
  {
    ident: "SKRG",
    type: "medium_airport",
    name: "Jose Maria C����rdova International Airport",
    elevation_ft: "6955",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Medell����n",
    gps_code: "SKRG",
    iata_code: "MDE",
    local_code: "MDE",
    coordinates: "-75.4231, 6.16454",
  },
  {
    ident: "SKRH",
    type: "medium_airport",
    name: "Almirante Padilla Airport",
    elevation_ft: "43",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-LAG",
    municipality: "Riohacha",
    gps_code: "SKRH",
    iata_code: "RCH",
    local_code: "RCH",
    coordinates: "-72.926, 11.5262",
  },
  {
    ident: "SKSA",
    type: "small_airport",
    name: "Los Colonizadores Airport",
    elevation_ft: "680",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ARA",
    municipality: "Saravena",
    gps_code: "SKSA",
    iata_code: "RVE",
    local_code: "SRV",
    coordinates: "-71.857179, 6.951868",
  },
  {
    ident: "SKSJ",
    type: "medium_airport",
    name: "Jorge E. Gonzalez Torres Airport",
    elevation_ft: "605",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-GUV",
    municipality: "San Jos���� Del Guaviare",
    gps_code: "SKSJ",
    iata_code: "SJE",
    local_code: "SJE",
    coordinates: "-72.6394, 2.57969",
  },
  {
    ident: "SKSL",
    type: "small_airport",
    name: "Santa Rosalia Airport",
    elevation_ft: "400",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VID",
    municipality: "Santa Rosalia",
    gps_code: "SKSL",
    iata_code: "SSL",
    coordinates: "-70.8682, 5.1309",
  },
  {
    ident: "SKSM",
    type: "medium_airport",
    name: "Sim����n Bol����var International Airport",
    elevation_ft: "22",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MAG",
    municipality: "Santa Marta",
    gps_code: "SKSM",
    iata_code: "SMR",
    local_code: "SMR",
    coordinates: "-74.2306, 11.1196",
  },
  {
    ident: "SKSO",
    type: "small_airport",
    name: "Alberto Lleras Camargo Airport",
    elevation_ft: "8155",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-BOY",
    municipality: "Sogamoso",
    gps_code: "SKSO",
    iata_code: "SOX",
    local_code: "SOX",
    coordinates: "-72.9703, 5.67732",
  },
  {
    ident: "SKSP",
    type: "medium_airport",
    name: "Gustavo Rojas Pinilla International Airport",
    elevation_ft: "19",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SAP",
    municipality: "San Andr����s",
    gps_code: "SKSP",
    iata_code: "ADZ",
    local_code: "ADZ",
    coordinates: "-81.7112, 12.5836",
  },
  {
    ident: "SKSR",
    type: "small_airport",
    name: "San Marcos Airport",
    elevation_ft: "140",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SUC",
    municipality: "San Marcos",
    gps_code: "SKSR",
    iata_code: "SRS",
    coordinates: "-75.156, 8.69",
  },
  {
    ident: "SKSV",
    type: "medium_airport",
    name: "Eduardo Falla Solano Airport",
    elevation_ft: "920",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAQ",
    municipality: "San Vicente Del Cagu����n",
    gps_code: "SKSV",
    iata_code: "SVI",
    local_code: "SVI",
    coordinates: "-74.7663, 2.15217",
  },
  {
    ident: "SKTB",
    type: "small_airport",
    name: "Tib���� Airport",
    elevation_ft: "194",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-NSA",
    municipality: "Tib����",
    gps_code: "SKTB",
    iata_code: "TIB",
    local_code: "TBU",
    coordinates: "-72.7304, 8.63152",
  },
  {
    ident: "SKTD",
    type: "small_airport",
    name: "Trinidad Airport",
    elevation_ft: "649",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "Trinidad",
    gps_code: "SKTD",
    iata_code: "TDA",
    local_code: "TDA",
    coordinates: "-71.6625, 5.43278",
  },
  {
    ident: "SKTL",
    type: "small_airport",
    name: "Golfo de Morrosquillo Airport",
    elevation_ft: "16",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-SUC",
    municipality: "Tol����",
    gps_code: "SKTL",
    iata_code: "TLU",
    local_code: "TLU",
    coordinates: "-75.5854, 9.50945",
  },
  {
    ident: "SKTM",
    type: "medium_airport",
    name: "Gustavo Vargas Airport",
    elevation_ft: "1050",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ARA",
    municipality: "Tame",
    gps_code: "SKTM",
    iata_code: "TME",
    local_code: "TME",
    coordinates: "-71.7603, 6.45108",
  },
  {
    ident: "SKTQ",
    type: "medium_airport",
    name: "Tres Esquinas Air Base",
    elevation_ft: "585",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAQ",
    municipality: "Tres Esquinas",
    gps_code: "SKTQ",
    iata_code: "TQS",
    local_code: "TQS",
    coordinates: "-75.234, 0.7459",
  },
  {
    ident: "SKTU",
    type: "small_airport",
    name: "Gonzalo Mej����a Airport",
    elevation_ft: "6",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Turbo",
    gps_code: "SKTU",
    iata_code: "TRB",
    local_code: "TRB",
    coordinates: "-76.7415, 8.07453",
  },
  {
    ident: "SKUC",
    type: "medium_airport",
    name: "Santiago Perez Airport",
    elevation_ft: "420",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ARA",
    municipality: "Arauca",
    gps_code: "SKUC",
    iata_code: "AUC",
    local_code: "AUC",
    coordinates: "-70.7369, 7.06888",
  },
  {
    ident: "SKUI",
    type: "medium_airport",
    name: "El Cara����o Airport",
    elevation_ft: "204",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CHO",
    municipality: "Quibd����",
    gps_code: "SKUI",
    iata_code: "UIB",
    local_code: "UIB",
    coordinates: "-76.6412, 5.69076",
  },
  {
    ident: "SKUL",
    type: "medium_airport",
    name: "Heriberto G����l Mart����nez Airport",
    elevation_ft: "3132",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-VAC",
    municipality: "Tulu����",
    gps_code: "SKUL",
    iata_code: "ULQ",
    local_code: "ULQ",
    coordinates: "-76.2351, 4.08836",
  },
  {
    ident: "SKUR",
    type: "small_airport",
    name: "Urrao Airport",
    elevation_ft: "6090",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-ANT",
    municipality: "Urrao",
    gps_code: "SKUR",
    iata_code: "URR",
    local_code: "URR",
    coordinates: "-76.1425, 6.32883",
  },
  {
    ident: "SKVG",
    type: "small_airport",
    name: "Villa Garz����n Airport",
    elevation_ft: "1248",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-PUT",
    municipality: "Villa Garz����n",
    gps_code: "SKVG",
    iata_code: "VGZ",
    local_code: "VGZ",
    coordinates: "-76.6056, 0.978767",
  },
  {
    ident: "SKVL",
    type: "small_airport",
    name: "Vel����squez Airport",
    elevation_ft: "566",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-BOY",
    municipality: "Puerto Boyac����",
    gps_code: "SKVL",
    iata_code: "PYA",
    coordinates: "-74.457, 5.93904",
  },
  {
    ident: "SKVP",
    type: "medium_airport",
    name: "Alfonso L����pez Pumarejo Airport",
    elevation_ft: "483",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CES",
    municipality: "Valledupar",
    gps_code: "SKVP",
    iata_code: "VUP",
    local_code: "VUP",
    coordinates: "-73.2495, 10.435",
  },
  {
    ident: "SKVV",
    type: "medium_airport",
    name: "Vanguardia Airport",
    elevation_ft: "1394",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-MET",
    municipality: "Villavicencio",
    gps_code: "SKVV",
    iata_code: "VVC",
    local_code: "VVC",
    coordinates: "-73.6138, 4.16787",
  },
  {
    ident: "SKYA",
    type: "small_airport",
    name: "Yaguara Airport",
    elevation_ft: "812",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAQ",
    municipality: "San Vicente Del Cagu����n",
    gps_code: "SKYA",
    iata_code: "AYG",
    local_code: "AYG",
    coordinates: "-73.9333, 1.54417",
  },
  {
    ident: "SKYP",
    type: "medium_airport",
    name: "El Yopal Airport",
    elevation_ft: "1028",
    continent: "SA",
    iso_country: "CO",
    iso_region: "CO-CAS",
    municipality: "El Yopal",
    gps_code: "SKYP",
    iata_code: "EYP",
    local_code: "EYP",
    coordinates: "-72.384, 5.31911",
  },
  {
    ident: "SLAG",
    type: "small_airport",
    name: "Monteagudo Airport",
    elevation_ft: "3674",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-H",
    municipality: "El Ba����ado",
    gps_code: "SLAG",
    iata_code: "MHW",
    coordinates: "-63.96099853515625, -19.82699966430664",
  },
  {
    ident: "SLAL",
    type: "medium_airport",
    name: "Alcantar���� Airport",
    elevation_ft: "10184",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-H",
    municipality: "Yamparaez",
    gps_code: "SLAL",
    iata_code: "SRE",
    coordinates: "-65.149611, -19.246835",
  },
  {
    ident: "SLAP",
    type: "small_airport",
    name: "Apolo Airport",
    elevation_ft: "4639",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-L",
    municipality: "Apolo",
    gps_code: "SLAP",
    iata_code: "APB",
    coordinates: "-68.41190338134766, -14.735600471496582",
  },
  {
    ident: "SLAS",
    type: "small_airport",
    name: "Ascenci����n De Guarayos Airport",
    elevation_ft: "807",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "Ascensi����n de Guarayos",
    gps_code: "SLAS",
    iata_code: "ASC",
    coordinates: "-63.156700134277344, -15.930299758911133",
  },
  {
    ident: "SLBJ",
    type: "medium_airport",
    name: "Bermejo Airport",
    elevation_ft: "1249",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-T",
    municipality: "Bermejo",
    gps_code: "SLBJ",
    iata_code: "BJO",
    coordinates: "-64.31289672850001, -22.7733001709",
  },
  {
    ident: "SLCA",
    type: "small_airport",
    name: "Camiri Airport",
    elevation_ft: "2614",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "Camiri",
    gps_code: "SLCA",
    iata_code: "CAM",
    coordinates: "-63.527801513671875, -20.006399154663086",
  },
  {
    ident: "SLCB",
    type: "medium_airport",
    name: "Jorge Wilsterman International Airport",
    elevation_ft: "8360",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-C",
    municipality: "Cochabamba",
    gps_code: "SLCB",
    iata_code: "CBB",
    coordinates: "-66.1771011352539, -17.421100616455078",
  },
  {
    ident: "SLCH",
    type: "small_airport",
    name: "Chimore Airport",
    elevation_ft: "875",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-C",
    municipality: "Chimore",
    gps_code: "SLCH",
    iata_code: "CCA",
    coordinates: "-65.145568, -16.976834",
  },
  {
    ident: "SLCO",
    type: "medium_airport",
    name: "Capit����n An����bal Arab Airport",
    elevation_ft: "889",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-N",
    municipality: "Cobija",
    gps_code: "SLCO",
    iata_code: "CIJ",
    coordinates: "-68.7829971313, -11.040399551400002",
  },
  {
    ident: "SLCP",
    type: "small_airport",
    name: "Concepci����n Airport",
    elevation_ft: "1620",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "Concepci����n",
    gps_code: "SLCP",
    iata_code: "CEP",
    coordinates: "-62.02859878540039, -16.1382999420166",
  },
  {
    ident: "SLET",
    type: "medium_airport",
    name: "El Trompillo Airport",
    elevation_ft: "1371",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "Santa Cruz",
    gps_code: "SLET",
    iata_code: "SRZ",
    coordinates: "-63.1715011597, -17.8115997314",
  },
  {
    ident: "SLGY",
    type: "small_airport",
    name: "Capit����n de Av. Emilio Beltr����n Airport",
    elevation_ft: "557",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Guayaramer����n",
    gps_code: "SLGY",
    iata_code: "GYA",
    coordinates: "-65.3455963135, -10.820599556",
  },
  {
    ident: "SLHJ",
    type: "small_airport",
    name: "Huacaraje Airport",
    elevation_ft: "846",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Itenes",
    gps_code: "SLHJ",
    iata_code: "BVK",
    coordinates: "-63.747898101800004, -13.5500001907",
  },
  {
    ident: "SLJ",
    type: "small_airport",
    name: "Solomon Airport",
    elevation_ft: "2030",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Karijini National Park",
    gps_code: "YSOL",
    iata_code: "SLJ",
    coordinates: "117.7627, -22.2554",
  },
  {
    ident: "SLJE",
    type: "small_airport",
    name: "San Jos���� De Chiquitos Airport",
    elevation_ft: "974",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "San Jos���� de Chiquitos",
    gps_code: "SLJE",
    iata_code: "SJS",
    coordinates: "-60.743099212646484, -17.830799102783203",
  },
  {
    ident: "SLJO",
    type: "small_airport",
    name: "San Joaqu����n Airport",
    elevation_ft: "456",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "San Joaqu����n",
    gps_code: "SLJO",
    iata_code: "SJB",
    coordinates: "-64.661697, -13.0528",
  },
  {
    ident: "SLJV",
    type: "small_airport",
    name: "San Javier Airport",
    elevation_ft: "1702",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "San Javier",
    gps_code: "SLJV",
    iata_code: "SJV",
    coordinates: "-62.470298767089844, -16.27079963684082",
  },
  {
    ident: "SLLP",
    type: "medium_airport",
    name: "El Alto International Airport",
    elevation_ft: "13355",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-L",
    municipality: "La Paz / El Alto",
    gps_code: "SLLP",
    iata_code: "LPB",
    coordinates: "-68.19229888916016, -16.5132999420166",
  },
  {
    ident: "SLMG",
    type: "small_airport",
    name: "Magdalena Airport",
    elevation_ft: "462",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Magdalena",
    gps_code: "SLMG",
    iata_code: "MGD",
    coordinates: "-64.0607643127, -13.2607483767",
  },
  {
    ident: "SLOR",
    type: "medium_airport",
    name: "Juan Mendoza Airport",
    elevation_ft: "12152",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-O",
    municipality: "Oruro",
    gps_code: "SLOR",
    iata_code: "ORU",
    coordinates: "-67.0762023926, -17.962600708",
  },
  {
    ident: "SLPO",
    type: "medium_airport",
    name: "Capitan Nicolas Rojas Airport",
    elevation_ft: "12922",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-P",
    municipality: "Potos����",
    gps_code: "SLPO",
    iata_code: "POI",
    coordinates: "-65.723734, -19.543331",
  },
  {
    ident: "SLPR",
    type: "small_airport",
    name: "Puerto Rico Airport",
    elevation_ft: "597",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-N",
    municipality: "Puerto Rico/Manuripi",
    gps_code: "SLPR",
    iata_code: "PUR",
    coordinates: "-67.551155, -11.107663",
  },
  {
    ident: "SLPS",
    type: "medium_airport",
    name: "Capit����n Av. Salvador Ogaya G. airport",
    elevation_ft: "440",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "Puerto Su����rez",
    gps_code: "SLPS",
    iata_code: "PSZ",
    coordinates: "-57.820599, -18.975301",
  },
  {
    ident: "SLRA",
    type: "small_airport",
    name: "San Ram����n Airport",
    elevation_ft: "698",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "San Ram����n / Mamor����",
    gps_code: "SLRA",
    iata_code: "SRD",
    coordinates: "-64.603897, -13.2639",
  },
  {
    ident: "SLRB",
    type: "small_airport",
    name: "Robor���� Airport",
    elevation_ft: "905",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "Robor����",
    gps_code: "SLRB",
    iata_code: "RBO",
    coordinates: "-59.76499938964844, -18.329200744628906",
  },
  {
    ident: "SLRI",
    type: "small_airport",
    name: "Capit����n Av. Selin Zeitun Lopez Airport",
    elevation_ft: "462",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Riberalta",
    gps_code: "SLRI",
    iata_code: "RIB",
    coordinates: "-66, -11",
  },
  {
    ident: "SLRY",
    type: "small_airport",
    name: "Reyes Airport",
    elevation_ft: "935",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Reyes",
    gps_code: "SLRY",
    iata_code: "REY",
    coordinates: "-67.353401, -14.3044",
  },
  {
    ident: "SLSA",
    type: "medium_airport",
    name: "Santa Ana Del Yacuma Airport",
    elevation_ft: "472",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Santa Ana del Yacuma",
    gps_code: "SLSA",
    iata_code: "SBL",
    coordinates: "-65.4352035522, -13.762200355500001",
  },
  {
    ident: "SLSB",
    type: "small_airport",
    name: "Capit����n Av. German Quiroga G. Airport",
    elevation_ft: "633",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "San Borja",
    gps_code: "SLSB",
    iata_code: "SRJ",
    coordinates: "-66.73750305175781, -14.859199523925781",
  },
  {
    ident: "SLSI",
    type: "small_airport",
    name: "Capit����n Av. Juan Cochamanidis S. Airport",
    elevation_ft: "1354",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "San Ignacio de Velasco",
    gps_code: "SLSI",
    iata_code: "SNG",
    coordinates: "-60.962799072265625, -16.38360023498535",
  },
  {
    ident: "SLSM",
    type: "small_airport",
    name: "San Ignacio de Moxos Airport",
    elevation_ft: "545",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "San Ignacio de Moxos",
    gps_code: "SLSM",
    iata_code: "SNM",
    coordinates: "-65.6338, -14.9658",
  },
  {
    ident: "SLSR",
    type: "small_airport",
    name: "Santa Rosa De Yacuma Airport",
    elevation_ft: "738",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Santa Rosa",
    gps_code: "SLSR",
    iata_code: "SRB",
    coordinates: "-66.78679656982422, -14.066200256347656",
  },
  {
    ident: "SLTI",
    type: "small_airport",
    name: "San Mat����as Airport",
    elevation_ft: "403",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "San Mat����as",
    gps_code: "SLTI",
    iata_code: "MQK",
    coordinates: "-58.40190124511719, -16.33919906616211",
  },
  {
    ident: "SLTJ",
    type: "medium_airport",
    name: "Capitan Oriel Lea Plaza Airport",
    elevation_ft: "6079",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-T",
    municipality: "Tarija",
    gps_code: "SLTJ",
    iata_code: "TJA",
    coordinates: "-64.7013015747, -21.5557003021",
  },
  {
    ident: "SLTR",
    type: "medium_airport",
    name: "Teniente Av. Jorge Henrich Arauz Airport",
    elevation_ft: "509",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-B",
    municipality: "Trinidad",
    gps_code: "SLTR",
    iata_code: "TDD",
    coordinates: "-64.9179992676, -14.8186998367",
  },
  {
    ident: "SLUY",
    type: "small_airport",
    name: "Uyuni Airport",
    elevation_ft: "11136",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-P",
    municipality: "Quijarro",
    gps_code: "SLUY",
    iata_code: "UYU",
    coordinates: "-66.8483963013, -20.446300506599997",
  },
  {
    ident: "SLVG",
    type: "small_airport",
    name: "Capit����n Av. Vidal Villagomez Toledo Airport",
    elevation_ft: "6551",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "Vallegrande",
    gps_code: "SLVG",
    iata_code: "VAH",
    coordinates: "-64.09940338134766, -18.482500076293945",
  },
  {
    ident: "SLVM",
    type: "medium_airport",
    name: "Teniente Coronel Rafael Pab����n Airport",
    elevation_ft: "1305",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-T",
    municipality: "Villamontes",
    gps_code: "SLVM",
    iata_code: "VLM",
    coordinates: "-63.4056015015, -21.255199432399998",
  },
  {
    ident: "SLVR",
    type: "large_airport",
    name: "Viru Viru International Airport",
    elevation_ft: "1224",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-S",
    municipality: "Santa Cruz",
    gps_code: "SLVR",
    iata_code: "VVI",
    coordinates: "-63.135399, -17.6448",
  },
  {
    ident: "SLYA",
    type: "medium_airport",
    name: "Yacuiba Airport",
    elevation_ft: "2112",
    continent: "SA",
    iso_country: "BO",
    iso_region: "BO-T",
    municipality: "Yacu����ba",
    gps_code: "SLYA",
    iata_code: "BYC",
    coordinates: "-63.65169906616211, -21.960899353027344",
  },
  {
    ident: "SMBN",
    type: "small_airport",
    name: "Albina Airport",
    elevation_ft: "19",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-MA",
    municipality: "Albina",
    gps_code: "SMBN",
    iata_code: "ABN",
    coordinates: "-54.05009841918945, 5.512720108032227",
  },
  {
    ident: "SMCO",
    type: "small_airport",
    name: "Totness Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-CR",
    municipality: "Totness",
    gps_code: "SMCO",
    iata_code: "TOT",
    coordinates: "-56.32749938964844, 5.865829944610596",
  },
  {
    ident: "SMDA",
    type: "small_airport",
    name: "Drietabbetje Airport",
    elevation_ft: "236",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-SI",
    municipality: "Drietabbetje",
    gps_code: "SMDA",
    iata_code: "DRJ",
    coordinates: "-54.672766, 4.111359",
  },
  {
    ident: "SMH",
    type: "small_airport",
    name: "Sapmanga Airport",
    elevation_ft: "2912",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Sapmanga",
    gps_code: "AYSP",
    iata_code: "SMH",
    local_code: "SAG",
    coordinates: "146.811111111, -6.075277777779999",
  },
  {
    ident: "SMJP",
    type: "medium_airport",
    name: "Johan Adolf Pengel International Airport",
    elevation_ft: "59",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-PR",
    municipality: "Zandery",
    gps_code: "SMJP",
    iata_code: "PBM",
    coordinates: "-55.1878013611, 5.4528298377999995",
  },
  {
    ident: "SMMO",
    type: "small_airport",
    name: "Moengo Airstrip",
    elevation_ft: "49",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-MA",
    municipality: "Moengo",
    gps_code: "SMMO",
    iata_code: "MOJ",
    coordinates: "-54.4003, 5.6076",
  },
  {
    ident: "SMNI",
    type: "small_airport",
    name: "Nieuw Nickerie Airport",
    elevation_ft: "9",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-NI",
    municipality: "Nieuw Nickerie",
    gps_code: "SMNI",
    iata_code: "ICK",
    coordinates: "-57.039398193359375, 5.955560207366943",
  },
  {
    ident: "SMP",
    type: "small_airport",
    name: "Stockholm Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EBR",
    municipality: "Stockholm",
    iata_code: "SMP",
    local_code: "STK",
    coordinates: "151.533, -4.358",
  },
  {
    ident: "SMPA",
    type: "small_airport",
    name: "Vincent Fayks Airport",
    elevation_ft: "714",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-SI",
    municipality: "Paloemeu",
    gps_code: "SMPA",
    iata_code: "OEM",
    coordinates: "-55.442501068115234, 3.3452799320220947",
  },
  {
    ident: "SMST",
    type: "small_airport",
    name: "Stoelmanseiland Airport",
    elevation_ft: "187",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-SI",
    municipality: "Stoelmanseiland",
    gps_code: "SMST",
    iata_code: "SMZ",
    coordinates: "-54.41666793823242, 4.349999904632568",
  },
  {
    ident: "SMWA",
    type: "small_airport",
    name: "Wageningen Airstrip",
    elevation_ft: "6",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-NI",
    municipality: "Wageningen",
    gps_code: "SMWA",
    iata_code: "AGI",
    coordinates: "-56.673328, 5.841128",
  },
  {
    ident: "SMZO",
    type: "small_airport",
    name: "Zorg en Hoop Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-PM",
    municipality: "Paramaribo",
    gps_code: "SMZO",
    iata_code: "ORG",
    coordinates: "-55.190701, 5.81108",
  },
  {
    ident: "SNAI",
    type: "small_airport",
    name: "Alto Parna����ba Airport",
    elevation_ft: "968",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Alto Parna����ba",
    gps_code: "SNAI",
    iata_code: "APY",
    coordinates: "-45.950557708740234, -9.083610534667969",
  },
  {
    ident: "SNAL",
    type: "small_airport",
    name: "Arapiraca Airport",
    elevation_ft: "886",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AL",
    municipality: "Arapiraca",
    gps_code: "SNAL",
    iata_code: "APQ",
    local_code: "SNAL",
    coordinates: "-36.62919998168945, -9.775360107421875",
  },
  {
    ident: "SNAR",
    type: "small_airport",
    name: "Cirilo Queir����z Airport",
    elevation_ft: "640",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Almenara",
    gps_code: "SNAR",
    iata_code: "AMJ",
    local_code: "SNAR",
    coordinates: "-40.66722, -16.18389",
  },
  {
    ident: "SNAX",
    type: "small_airport",
    name: "Marcelo Pires Halzhausen Airport",
    elevation_ft: "1850",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Assis",
    gps_code: "SNAX",
    iata_code: "AIF",
    local_code: "SNAX",
    coordinates: "-50.453056, -22.64",
  },
  {
    ident: "SNBC",
    type: "small_airport",
    name: "Barra do Corda Airport",
    elevation_ft: "509",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Barra Do Corda",
    gps_code: "SNBC",
    iata_code: "BDC",
    coordinates: "-45.215833, -5.5025",
  },
  {
    ident: "SNBL",
    type: "small_airport",
    name: "Belmonte Airport",
    elevation_ft: "33",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Belmonte",
    gps_code: "SNBL",
    iata_code: "BVM",
    local_code: "SNBL",
    coordinates: "-38.87189865112305, -15.871700286865234",
  },
  {
    ident: "SNBO",
    type: "small_airport",
    name: "Boquira Airport",
    elevation_ft: "1886",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Boquira",
    gps_code: "SNBO",
    iata_code: "0",
    coordinates: "-42.72890090942383, -12.790599822998047",
  },
  {
    ident: "SNBR",
    type: "small_airport",
    name: "Barreiras Airport",
    elevation_ft: "2447",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Barreiras",
    gps_code: "SNBR",
    iata_code: "BRA",
    local_code: "SNBR",
    coordinates: "-45.00899887084961, -12.078900337219238",
  },
  {
    ident: "SNBS",
    type: "small_airport",
    name: "Balsas Airport",
    elevation_ft: "930",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Balsas",
    gps_code: "SNBS",
    iata_code: "BSS",
    local_code: "SNBS",
    coordinates: "-46.05329895019531, -7.52603006362915",
  },
  {
    ident: "SNBU",
    type: "small_airport",
    name: "S����crates Mariani Bittencourt Airport",
    elevation_ft: "1673",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Brumado",
    gps_code: "SSXH",
    iata_code: "BMS",
    local_code: "BA0168",
    coordinates: "-41.817501, -14.2554",
  },
  {
    ident: "SNBX",
    type: "small_airport",
    name: "Barra Airport",
    elevation_ft: "1345",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Barra",
    gps_code: "SNBX",
    iata_code: "BQQ",
    local_code: "SNBX",
    coordinates: "-43.147499084472656, -11.08080005645752",
  },
  {
    ident: "SNCE",
    type: "small_airport",
    name: "Campo do Meio Airport",
    elevation_ft: "2559",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Campo Do Meio",
    gps_code: "SNCE",
    iata_code: "0",
    coordinates: "-45.8046989440918, -21.10610008239746",
  },
  {
    ident: "SNCP",
    type: "small_airport",
    name: "Carutapera Airport",
    elevation_ft: "85",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Carutapera",
    gps_code: "SNCP",
    iata_code: "CTP",
    coordinates: "-46.0172233581543, -1.2502779960632324",
  },
  {
    ident: "SNCU",
    type: "small_airport",
    name: "Cururupu Airport",
    elevation_ft: "39",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Cururupu",
    gps_code: "SNCU",
    iata_code: "CPU",
    coordinates: "-44.866944, -1.821111",
  },
  {
    ident: "SNCV",
    type: "small_airport",
    name: "Campina Verde Airport",
    elevation_ft: "1805",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Campina Verde",
    gps_code: "SNCV",
    iata_code: "0",
    coordinates: "-49.494998931884766, -19.53809928894043",
  },
  {
    ident: "SNCX",
    type: "small_airport",
    name: "Colatina Airport",
    elevation_ft: "492",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-ES",
    municipality: "Colatina",
    gps_code: "SNCX",
    iata_code: "QCH",
    local_code: "SNCX",
    coordinates: "-40.57939910888672, -19.48699951171875",
  },
  {
    ident: "SNDC",
    type: "small_airport",
    name: "Reden��������o Airport",
    elevation_ft: "670",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Reden��������o",
    gps_code: "SNDC",
    iata_code: "RDC",
    local_code: "SNDC",
    coordinates: "-49.97990036010742, -8.033289909362793",
  },
  {
    ident: "SNDN",
    type: "small_airport",
    name: "Leopoldina Airport",
    elevation_ft: "919",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Leopoldina",
    iata_code: "LEP",
    coordinates: "-42.727001, -21.466101",
  },
  {
    ident: "SNDT",
    type: "small_airport",
    name: "Diamantina Airport",
    elevation_ft: "4446",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Diamantina",
    gps_code: "SNDT",
    iata_code: "DTI",
    coordinates: "-43.650398254399995, -18.232000351",
  },
  {
    ident: "SNDV",
    type: "small_airport",
    name: "Brigadeiro Cabral Airport",
    elevation_ft: "2608",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Divin����polis",
    gps_code: "SNDV",
    iata_code: "DIQ",
    local_code: "SNDV",
    coordinates: "-44.870899200439, -20.180700302124",
  },
  {
    ident: "SNED",
    type: "small_airport",
    name: "S����crates Rezende Airport",
    elevation_ft: "19",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Canavieiras",
    gps_code: "SNED",
    iata_code: "CNV",
    local_code: "SNED",
    coordinates: "-38.954700469971, -15.666999816895",
  },
  {
    ident: "SNEM",
    type: "small_airport",
    name: "Encanta Mo����a Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PE",
    municipality: "Recife",
    gps_code: "SNEM",
    iata_code: "0",
    coordinates: "-34.892799377441406, -8.094169616699219",
  },
  {
    ident: "SNFO",
    type: "small_airport",
    name: "Formiga Airport",
    elevation_ft: "3337",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Formiga",
    gps_code: "SNFO",
    iata_code: "0",
    coordinates: "-45.48360061645508, -20.395299911499023",
  },
  {
    ident: "SNFX",
    type: "small_airport",
    name: "S����o F����lix do Xingu Airport",
    elevation_ft: "656",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "S����o F����lix do Xingu",
    gps_code: "SNFX",
    iata_code: "SXX",
    local_code: "PA0013",
    coordinates: "-51.9523, -6.6413",
  },
  {
    ident: "SNGA",
    type: "small_airport",
    name: "Guarapari Airport",
    elevation_ft: "28",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-ES",
    municipality: "Guarapari",
    gps_code: "SNGA",
    iata_code: "GUZ",
    local_code: "SNGA",
    coordinates: "-40.491901397700005, -20.646499633800005",
  },
  {
    ident: "SNGD",
    type: "small_airport",
    name: "Guadalupe Airport",
    elevation_ft: "564",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PI",
    municipality: "Guadalupe",
    gps_code: "SNGD",
    iata_code: "GDP",
    coordinates: "-43.58219909667969, -6.782219886779785",
  },
  {
    ident: "SNGI",
    type: "small_airport",
    name: "Guanambi Airport",
    elevation_ft: "1815",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Guanambi",
    gps_code: "SNGI",
    iata_code: "GNM",
    local_code: "SNGI",
    coordinates: "-42.74610137939453, -14.208200454711914",
  },
  {
    ident: "SNGN",
    type: "small_airport",
    name: "Garanhuns Airport",
    elevation_ft: "2533",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PE",
    municipality: "Garanhuns",
    gps_code: "SNGN",
    iata_code: "QGP",
    local_code: "SNGN",
    coordinates: "-36.47159957885742, -8.834280014038086",
  },
  {
    ident: "SNHA",
    type: "small_airport",
    name: "Tertuliano Guedes de Pinho Airport",
    elevation_ft: "197",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Itabuna",
    gps_code: "SWTX",
    iata_code: "ITN",
    local_code: "BA0248",
    coordinates: "-39.290401, -14.8105",
  },
  {
    ident: "SNIC",
    type: "small_airport",
    name: "Irec���� Airport",
    elevation_ft: "2561",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Irec����",
    gps_code: "SNIC",
    iata_code: "IRE",
    local_code: "SNIC",
    coordinates: "-41.84700012207031, -11.339900016784668",
  },
  {
    ident: "SNIG",
    type: "small_airport",
    name: "Iguatu Airport",
    elevation_ft: "699",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-CE",
    municipality: "Iguatu",
    gps_code: "SNIG",
    iata_code: "QIG",
    local_code: "SNIG",
    coordinates: "-39.293800354003906, -6.346640110015869",
  },
  {
    ident: "SNIP",
    type: "small_airport",
    name: "Itapetinga Airport",
    elevation_ft: "915",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Itapetinga",
    gps_code: "SNIP",
    iata_code: "QIT",
    local_code: "SNIP",
    coordinates: "-40.277198791503906, -15.244500160217285",
  },
  {
    ident: "SNIU",
    type: "small_airport",
    name: "Ipia���� Airport",
    elevation_ft: "492",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Ipia����",
    gps_code: "SNIU",
    iata_code: "IPU",
    local_code: "SNIU",
    coordinates: "-39.733890533447266, -14.133889198303223",
  },
  {
    ident: "SNJB",
    type: "small_airport",
    name: "Jacobina Airport",
    elevation_ft: "1640",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Jacobina",
    gps_code: "SNJB",
    iata_code: "JCM",
    local_code: "SNJB",
    coordinates: "-40.5531005859375, -11.163200378417969",
  },
  {
    ident: "SNJD",
    type: "small_airport",
    name: "Jo����o Durval Carneiro Airport",
    elevation_ft: "768",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Feira De Santana",
    gps_code: "SDIY",
    iata_code: "FEC",
    local_code: "BA0013",
    coordinates: "-38.906799, -12.2003",
  },
  {
    ident: "SNJK",
    type: "small_airport",
    name: "Jequi���� Airport",
    elevation_ft: "646",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Jequi����",
    gps_code: "SNJK",
    iata_code: "JEQ",
    local_code: "SNJK",
    coordinates: "-40.07160186767578, -13.877699851989746",
  },
  {
    ident: "SNJN",
    type: "small_airport",
    name: "Janu����ria Airport",
    elevation_ft: "1575",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Janu����ria",
    gps_code: "SNJN",
    iata_code: "JNA",
    local_code: "SNJN",
    coordinates: "-44.385501861572266, -15.473799705505371",
  },
  {
    ident: "SNJR",
    type: "small_airport",
    name: "Prefeito Oct����vio de Almeida Neves Airport",
    elevation_ft: "3117",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "S����o Jo����o Del Rei",
    gps_code: "SNJR",
    iata_code: "JDR",
    local_code: "SNJR",
    coordinates: "-44.2247238159, -21.0849990845",
  },
  {
    ident: "SNKE",
    type: "small_airport",
    name: "Santana do Araguaia Airport",
    elevation_ft: "597",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Santana Do Araguaia",
    gps_code: "SNKE",
    iata_code: "CMP",
    local_code: "SNKE",
    coordinates: "-50.32849884033203, -9.31997013092041",
  },
  {
    ident: "SNKI",
    type: "small_airport",
    name: "Cachoeiro do Itapemirim Airport",
    elevation_ft: "335",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-ES",
    municipality: "Cachoeiro Do Itapemirim",
    gps_code: "SNKI",
    iata_code: "CDI",
    local_code: "SNKI",
    coordinates: "-41.1856002808, -20.834299087499996",
  },
  {
    ident: "SNKL",
    type: "small_airport",
    name: "Colinas Airport",
    elevation_ft: "394",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Colinas",
    gps_code: "SNKL",
    iata_code: "0",
    coordinates: "-44.2338981628418, -5.966939926147461",
  },
  {
    ident: "SNKN",
    type: "small_airport",
    name: "Currais Novos Airport",
    elevation_ft: "1148",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RN",
    municipality: "Currais Novos",
    gps_code: "SNKN",
    iata_code: "QCP",
    coordinates: "-36.540279388427734, -6.280832767486572",
  },
  {
    ident: "SNLO",
    type: "small_airport",
    name: "S����o Louren����o Airport",
    elevation_ft: "2871",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "S����o Louren����o",
    gps_code: "SNLO",
    iata_code: "SSO",
    local_code: "SNLO",
    coordinates: "-45.044498443603516, -22.090900421142578",
  },
  {
    ident: "SNMA",
    type: "small_airport",
    name: "Monte Alegre Airport",
    elevation_ft: "325",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Monte Alegre",
    gps_code: "SNMA",
    iata_code: "MTE",
    local_code: "SNMA",
    coordinates: "-54.07419967651367, -1.9958000183105469",
  },
  {
    ident: "SNMB",
    type: "small_airport",
    name: "Momba����a Airport",
    elevation_ft: "748",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-CE",
    municipality: "Momba����a",
    gps_code: "SNMB",
    iata_code: "0",
    coordinates: "-39.62139892578125, -5.744170188903809",
  },
  {
    ident: "SNMS",
    type: "small_airport",
    name: "Monte Santo de Minas Airport",
    elevation_ft: "2936",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Monte Santo De Minas",
    gps_code: "SNMS",
    iata_code: "0",
    coordinates: "-46.958900451660156, -21.175800323486328",
  },
  {
    ident: "SNMU",
    type: "small_airport",
    name: "Mucuri Airport",
    elevation_ft: "276",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Mucuri",
    gps_code: "SNMU",
    iata_code: "MVS",
    local_code: "SNMU",
    coordinates: "-39.864200592041016, -18.048900604248047",
  },
  {
    ident: "SNMX",
    type: "small_airport",
    name: "S����o Mateus Airport",
    elevation_ft: "98",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-ES",
    municipality: "S����o Mateus",
    gps_code: "SNMX",
    iata_code: "SBJ",
    local_code: "SNMX",
    coordinates: "-39.83369827270508, -18.72130012512207",
  },
  {
    ident: "SNMZ",
    type: "small_airport",
    name: "Porto de Moz Airport",
    elevation_ft: "53",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Porto De Moz",
    gps_code: "SNMZ",
    iata_code: "PTQ",
    local_code: "SNMZ",
    coordinates: "-52.23609924316406, -1.7414499521255493",
  },
  {
    ident: "SNNU",
    type: "small_airport",
    name: "Nanuque Airport",
    elevation_ft: "591",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Nanuque",
    gps_code: "SNNU",
    iata_code: "NNU",
    local_code: "SNNU",
    coordinates: "-40.329898834228516, -17.823299407958984",
  },
  {
    ident: "SNOC",
    type: "small_airport",
    name: "Morro do Chap����u Airport",
    elevation_ft: "3609",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Morro Do Chap����u",
    gps_code: "SNOC",
    iata_code: "0",
    coordinates: "-41.1796989440918, -11.53439998626709",
  },
  {
    ident: "SNOE",
    type: "small_airport",
    name: "Oeiras Airport",
    elevation_ft: "820",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PI",
    municipality: "Oeiras",
    gps_code: "SNOE",
    iata_code: "0",
    coordinates: "-42.167198181152344, -7.018889904022217",
  },
  {
    ident: "SNOF",
    type: "small_airport",
    name: "Ouro Fino Airport",
    elevation_ft: "2818",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Ouro Fino",
    gps_code: "SNOF",
    iata_code: "0",
    coordinates: "-46.3932991027832, -22.297500610351562",
  },
  {
    ident: "SNOP",
    type: "small_airport",
    name: "Propri���� Airport",
    elevation_ft: "164",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SE",
    municipality: "Propri����",
    gps_code: "SNOP",
    iata_code: "0",
    coordinates: "-36.83359909057617, -10.267200469970703",
  },
  {
    ident: "SNOS",
    type: "small_airport",
    name: "Municipal Jos���� Figueiredo Airport",
    elevation_ft: "2697",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Passos",
    gps_code: "SNOS",
    iata_code: "PSW",
    local_code: "SNOS",
    coordinates: "-46.661800384521484, -20.732200622558594",
  },
  {
    ident: "SNOU",
    type: "small_airport",
    name: "Feij���� Airport",
    elevation_ft: "394",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AC",
    municipality: "Feij����",
    gps_code: "SNOU",
    iata_code: "FEJ",
    coordinates: "-70.347221374512, -8.140832901001",
  },
  {
    ident: "SNOX",
    type: "small_airport",
    name: "Oriximin���� Airport",
    elevation_ft: "262",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Oriximin����",
    gps_code: "SNOX",
    iata_code: "ORX",
    local_code: "SNOX",
    coordinates: "-55.83620071411133, -1.7140799760818481",
  },
  {
    ident: "SNPB",
    type: "small_airport",
    name: "Pastos Bons Airport",
    elevation_ft: "197",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Pastos Bons",
    gps_code: "SNPB",
    iata_code: "0",
    coordinates: "-44.083900451660156, -6.666940212249756",
  },
  {
    ident: "SNPC",
    type: "small_airport",
    name: "Picos Airport",
    elevation_ft: "1050",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PI",
    municipality: "Picos",
    gps_code: "SNPC",
    iata_code: "PCS",
    local_code: "SNPC",
    coordinates: "-41.52370071411133, -7.0620598793029785",
  },
  {
    ident: "SNPD",
    type: "small_airport",
    name: "Patos de Minas Airport",
    elevation_ft: "2793",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Patos De Minas",
    gps_code: "SNPD",
    iata_code: "POJ",
    local_code: "SNPD",
    coordinates: "-46.4911994934082, -18.672800064086914",
  },
  {
    ident: "SNPI",
    type: "small_airport",
    name: "Piat���� Airport",
    elevation_ft: "4118",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Piat����",
    gps_code: "SNPI",
    iata_code: "0",
    coordinates: "-41.775001525878906, -13.00059986114502",
  },
  {
    ident: "SNPJ",
    type: "small_airport",
    name: "Patroc����nio Airport",
    elevation_ft: "3229",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Patroc����nio",
    gps_code: "SNPJ",
    iata_code: "0",
    coordinates: "-46.98270034790039, -18.909400939941406",
  },
  {
    ident: "SNPO",
    type: "small_airport",
    name: "Pompeu Airport",
    elevation_ft: "2313",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Pompeu",
    gps_code: "SNPO",
    iata_code: "0",
    coordinates: "-45.02330017089844, -19.20330047607422",
  },
  {
    ident: "SNPU",
    type: "small_airport",
    name: "Paragua����u Airport",
    elevation_ft: "2838",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Paragua����u",
    gps_code: "SNPU",
    iata_code: "0",
    coordinates: "-45.75170135498047, -21.559200286865234",
  },
  {
    ident: "SNPX",
    type: "small_airport",
    name: "Pirapora Airport",
    elevation_ft: "1808",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Pirapora",
    gps_code: "SNPX",
    iata_code: "PIV",
    local_code: "SNPX",
    coordinates: "-44.86029815673828, -17.3169002532959",
  },
  {
    ident: "SNQ",
    type: "small_airport",
    name: "San Quint����n Military Airstrip",
    elevation_ft: "68",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCN",
    municipality: "Military Camp Number 2-D",
    iata_code: "SNQ",
    coordinates: "-115.9462, 30.5288",
  },
  {
    ident: "SNQG",
    type: "small_airport",
    name: "Cangapara Airport",
    elevation_ft: "689",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PI",
    municipality: "Floriano",
    gps_code: "SNQG",
    iata_code: "FLB",
    local_code: "SNQG",
    coordinates: "-43.077301025390625, -6.8463897705078125",
  },
  {
    ident: "SNQP",
    type: "small_airport",
    name: "Fazenda S����o Francisco Airport",
    elevation_ft: "1437",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Magda",
    gps_code: "SNQP",
    iata_code: "0",
    coordinates: "-50.23030090332031, -20.5674991607666",
  },
  {
    ident: "SNRD",
    type: "small_airport",
    name: "Prado Airport",
    elevation_ft: "66",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Prado",
    gps_code: "SNRD",
    iata_code: "PDF",
    local_code: "SNRD",
    coordinates: "-39.27119827270508, -17.29669952392578",
  },
  {
    ident: "SNRP",
    type: "small_airport",
    name: "Rio Parana����ba Airport",
    elevation_ft: "3757",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Rio Parana����ba",
    gps_code: "SNRP",
    iata_code: "0",
    coordinates: "-46.2406005859375, -19.212499618530273",
  },
  {
    ident: "SNRU",
    type: "small_airport",
    name: "Caruaru Airport",
    elevation_ft: "1891",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PE",
    municipality: "Caruaru",
    gps_code: "SNRU",
    iata_code: "CAU",
    local_code: "SNRU",
    coordinates: "-36.01350021362305, -8.282389640808105",
  },
  {
    ident: "SNRX",
    type: "small_airport",
    name: "Riach����o Airport",
    elevation_ft: "1312",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Riach����o",
    gps_code: "SNRX",
    iata_code: "0",
    coordinates: "-46.6338996887207, -7.350279808044434",
  },
  {
    ident: "SNSC",
    type: "small_airport",
    name: "Sacramento Airport",
    elevation_ft: "3288",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Sacramento",
    gps_code: "SNSC",
    iata_code: "0",
    coordinates: "-47.42219924926758, -19.89310073852539",
  },
  {
    ident: "SNSH",
    type: "small_airport",
    name: "S����o Jos���� Airport",
    elevation_ft: "440",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Santar����m",
    gps_code: "SNSH",
    iata_code: "0",
    coordinates: "-54.73139953613281, -2.5744400024414062",
  },
  {
    ident: "SNSW",
    type: "small_airport",
    name: "Soure Airport",
    elevation_ft: "43",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Soure",
    gps_code: "SNSW",
    iata_code: "SFK",
    local_code: "SNSW",
    coordinates: "-48.520999908447266, -0.6994310021400452",
  },
  {
    ident: "SNTF",
    type: "small_airport",
    name: "9 de Maio - Teixeira de Freitas Airport",
    elevation_ft: "344",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Teixeira De Freitas",
    gps_code: "SNTF",
    iata_code: "TXF",
    local_code: "SNTF",
    coordinates: "-39.66849899292, -17.524499893188",
  },
  {
    ident: "SNTI",
    type: "small_airport",
    name: "����bidos Airport",
    elevation_ft: "328",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "����bidos",
    gps_code: "SNTI",
    iata_code: "OBI",
    local_code: "SNTI",
    coordinates: "-55.514400482177734, -1.867169976234436",
  },
  {
    ident: "SNTK",
    type: "small_airport",
    name: "Monte Carmelo Airport",
    elevation_ft: "2904",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Monte Carmelo",
    gps_code: "SNTK",
    iata_code: "0",
    coordinates: "-47.48310089111328, -18.717199325561523",
  },
  {
    ident: "SNTO",
    type: "small_airport",
    name: "Juscelino Kubitscheck Airport",
    elevation_ft: "1572",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Te����filo Otoni",
    gps_code: "SNTO",
    iata_code: "TFL",
    local_code: "SNTO",
    coordinates: "-41.51359939575195, -17.89229965209961",
  },
  {
    ident: "SNTQ",
    type: "small_airport",
    name: "Buritirama Airport",
    elevation_ft: "1690",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Buritirama",
    gps_code: "SNTQ",
    iata_code: "0",
    coordinates: "-43.654701232910156, -10.724200248718262",
  },
  {
    ident: "SNVB",
    type: "small_airport",
    name: "Valen����a Airport",
    elevation_ft: "21",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Valen����a",
    gps_code: "SNVB",
    iata_code: "VAL",
    local_code: "SNVB",
    coordinates: "-38.992401, -13.2965",
  },
  {
    ident: "SNVD",
    type: "small_airport",
    name: "Santa Maria da Vit����ria Airport",
    elevation_ft: "1850",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Santa Maria Da Vit����ria",
    gps_code: "SNVD",
    iata_code: "0",
    coordinates: "-44.217201232910156, -13.40060043334961",
  },
  {
    ident: "SNVG",
    type: "small_airport",
    name: "Volta Grande Airport",
    elevation_ft: "1831",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Concei��������o Das Alagoas",
    gps_code: "SNVG",
    iata_code: "0",
    coordinates: "-48.2338981628418, -20.033899307250977",
  },
  {
    ident: "SNVI",
    type: "small_airport",
    name: "M����lio Viana Airport",
    elevation_ft: "3232",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Tr����s Cora��������es",
    gps_code: "SNVI",
    iata_code: "QID",
    coordinates: "-45.26919937133789, -21.790599822998047",
  },
  {
    ident: "SNVS",
    type: "small_airport",
    name: "Breves Airport",
    elevation_ft: "98",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Breves",
    gps_code: "SNVS",
    iata_code: "BVS",
    local_code: "SNVS",
    coordinates: "-50.443599700927734, -1.6365300416946411",
  },
  {
    ident: "SNWC",
    type: "small_airport",
    name: "Camocim Airport",
    elevation_ft: "16",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-CE",
    municipality: "Camocim",
    gps_code: "SNWC",
    iata_code: "CMC",
    coordinates: "-40.858001708984375, -2.8961799144744873",
  },
  {
    ident: "SNXA",
    type: "small_airport",
    name: "Machado Airport",
    elevation_ft: "2969",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Machado",
    gps_code: "SNXA",
    iata_code: "0",
    coordinates: "-45.84109878540039, -21.6781005859375",
  },
  {
    ident: "SNYE",
    type: "small_airport",
    name: "Pinheiro Airport",
    elevation_ft: "125",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Pinheiro",
    gps_code: "SNYE",
    iata_code: "PHI",
    local_code: "SNYE",
    coordinates: "-45.067222595214844, -2.4836111068725586",
  },
  {
    ident: "SNYH",
    type: "small_airport",
    name: "Agropecu����ria Castanhais Airport",
    elevation_ft: "1096",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Cumaru Do Norte",
    gps_code: "SNYH",
    iata_code: "ITI",
    coordinates: "-51.17416763305664, -8.699999809265137",
  },
  {
    ident: "SNYW",
    type: "small_airport",
    name: "Ant����nio Guerreiro Airport",
    elevation_ft: "245",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MA",
    municipality: "Guimar����es",
    gps_code: "SNYW",
    iata_code: "GMS",
    coordinates: "-44.651114, -2.109444",
  },
  {
    ident: "SNZA",
    type: "small_airport",
    name: "Pouso Alegre Airport",
    elevation_ft: "2904",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MG",
    municipality: "Pouso Alegre",
    gps_code: "SNZA",
    iata_code: "PPY",
    local_code: "SNZA",
    coordinates: "-45.91910171508789, -22.289199829101562",
  },
  {
    ident: "SNZW",
    type: "small_airport",
    name: "Ituber���� Airport",
    elevation_ft: "13",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Ituber����",
    gps_code: "SNZW",
    iata_code: "ITE",
    coordinates: "-39.1416664124, -13.7322216034",
  },
  {
    ident: "SO-BXX",
    type: "small_airport",
    name: "Borama Airport",
    continent: "AF",
    iso_country: "SO",
    iso_region: "SO-WO",
    municipality: "Borama",
    iata_code: "BXX",
    coordinates: "43.1495, 9.9463",
  },
  {
    ident: "SO-GTA",
    type: "small_airport",
    name: "Gatokae Airport",
    elevation_ft: "70",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-WE",
    municipality: "Gatokae",
    gps_code: "AGOK",
    iata_code: "GTA",
    coordinates: "158.203056, -8.739167",
  },
  {
    ident: "SOA",
    type: "small_airport",
    name: "S����c Tr����ng Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-52",
    municipality: "S����c Tr����ng",
    iata_code: "SOA",
    coordinates: "105.9602, 9.5814",
  },
  {
    ident: "SOCA",
    type: "medium_airport",
    name: "Cayenne-Rochambeau Airport",
    elevation_ft: "26",
    continent: "SA",
    iso_country: "GF",
    iso_region: "GF-CY",
    municipality: "Cayenne / Rochambeau",
    gps_code: "SOCA",
    iata_code: "CAY",
    coordinates: "-52.360401153599994, 4.819809913639999",
  },
  {
    ident: "SOGS",
    type: "small_airport",
    name: "Grand-Santi Airport",
    elevation_ft: "207",
    continent: "SA",
    iso_country: "GF",
    iso_region: "GF-SL",
    municipality: "Grand-Santi",
    gps_code: "SOGS",
    iata_code: "GSI",
    coordinates: "-54.373056, 4.285833",
  },
  {
    ident: "SOI",
    type: "heliport",
    name: "South Molle Island Helipad",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "South Molle Island Resort",
    iata_code: "SOI",
    coordinates: "148.8399, -20.2622",
  },
  {
    ident: "SOOA",
    type: "small_airport",
    name: "Maripasoula Airport",
    elevation_ft: "406",
    continent: "SA",
    iso_country: "GF",
    iso_region: "GF-SL",
    municipality: "Maripasoula",
    gps_code: "SOOA",
    iata_code: "MPY",
    coordinates: "-54.037201, 3.6575",
  },
  {
    ident: "SOOG",
    type: "medium_airport",
    name: "Saint-Georges-de-l'Oyapock Airport",
    elevation_ft: "46",
    continent: "SA",
    iso_country: "GF",
    iso_region: "GF-CY",
    municipality: "Saint-Georges-de-l'Oyapock Airport",
    gps_code: "SOOG",
    iata_code: "OYP",
    coordinates: "-51.8041000366, 3.89759993553",
  },
  {
    ident: "SOOM",
    type: "small_airport",
    name: "Saint-Laurent-du-Maroni Airport",
    elevation_ft: "16",
    continent: "SA",
    iso_country: "GF",
    iso_region: "GF-SL",
    municipality: "Saint-Laurent-du-Maroni",
    gps_code: "SOOM",
    iata_code: "LDX",
    coordinates: "-54.034401, 5.48306",
  },
  {
    ident: "SOOR",
    type: "small_airport",
    name: "Regina Airport",
    elevation_ft: "82",
    continent: "SA",
    iso_country: "GF",
    iso_region: "GF-CY",
    municipality: "Regina",
    gps_code: "SOOR",
    iata_code: "REI",
    coordinates: "-52.1316986084, 4.31472015381",
  },
  {
    ident: "SOOS",
    type: "small_airport",
    name: "Sa����l Airport",
    elevation_ft: "656",
    continent: "SA",
    iso_country: "GF",
    iso_region: "GF-SL",
    municipality: "Sa����l",
    gps_code: "SOOS",
    iata_code: "XAU",
    coordinates: "-53.204201, 3.61361",
  },
  {
    ident: "SOR",
    type: "small_airport",
    name: "Al Thaurah Airport",
    elevation_ft: "1278",
    continent: "AS",
    iso_country: "SY",
    iso_region: "SY-DY",
    municipality: "Al Thaurah",
    iata_code: "SOR",
    coordinates: "40.1524, 34.3905",
  },
  {
    ident: "SPAO",
    type: "small_airport",
    name: "San Juan Aposento Airport",
    elevation_ft: "23",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-ICA",
    municipality: "San Juan Aposento",
    gps_code: "SPAO",
    iata_code: "APE",
    coordinates: "-75.16709899902344, -15.353899955749512",
  },
  {
    ident: "SPAR",
    type: "small_airport",
    name: "Alerta Airport",
    elevation_ft: "797",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-MDD",
    municipality: "Fortaleza",
    gps_code: "SPAR",
    iata_code: "ALD",
    coordinates: "-69.33300018310547, -11.682999610900879",
  },
  {
    ident: "SPAS",
    type: "small_airport",
    name: "Alferez FAP Alfredo Vladimir Sara Bauer Airport",
    elevation_ft: "728",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-LOR",
    municipality: "Andoas",
    gps_code: "SPAS",
    iata_code: "AOP",
    coordinates: "-76.46659851070001, -2.79612994194",
  },
  {
    ident: "SPAY",
    type: "medium_airport",
    name: "Teniente General Gerardo P����rez Pinedo Airport",
    elevation_ft: "751",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-UCA",
    municipality: "Atalaya",
    gps_code: "SPAY",
    iata_code: "AYX",
    coordinates: "-73.766502, -10.7291",
  },
  {
    ident: "SPBB",
    type: "small_airport",
    name: "Moyobamba Airport",
    elevation_ft: "2749",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-SAM",
    municipality: "Moyobamba",
    gps_code: "SPBB",
    iata_code: "MBP",
    coordinates: "-76.98832702636719, -6.0188889503479",
  },
  {
    ident: "SPBL",
    type: "small_airport",
    name: "Huallaga Airport",
    elevation_ft: "980",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-SAM",
    municipality: "Bellavista",
    gps_code: "SPBL",
    iata_code: "BLP",
    coordinates: "-76.58219909667969, -7.06056022644043",
  },
  {
    ident: "SPBR",
    type: "medium_airport",
    name: "Iberia Airport",
    elevation_ft: "750",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-MDD",
    municipality: "Iberia",
    gps_code: "SPBR",
    iata_code: "IBP",
    coordinates: "-69.48870086669922, -11.411600112915039",
  },
  {
    ident: "SPCL",
    type: "medium_airport",
    name: "Cap FAP David Abenzur Rengifo International Airport",
    elevation_ft: "513",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-UCA",
    municipality: "Pucallpa",
    gps_code: "SPCL",
    iata_code: "PCL",
    coordinates: "-74.57430267333984, -8.37794017791748",
  },
  {
    ident: "SPDR",
    type: "small_airport",
    name: "Trompeteros Airport",
    elevation_ft: "427",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-LOR",
    municipality: "Corrientes",
    gps_code: "SPDR",
    iata_code: "TDP",
    coordinates: "-75.03929901119999, -3.80601000786",
  },
  {
    ident: "SPEO",
    type: "medium_airport",
    name: "Teniente FAP Jaime A De Montreuil Morales Airport",
    elevation_ft: "69",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-ANC",
    municipality: "Chimbote",
    gps_code: "SPEO",
    iata_code: "CHM",
    coordinates: "-78.5238037109375, -9.149609565734863",
  },
  {
    ident: "SPGM",
    type: "small_airport",
    name: "Tingo Maria Airport",
    elevation_ft: "2010",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-HUC",
    municipality: "Tingo Maria",
    gps_code: "SPGM",
    iata_code: "TGI",
    coordinates: "-75.94999694824219, -9.133000373840332",
  },
  {
    ident: "SPHI",
    type: "medium_airport",
    name: "Capitan FAP Jose A Quinones Gonzales International Airport",
    elevation_ft: "97",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-LAM",
    municipality: "Chiclayo",
    gps_code: "SPHI",
    iata_code: "CIX",
    coordinates: "-79.8281021118164, -6.787479877471924",
  },
  {
    ident: "SPHO",
    type: "medium_airport",
    name: "Coronel FAP Alfredo Mendivil Duarte Airport",
    elevation_ft: "8917",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-AYA",
    municipality: "Ayacucho",
    gps_code: "SPHO",
    iata_code: "AYP",
    coordinates: "-74.20439910888672, -13.154800415039062",
  },
  {
    ident: "SPHY",
    type: "small_airport",
    name: "Andahuaylas Airport",
    elevation_ft: "11300",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-APU",
    municipality: "Andahuaylas",
    gps_code: "SPHY",
    iata_code: "ANS",
    coordinates: "-73.35040283203125, -13.706399917602539",
  },
  {
    ident: "SPHZ",
    type: "medium_airport",
    name: "Comandante FAP German Arias Graziani Airport",
    elevation_ft: "9097",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-ANC",
    municipality: "Anta",
    gps_code: "SPHZ",
    iata_code: "ATA",
    coordinates: "-77.59839630126953, -9.347439765930176",
  },
  {
    ident: "SPIL",
    type: "small_airport",
    name: "Quince Air Base",
    elevation_ft: "2047",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-CUS",
    municipality: "Quince Mil",
    gps_code: "SPIL",
    iata_code: "UMI",
    coordinates: "-70.75330352783203, -13.23330020904541",
  },
  {
    ident: "SPIM",
    type: "large_airport",
    name: "Jorge Ch����vez International Airport",
    elevation_ft: "113",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-LIM",
    municipality: "Lima",
    gps_code: "SPJC",
    iata_code: "LIM",
    coordinates: "-77.114305, -12.0219",
  },
  {
    ident: "SPIZ",
    type: "small_airport",
    name: "Uchiza Airport",
    elevation_ft: "1965",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-HUC",
    municipality: "Uchiza",
    gps_code: "SPIZ",
    iata_code: "UCZ",
    coordinates: "-76.3499984741211, -8.467000007629395",
  },
  {
    ident: "SPJA",
    type: "medium_airport",
    name: "Juan Simons Vela Airport",
    elevation_ft: "2707",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-SAM",
    municipality: "Rioja",
    gps_code: "SPJA",
    iata_code: "RIJ",
    coordinates: "-77.16000366210938, -6.067860126495361",
  },
  {
    ident: "SPJE",
    type: "medium_airport",
    name: "Shumba Airport",
    elevation_ft: "2477",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-CAJ",
    municipality: "Ja����n",
    gps_code: "SPJE",
    iata_code: "JAE",
    coordinates: "-78.774002, -5.59248",
  },
  {
    ident: "SPJI",
    type: "medium_airport",
    name: "Juanjui Airport",
    elevation_ft: "1148",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-SAM",
    municipality: "Juanju����",
    gps_code: "SPJI",
    iata_code: "JJI",
    coordinates: "-76.72859954833984, -7.169099807739258",
  },
  {
    ident: "SPJJ",
    type: "medium_airport",
    name: "Francisco Carle Airport",
    elevation_ft: "11034",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-JUN",
    municipality: "Jauja",
    gps_code: "SPJJ",
    iata_code: "JAU",
    coordinates: "-75.47339630130001, -11.7831001282",
  },
  {
    ident: "SPJL",
    type: "medium_airport",
    name: "Inca Manco Capac International Airport",
    elevation_ft: "12552",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-PUN",
    municipality: "Juliaca",
    gps_code: "SPJL",
    iata_code: "JUL",
    coordinates: "-70.158203125, -15.467100143432617",
  },
  {
    ident: "SPJN",
    type: "small_airport",
    name: "San Juan de Marcona Airport",
    elevation_ft: "144",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-ICA",
    municipality: "San Juan de Marcona",
    gps_code: "SPJN",
    iata_code: "SJA",
    coordinates: "-75.13719940185547, -15.352499961853027",
  },
  {
    ident: "SPJR",
    type: "medium_airport",
    name: "Mayor General FAP Armando Revoredo Iglesias Airport",
    elevation_ft: "8781",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-CAJ",
    municipality: "Cajamarca",
    gps_code: "SPJR",
    iata_code: "CJA",
    coordinates: "-78.4894027709961, -7.1391801834106445",
  },
  {
    ident: "SPLN",
    type: "small_airport",
    name: "San Nicolas Airport",
    elevation_ft: "5082",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-AMA",
    municipality: "Rodriguez de Mendoza",
    gps_code: "SPLN",
    iata_code: "RIM",
    coordinates: "-77.5011978149414, -6.39231014251709",
  },
  {
    ident: "SPLO",
    type: "medium_airport",
    name: "Ilo Airport",
    elevation_ft: "72",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-MOQ",
    municipality: "Ilo",
    gps_code: "SPLO",
    iata_code: "ILQ",
    coordinates: "-71.34400177001953, -17.69499969482422",
  },
  {
    ident: "SPME",
    type: "medium_airport",
    name: "Capitan FAP Pedro Canga Rodriguez Airport",
    elevation_ft: "115",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-TUM",
    municipality: "Tumbes",
    gps_code: "SPME",
    iata_code: "TBP",
    coordinates: "-80.38140106201172, -3.55253005027771",
  },
  {
    ident: "SPMF",
    type: "small_airport",
    name: "Mayor PNP Nancy Flores Paucar Airport",
    elevation_ft: "2247",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-JUN",
    municipality: "Mazamari",
    gps_code: "SPMF",
    iata_code: "MZA",
    coordinates: "-74.535598, -11.3254",
  },
  {
    ident: "SPMR",
    type: "small_airport",
    name: "Santa Maria Airport",
    elevation_ft: "769",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-LIM",
    municipality: "Santa Mar����a",
    gps_code: "SPMR",
    iata_code: "SMG",
    coordinates: "-77, -11.983333587646484",
  },
  {
    ident: "SPMS",
    type: "medium_airport",
    name: "Moises Benzaquen Rengifo Airport",
    elevation_ft: "587",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-LOR",
    municipality: "Yurimaguas",
    gps_code: "SPMS",
    iata_code: "YMS",
    coordinates: "-76.11820220947266, -5.893770217895508",
  },
  {
    ident: "SPNC",
    type: "medium_airport",
    name: "Alferez Fap David Figueroa Fernandini Airport",
    elevation_ft: "6070",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-HUC",
    municipality: "Hu����nuco",
    gps_code: "SPNC",
    iata_code: "HUU",
    coordinates: "-76.20480346679688, -9.878809928894043",
  },
  {
    ident: "SPOA",
    type: "small_airport",
    name: "Saposoa Airport",
    elevation_ft: "982",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-SAM",
    municipality: "Plaza Saposoa",
    gps_code: "SPOA",
    iata_code: "SQU",
    coordinates: "-76.76840209960938, -6.9600300788879395",
  },
  {
    ident: "SPPY",
    type: "medium_airport",
    name: "Chachapoyas Airport",
    elevation_ft: "8333",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-AMA",
    municipality: "Chachapoyas",
    gps_code: "SPPY",
    iata_code: "CHH",
    coordinates: "-77.8561019897461, -6.201809883117676",
  },
  {
    ident: "SPQT",
    type: "medium_airport",
    name: "Coronel FAP Francisco Secada Vignetta International Airport",
    elevation_ft: "306",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-LOR",
    municipality: "Iquitos",
    gps_code: "SPQT",
    iata_code: "IQT",
    coordinates: "-73.30879974365234, -3.7847399711608887",
  },
  {
    ident: "SPQU",
    type: "medium_airport",
    name: "Rodr����guez Ball����n International Airport",
    elevation_ft: "8405",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-ARE",
    municipality: "Arequipa",
    gps_code: "SPQU",
    iata_code: "AQP",
    coordinates: "-71.5830993652, -16.3411006927",
  },
  {
    ident: "SPRU",
    type: "medium_airport",
    name: "Capitan FAP Carlos Martinez De Pinillos International Airport",
    elevation_ft: "106",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-LAL",
    municipality: "Trujillo",
    gps_code: "SPRU",
    iata_code: "TRU",
    coordinates: "-79.10880279541016, -8.08141040802002",
  },
  {
    ident: "SPSO",
    type: "medium_airport",
    name: "Capit����n FAP Ren����n El����as Olivera International Airport",
    elevation_ft: "39",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-ICA",
    municipality: "Pisco",
    gps_code: "SPSO",
    iata_code: "PIO",
    coordinates: "-76.22029876708984, -13.74489974975586",
  },
  {
    ident: "SPST",
    type: "medium_airport",
    name: "Cadete FAP Guillermo Del Castillo Paredes Airport",
    elevation_ft: "869",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-SAM",
    municipality: "Tarapoto",
    gps_code: "SPST",
    iata_code: "TPP",
    coordinates: "-76.37319946289062, -6.508739948272705",
  },
  {
    ident: "SPSY",
    type: "small_airport",
    name: "Shiringayoc Airport",
    elevation_ft: "856",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-MDD",
    municipality: "Shiringayoc",
    gps_code: "SPSY",
    iata_code: "SYC",
    coordinates: "-69.0625, -11.898",
  },
  {
    ident: "SPTN",
    type: "medium_airport",
    name: "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
    elevation_ft: "1538",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-TAC",
    municipality: "Tacna",
    gps_code: "SPTN",
    iata_code: "TCQ",
    coordinates: "-70.2758026123, -18.053300857500002",
  },
  {
    ident: "SPTU",
    type: "medium_airport",
    name: "Padre Aldamiz International Airport",
    elevation_ft: "659",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-MDD",
    municipality: "Puerto Maldonado",
    gps_code: "SPTU",
    iata_code: "PEM",
    coordinates: "-69.2285995483, -12.6135997772",
  },
  {
    ident: "SPUR",
    type: "medium_airport",
    name: "Capit����n FAP Guillermo Concha Iberico International Airport",
    elevation_ft: "120",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-PIU",
    municipality: "Piura",
    gps_code: "SPUR",
    iata_code: "PIU",
    coordinates: "-80.61640167239999, -5.20574998856",
  },
  {
    ident: "SPYL",
    type: "medium_airport",
    name: "Capitan Montes Airport",
    elevation_ft: "282",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-PIU",
    municipality: "Talara",
    gps_code: "SPYL",
    iata_code: "TYL",
    coordinates: "-81.254096984863, -4.5766401290894",
  },
  {
    ident: "SPZA",
    type: "medium_airport",
    name: "Maria Reiche Neuman Airport",
    elevation_ft: "1860",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-ICA",
    municipality: "Nazca",
    gps_code: "SPZA",
    iata_code: "NZC",
    coordinates: "-74.9615020752, -14.854000091600001",
  },
  {
    ident: "SPZO",
    type: "large_airport",
    name: "Alejandro Velasco Astete International Airport",
    elevation_ft: "10860",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-CUS",
    municipality: "Cusco",
    gps_code: "SPZO",
    iata_code: "CUZ",
    coordinates: "-71.9387969971, -13.535699844400002",
  },
  {
    ident: "SQD",
    type: "medium_airport",
    name: "Shangrao Sanqingshan Airport",
    elevation_ft: "340",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-36",
    municipality: "Shangrao",
    gps_code: "ZSSR",
    iata_code: "SQD",
    coordinates: "117.9643, 28.3797",
  },
  {
    ident: "SQJ",
    type: "medium_airport",
    name: "Shaxian Airport",
    elevation_ft: "830",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-35",
    municipality: "Sanming",
    gps_code: "ZSSM",
    iata_code: "SQJ",
    coordinates: "117.8336, 26.4263",
  },
  {
    ident: "SQT",
    type: "small_airport",
    name: "China Strait Airstrip",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Samarai Island",
    gps_code: "AYCS",
    iata_code: "SQT",
    local_code: "CHS",
    coordinates: "150.690694444, -10.5627777778",
  },
  {
    ident: "SR-AAJ",
    type: "small_airport",
    name: "Cayana Airstrip",
    elevation_ft: "360",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-SI",
    municipality: "Awaradam",
    gps_code: "SMCA",
    iata_code: "AAJ",
    coordinates: "-55.577907, 3.898681",
  },
  {
    ident: "SR-KCB",
    type: "small_airport",
    name: "Tepoe Airstrip",
    elevation_ft: "596",
    continent: "SA",
    iso_country: "SR",
    iso_region: "SR-SI",
    municipality: "Kasikasima",
    gps_code: "SMTP",
    iata_code: "KCB",
    coordinates: "-55.716999054, 3.15000009537",
  },
  {
    ident: "SRF",
    type: "closed",
    name: "Hamilton Field",
    elevation_ft: "2",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "San Rafael",
    iata_code: "SRF",
    coordinates: "-122.51, 38.06",
  },
  {
    ident: "SRL",
    type: "small_airport",
    name: "Palo Verde Airport",
    elevation_ft: "127",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "Santa Rosalia",
    iata_code: "SRL",
    local_code: "CIB",
    coordinates: "-112.0985, 27.0927",
  },
  {
    ident: "SRM",
    type: "small_airport",
    name: "Sandringham Airport",
    elevation_ft: "313",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Sandringham Station",
    iata_code: "SRM",
    coordinates: "139.0821, -24.0568",
  },
  {
    ident: "SRU",
    type: "closed",
    name: "Santa Cruz Sky Park",
    elevation_ft: "523",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Scotts Valley",
    iata_code: "SRU",
    coordinates: "-122.0315, 37.0503",
  },
  {
    ident: "SRV",
    type: "small_airport",
    name: "Stony River 2 Airport",
    elevation_ft: "230",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Stony River",
    gps_code: "SRV",
    iata_code: "SRV",
    local_code: "SRV",
    coordinates: "-156.589004517, 61.7896995544",
  },
  {
    ident: "SSAK",
    type: "small_airport",
    name: "Carlos Ruhl Airport",
    elevation_ft: "1542",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Cruz Alta",
    gps_code: "SSAK",
    iata_code: "CZB",
    coordinates: "-53.610557556152, -28.657777786255",
  },
  {
    ident: "SSAP",
    type: "small_airport",
    name: "Captain Jo����o Busse Airport",
    elevation_ft: "2641",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Apucarana",
    gps_code: "SSAP",
    iata_code: "APU",
    local_code: "SSAP",
    coordinates: "-51.384499, -23.609501",
  },
  {
    ident: "SSAQ",
    type: "small_airport",
    name: "Aeroclube Airport",
    elevation_ft: "1969",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Passo Fundo",
    gps_code: "SSAQ",
    iata_code: "0",
    coordinates: "-52.516700744628906, -28.250600814819336",
  },
  {
    ident: "SSBG",
    type: "small_airport",
    name: "Aeroclube de Bento Gon����alves Airport",
    elevation_ft: "2209",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Bento Gon����alves",
    gps_code: "SSBG",
    iata_code: "BGV",
    coordinates: "-51.5363883972, -29.1483325958",
  },
  {
    ident: "SSBL",
    type: "small_airport",
    name: "Blumenau Airport",
    elevation_ft: "60",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Blumenau",
    gps_code: "SSBL",
    iata_code: "BNU",
    local_code: "SSBL",
    coordinates: "-49.090301513671875, -26.83060073852539",
  },
  {
    ident: "SSBR",
    type: "small_airport",
    name: "Bandeirantes Airport",
    elevation_ft: "1319",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Bandeirantes",
    gps_code: "SSBR",
    iata_code: "0",
    coordinates: "-50.423099517822266, -23.072799682617188",
  },
  {
    ident: "SSCK",
    type: "small_airport",
    name: "Conc����rdia Airport",
    elevation_ft: "2461",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Conc����rdia",
    gps_code: "SSCK",
    iata_code: "CCI",
    local_code: "SSCK",
    coordinates: "-52.05270004272461, -27.180599212646484",
  },
  {
    ident: "SSCL",
    type: "small_airport",
    name: "Cassil����ndia Airport",
    elevation_ft: "1568",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MS",
    municipality: "Cassil����ndia",
    gps_code: "SSCL",
    iata_code: "CSS",
    local_code: "MS0018",
    coordinates: "-51.676941, -19.146861",
  },
  {
    ident: "SSCN",
    type: "small_airport",
    name: "Canela Airport",
    elevation_ft: "2723",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Canela",
    gps_code: "SSCN",
    iata_code: "QCN",
    local_code: "SSCN",
    coordinates: "-50.832000732421875, -29.37019920349121",
  },
  {
    ident: "SSCP",
    type: "small_airport",
    name: "Corn����lio Proc����pio Airport",
    elevation_ft: "1854",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Corn����lio Proc����pio",
    gps_code: "SSCP",
    iata_code: "CKO",
    local_code: "SSCP",
    coordinates: "-50.602500915527344, -23.15250015258789",
  },
  {
    ident: "SSDO",
    type: "small_airport",
    name: "Dourados Airport",
    elevation_ft: "1503",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MS",
    municipality: "Dourados",
    gps_code: "SBDO",
    iata_code: "DOU",
    local_code: "SBDO",
    coordinates: "-54.926601, -22.2019",
  },
  {
    ident: "SSEP",
    type: "small_airport",
    name: "S����o Sep���� Airport",
    elevation_ft: "502",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "S����o Sep����",
    gps_code: "SSEP",
    iata_code: "0",
    coordinates: "-53.57939910888672, -30.182199478149414",
  },
  {
    ident: "SSER",
    type: "small_airport",
    name: "Erechim Airport",
    elevation_ft: "2498",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Erechim",
    gps_code: "SSER",
    iata_code: "ERM",
    local_code: "SSER",
    coordinates: "-52.2682991027832, -27.66189956665039",
  },
  {
    ident: "SSFB",
    type: "small_airport",
    name: "Francisco Beltr����o Airport",
    elevation_ft: "2100",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Francisco Beltr����o",
    gps_code: "SSFB",
    iata_code: "FBE",
    local_code: "SSFB",
    coordinates: "-53.063499450683594, -26.059200286865234",
  },
  {
    ident: "SSGR",
    type: "small_airport",
    name: "Guapor���� Airport",
    elevation_ft: "1558",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Guapor����",
    gps_code: "SSGR",
    iata_code: "0",
    coordinates: "-51.85530090332031, -28.894699096679688",
  },
  {
    ident: "SSGY",
    type: "small_airport",
    name: "Gua����ra Airport",
    elevation_ft: "889",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Gua����ra",
    gps_code: "SSGY",
    iata_code: "QGA",
    local_code: "SSGY",
    coordinates: "-54.19169998168945, -24.081100463867188",
  },
  {
    ident: "SSHZ",
    type: "small_airport",
    name: "Walter B����ndchen Airport",
    elevation_ft: "1063",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Horizontina",
    gps_code: "SSHZ",
    iata_code: "HRZ",
    local_code: "SSHZ",
    coordinates: "-54.339099884, -27.638299942",
  },
  {
    ident: "SSIJ",
    type: "small_airport",
    name: "Iju���� Airport",
    elevation_ft: "1197",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Iju����",
    gps_code: "SSIJ",
    iata_code: "IJU",
    local_code: "SSIJ",
    coordinates: "-53.84659957885742, -28.36870002746582",
  },
  {
    ident: "SSIQ",
    type: "small_airport",
    name: "Itaqui Airport",
    elevation_ft: "230",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Itaqui",
    gps_code: "SSIQ",
    iata_code: "ITQ",
    coordinates: "-56.53670120239258, -29.173099517822266",
  },
  {
    ident: "SSJA",
    type: "small_airport",
    name: "Santa Terezinha Airport",
    elevation_ft: "2546",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Joa����aba",
    gps_code: "SSJA",
    iata_code: "JCB",
    local_code: "SSJA",
    coordinates: "-51.5532989502, -27.1714000702",
  },
  {
    ident: "SSJK",
    type: "small_airport",
    name: "J����lio de Castilho Airport",
    elevation_ft: "1633",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "J����lio De Castilho",
    gps_code: "SSJK",
    iata_code: "0",
    coordinates: "-53.74330139160156, -29.153099060058594",
  },
  {
    ident: "SSKM",
    type: "small_airport",
    name: "Campo Mour����o Airport",
    elevation_ft: "1854",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Campo Mour����o",
    gps_code: "SSKM",
    iata_code: "CBW",
    local_code: "SSKM",
    coordinates: "-52.3568000793, -24.009199142499998",
  },
  {
    ident: "SSKS",
    type: "small_airport",
    name: "Cachoeira do Sul Airport",
    elevation_ft: "253",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Cachoeira Do Sul",
    gps_code: "SSKS",
    iata_code: "QDB",
    local_code: "SSKS",
    coordinates: "-52.940799713134766, -30.00189971923828",
  },
  {
    ident: "SSKU",
    type: "small_airport",
    name: "Curitibanos Airport",
    elevation_ft: "3133",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Curitibanos",
    gps_code: "SSKU",
    iata_code: "QCR",
    coordinates: "-50.61140060424805, -27.282499313354492",
  },
  {
    ident: "SSKW",
    type: "small_airport",
    name: "Cacoal Airport",
    elevation_ft: "778",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RO",
    municipality: "Cacoal",
    gps_code: "SSKW",
    iata_code: "OAL",
    coordinates: "-61.4508, -11.496",
  },
  {
    ident: "SSLA",
    type: "small_airport",
    name: "Laguna Airport",
    elevation_ft: "20",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Laguna",
    gps_code: "SSLA",
    iata_code: "0",
    coordinates: "-48.742801666259766, -28.376699447631836",
  },
  {
    ident: "SSLN",
    type: "small_airport",
    name: "Helmuth Baungarten Airport",
    elevation_ft: "1095",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Lontras",
    gps_code: "SSLN",
    iata_code: "LOI",
    local_code: "SSLN",
    coordinates: "-49.5424995422, -27.159999847399998",
  },
  {
    ident: "SSLT",
    type: "small_airport",
    name: "Alegrete Novo Airport",
    elevation_ft: "459",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Alegrete",
    gps_code: "SSLT",
    iata_code: "ALQ",
    local_code: "SSLT",
    coordinates: "-55.8933982849, -29.812700271599997",
  },
  {
    ident: "SSMF",
    type: "small_airport",
    name: "Mafra Airport",
    elevation_ft: "2690",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Mafra",
    gps_code: "SSMF",
    iata_code: "QMF",
    coordinates: "-49.83219909667969, -26.158899307250977",
  },
  {
    ident: "SSNG",
    type: "small_airport",
    name: "Montenegro Airport",
    elevation_ft: "125",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Montenegro",
    gps_code: "SSNG",
    iata_code: "QGF",
    coordinates: "-51.48939895629883, -29.71940040588379",
  },
  {
    ident: "SSNH",
    type: "small_airport",
    name: "Novo Hamburgo Airport",
    elevation_ft: "66",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Novo Hamburgo",
    gps_code: "SSNH",
    iata_code: "QHV",
    coordinates: "-51.08169937133789, -29.69610023498535",
  },
  {
    ident: "SSNO",
    type: "small_airport",
    name: "Nonoa���� Airport",
    elevation_ft: "1969",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Nonoa����",
    gps_code: "SSNO",
    iata_code: "0",
    coordinates: "-52.7338981628418, -27.36720085144043",
  },
  {
    ident: "SSNP",
    type: "small_airport",
    name: "Nova Prata Airport",
    elevation_ft: "2264",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Nova Prata",
    gps_code: "SSNP",
    iata_code: "0",
    coordinates: "-51.60419845581055, -28.802499771118164",
  },
  {
    ident: "SSOE",
    type: "small_airport",
    name: "S����o Miguel do Oeste Airport",
    elevation_ft: "2180",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "S����o Miguel Do Oeste",
    gps_code: "SSOE",
    iata_code: "SQX",
    local_code: "SSOE",
    coordinates: "-53.503501892089844, -26.781600952148438",
  },
  {
    ident: "SSOG",
    type: "small_airport",
    name: "Arapongas Airport",
    elevation_ft: "2599",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Arapongas",
    gps_code: "SSOG",
    iata_code: "APX",
    local_code: "SSOG",
    coordinates: "-51.491699, -23.3529",
  },
  {
    ident: "SSPB",
    type: "small_airport",
    name: "Juvenal Loureiro Cardoso Airport",
    elevation_ft: "2697",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Pato Branco",
    gps_code: "SBPO",
    iata_code: "PTO",
    local_code: "PR0018",
    coordinates: "-52.694463, -26.217184",
  },
  {
    ident: "SSPG",
    type: "small_airport",
    name: "Paranagu���� Airport",
    elevation_ft: "16",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Paranagu����",
    gps_code: "SSPG",
    iata_code: "PNG",
    local_code: "SSPG",
    coordinates: "-48.53120040893555, -25.54010009765625",
  },
  {
    ident: "SSPI",
    type: "small_airport",
    name: "Paranava���� Airport",
    elevation_ft: "1526",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Paranava����",
    gps_code: "SSPI",
    iata_code: "PVI",
    local_code: "SSPI",
    coordinates: "-52.48849868774414, -23.08989906311035",
  },
  {
    ident: "SSPN",
    type: "small_airport",
    name: "Parana����ba Airport",
    elevation_ft: "1446",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MS",
    municipality: "Parana����ba",
    gps_code: "SSPN",
    iata_code: "PBB",
    local_code: "SSPN",
    coordinates: "-51.19940185546875, -19.651199340820312",
  },
  {
    ident: "SSQM",
    type: "small_airport",
    name: "Tanque Novo Airport",
    elevation_ft: "2464",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Tanque Novo",
    gps_code: "SSQM",
    iata_code: "0",
    coordinates: "-42.47999954223633, -13.54580020904541",
  },
  {
    ident: "SSQN",
    type: "small_airport",
    name: "Mundo Novo Airport",
    elevation_ft: "1788",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-BA",
    municipality: "Mundo Novo",
    gps_code: "SSQN",
    iata_code: "0",
    coordinates: "-40.41939926147461, -11.772199630737305",
  },
  {
    ident: "SSQT",
    type: "small_airport",
    name: "Castro Airport",
    elevation_ft: "3314",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Castro",
    gps_code: "SSQT",
    iata_code: "QAC",
    coordinates: "-49.96030044555664, -24.8075008392334",
  },
  {
    ident: "SSRU",
    type: "small_airport",
    name: "S����o Louren����o do Sul Airport",
    elevation_ft: "28",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "S����o Louren����o Do Sul",
    gps_code: "SSRU",
    iata_code: "SQY",
    coordinates: "-52.032798767089844, -31.38330078125",
  },
  {
    ident: "SSS",
    type: "small_airport",
    name: "Siassi Airport",
    elevation_ft: "1250",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Siassi",
    iata_code: "SSS",
    local_code: "SSI",
    coordinates: "147.8106, -5.5965",
  },
  {
    ident: "SSSB",
    type: "small_airport",
    name: "S����o Borja Airport",
    elevation_ft: "246",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "S����o Borja",
    gps_code: "SSSB",
    iata_code: "JBS",
    local_code: "SSSB",
    coordinates: "-56.034599, -28.6549",
  },
  {
    ident: "SSSC",
    type: "small_airport",
    name: "Santa Cruz do Sul Airport",
    elevation_ft: "646",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Santa Cruz Do Sul",
    gps_code: "SSSC",
    iata_code: "CSU",
    local_code: "SSSC",
    coordinates: "-52.412200927734375, -29.684099197387695",
  },
  {
    ident: "SSSD",
    type: "small_airport",
    name: "Soledade Airport",
    elevation_ft: "2165",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Soledade",
    gps_code: "SSSD",
    iata_code: "0",
    coordinates: "-52.54059982299805, -28.863100051879883",
  },
  {
    ident: "SSSG",
    type: "small_airport",
    name: "S����o Gabriel Airport",
    elevation_ft: "472",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "S����o Gabriel",
    gps_code: "SSSG",
    iata_code: "0",
    coordinates: "-54.267799377441406, -30.344999313354492",
  },
  {
    ident: "SSSQ",
    type: "small_airport",
    name: "S����o Joaquim Airport",
    elevation_ft: "4462",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "S����o Joaquim",
    gps_code: "SSSQ",
    iata_code: "0",
    coordinates: "-49.91579818725586, -28.258899688720703",
  },
  {
    ident: "SSSZ",
    type: "small_airport",
    name: "Sertan����polis Airport",
    elevation_ft: "1181",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Sertan����polis",
    gps_code: "SSSZ",
    iata_code: "0",
    coordinates: "-51.01390075683594, -23.06220054626465",
  },
  {
    ident: "SSTL",
    type: "small_airport",
    name: "Pl����nio Alarcom Airport",
    elevation_ft: "1050",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MS",
    municipality: "Tr����s Lagoas",
    gps_code: "SBTG",
    iata_code: "TJL",
    coordinates: "-51.684200286865, -20.754199981689",
  },
  {
    ident: "SSTO",
    type: "small_airport",
    name: "Tr����s Passos Airport",
    elevation_ft: "1503",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Tr����s Passos",
    gps_code: "SSTO",
    iata_code: "0",
    coordinates: "-53.89830017089844, -27.512500762939453",
  },
  {
    ident: "SSU",
    type: "closed",
    name: "Greenbrier Airport",
    elevation_ft: "1795",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "White Sulphur Springs",
    iata_code: "SSU",
    coordinates: "-80.336, 37.775",
  },
  {
    ident: "SSUM",
    type: "small_airport",
    name: "Umuarama Airport",
    elevation_ft: "1558",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Umuarama",
    gps_code: "SSUM",
    iata_code: "UMU",
    local_code: "SSUM",
    coordinates: "-53.31380081176758, -23.7987003326416",
  },
  {
    ident: "SSUO",
    type: "heliport",
    name: "Unifly Heliport",
    elevation_ft: "2641",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Aruj����",
    gps_code: "SSUO",
    iata_code: "ZFU",
    coordinates: "-46.330554962200004, -23.4086112976",
  },
  {
    ident: "SSUX",
    type: "small_airport",
    name: "Usina Mandu Airport",
    elevation_ft: "1597",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Gua����ra",
    gps_code: "SSUX",
    iata_code: "0",
    coordinates: "-48.372798919677734, -20.482200622558594",
  },
  {
    ident: "SSV",
    type: "small_airport",
    name: "Siasi Airport",
    elevation_ft: "170",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-U-A",
    municipality: "Siasi Island",
    iata_code: "SSV",
    coordinates: "120.833, 5.558",
  },
  {
    ident: "SSVI",
    type: "small_airport",
    name: "Videira Airport",
    elevation_ft: "2756",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Videira",
    gps_code: "SSVI",
    iata_code: "VIA",
    local_code: "SSVI",
    coordinates: "-51.14189910888672, -26.99970054626465",
  },
  {
    ident: "SSVN",
    type: "small_airport",
    name: "Veran����polis Airport",
    elevation_ft: "2133",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Veran����polis",
    gps_code: "SSVN",
    iata_code: "0",
    coordinates: "-51.56829833984375, -28.934999465942383",
  },
  {
    ident: "SSVP",
    type: "small_airport",
    name: "Santa Vit����ria do Palmar Airport",
    elevation_ft: "82",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Santa Vit����ria Do Palmar",
    gps_code: "SSVP",
    iata_code: "CTQ",
    coordinates: "-53.34416580200195, -33.50222396850586",
  },
  {
    ident: "SSVR",
    type: "closed",
    name: "Volta Redonda Airport",
    elevation_ft: "1245",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RJ",
    municipality: "Volta Redonda",
    gps_code: "SSVR",
    iata_code: "QVR",
    coordinates: "-44.085, -22.4978",
  },
  {
    ident: "SSVY",
    type: "small_airport",
    name: "Fazenda Tr����s Barras Airport",
    elevation_ft: "1575",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Pitangueiras",
    gps_code: "SSVY",
    iata_code: "0",
    coordinates: "-48.173301696777344, -20.93779945373535",
  },
  {
    ident: "SSWS",
    type: "small_airport",
    name: "Ca����apava do Sul Airport",
    elevation_ft: "1312",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Ca����apava Do Sul",
    gps_code: "SSWS",
    iata_code: "0",
    coordinates: "-53.45920181274414, -30.549400329589844",
  },
  {
    ident: "SSXD",
    type: "small_airport",
    name: "Sarandi Airport",
    elevation_ft: "2034",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Sarandi",
    gps_code: "SSXD",
    iata_code: "0",
    coordinates: "-52.86360168457031, -27.98110008239746",
  },
  {
    ident: "SSXX",
    type: "small_airport",
    name: "Xanxer���� Airport",
    elevation_ft: "2986",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SC",
    municipality: "Xanxer����",
    gps_code: "SSXX",
    iata_code: "AXE",
    coordinates: "-52.373054504399995, -26.875556945800003",
  },
  {
    ident: "SSYA",
    type: "small_airport",
    name: "Avelino Vieira Airport",
    elevation_ft: "2641",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Arapoti",
    gps_code: "SSYA",
    iata_code: "AAG",
    local_code: "PR0020",
    coordinates: "-49.789101, -24.103901",
  },
  {
    ident: "SSZR",
    type: "small_airport",
    name: "Santa Rosa Airport",
    elevation_ft: "984",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RS",
    municipality: "Santa Rosa",
    gps_code: "SSZR",
    iata_code: "SRA",
    local_code: "SSZR",
    coordinates: "-54.520401, -27.9067",
  },
  {
    ident: "SSZS",
    type: "small_airport",
    name: "Centen����rio do Sul Airport",
    elevation_ft: "1591",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Centen����rio Do Sul",
    gps_code: "SSZS",
    iata_code: "0",
    coordinates: "-51.603599548339844, -22.830799102783203",
  },
  {
    ident: "SSZW",
    type: "medium_airport",
    name: "Ponta Grossa Airport - Comandante Antonio Amilton Beraldo",
    elevation_ft: "2588",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PR",
    municipality: "Ponta Grossa",
    gps_code: "SBPG",
    iata_code: "PGZ",
    local_code: "SBPG",
    coordinates: "-50.1441, -25.1847",
  },
  {
    ident: "SUAG",
    type: "small_airport",
    name: "Artigas International Airport",
    elevation_ft: "410",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-AR",
    municipality: "Artigas",
    gps_code: "SUAG",
    iata_code: "ATI",
    coordinates: "-56.50790023803711, -30.400699615478516",
  },
  {
    ident: "SUBU",
    type: "small_airport",
    name: "Bella Union Airport",
    elevation_ft: "70",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-AR",
    municipality: "Bella Union",
    gps_code: "SUBU",
    iata_code: "BUV",
    coordinates: "-57.0833320618, -30.3333339691",
  },
  {
    ident: "SUCA",
    type: "small_airport",
    name: "Laguna de Los Patos International Airport",
    elevation_ft: "66",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-CO",
    municipality: "Colonia del Sacramento",
    gps_code: "SUCA",
    iata_code: "CYR",
    coordinates: "-57.770599365234, -34.456401824951",
  },
  {
    ident: "SUDU",
    type: "medium_airport",
    name: "Santa Bernardina International Airport",
    elevation_ft: "305",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-DU",
    municipality: "Durazno",
    gps_code: "SUDU",
    iata_code: "DZO",
    coordinates: "-56.49919891357422, -33.3588981628418",
  },
  {
    ident: "SULS",
    type: "medium_airport",
    name: "Capitan Corbeta CA Curbelo International Airport",
    elevation_ft: "95",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-MA",
    municipality: "Punta del Este",
    gps_code: "SULS",
    iata_code: "PDP",
    coordinates: "-55.09429931640625, -34.855098724365234",
  },
  {
    ident: "SUMO",
    type: "small_airport",
    name: "Cerro Largo International Airport",
    elevation_ft: "364",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-CL",
    municipality: "Melo",
    gps_code: "SUMO",
    iata_code: "MLZ",
    coordinates: "-54.21670150756836, -32.33789825439453",
  },
  {
    ident: "SUMU",
    type: "large_airport",
    name: "Carrasco International /General C L Berisso Airport",
    elevation_ft: "105",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-CA",
    municipality: "Montevideo",
    gps_code: "SUMU",
    iata_code: "MVD",
    coordinates: "-56.0308, -34.838402",
  },
  {
    ident: "SUPU",
    type: "small_airport",
    name: "Tydeo Larre Borges Airport",
    elevation_ft: "138",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-PA",
    municipality: "Paysandu",
    gps_code: "SUPU",
    iata_code: "PDU",
    coordinates: "-58.0619010925293, -32.36330032348633",
  },
  {
    ident: "SURV",
    type: "small_airport",
    name: "Presidente General Don Oscar D. Gestido International Airport",
    elevation_ft: "712",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-RV",
    municipality: "Rivera",
    gps_code: "SURV",
    iata_code: "RVY",
    coordinates: "-55.476200103759766, -30.974599838256836",
  },
  {
    ident: "SUSO",
    type: "medium_airport",
    name: "Nueva Hesperides International Airport",
    elevation_ft: "187",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-SA",
    municipality: "Salto",
    gps_code: "SUSO",
    iata_code: "STY",
    coordinates: "-57.98529815673828, -31.438499450683594",
  },
  {
    ident: "SUTB",
    type: "small_airport",
    name: "Tacuarembo Airport",
    elevation_ft: "440",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-TA",
    municipality: "Tacuarembo",
    gps_code: "SUTB",
    iata_code: "TAW",
    coordinates: "-55.925801, -31.749001",
  },
  {
    ident: "SUTR",
    type: "small_airport",
    name: "Treinta y Tres Airport",
    elevation_ft: "337",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-TT",
    municipality: "Treinta y Tres",
    gps_code: "SUTR",
    iata_code: "TYT",
    coordinates: "-54.347246, -33.195714",
  },
  {
    ident: "SUVO",
    type: "small_airport",
    name: "Vichadero Airport",
    elevation_ft: "488",
    continent: "SA",
    iso_country: "UY",
    iso_region: "UY-RV",
    municipality: "Vichadero",
    gps_code: "SUVO",
    iata_code: "VCH",
    coordinates: "-54.617000579833984, -31.767000198364258",
  },
  {
    ident: "SUZ",
    type: "closed",
    name: "Suria Airport",
    elevation_ft: "2600",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Suria",
    iata_code: "SUZ",
    local_code: "SUR",
    coordinates: "147.45, -9.032",
  },
  {
    ident: "SVAC",
    type: "medium_airport",
    name: "Oswaldo Guevara Mujica Airport",
    elevation_ft: "640",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-P",
    municipality: "Acarigua",
    gps_code: "SVAC",
    iata_code: "AGV",
    coordinates: "-69.23786926269531, 9.553375244140625",
  },
  {
    ident: "SVAN",
    type: "medium_airport",
    name: "Anaco Airport",
    elevation_ft: "721",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-B",
    municipality: "Anaco",
    gps_code: "SVAN",
    iata_code: "AAO",
    coordinates: "-64.4707260131836, 9.430225372314453",
  },
  {
    ident: "SVAS",
    type: "small_airport",
    name: "Armando Schwarck Airport",
    elevation_ft: "266",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    municipality: "Guayabal",
    gps_code: "SVAS",
    iata_code: "LPJ",
    coordinates: "-66.81690216064453, 6.578050136566162",
  },
  {
    ident: "SVBC",
    type: "large_airport",
    name: "General Jos���� Antonio Anzoategui International Airport",
    elevation_ft: "30",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-B",
    municipality: "Barcelona",
    gps_code: "SVBC",
    iata_code: "BLA",
    coordinates: "-64.692222, 10.111111",
  },
  {
    ident: "SVBI",
    type: "medium_airport",
    name: "Barinas Airport",
    elevation_ft: "615",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-E",
    municipality: "Barinas",
    gps_code: "SVBI",
    iata_code: "BNS",
    coordinates: "-70.21416667, 8.615",
  },
  {
    ident: "SVBM",
    type: "medium_airport",
    name: "Barquisimeto International Airport",
    elevation_ft: "2042",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-K",
    municipality: "Barquisimeto",
    gps_code: "SVBM",
    iata_code: "BRM",
    coordinates: "-69.3586196899414, 10.042746543884277",
  },
  {
    ident: "SVBS",
    type: "medium_airport",
    name: "Escuela Mariscal Sucre Airport",
    elevation_ft: "1338",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-D",
    municipality: "Maracay",
    gps_code: "SVBS",
    iata_code: "MYC",
    coordinates: "-67.64942169189453, 10.249978065490723",
  },
  {
    ident: "SVCB",
    type: "medium_airport",
    name: 'Aeropuerto "General Tomas de Heres". Ciudad Bolivar',
    elevation_ft: "197",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    gps_code: "SVCB",
    iata_code: "CBL",
    coordinates: "-63.5369567871, 8.12216091156",
  },
  {
    ident: "SVCD",
    type: "medium_airport",
    name: "Caicara del Orinoco Airport",
    elevation_ft: "141",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    gps_code: "SVCD",
    iata_code: "CXA",
    coordinates: "-66.16280364990234, 7.625510215759277",
  },
  {
    ident: "SVCE",
    type: "small_airport",
    name: "Zaraza Airport",
    elevation_ft: "204",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-J",
    municipality: "Zaraza",
    gps_code: "SVCE",
    iata_code: "ZRZ",
    coordinates: "-65.280655, 9.350306",
  },
  {
    ident: "SVCG",
    type: "small_airport",
    name: "Casigua El Cubo Airport",
    elevation_ft: "99",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-V",
    municipality: "Casigua El Cubo",
    gps_code: "SVCG",
    iata_code: "CUV",
    coordinates: "-72.53630065917969, 8.758139610290527",
  },
  {
    ident: "SVCL",
    type: "medium_airport",
    name: "Calabozo Airport",
    elevation_ft: "328",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-J",
    municipality: "Guarico",
    gps_code: "SVCL",
    iata_code: "CLZ",
    coordinates: "-67.4170913696289, 8.92465591430664",
  },
  {
    ident: "SVCN",
    type: "medium_airport",
    name: "Canaima Airport",
    elevation_ft: "1450",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    municipality: "Canaima",
    gps_code: "SVCN",
    iata_code: "CAJ",
    coordinates: "-62.85443115234375, 6.231988906860352",
  },
  {
    ident: "SVCO",
    type: "medium_airport",
    name: "Carora Airport",
    elevation_ft: "1437",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-K",
    municipality: "Carora",
    gps_code: "SVCO",
    iata_code: "VCR",
    coordinates: "-70.06521606445312, 10.175602912902832",
  },
  {
    ident: "SVCP",
    type: "medium_airport",
    name: "General Francisco Berm����dez Airport",
    elevation_ft: "33",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-R",
    municipality: "Car����pano",
    gps_code: "SVCP",
    iata_code: "CUP",
    coordinates: "-63.261680603027344, 10.660014152526855",
  },
  {
    ident: "SVCR",
    type: "medium_airport",
    name: "Jos���� Leonardo Chirinos Airport",
    elevation_ft: "52",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-I",
    municipality: "Coro",
    gps_code: "SVCR",
    iata_code: "CZE",
    coordinates: "-69.68090057373047, 11.41494369506836",
  },
  {
    ident: "SVCU",
    type: "medium_airport",
    name: "Cuman���� (Antonio Jos���� de Sucre) Airport",
    elevation_ft: "14",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-R",
    gps_code: "SVCU",
    iata_code: "CUM",
    coordinates: "-64.1304702758789, 10.450332641601562",
  },
  {
    ident: "SVDZ",
    type: "small_airport",
    name: "Puerto Paez Airport",
    elevation_ft: "146",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-C",
    municipality: "Puerto Paez",
    gps_code: "SVDZ",
    iata_code: "PPZ",
    coordinates: "-67.433609008789, 6.2333331108093",
  },
  {
    ident: "SVED",
    type: "medium_airport",
    name: "El Dorado Airport",
    elevation_ft: "318",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    municipality: "Bolivar",
    gps_code: "SVED",
    iata_code: "EOR",
    coordinates: "-61.58333206176758, 6.733333110809326",
  },
  {
    ident: "SVEZ",
    type: "medium_airport",
    name: "Elorza Airport",
    elevation_ft: "295",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-C",
    gps_code: "SVEZ",
    iata_code: "EOZ",
    coordinates: "-69.53333282470703, 7.0833330154418945",
  },
  {
    ident: "SVGD",
    type: "medium_airport",
    name: "Guasdalito Airport",
    elevation_ft: "426",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-C",
    gps_code: "SVGD",
    iata_code: "GDO",
    coordinates: "-70.80000305175781, 7.233333110809326",
  },
  {
    ident: "SVGI",
    type: "medium_airport",
    name: "Guiria Airport",
    elevation_ft: "42",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-R",
    gps_code: "SVGI",
    iata_code: "GUI",
    coordinates: "-62.3126678467, 10.574077606200001",
  },
  {
    ident: "SVGU",
    type: "medium_airport",
    name: "Guanare Airport",
    elevation_ft: "606",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-P",
    municipality: "Guanare",
    gps_code: "SVGU",
    iata_code: "GUQ",
    coordinates: "-69.7551498413086, 9.026944160461426",
  },
  {
    ident: "SVHG",
    type: "small_airport",
    name: "Higuerote Airport",
    elevation_ft: "12",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-M",
    municipality: "Higuerote",
    gps_code: "SVHG",
    iata_code: "HGE",
    coordinates: "-66.092779, 10.462474",
  },
  {
    ident: "SVIC",
    type: "small_airport",
    name: "Icabar���� Airport",
    elevation_ft: "1574",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    gps_code: "SVIC",
    iata_code: "ICA",
    coordinates: "-61.739601135253906, 4.336319923400879",
  },
  {
    ident: "SVIE",
    type: "small_airport",
    name: "Andr����s Miguel Salazar Marcano Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-O",
    municipality: "Isla de Coche",
    gps_code: "SVIE",
    iata_code: "ICC",
    coordinates: "-63.98159, 10.794432",
  },
  {
    ident: "SVJC",
    type: "medium_airport",
    name: "Josefa Camejo International Airport",
    elevation_ft: "75",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-I",
    municipality: "Paraguan����",
    gps_code: "SVJC",
    iata_code: "LSP",
    coordinates: "-70.15149688720703, 11.78077507019043",
  },
  {
    ident: "SVKA",
    type: "small_airport",
    name: "Kavanayen Airport",
    elevation_ft: "3900",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    gps_code: "SVKA",
    iata_code: "KAV",
    coordinates: "-61.78300094604492, 5.632999897003174",
  },
  {
    ident: "SVLF",
    type: "medium_airport",
    name: "La Fria Airport",
    elevation_ft: "305",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-S",
    gps_code: "SVLF",
    iata_code: "LFR",
    coordinates: "-72.27102661132812, 8.239167213439941",
  },
  {
    ident: "SVMC",
    type: "medium_airport",
    name: "La Chinita International Airport",
    elevation_ft: "239",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-V",
    municipality: "Maracaibo",
    gps_code: "SVMC",
    iata_code: "MAR",
    coordinates: "-71.7278594971, 10.5582084656",
  },
  {
    ident: "SVMD",
    type: "medium_airport",
    name: "Alberto Carnevalli Airport",
    elevation_ft: "5007",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-L",
    municipality: "M����rida",
    gps_code: "SVMD",
    iata_code: "MRD",
    coordinates: "-71.161041, 8.582078",
  },
  {
    ident: "SVMG",
    type: "medium_airport",
    name: "Del Caribe Santiago Mari����o International Airport",
    elevation_ft: "74",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-O",
    municipality: "Isla Margarita",
    gps_code: "SVMG",
    iata_code: "PMV",
    coordinates: "-63.96659851074219, 10.912603378295898",
  },
  {
    ident: "SVMI",
    type: "large_airport",
    name: "Sim����n Bol����var International Airport",
    elevation_ft: "234",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-X",
    municipality: "Caracas",
    gps_code: "SVMI",
    iata_code: "CCS",
    coordinates: "-66.991222, 10.601194",
  },
  {
    ident: "SVMT",
    type: "medium_airport",
    name: "Matur����n Airport",
    elevation_ft: "224",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-N",
    gps_code: "SVMT",
    iata_code: "MUN",
    coordinates: "-63.14739990234375, 9.75452995300293",
  },
  {
    ident: "SVON",
    type: "small_airport",
    name: "Oro Negro Airport",
    elevation_ft: "164",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-V",
    municipality: "Cabimas",
    gps_code: "SVON",
    iata_code: "CBS",
    coordinates: "-71.32250213623047, 10.330699920654297",
  },
  {
    ident: "SVPA",
    type: "medium_airport",
    name: "Cacique Aramare Airport",
    elevation_ft: "245",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-Z",
    municipality: "Puerto Ayacucho",
    gps_code: "SVPA",
    iata_code: "PYH",
    coordinates: "-67.606101989746, 5.6199898719788",
  },
  {
    ident: "SVPC",
    type: "medium_airport",
    name: "General Bartolome Salom International Airport",
    elevation_ft: "32",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-G",
    gps_code: "SVPC",
    iata_code: "PBL",
    coordinates: "-68.072998046875, 10.480500221252441",
  },
  {
    ident: "SVPE",
    type: "small_airport",
    name: "Pedernales Airport",
    elevation_ft: "25",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-Y",
    gps_code: "SVPE",
    iata_code: "PDZ",
    coordinates: "-62.228599548339844, 9.979240417480469",
  },
  {
    ident: "SVPH",
    type: "small_airport",
    name: "Perai Tepuy Airport",
    elevation_ft: "2797",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    gps_code: "SVPH",
    iata_code: "PPH",
    coordinates: "-61.483333587646484, 4.566667079925537",
  },
  {
    ident: "SVPM",
    type: "medium_airport",
    name: "Paramillo Airport",
    elevation_ft: "3314",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-S",
    gps_code: "SVPM",
    iata_code: "SCI",
    coordinates: "-72.2029037475586, 7.8013200759887695",
  },
  {
    ident: "SVPR",
    type: "medium_airport",
    name: "General Manuel Carlos Piar International Airport",
    elevation_ft: "472",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    municipality: "Puerto Ordaz-Ciudad Guayana",
    gps_code: "SVPR",
    iata_code: "PZO",
    coordinates: "-62.760398864746094, 8.288530349731445",
  },
  {
    ident: "SVPT",
    type: "medium_airport",
    name: "Palmarito Airport",
    elevation_ft: "347",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-C",
    municipality: "Palmarito",
    gps_code: "SVPT",
    iata_code: "PTM",
    coordinates: "-70.18329620361328, 7.566669940948486",
  },
  {
    ident: "SVRS",
    type: "small_airport",
    name: "Los Roques Airport",
    elevation_ft: "17",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-W",
    municipality: "Gran Roque Island",
    gps_code: "SVRS",
    iata_code: "LRV",
    coordinates: "-66.66999816890001, 11.9499998093",
  },
  {
    ident: "SVS",
    type: "small_airport",
    name: "Stevens Village Airport",
    elevation_ft: "305",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Stevens Village",
    gps_code: "SVS",
    iata_code: "SVS",
    local_code: "SVS",
    coordinates: "-149.0545, 66.0172",
  },
  {
    ident: "SVSA",
    type: "medium_airport",
    name: "San Antonio Del Tachira Airport",
    elevation_ft: "1312",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-S",
    gps_code: "SVSA",
    iata_code: "SVZ",
    coordinates: "-72.439697265625, 7.840829849243164",
  },
  {
    ident: "SVSB",
    type: "small_airport",
    name: "Santa B����rbara de Barinas Airport",
    elevation_ft: "590",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-E",
    municipality: "Santa B����rbara",
    gps_code: "SVSB",
    iata_code: "SBB",
    coordinates: "-71.16571807861328, 7.803514003753662",
  },
  {
    ident: "SVSE",
    type: "medium_airport",
    name: "Santa Elena de Uairen Airport",
    elevation_ft: "2938",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    gps_code: "SVSE",
    iata_code: "SNV",
    coordinates: "-61.150001525878906, 4.554999828338623",
  },
  {
    ident: "SVSO",
    type: "medium_airport",
    name: "Mayor Buenaventura Vivas International Airport",
    elevation_ft: "1083",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-S",
    municipality: "Santo Domingo",
    gps_code: "SVSO",
    iata_code: "STD",
    coordinates: "-72.035103, 7.56538",
  },
  {
    ident: "SVSP",
    type: "medium_airport",
    name: "Sub Teniente Nestor Arias Airport",
    elevation_ft: "761",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-U",
    municipality: "San Felipe",
    gps_code: "SVSP",
    iata_code: "SNF",
    coordinates: "-68.755203, 10.2787",
  },
  {
    ident: "SVSR",
    type: "medium_airport",
    name: "San Fernando De Apure Airport",
    elevation_ft: "154",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-C",
    municipality: "Inglaterra",
    gps_code: "SVSR",
    iata_code: "SFD",
    coordinates: "-67.44400024414062, 7.883319854736328",
  },
  {
    ident: "SVST",
    type: "medium_airport",
    name: "San Tom���� Airport",
    elevation_ft: "861",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-B",
    municipality: "El Tigre",
    gps_code: "SVST",
    iata_code: "SOM",
    coordinates: "-64.151084899902, 8.9451465606689",
  },
  {
    ident: "SVSZ",
    type: "medium_airport",
    name: "Santa B����rbara del Zulia Airport",
    elevation_ft: "32",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-V",
    gps_code: "SVSZ",
    iata_code: "STB",
    coordinates: "-71.94325256347656, 8.974550247192383",
  },
  {
    ident: "SVTC",
    type: "medium_airport",
    name: "Tucupita Airport",
    elevation_ft: "16",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-Y",
    municipality: "Tucupita",
    gps_code: "SVTC",
    iata_code: "TUV",
    coordinates: "-62.094173431396484, 9.088994026184082",
  },
  {
    ident: "SVTM",
    type: "medium_airport",
    name: "Tumeremo Airport",
    elevation_ft: "345",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    gps_code: "SVTM",
    iata_code: "TMO",
    coordinates: "-61.52893, 7.24938",
  },
  {
    ident: "SVUM",
    type: "small_airport",
    name: "Uriman Airport",
    elevation_ft: "1148",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    gps_code: "SVUM",
    iata_code: "URM",
    coordinates: "-62.766666412353516, 5.3333330154418945",
  },
  {
    ident: "SVVA",
    type: "medium_airport",
    name: "Arturo Michelena International Airport",
    elevation_ft: "1411",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-G",
    municipality: "Valencia",
    gps_code: "SVVA",
    iata_code: "VLN",
    coordinates: "-67.92839813232422, 10.14973258972168",
  },
  {
    ident: "SVVG",
    type: "medium_airport",
    name: "Juan Pablo P����rez Alfonso Airport",
    elevation_ft: "250",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-L",
    municipality: "El Vig����a",
    gps_code: "SVVG",
    iata_code: "VIG",
    coordinates: "-71.672668, 8.624139",
  },
  {
    ident: "SVVL",
    type: "medium_airport",
    name: "Dr. Antonio Nicol����s Brice����o Airport",
    elevation_ft: "2060",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-T",
    municipality: "Valera",
    gps_code: "SVVL",
    iata_code: "VLV",
    coordinates: "-70.58406066894531, 9.34047794342041",
  },
  {
    ident: "SVVP",
    type: "medium_airport",
    name: "Valle de La Pascua Airport",
    elevation_ft: "410",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-J",
    gps_code: "SVVP",
    iata_code: "VDP",
    coordinates: "-65.9935836792, 9.22202777863",
  },
  {
    ident: "SWAY",
    type: "small_airport",
    name: "Araguaiana Airport",
    elevation_ft: "994",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Araguaiana",
    gps_code: "SWAY",
    iata_code: "0",
    coordinates: "-51.83639907836914, -15.723899841308594",
  },
  {
    ident: "SWBA",
    type: "small_airport",
    name: "Buriti Alegre Airport",
    elevation_ft: "2848",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Buriti Alegre",
    gps_code: "SWBA",
    iata_code: "0",
    coordinates: "-49.04280090332031, -18.125600814819336",
  },
  {
    ident: "SWBC",
    type: "small_airport",
    name: "Barcelos Airport",
    elevation_ft: "112",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Barcelos",
    gps_code: "SWBC",
    iata_code: "BAZ",
    local_code: "SWBC",
    coordinates: "-62.919601, -0.981292",
  },
  {
    ident: "SWBG",
    type: "small_airport",
    name: "Pontes e Lacerda Airport",
    elevation_ft: "870",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Pontes e Lacerda",
    gps_code: "SWBG",
    iata_code: "LCB",
    coordinates: "-59.3848, -15.1934",
  },
  {
    ident: "SWBR",
    type: "small_airport",
    name: "Borba Airport",
    elevation_ft: "293",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Borba",
    gps_code: "SWBR",
    iata_code: "RBB",
    local_code: "SWBR",
    coordinates: "-59.60240173339844, -4.4063401222229",
  },
  {
    ident: "SWCA",
    type: "small_airport",
    name: "Carauari Airport",
    elevation_ft: "355",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Carauari",
    gps_code: "SWCA",
    iata_code: "CAF",
    local_code: "SWCA",
    coordinates: "-66.89749908447266, -4.871520042419434",
  },
  {
    ident: "SWCQ",
    type: "small_airport",
    name: "Costa Marques Airport",
    elevation_ft: "555",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RO",
    municipality: "Costa Marques",
    gps_code: "SWCQ",
    iata_code: "CQS",
    local_code: "SWCQ",
    coordinates: "-64.25160217285156, -12.421099662780762",
  },
  {
    ident: "SWCZ",
    type: "small_airport",
    name: "Ceres Airport",
    elevation_ft: "1962",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Ceres",
    gps_code: "SWCZ",
    iata_code: "0",
    coordinates: "-49.604698181152344, -15.34469985961914",
  },
  {
    ident: "SWDM",
    type: "small_airport",
    name: "Diamantino Airport",
    elevation_ft: "1476",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Diamantino",
    gps_code: "SWDM",
    iata_code: "DMT",
    local_code: "SWDM",
    coordinates: "-56.40039825439453, -14.376899719238281",
  },
  {
    ident: "SWDN",
    type: "small_airport",
    name: "Dian����polis Airport",
    elevation_ft: "2001",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-TO",
    municipality: "Dian����polis",
    gps_code: "SWDN",
    iata_code: "DNO",
    coordinates: "-46.846698761, -11.5953998566",
  },
  {
    ident: "SWE",
    type: "small_airport",
    name: "Siwea Airport",
    elevation_ft: "5960",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Siwea",
    gps_code: "AYEW",
    iata_code: "SWE",
    local_code: "SIW",
    coordinates: "147.582371, -6.284181",
  },
  {
    ident: "SWEI",
    type: "small_airport",
    name: "Eirunep���� Airport",
    elevation_ft: "412",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Eirunep����",
    gps_code: "SWEI",
    iata_code: "ERN",
    local_code: "SWEI",
    coordinates: "-69.87979888916016, -6.639530181884766",
  },
  {
    ident: "SWEK",
    type: "small_airport",
    name: "Canarana Airport",
    elevation_ft: "1314",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Canarana",
    gps_code: "SWEK",
    iata_code: "CQA",
    coordinates: "-52.27055740356445, -13.574443817138672",
  },
  {
    ident: "SWFX",
    type: "small_airport",
    name: "S����o F����lix do Araguaia Airport",
    elevation_ft: "650",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "S����o F����lix Do Araguaia",
    gps_code: "SWFX",
    iata_code: "SXO",
    local_code: "SWFX",
    coordinates: "-50.68960189819336, -11.632399559020996",
  },
  {
    ident: "SWG",
    type: "small_airport",
    name: "Satwag Airport",
    elevation_ft: "4185",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Satwag",
    gps_code: "AYSW",
    iata_code: "SWG",
    local_code: "SWG",
    coordinates: "147.279166667, -6.13955555556",
  },
  {
    ident: "SWGI",
    type: "small_airport",
    name: "Gurupi Airport",
    elevation_ft: "1148",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-TO",
    municipality: "Gurupi",
    gps_code: "SWGI",
    iata_code: "GRP",
    local_code: "SWGI",
    coordinates: "-49.132198333740234, -11.73960018157959",
  },
  {
    ident: "SWGN",
    type: "medium_airport",
    name: "Aragua����na Airport",
    elevation_ft: "771",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-TO",
    municipality: "Aragua����na",
    gps_code: "SWGN",
    iata_code: "AUX",
    local_code: "SWGN",
    coordinates: "-48.240501, -7.22787",
  },
  {
    ident: "SWHT",
    type: "small_airport",
    name: "Humait���� Airport",
    elevation_ft: "230",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Humait����",
    gps_code: "SWHT",
    iata_code: "HUW",
    local_code: "SWHT",
    coordinates: "-63.072101593, -7.532120227810001",
  },
  {
    ident: "SWIA",
    type: "small_airport",
    name: "Iaciara Airport",
    elevation_ft: "1854",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Iaciara",
    gps_code: "SWIA",
    iata_code: "0",
    coordinates: "-46.66939926147461, -14.106399536132812",
  },
  {
    ident: "SWII",
    type: "small_airport",
    name: "Ipiranga Airport",
    elevation_ft: "131",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Santo Ant����nio Do I��������",
    gps_code: "SWII",
    iata_code: "IPG",
    local_code: "SWII",
    coordinates: "-69.69400024414062, -2.939069986343384",
  },
  {
    ident: "SWIY",
    type: "small_airport",
    name: "Santa Izabel do Morro Airport",
    elevation_ft: "647",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-TO",
    municipality: "Cristal����ndia",
    gps_code: "SWIY",
    iata_code: "IDO",
    local_code: "SWIY",
    coordinates: "-50.66619873046875, -11.57229995727539",
  },
  {
    ident: "SWJI",
    type: "small_airport",
    name: "Ji-Paran���� Airport",
    elevation_ft: "598",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RO",
    municipality: "Ji-Paran����",
    gps_code: "SBJI",
    iata_code: "JPR",
    coordinates: "-61.8465003967, -10.870800018299999",
  },
  {
    ident: "SWJN",
    type: "small_airport",
    name: "Ju����na Airport",
    elevation_ft: "1083",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Ju����na",
    gps_code: "SWJN",
    iata_code: "JIA",
    coordinates: "-58.701668, -11.419444",
  },
  {
    ident: "SWJU",
    type: "small_airport",
    name: "Juruena Airport",
    elevation_ft: "525",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Juruena",
    gps_code: "SWJU",
    iata_code: "JRN",
    coordinates: "-58.489444732666016, -10.305832862854004",
  },
  {
    ident: "SWJW",
    type: "small_airport",
    name: "Jata���� Airport",
    elevation_ft: "2529",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Jata����",
    gps_code: "SWJW",
    iata_code: "JTI",
    local_code: "SWJW",
    coordinates: "-51.7729988098, -17.8299007416",
  },
  {
    ident: "SWKC",
    type: "small_airport",
    name: "C����ceres Airport",
    elevation_ft: "492",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "C����ceres",
    gps_code: "SWKC",
    iata_code: "CCX",
    local_code: "SWKC",
    coordinates: "-57.62990188598633, -16.04360008239746",
  },
  {
    ident: "SWKO",
    type: "small_airport",
    name: "Coari Airport",
    elevation_ft: "131",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Coari",
    gps_code: "SWKO",
    iata_code: "CIZ",
    local_code: "SWKO",
    coordinates: "-63.132598876953125, -4.134059906005859",
  },
  {
    ident: "SWKT",
    type: "small_airport",
    name: "Catal����o Airport",
    elevation_ft: "2612",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Catal����o",
    gps_code: "SWKT",
    iata_code: "TLZ",
    local_code: "SWKT",
    coordinates: "-47.89970016479492, -18.216800689697266",
  },
  {
    ident: "SWKX",
    type: "small_airport",
    name: "Corix���� Airport",
    elevation_ft: "394",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "C����ceres",
    gps_code: "SWKX",
    iata_code: "0",
    coordinates: "-58.31719970703125, -16.38360023498535",
  },
  {
    ident: "SWLB",
    type: "small_airport",
    name: "L����brea Airport",
    elevation_ft: "190",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "L����brea",
    gps_code: "SWLB",
    iata_code: "LBR",
    local_code: "SWLB",
    coordinates: "-64.76950073242188, -7.278969764709473",
  },
  {
    ident: "SWLC",
    type: "small_airport",
    name: "General Leite de Castro Airport",
    elevation_ft: "2464",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Rio Verde",
    gps_code: "SWLC",
    iata_code: "RVD",
    local_code: "SWLC",
    coordinates: "-50.956111907958984, -17.8347225189209",
  },
  {
    ident: "SWMW",
    type: "small_airport",
    name: "Mau����s Airport",
    elevation_ft: "69",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Mau����s",
    gps_code: "SWMW",
    iata_code: "MBZ",
    local_code: "SWMW",
    coordinates: "-57.7248, -3.37217",
  },
  {
    ident: "SWNA",
    type: "small_airport",
    name: "Novo Aripuan���� Airport",
    elevation_ft: "118",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Novo Aripuan����",
    gps_code: "SWNA",
    iata_code: "NVP",
    local_code: "SWNA",
    coordinates: "-60.364898681640625, -5.118030071258545",
  },
  {
    ident: "SWNI",
    type: "small_airport",
    name: "Nova Vida Airport",
    elevation_ft: "410",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RO",
    municipality: "Ariquemes",
    gps_code: "SWNI",
    iata_code: "AQM",
    coordinates: "-62.825557708740234, -10.178055763244629",
  },
  {
    ident: "SWNK",
    type: "small_airport",
    name: "Novo Campo Airport",
    elevation_ft: "394",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Boca do Acre",
    gps_code: "SWNK",
    iata_code: "BCR",
    local_code: "SWNK",
    coordinates: "-67.312401, -8.83456",
  },
  {
    ident: "SWNQ",
    type: "small_airport",
    name: "Niquel����ndia Airport",
    elevation_ft: "2756",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "Niquel����ndia",
    gps_code: "SWNQ",
    iata_code: "NQL",
    local_code: "SWNQ",
    coordinates: "-48.49150085449219, -14.434900283813477",
  },
  {
    ident: "SWNS",
    type: "small_airport",
    name: "An����polis Airport",
    elevation_ft: "3648",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "An����polis",
    gps_code: "SWNS",
    iata_code: "APS",
    local_code: "SWNS",
    coordinates: "-48.9271011353, -16.3623008728",
  },
  {
    ident: "SWOB",
    type: "small_airport",
    name: "Fonte Boa Airport",
    elevation_ft: "207",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Fonte Boa",
    gps_code: "SWOB",
    iata_code: "FBA",
    local_code: "SWOB",
    coordinates: "-66.0831985474, -2.5326099395800004",
  },
  {
    ident: "SWPG",
    type: "small_airport",
    name: "Porto dos Ga����chos Airport",
    elevation_ft: "1312",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Porto Dos Ga����chos",
    gps_code: "SWPG",
    iata_code: "PBV",
    local_code: "SWPG",
    coordinates: "-57.3782, -11.5404",
  },
  {
    ident: "SWPI",
    type: "small_airport",
    name: "Parintins Airport",
    elevation_ft: "87",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Parintins",
    gps_code: "SWPI",
    iata_code: "PIN",
    local_code: "SWPI",
    coordinates: "-56.777198791503906, -2.6730198860168457",
  },
  {
    ident: "SWPM",
    type: "small_airport",
    name: "Pimenta Bueno Airport",
    elevation_ft: "682",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-RO",
    municipality: "Pimenta Bueno",
    gps_code: "SWPM",
    iata_code: "PBQ",
    local_code: "SWPM",
    coordinates: "-61.179100036621094, -11.641599655151367",
  },
  {
    ident: "SWPQ",
    type: "small_airport",
    name: "Fazenda Piraguassu Airport",
    elevation_ft: "705",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Porto Alegre Do Norte",
    gps_code: "SWPQ",
    iata_code: "PBX",
    coordinates: "-51.685001373291, -10.861110687256",
  },
  {
    ident: "SWR",
    type: "small_airport",
    name: "Silur Airport",
    elevation_ft: "217",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Silur Mission",
    gps_code: "AYZI",
    iata_code: "SWR",
    local_code: "SLR",
    coordinates: "153.054444444, -4.5298888888899995",
  },
  {
    ident: "SWRA",
    type: "small_airport",
    name: "Arraias Airport",
    elevation_ft: "1923",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-TO",
    municipality: "Arraias",
    gps_code: "SWRA",
    iata_code: "AAI",
    coordinates: "-46.884107, -13.025154",
  },
  {
    ident: "SWRD",
    type: "small_airport",
    name: "Maestro Marinho Franco Airport",
    elevation_ft: "1467",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Rondon����polis",
    gps_code: "SBRD",
    iata_code: "ROO",
    local_code: "SBRD",
    coordinates: "-54.7248, -16.586",
  },
  {
    ident: "SWRP",
    type: "small_airport",
    name: "Aripuan���� Airport",
    elevation_ft: "623",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Aripuan����",
    gps_code: "SSOU",
    iata_code: "AIR",
    local_code: "SSOU",
    coordinates: "-59.457273, -10.188278",
  },
  {
    ident: "SWSI",
    type: "small_airport",
    name: "Presidente Jo����o Batista Figueiredo Airport",
    elevation_ft: "1227",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Sinop",
    gps_code: "SBSI",
    iata_code: "OPS",
    local_code: "MT0002",
    coordinates: "-55.586109, -11.885001",
  },
  {
    ident: "SWST",
    type: "small_airport",
    name: "Santa Terezinha Airport",
    elevation_ft: "663",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Santa Terezinha",
    gps_code: "SWST",
    iata_code: "STZ",
    coordinates: "-50.518611907958984, -10.4647216796875",
  },
  {
    ident: "SWTP",
    type: "small_airport",
    name: "Tapuruquara Airport",
    elevation_ft: "223",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Santa Isabel Do Rio Negro",
    gps_code: "SWTP",
    iata_code: "IRZ",
    coordinates: "-64.9923, -0.3786",
  },
  {
    ident: "SWTS",
    type: "small_airport",
    name: "Tangar���� da Serra Airport",
    elevation_ft: "1460",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Tangar���� Da Serra",
    gps_code: "SWTS",
    iata_code: "TGQ",
    local_code: "SWTS",
    coordinates: "-57.4435005188, -14.661999702500001",
  },
  {
    ident: "SWTU",
    type: "small_airport",
    name: "Fazenda Tucunar���� Airport",
    elevation_ft: "1814",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Sapezal",
    gps_code: "SWTU",
    iata_code: "AZL",
    local_code: "MT0410",
    coordinates: "-58.866935, -13.465528",
  },
  {
    ident: "SWTY",
    type: "small_airport",
    name: "Taguatinga Airport",
    elevation_ft: "1959",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-TO",
    municipality: "Taguatinga",
    gps_code: "SWTY",
    iata_code: "QHN",
    coordinates: "-46.40055465698242, -12.433889389038086",
  },
  {
    ident: "SWUA",
    type: "small_airport",
    name: "S����o Miguel do Araguaia Airport",
    elevation_ft: "1249",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-GO",
    municipality: "S����o Miguel Do Araguaia",
    gps_code: "SWUA",
    iata_code: "SQM",
    local_code: "SWUA",
    coordinates: "-50.197601318359375, -13.331299781799316",
  },
  {
    ident: "SWVB",
    type: "small_airport",
    name: "Vila Bela da Sant����ssima Trindade Airport",
    elevation_ft: "660",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Vila Bela Da Sant����ssima Trindade",
    gps_code: "SWVB",
    iata_code: "MTG",
    local_code: "SWVB",
    coordinates: "-59.9458, -14.9942",
  },
  {
    ident: "SWVC",
    type: "small_airport",
    name: "Vila Rica Airport",
    elevation_ft: "892",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Vila Rica",
    gps_code: "SWVC",
    iata_code: "VLP",
    coordinates: "-51.1422233581543, -9.979443550109863",
  },
  {
    ident: "SWXM",
    type: "small_airport",
    name: "Regional Orlando Villas Boas Airport",
    elevation_ft: "886",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Matup����",
    gps_code: "SWXM",
    iata_code: "MBK",
    coordinates: "-54.9527778625, -10.170277595500002",
  },
  {
    ident: "SWXV",
    type: "small_airport",
    name: "Xavantina Airport",
    elevation_ft: "1035",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-MT",
    municipality: "Nova Xavantina",
    gps_code: "SWXV",
    iata_code: "NOK",
    coordinates: "-52.34640121459961, -14.6983003616333",
  },
  {
    ident: "SXH",
    type: "small_airport",
    name: "Sehulea Airport",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Sehulea",
    gps_code: "AYSL",
    iata_code: "SXH",
    local_code: "SEH",
    coordinates: "151.161861111, -9.96452777778",
  },
  {
    ident: "SXP",
    type: "small_airport",
    name: "Nunam Iqua Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nunam Iqua",
    iata_code: "SXP",
    local_code: "SXP",
    coordinates: "-164.848006, 62.520599",
  },
  {
    ident: "SYAH",
    type: "small_airport",
    name: "Aishalton Airport",
    elevation_ft: "587",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-UT",
    municipality: "Aishalton",
    gps_code: "SYAH",
    iata_code: "AHL",
    coordinates: "-59.31340026855469, 2.486530065536499",
  },
  {
    ident: "SYAN",
    type: "small_airport",
    name: "Annai Airport",
    elevation_ft: "301",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-UT",
    municipality: "Annai",
    gps_code: "SYAN",
    iata_code: "NAI",
    coordinates: "-59.12419891357422, 3.959439992904663",
  },
  {
    ident: "SYB",
    type: "small_airport",
    name: "Seal Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Seal Bay",
    iata_code: "SYB",
    coordinates: "-152.2018, 58.3733",
  },
  {
    ident: "SYBR",
    type: "small_airport",
    name: "Baramita Airport",
    elevation_ft: "328",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-BA",
    municipality: "Baramita",
    gps_code: "SYBR",
    iata_code: "BMJ",
    coordinates: "-60.487998962402344, 7.370120048522949",
  },
  {
    ident: "SYBT",
    type: "small_airport",
    name: "Bartica A Airport",
    elevation_ft: "3",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-CU",
    municipality: "Bartica",
    gps_code: "SYBT",
    iata_code: "GFO",
    coordinates: "-58.655207, 6.358864",
  },
  {
    ident: "SYCJ",
    type: "medium_airport",
    name: "Cheddi Jagan International Airport",
    elevation_ft: "95",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-DE",
    municipality: "Georgetown",
    gps_code: "SYCJ",
    iata_code: "GEO",
    local_code: "SYGT",
    coordinates: "-58.25410079956055, 6.498549938201904",
  },
  {
    ident: "SYF",
    type: "seaplane_base",
    name: "Silva Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Gabriola Island",
    iata_code: "SYF",
    coordinates: "-123.696, 49.15",
  },
  {
    ident: "SYGO",
    type: "medium_airport",
    name: "Eugene F. Correira International Airport",
    elevation_ft: "10",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-DE",
    municipality: "Ogle",
    gps_code: "SYEC",
    iata_code: "OGL",
    coordinates: "-58.1059, 6.80628",
  },
  {
    ident: "SYIB",
    type: "small_airport",
    name: "Imbaimadai Airport",
    elevation_ft: "1646",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-CU",
    municipality: "Imbaimadai",
    gps_code: "SYIB",
    iata_code: "IMB",
    coordinates: "-60.2942008972168, 5.7081098556518555",
  },
  {
    ident: "SYKM",
    type: "small_airport",
    name: "Kamarang Airport",
    elevation_ft: "1601",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-CU",
    municipality: "Kamarang",
    gps_code: "SYKM",
    iata_code: "KAR",
    coordinates: "-60.614200592041016, 5.865340232849121",
  },
  {
    ident: "SYKR",
    type: "small_airport",
    name: "Karanambo Airport",
    elevation_ft: "300",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-UT",
    municipality: "Karanambo",
    gps_code: "SYKR",
    iata_code: "KRM",
    coordinates: "-59.30970001220703, 3.7519400119781494",
  },
  {
    ident: "SYKS",
    type: "small_airport",
    name: "Karasabai Airport",
    elevation_ft: "731",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-UT",
    municipality: "Karasabai",
    gps_code: "SYKS",
    iata_code: "KRG",
    coordinates: "-59.53329849243164, 4.033329963684082",
  },
  {
    ident: "SYKT",
    type: "small_airport",
    name: "Kato Airport",
    elevation_ft: "2299",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-PT",
    municipality: "Kato",
    gps_code: "SYKT",
    iata_code: "KTO",
    coordinates: "-59.83219909667969, 4.649159908294678",
  },
  {
    ident: "SYKZ",
    type: "small_airport",
    name: "Konawaruk Airport",
    elevation_ft: "230",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-PT",
    municipality: "Konawaruk",
    gps_code: "SYKZ",
    iata_code: "KKG",
    coordinates: "-58.995, 5.2684",
  },
  {
    ident: "SYL",
    type: "heliport",
    name: "Roberts Army Heliport",
    elevation_ft: "630",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Camp Roberts/San Miguel",
    gps_code: "KSYL",
    iata_code: "SYL",
    local_code: "SYL",
    coordinates: "-120.744003296, 35.814998626699996",
  },
  {
    ident: "SYLP",
    type: "small_airport",
    name: "Lumid Pau Airport",
    elevation_ft: "656",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-UT",
    municipality: "Lumid Pau",
    gps_code: "SYLP",
    iata_code: "LUB",
    coordinates: "-59.4410018921, 2.3939299583399998",
  },
  {
    ident: "SYLT",
    type: "medium_airport",
    name: "Lethem Airport",
    elevation_ft: "351",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-UT",
    municipality: "Lethem",
    gps_code: "SYLT",
    iata_code: "LTM",
    coordinates: "-59.789398, 3.37276",
  },
  {
    ident: "SYMB",
    type: "small_airport",
    name: "Mabaruma Airport",
    elevation_ft: "45",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-BA",
    municipality: "Mabaruma",
    gps_code: "SYMB",
    iata_code: "USI",
    coordinates: "-59.78329849243164, 8.199999809265137",
  },
  {
    ident: "SYMD",
    type: "small_airport",
    name: "Mahdia Airport",
    elevation_ft: "300",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-PT",
    municipality: "Mahdia",
    gps_code: "SYMD",
    iata_code: "MHA",
    coordinates: "-59.151100158691406, 5.277490139007568",
  },
  {
    ident: "SYMK",
    type: "small_airport",
    name: "Maikwak Airport",
    elevation_ft: "2001",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-PT",
    municipality: "Maikwak",
    gps_code: "SYMK",
    iata_code: "VEG",
    coordinates: "-59.817027, 4.898172",
  },
  {
    ident: "SYMM",
    type: "small_airport",
    name: "Monkey Mountain Airport",
    elevation_ft: "1456",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-PT",
    municipality: "Monkey Mountain",
    gps_code: "SYMM",
    iata_code: "MYM",
    coordinates: "-59.68330001831055, 4.483329772949219",
  },
  {
    ident: "SYMR",
    type: "small_airport",
    name: "Matthews Ridge Airport",
    elevation_ft: "324",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-BA",
    municipality: "Matthews Ridge",
    gps_code: "SYMR",
    iata_code: "MWJ",
    coordinates: "-60.184777, 7.488112",
  },
  {
    ident: "SYN",
    type: "small_airport",
    name: "Stanton Airfield",
    elevation_ft: "920",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MN",
    municipality: "Stanton",
    gps_code: "KSYN",
    iata_code: "SYN",
    local_code: "SYN",
    coordinates: "-93.0162963867, 44.475498199499995",
  },
  {
    ident: "SYNA",
    type: "small_airport",
    name: "New Amsterdam Airport",
    elevation_ft: "3",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-EB",
    municipality: "New Amsterdam",
    gps_code: "SYNA",
    iata_code: "QSX",
    coordinates: "-57.474172, 6.244324",
  },
  {
    ident: "SYOR",
    type: "small_airport",
    name: "Orinduik Airport",
    elevation_ft: "1797",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-PT",
    municipality: "Orinduik",
    gps_code: "SYOR",
    iata_code: "ORJ",
    coordinates: "-60.035, 4.72527",
  },
  {
    ident: "SYPM",
    type: "small_airport",
    name: "Paramakatoi Airport",
    elevation_ft: "2600",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-PT",
    municipality: "Paramakatoi",
    gps_code: "SYPM",
    iata_code: "PMT",
    coordinates: "-59.7125, 4.6975",
  },
  {
    ident: "SYPR",
    type: "small_airport",
    name: "Paruma Airport",
    elevation_ft: "1765",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-CU",
    municipality: "Paruma",
    gps_code: "SYPR",
    iata_code: "PRR",
    coordinates: "-61.05540084838867, 5.815450191497803",
  },
  {
    ident: "SYSC",
    type: "small_airport",
    name: "Sand Creek Airport",
    elevation_ft: "360",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-UT",
    municipality: "Sand Creek",
    gps_code: "SYSC",
    iata_code: "SDC",
    coordinates: "-59.51, 2.9913",
  },
  {
    ident: "SYSK",
    type: "small_airport",
    name: "Skeldon Airport",
    elevation_ft: "13",
    continent: "SA",
    iso_country: "GY",
    iso_region: "GY-EB",
    municipality: "Skeldon",
    gps_code: "SYSK",
    iata_code: "SKM",
    coordinates: "-57.14894, 5.8599",
  },
  {
    ident: "SZN",
    type: "small_airport",
    name: "Santa Cruz Island Airport",
    elevation_ft: "50",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Santa Barbara",
    gps_code: "KSZN",
    iata_code: "SZN",
    local_code: "SZN",
    coordinates: "-119.915321, 34.060197",
  },
  {
    ident: "SZP",
    type: "small_airport",
    name: "Santa Paula Airport",
    elevation_ft: "243",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Santa Paula",
    gps_code: "KSZP",
    iata_code: "SZP",
    local_code: "SZP",
    coordinates: "-119.060997, 34.34719849",
  },
  {
    ident: "TAA",
    type: "closed",
    name: "Tarapaina Airport",
    elevation_ft: "80",
    continent: "OC",
    iso_country: "SB",
    iso_region: "SB-ML",
    municipality: "Tarapaina",
    iata_code: "TAA",
    coordinates: "161.358, -9.414",
  },
  {
    ident: "TAPA",
    type: "medium_airport",
    name: "V.C. Bird International Airport",
    elevation_ft: "62",
    continent: "NA",
    iso_country: "AG",
    iso_region: "AG-03",
    municipality: "St. John's",
    gps_code: "TAPA",
    iata_code: "ANU",
    coordinates: "-61.792702, 17.1367",
  },
  {
    ident: "TAPH",
    type: "small_airport",
    name: "Codrington Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "AG",
    iso_region: "AG-10",
    municipality: "Codrington",
    gps_code: "TAPH",
    iata_code: "BBQ",
    coordinates: "-61.828602, 17.635799",
  },
  {
    ident: "TBA",
    type: "closed",
    name: "Tabibuga Airport",
    elevation_ft: "4400",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-JI",
    municipality: "Tabibuga",
    iata_code: "TBA",
    coordinates: "144.6508, -5.5766",
  },
  {
    ident: "TBE",
    type: "small_airport",
    name: "Timbunke Airport",
    elevation_ft: "24",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Timbunke",
    gps_code: "AYTV",
    iata_code: "TBE",
    local_code: "TIM",
    coordinates: "143.519222, -4.196633",
  },
  {
    ident: "TBPB",
    type: "medium_airport",
    name: "Grantley Adams International Airport",
    elevation_ft: "169",
    continent: "NA",
    iso_country: "BB",
    iso_region: "BB-01",
    municipality: "Bridgetown",
    gps_code: "TBPB",
    iata_code: "BGI",
    coordinates: "-59.4925, 13.0746",
  },
  {
    ident: "TBQ",
    type: "small_airport",
    name: "Tarabo Airport",
    elevation_ft: "6100",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EHG",
    municipality: "Tarabo",
    gps_code: "AYTR",
    iata_code: "TBQ",
    local_code: "TRB",
    coordinates: "145.532222222, -6.46666666667",
  },
  {
    ident: "TBV",
    type: "small_airport",
    name: "Tabal Airstrip",
    elevation_ft: "32",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-AUR",
    municipality: "Tabal Island",
    iata_code: "TBV",
    coordinates: "171.1615, 8.3027",
  },
  {
    ident: "TCK",
    type: "small_airport",
    name: "Tinboli Airport",
    elevation_ft: "59",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-ESW",
    municipality: "Tinboli",
    gps_code: "AYYL",
    iata_code: "TCK",
    local_code: "TOI",
    coordinates: "143.3838, -4.0949",
  },
  {
    ident: "TCT",
    type: "small_airport",
    name: "Takotna Airport",
    elevation_ft: "825",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Takotna",
    gps_code: "PPCT",
    iata_code: "TCT",
    local_code: "TCT",
    coordinates: "-156.029026, 62.993206",
  },
  {
    ident: "TDB",
    type: "small_airport",
    name: "Tetebedi Airport",
    elevation_ft: "3365",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Tetebedi",
    gps_code: "AYTF",
    iata_code: "TDB",
    local_code: "TEB",
    coordinates: "148.0686, -9.1586",
  },
  {
    ident: "TDCF",
    type: "medium_airport",
    name: "Canefield Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "DM",
    iso_region: "DM-10",
    municipality: "Canefield",
    gps_code: "TDCF",
    iata_code: "DCF",
    coordinates: "-61.3922004699707, 15.336700439453125",
  },
  {
    ident: "TDPD",
    type: "medium_airport",
    name: "Douglas-Charles Airport",
    elevation_ft: "73",
    continent: "NA",
    iso_country: "DM",
    iso_region: "DM-02",
    municipality: "Marigot",
    gps_code: "TDPD",
    iata_code: "DOM",
    coordinates: "-61.299999, 15.547",
  },
  {
    ident: "TDS",
    type: "small_airport",
    name: "Sasereme Airport",
    elevation_ft: "121",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Sasereme",
    gps_code: "AYSS",
    iata_code: "TDS",
    coordinates: "142.868, -7.6217",
  },
  {
    ident: "TEO",
    type: "small_airport",
    name: "Terapo Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-GPK",
    municipality: "Terapo Mission",
    gps_code: "AYTY",
    iata_code: "TEO",
    local_code: "TPO",
    coordinates: "146.194444444, -8.16972222222",
  },
  {
    ident: "TFB",
    type: "small_airport",
    name: "Tifalmin Airport",
    elevation_ft: "4735",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Tifalmin",
    gps_code: "AYTH",
    iata_code: "TFB",
    local_code: "TFM",
    coordinates: "141.419, -5.1173",
  },
  {
    ident: "TFFA",
    type: "small_airport",
    name: "La D����sirade Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "GP",
    iso_region: "GP-U-A",
    municipality: "Grande Anse",
    gps_code: "TFFA",
    iata_code: "DSD",
    coordinates: "-61.0844, 16.296902",
  },
  {
    ident: "TFFB",
    type: "small_airport",
    name: "Baillif Airport",
    elevation_ft: "59",
    continent: "NA",
    iso_country: "GP",
    iso_region: "GP-U-A",
    municipality: "Basse Terre",
    gps_code: "TFFB",
    iata_code: "BBR",
    coordinates: "-61.7421989440918, 16.0132999420166",
  },
  {
    ident: "TFFC",
    type: "small_airport",
    name: "St-Fran����ois Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "GP",
    iso_region: "GP-U-A",
    municipality: "St-Fran����ois",
    gps_code: "TFFC",
    iata_code: "SFC",
    coordinates: "-61.26250076293945, 16.25779914855957",
  },
  {
    ident: "TFFF",
    type: "medium_airport",
    name: "Martinique Aim���� C����saire International Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "MQ",
    iso_region: "MQ-U-A",
    municipality: "Fort-de-France",
    gps_code: "TFFF",
    iata_code: "FDF",
    coordinates: "-61.00320053100586, 14.590999603271484",
  },
  {
    ident: "TFFG",
    type: "medium_airport",
    name: "L'Esp����rance Airport",
    elevation_ft: "7",
    continent: "NA",
    iso_country: "MF",
    iso_region: "MF-U-A",
    municipality: "Grand Case",
    gps_code: "TFFG",
    iata_code: "SFG",
    coordinates: "-63.047199, 18.099899",
  },
  {
    ident: "TFFJ",
    type: "medium_airport",
    name: "Gustaf III Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "BL",
    iso_region: "BL-U-A",
    municipality: "Gustavia",
    gps_code: "TFFJ",
    iata_code: "SBH",
    coordinates: "-62.84360122680664, 17.904399871826172",
  },
  {
    ident: "TFFM",
    type: "medium_airport",
    name: "Les Bases Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "GP",
    iso_region: "GP-U-A",
    municipality: "Grand Bourg",
    gps_code: "TFFM",
    iata_code: "GBJ",
    coordinates: "-61.27000045776367, 15.86870002746582",
  },
  {
    ident: "TFFR",
    type: "large_airport",
    name: "Pointe-����-Pitre Le Raizet",
    elevation_ft: "36",
    continent: "NA",
    iso_country: "GP",
    iso_region: "GP-U-A",
    municipality: "Pointe-����-Pitre",
    gps_code: "TFFR",
    iata_code: "PTP",
    coordinates: "-61.531799, 16.265301",
  },
  {
    ident: "TFFS",
    type: "small_airport",
    name: "Terre-de-Haut Airport",
    elevation_ft: "46",
    continent: "NA",
    iso_country: "GP",
    iso_region: "GP-U-A",
    municipality: "Les Saintes",
    gps_code: "TFFS",
    iata_code: "LSS",
    coordinates: "-61.5806007385, 15.86439991",
  },
  {
    ident: "TFY",
    type: "small_airport",
    name: "Tarfaya Airport",
    elevation_ft: "20",
    continent: "AF",
    iso_country: "MA",
    iso_region: "MA-LAA",
    municipality: "Tarfaya",
    iata_code: "TFY",
    coordinates: "-12.9166, 27.9487",
  },
  {
    ident: "TGL",
    type: "small_airport",
    name: "Tagula Airport",
    elevation_ft: "59",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Sudest Island",
    gps_code: "AYTG",
    iata_code: "TGL",
    local_code: "TAG",
    coordinates: "153.202916667, -11.3311111111",
  },
  {
    ident: "TGPY",
    type: "medium_airport",
    name: "Point Salines International Airport",
    elevation_ft: "41",
    continent: "NA",
    iso_country: "GD",
    iso_region: "GD-GE",
    municipality: "Saint George's",
    gps_code: "TGPY",
    iata_code: "GND",
    coordinates: "-61.78620147705078, 12.004199981689453",
  },
  {
    ident: "TGPZ",
    type: "small_airport",
    name: "Lauriston Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "GD",
    iso_region: "GD-PA",
    municipality: "Carriacou Island",
    gps_code: "TGPZ",
    iata_code: "CRU",
    coordinates: "-61.472801208496094, 12.476099967956543",
  },
  {
    ident: "THW",
    type: "seaplane_base",
    name: "Trincomalee Harbor Waterdrome",
    elevation_ft: "0",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-5",
    municipality: "Trincomalee",
    iata_code: "THW",
    coordinates: "81.22, 8.56",
  },
  {
    ident: "TIG",
    type: "closed",
    name: "Tingwon Airport",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NIK",
    municipality: "Tingwon Island",
    iata_code: "TIG",
    coordinates: "149.7107, -2.6053",
  },
  {
    ident: "TIST",
    type: "medium_airport",
    name: "Cyril E. King Airport",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "VI",
    iso_region: "VI-U-A",
    municipality: "Charlotte Amalie, Harry S. Truman Airport",
    gps_code: "TIST",
    iata_code: "STT",
    local_code: "STT",
    coordinates: "-64.97339630126953, 18.337299346923828",
  },
  {
    ident: "TISX",
    type: "medium_airport",
    name: "Henry E Rohlsen Airport",
    elevation_ft: "74",
    continent: "NA",
    iso_country: "VI",
    iso_region: "VI-U-A",
    municipality: "Christiansted",
    gps_code: "TISX",
    iata_code: "STX",
    local_code: "STX",
    coordinates: "-64.79859924316406, 17.701900482177734",
  },
  {
    ident: "TJAB",
    type: "medium_airport",
    name: "Antonio Nery Juarbe Pol Airport",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Arecibo",
    gps_code: "TJAB",
    iata_code: "ARE",
    local_code: "ABO",
    coordinates: "-66.6753005981, 18.4500007629",
  },
  {
    ident: "TJBQ",
    type: "medium_airport",
    name: "Rafael Hernandez Airport",
    elevation_ft: "237",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Aguadilla",
    gps_code: "TJBQ",
    iata_code: "BQN",
    local_code: "BQN",
    coordinates: "-67.12940216064453, 18.49489974975586",
  },
  {
    ident: "TJC",
    type: "small_airport",
    name: "Ticantiki Airport",
    elevation_ft: "17",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Ticantiqui",
    iata_code: "TJC",
    coordinates: "-78.4896, 9.4185",
  },
  {
    ident: "TJCG",
    type: "closed",
    name: "Vieques Airport",
    elevation_ft: "19",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Vieques Island",
    gps_code: "TJCG",
    iata_code: "VQS",
    coordinates: "-65.4226989746, 18.115800857500002",
  },
  {
    ident: "TJCP",
    type: "small_airport",
    name: "Benjamin Rivera Noriega Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Culebra Island",
    gps_code: "TJCP",
    iata_code: "CPX",
    local_code: "CPX",
    coordinates: "-65.304324, 18.313289",
  },
  {
    ident: "TJFA",
    type: "medium_airport",
    name: "Diego Jimenez Torres Airport",
    elevation_ft: "64",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Fajardo",
    gps_code: "TJFA",
    iata_code: "FAJ",
    local_code: "X95",
    coordinates: "-65.66190338134766, 18.308900833129883",
  },
  {
    ident: "TJIG",
    type: "medium_airport",
    name: "Fernando Luis Ribas Dominicci Airport",
    elevation_ft: "10",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "San Juan",
    gps_code: "TJIG",
    iata_code: "SIG",
    local_code: "SIG",
    coordinates: "-66.09809875488281, 18.45680046081543",
  },
  {
    ident: "TJMZ",
    type: "medium_airport",
    name: "Eugenio Maria De Hostos Airport",
    elevation_ft: "28",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Mayaguez",
    gps_code: "TJMZ",
    iata_code: "MAZ",
    local_code: "MAZ",
    coordinates: "-67.14849853515625, 18.255699157714844",
  },
  {
    ident: "TJPS",
    type: "medium_airport",
    name: "Mercedita Airport",
    elevation_ft: "29",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Ponce",
    gps_code: "TJPS",
    iata_code: "PSE",
    local_code: "PSE",
    coordinates: "-66.56300354003906, 18.00830078125",
  },
  {
    ident: "TJRV",
    type: "small_airport",
    name: "Jos���� Aponte de la Torre Airport",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Ceiba",
    gps_code: "TJRV",
    iata_code: "NRR",
    local_code: "RVR",
    coordinates: "-65.6434020996, 18.245300293",
  },
  {
    ident: "TJSJ",
    type: "large_airport",
    name: "Luis Munoz Marin International Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "San Juan",
    gps_code: "TJSJ",
    iata_code: "SJU",
    local_code: "SJU",
    coordinates: "-66.0018005371, 18.4393997192",
  },
  {
    ident: "TJVQ",
    type: "small_airport",
    name: "Antonio Rivera Rodriguez Airport",
    elevation_ft: "49",
    continent: "NA",
    iso_country: "PR",
    iso_region: "PR-U-A",
    municipality: "Vieques Island",
    gps_code: "TJVQ",
    iata_code: "VQS",
    local_code: "VQS",
    coordinates: "-65.493598938, 18.1347999573",
  },
  {
    ident: "TKE",
    type: "seaplane_base",
    name: "Tenakee Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tenakee Springs",
    gps_code: "TKE",
    iata_code: "TKE",
    local_code: "TKE",
    coordinates: "-135.21800231934, 57.77970123291",
  },
  {
    ident: "TKL",
    type: "seaplane_base",
    name: "Taku Lodge Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Taku Lodge",
    gps_code: "TKL",
    iata_code: "TKL",
    local_code: "TKL",
    coordinates: "-133.942993164, 58.4897003174",
  },
  {
    ident: "TKPK",
    type: "medium_airport",
    name: "Robert L. Bradshaw International Airport",
    elevation_ft: "170",
    continent: "NA",
    iso_country: "KN",
    iso_region: "KN-U-A",
    municipality: "Basseterre",
    gps_code: "TKPK",
    iata_code: "SKB",
    coordinates: "-62.71870040893555, 17.311199188232422",
  },
  {
    ident: "TKPN",
    type: "medium_airport",
    name: "Vance W. Amory International Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "KN",
    iso_region: "KN-U-A",
    municipality: "Charlestown",
    gps_code: "TKPN",
    iata_code: "NEV",
    coordinates: "-62.589900970458984, 17.205699920654297",
  },
  {
    ident: "TLP",
    type: "small_airport",
    name: "Tumolbil Airport",
    elevation_ft: "3590",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Tumolbil",
    gps_code: "AYQL",
    iata_code: "TLP",
    local_code: "TUM",
    coordinates: "141.0133, -4.7748",
  },
  {
    ident: "TLPC",
    type: "medium_airport",
    name: "George F. L. Charles Airport",
    elevation_ft: "22",
    continent: "NA",
    iso_country: "LC",
    iso_region: "LC-02",
    municipality: "Castries",
    gps_code: "TLPC",
    iata_code: "SLU",
    coordinates: "-60.992901, 14.0202",
  },
  {
    ident: "TLPL",
    type: "medium_airport",
    name: "Hewanorra International Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "LC",
    iso_region: "LC-11",
    municipality: "Vieux Fort",
    gps_code: "TLPL",
    iata_code: "UVF",
    coordinates: "-60.952599, 13.7332",
  },
  {
    ident: "TLT",
    type: "small_airport",
    name: "Tuluksak Airport",
    elevation_ft: "30",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tuluksak",
    gps_code: "TLT",
    iata_code: "TLT",
    local_code: "TLT",
    coordinates: "-160.968994141, 61.096801757799994",
  },
  {
    ident: "TN-0002",
    type: "large_airport",
    name: "Enfidha - Hammamet International Airport",
    elevation_ft: "21",
    continent: "AF",
    iso_country: "TN",
    iso_region: "TN-51",
    municipality: "Enfidha",
    gps_code: "DTNH",
    iata_code: "NBE",
    local_code: "DTNH",
    coordinates: "10.438611, 36.075833",
  },
  {
    ident: "TNCA",
    type: "medium_airport",
    name: "Queen Beatrix International Airport",
    elevation_ft: "60",
    continent: "NA",
    iso_country: "AW",
    iso_region: "AW-U-A",
    municipality: "Oranjestad",
    gps_code: "TNCA",
    iata_code: "AUA",
    coordinates: "-70.015198, 12.5014",
  },
  {
    ident: "TNCB",
    type: "medium_airport",
    name: "Flamingo International Airport",
    elevation_ft: "20",
    continent: "NA",
    iso_country: "BQ",
    iso_region: "BQ-U-A",
    municipality: "Kralendijk",
    gps_code: "TNCB",
    iata_code: "BON",
    coordinates: "-68.26850128173828, 12.130999565124512",
  },
  {
    ident: "TNCC",
    type: "medium_airport",
    name: "Hato International Airport",
    elevation_ft: "29",
    continent: "NA",
    iso_country: "CW",
    iso_region: "CW-U-A",
    municipality: "Willemstad",
    gps_code: "TNCC",
    iata_code: "CUR",
    coordinates: "-68.959801, 12.1889",
  },
  {
    ident: "TNCE",
    type: "medium_airport",
    name: "F. D. Roosevelt Airport",
    elevation_ft: "129",
    continent: "NA",
    iso_country: "BQ",
    iso_region: "BQ-U-A",
    municipality: "Sint Eustatius",
    gps_code: "TNCE",
    iata_code: "EUX",
    coordinates: "-62.979400634765625, 17.49650001525879",
  },
  {
    ident: "TNCM",
    type: "large_airport",
    name: "Princess Juliana International Airport",
    elevation_ft: "13",
    continent: "NA",
    iso_country: "SX",
    iso_region: "SX-U-A",
    municipality: "Saint Martin",
    gps_code: "TNCM",
    iata_code: "SXM",
    coordinates: "-63.1088981628, 18.041000366200002",
  },
  {
    ident: "TNCS",
    type: "small_airport",
    name: "Juancho E. Yrausquin Airport",
    elevation_ft: "60",
    continent: "NA",
    iso_country: "BQ",
    iso_region: "BQ-U-A",
    municipality: "Saba",
    gps_code: "TNCS",
    iata_code: "SAB",
    coordinates: "-63.220001220703125, 17.645000457763672",
  },
  {
    ident: "TNW",
    type: "medium_airport",
    name: "Jumandy Airport",
    elevation_ft: "1234",
    continent: "SA",
    iso_country: "EC",
    iso_region: "EC-N",
    municipality: "Tena",
    gps_code: "SEJD",
    iata_code: "TNW",
    coordinates: "-77.583333, -1.059722",
  },
  {
    ident: "TOK",
    type: "small_airport",
    name: "Torokina Airport",
    elevation_ft: "130",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Torokina",
    iata_code: "TOK",
    local_code: "TKN",
    coordinates: "155.063, -6.2015",
  },
  {
    ident: "TOV",
    type: "seaplane_base",
    name: "Tortola West End Seaplane Base",
    continent: "NA",
    iso_country: "VG",
    iso_region: "VG-U-A",
    municipality: "Tortola",
    iata_code: "TOV",
    coordinates: "-64.583333, 18.45",
  },
  {
    ident: "TPO",
    type: "small_airport",
    name: "Port Alsworth Airport",
    elevation_ft: "280",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Port Alsworth",
    gps_code: "PALJ",
    iata_code: "PTA",
    local_code: "TPO",
    coordinates: "-154.325863, 60.201681",
  },
  {
    ident: "TPT",
    type: "small_airport",
    name: "Tapeta Airport",
    elevation_ft: "870",
    continent: "AF",
    iso_country: "LR",
    iso_region: "LR-NI",
    municipality: "Tapeta",
    iata_code: "TPT",
    coordinates: "-8.873, 6.4948",
  },
  {
    ident: "TQPF",
    type: "medium_airport",
    name: "Clayton J Lloyd International Airport",
    elevation_ft: "127",
    continent: "NA",
    iso_country: "AI",
    iso_region: "AI-U-A",
    municipality: "The Valley",
    gps_code: "TQPF",
    iata_code: "AXA",
    coordinates: "-63.055099, 18.2048",
  },
  {
    ident: "TR-0017",
    type: "small_airport",
    name: "Bing����l ����eltiksuyu Airport",
    elevation_ft: "3506",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-12",
    municipality: "Bing����l",
    gps_code: "LTCU",
    iata_code: "BGG",
    coordinates: "40.5959625244, 38.8592605591",
  },
  {
    ident: "TR-0025",
    type: "small_airport",
    name: "Ordu Giresun Airport",
    elevation_ft: "11",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-52",
    municipality: "Ordu",
    gps_code: "LTCB",
    iata_code: "OGU",
    local_code: "OGU",
    coordinates: "38.080994, 40.966047",
  },
  {
    ident: "TR-0026",
    type: "medium_airport",
    name: "I����d����r Airport",
    elevation_ft: "3101",
    continent: "AS",
    iso_country: "TR",
    iso_region: "TR-76",
    municipality: "I����d����r",
    gps_code: "LTCT",
    iata_code: "IGD",
    coordinates: "43.8766479492, 39.9766273499",
  },
  {
    ident: "TRPG",
    type: "medium_airport",
    name: "John A. Osborne Airport",
    elevation_ft: "550",
    continent: "NA",
    iso_country: "MS",
    iso_region: "MS-U-A",
    municipality: "Gerald's Park",
    gps_code: "TRPG",
    iata_code: "MNI",
    coordinates: "-62.19329833984375, 16.791400909423828",
  },
  {
    ident: "TSG",
    type: "small_airport",
    name: "Tanacross Airport",
    elevation_ft: "1549",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tanacross",
    gps_code: "TSG",
    iata_code: "TSG",
    local_code: "TSG",
    coordinates: "-143.335998535, 63.374401092499994",
  },
  {
    ident: "TSI",
    type: "small_airport",
    name: "Tsile Tsile Airport",
    elevation_ft: "500",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Tsile Tsile",
    iata_code: "TSI",
    local_code: "TSL",
    coordinates: "146.3554, -6.8498",
  },
  {
    ident: "TSK",
    type: "closed",
    name: "Taskul Airport",
    elevation_ft: "100",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-SAN",
    municipality: "Taskul",
    iata_code: "TSK",
    coordinates: "150.4553, -2.546",
  },
  {
    ident: "TTCP",
    type: "medium_airport",
    name: "Tobago-Crown Point Airport",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "TT",
    iso_region: "TT-WTO",
    municipality: "Scarborough",
    gps_code: "TTCP",
    iata_code: "TAB",
    coordinates: "-60.83219909667969, 11.149700164794922",
  },
  {
    ident: "TTPP",
    type: "medium_airport",
    name: "Piarco International Airport",
    elevation_ft: "58",
    continent: "NA",
    iso_country: "TT",
    iso_region: "TT-TUP",
    municipality: "Port of Spain",
    gps_code: "TTPP",
    iata_code: "POS",
    coordinates: "-61.33720016479492, 10.595399856567383",
  },
  {
    ident: "TTW",
    type: "seaplane_base",
    name: "Tissa Tank Waterdrome",
    elevation_ft: "75",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-3",
    municipality: "Tissamaharama",
    iata_code: "TTW",
    coordinates: "81.2906, 6.2876",
  },
  {
    ident: "TUE",
    type: "small_airport",
    name: "Tupile Airport",
    elevation_ft: "5",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Isla Tupile",
    iata_code: "TUE",
    coordinates: "-78.5757, 9.4468",
  },
  {
    ident: "TUJ",
    type: "small_airport",
    name: "Tum Airport",
    elevation_ft: "4700",
    continent: "AF",
    iso_country: "ET",
    iso_region: "ET-SN",
    municipality: "Tum",
    iata_code: "TUJ",
    coordinates: "35.5184, 6.26",
  },
  {
    ident: "TUPA",
    type: "small_airport",
    name: "Captain Auguste George Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "VG",
    iso_region: "VG-U-A",
    municipality: "Anegada",
    gps_code: "TUPA",
    iata_code: "NGD",
    coordinates: "-64.32969665527344, 18.72719955444336",
  },
  {
    ident: "TUPJ",
    type: "medium_airport",
    name: "Terrance B. Lettsome International Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "VG",
    iso_region: "VG-U-A",
    municipality: "Road Town",
    gps_code: "TUPJ",
    iata_code: "EIS",
    coordinates: "-64.54299926757812, 18.444799423217773",
  },
  {
    ident: "TUPW",
    type: "small_airport",
    name: "Virgin Gorda Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "VG",
    iso_region: "VG-U-A",
    municipality: "Spanish Town",
    gps_code: "TUPW",
    iata_code: "VIJ",
    coordinates: "-64.42749786376953, 18.446399688720703",
  },
  {
    ident: "TUT",
    type: "closed",
    name: "Tauta Airport",
    elevation_ft: "4200",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Tauta",
    iata_code: "TUT",
    coordinates: "145.9341, -5.8335",
  },
  {
    ident: "TUZ",
    type: "closed",
    name: "Tucuma Airport",
    elevation_ft: "59",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AM",
    municipality: "Tucuma",
    gps_code: "TUZ",
    iata_code: "BR-",
    coordinates: "-66.43299865722656, -3.9670000076293945",
  },
  {
    ident: "TVSA",
    type: "medium_airport",
    name: "Argyle International Airport",
    elevation_ft: "136",
    continent: "NA",
    iso_country: "VC",
    iso_region: "VC-U-A",
    municipality: "Kingstown",
    gps_code: "TVSA",
    iata_code: "SVD",
    coordinates: "-61.149945, 13.156695",
  },
  {
    ident: "TVSB",
    type: "medium_airport",
    name: "J F Mitchell Airport",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "VC",
    iso_region: "VC-06",
    municipality: "Bequia",
    gps_code: "TVSB",
    iata_code: "BQU",
    coordinates: "-61.2620010376, 12.9884004593",
  },
  {
    ident: "TVSC",
    type: "medium_airport",
    name: "Canouan Airport",
    elevation_ft: "11",
    continent: "NA",
    iso_country: "VC",
    iso_region: "VC-06",
    municipality: "Canouan",
    gps_code: "TVSC",
    iata_code: "CIW",
    coordinates: "-61.3424, 12.699",
  },
  {
    ident: "TVSM",
    type: "medium_airport",
    name: "Mustique Airport",
    elevation_ft: "8",
    continent: "NA",
    iso_country: "VC",
    iso_region: "VC-06",
    municipality: "Mustique Island",
    gps_code: "TVSM",
    iata_code: "MQS",
    coordinates: "-61.180198669433594, 12.887900352478027",
  },
  {
    ident: "TVSU",
    type: "medium_airport",
    name: "Union Island International Airport",
    elevation_ft: "16",
    continent: "NA",
    iso_country: "VC",
    iso_region: "VC-06",
    municipality: "Union Island",
    gps_code: "TVSU",
    iata_code: "UNI",
    coordinates: "-61.41194534301758, 12.60013484954834",
  },
  {
    ident: "TVSV",
    type: "closed",
    name: "E. T. Joshua Airport",
    elevation_ft: "66",
    continent: "NA",
    iso_country: "VC",
    iso_region: "VC-04",
    municipality: "Kingstown",
    gps_code: "TVSV",
    iata_code: "SVD",
    coordinates: "-61.210899, 13.1443",
  },
  {
    ident: "TW-0002",
    type: "small_airport",
    name: "Dongsha Island Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-KHH",
    municipality: "Pratas Island",
    gps_code: "RCLM",
    iata_code: "DSX",
    coordinates: "116.721001, 20.7066",
  },
  {
    ident: "TW-CMJ",
    type: "small_airport",
    name: "Chi Mei Airport",
    elevation_ft: "63",
    continent: "AS",
    iso_country: "TW",
    iso_region: "TW-PEN",
    municipality: "Chi Mei",
    gps_code: "RCCM",
    iata_code: "CMJ",
    coordinates: "119.417999268, 23.2131004333",
  },
  {
    ident: "TWH",
    type: "seaplane_base",
    name: "Two Harbors Amphibious Terminal",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Two Harbors",
    iata_code: "TWH",
    coordinates: "-118.508611111, 33.432222222200004",
  },
  {
    ident: "TXKF",
    type: "medium_airport",
    name: "L.F. Wade International International Airport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "BM",
    iso_region: "BM-U-A",
    municipality: "Hamilton",
    gps_code: "TXKF",
    iata_code: "BDA",
    coordinates: "-64.67870330810547, 32.36399841308594",
  },
  {
    ident: "TYE",
    type: "small_airport",
    name: "Tyonek Airport",
    elevation_ft: "110",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Tyonek",
    gps_code: "TYE",
    iata_code: "TYE",
    local_code: "TYE",
    coordinates: "-151.13800048828, 61.076698303223",
  },
  {
    ident: "TZ-GIT",
    type: "small_airport",
    name: "Mchauru Airport",
    elevation_ft: "3955",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-18",
    municipality: "Geita",
    gps_code: "HTRU",
    iata_code: "GIT",
    coordinates: "32.172472, -2.813667",
  },
  {
    ident: "TZ-LUY",
    type: "small_airport",
    name: "Lushoto Airport",
    elevation_ft: "5360",
    continent: "AF",
    iso_country: "TZ",
    iso_region: "TZ-25",
    municipality: "Lushoto",
    iata_code: "LUY",
    coordinates: "38.30419921875, -4.783259868621826",
  },
  {
    ident: "TZO",
    type: "small_airport",
    name: "Tsimiroro Airport",
    elevation_ft: "755",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-U",
    municipality: "Ankisatra",
    iata_code: "TZO",
    coordinates: "45.015643, -18.345064",
  },
  {
    ident: "U41",
    type: "small_airport",
    name: "Dubois Municipal Airport",
    elevation_ft: "5123",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ID",
    municipality: "Dubois",
    gps_code: "U41",
    iata_code: "DBS",
    local_code: "U41",
    coordinates: "-112.225997925, 44.1665992737",
  },
  {
    ident: "UA-0049",
    type: "small_airport",
    name: "Myrhorod Air Base",
    elevation_ft: "400",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-53",
    municipality: "Myrhorod",
    iata_code: "MXR",
    coordinates: "33.641, 49.932",
  },
  {
    ident: "UA-0050",
    type: "small_airport",
    name: "Kakhnovka Airfield",
    elevation_ft: "266",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-53",
    municipality: "Kremenchuk",
    gps_code: "UKHK",
    iata_code: "KHU",
    coordinates: "33.4766, 49.1342",
  },
  {
    ident: "UAAA",
    type: "large_airport",
    name: "Almaty Airport",
    elevation_ft: "2234",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-ALM",
    municipality: "Almaty",
    gps_code: "UAAA",
    iata_code: "ALA",
    coordinates: "77.04049682617188, 43.35210037231445",
  },
  {
    ident: "UAAH",
    type: "medium_airport",
    name: "Balkhash Airport",
    elevation_ft: "1446",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-KAR",
    municipality: "Balkhash",
    gps_code: "UAAH",
    iata_code: "BXH",
    coordinates: "75.00499725341797, 46.8932991027832",
  },
  {
    ident: "UAAR",
    type: "small_airport",
    name: "Boralday Airport",
    elevation_ft: "2313",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-ALM",
    municipality: "Aima Ata",
    gps_code: "UAAR",
    iata_code: "BXJ",
    coordinates: "76.88369750976562, 43.35260009765625",
  },
  {
    ident: "UACC",
    type: "large_airport",
    name: "Astana International Airport",
    elevation_ft: "1165",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-AKM",
    municipality: "Astana",
    gps_code: "UACC",
    iata_code: "TSE",
    coordinates: "71.46690368652344, 51.02220153808594",
  },
  {
    ident: "UACK",
    type: "medium_airport",
    name: "Kokshetau Airport",
    elevation_ft: "900",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-AKM",
    municipality: "Kokshetau",
    gps_code: "UACK",
    iata_code: "KOV",
    coordinates: "69.594597, 53.329102",
  },
  {
    ident: "UACP",
    type: "medium_airport",
    name: "Petropavlosk South Airport",
    elevation_ft: "453",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-SEV",
    municipality: "Petropavlosk",
    gps_code: "UACP",
    iata_code: "PPK",
    coordinates: "69.18389892578125, 54.77470016479492",
  },
  {
    ident: "UADD",
    type: "medium_airport",
    name: "Taraz Airport",
    elevation_ft: "2184",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-ZHA",
    municipality: "Taraz",
    gps_code: "UADD",
    iata_code: "DMB",
    coordinates: "71.30359649658203, 42.853599548339844",
  },
  {
    ident: "UAE",
    type: "small_airport",
    name: "Mount Aue Airport",
    elevation_ft: "4250",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-JI",
    gps_code: "AYAE",
    iata_code: "UAE",
    local_code: "AUE",
    coordinates: "144.664722222, -6.23416666667",
  },
  {
    ident: "UAFL",
    type: "medium_airport",
    name: "Issyk-Kul International Airport",
    elevation_ft: "5425",
    continent: "AS",
    iso_country: "KG",
    iso_region: "KG-Y",
    municipality: "Tamchy",
    gps_code: "UCFL",
    iata_code: "IKU",
    local_code: "IKU",
    coordinates: "76.713046, 42.58792",
  },
  {
    ident: "UAFM",
    type: "large_airport",
    name: "Manas International Airport",
    elevation_ft: "2058",
    continent: "AS",
    iso_country: "KG",
    iso_region: "KG-C",
    municipality: "Bishkek",
    gps_code: "UCFM",
    iata_code: "FRU",
    coordinates: "74.4776000977, 43.0612983704",
  },
  {
    ident: "UAFO",
    type: "medium_airport",
    name: "Osh Airport",
    elevation_ft: "2927",
    continent: "AS",
    iso_country: "KG",
    iso_region: "KG-O",
    municipality: "Osh",
    gps_code: "UCFO",
    iata_code: "OSS",
    coordinates: "72.793296814, 40.6090011597",
  },
  {
    ident: "UAII",
    type: "medium_airport",
    name: "Shymkent Airport",
    elevation_ft: "1385",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-YUZ",
    municipality: "Shymkent",
    gps_code: "UAII",
    iata_code: "CIT",
    coordinates: "69.47889709472656, 42.364200592041016",
  },
  {
    ident: "UAKD",
    type: "medium_airport",
    name: "Zhezkazgan Airport",
    elevation_ft: "1250",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-KAR",
    municipality: "Zhezkazgan",
    gps_code: "UAKD",
    iata_code: "DZN",
    coordinates: "67.733299, 47.708302",
  },
  {
    ident: "UAKK",
    type: "large_airport",
    name: "Sary-Arka Airport",
    elevation_ft: "1765",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-KAR",
    municipality: "Karaganda",
    gps_code: "UAKK",
    iata_code: "KGF",
    coordinates: "73.33439636230469, 49.670799255371094",
  },
  {
    ident: "UAOO",
    type: "small_airport",
    name: "Kzyl-Orda Southwest Airport",
    elevation_ft: "433",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-KZY",
    municipality: "Kzyl-Orda",
    gps_code: "UAOO",
    iata_code: "KZO",
    coordinates: "65.592499, 44.706902",
  },
  {
    ident: "UARR",
    type: "medium_airport",
    name: "Uralsk Airport",
    elevation_ft: "125",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-ZAP",
    municipality: "Uralsk",
    gps_code: "UARR",
    iata_code: "URA",
    coordinates: "51.54309844970703, 51.15079879760742",
  },
  {
    ident: "UASB",
    type: "medium_airport",
    name: "Ekibastuz Airport",
    elevation_ft: "621",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-PAV",
    municipality: "Ekibastuz",
    gps_code: "UASB",
    iata_code: "EKB",
    coordinates: "75.21499633789062, 51.590999603271484",
  },
  {
    ident: "UASE",
    type: "small_airport",
    name: "Zaysan Airport",
    elevation_ft: "1877",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-VOS",
    municipality: "Zaysan",
    gps_code: "UASZ",
    iata_code: "SZI",
    coordinates: "84.887675, 47.487491",
  },
  {
    ident: "UASK",
    type: "medium_airport",
    name: "Ust-Kamennogorsk Airport",
    elevation_ft: "939",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-VOS",
    municipality: "Ust Kamenogorsk",
    gps_code: "UASK",
    iata_code: "UKK",
    coordinates: "82.49420166015625, 50.036598205566406",
  },
  {
    ident: "UASP",
    type: "medium_airport",
    name: "Pavlodar Airport",
    elevation_ft: "410",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-PAV",
    municipality: "Pavlodar",
    gps_code: "UASP",
    iata_code: "PWQ",
    coordinates: "77.07389831542969, 52.19499969482422",
  },
  {
    ident: "UASS",
    type: "medium_airport",
    name: "Semipalatinsk Airport",
    elevation_ft: "761",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-VOS",
    municipality: "Semey",
    gps_code: "UASS",
    iata_code: "PLX",
    coordinates: "80.234398, 50.351295",
  },
  {
    ident: "UATE",
    type: "medium_airport",
    name: "Aktau Airport",
    elevation_ft: "73",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-MAN",
    municipality: "Aktau",
    gps_code: "UATE",
    iata_code: "SCO",
    coordinates: "51.091999, 43.8601",
  },
  {
    ident: "UATG",
    type: "medium_airport",
    name: "Atyrau Airport",
    elevation_ft: "-72",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-ATY",
    municipality: "Atyrau",
    gps_code: "UATG",
    iata_code: "GUW",
    coordinates: "51.8213996887207, 47.12189865112305",
  },
  {
    ident: "UATT",
    type: "medium_airport",
    name: "Aktobe Airport",
    elevation_ft: "738",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-AKT",
    municipality: "Aktyubinsk",
    gps_code: "UATT",
    iata_code: "AKX",
    coordinates: "57.206699, 50.2458",
  },
  {
    ident: "UAUR",
    type: "small_airport",
    name: "Arkalyk North Airport",
    elevation_ft: "1266",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-KUS",
    municipality: "Arkalyk",
    gps_code: "UAUR",
    iata_code: "AYK",
    coordinates: "66.95279693603516, 50.318599700927734",
  },
  {
    ident: "UAUU",
    type: "medium_airport",
    name: "Kostanay West Airport",
    elevation_ft: "595",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-KUS",
    municipality: "Kostanay",
    gps_code: "UAUU",
    iata_code: "KSN",
    coordinates: "63.55030059814453, 53.20690155029297",
  },
  {
    ident: "UBBB",
    type: "large_airport",
    name: "Heydar Aliyev International Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "AZ",
    iso_region: "AZ-BA",
    municipality: "Baku",
    gps_code: "UBBB",
    iata_code: "GYD",
    coordinates: "50.04669952392578, 40.467498779296875",
  },
  {
    ident: "UBBG",
    type: "medium_airport",
    name: "Ganja International Airport",
    elevation_ft: "1083",
    continent: "AS",
    iso_country: "AZ",
    iso_region: "AZ-GA",
    municipality: "Ganja",
    gps_code: "UBBG",
    iata_code: "KVD",
    coordinates: "46.3176, 40.737701",
  },
  {
    ident: "UBBL",
    type: "small_airport",
    name: "Lankaran International Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "AZ",
    iso_region: "AZ-LA",
    municipality: "Lankaran",
    gps_code: "UBBL",
    iata_code: "LLK",
    coordinates: "48.8180007935, 38.746398925799994",
  },
  {
    ident: "UBBN",
    type: "medium_airport",
    name: "Nakhchivan Airport",
    elevation_ft: "2863",
    continent: "AS",
    iso_country: "AZ",
    iso_region: "AZ-NX",
    municipality: "Nakhchivan",
    gps_code: "UBBN",
    iata_code: "NAJ",
    coordinates: "45.45840072631836, 39.18880081176758",
  },
  {
    ident: "UBBQ",
    type: "medium_airport",
    name: "Gabala International Airport",
    elevation_ft: "935",
    continent: "AS",
    iso_country: "AZ",
    iso_region: "AZ-QAB",
    municipality: "Gabala",
    gps_code: "UBBQ",
    iata_code: "GBB",
    coordinates: "47.7125, 40.826667",
  },
  {
    ident: "UBBY",
    type: "small_airport",
    name: "Zaqatala International Airport",
    elevation_ft: "1279",
    continent: "AS",
    iso_country: "AZ",
    iso_region: "AZ-ZAQ",
    municipality: "Zaqatala",
    gps_code: "UBBY",
    iata_code: "ZTU",
    coordinates: "46.667221, 41.562222",
  },
  {
    ident: "UBEE",
    type: "small_airport",
    name: "Yevlakh Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "AZ",
    iso_region: "AZ-YE",
    municipality: "Yevlakh",
    gps_code: "UBEE",
    iata_code: "YLV",
    coordinates: "47.141899, 40.631901",
  },
  {
    ident: "UBI",
    type: "small_airport",
    name: "Buin Airport",
    elevation_ft: "230",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NSB",
    municipality: "Buin",
    gps_code: "AYUI",
    iata_code: "UBI",
    local_code: "BUIN",
    coordinates: "155.683333333, -6.72916666667",
  },
  {
    ident: "UDSG",
    type: "medium_airport",
    name: "Gyumri Shirak Airport",
    elevation_ft: "5000",
    continent: "AS",
    iso_country: "AM",
    iso_region: "AM-SH",
    municipality: "Gyumri",
    gps_code: "UDSG",
    iata_code: "LWN",
    coordinates: "43.859298706100006, 40.7504005432",
  },
  {
    ident: "UDYZ",
    type: "large_airport",
    name: "Zvartnots International Airport",
    elevation_ft: "2838",
    continent: "AS",
    iso_country: "AM",
    iso_region: "AM-ER",
    municipality: "Yerevan",
    gps_code: "UDYZ",
    iata_code: "EVN",
    coordinates: "44.3959007263, 40.1473007202",
  },
  {
    ident: "UEBB",
    type: "small_airport",
    name: "Batagay Airport",
    elevation_ft: "696",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Batagay",
    gps_code: "UEBB",
    iata_code: "BQJ",
    coordinates: "134.69500732422, 67.648002624512",
  },
  {
    ident: "UEBS",
    type: "small_airport",
    name: "Sakkyryr Airport",
    elevation_ft: "1686",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Batagay-Alyta",
    gps_code: "UEBS",
    iata_code: "SUK",
    coordinates: "130.394, 67.792",
  },
  {
    ident: "UEBT",
    type: "small_airport",
    name: "Ust-Kuyga Airport",
    elevation_ft: "327",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Ust-Kuyga",
    gps_code: "UEBT",
    iata_code: "UKG",
    coordinates: "135.645004, 70.011002",
  },
  {
    ident: "UECT",
    type: "small_airport",
    name: "Talakan Airport",
    elevation_ft: "1329",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Talakan Oil Field",
    gps_code: "UECT",
    iata_code: "TLK",
    coordinates: "111.044444, 59.876389",
  },
  {
    ident: "UEEA",
    type: "small_airport",
    name: "Aldan Airport",
    elevation_ft: "2241",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Aldan",
    gps_code: "UEEA",
    iata_code: "ADH",
    coordinates: "125.40899658203125, 58.60279846191406",
  },
  {
    ident: "UEEE",
    type: "medium_airport",
    name: "Yakutsk Airport",
    elevation_ft: "325",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Yakutsk",
    gps_code: "UEEE",
    iata_code: "YKS",
    coordinates: "129.77099609375, 62.093299865722656",
  },
  {
    ident: "UELL",
    type: "medium_airport",
    name: "Chulman Airport",
    elevation_ft: "2812",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Neryungri",
    gps_code: "UELL",
    iata_code: "NER",
    coordinates: "124.91400146484, 56.913898468018",
  },
  {
    ident: "UEMA",
    type: "medium_airport",
    name: "Moma Airport",
    elevation_ft: "656",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Khonuu",
    gps_code: "UEMA",
    iata_code: "MQJ",
    coordinates: "143.261551, 66.450861",
  },
  {
    ident: "UEMM",
    type: "medium_airport",
    name: "Magan Airport",
    elevation_ft: "577",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Magan",
    gps_code: "UEMM",
    iata_code: "GYG",
    coordinates: "129.545288, 62.103484",
  },
  {
    ident: "UEMO",
    type: "medium_airport",
    name: "Olyokminsk Airport",
    elevation_ft: "656",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Olyokminsk",
    gps_code: "UEMO",
    iata_code: "OLZ",
    coordinates: "120.471001, 60.397499",
  },
  {
    ident: "UEMT",
    type: "medium_airport",
    name: "Ust-Nera Airport",
    elevation_ft: "1805",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Ust-Nera",
    gps_code: "UEMT",
    iata_code: "USR",
    coordinates: "143.11500549316, 64.550003051758",
  },
  {
    ident: "UEMU",
    type: "small_airport",
    name: "Ust-Maya Airport",
    elevation_ft: "561",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Ust-Maya",
    gps_code: "UEMU",
    iata_code: "UMS",
    coordinates: "134.43499755859, 60.356998443604",
  },
  {
    ident: "UENI",
    type: "small_airport",
    name: "Verkhnevilyuisk Airport",
    elevation_ft: "411",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Verkhnevilyuisk",
    gps_code: "UENI",
    iata_code: "VHV",
    coordinates: "120.26916503906, 63.458057403564",
  },
  {
    ident: "UENN",
    type: "small_airport",
    name: "Nyurba Airport",
    elevation_ft: "394",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Nyurba",
    gps_code: "UENN",
    iata_code: "NYR",
    coordinates: "118.336998, 63.294998",
  },
  {
    ident: "UENS",
    type: "small_airport",
    name: "Suntar Airport",
    elevation_ft: "452",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Suntar",
    gps_code: "UENS",
    iata_code: "SUY",
    coordinates: "117.63500213623, 62.185001373291",
  },
  {
    ident: "UENW",
    type: "medium_airport",
    name: "Vilyuisk Airport",
    elevation_ft: "361",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Vilyuisk",
    gps_code: "UENW",
    iata_code: "VYI",
    coordinates: "121.69333648682, 63.75666809082",
  },
  {
    ident: "UERL",
    type: "medium_airport",
    name: "Lensk Airport",
    elevation_ft: "801",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Lensk",
    gps_code: "UERL",
    iata_code: "ULK",
    coordinates: "114.825996399, 60.7206001282",
  },
  {
    ident: "UERO",
    type: "small_airport",
    name: "Olenyok Airport",
    elevation_ft: "847",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Olenyok",
    gps_code: "UERO",
    iata_code: "ONK",
    coordinates: "112.480003, 68.514999",
  },
  {
    ident: "UERP",
    type: "medium_airport",
    name: "Polyarny Airport",
    elevation_ft: "1660",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Yakutia",
    gps_code: "UERP",
    iata_code: "PYJ",
    coordinates: "112.029998779, 66.4003982544",
  },
  {
    ident: "UERR",
    type: "medium_airport",
    name: "Mirny Airport",
    elevation_ft: "1156",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Mirny",
    gps_code: "UERR",
    iata_code: "MJZ",
    coordinates: "114.03900146484375, 62.534698486328125",
  },
  {
    ident: "UERS",
    type: "medium_airport",
    name: "Saskylakh Airport",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Saskylakh",
    gps_code: "UERS",
    iata_code: "SYS",
    coordinates: "114.08000183105, 71.92790222168",
  },
  {
    ident: "UESG",
    type: "medium_airport",
    name: "Belaya Gora Airport",
    elevation_ft: "118",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Belaya Gora",
    gps_code: "UESG",
    iata_code: "BGN",
    coordinates: "146.231506, 68.556602",
  },
  {
    ident: "UESK",
    type: "medium_airport",
    name: "Srednekolymsk Airport",
    elevation_ft: "60",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Srednekolymsk",
    gps_code: "UESK",
    iata_code: "SEK",
    coordinates: "153.7364, 67.4805",
  },
  {
    ident: "UESO",
    type: "medium_airport",
    name: "Chokurdakh Airport",
    elevation_ft: "151",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Chokurdah",
    gps_code: "UESO",
    iata_code: "CKH",
    coordinates: "147.90199279785156, 70.62310028076172",
  },
  {
    ident: "UESS",
    type: "medium_airport",
    name: "Cherskiy Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Cherskiy",
    gps_code: "UESS",
    iata_code: "CYX",
    coordinates: "161.33799743652344, 68.7406005859375",
  },
  {
    ident: "UEST",
    type: "medium_airport",
    name: "Tiksi Airport",
    elevation_ft: "26",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Tiksi",
    gps_code: "UEST",
    iata_code: "IKS",
    coordinates: "128.90299987793, 71.697700500488",
  },
  {
    ident: "UESU",
    type: "medium_airport",
    name: "Zyryanka Airport",
    elevation_ft: "140",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Zyryanka",
    gps_code: "UESU",
    iata_code: "ZKP",
    coordinates: "150.8889, 65.7485",
  },
  {
    ident: "UG-0002",
    type: "small_airport",
    name: "Savannah Airstrip",
    elevation_ft: "3600",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-W",
    municipality: "Kihihi",
    iata_code: "KHX",
    coordinates: "29.6997, -0.7165",
  },
  {
    ident: "UG-OYG",
    type: "small_airport",
    name: "Moyo Airport",
    elevation_ft: "3250",
    continent: "AF",
    iso_country: "UG",
    iso_region: "UG-N",
    municipality: "Moyo",
    gps_code: "HUMY",
    iata_code: "OYG",
    coordinates: "31.762762069702, 3.6444001197815",
  },
  {
    ident: "UGB",
    type: "small_airport",
    name: "Ugashik Bay Airport",
    elevation_ft: "132",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Pilot Point",
    gps_code: "UGB",
    iata_code: "UGB",
    local_code: "UGB",
    coordinates: "-157.740005493, 57.4253997803",
  },
  {
    ident: "UGKO",
    type: "medium_airport",
    name: "Kopitnari Airport",
    elevation_ft: "223",
    continent: "AS",
    iso_country: "GE",
    iso_region: "GE-IM",
    municipality: "Kutaisi",
    gps_code: "UGKO",
    iata_code: "KUT",
    coordinates: "42.4826011658, 42.176700592",
  },
  {
    ident: "UGSB",
    type: "medium_airport",
    name: "Batumi International Airport",
    elevation_ft: "105",
    continent: "AS",
    iso_country: "GE",
    iso_region: "GE-AJ",
    municipality: "Batumi",
    gps_code: "UGSB",
    iata_code: "BUS",
    coordinates: "41.5997009277, 41.6102981567",
  },
  {
    ident: "UGSS",
    type: "medium_airport",
    name: "Sukhumi Dranda Airport",
    elevation_ft: "53",
    continent: "AS",
    iso_country: "GE",
    iso_region: "GE-AB",
    municipality: "Sukhumi",
    gps_code: "UGSS",
    iata_code: "SUI",
    coordinates: "41.128101348899996, 42.8582000732",
  },
  {
    ident: "UGTB",
    type: "large_airport",
    name: "Tbilisi International Airport",
    elevation_ft: "1624",
    continent: "AS",
    iso_country: "GE",
    iso_region: "GE-TB",
    municipality: "Tbilisi",
    gps_code: "UGTB",
    iata_code: "TBS",
    coordinates: "44.95470047, 41.6692008972",
  },
  {
    ident: "UHBB",
    type: "medium_airport",
    name: "Ignatyevo Airport",
    elevation_ft: "638",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-AMU",
    municipality: "Blagoveschensk",
    gps_code: "UHBB",
    iata_code: "BQS",
    coordinates: "127.41200256347656, 50.42539978027344",
  },
  {
    ident: "UHBI",
    type: "small_airport",
    name: "Magdagachi Airport",
    elevation_ft: "1211",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-AMU",
    municipality: "Magdagachi",
    gps_code: "UHBI",
    iata_code: "GDG",
    coordinates: "125.794998, 53.473301",
  },
  {
    ident: "UHBW",
    type: "small_airport",
    name: "Tynda Airport",
    elevation_ft: "2001",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-AMU",
    municipality: "Tynda",
    gps_code: "UHBW",
    iata_code: "TYD",
    coordinates: "124.778999, 55.284199",
  },
  {
    ident: "UHHH",
    type: "large_airport",
    name: "Khabarovsk-Novy Airport",
    elevation_ft: "244",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHA",
    municipality: "Khabarovsk",
    gps_code: "UHHH",
    iata_code: "KHV",
    coordinates: "135.18800354004, 48.52799987793",
  },
  {
    ident: "UHKK",
    type: "medium_airport",
    name: "Komsomolsk-on-Amur Airport",
    elevation_ft: "92",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHA",
    municipality: "Komsomolsk-on-Amur",
    gps_code: "UHKK",
    iata_code: "KXK",
    coordinates: "136.9340057373047, 50.409000396728516",
  },
  {
    ident: "UHKM",
    type: "medium_airport",
    name: "Maygatka Airport.",
    elevation_ft: "768",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHA",
    municipality: "Sovetskaya Gavan",
    gps_code: "UHKM",
    iata_code: "GVN",
    coordinates: "140.033996582, 48.926998138399995",
  },
  {
    ident: "UHMA",
    type: "medium_airport",
    name: "Ugolny Airport",
    elevation_ft: "194",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-CHU",
    municipality: "Anadyr",
    gps_code: "UHMA",
    iata_code: "DYR",
    coordinates: "177.740997, 64.734902",
  },
  {
    ident: "UHMD",
    type: "medium_airport",
    name: "Provideniya Bay Airport",
    elevation_ft: "72",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-CHU",
    municipality: "Chukotka",
    gps_code: "UHMD",
    iata_code: "PVS",
    coordinates: "-173.2429962158203, 64.37809753417969",
  },
  {
    ident: "UHMK",
    type: "medium_airport",
    name: "Keperveem Airport",
    elevation_ft: "623",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-CHU",
    municipality: "Keperveem",
    gps_code: "UHMK",
    iata_code: "KPW",
    coordinates: "166.139999, 67.845001",
  },
  {
    ident: "UHMM",
    type: "medium_airport",
    name: "Sokol Airport",
    elevation_ft: "574",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-MAG",
    municipality: "Magadan",
    gps_code: "UHMM",
    iata_code: "GDX",
    coordinates: "150.72000122070312, 59.9109992980957",
  },
  {
    ident: "UHMO",
    type: "small_airport",
    name: "Markovo Airport",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-CHU",
    municipality: "Markovo",
    gps_code: "UHMO",
    iata_code: "KVM",
    coordinates: "170.417007, 64.667",
  },
  {
    ident: "UHMP",
    type: "medium_airport",
    name: "Pevek Airport",
    elevation_ft: "11",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-CHU",
    municipality: "Pevek",
    gps_code: "UHMP",
    iata_code: "PWE",
    coordinates: "170.59700012207, 69.783302307129",
  },
  {
    ident: "UHNB",
    type: "small_airport",
    name: "Bogorodskoye Airport",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KHA",
    municipality: "Bogorodskoye",
    gps_code: "UHNB",
    iata_code: "BQG",
    coordinates: "140.448, 52.38",
  },
  {
    ident: "UHNN",
    type: "medium_airport",
    name: "Nikolayevsk-na-Amure Airport",
    elevation_ft: "170",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KHA",
    municipality: "Nikolayevsk-na-Amure Airport",
    gps_code: "UHNN",
    iata_code: "NLI",
    coordinates: "140.649994, 53.154999",
  },
  {
    ident: "UHOO",
    type: "medium_airport",
    name: "Okhotsk Airport",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHA",
    municipality: "Okhotsk",
    gps_code: "UHOO",
    iata_code: "OHO",
    coordinates: "143.05650329589844, 59.410064697265625",
  },
  {
    ident: "UHPP",
    type: "medium_airport",
    name: "Yelizovo Airport",
    elevation_ft: "131",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KAM",
    municipality: "Petropavlovsk-Kamchatsky",
    gps_code: "UHPP",
    iata_code: "PKC",
    coordinates: "158.45399475097656, 53.16790008544922",
  },
  {
    ident: "UHSB",
    type: "small_airport",
    name: "Burevestnik Airport",
    elevation_ft: "79",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SAK",
    municipality: "Iturup Island",
    gps_code: "UHSB",
    iata_code: "BVV",
    coordinates: "147.621994, 44.919998",
  },
  {
    ident: "UHSH",
    type: "small_airport",
    name: "Okha Airport",
    elevation_ft: "115",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SAK",
    municipality: "Okha",
    gps_code: "UHSH",
    iata_code: "OHH",
    coordinates: "142.88999939, 53.5200004578",
  },
  {
    ident: "UHSI",
    type: "small_airport",
    name: "Iturup Airport",
    elevation_ft: "387",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SAK",
    municipality: "Kurilsk",
    gps_code: "UHSI",
    iata_code: "ITU",
    coordinates: "147.95549, 45.256389",
  },
  {
    ident: "UHSK",
    type: "small_airport",
    name: "Shakhtyorsk Airport",
    elevation_ft: "50",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SAK",
    municipality: "Shakhtersk",
    gps_code: "UHSK",
    iata_code: "EKS",
    coordinates: "142.082993, 49.1903",
  },
  {
    ident: "UHSM",
    type: "small_airport",
    name: "Mendeleyevo Airport",
    elevation_ft: "584",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SAK",
    municipality: "Kunashir Island",
    gps_code: "UHSM",
    iata_code: "DEE",
    coordinates: "145.682998657, 43.9584007263",
  },
  {
    ident: "UHSO",
    type: "small_airport",
    name: "Zonalnoye Airport",
    elevation_ft: "479",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SAK",
    municipality: "Tymovskoye",
    gps_code: "UHSO",
    iata_code: "ZZO",
    coordinates: "142.761001587, 50.6692008972",
  },
  {
    ident: "UHSS",
    type: "medium_airport",
    name: "Yuzhno-Sakhalinsk Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SAK",
    municipality: "Yuzhno-Sakhalinsk",
    gps_code: "UHSS",
    iata_code: "UUS",
    coordinates: "142.71800231933594, 46.88869857788086",
  },
  {
    ident: "UHTG",
    type: "small_airport",
    name: "Amgu Airport",
    elevation_ft: "10",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Amgu",
    gps_code: "UHTG",
    iata_code: "AEM",
    coordinates: "137.673568, 45.84126",
  },
  {
    ident: "UHTQ",
    type: "small_airport",
    name: "Svetlaya Airport",
    elevation_ft: "131",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Svetlaya",
    gps_code: "UHTQ",
    iata_code: "ETL",
    coordinates: "138.321703, 46.541704",
  },
  {
    ident: "UHWE",
    type: "small_airport",
    name: "Yedinka Airport",
    elevation_ft: "98",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Yedinka",
    gps_code: "UHWE",
    iata_code: "EDN",
    coordinates: "138.657357, 47.178201",
  },
  {
    ident: "UHWK",
    type: "small_airport",
    name: "Kavalerovo Airport",
    elevation_ft: "730",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Kavalerovo",
    gps_code: "UHWK",
    iata_code: "KVR",
    coordinates: "135.029, 44.2726",
  },
  {
    ident: "UHWP",
    type: "small_airport",
    name: "Plastun Airport",
    elevation_ft: "66",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Plastun",
    gps_code: "UHWP",
    iata_code: "TLY",
    coordinates: "136.29200744629, 44.814998626709",
  },
  {
    ident: "UHWT",
    type: "small_airport",
    name: "Terney Airport",
    elevation_ft: "33",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Terney",
    gps_code: "UHWT",
    iata_code: "NEI",
    coordinates: "136.5912, 45.0825",
  },
  {
    ident: "UHWW",
    type: "medium_airport",
    name: "Vladivostok International Airport",
    elevation_ft: "46",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-PRI",
    municipality: "Vladivostok",
    gps_code: "UHWW",
    iata_code: "VVO",
    coordinates: "132.1479949951172, 43.39899826049805",
  },
  {
    ident: "UIAA",
    type: "medium_airport",
    name: "Chita-Kadala Airport",
    elevation_ft: "2272",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-CHI",
    municipality: "Chita",
    gps_code: "UIAA",
    iata_code: "HTA",
    coordinates: "113.306, 52.026299",
  },
  {
    ident: "UIBB",
    type: "medium_airport",
    name: "Bratsk Airport",
    elevation_ft: "1610",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-IRK",
    municipality: "Bratsk",
    gps_code: "UIBB",
    iata_code: "BTK",
    coordinates: "101.697998046875, 56.370601654052734",
  },
  {
    ident: "UIBS",
    type: "small_airport",
    name: "Ust-Ilimsk Airport",
    elevation_ft: "1339",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-IRK",
    municipality: "Ust-Ilimsk",
    gps_code: "UIBS",
    iata_code: "UIK",
    coordinates: "102.56500244140625, 58.13610076904297",
  },
  {
    ident: "UIII",
    type: "medium_airport",
    name: "Irkutsk Airport",
    elevation_ft: "1675",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-IRK",
    municipality: "Irkutsk",
    gps_code: "UIII",
    iata_code: "IKT",
    coordinates: "104.38899993896, 52.268001556396",
  },
  {
    ident: "UIKB",
    type: "small_airport",
    name: "Bodaybo Airport",
    elevation_ft: "919",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-IRK",
    municipality: "Bodaybo",
    gps_code: "UIKB",
    iata_code: "ODO",
    coordinates: "114.242996216, 57.866100311299995",
  },
  {
    ident: "UIKE",
    type: "small_airport",
    name: "Yerbogachen Airport",
    elevation_ft: "400",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-IRK",
    municipality: "Erbogachen",
    gps_code: "UIKE",
    iata_code: "ERG",
    coordinates: "108.029998779, 61.2750015259",
  },
  {
    ident: "UIKK",
    type: "small_airport",
    name: "Kirensk Airport",
    elevation_ft: "840",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-IRK",
    municipality: "Kirensk",
    gps_code: "UIKK",
    iata_code: "KCK",
    coordinates: "108.064, 57.773",
  },
  {
    ident: "UITT",
    type: "medium_airport",
    name: "Ust-Kut Airport",
    elevation_ft: "2188",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-IRK",
    municipality: "Ust-Kut",
    gps_code: "UITT",
    iata_code: "UKX",
    coordinates: "105.7300033569336, 56.8567008972168",
  },
  {
    ident: "UIUU",
    type: "medium_airport",
    name: "Ulan-Ude Airport (Mukhino)",
    elevation_ft: "1690",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-BU",
    municipality: "Ulan Ude",
    gps_code: "UIUU",
    iata_code: "UUD",
    coordinates: "107.43800354003906, 51.80780029296875",
  },
  {
    ident: "UJAP",
    type: "small_airport",
    name: "Ujae Atoll Airport",
    elevation_ft: "29",
    continent: "OC",
    iso_country: "MH",
    iso_region: "MH-UJA",
    municipality: "Ujae Atoll",
    iata_code: "UJE",
    coordinates: "165.761993408, 8.92805957794",
  },
  {
    ident: "UJN",
    type: "small_airport",
    name: "Uljin Airport",
    elevation_ft: "191",
    continent: "AS",
    iso_country: "KR",
    iso_region: "KR-47",
    municipality: "Bongsan-ri, Uljin",
    gps_code: "RKTL",
    iata_code: "UJN",
    coordinates: "129.461861, 36.777049",
  },
  {
    ident: "UKBB",
    type: "large_airport",
    name: "Boryspil International Airport",
    elevation_ft: "427",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-32",
    municipality: "Kiev",
    gps_code: "UKBB",
    iata_code: "KBP",
    coordinates: "30.894699096679688, 50.345001220703125",
  },
  {
    ident: "UKCC",
    type: "closed",
    name: "Donetsk International Airport",
    elevation_ft: "791",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-14",
    municipality: "Donetsk",
    gps_code: "UKCC",
    iata_code: "DOK",
    coordinates: "37.7397, 48.073601",
  },
  {
    ident: "UKCK",
    type: "small_airport",
    name: "Kramatorsk Airport",
    elevation_ft: "646",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-14",
    municipality: "Kramatorsk",
    gps_code: "UKCK",
    iata_code: "KRQ",
    coordinates: "37.62889862060547, 48.70560073852539",
  },
  {
    ident: "UKCM",
    type: "medium_airport",
    name: "Mariupol International Airport",
    elevation_ft: "251",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-14",
    municipality: "Mariupol",
    gps_code: "UKCM",
    iata_code: "MPW",
    coordinates: "37.44960021972656, 47.07609939575195",
  },
  {
    ident: "UKCS",
    type: "small_airport",
    name: "Sievierodonetsk Airport",
    elevation_ft: "236",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-09",
    municipality: "Sievierodonetsk",
    gps_code: "UKCS",
    iata_code: "SEV",
    coordinates: "38.541669845600005, 48.9003301015",
  },
  {
    ident: "UKCW",
    type: "medium_airport",
    name: "Luhansk International Airport",
    elevation_ft: "636",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-09",
    municipality: "Luhansk",
    gps_code: "UKCW",
    iata_code: "VSG",
    coordinates: "39.3740997314, 48.4174003601",
  },
  {
    ident: "UKDB",
    type: "closed",
    name: "Berdyansk Airport",
    elevation_ft: "171",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-23",
    municipality: "Berdyansk",
    gps_code: "UKDB",
    iata_code: "ERD",
    coordinates: "36.758099, 46.814999",
  },
  {
    ident: "UKDD",
    type: "medium_airport",
    name: "Dnipropetrovsk International Airport",
    elevation_ft: "481",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-12",
    municipality: "Dnipropetrovsk",
    gps_code: "UKDD",
    iata_code: "DNK",
    coordinates: "35.10060119628906, 48.357200622558594",
  },
  {
    ident: "UKDE",
    type: "medium_airport",
    name: "Zaporizhzhia International Airport",
    elevation_ft: "373",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-23",
    municipality: "Zaporizhia",
    gps_code: "UKDE",
    iata_code: "OZH",
    coordinates: "35.31570053100586, 47.867000579833984",
  },
  {
    ident: "UKDR",
    type: "medium_airport",
    name: "Kryvyi Rih International Airport",
    elevation_ft: "408",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-12",
    municipality: "Kryvyi Rih",
    gps_code: "UKDR",
    iata_code: "KWG",
    coordinates: "33.209999084472656, 48.04330062866211",
  },
  {
    ident: "UKFB",
    type: "medium_airport",
    name: "Belbek Airport",
    elevation_ft: "344",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-43",
    municipality: "Sevastopol",
    gps_code: "UKFB",
    iata_code: "UKS",
    coordinates: "33.570999145500004, 44.688999176",
  },
  {
    ident: "UKFF",
    type: "large_airport",
    name: "Simferopol International Airport",
    elevation_ft: "639",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-43",
    municipality: "Simferopol",
    iata_code: "SIP",
    local_code: "URFF",
    coordinates: "33.975101, 45.0522",
  },
  {
    ident: "UKFK",
    type: "small_airport",
    name: "Kerch Airport",
    elevation_ft: "171",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-43",
    municipality: "Kerch",
    gps_code: "UKFK",
    iata_code: "KHC",
    coordinates: "36.40140151977539, 45.372501373291016",
  },
  {
    ident: "UKH",
    type: "small_airport",
    name: "Mukhaizna Airport",
    elevation_ft: "473",
    continent: "AS",
    iso_country: "OM",
    iso_region: "OM-WU",
    municipality: "Mukhaizna Oil Field",
    iata_code: "UKH",
    coordinates: "56.4013888889, 19.3863888889",
  },
  {
    ident: "UKHH",
    type: "large_airport",
    name: "Kharkiv International Airport",
    elevation_ft: "508",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-63",
    municipality: "Kharkiv",
    gps_code: "UKHH",
    iata_code: "HRK",
    coordinates: "36.290000915527344, 49.924800872802734",
  },
  {
    ident: "UKHP",
    type: "small_airport",
    name: "Suprunovka Airport",
    elevation_ft: "505",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-53",
    municipality: "Poltava",
    gps_code: "UKHP",
    iata_code: "PLV",
    coordinates: "34.39720153808594, 49.568599700927734",
  },
  {
    ident: "UKHS",
    type: "small_airport",
    name: "Sumy Airport",
    elevation_ft: "594",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-59",
    municipality: "Sumy",
    gps_code: "UKHS",
    iata_code: "UMY",
    coordinates: "34.76250076293945, 50.858299255371094",
  },
  {
    ident: "UKKE",
    type: "medium_airport",
    name: "Cherkasy International Airport",
    elevation_ft: "375",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-71",
    municipality: "Cherkasy",
    gps_code: "UKKE",
    iata_code: "CKC",
    coordinates: "31.99530029296875, 49.41559982299805",
  },
  {
    ident: "UKKG",
    type: "small_airport",
    name: "Kirovograd Airport",
    elevation_ft: "568",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-35",
    municipality: "Kirovograd",
    gps_code: "UKKG",
    iata_code: "KGO",
    coordinates: "32.28499984741211, 48.54280090332031",
  },
  {
    ident: "UKKK",
    type: "medium_airport",
    name: "Kiev Zhuliany International Airport",
    elevation_ft: "587",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-32",
    municipality: "Kiev",
    gps_code: "UKKK",
    iata_code: "IEV",
    coordinates: "30.45194, 50.40194",
  },
  {
    ident: "UKKM",
    type: "medium_airport",
    name: "Gostomel Airport",
    elevation_ft: "517",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-32",
    municipality: "Kiev",
    gps_code: "UKKM",
    iata_code: "GML",
    coordinates: "30.1919002532959, 50.60350036621094",
  },
  {
    ident: "UKKV",
    type: "small_airport",
    name: "Zhytomyr Airport",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-18",
    gps_code: "UKKV",
    iata_code: "ZTR",
    coordinates: "28.738611, 50.270556",
  },
  {
    ident: "UKLC",
    type: "small_airport",
    name: "Lutsk Airport",
    elevation_ft: "774",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-07",
    municipality: "Lutsk",
    gps_code: "UKLC",
    iata_code: "UCK",
    coordinates: "25.487165, 50.678404",
  },
  {
    ident: "UKLH",
    type: "medium_airport",
    name: "Khmelnytskyi Airport",
    elevation_ft: "1150",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-68",
    municipality: "Khmelnytskyi",
    gps_code: "UKLH",
    iata_code: "HMJ",
    coordinates: "26.933399, 49.359699",
  },
  {
    ident: "UKLI",
    type: "medium_airport",
    name: "Ivano-Frankivsk International Airport",
    elevation_ft: "919",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-26",
    municipality: "Ivano-Frankivsk",
    gps_code: "UKLI",
    iata_code: "IFO",
    coordinates: "24.686100006103516, 48.88420104980469",
  },
  {
    ident: "UKLL",
    type: "medium_airport",
    name: "Lviv International Airport",
    elevation_ft: "1071",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-46",
    municipality: "Lviv",
    gps_code: "UKLL",
    iata_code: "LWO",
    coordinates: "23.956100463867188, 49.8125",
  },
  {
    ident: "UKLN",
    type: "medium_airport",
    name: "Chernivtsi International Airport",
    elevation_ft: "826",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-77",
    municipality: "Chernivtsi",
    gps_code: "UKLN",
    iata_code: "CWC",
    coordinates: "25.98080062866211, 48.259300231933594",
  },
  {
    ident: "UKLR",
    type: "medium_airport",
    name: "Rivne International Airport",
    elevation_ft: "755",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-19",
    municipality: "Rivne",
    gps_code: "UKLR",
    iata_code: "RWN",
    coordinates: "26.141599655151367, 50.60710144042969",
  },
  {
    ident: "UKLU",
    type: "medium_airport",
    name: "Uzhhorod International Airport",
    elevation_ft: "383",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-21",
    municipality: "Uzhhorod",
    gps_code: "UKLU",
    iata_code: "UDJ",
    coordinates: "22.263399124145508, 48.634300231933594",
  },
  {
    ident: "UKOH",
    type: "small_airport",
    name: "Kherson International Airport",
    elevation_ft: "148",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-65",
    municipality: "Kherson",
    gps_code: "UKOH",
    iata_code: "KHE",
    coordinates: "32.506401, 46.6758",
  },
  {
    ident: "UKON",
    type: "medium_airport",
    name: "Mykolaiv International Airport",
    elevation_ft: "184",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-48",
    municipality: "Nikolayev",
    gps_code: "UKON",
    iata_code: "NLV",
    coordinates: "31.9197998046875, 47.057899475097656",
  },
  {
    ident: "UKOO",
    type: "large_airport",
    name: "Odessa International Airport",
    elevation_ft: "172",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-51",
    municipality: "Odessa",
    gps_code: "UKOO",
    iata_code: "ODS",
    coordinates: "30.67650032043457, 46.42679977416992",
  },
  {
    ident: "UKRR",
    type: "closed",
    name: "Chernihiv Shestovitsa Airport",
    elevation_ft: "446",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-74",
    municipality: "Chernigow",
    gps_code: "UKKL",
    iata_code: "CEJ",
    coordinates: "31.1583003998, 51.4021987915",
  },
  {
    ident: "UKWW",
    type: "small_airport",
    name: "Vinnytsia/Gavyryshivka Airport",
    elevation_ft: "961",
    continent: "EU",
    iso_country: "UA",
    iso_region: "UA-05",
    municipality: "Vinnitsa",
    gps_code: "UKWW",
    iata_code: "VIN",
    coordinates: "28.613778, 49.242531",
  },
  {
    ident: "ULAA",
    type: "medium_airport",
    name: "Talagi Airport",
    elevation_ft: "62",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ARK",
    municipality: "Archangelsk",
    gps_code: "ULAA",
    iata_code: "ARH",
    coordinates: "40.71670150756836, 64.60030364990234",
  },
  {
    ident: "ULAL",
    type: "small_airport",
    name: "Leshukonskoye Airport",
    elevation_ft: "220",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ARK",
    municipality: "Leshukonskoye",
    gps_code: "ULAL",
    iata_code: "LDG",
    coordinates: "45.7229995728, 64.8960037231",
  },
  {
    ident: "ULAM",
    type: "medium_airport",
    name: "Naryan Mar Airport",
    elevation_ft: "36",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-NEN",
    municipality: "Naryan Mar",
    gps_code: "ULAM",
    iata_code: "NNM",
    coordinates: "53.12189865112305, 67.63999938964844",
  },
  {
    ident: "ULAS",
    type: "small_airport",
    name: "Solovki Airport",
    elevation_ft: "60",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ARK",
    municipality: "Solovetsky Islands",
    gps_code: "ULAS",
    iata_code: "CSH",
    coordinates: "35.7333335876, 65.0299987793",
  },
  {
    ident: "ULBC",
    type: "medium_airport",
    name: "Cherepovets Airport",
    elevation_ft: "377",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-VLG",
    municipality: "Cherepovets",
    gps_code: "ULWC",
    iata_code: "CEE",
    coordinates: "38.015800476100004, 59.273601532",
  },
  {
    ident: "ULDD",
    type: "medium_airport",
    name: "Amderma Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-NEN",
    municipality: "Amderma",
    gps_code: "ULDD",
    iata_code: "AMV",
    coordinates: "61.556400299072266, 69.76329803466797",
  },
  {
    ident: "ULDW",
    type: "small_airport",
    name: "Varandey Airport",
    elevation_ft: "39",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-NEN",
    municipality: "Varandey",
    gps_code: "ULDW",
    iata_code: "VRI",
    coordinates: "58.201401, 68.848503",
  },
  {
    ident: "ULH",
    type: "medium_airport",
    name: "Majeed Bin Abdulaziz Airport",
    elevation_ft: "2050",
    continent: "AS",
    iso_country: "SA",
    iso_region: "SA-03",
    municipality: "Al Ula",
    gps_code: "OEAO",
    iata_code: "ULH",
    coordinates: "38.1288888889, 26.48",
  },
  {
    ident: "ULKK",
    type: "medium_airport",
    name: "Kotlas Airport",
    elevation_ft: "184",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ARK",
    municipality: "Kotlas",
    gps_code: "ULKK",
    iata_code: "KSZ",
    coordinates: "46.6974983215332, 61.235801696777344",
  },
  {
    ident: "ULLI",
    type: "large_airport",
    name: "Pulkovo Airport",
    elevation_ft: "78",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SPE",
    municipality: "St. Petersburg",
    gps_code: "ULLI",
    iata_code: "LED",
    coordinates: "30.262500762939453, 59.80030059814453",
  },
  {
    ident: "ULMK",
    type: "small_airport",
    name: "Kirovsk-Apatity Airport",
    elevation_ft: "515",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MUR",
    municipality: "Apatity",
    gps_code: "ULMK",
    iata_code: "KVK",
    coordinates: "33.58829879760742, 67.46330261230469",
  },
  {
    ident: "ULMM",
    type: "medium_airport",
    name: "Murmansk Airport",
    elevation_ft: "266",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MUR",
    municipality: "Murmansk",
    gps_code: "ULMM",
    iata_code: "MMK",
    coordinates: "32.75080108642578, 68.78170013427734",
  },
  {
    ident: "ULNN",
    type: "closed",
    name: "Novgorod Airport",
    elevation_ft: "85",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-NGR",
    municipality: "Velikiy Novgorod",
    gps_code: "ULNN",
    iata_code: "NVR",
    coordinates: "31.24169921875, 58.49330139160156",
  },
  {
    ident: "ULOL",
    type: "medium_airport",
    name: "Velikiye Luki Airport",
    elevation_ft: "328",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PSK",
    municipality: "Velikiye Luki",
    gps_code: "ULOL",
    iata_code: "VLU",
    coordinates: "30.60810089111328, 56.381099700927734",
  },
  {
    ident: "ULOO",
    type: "medium_airport",
    name: "Pskov Airport",
    elevation_ft: "154",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PSK",
    municipality: "Pskov",
    gps_code: "ULOO",
    iata_code: "PKV",
    coordinates: "28.395599365234375, 57.78390121459961",
  },
  {
    ident: "ULPB",
    type: "medium_airport",
    name: "Petrozavodsk Airport",
    elevation_ft: "151",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KR",
    municipality: "Petrozavodsk",
    gps_code: "ULPB",
    iata_code: "PES",
    coordinates: "34.154701232910156, 61.88520050048828",
  },
  {
    ident: "ULSS",
    type: "closed",
    name: "Rzhevka Airport",
    elevation_ft: "56",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-LEN",
    municipality: "St. Petersburg",
    gps_code: "ULSS",
    iata_code: "RVH",
    coordinates: "30.585599899291992, 59.97999954223633",
  },
  {
    ident: "ULWW",
    type: "medium_airport",
    name: "Vologda Airport",
    elevation_ft: "387",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-VLG",
    municipality: "Vologda",
    gps_code: "ULWW",
    iata_code: "VGD",
    coordinates: "39.944400787353516, 59.282501220703125",
  },
  {
    ident: "UMBB",
    type: "medium_airport",
    name: "Brest Airport",
    elevation_ft: "468",
    continent: "EU",
    iso_country: "BY",
    iso_region: "BY-BR",
    municipality: "Brest",
    gps_code: "UMBB",
    iata_code: "BQT",
    coordinates: "23.8981, 52.108299",
  },
  {
    ident: "UMGG",
    type: "medium_airport",
    name: "Gomel Airport",
    elevation_ft: "472",
    continent: "EU",
    iso_country: "BY",
    iso_region: "BY-HO",
    municipality: "Gomel",
    gps_code: "UMGG",
    iata_code: "GME",
    coordinates: "31.016700744628906, 52.527000427246094",
  },
  {
    ident: "UMII",
    type: "medium_airport",
    name: "Vitebsk Vostochny Airport",
    elevation_ft: "682",
    continent: "EU",
    iso_country: "BY",
    iso_region: "BY-VI",
    municipality: "Vitebsk",
    gps_code: "UMII",
    iata_code: "VTB",
    coordinates: "30.349599838257, 55.126499176025",
  },
  {
    ident: "UMKK",
    type: "medium_airport",
    name: "Khrabrovo Airport",
    elevation_ft: "42",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KGD",
    municipality: "Kaliningrad",
    gps_code: "UMKK",
    iata_code: "KGD",
    coordinates: "20.592599868774414, 54.88999938964844",
  },
  {
    ident: "UMMG",
    type: "medium_airport",
    name: "Hrodna Airport",
    elevation_ft: "443",
    continent: "EU",
    iso_country: "BY",
    iso_region: "BY-HR",
    municipality: "Hrodna",
    gps_code: "UMMG",
    iata_code: "GNA",
    coordinates: "24.053800582885742, 53.60200119018555",
  },
  {
    ident: "UMMM",
    type: "closed",
    name: "Minsk 1 Airport",
    elevation_ft: "748",
    continent: "EU",
    iso_country: "BY",
    iso_region: "BY-MI",
    municipality: "Minsk",
    gps_code: "UMMM",
    iata_code: "MHP",
    coordinates: "27.5397, 53.864498",
  },
  {
    ident: "UMMS",
    type: "large_airport",
    name: "Minsk National Airport",
    elevation_ft: "670",
    continent: "EU",
    iso_country: "BY",
    iso_region: "BY-MI",
    municipality: "Minsk",
    gps_code: "UMMS",
    iata_code: "MSQ",
    coordinates: "28.030700683594, 53.882499694824",
  },
  {
    ident: "UMOO",
    type: "medium_airport",
    name: "Mogilev Airport",
    elevation_ft: "637",
    continent: "EU",
    iso_country: "BY",
    iso_region: "BY-MA",
    municipality: "Mogilev",
    gps_code: "UMOO",
    iata_code: "MVQ",
    coordinates: "30.09510040283203, 53.954898834228516",
  },
  {
    ident: "UNAA",
    type: "medium_airport",
    name: "Abakan Airport",
    elevation_ft: "831",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KK",
    municipality: "Abakan",
    gps_code: "UNAA",
    iata_code: "ABA",
    coordinates: "91.38500213623047, 53.7400016784668",
  },
  {
    ident: "UNBB",
    type: "medium_airport",
    name: "Barnaul Airport",
    elevation_ft: "837",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-ALT",
    municipality: "Barnaul",
    gps_code: "UNBB",
    iata_code: "BAX",
    coordinates: "83.53849792480469, 53.363800048828125",
  },
  {
    ident: "UNBG",
    type: "medium_airport",
    name: "Gorno-Altaysk Airport",
    elevation_ft: "965",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-AL",
    municipality: "Gorno-Altaysk",
    gps_code: "UNBG",
    iata_code: "RGK",
    coordinates: "85.8332977295, 51.9667015076",
  },
  {
    ident: "UNEE",
    type: "medium_airport",
    name: "Kemerovo Airport",
    elevation_ft: "863",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KEM",
    municipality: "Kemerovo",
    gps_code: "UNEE",
    iata_code: "KEJ",
    coordinates: "86.1072006225586, 55.27009963989258",
  },
  {
    ident: "UNII",
    type: "medium_airport",
    name: "Yeniseysk Airport",
    elevation_ft: "253",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KYA",
    municipality: "Yeniseysk",
    gps_code: "UNII",
    iata_code: "EIE",
    coordinates: "92.11250305175781, 58.47420120239258",
  },
  {
    ident: "UNIP",
    type: "medium_airport",
    name: "Podkamennaya Tunguska Airport",
    elevation_ft: "213",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KYA",
    municipality: "Bor",
    gps_code: "UNIP",
    iata_code: "TGP",
    coordinates: "89.994003, 61.589699",
  },
  {
    ident: "UNKL",
    type: "large_airport",
    name: "Yemelyanovo Airport",
    elevation_ft: "942",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KYA",
    municipality: "Krasnoyarsk",
    gps_code: "UNKL",
    iata_code: "KJA",
    coordinates: "92.493301, 56.172901",
  },
  {
    ident: "UNKS",
    type: "medium_airport",
    name: "Achinsk Airport",
    elevation_ft: "1033",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KYA",
    municipality: "Achinsk",
    gps_code: "UNKS",
    iata_code: "ACS",
    coordinates: "90.57080078125, 56.2682991027832",
  },
  {
    ident: "UNKY",
    type: "medium_airport",
    name: "Kyzyl Airport",
    elevation_ft: "2123",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-TY",
    municipality: "Kyzyl",
    gps_code: "UNKY",
    iata_code: "KYZ",
    coordinates: "94.40059661865234, 51.66939926147461",
  },
  {
    ident: "UNNT",
    type: "large_airport",
    name: "Tolmachevo Airport",
    elevation_ft: "365",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-NVS",
    municipality: "Novosibirsk",
    gps_code: "UNNT",
    iata_code: "OVB",
    coordinates: "82.650703430176, 55.012599945068",
  },
  {
    ident: "UNOO",
    type: "medium_airport",
    name: "Omsk Central Airport",
    elevation_ft: "311",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-OMS",
    municipality: "Omsk",
    gps_code: "UNOO",
    iata_code: "OMS",
    coordinates: "73.31050109863281, 54.96699905395508",
  },
  {
    ident: "UNSS",
    type: "medium_airport",
    name: "Strezhevoy Airport",
    elevation_ft: "164",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-TOM",
    municipality: "Strezhevoy",
    gps_code: "UNSS",
    iata_code: "SWT",
    coordinates: "77.66000366210001, 60.709400177",
  },
  {
    ident: "UNTT",
    type: "medium_airport",
    name: "Bogashevo Airport",
    elevation_ft: "597",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-TOM",
    municipality: "Tomsk",
    gps_code: "UNTT",
    iata_code: "TOF",
    coordinates: "85.208297729492, 56.380298614502",
  },
  {
    ident: "UNWW",
    type: "medium_airport",
    name: "Spichenkovo Airport",
    elevation_ft: "1024",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KEM",
    municipality: "Novokuznetsk",
    gps_code: "UNWW",
    iata_code: "NOZ",
    coordinates: "86.877197, 53.811401",
  },
  {
    ident: "UODD",
    type: "medium_airport",
    name: "Dikson Airport",
    elevation_ft: "47",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KYA",
    municipality: "Dikson",
    gps_code: "UODD",
    iata_code: "DKS",
    coordinates: "80.37966918945312, 73.51780700683594",
  },
  {
    ident: "UOHH",
    type: "medium_airport",
    name: "Khatanga Airport",
    elevation_ft: "95",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KYA",
    municipality: "Khatanga",
    gps_code: "UOHH",
    iata_code: "HTG",
    coordinates: "102.49099731445312, 71.97810363769531",
  },
  {
    ident: "UOII",
    type: "medium_airport",
    name: "Igarka Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KYA",
    municipality: "Igarka",
    gps_code: "UOII",
    iata_code: "IAA",
    coordinates: "86.62190246582031, 67.43720245361328",
  },
  {
    ident: "UOOO",
    type: "medium_airport",
    name: "Norilsk-Alykel Airport",
    elevation_ft: "574",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KYA",
    municipality: "Norilsk",
    gps_code: "UOOO",
    iata_code: "NSK",
    coordinates: "87.33219909667969, 69.31109619140625",
  },
  {
    ident: "UOTT",
    type: "small_airport",
    name: "Turukhansk Airport",
    elevation_ft: "128",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KYA",
    municipality: "Turukhansk",
    gps_code: "UOTT",
    iata_code: "THX",
    coordinates: "87.9353027344, 65.797203064",
  },
  {
    ident: "URB",
    type: "closed",
    name: "Urubupunga Airport",
    elevation_ft: "1169",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-SP",
    municipality: "Castilho",
    iata_code: "URB",
    coordinates: "-51.564800262451, -20.777099609375",
  },
  {
    ident: "URKA",
    type: "medium_airport",
    name: "Anapa Vityazevo Airport",
    elevation_ft: "174",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KDA",
    municipality: "Anapa",
    gps_code: "URKA",
    iata_code: "AAQ",
    coordinates: "37.347301483154, 45.002101898193",
  },
  {
    ident: "URKE",
    type: "medium_airport",
    name: "Yeysk Airport",
    elevation_ft: "60",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KDA",
    municipality: "Yeysk",
    gps_code: "URKE",
    iata_code: "EIK",
    coordinates: "38.21, 46.68",
  },
  {
    ident: "URKG",
    type: "medium_airport",
    name: "Gelendzhik Airport",
    elevation_ft: "98",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KDA",
    municipality: "Gelendzhik",
    gps_code: "URKG",
    iata_code: "GDZ",
    coordinates: "38.0124807358, 44.5820926295",
  },
  {
    ident: "URKK",
    type: "medium_airport",
    name: "Krasnodar Pashkovsky International Airport",
    elevation_ft: "118",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KDA",
    municipality: "Krasnodar",
    gps_code: "URKK",
    iata_code: "KRR",
    coordinates: "39.170501708984, 45.034698486328",
  },
  {
    ident: "URML",
    type: "medium_airport",
    name: "Uytash Airport",
    elevation_ft: "12",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-DA",
    municipality: "Makhachkala",
    gps_code: "URML",
    iata_code: "MCX",
    coordinates: "47.65230178833008, 42.81679916381836",
  },
  {
    ident: "URMM",
    type: "medium_airport",
    name: "Mineralnyye Vody Airport",
    elevation_ft: "1054",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-STA",
    municipality: "Mineralnyye Vody",
    gps_code: "URMM",
    iata_code: "MRV",
    coordinates: "43.08190155029297, 44.225101470947266",
  },
  {
    ident: "URMN",
    type: "medium_airport",
    name: "Nalchik Airport",
    elevation_ft: "1461",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KB",
    municipality: "Nalchik",
    gps_code: "URMN",
    iata_code: "NAL",
    coordinates: "43.636600494384766, 43.512901306152344",
  },
  {
    ident: "URMO",
    type: "medium_airport",
    name: "Beslan Airport",
    elevation_ft: "1673",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SE",
    municipality: "Beslan",
    gps_code: "URMO",
    iata_code: "OGZ",
    coordinates: "44.6066017151, 43.2051010132",
  },
  {
    ident: "URMS",
    type: "medium_airport",
    name: "Magas Airport",
    elevation_ft: "1165",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-IN",
    municipality: "Magas",
    gps_code: "URMS",
    iata_code: "IGT",
    coordinates: "45.0125999451, 43.322299957300004",
  },
  {
    ident: "URMT",
    type: "medium_airport",
    name: "Stavropol Shpakovskoye Airport",
    elevation_ft: "1486",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-STA",
    municipality: "Stavropol",
    gps_code: "URMT",
    iata_code: "STW",
    coordinates: "42.11280059814453, 45.10919952392578",
  },
  {
    ident: "URRP",
    type: "large_airport",
    name: "Platov International Airport",
    elevation_ft: "213",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ROS",
    municipality: "Rostov-on-Don",
    gps_code: "URRP",
    iata_code: "ROV",
    coordinates: "39.924722, 47.493888",
  },
  {
    ident: "URRR",
    type: "closed",
    name: "Rostov-on-Don Airport",
    elevation_ft: "280",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ROS",
    municipality: "Rostov-on-Don",
    gps_code: "URRR",
    iata_code: "RVI",
    coordinates: "39.8181, 47.258203",
  },
  {
    ident: "URRT",
    type: "medium_airport",
    name: "Taganrog Yuzhny Airport",
    elevation_ft: "117",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ROS",
    municipality: "Taganrog",
    gps_code: "URRT",
    iata_code: "TGK",
    coordinates: "38.8491667, 47.1983333",
  },
  {
    ident: "URRY",
    type: "closed",
    name: "Volgodonsk Airport",
    elevation_ft: "276",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ROS",
    gps_code: "URRQ",
    iata_code: "VLK",
    coordinates: "42.0728, 47.682098",
  },
  {
    ident: "URSS",
    type: "large_airport",
    name: "Sochi International Airport",
    elevation_ft: "89",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KDA",
    municipality: "Sochi",
    gps_code: "URSS",
    iata_code: "AER",
    coordinates: "39.9566, 43.449902",
  },
  {
    ident: "URWA",
    type: "medium_airport",
    name: "Astrakhan Airport",
    elevation_ft: "-65",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-AST",
    municipality: "Astrakhan",
    gps_code: "URWA",
    iata_code: "ASF",
    coordinates: "48.0063018799, 46.2832984924",
  },
  {
    ident: "URWI",
    type: "medium_airport",
    name: "Elista Airport",
    elevation_ft: "501",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KL",
    municipality: "Elista",
    gps_code: "URWI",
    iata_code: "ESL",
    coordinates: "44.33089828491211, 46.3739013671875",
  },
  {
    ident: "URWW",
    type: "medium_airport",
    name: "Volgograd International Airport",
    elevation_ft: "482",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-VGG",
    municipality: "Volgograd",
    gps_code: "URWW",
    iata_code: "VOG",
    coordinates: "44.34550094604492, 48.782501220703125",
  },
  {
    ident: "US-0099",
    type: "closed",
    name: "Havasu Airpark",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AZ",
    iata_code: "LHU",
    local_code: "LHU",
    coordinates: "-114.364114, 34.457279",
  },
  {
    ident: "US-0133",
    type: "closed",
    name: "Amchitka Army Airfield",
    elevation_ft: "215",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Amchitka Island",
    iata_code: "AHT",
    coordinates: "179.259166667, 51.3777777778",
  },
  {
    ident: "US-0370",
    type: "seaplane_base",
    name: "Cape Air Seaplanes on Boston Harbor Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-MA",
    municipality: "Boston",
    gps_code: "MA87",
    iata_code: "BNH",
    local_code: "MA87",
    coordinates: "-71.025832, 42.352502",
  },
  {
    ident: "US-0571",
    type: "medium_airport",
    name: "Williston Basin International Airport",
    elevation_ft: "2344",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-ND",
    municipality: "Williston",
    gps_code: "KXWA",
    iata_code: "XWA",
    local_code: "XWA",
    coordinates: "-103.748797, 48.258387",
  },
  {
    ident: "US-0F3",
    type: "small_airport",
    name: "Spirit Lake Municipal Airport",
    elevation_ft: "1434",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Spirit Lake",
    iata_code: "RTL",
    local_code: "0F3",
    coordinates: "-95.139198303223, 43.387500762939",
  },
  {
    ident: "US-BPA",
    type: "closed",
    name: "Grumman Bethpage Airport",
    elevation_ft: "115",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-NY",
    municipality: "Bethpage",
    iata_code: "BPA",
    coordinates: "-73.4960021973, 40.749401092499994",
  },
  {
    ident: "USCC",
    type: "medium_airport",
    name: "Chelyabinsk Balandino Airport",
    elevation_ft: "769",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-CHE",
    municipality: "Chelyabinsk",
    gps_code: "USCC",
    iata_code: "CEK",
    coordinates: "61.5033, 55.305801",
  },
  {
    ident: "USCM",
    type: "medium_airport",
    name: "Magnitogorsk International Airport",
    elevation_ft: "1430",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-BA",
    municipality: "Magnitogorsk",
    gps_code: "USCM",
    iata_code: "MQF",
    coordinates: "58.755699157714844, 53.39310073852539",
  },
  {
    ident: "USDA",
    type: "medium_airport",
    name: "Sabetta International Airport",
    elevation_ft: "46",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-YAN",
    municipality: "Sabetta",
    gps_code: "USDA",
    iata_code: "SBT",
    coordinates: "72.052222, 71.219167",
  },
  {
    ident: "USDD",
    type: "medium_airport",
    name: "Salekhard Airport",
    elevation_ft: "218",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-YAN",
    municipality: "Salekhard",
    gps_code: "USDD",
    iata_code: "SLY",
    coordinates: "66.61100006103516, 66.5907974243164",
  },
  {
    ident: "USDK",
    type: "small_airport",
    name: "Mys Kamenny Airport",
    elevation_ft: "98",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-YAN",
    municipality: "Mys Kamennyi",
    gps_code: "USDK",
    iata_code: "YMK",
    coordinates: "73.5670013428, 68.483001709",
  },
  {
    ident: "USDP",
    type: "small_airport",
    name: "Krasnoselkup Airport",
    elevation_ft: "101",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-YAN",
    municipality: "Krasnoselkup",
    gps_code: "USDP",
    iata_code: "KKQ",
    coordinates: "82.455, 65.717",
  },
  {
    ident: "USDS",
    type: "small_airport",
    name: "Tarko-Sale Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-YAN",
    municipality: "Tarko-Sale",
    gps_code: "USDS",
    iata_code: "TQL",
    coordinates: "77.81809997559999, 64.9308013916",
  },
  {
    ident: "USDU",
    type: "small_airport",
    name: "Urengoy Airport",
    elevation_ft: "56",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-YAN",
    municipality: "Urengoy",
    gps_code: "USDU",
    iata_code: "UEN",
    coordinates: "78.43699646, 65.9599990845",
  },
  {
    ident: "USHB",
    type: "medium_airport",
    name: "Berezovo Airport",
    elevation_ft: "98",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    gps_code: "USHB",
    iata_code: "EZV",
    coordinates: "65.03050231933594, 63.92100143432617",
  },
  {
    ident: "USHH",
    type: "medium_airport",
    name: "Khanty Mansiysk Airport",
    elevation_ft: "76",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    municipality: "Khanty-Mansiysk",
    gps_code: "USHH",
    iata_code: "HMA",
    coordinates: "69.08609771728516, 61.028499603271484",
  },
  {
    ident: "USHI",
    type: "small_airport",
    name: "Igrim Airport",
    elevation_ft: "79",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    gps_code: "USHI",
    iata_code: "IRM",
    coordinates: "64.4393005371, 63.1987991333",
  },
  {
    ident: "USHN",
    type: "medium_airport",
    name: "Nyagan Airport",
    elevation_ft: "361",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KHM",
    municipality: "Nyagan",
    gps_code: "USHN",
    iata_code: "NYA",
    coordinates: "65.614998, 62.110001",
  },
  {
    ident: "USHS",
    type: "medium_airport",
    name: "Sovetskiy Airport",
    elevation_ft: "351",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    municipality: "Sovetskiy",
    gps_code: "USHS",
    iata_code: "OVS",
    coordinates: "63.60191345214844, 61.326622009277344",
  },
  {
    ident: "USHU",
    type: "medium_airport",
    name: "Uray Airport",
    elevation_ft: "190",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    municipality: "Uray",
    gps_code: "USHU",
    iata_code: "URJ",
    coordinates: "64.82669830322266, 60.10329818725586",
  },
  {
    ident: "USHY",
    type: "medium_airport",
    name: "Beloyarskiy Airport",
    elevation_ft: "82",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KHM",
    gps_code: "USHQ",
    iata_code: "EYK",
    coordinates: "66.698601, 63.686901",
  },
  {
    ident: "USII",
    type: "medium_airport",
    name: "Izhevsk Airport",
    elevation_ft: "531",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-UD",
    municipality: "Izhevsk",
    gps_code: "USII",
    iata_code: "IJK",
    coordinates: "53.45750045776367, 56.82809829711914",
  },
  {
    ident: "USKK",
    type: "medium_airport",
    name: "Pobedilovo Airport",
    elevation_ft: "479",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KIR",
    municipality: "Kirov",
    gps_code: "USKK",
    iata_code: "KVX",
    coordinates: "49.348300933838, 58.503299713135",
  },
  {
    ident: "USMM",
    type: "medium_airport",
    name: "Nadym Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-YAN",
    municipality: "Nadym",
    gps_code: "USMM",
    iata_code: "NYM",
    coordinates: "72.69889831542969, 65.48090362548828",
  },
  {
    ident: "USMU",
    type: "medium_airport",
    name: "Novy Urengoy Airport",
    elevation_ft: "210",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-YAN",
    municipality: "Novy Urengoy",
    gps_code: "USMU",
    iata_code: "NUX",
    coordinates: "76.52030181884766, 66.06939697265625",
  },
  {
    ident: "USNN",
    type: "medium_airport",
    name: "Nizhnevartovsk Airport",
    elevation_ft: "177",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    municipality: "Nizhnevartovsk",
    gps_code: "USNN",
    iata_code: "NJC",
    coordinates: "76.48359680175781, 60.94929885864258",
  },
  {
    ident: "USNR",
    type: "closed",
    name: "Raduzhny Airport",
    elevation_ft: "250",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    municipality: "Raduzhnyi",
    gps_code: "USNR",
    iata_code: "RAT",
    coordinates: "77.32890319820001, 62.1585998535",
  },
  {
    ident: "USO",
    type: "closed",
    name: "Usino Airport",
    elevation_ft: "430",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Usino",
    iata_code: "USO",
    coordinates: "145.371, -5.5276",
  },
  {
    ident: "USPP",
    type: "medium_airport",
    name: "Bolshoye Savino Airport",
    elevation_ft: "404",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PER",
    municipality: "Perm",
    gps_code: "USPP",
    iata_code: "PEE",
    coordinates: "56.021198272705, 57.914501190186",
  },
  {
    ident: "USRK",
    type: "medium_airport",
    name: "Kogalym International Airport",
    elevation_ft: "220",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    municipality: "Kogalym",
    gps_code: "USRK",
    iata_code: "KGP",
    coordinates: "74.53379821777344, 62.190399169921875",
  },
  {
    ident: "USRN",
    type: "medium_airport",
    name: "Nefteyugansk Airport",
    elevation_ft: "115",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    municipality: "Nefteyugansk",
    gps_code: "USRN",
    iata_code: "NFG",
    coordinates: "72.6500015258789, 61.108299255371094",
  },
  {
    ident: "USRO",
    type: "medium_airport",
    name: "Noyabrsk Airport",
    elevation_ft: "446",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-YAN",
    municipality: "Noyabrsk",
    gps_code: "USRO",
    iata_code: "NOJ",
    coordinates: "75.2699966430664, 63.18330001831055",
  },
  {
    ident: "USRR",
    type: "medium_airport",
    name: "Surgut Airport",
    elevation_ft: "200",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KHM",
    municipality: "Surgut",
    gps_code: "USRR",
    iata_code: "SGC",
    coordinates: "73.40180206298828, 61.34370040893555",
  },
  {
    ident: "USSS",
    type: "large_airport",
    name: "Koltsovo Airport",
    elevation_ft: "764",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SVE",
    municipality: "Yekaterinburg",
    gps_code: "USSS",
    iata_code: "SVX",
    coordinates: "60.802700042725, 56.743099212646",
  },
  {
    ident: "USTO",
    type: "small_airport",
    name: "Tobolsk Airport",
    elevation_ft: "167",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-TYU",
    municipality: "Tobolsk",
    gps_code: "USTO",
    iata_code: "TOX",
    coordinates: "68.23190307617188, 58.135799407958984",
  },
  {
    ident: "USTR",
    type: "medium_airport",
    name: "Roshchino International Airport",
    elevation_ft: "378",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-TYU",
    municipality: "Tyumen",
    gps_code: "USTR",
    iata_code: "TJM",
    coordinates: "65.3243026733, 57.189601898199996",
  },
  {
    ident: "USUU",
    type: "medium_airport",
    name: "Kurgan Airport",
    elevation_ft: "240",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KGN",
    municipality: "Kurgan",
    gps_code: "USUU",
    iata_code: "KRO",
    coordinates: "65.41560363769531, 55.47529983520508",
  },
  {
    ident: "UT1H",
    type: "small_airport",
    name: "Balkanabat Airport",
    elevation_ft: "5",
    continent: "AS",
    iso_country: "TM",
    iso_region: "TM-B",
    municipality: "Balkanabat",
    iata_code: "BKN",
    coordinates: "54.366001, 39.480598",
  },
  {
    ident: "UT25",
    type: "small_airport",
    name: "Monument Valley Airport",
    elevation_ft: "5192",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-UT",
    municipality: "Goulding's Lodge",
    gps_code: "UT25",
    iata_code: "GMV",
    local_code: "UT25",
    coordinates: "-110.200996399, 37.016700744599994",
  },
  {
    ident: "UTAA",
    type: "large_airport",
    name: "Ashgabat International Airport",
    elevation_ft: "692",
    continent: "AS",
    iso_country: "TM",
    iso_region: "TM-A",
    municipality: "Ashgabat",
    gps_code: "UTAA",
    iata_code: "ASB",
    coordinates: "58.361, 37.986801",
  },
  {
    ident: "UTAK",
    type: "medium_airport",
    name: "Turkmenbashi Airport",
    elevation_ft: "279",
    continent: "AS",
    iso_country: "TM",
    iso_region: "TM-B",
    municipality: "Krasnovodsk",
    gps_code: "UTAK",
    iata_code: "KRW",
    coordinates: "53.007198, 40.063301",
  },
  {
    ident: "UTAM",
    type: "medium_airport",
    name: "Mary Airport",
    elevation_ft: "728",
    continent: "AS",
    iso_country: "TM",
    iso_region: "TM-M",
    municipality: "Mary",
    gps_code: "UTAM",
    iata_code: "MYP",
    coordinates: "61.896702, 37.6194",
  },
  {
    ident: "UTAT",
    type: "medium_airport",
    name: "Da����oguz Airport",
    elevation_ft: "272",
    continent: "AS",
    iso_country: "TM",
    iso_region: "TM-D",
    municipality: "Da����oguz",
    gps_code: "UTAT",
    iata_code: "TAZ",
    coordinates: "59.826698, 41.761101",
  },
  {
    ident: "UTAV",
    type: "medium_airport",
    name: "Turkmenabat Airport",
    elevation_ft: "630",
    continent: "AS",
    iso_country: "TM",
    iso_region: "TM-L",
    municipality: "T����rkmenabat",
    gps_code: "UTAV",
    iata_code: "CRZ",
    coordinates: "63.61330032348633, 39.08330154418945",
  },
  {
    ident: "UTDD",
    type: "medium_airport",
    name: "Dushanbe Airport",
    elevation_ft: "2575",
    continent: "AS",
    iso_country: "TJ",
    iso_region: "TJ-RR",
    municipality: "Dushanbe",
    gps_code: "UTDD",
    iata_code: "DYU",
    coordinates: "68.8249969482, 38.543300628699996",
  },
  {
    ident: "UTDK",
    type: "medium_airport",
    name: "Kulob Airport",
    elevation_ft: "2293",
    continent: "AS",
    iso_country: "TJ",
    iso_region: "TJ-KT",
    municipality: "Kulyab",
    gps_code: "UTDK",
    iata_code: "TJU",
    coordinates: "69.80500030517578, 37.98809814453125",
  },
  {
    ident: "UTDL",
    type: "medium_airport",
    name: "Khudzhand Airport",
    elevation_ft: "1450",
    continent: "AS",
    iso_country: "TJ",
    iso_region: "TJ-SU",
    municipality: "Khudzhand",
    gps_code: "UTDL",
    iata_code: "LBD",
    coordinates: "69.6947021484375, 40.21540069580078",
  },
  {
    ident: "UTDT",
    type: "medium_airport",
    name: "Qurghonteppa International Airport",
    elevation_ft: "1473",
    continent: "AS",
    iso_country: "TJ",
    iso_region: "TJ-KT",
    municipality: "Kurgan-Tyube",
    gps_code: "UTDT",
    iata_code: "KQT",
    coordinates: "68.86470031738281, 37.86640167236328",
  },
  {
    ident: "UTKA",
    type: "small_airport",
    name: "Andizhan Airport",
    elevation_ft: "1515",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-AN",
    municipality: "Andizhan",
    gps_code: "UTFA",
    iata_code: "AZN",
    coordinates: "72.2939987183, 40.7276992798",
  },
  {
    ident: "UTKF",
    type: "small_airport",
    name: "Fergana International Airport",
    elevation_ft: "1980",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-FA",
    municipality: "Fergana",
    gps_code: "UTFF",
    iata_code: "FEG",
    coordinates: "71.7450027466, 40.358798980699994",
  },
  {
    ident: "UTKN",
    type: "small_airport",
    name: "Namangan Airport",
    elevation_ft: "1555",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-NG",
    municipality: "Namangan",
    gps_code: "UTFN",
    iata_code: "NMA",
    coordinates: "71.5567016602, 40.9846000671",
  },
  {
    ident: "UTNN",
    type: "small_airport",
    name: "Nukus Airport",
    elevation_ft: "246",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-QR",
    municipality: "Nukus",
    gps_code: "UTNN",
    iata_code: "NCU",
    coordinates: "59.62329864501953, 42.488399505615234",
  },
  {
    ident: "UTNU",
    type: "medium_airport",
    name: "Urgench Airport",
    elevation_ft: "320",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-XO",
    municipality: "Urgench",
    gps_code: "UTNU",
    iata_code: "UGC",
    coordinates: "60.641700744628906, 41.58430099487305",
  },
  {
    ident: "UTSA",
    type: "small_airport",
    name: "Navoi Airport",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-NW",
    municipality: "Navoi",
    gps_code: "UTSA",
    iata_code: "NVI",
    coordinates: "65.1707992553711, 40.1171989440918",
  },
  {
    ident: "UTSB",
    type: "medium_airport",
    name: "Bukhara Airport",
    elevation_ft: "751",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-BU",
    municipality: "Bukhara",
    gps_code: "UTSB",
    iata_code: "BHK",
    coordinates: "64.4832992553711, 39.775001525878906",
  },
  {
    ident: "UTSL",
    type: "small_airport",
    name: "Karshi Khanabad Airport",
    elevation_ft: "1365",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-QA",
    municipality: "Khanabad",
    gps_code: "UTSK",
    iata_code: "KSQ",
    coordinates: "65.9215011597, 38.8335990906",
  },
  {
    ident: "UTSN",
    type: "small_airport",
    name: "Sugraly Airport",
    elevation_ft: "1396",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-NW",
    municipality: "Zarafshan",
    gps_code: "UTSN",
    iata_code: "AFS",
    coordinates: "64.23320007324219, 41.61389923095703",
  },
  {
    ident: "UTSS",
    type: "medium_airport",
    name: "Samarkand Airport",
    elevation_ft: "2224",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-SA",
    municipality: "Samarkand",
    gps_code: "UTSS",
    iata_code: "SKD",
    coordinates: "66.98380279541016, 39.70050048828125",
  },
  {
    ident: "UTST",
    type: "medium_airport",
    name: "Termez Airport",
    elevation_ft: "1027",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-SU",
    municipality: "Termez",
    gps_code: "UTST",
    iata_code: "TMJ",
    coordinates: "67.30999755859375, 37.28670120239258",
  },
  {
    ident: "UTTT",
    type: "large_airport",
    name: "Tashkent International Airport",
    elevation_ft: "1417",
    continent: "AS",
    iso_country: "UZ",
    iso_region: "UZ-TO",
    municipality: "Tashkent",
    gps_code: "UTTT",
    iata_code: "TAS",
    coordinates: "69.2811965942, 41.257900238",
  },
  {
    ident: "UTU",
    type: "small_airport",
    name: "Ustupo Airport",
    elevation_ft: "9",
    continent: "NA",
    iso_country: "PA",
    iso_region: "PA-KY",
    municipality: "Ustupo",
    iata_code: "UTU",
    coordinates: "-77.9337, 9.1283",
  },
  {
    ident: "UUBA",
    type: "medium_airport",
    name: "Kostroma Sokerkino Airport",
    elevation_ft: "446",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KOS",
    municipality: "Kostroma",
    gps_code: "UUBA",
    iata_code: "KMW",
    coordinates: "41.019401550299996, 57.7969017029",
  },
  {
    ident: "UUBB",
    type: "closed",
    name: "Bykovo Airport",
    elevation_ft: "427",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MOS",
    municipality: "Moscow",
    gps_code: "UUBB",
    iata_code: "BKA",
    coordinates: "38.0600013733, 55.6171989441",
  },
  {
    ident: "UUBC",
    type: "medium_airport",
    name: "Grabtsevo Airport",
    elevation_ft: "656",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KLU",
    municipality: "Kaluga",
    gps_code: "UUBC",
    iata_code: "KLF",
    coordinates: "36.3666687012, 54.5499992371",
  },
  {
    ident: "UUBI",
    type: "medium_airport",
    name: "Ivanovo South Airport",
    elevation_ft: "410",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-IVA",
    municipality: "Ivanovo",
    gps_code: "UUBI",
    iata_code: "IWA",
    coordinates: "40.940799713134766, 56.93939971923828",
  },
  {
    ident: "UUBK",
    type: "medium_airport",
    name: "Staroselye Airport",
    elevation_ft: "423",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-YAR",
    municipality: "Rybinsk",
    gps_code: "UUBK",
    iata_code: "RYB",
    coordinates: "38.92940139770508, 58.10419845581055",
  },
  {
    ident: "UUBP",
    type: "medium_airport",
    name: "Bryansk Airport",
    elevation_ft: "663",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-BRY",
    municipality: "Bryansk",
    gps_code: "UUBP",
    iata_code: "BZK",
    coordinates: "34.176399, 53.214199",
  },
  {
    ident: "UUBW",
    type: "large_airport",
    name: "Zhukovsky International Airport",
    elevation_ft: "377",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MOS",
    municipality: "Moscow",
    gps_code: "UUBW",
    iata_code: "ZIA",
    coordinates: "38.150002, 55.553299",
  },
  {
    ident: "UUDD",
    type: "large_airport",
    name: "Domodedovo International Airport",
    elevation_ft: "588",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MOS",
    municipality: "Moscow",
    gps_code: "UUDD",
    iata_code: "DME",
    coordinates: "37.90629959106445, 55.40879821777344",
  },
  {
    ident: "UUDL",
    type: "small_airport",
    name: "Tunoshna Airport",
    elevation_ft: "287",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-YAR",
    gps_code: "UUDL",
    iata_code: "IAR",
    coordinates: "40.15739822387695, 57.560699462890625",
  },
  {
    ident: "UUEE",
    type: "large_airport",
    name: "Sheremetyevo International Airport",
    elevation_ft: "622",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MOS",
    municipality: "Moscow",
    gps_code: "UUEE",
    iata_code: "SVO",
    coordinates: "37.4146, 55.972599",
  },
  {
    ident: "UUEM",
    type: "medium_airport",
    name: "Migalovo Air Base",
    elevation_ft: "469",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-TVE",
    municipality: "Tver",
    gps_code: "UUEM",
    iata_code: "KLD",
    coordinates: "35.7577018737793, 56.82469940185547",
  },
  {
    ident: "UUMO",
    type: "medium_airport",
    name: "Ostafyevo International Airport",
    elevation_ft: "568",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MOS",
    municipality: "Moscow",
    gps_code: "UUMO",
    iata_code: "OSF",
    coordinates: "37.507222, 55.511667",
  },
  {
    ident: "UUMU",
    type: "medium_airport",
    name: "Chkalovskiy Air Base",
    elevation_ft: "499",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MOS",
    municipality: "Moscow",
    gps_code: "UUMU",
    iata_code: "CKL",
    coordinates: "38.061699, 55.8783",
  },
  {
    ident: "UUOB",
    type: "medium_airport",
    name: "Belgorod International Airport",
    elevation_ft: "735",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-BEL",
    municipality: "Belgorod",
    gps_code: "UUOB",
    iata_code: "EGO",
    coordinates: "36.5900993347168, 50.643798828125",
  },
  {
    ident: "UUOK",
    type: "medium_airport",
    name: "Kursk East Airport",
    elevation_ft: "686",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KRS",
    municipality: "Kursk",
    gps_code: "UUOK",
    iata_code: "URS",
    coordinates: "36.29560089111328, 51.7505989074707",
  },
  {
    ident: "UUOL",
    type: "medium_airport",
    name: "Lipetsk Airport",
    elevation_ft: "584",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-LIP",
    municipality: "Lipetsk",
    gps_code: "UUOL",
    iata_code: "LPK",
    coordinates: "39.53779983520508, 52.70280075073242",
  },
  {
    ident: "UUOO",
    type: "medium_airport",
    name: "Voronezh International Airport",
    elevation_ft: "514",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-VOR",
    municipality: "Voronezh",
    gps_code: "UUOO",
    iata_code: "VOZ",
    coordinates: "39.22959899902344, 51.81420135498047",
  },
  {
    ident: "UUOR",
    type: "medium_airport",
    name: "Oryol Yuzhny Airport",
    elevation_ft: "656",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ORL",
    municipality: "Orel",
    gps_code: "UUOR",
    iata_code: "OEL",
    coordinates: "36.0022010803, 52.934700012200004",
  },
  {
    ident: "UUOT",
    type: "medium_airport",
    name: "Donskoye Airport",
    elevation_ft: "413",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-TAM",
    municipality: "Tambov",
    gps_code: "UUOT",
    iata_code: "TBW",
    coordinates: "41.482799530029, 52.806098937988",
  },
  {
    ident: "UUU",
    type: "small_airport",
    name: "Manumu Airport",
    elevation_ft: "1800",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-CPM",
    municipality: "Manumu",
    iata_code: "UUU",
    local_code: "MMU",
    coordinates: "147.5735, -9.0746",
  },
  {
    ident: "UUWR",
    type: "small_airport",
    name: "Turlatovo Airport",
    elevation_ft: "502",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-RYA",
    municipality: "Ryazan",
    gps_code: "UUWR",
    iata_code: "RZN",
    coordinates: "39.855201721191406, 54.55590057373047",
  },
  {
    ident: "UUWV",
    type: "small_airport",
    name: "Klokovo Airfield",
    elevation_ft: "499",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-TUL",
    municipality: "Tula",
    gps_code: "UUBT",
    iata_code: "TYA",
    coordinates: "37.6, 54.239",
  },
  {
    ident: "UUWW",
    type: "large_airport",
    name: "Vnukovo International Airport",
    elevation_ft: "685",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MOS",
    municipality: "Moscow",
    gps_code: "UUWW",
    iata_code: "VKO",
    coordinates: "37.2615013123, 55.5914993286",
  },
  {
    ident: "UUYH",
    type: "medium_airport",
    name: "Ukhta Airport",
    elevation_ft: "482",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KO",
    municipality: "Ukhta",
    gps_code: "UUYH",
    iata_code: "UCT",
    coordinates: "53.8046989440918, 63.566898345947266",
  },
  {
    ident: "UUYI",
    type: "medium_airport",
    name: "Inta Airport",
    elevation_ft: "184",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KO",
    municipality: "Inta",
    gps_code: "UUYI",
    iata_code: "INA",
    coordinates: "60.110321044921875, 66.0548324584961",
  },
  {
    ident: "UUYP",
    type: "medium_airport",
    name: "Pechora Airport",
    elevation_ft: "98",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KO",
    municipality: "Pechora",
    gps_code: "UUYP",
    iata_code: "PEX",
    coordinates: "57.13079833984375, 65.12110137939453",
  },
  {
    ident: "UUYS",
    type: "medium_airport",
    name: "Usinsk Airport",
    elevation_ft: "262",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KO",
    municipality: "Usinsk",
    gps_code: "UUYS",
    iata_code: "USK",
    coordinates: "57.3671989440918, 66.00469970703125",
  },
  {
    ident: "UUYW",
    type: "medium_airport",
    name: "Vorkuta Airport",
    elevation_ft: "604",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-KO",
    municipality: "Vorkuta",
    gps_code: "UUYW",
    iata_code: "VKT",
    coordinates: "63.993099212646484, 67.48860168457031",
  },
  {
    ident: "UUYX",
    type: "medium_airport",
    name: "Ust-Tsylma Airport",
    elevation_ft: "262",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KO",
    municipality: "Ust-Tsylma",
    gps_code: "UUYX",
    iata_code: "UTS",
    coordinates: "52.20033645629999, 65.43729400630001",
  },
  {
    ident: "UUYY",
    type: "medium_airport",
    name: "Syktyvkar Airport",
    elevation_ft: "342",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-KO",
    municipality: "Syktyvkar",
    gps_code: "UUYY",
    iata_code: "SCW",
    coordinates: "50.84510040283203, 61.64699935913086",
  },
  {
    ident: "UWGG",
    type: "medium_airport",
    name: "Nizhny Novgorod Strigino International Airport",
    elevation_ft: "256",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-NIZ",
    municipality: "Nizhny Novgorod",
    gps_code: "UWGG",
    iata_code: "GOJ",
    coordinates: "43.784000396729, 56.230098724365",
  },
  {
    ident: "UWKB",
    type: "medium_airport",
    name: "Bugulma Airport",
    elevation_ft: "991",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-TA",
    municipality: "Bugulma",
    gps_code: "UWKB",
    iata_code: "UUA",
    coordinates: "52.801700592041016, 54.63999938964844",
  },
  {
    ident: "UWKD",
    type: "large_airport",
    name: "Kazan International Airport",
    elevation_ft: "411",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-TA",
    municipality: "Kazan",
    gps_code: "UWKD",
    iata_code: "KZN",
    coordinates: "49.278701782227, 55.606201171875",
  },
  {
    ident: "UWKE",
    type: "medium_airport",
    name: "Begishevo Airport",
    elevation_ft: "643",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-TA",
    municipality: "Nizhnekamsk",
    gps_code: "UWKE",
    iata_code: "NBC",
    coordinates: "52.092498779296875, 55.564701080322266",
  },
  {
    ident: "UWKJ",
    type: "medium_airport",
    name: "Yoshkar-Ola Airport",
    elevation_ft: "348",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ME",
    municipality: "Yoshkar-Ola",
    gps_code: "UWKJ",
    iata_code: "JOK",
    coordinates: "47.904701232910156, 56.700599670410156",
  },
  {
    ident: "UWKS",
    type: "medium_airport",
    name: "Cheboksary Airport",
    elevation_ft: "558",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-CU",
    municipality: "Cheboksary",
    gps_code: "UWKS",
    iata_code: "CSY",
    coordinates: "47.3473014831543, 56.090301513671875",
  },
  {
    ident: "UWKV",
    type: "medium_airport",
    name: "Zhigansk Airport",
    elevation_ft: "292",
    continent: "AS",
    iso_country: "RU",
    iso_region: "RU-SA",
    municipality: "Zhigansk",
    gps_code: "UEVV",
    iata_code: "ZIX",
    coordinates: "123.361000061, 66.7965011597",
  },
  {
    ident: "UWLL",
    type: "medium_airport",
    name: "Ulyanovsk Baratayevka Airport",
    elevation_ft: "463",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ULY",
    municipality: "Ulyanovsk",
    gps_code: "UWLL",
    iata_code: "ULV",
    coordinates: "48.226699829100006, 54.26829910279999",
  },
  {
    ident: "UWLW",
    type: "medium_airport",
    name: "Ulyanovsk East Airport",
    elevation_ft: "252",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ULY",
    municipality: "Ulyanovsk",
    gps_code: "UWLW",
    iata_code: "ULY",
    coordinates: "48.80270004272461, 54.4010009765625",
  },
  {
    ident: "UWOO",
    type: "medium_airport",
    name: "Orenburg Central Airport",
    elevation_ft: "387",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ORE",
    municipality: "Orenburg",
    gps_code: "UWOO",
    iata_code: "REN",
    coordinates: "55.45669937133789, 51.795799255371094",
  },
  {
    ident: "UWOR",
    type: "medium_airport",
    name: "Orsk Airport",
    elevation_ft: "909",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-ORE",
    municipality: "Orsk",
    gps_code: "UWOR",
    iata_code: "OSW",
    coordinates: "58.59560012817383, 51.0724983215332",
  },
  {
    ident: "UWPP",
    type: "medium_airport",
    name: "Penza Airport",
    elevation_ft: "614",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-PNZ",
    municipality: "Penza",
    gps_code: "UWPP",
    iata_code: "PEZ",
    coordinates: "45.02109909057617, 53.110599517822266",
  },
  {
    ident: "UWPS",
    type: "medium_airport",
    name: "Saransk Airport",
    elevation_ft: "676",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-MO",
    municipality: "Saransk",
    gps_code: "UWPS",
    iata_code: "SKX",
    coordinates: "45.212257385253906, 54.12512969970703",
  },
  {
    ident: "UWSB",
    type: "medium_airport",
    name: "Balakovo Airport",
    elevation_ft: "95",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SAR",
    municipality: "Balakovo",
    gps_code: "UWSB",
    iata_code: "BWO",
    coordinates: "47.7456016541, 51.8582992554",
  },
  {
    ident: "UWSS",
    type: "medium_airport",
    name: "Saratov Central Airport",
    elevation_ft: "499",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SAR",
    municipality: "Saratov",
    gps_code: "UWSS",
    iata_code: "RTW",
    coordinates: "46.04669952392578, 51.564998626708984",
  },
  {
    ident: "UWUB",
    type: "small_airport",
    name: "Beloretsk Airport",
    elevation_ft: "1827",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-BA",
    municipality: "Beloretsk",
    gps_code: "UWUB",
    iata_code: "BCX",
    coordinates: "58.34000015258789, 53.9380989074707",
  },
  {
    ident: "UWUF",
    type: "small_airport",
    name: "Neftekamsk Airport",
    elevation_ft: "456",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-BA",
    municipality: "Neftekamsk",
    gps_code: "UWUF",
    iata_code: "NEF",
    coordinates: "54.3471984863, 56.1068992615",
  },
  {
    ident: "UWUK",
    type: "small_airport",
    name: "Oktyabrskiy Airport",
    elevation_ft: "377",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-TA",
    municipality: "Kzyl-Yar",
    gps_code: "UWUK",
    iata_code: "OKT",
    coordinates: "53.3883018494, 54.4399986267",
  },
  {
    ident: "UWUU",
    type: "large_airport",
    name: "Ufa International Airport",
    elevation_ft: "449",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-BA",
    municipality: "Ufa",
    gps_code: "UWUU",
    iata_code: "UFA",
    coordinates: "55.874401092529, 54.557498931885",
  },
  {
    ident: "UWWW",
    type: "large_airport",
    name: "Kurumoch International Airport",
    elevation_ft: "477",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SAM",
    municipality: "Samara",
    gps_code: "UWWW",
    iata_code: "KUF",
    coordinates: "50.16429901123, 53.504901885986",
  },
  {
    ident: "UZM",
    type: "small_airport",
    name: "Hope Bay Aerodrome",
    elevation_ft: "150",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-NU",
    municipality: "Hope Bay",
    iata_code: "UZM",
    local_code: "CHB3",
    coordinates: "-106.618, 68.156",
  },
  {
    ident: "UZR",
    type: "small_airport",
    name: "Urzhar Airport",
    continent: "AS",
    iso_country: "KZ",
    iso_region: "KZ-VOS",
    municipality: "Urzhar",
    gps_code: "UASU",
    iata_code: "UZR",
    coordinates: "81.66521, 47.09115",
  },
  {
    ident: "VA1G",
    type: "medium_airport",
    name: "Rewa Airport, Chorhata, REWA",
    elevation_ft: "1000",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MP",
    municipality: "Rewa",
    gps_code: "VA1G",
    iata_code: "REW",
    local_code: "VA1G",
    coordinates: "81.220299, 24.503401",
  },
  {
    ident: "VA1P",
    type: "small_airport",
    name: "Diu Airport",
    elevation_ft: "31",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    municipality: "Diu",
    gps_code: "VA1P",
    iata_code: "DIU",
    local_code: "VA1P",
    coordinates: "70.92109680175781, 20.71310043334961",
  },
  {
    ident: "VAAH",
    type: "medium_airport",
    name: "Sardar Vallabhbhai Patel International Airport",
    elevation_ft: "189",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    municipality: "Ahmedabad",
    gps_code: "VAAH",
    iata_code: "AMD",
    coordinates: "72.63469696039999, 23.0771999359",
  },
  {
    ident: "VAAK",
    type: "medium_airport",
    name: "Akola Airport",
    elevation_ft: "999",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Akola",
    gps_code: "VAAK",
    iata_code: "AKD",
    coordinates: "77.058601, 20.698999",
  },
  {
    ident: "VAAU",
    type: "medium_airport",
    name: "Aurangabad Airport",
    elevation_ft: "1911",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Aurangabad",
    gps_code: "VAAU",
    iata_code: "IXU",
    coordinates: "75.39810180664062, 19.862699508666992",
  },
  {
    ident: "VABB",
    type: "large_airport",
    name: "Chhatrapati Shivaji International Airport",
    elevation_ft: "39",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Mumbai",
    gps_code: "VABB",
    iata_code: "BOM",
    coordinates: "72.8678970337, 19.0886993408",
  },
  {
    ident: "VABI",
    type: "medium_airport",
    name: "Bilaspur Airport",
    elevation_ft: "899",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-CT",
    municipality: "Bilaspur",
    gps_code: "VEBU",
    iata_code: "PAB",
    coordinates: "82.111, 21.9884",
  },
  {
    ident: "VABJ",
    type: "medium_airport",
    name: "Bhuj Airport",
    elevation_ft: "268",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    municipality: "Bhuj",
    gps_code: "VABJ",
    iata_code: "BHJ",
    coordinates: "69.6701965332, 23.2877998352",
  },
  {
    ident: "VABM",
    type: "medium_airport",
    name: "Belgaum Airport",
    elevation_ft: "2487",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KA",
    municipality: "Belgaum",
    gps_code: "VOBM",
    iata_code: "IXG",
    coordinates: "74.6183013916, 15.859299659700001",
  },
  {
    ident: "VABO",
    type: "medium_airport",
    name: "Vadodara Airport",
    elevation_ft: "129",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    municipality: "Vadodara",
    gps_code: "VABO",
    iata_code: "BDQ",
    coordinates: "73.226303, 22.336201",
  },
  {
    ident: "VABP",
    type: "medium_airport",
    name: "Raja Bhoj International Airport",
    elevation_ft: "1711",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MP",
    municipality: "Bhopal",
    gps_code: "VABP",
    iata_code: "BHO",
    coordinates: "77.3374023438, 23.2875003815",
  },
  {
    ident: "VABV",
    type: "medium_airport",
    name: "Bhavnagar Airport",
    elevation_ft: "44",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    municipality: "Bhavnagar",
    gps_code: "VABV",
    iata_code: "BHU",
    coordinates: "72.1852035522, 21.752199173",
  },
  {
    ident: "VADN",
    type: "medium_airport",
    name: "Daman Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-DD",
    gps_code: "VADN",
    iata_code: "NMB",
    coordinates: "72.84320068359375, 20.43440055847168",
  },
  {
    ident: "VAGN",
    type: "small_airport",
    name: "Guna Airport",
    elevation_ft: "1600",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MP",
    gps_code: "VAGN",
    iata_code: "GUX",
    coordinates: "77.347298, 24.654699",
  },
  {
    ident: "VAGO",
    type: "large_airport",
    name: "Dabolim Airport",
    elevation_ft: "150",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GA",
    municipality: "Vasco da Gama",
    gps_code: "VOGO",
    iata_code: "GOI",
    coordinates: "73.8313980103, 15.3808002472",
  },
  {
    ident: "VAHB",
    type: "medium_airport",
    name: "Hubli Airport",
    elevation_ft: "2171",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KA",
    municipality: "Hubli",
    gps_code: "VOHB",
    iata_code: "HBX",
    coordinates: "75.08489990230001, 15.361700058",
  },
  {
    ident: "VAID",
    type: "medium_airport",
    name: "Devi Ahilyabai Holkar Airport",
    elevation_ft: "1850",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MP",
    municipality: "Indore",
    gps_code: "VAID",
    iata_code: "IDR",
    coordinates: "75.8011016846, 22.7217998505",
  },
  {
    ident: "VAJB",
    type: "medium_airport",
    name: "Jabalpur Airport",
    elevation_ft: "1624",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MP",
    gps_code: "VAJB",
    iata_code: "JLR",
    coordinates: "80.052001953125, 23.177799224853516",
  },
  {
    ident: "VAJM",
    type: "medium_airport",
    name: "Jamnagar Airport",
    elevation_ft: "69",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    municipality: "Jamnagar",
    gps_code: "VAJM",
    iata_code: "JGA",
    coordinates: "70.01260375976562, 22.465499877929688",
  },
  {
    ident: "VAKE",
    type: "medium_airport",
    name: "Kandla Airport",
    elevation_ft: "96",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    municipality: "Kandla",
    gps_code: "VAKE",
    iata_code: "IXY",
    coordinates: "70.100304, 23.1127",
  },
  {
    ident: "VAKJ",
    type: "medium_airport",
    name: "Khajuraho Airport",
    elevation_ft: "728",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MP",
    municipality: "Khajuraho",
    gps_code: "VAKJ",
    iata_code: "HJR",
    coordinates: "79.91860198970001, 24.817199707",
  },
  {
    ident: "VAKP",
    type: "medium_airport",
    name: "Kolhapur Airport",
    elevation_ft: "1996",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    gps_code: "VAKP",
    iata_code: "KLH",
    coordinates: "74.2893981934, 16.6646995544",
  },
  {
    ident: "VAKS",
    type: "medium_airport",
    name: "Keshod Airport",
    elevation_ft: "167",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    gps_code: "VAKS",
    iata_code: "IXK",
    coordinates: "70.27040100097656, 21.317100524902344",
  },
  {
    ident: "VAND",
    type: "medium_airport",
    name: "Nanded Airport",
    elevation_ft: "1250",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Nanded",
    gps_code: "VAND",
    iata_code: "NDC",
    coordinates: "77.31670379639999, 19.1833000183",
  },
  {
    ident: "VANP",
    type: "medium_airport",
    name: "Dr. Babasaheb Ambedkar International Airport",
    elevation_ft: "1033",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Nagpur",
    gps_code: "VANP",
    iata_code: "NAG",
    coordinates: "79.047203, 21.092199",
  },
  {
    ident: "VAOZ",
    type: "medium_airport",
    name: "Nashik Airport",
    elevation_ft: "1900",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Nasik",
    gps_code: "VAOZ",
    iata_code: "ISK",
    coordinates: "73.912903, 20.119101",
  },
  {
    ident: "VAPO",
    type: "medium_airport",
    name: "Pune Airport",
    elevation_ft: "1942",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Pune",
    gps_code: "VAPO",
    iata_code: "PNQ",
    coordinates: "73.9197006225586, 18.58209991455078",
  },
  {
    ident: "VAPR",
    type: "medium_airport",
    name: "Porbandar Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    municipality: "Porbandar",
    gps_code: "VAPR",
    iata_code: "PBD",
    coordinates: "69.65720367429999, 21.6487007141",
  },
  {
    ident: "VARG",
    type: "medium_airport",
    name: "Ratnagiri Airport",
    elevation_ft: "305",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    gps_code: "VARG",
    iata_code: "RTC",
    coordinates: "73.327797, 17.013599",
  },
  {
    ident: "VARK",
    type: "medium_airport",
    name: "Rajkot Airport",
    elevation_ft: "441",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    municipality: "Rajkot",
    gps_code: "VARK",
    iata_code: "RAJ",
    coordinates: "70.77950286869999, 22.3092002869",
  },
  {
    ident: "VARP",
    type: "small_airport",
    name: "Raipur Airport",
    elevation_ft: "1041",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-CT",
    municipality: "Raipur",
    gps_code: "VERP",
    iata_code: "RPR",
    coordinates: "81.7388, 21.180401",
  },
  {
    ident: "VASD",
    type: "medium_airport",
    name: "Shirdi Airport",
    elevation_ft: "1926",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Shirdi",
    gps_code: "VASD",
    iata_code: "SAG",
    coordinates: "74.378889, 19.688611",
  },
  {
    ident: "VASL",
    type: "medium_airport",
    name: "Solapur Airport",
    elevation_ft: "1584",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Solapur",
    gps_code: "VASL",
    iata_code: "SSE",
    coordinates: "75.93479919430001, 17.6280002594",
  },
  {
    ident: "VASU",
    type: "medium_airport",
    name: "Surat Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-GJ",
    gps_code: "VASU",
    iata_code: "STV",
    coordinates: "72.7417984009, 21.1140995026",
  },
  {
    ident: "VAUD",
    type: "medium_airport",
    name: "Maharana Pratap Airport",
    elevation_ft: "1684",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-RJ",
    municipality: "Udaipur",
    gps_code: "VAUD",
    iata_code: "UDR",
    coordinates: "73.89610290530001, 24.617700576799997",
  },
  {
    ident: "VCBI",
    type: "large_airport",
    name: "Bandaranaike International Colombo Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-1",
    municipality: "Colombo",
    gps_code: "VCBI",
    iata_code: "CMB",
    coordinates: "79.88410186767578, 7.180759906768799",
  },
  {
    ident: "VCCA",
    type: "medium_airport",
    name: "Anuradhapura Air Force Base",
    elevation_ft: "324",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-7",
    municipality: "Anuradhapura",
    gps_code: "VCCA",
    iata_code: "ACJ",
    coordinates: "80.42790222170001, 8.30148983002",
  },
  {
    ident: "VCCB",
    type: "medium_airport",
    name: "Batticaloa Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-5",
    municipality: "Batticaloa",
    gps_code: "VCCB",
    iata_code: "BTC",
    coordinates: "81.678802, 7.70576",
  },
  {
    ident: "VCCC",
    type: "medium_airport",
    name: "Colombo Ratmalana Airport",
    elevation_ft: "22",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-1",
    municipality: "Colombo",
    gps_code: "VCCC",
    iata_code: "RML",
    coordinates: "79.88619995117188, 6.821990013122559",
  },
  {
    ident: "VCCG",
    type: "medium_airport",
    name: "Ampara Airport",
    elevation_ft: "150",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-5",
    municipality: "Ampara",
    gps_code: "VCCG",
    iata_code: "ADP",
    coordinates: "81.62594, 7.33776",
  },
  {
    ident: "VCCH",
    type: "medium_airport",
    name: "Hingurakgoda Air Force Base",
    elevation_ft: "170",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-7",
    municipality: "Polonnaruwa Town",
    gps_code: "VCCH",
    iata_code: "HIM",
    coordinates: "80.9814, 8.04981",
  },
  {
    ident: "VCCJ",
    type: "medium_airport",
    name: "Kankesanturai Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-4",
    municipality: "Jaffna",
    gps_code: "VCCJ",
    iata_code: "JAF",
    coordinates: "80.07009887695312, 9.792329788208008",
  },
  {
    ident: "VCCK",
    type: "medium_airport",
    name: "Koggala Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-3",
    municipality: "Galle",
    gps_code: "VCCK",
    iata_code: "KCT",
    coordinates: "80.32029724121094, 5.993680000305176",
  },
  {
    ident: "VCCN",
    type: "small_airport",
    name: "Katukurunda Air Force Base",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-1",
    municipality: "Kalutara",
    gps_code: "VCCN",
    iata_code: "KTY",
    local_code: "VC14",
    coordinates: "79.9775009155, 6.55212020874",
  },
  {
    ident: "VCCS",
    type: "small_airport",
    name: "Sigiriya Air Force Base",
    elevation_ft: "630",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-2",
    municipality: "Sigiriya",
    gps_code: "VCCS",
    iata_code: "GIU",
    coordinates: "80.7285003662, 7.956669807430001",
  },
  {
    ident: "VCCT",
    type: "medium_airport",
    name: "China Bay Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-5",
    municipality: "Trincomalee",
    gps_code: "VCCT",
    iata_code: "TRR",
    coordinates: "81.18190002441406, 8.5385103225708",
  },
  {
    ident: "VCCW",
    type: "small_airport",
    name: "Weerawila Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-3",
    municipality: "Weerawila",
    gps_code: "VCCW",
    iata_code: "WRZ",
    coordinates: "81.23519897460001, 6.25448989868",
  },
  {
    ident: "VCRI",
    type: "large_airport",
    name: "Mattala Rajapaksa International Airport",
    elevation_ft: "157",
    continent: "AS",
    iso_country: "LK",
    iso_region: "LK-3",
    gps_code: "VCRI",
    iata_code: "HRI",
    local_code: "VCRI",
    coordinates: "81.124128, 6.284467",
  },
  {
    ident: "VDBG",
    type: "medium_airport",
    name: "Battambang Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-2",
    municipality: "Battambang",
    gps_code: "VDBG",
    iata_code: "BBM",
    coordinates: "103.2239990234375, 13.095600128173828",
  },
  {
    ident: "VDKH",
    type: "medium_airport",
    name: "Kampong Chhnang Airport",
    elevation_ft: "56",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-4",
    municipality: "Kampong Chhnang",
    gps_code: "VDKH",
    iata_code: "KZC",
    coordinates: "104.564002991, 12.255200386",
  },
  {
    ident: "VDKK",
    type: "small_airport",
    name: "Kaoh Kong Airport",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-9",
    municipality: "Kaoh Kong",
    gps_code: "VDKK",
    iata_code: "KKZ",
    coordinates: "102.997083664, 11.613397118600002",
  },
  {
    ident: "VDKT",
    type: "small_airport",
    name: "Kratie Airport",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-10",
    municipality: "Kratie",
    gps_code: "VDKT",
    iata_code: "KTI",
    coordinates: "106.05500030517578, 12.48799991607666",
  },
  {
    ident: "VDMK",
    type: "small_airport",
    name: "Mondulkiri Airport",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-11",
    municipality: "Sen Monorom",
    gps_code: "VDMK",
    iata_code: "MWV",
    coordinates: "107.187252, 12.463648",
  },
  {
    ident: "VDPP",
    type: "large_airport",
    name: "Phnom Penh International Airport",
    elevation_ft: "40",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-8",
    municipality: "Phnom Penh",
    gps_code: "VDPP",
    iata_code: "PNH",
    coordinates: "104.84400177001953, 11.546600341796875",
  },
  {
    ident: "VDRK",
    type: "medium_airport",
    name: "Ratanakiri Airport",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-16",
    municipality: "Ratanakiri",
    gps_code: "VDRK",
    iata_code: "RBE",
    coordinates: "106.98699951171875, 13.729999542236328",
  },
  {
    ident: "VDSR",
    type: "large_airport",
    name: "Siem Reap International Airport",
    elevation_ft: "60",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-17",
    municipality: "Siem Reap",
    gps_code: "VDSR",
    iata_code: "REP",
    coordinates: "103.81300354, 13.410699844400002",
  },
  {
    ident: "VDST",
    type: "medium_airport",
    name: "Stung Treng Airport",
    elevation_ft: "203",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-19",
    municipality: "Stung Treng",
    gps_code: "VDST",
    iata_code: "TNX",
    coordinates: "106.01499938964844, 13.531900405883789",
  },
  {
    ident: "VDSV",
    type: "small_airport",
    name: "Sihanoukville International Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-18",
    municipality: "Sihanukville",
    gps_code: "VDSV",
    iata_code: "KOS",
    coordinates: "103.637001038, 10.57970047",
  },
  {
    ident: "VDSY",
    type: "small_airport",
    name: "Krakor Airport",
    elevation_ft: "62",
    continent: "AS",
    iso_country: "KH",
    iso_region: "KH-15",
    municipality: "Krakor",
    gps_code: "VDSY",
    iata_code: "KZD",
    coordinates: "104.1486, 12.5385",
  },
  {
    ident: "VE-0095",
    type: "heliport",
    name: "Isla de Tigre  Heliport",
    elevation_ft: "20",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-Y",
    municipality: "Isla de Tigre",
    iata_code: "ELX",
    coordinates: "-62.43669891357422, 9.569999694824219",
  },
  {
    ident: "VE-0114",
    type: "small_airport",
    name: "Lagunillas Airport",
    elevation_ft: "2469",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-V",
    municipality: "Lagunillas",
    iata_code: "LGY",
    coordinates: "-71.23799896240234, 10.12399959564209",
  },
  {
    ident: "VE-0204",
    type: "small_airport",
    name: "Zaraza Airport",
    elevation_ft: "400",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-J",
    municipality: "Zaraza",
    gps_code: "SVCE",
    iata_code: "ZRZ",
    coordinates: "-65.28078, 9.349913",
  },
  {
    ident: "VE-0205",
    type: "small_airport",
    name: "Kamarata Airport",
    elevation_ft: "1770",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    municipality: "Kamarata",
    gps_code: "SVKM",
    iata_code: "KTV",
    coordinates: "-62.416000366199995, 5.75",
  },
  {
    ident: "VE-0206",
    type: "small_airport",
    name: "La Guaira Airport",
    elevation_ft: "2569",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-X",
    municipality: "La Guaira",
    iata_code: "LAG",
    coordinates: "-67.0333328247, 10.5333299637",
  },
  {
    ident: "VE-0207",
    type: "small_airport",
    name: "Macagua Airport",
    elevation_ft: "231",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    municipality: "Ciudad Guayana",
    gps_code: "SVMU",
    iata_code: "SFX",
    coordinates: "-62.6652, 8.2788",
  },
  {
    ident: "VE-0208",
    type: "small_airport",
    name: "San Salvador de Paul Airport",
    elevation_ft: "576",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    municipality: "San Salvador de Paul",
    iata_code: "SVV",
    coordinates: "-62, 7",
  },
  {
    ident: "VE-0209",
    type: "small_airport",
    name: "Wonken Airport",
    elevation_ft: "3250",
    continent: "SA",
    iso_country: "VE",
    iso_region: "VE-F",
    municipality: "Wonken",
    iata_code: "WOK",
    coordinates: "-61.733333587646484, 5.25",
  },
  {
    ident: "VEAN",
    type: "medium_airport",
    name: "Along Airport",
    elevation_ft: "900",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AR",
    gps_code: "VEAN",
    iata_code: "IXV",
    coordinates: "94.802001953125, 28.17530059814453",
  },
  {
    ident: "VEAT",
    type: "medium_airport",
    name: "Agartala Airport",
    elevation_ft: "46",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TR",
    municipality: "Agartala",
    gps_code: "VEAT",
    iata_code: "IXA",
    coordinates: "91.24040222170001, 23.8869991302",
  },
  {
    ident: "VEBD",
    type: "medium_airport",
    name: "Bagdogra Airport",
    elevation_ft: "412",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-WB",
    municipality: "Siliguri",
    gps_code: "VEBD",
    iata_code: "IXB",
    coordinates: "88.32859802246094, 26.68120002746582",
  },
  {
    ident: "VEBG",
    type: "small_airport",
    name: "Balurghat Airport",
    elevation_ft: "78",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-WB",
    municipality: "Balurghat",
    gps_code: "VEBG",
    iata_code: "RGH",
    coordinates: "88.79560089111328, 25.261699676513672",
  },
  {
    ident: "VEBI",
    type: "medium_airport",
    name: "Shillong Airport",
    elevation_ft: "2910",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-ML",
    municipality: "Shillong",
    gps_code: "VEBI",
    iata_code: "SHL",
    coordinates: "91.97869873046875, 25.70359992980957",
  },
  {
    ident: "VEBS",
    type: "medium_airport",
    name: "Biju Patnaik Airport",
    elevation_ft: "138",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-OR",
    municipality: "Bhubaneswar",
    gps_code: "VEBS",
    iata_code: "BBI",
    coordinates: "85.8178024292, 20.244400024399997",
  },
  {
    ident: "VECC",
    type: "large_airport",
    name: "Netaji Subhash Chandra Bose International Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-WB",
    municipality: "Kolkata",
    gps_code: "VECC",
    iata_code: "CCU",
    coordinates: "88.44670104980469, 22.654699325561523",
  },
  {
    ident: "VECO",
    type: "small_airport",
    name: "Cooch Behar Airport",
    elevation_ft: "138",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-WB",
    gps_code: "VECO",
    iata_code: "COH",
    coordinates: "89.4672012329, 26.330499649",
  },
  {
    ident: "VEDB",
    type: "medium_airport",
    name: "Dhanbad Airport",
    elevation_ft: "847",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-BR",
    gps_code: "VEDB",
    iata_code: "DBD",
    coordinates: "86.42530059814453, 23.833999633789062",
  },
  {
    ident: "VEDG",
    type: "medium_airport",
    name: "Kazi Nazrul Islam Airport",
    elevation_ft: "300",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-WB",
    municipality: "Durgapur",
    gps_code: "VEDG",
    iata_code: "RDP",
    coordinates: "87.243, 23.6225",
  },
  {
    ident: "VEDZ",
    type: "small_airport",
    name: "Daporijo Airport",
    elevation_ft: "750",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AR",
    municipality: "Daporijo",
    gps_code: "VEDZ",
    iata_code: "DEP",
    coordinates: "94.22280120850002, 27.985500335699996",
  },
  {
    ident: "VEGK",
    type: "medium_airport",
    name: "Gorakhpur Airport",
    elevation_ft: "259",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-UP",
    municipality: "Gorakhpur",
    gps_code: "VEGK",
    iata_code: "GOP",
    coordinates: "83.4496994019, 26.739700317399997",
  },
  {
    ident: "VEGT",
    type: "medium_airport",
    name: "Lokpriya Gopinath Bordoloi International Airport",
    elevation_ft: "162",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AS",
    municipality: "Guwahati",
    gps_code: "VEGT",
    iata_code: "GAU",
    coordinates: "91.58589935302734, 26.10610008239746",
  },
  {
    ident: "VEGY",
    type: "medium_airport",
    name: "Gaya Airport",
    elevation_ft: "380",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-BR",
    gps_code: "VEGY",
    iata_code: "GAY",
    coordinates: "84.95120239257812, 24.744300842285156",
  },
  {
    ident: "VEIM",
    type: "medium_airport",
    name: "Imphal Airport",
    elevation_ft: "2540",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MN",
    municipality: "Imphal",
    gps_code: "VEIM",
    iata_code: "IMF",
    coordinates: "93.896697998, 24.7600002289",
  },
  {
    ident: "VEJH",
    type: "small_airport",
    name: "Jharsuguda Airport",
    elevation_ft: "751",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-OR",
    gps_code: "VEJH",
    iata_code: "JRG",
    coordinates: "84.0504, 21.9135",
  },
  {
    ident: "VEJP",
    type: "small_airport",
    name: "Jeypore Airport",
    elevation_ft: "1952",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-OR",
    municipality: "Jeypore",
    gps_code: "VEJP",
    iata_code: "PYB",
    coordinates: "82.552001953125, 18.8799991607666",
  },
  {
    ident: "VEJS",
    type: "medium_airport",
    name: "Sonari Airport",
    elevation_ft: "475",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-JH",
    municipality: "Jamshedpur",
    gps_code: "VEJS",
    iata_code: "IXW",
    coordinates: "86.1688, 22.8132",
  },
  {
    ident: "VEJT",
    type: "medium_airport",
    name: "Jorhat Airport",
    elevation_ft: "311",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AS",
    municipality: "Jorhat",
    gps_code: "VEJT",
    iata_code: "JRH",
    coordinates: "94.1754989624, 26.7315006256",
  },
  {
    ident: "VEKM",
    type: "small_airport",
    name: "Kamalpur Airport",
    elevation_ft: "131",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TR",
    gps_code: "VEKM",
    iata_code: "IXQ",
    coordinates: "91.81420135498047, 24.13170051574707",
  },
  {
    ident: "VEKR",
    type: "medium_airport",
    name: "Kailashahar Airport",
    elevation_ft: "79",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TR",
    gps_code: "VEKR",
    iata_code: "IXH",
    coordinates: "92.0072021484375, 24.30820083618164",
  },
  {
    ident: "VEKU",
    type: "medium_airport",
    name: "Silchar Airport",
    elevation_ft: "352",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AS",
    municipality: "Silchar",
    gps_code: "VEKU",
    iata_code: "IXS",
    coordinates: "92.97869873050001, 24.9129009247",
  },
  {
    ident: "VEKW",
    type: "small_airport",
    name: "Khowai Airport",
    elevation_ft: "95",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TR",
    municipality: "Khowai",
    gps_code: "VEKW",
    iata_code: "IXN",
    coordinates: "91.603897, 24.061899",
  },
  {
    ident: "VELP",
    type: "medium_airport",
    name: "Lengpui Airport",
    elevation_ft: "1398",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MZ",
    municipality: "Aizawl",
    gps_code: "VELP",
    iata_code: "AJL",
    coordinates: "92.6196975708, 23.840599060099997",
  },
  {
    ident: "VELR",
    type: "medium_airport",
    name: "North Lakhimpur Airport",
    elevation_ft: "330",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AS",
    municipality: "Lilabari",
    gps_code: "VELR",
    iata_code: "IXI",
    coordinates: "94.09760284423828, 27.295499801635742",
  },
  {
    ident: "VEMH",
    type: "small_airport",
    name: "Malda Airport",
    elevation_ft: "79",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-WB",
    municipality: "Malda",
    gps_code: "VEMH",
    iata_code: "LDA",
    coordinates: "88.133003, 25.033001",
  },
  {
    ident: "VEMN",
    type: "medium_airport",
    name: "Dibrugarh Airport",
    elevation_ft: "362",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AS",
    municipality: "Dibrugarh",
    gps_code: "VEMN",
    iata_code: "DIB",
    coordinates: "95.0168991089, 27.4839000702",
  },
  {
    ident: "VEMR",
    type: "medium_airport",
    name: "Dimapur Airport",
    elevation_ft: "487",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-NL",
    municipality: "Dimapur",
    gps_code: "VEMR",
    iata_code: "DMU",
    coordinates: "93.77110290530001, 25.883899688699998",
  },
  {
    ident: "VEMZ",
    type: "medium_airport",
    name: "Muzaffarpur Airport",
    elevation_ft: "174",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-BR",
    gps_code: "VEMZ",
    iata_code: "MZU",
    coordinates: "85.3136978149414, 26.11910057067871",
  },
  {
    ident: "VEPG",
    type: "small_airport",
    name: "Pasighat Airport",
    elevation_ft: "477",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AR",
    municipality: "Pasighat",
    gps_code: "VEPG",
    iata_code: "IXT",
    coordinates: "95.33560180664062, 28.066099166870117",
  },
  {
    ident: "VEPT",
    type: "medium_airport",
    name: "Lok Nayak Jayaprakash Airport",
    elevation_ft: "170",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-BR",
    municipality: "Patna",
    gps_code: "VEPT",
    iata_code: "PAT",
    coordinates: "85.0879974365, 25.591299057",
  },
  {
    ident: "VERC",
    type: "medium_airport",
    name: "Birsa Munda Airport",
    elevation_ft: "2148",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-JH",
    municipality: "Ranchi",
    gps_code: "VERC",
    iata_code: "IXR",
    coordinates: "85.3217010498, 23.314300537100003",
  },
  {
    ident: "VERK",
    type: "medium_airport",
    name: "Rourkela Airport",
    elevation_ft: "659",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-OR",
    gps_code: "VERK",
    iata_code: "RRK",
    coordinates: "84.814598, 22.256701",
  },
  {
    ident: "VERU",
    type: "small_airport",
    name: "Rupsi India Airport",
    elevation_ft: "131",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AS",
    gps_code: "VERU",
    iata_code: "RUP",
    coordinates: "89.91000366210938, 26.139699935913086",
  },
  {
    ident: "VETZ",
    type: "medium_airport",
    name: "Tezpur Airport",
    elevation_ft: "240",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AS",
    gps_code: "VETZ",
    iata_code: "TEZ",
    coordinates: "92.78469848632812, 26.7091007232666",
  },
  {
    ident: "VEVZ",
    type: "small_airport",
    name: "Vishakhapatnam Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AP",
    municipality: "Visakhapatnam",
    gps_code: "VOVZ",
    iata_code: "VTZ",
    coordinates: "83.224503, 17.721201",
  },
  {
    ident: "VEZO",
    type: "medium_airport",
    name: "Ziro Airport",
    elevation_ft: "5403",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AR",
    municipality: "Ziro",
    gps_code: "VEZO",
    iata_code: "ZER",
    coordinates: "93.828102, 27.588301",
  },
  {
    ident: "VGBR",
    type: "medium_airport",
    name: "Barisal Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-1",
    municipality: "Barisal",
    gps_code: "VGBR",
    iata_code: "BZL",
    coordinates: "90.30120086669922, 22.801000595092773",
  },
  {
    ident: "VGCB",
    type: "medium_airport",
    name: "Cox's Bazar Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-2",
    municipality: "Cox's Bazar",
    gps_code: "VGCB",
    iata_code: "CXB",
    coordinates: "91.96389770507812, 21.452199935913086",
  },
  {
    ident: "VGCM",
    type: "small_airport",
    name: "Comilla Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-2",
    municipality: "Comilla",
    gps_code: "VGCM",
    iata_code: "CLA",
    coordinates: "91.189697265625, 23.43720054626465",
  },
  {
    ident: "VGEG",
    type: "medium_airport",
    name: "Shah Amanat International Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-2",
    municipality: "Chittagong",
    gps_code: "VGEG",
    iata_code: "CGP",
    coordinates: "91.81330108642578, 22.24959945678711",
  },
  {
    ident: "VGIS",
    type: "medium_airport",
    name: "Ishurdi Airport",
    elevation_ft: "45",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-5",
    municipality: "Ishurdi",
    gps_code: "VGIS",
    iata_code: "IRD",
    coordinates: "89.04940032958984, 24.15250015258789",
  },
  {
    ident: "VGJR",
    type: "medium_airport",
    name: "Jessore Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-4",
    municipality: "Jashahor",
    gps_code: "VGJR",
    iata_code: "JSR",
    coordinates: "89.16079711914062, 23.183799743652344",
  },
  {
    ident: "VGLM",
    type: "small_airport",
    name: "Lalmonirhat Airport",
    elevation_ft: "106",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-5",
    municipality: "Lalmonirhat",
    gps_code: "VGLM",
    iata_code: "LLJ",
    coordinates: "89.43309783935547, 25.887500762939453",
  },
  {
    ident: "VGRJ",
    type: "medium_airport",
    name: "Shah Mokhdum Airport",
    elevation_ft: "64",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-5",
    municipality: "Rajshahi",
    gps_code: "VGRJ",
    iata_code: "RJH",
    coordinates: "88.61650085449219, 24.43720054626465",
  },
  {
    ident: "VGSD",
    type: "medium_airport",
    name: "Saidpur Airport",
    elevation_ft: "125",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-5",
    municipality: "Saidpur",
    gps_code: "VGSD",
    iata_code: "SPD",
    coordinates: "88.90889739990234, 25.759199142456055",
  },
  {
    ident: "VGSG",
    type: "small_airport",
    name: "Thakurgaon Airport",
    elevation_ft: "176",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-5",
    municipality: "Thakurgaon",
    gps_code: "VGSG",
    iata_code: "TKR",
    coordinates: "88.40360260009766, 26.016399383544922",
  },
  {
    ident: "VGSH",
    type: "small_airport",
    name: "Shamshernagar Airport",
    elevation_ft: "56",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-6",
    municipality: "Shamshernagar",
    gps_code: "VGSH",
    iata_code: "ZHM",
    coordinates: "91.8830032349, 24.416999816900002",
  },
  {
    ident: "VGSY",
    type: "medium_airport",
    name: "Osmany International Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-6",
    municipality: "Sylhet",
    gps_code: "VGSY",
    iata_code: "ZYL",
    coordinates: "91.8667984008789, 24.963199615478516",
  },
  {
    ident: "VGZR",
    type: "large_airport",
    name: "Hazrat Shahjalal International Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "BD",
    iso_region: "BD-3",
    municipality: "Dhaka",
    gps_code: "VGHS",
    iata_code: "DAC",
    coordinates: "90.397783, 23.843347",
  },
  {
    ident: "VHHH",
    type: "large_airport",
    name: "Hong Kong International Airport",
    elevation_ft: "28",
    continent: "AS",
    iso_country: "HK",
    iso_region: "HK-U-A",
    municipality: "Hong Kong",
    gps_code: "VHHH",
    iata_code: "HKG",
    coordinates: "113.915001, 22.308901",
  },
  {
    ident: "VHHX",
    type: "closed",
    name: "Hong Kong International Airport Kai Tak",
    elevation_ft: "28",
    continent: "AS",
    iso_country: "HK",
    iso_region: "HK-U-A",
    municipality: "Hong Kong",
    gps_code: "VHHX",
    iata_code: "HKG",
    coordinates: "114.198074341, 22.3203040432",
  },
  {
    ident: "VHST",
    type: "heliport",
    name: "Shun Tak Heliport",
    elevation_ft: "107",
    continent: "AS",
    iso_country: "HK",
    iso_region: "HK-U-A",
    municipality: "Hong Kong",
    gps_code: "VHST",
    iata_code: "HHP",
    coordinates: "114.152153015, 22.2893714905",
  },
  {
    ident: "VI22",
    type: "seaplane_base",
    name: "Charlotte Amalie Harbor Seaplane Base",
    continent: "NA",
    iso_country: "VI",
    iso_region: "VI-U-A",
    municipality: "Charlotte Amalie St Thomas",
    gps_code: "VI22",
    iata_code: "SPB",
    local_code: "VI22",
    coordinates: "-64.9406967163086, 18.338600158691406",
  },
  {
    ident: "VI32",
    type: "seaplane_base",
    name: "Christiansted Harbor Seaplane Base",
    continent: "NA",
    iso_country: "VI",
    iso_region: "VI-U-A",
    municipality: "Christiansted St Croix",
    gps_code: "VI32",
    iata_code: "SSB",
    local_code: "VI32",
    coordinates: "-64.70490264892578, 17.74720001220703",
  },
  {
    ident: "VIAG",
    type: "medium_airport",
    name: "Agra Airport",
    elevation_ft: "551",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-UP",
    gps_code: "VIAG",
    iata_code: "AGR",
    coordinates: "77.96089935302734, 27.155799865722656",
  },
  {
    ident: "VIAL",
    type: "medium_airport",
    name: "Allahabad Airport",
    elevation_ft: "322",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-UP",
    municipality: "Allahabad",
    gps_code: "VIAL",
    iata_code: "IXD",
    coordinates: "81.733902, 25.4401",
  },
  {
    ident: "VIAR",
    type: "large_airport",
    name: "Sri Guru Ram Dass Jee International Airport",
    elevation_ft: "756",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-PB",
    municipality: "Amritsar",
    gps_code: "VIAR",
    iata_code: "ATQ",
    coordinates: "74.7973022461, 31.7096004486",
  },
  {
    ident: "VIAX",
    type: "small_airport",
    name: "Adampur Airport",
    elevation_ft: "775",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-PB",
    municipality: "Adampur",
    gps_code: "VIAX",
    iata_code: "AIP",
    coordinates: "75.758797, 31.4338",
  },
  {
    ident: "VIB",
    type: "closed",
    name: "Villa Constituci����n Airport",
    elevation_ft: "175",
    continent: "NA",
    iso_country: "MX",
    iso_region: "MX-BCS",
    municipality: "Ciudad Constituci����n",
    iata_code: "VIB",
    coordinates: "-111.6874, 25.0552",
  },
  {
    ident: "VIBK",
    type: "medium_airport",
    name: "Nal Airport",
    elevation_ft: "750",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-RJ",
    municipality: "Bikaner",
    gps_code: "VIBK",
    iata_code: "BKB",
    coordinates: "73.20719909667969, 28.070600509643555",
  },
  {
    ident: "VIBN",
    type: "medium_airport",
    name: "Lal Bahadur Shastri Airport",
    elevation_ft: "266",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-UP",
    municipality: "Varanasi",
    gps_code: "VEBN",
    iata_code: "VNS",
    coordinates: "82.859299, 25.4524",
  },
  {
    ident: "VIBR",
    type: "medium_airport",
    name: "Kullu Manali Airport",
    elevation_ft: "3573",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-HP",
    gps_code: "VIBR",
    iata_code: "KUU",
    coordinates: "77.15440368652344, 31.876699447631836",
  },
  {
    ident: "VIBT",
    type: "medium_airport",
    name: "Bhatinda Air Force Station",
    elevation_ft: "662",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-PB",
    gps_code: "VIBT",
    iata_code: "BUP",
    coordinates: "74.75579833984375, 30.270099639892578",
  },
  {
    ident: "VIBY",
    type: "medium_airport",
    name: "Bareilly Air Force Station",
    elevation_ft: "580",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-UP",
    municipality: "Bareilly",
    gps_code: "VIBY",
    iata_code: "BEK",
    coordinates: "79.45079803470001, 28.4221000671",
  },
  {
    ident: "VICG",
    type: "medium_airport",
    name: "Chandigarh Airport",
    elevation_ft: "1012",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-CH",
    municipality: "Chandigarh",
    gps_code: "VICG",
    iata_code: "IXC",
    coordinates: "76.78849792480469, 30.673500061035156",
  },
  {
    ident: "VICX",
    type: "medium_airport",
    name: "Kanpur Airport",
    elevation_ft: "410",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-UP",
    municipality: "Kanpur",
    gps_code: "VICX",
    iata_code: "KNU",
    coordinates: "80.410103, 26.404301",
  },
  {
    ident: "VIDN",
    type: "medium_airport",
    name: "Dehradun Airport",
    elevation_ft: "1831",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-UL",
    municipality: "Dehradun",
    gps_code: "VIDN",
    iata_code: "DED",
    coordinates: "78.180298, 30.189699",
  },
  {
    ident: "VIDP",
    type: "large_airport",
    name: "Indira Gandhi International Airport",
    elevation_ft: "777",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-DL",
    municipality: "New Delhi",
    gps_code: "VIDP",
    iata_code: "DEL",
    coordinates: "77.103104, 28.5665",
  },
  {
    ident: "VIF",
    type: "heliport",
    name: "Vieste Airport",
    elevation_ft: "14",
    continent: "EU",
    iso_country: "IT",
    iso_region: "IT-75",
    municipality: "Vieste",
    iata_code: "VIF",
    coordinates: "16.166667, 41.883317",
  },
  {
    ident: "VIGG",
    type: "medium_airport",
    name: "Kangra Airport",
    elevation_ft: "2525",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-HP",
    gps_code: "VIGG",
    iata_code: "DHM",
    coordinates: "76.26339721679688, 32.16510009765625",
  },
  {
    ident: "VIGR",
    type: "medium_airport",
    name: "Gwalior Airport",
    elevation_ft: "617",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MP",
    municipality: "Gwalior",
    gps_code: "VIGR",
    iata_code: "GWL",
    coordinates: "78.22779846191406, 26.29330062866211",
  },
  {
    ident: "VIHR",
    type: "medium_airport",
    name: "Hissar Airport",
    elevation_ft: "700",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-HR",
    gps_code: "VIHR",
    iata_code: "HSS",
    coordinates: "75.75530242919922, 29.179399490356445",
  },
  {
    ident: "VIJO",
    type: "medium_airport",
    name: "Jodhpur Airport",
    elevation_ft: "717",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-RJ",
    municipality: "Jodhpur",
    gps_code: "VIJO",
    iata_code: "JDH",
    coordinates: "73.04889678955078, 26.251100540161133",
  },
  {
    ident: "VIJP",
    type: "medium_airport",
    name: "Jaipur International Airport",
    elevation_ft: "1263",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-RJ",
    municipality: "Jaipur",
    gps_code: "VIJP",
    iata_code: "JAI",
    coordinates: "75.812202, 26.8242",
  },
  {
    ident: "VIJR",
    type: "medium_airport",
    name: "Jaisalmer Airport",
    elevation_ft: "751",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-RJ",
    gps_code: "VIJR",
    iata_code: "JSA",
    coordinates: "70.86499786376953, 26.888700485229492",
  },
  {
    ident: "VIJU",
    type: "medium_airport",
    name: "Jammu Airport",
    elevation_ft: "996",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-JK",
    municipality: "Jammu",
    gps_code: "VIJU",
    iata_code: "IXJ",
    coordinates: "74.837402, 32.689098",
  },
  {
    ident: "VIKG",
    type: "medium_airport",
    name: "Kishangarh Airport",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-RJ",
    municipality: "Ajmer",
    gps_code: "VIKG",
    iata_code: "KQH",
    coordinates: "74.814147, 26.601473",
  },
  {
    ident: "VIKO",
    type: "medium_airport",
    name: "Kota Airport",
    elevation_ft: "896",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-RJ",
    municipality: "Kota",
    gps_code: "VIKO",
    iata_code: "KTU",
    coordinates: "75.84559631350001, 25.160200119",
  },
  {
    ident: "VILD",
    type: "medium_airport",
    name: "Ludhiana Airport",
    elevation_ft: "834",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-PB",
    gps_code: "VILD",
    iata_code: "LUH",
    coordinates: "75.95259857177734, 30.854700088500977",
  },
  {
    ident: "VILH",
    type: "medium_airport",
    name: "Leh Kushok Bakula Rimpochee Airport",
    elevation_ft: "10682",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-JK",
    municipality: "Leh",
    gps_code: "VILH",
    iata_code: "IXL",
    coordinates: "77.5465011597, 34.1358985901",
  },
  {
    ident: "VILK",
    type: "medium_airport",
    name: "Chaudhary Charan Singh International Airport",
    elevation_ft: "410",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-UP",
    municipality: "Lucknow",
    gps_code: "VILK",
    iata_code: "LKO",
    coordinates: "80.8892974854, 26.7605991364",
  },
  {
    ident: "VIPK",
    type: "medium_airport",
    name: "Pathankot Airport",
    elevation_ft: "1017",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-PB",
    municipality: "Pathankot",
    gps_code: "VIPK",
    iata_code: "IXP",
    coordinates: "75.634444, 32.233611",
  },
  {
    ident: "VIPT",
    type: "medium_airport",
    name: "Pantnagar Airport",
    elevation_ft: "769",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-UP",
    municipality: "Pantnagar",
    gps_code: "VIPT",
    iata_code: "PGH",
    coordinates: "79.473701, 29.0334",
  },
  {
    ident: "VISM",
    type: "small_airport",
    name: "Shimla Airport",
    elevation_ft: "5072",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-HP",
    gps_code: "VISM",
    iata_code: "SLV",
    coordinates: "77.068001, 31.0818",
  },
  {
    ident: "VISR",
    type: "medium_airport",
    name: "Sheikh ul Alam Airport",
    elevation_ft: "5429",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-JK",
    municipality: "Srinagar",
    gps_code: "VISR",
    iata_code: "SXR",
    coordinates: "74.77420043945312, 33.987098693847656",
  },
  {
    ident: "VIST",
    type: "small_airport",
    name: "Satna Airport",
    elevation_ft: "1060",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MP",
    gps_code: "VIST",
    iata_code: "TNI",
    coordinates: "80.854897, 24.5623",
  },
  {
    ident: "VIV",
    type: "small_airport",
    name: "Vivigani Airfield",
    elevation_ft: "117",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Vivigani",
    iata_code: "VIV",
    local_code: "VIV",
    coordinates: "150.318888889, -9.30333333333",
  },
  {
    ident: "VJQ",
    type: "small_airport",
    name: "Gurue Airport",
    elevation_ft: "2248",
    continent: "AF",
    iso_country: "MZ",
    iso_region: "MZ-Q",
    municipality: "Gurue",
    iata_code: "VJQ",
    coordinates: "36.9904, -15.5062",
  },
  {
    ident: "VLAP",
    type: "small_airport",
    name: "Attopeu Airport",
    elevation_ft: "344",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-AT",
    municipality: "Attopeu",
    gps_code: "VLAP",
    iata_code: "AOU",
    coordinates: "107.045278, 14.793056",
  },
  {
    ident: "VLHS",
    type: "small_airport",
    name: "Ban Huoeisay Airport",
    elevation_ft: "1380",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-BK",
    municipality: "Huay Xai",
    gps_code: "VLHS",
    iata_code: "HOE",
    coordinates: "100.43699646, 20.2572994232",
  },
  {
    ident: "VLLB",
    type: "medium_airport",
    name: "Luang Phabang International Airport",
    elevation_ft: "955",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-LP",
    municipality: "Luang Phabang",
    gps_code: "VLLB",
    iata_code: "LPQ",
    coordinates: "102.16100311279297, 19.897300720214844",
  },
  {
    ident: "VLLN",
    type: "small_airport",
    name: "Luang Namtha Airport",
    elevation_ft: "1968",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-LM",
    municipality: "Luang Namtha",
    gps_code: "VLLN",
    iata_code: "LXG",
    coordinates: "101.400002, 20.966999",
  },
  {
    ident: "VLOS",
    type: "small_airport",
    name: "Oudomsay Airport",
    elevation_ft: "1804",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-OU",
    municipality: "Oudomsay",
    gps_code: "VLOS",
    iata_code: "ODY",
    coordinates: "101.99400329589844, 20.68269920349121",
  },
  {
    ident: "VLPS",
    type: "medium_airport",
    name: "Pakse International Airport",
    elevation_ft: "351",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-CH",
    municipality: "Pakse",
    gps_code: "VLPS",
    iata_code: "PKZ",
    coordinates: "105.78099822998047, 15.132100105285645",
  },
  {
    ident: "VLSB",
    type: "small_airport",
    name: "Sayaboury Airport",
    elevation_ft: "962",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-XA",
    municipality: "Sainyabuli",
    gps_code: "VLSB",
    iata_code: "ZBY",
    coordinates: "101.7093, 19.2436",
  },
  {
    ident: "VLSK",
    type: "medium_airport",
    name: "Savannakhet Airport",
    elevation_ft: "509",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-SV",
    gps_code: "VLSK",
    iata_code: "ZVK",
    coordinates: "104.76000213623047, 16.55660057067871",
  },
  {
    ident: "VLSN",
    type: "medium_airport",
    name: "Sam Neua Airport",
    elevation_ft: "3281",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-HO",
    gps_code: "VLSN",
    iata_code: "NEU",
    coordinates: "104.06700134277344, 20.418399810791016",
  },
  {
    ident: "VLSV",
    type: "small_airport",
    name: "Saravane Airport",
    elevation_ft: "574",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-SL",
    municipality: "Saravane",
    gps_code: "VLSV",
    iata_code: "VNA",
    coordinates: "106.410698891, 15.709439207700001",
  },
  {
    ident: "VLTK",
    type: "small_airport",
    name: "Thakhek Airport",
    elevation_ft: "449",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-KH",
    municipality: "Thakhek",
    gps_code: "VLTK",
    iata_code: "THK",
    coordinates: "104.80000305175781, 17.399999618530273",
  },
  {
    ident: "VLVT",
    type: "medium_airport",
    name: "Wattay International Airport",
    elevation_ft: "564",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-VT",
    municipality: "Vientiane",
    gps_code: "VLVT",
    iata_code: "VTE",
    coordinates: "102.56300354, 17.988300323500003",
  },
  {
    ident: "VLXK",
    type: "small_airport",
    name: "Xieng Khouang Airport",
    elevation_ft: "3445",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-XI",
    municipality: "Xieng Khouang",
    gps_code: "VLXK",
    iata_code: "XKH",
    coordinates: "103.157997, 19.450001",
  },
  {
    ident: "VLXL",
    type: "small_airport",
    name: "Xienglom Airport",
    elevation_ft: "1830",
    continent: "AS",
    iso_country: "LA",
    iso_region: "LA-BK",
    municipality: "Xienglom",
    gps_code: "VLXL",
    iata_code: "XIE",
    coordinates: "100.815332, 19.6203",
  },
  {
    ident: "VMI",
    type: "small_airport",
    name: "Dr Juan Plate Airport",
    elevation_ft: "280",
    continent: "SA",
    iso_country: "PY",
    iso_region: "PY-1",
    municipality: "Puerto Vallemi",
    iata_code: "VMI",
    coordinates: "-57.942581, -22.159109",
  },
  {
    ident: "VMMC",
    type: "large_airport",
    name: "Macau International Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "MO",
    iso_region: "MO-U-A",
    municipality: "Macau",
    gps_code: "VMMC",
    iata_code: "MFM",
    coordinates: "113.592003, 22.149599",
  },
  {
    ident: "VN-0001",
    type: "medium_airport",
    name: "Dong Hoi Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-24",
    municipality: "Dong Hoi",
    gps_code: "VVDH",
    iata_code: "VDH",
    coordinates: "106.590556, 17.515",
  },
  {
    ident: "VN-KON",
    type: "medium_airport",
    name: "Kontum Airport",
    elevation_ft: "1804",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-28",
    municipality: "Kontum",
    iata_code: "KON",
    coordinates: "108.01699829101562, 14.350000381469727",
  },
  {
    ident: "VNBG",
    type: "small_airport",
    name: "Bajhang Airport",
    elevation_ft: "4100",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SE",
    municipality: "Bajhang",
    gps_code: "VNBG",
    iata_code: "BJH",
    coordinates: "81.1854019165039, 29.538999557495117",
  },
  {
    ident: "VNBJ",
    type: "small_airport",
    name: "Bhojpur Airport",
    elevation_ft: "4000",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-KO",
    municipality: "Bhojpur",
    gps_code: "VNBJ",
    iata_code: "BHP",
    coordinates: "87.05079650878906, 27.14739990234375",
  },
  {
    ident: "VNBL",
    type: "small_airport",
    name: "Baglung Airport",
    elevation_ft: "3320",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-DH",
    municipality: "Baglung",
    gps_code: "VNBL",
    iata_code: "BGL",
    coordinates: "83.66629791259766, 28.212799072265625",
  },
  {
    ident: "VNBP",
    type: "small_airport",
    name: "Bharatpur Airport",
    elevation_ft: "600",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-NA",
    municipality: "Bharatpur",
    gps_code: "VNBP",
    iata_code: "BHR",
    coordinates: "84.429398, 27.678101",
  },
  {
    ident: "VNBR",
    type: "small_airport",
    name: "Bajura Airport",
    elevation_ft: "4300",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SE",
    municipality: "Bajura",
    gps_code: "VNBR",
    iata_code: "BJU",
    coordinates: "81.66899871826172, 29.50200080871582",
  },
  {
    ident: "VNBT",
    type: "small_airport",
    name: "Baitadi Airport",
    elevation_ft: "4200",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-MA",
    municipality: "Baitadi",
    gps_code: "VNBT",
    iata_code: "BIT",
    coordinates: "80.54920196533203, 29.465299606323242",
  },
  {
    ident: "VNBW",
    type: "medium_airport",
    name: "Gautam Buddha Airport",
    elevation_ft: "358",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-LU",
    municipality: "Bhairawa",
    gps_code: "VNBW",
    iata_code: "BWA",
    coordinates: "83.416293, 27.505685",
  },
  {
    ident: "VNCG",
    type: "small_airport",
    name: "Bhadrapur Airport",
    elevation_ft: "300",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-ME",
    municipality: "Bhadrapur",
    gps_code: "VNCG",
    iata_code: "BDP",
    coordinates: "88.07959747310001, 26.5708007812",
  },
  {
    ident: "VNDG",
    type: "small_airport",
    name: "Tulsipur Airport",
    elevation_ft: "2100",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-RA",
    municipality: "Dang",
    gps_code: "VNDG",
    iata_code: "DNP",
    coordinates: "82.29419708251953, 28.111099243164062",
  },
  {
    ident: "VNDH",
    type: "small_airport",
    name: "Dhangarhi Airport",
    elevation_ft: "690",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-BH",
    municipality: "Dhangarhi",
    gps_code: "VNDH",
    iata_code: "DHI",
    coordinates: "80.58190155029297, 28.753299713134766",
  },
  {
    ident: "VNDL",
    type: "small_airport",
    name: "Darchula Airport",
    elevation_ft: "2132",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-MA",
    municipality: "Darchula",
    gps_code: "VNDL",
    iata_code: "DAP",
    coordinates: "80.54840087890625, 29.669200897216797",
  },
  {
    ident: "VNDP",
    type: "small_airport",
    name: "Dolpa Airport",
    elevation_ft: "8200",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-KA",
    municipality: "Dolpa",
    gps_code: "VNDP",
    iata_code: "DOP",
    coordinates: "82.81909942626953, 28.985700607299805",
  },
  {
    ident: "VNDT",
    type: "small_airport",
    name: "Silgadi Doti Airport",
    elevation_ft: "2100",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SE",
    municipality: "Silgadi Doti",
    gps_code: "VNDT",
    iata_code: "SIH",
    coordinates: "80.93599700927734, 29.263099670410156",
  },
  {
    ident: "VNGK",
    type: "small_airport",
    name: "Palungtar Airport",
    elevation_ft: "1500",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-GA",
    municipality: "Gorkha",
    gps_code: "VNGK",
    iata_code: "GKH",
    coordinates: "84.4664, 28.0385",
  },
  {
    ident: "VNJI",
    type: "small_airport",
    name: "Jiri Airport",
    elevation_ft: "6000",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-JA",
    municipality: "Jiri",
    gps_code: "VNJI",
    iata_code: "JIR",
    coordinates: "86.230581, 27.626313",
  },
  {
    ident: "VNJL",
    type: "small_airport",
    name: "Jumla Airport",
    elevation_ft: "7700",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-KA",
    municipality: "Jumla",
    gps_code: "VNJL",
    iata_code: "JUM",
    coordinates: "82.193298, 29.2742",
  },
  {
    ident: "VNJP",
    type: "medium_airport",
    name: "Janakpur Airport",
    elevation_ft: "256",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-JA",
    municipality: "Janakpur",
    gps_code: "VNJP",
    iata_code: "JKR",
    coordinates: "85.9224014282, 26.708799362199997",
  },
  {
    ident: "VNJS",
    type: "small_airport",
    name: "Jomsom Airport",
    elevation_ft: "8976",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-DH",
    municipality: "Jomsom",
    gps_code: "VNJS",
    iata_code: "JMO",
    coordinates: "83.723, 28.780426",
  },
  {
    ident: "VNKT",
    type: "large_airport",
    name: "Tribhuvan International Airport",
    elevation_ft: "4390",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-BA",
    municipality: "Kathmandu",
    gps_code: "VNKT",
    iata_code: "KTM",
    coordinates: "85.3591, 27.6966",
  },
  {
    ident: "VNLD",
    type: "small_airport",
    name: "Lamidanda Airport",
    elevation_ft: "4100",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SA",
    municipality: "Lamidanda",
    gps_code: "VNLD",
    iata_code: "LDN",
    coordinates: "86.66999816894531, 27.25309944152832",
  },
  {
    ident: "VNLK",
    type: "small_airport",
    name: "Lukla Airport",
    elevation_ft: "9380",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SA",
    municipality: "Lukla",
    gps_code: "VNLK",
    iata_code: "LUA",
    coordinates: "86.72969818115234, 27.686899185180664",
  },
  {
    ident: "VNLT",
    type: "small_airport",
    name: "Langtang Airport",
    elevation_ft: "12000",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-BA",
    municipality: "Langtang",
    gps_code: "VNLT",
    iata_code: "LTG",
    coordinates: "85.58300018310547, 28.200000762939453",
  },
  {
    ident: "VNMA",
    type: "small_airport",
    name: "Manang Airport",
    elevation_ft: "11001",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-GA",
    municipality: "Ngawal",
    gps_code: "VNMA",
    iata_code: "NGX",
    coordinates: "84.089203, 28.641399",
  },
  {
    ident: "VNMG",
    type: "small_airport",
    name: "Meghauli Airport",
    elevation_ft: "600",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-NA",
    municipality: "Meghauli",
    gps_code: "VNMG",
    iata_code: "MEY",
    coordinates: "84.22875, 27.5774",
  },
  {
    ident: "VNMN",
    type: "small_airport",
    name: "Mahendranagar Airport",
    elevation_ft: "650",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-MA",
    municipality: "Mahendranagar",
    gps_code: "VNMN",
    iata_code: "XMG",
    coordinates: "80.14790344238281, 28.963199615478516",
  },
  {
    ident: "VNNG",
    type: "medium_airport",
    name: "Nepalgunj Airport",
    elevation_ft: "540",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-BH",
    municipality: "Nepalgunj",
    gps_code: "VNNG",
    iata_code: "KEP",
    coordinates: "81.66699981689453, 28.103599548339844",
  },
  {
    ident: "VNPK",
    type: "medium_airport",
    name: "Pokhara Airport",
    elevation_ft: "2712",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-GA",
    municipality: "Pokhara",
    gps_code: "VNPK",
    iata_code: "PKR",
    coordinates: "83.98210144042969, 28.200899124145508",
  },
  {
    ident: "VNPL",
    type: "small_airport",
    name: "Phaplu Airport",
    elevation_ft: "7918",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SA",
    municipality: "Phaplu",
    gps_code: "VNPL",
    iata_code: "PPL",
    coordinates: "86.584454, 27.517787",
  },
  {
    ident: "VNRB",
    type: "small_airport",
    name: "Rajbiraj Airport",
    elevation_ft: "250",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SA",
    municipality: "Rajbiraj",
    gps_code: "VNRB",
    iata_code: "RJB",
    coordinates: "86.733902, 26.510066",
  },
  {
    ident: "VNRC",
    type: "small_airport",
    name: "Ramechhap Airport",
    elevation_ft: "1555",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-JA",
    municipality: "Ramechhap",
    gps_code: "VNRC",
    iata_code: "RHP",
    coordinates: "86.0614013671875, 27.393999099731445",
  },
  {
    ident: "VNRK",
    type: "small_airport",
    name: "Rukum Chaurjahari Airport",
    elevation_ft: "2500",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-RA",
    municipality: "Rukumkot",
    gps_code: "VNRK",
    iata_code: "RUK",
    coordinates: "82.195, 28.627001",
  },
  {
    ident: "VNRP",
    type: "small_airport",
    name: "Rolpa Airport",
    elevation_ft: "4100",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-RA",
    municipality: "Rolpa",
    gps_code: "VNRP",
    iata_code: "RPA",
    coordinates: "82.756496, 28.267292",
  },
  {
    ident: "VNRT",
    type: "small_airport",
    name: "Rumjatar Airport",
    elevation_ft: "4500",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SA",
    municipality: "Rumjatar",
    gps_code: "VNRT",
    iata_code: "RUM",
    coordinates: "86.55039978027344, 27.303499221801758",
  },
  {
    ident: "VNSB",
    type: "small_airport",
    name: "Syangboche Airport",
    elevation_ft: "12400",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SA",
    municipality: "Namche Bazaar",
    gps_code: "VNSB",
    iata_code: "SYH",
    coordinates: "86.7124, 27.8112",
  },
  {
    ident: "VNSI",
    type: "small_airport",
    name: "Simara Airport",
    elevation_ft: "450",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-NA",
    municipality: "Simara",
    gps_code: "VNSI",
    iata_code: "SIF",
    coordinates: "84.9801025390625, 27.159500122070312",
  },
  {
    ident: "VNSK",
    type: "small_airport",
    name: "Surkhet Airport",
    elevation_ft: "2400",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-BH",
    municipality: "Surkhet",
    gps_code: "VNSK",
    iata_code: "SKH",
    coordinates: "81.636002, 28.586",
  },
  {
    ident: "VNSR",
    type: "small_airport",
    name: "Sanfebagar Airport",
    elevation_ft: "2280",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SE",
    municipality: "Sanfebagar",
    gps_code: "VNSR",
    iata_code: "FEB",
    coordinates: "81.215317, 29.236629",
  },
  {
    ident: "VNST",
    type: "small_airport",
    name: "Simikot Airport",
    elevation_ft: "9246",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-KA",
    municipality: "Simikot",
    gps_code: "VNST",
    iata_code: "IMK",
    coordinates: "81.81890106201172, 29.971099853515625",
  },
  {
    ident: "VNTJ",
    type: "medium_airport",
    name: "Taplejung Airport",
    elevation_ft: "7990",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-ME",
    municipality: "Taplejung",
    gps_code: "VNTJ",
    iata_code: "TPJ",
    coordinates: "87.69525, 27.3509",
  },
  {
    ident: "VNTP",
    type: "small_airport",
    name: "Tikapur Airport",
    elevation_ft: "522",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-SE",
    municipality: "Tikapur",
    gps_code: "VNTP",
    iata_code: "TPU",
    coordinates: "81.123, 28.5219",
  },
  {
    ident: "VNTR",
    type: "small_airport",
    name: "Tumling Tar Airport",
    elevation_ft: "1700",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-KO",
    municipality: "Tumling Tar",
    gps_code: "VNTR",
    iata_code: "TMI",
    coordinates: "87.193298, 27.315001",
  },
  {
    ident: "VNVT",
    type: "medium_airport",
    name: "Biratnagar Airport",
    elevation_ft: "236",
    continent: "AS",
    iso_country: "NP",
    iso_region: "NP-KO",
    municipality: "Biratnagar",
    gps_code: "VNVT",
    iata_code: "BIR",
    coordinates: "87.26399993896484, 26.48150062561035",
  },
  {
    ident: "VO55",
    type: "medium_airport",
    name: "Murod Kond Airport",
    elevation_ft: "2136",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-MM",
    municipality: "Latur",
    gps_code: "VALT",
    iata_code: "LTU",
    coordinates: "76.464699, 18.411501",
  },
  {
    ident: "VO80",
    type: "small_airport",
    name: "Tuticorin Airport",
    elevation_ft: "129",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TN",
    municipality: "Thoothukudi",
    gps_code: "VOTK",
    iata_code: "TCR",
    coordinates: "78.025803, 8.724241",
  },
  {
    ident: "VOAT",
    type: "medium_airport",
    name: "Agatti Airport",
    elevation_ft: "14",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-LD",
    gps_code: "VOAT",
    iata_code: "AGX",
    coordinates: "72.1760025024414, 10.823699951171875",
  },
  {
    ident: "VOBI",
    type: "medium_airport",
    name: "Bellary Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KA",
    municipality: "Bellary",
    gps_code: "VOBI",
    iata_code: "BEP",
    coordinates: "76.88279724121094, 15.162799835205078",
  },
  {
    ident: "VOBL",
    type: "large_airport",
    name: "Kempegowda International Airport",
    elevation_ft: "3000",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KA",
    municipality: "Bangalore",
    gps_code: "VOBL",
    iata_code: "BLR",
    coordinates: "77.706299, 13.1979",
  },
  {
    ident: "VOBR",
    type: "medium_airport",
    name: "Bidar Air Force Station",
    elevation_ft: "2178",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KA",
    gps_code: "VOBR",
    iata_code: "IXX",
    coordinates: "77.487099, 17.9081",
  },
  {
    ident: "VOBZ",
    type: "medium_airport",
    name: "Vijayawada Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AP",
    municipality: "Gannavaram",
    gps_code: "VOBZ",
    iata_code: "VGA",
    coordinates: "80.796799, 16.530399",
  },
  {
    ident: "VOCB",
    type: "medium_airport",
    name: "Coimbatore International Airport",
    elevation_ft: "1324",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TN",
    municipality: "Coimbatore",
    gps_code: "VOCB",
    iata_code: "CJB",
    coordinates: "77.0434036255, 11.029999733",
  },
  {
    ident: "VOCI",
    type: "large_airport",
    name: "Cochin International Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KL",
    municipality: "Kochi",
    gps_code: "VOCI",
    iata_code: "COK",
    coordinates: "76.401901, 10.152",
  },
  {
    ident: "VOCL",
    type: "large_airport",
    name: "Calicut International Airport",
    elevation_ft: "342",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KL",
    municipality: "Calicut",
    gps_code: "VOCL",
    iata_code: "CCJ",
    coordinates: "75.95529937740001, 11.1367998123",
  },
  {
    ident: "VOCP",
    type: "medium_airport",
    name: "Kadapa Airport",
    elevation_ft: "430",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AP",
    municipality: "Kadapa",
    gps_code: "VOCP",
    iata_code: "CDP",
    coordinates: "78.772778, 14.51",
  },
  {
    ident: "VOCX",
    type: "medium_airport",
    name: "Car Nicobar Air Force Station",
    elevation_ft: "5",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AN",
    gps_code: "VOCX",
    iata_code: "CBD",
    coordinates: "92.8196029663086, 9.152509689331055",
  },
  {
    ident: "VOHS",
    type: "large_airport",
    name: "Rajiv Gandhi International Airport",
    elevation_ft: "2024",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TG",
    municipality: "Hyderabad",
    gps_code: "VOHS",
    iata_code: "HYD",
    coordinates: "78.429855, 17.231318",
  },
  {
    ident: "VOHY",
    type: "medium_airport",
    name: "Begumpet Airport",
    elevation_ft: "1742",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TG",
    municipality: "Hyderabad",
    gps_code: "VOHY",
    iata_code: "BPM",
    coordinates: "78.4675979614, 17.4531002045",
  },
  {
    ident: "VOKN",
    type: "medium_airport",
    name: "Kannur International Airport",
    elevation_ft: "330",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KL",
    municipality: "Kannur",
    gps_code: "VOKN",
    iata_code: "CNN",
    coordinates: "75.547211, 11.918614",
  },
  {
    ident: "VOMD",
    type: "medium_airport",
    name: "Madurai Airport",
    elevation_ft: "459",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TN",
    municipality: "Madurai",
    gps_code: "VOMD",
    iata_code: "IXM",
    coordinates: "78.09339904790001, 9.83450984955",
  },
  {
    ident: "VOML",
    type: "medium_airport",
    name: "Mangalore International Airport",
    elevation_ft: "337",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KA",
    municipality: "Mangalore",
    gps_code: "VOML",
    iata_code: "IXE",
    coordinates: "74.8900985718, 12.9612998962",
  },
  {
    ident: "VOMM",
    type: "large_airport",
    name: "Chennai International Airport",
    elevation_ft: "52",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TN",
    municipality: "Chennai",
    gps_code: "VOMM",
    iata_code: "MAA",
    coordinates: "80.16929626464844, 12.990005493164062",
  },
  {
    ident: "VOMY",
    type: "small_airport",
    name: "Mysore Airport",
    elevation_ft: "2349",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KA",
    municipality: "Mysore",
    gps_code: "VOMY",
    iata_code: "MYQ",
    coordinates: "76.655833, 12.23",
  },
  {
    ident: "VOPB",
    type: "medium_airport",
    name: "Vir Savarkar International Airport",
    elevation_ft: "14",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AN",
    municipality: "Port Blair",
    gps_code: "VOPB",
    iata_code: "IXZ",
    coordinates: "92.72969818115234, 11.641200065612793",
  },
  {
    ident: "VOPC",
    type: "medium_airport",
    name: "Pondicherry Airport",
    elevation_ft: "134",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TN",
    municipality: "Pondicherry",
    gps_code: "VOPC",
    iata_code: "PNY",
    coordinates: "79.812, 11.968",
  },
  {
    ident: "VOPN",
    type: "medium_airport",
    name: "Sri Sathya Sai Airport",
    elevation_ft: "1558",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AP",
    municipality: "Puttaparthi",
    gps_code: "VOPN",
    iata_code: "PUT",
    coordinates: "77.7910995483, 14.1492996216",
  },
  {
    ident: "VORG",
    type: "small_airport",
    name: "Basanth Nagar Airport",
    elevation_ft: "670",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TG",
    municipality: "Ramagundam",
    gps_code: "VORG",
    iata_code: "RMD",
    coordinates: "79.3923, 18.701",
  },
  {
    ident: "VORY",
    type: "medium_airport",
    name: "Rajahmundry Airport",
    elevation_ft: "151",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AP",
    municipality: "Rajahmundry",
    gps_code: "VORY",
    iata_code: "RJA",
    coordinates: "81.81819915770001, 17.1103992462",
  },
  {
    ident: "VOSM",
    type: "medium_airport",
    name: "Salem Airport",
    elevation_ft: "1008",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TN",
    municipality: "Salem",
    gps_code: "VOSM",
    iata_code: "SXV",
    coordinates: "78.06559753418, 11.78330039978",
  },
  {
    ident: "VOTJ",
    type: "small_airport",
    name: "Tanjore Air Force Base",
    elevation_ft: "253",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TN",
    municipality: "Thanjavur",
    gps_code: "VOTJ",
    iata_code: "TJV",
    coordinates: "79.10160064697266, 10.722399711608887",
  },
  {
    ident: "VOTP",
    type: "small_airport",
    name: "Tirupati Airport",
    elevation_ft: "350",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-AP",
    municipality: "Tirupati",
    gps_code: "VOTP",
    iata_code: "TIR",
    coordinates: "79.543296814, 13.632499694800002",
  },
  {
    ident: "VOTR",
    type: "medium_airport",
    name: "Tiruchirapally Civil Airport Airport",
    elevation_ft: "288",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TN",
    municipality: "Tiruchirappally",
    gps_code: "VOTR",
    iata_code: "TRZ",
    coordinates: "78.70970153808594, 10.765399932861328",
  },
  {
    ident: "VOTV",
    type: "large_airport",
    name: "Trivandrum International Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-KL",
    municipality: "Thiruvananthapuram",
    gps_code: "VOTV",
    iata_code: "TRV",
    coordinates: "76.9200973511, 8.48211956024",
  },
  {
    ident: "VOWA",
    type: "small_airport",
    name: "Warangal Airport",
    elevation_ft: "935",
    continent: "AS",
    iso_country: "IN",
    iso_region: "IN-TG",
    municipality: "Warangal",
    gps_code: "VOWA",
    iata_code: "WGC",
    coordinates: "79.602203, 17.9144",
  },
  {
    ident: "VQ10",
    type: "small_airport",
    name: "Yongphulla Airport",
    elevation_ft: "9000",
    continent: "AS",
    iso_country: "BT",
    iso_region: "BT-41",
    municipality: "Tashigang",
    gps_code: "VQTY",
    iata_code: "YON",
    coordinates: "91.514503, 27.256399",
  },
  {
    ident: "VQBT",
    type: "small_airport",
    name: "Bathpalathang Airport",
    elevation_ft: "8485",
    continent: "AS",
    iso_country: "BT",
    iso_region: "BT-33",
    municipality: "Jakar",
    gps_code: "VQBT",
    iata_code: "BUT",
    coordinates: "90.7471, 27.5622",
  },
  {
    ident: "VQGP",
    type: "medium_airport",
    name: "Gelephu Airport",
    elevation_ft: "980",
    continent: "AS",
    iso_country: "BT",
    iso_region: "BT-31",
    municipality: "Gelephu",
    gps_code: "VQGP",
    iata_code: "GLU",
    coordinates: "90.46412, 26.88456",
  },
  {
    ident: "VQPR",
    type: "medium_airport",
    name: "Paro Airport",
    elevation_ft: "7332",
    continent: "AS",
    iso_country: "BT",
    iso_region: "BT-11",
    municipality: "Paro",
    gps_code: "VQPR",
    iata_code: "PBH",
    coordinates: "89.42459869380001, 27.403200149499998",
  },
  {
    ident: "VREI",
    type: "small_airport",
    name: "Ifuru Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-13",
    municipality: "Ifuru Island",
    gps_code: "VREI",
    iata_code: "IFU",
    coordinates: "73.025, 5.7083",
  },
  {
    ident: "VRMD",
    type: "small_airport",
    name: "Dharavandhoo Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-20",
    municipality: "Baa Atoll",
    gps_code: "VRMD",
    iata_code: "DRV",
    coordinates: "73.1302, 5.1561",
  },
  {
    ident: "VRMF",
    type: "small_airport",
    name: "Fuvahmulah Airport",
    elevation_ft: "17",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-29",
    municipality: "Fuvahmulah Island",
    gps_code: "VRMR",
    iata_code: "FVM",
    coordinates: "73.435, -0.309722222222",
  },
  {
    ident: "VRMG",
    type: "medium_airport",
    name: "Gan International Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-01",
    municipality: "Gan",
    gps_code: "VRMG",
    iata_code: "GAN",
    local_code: "MV",
    coordinates: "73.155602, -0.693342",
  },
  {
    ident: "VRMH",
    type: "medium_airport",
    name: "Hanimaadhoo Airport",
    elevation_ft: "4",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-23",
    municipality: "Haa Dhaalu Atoll",
    gps_code: "VRMH",
    iata_code: "HAQ",
    coordinates: "73.17050170898438, 6.744229793548584",
  },
  {
    ident: "VRMK",
    type: "medium_airport",
    name: "Kadhdhoo Airport",
    elevation_ft: "4",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-05",
    municipality: "Kadhdhoo",
    gps_code: "VRMK",
    iata_code: "KDO",
    coordinates: "73.52189636230469, 1.8591699600219727",
  },
  {
    ident: "VRMM",
    type: "large_airport",
    name: "Mal���� International Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-MLE",
    municipality: "Mal����",
    gps_code: "VRMM",
    iata_code: "MLE",
    coordinates: "73.52909851074219, 4.191830158233643",
  },
  {
    ident: "VRMO",
    type: "small_airport",
    name: "Kooddoo Airport",
    elevation_ft: "29",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-27",
    municipality: "Huvadhu Atoll",
    gps_code: "VRMO",
    iata_code: "GKK",
    coordinates: "73.4336, 0.7324",
  },
  {
    ident: "VRMT",
    type: "medium_airport",
    name: "Kaadedhdhoo Airport",
    elevation_ft: "2",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-28",
    municipality: "Huvadhu Atoll",
    gps_code: "VRMT",
    iata_code: "KDM",
    coordinates: "72.99690246582031, 0.48813098669052124",
  },
  {
    ident: "VRMU",
    type: "small_airport",
    name: "Dhaalu Atoll Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-17",
    municipality: "Kudahuvadhoo",
    gps_code: "VRMU",
    iata_code: "DDD",
    coordinates: "72.887118, 2.666075",
  },
  {
    ident: "VRMV",
    type: "medium_airport",
    name: "Villa Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-13",
    municipality: "Maamigili",
    gps_code: "VRMV",
    iata_code: "VAM",
    coordinates: "72.8358333333, 3.47055555556",
  },
  {
    ident: "VRNT",
    type: "small_airport",
    name: "Thimarafushi Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "MV",
    iso_region: "MV-08",
    municipality: "Thimarafushi",
    gps_code: "VRNT",
    iata_code: "TMF",
    coordinates: "73.1533, 2.211",
  },
  {
    ident: "VTBD",
    type: "large_airport",
    name: "Don Mueang International Airport",
    elevation_ft: "9",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-10",
    municipality: "Bangkok",
    gps_code: "VTBD",
    iata_code: "DMK",
    coordinates: "100.607002258, 13.9125995636",
  },
  {
    ident: "VTBH",
    type: "small_airport",
    name: "Sa Pran Nak Airport",
    elevation_ft: "95",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-16",
    gps_code: "VTBH",
    iata_code: "KKM",
    coordinates: "100.642997742, 14.9493999481",
  },
  {
    ident: "VTBK",
    type: "medium_airport",
    name: "Kamphaeng Saen Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-72",
    municipality: "Nakhon Pathom",
    gps_code: "VTBK",
    iata_code: "KDT",
    coordinates: "99.9171981812, 14.1020002365",
  },
  {
    ident: "VTBO",
    type: "medium_airport",
    name: "Trat Airport",
    elevation_ft: "105",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-23",
    gps_code: "VTBO",
    iata_code: "TDX",
    coordinates: "102.319000244, 12.274600029",
  },
  {
    ident: "VTBS",
    type: "large_airport",
    name: "Suvarnabhumi Airport",
    elevation_ft: "5",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-10",
    municipality: "Bangkok",
    gps_code: "VTBS",
    iata_code: "BKK",
    coordinates: "100.74700164794922, 13.681099891662598",
  },
  {
    ident: "VTBU",
    type: "medium_airport",
    name: "U-Tapao International Airport",
    elevation_ft: "42",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-21",
    municipality: "Rayong",
    gps_code: "VTBU",
    iata_code: "UTP",
    coordinates: "101.00499725341797, 12.679900169372559",
  },
  {
    ident: "VTCC",
    type: "large_airport",
    name: "Chiang Mai International Airport",
    elevation_ft: "1036",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-50",
    municipality: "Chiang Mai",
    gps_code: "VTCC",
    iata_code: "CNX",
    coordinates: "98.962600708, 18.766799926799997",
  },
  {
    ident: "VTCH",
    type: "medium_airport",
    name: "Mae Hong Son Airport",
    elevation_ft: "929",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-58",
    gps_code: "VTCH",
    iata_code: "HGN",
    coordinates: "97.97579956054688, 19.301300048828125",
  },
  {
    ident: "VTCI",
    type: "small_airport",
    name: "Mae Hong Son Airport",
    elevation_ft: "1271",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-58",
    municipality: "Mae Hong Son",
    gps_code: "VTCI",
    iata_code: "PYY",
    coordinates: "98.43699646, 19.3719997406",
  },
  {
    ident: "VTCL",
    type: "medium_airport",
    name: "Lampang Airport",
    elevation_ft: "811",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-52",
    gps_code: "VTCL",
    iata_code: "LPT",
    coordinates: "99.50420379638672, 18.27090072631836",
  },
  {
    ident: "VTCN",
    type: "medium_airport",
    name: "Nan Airport",
    elevation_ft: "685",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-55",
    gps_code: "VTCN",
    iata_code: "NNT",
    coordinates: "100.78299713134766, 18.807899475097656",
  },
  {
    ident: "VTCP",
    type: "medium_airport",
    name: "Phrae Airport",
    elevation_ft: "538",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-54",
    gps_code: "VTCP",
    iata_code: "PRH",
    coordinates: "100.16500091552734, 18.132200241088867",
  },
  {
    ident: "VTCT",
    type: "medium_airport",
    name: "Chiang Rai International Airport",
    elevation_ft: "1280",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-57",
    municipality: "Chiang Rai",
    gps_code: "VTCT",
    iata_code: "CEI",
    coordinates: "99.88289642330001, 19.952299118",
  },
  {
    ident: "VTED",
    type: "small_airport",
    name: "Udorn Air Base",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-41",
    municipality: "Ban Mak Khaen",
    gps_code: "VTED",
    iata_code: "BAO",
    coordinates: "102.80000305175781, 17.382999420166016",
  },
  {
    ident: "VTPB",
    type: "medium_airport",
    name: "Phetchabun Airport",
    elevation_ft: "450",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-67",
    gps_code: "VTPB",
    iata_code: "PHY",
    coordinates: "101.194999695, 16.6760005951",
  },
  {
    ident: "VTPH",
    type: "medium_airport",
    name: "Hua Hin Airport",
    elevation_ft: "62",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-76",
    municipality: "Hua Hin",
    gps_code: "VTPH",
    iata_code: "HHQ",
    coordinates: "99.951499939, 12.6361999512",
  },
  {
    ident: "VTPI",
    type: "medium_airport",
    name: "Takhli Airport",
    elevation_ft: "107",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-60",
    gps_code: "VTPI",
    iata_code: "TKH",
    coordinates: "100.29599762, 15.277299881",
  },
  {
    ident: "VTPM",
    type: "medium_airport",
    name: "Mae Sot Airport",
    elevation_ft: "690",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-63",
    gps_code: "VTPM",
    iata_code: "MAQ",
    coordinates: "98.54509735107422, 16.699899673461914",
  },
  {
    ident: "VTPO",
    type: "medium_airport",
    name: "Sukhothai Airport",
    elevation_ft: "179",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-64",
    gps_code: "VTPO",
    iata_code: "THS",
    coordinates: "99.81819915771484, 17.238000869750977",
  },
  {
    ident: "VTPP",
    type: "medium_airport",
    name: "Phitsanulok Airport",
    elevation_ft: "154",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-65",
    gps_code: "VTPP",
    iata_code: "PHS",
    coordinates: "100.27899932861328, 16.782899856567383",
  },
  {
    ident: "VTPT",
    type: "medium_airport",
    name: "Tak Airport",
    elevation_ft: "478",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-63",
    gps_code: "VTPT",
    iata_code: "TKT",
    coordinates: "99.25330352783203, 16.895999908447266",
  },
  {
    ident: "VTPU",
    type: "small_airport",
    name: "Uttaradit Airport",
    elevation_ft: "262",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-53",
    municipality: "Uttaradit",
    gps_code: "VTPU",
    iata_code: "UTR",
    coordinates: "100.0999984741211, 17.617000579833984",
  },
  {
    ident: "VTSB",
    type: "medium_airport",
    name: "Surat Thani Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-84",
    municipality: "Surat Thani",
    gps_code: "VTSB",
    iata_code: "URT",
    coordinates: "99.135597229, 9.13259983063",
  },
  {
    ident: "VTSC",
    type: "medium_airport",
    name: "Narathiwat Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-96",
    gps_code: "VTSC",
    iata_code: "NAW",
    coordinates: "101.74299621582031, 6.5199198722839355",
  },
  {
    ident: "VTSE",
    type: "medium_airport",
    name: "Chumphon Airport",
    elevation_ft: "18",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-86",
    gps_code: "VTSE",
    iata_code: "CJM",
    coordinates: "99.36170196533203, 10.711199760437012",
  },
  {
    ident: "VTSF",
    type: "medium_airport",
    name: "Nakhon Si Thammarat Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-80",
    municipality: "Nakhon Si Thammarat",
    gps_code: "VTSF",
    iata_code: "NST",
    coordinates: "99.9447021484375, 8.539620399475098",
  },
  {
    ident: "VTSG",
    type: "medium_airport",
    name: "Krabi Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-81",
    municipality: "Krabi",
    gps_code: "VTSG",
    iata_code: "KBV",
    coordinates: "98.9861984253, 8.09912014008",
  },
  {
    ident: "VTSH",
    type: "medium_airport",
    name: "Songkhla Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-90",
    gps_code: "VTSH",
    iata_code: "SGZ",
    coordinates: "100.60800170898438, 7.186560153961182",
  },
  {
    ident: "VTSK",
    type: "medium_airport",
    name: "Pattani Airport",
    elevation_ft: "8",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-94",
    gps_code: "VTSK",
    iata_code: "PAN",
    coordinates: "101.15399932861328, 6.785459995269775",
  },
  {
    ident: "VTSM",
    type: "medium_airport",
    name: "Samui Airport",
    elevation_ft: "64",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-80",
    municipality: "Na Thon (Ko Samui Island)",
    gps_code: "VTSM",
    iata_code: "USM",
    coordinates: "100.06199646, 9.547789573669998",
  },
  {
    ident: "VTSP",
    type: "large_airport",
    name: "Phuket International Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-83",
    municipality: "Phuket",
    gps_code: "VTSP",
    iata_code: "HKT",
    coordinates: "98.316902, 8.1132",
  },
  {
    ident: "VTSR",
    type: "medium_airport",
    name: "Ranong Airport",
    elevation_ft: "57",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-85",
    gps_code: "VTSR",
    iata_code: "UNN",
    coordinates: "98.58550262451172, 9.777620315551758",
  },
  {
    ident: "VTSS",
    type: "medium_airport",
    name: "Hat Yai International Airport",
    elevation_ft: "90",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-90",
    municipality: "Hat Yai",
    gps_code: "VTSS",
    iata_code: "HDY",
    coordinates: "100.392997742, 6.93320989609",
  },
  {
    ident: "VTST",
    type: "medium_airport",
    name: "Trang Airport",
    elevation_ft: "67",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-92",
    gps_code: "VTST",
    iata_code: "TST",
    coordinates: "99.6166000366211, 7.508739948272705",
  },
  {
    ident: "VTUD",
    type: "medium_airport",
    name: "Udon Thani Airport",
    elevation_ft: "579",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-41",
    municipality: "Udon Thani",
    gps_code: "VTUD",
    iata_code: "UTH",
    coordinates: "102.788002014, 17.386400222800003",
  },
  {
    ident: "VTUI",
    type: "medium_airport",
    name: "Sakon Nakhon Airport",
    elevation_ft: "529",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-47",
    gps_code: "VTUI",
    iata_code: "SNO",
    coordinates: "104.11900329589844, 17.195100784301758",
  },
  {
    ident: "VTUJ",
    type: "medium_airport",
    name: "Surin Airport",
    elevation_ft: "478",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-32",
    municipality: "Surin",
    gps_code: "VTUJ",
    iata_code: "PXR",
    coordinates: "103.49800109863, 14.868300437927",
  },
  {
    ident: "VTUK",
    type: "medium_airport",
    name: "Khon Kaen Airport",
    elevation_ft: "670",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-40",
    municipality: "Khon Kaen",
    gps_code: "VTUK",
    iata_code: "KKC",
    coordinates: "102.783996582, 16.466600418099997",
  },
  {
    ident: "VTUL",
    type: "medium_airport",
    name: "Loei Airport",
    elevation_ft: "860",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-42",
    gps_code: "VTUL",
    iata_code: "LOE",
    coordinates: "101.72200012207031, 17.43910026550293",
  },
  {
    ident: "VTUO",
    type: "medium_airport",
    name: "Buri Ram Airport",
    elevation_ft: "590",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-31",
    gps_code: "VTUO",
    iata_code: "BFV",
    coordinates: "103.25299835205078, 15.229499816894531",
  },
  {
    ident: "VTUQ",
    type: "medium_airport",
    name: "Nakhon Ratchasima Airport",
    elevation_ft: "765",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-30",
    gps_code: "VTUQ",
    iata_code: "NAK",
    coordinates: "102.31300354003906, 14.94950008392334",
  },
  {
    ident: "VTUU",
    type: "medium_airport",
    name: "Ubon Ratchathani Airport",
    elevation_ft: "406",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-34",
    municipality: "Ubon Ratchathani",
    gps_code: "VTUU",
    iata_code: "UBP",
    coordinates: "104.870002747, 15.2512998581",
  },
  {
    ident: "VTUV",
    type: "medium_airport",
    name: "Roi Et Airport",
    elevation_ft: "451",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-45",
    gps_code: "VTUV",
    iata_code: "ROI",
    coordinates: "103.77400207519531, 16.11680030822754",
  },
  {
    ident: "VTUW",
    type: "medium_airport",
    name: "Nakhon Phanom Airport",
    elevation_ft: "587",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-48",
    gps_code: "VTUW",
    iata_code: "KOP",
    coordinates: "104.64299774169922, 17.383800506591797",
  },
  {
    ident: "VUU",
    type: "small_airport",
    name: "Mvuu Camp Airport",
    elevation_ft: "1600",
    continent: "AF",
    iso_country: "MW",
    iso_region: "MW-MH",
    municipality: "Liwonde National Park",
    iata_code: "VUU",
    coordinates: "35.3013888889, -14.8386111111",
  },
  {
    ident: "VVBM",
    type: "medium_airport",
    name: "Buon Ma Thuot Airport",
    elevation_ft: "1729",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-33",
    municipality: "Buon Ma Thuot",
    gps_code: "VVBM",
    iata_code: "BMV",
    coordinates: "108.120002747, 12.668299675",
  },
  {
    ident: "VVCA",
    type: "small_airport",
    name: "Chu Lai International Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-29",
    municipality: "Dung Quat Bay",
    gps_code: "VVCA",
    iata_code: "VCL",
    coordinates: "108.706001282, 15.403300285299999",
  },
  {
    ident: "VVCI",
    type: "medium_airport",
    name: "Cat Bi International Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-61",
    municipality: "Haiphong",
    gps_code: "VVCI",
    iata_code: "HPH",
    coordinates: "106.7249984741211, 20.819400787353516",
  },
  {
    ident: "VVCM",
    type: "medium_airport",
    name: "C���� Mau Airport",
    elevation_ft: "6",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-59",
    municipality: "Ca Mau City",
    gps_code: "VVCM",
    iata_code: "CAH",
    coordinates: "105.177778, 9.177667",
  },
  {
    ident: "VVCR",
    type: "medium_airport",
    name: "Cam Ranh Airport",
    elevation_ft: "40",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-34",
    municipality: "Nha Trang",
    gps_code: "VVCR",
    iata_code: "CXR",
    coordinates: "109.21900177001953, 11.998200416564941",
  },
  {
    ident: "VVCS",
    type: "medium_airport",
    name: "Co Ong Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-43",
    municipality: "Con Dao",
    gps_code: "VVCS",
    iata_code: "VCS",
    coordinates: "106.633003, 8.73183",
  },
  {
    ident: "VVCT",
    type: "medium_airport",
    name: "Can Tho International Airport",
    elevation_ft: "9",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-02",
    municipality: "Can Tho",
    gps_code: "VVCT",
    iata_code: "VCA",
    coordinates: "105.711997986, 10.085100174",
  },
  {
    ident: "VVDB",
    type: "medium_airport",
    name: "Dien Bien Phu Airport",
    elevation_ft: "1611",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-01",
    municipality: "Dien Bien Phu",
    gps_code: "VVDB",
    iata_code: "DIN",
    coordinates: "103.008003235, 21.3974990845",
  },
  {
    ident: "VVDL",
    type: "medium_airport",
    name: "Lien Khuong Airport",
    elevation_ft: "3156",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-35",
    municipality: "Da Lat",
    gps_code: "VVDL",
    iata_code: "DLI",
    coordinates: "108.366997, 11.75",
  },
  {
    ident: "VVDN",
    type: "large_airport",
    name: "Da Nang International Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-60",
    municipality: "Da Nang",
    gps_code: "VVDN",
    iata_code: "DAD",
    coordinates: "108.1989974975586, 16.043899536132812",
  },
  {
    ident: "VVN",
    type: "small_airport",
    name: "Las Malvinas/Echarate Airport",
    elevation_ft: "1285",
    continent: "SA",
    iso_country: "PE",
    iso_region: "PE-CUS",
    municipality: "Las Malvinas",
    gps_code: "SPWT",
    iata_code: "VVN",
    coordinates: "-72.939332, -11.854861",
  },
  {
    ident: "VVNB",
    type: "large_airport",
    name: "Noi Bai International Airport",
    elevation_ft: "39",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-15",
    municipality: "Hanoi",
    gps_code: "VVNB",
    iata_code: "HAN",
    coordinates: "105.80699920654297, 21.221200942993164",
  },
  {
    ident: "VVNS",
    type: "small_airport",
    name: "Na-San Airport",
    elevation_ft: "2133",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-05",
    municipality: "Son-La",
    gps_code: "VVNS",
    iata_code: "SQH",
    coordinates: "104.03299713134766, 21.216999053955078",
  },
  {
    ident: "VVNT",
    type: "closed",
    name: "Nha Trang Air Base",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-34",
    municipality: "Nha Trang",
    gps_code: "VVNT",
    iata_code: "NHA",
    coordinates: "109.192001, 12.2275",
  },
  {
    ident: "VVPB",
    type: "medium_airport",
    name: "Phu Bai Airport",
    elevation_ft: "48",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-56",
    municipality: "Hue",
    gps_code: "VVPB",
    iata_code: "HUI",
    coordinates: "107.70300293, 16.401500701899998",
  },
  {
    ident: "VVPC",
    type: "medium_airport",
    name: "Phu Cat Airport",
    elevation_ft: "80",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-31",
    municipality: "Quy Nohn",
    gps_code: "VVPC",
    iata_code: "UIH",
    coordinates: "109.042, 13.955",
  },
  {
    ident: "VVPK",
    type: "medium_airport",
    name: "Pleiku Airport",
    elevation_ft: "2434",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-30",
    municipality: "Pleiku",
    gps_code: "VVPK",
    iata_code: "PXU",
    coordinates: "108.01699829101562, 14.004500389099121",
  },
  {
    ident: "VVPQ",
    type: "medium_airport",
    name: "Phu Quoc International Airport",
    elevation_ft: "37",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-47",
    municipality: "Phu Quoc Island",
    gps_code: "VVPQ",
    iata_code: "PQC",
    coordinates: "103.9931, 10.1698",
  },
  {
    ident: "VVPR",
    type: "small_airport",
    name: "Phan Rang Airport",
    elevation_ft: "101",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-07",
    municipality: "Phan Rang",
    gps_code: "VVPR",
    iata_code: "PHA",
    coordinates: "108.952003479, 11.6335000992",
  },
  {
    ident: "VVPT",
    type: "small_airport",
    name: "Phan Thiet Airport",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-40",
    municipality: "Phan Thiet",
    gps_code: "VVPT",
    iata_code: "PHH",
    coordinates: "108.06900024414062, 10.906399726867676",
  },
  {
    ident: "VVRG",
    type: "medium_airport",
    name: "Rach Gia Airport",
    elevation_ft: "7",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-47",
    municipality: "Rach Gia",
    gps_code: "VVRG",
    iata_code: "VKG",
    coordinates: "105.132379532, 9.95802997234",
  },
  {
    ident: "VVTH",
    type: "medium_airport",
    name: "Dong Tac Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-49",
    municipality: "Tuy Hoa",
    gps_code: "VVTH",
    iata_code: "TBB",
    coordinates: "109.333999634, 13.049599647500001",
  },
  {
    ident: "VVTS",
    type: "large_airport",
    name: "Tan Son Nhat International Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-23",
    municipality: "Ho Chi Minh City",
    gps_code: "VVTS",
    iata_code: "SGN",
    coordinates: "106.652000427, 10.8187999725",
  },
  {
    ident: "VVTX",
    type: "small_airport",
    name: "Tho Xuan Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-21",
    municipality: "Thanh H����a",
    gps_code: "VVTX",
    iata_code: "THD",
    coordinates: "105.467778, 19.901667",
  },
  {
    ident: "VVVD",
    type: "medium_airport",
    name: "V����n ����������n International Airport",
    elevation_ft: "24",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-13",
    municipality: "Ha Long",
    gps_code: "VVVD",
    iata_code: "VDO",
    coordinates: "107.414167, 21.117778",
  },
  {
    ident: "VVVH",
    type: "medium_airport",
    name: "Vinh Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-26",
    municipality: "Vinh",
    gps_code: "VVVH",
    iata_code: "VII",
    coordinates: "105.67099762, 18.7376003265",
  },
  {
    ident: "VVVT",
    type: "small_airport",
    name: "Vung Tau Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-43",
    municipality: "Vung Tau",
    gps_code: "VVVT",
    iata_code: "VTG",
    coordinates: "107.09500122070312, 10.3725004196167",
  },
  {
    ident: "VYAN",
    type: "small_airport",
    name: "Ann Airport",
    elevation_ft: "74",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-16",
    municipality: "Aeng",
    gps_code: "VYAN",
    iata_code: "VBA",
    coordinates: "94.0261, 19.769199",
  },
  {
    ident: "VYBG",
    type: "small_airport",
    name: "Bagan Airport",
    elevation_ft: "312",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-04",
    municipality: "Nyaung U",
    gps_code: "VYBG",
    iata_code: "NYU",
    coordinates: "94.9301986694336, 21.178800582885742",
  },
  {
    ident: "VYBM",
    type: "small_airport",
    name: "Banmaw Airport",
    elevation_ft: "370",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-11",
    municipality: "Banmaw",
    gps_code: "VYBM",
    iata_code: "BMO",
    coordinates: "97.24620056152344, 24.268999099731445",
  },
  {
    ident: "VYBP",
    type: "small_airport",
    name: "Bokpyinn Airport",
    elevation_ft: "100",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-05",
    municipality: "Bokpyinn",
    gps_code: "VYBP",
    iata_code: "VBP",
    coordinates: "98.735901, 11.1494",
  },
  {
    ident: "VYDW",
    type: "medium_airport",
    name: "Dawei Airport",
    elevation_ft: "84",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-05",
    municipality: "Dawei",
    gps_code: "VYDW",
    iata_code: "TVY",
    coordinates: "98.20359802246094, 14.103899955749512",
  },
  {
    ident: "VYEL",
    type: "medium_airport",
    name: "Naypyidaw Airport",
    elevation_ft: "302",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-04",
    municipality: "Pyinmana",
    gps_code: "VYNT",
    iata_code: "NYT",
    coordinates: "96.200996, 19.623501",
  },
  {
    ident: "VYGG",
    type: "small_airport",
    name: "Gangaw Airport",
    elevation_ft: "200",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-03",
    municipality: "Gangaw",
    gps_code: "VYGG",
    iata_code: "GAW",
    coordinates: "94.1343994140625, 22.174699783325195",
  },
  {
    ident: "VYGW",
    type: "small_airport",
    name: "Gwa Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-16",
    municipality: "Gwa",
    gps_code: "VYGW",
    iata_code: "GWA",
    coordinates: "94.58329772949219, 17.600000381469727",
  },
  {
    ident: "VYHH",
    type: "medium_airport",
    name: "Heho Airport",
    elevation_ft: "3858",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-17",
    municipality: "Heho",
    gps_code: "VYHH",
    iata_code: "HEH",
    coordinates: "96.79199981689453, 20.746999740600586",
  },
  {
    ident: "VYHL",
    type: "small_airport",
    name: "Hommalinn Airport",
    elevation_ft: "534",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-01",
    municipality: "Hommalinn",
    gps_code: "VYHL",
    iata_code: "HOX",
    coordinates: "94.91400146484375, 24.899599075317383",
  },
  {
    ident: "VYHN",
    type: "small_airport",
    name: "Tilin Airport",
    elevation_ft: "1385",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-03",
    municipality: "Tilin",
    gps_code: "VYHN",
    iata_code: "TIO",
    coordinates: "94.0999984741211, 21.700000762939453",
  },
  {
    ident: "VYKG",
    type: "medium_airport",
    name: "Kengtung Airport",
    elevation_ft: "2798",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-17",
    municipality: "Kengtung",
    gps_code: "VYKG",
    iata_code: "KET",
    coordinates: "99.63600158691406, 21.301599502563477",
  },
  {
    ident: "VYKI",
    type: "small_airport",
    name: "Kanti Airport",
    elevation_ft: "6000",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-01",
    municipality: "Kanti",
    gps_code: "VYKI",
    iata_code: "KHM",
    coordinates: "95.67440032958984, 25.988300323486328",
  },
  {
    ident: "VYKL",
    type: "small_airport",
    name: "Kalay Airport",
    elevation_ft: "499",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-01",
    municipality: "Kalemyo",
    gps_code: "VYKL",
    iata_code: "KMV",
    coordinates: "94.05110168457031, 23.188800811767578",
  },
  {
    ident: "VYKP",
    type: "medium_airport",
    name: "Kyaukpyu Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-16",
    municipality: "Kyaukpyu",
    gps_code: "VYKP",
    iata_code: "KYP",
    coordinates: "93.53479766845703, 19.42639923095703",
  },
  {
    ident: "VYKT",
    type: "medium_airport",
    name: "Kawthoung Airport",
    elevation_ft: "180",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-05",
    municipality: "Kawthoung",
    gps_code: "VYKT",
    iata_code: "KAW",
    coordinates: "98.53800201416016, 10.049300193786621",
  },
  {
    ident: "VYKU",
    type: "small_airport",
    name: "Kyauktu Airport",
    elevation_ft: "1250",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-03",
    municipality: "Kyauktu",
    gps_code: "VYKU",
    iata_code: "KYT",
    coordinates: "94.13330078125, 21.399999618530273",
  },
  {
    ident: "VYLK",
    type: "medium_airport",
    name: "Loikaw Airport",
    elevation_ft: "2940",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-12",
    municipality: "Loikaw",
    gps_code: "VYLK",
    iata_code: "LIW",
    coordinates: "97.21479797363281, 19.691499710083008",
  },
  {
    ident: "VYLS",
    type: "medium_airport",
    name: "Lashio Airport",
    elevation_ft: "2450",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-17",
    municipality: "Lashio",
    gps_code: "VYLS",
    iata_code: "LSH",
    coordinates: "97.752197265625, 22.9778995513916",
  },
  {
    ident: "VYMD",
    type: "large_airport",
    name: "Mandalay International Airport",
    elevation_ft: "300",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-04",
    municipality: "Mandalay",
    gps_code: "VYMD",
    iata_code: "MDL",
    coordinates: "95.97789764404297, 21.702199935913086",
  },
  {
    ident: "VYME",
    type: "medium_airport",
    name: "Myeik Airport",
    elevation_ft: "75",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-05",
    municipality: "Mkeik",
    gps_code: "VYME",
    iata_code: "MGZ",
    coordinates: "98.62149810791016, 12.439800262451172",
  },
  {
    ident: "VYMK",
    type: "medium_airport",
    name: "Myitkyina Airport",
    elevation_ft: "475",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-11",
    municipality: "Myitkyina",
    gps_code: "VYMK",
    iata_code: "MYT",
    coordinates: "97.35189819335938, 25.38360023498535",
  },
  {
    ident: "VYMM",
    type: "small_airport",
    name: "Mawlamyine Airport",
    elevation_ft: "52",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-15",
    municipality: "Mawlamyine",
    gps_code: "VYMM",
    iata_code: "MNU",
    coordinates: "97.66069793701172, 16.444700241088867",
  },
  {
    ident: "VYMN",
    type: "small_airport",
    name: "Manaung Airport",
    elevation_ft: "102",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-16",
    municipality: "Manaung",
    gps_code: "VYMN",
    iata_code: "MGU",
    coordinates: "93.68890380859375, 18.845800399780273",
  },
  {
    ident: "VYMO",
    type: "medium_airport",
    name: "Momeik Airport",
    elevation_ft: "600",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-17",
    gps_code: "VYMO",
    iata_code: "MOE",
    coordinates: "96.64530181884766, 23.092500686645508",
  },
  {
    ident: "VYMS",
    type: "medium_airport",
    name: "Mong Hsat Airport",
    elevation_ft: "1875",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-17",
    municipality: "Mong Hsat",
    gps_code: "VYMS",
    iata_code: "MOG",
    coordinates: "99.25679779052734, 20.516799926757812",
  },
  {
    ident: "VYMT",
    type: "small_airport",
    name: "Mong Tong Airport",
    elevation_ft: "1840",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-17",
    municipality: "Mong Tong",
    gps_code: "VYMT",
    iata_code: "MGK",
    coordinates: "98.8989028930664, 20.29669952392578",
  },
  {
    ident: "VYMW",
    type: "small_airport",
    name: "Magway Airport",
    elevation_ft: "279",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-03",
    municipality: "Magway",
    gps_code: "VYMW",
    iata_code: "MWQ",
    coordinates: "94.941399, 20.1656",
  },
  {
    ident: "VYMY",
    type: "small_airport",
    name: "Monywar Airport",
    elevation_ft: "298",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-01",
    municipality: "Monywar",
    gps_code: "VYMY",
    iata_code: "NYW",
    coordinates: "95.093479, 22.221638",
  },
  {
    ident: "VYNS",
    type: "medium_airport",
    name: "Namsang Airport",
    elevation_ft: "3100",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-17",
    municipality: "Namsang",
    gps_code: "VYNS",
    iata_code: "NMS",
    coordinates: "97.73590087890625, 20.890499114990234",
  },
  {
    ident: "VYNT",
    type: "small_airport",
    name: "Namtu Airport",
    elevation_ft: "2000",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-17",
    municipality: "Namtu",
    gps_code: "VYNU",
    iata_code: "NMT",
    coordinates: "97.383003, 23.083",
  },
  {
    ident: "VYPA",
    type: "small_airport",
    name: "Hpa-N Airport",
    elevation_ft: "150",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-13",
    municipality: "Hpa-N",
    gps_code: "VYPA",
    iata_code: "PAA",
    coordinates: "97.67459869384766, 16.893699645996094",
  },
  {
    ident: "VYPK",
    type: "small_airport",
    name: "Pauk Airport",
    elevation_ft: "249",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-03",
    municipality: "Pauk",
    gps_code: "VYPK",
    iata_code: "PAU",
    coordinates: "94.48690032958984, 21.449199676513672",
  },
  {
    ident: "VYPN",
    type: "small_airport",
    name: "Pathein Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-07",
    municipality: "Pathein",
    gps_code: "VYPN",
    iata_code: "BSX",
    coordinates: "94.7799, 16.815201",
  },
  {
    ident: "VYPP",
    type: "small_airport",
    name: "Hpapun Airport",
    elevation_ft: "300",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-13",
    municipality: "Pa Pun",
    gps_code: "VYPP",
    iata_code: "PPU",
    coordinates: "97.449798584, 18.0666999817",
  },
  {
    ident: "VYPT",
    type: "medium_airport",
    name: "Putao Airport",
    elevation_ft: "1500",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-11",
    municipality: "Putao",
    gps_code: "VYPT",
    iata_code: "PBU",
    coordinates: "97.42630004882812, 27.32990074157715",
  },
  {
    ident: "VYPU",
    type: "small_airport",
    name: "Pakhokku Airport",
    elevation_ft: "151",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-03",
    municipality: "Pakhokku",
    gps_code: "VYPU",
    iata_code: "PKK",
    coordinates: "95.11125, 21.4043",
  },
  {
    ident: "VYPY",
    type: "small_airport",
    name: "Pyay Airport",
    elevation_ft: "120",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-02",
    municipality: "Pye",
    gps_code: "VYPY",
    iata_code: "PRU",
    coordinates: "95.26599884033203, 18.824499130249023",
  },
  {
    ident: "VYSW",
    type: "medium_airport",
    name: "Sittwe Airport",
    elevation_ft: "27",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-16",
    municipality: "Sittwe",
    gps_code: "VYSW",
    iata_code: "AKY",
    coordinates: "92.87259674072266, 20.132699966430664",
  },
  {
    ident: "VYTD",
    type: "medium_airport",
    name: "Thandwe Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-16",
    municipality: "Thandwe",
    gps_code: "VYTD",
    iata_code: "SNW",
    coordinates: "94.30010223388672, 18.4606990814209",
  },
  {
    ident: "VYTL",
    type: "medium_airport",
    name: "Tachileik Airport",
    elevation_ft: "1280",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-17",
    municipality: "Tachileik",
    gps_code: "VYTL",
    iata_code: "THL",
    coordinates: "99.9354019165039, 20.483800888061523",
  },
  {
    ident: "VYYE",
    type: "small_airport",
    name: "Ye Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-15",
    municipality: "Ye",
    gps_code: "VYYE",
    iata_code: "XYE",
    coordinates: "97.86699676513672, 15.300000190734863",
  },
  {
    ident: "VYYY",
    type: "large_airport",
    name: "Yangon International Airport",
    elevation_ft: "109",
    continent: "AS",
    iso_country: "MM",
    iso_region: "MM-06",
    municipality: "Yangon",
    gps_code: "VYYY",
    iata_code: "RGN",
    coordinates: "96.1332015991, 16.907300949099998",
  },
  {
    ident: "W33",
    type: "seaplane_base",
    name: "Friday Harbor Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Friday Harbor",
    gps_code: "W33",
    iata_code: "FBS",
    local_code: "W33",
    coordinates: "-123.010002136, 48.537300109899995",
  },
  {
    ident: "W49",
    type: "seaplane_base",
    name: "Rosario Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Rosario",
    gps_code: "W49",
    iata_code: "RSJ",
    local_code: "W49",
    coordinates: "-122.867996216, 48.6456985474",
  },
  {
    ident: "W55",
    type: "seaplane_base",
    name: "Kenmore Air Harbor Seaplane Base",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Seattle",
    gps_code: "W55",
    iata_code: "LKE",
    local_code: "W55",
    coordinates: "-122.338996887, 47.6290016174",
  },
  {
    ident: "WA09",
    type: "small_airport",
    name: "Roche Harbor Airport",
    elevation_ft: "100",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Roche Harbor",
    gps_code: "WA09",
    iata_code: "RCE",
    local_code: "WA09",
    coordinates: "-123.138999939, 48.6123008728",
  },
  {
    ident: "WA44",
    type: "small_airport",
    name: "Maranggo Airport",
    elevation_ft: "169",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SG",
    municipality: "Waha-Tomea Island",
    gps_code: "WA44",
    iata_code: "TQQ",
    local_code: "WA44",
    coordinates: "123.91699981689, -5.7645702362061",
  },
  {
    ident: "WA83",
    type: "seaplane_base",
    name: "Westsound/Wsx Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Westsound",
    gps_code: "WA83",
    iata_code: "WSX",
    local_code: "WA83",
    coordinates: "-122.95300293, 48.6179008484",
  },
  {
    ident: "WAAA",
    type: "large_airport",
    name: "Hasanuddin International Airport",
    elevation_ft: "47",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SN",
    municipality: "Ujung Pandang-Celebes Island",
    gps_code: "WAAA",
    iata_code: "UPG",
    coordinates: "119.55400085449219, -5.061629772186279",
  },
  {
    ident: "WAAJ",
    type: "small_airport",
    name: "Tampa Padang Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SN",
    municipality: "Mamuju-Celebes Island",
    gps_code: "WAWJ",
    iata_code: "MJU",
    coordinates: "119.033333, -2.583333",
  },
  {
    ident: "WAB",
    type: "closed",
    name: "Wabag Airport",
    elevation_ft: "6800",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EPW",
    municipality: "Wabag",
    iata_code: "WAB",
    coordinates: "143.72, -5.4915",
  },
  {
    ident: "WABB",
    type: "medium_airport",
    name: "Frans Kaisiepo Airport",
    elevation_ft: "46",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Biak-Supiori Island",
    gps_code: "WABB",
    iata_code: "BIK",
    coordinates: "136.10800170898438, -1.190019965171814",
  },
  {
    ident: "WABD",
    type: "small_airport",
    name: "Moanamani Airport",
    elevation_ft: "5204",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Moanamani-Papua Island",
    gps_code: "WABD",
    iata_code: "ONI",
    coordinates: "136.0832977294922, -3.9834020137786865",
  },
  {
    ident: "WABG",
    type: "small_airport",
    name: "Wagethe Airport",
    elevation_ft: "5627",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Wagethe-Papua Island",
    gps_code: "WABG",
    iata_code: "WET",
    coordinates: "136.2779998779297, -4.044229984283447",
  },
  {
    ident: "WABI",
    type: "medium_airport",
    name: "Nabire Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Nabire-Papua Island",
    gps_code: "WABI",
    iata_code: "NBX",
    coordinates: "135.496002, -3.36818",
  },
  {
    ident: "WABL",
    type: "small_airport",
    name: "Illaga Airport",
    elevation_ft: "7989",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Illaga-Papua Island",
    gps_code: "WABL",
    iata_code: "ILA",
    coordinates: "137.6225, -3.97648",
  },
  {
    ident: "WABN",
    type: "small_airport",
    name: "Kokonau Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Kokonau-Papua Island",
    gps_code: "WABN",
    iata_code: "KOX",
    coordinates: "136.43515, -4.71075",
  },
  {
    ident: "WABO",
    type: "small_airport",
    name: "Serui Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Serui-Japen Island",
    gps_code: "WABO",
    iata_code: "ZRI",
    coordinates: "136.24099731445312, -1.8755799531936646",
  },
  {
    ident: "WABP",
    type: "medium_airport",
    name: "Moses Kilangin Airport",
    elevation_ft: "103",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Timika-Papua Island",
    gps_code: "WAYY",
    iata_code: "TIM",
    coordinates: "136.886993, -4.52828",
  },
  {
    ident: "WABT",
    type: "small_airport",
    name: "Enarotali Airport",
    elevation_ft: "5807",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Enarotali-Papua Island",
    gps_code: "WABT",
    iata_code: "EWI",
    coordinates: "136.3769989013672, -3.9258999824523926",
  },
  {
    ident: "WAD",
    type: "small_airport",
    name: "Andriamena Airport",
    elevation_ft: "2475",
    continent: "AF",
    iso_country: "MG",
    iso_region: "MG-M",
    municipality: "Andriamena",
    iata_code: "WAD",
    coordinates: "47.7233, -17.631",
  },
  {
    ident: "WADA",
    type: "small_airport",
    name: "Selaparang Airport",
    elevation_ft: "52",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NB",
    municipality: "Mataram-Lombok Island",
    gps_code: "WADA",
    iata_code: "AMI",
    coordinates: "116.095001221, -8.560709953309999",
  },
  {
    ident: "WADB",
    type: "small_airport",
    name: "Muhammad Salahuddin Airport",
    elevation_ft: "3",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NB",
    municipality: "Bima-Sumbawa Island",
    gps_code: "WADB",
    iata_code: "BMU",
    coordinates: "118.68699645996, -8.5396499633789",
  },
  {
    ident: "WADD",
    type: "large_airport",
    name: "Ngurah Rai (Bali) International Airport",
    elevation_ft: "14",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-BA",
    municipality: "Denpasar-Bali Island",
    gps_code: "WADD",
    iata_code: "DPS",
    coordinates: "115.16699981689, -8.7481698989868",
  },
  {
    ident: "WADL",
    type: "medium_airport",
    name: "Lombok International Airport",
    elevation_ft: "319",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NB",
    municipality: "Mataram",
    gps_code: "WADL",
    iata_code: "LOP",
    coordinates: "116.276675, -8.757322",
  },
  {
    ident: "WADS",
    type: "small_airport",
    name: "Sumbawa Besar Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NB",
    municipality: "Sumbawa Island",
    gps_code: "WADS",
    iata_code: "SWQ",
    coordinates: "117.41200256347656, -8.48904037475586",
  },
  {
    ident: "WADT",
    type: "small_airport",
    name: "Tambolaka Airport",
    elevation_ft: "161",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Waikabubak-Sumba Island",
    gps_code: "WATK",
    iata_code: "TMC",
    coordinates: "119.244003, -9.40972",
  },
  {
    ident: "WADW",
    type: "small_airport",
    name: "Umbu Mehang Kunda Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Waingapu-Sumba Island",
    gps_code: "WATU",
    iata_code: "WGP",
    coordinates: "120.302002, -9.66922",
  },
  {
    ident: "WAFD",
    type: "small_airport",
    name: "Bua Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SN",
    municipality: "Palopo",
    gps_code: "WAFD",
    iata_code: "LLO",
    coordinates: "120.245018, -3.082997",
  },
  {
    ident: "WAHI",
    type: "medium_airport",
    name: "Yogyakarta International Airport",
    elevation_ft: "24",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-YO",
    municipality: "Yogyakarta",
    gps_code: "WAHI",
    iata_code: "YIA",
    coordinates: "110.057264, -7.905338",
  },
  {
    ident: "WAJA",
    type: "small_airport",
    name: "Arso Airport",
    elevation_ft: "208",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Arso-Papua Island",
    gps_code: "WAJA",
    iata_code: "ARJ",
    coordinates: "140.78334045410156, -2.933332920074463",
  },
  {
    ident: "WAJB",
    type: "small_airport",
    name: "Bokondini Airport",
    elevation_ft: "4550",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Bokondini",
    gps_code: "WAJB",
    iata_code: "BUI",
    coordinates: "138.6755, -3.6822",
  },
  {
    ident: "WAJI",
    type: "small_airport",
    name: "Sarmi Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Sarmi-Papua Island",
    gps_code: "WAJI",
    iata_code: "ZRM",
    coordinates: "138.75, -1.8695499897",
  },
  {
    ident: "WAJJ",
    type: "large_airport",
    name: "Sentani International Airport",
    elevation_ft: "289",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Jayapura-Papua Island",
    gps_code: "WAJJ",
    iata_code: "DJJ",
    coordinates: "140.5160064698, -2.5769500733",
  },
  {
    ident: "WAJL",
    type: "small_airport",
    name: "Lereh Airport",
    elevation_ft: "820",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Lereh-Papua Island",
    gps_code: "WAJL",
    iata_code: "LHI",
    coordinates: "139.952, -3.0795",
  },
  {
    ident: "WAJM",
    type: "small_airport",
    name: "Mulia Airport",
    elevation_ft: "6527",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Mulia-Papua Island",
    gps_code: "WABQ",
    iata_code: "LII",
    coordinates: "137.957, -3.7018",
  },
  {
    ident: "WAJO",
    type: "small_airport",
    name: "Oksibil Airport",
    elevation_ft: "4315",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Oksibil-Papua Island",
    gps_code: "WAJO",
    iata_code: "OKL",
    coordinates: "140.6277, -4.9071",
  },
  {
    ident: "WAJR",
    type: "small_airport",
    name: "Waris Airport",
    elevation_ft: "1500",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Swach",
    gps_code: "WAJR",
    iata_code: "WAR",
    coordinates: "140.994, -3.235",
  },
  {
    ident: "WAJS",
    type: "small_airport",
    name: "Senggeh Airport",
    elevation_ft: "914",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Senggeh",
    gps_code: "WAJS",
    iata_code: "SEH",
    coordinates: "140.779, -3.45",
  },
  {
    ident: "WAJU",
    type: "small_airport",
    name: "Ubrub Airport",
    elevation_ft: "1370",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Ubrub-Papua Island",
    gps_code: "WAJU",
    iata_code: "UBR",
    coordinates: "140.8838, -3.67565",
  },
  {
    ident: "WAJW",
    type: "medium_airport",
    name: "Wamena Airport",
    elevation_ft: "5085",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Wamena-Papua Island",
    gps_code: "WAJW",
    iata_code: "WMX",
    coordinates: "138.957001, -4.10251",
  },
  {
    ident: "WAKD",
    type: "small_airport",
    name: "Mindiptana Airport",
    elevation_ft: "157",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Mindiptana-Papua Island",
    gps_code: "WAKD",
    iata_code: "MDP",
    coordinates: "140.36700439453125, -5.75",
  },
  {
    ident: "WAKE",
    type: "small_airport",
    name: "Bade Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Bade",
    gps_code: "WAKE",
    iata_code: "BXD",
    coordinates: "139.58333, -7.175902",
  },
  {
    ident: "WAKK",
    type: "medium_airport",
    name: "Mopah Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Merauke-Papua Island",
    gps_code: "WAKK",
    iata_code: "MKQ",
    coordinates: "140.41799926757812, -8.52029037475586",
  },
  {
    ident: "WAKO",
    type: "small_airport",
    name: "Okaba Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Okaba-Papua Island",
    gps_code: "WAKO",
    iata_code: "OKQ",
    coordinates: "139.7233, -8.0946",
  },
  {
    ident: "WAKP",
    type: "small_airport",
    name: "Kepi Airport",
    elevation_ft: "67",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Kepi-Papua Island",
    gps_code: "WAKP",
    iata_code: "KEI",
    coordinates: "139.3318, -6.5418",
  },
  {
    ident: "WAKT",
    type: "small_airport",
    name: "Tanah Merah Airport",
    elevation_ft: "57",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PA",
    municipality: "Tanah Merah-Papua Island",
    gps_code: "WAKT",
    iata_code: "TMH",
    coordinates: "140.29800415039062, -6.099219799041748",
  },
  {
    ident: "WALG",
    type: "small_airport",
    name: "Tanjung Harapan Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KU",
    municipality: "Tanjung Selor-Borneo Island",
    gps_code: "WALG",
    iata_code: "TJS",
    coordinates: "117.373611111, 2.83583333333",
  },
  {
    ident: "WALJ",
    type: "small_airport",
    name: "Datadawai Airport",
    elevation_ft: "508",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Datadawai-Borneo Island",
    gps_code: "WALJ",
    iata_code: "DTD",
    coordinates: "114.5306, 0.8106",
  },
  {
    ident: "WALK",
    type: "medium_airport",
    name: "Kalimarau Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Tanjung Redeb - Borneo Island",
    gps_code: "WAQT",
    iata_code: "BEJ",
    coordinates: "117.431999, 2.1555",
  },
  {
    ident: "WALL",
    type: "small_airport",
    name: "Sultan Aji Muhamad Sulaiman Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Kotamadya Balikpapan",
    gps_code: "WALL",
    iata_code: "BPN",
    coordinates: "116.893997192, -1.26827001572",
  },
  {
    ident: "WALR",
    type: "medium_airport",
    name: "Juwata Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KU",
    municipality: "Tarakan Island",
    gps_code: "WAQQ",
    iata_code: "TRK",
    local_code: "TRK",
    coordinates: "117.569444, 3.326667",
  },
  {
    ident: "WALT",
    type: "small_airport",
    name: "Tanjung Santan Airport",
    elevation_ft: "121",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Santan-Borneo Island",
    gps_code: "WALT",
    iata_code: "TSX",
    coordinates: "117.45300293, -0.0929730013013",
  },
  {
    ident: "WALV",
    type: "small_airport",
    name: "Bunyu Airport",
    elevation_ft: "118",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KU",
    municipality: "Bunju Island",
    gps_code: "WALV",
    iata_code: "BYQ",
    local_code: "WA1C",
    coordinates: "117.866996765, 3.45571994781",
  },
  {
    ident: "WAMA",
    type: "small_airport",
    name: "Gamarmalamo Airport",
    elevation_ft: "180",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MU",
    municipality: "Galela-Celebes Island",
    gps_code: "WAMA",
    iata_code: "GLX",
    coordinates: "127.78600311279297, 1.8383300304412842",
  },
  {
    ident: "WAME",
    type: "small_airport",
    name: "Buli Airport",
    elevation_ft: "7",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Pekaulang",
    gps_code: "WAEM",
    iata_code: "PGQ",
    coordinates: "128.3825, 0.9194",
  },
  {
    ident: "WAMG",
    type: "small_airport",
    name: "Jalaluddin Airport",
    elevation_ft: "105",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-GO",
    municipality: "Gorontalo-Celebes Island",
    gps_code: "WAMG",
    iata_code: "GTO",
    coordinates: "122.849998474, 0.63711899519",
  },
  {
    ident: "WAMH",
    type: "medium_airport",
    name: "Naha Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SA",
    municipality: "Tahuna-Sangihe Island",
    gps_code: "WAMH",
    iata_code: "NAH",
    coordinates: "125.52799987792969, 3.6832098960876465",
  },
  {
    ident: "WAMI",
    type: "small_airport",
    name: "Sultan Bantilan Airport",
    elevation_ft: "40",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-ST",
    municipality: "Toli Toli-Celebes Island",
    gps_code: "WAMI",
    iata_code: "TLI",
    coordinates: "120.793658, 1.123428",
  },
  {
    ident: "WAMJ",
    type: "small_airport",
    name: "Gebe Airport",
    elevation_ft: "125",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MU",
    municipality: "Gebe Island",
    gps_code: "WAMJ",
    iata_code: "GEB",
    coordinates: "129.45799255371094, -0.07888899743556976",
  },
  {
    ident: "WAMK",
    type: "small_airport",
    name: "Kao Airport",
    elevation_ft: "27",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MU",
    municipality: "Kao-Celebes Island",
    gps_code: "WAMK",
    iata_code: "KAZ",
    coordinates: "127.89600372314453, 1.1852799654006958",
  },
  {
    ident: "WAML",
    type: "medium_airport",
    name: "Mutiara Airport",
    elevation_ft: "284",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-ST",
    municipality: "Palu-Celebes Island",
    gps_code: "WAML",
    iata_code: "PLW",
    coordinates: "119.91000366210938, -0.9185420274734497",
  },
  {
    ident: "WAMM",
    type: "medium_airport",
    name: "Sam Ratulangi Airport",
    elevation_ft: "264",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SA",
    municipality: "Manado-Celebes Island",
    gps_code: "WAMM",
    iata_code: "MDC",
    coordinates: "124.9260025024414, 1.5492600202560425",
  },
  {
    ident: "WAMN",
    type: "small_airport",
    name: "Melangguane Airport",
    elevation_ft: "3",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SA",
    municipality: "Karakelong Island",
    gps_code: "WAMN",
    iata_code: "MNA",
    coordinates: "126.672997, 4.00694",
  },
  {
    ident: "WAMP",
    type: "medium_airport",
    name: "Kasiguncu Airport",
    elevation_ft: "174",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-ST",
    municipality: "Poso-Celebes Island",
    gps_code: "WAMP",
    iata_code: "PSJ",
    coordinates: "120.657997131, -1.41674995422",
  },
  {
    ident: "WAMR",
    type: "medium_airport",
    name: "Pitu Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MU",
    municipality: "Gotalalamo-Morotai Island",
    gps_code: "WAMR",
    iata_code: "OTI",
    coordinates: "128.324996948, 2.0459899902300003",
  },
  {
    ident: "WAMT",
    type: "medium_airport",
    name: "Sultan Babullah Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MU",
    municipality: "Sango-Ternate Island",
    gps_code: "WAEE",
    iata_code: "TTE",
    coordinates: "127.380997, 0.831414",
  },
  {
    ident: "WAMW",
    type: "medium_airport",
    name: "Syukuran Aminuddin Amir Airport",
    elevation_ft: "56",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-ST",
    municipality: "Luwok - Celebes Island",
    gps_code: "WAMW",
    iata_code: "LUW",
    coordinates: "122.772003, -1.03892",
  },
  {
    ident: "WAMY",
    type: "small_airport",
    name: "Buol Airport",
    elevation_ft: "27",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-ST",
    municipality: "Buol-Celebes Island",
    gps_code: "WAMY",
    iata_code: "UOL",
    coordinates: "121.4141, 1.1027",
  },
  {
    ident: "WAN",
    type: "small_airport",
    name: "Waverney Airport",
    elevation_ft: "570",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Waverney",
    iata_code: "WAN",
    coordinates: "141.9254, -25.3563",
  },
  {
    ident: "WAOC",
    type: "small_airport",
    name: "Batu Licin Airport",
    elevation_ft: "3",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KS",
    municipality: "Batu Licin-Borneo Island",
    gps_code: "WAOC",
    iata_code: "BTW",
    coordinates: "115.995002747, -3.4124100208300003",
  },
  {
    ident: "WAOI",
    type: "small_airport",
    name: "Iskandar Airport",
    elevation_ft: "75",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KT",
    municipality: "Pangkalanbun-Borneo Island",
    gps_code: "WAGI",
    iata_code: "PKN",
    coordinates: "111.672997, -2.7052",
  },
  {
    ident: "WAOK",
    type: "small_airport",
    name: "Gusti Syamsir Alam Airport",
    elevation_ft: "4",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KS",
    municipality: "Stagen - Laut Island",
    gps_code: "WAOK",
    iata_code: "KBU",
    coordinates: "116.165001, -3.29472",
  },
  {
    ident: "WAON",
    type: "medium_airport",
    name: "Warukin Airport",
    elevation_ft: "197",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KS",
    municipality: "Tanta-Tabalong-Borneo Island",
    gps_code: "WAON",
    iata_code: "TJG",
    coordinates: "115.435997009, -2.21655988693",
  },
  {
    ident: "WAOO",
    type: "small_airport",
    name: "Syamsudin Noor Airport",
    elevation_ft: "66",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KS",
    municipality: "Banjarmasin-Borneo Island",
    gps_code: "WAOO",
    iata_code: "BDJ",
    coordinates: "114.76300048828125, -3.4423599243164062",
  },
  {
    ident: "WAOP",
    type: "small_airport",
    name: "Tjilik Riwut Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KT",
    municipality: "Palangkaraya-Kalimantan Tengah",
    gps_code: "WAOP",
    iata_code: "PKY",
    coordinates: "113.943000793, -2.22513008118",
  },
  {
    ident: "WAOS",
    type: "small_airport",
    name: "Sampit(Hasan) Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KT",
    municipality: "Sampit-Borneo Island",
    gps_code: "WAGS",
    iata_code: "SMQ",
    coordinates: "112.975555, -2.501389",
  },
  {
    ident: "WAPA",
    type: "small_airport",
    name: "Amahai Airport",
    elevation_ft: "29",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Amahai-Seram Island",
    gps_code: "WAPA",
    iata_code: "AHI",
    coordinates: "128.925994873, -3.34800004959",
  },
  {
    ident: "WAPC",
    type: "small_airport",
    name: "Bandanaira Airport",
    elevation_ft: "100",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Banda-Naira Island",
    gps_code: "WAPC",
    iata_code: "NDA",
    coordinates: "129.9054, -4.5214",
  },
  {
    ident: "WAPD",
    type: "small_airport",
    name: "Rar Gwamar Airport",
    elevation_ft: "61",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Dobo-Warmar Island",
    gps_code: "WAPD",
    iata_code: "DOB",
    coordinates: "134.212005615, -5.7722201347399995",
  },
  {
    ident: "WAPE",
    type: "small_airport",
    name: "Mangole Airport, Falabisahaya",
    elevation_ft: "889",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MU",
    municipality: "Mangole Island",
    gps_code: "WAPE",
    iata_code: "MAL",
    coordinates: "125.83000183105469, -1.875789999961853",
  },
  {
    ident: "WAPF",
    type: "medium_airport",
    name: "Karel Sadsuitubun Airport",
    elevation_ft: "78",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Langgur",
    gps_code: "WAPF",
    iata_code: "LUV",
    coordinates: "132.759444, -5.760278",
  },
  {
    ident: "WAPG",
    type: "small_airport",
    name: "Namrole Airport",
    elevation_ft: "31",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Namrole-Buru Island",
    gps_code: "WAPG",
    iata_code: "NRE",
    coordinates: "126.7012, -3.8548",
  },
  {
    ident: "WAPH",
    type: "small_airport",
    name: "Oesman Sadik Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MU",
    municipality: "Labuha-Halmahera Island",
    gps_code: "WAEL",
    iata_code: "LAH",
    coordinates: "127.501999, -0.635259",
  },
  {
    ident: "WAPI",
    type: "small_airport",
    name: "Saumlaki/Olilit Airport",
    elevation_ft: "218",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Saumlaki-Yamdena Island",
    gps_code: "WAPI",
    iata_code: "SXK",
    coordinates: "131.305999756, -7.9886097908",
  },
  {
    ident: "WAPK",
    type: "small_airport",
    name: "Nangasuri Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Maikoor Island",
    gps_code: "WAPK",
    iata_code: "BJK",
    coordinates: "134.2740020751953, -6.066199779510498",
  },
  {
    ident: "WAPN",
    type: "small_airport",
    name: "Emalamo Sanana Airport",
    elevation_ft: "39",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MU",
    municipality: "Sanana-Seram Island",
    gps_code: "WAPN",
    iata_code: "SQN",
    coordinates: "125.96700286865234, -2.080509901046753",
  },
  {
    ident: "WAPP",
    type: "medium_airport",
    name: "Pattimura Airport, Ambon",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Ambon",
    gps_code: "WAPP",
    iata_code: "AMQ",
    coordinates: "128.089004517, -3.7102599144",
  },
  {
    ident: "WAPR",
    type: "small_airport",
    name: "Namlea Airport",
    elevation_ft: "41",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Namlea-Buru Island",
    gps_code: "WAPR",
    iata_code: "NAM",
    coordinates: "127.0999984741211, -3.235569953918457",
  },
  {
    ident: "WAPT",
    type: "small_airport",
    name: "Taliabu Island Airport",
    elevation_ft: "9",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MU",
    municipality: "Tikong-Taliabu Island",
    gps_code: "WAPT",
    iata_code: "TAX",
    coordinates: "124.55899810791016, -1.6426299810409546",
  },
  {
    ident: "WAPV",
    type: "small_airport",
    name: "Wahai,Seram Island",
    elevation_ft: "558",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-MA",
    municipality: "Seram Island",
    gps_code: "WAPV",
    iata_code: "WBA",
    coordinates: "129.484, -2.8114",
  },
  {
    ident: "WARA",
    type: "small_airport",
    name: "Abdul Rachman Saleh Airport",
    elevation_ft: "1726",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JI",
    municipality: "Malang-Java Island",
    gps_code: "WARA",
    iata_code: "MLG",
    coordinates: "112.714996338, -7.926559925079999",
  },
  {
    ident: "WARC",
    type: "small_airport",
    name: "Ngloram Airport",
    elevation_ft: "131",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JT",
    municipality: "Tjepu-Java Island",
    gps_code: "WARC",
    iata_code: "CPF",
    coordinates: "111.548166667, -7.19484166667",
  },
  {
    ident: "WARJ",
    type: "small_airport",
    name: "Adisutjipto International Airport",
    elevation_ft: "379",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-YO",
    municipality: "Yogyakarta-Java Island",
    gps_code: "WAHH",
    iata_code: "JOG",
    coordinates: "110.431999, -7.78818",
  },
  {
    ident: "WARQ",
    type: "small_airport",
    name: "Adi Sumarmo Wiryokusumo Airport",
    elevation_ft: "421",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JT",
    municipality: "Sukarata(Solo)-Java Island",
    gps_code: "WAHQ",
    iata_code: "SOC",
    coordinates: "110.750494, -7.513564",
  },
  {
    ident: "WARR",
    type: "large_airport",
    name: "Juanda International Airport",
    elevation_ft: "9",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JI",
    municipality: "Surabaya",
    gps_code: "WARR",
    iata_code: "SUB",
    coordinates: "112.78700256347656, -7.3798298835754395",
  },
  {
    ident: "WARS",
    type: "small_airport",
    name: "Achmad Yani Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JT",
    municipality: "Semarang-Java Island",
    gps_code: "WAHS",
    iata_code: "SRG",
    coordinates: "110.375, -6.97273",
  },
  {
    ident: "WART",
    type: "small_airport",
    name: "Trunojoyo Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JI",
    municipality: "Sumenep-Madura Island",
    gps_code: "WART",
    iata_code: "SUP",
    local_code: "TRJ",
    coordinates: "113.89023, -7.0242",
  },
  {
    ident: "WARU",
    type: "small_airport",
    name: "Dewadaru - Kemujan Island",
    elevation_ft: "35",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JT",
    municipality: "Karimunjawa",
    gps_code: "WARU",
    iata_code: "KWB",
    coordinates: "110.47838, -5.80091",
  },
  {
    ident: "WASB",
    type: "small_airport",
    name: "Stenkol Airport",
    elevation_ft: "57",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Bintuni",
    gps_code: "WASB",
    iata_code: "NTI",
    coordinates: "133.5164, -2.1033",
  },
  {
    ident: "WASC",
    type: "small_airport",
    name: "Abresso Airport",
    elevation_ft: "52",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Ransiki-Papua Island",
    gps_code: "WASC",
    iata_code: "RSK",
    coordinates: "134.175, -1.496771",
  },
  {
    ident: "WASE",
    type: "small_airport",
    name: "Kebar Airport",
    elevation_ft: "2050",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Kebar-Papua Island",
    gps_code: "WASE",
    iata_code: "KEQ",
    coordinates: "133.1280059814453, -0.6371009945869446",
  },
  {
    ident: "WASF",
    type: "medium_airport",
    name: "Fakfak Airport",
    elevation_ft: "462",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Fakfak-Papua Island",
    gps_code: "WASF",
    iata_code: "FKQ",
    coordinates: "132.26699829101562, -2.9201900959014893",
  },
  {
    ident: "WASI",
    type: "small_airport",
    name: "Inanwatan Airport",
    elevation_ft: "9",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Inanwatan Airport-Papua Island",
    gps_code: "WASI",
    iata_code: "INX",
    coordinates: "132.16099548339844, -2.1280999183654785",
  },
  {
    ident: "WASK",
    type: "medium_airport",
    name: "Kaimana Airport",
    elevation_ft: "19",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Kaimana-Papua Island",
    gps_code: "WASK",
    iata_code: "KNG",
    coordinates: "133.6959991455078, -3.6445200443267822",
  },
  {
    ident: "WASM",
    type: "small_airport",
    name: "Merdei Airport",
    elevation_ft: "425",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Merdei-Papua Island",
    gps_code: "WASM",
    iata_code: "RDE",
    coordinates: "133.33299255371094, -1.5833300352096558",
  },
  {
    ident: "WASN",
    type: "small_airport",
    name: "Marinda Airport",
    elevation_ft: "9",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Waisai",
    gps_code: "WASN",
    iata_code: "RJM",
    coordinates: "130.773333, -0.423056",
  },
  {
    ident: "WASO",
    type: "medium_airport",
    name: "Babo Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Babo-Papua Island",
    gps_code: "WASO",
    iata_code: "BXB",
    coordinates: "133.43899536132812, -2.5322399139404297",
  },
  {
    ident: "WASR",
    type: "medium_airport",
    name: "Rendani Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Manokwari-Papua Island",
    gps_code: "WAUU",
    iata_code: "MKW",
    coordinates: "134.048996, -0.891833",
  },
  {
    ident: "WAST",
    type: "small_airport",
    name: "Teminabuan Airport",
    elevation_ft: "141",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Atinjoe-Papua Island",
    gps_code: "WAST",
    iata_code: "TXM",
    coordinates: "132.02099609375, -1.4447200298309326",
  },
  {
    ident: "WASW",
    type: "small_airport",
    name: "Wasior Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Wasior-Papua Island",
    gps_code: "WASW",
    iata_code: "WSR",
    coordinates: "134.5061, -2.721",
  },
  {
    ident: "WATB",
    type: "small_airport",
    name: "Bajawa Soa Airport",
    elevation_ft: "4326",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Bajawa",
    gps_code: "WATB",
    iata_code: "BJW",
    coordinates: "121.057426929, -8.70743498008",
  },
  {
    ident: "WATC",
    type: "small_airport",
    name: "Maumere(Wai Oti) Airport",
    elevation_ft: "115",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Maumere-Flores Island",
    gps_code: "WATC",
    iata_code: "MOF",
    coordinates: "122.236999512, -8.64064979553",
  },
  {
    ident: "WATE",
    type: "small_airport",
    name: "Ende (H Hasan Aroeboesman) Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Ende-Flores Island",
    gps_code: "WATE",
    iata_code: "ENE",
    coordinates: "121.661003113, -8.8492898941",
  },
  {
    ident: "WATG",
    type: "small_airport",
    name: "Frans Sales Lega Airport",
    elevation_ft: "3510",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Satar Tacik-Flores Island",
    gps_code: "WATG",
    iata_code: "RTG",
    coordinates: "120.47699737549, -8.5970096588135",
  },
  {
    ident: "WATM",
    type: "small_airport",
    name: "Mali Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Alor Island",
    gps_code: "WATM",
    iata_code: "ARD",
    coordinates: "124.59700012207031, -8.132340431213379",
  },
  {
    ident: "WATO",
    type: "small_airport",
    name: "Komodo Airport",
    elevation_ft: "66",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Labuan Bajo - Flores Island",
    gps_code: "WATO",
    iata_code: "LBJ",
    coordinates: "119.889, -8.48666",
  },
  {
    ident: "WATS",
    type: "small_airport",
    name: "Sabu-Tardanu Airport",
    elevation_ft: "71",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Sabu-Sawu Island",
    gps_code: "WATS",
    iata_code: "SAU",
    coordinates: "121.8482, -10.4924",
  },
  {
    ident: "WATT",
    type: "small_airport",
    name: "El Tari Airport",
    elevation_ft: "335",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Kupang-Timor Island",
    gps_code: "WATT",
    iata_code: "KOE",
    coordinates: "123.6709976196289, -10.171600341796875",
  },
  {
    ident: "WAWB",
    type: "small_airport",
    name: "Betoambari Airport",
    elevation_ft: "164",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SG",
    municipality: "Bau Bau-Butung Island",
    gps_code: "WAWB",
    iata_code: "BUW",
    coordinates: "122.56900024414062, -5.486879825592041",
  },
  {
    ident: "WAWD",
    type: "medium_airport",
    name: "Matahora Airport",
    elevation_ft: "88",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SG",
    municipality: "Wangi-wangi Island",
    gps_code: "WAWD",
    iata_code: "WNI",
    coordinates: "123.634, -5.293996",
  },
  {
    ident: "WAWH",
    type: "small_airport",
    name: "Selayar/Aroepala Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SN",
    municipality: "Benteng",
    gps_code: "WAWH",
    iata_code: "KSR",
    coordinates: "120.4362, -6.1751",
  },
  {
    ident: "WAWM",
    type: "small_airport",
    name: "Andi Jemma Airport",
    elevation_ft: "164",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SN",
    municipality: "Masamba-Celebes Island",
    gps_code: "WAAM",
    iata_code: "MXB",
    coordinates: "120.323997, -2.55803",
  },
  {
    ident: "WAWS",
    type: "small_airport",
    name: "Soroako Airport",
    elevation_ft: "1388",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SN",
    municipality: "Soroako-Celebes Island",
    gps_code: "WAWS",
    iata_code: "SQR",
    coordinates: "121.35800170898438, -2.5311999320983887",
  },
  {
    ident: "WAWT",
    type: "small_airport",
    name: "Pongtiku Airport",
    elevation_ft: "2884",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SN",
    municipality: "Makale",
    gps_code: "WAWT",
    iata_code: "TTR",
    coordinates: "119.82199859619, -3.0447399616241",
  },
  {
    ident: "WAWW",
    type: "small_airport",
    name: "Wolter Monginsidi Airport",
    elevation_ft: "538",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SG",
    municipality: "Kendari-Celebes Island",
    gps_code: "WAWW",
    iata_code: "KDI",
    coordinates: "122.41799926757812, -4.081610202789307",
  },
  {
    ident: "WAXX",
    type: "large_airport",
    name: "Dominique Edward Osok Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-PB",
    municipality: "Sorong-Papua Island",
    gps_code: "WASS",
    iata_code: "SOQ",
    coordinates: "131.287, -0.894",
  },
  {
    ident: "WBB",
    type: "small_airport",
    name: "Stebbins Airport",
    elevation_ft: "14",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Stebbins",
    gps_code: "WBB",
    iata_code: "WBB",
    local_code: "WBB",
    coordinates: "-162.277999878, 63.5159988403",
  },
  {
    ident: "WBC",
    type: "small_airport",
    name: "Wapolu Airport",
    elevation_ft: "45",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Wapolu",
    gps_code: "AYWJ",
    iata_code: "WBC",
    local_code: "WPL",
    coordinates: "150.5093, -9.3376",
  },
  {
    ident: "WBGB",
    type: "medium_airport",
    name: "Bintulu Airport",
    elevation_ft: "74",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Bintulu",
    gps_code: "WBGB",
    iata_code: "BTU",
    coordinates: "113.019996643, 3.12385010719",
  },
  {
    ident: "WBGC",
    type: "small_airport",
    name: "Belaga Airport",
    elevation_ft: "200",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Belaga",
    gps_code: "WBGC",
    iata_code: "BLG",
    coordinates: "113.766998291, 2.65000009537",
  },
  {
    ident: "WBGD",
    type: "small_airport",
    name: "Long Semado Airport",
    elevation_ft: "2150",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Long Semado",
    gps_code: "WBGD",
    iata_code: "LSM",
    coordinates: "115.599998474, 4.21700000763",
  },
  {
    ident: "WBGF",
    type: "small_airport",
    name: "Long Lellang Airport",
    elevation_ft: "1400",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Long Datih",
    gps_code: "WBGF",
    iata_code: "LGL",
    coordinates: "115.153999329, 3.4210000038099997",
  },
  {
    ident: "WBGG",
    type: "medium_airport",
    name: "Kuching International Airport",
    elevation_ft: "89",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Kuching",
    gps_code: "WBGG",
    iata_code: "KCH",
    coordinates: "110.34700012207031, 1.4846999645233154",
  },
  {
    ident: "WBGI",
    type: "small_airport",
    name: "Long Seridan Airport",
    elevation_ft: "607",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Long Seridan",
    gps_code: "WBGI",
    iata_code: "ODN",
    coordinates: "115.05000305175781, 3.9670000076293945",
  },
  {
    ident: "WBGJ",
    type: "medium_airport",
    name: "Limbang Airport",
    elevation_ft: "14",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Limbang",
    gps_code: "WBGJ",
    iata_code: "LMN",
    coordinates: "115.01000213623047, 4.808300018310547",
  },
  {
    ident: "WBGK",
    type: "small_airport",
    name: "Mukah Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Mukah",
    gps_code: "WBGK",
    iata_code: "MKM",
    coordinates: "112.080002, 2.90639",
  },
  {
    ident: "WBGL",
    type: "small_airport",
    name: "Long Akah Airport",
    elevation_ft: "289",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Long Akah",
    gps_code: "WBGL",
    iata_code: "LKH",
    coordinates: "114.78299713134766, 3.299999952316284",
  },
  {
    ident: "WBGM",
    type: "medium_airport",
    name: "Marudi Airport",
    elevation_ft: "103",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Marudi",
    gps_code: "WBGM",
    iata_code: "MUR",
    coordinates: "114.3290023803711, 4.178979873657227",
  },
  {
    ident: "WBGN",
    type: "small_airport",
    name: "Sematan Airport",
    elevation_ft: "22",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Sematan",
    gps_code: "WBGN",
    iata_code: "BSE",
    coordinates: "109.76300048828125, 1.8136099576950073",
  },
  {
    ident: "WBGP",
    type: "small_airport",
    name: "Kapit Airport",
    elevation_ft: "65",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Kapit",
    gps_code: "WBGP",
    iata_code: "KPI",
    coordinates: "112.931468248, 2.01052374505",
  },
  {
    ident: "WBGQ",
    type: "small_airport",
    name: "Bakalalan Airport",
    elevation_ft: "2900",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Bakalalan",
    gps_code: "WBGQ",
    iata_code: "BKM",
    coordinates: "115.61799621582031, 3.9739999771118164",
  },
  {
    ident: "WBGR",
    type: "medium_airport",
    name: "Miri Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Miri",
    gps_code: "WBGR",
    iata_code: "MYY",
    coordinates: "113.98699951171875, 4.322010040283203",
  },
  {
    ident: "WBGS",
    type: "medium_airport",
    name: "Sibu Airport",
    elevation_ft: "122",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Sibu",
    gps_code: "WBGS",
    iata_code: "SBW",
    coordinates: "111.98500061035156, 2.2616000175476074",
  },
  {
    ident: "WBGT",
    type: "small_airport",
    name: "Tanjung Manis Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Tanjung Manis",
    gps_code: "WBGT",
    iata_code: "TGC",
    coordinates: "111.2020034790039, 2.177839994430542",
  },
  {
    ident: "WBGU",
    type: "small_airport",
    name: "Long Sukang Airport",
    elevation_ft: "1200",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Long Sukang",
    gps_code: "WBGU",
    iata_code: "LSU",
    coordinates: "115.49400329589844, 4.552219867706299",
  },
  {
    ident: "WBGW",
    type: "small_airport",
    name: "Lawas Airport",
    elevation_ft: "5",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Lawas",
    gps_code: "WBGW",
    iata_code: "LWY",
    coordinates: "115.407997, 4.84917",
  },
  {
    ident: "WBGY",
    type: "closed",
    name: "Simanggang Airport",
    elevation_ft: "47",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Sri Aman",
    gps_code: "WBGY",
    iata_code: "SGG",
    coordinates: "111.4636, 1.2219",
  },
  {
    ident: "WBGZ",
    type: "medium_airport",
    name: "Bario Airport",
    elevation_ft: "3350",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Bario",
    gps_code: "WBGZ",
    iata_code: "BBN",
    coordinates: "115.47899627685547, 3.7338900566101074",
  },
  {
    ident: "WBKA",
    type: "small_airport",
    name: "Semporna Airport",
    elevation_ft: "60",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Semporna",
    gps_code: "WBKA",
    iata_code: "SMM",
    coordinates: "118.58300018310547, 4.449999809265137",
  },
  {
    ident: "WBKD",
    type: "medium_airport",
    name: "Lahad Datu Airport",
    elevation_ft: "45",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Lahad Datu",
    gps_code: "WBKD",
    iata_code: "LDU",
    coordinates: "118.3239974975586, 5.032249927520752",
  },
  {
    ident: "WBKE",
    type: "small_airport",
    name: "Telupid Airport",
    elevation_ft: "343",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Telupid",
    gps_code: "WBKE",
    iata_code: "TEL",
    coordinates: "117.1259994506836, 5.628610134124756",
  },
  {
    ident: "WBKG",
    type: "small_airport",
    name: "Keningau Airport",
    elevation_ft: "1036",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Keningau",
    gps_code: "WBKG",
    iata_code: "KGU",
    coordinates: "116.16200256347656, 5.357490062713623",
  },
  {
    ident: "WBKH",
    type: "small_airport",
    name: "Sahabat [Sahabat 16] Airport",
    elevation_ft: "40",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Sahabat",
    gps_code: "WBKH",
    iata_code: "SXS",
    coordinates: "119.09400177001953, 5.087779998779297",
  },
  {
    ident: "WBKK",
    type: "medium_airport",
    name: "Kota Kinabalu International Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Kota Kinabalu",
    gps_code: "WBKK",
    iata_code: "BKI",
    coordinates: "116.0510025024414, 5.9372100830078125",
  },
  {
    ident: "WBKL",
    type: "medium_airport",
    name: "Labuan Airport",
    elevation_ft: "101",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-15",
    municipality: "Labuan",
    gps_code: "WBKL",
    iata_code: "LBU",
    coordinates: "115.25, 5.300680160522461",
  },
  {
    ident: "WBKM",
    type: "small_airport",
    name: "Tomanggong Airport",
    elevation_ft: "26",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Tomanggong",
    gps_code: "WBKM",
    iata_code: "TMG",
    coordinates: "118.65763, 5.40257",
  },
  {
    ident: "WBKN",
    type: "small_airport",
    name: "Long Pasia Airport",
    elevation_ft: "3175",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Long Miau",
    gps_code: "WBKN",
    iata_code: "GSA",
    coordinates: "115.71700286865234, 4.400000095367432",
  },
  {
    ident: "WBKO",
    type: "small_airport",
    name: "Sepulot Airport",
    elevation_ft: "800",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Sepulot",
    gps_code: "WBKO",
    iata_code: "SPE",
    coordinates: "116.46700286865234, 4.732999801635742",
  },
  {
    ident: "WBKP",
    type: "small_airport",
    name: "Pamol Airport",
    elevation_ft: "36",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Pamol",
    gps_code: "WBKP",
    iata_code: "PAY",
    coordinates: "117.39991760253906, 5.999300003051758",
  },
  {
    ident: "WBKR",
    type: "small_airport",
    name: "Ranau Airport",
    elevation_ft: "1800",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Ranau",
    gps_code: "WBKR",
    iata_code: "RNU",
    coordinates: "116.66699981689453, 5.949999809265137",
  },
  {
    ident: "WBKS",
    type: "medium_airport",
    name: "Sandakan Airport",
    elevation_ft: "46",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Sandakan",
    gps_code: "WBKS",
    iata_code: "SDK",
    coordinates: "118.05899810791016, 5.900899887084961",
  },
  {
    ident: "WBKT",
    type: "small_airport",
    name: "Kudat Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Kudat",
    gps_code: "WBKT",
    iata_code: "KUD",
    coordinates: "116.83599853515625, 6.922500133514404",
  },
  {
    ident: "WBKW",
    type: "medium_airport",
    name: "Tawau Airport",
    elevation_ft: "57",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-12",
    municipality: "Tawau",
    gps_code: "WBKW",
    iata_code: "TWU",
    coordinates: "118.12799835205078, 4.320159912109375",
  },
  {
    ident: "WBMU",
    type: "medium_airport",
    name: "Mulu Airport",
    elevation_ft: "80",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-13",
    municipality: "Mulu",
    gps_code: "WBMU",
    iata_code: "MZV",
    coordinates: "114.80500030517578, 4.048329830169678",
  },
  {
    ident: "WBSB",
    type: "large_airport",
    name: "Brunei International Airport",
    elevation_ft: "73",
    continent: "AS",
    iso_country: "BN",
    iso_region: "BN-BM",
    municipality: "Bandar Seri Begawan",
    gps_code: "WBSB",
    iata_code: "BWN",
    coordinates: "114.9280014038086, 4.944200038909912",
  },
  {
    ident: "WEA",
    type: "small_airport",
    name: "Parker County Airport",
    elevation_ft: "990",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-TX",
    municipality: "Weatherford",
    gps_code: "KWEA",
    iata_code: "WEA",
    local_code: "WEA",
    coordinates: "-97.6824035645, 32.7462997437",
  },
  {
    ident: "WED",
    type: "small_airport",
    name: "Wedau Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MBA",
    municipality: "Wedau",
    iata_code: "WED",
    local_code: "WDU",
    coordinates: "150.0826, -10.0954",
  },
  {
    ident: "WGU",
    type: "closed",
    name: "Wagau Airport",
    elevation_ft: "3853",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Wagau",
    iata_code: "WGU",
    coordinates: "146.8022, -6.8519",
  },
  {
    ident: "WGY",
    type: "closed",
    name: "Wagny Airport",
    elevation_ft: "1025",
    continent: "AF",
    iso_country: "GA",
    iso_region: "GA-7",
    municipality: "Wagny",
    iata_code: "WGY",
    coordinates: "12.2608, -0.6035",
  },
  {
    ident: "WHL",
    type: "small_airport",
    name: "Welshpool Airport",
    elevation_ft: "35",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Welshpool",
    iata_code: "WHL",
    coordinates: "146.445331, -38.682382",
  },
  {
    ident: "WI1A",
    type: "small_airport",
    name: "Nusawiru Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JB",
    municipality: "Cijulang",
    gps_code: "WICN",
    iata_code: "CJN",
    local_code: "WI1A",
    coordinates: "108.488995, -7.719895",
  },
  {
    ident: "WIBB",
    type: "medium_airport",
    name: "Sultan Syarif Kasim Ii (Simpang Tiga) Airport",
    elevation_ft: "102",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Pekanbaru-Sumatra Island",
    gps_code: "WIBB",
    iata_code: "PKU",
    coordinates: "101.44499969482422, 0.46078601479530334",
  },
  {
    ident: "WIBD",
    type: "medium_airport",
    name: "Pinang Kampai Airport",
    elevation_ft: "55",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Dumai-Sumatra Island",
    gps_code: "WIBD",
    iata_code: "DUM",
    coordinates: "101.433998, 1.60919",
  },
  {
    ident: "WIBR",
    type: "small_airport",
    name: "Rokot Airport",
    elevation_ft: "1",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SB",
    municipality: "Sipora Island",
    gps_code: "WIBR",
    iata_code: "RKO",
    coordinates: "99.70069885253906, -2.0991199016571045",
  },
  {
    ident: "WIBS",
    type: "small_airport",
    name: "Sungai Pakning Bengkalis Airport",
    elevation_ft: "3",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Bengkalis-Sumatra Island",
    gps_code: "WIBS",
    iata_code: "SEQ",
    coordinates: "102.13999938964844, 1.3700000047683716",
  },
  {
    ident: "WIBT",
    type: "small_airport",
    name: "Sei Bati Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Tanjung Balai-Karinmunbesar Island",
    gps_code: "WIBT",
    iata_code: "TJB",
    coordinates: "103.3931, 1.0527",
  },
  {
    ident: "WICA",
    type: "medium_airport",
    name: "Kertajati International Airport",
    elevation_ft: "134",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JB",
    municipality: "Kertajati",
    gps_code: "WICA",
    iata_code: "KJT",
    coordinates: "108.166692, -6.648924",
  },
  {
    ident: "WICC",
    type: "medium_airport",
    name: "Husein Sastranegara International Airport",
    elevation_ft: "2436",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JB",
    municipality: "Bandung-Java Island",
    gps_code: "WICC",
    iata_code: "BDO",
    coordinates: "107.57599639892578, -6.900629997253418",
  },
  {
    ident: "WICD",
    type: "small_airport",
    name: "Penggung Airport",
    elevation_ft: "89",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JB",
    municipality: "Cirebon-Java Island",
    gps_code: "WICD",
    iata_code: "CBN",
    local_code: "WIIC",
    coordinates: "108.540000916, -6.756140232090001",
  },
  {
    ident: "WICM",
    type: "small_airport",
    name: "Cibeureum Airport",
    elevation_ft: "1148",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JB",
    municipality: "Tasikmalaya-Java Island",
    gps_code: "WICM",
    iata_code: "TSY",
    coordinates: "108.246002197, -7.346600055690001",
  },
  {
    ident: "WID",
    type: "closed",
    name: "RAF Wildenrath",
    elevation_ft: "285",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-NW",
    municipality: "British Armed Forces",
    iata_code: "WID",
    coordinates: "6.2151, 51.1141",
  },
  {
    ident: "WIDD",
    type: "medium_airport",
    name: "Hang Nadim International Airport",
    elevation_ft: "126",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Batam Island",
    gps_code: "WIDD",
    iata_code: "BTH",
    coordinates: "104.119003296, 1.12102997303",
  },
  {
    ident: "WIDE",
    type: "small_airport",
    name: "Pasir Pangaraan Airport",
    elevation_ft: "151",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Pasir Pengarayan-Sumatra Island",
    gps_code: "WIDE",
    iata_code: "PPR",
    coordinates: "100.37000274658203, 0.8454310297966003",
  },
  {
    ident: "WIDL",
    type: "small_airport",
    name: "Letung Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Bukit Padi",
    gps_code: "WIDL",
    iata_code: "LMU",
    coordinates: "105.754444, 2.964167",
  },
  {
    ident: "WIDN",
    type: "small_airport",
    name: "Raja Haji Fisabilillah International Airport",
    elevation_ft: "52",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Tanjung Pinang-Bintan Island",
    gps_code: "WIDN",
    iata_code: "TNJ",
    coordinates: "104.531997681, 0.922683000565",
  },
  {
    ident: "WIDS",
    type: "small_airport",
    name: "Dabo Airport",
    elevation_ft: "95",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Pasirkuning-Singkep Island",
    gps_code: "WIDS",
    iata_code: "SIQ",
    coordinates: "104.5790023803711, -0.47918900847435",
  },
  {
    ident: "WIHH",
    type: "medium_airport",
    name: "Halim Perdanakusuma International Airport",
    elevation_ft: "84",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JK",
    municipality: "Jakarta",
    gps_code: "WIHH",
    iata_code: "HLP",
    coordinates: "106.89099884033203, -6.266610145568848",
  },
  {
    ident: "WIHL",
    type: "medium_airport",
    name: "Tunggul Wulung Airport",
    elevation_ft: "69",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JT",
    municipality: "Cilacap-Java Island",
    gps_code: "WIHL",
    iata_code: "CXP",
    coordinates: "109.033996582, -7.645060062410001",
  },
  {
    ident: "WIHP",
    type: "small_airport",
    name: "Pondok Cabe Air Base",
    elevation_ft: "200",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JK",
    municipality: "Jakarta",
    gps_code: "WIHP",
    iata_code: "PCB",
    coordinates: "106.76499938964844, -6.3369598388671875",
  },
  {
    ident: "WIII",
    type: "large_airport",
    name: "Soekarno-Hatta International Airport",
    elevation_ft: "34",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-BT",
    municipality: "Jakarta",
    gps_code: "WIII",
    iata_code: "CGK",
    coordinates: "106.65599823, -6.1255698204",
  },
  {
    ident: "WIKL",
    type: "small_airport",
    name: "Silampari Airport",
    elevation_ft: "371",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SS",
    municipality: "Lubuk Linggau-Sumatra Island",
    gps_code: "WIKL",
    iata_code: "LLJ",
    local_code: "LLG",
    coordinates: "102.901001, -3.27775",
  },
  {
    ident: "WILL",
    type: "medium_airport",
    name: "Radin Inten II (Branti) Airport",
    elevation_ft: "282",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-LA",
    municipality: "Bandar Lampung-Sumatra Island",
    gps_code: "WILL",
    iata_code: "TKG",
    coordinates: "105.175556, -5.240556",
  },
  {
    ident: "WILP",
    type: "small_airport",
    name: "Muhammad Taufiq Kiemas Airport",
    elevation_ft: "118",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-LA",
    municipality: "Krui",
    gps_code: "WILP",
    iata_code: "TFY",
    coordinates: "103.936501, -5.211562",
  },
  {
    ident: "WIMB",
    type: "medium_airport",
    name: "Binaka Airport",
    elevation_ft: "20",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SU",
    municipality: "Gunung Sitoli-Nias Island",
    gps_code: "WIMB",
    iata_code: "GNS",
    coordinates: "97.704697, 1.16638",
  },
  {
    ident: "WIME",
    type: "medium_airport",
    name: "Aek Godang Airport",
    elevation_ft: "922",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SU",
    municipality: "Padang Sidempuan-Sumatra Island",
    gps_code: "WIME",
    iata_code: "AEG",
    coordinates: "99.430496, 1.4001",
  },
  {
    ident: "WIMK",
    type: "medium_airport",
    name: "Soewondo Air Force Base",
    elevation_ft: "114",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SU",
    municipality: "Medan-Sumatra Island",
    gps_code: "WIMK",
    iata_code: "MES",
    coordinates: "98.671111, 3.559167",
  },
  {
    ident: "WIMM",
    type: "large_airport",
    name: "Kualanamu International Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SU",
    gps_code: "WIMM",
    iata_code: "KNO",
    coordinates: "98.885278, 3.642222",
  },
  {
    ident: "WIMN",
    type: "small_airport",
    name: "Silangit Airport",
    elevation_ft: "4700",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SU",
    municipality: "Siborong-Borong",
    gps_code: "WIMN",
    iata_code: "DTB",
    coordinates: "98.991898, 2.25973",
  },
  {
    ident: "WIMP",
    type: "small_airport",
    name: "Sibisa Airport",
    elevation_ft: "3081",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SU",
    municipality: "Parapat-Sumatra Island",
    gps_code: "WIMP",
    iata_code: "SIW",
    coordinates: "98.933334350586, 2.6666669845581",
  },
  {
    ident: "WIMS",
    type: "small_airport",
    name: "Dr Ferdinand Lumban Tobing Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SU",
    municipality: "Sibolga-Sumatra Island",
    gps_code: "WIMS",
    iata_code: "FLZ",
    coordinates: "98.888901, 1.55594",
  },
  {
    ident: "WIOD",
    type: "small_airport",
    name: "H.AS. Hanandjoeddin International Airport",
    elevation_ft: "164",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-BB",
    municipality: "Tanjung Pandan-Belitung Island",
    gps_code: "WIKT",
    iata_code: "TJQ",
    coordinates: "107.754997, -2.74572",
  },
  {
    ident: "WIOG",
    type: "medium_airport",
    name: "Nanga Pinoh Airport",
    elevation_ft: "123",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KB",
    municipality: "Nanga Pinoh-Borneo Island",
    gps_code: "WIOG",
    iata_code: "NPO",
    coordinates: "111.74800109863, -0.34886899590492",
  },
  {
    ident: "WIOK",
    type: "medium_airport",
    name: "Ketapang(Rahadi Usman) Airport",
    elevation_ft: "46",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KB",
    municipality: "Ketapang-Borneo Island",
    gps_code: "WIOK",
    iata_code: "KTG",
    coordinates: "109.96299743652344, -1.816640019416809",
  },
  {
    ident: "WIOM",
    type: "small_airport",
    name: "Tarempa Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Matak Island",
    gps_code: "WIOM",
    iata_code: "MWK",
    coordinates: "106.25800323486328, 3.3481199741363525",
  },
  {
    ident: "WION",
    type: "medium_airport",
    name: "Ranai Airport",
    elevation_ft: "7",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Ranai-Natuna Besar Island",
    gps_code: "WIDO",
    iata_code: "NTX",
    coordinates: "108.388, 3.90871",
  },
  {
    ident: "WIOO",
    type: "medium_airport",
    name: "Supadio Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KB",
    municipality: "Pontianak-Borneo Island",
    gps_code: "WIOO",
    iata_code: "PNK",
    coordinates: "109.40399932861328, -0.15071099996566772",
  },
  {
    ident: "WIOP",
    type: "medium_airport",
    name: "Pangsuma Airport",
    elevation_ft: "297",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KB",
    municipality: "Putussibau-Borneo Island",
    gps_code: "WIOP",
    iata_code: "PSU",
    coordinates: "112.93699645996094, 0.8355780243873596",
  },
  {
    ident: "WIOS",
    type: "medium_airport",
    name: "Sintang(Susilo) Airport",
    elevation_ft: "98",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KB",
    municipality: "Sintang-Borneo Island",
    gps_code: "WIOS",
    iata_code: "SQG",
    coordinates: "111.4729995727539, 0.06361900269985199",
  },
  {
    ident: "WIPA",
    type: "small_airport",
    name: "Sultan Thaha Airport",
    elevation_ft: "82",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JA",
    municipality: "Jambi-Sumatra Island",
    gps_code: "WIJJ",
    iata_code: "DJB",
    coordinates: "103.643997, -1.63802",
  },
  {
    ident: "WIPI",
    type: "small_airport",
    name: "Muara Bungo Airport",
    elevation_ft: "214",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JA",
    municipality: "Muara Bungo",
    gps_code: "WIPI",
    iata_code: "BUU",
    local_code: "MRBMWS",
    coordinates: "102.135, -1.1278",
  },
  {
    ident: "WIPK",
    type: "small_airport",
    name: "Pangkal Pinang (Depati Amir) Airport",
    elevation_ft: "109",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-BB",
    municipality: "Pangkal Pinang-Palaubangka Island",
    gps_code: "WIPK",
    iata_code: "PGK",
    coordinates: "106.138999939, -2.16219997406",
  },
  {
    ident: "WIPL",
    type: "medium_airport",
    name: "Fatmawati Soekarno Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-BE",
    municipality: "Bengkulu-Sumatra Island",
    gps_code: "WIGG",
    iata_code: "BKS",
    coordinates: "102.338997, -3.8637",
  },
  {
    ident: "WIPP",
    type: "medium_airport",
    name: "Sultan Mahmud Badaruddin II Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SS",
    municipality: "Palembang-Sumatra Island",
    gps_code: "WIPP",
    iata_code: "PLM",
    coordinates: "104.69999694824, -2.8982501029968",
  },
  {
    ident: "WIPQ",
    type: "medium_airport",
    name: "Pendopo Airport",
    elevation_ft: "184",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SS",
    municipality: "Talang Gudang-Sumatra Island",
    gps_code: "WIPQ",
    iata_code: "PDO",
    coordinates: "103.87999725341797, -3.2860701084136963",
  },
  {
    ident: "WIPR",
    type: "medium_airport",
    name: "Japura Airport",
    elevation_ft: "62",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-RI",
    municipality: "Rengat-Sumatra Island",
    gps_code: "WIPR",
    iata_code: "RGT",
    coordinates: "102.33499908447266, -0.35280799865722656",
  },
  {
    ident: "WIPT",
    type: "medium_airport",
    name: "Minangkabau International Airport",
    elevation_ft: "18",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SB",
    municipality: "Ketaping/Padang - Sumatra Island",
    gps_code: "WIEE",
    iata_code: "PDG",
    coordinates: "100.280998, -0.786917",
  },
  {
    ident: "WIPU",
    type: "small_airport",
    name: "Muko Muko Airport",
    elevation_ft: "24",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-JA",
    municipality: "Muko Muko-Sumatra Island",
    gps_code: "WIPU",
    iata_code: "MPC",
    coordinates: "101.08799743652344, -2.5418601036071777",
  },
  {
    ident: "WIPV",
    type: "small_airport",
    name: "Keluang Airport",
    elevation_ft: "76",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SS",
    municipality: "Keluang-Sumatra Island",
    gps_code: "WIPV",
    iata_code: "KLQ",
    coordinates: "103.95500183105469, -2.6235299110412598",
  },
  {
    ident: "WIPY",
    type: "small_airport",
    name: "Bentayan Airport",
    elevation_ft: "40",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-SS",
    municipality: "Bentayan-Sumatra Island",
    gps_code: "WIPY",
    iata_code: "PXA",
    coordinates: "104.092003, -2.43443",
  },
  {
    ident: "WITA",
    type: "small_airport",
    name: "Teuku Cut Ali Airport",
    elevation_ft: "17",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-AC",
    municipality: "Tapak Tuan-Sumatra Island",
    gps_code: "WITA",
    iata_code: "TPK",
    coordinates: "97.2869, 3.1707",
  },
  {
    ident: "WITB",
    type: "small_airport",
    name: "Maimun Saleh Airport",
    elevation_ft: "393",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-AC",
    municipality: "Sabang-We Island",
    gps_code: "WITN",
    iata_code: "SBG",
    coordinates: "95.33969879150001, 5.87412977219",
  },
  {
    ident: "WITC",
    type: "small_airport",
    name: "Seunagan Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-AC",
    municipality: "Peureumeue-Sumatra Island",
    gps_code: "WITC",
    iata_code: "MEQ",
    coordinates: "96.21700286865234, 4.25",
  },
  {
    ident: "WITK",
    type: "medium_airport",
    name: "Rembele Airport",
    elevation_ft: "4648",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-AC",
    municipality: "Takengon",
    gps_code: "WITK",
    iata_code: "TXE",
    coordinates: "96.849444, 4.720833",
  },
  {
    ident: "WITL",
    type: "medium_airport",
    name: "Lhok Sukon Airport",
    elevation_ft: "28",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-AC",
    municipality: "Lhok Sukon-Sumatra Island",
    gps_code: "WITL",
    iata_code: "LSX",
    coordinates: "97.25920104980469, 5.069509983062744",
  },
  {
    ident: "WITM",
    type: "small_airport",
    name: "Malikus Saleh Airport",
    elevation_ft: "90",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-AC",
    municipality: "Lhok Seumawe-Sumatra Island",
    gps_code: "WITM",
    iata_code: "LSW",
    coordinates: "96.95030212402344, 5.226679801940918",
  },
  {
    ident: "WITT",
    type: "medium_airport",
    name: "Sultan Iskandar Muda International Airport",
    elevation_ft: "65",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-AC",
    municipality: "Banda Aceh",
    gps_code: "WITT",
    iata_code: "BTJ",
    coordinates: "95.42063713070002, 5.522872024010001",
  },
  {
    ident: "WJBK",
    type: "closed",
    name: "Berkley Municipal Heliport",
    elevation_ft: "12",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "Oakland",
    gps_code: "WJBK",
    iata_code: "JBK",
    local_code: "JBK",
    coordinates: "-122.3065, 37.8666",
  },
  {
    ident: "WMAN",
    type: "small_airport",
    name: "Sungai Tiang Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-06",
    municipality: "Taman Negara",
    gps_code: "WMAN",
    iata_code: "SXT",
    coordinates: "102.3949966430664, 4.330277919769287",
  },
  {
    ident: "WMAU",
    type: "small_airport",
    name: "Mersing Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-01",
    municipality: "Mersing",
    gps_code: "WMAU",
    iata_code: "MEP",
    coordinates: "103.86699676513672, 2.382999897003174",
  },
  {
    ident: "WMBA",
    type: "small_airport",
    name: "Sitiawan Airport",
    elevation_ft: "25",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-08",
    municipality: "Sitiawan",
    gps_code: "WMBA",
    iata_code: "SWY",
    coordinates: "100.6993, 4.2222",
  },
  {
    ident: "WMBI",
    type: "small_airport",
    name: "Taiping (Tekah) Airport",
    elevation_ft: "40",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-08",
    municipality: "Taiping",
    gps_code: "WMBI",
    iata_code: "TPG",
    coordinates: "100.71700286865, 4.8670001029968",
  },
  {
    ident: "WMBT",
    type: "medium_airport",
    name: "Pulau Tioman Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-06",
    municipality: "Pulau Tioman",
    gps_code: "WMBT",
    iata_code: "TOD",
    coordinates: "104.16000366210938, 2.8181800842285156",
  },
  {
    ident: "WMKA",
    type: "medium_airport",
    name: "Sultan Abdul Halim Airport",
    elevation_ft: "15",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-02",
    municipality: "Alor Satar",
    gps_code: "WMKA",
    iata_code: "AOR",
    coordinates: "100.39800262451172, 6.189670085906982",
  },
  {
    ident: "WMKB",
    type: "medium_airport",
    name: "Butterworth Airport",
    elevation_ft: "11",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-07",
    municipality: "Butterworth",
    gps_code: "WMKB",
    iata_code: "BWH",
    coordinates: "100.39099884033203, 5.4659199714660645",
  },
  {
    ident: "WMKC",
    type: "medium_airport",
    name: "Sultan Ismail Petra Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-03",
    municipality: "Kota Baharu",
    gps_code: "WMKC",
    iata_code: "KBR",
    coordinates: "102.29299926757812, 6.1668500900268555",
  },
  {
    ident: "WMKD",
    type: "medium_airport",
    name: "Kuantan Airport",
    elevation_ft: "58",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-06",
    municipality: "Kuantan",
    gps_code: "WMKD",
    iata_code: "KUA",
    coordinates: "103.20899963378906, 3.7753899097442627",
  },
  {
    ident: "WMKE",
    type: "medium_airport",
    name: "Kerteh Airport",
    elevation_ft: "18",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-11",
    municipality: "Kerteh",
    gps_code: "WMKE",
    iata_code: "KTE",
    coordinates: "103.427001953125, 4.537220001220703",
  },
  {
    ident: "WMKI",
    type: "medium_airport",
    name: "Sultan Azlan Shah Airport",
    elevation_ft: "130",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-08",
    municipality: "Ipoh",
    gps_code: "WMKI",
    iata_code: "IPH",
    coordinates: "101.09200286865234, 4.567969799041748",
  },
  {
    ident: "WMKJ",
    type: "medium_airport",
    name: "Senai International Airport",
    elevation_ft: "135",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-01",
    municipality: "Johor Bahru",
    gps_code: "WMKJ",
    iata_code: "JHB",
    coordinates: "103.669998, 1.64131",
  },
  {
    ident: "WMKK",
    type: "large_airport",
    name: "Kuala Lumpur International Airport",
    elevation_ft: "69",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-14",
    municipality: "Kuala Lumpur",
    gps_code: "WMKK",
    iata_code: "KUL",
    coordinates: "101.70999908447, 2.745579957962",
  },
  {
    ident: "WMKL",
    type: "medium_airport",
    name: "Langkawi International Airport",
    elevation_ft: "29",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-02",
    municipality: "Langkawi",
    gps_code: "WMKL",
    iata_code: "LGK",
    coordinates: "99.72869873046875, 6.329730033874512",
  },
  {
    ident: "WMKM",
    type: "medium_airport",
    name: "Malacca Airport",
    elevation_ft: "35",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-04",
    municipality: "Malacca",
    gps_code: "WMKM",
    iata_code: "MKZ",
    coordinates: "102.251998901, 2.2633600235",
  },
  {
    ident: "WMKN",
    type: "medium_airport",
    name: "Sultan Mahmud Airport",
    elevation_ft: "21",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-11",
    municipality: "Kuala Terengganu",
    gps_code: "WMKN",
    iata_code: "TGG",
    coordinates: "103.10299682617188, 5.3826398849487305",
  },
  {
    ident: "WMKP",
    type: "medium_airport",
    name: "Penang International Airport",
    elevation_ft: "11",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-07",
    municipality: "Penang",
    gps_code: "WMKP",
    iata_code: "PEN",
    coordinates: "100.2770004272461, 5.297140121459961",
  },
  {
    ident: "WMPA",
    type: "small_airport",
    name: "Pulau Pangkor Airport",
    elevation_ft: "19",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-08",
    municipality: "Pangkor Island",
    gps_code: "WMPA",
    iata_code: "PKG",
    coordinates: "100.5530014038086, 4.244719982147217",
  },
  {
    ident: "WMPR",
    type: "small_airport",
    name: "LTS Pulau Redang Airport",
    elevation_ft: "36",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-11",
    municipality: "Redang",
    gps_code: "WMPR",
    iata_code: "RDN",
    coordinates: "103.00700378417969, 5.765279769897461",
  },
  {
    ident: "WMSA",
    type: "medium_airport",
    name: "Sultan Abdul Aziz Shah International Airport",
    elevation_ft: "90",
    continent: "AS",
    iso_country: "MY",
    iso_region: "MY-10",
    municipality: "Subang",
    gps_code: "WMSA",
    iata_code: "SZB",
    coordinates: "101.54900360107422, 3.130579948425293",
  },
  {
    ident: "WN07",
    type: "small_airport",
    name: "Decatur Shores Airport",
    elevation_ft: "38",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WA",
    municipality: "Decatur",
    gps_code: "WN07",
    iata_code: "DTR",
    local_code: "WN07",
    coordinates: "-122.814002991, 48.499801635699995",
  },
  {
    ident: "WNU",
    type: "small_airport",
    name: "Wanuma Airport",
    elevation_ft: "2260",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPM",
    municipality: "Wanuma",
    gps_code: "AYWH",
    iata_code: "WNU",
    local_code: "WUA",
    coordinates: "145.3213, -4.8961",
  },
  {
    ident: "WPAT",
    type: "small_airport",
    name: "Atauro Airport",
    elevation_ft: "29",
    continent: "AS",
    iso_country: "TL",
    iso_region: "TL-DI",
    municipality: "Atauro",
    gps_code: "WPAT",
    iata_code: "AUT",
    coordinates: "125.606378, -8.243133",
  },
  {
    ident: "WPDB",
    type: "medium_airport",
    name: "Suai Airport",
    elevation_ft: "96",
    continent: "AS",
    iso_country: "TL",
    iso_region: "TL-CO",
    municipality: "Suai",
    gps_code: "WPDB",
    iata_code: "UAI",
    coordinates: "125.28700256347656, -9.30331039428711",
  },
  {
    ident: "WPDL",
    type: "medium_airport",
    name: "Presidente Nicolau Lobato International Airport",
    elevation_ft: "154",
    continent: "AS",
    iso_country: "TL",
    iso_region: "TL-DI",
    municipality: "Dili",
    gps_code: "WPDL",
    iata_code: "DIL",
    coordinates: "125.526000977, -8.54640007019",
  },
  {
    ident: "WPEC",
    type: "medium_airport",
    name: "Cakung Airport",
    elevation_ft: "1771",
    continent: "AS",
    iso_country: "TL",
    iso_region: "TL-BA",
    municipality: "Baucau",
    gps_code: "WPEC",
    iata_code: "BCH",
    coordinates: "126.401000977, -8.489029884339999",
  },
  {
    ident: "WPMN",
    type: "small_airport",
    name: "Maliana Airport",
    continent: "AS",
    iso_country: "TL",
    iso_region: "TL-BO",
    municipality: "Maliana",
    gps_code: "WPMN",
    iata_code: "MPT",
    coordinates: "125.214996, -8.97224",
  },
  {
    ident: "WPOC",
    type: "medium_airport",
    name: "Rota Do S����ndalo Oecusse Airport",
    continent: "AS",
    iso_country: "TL",
    iso_region: "TL-OE",
    municipality: "Oecussi-Ambeno",
    gps_code: "WPOC",
    iata_code: "OEC",
    coordinates: "124.343002, -9.19806",
  },
  {
    ident: "WPVQ",
    type: "small_airport",
    name: "Viqueque Airport",
    elevation_ft: "500",
    continent: "AS",
    iso_country: "TL",
    iso_region: "TL-VI",
    municipality: "Viqueque",
    gps_code: "WPVQ",
    iata_code: "VIQ",
    coordinates: "126.37300109863281, -8.88379955291748",
  },
  {
    ident: "WRKA",
    type: "small_airport",
    name: "Haliwen Airport",
    elevation_ft: "1027",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Atambua-Timor Island",
    gps_code: "WATA",
    iata_code: "ABU",
    coordinates: "124.904998779, -9.073049545289999",
  },
  {
    ident: "WRKL",
    type: "small_airport",
    name: "Gewayentana Airport",
    elevation_ft: "63",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Larantuka-Flores Island",
    gps_code: "WATL",
    iata_code: "LKA",
    coordinates: "123.002, -8.274424",
  },
  {
    ident: "WRKR",
    type: "small_airport",
    name: "David Constantijn Saudale Airport",
    elevation_ft: "470",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-NT",
    municipality: "Ba'a - Rote Island",
    gps_code: "WATR",
    iata_code: "RTI",
    coordinates: "123.0747, -10.7673",
  },
  {
    ident: "WRLA",
    type: "small_airport",
    name: "Sanggata/Sangkimah Airport",
    elevation_ft: "60",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Sanggata/Sangkimah",
    gps_code: "WALA",
    iata_code: "SGQ",
    coordinates: "117.543, 0.3847",
  },
  {
    ident: "WRLB",
    type: "small_airport",
    name: "Long Bawan Airport",
    elevation_ft: "3165",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KU",
    municipality: "Long Bawan-Borneo Island",
    gps_code: "WALB",
    iata_code: "LBW",
    coordinates: "115.6921, 3.9028",
  },
  {
    ident: "WRLC",
    type: "small_airport",
    name: "Bontang Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Bontang-Borneo Island",
    gps_code: "WALC",
    iata_code: "BXT",
    coordinates: "117.474998, 0.119691",
  },
  {
    ident: "WRLF",
    type: "small_airport",
    name: "Nunukan Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KU",
    municipality: "Nunukan-Nunukan Island",
    gps_code: "WALF",
    iata_code: "NNX",
    coordinates: "117.666666667, 4.13333333333",
  },
  {
    ident: "WRLH",
    type: "small_airport",
    name: "Tanah Grogot Airport",
    elevation_ft: "17",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Tanah Grogot-Borneo Island",
    gps_code: "WRLH",
    iata_code: "TNB",
    coordinates: "116.202003479, -1.91013002396",
  },
  {
    ident: "WRLP",
    type: "small_airport",
    name: "Long Apung Airport",
    elevation_ft: "627",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KU",
    municipality: "Long Apung-Borneo Island",
    gps_code: "WALP",
    iata_code: "LPU",
    coordinates: "114.970297, 1.704486",
  },
  {
    ident: "WRLY",
    type: "heliport",
    name: "Senipah Heliport",
    elevation_ft: "38",
    continent: "AS",
    iso_country: "ID",
    iso_region: "ID-KI",
    municipality: "Senipah",
    gps_code: "WALY",
    iata_code: "SZH",
    coordinates: "117.147701, -0.975679",
  },
  {
    ident: "WSA",
    type: "small_airport",
    name: "Wasua Airport",
    elevation_ft: "45",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-WPD",
    municipality: "Wasua",
    gps_code: "AYIW",
    iata_code: "WSA",
    local_code: "WAA",
    coordinates: "142.8697, -8.2836",
  },
  {
    ident: "WSAP",
    type: "medium_airport",
    name: "Paya Lebar Air Base",
    elevation_ft: "65",
    continent: "AS",
    iso_country: "SG",
    iso_region: "SG-04",
    gps_code: "WSAP",
    iata_code: "QPG",
    coordinates: "103.91000366210938, 1.3604199886322021",
  },
  {
    ident: "WSAT",
    type: "medium_airport",
    name: "Tengah Air Base",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "SG",
    iso_region: "SG-03",
    gps_code: "WSAT",
    iata_code: "TGA",
    coordinates: "103.708999634, 1.38725996017",
  },
  {
    ident: "WSB",
    type: "seaplane_base",
    name: "Steamboat Bay Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Steamboat Bay",
    gps_code: "WSB",
    iata_code: "WSB",
    local_code: "WSB",
    coordinates: "-133.641998291, 55.5295982361",
  },
  {
    ident: "WSJ",
    type: "seaplane_base",
    name: "San Juan /Uganik/ Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "San Juan",
    iata_code: "UGI",
    local_code: "WSJ",
    coordinates: "-153.320999, 57.7304",
  },
  {
    ident: "WSM",
    type: "small_airport",
    name: "Wiseman Airport",
    elevation_ft: "1180",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Wiseman",
    gps_code: "WSM",
    iata_code: "WSM",
    local_code: "WSM",
    coordinates: "-150.123001099, 67.4046020508",
  },
  {
    ident: "WSSL",
    type: "medium_airport",
    name: "Seletar Airport",
    elevation_ft: "36",
    continent: "AS",
    iso_country: "SG",
    iso_region: "SG-02",
    municipality: "Seletar",
    gps_code: "WSSL",
    iata_code: "XSP",
    coordinates: "103.86799621582031, 1.416949987411499",
  },
  {
    ident: "WSSS",
    type: "large_airport",
    name: "Singapore Changi Airport",
    elevation_ft: "22",
    continent: "AS",
    iso_country: "SG",
    iso_region: "SG-04",
    municipality: "Singapore",
    gps_code: "WSSS",
    iata_code: "SIN",
    coordinates: "103.994003, 1.35019",
  },
  {
    ident: "WTT",
    type: "small_airport",
    name: "Wantoat Airport",
    elevation_ft: "3900",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Wantoat",
    gps_code: "AYWC",
    iata_code: "WTT",
    local_code: "WNT",
    coordinates: "146.467777778, -6.1325",
  },
  {
    ident: "WUV",
    type: "small_airport",
    name: "Wuvulu Island Airport",
    elevation_ft: "16",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MRL",
    municipality: "Wuvulu Island",
    gps_code: "AYVW",
    iata_code: "WUV",
    local_code: "WUV",
    coordinates: "142.836666667, -1.73611111111",
  },
  {
    ident: "WV66",
    type: "small_airport",
    name: "Glendale Fokker Field",
    elevation_ft: "648",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-WV",
    municipality: "Glendale",
    gps_code: "WV66",
    iata_code: "GWV",
    local_code: "WV66",
    coordinates: "-80.7594985962, 39.948699951200005",
  },
  {
    ident: "WZQ",
    type: "small_airport",
    name: "Urad Middle Banner",
    elevation_ft: "3996",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Urad Middle Banner",
    iata_code: "WZQ",
    coordinates: "108.53454, 41.45958",
  },
  {
    ident: "X-TRPM",
    type: "closed",
    name: "W. H. Bramble Airport",
    continent: "NA",
    iso_country: "MS",
    iso_region: "MS-U-A",
    municipality: "Plymouth",
    gps_code: "TRPM",
    iata_code: "MNI",
    coordinates: "-62.15639877319336, 16.758899688720703",
  },
  {
    ident: "X44",
    type: "seaplane_base",
    name: "Miami Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-FL",
    municipality: "Miami",
    gps_code: "X44",
    iata_code: "MPB",
    local_code: "X44",
    coordinates: "-80.170303344727, 25.778299331665",
  },
  {
    ident: "XBB",
    type: "seaplane_base",
    name: "Blubber Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Blubber Bay",
    iata_code: "XBB",
    coordinates: "-124.621064, 49.793958",
  },
  {
    ident: "XBN",
    type: "small_airport",
    name: "Biniguni Airport",
    elevation_ft: "215",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-NPP",
    municipality: "Biniguni",
    gps_code: "AYBZ",
    iata_code: "XBN",
    local_code: "BNI",
    coordinates: "149.303888889, -9.6425",
  },
  {
    ident: "XIG",
    type: "small_airport",
    name: "Xinguara Municipal Airport",
    elevation_ft: "810",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-PA",
    municipality: "Xinguara",
    gps_code: "SWSX",
    iata_code: "XIG",
    coordinates: "-49.9765, -7.0906",
  },
  {
    ident: "XLO",
    type: "closed",
    name: "Long Xuy����n Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-44",
    municipality: "Long Xuy����n",
    iata_code: "XLO",
    coordinates: "105.473144, 10.330134",
  },
  {
    ident: "XMA",
    type: "small_airport",
    name: "Maramag Airport",
    elevation_ft: "919",
    continent: "AS",
    iso_country: "PH",
    iso_region: "PH-BUK",
    municipality: "Maramag",
    iata_code: "XMA",
    coordinates: "125.0333, 7.7538",
  },
  {
    ident: "XMUC",
    type: "closed",
    name: "Flughafen M����nchen-Riem",
    continent: "EU",
    iso_country: "DE",
    iso_region: "DE-BY",
    municipality: "Munich",
    gps_code: "EDDM",
    iata_code: "MUC",
    coordinates: "11.69029999, 48.137798309",
  },
  {
    ident: "XNG",
    type: "closed",
    name: "Qu������ng Ng����i Airfield",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-13",
    municipality: "Qu������ng Ng����i",
    iata_code: "XNG",
    coordinates: "108.771816, 15.115474",
  },
  {
    ident: "XRQ",
    type: "small_airport",
    name: "Xinbarag Youqi Baogede Airport",
    elevation_ft: "1798",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "New Barag Right Banner",
    iata_code: "XRQ",
    coordinates: "116.939382, 48.575585",
  },
  {
    ident: "XUBS",
    type: "medium_airport",
    name: "Smolensk North Airport",
    elevation_ft: "820",
    continent: "EU",
    iso_country: "RU",
    iso_region: "RU-SMO",
    municipality: "Smolensk",
    iata_code: "LNX",
    local_code: "XUBS",
    coordinates: "32.025, 54.824",
  },
  {
    ident: "XVL",
    type: "small_airport",
    name: "Vinh Long Airfield",
    elevation_ft: "27",
    continent: "AS",
    iso_country: "VN",
    iso_region: "VN-49",
    municipality: "Vinh Long",
    iata_code: "XVL",
    coordinates: "105.9445, 10.2509",
  },
  {
    ident: "XXZ",
    type: "medium_airport",
    name: "Modi",
    elevation_ft: "29977",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-CA",
    municipality: "California",
    gps_code: "EGLA",
    iata_code: "XXZ",
    local_code: "NY12",
    coordinates: "179.999894, 89.999845",
  },
  {
    ident: "XZM",
    type: "heliport",
    name: "Macau Heliport at Outer Harbour Ferry Terminal",
    continent: "AS",
    iso_country: "MO",
    iso_region: "MO-U-A",
    municipality: "Macau",
    iata_code: "XZM",
    coordinates: "113.559, 22.1971",
  },
  {
    ident: "Y01",
    type: "small_airport",
    name: "Waukon Municipal Airport",
    elevation_ft: "1281",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-IA",
    municipality: "Waukon",
    gps_code: "Y01",
    iata_code: "UKN",
    local_code: "Y01",
    coordinates: "-91.469497680664, 43.280498504639",
  },
  {
    ident: "YABA",
    type: "medium_airport",
    name: "Albany Airport",
    elevation_ft: "233",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Albany",
    gps_code: "YABA",
    iata_code: "ALH",
    coordinates: "117.80899810791016, -34.94329833984375",
  },
  {
    ident: "YABI",
    type: "small_airport",
    name: "Abingdon Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YABI",
    iata_code: "ABG",
    coordinates: "143.167007446, -17.616699218799997",
  },
  {
    ident: "YADS",
    type: "small_airport",
    name: "Alton Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YADS",
    iata_code: "AWN",
    coordinates: "139.259906, -26.488959",
  },
  {
    ident: "YAGD",
    type: "small_airport",
    name: "Augustus Downs Airport",
    elevation_ft: "46",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YAGD",
    iata_code: "AUD",
    coordinates: "139.8780059814453, -18.514999389648438",
  },
  {
    ident: "YAJ",
    type: "seaplane_base",
    name: "Lyall Harbour Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Saturna Island",
    iata_code: "YAJ",
    coordinates: "-123.1816, 48.7952",
  },
  {
    ident: "YALA",
    type: "small_airport",
    name: "Marla Airport",
    elevation_ft: "328",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YALA",
    iata_code: "MRP",
    coordinates: "133.6269989013672, -27.33329963684082",
  },
  {
    ident: "YALX",
    type: "small_airport",
    name: "Alexandria Homestead Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YALX",
    iata_code: "AXL",
    coordinates: "136.7100067138672, -19.060199737548828",
  },
  {
    ident: "YAMC",
    type: "small_airport",
    name: "Aramac Airport",
    elevation_ft: "232",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YAMC",
    iata_code: "AXC",
    coordinates: "145.24200439453125, -22.966699600219727",
  },
  {
    ident: "YAMK",
    type: "small_airport",
    name: "Andamooka Airport",
    elevation_ft: "76",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YAMK",
    iata_code: "ADO",
    coordinates: "137.136993, -30.438299",
  },
  {
    ident: "YAMM",
    type: "small_airport",
    name: "Ammaroo Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YAMM",
    iata_code: "AMX",
    coordinates: "135.24200439453125, -21.738300323486328",
  },
  {
    ident: "YAMT",
    type: "small_airport",
    name: "Amata Airport",
    elevation_ft: "695",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YAMT",
    iata_code: "AMT",
    coordinates: "131.202732, -26.097363",
  },
  {
    ident: "YANG",
    type: "small_airport",
    name: "West Angelas Airport",
    elevation_ft: "2340",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YANG",
    iata_code: "WLP",
    coordinates: "118.707222222, -23.1355555556",
  },
  {
    ident: "YANL",
    type: "small_airport",
    name: "Anthony Lagoon Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YANL",
    iata_code: "AYL",
    coordinates: "135.535068512, -18.018079031",
  },
  {
    ident: "YAPH",
    type: "medium_airport",
    name: "Alpha Airport",
    elevation_ft: "1255",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Alpha",
    gps_code: "YAPH",
    iata_code: "ABH",
    coordinates: "146.584, -23.646099",
  },
  {
    ident: "YAQ",
    type: "seaplane_base",
    name: "Maple Bay Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Maple Bay",
    iata_code: "YAQ",
    coordinates: "-123.6084, 48.8167",
  },
  {
    ident: "YARA",
    type: "medium_airport",
    name: "Ararat Airport",
    elevation_ft: "1008",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YARA",
    iata_code: "ARY",
    coordinates: "142.98899841308594, -37.30939865112305",
  },
  {
    ident: "YARG",
    type: "small_airport",
    name: "Argyle Airport",
    elevation_ft: "522",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YARG",
    iata_code: "GYL",
    coordinates: "128.451004, -16.6369",
  },
  {
    ident: "YARM",
    type: "medium_airport",
    name: "Armidale Airport",
    elevation_ft: "3556",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Armidale",
    gps_code: "YARM",
    iata_code: "ARM",
    coordinates: "151.617004395, -30.528099060099997",
  },
  {
    ident: "YARY",
    type: "small_airport",
    name: "Arrabury Airport",
    elevation_ft: "334",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YARY",
    iata_code: "AAB",
    coordinates: "141.047771, -26.693023",
  },
  {
    ident: "YAUR",
    type: "small_airport",
    name: "Aurukun Airport",
    elevation_ft: "31",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Aurukun",
    gps_code: "YAUR",
    iata_code: "AUU",
    coordinates: "141.72065, -13.354067",
  },
  {
    ident: "YAUS",
    type: "small_airport",
    name: "Austral Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YAUS",
    iata_code: "AWP",
    coordinates: "137.75, -20.5",
  },
  {
    ident: "YAUV",
    type: "small_airport",
    name: "Auvergne Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YAUV",
    iata_code: "AVG",
    coordinates: "130, -15.699999809265137",
  },
  {
    ident: "YAYE",
    type: "medium_airport",
    name: "Ayers Rock Connellan Airport",
    elevation_ft: "1626",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Ayers Rock",
    gps_code: "YAYE",
    iata_code: "AYQ",
    coordinates: "130.975998, -25.1861",
  },
  {
    ident: "YAYR",
    type: "small_airport",
    name: "Ayr Airport",
    elevation_ft: "41",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YAYR",
    iata_code: "AYR",
    coordinates: "147.32899475097656, -19.584400177001953",
  },
  {
    ident: "YBAF",
    type: "small_airport",
    name: "Brisbane Archerfield Airport",
    elevation_ft: "63",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Brisbane",
    gps_code: "YBAF",
    iata_code: "ACF",
    coordinates: "153.007995605, -27.5702991486",
  },
  {
    ident: "YBAM",
    type: "small_airport",
    name: "Northern Peninsula Airport",
    elevation_ft: "34",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Bamaga",
    gps_code: "YNPE",
    iata_code: "ABM",
    coordinates: "142.459, -10.9508",
  },
  {
    ident: "YBAR",
    type: "medium_airport",
    name: "Barcaldine Airport",
    elevation_ft: "878",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Barcaldine",
    gps_code: "YBAR",
    iata_code: "BCI",
    coordinates: "145.307006836, -23.5652999878",
  },
  {
    ident: "YBAS",
    type: "medium_airport",
    name: "Alice Springs Airport",
    elevation_ft: "1789",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Alice Springs",
    gps_code: "YBAS",
    iata_code: "ASP",
    coordinates: "133.90199279785156, -23.806699752807617",
  },
  {
    ident: "YBAU",
    type: "small_airport",
    name: "Badu Island Airport",
    elevation_ft: "14",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBAU",
    iata_code: "BDD",
    coordinates: "142.1734, -10.149999618499999",
  },
  {
    ident: "YBAW",
    type: "small_airport",
    name: "Barkly Downs Airport",
    elevation_ft: "810",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBAW",
    iata_code: "BKP",
    coordinates: "138.474722222, -20.4958333333",
  },
  {
    ident: "YBBE",
    type: "small_airport",
    name: "Big Bell Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Big Bell",
    gps_code: "YBBE",
    iata_code: "BBE",
    coordinates: "117.672996521, -27.3285999298",
  },
  {
    ident: "YBBN",
    type: "large_airport",
    name: "Brisbane International Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Brisbane",
    gps_code: "YBBN",
    iata_code: "BNE",
    coordinates: "153.11700439453125, -27.384199142456055",
  },
  {
    ident: "YBCG",
    type: "medium_airport",
    name: "Gold Coast Airport",
    elevation_ft: "21",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Gold Coast",
    gps_code: "YBCG",
    iata_code: "OOL",
    coordinates: "153.505004883, -28.1644001007",
  },
  {
    ident: "YBCK",
    type: "medium_airport",
    name: "Blackall Airport",
    elevation_ft: "928",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Blackall",
    gps_code: "YBCK",
    iata_code: "BKQ",
    coordinates: "145.429000854, -24.427799224900003",
  },
  {
    ident: "YBCS",
    type: "medium_airport",
    name: "Cairns International Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Cairns",
    gps_code: "YBCS",
    iata_code: "CNS",
    coordinates: "145.755004883, -16.885799408",
  },
  {
    ident: "YBCV",
    type: "medium_airport",
    name: "Charleville Airport",
    elevation_ft: "1003",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Charleville",
    gps_code: "YBCV",
    iata_code: "CTL",
    coordinates: "146.261993408, -26.4132995605",
  },
  {
    ident: "YBDF",
    type: "small_airport",
    name: "Bedford Downs Airport",
    elevation_ft: "1750",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YBDF",
    iata_code: "BDW",
    coordinates: "127.462997437, -17.286699295",
  },
  {
    ident: "YBDG",
    type: "small_airport",
    name: "Bendigo Airport",
    elevation_ft: "705",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YBDG",
    iata_code: "BXG",
    coordinates: "144.330001831, -36.7393989563",
  },
  {
    ident: "YBDV",
    type: "medium_airport",
    name: "Birdsville Airport",
    elevation_ft: "159",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBDV",
    iata_code: "BVI",
    coordinates: "139.34800720214844, -25.897499084472656",
  },
  {
    ident: "YBEB",
    type: "small_airport",
    name: "Bellburn Airstrip",
    elevation_ft: "810",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Pumululu National Park",
    gps_code: "YBEB",
    iata_code: "BXF",
    coordinates: "128.305, -17.545",
  },
  {
    ident: "YBEO",
    type: "small_airport",
    name: "Betoota Airport",
    elevation_ft: "91",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBEO",
    iata_code: "BTX",
    coordinates: "140.7830047607422, -25.641700744628906",
  },
  {
    ident: "YBGB",
    type: "small_airport",
    name: "Beagle Bay Airport",
    elevation_ft: "124",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Beagle Bay",
    gps_code: "YBGB",
    iata_code: "BEE",
    coordinates: "122.6464, -17.0165",
  },
  {
    ident: "YBGD",
    type: "small_airport",
    name: "Boolgeeda",
    elevation_ft: "1871",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YBGD",
    iata_code: "OCM",
    coordinates: "117.275, -22.54",
  },
  {
    ident: "YBGO",
    type: "small_airport",
    name: "Balgo Hill Airport",
    elevation_ft: "1440",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Balgo",
    gps_code: "YBGO",
    iata_code: "BQW",
    coordinates: "127.973, -20.1483",
  },
  {
    ident: "YBH",
    type: "seaplane_base",
    name: "Bull Harbour Water Aerodrome",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Bull Harbour",
    iata_code: "YBH",
    local_code: "AH6",
    coordinates: "-127.9372, 50.9179",
  },
  {
    ident: "YBHI",
    type: "medium_airport",
    name: "Broken Hill Airport",
    elevation_ft: "958",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Broken Hill",
    gps_code: "YBHI",
    iata_code: "BHQ",
    coordinates: "141.472000122, -32.0013999939",
  },
  {
    ident: "YBHM",
    type: "medium_airport",
    name: "Hamilton Island Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Hamilton Island",
    gps_code: "YBHM",
    iata_code: "HTI",
    coordinates: "148.95199585, -20.3581008911",
  },
  {
    ident: "YBIE",
    type: "medium_airport",
    name: "Bedourie Airport",
    elevation_ft: "300",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBIE",
    iata_code: "BEU",
    coordinates: "139.4600067138672, -24.346099853515625",
  },
  {
    ident: "YBIL",
    type: "small_airport",
    name: "Billiluna Airport",
    elevation_ft: "1000",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YBIL",
    iata_code: "BIW",
    coordinates: "127.666999817, -19.566699981699998",
  },
  {
    ident: "YBIZ",
    type: "small_airport",
    name: "Bizant Airport",
    elevation_ft: "65",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Lakefield National Park",
    gps_code: "YBIZ",
    iata_code: "BZP",
    coordinates: "144.119444444, -14.7402777778",
  },
  {
    ident: "YBJ",
    type: "seaplane_base",
    name: "Baie-Johan-Beetz Water Aerodrome",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Baie-Johan-Beetz",
    iata_code: "YBJ",
    local_code: "SG6",
    coordinates: "-62.8063, 50.2838",
  },
  {
    ident: "YBKE",
    type: "medium_airport",
    name: "Bourke Airport",
    elevation_ft: "352",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YBKE",
    iata_code: "BRK",
    coordinates: "145.95199584960938, -30.039199829101562",
  },
  {
    ident: "YBKT",
    type: "small_airport",
    name: "Burketown Airport",
    elevation_ft: "21",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBKT",
    iata_code: "BUC",
    coordinates: "139.53399658203125, -17.748600006103516",
  },
  {
    ident: "YBLA",
    type: "medium_airport",
    name: "Benalla Airport",
    elevation_ft: "569",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YBLA",
    iata_code: "BLN",
    coordinates: "146.0070037841797, -36.55189895629883",
  },
  {
    ident: "YBLC",
    type: "small_airport",
    name: "Balcanoona Airport",
    elevation_ft: "144",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YBLC",
    iata_code: "LCN",
    coordinates: "139.33700561523438, -30.53499984741211",
  },
  {
    ident: "YBLL",
    type: "small_airport",
    name: "Bollon Airport",
    elevation_ft: "182",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBLL",
    iata_code: "BLS",
    coordinates: "147.48300170898438, -28.058300018310547",
  },
  {
    ident: "YBLN",
    type: "small_airport",
    name: "Busselton Regional Airport",
    elevation_ft: "55",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YBLN",
    iata_code: "BQB",
    coordinates: "115.401596069, -33.6884231567",
  },
  {
    ident: "YBMA",
    type: "medium_airport",
    name: "Mount Isa Airport",
    elevation_ft: "1121",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Mount Isa",
    gps_code: "YBMA",
    iata_code: "ISA",
    coordinates: "139.488998413, -20.663900375399997",
  },
  {
    ident: "YBMC",
    type: "medium_airport",
    name: "Sunshine Coast Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Maroochydore",
    gps_code: "YBSU",
    iata_code: "MCY",
    coordinates: "153.091003, -26.6033",
  },
  {
    ident: "YBMD",
    type: "small_airport",
    name: "Bloomfield River Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBMD",
    iata_code: "BFC",
    coordinates: "145.330001831, -15.8736000061",
  },
  {
    ident: "YBMK",
    type: "medium_airport",
    name: "Mackay Airport",
    elevation_ft: "19",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Mackay",
    gps_code: "YBMK",
    iata_code: "MKY",
    coordinates: "149.179992676, -21.171699523900003",
  },
  {
    ident: "YBNA",
    type: "medium_airport",
    name: "Ballina Byron Gateway Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Ballina",
    gps_code: "YBNA",
    iata_code: "BNK",
    coordinates: "153.56199646, -28.8339004517",
  },
  {
    ident: "YBNS",
    type: "medium_airport",
    name: "Bairnsdale Airport",
    elevation_ft: "165",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YBNS",
    iata_code: "BSJ",
    coordinates: "147.5679931640625, -37.88750076293945",
  },
  {
    ident: "YBOI",
    type: "small_airport",
    name: "Boigu Airport",
    elevation_ft: "23",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBOI",
    iata_code: "GIC",
    coordinates: "142.218002319, -9.23278045654",
  },
  {
    ident: "YBOK",
    type: "medium_airport",
    name: "Oakey Airport",
    elevation_ft: "1335",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBOK",
    iata_code: "OKY",
    coordinates: "151.73500061035156, -27.411399841308594",
  },
  {
    ident: "YBOU",
    type: "medium_airport",
    name: "Boulia Airport",
    elevation_ft: "542",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBOU",
    iata_code: "BQL",
    coordinates: "139.89999389648438, -22.913299560546875",
  },
  {
    ident: "YBPI",
    type: "small_airport",
    name: "Brampton Island Airport",
    elevation_ft: "11",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBPI",
    iata_code: "BMP",
    coordinates: "149.27000427246094, -20.803300857543945",
  },
  {
    ident: "YBPN",
    type: "medium_airport",
    name: "Proserpine Whitsunday Coast Airport",
    elevation_ft: "82",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Proserpine",
    gps_code: "YBPN",
    iata_code: "PPP",
    coordinates: "148.552001953, -20.4950008392",
  },
  {
    ident: "YBQ",
    type: "seaplane_base",
    name: "Telegraph Harbour Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Thetis Island",
    iata_code: "YBQ",
    coordinates: "-123.664, 48.97",
  },
  {
    ident: "YBRK",
    type: "medium_airport",
    name: "Rockhampton Airport",
    elevation_ft: "34",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Rockhampton",
    gps_code: "YBRK",
    iata_code: "ROK",
    coordinates: "150.475006104, -23.3819007874",
  },
  {
    ident: "YBRL",
    type: "small_airport",
    name: "Borroloola Airport",
    elevation_ft: "55",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YBRL",
    iata_code: "BOX",
    coordinates: "136.302001953125, -16.075300216674805",
  },
  {
    ident: "YBRM",
    type: "medium_airport",
    name: "Broome International Airport",
    elevation_ft: "56",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Broome",
    gps_code: "YBRM",
    iata_code: "BME",
    coordinates: "122.23200225830078, -17.944700241088867",
  },
  {
    ident: "YBRN",
    type: "medium_airport",
    name: "Balranald Airport",
    elevation_ft: "210",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YBRN",
    iata_code: "BZD",
    coordinates: "143.5780029296875, -34.623600006103516",
  },
  {
    ident: "YBRU",
    type: "small_airport",
    name: "Brunette Downs Airport",
    elevation_ft: "700",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YBRU",
    iata_code: "BTD",
    coordinates: "135.93800354, -18.639999389599996",
  },
  {
    ident: "YBRW",
    type: "medium_airport",
    name: "Brewarrina Airport",
    elevation_ft: "414",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YBRW",
    iata_code: "BWQ",
    coordinates: "146.81700134277344, -29.973899841308594",
  },
  {
    ident: "YBRY",
    type: "small_airport",
    name: "Barimunya Airport",
    elevation_ft: "2082",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YBRY",
    iata_code: "BYP",
    coordinates: "119.16600036621, -22.673900604248",
  },
  {
    ident: "YBTH",
    type: "medium_airport",
    name: "Bathurst Airport",
    elevation_ft: "2435",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Bathurst",
    gps_code: "YBTH",
    iata_code: "BHS",
    coordinates: "149.651992798, -33.4094009399",
  },
  {
    ident: "YBTI",
    type: "medium_airport",
    name: "Bathurst Island Airport",
    elevation_ft: "67",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YBTI",
    iata_code: "BRT",
    coordinates: "130.6199951171875, -11.769200325012207",
  },
  {
    ident: "YBTL",
    type: "medium_airport",
    name: "Townsville Airport",
    elevation_ft: "18",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Townsville",
    gps_code: "YBTL",
    iata_code: "TSV",
    coordinates: "146.76499938964844, -19.252500534057617",
  },
  {
    ident: "YBTR",
    type: "medium_airport",
    name: "Blackwater Airport",
    elevation_ft: "657",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBTR",
    iata_code: "BLT",
    coordinates: "148.8070068359375, -23.603099822998047",
  },
  {
    ident: "YBTV",
    type: "small_airport",
    name: "Batavia Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBTV",
    iata_code: "BVW",
    coordinates: "142.675003052, -12.6591997147",
  },
  {
    ident: "YBUD",
    type: "medium_airport",
    name: "Bundaberg Airport",
    elevation_ft: "107",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Bundaberg",
    gps_code: "YBUD",
    iata_code: "BDB",
    coordinates: "152.319000244, -24.903900146499996",
  },
  {
    ident: "YBUN",
    type: "small_airport",
    name: "Bunbury Airport",
    elevation_ft: "53",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YBUN",
    iata_code: "BUY",
    coordinates: "115.677001953125, -33.378299713134766",
  },
  {
    ident: "YBWM",
    type: "small_airport",
    name: "Bulimba Airport",
    elevation_ft: "470",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBWM",
    iata_code: "BIP",
    coordinates: "143.479003906, -16.8808002472",
  },
  {
    ident: "YBWN",
    type: "small_airport",
    name: "Bowen Airport",
    elevation_ft: "8",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBWN",
    iata_code: "ZBO",
    coordinates: "148.21499633789062, -20.018299102783203",
  },
  {
    ident: "YBWP",
    type: "medium_airport",
    name: "Weipa Airport",
    elevation_ft: "63",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Weipa",
    gps_code: "YBWP",
    iata_code: "WEI",
    coordinates: "141.925003052, -12.6786003113",
  },
  {
    ident: "YBWR",
    type: "small_airport",
    name: "Bolwarra Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YBWR",
    iata_code: "BCK",
    coordinates: "144.169006348, -17.388299942",
  },
  {
    ident: "YBWW",
    type: "medium_airport",
    name: "Toowoomba Wellcamp Airport",
    elevation_ft: "1509",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Toowoomba",
    gps_code: "YBWW",
    iata_code: "WTB",
    coordinates: "151.793335, -27.558332",
  },
  {
    ident: "YBWX",
    type: "small_airport",
    name: "Barrow Island Airport",
    elevation_ft: "26",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YBWX",
    iata_code: "BWB",
    coordinates: "115.40599822998047, -20.86440086364746",
  },
  {
    ident: "YBYS",
    type: "small_airport",
    name: "Beverley Springs Airport",
    elevation_ft: "385",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YBYS",
    iata_code: "BVZ",
    coordinates: "125.43299865722656, -16.733299255371094",
  },
  {
    ident: "YCAC",
    type: "small_airport",
    name: "Cattle Creek Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YCAC",
    iata_code: "CTR",
    coordinates: "131.548995972, -17.607000351",
  },
  {
    ident: "YCAG",
    type: "small_airport",
    name: "Caiguna Airport",
    elevation_ft: "87",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YCAG",
    iata_code: "CGV",
    coordinates: "125.49299621582031, -32.26499938964844",
  },
  {
    ident: "YCAH",
    type: "small_airport",
    name: "Coolah Airport",
    elevation_ft: "504",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCAH",
    iata_code: "CLH",
    coordinates: "149.61000061035156, -31.773300170898438",
  },
  {
    ident: "YCAR",
    type: "medium_airport",
    name: "Carnarvon Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Carnarvon",
    gps_code: "YCAR",
    iata_code: "CVQ",
    coordinates: "113.67174, -24.880211",
  },
  {
    ident: "YCAS",
    type: "small_airport",
    name: "Casino Airport",
    elevation_ft: "86",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCAS",
    iata_code: "CSI",
    coordinates: "153.06700134277344, -28.88279914855957",
  },
  {
    ident: "YCBA",
    type: "medium_airport",
    name: "Cobar Airport",
    elevation_ft: "724",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCBA",
    iata_code: "CAZ",
    coordinates: "145.79400634765625, -31.538299560546875",
  },
  {
    ident: "YCBB",
    type: "medium_airport",
    name: "Coonabarabran Airport",
    elevation_ft: "2117",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCBB",
    iata_code: "COJ",
    coordinates: "149.26699829101562, -31.332500457763672",
  },
  {
    ident: "YCBE",
    type: "small_airport",
    name: "Canobie Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Canobie",
    gps_code: "YCBE",
    iata_code: "CBY",
    coordinates: "140.927001953, -19.479400634799998",
  },
  {
    ident: "YCBN",
    type: "small_airport",
    name: "Cape Barren Island Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    gps_code: "YCBN",
    iata_code: "CBI",
    coordinates: "148.01699829101562, -40.391700744628906",
  },
  {
    ident: "YCBP",
    type: "medium_airport",
    name: "Coober Pedy Airport",
    elevation_ft: "740",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YCBP",
    iata_code: "CPD",
    coordinates: "134.7209930419922, -29.040000915527344",
  },
  {
    ident: "YCBR",
    type: "small_airport",
    name: "Collarenebri Airport",
    elevation_ft: "152",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCBR",
    iata_code: "CRB",
    coordinates: "148.58200073242188, -29.521699905395508",
  },
  {
    ident: "YCCA",
    type: "medium_airport",
    name: "Chinchilla Airport",
    elevation_ft: "1028",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCCA",
    iata_code: "CCL",
    coordinates: "150.61700439453125, -26.774999618530273",
  },
  {
    ident: "YCCT",
    type: "small_airport",
    name: "Coconut Island Airport",
    elevation_ft: "3",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCCT",
    iata_code: "CNC",
    coordinates: "143.07000732421875, -10.050000190734863",
  },
  {
    ident: "YCCY",
    type: "medium_airport",
    name: "Cloncurry Airport",
    elevation_ft: "616",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Cloncurry",
    gps_code: "YCCY",
    iata_code: "CNJ",
    coordinates: "140.503997803, -20.668600082399998",
  },
  {
    ident: "YCDO",
    type: "small_airport",
    name: "Condobolin Airport",
    elevation_ft: "650",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCDO",
    iata_code: "CBX",
    coordinates: "147.20899963378906, -33.06439971923828",
  },
  {
    ident: "YCDR",
    type: "small_airport",
    name: "Caloundra Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCDR",
    iata_code: "CUD",
    coordinates: "153.10000610351562, -26.799999237060547",
  },
  {
    ident: "YCDU",
    type: "medium_airport",
    name: "Ceduna Airport",
    elevation_ft: "77",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YCDU",
    iata_code: "CED",
    coordinates: "133.7100067138672, -32.13059997558594",
  },
  {
    ident: "YCEE",
    type: "medium_airport",
    name: "Cleve Airport",
    elevation_ft: "589",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YCEE",
    iata_code: "CVC",
    coordinates: "136.5050048828125, -33.70970153808594",
  },
  {
    ident: "YCF",
    type: "seaplane_base",
    name: "Cortes Bay Water Aerodrome",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Cortes Bay",
    iata_code: "YCF",
    local_code: "AM6",
    coordinates: "-124.930002, 50.0630001",
  },
  {
    ident: "YCFD",
    type: "small_airport",
    name: "Camfield Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YCFD",
    iata_code: "CFI",
    coordinates: "131.32699584960938, -17.021699905395508",
  },
  {
    ident: "YCFH",
    type: "small_airport",
    name: "Clifton Hills Airport",
    elevation_ft: "105",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Clifton Hills",
    gps_code: "YCFH",
    iata_code: "CFH",
    coordinates: "138.891998291, -27.018299102800004",
  },
  {
    ident: "YCFL",
    type: "small_airport",
    name: "Cape Flattery Airport",
    elevation_ft: "3609",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCFL",
    iata_code: "CQP",
    coordinates: "145.311667, -14.970832",
  },
  {
    ident: "YCGO",
    type: "small_airport",
    name: "Chillagoe Airport",
    elevation_ft: "1110",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCGO",
    iata_code: "LLG",
    coordinates: "144.529006958, -17.1427993774",
  },
  {
    ident: "YCHB",
    type: "small_airport",
    name: "Cherrabah Airport",
    elevation_ft: "2228",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Cherrabah Homestead Resort",
    gps_code: "YCHB",
    iata_code: "CRH",
    coordinates: "152.08895, -28.4301",
  },
  {
    ident: "YCHK",
    type: "small_airport",
    name: "Graeme Rowley Aerodrome",
    elevation_ft: "1462",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Christmas Creek Mine",
    gps_code: "YCHK",
    iata_code: "CKW",
    coordinates: "119.6426, -22.3543",
  },
  {
    ident: "YCHT",
    type: "small_airport",
    name: "Charters Towers Airport",
    elevation_ft: "955",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCHT",
    iata_code: "CXT",
    coordinates: "146.2729949951172, -20.043100357055664",
  },
  {
    ident: "YCIN",
    type: "medium_airport",
    name: "RAAF Base Curtin",
    elevation_ft: "300",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YCIN",
    iata_code: "DCN",
    coordinates: "123.82800293, -17.5813999176",
  },
  {
    ident: "YCKI",
    type: "small_airport",
    name: "Croker Island Airport",
    elevation_ft: "16",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YCKI",
    iata_code: "CKI",
    coordinates: "132.48300170898438, -11.164999961853027",
  },
  {
    ident: "YCKN",
    type: "medium_airport",
    name: "Cooktown Airport",
    elevation_ft: "26",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCKN",
    iata_code: "CTN",
    coordinates: "145.1840057373047, -15.444700241088867",
  },
  {
    ident: "YCMT",
    type: "medium_airport",
    name: "Clermont Airport",
    elevation_ft: "908",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCMT",
    iata_code: "CMQ",
    coordinates: "147.62100219726562, -22.773099899291992",
  },
  {
    ident: "YCMU",
    type: "medium_airport",
    name: "Cunnamulla Airport",
    elevation_ft: "630",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCMU",
    iata_code: "CMA",
    coordinates: "145.6219940185547, -28.030000686645508",
  },
  {
    ident: "YCMW",
    type: "small_airport",
    name: "Camooweal Airport",
    elevation_ft: "241",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCMW",
    iata_code: "CML",
    coordinates: "138.125, -19.911699295043945",
  },
  {
    ident: "YCNF",
    type: "small_airport",
    name: "Camp Nifty Airport",
    elevation_ft: "295",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YCNF",
    iata_code: "NIF",
    coordinates: "121.58699798583984, -21.67169952392578",
  },
  {
    ident: "YCNK",
    type: "small_airport",
    name: "Cessnock Airport",
    elevation_ft: "210",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCNK",
    iata_code: "CES",
    coordinates: "151.341995, -32.787498",
  },
  {
    ident: "YCNM",
    type: "medium_airport",
    name: "Coonamble Airport",
    elevation_ft: "604",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCNM",
    iata_code: "CNB",
    coordinates: "148.37600708007812, -30.983299255371094",
  },
  {
    ident: "YCOD",
    type: "small_airport",
    name: "Cordillo Downs Airport",
    elevation_ft: "125",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Cordillo Downs",
    gps_code: "YCOD",
    iata_code: "ODL",
    coordinates: "140.63800048828125, -26.74530029296875",
  },
  {
    ident: "YCOE",
    type: "medium_airport",
    name: "Coen Airport",
    elevation_ft: "532",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Coen",
    gps_code: "YCOE",
    iata_code: "CUQ",
    coordinates: "143.113311, -13.761133",
  },
  {
    ident: "YCOI",
    type: "small_airport",
    name: "Collie Airport",
    elevation_ft: "818",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Collie",
    gps_code: "YCOI",
    iata_code: "CIE",
    coordinates: "116.217002869, -33.3666992188",
  },
  {
    ident: "YCOM",
    type: "medium_airport",
    name: "Cooma Snowy Mountains Airport",
    elevation_ft: "3088",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Cooma",
    gps_code: "YCOM",
    iata_code: "OOM",
    coordinates: "148.973999023, -36.3005981445",
  },
  {
    ident: "YCOO",
    type: "small_airport",
    name: "Cooinda Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YCOO",
    iata_code: "CDA",
    coordinates: "132.53199768066406, -12.903300285339355",
  },
  {
    ident: "YCOR",
    type: "medium_airport",
    name: "Corowa Airport",
    elevation_ft: "469",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCOR",
    iata_code: "CWW",
    coordinates: "146.35699462890625, -35.99470138549805",
  },
  {
    ident: "YCPN",
    type: "small_airport",
    name: "Carpentaria Downs Airport",
    elevation_ft: "1585",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Carpentaria Downs",
    gps_code: "YCPN",
    iata_code: "CFP",
    coordinates: "144.317001343, -18.7166996002",
  },
  {
    ident: "YCRG",
    type: "medium_airport",
    name: "Corryong Airport",
    elevation_ft: "963",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YCRG",
    iata_code: "CYG",
    coordinates: "147.88800048828125, -36.18280029296875",
  },
  {
    ident: "YCRK",
    type: "small_airport",
    name: "Christmas Creek Station Airport",
    elevation_ft: "540",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Christmas Creek",
    gps_code: "YCRK",
    iata_code: "CXQ",
    coordinates: "125.9338, -18.8728",
  },
  {
    ident: "YCRY",
    type: "small_airport",
    name: "Croydon Airport",
    elevation_ft: "107",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCRY",
    iata_code: "CDQ",
    coordinates: "142.25799560546875, -18.225000381469727",
  },
  {
    ident: "YCSV",
    type: "small_airport",
    name: "Collinsville Airport",
    elevation_ft: "180",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YCSV",
    iata_code: "KCE",
    coordinates: "147.86000061035156, -20.59670066833496",
  },
  {
    ident: "YCTM",
    type: "medium_airport",
    name: "Cootamundra Airport",
    elevation_ft: "1110",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCTM",
    iata_code: "CMD",
    coordinates: "148.0279998779297, -34.6239013671875",
  },
  {
    ident: "YCUA",
    type: "small_airport",
    name: "Cudal Airport",
    elevation_ft: "491",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCUA",
    iata_code: "CUG",
    coordinates: "148.76300048828125, -33.27830123901367",
  },
  {
    ident: "YCUE",
    type: "small_airport",
    name: "Cue Airport",
    elevation_ft: "442",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YCUE",
    iata_code: "CUY",
    coordinates: "117.91799926757812, -27.446699142456055",
  },
  {
    ident: "YCWA",
    type: "small_airport",
    name: "Coondewanna Airport",
    elevation_ft: "2300",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Coondewanna",
    gps_code: "YCWA",
    iata_code: "CJF",
    coordinates: "118.81300354004, -22.96669960022",
  },
  {
    ident: "YCWI",
    type: "small_airport",
    name: "Cowarie Airport",
    elevation_ft: "52",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YCWI",
    iata_code: "CWR",
    coordinates: "138.3280029296875, -27.711700439453125",
  },
  {
    ident: "YCWL",
    type: "small_airport",
    name: "Cowell Airport",
    elevation_ft: "39",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YCWL",
    iata_code: "CCW",
    coordinates: "136.89199829101562, -33.66669845581055",
  },
  {
    ident: "YCWR",
    type: "medium_airport",
    name: "Cowra Airport",
    elevation_ft: "966",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YCWR",
    iata_code: "CWT",
    coordinates: "148.6490020751953, -33.84469985961914",
  },
  {
    ident: "YCWY",
    type: "small_airport",
    name: "Coolawanyah Airport",
    elevation_ft: "1195",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Coolawanyah Station",
    gps_code: "YCWY",
    iata_code: "COY",
    coordinates: "117.755, -21.7946",
  },
  {
    ident: "YDAJ",
    type: "small_airport",
    name: "Dajarra Airport",
    elevation_ft: "335",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDAJ",
    iata_code: "DJR",
    coordinates: "139.5330047607422, -21.70829963684082",
  },
  {
    ident: "YDAY",
    type: "small_airport",
    name: "Dalby Airport",
    elevation_ft: "1137",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDAY",
    iata_code: "DBY",
    coordinates: "151.26699829101562, -27.15530014038086",
  },
  {
    ident: "YDBI",
    type: "medium_airport",
    name: "Dirranbandi Airport",
    elevation_ft: "567",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDBI",
    iata_code: "DRN",
    coordinates: "148.2169952392578, -28.591699600219727",
  },
  {
    ident: "YDBR",
    type: "small_airport",
    name: "Dunbar Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDBR",
    iata_code: "DNB",
    coordinates: "142.39999389648438, -16.049999237060547",
  },
  {
    ident: "YDBY",
    type: "medium_airport",
    name: "Derby Airport",
    elevation_ft: "24",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YDBY",
    iata_code: "DRB",
    coordinates: "123.66100311279297, -17.3700008392334",
  },
  {
    ident: "YDDF",
    type: "small_airport",
    name: "Drumduff Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Drumduff",
    gps_code: "YDDF",
    iata_code: "DFP",
    coordinates: "143.011993408, -16.0529994965",
  },
  {
    ident: "YDGA",
    type: "small_airport",
    name: "Dalgaranga Gold Mine Airport",
    elevation_ft: "1459",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YDGA",
    iata_code: "DGD",
    coordinates: "117.316388889, -27.830277777800003",
  },
  {
    ident: "YDGN",
    type: "small_airport",
    name: "Doongan Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YDGN",
    iata_code: "DNG",
    coordinates: "126.302235, -15.386429",
  },
  {
    ident: "YDIX",
    type: "small_airport",
    name: "Dixie Airport",
    elevation_ft: "580",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "New Dixie",
    gps_code: "YDIX",
    iata_code: "DXD",
    coordinates: "143.316049576, -15.1174944348",
  },
  {
    ident: "YDKI",
    type: "small_airport",
    name: "Dunk Island Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Dunk Island",
    gps_code: "YDKI",
    iata_code: "DKI",
    coordinates: "146.13999939, -17.9416999817",
  },
  {
    ident: "YDLK",
    type: "small_airport",
    name: "Dulkaninna Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Dulkaninna",
    gps_code: "YDLK",
    iata_code: "DLK",
    coordinates: "138.4810028076172, -29.0132999420166",
  },
  {
    ident: "YDLQ",
    type: "medium_airport",
    name: "Deniliquin Airport",
    elevation_ft: "316",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Deniliquin",
    gps_code: "YDLQ",
    iata_code: "DNQ",
    coordinates: "144.945999146, -35.5593986511",
  },
  {
    ident: "YDLT",
    type: "small_airport",
    name: "Delta Downs Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDLT",
    iata_code: "DDN",
    coordinates: "141.31700134277344, -16.99169921875",
  },
  {
    ident: "YDLV",
    type: "small_airport",
    name: "Delissaville Airport",
    elevation_ft: "34",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YDLV",
    iata_code: "DLV",
    coordinates: "130.68499755859375, -12.550000190734863",
  },
  {
    ident: "YDLW",
    type: "small_airport",
    name: "Daly Waters Airport",
    elevation_ft: "700",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Daly Waters",
    gps_code: "YDLW",
    iata_code: "DYW",
    coordinates: "133.383378983, -16.264695066399998",
  },
  {
    ident: "YDMG",
    type: "small_airport",
    name: "Doomadgee Airport",
    elevation_ft: "153",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDMG",
    iata_code: "DMD",
    coordinates: "138.822006, -17.9403",
  },
  {
    ident: "YDMN",
    type: "small_airport",
    name: "Daly River Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YDMN",
    iata_code: "DVR",
    coordinates: "130.69387817382812, -13.74980640411377",
  },
  {
    ident: "YDNI",
    type: "small_airport",
    name: "Darnley Island Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Darnley Island",
    gps_code: "YDNI",
    iata_code: "NLF",
    coordinates: "143.76699829101562, -9.583330154418945",
  },
  {
    ident: "YDOR",
    type: "small_airport",
    name: "Dorunda Airport",
    elevation_ft: "58",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Dorunda Outstation",
    gps_code: "YDOR",
    iata_code: "DRD",
    coordinates: "141.8238, -16.5537",
  },
  {
    ident: "YDPD",
    type: "small_airport",
    name: "Davenport Downs Airport",
    elevation_ft: "95",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDPD",
    iata_code: "DVP",
    coordinates: "141.10800170898438, -24.149999618530273",
  },
  {
    ident: "YDPO",
    type: "medium_airport",
    name: "Devonport Airport",
    elevation_ft: "33",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    municipality: "Devonport",
    gps_code: "YDPO",
    iata_code: "DPO",
    coordinates: "146.429992676, -41.1697006226",
  },
  {
    ident: "YDRA",
    type: "small_airport",
    name: "Dongara Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YDRA",
    iata_code: "DOX",
    coordinates: "114.927334, -29.298128",
  },
  {
    ident: "YDRD",
    type: "small_airport",
    name: "Drysdale River Airport",
    elevation_ft: "360",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YDRD",
    iata_code: "DRY",
    coordinates: "126.38109684, -15.7135703992",
  },
  {
    ident: "YDRH",
    type: "small_airport",
    name: "Durham Downs Airport",
    elevation_ft: "116",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDRH",
    iata_code: "DHD",
    coordinates: "141.89999389648438, -27.075000762939453",
  },
  {
    ident: "YDRI",
    type: "small_airport",
    name: "Durrie Airport",
    elevation_ft: "58",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDRI",
    iata_code: "DRR",
    coordinates: "140.22799682617188, -25.684999465942383",
  },
  {
    ident: "YDUN",
    type: "small_airport",
    name: "Dunwich Airport",
    elevation_ft: "260",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Stradbroke Island",
    gps_code: "YDUN",
    iata_code: "SRR",
    coordinates: "153.42799377441, -27.516700744629",
  },
  {
    ident: "YDVR",
    type: "small_airport",
    name: "Docker River Airport",
    elevation_ft: "589",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YDVR",
    iata_code: "DKV",
    coordinates: "129.07000732421875, -24.860000610351562",
  },
  {
    ident: "YDYS",
    type: "medium_airport",
    name: "Dysart Airport",
    elevation_ft: "670",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YDYS",
    iata_code: "DYA",
    coordinates: "148.36399841308594, -22.62220001220703",
  },
  {
    ident: "YE-WDA",
    type: "small_airport",
    name: "Wadi Ain Airport",
    continent: "AS",
    iso_country: "YE",
    iso_region: "YE-BA",
    municipality: "Wadi Ain",
    iata_code: "WDA",
    coordinates: "45.54999923706055, 14.866999626159668",
  },
  {
    ident: "YECH",
    type: "medium_airport",
    name: "Echuca Airport",
    elevation_ft: "323",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YECH",
    iata_code: "ECH",
    coordinates: "144.76199340820312, -36.15719985961914",
  },
  {
    ident: "YECL",
    type: "small_airport",
    name: "Eucla Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YECL",
    iata_code: "EUC",
    coordinates: "128.88299560546875, -31.700000762939453",
  },
  {
    ident: "YEDA",
    type: "small_airport",
    name: "Etadunna Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Etadunna",
    gps_code: "YEDA",
    iata_code: "ETD",
    coordinates: "138.58900451660156, -28.740800857543945",
  },
  {
    ident: "YEEB",
    type: "small_airport",
    name: "Eneabba Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Eneabba",
    gps_code: "YEEB",
    iata_code: "ENB",
    coordinates: "115.24600219726562, -29.832500457763672",
  },
  {
    ident: "YEIN",
    type: "small_airport",
    name: "Einasleigh Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Einasleigh",
    gps_code: "YEIN",
    iata_code: "EIH",
    coordinates: "144.093994141, -18.503299713100002",
  },
  {
    ident: "YELD",
    type: "medium_airport",
    name: "Elcho Island Airport",
    elevation_ft: "101",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Elcho Island",
    gps_code: "YELD",
    iata_code: "ELC",
    coordinates: "135.570999146, -12.019399642899998",
  },
  {
    ident: "YELK",
    type: "small_airport",
    name: "Elkedra Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YELK",
    iata_code: "EKD",
    coordinates: "135.444000244, -21.172500610399997",
  },
  {
    ident: "YEML",
    type: "medium_airport",
    name: "Emerald Airport",
    elevation_ft: "624",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Emerald",
    gps_code: "YEML",
    iata_code: "EMD",
    coordinates: "148.179000854, -23.5674991608",
  },
  {
    ident: "YEQ",
    type: "small_airport",
    name: "Yenkis(Yankisa) Airport",
    elevation_ft: "3692",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-EPW",
    gps_code: "AYYK",
    iata_code: "YEQ",
    local_code: "YIS",
    coordinates: "143.917666667, -5.10972222222",
  },
  {
    ident: "YERL",
    type: "small_airport",
    name: "Erldunda Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YERL",
    iata_code: "EDD",
    coordinates: "133.253997803, -25.205799102800004",
  },
  {
    ident: "YERN",
    type: "small_airport",
    name: "Ernabella Airport",
    elevation_ft: "707",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YERN",
    iata_code: "ERB",
    coordinates: "132.1820068359375, -26.2632999420166",
  },
  {
    ident: "YESE",
    type: "small_airport",
    name: "Elrose Airport",
    elevation_ft: "645",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Elrose Mine",
    gps_code: "YESE",
    iata_code: "ERQ",
    local_code: "YESE",
    coordinates: "141.0066, -20.9764",
  },
  {
    ident: "YESP",
    type: "medium_airport",
    name: "Esperance Airport",
    elevation_ft: "470",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Esperance",
    gps_code: "YESP",
    iata_code: "EPR",
    coordinates: "121.822998, -33.684399",
  },
  {
    ident: "YEVA",
    type: "small_airport",
    name: "Eva Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Eva Downs",
    gps_code: "YEVA",
    iata_code: "EVD",
    coordinates: "134.863006592, -18.0009994507",
  },
  {
    ident: "YEVD",
    type: "small_airport",
    name: "Evans Head Aerodrome",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YEVD",
    iata_code: "EVH",
    coordinates: "153.4199981689453, -29.093299865722656",
  },
  {
    ident: "YEXM",
    type: "small_airport",
    name: "Exmouth Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YEXM",
    iata_code: "EXM",
    coordinates: "114.0999984741211, -22.033300399780273",
  },
  {
    ident: "YFBS",
    type: "medium_airport",
    name: "Forbes Airport",
    elevation_ft: "760",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Forbes",
    gps_code: "YFBS",
    iata_code: "FRB",
    coordinates: "147.934998, -33.363602",
  },
  {
    ident: "YFDF",
    type: "small_airport",
    name: "Fortescue - Dave Forrest Aerodrome",
    elevation_ft: "1555",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Cloudbreak Village",
    gps_code: "YFDF",
    iata_code: "KFE",
    coordinates: "119.437143, -22.290754",
  },
  {
    ident: "YFIL",
    type: "small_airport",
    name: "Finley Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YFIL",
    iata_code: "FLY",
    coordinates: "145.550003052, -35.666698455799995",
  },
  {
    ident: "YFLI",
    type: "small_airport",
    name: "Flinders Island Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    municipality: "Flinders Island",
    gps_code: "YFLI",
    iata_code: "FLS",
    coordinates: "147.992996216, -40.0917015076",
  },
  {
    ident: "YFLO",
    type: "small_airport",
    name: "Flora Valley Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YFLO",
    iata_code: "FVL",
    coordinates: "128.417007446, -18.2833003998",
  },
  {
    ident: "YFNE",
    type: "small_airport",
    name: "Finke Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Finke",
    gps_code: "YFNE",
    iata_code: "FIK",
    coordinates: "134.582992554, -25.5946998596",
  },
  {
    ident: "YFRT",
    type: "medium_airport",
    name: "Forrest Airport",
    elevation_ft: "511",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YFRT",
    iata_code: "FOS",
    coordinates: "128.11500549316406, -30.83810043334961",
  },
  {
    ident: "YFRV",
    type: "small_airport",
    name: "Oombulgurri Airport",
    elevation_ft: "22",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Forrest River Mission",
    gps_code: "YFRV",
    iata_code: "FVR",
    local_code: "YFRV",
    coordinates: "127.8401, -15.1647",
  },
  {
    ident: "YFST",
    type: "small_airport",
    name: "Forster (Wallis Is) Airport",
    elevation_ft: "4",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YFST",
    iata_code: "FOT",
    coordinates: "152.47900390625, -32.204200744628906",
  },
  {
    ident: "YFTZ",
    type: "medium_airport",
    name: "Fitzroy Crossing Airport",
    elevation_ft: "368",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YFTZ",
    iata_code: "FIZ",
    coordinates: "125.55899810791016, -18.181900024414062",
  },
  {
    ident: "YGAM",
    type: "small_airport",
    name: "Gamboola Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YGAM",
    iata_code: "GBP",
    coordinates: "143.667007446, -16.5499992371",
  },
  {
    ident: "YGAY",
    type: "small_airport",
    name: "Gayndah Airport",
    elevation_ft: "369",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YGAY",
    iata_code: "GAH",
    coordinates: "151.61900329589844, -25.61440086364746",
  },
  {
    ident: "YGBI",
    type: "small_airport",
    name: "South Goulburn Is Airport",
    elevation_ft: "19",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YGBI",
    iata_code: "GBL",
    coordinates: "133.3820037841797, -11.649999618530273",
  },
  {
    ident: "YGDH",
    type: "medium_airport",
    name: "Gunnedah Airport",
    elevation_ft: "863",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YGDH",
    iata_code: "GUH",
    coordinates: "150.25100708007812, -30.96109962463379",
  },
  {
    ident: "YGDI",
    type: "small_airport",
    name: "Goondiwindi Airport",
    elevation_ft: "714",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YGDI",
    iata_code: "GOO",
    coordinates: "150.32000732421875, -28.521400451660156",
  },
  {
    ident: "YGDN",
    type: "small_airport",
    name: "Gordon Downs Airport",
    elevation_ft: "800",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Gordon Downs",
    gps_code: "YGDN",
    iata_code: "GDD",
    coordinates: "128.5919952392578, -18.6781005859375",
  },
  {
    ident: "YGDS",
    type: "small_airport",
    name: "Gregory Downs Airport",
    elevation_ft: "52",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YGDS",
    iata_code: "GGD",
    coordinates: "139.23300170898438, -18.625",
  },
  {
    ident: "YGDW",
    type: "small_airport",
    name: "Granite Downs Airport",
    elevation_ft: "337",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YGDW",
    iata_code: "GTS",
    coordinates: "133.60699462890625, -26.948299407958984",
  },
  {
    ident: "YGE",
    type: "seaplane_base",
    name: "Gorge Harbour Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Gorge Harbour",
    iata_code: "YGE",
    coordinates: "-125.0235, 50.0994",
  },
  {
    ident: "YGEL",
    type: "medium_airport",
    name: "Geraldton Airport",
    elevation_ft: "121",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Geraldton",
    gps_code: "YGEL",
    iata_code: "GET",
    coordinates: "114.707001, -28.796101",
  },
  {
    ident: "YGFN",
    type: "medium_airport",
    name: "Grafton Airport",
    elevation_ft: "110",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YGFN",
    iata_code: "GFN",
    coordinates: "153.02999877929688, -29.7593994140625",
  },
  {
    ident: "YGIA",
    type: "small_airport",
    name: "Ginbata Airport",
    elevation_ft: "1409",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Ginbata",
    gps_code: "YGIA",
    iata_code: "GBW",
    coordinates: "120.03553, -22.5812",
  },
  {
    ident: "YGIB",
    type: "small_airport",
    name: "Gibb River Airport",
    elevation_ft: "509",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YGIB",
    iata_code: "GBV",
    coordinates: "126.44499969482422, -16.42329978942871",
  },
  {
    ident: "YGKL",
    type: "small_airport",
    name: "Great Keppel Is Airport",
    elevation_ft: "21",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Great Keppel Island",
    gps_code: "YGKL",
    iata_code: "GKL",
    coordinates: "150.942001343, -23.1833000183",
  },
  {
    ident: "YGLA",
    type: "medium_airport",
    name: "Gladstone Airport",
    elevation_ft: "64",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Gladstone",
    gps_code: "YGLA",
    iata_code: "GLT",
    local_code: "4680",
    coordinates: "151.223007, -23.869699",
  },
  {
    ident: "YGLB",
    type: "medium_airport",
    name: "Goulburn Airport",
    elevation_ft: "2141",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YGLB",
    iata_code: "GUL",
    coordinates: "149.7259979248047, -34.810298919677734",
  },
  {
    ident: "YGLE",
    type: "small_airport",
    name: "Glengyle Airport",
    elevation_ft: "91",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YGLE",
    iata_code: "GLG",
    coordinates: "139.60000610351562, -24.808300018310547",
  },
  {
    ident: "YGLG",
    type: "small_airport",
    name: "Geelong Airport",
    elevation_ft: "43",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YGLG",
    iata_code: "GEX",
    coordinates: "144.33299255371094, -38.224998474121094",
  },
  {
    ident: "YGLI",
    type: "medium_airport",
    name: "Glen Innes Airport",
    elevation_ft: "3433",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YGLI",
    iata_code: "GLI",
    coordinates: "151.68899536132812, -29.674999237060547",
  },
  {
    ident: "YGLO",
    type: "small_airport",
    name: "Glenormiston Airport",
    elevation_ft: "158",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YGLO",
    iata_code: "GLM",
    coordinates: "138.8249969482422, -22.8882999420166",
  },
  {
    ident: "YGN",
    type: "seaplane_base",
    name: "Greenway Sound Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Broughton Island",
    gps_code: "CYGN",
    iata_code: "YGN",
    local_code: "CYGN",
    coordinates: "-126.775, 50.839",
  },
  {
    ident: "YGNF",
    type: "small_airport",
    name: "Grenfell Airport",
    elevation_ft: "1080",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Grenfell",
    gps_code: "YGNF",
    iata_code: "GFE",
    coordinates: "148.132995605, -34",
  },
  {
    ident: "YGNV",
    type: "small_airport",
    name: "Greenvale Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YGNV",
    iata_code: "GVP",
    coordinates: "145.0136, -18.9966",
  },
  {
    ident: "YGON",
    type: "small_airport",
    name: "Mount Gordon Airport",
    elevation_ft: "900",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Mount Gordon Mine",
    gps_code: "YGON",
    iata_code: "GPD",
    coordinates: "139.40425, -19.77265",
  },
  {
    ident: "YGPT",
    type: "medium_airport",
    name: "Garden Point Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YGPT",
    iata_code: "GPN",
    coordinates: "130.4219970703125, -11.40250015258789",
  },
  {
    ident: "YGSC",
    type: "small_airport",
    name: "Gascoyne Junction Airport",
    elevation_ft: "499",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Gascoyne Junction",
    gps_code: "YGSC",
    iata_code: "GSC",
    coordinates: "115.2026, -25.0546",
  },
  {
    ident: "YGTE",
    type: "medium_airport",
    name: "Groote Eylandt Airport",
    elevation_ft: "53",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Groote Eylandt",
    gps_code: "YGTE",
    iata_code: "GTE",
    coordinates: "136.460006714, -13.975000381500001",
  },
  {
    ident: "YGTH",
    type: "medium_airport",
    name: "Griffith Airport",
    elevation_ft: "439",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Griffith",
    gps_code: "YGTH",
    iata_code: "GFF",
    coordinates: "146.067001343, -34.2508010864",
  },
  {
    ident: "YGTN",
    type: "small_airport",
    name: "Georgetown Airport",
    elevation_ft: "995",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YGTN",
    iata_code: "GTT",
    coordinates: "143.52999877929688, -18.30500030517578",
  },
  {
    ident: "YGTO",
    type: "small_airport",
    name: "Georgetown (Tas) Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    gps_code: "YGTO",
    iata_code: "GEE",
    coordinates: "146.83999633789062, -41.08000183105469",
  },
  {
    ident: "YGYM",
    type: "small_airport",
    name: "Gympie Airport",
    elevation_ft: "260",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YGYM",
    iata_code: "GYP",
    coordinates: "152.70199584960938, -26.282800674438477",
  },
  {
    ident: "YHAW",
    type: "small_airport",
    name: "Wilpena Pound Airport",
    elevation_ft: "321",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Hawker",
    gps_code: "YHAW",
    iata_code: "HWK",
    coordinates: "138.46807861328125, -31.855907440185547",
  },
  {
    ident: "YHAY",
    type: "medium_airport",
    name: "Hay Airport",
    elevation_ft: "305",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YHAY",
    iata_code: "HXX",
    coordinates: "144.8300018310547, -34.53139877319336",
  },
  {
    ident: "YHBA",
    type: "medium_airport",
    name: "Hervey Bay Airport",
    elevation_ft: "60",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Hervey Bay",
    gps_code: "YHBA",
    iata_code: "HVB",
    coordinates: "152.880004883, -25.3188991547",
  },
  {
    ident: "YHBR",
    type: "small_airport",
    name: "Humbert River Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YHBR",
    iata_code: "HUB",
    coordinates: "130.63027954101562, -16.489669799804688",
  },
  {
    ident: "YHBY",
    type: "small_airport",
    name: "Henbury Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YHBY",
    iata_code: "HRY",
    coordinates: "133.236, -24.584",
  },
  {
    ident: "YHDY",
    type: "small_airport",
    name: "Headingly Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YHDY",
    iata_code: "HIP",
    coordinates: "138.2830047607422, -21.33329963684082",
  },
  {
    ident: "YHHY",
    type: "small_airport",
    name: "Highbury Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YHHY",
    iata_code: "HIG",
    coordinates: "143.145996094, -16.4244003296",
  },
  {
    ident: "YHID",
    type: "medium_airport",
    name: "Horn Island Airport",
    elevation_ft: "43",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Horn Island",
    gps_code: "YHID",
    iata_code: "HID",
    coordinates: "142.289993286, -10.586400032",
  },
  {
    ident: "YHIL",
    type: "small_airport",
    name: "Hillside Airport",
    elevation_ft: "935",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Hillside",
    gps_code: "YHIL",
    iata_code: "HLL",
    coordinates: "119.3922, -21.7244",
  },
  {
    ident: "YHLC",
    type: "medium_airport",
    name: "Halls Creek Airport",
    elevation_ft: "1346",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YHLC",
    iata_code: "HCQ",
    coordinates: "127.66999816894531, -18.23390007019043",
  },
  {
    ident: "YHMB",
    type: "small_airport",
    name: "Hermannsburg Airport",
    elevation_ft: "593",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YHMB",
    iata_code: "HMG",
    coordinates: "132.80499267578125, -23.93000030517578",
  },
  {
    ident: "YHML",
    type: "medium_airport",
    name: "Hamilton Airport",
    elevation_ft: "803",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YHML",
    iata_code: "HLT",
    coordinates: "142.06500244140625, -37.64889907836914",
  },
  {
    ident: "YHOO",
    type: "small_airport",
    name: "Hooker Creek Airport",
    elevation_ft: "320",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Lajamanu",
    gps_code: "YHOO",
    iata_code: "HOK",
    coordinates: "130.638000488, -18.3367004395",
  },
  {
    ident: "YHOT",
    type: "medium_airport",
    name: "Mount Hotham Airport",
    elevation_ft: "4260",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Mount Hotham",
    gps_code: "YHOT",
    iata_code: "MHU",
    coordinates: "147.333999634, -37.0475006104",
  },
  {
    ident: "YHPN",
    type: "medium_airport",
    name: "Hopetoun Airport",
    elevation_ft: "256",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YHPN",
    iata_code: "HTU",
    coordinates: "142.36000061035156, -35.715301513671875",
  },
  {
    ident: "YHPV",
    type: "small_airport",
    name: "Hope Vale Airport",
    elevation_ft: "228",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Hope Vale",
    gps_code: "YHPV",
    iata_code: "HPE",
    local_code: "YHPV",
    coordinates: "145.1035, -15.2923",
  },
  {
    ident: "YHSM",
    type: "medium_airport",
    name: "Horsham Airport",
    elevation_ft: "445",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YHSM",
    iata_code: "HSM",
    coordinates: "142.17300415039062, -36.669700622558594",
  },
  {
    ident: "YHTL",
    type: "small_airport",
    name: "Heathlands Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YHTL",
    iata_code: "HAT",
    coordinates: "142.57699585, -11.7369003296",
  },
  {
    ident: "YHUG",
    type: "small_airport",
    name: "Hughenden Airport",
    elevation_ft: "1043",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YHUG",
    iata_code: "HGD",
    coordinates: "144.22500610351562, -20.815000534057617",
  },
  {
    ident: "YIDK",
    type: "small_airport",
    name: "Indulkana Airport",
    elevation_ft: "401",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YIDK",
    iata_code: "IDK",
    coordinates: "133.3249969482422, -26.966699600219727",
  },
  {
    ident: "YIFL",
    type: "small_airport",
    name: "Innisfail Airport",
    elevation_ft: "46",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YIFL",
    iata_code: "IFL",
    coordinates: "146.01199340820312, -17.55940055847168",
  },
  {
    ident: "YIFY",
    type: "small_airport",
    name: "Iffley Airport",
    elevation_ft: "43",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YIFY",
    iata_code: "IFF",
    coordinates: "141.2169952392578, -18.899999618530273",
  },
  {
    ident: "YIGM",
    type: "small_airport",
    name: "Ingham Airport",
    elevation_ft: "49",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YIGM",
    iata_code: "IGH",
    coordinates: "146.15199279785156, -18.660600662231445",
  },
  {
    ident: "YIKM",
    type: "small_airport",
    name: "Inkerman Airport",
    elevation_ft: "6",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YIKM",
    iata_code: "IKP",
    coordinates: "141.44200134277344, -16.274999618530273",
  },
  {
    ident: "YINJ",
    type: "small_airport",
    name: "Injune Airport",
    elevation_ft: "401",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Injune",
    gps_code: "YINJ",
    iata_code: "INJ",
    coordinates: "148.5497, -25.851",
  },
  {
    ident: "YINN",
    type: "small_airport",
    name: "Innamincka Airport",
    elevation_ft: "54",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YINN",
    iata_code: "INM",
    coordinates: "140.73300170898438, -27.700000762939453",
  },
  {
    ident: "YINW",
    type: "small_airport",
    name: "Inverway Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Inverway",
    gps_code: "YINW",
    iata_code: "IVW",
    coordinates: "129.643005371, -17.8411006927",
  },
  {
    ident: "YISF",
    type: "small_airport",
    name: "Isisford Airport",
    elevation_ft: "694",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YISF",
    iata_code: "ISI",
    coordinates: "144.4250030517578, -24.25830078125",
  },
  {
    ident: "YIVL",
    type: "medium_airport",
    name: "Inverell Airport",
    elevation_ft: "2667",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Inverell",
    gps_code: "YIVL",
    iata_code: "IVR",
    coordinates: "151.143997192, -29.888299942",
  },
  {
    ident: "YJAB",
    type: "small_airport",
    name: "Jabiru Airport",
    elevation_ft: "85",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YJAB",
    iata_code: "JAB",
    coordinates: "132.89300537109375, -12.658300399780273",
  },
  {
    ident: "YJDA",
    type: "small_airport",
    name: "Jundah Airport",
    elevation_ft: "145",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YJDA",
    iata_code: "JUN",
    coordinates: "143.05799865722656, -24.841699600219727",
  },
  {
    ident: "YJIN",
    type: "small_airport",
    name: "Jindabyne Airport",
    elevation_ft: "1036",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YJIN",
    iata_code: "QJD",
    coordinates: "148.6020050048828, -36.426700592041016",
  },
  {
    ident: "YJLC",
    type: "small_airport",
    name: "Julia Creek Airport",
    elevation_ft: "404",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YJLC",
    iata_code: "JCK",
    coordinates: "141.72300720214844, -20.66830062866211",
  },
  {
    ident: "YJNB",
    type: "small_airport",
    name: "Jurien Bay Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YJNB",
    iata_code: "JUR",
    coordinates: "115.055914, -30.301629",
  },
  {
    ident: "YKAL",
    type: "small_airport",
    name: "Kalumburu Airport",
    elevation_ft: "29",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YKAL",
    iata_code: "UBU",
    coordinates: "126.63200378417969, -14.288299560546875",
  },
  {
    ident: "YKBL",
    type: "small_airport",
    name: "Kambalda Airport",
    elevation_ft: "1073",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Kambalda",
    gps_code: "YKBL",
    iata_code: "KDB",
    coordinates: "121.5978, -31.1907",
  },
  {
    ident: "YKBR",
    type: "small_airport",
    name: "Kalbarri Airport",
    elevation_ft: "157",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Kalbarri",
    gps_code: "YKBR",
    iata_code: "KAX",
    coordinates: "114.259169, -27.692813",
  },
  {
    ident: "YKBY",
    type: "small_airport",
    name: "Streaky Bay Airport",
    elevation_ft: "69",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YKBY",
    iata_code: "KBY",
    coordinates: "134.29299926757812, -32.83580017089844",
  },
  {
    ident: "YKCA",
    type: "small_airport",
    name: "Kings Canyon Airport",
    elevation_ft: "2100",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Kings Canyon Resort",
    gps_code: "YKCA",
    iata_code: "KBJ",
    coordinates: "131.490005493, -24.2600002289",
  },
  {
    ident: "YKCS",
    type: "small_airport",
    name: "Kings Creek Airport",
    elevation_ft: "615",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YKCS",
    iata_code: "KCS",
    coordinates: "131.8350067138672, -24.42329978942871",
  },
  {
    ident: "YKER",
    type: "medium_airport",
    name: "Kerang Airport",
    elevation_ft: "254",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YKER",
    iata_code: "KRA",
    coordinates: "143.93899536132812, -35.751399993896484",
  },
  {
    ident: "YKII",
    type: "medium_airport",
    name: "King Island Airport",
    elevation_ft: "132",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    gps_code: "YKII",
    iata_code: "KNS",
    coordinates: "143.8780059814453, -39.877498626708984",
  },
  {
    ident: "YKIR",
    type: "small_airport",
    name: "Kirkimbie Station Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Kirkimbie",
    gps_code: "YKIR",
    iata_code: "KBB",
    coordinates: "129.210006714, -17.7791996002",
  },
  {
    ident: "YKKG",
    type: "small_airport",
    name: "Kalkgurung Airport",
    elevation_ft: "646",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YKKG",
    iata_code: "KFG",
    coordinates: "130.80799865722656, -17.431900024414062",
  },
  {
    ident: "YKLA",
    type: "small_airport",
    name: "Koolatah Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YKLA",
    iata_code: "KOH",
    coordinates: "142.438995361, -15.888600349399999",
  },
  {
    ident: "YKLB",
    type: "small_airport",
    name: "Koolburra Airport",
    elevation_ft: "350",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Koolburra",
    gps_code: "YKLB",
    iata_code: "KKP",
    coordinates: "143.9550018310547, -15.318900108337402",
  },
  {
    ident: "YKMB",
    type: "small_airport",
    name: "Karumba Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YKMB",
    iata_code: "KRB",
    coordinates: "140.8300018310547, -17.45669937133789",
  },
  {
    ident: "YKML",
    type: "small_airport",
    name: "Kamileroi Airport",
    elevation_ft: "91",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YKML",
    iata_code: "KML",
    coordinates: "140.0570068359375, -19.375",
  },
  {
    ident: "YKMP",
    type: "medium_airport",
    name: "Kempsey Airport",
    elevation_ft: "54",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YKMP",
    iata_code: "KPS",
    coordinates: "152.77000427246094, -31.074399948120117",
  },
  {
    ident: "YKNG",
    type: "small_airport",
    name: "Katanning Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YKNG",
    iata_code: "KNI",
    coordinates: "117.63300323486328, -33.71670150756836",
  },
  {
    ident: "YKOW",
    type: "medium_airport",
    name: "Kowanyama Airport",
    elevation_ft: "35",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Kowanyama",
    gps_code: "YKOW",
    iata_code: "KWM",
    coordinates: "141.75100708007812, -15.485600471496582",
  },
  {
    ident: "YKPR",
    type: "small_airport",
    name: "Kalpowar Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Kalpower",
    gps_code: "YKPR",
    iata_code: "KPP",
    coordinates: "144.22, -14.89",
  },
  {
    ident: "YKRY",
    type: "medium_airport",
    name: "Kingaroy Airport",
    elevation_ft: "1492",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YKRY",
    iata_code: "KGY",
    coordinates: "151.84100341796875, -26.580799102783203",
  },
  {
    ident: "YKSC",
    type: "medium_airport",
    name: "Kingscote Airport",
    elevation_ft: "24",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YKSC",
    iata_code: "KGC",
    coordinates: "137.52099609375, -35.71390151977539",
  },
  {
    ident: "YKT",
    type: "seaplane_base",
    name: "Klemtu Water Aerodrome",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Klemtu",
    iata_code: "YKT",
    coordinates: "-128.521757126, 52.6076345452",
  },
  {
    ident: "YKUB",
    type: "small_airport",
    name: "Kubin Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Moa Island",
    gps_code: "YKUB",
    iata_code: "KUG",
    coordinates: "142.218002319, -10.225000381500001",
  },
  {
    ident: "YKUR",
    type: "small_airport",
    name: "Kurundi Airport",
    elevation_ft: "1340",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Kurundi Station",
    gps_code: "YKUR",
    iata_code: "KRD",
    local_code: "YKUR",
    coordinates: "134.6706, -20.51",
  },
  {
    ident: "YLAH",
    type: "small_airport",
    name: "Lawn Hill Airport",
    elevation_ft: "122",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YLAH",
    iata_code: "LWH",
    coordinates: "138.63499450683594, -18.568300247192383",
  },
  {
    ident: "YLEC",
    type: "medium_airport",
    name: "Leigh Creek Airport",
    elevation_ft: "856",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YLEC",
    iata_code: "LGH",
    coordinates: "138.42599487304688, -30.59830093383789",
  },
  {
    ident: "YLEO",
    type: "medium_airport",
    name: "Leonora Airport",
    elevation_ft: "1217",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Leonora",
    gps_code: "YLEO",
    iata_code: "LNO",
    coordinates: "121.31500244140625, -28.87809944152832",
  },
  {
    ident: "YLEV",
    type: "small_airport",
    name: "Lake Evella Airport",
    elevation_ft: "256",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YLEV",
    iata_code: "LEL",
    coordinates: "135.80599975585938, -12.498900413513184",
  },
  {
    ident: "YLFD",
    type: "small_airport",
    name: "Lakefield Airport",
    elevation_ft: "107",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Lakefield",
    gps_code: "YLFD",
    iata_code: "LFP",
    coordinates: "144.203, -14.9207",
  },
  {
    ident: "YLHI",
    type: "small_airport",
    name: "Lord Howe Island Airport",
    elevation_ft: "5",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Lord Howe Island",
    gps_code: "YLHI",
    iata_code: "LDH",
    coordinates: "159.07699585, -31.5382995605",
  },
  {
    ident: "YLHR",
    type: "medium_airport",
    name: "Lockhart River Airport",
    elevation_ft: "77",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Lockhart River",
    gps_code: "YLHR",
    iata_code: "IRG",
    coordinates: "143.304993, -12.7869",
  },
  {
    ident: "YLHS",
    type: "small_airport",
    name: "Lyndhurst Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Lyndhurst",
    gps_code: "YLHS",
    iata_code: "LTP",
    coordinates: "144.371002197, -19.1958007812",
  },
  {
    ident: "YLIM",
    type: "small_airport",
    name: "Limbunya Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Limbunya",
    gps_code: "YLIM",
    iata_code: "LIB",
    coordinates: "129.882003784, -17.2355995178",
  },
  {
    ident: "YLIN",
    type: "small_airport",
    name: "Lindeman Island Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Lindeman Island",
    gps_code: "YLIN",
    iata_code: "LDC",
    coordinates: "149.039993286, -20.4535999298",
  },
  {
    ident: "YLIS",
    type: "medium_airport",
    name: "Lismore Airport",
    elevation_ft: "35",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Lismore",
    gps_code: "YLIS",
    iata_code: "LSY",
    coordinates: "153.259994507, -28.8302993774",
  },
  {
    ident: "YLKN",
    type: "small_airport",
    name: "Lake Nash Airport",
    elevation_ft: "640",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Alpurrurulam",
    gps_code: "YLKN",
    iata_code: "LNH",
    coordinates: "137.9178, -20.9807",
  },
  {
    ident: "YLLE",
    type: "small_airport",
    name: "Ballera Airport",
    elevation_ft: "385",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YLLE",
    iata_code: "BBL",
    coordinates: "141.809458, -27.405633",
  },
  {
    ident: "YLND",
    type: "small_airport",
    name: "Lakeland Airport",
    elevation_ft: "930",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Lakeland Downs",
    gps_code: "YLND",
    iata_code: "LKD",
    coordinates: "144.850006104, -15.8332996368",
  },
  {
    ident: "YLOK",
    type: "small_airport",
    name: "Lock Airport",
    elevation_ft: "350",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Lock",
    gps_code: "YLOK",
    iata_code: "LOC",
    coordinates: "135.6929931640625, -33.5442008972168",
  },
  {
    ident: "YLOR",
    type: "small_airport",
    name: "Lorraine Airport",
    elevation_ft: "61",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YLOR",
    iata_code: "LOA",
    coordinates: "139.90699768066406, -18.99329948425293",
  },
  {
    ident: "YLOV",
    type: "small_airport",
    name: "Lotus Vale Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Lotus Vale",
    gps_code: "YLOV",
    iata_code: "LTV",
    coordinates: "141.37600708, -17.048299789399998",
  },
  {
    ident: "YLP",
    type: "small_airport",
    name: "Mingan Airport",
    elevation_ft: "70",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "Mingan",
    iata_code: "YLP",
    coordinates: "-64.1528, 50.2869",
  },
  {
    ident: "YLRA",
    type: "small_airport",
    name: "Laura Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YLRA",
    iata_code: "LUU",
    coordinates: "144.4499969482422, -15.550000190734863",
  },
  {
    ident: "YLRD",
    type: "medium_airport",
    name: "Lightning Ridge Airport",
    elevation_ft: "540",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YLRD",
    iata_code: "LHG",
    coordinates: "147.98399353027344, -29.45669937133789",
  },
  {
    ident: "YLRE",
    type: "medium_airport",
    name: "Longreach Airport",
    elevation_ft: "627",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Longreach",
    gps_code: "YLRE",
    iata_code: "LRE",
    coordinates: "144.279998779, -23.4342002869",
  },
  {
    ident: "YLRS",
    type: "small_airport",
    name: "New Laura Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YLRS",
    iata_code: "LUT",
    coordinates: "144.36700439453125, -15.183300018310547",
  },
  {
    ident: "YLST",
    type: "medium_airport",
    name: "Leinster Airport",
    elevation_ft: "1631",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YLST",
    iata_code: "LER",
    coordinates: "120.7030029296875, -27.843299865722656",
  },
  {
    ident: "YLTN",
    type: "small_airport",
    name: "Laverton Airport",
    elevation_ft: "1530",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YLTN",
    iata_code: "LVO",
    coordinates: "122.42400360107422, -28.61359977722168",
  },
  {
    ident: "YLTV",
    type: "medium_airport",
    name: "Latrobe Valley Airport",
    elevation_ft: "180",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Morwell",
    gps_code: "YLTV",
    iata_code: "TGN",
    coordinates: "146.470001, -38.207199",
  },
  {
    ident: "YLZI",
    type: "small_airport",
    name: "Lizard Island Airport",
    elevation_ft: "70",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YLZI",
    iata_code: "LZR",
    coordinates: "145.454571, -14.673273",
  },
  {
    ident: "YMAA",
    type: "small_airport",
    name: "Mabuiag Island Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Mabuiag Island",
    gps_code: "YMAA",
    iata_code: "UBB",
    coordinates: "142.18299865722656, -9.949999809265137",
  },
  {
    ident: "YMAV",
    type: "medium_airport",
    name: "Avalon Airport",
    elevation_ft: "35",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Melbourne",
    gps_code: "YMAV",
    iata_code: "AVV",
    coordinates: "144.468994, -38.039398",
  },
  {
    ident: "YMAY",
    type: "medium_airport",
    name: "Albury Airport",
    elevation_ft: "539",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Albury",
    gps_code: "YMAY",
    iata_code: "ABX",
    coordinates: "146.95799255371094, -36.06779861450195",
  },
  {
    ident: "YMBA",
    type: "medium_airport",
    name: "Mareeba Airport",
    elevation_ft: "1560",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMBA",
    iata_code: "MRG",
    coordinates: "145.41900634765625, -17.06920051574707",
  },
  {
    ident: "YMBL",
    type: "small_airport",
    name: "Marble Bar Airport",
    elevation_ft: "194",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMBL",
    iata_code: "MBB",
    coordinates: "119.83300018310547, -21.163299560546875",
  },
  {
    ident: "YMCO",
    type: "small_airport",
    name: "Mallacoota Airport",
    elevation_ft: "31",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YMCO",
    iata_code: "XMC",
    coordinates: "149.72000122070312, -37.59830093383789",
  },
  {
    ident: "YMCR",
    type: "small_airport",
    name: "Manners Creek Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YMCR",
    iata_code: "MFP",
    coordinates: "137.98300170898438, -22.100000381469727",
  },
  {
    ident: "YMCT",
    type: "small_airport",
    name: "Millicent Airport",
    elevation_ft: "56",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YMCT",
    iata_code: "MLR",
    coordinates: "140.36599731445312, -37.58359909057617",
  },
  {
    ident: "YMDG",
    type: "medium_airport",
    name: "Mudgee Airport",
    elevation_ft: "1545",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Mudgee",
    gps_code: "YMDG",
    iata_code: "DGE",
    coordinates: "149.610992432, -32.5625",
  },
  {
    ident: "YMDI",
    type: "small_airport",
    name: "Mandora Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Mandora",
    gps_code: "YMDI",
    iata_code: "MQA",
    coordinates: "120.837997437, -19.7383003235",
  },
  {
    ident: "YMDS",
    type: "small_airport",
    name: "Macdonald Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YMDS",
    iata_code: "MNW",
    coordinates: "135.199005127, -22.444000244099996",
  },
  {
    ident: "YMEK",
    type: "medium_airport",
    name: "Meekatharra Airport",
    elevation_ft: "1713",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMEK",
    iata_code: "MKR",
    coordinates: "118.5479965209961, -26.6117000579834",
  },
  {
    ident: "YMEN",
    type: "medium_airport",
    name: "Melbourne Essendon Airport",
    elevation_ft: "282",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Melbourne",
    gps_code: "YMEN",
    iata_code: "MEB",
    coordinates: "144.901993, -37.7281",
  },
  {
    ident: "YMER",
    type: "medium_airport",
    name: "Merimbula Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Merimbula",
    gps_code: "YMER",
    iata_code: "MIM",
    coordinates: "149.901000977, -36.9085998535",
  },
  {
    ident: "YMEU",
    type: "small_airport",
    name: "Merluna Airport",
    elevation_ft: "276",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Merluna",
    gps_code: "YMEU",
    iata_code: "MLV",
    coordinates: "142.4536, -13.0649",
  },
  {
    ident: "YMGB",
    type: "small_airport",
    name: "Milingimbi Airport",
    elevation_ft: "53",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Milingimbi Island",
    gps_code: "YMGB",
    iata_code: "MGT",
    coordinates: "134.893997192, -12.0944004059",
  },
  {
    ident: "YMGD",
    type: "medium_airport",
    name: "Maningrida Airport",
    elevation_ft: "123",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Maningrida",
    gps_code: "YMGD",
    iata_code: "MNG",
    coordinates: "134.23399353, -12.0560998917",
  },
  {
    ident: "YMGN",
    type: "small_airport",
    name: "Mount Gunson Airport",
    elevation_ft: "285",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Mount Gunson",
    gps_code: "YMGN",
    iata_code: "GSN",
    coordinates: "137.174443333, -31.4597",
  },
  {
    ident: "YMGR",
    type: "small_airport",
    name: "Margaret River (Station) Airport",
    elevation_ft: "289",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMGR",
    iata_code: "MGV",
    coordinates: "126.883003235, -18.6217002869",
  },
  {
    ident: "YMGT",
    type: "small_airport",
    name: "Margaret River Airport",
    elevation_ft: "374",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMGT",
    iata_code: "MQZ",
    coordinates: "115.099998474, -33.9305992126",
  },
  {
    ident: "YMGV",
    type: "small_airport",
    name: "Musgrave Airport",
    elevation_ft: "302",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Musgrave",
    gps_code: "YMGV",
    iata_code: "MVU",
    coordinates: "143.5047, -14.7757",
  },
  {
    ident: "YMHB",
    type: "medium_airport",
    name: "Hobart International Airport",
    elevation_ft: "13",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    municipality: "Hobart",
    gps_code: "YMHB",
    iata_code: "HBA",
    coordinates: "147.509994507, -42.836101532",
  },
  {
    ident: "YMHO",
    type: "small_airport",
    name: "Mount House Airport",
    elevation_ft: "289",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMHO",
    iata_code: "MHO",
    coordinates: "125.70999908447266, -17.05500030517578",
  },
  {
    ident: "YMHU",
    type: "small_airport",
    name: "McArthur River Mine Airport",
    elevation_ft: "131",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "McArthur River Mine",
    gps_code: "YMHU",
    iata_code: "MCV",
    coordinates: "136.083999634, -16.4424991608",
  },
  {
    ident: "YMIA",
    type: "medium_airport",
    name: "Mildura Airport",
    elevation_ft: "167",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Mildura",
    gps_code: "YMIA",
    iata_code: "MQL",
    coordinates: "142.085998535, -34.229198455799995",
  },
  {
    ident: "YMIN",
    type: "small_airport",
    name: "Minlaton Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YMIN",
    iata_code: "XML",
    coordinates: "137.5330047607422, -34.75",
  },
  {
    ident: "YMIP",
    type: "small_airport",
    name: "Mitchell Plateau Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Mitchell Plateau",
    gps_code: "YMIP",
    iata_code: "MIH",
    coordinates: "125.8239974975586, -14.791399955749512",
  },
  {
    ident: "YMIT",
    type: "small_airport",
    name: "Mitchell Airport",
    elevation_ft: "1144",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMIT",
    iata_code: "MTQ",
    coordinates: "147.93699645996094, -26.483299255371094",
  },
  {
    ident: "YMJM",
    type: "small_airport",
    name: "Manjimup Airport",
    elevation_ft: "940",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMJM",
    iata_code: "MJP",
    coordinates: "116.13999938964844, -34.26530075073242",
  },
  {
    ident: "YMLS",
    type: "small_airport",
    name: "Miles Airport",
    elevation_ft: "304",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMLS",
    iata_code: "WLE",
    coordinates: "150.17500305176, -26.808300018311",
  },
  {
    ident: "YMLT",
    type: "medium_airport",
    name: "Launceston Airport",
    elevation_ft: "562",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    municipality: "Launceston",
    gps_code: "YMLT",
    iata_code: "LST",
    coordinates: "147.214004517, -41.54529953",
  },
  {
    ident: "YMMB",
    type: "medium_airport",
    name: "Melbourne Moorabbin Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Melbourne",
    gps_code: "YMMB",
    iata_code: "MBW",
    coordinates: "145.1020050048828, -37.975799560546875",
  },
  {
    ident: "YMMI",
    type: "small_airport",
    name: "Murrin Murrin Airport",
    elevation_ft: "1535",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Murrin Murrin",
    gps_code: "YMMI",
    iata_code: "WUI",
    coordinates: "121.89099, -28.705299",
  },
  {
    ident: "YMML",
    type: "large_airport",
    name: "Melbourne International Airport",
    elevation_ft: "434",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Melbourne",
    gps_code: "YMML",
    iata_code: "MEL",
    coordinates: "144.843002, -37.673302",
  },
  {
    ident: "YMMU",
    type: "small_airport",
    name: "Middlemount Airport",
    elevation_ft: "547",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMMU",
    iata_code: "MMM",
    coordinates: "148.7050018310547, -22.802499771118164",
  },
  {
    ident: "YMND",
    type: "small_airport",
    name: "Maitland Airport",
    elevation_ft: "85",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YMND",
    iata_code: "MTL",
    coordinates: "151.492912, -32.701265",
  },
  {
    ident: "YMNE",
    type: "medium_airport",
    name: "Mount Keith Airport",
    elevation_ft: "1792",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMNE",
    iata_code: "WME",
    coordinates: "120.55500030517578, -27.286399841308594",
  },
  {
    ident: "YMNK",
    type: "small_airport",
    name: "Monkira Airport",
    elevation_ft: "107",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMNK",
    iata_code: "ONR",
    coordinates: "140.5330047607422, -24.816699981689453",
  },
  {
    ident: "YMNS",
    type: "small_airport",
    name: "Mount Swan Airport",
    elevation_ft: "1641",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YMNS",
    iata_code: "MSF",
    coordinates: "135.034512, -22.624696",
  },
  {
    ident: "YMNY",
    type: "small_airport",
    name: "Morney Airport",
    elevation_ft: "104",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMNY",
    iata_code: "OXY",
    coordinates: "141.43299865722656, -25.358299255371094",
  },
  {
    ident: "YMOG",
    type: "medium_airport",
    name: "Mount Magnet Airport",
    elevation_ft: "1354",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMOG",
    iata_code: "MMG",
    coordinates: "117.84200286865234, -28.116100311279297",
  },
  {
    ident: "YMOO",
    type: "small_airport",
    name: "Mooraberree Airport",
    elevation_ft: "85",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMOO",
    iata_code: "OOR",
    coordinates: "140.98300170898438, -25.25",
  },
  {
    ident: "YMOR",
    type: "medium_airport",
    name: "Moree Airport",
    elevation_ft: "701",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Moree",
    gps_code: "YMOR",
    iata_code: "MRZ",
    coordinates: "149.845001221, -29.498899459799997",
  },
  {
    ident: "YMOT",
    type: "small_airport",
    name: "Moreton Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Moreton",
    gps_code: "YMOT",
    iata_code: "MET",
    coordinates: "142.638000488, -12.444199562099998",
  },
  {
    ident: "YMPA",
    type: "small_airport",
    name: "Minnipa Airport",
    elevation_ft: "155",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YMPA",
    iata_code: "MIN",
    coordinates: "135.14500427246094, -32.843299865722656",
  },
  {
    ident: "YMQA",
    type: "small_airport",
    name: "Marqua Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Marqua",
    gps_code: "YMQA",
    iata_code: "MQE",
    coordinates: "137.25100708007812, -22.80579948425293",
  },
  {
    ident: "YMRB",
    type: "medium_airport",
    name: "Moranbah Airport",
    elevation_ft: "770",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Moranbah",
    gps_code: "YMRB",
    iata_code: "MOV",
    coordinates: "148.07699585, -22.057800293",
  },
  {
    ident: "YMRE",
    type: "small_airport",
    name: "Marree Airport",
    elevation_ft: "50",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YMRE",
    iata_code: "RRE",
    coordinates: "138.06500244140625, -29.663299560546875",
  },
  {
    ident: "YMRW",
    type: "small_airport",
    name: "Morawa Airport",
    elevation_ft: "270",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMRW",
    iata_code: "MWB",
    coordinates: "116.02200317382812, -29.20170021057129",
  },
  {
    ident: "YMRY",
    type: "medium_airport",
    name: "Moruya Airport",
    elevation_ft: "14",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Moruya",
    gps_code: "YMRY",
    iata_code: "MYA",
    coordinates: "150.143997192, -35.8978004456",
  },
  {
    ident: "YMSF",
    type: "small_airport",
    name: "Mount Sanford Station Airport",
    elevation_ft: "231",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YMSF",
    iata_code: "MTD",
    coordinates: "130.55499267578125, -16.978300094604492",
  },
  {
    ident: "YMTA",
    type: "small_airport",
    name: "Mittebah Airport",
    elevation_ft: "865",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YMTA",
    iata_code: "MIY",
    coordinates: "137.0815, -18.8093",
  },
  {
    ident: "YMTB",
    type: "small_airport",
    name: "Muttaburra Airport",
    elevation_ft: "230",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMTB",
    iata_code: "UTB",
    coordinates: "144.5330047607422, -22.58329963684082",
  },
  {
    ident: "YMTG",
    type: "medium_airport",
    name: "Mount Gambier Airport",
    elevation_ft: "212",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YMTG",
    iata_code: "MGB",
    coordinates: "140.78500366210938, -37.745601654052734",
  },
  {
    ident: "YMTI",
    type: "small_airport",
    name: "Mornington Island Airport",
    elevation_ft: "33",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMTI",
    iata_code: "ONG",
    coordinates: "139.17799377441406, -16.662500381469727",
  },
  {
    ident: "YMTO",
    type: "small_airport",
    name: "Monto Airport",
    elevation_ft: "757",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YMTO",
    iata_code: "MNQ",
    coordinates: "151.10000610351562, -24.885799407958984",
  },
  {
    ident: "YMUC",
    type: "small_airport",
    name: "Muccan Station Airport",
    elevation_ft: "300",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Muccan Station",
    gps_code: "YMUC",
    iata_code: "MUQ",
    coordinates: "120.06700134277344, -20.658899307250977",
  },
  {
    ident: "YMUG",
    type: "small_airport",
    name: "Mungeranie Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Mungeranie",
    gps_code: "YMUG",
    iata_code: "MNE",
    coordinates: "138.65699768066406, -28.009199142456055",
  },
  {
    ident: "YMUI",
    type: "small_airport",
    name: "Murray Island Airport",
    elevation_ft: "300",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Murray Island",
    gps_code: "YMAE",
    iata_code: "MYI",
    local_code: "YMUI",
    coordinates: "144.054992676, -9.91666984558",
  },
  {
    ident: "YMUK",
    type: "small_airport",
    name: "Mulka Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Mulka",
    gps_code: "YMUK",
    iata_code: "MVK",
    coordinates: "138.64999389648438, -28.34779930114746",
  },
  {
    ident: "YMUP",
    type: "small_airport",
    name: "Mulga Park Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YMUP",
    iata_code: "MUP",
    coordinates: "131.649993896, -25.860000610399997",
  },
  {
    ident: "YMVG",
    type: "small_airport",
    name: "Mount Cavenagh Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YMVG",
    iata_code: "MKV",
    coordinates: "133.199996948, -25.9666996002",
  },
  {
    ident: "YMWA",
    type: "small_airport",
    name: "Mullewa Airport",
    elevation_ft: "290",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YMWA",
    iata_code: "MXU",
    coordinates: "115.51699829101562, -28.475000381469727",
  },
  {
    ident: "YMWT",
    type: "small_airport",
    name: "Moolawatana Airport",
    elevation_ft: "265",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Moolawatana Station",
    gps_code: "YMWT",
    iata_code: "MWT",
    coordinates: "139.765, -29.9069",
  },
  {
    ident: "YMWX",
    type: "small_airport",
    name: "Marion Downs Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Marion Downs",
    gps_code: "YMWX",
    iata_code: "MXD",
    coordinates: "139.649663, -23.363702",
  },
  {
    ident: "YMYB",
    type: "small_airport",
    name: "Maryborough Airport",
    elevation_ft: "38",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Maryborough",
    gps_code: "YMYB",
    iata_code: "MBH",
    coordinates: "152.714996, -25.5133",
  },
  {
    ident: "YMYT",
    type: "small_airport",
    name: "Merty Merty Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YMYT",
    iata_code: "RTY",
    coordinates: "140.31700134277344, -28.58329963684082",
  },
  {
    ident: "YNAP",
    type: "small_airport",
    name: "Nappa Merrie Airport",
    elevation_ft: "55",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YNAP",
    iata_code: "NMR",
    coordinates: "141.13299560546875, -27.558300018310547",
  },
  {
    ident: "YNAR",
    type: "medium_airport",
    name: "Narrandera Airport",
    elevation_ft: "474",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Narrandera",
    gps_code: "YNAR",
    iata_code: "NRA",
    coordinates: "146.511993408, -34.7022018433",
  },
  {
    ident: "YNBR",
    type: "medium_airport",
    name: "Narrabri Airport",
    elevation_ft: "788",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Narrabri",
    gps_code: "YNBR",
    iata_code: "NAA",
    coordinates: "149.82699585, -30.3192005157",
  },
  {
    ident: "YNGU",
    type: "medium_airport",
    name: "Ngukurr Airport",
    elevation_ft: "45",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YNGU",
    iata_code: "RPM",
    coordinates: "134.7469940185547, -14.722800254821777",
  },
  {
    ident: "YNHS",
    type: "small_airport",
    name: "Nambucca Heads Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Nambucca Heads",
    gps_code: "YNHS",
    iata_code: "NBH",
    coordinates: "153, -30.649999618530273",
  },
  {
    ident: "YNIC",
    type: "small_airport",
    name: "Nicholson Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YNIC",
    iata_code: "NLS",
    coordinates: "128.89999389648438, -18.049999237060547",
  },
  {
    ident: "YNKA",
    type: "small_airport",
    name: "Noonkanbah Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YNKA",
    iata_code: "NKB",
    coordinates: "124.851997375, -18.4946994781",
  },
  {
    ident: "YNMN",
    type: "small_airport",
    name: "New Moon Airport",
    elevation_ft: "1099",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Basalt",
    gps_code: "YNMN",
    iata_code: "NMP",
    local_code: "YNMN",
    coordinates: "145.773, -19.2",
  },
  {
    ident: "YNPB",
    type: "small_airport",
    name: "Napperby Airport",
    elevation_ft: "2135",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Napperby",
    gps_code: "YNPB",
    iata_code: "NPP",
    coordinates: "132.7632, -22.5312",
  },
  {
    ident: "YNRC",
    type: "small_airport",
    name: "Naracoorte Airport",
    elevation_ft: "169",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YNRC",
    iata_code: "NAC",
    coordinates: "140.72500610351562, -36.98529815673828",
  },
  {
    ident: "YNRG",
    type: "small_airport",
    name: "Narrogin Airport",
    elevation_ft: "329",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Narrogin",
    gps_code: "YNRG",
    iata_code: "NRG",
    coordinates: "117.08000183105, -32.930000305176",
  },
  {
    ident: "YNRM",
    type: "medium_airport",
    name: "Narromine Airport",
    elevation_ft: "782",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YNRM",
    iata_code: "QRM",
    coordinates: "148.22500610351562, -32.214698791503906",
  },
  {
    ident: "YNRV",
    type: "small_airport",
    name: "Ravensthorpe Airport",
    elevation_ft: "197",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YNRV",
    iata_code: "RVT",
    coordinates: "120.208000183, -33.7971992493",
  },
  {
    ident: "YNSH",
    type: "small_airport",
    name: "Noosa Airport",
    elevation_ft: "1",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YNSH",
    iata_code: "NSV",
    coordinates: "153.06300354003906, -26.42329978942871",
  },
  {
    ident: "YNSM",
    type: "small_airport",
    name: "Norseman Airport",
    elevation_ft: "263",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YNSM",
    iata_code: "NSM",
    coordinates: "121.75499725341797, -32.209999084472656",
  },
  {
    ident: "YNT",
    type: "large_airport",
    name: "Yantai Penglai International Airport",
    elevation_ft: "154",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-37",
    municipality: "Yantai",
    gps_code: "ZSYT",
    iata_code: "YNT",
    coordinates: "120.987222, 37.657222",
  },
  {
    ident: "YNTN",
    type: "medium_airport",
    name: "Normanton Airport",
    elevation_ft: "73",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Normanton",
    gps_code: "YNTN",
    iata_code: "NTN",
    coordinates: "141.069664, -17.68409",
  },
  {
    ident: "YNUB",
    type: "small_airport",
    name: "Nullabor Motel Airport",
    elevation_ft: "67",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YNUB",
    iata_code: "NUR",
    coordinates: "130.90199279785156, -31.441699981689453",
  },
  {
    ident: "YNUL",
    type: "small_airport",
    name: "Nullagine Airport",
    elevation_ft: "381",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YNUL",
    iata_code: "NLL",
    coordinates: "120.197998046875, -21.913299560546875",
  },
  {
    ident: "YNUM",
    type: "small_airport",
    name: "Numbulwar Airport",
    elevation_ft: "31",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YNUM",
    iata_code: "NUB",
    coordinates: "135.7169952392578, -14.271699905395508",
  },
  {
    ident: "YNUT",
    type: "small_airport",
    name: "Nutwood Downs Airport",
    elevation_ft: "500",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Nutwood Downs",
    gps_code: "YNUT",
    iata_code: "UTD",
    coordinates: "134.1459, -15.8074",
  },
  {
    ident: "YNWN",
    type: "medium_airport",
    name: "Newman Airport",
    elevation_ft: "1724",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Newman",
    gps_code: "YNWN",
    iata_code: "ZNE",
    coordinates: "119.803001404, -23.417800903299998",
  },
  {
    ident: "YNYN",
    type: "small_airport",
    name: "Nyngan Airport",
    elevation_ft: "569",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YNYN",
    iata_code: "NYN",
    coordinates: "147.2030029296875, -31.55109977722168",
  },
  {
    ident: "YOEN",
    type: "small_airport",
    name: "Oenpelli Airport",
    elevation_ft: "30",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YOEN",
    iata_code: "OPI",
    coordinates: "133.00599670410156, -12.324999809265137",
  },
  {
    ident: "YOI",
    type: "small_airport",
    name: "Opinaca Aerodrome",
    elevation_ft: "692",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-QC",
    municipality: "����l����onore Mine",
    iata_code: "YOI",
    coordinates: "-76.611944, 52.221944",
  },
  {
    ident: "YOLA",
    type: "small_airport",
    name: "Colac Airport",
    elevation_ft: "450",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Colac",
    gps_code: "YOLA",
    iata_code: "XCO",
    coordinates: "143.679993, -38.286701",
  },
  {
    ident: "YOLD",
    type: "small_airport",
    name: "Olympic Dam Airport",
    elevation_ft: "343",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Olympic Dam",
    gps_code: "YOLD",
    iata_code: "OLP",
    coordinates: "136.876998901, -30.485000610399997",
  },
  {
    ident: "YOLW",
    type: "small_airport",
    name: "Onslow Airport",
    elevation_ft: "7",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YOLW",
    iata_code: "ONS",
    coordinates: "115.1129989624, -21.668300628662",
  },
  {
    ident: "YOOD",
    type: "small_airport",
    name: "Oodnadatta Airport",
    elevation_ft: "118",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YOOD",
    iata_code: "ODD",
    coordinates: "135.44700622558594, -27.56170082092285",
  },
  {
    ident: "YOOM",
    type: "small_airport",
    name: "Moomba Airport",
    elevation_ft: "143",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YOOM",
    iata_code: "MOO",
    coordinates: "140.19700622558594, -28.09939956665039",
  },
  {
    ident: "YORB",
    type: "small_airport",
    name: "Orbost Airport",
    elevation_ft: "28",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YORB",
    iata_code: "RBS",
    coordinates: "148.61000061035156, -37.790000915527344",
  },
  {
    ident: "YORG",
    type: "medium_airport",
    name: "Orange Airport",
    elevation_ft: "3115",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Orange",
    gps_code: "YORG",
    iata_code: "OAG",
    coordinates: "149.132995605, -33.3816986084",
  },
  {
    ident: "YORV",
    type: "small_airport",
    name: "Ord River Airport",
    elevation_ft: "390",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Ord River",
    gps_code: "YORV",
    iata_code: "ODR",
    coordinates: "128.91200256347656, -17.34079933166504",
  },
  {
    ident: "YOSB",
    type: "small_airport",
    name: "Osborne Mine Airport",
    elevation_ft: "285",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YOSB",
    iata_code: "OSO",
    coordinates: "140.554992676, -22.0816993713",
  },
  {
    ident: "YOUY",
    type: "small_airport",
    name: "Ouyen Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YOUY",
    iata_code: "OYN",
    coordinates: "142.354488373, -35.0890124268",
  },
  {
    ident: "YPAD",
    type: "large_airport",
    name: "Adelaide International Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Adelaide",
    gps_code: "YPAD",
    iata_code: "ADL",
    coordinates: "138.531006, -34.945",
  },
  {
    ident: "YPAG",
    type: "medium_airport",
    name: "Port Augusta Airport",
    elevation_ft: "56",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YPAG",
    iata_code: "PUG",
    coordinates: "137.7169952392578, -32.506900787353516",
  },
  {
    ident: "YPAM",
    type: "small_airport",
    name: "Palm Island Airport",
    elevation_ft: "28",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YPAM",
    iata_code: "PMK",
    coordinates: "146.58099365234375, -18.755300521850586",
  },
  {
    ident: "YPBO",
    type: "medium_airport",
    name: "Paraburdoo Airport",
    elevation_ft: "1406",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Paraburdoo",
    gps_code: "YPBO",
    iata_code: "PBO",
    coordinates: "117.745002747, -23.1711006165",
  },
  {
    ident: "YPCC",
    type: "medium_airport",
    name: "Cocos (Keeling) Islands Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "CC",
    iso_region: "CC-U-A",
    municipality: "Cocos (Keeling) Islands",
    gps_code: "YPCC",
    iata_code: "CCK",
    coordinates: "96.8339004517, -12.1883001328",
  },
  {
    ident: "YPDA",
    type: "small_airport",
    name: "Parndana Airport",
    elevation_ft: "545",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Kangaroo Island",
    gps_code: "YPDA",
    iata_code: "PDN",
    local_code: "YPDA",
    coordinates: "137.264, -35.807",
  },
  {
    ident: "YPDI",
    type: "small_airport",
    name: "Pandie Pandie Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YPDI",
    iata_code: "PDE",
    coordinates: "139.39999389648438, -26.11669921875",
  },
  {
    ident: "YPDN",
    type: "medium_airport",
    name: "Darwin International Airport",
    elevation_ft: "103",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Darwin",
    gps_code: "YPDN",
    iata_code: "DRW",
    coordinates: "130.8769989013672, -12.41469955444336",
  },
  {
    ident: "YPDO",
    type: "small_airport",
    name: "Pardoo Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Pardoo",
    gps_code: "YPDO",
    iata_code: "PRD",
    coordinates: "119.58999633789062, -20.11750030517578",
  },
  {
    ident: "YPEC",
    type: "small_airport",
    name: "Lake Macquarie Airport",
    elevation_ft: "2",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YLMQ",
    iata_code: "BEO",
    coordinates: "151.647995, -33.0667",
  },
  {
    ident: "YPGV",
    type: "medium_airport",
    name: "Gove Airport",
    elevation_ft: "192",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Nhulunbuy",
    gps_code: "YPGV",
    iata_code: "GOV",
    coordinates: "136.817993164, -12.269399642899998",
  },
  {
    ident: "YPIR",
    type: "medium_airport",
    name: "Port Pirie Airport",
    elevation_ft: "40",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YPIR",
    iata_code: "PPI",
    coordinates: "137.9949951171875, -33.23889923095703",
  },
  {
    ident: "YPJT",
    type: "medium_airport",
    name: "Perth Jandakot Airport",
    elevation_ft: "99",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Perth",
    gps_code: "YPJT",
    iata_code: "JAD",
    coordinates: "115.88099670410156, -32.09749984741211",
  },
  {
    ident: "YPKA",
    type: "medium_airport",
    name: "Karratha Airport",
    elevation_ft: "29",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Karratha",
    gps_code: "YPKA",
    iata_code: "KTA",
    coordinates: "116.773002625, -20.712200164799995",
  },
  {
    ident: "YPKG",
    type: "medium_airport",
    name: "Kalgoorlie Boulder Airport",
    elevation_ft: "1203",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Kalgoorlie",
    gps_code: "YPKG",
    iata_code: "KGI",
    coordinates: "121.461997986, -30.789400100699996",
  },
  {
    ident: "YPKS",
    type: "medium_airport",
    name: "Parkes Airport",
    elevation_ft: "1069",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Parkes",
    gps_code: "YPKS",
    iata_code: "PKE",
    coordinates: "148.238998413, -33.131401062",
  },
  {
    ident: "YPKT",
    type: "medium_airport",
    name: "Port Keats Airport",
    elevation_ft: "91",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YPKT",
    iata_code: "PKT",
    coordinates: "129.5290069580078, -14.25",
  },
  {
    ident: "YPKU",
    type: "medium_airport",
    name: "Kununurra Airport",
    elevation_ft: "145",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Kununurra",
    gps_code: "YPKU",
    iata_code: "KNX",
    coordinates: "128.707992554, -15.7781000137",
  },
  {
    ident: "YPLC",
    type: "medium_airport",
    name: "Port Lincoln Airport",
    elevation_ft: "36",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Port Lincoln",
    gps_code: "YPLC",
    iata_code: "PLO",
    coordinates: "135.880004883, -34.6053009033",
  },
  {
    ident: "YPLM",
    type: "medium_airport",
    name: "Learmonth Airport",
    elevation_ft: "19",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Exmouth",
    gps_code: "YPLM",
    iata_code: "LEA",
    coordinates: "114.088996887, -22.235599517799997",
  },
  {
    ident: "YPMH",
    type: "small_airport",
    name: "Prominent Hill Airport",
    elevation_ft: "745",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "OZ Minerals Prominent Hill Mine",
    gps_code: "YPMH",
    iata_code: "PXH",
    coordinates: "135.5244, -29.716",
  },
  {
    ident: "YPMP",
    type: "small_airport",
    name: "Pormpuraaw Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Pormpuraaw",
    gps_code: "YPMP",
    iata_code: "EDR",
    coordinates: "141.60908, -14.896451",
  },
  {
    ident: "YPMQ",
    type: "medium_airport",
    name: "Port Macquarie Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Port Macquarie",
    gps_code: "YPMQ",
    iata_code: "PQQ",
    coordinates: "152.863006592, -31.4358005524",
  },
  {
    ident: "YPNG",
    type: "small_airport",
    name: "Penong Airport",
    elevation_ft: "78",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Penong",
    gps_code: "YPNG",
    iata_code: "PEY",
    coordinates: "133, -31.916700363159",
  },
  {
    ident: "YPOD",
    type: "medium_airport",
    name: "Portland Airport",
    elevation_ft: "265",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YPOD",
    iata_code: "PTJ",
    coordinates: "141.4709930419922, -38.31809997558594",
  },
  {
    ident: "YPPD",
    type: "medium_airport",
    name: "Port Hedland International Airport",
    elevation_ft: "33",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Port Hedland",
    gps_code: "YPPD",
    iata_code: "PHE",
    coordinates: "118.625999451, -20.3777999878",
  },
  {
    ident: "YPPH",
    type: "large_airport",
    name: "Perth International Airport",
    elevation_ft: "67",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Perth",
    gps_code: "YPPH",
    iata_code: "PER",
    coordinates: "115.96700286865234, -31.94029998779297",
  },
  {
    ident: "YPSH",
    type: "small_airport",
    name: "Penneshaw Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Ironstone",
    gps_code: "YPSH",
    iata_code: "PEA",
    coordinates: "137.962875366, -35.7558462874",
  },
  {
    ident: "YPTN",
    type: "medium_airport",
    name: "Tindal Airport",
    elevation_ft: "443",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YPTN",
    iata_code: "KTR",
    coordinates: "132.3780059814453, -14.521100044250488",
  },
  {
    ident: "YPWR",
    type: "small_airport",
    name: "Woomera Airfield",
    elevation_ft: "548",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Woomera",
    gps_code: "YPWR",
    iata_code: "UMR",
    coordinates: "136.81700134277344, -31.14419937133789",
  },
  {
    ident: "YPXM",
    type: "medium_airport",
    name: "Christmas Island Airport",
    elevation_ft: "916",
    continent: "AS",
    iso_country: "CX",
    iso_region: "CX-U-A",
    municipality: "Christmas Island",
    gps_code: "YPXM",
    iata_code: "XCH",
    coordinates: "105.69000244140625, -10.450599670410156",
  },
  {
    ident: "YQDI",
    type: "small_airport",
    name: "Quirindi Airport",
    elevation_ft: "1054",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YQDI",
    iata_code: "UIR",
    coordinates: "150.51400756835938, -31.4906005859375",
  },
  {
    ident: "YQJ",
    type: "seaplane_base",
    name: "April Point Seaplane Base",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Quadra Island",
    iata_code: "YQJ",
    coordinates: "-125.235, 50.065",
  },
  {
    ident: "YQLP",
    type: "medium_airport",
    name: "Quilpie Airport",
    elevation_ft: "655",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YQLP",
    iata_code: "ULP",
    coordinates: "144.2530059814453, -26.612199783325195",
  },
  {
    ident: "YQNS",
    type: "small_airport",
    name: "Queenstown Airport",
    elevation_ft: "867",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    gps_code: "YQNS",
    iata_code: "UEE",
    coordinates: "145.531998, -42.075001",
  },
  {
    ident: "YRBR",
    type: "small_airport",
    name: "Robinson River Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YRBR",
    iata_code: "RRV",
    coordinates: "136.945007324, -16.7182998657",
  },
  {
    ident: "YRC",
    type: "seaplane_base",
    name: "Refuge Cove Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Desolation Sound",
    iata_code: "YRC",
    coordinates: "-124.843, 50.1234",
  },
  {
    ident: "YRD",
    type: "small_airport",
    name: "Dean River Airport",
    elevation_ft: "62",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Kimsquit Valley",
    iata_code: "YRD",
    coordinates: "-126.964957, 52.82371",
  },
  {
    ident: "YREN",
    type: "medium_airport",
    name: "Renmark Airport",
    elevation_ft: "115",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YREN",
    iata_code: "RMK",
    coordinates: "140.6739959716797, -34.1963996887207",
  },
  {
    ident: "YRMD",
    type: "small_airport",
    name: "Richmond Airport",
    elevation_ft: "676",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YRMD",
    iata_code: "RCM",
    coordinates: "143.11500549316406, -20.701900482177734",
  },
  {
    ident: "YRNG",
    type: "small_airport",
    name: "Ramingining Airport",
    elevation_ft: "206",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YRNG",
    iata_code: "RAM",
    coordinates: "134.8979949951172, -12.356399536132812",
  },
  {
    ident: "YROB",
    type: "small_airport",
    name: "Robinhood Airport",
    elevation_ft: "460",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YROB",
    iata_code: "ROH",
    coordinates: "143.7100067138672, -18.844999313354492",
  },
  {
    ident: "YROE",
    type: "small_airport",
    name: "Roebourne Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Roebourne",
    gps_code: "YROE",
    iata_code: "RBU",
    coordinates: "117.156997681, -20.7616996765",
  },
  {
    ident: "YROI",
    type: "small_airport",
    name: "Robinvale Airport",
    elevation_ft: "87",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YROI",
    iata_code: "RBC",
    coordinates: "142.7830047607422, -34.650001525878906",
  },
  {
    ident: "YROM",
    type: "medium_airport",
    name: "Roma Airport",
    elevation_ft: "1032",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Roma",
    gps_code: "YROM",
    iata_code: "RMA",
    coordinates: "148.774993896, -26.545000076300003",
  },
  {
    ident: "YRRB",
    type: "small_airport",
    name: "Roper Bar Airport",
    elevation_ft: "92",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Roper Bar",
    gps_code: "YRRB",
    iata_code: "RPB",
    coordinates: "134.525485, -14.734814",
  },
  {
    ident: "YRSB",
    type: "small_airport",
    name: "Roseberth Airport",
    elevation_ft: "55",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YRSB",
    iata_code: "RSB",
    coordinates: "139.64999389648438, -25.83329963684082",
  },
  {
    ident: "YRTI",
    type: "small_airport",
    name: "Rottnest Island Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YRTI",
    iata_code: "RTS",
    coordinates: "115.540001, -32.006699",
  },
  {
    ident: "YRTP",
    type: "small_airport",
    name: "Rutland Plains Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YRTP",
    iata_code: "RTP",
    coordinates: "141.84300231933594, -15.64330005645752",
  },
  {
    ident: "YRYH",
    type: "small_airport",
    name: "Roy Hill Station Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YRYH",
    iata_code: "RHL",
    coordinates: "119.959030151, -22.625815891",
  },
  {
    ident: "YSAN",
    type: "small_airport",
    name: "Sandstone Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Sandstone",
    gps_code: "YSAN",
    iata_code: "NDS",
    coordinates: "119.2969970703125, -27.979999542236328",
  },
  {
    ident: "YSBK",
    type: "medium_airport",
    name: "Sydney Bankstown Airport",
    elevation_ft: "29",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Sydney",
    gps_code: "YSBK",
    iata_code: "BWU",
    coordinates: "150.98800659179688, -33.924400329589844",
  },
  {
    ident: "YSCB",
    type: "large_airport",
    name: "Canberra International Airport",
    elevation_ft: "1886",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-ACT",
    municipality: "Canberra",
    gps_code: "YSCB",
    iata_code: "CBR",
    coordinates: "149.19500732421875, -35.30690002441406",
  },
  {
    ident: "YSCD",
    type: "small_airport",
    name: "Carosue Dam Airport",
    elevation_ft: "1306",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Carosue Dam Gold Mine",
    gps_code: "YSCD",
    iata_code: "WCD",
    coordinates: "122.3221, -30.1737",
  },
  {
    ident: "YSCH",
    type: "medium_airport",
    name: "Coffs Harbour Airport",
    elevation_ft: "18",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Coffs Harbour",
    gps_code: "YCFS",
    iata_code: "CFS",
    coordinates: "153.115997, -30.320601",
  },
  {
    ident: "YSCN",
    type: "medium_airport",
    name: "Camden Airport",
    elevation_ft: "230",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YSCN",
    iata_code: "CDU",
    coordinates: "150.68699645996094, -34.04029846191406",
  },
  {
    ident: "YSCO",
    type: "small_airport",
    name: "Scone Airport",
    elevation_ft: "745",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YSCO",
    iata_code: "NSO",
    coordinates: "150.83200073242188, -32.037200927734375",
  },
  {
    ident: "YSCR",
    type: "small_airport",
    name: "Southern Cross Airport",
    elevation_ft: "354",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YSCR",
    iata_code: "SQC",
    coordinates: "119.36000061035156, -31.239999771118164",
  },
  {
    ident: "YSDU",
    type: "medium_airport",
    name: "Dubbo City Regional Airport",
    elevation_ft: "935",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Dubbo",
    gps_code: "YSDU",
    iata_code: "DBO",
    coordinates: "148.574996948, -32.2167015076",
  },
  {
    ident: "YSGE",
    type: "small_airport",
    name: "St George Airport",
    elevation_ft: "656",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YSGE",
    iata_code: "SGO",
    coordinates: "148.59500122070312, -28.049699783325195",
  },
  {
    ident: "YSGT",
    type: "small_airport",
    name: "Singleton Airport",
    elevation_ft: "46",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Singleton",
    gps_code: "YSGT",
    iata_code: "SIX",
    coordinates: "151.193056, -32.600832",
  },
  {
    ident: "YSGW",
    type: "small_airport",
    name: "South Galway Airport",
    elevation_ft: "116",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YSGW",
    iata_code: "ZGL",
    coordinates: "142.10800170898438, -25.683300018310547",
  },
  {
    ident: "YSHG",
    type: "small_airport",
    name: "Shay Gap Airport",
    elevation_ft: "620",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Shay Gap",
    gps_code: "YSHG",
    iata_code: "SGP",
    coordinates: "120.14099884033203, -20.424699783325195",
  },
  {
    ident: "YSHK",
    type: "medium_airport",
    name: "Shark Bay Airport",
    elevation_ft: "111",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Denham",
    gps_code: "YSHK",
    iata_code: "MJK",
    coordinates: "113.577003479, -25.8938999176",
  },
  {
    ident: "YSHR",
    type: "small_airport",
    name: "Shute Harbour Airport",
    elevation_ft: "12",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YSHR",
    iata_code: "JHQ",
    coordinates: "148.755556, -20.277221",
  },
  {
    ident: "YSHT",
    type: "medium_airport",
    name: "Shepparton Airport",
    elevation_ft: "374",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YSHT",
    iata_code: "SHT",
    coordinates: "145.39300537109375, -36.42890167236328",
  },
  {
    ident: "YSII",
    type: "small_airport",
    name: "Saibai Island Airport",
    elevation_ft: "15",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Saibai Island",
    gps_code: "YSII",
    iata_code: "SBR",
    coordinates: "142.625, -9.378330230710002",
  },
  {
    ident: "YSMI",
    type: "medium_airport",
    name: "Smithton Airport",
    elevation_ft: "31",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    gps_code: "YSMI",
    iata_code: "SIO",
    coordinates: "145.08399963378906, -40.834999084472656",
  },
  {
    ident: "YSMP",
    type: "small_airport",
    name: "Smith Point Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YSMP",
    iata_code: "SHU",
    coordinates: "132.149993896, -11.149999618499999",
  },
  {
    ident: "YSMR",
    type: "small_airport",
    name: "Strathmore Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YSMR",
    iata_code: "STH",
    coordinates: "142.56700134277344, -17.850000381469727",
  },
  {
    ident: "YSNB",
    type: "medium_airport",
    name: "Snake Bay Airport",
    elevation_ft: "173",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YSNB",
    iata_code: "SNB",
    coordinates: "130.6540069580078, -11.422800064086914",
  },
  {
    ident: "YSNF",
    type: "medium_airport",
    name: "Norfolk Island International Airport",
    elevation_ft: "371",
    continent: "OC",
    iso_country: "NF",
    iso_region: "NF-U-A",
    municipality: "Burnt Pine",
    gps_code: "YSNF",
    iata_code: "NLK",
    coordinates: "167.93899536132812, -29.04159927368164",
  },
  {
    ident: "YSNW",
    type: "medium_airport",
    name: "Nowra Airport",
    elevation_ft: "400",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YSNW",
    iata_code: "NOA",
    coordinates: "150.53700256347656, -34.94889831542969",
  },
  {
    ident: "YSPE",
    type: "small_airport",
    name: "Stanthorpe Airport",
    elevation_ft: "2938",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YSPE",
    iata_code: "SNH",
    coordinates: "151.99099731445312, -28.62030029296875",
  },
  {
    ident: "YSPK",
    type: "small_airport",
    name: "Spring Creek Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YSPK",
    iata_code: "SCG",
    coordinates: "144.56700134277344, -18.63330078125",
  },
  {
    ident: "YSPT",
    type: "small_airport",
    name: "Southport Airport",
    elevation_ft: "2",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YSPT",
    iata_code: "SHQ",
    coordinates: "153.372143, -27.922054",
  },
  {
    ident: "YSPV",
    type: "small_airport",
    name: "Springvale Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YSPV",
    iata_code: "KSV",
    coordinates: "140.6999969482422, -23.549999237060547",
  },
  {
    ident: "YSRI",
    type: "medium_airport",
    name: "RAAF Base Richmond",
    elevation_ft: "67",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Richmond",
    gps_code: "YSRI",
    iata_code: "XRH",
    coordinates: "150.781006, -33.600601",
  },
  {
    ident: "YSRN",
    type: "small_airport",
    name: "Strahan Airport",
    elevation_ft: "20",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    gps_code: "YSRN",
    iata_code: "SRN",
    coordinates: "145.29200744628906, -42.154998779296875",
  },
  {
    ident: "YSSY",
    type: "large_airport",
    name: "Sydney Kingsford Smith International Airport",
    elevation_ft: "21",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Sydney",
    gps_code: "YSSY",
    iata_code: "SYD",
    coordinates: "151.177001953125, -33.94609832763672",
  },
  {
    ident: "YSTH",
    type: "small_airport",
    name: "St Helens Airport",
    elevation_ft: "48",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    gps_code: "YSTH",
    iata_code: "HLS",
    coordinates: "148.28199768066406, -41.336700439453125",
  },
  {
    ident: "YSTI",
    type: "seaplane_base",
    name: "Stephens Island Seaplane Base",
    elevation_ft: "0",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Stephens Island",
    gps_code: "YSTI",
    iata_code: "STF",
    local_code: "YSTI",
    coordinates: "143.55, -9.51",
  },
  {
    ident: "YSTW",
    type: "medium_airport",
    name: "Tamworth Airport",
    elevation_ft: "1334",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Tamworth",
    gps_code: "YSTW",
    iata_code: "TMW",
    coordinates: "150.847000122, -31.0839004517",
  },
  {
    ident: "YSVP",
    type: "small_airport",
    name: "Silver Plains Airport",
    elevation_ft: "48",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Silver Plains",
    gps_code: "YSVP",
    iata_code: "SSP",
    local_code: "YSVP",
    coordinates: "143.5537, -13.9754",
  },
  {
    ident: "YSWG",
    type: "medium_airport",
    name: "Wagga Wagga City Airport",
    elevation_ft: "724",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Wagga Wagga",
    gps_code: "YSWG",
    iata_code: "WGA",
    coordinates: "147.466003418, -35.1652984619",
  },
  {
    ident: "YSWH",
    type: "medium_airport",
    name: "Swan Hill Airport",
    elevation_ft: "234",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YSWH",
    iata_code: "SWH",
    coordinates: "143.5330047607422, -35.37580108642578",
  },
  {
    ident: "YSWL",
    type: "medium_airport",
    name: "Stawell Airport",
    elevation_ft: "807",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YSWL",
    iata_code: "SWC",
    coordinates: "142.74099731445312, -37.07170104980469",
  },
  {
    ident: "YTAA",
    type: "small_airport",
    name: "Tara Airport",
    elevation_ft: "360",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YTAA",
    iata_code: "XTR",
    coordinates: "150.4770050048828, -27.156700134277344",
  },
  {
    ident: "YTAB",
    type: "small_airport",
    name: "Tableland Homestead Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YTAB",
    iata_code: "TBL",
    coordinates: "126.900001526, -17.2833003998",
  },
  {
    ident: "YTAM",
    type: "small_airport",
    name: "Taroom Airport",
    elevation_ft: "240",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YTAM",
    iata_code: "XTO",
    coordinates: "149.89999389648438, -25.801700592041016",
  },
  {
    ident: "YTAR",
    type: "small_airport",
    name: "Tarcoola Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Tarcoola",
    gps_code: "YTAR",
    iata_code: "TAQ",
    coordinates: "134.583999634, -30.7033004761",
  },
  {
    ident: "YTBF",
    type: "small_airport",
    name: "Pattaya Airpark",
    elevation_ft: "100",
    continent: "AS",
    iso_country: "TH",
    iso_region: "TH-20",
    municipality: "Pattaya",
    gps_code: "VTBF",
    iata_code: "PYX",
    coordinates: "100.949444, 12.8325",
  },
  {
    ident: "YTBR",
    type: "small_airport",
    name: "Timber Creek Airport",
    elevation_ft: "16",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YTBR",
    iata_code: "TBK",
    coordinates: "130.44500732421875, -15.619999885559082",
  },
  {
    ident: "YTDR",
    type: "small_airport",
    name: "Theodore Airport",
    elevation_ft: "171",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YTDR",
    iata_code: "TDR",
    coordinates: "150.09300231933594, -24.99329948425293",
  },
  {
    ident: "YTEE",
    type: "small_airport",
    name: "Trepell Airport",
    elevation_ft: "891",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Trepell",
    gps_code: "YTEE",
    iata_code: "TQP",
    coordinates: "140.88800048828, -21.834999084473",
  },
  {
    ident: "YTEF",
    type: "medium_airport",
    name: "Telfer Airport",
    elevation_ft: "970",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YTEF",
    iata_code: "TEF",
    coordinates: "122.22899627685547, -21.71500015258789",
  },
  {
    ident: "YTEM",
    type: "medium_airport",
    name: "Temora Airport",
    elevation_ft: "921",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YTEM",
    iata_code: "TEM",
    coordinates: "147.51199340820312, -34.4213981628418",
  },
  {
    ident: "YTGA",
    type: "small_airport",
    name: "Tangalooma Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YTGA",
    iata_code: "TAN",
    coordinates: "153.363006592, -27.1299991608",
  },
  {
    ident: "YTGM",
    type: "medium_airport",
    name: "Thargomindah Airport",
    elevation_ft: "433",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YTGM",
    iata_code: "XTG",
    coordinates: "143.81100463867188, -27.986400604248047",
  },
  {
    ident: "YTHD",
    type: "small_airport",
    name: "Theda Station Airport",
    elevation_ft: "700",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Theda Station",
    gps_code: "YTHD",
    iata_code: "TDN",
    coordinates: "126.496002197, -14.788100242599999",
  },
  {
    ident: "YTHY",
    type: "small_airport",
    name: "Thylungra Airport",
    elevation_ft: "165",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YTHY",
    iata_code: "TYG",
    coordinates: "143.4669952392578, -26.08329963684082",
  },
  {
    ident: "YTIB",
    type: "medium_airport",
    name: "Tibooburra Airport",
    elevation_ft: "584",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YTIB",
    iata_code: "TYB",
    coordinates: "142.05799865722656, -29.451099395751953",
  },
  {
    ident: "YTKY",
    type: "small_airport",
    name: "Turkey Creek Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Turkey Creek",
    gps_code: "YTKY",
    iata_code: "TKY",
    coordinates: "128.205993652, -17.0408000946",
  },
  {
    ident: "YTMO",
    type: "small_airport",
    name: "The Monument Airport",
    elevation_ft: "949",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Phosphate Hill",
    gps_code: "YTMO",
    iata_code: "PHQ",
    coordinates: "139.92399597168, -21.811100006104",
  },
  {
    ident: "YTMU",
    type: "medium_airport",
    name: "Tumut Airport",
    elevation_ft: "878",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YTMU",
    iata_code: "TUM",
    coordinates: "148.24099731445312, -35.26279830932617",
  },
  {
    ident: "YTMY",
    type: "small_airport",
    name: "Tobermorey Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Tobermorey",
    gps_code: "YTMY",
    iata_code: "TYP",
    coordinates: "137.9530029296875, -22.255800247192383",
  },
  {
    ident: "YTNB",
    type: "small_airport",
    name: "Tanbar Airport",
    elevation_ft: "344",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Tanbar Station",
    gps_code: "YTNB",
    iata_code: "TXR",
    coordinates: "141.92799377441, -25.847799301147",
  },
  {
    ident: "YTNG",
    type: "medium_airport",
    name: "Thangool Airport",
    elevation_ft: "644",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Biloela",
    gps_code: "YTNG",
    iata_code: "THG",
    coordinates: "150.5760040283203, -24.493900299072266",
  },
  {
    ident: "YTNK",
    type: "medium_airport",
    name: "Tennant Creek Airport",
    elevation_ft: "1236",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Tennant Creek",
    gps_code: "YTNK",
    iata_code: "TCA",
    coordinates: "134.18299865722656, -19.6343994140625",
  },
  {
    ident: "YTOC",
    type: "small_airport",
    name: "Tocumwal Airport",
    elevation_ft: "372",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YTOC",
    iata_code: "TCW",
    coordinates: "145.60800170898438, -35.81169891357422",
  },
  {
    ident: "YTRE",
    type: "medium_airport",
    name: "Taree Airport",
    elevation_ft: "38",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Taree",
    gps_code: "YTRE",
    iata_code: "TRO",
    coordinates: "152.514007568, -31.8885993958",
  },
  {
    ident: "YTST",
    type: "small_airport",
    name: "Truscott-Mungalalu Airport",
    elevation_ft: "181",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Anjo Peninsula",
    gps_code: "YTST",
    iata_code: "TTX",
    coordinates: "126.3809967041, -14.089699745178",
  },
  {
    ident: "YTU",
    type: "closed",
    name: "Tasu Water Aerodrome",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Tasu",
    iata_code: "YTU",
    local_code: "AP4",
    coordinates: "-132.04, 52.7630555556",
  },
  {
    ident: "YTWB",
    type: "small_airport",
    name: "Toowoomba Airport",
    elevation_ft: "2086",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Toowoomba",
    gps_code: "YTWB",
    iata_code: "TWB",
    coordinates: "151.916, -27.542801",
  },
  {
    ident: "YTY",
    type: "medium_airport",
    name: "Yangzhou Taizhou Airport",
    elevation_ft: "16",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    gps_code: "ZSYA",
    iata_code: "YTY",
    coordinates: "119.717141, 32.560184",
  },
  {
    ident: "YUDA",
    type: "small_airport",
    name: "Undara Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YUDA",
    iata_code: "UDA",
    coordinates: "144.60000610351562, -18.200000762939453",
  },
  {
    ident: "YUNY",
    type: "small_airport",
    name: "Cluny Airport",
    elevation_ft: "90",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YUNY",
    iata_code: "CZY",
    coordinates: "139.61700439453125, -24.516700744628906",
  },
  {
    ident: "YUSL",
    type: "small_airport",
    name: "Useless Loop Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YUSL",
    iata_code: "USL",
    coordinates: "113.4000015258789, -26.16670036315918",
  },
  {
    ident: "YVRD",
    type: "small_airport",
    name: "Victoria River Downs Airport",
    elevation_ft: "89",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YVRD",
    iata_code: "VCD",
    coordinates: "131.00497436523438, -16.402124404907227",
  },
  {
    ident: "YVRS",
    type: "small_airport",
    name: "Vanrook Station Airport",
    elevation_ft: "43",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YVRS",
    iata_code: "VNR",
    coordinates: "141.9499969482422, -16.963300704956055",
  },
  {
    ident: "YWAC",
    type: "small_airport",
    name: "Wauchope Airport",
    elevation_ft: "1186",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    municipality: "Wauchope",
    gps_code: "YWAC",
    iata_code: "WAU",
    coordinates: "134.215276, -20.640614",
  },
  {
    ident: "YWAL",
    type: "small_airport",
    name: "Wallal Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Wallal",
    gps_code: "YWAL",
    iata_code: "WLA",
    coordinates: "120.649002075, -19.7735996246",
  },
  {
    ident: "YWAV",
    type: "small_airport",
    name: "Wave Hill Airport",
    elevation_ft: "201",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YWAV",
    iata_code: "WAV",
    coordinates: "131.1179962158203, -17.393299102783203",
  },
  {
    ident: "YWBL",
    type: "small_airport",
    name: "Warrnambool Airport",
    elevation_ft: "242",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YWBL",
    iata_code: "WMB",
    coordinates: "142.44700622558594, -38.2952995300293",
  },
  {
    ident: "YWBS",
    type: "small_airport",
    name: "Warraber Island Airport",
    elevation_ft: "3",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Sue Islet",
    gps_code: "YWBS",
    iata_code: "SYU",
    coordinates: "142.8249969482422, -10.20829963684082",
  },
  {
    ident: "YWCA",
    type: "small_airport",
    name: "Wilcannia Airport",
    elevation_ft: "250",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YWCA",
    iata_code: "WIO",
    coordinates: "143.375, -31.526399612426758",
  },
  {
    ident: "YWCH",
    type: "small_airport",
    name: "Walcha Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YWCH",
    iata_code: "WLC",
    coordinates: "151.56700134277344, -31",
  },
  {
    ident: "YWCK",
    type: "small_airport",
    name: "Warwick Airport",
    elevation_ft: "1500",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YWCK",
    iata_code: "WAZ",
    coordinates: "151.9429931640625, -28.14940071105957",
  },
  {
    ident: "YWDA",
    type: "small_airport",
    name: "Windarra Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YWDA",
    iata_code: "WND",
    coordinates: "122.241996765, -28.4750003815",
  },
  {
    ident: "YWDG",
    type: "small_airport",
    name: "Windarling Airport",
    elevation_ft: "1502",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Windarling Mine",
    gps_code: "YWDG",
    iata_code: "WRN",
    coordinates: "119.39, -30.031667",
  },
  {
    ident: "YWDH",
    type: "medium_airport",
    name: "Windorah Airport",
    elevation_ft: "452",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YWDH",
    iata_code: "WNR",
    coordinates: "142.66700744628906, -25.41309928894043",
  },
  {
    ident: "YWDL",
    type: "small_airport",
    name: "Wondoola Airport",
    elevation_ft: "58",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Wondoola",
    gps_code: "YWDL",
    iata_code: "WON",
    coordinates: "140.891998291, -18.5750007629",
  },
  {
    ident: "YWDV",
    type: "small_airport",
    name: "Mount Full Stop Airport",
    elevation_ft: "1918",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Wando Vale",
    gps_code: "YWDV",
    iata_code: "MFL",
    coordinates: "144.8852, -19.67",
  },
  {
    ident: "YWGT",
    type: "medium_airport",
    name: "Wangaratta Airport",
    elevation_ft: "504",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YWGT",
    iata_code: "WGT",
    coordinates: "146.3070068359375, -36.41579818725586",
  },
  {
    ident: "YWHA",
    type: "medium_airport",
    name: "Whyalla Airport",
    elevation_ft: "41",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Whyalla",
    gps_code: "YWHA",
    iata_code: "WYA",
    coordinates: "137.51400756835938, -33.05889892578125",
  },
  {
    ident: "YWHI",
    type: "small_airport",
    name: "Whitsunday Island Airport",
    elevation_ft: "40",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YWHI",
    iata_code: "WSY",
    coordinates: "148.755, -20.27611",
  },
  {
    ident: "YWIT",
    type: "small_airport",
    name: "Wittenoom Airport",
    elevation_ft: "444",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YWIT",
    iata_code: "WIT",
    coordinates: "118.3479995727539, -22.218299865722656",
  },
  {
    ident: "YWKB",
    type: "medium_airport",
    name: "Warracknabeal Airport",
    elevation_ft: "397",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    gps_code: "YWKB",
    iata_code: "WKB",
    coordinates: "142.41900634765625, -36.32109832763672",
  },
  {
    ident: "YWLG",
    type: "medium_airport",
    name: "Walgett Airport",
    elevation_ft: "439",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YWLG",
    iata_code: "WGE",
    coordinates: "148.12600708007812, -30.032800674438477",
  },
  {
    ident: "YWLM",
    type: "medium_airport",
    name: "Newcastle Airport",
    elevation_ft: "31",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "Williamtown",
    gps_code: "YWLM",
    iata_code: "NTL",
    coordinates: "151.83399963378906, -32.79499816894531",
  },
  {
    ident: "YWLU",
    type: "medium_airport",
    name: "Wiluna Airport",
    elevation_ft: "1649",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YWLU",
    iata_code: "WUN",
    coordinates: "120.22100067138672, -26.629199981689453",
  },
  {
    ident: "YWMP",
    type: "small_airport",
    name: "Wrotham Park Airport",
    elevation_ft: "152",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YWMP",
    iata_code: "WPK",
    coordinates: "144.0019989013672, -16.658300399780273",
  },
  {
    ident: "YWND",
    type: "small_airport",
    name: "Wondai Airport",
    elevation_ft: "320",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YWND",
    iata_code: "WDI",
    coordinates: "151.85800170898438, -26.283300399780273",
  },
  {
    ident: "YWOL",
    type: "medium_airport",
    name: "Shellharbour Airport",
    elevation_ft: "31",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YSHL",
    iata_code: "WOL",
    coordinates: "150.789001, -34.5611",
  },
  {
    ident: "YWOR",
    type: "small_airport",
    name: "Wollogorang Airport",
    elevation_ft: "199",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YWOR",
    iata_code: "WLL",
    coordinates: "137.93453, -17.2199",
  },
  {
    ident: "YWRN",
    type: "medium_airport",
    name: "Warren Airport",
    elevation_ft: "669",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YWRN",
    iata_code: "QRR",
    coordinates: "147.80299377441406, -31.733299255371094",
  },
  {
    ident: "YWSL",
    type: "medium_airport",
    name: "West Sale Airport",
    elevation_ft: "72",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-VIC",
    municipality: "Sale",
    gps_code: "YWSL",
    iata_code: "SXE",
    coordinates: "146.965335, -38.090827",
  },
  {
    ident: "YWTL",
    type: "small_airport",
    name: "Waterloo Airport",
    elevation_ft: "132",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YWTL",
    iata_code: "WLO",
    coordinates: "129.32000732421875, -16.6299991607666",
  },
  {
    ident: "YWTN",
    type: "medium_airport",
    name: "Winton Airport",
    elevation_ft: "638",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    gps_code: "YWTN",
    iata_code: "WIN",
    coordinates: "143.08599853515625, -22.36359977722168",
  },
  {
    ident: "YWUD",
    type: "small_airport",
    name: "Wudinna Airport",
    elevation_ft: "310",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YWUD",
    iata_code: "WUD",
    coordinates: "135.44700622558594, -33.04330062866211",
  },
  {
    ident: "YWWA",
    type: "small_airport",
    name: "Wee Waa Airport",
    elevation_ft: "190",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YWWA",
    iata_code: "WEW",
    coordinates: "149.4080047607422, -30.25830078125",
  },
  {
    ident: "YWWG",
    type: "small_airport",
    name: "Warrawagine Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YWWG",
    iata_code: "WRW",
    coordinates: "120.702003479, -20.8442001343",
  },
  {
    ident: "YWWI",
    type: "small_airport",
    name: "Woodie Woodie Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Woodie Woodie",
    gps_code: "YWWI",
    iata_code: "WWI",
    coordinates: "121.23400115966797, -21.662799835205078",
  },
  {
    ident: "YWWL",
    type: "medium_airport",
    name: "West Wyalong Airport",
    elevation_ft: "859",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    municipality: "West Wyalong",
    gps_code: "YWWL",
    iata_code: "WWY",
    coordinates: "147.190994263, -33.9371986389",
  },
  {
    ident: "YWYM",
    type: "small_airport",
    name: "Wyndham Airport",
    elevation_ft: "14",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YWYM",
    iata_code: "WYN",
    coordinates: "128.1529998779297, -15.51140022277832",
  },
  {
    ident: "YWYY",
    type: "medium_airport",
    name: "Wynyard Airport",
    elevation_ft: "62",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-TAS",
    municipality: "Burnie",
    gps_code: "YWYY",
    iata_code: "BWT",
    coordinates: "145.7310028076172, -40.9989013671875",
  },
  {
    ident: "YYA",
    type: "medium_airport",
    name: "Sanhe Airport",
    elevation_ft: "230",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-43",
    municipality: "Yueyang",
    iata_code: "YYA",
    coordinates: "113.278, 29.314",
  },
  {
    ident: "YYAL",
    type: "small_airport",
    name: "Yalgoo Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    municipality: "Yalgoo",
    gps_code: "YYAL",
    iata_code: "YLG",
    coordinates: "116.683998108, -28.355300903299998",
  },
  {
    ident: "YYKI",
    type: "small_airport",
    name: "Yorke Island Airport",
    elevation_ft: "10",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Yorke Island",
    gps_code: "YYKI",
    iata_code: "OKR",
    coordinates: "143.405673, -9.752801",
  },
  {
    ident: "YYLR",
    type: "small_airport",
    name: "Yeelirrie Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-WA",
    gps_code: "YYLR",
    iata_code: "KYF",
    coordinates: "120.095672607, -27.277060037800002",
  },
  {
    ident: "YYMI",
    type: "small_airport",
    name: "Yam Island Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-QLD",
    municipality: "Yam Island",
    gps_code: "YYMI",
    iata_code: "XMY",
    coordinates: "142.7760009765625, -9.90110969543457",
  },
  {
    ident: "YYND",
    type: "small_airport",
    name: "Yuendumu Airport",
    elevation_ft: "2205",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NT",
    gps_code: "YYND",
    iata_code: "YUE",
    coordinates: "131.78199768066406, -22.254199981689453",
  },
  {
    ident: "YYNG",
    type: "medium_airport",
    name: "Young Airport",
    elevation_ft: "1267",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-NSW",
    gps_code: "YYNG",
    iata_code: "NGA",
    coordinates: "148.2480010986328, -34.25559997558594",
  },
  {
    ident: "YYOR",
    type: "small_airport",
    name: "Yorketown Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    gps_code: "YYOR",
    iata_code: "ORR",
    coordinates: "137.61700439453125, -35",
  },
  {
    ident: "YYTA",
    type: "small_airport",
    name: "Yalata Mission Airport",
    continent: "OC",
    iso_country: "AU",
    iso_region: "AU-SA",
    municipality: "Yalata Mission",
    gps_code: "YYTA",
    iata_code: "KYI",
    coordinates: "131.8249969482422, -31.470600128173828",
  },
  {
    ident: "Z13",
    type: "small_airport",
    name: "Akiachak Airport",
    elevation_ft: "23",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Akiachak",
    gps_code: "Z13",
    iata_code: "KKI",
    local_code: "Z13",
    coordinates: "-161.42199707031, 60.904800415039",
  },
  {
    ident: "Z48",
    type: "small_airport",
    name: "Bear Creek 3 Airport",
    elevation_ft: "740",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Bear Creek",
    gps_code: "Z48",
    iata_code: "BCC",
    local_code: "Z48",
    coordinates: "-156.149454117, 63.5733160384",
  },
  {
    ident: "Z59",
    type: "seaplane_base",
    name: "Bethel Seaplane Base",
    elevation_ft: "15",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Bethel",
    gps_code: "Z59",
    iata_code: "JBT",
    local_code: "Z59",
    coordinates: "-161.742996216, 60.782001495399996",
  },
  {
    ident: "Z71",
    type: "seaplane_base",
    name: "Cape Pole Seaplane Base",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Cape Pole",
    gps_code: "Z71",
    iata_code: "CZP",
    local_code: "Z71",
    coordinates: "-133.79699707, 55.9663009644",
  },
  {
    ident: "Z78",
    type: "seaplane_base",
    name: "Chignik Bay Seaplane Base",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Chignik",
    gps_code: "Z78",
    iata_code: "KBW",
    local_code: "Z78",
    coordinates: "-158.401000977, 56.295600891099994",
  },
  {
    ident: "Z91",
    type: "small_airport",
    name: "Birch Creek Airport",
    elevation_ft: "450",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Birch Creek",
    gps_code: "Z91",
    iata_code: "KBC",
    local_code: "Z91",
    coordinates: "-145.824005127, 66.2740020752",
  },
  {
    ident: "Z93",
    type: "small_airport",
    name: "Copper Center 2 Airport",
    elevation_ft: "1150",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Copper Center",
    gps_code: "Z93",
    iata_code: "CZC",
    local_code: "Z93",
    coordinates: "-145.294006348, 61.9412002563",
  },
  {
    ident: "ZA-0021",
    type: "heliport",
    name: "Pretoria Central Heliport",
    elevation_ft: "4087",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-GT",
    municipality: "Pretoria",
    iata_code: "HPR",
    coordinates: "28.220600128173828, -25.65570068359375",
  },
  {
    ident: "ZA-0069",
    type: "small_airport",
    name: "Ulusaba Airport",
    elevation_ft: "1263",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Ulusaba",
    gps_code: "FAUS",
    iata_code: "ULX",
    coordinates: "31.3549003601, -24.7854003906",
  },
  {
    ident: "ZA-0072",
    type: "small_airport",
    name: "Tanda Tula Airport",
    elevation_ft: "1555",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Welverdiend",
    gps_code: "FATD",
    iata_code: "TDT",
    coordinates: "31.2999992371, -24.533599853499997",
  },
  {
    ident: "ZA-0094",
    type: "small_airport",
    name: "Hazyview Airport",
    elevation_ft: "1810",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Hazyview",
    iata_code: "HZV",
    coordinates: "31.131900787353516, -25.050100326538086",
  },
  {
    ident: "ZA-0095",
    type: "small_airport",
    name: "Khoka Moya Airport",
    elevation_ft: "1440",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Khoka Moya",
    iata_code: "KHO",
    coordinates: "31.41510009765625, -24.593000411987305",
  },
  {
    ident: "ZA-0096",
    type: "small_airport",
    name: "Mkambati Airport",
    elevation_ft: "560",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-EC",
    municipality: "Mkambati",
    iata_code: "MBM",
    coordinates: "29.966699600219727, -31.283300399780273",
  },
  {
    ident: "ZA-0097",
    type: "small_airport",
    name: "Inyati Airport",
    elevation_ft: "1245",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Inyati",
    iata_code: "INY",
    coordinates: "31.385499954223633, -24.777700424194336",
  },
  {
    ident: "ZA-0098",
    type: "small_airport",
    name: "Tshipise Airport",
    elevation_ft: "1786",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NP",
    municipality: "Tshipise",
    iata_code: "TSD",
    coordinates: "30.175600051879883, -22.619300842285156",
  },
  {
    ident: "ZA-0138",
    type: "small_airport",
    name: "Singita Safari Lodge Airport",
    elevation_ft: "1365",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-MP",
    municipality: "Singita Safari Lodge",
    gps_code: "FASP",
    iata_code: "SSX",
    coordinates: "31.42194, -24.801985",
  },
  {
    ident: "ZA-KIG",
    type: "small_airport",
    name: "Koingnaas Airport",
    elevation_ft: "228",
    continent: "AF",
    iso_country: "ZA",
    iso_region: "ZA-NC",
    municipality: "Koingnaas",
    iata_code: "KIG",
    coordinates: "17.279199600219727, -30.18779945373535",
  },
  {
    ident: "ZBAA",
    type: "large_airport",
    name: "Beijing Capital International Airport",
    elevation_ft: "116",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-11",
    municipality: "Beijing",
    gps_code: "ZBAA",
    iata_code: "PEK",
    coordinates: "116.58499908447266, 40.080101013183594",
  },
  {
    ident: "ZBAD",
    type: "large_airport",
    name: "Beijing Daxing International Airport",
    elevation_ft: "98",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-13",
    municipality: "Beijing",
    gps_code: "ZBAD",
    iata_code: "PKX",
    coordinates: "116.41092, 39.509945",
  },
  {
    ident: "ZBCD",
    type: "medium_airport",
    name: "Chengde Puning Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-13",
    municipality: "Chengde",
    gps_code: "ZBCD",
    iata_code: "CDE",
    coordinates: "118.073889, 41.1225",
  },
  {
    ident: "ZBCF",
    type: "medium_airport",
    name: "Chifeng Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Chifeng",
    gps_code: "ZBCF",
    iata_code: "CIF",
    coordinates: "118.90799713134766, 42.23500061035156",
  },
  {
    ident: "ZBCZ",
    type: "small_airport",
    name: "Changzhi Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-14",
    municipality: "Changzhi",
    gps_code: "ZBCZ",
    iata_code: "CIH",
    coordinates: "113.125999, 36.247501",
  },
  {
    ident: "ZBDH",
    type: "medium_airport",
    name: "Qinhuangdao Beidaihe Airport",
    elevation_ft: "46",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-13",
    municipality: "Qinhuangdao",
    gps_code: "ZBDH",
    iata_code: "BPE",
    coordinates: "119.058889, 39.666389",
  },
  {
    ident: "ZBDS",
    type: "medium_airport",
    name: "Ordos Ejin Horo Airport",
    elevation_ft: "4557",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Ordos",
    gps_code: "ZBDS",
    iata_code: "DSN",
    coordinates: "109.861388889, 39.49",
  },
  {
    ident: "ZBDT",
    type: "small_airport",
    name: "Datong Airport",
    elevation_ft: "3442",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-14",
    municipality: "Datong",
    gps_code: "ZBDT",
    iata_code: "DAT",
    coordinates: "113.482002, 40.060299",
  },
  {
    ident: "ZBER",
    type: "medium_airport",
    name: "Erenhot Saiwusu International Airport",
    elevation_ft: "3301",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Erenhot",
    gps_code: "ZBER",
    iata_code: "ERL",
    coordinates: "112.096667, 43.4225",
  },
  {
    ident: "ZBES",
    type: "medium_airport",
    name: "Arxan Yi'ershi Airport",
    elevation_ft: "2925",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Arxan",
    gps_code: "ZBES",
    iata_code: "YIE",
    coordinates: "119.9117, 47.3106",
  },
  {
    ident: "ZBHD",
    type: "medium_airport",
    name: "Handan Airport",
    elevation_ft: "229",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-42",
    municipality: "Handan",
    gps_code: "ZBHD",
    iata_code: "HDG",
    coordinates: "114.425555556, 36.5258333333",
  },
  {
    ident: "ZBHH",
    type: "large_airport",
    name: "Baita International Airport",
    elevation_ft: "3556",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Hohhot",
    gps_code: "ZBHH",
    iata_code: "HET",
    coordinates: "111.823997, 40.851398",
  },
  {
    ident: "ZBHZ",
    type: "medium_airport",
    name: "Holingol Huolinhe Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Holingol",
    gps_code: "ZBHZ",
    iata_code: "HUO",
    coordinates: "119.407222, 45.487222",
  },
  {
    ident: "ZBK",
    type: "closed",
    name: "����abljak Airport",
    elevation_ft: "4252",
    continent: "EU",
    iso_country: "ME",
    iso_region: "ME-21",
    municipality: "����abljak Airport",
    iata_code: "ZBK",
    coordinates: "19.233334, 43.116669",
  },
  {
    ident: "ZBLA",
    type: "medium_airport",
    name: "Hulunbuir Hailar Airport",
    elevation_ft: "2169",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Hailar",
    gps_code: "ZBLA",
    iata_code: "HLD",
    coordinates: "119.824997, 49.205002",
  },
  {
    ident: "ZBLF",
    type: "medium_airport",
    name: "Linfen Qiaoli Airport",
    elevation_ft: "1483",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-14",
    municipality: "Linfen",
    gps_code: "ZBLF",
    iata_code: "LFQ",
    coordinates: "111.641236, 36.132629",
  },
  {
    ident: "ZBLL",
    type: "medium_airport",
    name: "L����liang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-61",
    municipality: "L����liang",
    gps_code: "ZBLL",
    iata_code: "LLV",
    coordinates: "111.142778, 37.683333",
  },
  {
    ident: "ZBMZ",
    type: "medium_airport",
    name: "Manzhouli Xijiao Airport",
    elevation_ft: "2231",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    gps_code: "ZBMZ",
    iata_code: "NZH",
    coordinates: "117.33, 49.566667",
  },
  {
    ident: "ZBNY",
    type: "large_airport",
    name: "Beijing Nanyuan Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-11",
    municipality: "Beijing",
    gps_code: "ZBNY",
    iata_code: "NAY",
    coordinates: "116.38800048828125, 39.782798767089844",
  },
  {
    ident: "ZBOW",
    type: "medium_airport",
    name: "Baotou Airport",
    elevation_ft: "3321",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Baotou",
    gps_code: "ZBOW",
    iata_code: "BAV",
    coordinates: "109.99700164794922, 40.560001373291016",
  },
  {
    ident: "ZBSJ",
    type: "medium_airport",
    name: "Shijiazhuang Daguocun International Airport",
    elevation_ft: "233",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-13",
    municipality: "Shijiazhuang",
    gps_code: "ZBSJ",
    iata_code: "SJW",
    coordinates: "114.6969985961914, 38.28070068359375",
  },
  {
    ident: "ZBTJ",
    type: "large_airport",
    name: "Tianjin Binhai International Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-12",
    municipality: "Tianjin",
    gps_code: "ZBTJ",
    iata_code: "TSN",
    coordinates: "117.346000671, 39.124401092499994",
  },
  {
    ident: "ZBTL",
    type: "medium_airport",
    name: "Tongliao Airport",
    elevation_ft: "2395",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Tongliao",
    gps_code: "ZBTL",
    iata_code: "TGO",
    coordinates: "122.199997, 43.556702",
  },
  {
    ident: "ZBUC",
    type: "medium_airport",
    name: "Ulanqab Jining Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Ulanqab",
    gps_code: "ZBUC",
    iata_code: "UCB",
    coordinates: "113.108056, 41.129722",
  },
  {
    ident: "ZBUH",
    type: "small_airport",
    name: "Wuhai Airport",
    elevation_ft: "3650",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Wuhai",
    gps_code: "ZBUH",
    iata_code: "WUA",
    coordinates: "106.7993, 39.7934",
  },
  {
    ident: "ZBUL",
    type: "small_airport",
    name: "Ulanhot Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Ulanhot",
    gps_code: "ZBUL",
    iata_code: "HLH",
    coordinates: "122.008333, 46.195333",
  },
  {
    ident: "ZBXH",
    type: "medium_airport",
    name: "Xilinhot Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Xilinhot",
    gps_code: "ZBXH",
    iata_code: "XIL",
    coordinates: "115.96399688720703, 43.91559982299805",
  },
  {
    ident: "ZBXT",
    type: "small_airport",
    name: "Xingtai Dalian Airport",
    elevation_ft: "280",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-13",
    municipality: "Xingtai",
    gps_code: "ZBXT",
    iata_code: "XNT",
    coordinates: "114.4293, 36.8831",
  },
  {
    ident: "ZBXZ",
    type: "small_airport",
    name: "Xinzhou Wutaishan Airport",
    elevation_ft: "2527",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-14",
    municipality: "Xinzhou",
    gps_code: "ZBXZ",
    iata_code: "WUT",
    coordinates: "112.969173, 38.597456",
  },
  {
    ident: "ZBYC",
    type: "small_airport",
    name: "Yuncheng Guangong Airport",
    elevation_ft: "1242",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-61",
    municipality: "Yuncheng",
    gps_code: "ZBYC",
    iata_code: "YCU",
    coordinates: "111.031388889, 35.116391",
  },
  {
    ident: "ZBYN",
    type: "large_airport",
    name: "Taiyuan Wusu Airport",
    elevation_ft: "2575",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-14",
    municipality: "Taiyuan",
    gps_code: "ZBYN",
    iata_code: "TYN",
    coordinates: "112.62799835205078, 37.74689865112305",
  },
  {
    ident: "ZBYZ",
    type: "medium_airport",
    name: "Bayannur Tianjitai Airport",
    elevation_ft: "3400",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Bavannur",
    gps_code: "ZBYZ",
    iata_code: "RLK",
    coordinates: "107.7428, 40.926",
  },
  {
    ident: "ZBZL",
    type: "medium_airport",
    name: "Chengjisihan Airport",
    elevation_ft: "928",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-15",
    municipality: "Zhalantun",
    gps_code: "ZBZL",
    iata_code: "NZL",
    coordinates: "122.767503, 47.865833",
  },
  {
    ident: "ZDY",
    type: "small_airport",
    name: "Delma Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "AE",
    iso_region: "AE-AZ",
    municipality: "Delma Island",
    gps_code: "OMDL",
    iata_code: "ZDY",
    coordinates: "52.3352, 24.51",
  },
  {
    ident: "ZEN",
    type: "small_airport",
    name: "Zenag Airport",
    elevation_ft: "3200",
    continent: "OC",
    iso_country: "PG",
    iso_region: "PG-MPL",
    municipality: "Zenag",
    gps_code: "AYZA",
    iata_code: "ZEN",
    local_code: "ZEN",
    coordinates: "146.61625, -6.9522222222200005",
  },
  {
    ident: "ZGBH",
    type: "small_airport",
    name: "Beihai Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-45",
    municipality: "Beihai",
    gps_code: "ZGBH",
    iata_code: "BHY",
    coordinates: "109.293999, 21.5394",
  },
  {
    ident: "ZGCD",
    type: "medium_airport",
    name: "Changde Airport",
    elevation_ft: "128",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-43",
    municipality: "Changde",
    gps_code: "ZGCD",
    iata_code: "CGD",
    coordinates: "111.63999939, 28.9188995361",
  },
  {
    ident: "ZGCJ",
    type: "medium_airport",
    name: "Zhijiang Airport",
    elevation_ft: "882",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-43",
    municipality: "Huaihua",
    gps_code: "ZGCJ",
    iata_code: "HJJ",
    coordinates: "109.7, 27.4411111111",
  },
  {
    ident: "ZGDY",
    type: "small_airport",
    name: "Dayong Airport",
    elevation_ft: "692",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-43",
    municipality: "Dayong",
    gps_code: "ZGDY",
    iata_code: "DYG",
    coordinates: "110.443001, 29.1028",
  },
  {
    ident: "ZGGG",
    type: "large_airport",
    name: "Guangzhou Baiyun International Airport",
    elevation_ft: "50",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Guangzhou",
    gps_code: "ZGGG",
    iata_code: "CAN",
    coordinates: "113.29900360107422, 23.39240074157715",
  },
  {
    ident: "ZGHA",
    type: "large_airport",
    name: "Changsha Huanghua International Airport",
    elevation_ft: "217",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-43",
    municipality: "Changsha",
    gps_code: "ZGHA",
    iata_code: "CSX",
    coordinates: "113.220001221, 28.189199447599997",
  },
  {
    ident: "ZGHC",
    type: "medium_airport",
    name: "Hechi Jinchengjiang Airport",
    elevation_ft: "2221",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-45",
    municipality: "Hechi",
    gps_code: "ZGHC",
    iata_code: "HCJ",
    coordinates: "107.6997, 24.805",
  },
  {
    ident: "ZGHU",
    type: "small_airport",
    name: "Huayuan Airport",
    elevation_ft: "1700",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Shihezi",
    gps_code: "ZWHZ",
    iata_code: "SHF",
    coordinates: "85.8905, 44.2421",
  },
  {
    ident: "ZGHY",
    type: "small_airport",
    name: "Hengyang Nanyue Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-43",
    municipality: "Hengyang",
    gps_code: "ZGHY",
    iata_code: "HNY",
    coordinates: "112.627998, 26.9053",
  },
  {
    ident: "ZGKL",
    type: "large_airport",
    name: "Guilin Liangjiang International Airport",
    elevation_ft: "570",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-45",
    municipality: "Guilin City",
    gps_code: "ZGKL",
    iata_code: "KWL",
    coordinates: "110.03900146484375, 25.21809959411621",
  },
  {
    ident: "ZGLG",
    type: "medium_airport",
    name: "Lingling Airport",
    elevation_ft: "340",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-43",
    municipality: "Yongzhou",
    gps_code: "ZGLG",
    iata_code: "LLF",
    coordinates: "111.610043, 26.338661",
  },
  {
    ident: "ZGMX",
    type: "small_airport",
    name: "Meixian Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Meixian",
    gps_code: "ZGMX",
    iata_code: "MXZ",
    coordinates: "116.13300323486328, 24.350000381469727",
  },
  {
    ident: "ZGNN",
    type: "large_airport",
    name: "Nanning Wuxu Airport",
    elevation_ft: "421",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-45",
    municipality: "Nanning",
    gps_code: "ZGNN",
    iata_code: "NNG",
    coordinates: "108.1719970703125, 22.608299255371094",
  },
  {
    ident: "ZGOW",
    type: "medium_airport",
    name: "Jieyang Chaoshan International Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Shantou",
    gps_code: "ZGOW",
    iata_code: "SWA",
    coordinates: "116.5033, 23.552",
  },
  {
    ident: "ZGSD",
    type: "medium_airport",
    name: "Zhuhai Jinwan Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Zhuhai",
    gps_code: "ZGSD",
    iata_code: "ZUH",
    coordinates: "113.375999, 22.006399",
  },
  {
    ident: "ZGSY",
    type: "medium_airport",
    name: "Shaoyang Wugang Airport",
    elevation_ft: "1444",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-43",
    municipality: "Shaoyang",
    gps_code: "ZGSY",
    iata_code: "WGN",
    coordinates: "110.642, 26.802",
  },
  {
    ident: "ZGSZ",
    type: "large_airport",
    name: "Shenzhen Bao'an International Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Shenzhen",
    gps_code: "ZGSZ",
    iata_code: "SZX",
    coordinates: "113.81099700927734, 22.639299392700195",
  },
  {
    ident: "ZGWZ",
    type: "small_airport",
    name: "Wuzhou Changzhoudao Airport",
    elevation_ft: "89",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-45",
    municipality: "Wuzhou",
    gps_code: "ZGWZ",
    iata_code: "WUZ",
    coordinates: "111.248001, 23.456699",
  },
  {
    ident: "ZGXN",
    type: "small_airport",
    name: "Xingning Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Xingning",
    gps_code: "ZGXN",
    iata_code: "XIN",
    coordinates: "115.758003, 24.1492",
  },
  {
    ident: "ZGZH",
    type: "medium_airport",
    name: "Liuzhou Bailian Airport",
    elevation_ft: "295",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-45",
    municipality: "Liuzhou",
    gps_code: "ZGZH",
    iata_code: "LZH",
    coordinates: "109.390999, 24.2075",
  },
  {
    ident: "ZGZJ",
    type: "medium_airport",
    name: "Zhanjiang Airport",
    elevation_ft: "125",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-44",
    municipality: "Zhanjiang",
    gps_code: "ZGZJ",
    iata_code: "ZHA",
    coordinates: "110.358002, 21.214399",
  },
  {
    ident: "ZHAY",
    type: "small_airport",
    name: "Anyang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-41",
    municipality: "Anyang",
    gps_code: "ZHAY",
    iata_code: "AYN",
    coordinates: "114.344002, 36.1339",
  },
  {
    ident: "ZHCC",
    type: "large_airport",
    name: "Zhengzhou Xinzheng International Airport",
    elevation_ft: "495",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-41",
    municipality: "Zhengzhou",
    gps_code: "ZHCC",
    iata_code: "CGO",
    coordinates: "113.841003418, 34.519699096699995",
  },
  {
    ident: "ZHES",
    type: "small_airport",
    name: "Enshi Airport",
    elevation_ft: "1605",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-42",
    municipality: "Enshi",
    gps_code: "ZHES",
    iata_code: "ENH",
    coordinates: "109.48500061, 30.3202991486",
  },
  {
    ident: "ZHGH",
    type: "medium_airport",
    name: "Guangzhou MR Air Base",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-42",
    municipality: "Guanghua",
    gps_code: "ZHGH",
    iata_code: "LHK",
    coordinates: "111.69499969482422, 32.389400482177734",
  },
  {
    ident: "ZHHH",
    type: "large_airport",
    name: "Wuhan Tianhe International Airport",
    elevation_ft: "113",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-42",
    municipality: "Wuhan",
    gps_code: "ZHHH",
    iata_code: "WUH",
    coordinates: "114.208, 30.7838",
  },
  {
    ident: "ZHLY",
    type: "small_airport",
    name: "Luoyang Airport",
    elevation_ft: "840",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-41",
    municipality: "Luoyang",
    gps_code: "ZHLY",
    iata_code: "LYA",
    coordinates: "112.388000488, 34.741100311299995",
  },
  {
    ident: "ZHNY",
    type: "small_airport",
    name: "Nanyang Jiangying Airport",
    elevation_ft: "840",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-41",
    municipality: "Nanyang",
    gps_code: "ZHNY",
    iata_code: "NNY",
    coordinates: "112.614998, 32.980801",
  },
  {
    ident: "ZHSN",
    type: "medium_airport",
    name: "Shennongjia Hongping Airport",
    elevation_ft: "8365",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-42",
    municipality: "Shennongjia",
    gps_code: "ZHSN",
    iata_code: "HPG",
    coordinates: "110.34, 31.626",
  },
  {
    ident: "ZHSS",
    type: "small_airport",
    name: "Shashi Airport",
    elevation_ft: "112",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-42",
    municipality: "Shashi",
    gps_code: "ZHSS",
    iata_code: "SHS",
    coordinates: "112.279861, 30.324289",
  },
  {
    ident: "ZHSY",
    type: "medium_airport",
    name: "Shiyan Wudangshan Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-42",
    municipality: "Shiyan",
    gps_code: "ZHSY",
    iata_code: "WDS",
    coordinates: "110.907778, 32.591667",
  },
  {
    ident: "ZHXF",
    type: "small_airport",
    name: "Xiangyang Liuji Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-42",
    municipality: "Xiangfan",
    gps_code: "ZHXF",
    iata_code: "XFN",
    coordinates: "112.291, 32.1506",
  },
  {
    ident: "ZHYC",
    type: "medium_airport",
    name: "Yichang Sanxia Airport",
    elevation_ft: "673",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-42",
    municipality: "Yichang",
    gps_code: "ZHYC",
    iata_code: "YIH",
    coordinates: "111.479988, 30.55655",
  },
  {
    ident: "ZIZ",
    type: "heliport",
    name: "Zamzama Heliport",
    elevation_ft: "128",
    continent: "AS",
    iso_country: "PK",
    iso_region: "PK-SD",
    municipality: "Zamzama Gas Field",
    iata_code: "ZIZ",
    coordinates: "67.66725, 26.710944444400003",
  },
  {
    ident: "ZJHK",
    type: "large_airport",
    name: "Haikou Meilan International Airport",
    elevation_ft: "75",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-46",
    municipality: "Haikou",
    gps_code: "ZJHK",
    iata_code: "HAK",
    coordinates: "110.45899963378906, 19.934900283813477",
  },
  {
    ident: "ZJQH",
    type: "medium_airport",
    name: "Qionghai Bo'ao Airport",
    elevation_ft: "30",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-46",
    municipality: "Qionghai",
    gps_code: "ZJQH",
    iata_code: "BAR",
    coordinates: "110.454775, 19.13824",
  },
  {
    ident: "ZJSY",
    type: "large_airport",
    name: "Sanya Phoenix International Airport",
    elevation_ft: "92",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-46",
    municipality: "Sanya",
    gps_code: "ZJSY",
    iata_code: "SYX",
    coordinates: "109.41200256347656, 18.302900314331055",
  },
  {
    ident: "ZKPY",
    type: "medium_airport",
    name: "Pyongyang Sunan International Airport",
    elevation_ft: "117",
    continent: "AS",
    iso_country: "KP",
    iso_region: "KP-01",
    municipality: "Pyongyang",
    gps_code: "ZKPY",
    iata_code: "FNJ",
    coordinates: "125.669998, 39.224098",
  },
  {
    ident: "ZKSD",
    type: "medium_airport",
    name: "Sondok Airport",
    elevation_ft: "12",
    continent: "AS",
    iso_country: "KP",
    iso_region: "KP-08",
    municipality: "S����nd����ng-ni",
    gps_code: "ZKSD",
    iata_code: "DSO",
    coordinates: "127.473999, 39.745201",
  },
  {
    ident: "ZKWS",
    type: "medium_airport",
    name: "Wonsan Kalma International Airport",
    elevation_ft: "7",
    continent: "AS",
    iso_country: "KP",
    iso_region: "KP-07",
    municipality: "Wonsan",
    gps_code: "ZKWS",
    iata_code: "WOS",
    coordinates: "127.486, 39.166801",
  },
  {
    ident: "ZLAK",
    type: "small_airport",
    name: "Ankang Wulipu Airport",
    elevation_ft: "860",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-61",
    municipality: "Ankang",
    gps_code: "ZLAK",
    iata_code: "AKA",
    coordinates: "108.931, 32.708099",
  },
  {
    ident: "ZLDH",
    type: "medium_airport",
    name: "Dunhuang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-62",
    municipality: "Dunhuang",
    gps_code: "ZLDH",
    iata_code: "DNH",
    coordinates: "94.80919647216797, 40.16109848022461",
  },
  {
    ident: "ZLDL",
    type: "small_airport",
    name: "Delingha Airport",
    elevation_ft: "9843",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-63",
    municipality: "Delingha",
    gps_code: "ZLDL",
    iata_code: "HXD",
    coordinates: "97.268658, 37.125286",
  },
  {
    ident: "ZLG",
    type: "closed",
    name: "La G����era Airport",
    elevation_ft: "10",
    continent: "AF",
    iso_country: "EH",
    iso_region: "EH-U-A",
    municipality: "La G����era",
    iata_code: "ZLG",
    coordinates: "-17.074167, 20.836389",
  },
  {
    ident: "ZLGM",
    type: "medium_airport",
    name: "Golmud Airport",
    elevation_ft: "9334",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-63",
    municipality: "Golmud",
    gps_code: "ZLGM",
    iata_code: "GOQ",
    coordinates: "94.786102, 36.4006",
  },
  {
    ident: "ZLGY",
    type: "medium_airport",
    name: "Guyuan Liupanshan Airport",
    elevation_ft: "5696",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-64",
    municipality: "Guyuan",
    gps_code: "ZLGY",
    iata_code: "GYU",
    coordinates: "106.216944444, 36.0788888889",
  },
  {
    ident: "ZLHX",
    type: "medium_airport",
    name: "Huatugou Airport",
    elevation_ft: "2945",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-63",
    municipality: "Mengnai",
    gps_code: "ZLHX",
    iata_code: "HTT",
    coordinates: "90.841495, 38.201984",
  },
  {
    ident: "ZLHZ",
    type: "small_airport",
    name: "Hanzhong Chenggu Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-61",
    municipality: "Hanzhong",
    gps_code: "ZLHZ",
    iata_code: "HZG",
    coordinates: "107.206014, 33.134136",
  },
  {
    ident: "ZLIC",
    type: "small_airport",
    name: "Yinchuan/Xincheng Air Base",
    elevation_ft: "3770",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-64",
    municipality: "Yinchuan",
    iata_code: "YEH",
    coordinates: "106.009003, 38.481899",
  },
  {
    ident: "ZLJN",
    type: "medium_airport",
    name: "Jining Qufu Airport",
    elevation_ft: "134",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-37",
    municipality: "Jining",
    gps_code: "ZSJG",
    iata_code: "JNG",
    coordinates: "116.346667, 35.292778",
  },
  {
    ident: "ZLJQ",
    type: "medium_airport",
    name: "Jiayuguan Airport",
    elevation_ft: "5112",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-62",
    municipality: "Jiayuguan",
    gps_code: "ZLJQ",
    iata_code: "JGN",
    coordinates: "98.3414, 39.856899",
  },
  {
    ident: "ZLLL",
    type: "medium_airport",
    name: "Lanzhou Zhongchuan Airport",
    elevation_ft: "6388",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-62",
    municipality: "Lanzhou",
    gps_code: "ZLLL",
    iata_code: "LHW",
    coordinates: "103.620002747, 36.5152015686",
  },
  {
    ident: "ZLLN",
    type: "medium_airport",
    name: "Longnan Chengzhou Airport",
    elevation_ft: "3707",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-62",
    municipality: "Longnan",
    gps_code: "ZLLN",
    iata_code: "LNL",
    coordinates: "105.797, 33.788",
  },
  {
    ident: "ZLQY",
    type: "medium_airport",
    name: "Qingyang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-62",
    municipality: "Qingyang",
    gps_code: "ZLQY",
    iata_code: "IQN",
    coordinates: "107.602997, 35.799702",
  },
  {
    ident: "ZLSN",
    type: "medium_airport",
    name: "Xi'an Xiguan Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-61",
    municipality: "Xi'an",
    gps_code: "ZLSN",
    iata_code: "SIA",
    coordinates: "109.120003, 34.376701",
  },
  {
    ident: "ZLXH",
    type: "medium_airport",
    name: "Gannan Xiahe Airport",
    elevation_ft: "10510",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-62",
    municipality: "Xiahe",
    gps_code: "ZLXH",
    iata_code: "GXH",
    coordinates: "102.6447, 34.8105",
  },
  {
    ident: "ZLXN",
    type: "medium_airport",
    name: "Xining Caojiabu Airport",
    elevation_ft: "7119",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-63",
    municipality: "Xining",
    gps_code: "ZLXN",
    iata_code: "XNN",
    coordinates: "102.042999, 36.5275",
  },
  {
    ident: "ZLXY",
    type: "large_airport",
    name: "Xi'an Xianyang International Airport",
    elevation_ft: "1572",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-61",
    municipality: "Xi'an",
    gps_code: "ZLXY",
    iata_code: "XIY",
    coordinates: "108.751999, 34.447102",
  },
  {
    ident: "ZLYA",
    type: "small_airport",
    name: "Yan'an Ershilipu Airport",
    elevation_ft: "3100",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-61",
    municipality: "Yan'an",
    gps_code: "ZLYA",
    iata_code: "ENY",
    coordinates: "109.554001, 36.636902",
  },
  {
    ident: "ZLYL",
    type: "small_airport",
    name: "Yulin Yuyang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-61",
    municipality: "Yulin",
    gps_code: "ZLYL",
    iata_code: "UYN",
    coordinates: "109.590927, 38.35971",
  },
  {
    ident: "ZLZW",
    type: "medium_airport",
    name: "Zhongwei Shapotou Airport",
    elevation_ft: "8202",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-64",
    municipality: "Zhongwei",
    gps_code: "ZLZW",
    iata_code: "ZHY",
    coordinates: "105.154454, 37.573125",
  },
  {
    ident: "ZMAH",
    type: "medium_airport",
    name: "Arvaikheer Airport",
    elevation_ft: "5932",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-055",
    municipality: "Arvaikheer",
    gps_code: "ZMAH",
    iata_code: "AVK",
    coordinates: "102.802001953125, 46.250301361083984",
  },
  {
    ident: "ZMAT",
    type: "medium_airport",
    name: "Altai Airport",
    elevation_ft: "7260",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-065",
    municipality: "Altai",
    gps_code: "ZMAT",
    iata_code: "LTI",
    coordinates: "96.22109985351562, 46.376399993896484",
  },
  {
    ident: "ZMBH",
    type: "medium_airport",
    name: "Bayankhongor Airport",
    elevation_ft: "6085",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-069",
    municipality: "Bayankhongor",
    gps_code: "ZMBH",
    iata_code: "BYN",
    coordinates: "100.7040023803711, 46.163299560546875",
  },
  {
    ident: "ZMBN",
    type: "medium_airport",
    name: "Bulgan Airport",
    elevation_ft: "4311",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-067",
    municipality: "Bulgan",
    gps_code: "ZMBN",
    iata_code: "UGA",
    coordinates: "103.47599792480469, 48.85499954223633",
  },
  {
    ident: "ZMBR",
    type: "small_airport",
    name: "Bulagtai Resort Airport",
    elevation_ft: "4659",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-053",
    municipality: "Umnugobitour",
    gps_code: "ZMBR",
    iata_code: "UGT",
    coordinates: "104.114985466, 43.749304182",
  },
  {
    ident: "ZMBS",
    type: "small_airport",
    name: "Bulgan Sum Airport",
    elevation_ft: "3921",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-043",
    municipality: "Bulgan",
    gps_code: "ZMBS",
    iata_code: "HBU",
    coordinates: "91.584198, 46.100601",
  },
  {
    ident: "ZMBU",
    type: "medium_airport",
    name: "Baruun Urt Airport",
    elevation_ft: "3205",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-051",
    gps_code: "ZMBU",
    iata_code: "UUN",
    coordinates: "113.28500366210938, 46.660301208496094",
  },
  {
    ident: "ZMCD",
    type: "medium_airport",
    name: "Choibalsan Airport",
    elevation_ft: "2457",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-061",
    gps_code: "ZMCD",
    iata_code: "COQ",
    coordinates: "114.64600372314453, 48.13570022583008",
  },
  {
    ident: "ZMCK",
    type: "large_airport",
    name: "Ulaanbaatar International Airport",
    elevation_ft: "4482",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-047",
    municipality: "Ulaanbaatar",
    gps_code: "ZMCK",
    iata_code: "UBN",
    coordinates: "106.819833, 47.646916",
  },
  {
    ident: "ZMD",
    type: "small_airport",
    name: "Sena Madureira Airport",
    elevation_ft: "540",
    continent: "SA",
    iso_country: "BR",
    iso_region: "BR-AC",
    municipality: "Sena Madureira",
    iata_code: "ZMD",
    coordinates: "-68.6108, -9.116",
  },
  {
    ident: "ZMDN",
    type: "small_airport",
    name: "Donoi Airport",
    elevation_ft: "5800",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-057",
    municipality: "Uliastai",
    gps_code: "ZMDN",
    iata_code: "ULZ",
    coordinates: "96.5258, 47.7093",
  },
  {
    ident: "ZMDZ",
    type: "medium_airport",
    name: "Dalanzadgad Airport",
    elevation_ft: "4787",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-053",
    municipality: "Dalanzadgad",
    gps_code: "ZMDZ",
    iata_code: "DLZ",
    coordinates: "104.43000030517578, 43.59170150756836",
  },
  {
    ident: "ZMHH",
    type: "small_airport",
    name: "Kharkhorin Airport",
    elevation_ft: "4759",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-055",
    gps_code: "ZMHH",
    iata_code: "KHR",
    coordinates: "102.826111, 47.246667",
  },
  {
    ident: "ZMHU",
    type: "small_airport",
    name: "Khujirt Airport",
    elevation_ft: "5522",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-055",
    municipality: "Khujirt",
    gps_code: "ZMHU",
    iata_code: "HJT",
    coordinates: "102.773002625, 46.9258003235",
  },
  {
    ident: "ZMKD",
    type: "medium_airport",
    name: "Khovd Airport",
    elevation_ft: "4898",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-043",
    municipality: "Khovd",
    gps_code: "ZMKD",
    iata_code: "HVD",
    coordinates: "91.6281967163086, 47.9541015625",
  },
  {
    ident: "ZMMN",
    type: "medium_airport",
    name: "M����r����n Airport",
    elevation_ft: "4272",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-041",
    municipality: "M����r����n",
    gps_code: "ZMMN",
    iata_code: "MXV",
    coordinates: "100.0989990234375, 49.663299560546875",
  },
  {
    ident: "ZMTG",
    type: "small_airport",
    name: "Tselserleg Airport",
    elevation_ft: "5530",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-073",
    municipality: "Tselserleg",
    gps_code: "ZMTG",
    iata_code: "TSZ",
    coordinates: "101.47755, 47.46544",
  },
  {
    ident: "ZMTL",
    type: "small_airport",
    name: "Tosontsengel Airport",
    elevation_ft: "5610",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-057",
    municipality: "Tosontsengel",
    gps_code: "ZMTL",
    iata_code: "TNZ",
    coordinates: "98.2941, 48.73887",
  },
  {
    ident: "ZMUB",
    type: "large_airport",
    name: "Buyant-Ukhaa International Airport",
    elevation_ft: "4364",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-1",
    municipality: "Ulan Bator",
    gps_code: "ZMUB",
    iata_code: "ULN",
    coordinates: "106.766998, 47.843102",
  },
  {
    ident: "ZMUG",
    type: "small_airport",
    name: "Ulaangom Airport",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-046",
    municipality: "Ulaangom",
    gps_code: "ZMUG",
    iata_code: "ULO",
    coordinates: "91.938273, 50.066588",
  },
  {
    ident: "ZMUL",
    type: "small_airport",
    name: "Ulgii Mongolei Airport",
    elevation_ft: "5732",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-071",
    gps_code: "ZMUL",
    iata_code: "ULG",
    coordinates: "89.9225006104, 48.9933013916",
  },
  {
    ident: "ZNC",
    type: "small_airport",
    name: "Nyac Airport",
    elevation_ft: "460",
    continent: "NA",
    iso_country: "US",
    iso_region: "US-AK",
    municipality: "Nyac",
    gps_code: "ZNC",
    iata_code: "ZNC",
    local_code: "ZNC",
    coordinates: "-159.994003296, 60.9807014465",
  },
  {
    ident: "ZNU",
    type: "seaplane_base",
    name: "Namu Water Aerodrome",
    elevation_ft: "0",
    continent: "NA",
    iso_country: "CA",
    iso_region: "CA-BC",
    municipality: "Namu",
    iata_code: "ZNU",
    coordinates: "-127.869357, 51.862825",
  },
  {
    ident: "ZPCW",
    type: "medium_airport",
    name: "Cangyuan Washan Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Cangyuan Va Autonomous County",
    gps_code: "ZPCW",
    iata_code: "CWJ",
    coordinates: "99.373611, 23.273889",
  },
  {
    ident: "ZPDL",
    type: "medium_airport",
    name: "Dali Airport",
    elevation_ft: "7050",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Xiaguan",
    gps_code: "ZPDL",
    iata_code: "DLU",
    coordinates: "100.319, 25.649401",
  },
  {
    ident: "ZPDQ",
    type: "medium_airport",
    name: "Diqing Airport",
    elevation_ft: "10761",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Shangri-La",
    gps_code: "ZPDQ",
    iata_code: "DIG",
    coordinates: "99.6772, 27.7936",
  },
  {
    ident: "ZPJH",
    type: "medium_airport",
    name: "Xishuangbanna Gasa Airport",
    elevation_ft: "1815",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Jinghong",
    gps_code: "ZPJH",
    iata_code: "JHG",
    coordinates: "100.76000213623047, 21.973899841308594",
  },
  {
    ident: "ZPJM",
    type: "medium_airport",
    name: "Lancang Jingmai Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Lancang Lahu Autonomous County",
    gps_code: "ZPJM",
    iata_code: "JMJ",
    coordinates: "99.786389, 22.415833",
  },
  {
    ident: "ZPLJ",
    type: "medium_airport",
    name: "Lijiang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Lijiang",
    gps_code: "ZPLJ",
    iata_code: "LJG",
    coordinates: "100.246002197, 26.6800003052",
  },
  {
    ident: "ZPLX",
    type: "medium_airport",
    name: "Mangshi Airport",
    elevation_ft: "2890",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Luxi",
    gps_code: "ZPMS",
    iata_code: "LUM",
    coordinates: "98.5317, 24.4011",
  },
  {
    ident: "ZPPP",
    type: "large_airport",
    name: "Kunming Changshui International Airport",
    elevation_ft: "6903",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Kunming",
    gps_code: "ZPPP",
    iata_code: "KMG",
    coordinates: "102.9291667, 25.1019444",
  },
  {
    ident: "ZPSM",
    type: "small_airport",
    name: "Pu'er Simao Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Pu'er",
    gps_code: "ZPSM",
    iata_code: "SYM",
    coordinates: "100.959, 22.793301",
  },
  {
    ident: "ZPWS",
    type: "small_airport",
    name: "Wenshan Puzhehei Airport",
    elevation_ft: "5217",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Wenshan",
    gps_code: "ZPWS",
    iata_code: "WNH",
    coordinates: "104.3255, 23.5583",
  },
  {
    ident: "ZPZT",
    type: "small_airport",
    name: "Zhaotong Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Zhaotong",
    gps_code: "ZPZT",
    iata_code: "ZAT",
    coordinates: "103.75499725341797, 27.325599670410156",
  },
  {
    ident: "ZSAM",
    type: "large_airport",
    name: "Xiamen Gaoqi International Airport",
    elevation_ft: "59",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-35",
    municipality: "Xiamen",
    gps_code: "ZSAM",
    iata_code: "XMN",
    coordinates: "118.12799835205078, 24.54400062561035",
  },
  {
    ident: "ZSAQ",
    type: "medium_airport",
    name: "Anqing Tianzhushan Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-34",
    municipality: "Anqing",
    gps_code: "ZSAQ",
    iata_code: "AQG",
    coordinates: "117.050003, 30.582199",
  },
  {
    ident: "ZSBB",
    type: "medium_airport",
    name: "Bengbu Airport",
    elevation_ft: "100",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-34",
    municipality: "Bengbu",
    gps_code: "ZSBB",
    iata_code: "BFU",
    coordinates: "117.320244444, 32.8477333333",
  },
  {
    ident: "ZSCG",
    type: "small_airport",
    name: "Changzhou Benniu Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Changzhou",
    gps_code: "ZSCG",
    iata_code: "CZX",
    coordinates: "119.778999, 31.919701",
  },
  {
    ident: "ZSCN",
    type: "medium_airport",
    name: "Nanchang Changbei International Airport",
    elevation_ft: "143",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-36",
    municipality: "Nanchang",
    gps_code: "ZSCN",
    iata_code: "KHN",
    coordinates: "115.9000015258789, 28.864999771118164",
  },
  {
    ident: "ZSFY",
    type: "medium_airport",
    name: "Fuyang Xiguan Airport",
    elevation_ft: "104",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-U-A",
    municipality: "Fuyang",
    gps_code: "ZSFY",
    iata_code: "FUG",
    coordinates: "115.734364, 32.882157",
  },
  {
    ident: "ZSFZ",
    type: "large_airport",
    name: "Fuzhou Changle International Airport",
    elevation_ft: "46",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-35",
    municipality: "Fuzhou",
    gps_code: "ZSFZ",
    iata_code: "FOC",
    coordinates: "119.66300201416016, 25.935100555419922",
  },
  {
    ident: "ZSGZ",
    type: "small_airport",
    name: "Ganzhou Airport",
    elevation_ft: "387",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-36",
    municipality: "Ganzhou",
    gps_code: "ZSGZ",
    iata_code: "KOW",
    coordinates: "114.778889, 25.853333",
  },
  {
    ident: "ZSHC",
    type: "large_airport",
    name: "Hangzhou Xiaoshan International Airport",
    elevation_ft: "23",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-33",
    municipality: "Hangzhou",
    gps_code: "ZSHC",
    iata_code: "HGH",
    coordinates: "120.43399810791016, 30.22949981689453",
  },
  {
    ident: "ZSJD",
    type: "medium_airport",
    name: "Jingdezhen Airport",
    elevation_ft: "112",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-36",
    municipality: "Jingdezhen",
    gps_code: "ZSJD",
    iata_code: "JDZ",
    coordinates: "117.176002502, 29.3386001587",
  },
  {
    ident: "ZSJJ",
    type: "small_airport",
    name: "Jiujiang Lushan Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-36",
    municipality: "Jiujiang",
    gps_code: "ZSJJ",
    iata_code: "JIU",
    coordinates: "115.801111, 29.476944",
  },
  {
    ident: "ZSJN",
    type: "large_airport",
    name: "Yaoqiang Airport",
    elevation_ft: "76",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-37",
    municipality: "Jinan",
    gps_code: "ZSJN",
    iata_code: "TNA",
    coordinates: "117.21600341796875, 36.857200622558594",
  },
  {
    ident: "ZSJU",
    type: "medium_airport",
    name: "Quzhou Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-33",
    municipality: "Quzhou",
    gps_code: "ZSJU",
    iata_code: "JUZ",
    coordinates: "118.899002, 28.965799",
  },
  {
    ident: "ZSLD",
    type: "medium_airport",
    name: "Longyan Guanzhishan Airport",
    elevation_ft: "1225",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-35",
    municipality: "Longyan",
    gps_code: "ZSLD",
    iata_code: "LCX",
    coordinates: "116.747001648, 25.6746997833",
  },
  {
    ident: "ZSLG",
    type: "small_airport",
    name: "Lianyungang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Lianyungang",
    gps_code: "ZSLG",
    iata_code: "LYG",
    coordinates: "118.873611, 34.571667",
  },
  {
    ident: "ZSLQ",
    type: "medium_airport",
    name: "Huangyan Luqiao Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-33",
    municipality: "Huangyan",
    gps_code: "ZSLQ",
    iata_code: "HYN",
    coordinates: "121.42900085449219, 28.56220054626465",
  },
  {
    ident: "ZSLY",
    type: "small_airport",
    name: "Shubuling Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-37",
    municipality: "Linyi",
    gps_code: "ZSLY",
    iata_code: "LYI",
    coordinates: "118.41200256347656, 35.04610061645508",
  },
  {
    ident: "ZSNB",
    type: "large_airport",
    name: "Ningbo Lishe International Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-33",
    municipality: "Ningbo",
    gps_code: "ZSNB",
    iata_code: "NGB",
    coordinates: "121.46199798583984, 29.82670021057129",
  },
  {
    ident: "ZSNJ",
    type: "large_airport",
    name: "Nanjing Lukou Airport",
    elevation_ft: "49",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Nanjing",
    gps_code: "ZSNJ",
    iata_code: "NKG",
    coordinates: "118.86199951171875, 31.742000579833984",
  },
  {
    ident: "ZSOF",
    type: "medium_airport",
    name: "Hefei Luogang International Airport",
    elevation_ft: "108",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-34",
    municipality: "Hefei",
    gps_code: "ZSOF",
    iata_code: "HFE",
    coordinates: "117.2979965209961, 31.780000686645508",
  },
  {
    ident: "ZSPD",
    type: "large_airport",
    name: "Shanghai Pudong International Airport",
    elevation_ft: "13",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-31",
    municipality: "Shanghai",
    gps_code: "ZSPD",
    iata_code: "PVG",
    coordinates: "121.80500030517578, 31.143400192260742",
  },
  {
    ident: "ZSQD",
    type: "medium_airport",
    name: "Liuting Airport",
    elevation_ft: "33",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-37",
    municipality: "Qingdao",
    gps_code: "ZSQD",
    iata_code: "TAO",
    coordinates: "120.374000549, 36.2661018372",
  },
  {
    ident: "ZSQZ",
    type: "medium_airport",
    name: "Quanzhou Jinjiang International Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-35",
    municipality: "Quanzhou",
    gps_code: "ZSQZ",
    iata_code: "JJN",
    coordinates: "118.589996, 24.7964",
  },
  {
    ident: "ZSRG",
    type: "medium_airport",
    name: "Rugao Air Base",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Rugao",
    gps_code: "ZSRG",
    iata_code: "RUG",
    coordinates: "120.50165557861328, 32.25788497924805",
  },
  {
    ident: "ZSRZ",
    type: "medium_airport",
    name: "Rizhao Shanzihe Airport",
    elevation_ft: "121",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-37",
    municipality: "Rizhao",
    gps_code: "ZSRZ",
    iata_code: "RIZ",
    coordinates: "119.324403, 35.405033",
  },
  {
    ident: "ZSSS",
    type: "large_airport",
    name: "Shanghai Hongqiao International Airport",
    elevation_ft: "10",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-31",
    municipality: "Shanghai",
    gps_code: "ZSSS",
    iata_code: "SHA",
    coordinates: "121.33599853515625, 31.197900772094727",
  },
  {
    ident: "ZSSZ",
    type: "medium_airport",
    name: "Suzhou Guangfu Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Suzhou",
    gps_code: "ZSSZ",
    iata_code: "SZV",
    coordinates: "120.401001, 31.2631",
  },
  {
    ident: "ZSTX",
    type: "medium_airport",
    name: "Tunxi International Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-34",
    municipality: "Huangshan",
    gps_code: "ZSTX",
    iata_code: "TXN",
    coordinates: "118.25599670410156, 29.733299255371094",
  },
  {
    ident: "ZSWF",
    type: "medium_airport",
    name: "Weifang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-37",
    municipality: "Weifang",
    gps_code: "ZSWF",
    iata_code: "WEF",
    coordinates: "119.119003, 36.646702",
  },
  {
    ident: "ZSWH",
    type: "medium_airport",
    name: "Weihai Airport",
    elevation_ft: "145",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-37",
    municipality: "Weihai",
    gps_code: "ZSWH",
    iata_code: "WEH",
    coordinates: "122.22899627685547, 37.18709945678711",
  },
  {
    ident: "ZSWU",
    type: "medium_airport",
    name: "Wuhu Air Base",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-34",
    municipality: "Wuhu",
    gps_code: "ZSWU",
    iata_code: "WHU",
    coordinates: "118.408997, 31.3906",
  },
  {
    ident: "ZSWX",
    type: "medium_airport",
    name: "Sunan Shuofang International Airport",
    elevation_ft: "24",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Wuxi",
    gps_code: "ZSWX",
    iata_code: "WUX",
    coordinates: "120.429000854, 31.494400024399997",
  },
  {
    ident: "ZSWY",
    type: "medium_airport",
    name: "Nanping Wuyishan Airport",
    elevation_ft: "614",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-35",
    municipality: "Wuyishan",
    gps_code: "ZSWY",
    iata_code: "WUS",
    coordinates: "118.000999, 27.7019",
  },
  {
    ident: "ZSWZ",
    type: "large_airport",
    name: "Wenzhou Longwan International Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-33",
    municipality: "Wenzhou",
    gps_code: "ZSWZ",
    iata_code: "WNZ",
    coordinates: "120.851997, 27.912201",
  },
  {
    ident: "ZSXZ",
    type: "medium_airport",
    name: "Xuzhou Guanyin Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Xuzhou",
    gps_code: "ZSXZ",
    iata_code: "XUZ",
    coordinates: "117.555278, 34.059056",
  },
  {
    ident: "ZSYA",
    type: "medium_airport",
    name: "Yangzhou Taizhou Airport",
    elevation_ft: "7",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Yangzhou and Taizhou",
    gps_code: "ZSYA",
    iata_code: "YTY",
    coordinates: "119.7198, 32.5634",
  },
  {
    ident: "ZSYC",
    type: "medium_airport",
    name: "Yichun Mingyueshan Airport",
    elevation_ft: "430",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-36",
    municipality: "Yichun",
    gps_code: "ZSYC",
    iata_code: "YIC",
    coordinates: "114.3062, 27.8025",
  },
  {
    ident: "ZSYN",
    type: "small_airport",
    name: "Yancheng Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-32",
    municipality: "Yancheng",
    gps_code: "ZSYN",
    iata_code: "YNZ",
    coordinates: "120.203056, 33.425833",
  },
  {
    ident: "ZSYW",
    type: "medium_airport",
    name: "Yiwu Airport",
    elevation_ft: "262",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-33",
    municipality: "Yiwu",
    gps_code: "ZSYW",
    iata_code: "YIW",
    coordinates: "120.031997681, 29.3446998596",
  },
  {
    ident: "ZSZS",
    type: "small_airport",
    name: "Zhoushan Airport",
    elevation_ft: "3",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-33",
    municipality: "Zhoushan",
    gps_code: "ZSZS",
    iata_code: "HSN",
    coordinates: "122.361999512, 29.9342002869",
  },
  {
    ident: "ZUAL",
    type: "medium_airport",
    name: "Ngari Gunsa Airport",
    elevation_ft: "14022",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-54",
    municipality: "Shiquanhe",
    gps_code: "ZUAL",
    iata_code: "NGQ",
    coordinates: "80.0530555556, 32.1",
  },
  {
    ident: "ZUAS",
    type: "medium_airport",
    name: "Anshun Huangguoshu Airport",
    elevation_ft: "4812",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-U-A",
    municipality: "Anshun",
    gps_code: "ZUAS",
    iata_code: "AVA",
    coordinates: "105.873333333, 26.2605555556",
  },
  {
    ident: "ZUBD",
    type: "medium_airport",
    name: "Qamdo Bangda Airport",
    elevation_ft: "14219",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-54",
    municipality: "Bangda",
    gps_code: "ZUBD",
    iata_code: "BPX",
    coordinates: "97.1082992553711, 30.553600311279297",
  },
  {
    ident: "ZUBJ",
    type: "medium_airport",
    name: "Bijie Feixiong Airport",
    elevation_ft: "4751",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    municipality: "Bijie",
    gps_code: "ZUBJ",
    iata_code: "BFJ",
    coordinates: "105.472097, 27.267066",
  },
  {
    ident: "ZUCK",
    type: "large_airport",
    name: "Chongqing Jiangbei International Airport",
    elevation_ft: "1365",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-50",
    municipality: "Chongqing",
    gps_code: "ZUCK",
    iata_code: "CKG",
    coordinates: "106.64199829101562, 29.719200134277344",
  },
  {
    ident: "ZUDC",
    type: "medium_airport",
    name: "Daocheng Yading Airport",
    elevation_ft: "14472",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Daocheng County",
    gps_code: "ZUDC",
    iata_code: "DCY",
    coordinates: "100.053333, 29.323056",
  },
  {
    ident: "ZUDX",
    type: "small_airport",
    name: "Dachuan Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Dazhou",
    gps_code: "ZUDX",
    iata_code: "DAX",
    coordinates: "107.4295, 31.1302",
  },
  {
    ident: "ZUGH",
    type: "small_airport",
    name: "Guanghan Airport",
    elevation_ft: "1531",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Civil Aviation Flight University of China",
    gps_code: "ZUGH",
    iata_code: "GHN",
    coordinates: "104.3296, 30.9485",
  },
  {
    ident: "ZUGU",
    type: "medium_airport",
    name: "Guangyuan Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Guangyuan",
    gps_code: "ZUGU",
    iata_code: "GYS",
    coordinates: "105.7020034790039, 32.3911018371582",
  },
  {
    ident: "ZUGY",
    type: "large_airport",
    name: "Longdongbao Airport",
    elevation_ft: "3736",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    municipality: "Guiyang",
    gps_code: "ZUGY",
    iata_code: "KWE",
    coordinates: "106.8010025024414, 26.53849983215332",
  },
  {
    ident: "ZUJZ",
    type: "medium_airport",
    name: "Jiuzhai Huanglong Airport",
    elevation_ft: "11327",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Jiuzhaigou",
    gps_code: "ZUJZ",
    iata_code: "JZH",
    coordinates: "103.682222222, 32.8533333333",
  },
  {
    ident: "ZUKD",
    type: "medium_airport",
    name: "Kangding Airport",
    elevation_ft: "14042",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Kangding",
    gps_code: "ZUKD",
    iata_code: "KGT",
    coordinates: "101.73872, 30.142464",
  },
  {
    ident: "ZUKJ",
    type: "medium_airport",
    name: "Kaili Airport",
    elevation_ft: "3115",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    municipality: "Huangping",
    gps_code: "ZUKJ",
    iata_code: "KJH",
    coordinates: "107.988, 26.972",
  },
  {
    ident: "ZULB",
    type: "small_airport",
    name: "Libo Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    municipality: "Libo County",
    gps_code: "ZULB",
    iata_code: "LLB",
    coordinates: "107.961667, 25.4525",
  },
  {
    ident: "ZULP",
    type: "small_airport",
    name: "Liangping Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-50",
    municipality: "Liangping",
    gps_code: "ZULP",
    iata_code: "LIA",
    coordinates: "107.786003, 30.679399",
  },
  {
    ident: "ZULS",
    type: "medium_airport",
    name: "Lhasa Gonggar Airport",
    elevation_ft: "11713",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-54",
    municipality: "Lhasa",
    gps_code: "ZULS",
    iata_code: "LXA",
    coordinates: "90.91190338130001, 29.2978000641",
  },
  {
    ident: "ZULZ",
    type: "small_airport",
    name: "Luzhou Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Luzhou",
    gps_code: "ZULZ",
    iata_code: "LZO",
    coordinates: "105.39299774169922, 28.85219955444336",
  },
  {
    ident: "ZUMH",
    type: "small_airport",
    name: "����nd����rkhaan Airport",
    elevation_ft: "3410",
    continent: "AS",
    iso_country: "MN",
    iso_region: "MN-039",
    municipality: "����nd����rkhaan",
    gps_code: "ZMUH",
    iata_code: "UNR",
    coordinates: "110.6092, 47.30486",
  },
  {
    ident: "ZUMT",
    type: "medium_airport",
    name: "Zunyi Maotai Airport",
    elevation_ft: "4068",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    municipality: "Zunyi",
    gps_code: "ZUMT",
    iata_code: "WMT",
    coordinates: "106.33268, 27.81638",
  },
  {
    ident: "ZUMY",
    type: "medium_airport",
    name: "Mianyang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Mianyang",
    gps_code: "ZUMY",
    iata_code: "MIG",
    coordinates: "104.74099731445312, 31.4281005859375",
  },
  {
    ident: "ZUNC",
    type: "small_airport",
    name: "Nanchong Gaoping Airport",
    elevation_ft: "1145",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Nanchong",
    gps_code: "ZUNC",
    iata_code: "NAO",
    coordinates: "106.1626, 30.79545",
  },
  {
    ident: "ZUNP",
    type: "medium_airport",
    name: "Liping Airport",
    elevation_ft: "1620",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    municipality: "Liping",
    gps_code: "ZUNP",
    iata_code: "HZH",
    coordinates: "109.1499, 26.32217",
  },
  {
    ident: "ZUNZ",
    type: "medium_airport",
    name: "Nyingchi Airport",
    elevation_ft: "9675",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-54",
    municipality: "Nyingchi",
    gps_code: "ZUNZ",
    iata_code: "LZY",
    coordinates: "94.33529663085938, 29.303300857543945",
  },
  {
    ident: "ZUPS",
    type: "medium_airport",
    name: "Liupanshui Yuezhao Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    municipality: "Liupanshui",
    gps_code: "ZUPS",
    iata_code: "LPF",
    coordinates: "104.979, 26.609417",
  },
  {
    ident: "ZUTC",
    type: "medium_airport",
    name: "Tengchong Tuofeng Airport",
    elevation_ft: "6250",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-53",
    municipality: "Tengchong",
    gps_code: "ZUTC",
    iata_code: "TCZ",
    coordinates: "98.48583333330001, 24.9380555556",
  },
  {
    ident: "ZUTR",
    type: "small_airport",
    name: "Tongren Fenghuang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    gps_code: "ZUTR",
    iata_code: "TEN",
    coordinates: "109.308889, 27.883333",
  },
  {
    ident: "ZUUU",
    type: "large_airport",
    name: "Chengdu Shuangliu International Airport",
    elevation_ft: "1625",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Chengdu",
    gps_code: "ZUUU",
    iata_code: "CTU",
    coordinates: "103.9469985961914, 30.578500747680664",
  },
  {
    ident: "ZUWX",
    type: "small_airport",
    name: "Wanxian Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-50",
    municipality: "Wanxian",
    gps_code: "ZUWX",
    iata_code: "WXN",
    coordinates: "108.433, 30.8017",
  },
  {
    ident: "ZUXC",
    type: "medium_airport",
    name: "Xichang Qingshan Airport",
    elevation_ft: "5112",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Xichang",
    gps_code: "ZUXC",
    iata_code: "XIC",
    coordinates: "102.18399810791016, 27.989099502563477",
  },
  {
    ident: "ZUYB",
    type: "small_airport",
    name: "Yibin Caiba Airport",
    elevation_ft: "924",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-51",
    municipality: "Yibin",
    gps_code: "ZUYB",
    iata_code: "YBP",
    coordinates: "104.545, 28.8005555556",
  },
  {
    ident: "ZUYI",
    type: "medium_airport",
    name: "Xingyi Airport",
    elevation_ft: "4150",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    municipality: "Xingyi",
    gps_code: "ZUYI",
    iata_code: "ACX",
    coordinates: "104.959444444, 25.0863888889",
  },
  {
    ident: "ZUZY",
    type: "medium_airport",
    name: "Zunyi Xinzhou Airport",
    elevation_ft: "2920",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-52",
    municipality: "Zunyi",
    gps_code: "ZUZY",
    iata_code: "ZYI",
    coordinates: "107.0007, 27.5895",
  },
  {
    ident: "ZWAK",
    type: "medium_airport",
    name: "Aksu Airport",
    elevation_ft: "3816",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Aksu",
    gps_code: "ZWAK",
    iata_code: "AKU",
    coordinates: "80.291702, 41.262501",
  },
  {
    ident: "ZWAX",
    type: "medium_airport",
    name: "Alashankou Bole (Bortala) airport",
    elevation_ft: "1253",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Bole",
    gps_code: "ZWBL",
    iata_code: "BPL",
    coordinates: "82.3, 44.895",
  },
  {
    ident: "ZWCM",
    type: "medium_airport",
    name: "Qiemo Yudu Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Qiemo",
    gps_code: "ZWCM",
    iata_code: "IQM",
    coordinates: "85.465556, 38.233611",
  },
  {
    ident: "ZWFY",
    type: "medium_airport",
    name: "Fuyun Koktokay Airport",
    elevation_ft: "3081",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Fuyun",
    gps_code: "ZWFY",
    iata_code: "FYN",
    coordinates: "89.512006, 46.804169",
  },
  {
    ident: "ZWHM",
    type: "medium_airport",
    name: "Hami Airport",
    elevation_ft: "2703",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Hami",
    gps_code: "ZWHM",
    iata_code: "HMI",
    coordinates: "93.669197, 42.8414",
  },
  {
    ident: "ZWKC",
    type: "small_airport",
    name: "Kuqa Airport",
    elevation_ft: "3524",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Kuqa",
    gps_code: "ZWKC",
    iata_code: "KCA",
    coordinates: "82.872917, 41.677856",
  },
  {
    ident: "ZWKL",
    type: "medium_airport",
    name: "Korla Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Korla",
    gps_code: "ZWKL",
    iata_code: "KRL",
    coordinates: "86.12889862060547, 41.69779968261719",
  },
  {
    ident: "ZWKM",
    type: "small_airport",
    name: "Karamay Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Karamay",
    gps_code: "ZWKM",
    iata_code: "KRY",
    coordinates: "84.9527, 45.46655",
  },
  {
    ident: "ZWKN",
    type: "medium_airport",
    name: "Kanas Airport",
    elevation_ft: "3921",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Burqin",
    gps_code: "ZWKN",
    iata_code: "KJI",
    coordinates: "86.9959, 48.2223",
  },
  {
    ident: "ZWNL",
    type: "medium_airport",
    name: "Xinyuan Nalati Airport",
    elevation_ft: "3050",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Xinyuan County",
    gps_code: "ZWNL",
    iata_code: "NLT",
    coordinates: "83.3786, 43.4318",
  },
  {
    ident: "ZWRQ",
    type: "medium_airport",
    name: "Ruoqiang Loulan Airport",
    elevation_ft: "2916",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Ruoqiang Town",
    gps_code: "ZWRQ",
    iata_code: "RQA",
    coordinates: "88.008333, 38.974722",
  },
  {
    ident: "ZWSC",
    type: "medium_airport",
    name: "Yeerqiang Airport",
    elevation_ft: "4232",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Shache",
    gps_code: "ZWSC",
    iata_code: "QSZ",
    coordinates: "77.075192, 38.281055",
  },
  {
    ident: "ZWSH",
    type: "medium_airport",
    name: "Kashgar Airport",
    elevation_ft: "4529",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Kashgar",
    gps_code: "ZWSH",
    iata_code: "KHG",
    coordinates: "76.0199966431, 39.5429000854",
  },
  {
    ident: "ZWSS",
    type: "medium_airport",
    name: "Shanshan Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Shanshan",
    gps_code: "ZWSS",
    iata_code: "SXJ",
    coordinates: "90.24749755859375, 42.91170120239258",
  },
  {
    ident: "ZWTC",
    type: "small_airport",
    name: "Tacheng Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Tacheng",
    gps_code: "ZWTC",
    iata_code: "TCG",
    coordinates: "83.3407974243164, 46.67250061035156",
  },
  {
    ident: "ZWTN",
    type: "medium_airport",
    name: "Hotan Airport",
    elevation_ft: "4672",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Hotan",
    gps_code: "ZWTN",
    iata_code: "HTN",
    coordinates: "79.86489868164062, 37.03850173950195",
  },
  {
    ident: "ZWTP",
    type: "medium_airport",
    name: "Turpan Jiaohe Airport",
    elevation_ft: "934",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Turpan",
    gps_code: "ZWTP",
    iata_code: "TLQ",
    coordinates: "89.0987, 43.0308",
  },
  {
    ident: "ZWWW",
    type: "large_airport",
    name: "����r����mqi Diwopu International Airport",
    elevation_ft: "2125",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "����r����mqi",
    gps_code: "ZWWW",
    iata_code: "URC",
    coordinates: "87.47419738769531, 43.907100677490234",
  },
  {
    ident: "ZWYN",
    type: "small_airport",
    name: "Yining Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-65",
    municipality: "Yining",
    gps_code: "ZWYN",
    iata_code: "YIN",
    coordinates: "81.330299, 43.955799",
  },
  {
    ident: "ZYAS",
    type: "medium_airport",
    name: "Anshan Air Base",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-21",
    municipality: "Anshan",
    gps_code: "ZYAS",
    iata_code: "AOG",
    coordinates: "122.853996, 41.105301",
  },
  {
    ident: "ZYCC",
    type: "medium_airport",
    name: "Longjia Airport",
    elevation_ft: "706",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-22",
    municipality: "Changchun",
    gps_code: "ZYCC",
    iata_code: "CGQ",
    coordinates: "125.684997559, 43.9962005615",
  },
  {
    ident: "ZYCH",
    type: "medium_airport",
    name: "Changhai Airport",
    elevation_ft: "80",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-21",
    municipality: "Changhai",
    gps_code: "ZYCH",
    iata_code: "CNI",
    coordinates: "122.666944444, 39.2666666667",
  },
  {
    ident: "ZYCY",
    type: "medium_airport",
    name: "Chaoyang Airport",
    elevation_ft: "568",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-21",
    municipality: "Chaoyang",
    gps_code: "ZYCY",
    iata_code: "CHG",
    coordinates: "120.434998, 41.538101",
  },
  {
    ident: "ZYDU",
    type: "medium_airport",
    name: "Wudalianchi Dedu Airport",
    elevation_ft: "984",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Wudalianchi",
    gps_code: "ZYDU",
    iata_code: "DTU",
    coordinates: "126.133, 48.445",
  },
  {
    ident: "ZYFY",
    type: "medium_airport",
    name: "Dongji Aiport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Fuyuan",
    gps_code: "ZYFY",
    iata_code: "FYJ",
    coordinates: "134.366447, 48.199494",
  },
  {
    ident: "ZYHB",
    type: "large_airport",
    name: "Taiping Airport",
    elevation_ft: "457",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Harbin",
    gps_code: "ZYHB",
    iata_code: "HRB",
    coordinates: "126.25, 45.6234016418457",
  },
  {
    ident: "ZYHE",
    type: "medium_airport",
    name: "Heihe Airport",
    elevation_ft: "8530",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Heihe",
    gps_code: "ZYHE",
    iata_code: "HEK",
    coordinates: "127.308883667, 50.1716209371",
  },
  {
    ident: "ZYJL",
    type: "small_airport",
    name: "Jilin Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-22",
    municipality: "Jilin",
    gps_code: "ZYJL",
    iata_code: "JIL",
    coordinates: "126.396004, 44.002201",
  },
  {
    ident: "ZYJM",
    type: "medium_airport",
    name: "Jiamusi Airport",
    elevation_ft: "262",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Jiamusi",
    gps_code: "ZYJM",
    iata_code: "JMU",
    coordinates: "130.464996338, 46.84339904789999",
  },
  {
    ident: "ZYJS",
    type: "medium_airport",
    name: "Jiansanjiang Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Jiansanjiang",
    gps_code: "ZYJS",
    iata_code: "JSJ",
    coordinates: "132.660278, 47.11",
  },
  {
    ident: "ZYJX",
    type: "medium_airport",
    name: "Jixi Xingkaihu Airport",
    elevation_ft: "760",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Jixi",
    gps_code: "ZYJX",
    iata_code: "JXA",
    coordinates: "131.193, 45.293",
  },
  {
    ident: "ZYJZ",
    type: "medium_airport",
    name: "Jinzhou Airport",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-21",
    municipality: "Jinzhou",
    gps_code: "ZYJZ",
    iata_code: "JNZ",
    coordinates: "121.06199645996094, 41.10139846801758",
  },
  {
    ident: "ZYLD",
    type: "medium_airport",
    name: "Lindu Airport",
    elevation_ft: "791",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Yichun",
    gps_code: "ZYLD",
    iata_code: "LDS",
    coordinates: "129.019125, 47.7520555556",
  },
  {
    ident: "ZYLS",
    type: "medium_airport",
    name: "Yushu Batang Airport",
    elevation_ft: "12816",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-63",
    municipality: "Yushu",
    gps_code: "ZYLS",
    iata_code: "YUS",
    coordinates: "97.0363888889, 32.836388888900004",
  },
  {
    ident: "ZYMD",
    type: "medium_airport",
    name: "Mudanjiang Hailang International Airport",
    elevation_ft: "883",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Mudanjiang",
    gps_code: "ZYMD",
    iata_code: "MDG",
    coordinates: "129.569000244, 44.5241012573",
  },
  {
    ident: "ZYMH",
    type: "medium_airport",
    name: "Gu-Lian Airport",
    elevation_ft: "1836",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Mohe",
    gps_code: "ZYMH",
    iata_code: "OHE",
    coordinates: "122.43, 52.912777777799995",
  },
  {
    ident: "ZYQQ",
    type: "medium_airport",
    name: "Qiqihar Sanjiazi Airport",
    elevation_ft: "477",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-23",
    municipality: "Qiqihar",
    gps_code: "ZYQQ",
    iata_code: "NDG",
    coordinates: "123.91799926757812, 47.239601135253906",
  },
  {
    ident: "ZYSQ",
    type: "medium_airport",
    name: "Songyuan Chaganhu Airport",
    elevation_ft: "459",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-22",
    municipality: "Qian Gorlos Mongol Autonomous County",
    gps_code: "ZYSQ",
    iata_code: "YSQ",
    coordinates: "124.550178, 44.938114",
  },
  {
    ident: "ZYTL",
    type: "large_airport",
    name: "Zhoushuizi Airport",
    elevation_ft: "107",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-21",
    municipality: "Dalian",
    gps_code: "ZYTL",
    iata_code: "DLC",
    coordinates: "121.53900146484375, 38.9656982421875",
  },
  {
    ident: "ZYTN",
    type: "medium_airport",
    name: "Tonghua Sanyuanpu Airport",
    elevation_ft: "1200",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-22",
    municipality: "Tonghua",
    gps_code: "ZYTN",
    iata_code: "TNH",
    coordinates: "125.703333333, 42.2538888889",
  },
  {
    ident: "ZYTX",
    type: "large_airport",
    name: "Taoxian Airport",
    elevation_ft: "198",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-21",
    municipality: "Shenyang",
    gps_code: "ZYTX",
    iata_code: "SHE",
    coordinates: "123.48300170898438, 41.639801025390625",
  },
  {
    ident: "ZYYJ",
    type: "medium_airport",
    name: "Yanji Chaoyangchuan Airport",
    elevation_ft: "624",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-22",
    municipality: "Yanji",
    gps_code: "ZYYJ",
    iata_code: "YNJ",
    coordinates: "129.451004028, 42.8828010559",
  },
  {
    ident: "ZYYK",
    type: "medium_airport",
    name: "Yingkou Lanqi Airport",
    elevation_ft: "0",
    continent: "AS",
    iso_country: "CN",
    iso_region: "CN-21",
    municipality: "Yingkou",
    gps_code: "ZYYK",
    iata_code: "YKH",
    coordinates: "122.3586, 40.542524",
  },
];

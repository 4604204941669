import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.scss";

function Modal({ children, onClose, isOpen }) {
  return (
    isOpen && (
      <div className={styles.modal} onClick={onClose}>
        <div
          className={`${styles.popUp} `}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    )
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
export default Modal;

import PropTypes from "prop-types";
import Seat from "./Seat";

const displaySeats = (
  seatList,
  onSeatSelect,
  seatData,
  segmentindex,
  travelerindex,
  segmentId,
  segmentdata
) => {
  return (
    <div style={{ position: "relative" }}>
      {seatList.map((seat) => (
        <Seat
          number={seat?.number}
          x={seat?.coordinates?.x}
          y={seat?.coordinates?.y}
          availability={seat?.travelerPricing?.[0]?.seatAvailabilityStatus}
          price={seat?.travelerPricing?.[0]?.price}
          onSeatSelect={onSeatSelect}
          segmentindex={segmentindex}
          travelerindex={travelerindex}
          seatData={seatData}
          segmentId={segmentId}
          segmentdata={segmentdata}
        />
      ))}
    </div>
  );
};

function Deck(props) {
  const {
    deck,
    onSeatSelect,
    seatData,
    segmentindex,
    travelerindex,
    segmentId,
    segmentdata,
  } = props;
  const { deckConfiguration, seats } = deck;
  const { width, length } = deckConfiguration;
  return (
    <div
      id="deck"
      style={{
        width: `${width * 2.2}em`,
        height: `${length * 2.1}em`,
        paddingTop: "1em",
        marginTop: "1.5em",
        boxShadow: "0 35px 60px -15px rgba(0, 0, 0, 0.3)",
        borderRadius: "20px",
      }}
    >
      {displaySeats(
        seats,
        onSeatSelect,
        seatData,
        segmentindex,
        travelerindex,
        segmentId,
        segmentdata
      )}
    </div>
  );
}

export default Deck;

Deck.propTypes = {
  deck: PropTypes.shape().isRequired,
  onSeatSelect: PropTypes.func.isRequired,
  seatData: PropTypes.shape().isRequired,
  segmentindex: PropTypes.number.isRequired,
  travelerindex: PropTypes.number.isRequired,
  segmentId: PropTypes.number.isRequired,
  segmentdata: PropTypes.shape().isRequired,
};

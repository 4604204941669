import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  formatDOLS,
  formatGHS,
  formatNGN,
  formatXAF,
  formatXOF,
} from "../../../utils/formatingFunctions";

function Seat(props) {
  const { currency } = useSelector((state) => state.auth);

  const {
    onSeatSelect,
    availability,
    y,
    x,
    number,
    price,
    segmentindex,
    travelerindex,
    segmentId,
    segmentdata,
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const isSelected =
    segmentdata?.additionalServices?.chargeableSeatNumber === number;
  // Define the base color for the seat based on availability
  let baseColor = "#F44336";
  if (availability === "AVAILABLE") {
    baseColor = "#4CAF50";
  } else if (availability === "OCCUPIED") {
    baseColor = "#FF9800"; // Set the color for OCCUPIED seats
  }

  // Apply the selected and hover colors based on the conditions
  const selectedColor = isSelected ? "#083d53" : baseColor;
  const hoverColor = isHovering ? "#FF5E0D" : selectedColor;
  const style = {
    position: "absolute",
    left: `${y * 2}em`,
    top: `${x * 2}em`,
    backgroundColor: hoverColor,
    color: "grey",
    margin: "20px",
    borderRadius: "4px",
    width: "1.6em",
    height: "1.6em",
    cursor: "pointer",
    // border: `0.5px solid #FF5E0D`,
  };
  const tooltipStyle = {
    position: "absolute",
    top: "-2.5em",
    left: "3em",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "white",
    width: "150px",
    padding: "0.5em",
    borderRadius: "4px",
    visibility: isHovering ? "visible" : "hidden",
    opacity: isHovering ? 1 : 0,
    transition: "visibility 0s, opacity 0.3s linear",
    zIndex: 10000,
    fontSize: "10px",
  };
  const handleClick = (e) => {
    if (availability === "AVAILABLE") {
      onSeatSelect(e, travelerindex, segmentId, segmentindex, number);
    }
  };
  console.log({ helo: price });
  return (
    <div
      className="seat"
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => handleClick(e)}
    >
      <div style={tooltipStyle}>
        <p>Seat Number: {number}</p>
        <p>Availability: {availability}</p>
        <p>
          Price:
          <span>
            {" "}
            {currency === "USD" && <span>{formatDOLS(price?.total)}</span>}
            {currency === "NGN" && (
              <span>{formatNGN(price?.TramangoTotalNaira)}</span>
            )}
            {currency === "XOF" && (
              <span>{formatXOF(price?.TramangoTotalXOF)}</span>
            )}
            {currency === "XAF" && (
              <span>{formatXAF(price?.TramangoTotalXAF)}</span>
            )}
            {currency === "GHS" && (
              <span>{formatGHS(price?.TramangoTotalGHS)}</span>
            )}
          </span>
        </p>
      </div>
    </div>
  );
}
export default Seat;

Seat.propTypes = {
  onSeatSelect: PropTypes.func.isRequired,
  availability: PropTypes.string.isRequired,
  y: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  price: PropTypes.shape().isRequired,
  segmentindex: PropTypes.number.isRequired,
  travelerindex: PropTypes.number.isRequired,
  segmentId: PropTypes.number.isRequired,
  segmentdata: PropTypes.shape().isRequired,
};

// export const formatFlightTime = (time) => {
//   // formats PT28H25M to 28h25m
//   if (time) {
//     const newFlightTime = time.split("T")[1];
//     return newFlightTime.toLowerCase();
//   }
//   return null;
// };

// export const formatFlightTime = (time) => {
//   // formats PT28H25M to "28h 25m"
//   if (time) {
//     const newFlightTime = time.split("T")[1]; // Extract the time part
//     const [hours, minutes] = newFlightTime.split("H"); // Split hours and minutes
//     return `${hours}h ${minutes?.toLowerCase()}`; // Format with a space between hours and minutes
//   }
//   return null;
// };

export const formatFlightTime = (time) => {
  if (time) {
    const match = time.match(/PT(\d+H)?(\d+M)?/); // Match the hours and minutes
    if (match) {
      const hours = match[1] ? parseInt(match[1]) : 0; // Extract hours, default to 0 if not present
      const minutes = match[2] ? parseInt(match[2]) : 0; // Extract minutes, default to 0 if not present
      return `${hours}h ${minutes}m`; // Format the time
    }
  }
  return null; // Return null if time format is invalid or not provided
};

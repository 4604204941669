/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
// import { useSelector } from "react-redux";
import "react-dates/initialize";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import styled from "styled-components";
import { FaCalendarDay } from "react-icons/fa";
import moment from "moment";
import "moment/locale/fr";

import styles from "./styles.module.scss";

const StyledDatePickerWrapper = styled.div``;
const StyledDateRangePickerWrapper = styled.div``;

// Override default hover styles for DayPickerSingleDateController
const StyledDayPickerSingleDateController = styled(DateRangePicker)`
  .CalendarDay__default:hover {
    background-color: lightblue;
    color: white;
    /* Add any other hover styles you want */
  }

  .CalendarDay__hovered_span {
    background-color: lightgreen;
    color: white;
    /* Add any other hover styles you want */
  }
`;

function DateSelector({
  index,
  updateSegmentDate,
  segments,
  triptype,
  setSegments,
  allDates,
  engineErrors,
}) {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const { date } = useSelector((state) => state.flight);

  let orientation = null;
  if (typeof window !== "undefined") {
    orientation = window.matchMedia("(max-width: 700px)").matches
      ? "vertical"
      : "horizontal";
  }

  function handledateChange(date) {
    updateSegmentDate(index, date);
  }
  const storedLanguage =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("selectedLanguage")
      : null;
  moment.locale(storedLanguage || "en");

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setSegments((prevSegments) => {
      // Create new segments with the updated departure dates
      const updatedSegment0 = {
        ...prevSegments[0],
        departureDateTimeRange: {
          ...prevSegments[0].departureDateTimeRange,
          date: startDate ? moment(startDate).utc().format("YYYY-MM-DD") : null,
        },
      };
      const updatedSegment1 = {
        ...prevSegments[1],
        departureDateTimeRange: {
          ...prevSegments[1].departureDateTimeRange,
          date: endDate ? moment(endDate).utc().format("YYYY-MM-DD") : null,
        },
      };

      // Return the updated segments in a new array
      return [updatedSegment0, updatedSegment1];
    });
  };

  const isDayBlocked = (day) => {
    if (triptype === "multiTrip") {
      // Check if the selected day is before any of the selected dates
      return allDates.some((selectedDate) => day.isBefore(selectedDate, "day"));
    }
    return null;
  };
  const isDaySelected = (day) => {
    // Check if the day is in the selectedDates array
    return allDates.some((selectedDate) => day.isSame(selectedDate, "day"));
  };
  const errors = engineErrors?.filter(
    (error) => error.index === index && error.field === "date"
  );
  return (
    <div className={styles.date}>
      <label htmlFor="date">
        <FaCalendarDay className={styles.inputIcon} />
        <div className={styles.input}>
          {triptype === "roundTrip" ? (
            <StyledDateRangePickerWrapper>
              <StyledDayPickerSingleDateController
                isRTL={false}
                numberOfMonths={2}
                startDate={startDate}
                hideKeyboardShortcutsPanel
                startDateId="tata-start-date"
                endDate={endDate}
                endDateId="tata-end-date"
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                orientation={orientation}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                initialDate={{
                  _isAMomentObject: true,
                  _isUTC: false,
                  _pf: {
                    empty: false,
                    unusedTokens: [],
                    unusedInput: [],
                  },
                }}
                displayFormat="D/M/YYYY"
              />
            </StyledDateRangePickerWrapper>
          ) : (
            <StyledDatePickerWrapper>
              <SingleDatePicker
                isRTL={false}
                placeholder={t("datePlaceholder")}
                numberOfMonths={1}
                hideKeyboardShortcutsPanel
                onDateChange={(date) => handledateChange(date)}
                onFocusChange={({ focused }) => setFocused(focused)}
                focused={focused}
                date={
                  segments[index].departureDateTimeRange.date !== ""
                    ? moment(segments[index].departureDateTimeRange.date)
                    : null
                }
                displayFormat="D/M/YYYY"
                isDayHighlighted={isDaySelected}
                isDayBlocked={isDayBlocked}
              />
            </StyledDatePickerWrapper>
          )}
        </div>
      </label>
      {errors?.length > 0 && (
        <p className="text-error text-sm block mt-1">{errors[0].message}</p>
      )}
    </div>
  );
}
DateSelector.propTypes = {
  index: PropTypes.number.isRequired,
  updateSegmentDate: PropTypes.func.isRequired,
  setSegments: PropTypes.func.isRequired,
  triptype: PropTypes.string.isRequired,
  allDates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  engineErrors: PropTypes.shape.isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      originLocationCode: PropTypes.string.isRequired,
      destinationLocationCode: PropTypes.string.isRequired,
      departureDateTimeRange: PropTypes.shape({
        date: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
export default DateSelector;

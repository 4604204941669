/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import querystring from "querystring";
import * as moment from "moment";
import { FaChevronDown, FaPlus, FaMinus, FaSearch } from "react-icons/fa";
import Router from "next/router";
import { useTranslation } from "react-i18next";
import Modal from "../../../common/Modal";
import ErrorHandler from "../ErrorHandler";
import {
  useComponentVisible,
  useClassSelector,
} from "../../../hooks/useComponentVisible";
import "react-datepicker/dist/react-datepicker.css";
import DepartureInput from "./DepartureInput";
import ArrivalInput from "./ArrivalInput";
import DateSelector from "./DateSelector";
import { PlaneSearch } from "../../../features/flight/flightThunk";
import { getIATA } from "../../../utils/flight/getIATA";
import {
  setTriptype,
  setTicketType,
  setselectedTicketType,
  minusAdult,
  addAdult,
  addChild,
  minusChild,
  addInfant,
  minusInfant,
  addHeldInfant,
  minusHeldInfant,
  setBookingData,
  reset,
} from "../../../features/flight/flightSlice";
import { encodeObjectToURLComponent } from "../../../utils/transformers/encodeObjectToURLComponent";
import styles from "./styles.module.scss";
import FlightSwitcher from "../FlightSwitcher";
import { generateId } from "../../../utils/generators/IDGenerator";

function OneWaySegment({
  tripCards,
  segments,
  handleSearch,
  updateSegment,
  updateSegmentDate,
  triptype,
  toggleSegment,
  formattedValueDeparture,
  setFormattedValueDeparture,
  formattedValueArrival,
  setFormattedValueArrival,
  handleRoundUpdate,
  allDates,
  engineErrors,
}) {
  const { flightLoader } = useSelector((state) => state.flight);
  return (
    <div>
      <form className={`${styles.flightEngine} flightEngine`}>
        {tripCards.map((trip, index) => (
          <div className={styles.tripCard} key={index}>
            {/* departure */}
            <DepartureInput
              index={index}
              updateSegment={updateSegment}
              triptype={triptype}
              toggleSegment={toggleSegment}
              segments={segments}
              formattedValueDeparture={formattedValueDeparture}
              setFormattedValueDeparture={setFormattedValueDeparture}
              engineErrors={engineErrors}
            />
            {/* arrival */}
            <ArrivalInput
              index={index}
              updateSegment={updateSegment}
              triptype={triptype}
              segments={segments}
              formattedValueArrival={formattedValueArrival}
              setFormattedValueArrival={setFormattedValueArrival}
              engineErrors={engineErrors}
            />
            {/* date selector */}
            <DateSelector
              index={index}
              updateSegmentDate={updateSegmentDate}
              segments={segments}
              triptype={triptype}
              allDates={allDates}
              engineErrors={engineErrors}
            />

            {/* button */}
            <div className={styles.button}>
              <button onClick={(e) => handleSearch(e)} type="submit">
                <FaSearch className={styles.icon} />
              </button>
            </div>
          </div>
        ))}
        <div className={styles.buttonSmall} onClick={(e) => handleSearch(e)}>
          <button type="button" className={styles.small}>
            Search
          </button>
        </div>
      </form>
    </div>
  );
}
function RoundTripSegment({
  tripCards,
  segments,
  updateSegment,
  updateSegmentDate,
  handleSearch,
  triptype,
  handleRoundUpdate,
  setSegments,
  toggleSegment,
  formattedValueDeparture,
  setFormattedValueDeparture,
  formattedValueArrival,
  setFormattedValueArrival,
  allDates,
  engineErrors,
}) {
  const { flightLoader } = useSelector((state) => state.flight);

  return (
    <div>
      <form className={`${styles.flightEngine} flightEngine`}>
        {tripCards.map((trip, index) => (
          <div className={styles.tripCard} key={index}>
            {/* departure */}
            <DepartureInput
              index={index}
              updateSegment={updateSegment}
              handleRoundUpdate={handleRoundUpdate}
              triptype={triptype}
              toggleSegment={toggleSegment}
              formattedValueDeparture={formattedValueDeparture}
              setFormattedValueDeparture={setFormattedValueDeparture}
              engineErrors={engineErrors}
            />
            {/* arrival */}
            <ArrivalInput
              index={index}
              updateSegment={updateSegment}
              handleRoundUpdate={handleRoundUpdate}
              triptype={triptype}
              formattedValueArrival={formattedValueArrival}
              setFormattedValueArrival={setFormattedValueArrival}
              engineErrors={engineErrors}
            />
            {/* date selector */}
            <DateSelector
              index={index}
              updateSegmentDate={updateSegmentDate}
              segments={segments}
              triptype={triptype}
              setSegments={setSegments}
              allDates={allDates}
              engineErrors={engineErrors}
            />

            {/* button */}
            <div className={styles.button}>
              <button onClick={(e) => handleSearch(e)} type="submit">
                <FaSearch className={styles.icon} />
              </button>
            </div>
          </div>
        ))}
        <div className={styles.buttonSmall} onClick={(e) => handleSearch(e)}>
          <button type="button" className={styles.small}>
            Search
          </button>
        </div>
      </form>
    </div>
  );
}
function MultiTripSegment({
  tripCards,
  triptype,
  segments,
  updateSegment,
  updateSegmentDate,
  removeSegment,
  addSegment,
  handleSearch,
  toggleSegment,
  formattedValueDeparture,
  setFormattedValueDeparture,
  formattedValueArrival,
  setFormattedValueArrival,
  handleRoundUpdate,
  allDates,
  engineErrors,
}) {
  const { flightLoader } = useSelector((state) => state.flight);

  return (
    <form className={`${styles.flightEngine} flightEngine`}>
      {segments.map((trip, index) => (
        <div className={styles.tripCard} key={index}>
          <p className={styles.tripCardTop}>
            <span>Trip {index + 1}</span>
            {segments.length > 2 && index !== 0 && (
              <span
                className={styles.removeBtn}
                onClick={(e) => removeSegment(e, index)}
              >
                Remove trip
              </span>
            )}
          </p>

          {/* departure */}
          <DepartureInput
            index={index}
            updateSegment={updateSegment}
            triptype={triptype}
            toggleSegment={toggleSegment}
            formattedValueDeparture={formattedValueDeparture}
            setFormattedValueDeparture={setFormattedValueDeparture}
            engineErrors={engineErrors}
          />
          {/* arrival */}
          <ArrivalInput
            index={index}
            updateSegment={updateSegment}
            triptype={triptype}
            formattedValueArrival={formattedValueArrival}
            setFormattedValueArrival={setFormattedValueArrival}
            engineErrors={engineErrors}
          />
          {/* date selector */}
          <DateSelector
            index={index}
            updateSegmentDate={updateSegmentDate}
            segments={segments}
            triptype={triptype}
            allDates={allDates}
            engineErrors={engineErrors}
          />

          {/* button */}
          <div className={styles.button}>
            {index === 0 ? (
              <button onClick={(e) => handleSearch(e)} type="submit">
                <FaSearch className={styles.icon} />
              </button>
            ) : (
              <>
                {index > 0 && index !== segments.length - 1 && (
                  <button
                    type="button"
                    onClick={(e) => removeSegment(e, index)}
                  >
                    <FaMinus className={styles.icon} />
                  </button>
                )}
                {index === 4 && (
                  <button
                    type="button"
                    onClick={(e) => removeSegment(e, index)}
                  >
                    <FaMinus className={styles.icon} />
                  </button>
                )}
                {index === segments.length - 1 && segments.length !== 5 && (
                  <button type="button" onClick={(e) => addSegment(e)}>
                    <FaPlus className={styles.icon} />
                  </button>
                )}
              </>
            )}
          </div>

          {segments.length !== 5 && index !== 0 && (
            <p className={styles.tripCardBottom} onClick={addSegment}>
              <span className={styles.addBtn}>+ Add a trip</span>
            </p>
          )}
        </div>
      ))}
      <div className={styles.buttonSmall} onClick={(e) => handleSearch(e)}>
        <button type="button" className={styles.small}>
          Search
        </button>
      </div>
    </form>
  );
}

function BookingEngine() {
  const { location } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [reloadCard, setreloadCard] = useState(false);
  const [tripCard, setTripCard] = useState([1]);
  const [tripCategory, setTripCategory] = useState("oneWay");
  const [engineErrors, setEngineErrors] = useState([]);
  const [segments, setSegments] = useState([
    {
      id: generateId(8),
      originLocationCode: "",
      destinationLocationCode: "",
      departureDateTimeRange: { date: "" },
    },
  ]);
  const [formattedValueDeparture, setFormattedValueDeparture] = useState([""]);
  const [formattedValueArrival, setFormattedValueArrival] = useState([""]);

  const [allDates, setAllDates] = useState([]);

  // Function to extract dates from segments
  const getDatesFromSegments = () => {
    const dates = segments.map((segment) => {
      // eslint-disable-next-line prefer-destructuring
      const date = segment.departureDateTimeRange.date;
      const formattedDate = moment(date).format("YYYY-MM-DD");
      return formattedDate;
    });
    setAllDates(dates);
  };
  useEffect(() => {
    getDatesFromSegments();
  }, []);
  useEffect(() => {
    getDatesFromSegments();
  }, [segments]);

  const [roundSegment, setRoundSegment] = useState([
    // {
    //   id: generateId(8),
    //   originLocationCode: segments[0].destinationLocationCode,
    //   destinationLocationCode: segments[0].originLocationCode,
    //   departureDateTimeRange: { date: "" },
    // },
  ]);

  const handleRoundUpdate = () => {
    if (
      tripCategory === "roundTrip" &&
      segments.length === 2 &&
      segments[0].originLocationCode !== segments[1].destinationLocationCode &&
      segments[0].originLocationCode &&
      segments[0].destinationLocationCode // Check if segment[0] has valid origin and destination
    ) {
      setSegments((prevSegments) => {
        const [segment0, segment1] = prevSegments; // Destructure the previous segments
        const updatedSegment = {
          ...segment1,
          originLocationCode: segment0.destinationLocationCode,
          destinationLocationCode: segment0.originLocationCode,
        };
        // Use functional update to ensure the latest state is used
        return [segment0, updatedSegment];
      });
    }
  };

  // const swithFormatValue = (index) => {
  //   const a = formattedValueArrival;
  //   const b = formattedValueDeparture;
  //   console.log(a);
  //   console.log("skip");
  //   console.log(b);
  //   setFormattedValueArrival(formattedValueDeparture);
  //   setFormattedValueDeparture(a);
  // };
  const swithFormatValue = (index) => {
    if (index < 0 || index >= formattedValueArrival.length) {
      // index is out of bounds
      return;
    }

    const newFormattedValueArrival = [...formattedValueArrival];
    const newFormattedValueDeparture = [...formattedValueDeparture];

    const temp = newFormattedValueArrival[index];
    newFormattedValueArrival[index] = newFormattedValueDeparture[index];
    newFormattedValueDeparture[index] = temp;

    setFormattedValueArrival(newFormattedValueArrival);
    setFormattedValueDeparture(newFormattedValueDeparture);
  };

  const toggleSegment = (index) => {
    setSegments((prevSegments) => {
      const { originLocationCode, destinationLocationCode } =
        prevSegments[index];
      const updatedSegment = {
        ...prevSegments[index],
        originLocationCode: destinationLocationCode,
        destinationLocationCode: originLocationCode,
      };
      return prevSegments.map((segment, i) =>
        i === index ? updatedSegment : segment
      );
    });
    swithFormatValue(index);
  };

  const [travelers, setTravelers] = useState([]);
  // to add segments details in state

  const addSegment = () => {
    setSegments([
      ...segments,
      {
        id: Date.now(),
        originLocationCode: "",
        destinationLocationCode: "",
        departureDateTimeRange: { date: "" },
      },
    ]);
  };
  // Handler function to update segment details in state
  const updateSegment = (index, field, value) => {
    const updatedSegments = [...segments];
    updatedSegments[index][field] = getIATA(value);

    if (tripCategory === "roundTrip") {
      // If the origin of the first segment is being updated, update the destination of the second segment
      if (
        index === 0 &&
        field === "originLocationCode" &&
        updatedSegments.length > 1
      ) {
        updatedSegments[1].destinationLocationCode = getIATA(value);
      }
      // If the destination of the first segment is being updated, update the origin of the second segment
      else if (
        index === 0 &&
        field === "destinationLocationCode" &&
        updatedSegments.length > 1
      ) {
        updatedSegments[1].originLocationCode = getIATA(value);
      }
      // If the origin of the second segment is being updated, update the destination of the first segment
      else if (
        index === 1 &&
        field === "originLocationCode" &&
        updatedSegments.length > 0
      ) {
        updatedSegments[0].destinationLocationCode = getIATA(value);
      }
      // If the destination of the second segment is being updated, update the origin of the first segment
      else if (
        index === 1 &&
        field === "destinationLocationCode" &&
        updatedSegments.length > 0
      ) {
        updatedSegments[0].originLocationCode = getIATA(value);
      }
    }
    setSegments(updatedSegments);
  };
  const updateSegmentDate = (index, value) => {
    const newSegments = [...segments];
    if (newSegments[index]) {
      // Check if newSegments[index] exists before accessing its properties
      newSegments[index].departureDateTimeRange = {
        ...newSegments[index].departureDateTimeRange,
        date: moment(value).utc().format("YYYY-MM-DD"),
      };
      setSegments(newSegments);
    }
  };
  // Handler function to remove a segment from state
  const removeSegment = (e, index) => {
    e.preventDefault();
    const updatedSegments = [...segments];
    updatedSegments.splice(index, 1);
    setSegments(updatedSegments);
  };

  useEffect(() => {
    const id = setInterval(() => {
      setreloadCard(true);
    }, 300000); // show 5mins
    return () => {
      clearInterval(id);
    };
  }, [reloadCard]);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const { classref, isClassVisible, setIsClassVisible } =
    useClassSelector(false);
  const dispatch = useDispatch();
  const {
    ticketType,
    selectedTicketType,
    numberofAdults,
    numberofInfants,
    numberofChildren,
    numberofHeldInfants,
    isError,
    errorcode,
  } = useSelector((state) => state.flight);
  useEffect(() => {
    dispatch(reset());
  }, []);
  const travellers = [];
  /// to generate ID
  function randomString(length, chars) {
    let result = "";
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  // To map the travellers based on ID
  function travellerMapper() {
    const adultIds = [];
    for (let i = 0; i < numberofAdults; i++) {
      const adultId = randomString(
        10,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      );
      adultIds.push(adultId);
      travellers.push({
        id: adultId,
        travelerType: "ADULT",
      });
    }
    for (let j = 0; j < numberofChildren; j++) {
      travellers.push({
        id: `${randomString(
          10,
          "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
        )}`,
        travelerType: "CHILD",
      });
    }
    for (let k = 0; k < numberofInfants; k++) {
      travellers.push({
        id: `${randomString(
          10,
          "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
        )}`,
        travelerType: "SEATED_INFANT",
      });
    }
    for (let l = 0; l < numberofHeldInfants; l++) {
      travellers.push({
        id: `${randomString(
          10,
          "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
        )}`,
        travelerType: "HELD_INFANT",
        associatedAdultId: adultIds[l % adultIds.length], // Distribute among adults
      });
    }
    return travellers;
  }

  useEffect(() => {
    sessionStorage.setItem("travellers", JSON.stringify(travellers));
  }, [travellers]);
  // Round trip data

  // final

  // Do not delete the console.logs
  travellerMapper();

  const [tripCards, setTripCards] = useState([1]);
  const [travelerDropdown, settravelerDropdown] = useState(false);
  const [ticketDropdown, setticketDropdown] = useState(false);
  const [changeMultiTripBtn, setchangeMultiTripBtn] = useState(true);

  const encodedsegments = encodeObjectToURLComponent(segments);
  const encodedtravellers = encodeObjectToURLComponent(travellers);

  // Validate Engine

  const validateEngine = (e) => {
    e.preventDefault();
    const errors = [];
    segments.forEach((segment, index) => {
      if (
        segment?.originLocationCode &&
        segment?.destinationLocationCode !== "" &&
        segment?.originLocationCode === segment?.destinationLocationCode
      ) {
        errors.push({
          index,
          field: "destination",
          message: "Origin and Destination can't be same",
        });
      }
    });
    segments.forEach((segment, index) => {
      if (!segment?.originLocationCode) {
        errors.push({
          index,
          field: "origin",
          message: "Origin is Required",
        });
      }
    });

    segments.forEach((segment, index) => {
      if (!segment?.destinationLocationCode) {
        errors.push({
          index,
          field: "destination",
          message: "Destination is Required",
        });
      }
    });
    segments.forEach((segment, index) => {
      if (!segment?.departureDateTimeRange?.date) {
        errors.push({
          index,
          field: "date",
          message: "Date is Required",
        });
      }
    });

    if (tripCategory === "roundTrip") {
      // console.log(segments);
      segments.forEach((segment, index) => {
        if (
          !segments[0]?.departureDateTimeRange?.date ||
          !segments[1]?.departureDateTimeRange?.date
        ) {
          errors.push({
            index,
            field: "date",
            message: "Arrival and departure dates are required.",
          });
        }
      });
    }
    // console.log(errors);
    setEngineErrors(errors);
    if (errors.length === 0) {
      Router.push({
        pathname: `/flights/flightresults`,
        query: {
          segments: encodedsegments,
          travellers: encodedtravellers,
          triptype: tripCategory,
          ticketType: ticketType.toUpperCase(),
        },
      });
    }
    // return false;
  };
  const handleSearch = (event) => {
    event.preventDefault();
    // setSearchQuery(data);
    validateEngine(event);
  };

  useEffect(() => {
    // Update replacementList whenever segments changes
    const replacementList = [
      {
        id: Date.now(),
        originLocationCode: segments[0].destinationLocationCode,
        destinationLocationCode: segments[0].originLocationCode,
        departureDateTimeRange: { date: "" },
      },
    ];
    // Do something with replacementList, e.g. pass it to a function or update state
    // ...
    setRoundSegment(replacementList);
  }, [
    segments[0].originLocationCode,
    segments[0].destinationLocationCode,
    tripCategory,
    segments,
  ]);
  // console.log(segments);

  const activateOneTrip = () => {
    dispatch(setTriptype("singleTrip"));
    setTripCategory("oneWay");
    const replacementList = [...segments];
    setSegments([replacementList[0]]);
    setTripCard([1]);
    setEngineErrors([]);
  };
  const activateRoundTrip = () => {
    dispatch(setTriptype("roundTrip"));
    setTripCategory("roundTrip");
    setSegments([...segments, roundSegment[0]]);
    setTripCard([1]);
    setEngineErrors([]);
  };
  const activateMultiTrip = () => {
    dispatch(setTriptype("multiTrip"));
    setTripCategory("multiTrip");
    setEngineErrors([]);

    // Create a new array with the first segment and the new object
    const newSegment = {
      id: generateId(8),
      originLocationCode: "",
      destinationLocationCode: "",
      departureDateTimeRange: { date: "" },
    };

    const replacementList = [segments[0], newSegment];

    setSegments(replacementList);
    setTripCard([1, 1]);
    setFormattedValueDeparture([...formattedValueDeparture, "", "", "", ""]);
    setFormattedValueArrival([...formattedValueArrival, "", "", "", ""]);
  };

  const totalTraveler =
    numberofAdults + numberofChildren + numberofInfants + numberofHeldInfants;

  const minusAdultNo = () => {
    if (numberofAdults > 1) {
      dispatch(minusAdult(numberofAdults - 1));
      travellerMapper();
    }
  };
  const plusAdultNo = () => {
    if (totalTraveler < 9) {
      dispatch(addAdult(numberofAdults + 1));
      travellerMapper();
    }
  };
  const minusChildrenNo = () => {
    if (numberofChildren > 0) {
      dispatch(minusChild(numberofChildren - 1));
      travellerMapper();
    }
  };
  const plusChildrenNo = () => {
    if (totalTraveler < 9) {
      dispatch(addChild(numberofChildren + 1));
      travellerMapper();
    }
  };
  const minusInfantNo = () => {
    if (numberofInfants > 0) {
      dispatch(minusInfant(numberofInfants - 1));
      travellerMapper();
    }
  };
  const plusInfantNo = () => {
    if (totalTraveler < 9) {
      dispatch(addInfant(numberofInfants + 1));
      travellerMapper();
    }
  };
  const minusHeldInfantNo = () => {
    if (numberofHeldInfants > 0) {
      dispatch(minusHeldInfant(numberofHeldInfants - 1));
      travellerMapper();
    }
  };
  const plusHeldInfantNo = () => {
    if (totalTraveler < 9 && numberofAdults / 2 > numberofHeldInfants) {
      dispatch(addHeldInfant(numberofHeldInfants + 1));
      travellerMapper();
    }
  };
  const selectTicketType = (ticket, selection) => {
    dispatch(setTicketType(ticket));
    setIsClassVisible(false);
    dispatch(setselectedTicketType(selection));
  };

  const addTrip = (e) => {
    e.preventDefault();
    if (tripCards.length < 5) {
      const replacementList = [
        ...tripCards,
        tripCards.push(tripCards.length + 1),
      ];
      setTripCards(replacementList);
      setchangeMultiTripBtn(!changeMultiTripBtn);
    }
  };
  const removeTrip = (e, index) => {
    e.preventDefault();
    setTripCards([
      ...tripCards.slice(0, index),
      ...tripCards.slice(index + 1, tripCards.length),
    ]);
  };
  const ticketTypeMapping = {
    ECONOMY: t("economy"),
    FIRST: t("first_class"),
    PREMIUM_ECONOMY: t("premium_economy"),
    BUSINESS: t("business"),
    // Add more mappings as needed
  };
  function formatTicketType(ticket) {
    return ticketTypeMapping[ticket] || ticket; // Use mapping or original value if not found
  }
  return (
    <div className={`${styles.flightBooking}`}>
      {/* {flightResult && reloadCard && ( */}
      {isError && (
        <Modal>
          <section className="section">
            <ErrorHandler code={errorcode} />
          </section>
        </Modal>
      )}
      {/* )} */}
      <div className={styles.header}>
        <h1 className=" text-[23px] leading-8 sm:text-3xl font-[700] ">
          {t("herotext")}
        </h1>
      </div>

      <div className={styles.options}>
        <ul className={styles.tripType}>
          <li
            onClick={activateOneTrip}
            className={`${tripCategory === "oneWay" ? styles.active : ""}`}
          >
            {t("oneway_be")}
          </li>
          <li
            onClick={activateRoundTrip}
            className={`${tripCategory === "roundTrip" ? styles.active : ""}`}
          >
            {t("roundtrip_be")}
          </li>
          <li
            onClick={activateMultiTrip}
            className={`${tripCategory === "multiTrip" ? styles.active : ""}`}
          >
            {t("multitrip_be")}
          </li>
        </ul>

        {/* ticketType */}
        <div className={styles.ticketType}>
          <div ref={ref} className={styles.travelers}>
            <p
              className={styles.travelerName}
              onClick={() => setIsComponentVisible(!isComponentVisible)}
            >
              {totalTraveler}{" "}
              {`${totalTraveler > 1 ? "Travelers" : t("traveler")}`}{" "}
              <FaChevronDown
                className={`${styles.icons} ${
                  isComponentVisible ? styles.rotate : ""
                }`}
              />
            </p>
            {isComponentVisible && (
              <ul className={styles.travelerNumbers}>
                <li>
                  <div className={styles.left}>
                    <span className={styles.person}>{t("adult")}</span>
                    <span className={styles.range}>
                      {">"}
                      {t("12years")}
                    </span>
                  </div>
                  <div className={styles.right}>
                    <span className={styles.iconBorder} onClick={minusAdultNo}>
                      <FaMinus className={styles.iconsInput} />
                    </span>
                    <span className={styles.personNo}>{numberofAdults}</span>
                    <span className={styles.iconBorder} onClick={plusAdultNo}>
                      <FaPlus className={styles.iconsInput} />
                    </span>
                  </div>
                </li>
                <li>
                  <div className={styles.left}>
                    <span className={styles.person}>{t("child")}</span>
                    <span className={styles.range}>{t("2to12years")}</span>
                  </div>

                  <div className={styles.right}>
                    <span
                      className={styles.iconBorder}
                      onClick={minusChildrenNo}
                    >
                      <FaMinus className={styles.iconsInput} />
                    </span>
                    <span className={styles.personNo}>{numberofChildren}</span>
                    <span
                      className={styles.iconBorder}
                      onClick={plusChildrenNo}
                    >
                      <FaPlus className={styles.iconsInput} />
                    </span>
                  </div>
                </li>

                <li>
                  <div className={styles.left}>
                    <span className={styles.person}> {t("InfantOnLap")}</span>
                    <span className={styles.range}>
                      {" "}
                      {"<"} {t("lessThan2years")}
                    </span>
                  </div>

                  <div className={styles.right}>
                    <span
                      className={styles.iconBorder}
                      onClick={minusHeldInfantNo}
                    >
                      <FaMinus className={styles.iconsInput} />
                    </span>
                    <span className={styles.personNo}>
                      {numberofHeldInfants}
                    </span>

                    <span
                      className={styles.iconBorder}
                      onClick={plusHeldInfantNo}
                    >
                      <FaPlus className={styles.iconsInput} />
                    </span>
                  </div>
                </li>
                <li>
                  <div className={styles.left}>
                    <span className={styles.person}> {t("InfantOnSeat")}</span>
                    <span className={styles.range}>
                      {" "}
                      {"<"} {t("lessThan2years")}
                    </span>
                  </div>

                  <div className={styles.right}>
                    <span className={styles.iconBorder} onClick={minusInfantNo}>
                      <FaMinus className={styles.iconsInput} />
                    </span>
                    <span className={styles.personNo}>{numberofInfants}</span>

                    <span className={styles.iconBorder} onClick={plusInfantNo}>
                      <FaPlus className={styles.iconsInput} />
                    </span>
                  </div>
                </li>
                <div className={styles.btnWrapper}>
                  <button
                    type="button"
                    onClick={() => setIsComponentVisible(false)}
                  >
                    {t("done")}
                  </button>
                </div>
              </ul>
            )}
          </div>

          <div ref={classref} className={styles.ticketClass}>
            <p
              className={styles.ticketName}
              onClick={() => setIsClassVisible(!isClassVisible)}
            >
              {formatTicketType(selectedTicketType)}
              <FaChevronDown
                className={`${styles.icons} ${
                  isClassVisible ? styles.rotate : ""
                }`}
              />
            </p>
            {isClassVisible && (
              <ul className={styles.ticketOptions}>
                <li onClick={() => selectTicketType("ECONOMY", t("economy"))}>
                  {t("economy")}
                </li>
                <li
                  onClick={() =>
                    selectTicketType("PREMIUM_ECONOMY", t("premium_economy"))
                  }
                >
                  {t("premium_economy")}
                </li>
                <li onClick={() => selectTicketType("BUSINESS", t("business"))}>
                  {t("business")}
                </li>
                <li onClick={() => selectTicketType("FIRST", t("first_class"))}>
                  {t("first_class")}
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      {/* flight engine */}
      {tripCategory === "oneWay" && (
        <OneWaySegment
          toggleSegment={toggleSegment}
          segments={segments}
          tripCards={tripCards}
          updateSegment={updateSegment}
          updateSegmentDate={updateSegmentDate}
          triptype={tripCategory}
          handleSearch={handleSearch}
          formattedValueDeparture={formattedValueDeparture}
          setFormattedValueDeparture={setFormattedValueDeparture}
          formattedValueArrival={formattedValueArrival}
          setFormattedValueArrival={setFormattedValueArrival}
          allDates={allDates}
          engineErrors={engineErrors}
        />
      )}
      {tripCategory === "roundTrip" && (
        <RoundTripSegment
          toggleSegment={toggleSegment}
          segments={segments}
          tripCards={tripCards}
          updateSegment={updateSegment}
          triptype={tripCategory}
          updateSegmentDate={updateSegmentDate}
          handleRoundUpdate={handleRoundUpdate}
          setSegments={setSegments}
          handleSearch={handleSearch}
          formattedValueDeparture={formattedValueDeparture}
          setFormattedValueDeparture={setFormattedValueDeparture}
          formattedValueArrival={formattedValueArrival}
          setFormattedValueArrival={setFormattedValueArrival}
          allDates={allDates}
          engineErrors={engineErrors}
        />
      )}
      {tripCategory === "multiTrip" && (
        <MultiTripSegment
          toggleSegment={toggleSegment}
          triptype={tripCategory}
          segments={segments}
          tripCards={tripCards}
          updateSegment={updateSegment}
          updateSegmentDate={updateSegmentDate}
          removeSegment={removeSegment}
          addSegment={addSegment}
          handleSearch={handleSearch}
          formattedValueDeparture={formattedValueDeparture}
          setFormattedValueDeparture={setFormattedValueDeparture}
          formattedValueArrival={formattedValueArrival}
          setFormattedValueArrival={setFormattedValueArrival}
          allDates={allDates}
          engineErrors={engineErrors}
        />
      )}
    </div>
  );
}
OneWaySegment.propTypes = {
  engineErrors: PropTypes.shape.isRequired,
  handleSearch: PropTypes.func.isRequired,
  updateSegment: PropTypes.func.isRequired,
  tripCards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSegmentDate: PropTypes.func.isRequired,
  triptype: PropTypes.string.isRequired,
  toggleSegment: PropTypes.func.isRequired,
  formattedValueDeparture: PropTypes.string.isRequired,
  handleRoundUpdate: PropTypes.func.isRequired,
  setFormattedValueDeparture: PropTypes.func.isRequired,
  formattedValueArrival: PropTypes.string.isRequired,
  setFormattedValueArrival: PropTypes.func.isRequired,
  allDates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      originLocationCode: PropTypes.string.isRequired,
      destinationLocationCode: PropTypes.string.isRequired,
      departureDateTimeRange: PropTypes.shape({
        date: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
RoundTripSegment.propTypes = {
  engineErrors: PropTypes.shape.isRequired,
  handleSearch: PropTypes.func.isRequired,
  triptype: PropTypes.string.isRequired,
  updateSegment: PropTypes.func.isRequired,
  tripCards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSegmentDate: PropTypes.func.isRequired,
  handleRoundUpdate: PropTypes.func.isRequired,
  setSegments: PropTypes.func.isRequired,
  toggleSegment: PropTypes.func.isRequired,
  formattedValueDeparture: PropTypes.string.isRequired,
  setFormattedValueDeparture: PropTypes.func.isRequired,
  formattedValueArrival: PropTypes.string.isRequired,
  setFormattedValueArrival: PropTypes.func.isRequired,
  allDates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      originLocationCode: PropTypes.string.isRequired,
      destinationLocationCode: PropTypes.string.isRequired,
      departureDateTimeRange: PropTypes.shape({
        date: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
MultiTripSegment.propTypes = {
  engineErrors: PropTypes.shape.isRequired,
  addSegment: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  triptype: PropTypes.string.isRequired,
  removeSegment: PropTypes.func.isRequired,
  updateSegment: PropTypes.func.isRequired,
  tripCards: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSegmentDate: PropTypes.func.isRequired,
  toggleSegment: PropTypes.func.isRequired,
  handleRoundUpdate: PropTypes.func.isRequired,
  formattedValueDeparture: PropTypes.string.isRequired,
  setFormattedValueDeparture: PropTypes.func.isRequired,
  formattedValueArrival: PropTypes.string.isRequired,
  setFormattedValueArrival: PropTypes.func.isRequired,
  allDates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      originLocationCode: PropTypes.string.isRequired,
      destinationLocationCode: PropTypes.string.isRequired,
      departureDateTimeRange: PropTypes.shape({
        date: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
export default BookingEngine;

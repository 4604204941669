import React from "react";
import Image from "next/image";
import { useSelector } from "react-redux";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import Img1 from "../../../public/images/culture.png";
import Img2 from "../../../public/images/destination.png";
import Img3 from "../../../public/images/experience.png";
import Img4 from "../../../public/images/press_release.png";

function Blog() {
  // rebuff
  const { blogs } = useSelector((state) => state.flight);
  const { t } = useTranslation();

  return (
    blogs !== null && (
      <div>
        <h2 className="text-2xl sm:text-3xl font-[700]">{t("travelBlog")}</h2>
        <p>{t("travelBlogSub")}</p>

        <div className={styles.blog}>
          <div className={styles.postSection1}>
            <div className={`${styles.card} ${styles.card1}`}>
              <a target="_blank" href={blogs[1].link} rel="noreferrer">
                <div className={styles.imageWrapper}>
                  <Image src={Img1} alt="Tramango Blog Post" layout="fill" />
                  {/* <h6 className={styles.title}>{blogs[1].name}</h6> */}
                  <h6 className={styles.title}>{t("cultures")}</h6>
                </div>
                <p className={styles.link}>
                  {t("exploreCultures")}
                  <FaArrowRight className={styles.icon} />
                </p>
              </a>
            </div>
          </div>

          <div className={styles.postSection2}>
            <div className={`${styles.card} ${styles.card2}`}>
              <a target="_blank" href={blogs[2].link} rel="noreferrer">
                <div className={styles.imageWrapper}>
                  <Image src={Img2} layout="fill" alt="Tramango Blog Post" />
                  <h6 className={styles.title}> {t("destinations")}</h6>
                </div>
                <p className={styles.link}>
                  {t("exploreDestinations")}
                  <FaArrowRight className={styles.icon} />
                </p>
              </a>
            </div>
          </div>

          <div className={styles.postSection3}>
            <div className={`${styles.card} ${styles.card3}`}>
              <a target="_blank" href={blogs[5].link} rel="noreferrer">
                <div className={styles.imageWrapper}>
                  <Image src={Img3} alt="Tramango Blog Post" layout="fill" />
                  <h6 className={styles.title}> {t("stories")}</h6>
                </div>
                <p className={styles.link}>
                  {t("exploreStories")}
                  <FaArrowRight className={styles.icon} />
                </p>
              </a>
            </div>
            <div className={`${styles.card} ${styles.card4}`}>
              <a target="_blank" href={blogs[4].link} rel="noreferrer">
                <div className={styles.imageWrapper}>
                  <Image src={Img4} alt="Tramango Blog Post" layout="fill" />
                  <h6 className={styles.title}>{t("pressReleases")}</h6>
                </div>
                <p className={styles.link}>
                  {t("readPress")}
                  <FaArrowRight className={styles.icon} />
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Blog;

import PropTypes from "prop-types";
import React, { useState } from "react";
import { IoIosPin, IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useDepartureVisible } from "../../../hooks/useComponentVisible";
import FlightSwitcher from "../FlightSwitcher";
import { airportLocations } from "../../../utils/dropdownValues";

function DepartureInput({
  index,
  updateSegment,
  handleRoundUpdate,
  triptype,
  toggleSegment,
  formattedValueDeparture,
  setFormattedValueDeparture,
  engineErrors,
  // segments,
}) {
  const { t } = useTranslation();
  const { ref, isDepartureVisible, setIsDepartureVisible } =
    useDepartureVisible(false);

  const [filteredDepartureResults, setFilteredDepartureResults] = useState([]);
  const [departureInput, setDepartureInput] = useState("");
  // const [formattedValue, setFormattedValue] = useState("");

  const searchDepartures = (searchText) => {
    setDepartureInput(searchText);

    if (searchText !== "") {
      const lowerCaseSearchText = searchText.toLowerCase();
      const results = [];

      const calculateRelevanceScore = (item) => {
        const values = Object.values(item).map((value) =>
          value.toLowerCase().replace(/[()]/g, "")
        );
        const concatenatedValues = values.join(" ");
        const matchIndex = concatenatedValues.indexOf(lowerCaseSearchText);
        return matchIndex >= 0 ? 1000 - matchIndex : 0;
      };

      const iataMatch = airportLocations.find(
        (item) => item.iata.toLowerCase() === lowerCaseSearchText
      );

      if (iataMatch) {
        results.push({ item: iataMatch, relevanceScore: 2000 });
      }

      airportLocations.forEach((item) => {
        const relevanceScore = calculateRelevanceScore(item);
        if (relevanceScore > 0 && item !== iataMatch) {
          results.push({ item, relevanceScore });
        }
      });

      results.sort((a, b) => b.relevanceScore - a.relevanceScore);

      const sortedItems = results.map((result) => result.item);

      setFilteredDepartureResults(sortedItems);
    } else {
      setFilteredDepartureResults(null);
    }
  };

  const showDepartureDropdown = (e) => {
    e.preventDefault();
    setIsDepartureVisible(true);
  };
  const handleDepartureChange = (e) => {
    e.preventDefault();
    searchDepartures(e.target.value);
    const newFormattedValue = [...formattedValueDeparture];
    newFormattedValue[index] = e.target.value;
    setFormattedValueDeparture(newFormattedValue);
    showDepartureDropdown(e);
    if (newFormattedValue[index] === "") {
      updateSegment(index, "originLocationCode", "");
    }
  };

  const clearInput = () => {
    setDepartureInput("");
    const newFormattedValue = [...formattedValueDeparture];
    newFormattedValue[index] = "";
    setFormattedValueDeparture(newFormattedValue);
    updateSegment(index, "originLocationCode", "");
  };
  const handleSelect = (e, iata, city) => {
    e.preventDefault();
    const newFormattedValue = [...formattedValueDeparture];
    newFormattedValue[index] = `${city} (${iata})`;
    setFormattedValueDeparture(newFormattedValue);
    updateSegment(index, "originLocationCode", `${city} (${iata})`);
    setIsDepartureVisible(false);
    if (triptype === "roundTrip") {
      handleRoundUpdate();
    }
  };

  const errors = engineErrors?.filter(
    (error) => error.index === index && error.field === "origin"
  );

  return (
    <>
      <div ref={ref} className={styles.departure}>
        <label htmlFor="departure">
          <IoIosPin className={styles.inputIcon} />
          <input
            className={styles.input}
            name="departure"
            type="text"
            placeholder={t("departurePlaceholder")}
            autoComplete="off"
            value={formattedValueDeparture[index]}
            onChange={(e) => handleDepartureChange(e, index)}
            required
          />
          {formattedValueDeparture[index] && (
            <button type="button" onClick={clearInput}>
              <IoIosClose className="text-3xl mr-2" />
            </button>
          )}
        </label>
        {departureInput?.length > 0 && isDepartureVisible ? (
          <ul className={styles.citySuggestions}>
            {departureInput?.length > 0
              ? // eslint-disable-next-line no-shadow
                filteredDepartureResults?.map((item, index) => {
                  return (
                    <li
                      onClick={(e) => handleSelect(e, item.iata, item.city)}
                      key={index}
                    >
                      <div className={styles.left}>
                        <span className={styles.top}>{item.city}</span>
                        <span className={styles.bottom}>
                          {item.airport} ({item.iata})
                        </span>
                      </div>
                      <div className={styles.right}>
                        <span className={styles.btn}>{item.iata}</span>
                      </div>
                    </li>
                  );
                })
              : null}
          </ul>
        ) : null}
        <FlightSwitcher toggleSegment={toggleSegment} index={index} />
        {errors?.length > 0 && (
          <p className="text-error text-sm block mt-1">{errors[0].message}</p>
        )}
      </div>
      {/* dummy comment */}
    </>
  );
}
DepartureInput.propTypes = {
  index: PropTypes.number.isRequired,
  updateSegment: PropTypes.func.isRequired,
  handleRoundUpdate: PropTypes.func.isRequired,
  triptype: PropTypes.string.isRequired,
  toggleSegment: PropTypes.func.isRequired,
  // segments: PropTypes.shape.isRequired,
  formattedValueDeparture: PropTypes.string.isRequired,
  setFormattedValueDeparture: PropTypes.func.isRequired,
  engineErrors: PropTypes.shape.isRequired,
};
export default DepartureInput;

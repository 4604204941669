import PropTypes from "prop-types";
import React, { useState } from "react";
import { IoIosPin, IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useArrivalVisible } from "../../../hooks/useComponentVisible";
import { airportLocations } from "../../../utils/dropdownValues";

function ArrivalInput({
  index,
  updateSegment,
  handleRoundUpdate,
  triptype,
  formattedValueArrival,
  setFormattedValueArrival,
  engineErrors,
}) {
  const { t } = useTranslation();
  const [arrivalInput, setArrivalInput] = useState("");
  const [filteredArrivalResults, setFilteredArrivalResults] = useState([]);
  const { ref, isArrivalVisible, setIsArrivalVisible } =
    useArrivalVisible(false);
  const showArrivalDropdown = (e) => {
    e.preventDefault();
    setIsArrivalVisible(true);
  };

  const handleSelect = (e, iata, city) => {
    e.preventDefault();
    const newFormattedValue = [...formattedValueArrival];
    newFormattedValue[index] = `${city} (${iata})`;
    setFormattedValueArrival(newFormattedValue);
    updateSegment(index, "destinationLocationCode", `${city} (${iata})`);
    setIsArrivalVisible(false);
    if (triptype === "roundTrip") {
      handleRoundUpdate();
    }
  };

  const searchArrivals = (searchText) => {
    setArrivalInput(searchText);

    if (searchText !== "") {
      const lowerCaseSearchText = searchText.toLowerCase();
      const results = [];

      const calculateRelevanceScore = (item) => {
        const values = Object.values(item).map((value) =>
          value.toLowerCase().replace(/[()]/g, "")
        );
        const concatenatedValues = values.join(" ");
        const matchIndex = concatenatedValues.indexOf(lowerCaseSearchText);
        return matchIndex >= 0 ? 1000 - matchIndex : 0;
      };

      const iataMatch = airportLocations.find(
        (item) => item.iata.toLowerCase() === lowerCaseSearchText
      );

      if (iataMatch) {
        results.push({ item: iataMatch, relevanceScore: 2000 });
      }

      airportLocations.forEach((item) => {
        const relevanceScore = calculateRelevanceScore(item);
        if (relevanceScore > 0 && item !== iataMatch) {
          results.push({ item, relevanceScore });
        }
      });

      results.sort((a, b) => b.relevanceScore - a.relevanceScore);

      const sortedItems = results.map((result) => result.item);

      setFilteredArrivalResults(sortedItems);
    } else {
      setFilteredArrivalResults(null);
    }
  };

  const handleArrivalChange = (e) => {
    e.preventDefault();
    searchArrivals(e.target.value);
    const newFormattedValue = [...formattedValueArrival];
    newFormattedValue[index] = e.target.value;
    setFormattedValueArrival(newFormattedValue);
    showArrivalDropdown(e);
    if (newFormattedValue[index] === "") {
      updateSegment(index, "destinationLocationCode", "");
    }
  };

  const clearInput = () => {
    setArrivalInput("");
    const newFormattedValue = [...formattedValueArrival];
    newFormattedValue[index] = "";
    setFormattedValueArrival(newFormattedValue);
    updateSegment(index, "destinationLocationCode", "");
  };

  const errors = engineErrors?.filter(
    (error) => error.index === index && error.field === "destination"
  );
  return (
    <div ref={ref} className={styles.arrival}>
      <label htmlFor="arrival">
        <IoIosPin className={styles.inputIcon} />
        <input
          className={styles.input}
          id="arrival"
          name={`arrival${index}`}
          type="text"
          autoComplete="off"
          value={formattedValueArrival[index]}
          placeholder={t("arrivalPlaceholder")}
          onChange={(e) => handleArrivalChange(e, index)}
          required
        />
        {formattedValueArrival[index] && (
          <button type="button" onClick={clearInput}>
            <IoIosClose className="text-3xl mr-2" />
          </button>
        )}
      </label>

      {arrivalInput?.length > 1 && isArrivalVisible ? (
        <ul className={styles.citySuggestions}>
          {arrivalInput?.length > 1
            ? // eslint-disable-next-line no-shadow
              filteredArrivalResults?.map((item, index) => {
                return (
                  <li
                    onClick={(e) => handleSelect(e, item.iata, item.city)}
                    key={index}
                  >
                    <div className={styles.left}>
                      <span className={styles.top}>{item.city}</span>
                      <span className={styles.bottom}>
                        {item.airport} ({item.iata})
                      </span>
                    </div>
                    <div className={styles.right}>
                      <span className={styles.btn}>{item.iata}</span>
                    </div>
                  </li>
                );
              })
            : null}
        </ul>
      ) : null}
      {errors?.length > 0 && (
        <p className="text-error mt-1 text-sm">{errors[0].message}</p>
      )}
      {/* dummy comment  */}
    </div>
  );
}
ArrivalInput.propTypes = {
  index: PropTypes.number.isRequired,
  updateSegment: PropTypes.func.isRequired,
  handleRoundUpdate: PropTypes.func.isRequired,
  triptype: PropTypes.string.isRequired,
  formattedValueArrival: PropTypes.string.isRequired,
  setFormattedValueArrival: PropTypes.func.isRequired,
  engineErrors: PropTypes.shape.isRequired,
};
export default ArrivalInput;

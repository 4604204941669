import { airports } from "../dropdownValues/worldCities";

export function getNearestAirport(ipInfo) {
  const R = 6371; // Earth's radius in kilometers
  const lat1 = ipInfo.latitude;
  const lon1 = ipInfo.longitude;

  let minDist = Infinity;
  let nearestAirport = null;

  for (let i = 0; i < airports.length; i++) {
    const airport = airports[i];
    if (airport.iata_code) {
      const [lon2, lat2] = airport.coordinates.split(",");
      const dLat = ((lat2 - lat1) * Math.PI) / 180;
      const dLon = ((lon2 - lon1) * Math.PI) / 180;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
          Math.cos((lat2 * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const dist = R * c;
      if (dist < minDist) {
        minDist = dist;
        nearestAirport = airport;
      }
    }
  }

  if (nearestAirport) {
    return {
      city: nearestAirport.municipality,
      iata_code: nearestAirport.iata_code,
    };
  }
  return null;
}

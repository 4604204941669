"use client";

/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Head from "next/head";
import { setActivePage } from "../features/sidebar/sidebarSlice";
import { getPopularTrips, getBlogs } from "../features/flight/flightThunk";
import { setAirport } from "../features/auth/authSlice";
import { getNearestAirport } from "../utils/flight/nearestAirports";
import BookingEngine from "../components/flight/BookingEngine";
import PopularTrips from "../components/flight/PopularTrips";
import CustomerSupport from "../common/CustomerSupport";
import SubscribeSection from "../common/SubscribeSection";
import Affiliations from "../common/Affiliations";
import Publications from "../common/Publications";
import Blog from "../components/flight/Blog";
import FlightTravelDiscount from "../common/advertCards/FlightTravelDiscount";
import ClientOnly from "../utils/ClientOnly";
import DocHead from "../layouts/Head";
import config from "../website.config";
import SeatMap from "../components/flight/SeatMap";
import { FrontPageAds } from "../features/advert/advertThunk";
import { checkCookie } from "../features/auth/authThunk";

export default function Home() {
  const dispatch = useDispatch();
  dispatch(setActivePage("flight"));
  const { FRONT_PAGE_ADS } = useSelector((state) => state.advert);
  const affiliatedCompanies = [
    "/images/affiliations/Amadeus-Black.png",
    "/images/affiliations/Amadeus-Coloured.png",
    "/images/affiliations/Travelport-black.png",
    "/images/affiliations/Travelport-coloured.png",
    "/images/affiliations/Nanta-black.png",
    "/images/affiliations/Nanta-colored.png",
    "/images/affiliations/ncaa-black.png",
    "/images/affiliations/ncaa-coloured.png",
    "/images/affiliations/IATA-Blacks.png",
    "/images/affiliations/IATA-Coloureds.png",
  ];

  const publicatedCompanies = [
    "/images/publications/TechCabal-black.png",
    "/images/publications/TechCabal-coloured.png",
    "/images/publications/Techpoint-black.png",
    "/images/publications/Techpoint-coloured.png",

    "/images/publications/Businessday-black.png",
    "/images/publications/Businessday-coloured.png",
  ];

  useEffect(() => {
    dispatch(FrontPageAds());
    dispatch(getBlogs());
    dispatch(getPopularTrips());
    dispatch(checkCookie());
    localStorage.removeItem("confirmation");
  }, []);
  // const { closestairport } = useSelector((state) => state.auth);
  // const { location } = useSelector((state) => state.auth);
  // if (location) {
  //   const result = getNearestAirport(location);
  //   console.log(result);
  //   if (result) {
  //     console.log(
  //       `Your nearest airport is ${result.city} (${result.iata_code})`
  //     );
  //     dispatch(setAirport(result.iata_code));
  //   } else {
  //     console.log("Could not find nearest airport.");
  //   }
  // }

  // useEffect(() => {
  //   if (closestairport) {
  //     dispatch(getPopularTrips(closestairport));
  //   }

  //   dispatch(getBlogs());
  // }, [dispatch, closestairport]);

  return (
    <ClientOnly>
      <div className="container">
        <DocHead
          url={config?.url}
          title={config?.title}
          description={config?.description}
        />

        <main>
          <section className="section">
            <BookingEngine />
          </section>

          <section className="section mt-14 ">
            <FlightTravelDiscount images={FRONT_PAGE_ADS} />
          </section>
        </main>

        <aside className=" -mt-12">
          <section className="section">
            <PopularTrips />
          </section>

          <section className="section">
            <CustomerSupport />
          </section>

          <section className="section">
            <Blog />
          </section>

          <section className="section">
            <SubscribeSection />
          </section>

          <section className="section">
            <Affiliations affiliatedCompaniesCloud={affiliatedCompanies} />
          </section>

          <section className="section">
            <Publications publicatedCompanies={publicatedCompanies} />
          </section>
        </aside>
      </div>
    </ClientOnly>
  );
}

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-destructuring */
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// Import Swiper React components
import Router from "next/router";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// eslint-disable-next-line import/no-unresolved
import "swiper/css";
// import required modules
import { Autoplay } from "swiper";
// import { getNearestAirport } from "../../../utils/flight/nearestAirports";
// import { setAirport } from "../../../features/auth/authSlice";
import * as airports from "../../../utils/flight/airportNames";
import { encodeObjectToURLComponent } from "../../../utils/transformers/encodeObjectToURLComponent";
import {
  formatFlightTime,
  formatNGN,
  formatDOLS,
  formatXOF,
  formatGHS,
  formatXAF,
} from "../../../utils/formatingFunctions";

import styles from "./styles.module.scss";

function TripCardSkeleton() {
  return (
    <article className={styles.tripCardSkeleton}>
      <div className={styles.info}>
        <div className={styles.box} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
      </div>
    </article>
  );
}

function TripCard({ trip }) {
  function extractFlightInfo(data) {
    const flightInfo = {};

    // Extract segments
    const segments = data.itineraries[0].segments;
    if (segments.length > 0) {
      const firstSegment = segments[0];
      flightInfo.segments = [
        {
          id: firstSegment.id,
          originLocationCode: firstSegment.departure.iataCode,
          destinationLocationCode:
            segments[segments.length - 1].arrival.iataCode,
          departureDateTimeRange: {
            date: firstSegment.departure.at.split("T")[0],
            time: firstSegment.departure.at.split("T")[1],
          },
        },
      ];
    }

    // Extract travelers
    const travelers = data.travelerPricings;
    if (travelers.length > 0) {
      const traveler = travelers[0];
      flightInfo.travelers = [
        {
          id: traveler.travelerId,
          travelerType: traveler.travelerType,
          fareOptions: [traveler.fareOption],
        },
      ];
    }

    // Extract cabin and trip type
    flightInfo.cabin = "ECONOMY";
    flightInfo.tripType = "oneWay";

    return flightInfo;
  }
  const itinerary = extractFlightInfo(trip);
  const encodedsegments = encodeObjectToURLComponent(itinerary.segments);
  const encodedtravellers = encodeObjectToURLComponent(itinerary.travelers);
  const handleSearch = (event) => {
    event.preventDefault();
    Router.push({
      pathname: `/flights/flightresults`,
      query: {
        segments: encodedsegments,
        travellers: encodedtravellers,
        triptype: itinerary.tripType,
        ticketType: itinerary.cabin.toUpperCase(),
      },
    });
  };
  const getAirportCity = (code) => {
    if (airports[code] === undefined) {
      return code || "";
    }
    return airports[code][0];
  };
  const getAirlineLogo = (code) => {
    if (code === undefined) {
      return `/images/airlineImages/UA-02.png`;
    }
    const src = `/images/airlineImages/${code}.png`;
    return src;
  };

  // const base64ToImage = (buffer) => {
  //   const uint8Array = new Uint8Array(buffer);
  //   const binaryString = uint8Array.reduce(
  //     (acc, byte) => acc + String.fromCharCode(byte),
  //     ""
  //   );
  //   const base64Image = btoa(binaryString);
  //   return base64Image;
  // };

  const { currency } = useSelector((state) => state.auth);

  return (
    <article onClick={(e) => handleSearch(e)} className={styles.tripCard}>
      <img
        className={styles.locationImage}
        src={trip?.googleimage}
        alt="trip image"
      />
      <div className={styles.info}>
        <h3 className={styles.title}>
          {getAirportCity(
            trip?.itineraries?.[0]?.segments[
              trip?.itineraries?.[0]?.segments?.length - 1
            ].arrival?.iataCode
          )}
        </h3>
        <p className={styles.date}>{trip?.period}</p>
        <div className={styles.flex}>
          <img
            className={`${styles.airline} self-center`}
            src={getAirlineLogo(
              trip?.itineraries?.[0]?.segments?.[0].carrierCode
            )}
            alt="airline logo"
          />
          <p className={styles.additionalInfo}>
            {trip?.itineraries?.[0]?.segments?.length} stops.&nbsp;
            {formatFlightTime(trip?.itineraries?.[0]?.duration)}
          </p>
        </div>
        <p className={styles.price}>
          {currency === "NGN" && (
            <span>{formatNGN(trip?.price?.grandTotalTramangoNaira)}</span>
          )}
          {currency === "USD" && (
            <span>{formatDOLS(trip?.price?.grandTotalTramango)}</span>
          )}
          {currency === "GHS" && (
            <span>{formatGHS(trip?.price?.grandTotalTramangoGHS)}</span>
          )}
          {currency === "XOF" && (
            <span>{formatXOF(trip?.price?.grandTotalTramangoXOF)}</span>
          )}
          {currency === "XAF" && (
            <span>{formatXAF(trip?.price?.grandTotalTramangoXAF)}</span>
          )}
        </p>
        <p className={styles.clear} />
      </div>
    </article>
  );
}

TripCard.propTypes = {
  trip: PropTypes.shape.isRequired,
};

function PopularTrips() {
  const { populartrips, ptloading } = useSelector((state) => state.flight);
  const { t } = useTranslation();
  if (ptloading || populartrips === null) {
    return (
      <div className="popularTrips">
        <h2 className="section-title ">{t("popularTrips")}</h2>
        <p className=" pb-3 ">{t("popularTripsSubText")}</p>
        <Swiper
          direction="horizontal"
          loop
          slidesPerView="auto"
          autoplay={{ delay: 2000 }}
          modules={[Autoplay]}
          spaceBetween={20}
          className="mySwiper"
        >
          {Array(6)
            .fill()
            .map((_, index) => (
              <SwiperSlide key={index}>
                <TripCardSkeleton />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  }

  return (
    <div className="">
      <h2 className="text-2xl sm:text-3xl font-[700] ">{t("popularTrips")}</h2>
      <p className="section-subheading mb-5">{t("popularTripsSubText")}</p>

      {populartrips?.length > 0 && (
        <div className="popularTrips">
          <Swiper
            direction="horizontal"
            loop
            slidesPerView="auto"
            autoplay={{ delay: 5000 }}
            modules={[Autoplay]}
            spaceBetween={20}
            className="mySwiper"
          >
            {populartrips?.map((trip, index) => (
              <SwiperSlide key={index}>
                <TripCard trip={trip} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      {populartrips?.length === 0 && <a>{t("popularTripsNotFound")}</a>}
    </div>
  );
}

export default PopularTrips;

import PropTypes from "prop-types";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import adImage from "../../../public/images/flight/advert.png";
import styles from "./styles.module.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules

function FlightTravelDiscount({ images }) {
  return (
    <div className={`${styles.flightTravelDiscount}`}>
      {images && images?.length > 0 ? (
        <Swiper
          slidesPerView={1}
          spaceBetween={1}
          loop={images.length > 1}
          loopFillGroupWithBlank
          centeredSlides
          navigation={false}
          autoplay
          // pagination={{
          //   clickable: true,
          // }}
          pagination={false}
          modules={[Pagination, Navigation, Autoplay]}
          className={styles.listingDetailsSwiper}
        >
          {images?.map((item, index) => (
            <SwiperSlide key={index} className={styles.slide}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <span href="#" className={styles.image}>
                  <img href="#" src={item.image} alt={`image${index}`} />
                </span>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="">
          <img src={adImage.src} alt="" />
        </div>
      )}
    </div>
  );
}

export default FlightTravelDiscount;

FlightTravelDiscount.propTypes = {
  images: PropTypes.shape.isRequired,
};
